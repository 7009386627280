import React from "react";
import Tranzacioni from "./Transazioni";
import AdminPanelDom from "../domains/adminPanel/adminPanelDom";
import { connect } from "react-redux";
import MainActions from "redux-store/models/main";
import TransazioniAdmin from "./TransazioniAdmin";

class AdminPanelListaUtenti extends React.Component {
  // componentDidMount() {
  //   this.props.setActiveSkinId(1);
  // }
  // componentDidUpdate() {
  //   if (this.props.activeSkinId === -1) {
  //     this.props.setActiveSkinId(1);
  //   }
  // }
  render() {
    return (
      <AdminPanelDom
      component={({MessaggiComponent})=>
          <TransazioniAdmin forAdmin={true} activeSkinId={this.props.activeSkinId}  MessaggiComponent={MessaggiComponent} />
        }
        {...this.props}
      />
    );
  }
}
const mapStatToProps = (state) => ({
  activeSkinId: state.main.activeSkinId,
});
export default connect(mapStatToProps, MainActions)(AdminPanelListaUtenti);
