import React from "react"
import {connect} from "react-redux";

const DownloadVisure=({loading,handleClick})=>{
    return <button className="printBtn" onClick={handleClick}>{loading?   <div className="moderspinner"></div>:
    <>
     <i className="fal fa-cloud-upload" />
    {`Scarica documento`}
    </>
   }</button>
}

const mapStateToProps=({auth:{visureDocumentLoading}})=>({
  loading: visureDocumentLoading,
})

export default connect(mapStateToProps)(DownloadVisure);