import React from "react"
import { Header } from "../../shared-components"
import InfoRequestModal from "../../shared-components/InfoRequestModal/InfoRequestModal"

const SimpleRegisterUser = () => {
  return (
    <div >
      <Header />
      <div className="info-request" style={{display: "flex", justifyContent: "center", padding:30}}>
      <InfoRequestModal />
      </div>
    </div>
  )
}

export default SimpleRegisterUser
