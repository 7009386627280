import React, { Component } from "react";
import { Select, notification, Checkbox } from "antd";
import TextArea from "antd/lib/input/TextArea";
import images from "themes/images";
import { connect } from "react-redux";
import { AuthActions, MainActions } from "redux-store/models";
import InviaPrenotationButton from "shared-components/InviaPrenotationButton/InviaPrenotationButton";
import VoliUserFrom from "./VoliUserFrom";

const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
class Eventi extends Component {
  state = {
    categoria: 0,
    quantity: 0,
    categoryArray: [
      "Selezionare Evento",
      "Concerti",
      "Sport",
      "Museo",
      "Teatro",
      "Altro",
    ],
    quantityArray: [
      "Selezionare un numero",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6+(specificare su note)",
    ],
    prezzo: null,
    destinazione: "",
    options: [
      "Accetto l'Informativa Privacy.*",
      "Acconsento al trattamento dei dati personali a fini di marketing diretto e di",
    ],
    checkedList: [],
    indeterminate: true,
    checkAll: false,
    travalers: {},
    adults: 1,
    children: 0,
    codice_fiscale: ""
  };
  resetState = (msg) => {
    if (!msg.error) {
      this.setState({
        link: "",
        nome_agenzia: "",
        extra_data: "",
        descrizione_categoria: "",
        name: "",
        surname: "",
        email: "",
        telefono: "",
        prezzo: null,
        destinazione: "",
        travalers: {},
        codice_fiscale: ""
      });
      notification["success"]({
        message: "Azione completata",
        description: msg.msg,
        placement: "bottomRight",
      });
    } else {
      notification["error"]({
        message: msg.msg[0],
        description: msg.msg[1],
        placement: "bottomRight",
        duration: 5,
      });
    }
  };
  submitData = (onSucces = () => {}) => {
    const {
      link,
      extra_data,
      categoria,
      email,
      telefono,
      destinazione,
      adults,
      children,
      codice_fiscale
    } = this.state;
    this.props.sendDataForm(
      this.state.prezzo ? this.state.prezzo : 0.00,
      this.props.typee,
      link,
      this.props.nome_agenzia,
      JSON.stringify(this.state.travalers),
      null,
      null,
      (msg) => {
        this.resetState(msg);
        onSucces();
      },
      "",
      "",
      "",
      destinazione,
      "",
      adults,
      children,
      "",
      "",
      "",
      categoria,
      extra_data ? extra_data : "",
      "",
      "",
      email,
      telefono,
      "",
      "",
      this.props.accountInfo.profile.hasSepafinCredentials,
      this.props.accountInfo.profile.username,
      codice_fiscale
    );
  };

  onChange = (list) => {
    const { options } = this.state;
    this.setState({
      checkedList: list,
      indeterminate: !!list.length && list.length < options.length,
      checkAll: list.length === options.length,
    });
  };

  onCheckAllChange = (e) => {
    const { options } = this.state;
    this.setState({
      checkedList: e.target.checked ? options : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };
  setTravalers = (travalers, travaler) => {
    this.setState((prevState) => {
      return {
        travalers: prevState.travalers
          ? {
              ...prevState.travalers,
              ...travalers,
              [`${travaler}`]: {
                ...prevState.travalers[travaler],
                ...travalers[travaler],
              },
            }
          : {
              ...travalers,
              [`${travaler}`]: {
                ...travalers[travaler],
              },
            },
      };
    });
  };
  render() {
    const { nome_agenzia, color, goBack, isMobile, activeService } = this.props;
    const {
      options,
      indeterminate,
      checkAll,
      checkedList,
      adults,
      children,
    } = this.state;
    return (
      <div className="formsContainer--body animated fadeIn eventi">
        {!isMobile && (
          <div className="leftForm">
            <img src={images[`${nome_agenzia}-bg`]} alt="" className="imgBg" />
            <img
              src={images[`${nome_agenzia}-logo`]}
              alt=""
              className="imgLogo"
            />
            <div
              className="overlayImg"
            ></div>
          </div>
        )}

        <div className="rightForm">
          <div className="rightForm--header">
            {!isMobile && (
              <div className="TitleBack" style={{ color: color }}>
                <i className="fal fa-chevron-left Arrow" onClick={goBack}></i>
                Prenotazione Biglietti Sport & Spettacolo{" "}
              </div>
            )}

            {isMobile && (
              <div className="TitleBack">
                {" "}
                <i className="fal fa-ticket-alt"></i> {activeService}{" "}
              </div>
            )}
            <img
              className={nome_agenzia}
              src={images[`${nome_agenzia}-logo`]}
              alt={[`${nome_agenzia}-logo`]}
            />
          </div>
          <div className="rightForm--left">
            <div className="formsContainer--body__item">
              <div className="label">
                Nome Agenzia <span className="Red">*</span>
              </div>
              <input
                value={this.props.accountInfo?.profile?.name || ""}
                type="text"
                disabled
              />
            </div>
            <div className="formsContainer--body__item">
              <div className="label">
                Link Evento <span className="Red">*</span>
              </div>
              <input
                value={this.state.link || ""}
                onChange={(e) => {
                  this.setState({ link: e.target.value });
                }}
                type="text"
                placeholder="Link"
              />
            </div>
            <div className="formsContainer--body__item">
              <div className="label">Prezzo </div>
              <input
                placeholder="Prezzo"
                value={this.state.prezzo || ""}
                onChange={(e) => {
                  this.setState({ prezzo: e.target.value });
                }}
                type="text"
              />
            </div>
            <div className="formsContainer--body__item">
              <div className="label">
                Categoria <span className="Red">*</span>
              </div>

              <Select
                onChange={(value) => {
                  this.setState({ categoria: value });
                }}
                defaultValue={"Selezionare Evento"}
              >
                <Option value="0">Selezionare Evento</Option>
                <Option value="1">Concerti</Option>
                <Option value="2">Sport</Option>
                <Option value="3">Museo</Option>
                <Option value="4">Teatro</Option>
                <Option value="5">Altro</Option>
              </Select>
            </div>
            <div className="formsContainer--body__item">
              <div className="label">
                Luogo dell’evento<span className="Red">*</span>
              </div>
              <input
                value={this.state.destinazione || ""}
                onChange={(e) => {
                  this.setState({ destinazione: e.target.value });
                }}
                type="text"
                placeholder="Luogo dell’evento"
              />
            </div>
            <div className="formsContainer--body__item">
              <div className="label">
                Email <span className="Red">*</span>
              </div>
              <input
                value={this.state.email || ""}
                type="text"
                onChange={(e) => {
                  this.setState({ email: e.target.value });
                }}
                placeholder="Email"
              />
            </div>
            <div className="formsContainer--body__item ">
              <div className="label">
                {" "}
                Telefono <span className="Red">*</span>
              </div>
              <input
                value={this.state.telefono || ""}
                type="number"
                onChange={(e) => {
                  this.setState({ telefono: e.target.value });
                }}
                placeholder="Telefono"
              />
            </div>
            <div className="formsContainer--body__item ">
              <div className="label checkbox">
                Selezione Termini e Condizioni <span className="Red">*</span>{" "}
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={this.onCheckAllChange}
                  checked={checkAll}
                >
                  Seleziona Tutti
                </Checkbox>
              </div>

              <CheckboxGroup
                options={options}
                value={checkedList}
                onChange={this.onChange}
              />
            </div>
          </div>
          <div className="rightForm--right">
            <div className="formsContainer--body__item">
              <div className="label">
                Qta biglietti<span className="Red">*</span>
              </div>
              <div className="formsContainer--body__semiCont qta">
                <div className="formsContainer--body__item semi">
                  <span>Adulti</span>
                  <div>
                    <i
                      onClick={() =>
                        this.setState({
                          adults: adults < 1 ? 0 : adults - 1,
                        })
                      }
                      className="fal fa-minus-square"
                      aria-hidden="true"
                    ></i>
                    <span> {adults}</span>

                    <i
                      onClick={() =>
                        this.setState({
                          adults: adults >= 6 ? 6 : adults + 1,
                        })
                      }
                      className="fal fa-plus-square"
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
                <div className="formsContainer--body__item semi ">
                  <span>
                    Ragazzi
                    <i className="far fa-info-circle" title="Info" />
                  </span>
                  <div>
                    <i
                      onClick={() =>
                        this.setState({
                          children: children < 1 ? 0 : children - 1,
                        })
                      }
                      className="fal fa-minus-square"
                      aria-hidden="true"
                    ></i>
                    <span>{children}</span>
                    <i
                      onClick={() =>
                        this.setState({
                          children: children >= 6 ? 6 : children + 1,
                        })
                      }
                      className="fal fa-plus-square"
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            {new Array(adults)
              .join(".")
              .split(".")
              .map((a, b) => {
                return (
                  adults > 0 && (
                    <VoliUserFrom
                      bigliettiForm
                      handleChangeName={this.setTravalers}
                      handleChangeCognome={this.setTravalers}
                      handleChangeDate={this.setTravalers}
                      handleChangeTel={this.setTravalers}
                      handleChangeEmail={this.setTravalers}
                      handleChangeBirthPlace={this.setTravalers}
                      handleChangeProvince={this.setTravalers}
                      handleChangeSesso={this.setTravalers}
                      ind={b}
                      isAdult={true}
                      key={b}
                    />
                  )
                );
              })}
            {new Array(children)
              .join(".")
              .split(".")
              .map((a, b) => {
                return (
                  children > 0 && (
                    <VoliUserFrom
                      bigliettiForm
                      handleChangeName={this.setTravalers}
                      handleChangeCognome={this.setTravalers}
                      handleChangeDate={this.setTravalers}
                      handleChangeTel={this.setTravalers}
                      handleChangeEmail={this.setTravalers}
                      handleChangeBirthPlace={this.setTravalers}
                      handleChangeProvince={this.setTravalers}
                      handleChangeSesso={this.setTravalers}
                      ind={b}
                      isChild={true}
                      key={b}
                    />
                  )
                );
              })}
            <div className="formsContainer--body__item ">
              <div className="label">
                {" "}
                Codice fiscale
              </div>
              <input
                value={this.state.codice_fiscale || ""}
                type="text"
                onChange={(e) => {
                  this.setState({ codice_fiscale: e.target.value });
                }}
                placeholder="Codice"
              />
            </div>  
            <div className="formsContainer--body__item">
              <div className="label">
                Note, indicare eventuali preferenze per il posto o altre
                informazioni utili
              </div>
              <TextArea
                value={this.state.extra_data || ""}
                type="number"
                onChange={(e) => {
                  this.setState({ extra_data: e.target.value });
                }}
              />
            </div>
            <InviaPrenotationButton
              handleClick={this.submitData}
              color={color}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mstp = (state) => {
  return {
    accountInfo: state.auth.accountInfo,
  };
};
export default connect(mstp, { ...AuthActions, ...MainActions })(Eventi);
