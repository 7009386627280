import React, { Fragment } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import AuthActions from "redux-store/models/auth"
import NewRegisterAgent from "../../shared-components/adminSharedComp/NewRegisterAgent/NewRegisterAgent"
import { Header } from "../../shared-components"
class RegisterAgent extends React.Component {
  render() {
    return (
      <Fragment>
        {!this.props.accountInfo?.profile?.activity_id &&
          this.props.accountInfo?.profile?.role?.name !== "main_admin" && (
            <Header></Header>
          )}
        <NewRegisterAgent />
      </Fragment>
    )
  }
}

const mapsStateToProps = ({ auth }) => ({
  accountInfo: auth.accountInfo,
})

export default withRouter(
  connect(mapsStateToProps, { ...AuthActions })(RegisterAgent)
)
