import React, { useEffect,useRef } from "react";
import { Html5Qrcode } from "html5-qrcode";
import { useState } from "react";
import $ from "jquery";
import { Loader } from "shared-components"

// const config={
//   qrCode:{ fps: 10, qrbox: { width: 300, height: 300 } },
//    barCode:{ 
//         fps: 10,
//         qrbox: { width: 300, height: 150 },
//         // Important notice: this is experimental feature, use it at your
//         // own risk. See documentation in
//         // mebjas@/html5-qrcode/src/experimental-features.ts
//         experimentalFeatures: {
//             useBarCodeDetectorIfSupported: true
//         },
//         rememberLastUsedCamera: true
//  },
// }
let html5QrCode;

const QrCode = (props) => {
    const { onSubmit } = props;
    const date = useRef(null);
    // const params = new Proxy(new URLSearchParams(window.location.hash.replace("#/scan-mobile","")), {
    //   get: (searchParams, prop) => searchParams.get(prop),
    // });
    // const [type,setType]=useState(params.type in config ? params.type : "qrCode");
    const [mounted,setMounted]=useState(false)

  const handleStop = () => {
    try {
      html5QrCode
        .stop()
        .then(() => {
          html5QrCode.clear();
        })
        .catch((err) => {
          console.log(err.message);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const qrCodeSuccessCallback=(decodedText)=> {
    const reader = $("#reader");
    reader.addClass("succeed")
    if(!date.current)  date.current = new Date().toString();
    const successPass = (new Date() - new Date(date.current)) / 1000 >= 0.5
    if(successPass && decodedText && onSubmit){
        reader.removeClass("succeed")
        if(onSubmit) onSubmit(decodedText);
    }
  }

  const onErr = ()=> {
    $("#reader").removeClass("succeed")
    $("#reader").removeAttr("label-data")
  }

  const mount = async () => {
     await (setMounted(false));
     html5QrCode = await new Html5Qrcode("reader");
    await html5QrCode.start({ facingMode: "environment" },{ fps: 10, qrbox: { width: 300, height: 300 } }, qrCodeSuccessCallback,onErr);
   await (setMounted(true))
   await ($("#reader-wrapper").addClass("align-center-wrapper"))
  }

   useEffect(() => {
   mount()
    return () => {
      handleStop()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  // const handleChange = useCallback((e) => setType(e.target.value),[])
  return (
    <React.Fragment>
      {/* <div className="scanner-type">
        <h6>Chose type</h6>
         <Radio.Group
                onChange={handleChange}
                value={type}
              >
                <Radio value='qrCode'>Qr Code</Radio>
                <Radio value='barCode'>Barcode</Radio>
              </Radio.Group>
      </div> */}
 <div id="reader-wrapper" className={mounted?"is-mounted":  ""} style={styles}>
      <div id="reader" width="100%" />
    </div>
    <div className="empty-scanner-fill"/>
    {!mounted &&  <Loader />}
    </React.Fragment>
   
  );
};

const styles = { position: "relative" }

export default QrCode;
