import React from "react"
import moment from "moment"

const ModalRespForDepositi = ({ Close, mobilePopUpData, exception }) => (
  <React.Fragment>
    <div className="rowPopUp ">
      <div className="rowPopUp--header">
        Dettagli{" "}
        <i
          onClick={() => {
            Close()
          }}
          className="fal fa-times"
          aria-hidden="true"
        ></i>{" "}
      </div>
      <div className="rowPopUp--body">
        <div className="rowPopUp--body__item">
          <span>Importo</span>
          <span>
            {mobilePopUpData.amount !== "" ? mobilePopUpData.amount + "€" : "-"}
          </span>
        </div>
        <div className="rowPopUp--body__item">
          <span>Commissione</span>
          <span>
            {mobilePopUpData.amount !== "" ? mobilePopUpData.commissione + "€" : "-"}
          </span>
        </div>
        <div className="rowPopUp--body__item">
          <span>Metodo di pagamento</span>
          <span>{mobilePopUpData.metodo}</span>
        </div>
        <div className="rowPopUp--body__item">
          <span>Eseguito il</span>
          <span>{moment(mobilePopUpData.eseguito_il).format(
                                    "DD/MM/YY HH:mm"
                                  )}</span>
        </div>
        <div className="rowPopUp--body__item">
          <span>Caricato il</span>
          {mobilePopUpData.caricato_il !== "" ? moment(mobilePopUpData.eseguito_il).format(
                                    "DD/MM/YY HH:mm"
                                  ) : "-" }
        </div>
        <div className="rowPopUp--body__item">
          <span>Mittente</span>
          <span>{mobilePopUpData.mittente}</span>
        </div>
        <div className="rowPopUp--body__item">
          <span>Destinatario</span>
          <span>{mobilePopUpData.destinatario}</span>
        </div>
        <div className="rowPopUp--body__item">
          <span>Stato</span>
          <span>{mobilePopUpData.stato === "accepted" ? "Accettato" : mobilePopUpData.stato === "rejected" ? "Rifiutato" : "In Attesa"}</span>
        </div>
        <div className="rowPopUp--body__item">
          <span>Ricevuta</span>
          <span>{mobilePopUpData.document ? "Apri l'allegato" : "Non pervenuto"}</span>
        </div>
      </div>
    </div>

    <div
      onClick={() => {
        Close()
      }}
      className="backDrop"
    ></div>
  </React.Fragment>
)
export default ModalRespForDepositi
