import { createStore, applyMiddleware, combineReducers, compose } from "redux"
import createSagaMiddleware from "redux-saga"
import { notification } from "antd"

import { reducer as AuthReducer } from "./models/auth"
import { reducer as MainReducer } from "./models/main"
import { reducer as ShopReducer } from "./models/shop"
import rootSaga from "./sagas"

const rootReducer = combineReducers({
  auth: AuthReducer,
  main: MainReducer,
  shop: ShopReducer,
})

export function configureStore() {
  const sagaMiddleware = createSagaMiddleware({
    onError: (err) => {
      notification["error"]({
        message: "Oh-no! Something went wrong",
      })
    },
  })
  //redux extension logger
  const composeEnhancers =
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose
  const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware))
  return {
    ...createStore(rootReducer, enhancer),
    runSaga: sagaMiddleware.run,
  }
}

export const store = configureStore()
store.runSaga(rootSaga)
window.store = store
