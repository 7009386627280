import React from "react"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"

class SpedizioniRow extends React.Component {
  render() {
    return (
      <div className="formsContainer--body__semiCont mt-2">
        <div className="formsContainer--body__item semi">
          <div className="label">
            <i className="fas fa-weight-hanging"></i> Peso (kg)
          </div>
          <input
            type="text"
            placeholder="kg"
            onChange={this.props.handleChangePeso}
            defaultValue={this.props.defaultValuePeso}
          />
        </div>
        <div className="formsContainer--body__item semi">
          <div className="label">
            <i className="fas fa-ruler-vertical"></i> Altezza (cm)
          </div>
          <input
            type="text"
            placeholder="cm"
            onChange={this.props.handleChangeAltezza}
            defaultValue={this.props.defaultValueAltezza}
          />
        </div>
        <div className="formsContainer--body__item semi">
          <div className="label">
            <i className="fas fa-ruler-horizontal"></i> Larghezza (cm)
          </div>
          <input
            type="text"
            placeholder="cm"
            onChange={this.props.handleChangeLarghezza}
            defaultValue={this.props.defaultValueLarghezza}
          />
        </div>
        <div className="formsContainer--body__item semi">
          <div className="label">
            <i className="fas fa-ruler"></i> Profondità (cm)
          </div>
          <input
            type="text"
            placeholder="cm"
            onChange={this.props.handleChangeProfondita}
            defaultValue={this.props.defaultValueProfondita}
          />
        </div>
        {this.props.id === 1 ? (
          <div className="formsContainer--body__item semi ">
            <button onClick={this.props.handleAddRow}>
              <i className="fas fa-plus-circle"></i>Aggiungi collo
            </button>
          </div>
        ) : (
          <div className="formsContainer--body__item semi ">
            <button onClick={this.props.handleRemoveRow} className="remove-btn">
              <i className="fas fa-minus-circle"></i>Rimuovi collo {this.props.id}
            </button>
          </div>
        )}
      </div>
    )
  }
}

const mapsStateToProps = (state) => ({})

export default connect(mapsStateToProps, { ...MainActions, ...AuthActions })(
  SpedizioniRow
)
