import React,{useState} from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import {visureSlector } from "utils/HelperFunc";
 import { AuthActions } from "redux-store/models";
 import _ from "lodash";

const VisureFilter = (props)=>{
    const {servicesVisure,searchedVisure,setActive,activeService,categoryShown,className,handleClick,visureMobileDetails,setVisureMobileDetails}=props;
    const [mobileState,setMobileState]=useState(setActive?{}:(visureMobileDetails || {}));
    const reduxActiveService = JSON.stringify(visureMobileDetails?.activeService || {});
    const stateActiveService = JSON.stringify(mobileState?.activeService || {});
    useEffect(()=>{
        if(!_.isEqual(stateActiveService,reduxActiveService)){
            handleClick && handleClick();          
            window.location.href=window.location.origin + "#/visure"
            setVisureMobileDetails(mobileState)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[stateActiveService])
    const updateMobileState = (newState={})=>setMobileState({...mobileState,...newState})
    return   <div className={`itemWrapper ${className || ""}`}>
              {Object.keys(servicesVisure || {}).map((service) => {
                return (
                  <VisureItem
                    searchedVisure={searchedVisure}
                    key={service}
                    setActive={setActive || updateMobileState}
                    activeService={mobileState?.activeService || activeService}
                    categoryShown={mobileState?.activeCategory || categoryShown}
                    service={service}
                    allServices={servicesVisure}
                  />
                );
              })}
            </div>
}

const VisureItem = ({ service, activeService, setActive, categoryShown, allServices, searchedVisure }) => {
  const isCategoryOpened = service === categoryShown;

  const categoryClick = () => {
    const openStatus = !isCategoryOpened && service;
    setActive({ categoryShown: openStatus });
    if (openStatus) setActive({ activeCategory: openStatus });
  };
  return (
    <div className={"visures--services__item" + (isCategoryOpened ? " active" : "")}>
      <div onClick={categoryClick} className="header">
        <span>{service}</span>{" "}
        <i className={`fal fa-${isCategoryOpened ? "minus" : "plus"}-circle`} aria-hidden="true"></i>
      </div>
      {!!(isCategoryOpened && allServices) && (
        <ul className="animated fadeIn">
          {allServices[service].services
            .filter(
              ({ name }) => (name || "").toLowerCase().includes((searchedVisure || "").toLowerCase()) || !searchedVisure
            )
            .map((item) => {
              return (
                <li
                  kwy={item?.name}
                  className={activeService?.name === item?.name ? "active" : ""}
                  onClick={() => setActive({ activeService: item })}
                >
                  {item.name}
                </li>
              );
            })}
        </ul>
      )}
    </div>
  );
};

const mapsStateToProps = (state) => {
  return {
    servicesVisure:visureSlector(state),
        visureMobileDetails:state.auth.visureMobileDetails,
  };
};

export default connect(mapsStateToProps,AuthActions)(VisureFilter);