import React, { useRef } from "react"
import { connect } from "react-redux"
import ReactToPrint from "react-to-print"
import { MainActions, AuthActions } from "redux-store/models"
import DownloadVisure from "shared-components/DownloadVisure"
import { baseUrlImg } from "../../config/api"

const TicketLayout = ({
  children,
  arr,
  captureRechargeMobileSim,
  bolletinoCapture,
  setBolletiniBianchi,
  setBolletiniPremercati,
  setRechargeMobile,
  getVisureDocument,
  skinExtras,
  activeSkinName,
}) => {
  const componentRef = useRef()
  const barcode = Array.isArray(arr) ? arr[0]?.barcode : arr?.barcode
  const isVisure = ["JSON", "json_bitnovo"].includes(arr.receipt_type)

  const skinName = localStorage.getItem("skin_name") || ""

  const getPrintBtn = () => {
    return arr?.message === "Reserve processed successfully" ? (
      <div style={{ display: "flex", gap: "10px" }}>
        <div
          className="printBtn acceta"
          onClick={() => {
            arr.service_type === "TOPUP" || arr.type === "Phone Recharge"
              ? captureRechargeMobileSim(arr.payload)
              : bolletinoCapture(arr.payload)
          }}
        >
          ACCETA
        </div>
        <div
          className="printBtn annulla"
          onClick={() => {
            setBolletiniBianchi([])
            setBolletiniPremercati([])
            setRechargeMobile([])
          }}
        >
          ANNULLA
        </div>
      </div>
    ) : (
      <ReactToPrint
        trigger={() => <div className="printBtn">Print</div>}
        content={() => componentRef.current}
        bodyClass="afterprint"
        copyStyles="false"
      />
    )
  }

  return (
    <div
      className={`printModal rootTicket ${isVisure ? "visure" : ""}`}
      ref={componentRef}
    >
      <style type="text/css" media="print">
        {
          "\
   @page {size: 71mm 200mm;margin: 0;}\
"
        }
      </style>
      <div className="headerModal">
        <img
          className={`logo ${skinExtras.name}`}
          src={
            activeSkinName
              ? `${baseUrlImg}/logo/${activeSkinName}.svg`
              : `${baseUrlImg}/logo/${skinName}.svg`
          }
          alt=""
        />
        <span className="superSmall text-bold">
          <span> {skinExtras.account_name} </span>
        </span>
        <span className="superSmall">{skinExtras.address}</span>
        <span className="superSmall link">{skinExtras.email}</span>
        <span className="superSmall ">Tel: {skinExtras.cel}</span>
        <span className="superSmall">P.IVA 03852290406</span>
        <span className="fontSmall text-bold">{arr.agency_name}</span>
        <span className="fontSmall address">{arr.agency_address}</span>
      </div>
      {children}
      <div className="buttons">
        {arr.receipt_type === "JSON" && !(arr.barcode || "").includes("000024") && (
          <DownloadVisure handleClick={() => getVisureDocument(barcode)} />
        )}
        {getPrintBtn()}
      </div>
    </div>
  )
}

const mapsStateToProps = (state) => ({
  skinExtras: state.auth.skinExtras,
  activeSkinName: state.main.activeSkinName,
})

export default connect(mapsStateToProps, { ...MainActions, ...AuthActions })(
  TicketLayout
)
