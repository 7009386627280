import React, { Component, Fragment } from "react";
import { notification } from "antd";
import MyInput from "./Input";
import FormSubmiter from "./FormSubmiter";
import TelefonoInput from "./TelefonoInput";
class Enagic extends Component {
  state = {
    nome: "",
    cognome: "",
    email: "",
    price: 0,
    phone: "",
    extra_data: "",
    citta: "",
  };
  componentDidMount() {
    const { TicketByTcketId } = this.props;
    this.setState({
      ...TicketByTcketId,
    });
  }
  componentDidUpdate(prevProps) {
    const { TicketByTcketId } = this.props;
    if (prevProps.TicketByTcketId !== TicketByTcketId) {
      this.setState({
        ...TicketByTcketId,
      });
    }
  }

  resetState = (msg) => {
    if (!msg.error) {
      notification["success"]({
        message: "Azione completata",
        description: msg.msg,
        placement: "bottomRight",
      });
    } else {
      notification["error"]({
        message: msg.msg[0],
        description: msg.msg[1],
        placement: "bottomRight",
        duration: 5,
      });
    }
  };
  submitData = () => {
    const {
      nome,
      cognome,
      email,
      phone,
      price,
      extra_data,
      citta,
    } = this.state;
    this.props.updateDataForm(
      this.props.typee,
      null,
      this.props.TicketByTcketId.nome_agenzia,
      extra_data,
      null,
      null,
      this.resetState,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      nome,
      email,
      null,
      price,
      this.props.ticketId,
      2,
      cognome,
      phone,
      null,
      citta
    );
  };
  render() {
    const { editable } = this.props;
    const {
      nome,
      cognome,
      email,
      phone,
      price,
      extra_data,
      citta,
    } = this.state;
    return (
      <React.Fragment>
        <div className="formBody">
          <div className="formBody--col">
            <Fragment>
              <MyInput
                labelName={"Nome:"}
                type={"text"}
                editable={editable}
                value={nome}
                handleChange={(e) => {
                  this.setState({ nome: e.target.value });
                }}
              />
              <MyInput
                labelName={"Cognome:"}
                type={"text"}
                editable={editable}
                value={cognome}
                handleChange={(e) => {
                  this.setState({ cognome: e.target.value });
                }}
              />
              <MyInput
                labelName={"Email:"}
                type={"text"}
                editable={editable}
                value={email}
                handleChange={(e) => {
                  this.setState({ email: e.target.value });
                }}
              />
              <TelefonoInput
                nome={nome}
                editable={editable}
                value={phone}
                set={(val) => {
                  this.setState({ phone: val });
                }}
              />
            </Fragment>
          </div>
          <div className="formBody--col">
            <MyInput
              labelName={"Citta:"}
              type={"text"}
              editable={editable}
              value={citta}
              handleChange={(e) => {
                this.setState({ citta: e.target.value });
              }}
            />
            <div className="itemCol full">
              <div className="inputLabel">Prezzo:</div>
              <input className="ant-input" value={price} readOnly />
            </div>
            <div className="itemCol full">
              <label className="inputLabel">NOTE:</label>
              <textarea
                onChange={(e) => {
                  this.setState({ extra_data: e.target.value });
                }}
                value={extra_data || ""}
              />{" "}
            </div>
          </div>
        </div>
        <FormSubmiter
          price={price}
          priceChange={(e) => {
            this.setState({ price: e });
          }}
          sendOffert={this.submitData}
        />
      </React.Fragment>
    );
  }
}

export default Enagic;
