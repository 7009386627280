import React from "react"
import AdminPanelDom from "../domains/adminPanel/adminPanelDom"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import AdminNewStatistiche from "../../shared-components/adminSharedComp/adminNewStatistiche/adminNewStatistiche"
import AdminWallet from "../../shared-components/adminSharedComp/adminNewStatistiche/AdminWallet"
import StatisticheTabs from "../../shared-components/adminSharedComp/adminNewStatistiche/StatisticheTabs"
import DonutStatistiche from "../../shared-components/adminSharedComp/adminNewStatistiche/DonutStatistiche"
import StatisticheReportMensile from "../../shared-components/adminSharedComp/adminNewStatistiche/StatisticheReportMensile"
import { Col, Row } from "antd"
import TableStatistiche from "../../shared-components/adminSharedComp/adminNewStatistiche/TableStatistiche"

class AdminPanelStatistiche extends React.Component {
  // componentDidMount() {
  //   this.props.getAnnualiStatitiche(
  //     this.props.activeSkinId !== -1 ? this.props.activeSkinId : 1
  //   )
  // }

  componentDidUpdate(prevProps) {
    if (
      this.props.activeSkinId !== prevProps.activeSkinId &&
      this.props.activeSkinId !== -1
    ) {
      this.props.getAnnualiStatitiche(this.props.activeSkinId)
    }
  }
  render() {
    return (
      <AdminPanelDom
        component={({ MessaggiComponent }) => (
          <div className="admin-statistiche" style={{ width: "100%"}}>
            <AdminWallet accountInfo={this.props.accountInfo} />
            <StatisticheTabs annualiStatitiche={this.props.annualiStatitiche} />
            <Row gutter={16} className="statistiche-row">
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <TableStatistiche />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <DonutStatistiche />
              </Col>
            </Row>

            <StatisticheReportMensile />
          </div>
        )}
        {...this.props}
      />
    )
  }
}
const mapStatToProps = (state) => ({
  activeSkinId: state.main.activeSkinId,
  accountInfo: state.auth.accountInfo,
  annualiStatitiche: state.auth.annualiStatitiche,
})
export default connect(mapStatToProps, { ...MainActions, ...AuthActions })(
  AdminPanelStatistiche
)
