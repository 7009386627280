import React from "react"
import CompaniesCheck from "./CompaniesCheck"
import { removeUnwantedCompanies } from "utils/HelperFunc"
import { disallowedCompanies } from "config/index"
import { skin } from "config/api"

const CompaniesRender = ({
  Companies,
  search,
  toggleFavorite,
  getServices,
  accountInfo,
  changeServce,
  togglePopUp,
}) => {
  const filterCompByServicesId = (
    Companies,
    companyName,
    notAllowedServices = []
  ) => {
    if (Companies.find((comp) => Object.keys(comp)[0] == companyName)) {
      const index = Companies.findIndex(
        (comp) => Object.keys(comp)[0] == companyName
      )
      let replaceDanz = Companies.find((comp) => Object.keys(comp)[0] == companyName)
      replaceDanz[companyName].services = [
        ...replaceDanz[companyName].services.filter(
          ({ service_id }) => !notAllowedServices.includes(service_id)
        ),
      ]
      Companies[index] = replaceDanz
    }
    return Companies
  }

  const FilterCompanies = (Companies, search) => {
    filterCompByServicesId(Companies, "DAZN", [
      "DZN009",
      "DZN029",
      "DZN059",
      "DZN099",
    ])
    const FilterCompanies = []

    let pushed = false
    if (search === "" || !search || !Companies) {
      return Companies
    } else {
      Companies.map((comp) =>
        Object.keys(comp).forEach((key) => {
          if (["BOLL", "SBOLL"].includes(key)) {
            comp[key].services.forEach((service) => {
              if (pushed === false && compareIfAreSimilar(service.name, search)) {
                pushed = true
                FilterCompanies.push(comp)
              }
            })
          } else {
            if (compareIfAreSimilar(comp[key].name, search)) {
              FilterCompanies.push(comp)
            }
          }
        })
      )
    }
    return FilterCompanies.filter((comp) =>
      skin.toString() in disallowedCompanies
        ? disallowedCompanies[skin].includes(Object.keys(comp)[0])
        : true
    )
  }

  const removeDuplicate = (str) => {
    let last = ""
    let result = ""
    for (let i = 0; i < str.length; i++) {
      let char = str.charAt(i)
      if (char !== last) {
        result += char
        last = char
      }
    }
    return result
  }
  const compareIfAreSimilar = (firstWord, secondWord) => {
    let similar = true
    if (firstWord && secondWord && secondWord !== "") {
      let newFWord = removeDuplicate(firstWord.toLowerCase().replace(" ", ""))
      let newSWord = removeDuplicate(secondWord.toLowerCase().replace(" ", ""))

      if (newFWord.includes(newSWord)) {
        similar = true
      } else {
        similar = false
      }
    }
    return similar
  }

  return (
    <>
      {Companies &&
        Array.isArray(Companies) &&
        FilterCompanies(
          removeUnwantedCompanies(Companies, (comp) => {
            const companyKeyID = Object.keys(comp)[0]
            return {
              companyKeyID,
              companyName: comp[companyKeyID].name,
            }
          }),
          search
        ).map((comp) =>
          (Object.keys(comp || {}) || []).map((key, i) => {
            const company = comp[key]
            const { services, name } = company
            return ["BOL001", "SBOL001", "SBOL003"].includes(
              services[0].service_id
            ) ? (
              services.map((service) => {
                return (
                  compareIfAreSimilar(service.name, search) && (
                    <CompaniesCheck
                      toggleFavorite={toggleFavorite}
                      getServices={getServices}
                      id={key}
                      // id={key.replace(/\sTOPUP/, '').trim()}
                      key={service.service_id}
                      submit={() => {
                        changeServce(key, services, name, "", service)
                        togglePopUp(true)
                      }}
                      {...{ company, service }}
                    />
                  )
                )
              })
            ) : comp[key].services[0].service_id === "BGM001-remove_me" &&
              (accountInfo?.profile?.role?.name !== "super_admin" ||
                accountInfo?.profile?.name !== "johny cash") ? null : (
              <CompaniesCheck
                key={comp[key]?.services[0]?.service_id}
                id={key}
                // id={key.replace(/\sTOPUP/, '').trim()}
                toggleFavorite={toggleFavorite}
                getServices={getServices}
                submit={() => {
                  changeServce(key, comp[key].services, comp[key].name)
                  togglePopUp(true)
                }}
                company={comp[key]}
                service={comp[key]}
              />
            )
          })
        )}
    </>
  )
}

export default CompaniesRender
