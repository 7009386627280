import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Collapse, Icon, notification } from "antd"
import { MainActions, AuthActions } from "redux-store/models"
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc"
import "./categoryStyle.css"
import axios from "axios"
import { endpoint } from "../../config/api"

const { Panel } = Collapse

// Drag handle component
const DragHandle = SortableHandle(() => <Icon type="menu" className="menu-icon" />)

// Sortable subcategory element
const SortableSubcategory = SortableElement(
  ({ category, handleEdit, handleDelete }) => {
    const hasChildren = category.children_recursive.length > 0

    const handleSettingsClick = (e) => {
      e.stopPropagation()
      handleEdit(category.id)
    }

    const handleDeleteClick = (e) => {
      e.stopPropagation()
      handleDelete(category.id)
    }

    if (hasChildren) {
      return (
        <Collapse key={category.id} className="subcategory-collapse">
          <Panel
            header={
              <div className="category-header">
                <span>{category.name}</span>
                <div className="icon-group">
                  <Icon type="delete" onClick={handleDeleteClick} />

                  <Icon
                    type="setting"
                    className="settings-icon"
                    onClick={handleSettingsClick}
                  />
                  <DragHandle />
                </div>
              </div>
            }
            key={category.id}
          >
            <SortableSubcategoriesList
              categories={category.children_recursive}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              parentCategoryId={category.id}
              axis="y"
              useDragHandle // Restrict dragging to the handle
            />
          </Panel>
        </Collapse>
      )
    } else {
      return (
        <div key={category.id} className="subcategory no-accordion">
          <div className="category-header">
            <span>{category.name}</span>
            <div className="icon-group">
              <Icon type="delete" onClick={handleDeleteClick} />
              <Icon
                type="setting"
                className="settings-icon"
                onClick={handleSettingsClick}
              />
              <DragHandle />
            </div>
          </div>
        </div>
      )
    }
  }
)

// Sortable container for a list of subcategories
const SortableSubcategoriesList = SortableContainer(
  ({ categories, handleEdit, parentCategoryId, handleDelete }) => {
    return (
      <div className="subcategory-list">
        {categories.map((category, index) => (
          <SortableSubcategory
            key={category.id}
            index={index}
            category={category}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            collection={parentCategoryId} // Collection identifier for sorting
          />
        ))}
      </div>
    )
  }
)

// Sortable main category element
const SortableMainCategory = SortableElement(
  ({ category, handleEdit, onSortEndSubcategory, handleDelete }) => {
    const handleSettingsClick = (e) => {
      e.stopPropagation()
      handleEdit(category.id)
    }

    const handleDeleteClick = (e) => {
      e.stopPropagation()
      handleDelete(category.id)
    }

    return (
      <div className="main-category">
        <div className="main-category-name">
          <span>{category.name}</span>
          <div>
            <Icon type="delete" onClick={handleDeleteClick} />
            <Icon
              type="setting"
              className="settings-icon"
              onClick={handleSettingsClick}
            />
            <DragHandle />
          </div>
        </div>
        {category.children_recursive.length > 0 && (
          <SortableSubcategoriesList
            categories={category.children_recursive}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            onSortEnd={({ oldIndex, newIndex }) =>
              onSortEndSubcategory({ oldIndex, newIndex, collection: category.id })
            }
            axis="y"
            parentCategoryId={category.id}
            useDragHandle // Restrict dragging to the handle
          />
        )}
      </div>
    )
  }
)

// Sortable container for a list of main categories
const SortableMainCategoriesList = SortableContainer(
  ({ categories, handleEdit, onSortEndSubcategory, handleDelete }) => {
    return (
      <div className="main-category-container">
        {categories.map((category, index) => (
          <SortableMainCategory
            key={category.id}
            index={index}
            category={category}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            onSortEndSubcategory={onSortEndSubcategory} // Subcategory sorting handler
          />
        ))}
      </div>
    )
  }
)

// Main component to display categories
const DisplayCategory = ({
  getServicesTree,
  servicesTree,
  handleEdit,
  handleDelete,
  accountInfo,
}) => {
  const [localServicesTree, setLocalServicesTree] = useState([])
  const token = accountInfo?.token

  useEffect(() => {
    getServicesTree()
  }, [getServicesTree])

  useEffect(() => {
    setLocalServicesTree(servicesTree)
  }, [servicesTree])

  // Handler for main categories sorting
  const onSortEndMain = ({ oldIndex, newIndex }) => {
    const newTree = [...localServicesTree]
    const [removed] = newTree.splice(oldIndex, 1)
    newTree.splice(newIndex, 0, removed)
    setLocalServicesTree(newTree)

    const updatedOrder = newTree.map((category, index) => ({
      id: category.id,
      order: index,
    }))

    const headers = {
      Authorization: `Bearer ${token}`,
    }

    updatedOrder.forEach((category) => {
      axios
        .post(`${endpoint}/category/update`, category, { headers })
        .then(() => {
          console.log(`Category ${category.id} reordered successfully!`)
        })
        .catch((err) => {
          console.error(`Error updating order for category ${category.id}:`, err)
        })
    })

    console.log("Reordered Main Categories:", newTree)
  }

  // Handler for subcategories sorting
  const onSortEndSubcategory = ({ oldIndex, newIndex, collection }) => {
    const targetCategoryId = collection

    const targetCategory = localServicesTree.find(
      (category) => category.id === targetCategoryId
    )

    if (!targetCategory) {
      console.error("Target category not found.")
      return
    }

    const newChildren = [...targetCategory.children_recursive]
    const [removed] = newChildren.splice(oldIndex, 1)
    newChildren.splice(newIndex, 0, removed)

    const updatedServicesTree = localServicesTree.map((category) => {
      if (category.id === targetCategoryId) {
        return { ...category, children_recursive: newChildren }
      }
      return category
    })

    setLocalServicesTree(updatedServicesTree)
    console.log("Reordered Subcategories:", updatedServicesTree)
  }

  return (
    <div className="categories">
      <div className="container">
        <div className="title">Categorie Prodotti</div>
        <div className="content">
          <SortableMainCategoriesList
            categories={localServicesTree}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            onSortEnd={onSortEndMain} // Main category sorting callback
            onSortEndSubcategory={onSortEndSubcategory} // Subcategory sorting callback
            useDragHandle
            axis="y"
          />
        </div>
      </div>
    </div>
  )
}

const mapsStateToProps = (state) => ({
  accountInfo: state.auth.accountInfo,
  servicesTree: state.main.servicesTree,
  accountInfo: state.auth.accountInfo,
})

export default connect(mapsStateToProps, { ...MainActions, ...AuthActions })(
  DisplayCategory
)
