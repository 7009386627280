import React from "react";
import images from "themes/images";
import { connect } from "react-redux";
import AuthActions from "redux-store/models/auth";
import { PagoPaLeftForm,PagoPaLeftFormMrPay } from "shared-components/ModulePopUp/PagoPaForms";
import CommonBolletinoWrapper from "./CommonBolletinoWrapper";
import { isSepafinSelector} from "utils/HelperFunc";

const PagoPaForm = ({ setService, activeService, setPagoPa,mrPayPagoPa, allFavServices,isSepafin }) => {
  const commonBolletinoProps ={
    title: "Pago PA",
    favService: "PPA",
    image: <img src={images["pagoPaBack"]} alt="pagoPaBack"/>,
  };

  const Component= isSepafin ? PagoPaLeftForm : PagoPaLeftFormMrPay
  const submit = isSepafin ? setPagoPa : mrPayPagoPa

  return (
    <CommonBolletinoWrapper submit={submit} {...{ commonBolletinoProps, activeService, setService, allFavServices }}>
      {({ getFieldDecorator, getFieldValue,setFieldsValue }, barcode) => (
         <Component
            barcodeData={barcode}
             code={getFieldValue("code")}
      codice_aviso={getFieldValue("codice_aviso")}
      eseguito_da={getFieldValue("eseguito_da")}
            mobile
            {...{ getFieldDecorator, getFieldValue,setFieldsValue }}
          /> 
      )}
    </CommonBolletinoWrapper>
  );
};

const mapStateToProps = (state) => ({
   isSepafin:isSepafinSelector(state),
})

export default connect(mapStateToProps, AuthActions)(PagoPaForm);
