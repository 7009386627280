import React, { useEffect, useState } from "react"
import { numberWithCommas } from "utils/HelperFunc"
import ShopActions from "../../../../redux-store/models/shop"
import "./MammaneroCaffeDomain.css"
import { connect } from "react-redux"
import { caffeeImages } from "../Helpers/caffeImages"
import DettagliPerLaSpedizione from "../DettagliPerLaSpeidzione/DettagliPerLaSpedizione"
import images from "themes/images"
import { typeOfDetails } from "../DettagliPerLaSpeidzione/DettagliPerLaSpedizione"
import PrintTicket from "shared-components/ModulePopUp/PrintTicket"
import PaymentModal from "../PaymentModal/PaymentModal"
import { Loader } from "shared-components"

const MammaneroCaffeDomain = (props) => {
  const {
    coffeeServices,
    getCoffeeServices,
    getItemsCart,
    userData,
    buyCaffe,
    coffeePaymentDetails,
    setCoffeAddress,
    coffeLoader,
    coffeBuyingLoader,
  } = props
  const isMobile = props.screenWidth <= 1024

  const [items, setItems] = useState(
    coffeeServices.map((item) => ({ ...item, checked: false, quantity: 1 }))
  )

  const [modal, setModal] = useState(false)
  const [nome, setNome] = useState("")
  const [codiceFiscale, setCodiceFiscale] = useState("")
  const [paese, setPaese] = useState("")
  const [email, setEmail] = useState("")
  const [citta, setCitta] = useState("")
  const [cognome, setCognome] = useState("")
  const [vila, setVila] = useState("")
  const [telefono, setTelefono] = useState("")
  const [provincia, setProvincia] = useState("")
  const [cap, setCap] = useState("")
  const [nomeSocieta, setNomeSocieta] = useState("")
  const [check, setChecked] = useState(typeOfDetails[0])
  const [print, setPrint] = useState(false)
  const [modalOfFattura, setModalOfFattura] = useState(false)

  useEffect(() => {
    getCoffeeServices()
    getItemsCart(true)
    setCoffeAddress({})
  }, [getCoffeeServices, getItemsCart, setCoffeAddress])

  useEffect(() => {
    setItems(
      coffeeServices.map((item) => ({ ...item, checked: false, quantity: 1 }))
    )
  }, [coffeeServices])

  useEffect(() => {
    if (coffeePaymentDetails && Object.keys(coffeePaymentDetails).length >= 1) {
      setModalOfFattura(true)
    }
  }, [coffeePaymentDetails])

  const startBuying = () => {
    const productArray = items
      .filter(({ checked }) => checked)
      .map((item) => ({ product_id: item.service_id, quantity: item.quantity }))
    buyCaffe(
      JSON.stringify(productArray),
      nome,
      cognome,
      codiceFiscale,
      nomeSocieta,
      paese,
      vila,
      email,
      telefono,
      citta,
      provincia,
      cap
    )
  }

  const modifyItems = (item, quantity, increment) => {
    const updatedQuantity = (currentItem) =>
      quantity ? quantity : (currentItem.quantity || 0) + increment
    setItems(
      items.map((currentItem) =>
        item.service_id === currentItem.service_id
          ? {
              ...currentItem,
              quantity:
                updatedQuantity(currentItem) <= 1 ? 1 : updatedQuantity(currentItem),
            }
          : currentItem
      )
    )
  }

  const getSubtotal = () =>
    items
      .filter(({ checked }) => !!checked)
      .map((item) => parseFloat(item.cost) * item.quantity)
      .reduce((a, b) => a + b, 0)
  const paymentSuccess = !!(
    coffeePaymentDetails && Object.keys(coffeePaymentDetails).length >= 1
  )
  return (
    <React.Fragment>
      {paymentSuccess && (
        <PrintTicket
          arr={coffeePaymentDetails}
          onlyForPrint={true}
          print={print}
          setPrint={(p) => setPrint(p)}
        />
      )}
      {paymentSuccess && modalOfFattura && (
        <PaymentModal
          setModal={() => {
            setCoffeAddress({})
            setModalOfFattura(false)
          }}
          setPrint={setPrint}
        />
      )}
      <div className="MammaneroCaffe">
        {modal && (
          <DettagliPerLaSpedizione
            {...{
              setModal,
              nome,
              setNome,
              codiceFiscale,
              setCodiceFiscale,
              paese,
              setPaese,
              email,
              setEmail,
              citta,
              setCitta,
              cognome,
              setCognome,
              vila,
              setVila,
              telefono,
              setTelefono,
              provincia,
              setProvincia,
              cap,
              setCap,
              nomeSocieta,
              setNomeSocieta,
              check,
              setChecked,
              userData,
            }}
          />
        )}
        <div className="header-wrapper">
          <div className="MammaneroCaffe--header maxWidth">
            <div className="firstPart">
              <div className="header-icon">
                <i className="fal fa-coffee" />
              </div>
              {!isMobile && (
                <HeaderDetails getSubtotal={getSubtotal} isMobile={isMobile} />
              )}
              <div className="header-total">
                <span>TOTALE</span>
                <span className="skin">
                  {" "}
                  € {numberWithCommas(getSubtotal() <= 0 ? 0 : getSubtotal() + 7)}
                </span>
              </div>
            </div>
            {isMobile && (
              <HeaderDetails getSubtotal={getSubtotal} isMobile={isMobile} />
            )}

            <div className="header-buttons">
              <button onClick={() => setModal(true)}>
                <i className="fas fa-truck" />
                <span>{`${
                  isMobile ? "SPEDIZIONE" : "Dettagli PER LA SPEDIZIONE"
                }`}</span>
              </button>
              <button
                className="skin"
                onClick={() =>
                  !coffeBuyingLoader &&
                  (nome &&
                  nome !== "" &&
                  codiceFiscale &&
                  codiceFiscale !== "" &&
                  paese &&
                  paese !== "" &&
                  email &&
                  email !== "" &&
                  citta &&
                  citta !== "" &&
                  cognome &&
                  cognome !== "" &&
                  vila &&
                  vila !== "" &&
                  telefono &&
                  telefono !== "" &&
                  provincia &&
                  provincia !== "" &&
                  cap &&
                  cap !== ""
                    ? startBuying()
                    : setModal(true))
                }
              >
                {coffeBuyingLoader ? (
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : (
                  <React.Fragment>
                    <i className="fas fa-wallet" />
                    <span>{`${
                      isMobile ? `ACQUISTA` : `PAGA CON ${(props.skinExtras.name || "").toString().toUpperCase()} WALLET`
                    }`}</span>
                  </React.Fragment>
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="MammaneroCaffe--body maxWidth">
          <div className="body-title">
            <img src={images["mammaroHeader"]} alt="mammaro-header" />
          </div>

          {coffeLoader ? (
            <Loader />
          ) : (
            <div className="body-grid">
              {(items || []).map((item) => (
                <div className="grid-item" key={item.service_id}>
                  <div className="item-icon-desc">
                    <img src={caffeeImages[item.service_id]} alt="caffe-images"/>
                    <div className="item-desc">
                      <span>{item.name}</span>
                      <div>
                        <span className="price">
                          € {numberWithCommas(item.cost)}
                        </span>
                        <span>
                          <span>Q.tà</span>
                          <span className="input-wrapper">
                            <input
                              type="number"
                              min={0}
                              value={item.quantity || ""}
                              onChange={(e) => modifyItems(item, e.target.value)}
                            />
                            <i className="fas fa-sort-up" />
                            <i
                              className={`fas fa-sort-down ${
                                item.quantity >= 2 ? "" : "disabled"
                              }`}
                            />
                            <div
                              className="clickable clickable-up"
                              onClick={() => modifyItems(item, null, 1)}
                            />
                            <div
                              className="clickable clickable-down"
                              onClick={() => modifyItems(item, null, -1)}
                            />
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>

                  <CheckBox
                    checked={item.checked}
                    handleClick={() => {
                      const foundItemIndex = items.findIndex(
                        ({ service_id }) => service_id === item.service_id
                      )
                      let newItems = [...items]
                      newItems[foundItemIndex] = { ...item, checked: !item.checked }
                      setItems(newItems)
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

const CheckBox = ({ checked, handleClick }) => {
  return (
    <div className={`item-check ${checked ? "checked" : ""}`} onClick={handleClick}>
      <span>{checked && <i className="fal fa-check" />}</span>
    </div>
  )
}

const mpstp = (state) => ({
  coffeeServices: state.shop.coffeeServices,
  userData: state.shop.itemsCart,
  screenWidth: state.main.screenWidth,
  coffeePaymentDetails: state.shop.coffeePaymentDetails,
  coffeLoader: state.shop.coffeLoader,
  coffeBuyingLoader: state.shop.coffeBuyingLoader,
  skinExtras: state.auth.skinExtras,
})

const HeaderDetails = ({ getSubtotal, isMobile }) => {
  return (
    <div className="header-details">
      <div className="details-container">
        <div>
          <span>SUBTOTALE</span>
          <span className="bold">{numberWithCommas(getSubtotal())} €</span>
        </div>
        <div>
          <span>SPEDIZIONE</span>
          <span className="bold">
            {numberWithCommas(getSubtotal() !== 0 ? 7 : 0)} €
          </span>
        </div>
        <div>
          <span>IVA INCLUSA</span>
          <span className="bold">
            {numberWithCommas(getSubtotal() !== 0 ? getSubtotal() * 0.22 : 0)} €
          </span>
        </div>
        {isMobile && (
          <div>
            <span>TOTALE</span>
            <span className="skin">
              € {numberWithCommas(getSubtotal() <= 0 ? 0 : getSubtotal() + 7)}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}


export default connect(mpstp, { ...ShopActions })(MammaneroCaffeDomain)
