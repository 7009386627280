import React, { useState } from "react"
import images from "themes/images"
import { connect } from "react-redux"
import AuthActions from "redux-store/models/auth"
import { Form, Input } from "antd"
import "./serviziModal.css"
import BonusModalRow from "./BonusModalRow"
import { baseUrlImg } from "../../../../config/api"

const BonusModal = ({ open, onClose, selectedCompany }) => {
  const [rows, setRows] = useState([{ id: 1, da: 0, a: 0, bonus: 0 }])
  const handleClick = () => {
    setRows([...rows, { id: rows.length + 1, da: 0, a: 0, bonus: 0 }])
  }
  if (!open) return null
  console.log(selectedCompany)
  return (
    <div className="Servizi">
      <div className="backDrop" style={{ zIndex: 8 }} onClick={onClose}></div>
      <div className="Servizi--Container">
        <div className="Servizi--Container--Up text-center">
          <div className="Servizi--Container--Up--Title">
            <div className="service-name">{selectedCompany.name}</div>

            <div className="service-settings">
              BONUS SETTINGS <i className="far fa-cog"></i>
            </div>
          </div>
          <div className="Servizi--Container--Up--Logo">
            <img  src={`${baseUrlImg}/${selectedCompany.logo}` || images["placeholder"]} alt="service" />
          </div>
        </div>
        <div className="Servizi--Container--Down">
          <div className="Servizi--Container--Down--Form">
            <Form>
              {rows.map((row) => (
                <BonusModalRow id={row.id} key={row.id} />
              ))}
              <Form.Item name="aggiungi">
                <div onClick={handleClick}>
                  <Input
                    className="aggiungi-btn"
                    placeholder="Aggiungi Variabile"
                    suffix={<i className="fas fa-plus-circle"></i>}
                    readOnly
                  />
                </div>
              </Form.Item>
              <p className="bonus-info">Bonus calcolato in percentuale su aggio netto (mensile)</p>
            </Form>
            <div className="Servizi--Container--Down--Form--buttons">
              <button
                className="Servizi--Container--Down--Form--buttons--Cancel"
                onClick={onClose}
              >
                <i className="fal fa-times"></i>
                <span>CANCEL</span>
              </button>
              <button className="Servizi--Container--Down--Form--buttons--Conferma" onClick={() => console.log(selectedCompany)}>
                <i className="fal fa-check"></i>
                <span>SAVE</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({})
export default connect(mapStateToProps, AuthActions)(BonusModal)
