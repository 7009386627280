import React from "react"
import moment from "moment"

const CreateGiftCardTicket = (arr) => {
  const receipt = JSON.parse(arr?.arr?.receipt)

  return (
    <div className="json_spedizionepro">
      <h5 className="d-flex justify-content-center font-weight-bold">MRPAY CARD</h5>
      <div style={{ paddingTop: 10 }}>
        <strong>Importo:</strong> <span>{receipt.balance}</span>
      </div>
      <div style={{ paddingTop: 10 }}>
        <strong>Codice Voucher:</strong> <span>{receipt.code}</span>
      </div>
      <div style={{ paddingTop: 10 }}>
        <strong>Codice Fiscale:</strong> <span>{receipt.cf}</span>
      </div>
      <div style={{ paddingTop: 10 }}>
        <strong>Scadenza:</strong>{" "}
        <span>{moment(receipt.expires_at).format("DD/MM/YYYY HH:mm:ss")}</span>
      </div>

      {/* <div style={{ marginTop: 20, display: "flex", flexDirection: "column" }}>
        <p className="mb-1">
          <strong>Conservare lo scontrino fino a ricarica avvenuta.</strong>
        </p>
        <p className="mb-1">
          <strong>Pagamento tramite internet:</strong>
        </p>
        <ol className="mb-1">
          <li>Cliccare "Acquisti online" sul sito desiderato.</li>
          <li>Scegliere l'offerta e cliccare su "Pagamento con MrPayCard".</li>
          <li>Inserire il codice PIN e confermare.</li>
        </ol>
        <p className="mb-1">
          <strong>
            Il voucher può essere utilizzato più volte e non è trasferibile.
          </strong>
        </p>
        <p className="mb-1">
          La carta di pagamento <strong>MrPayCard</strong> viene emessa e gestita da{" "}
          <a href="https://www.mrpay.it" target="_blank" rel="noopener noreferrer">
            MrPay
          </a>.
        </p>
        <p className="mb-1">
          L'utilizzo da parte dell'utente è disciplinato dalle{" "}
          <a
            href="https://mrpay.it/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Condizioni Generali
          </a>{" "}
          consultabili su{" "}
          <a href="https://www.mrpay.it" target="_blank" rel="noopener noreferrer">
            www.mrpay.it
          </a>.
        </p>
        <p className="mb-1">
          <strong>ATTENZIONE - AVVISO DI SICUREZZA DEI PUNTI VENDITA:</strong>
        </p>
        <ul className="mb-1">
          <li>
            <strong>CODICE PIN = CREDITO</strong>
          </li>
          <li>
            <strong>NON DEVE MAI ESSERE COMUNICATO TELEFONICAMENTE.</strong>
          </li>
        </ul>
        <p className="mb-1">
          Il servizio viene erogato a seguito di riscatto del voucher da parti terze.
        </p>
        <p className="mb-1">
          Non è consentito riscattare il voucher in contanti, trasferirlo a terzi o
          cambiarlo in qualsiasi altro modo.
        </p>
        <p className="mb-1">
          Richiesta credito:{" "}
          <a href="https://www.mrpay.it" target="_blank" rel="noopener noreferrer">
            www.mrpay.it
          </a>
        </p>
        <p className="mb-1">
          <strong>Il voucher non può essere cambiato e non è rimborsabile.</strong>
        </p>
        <p className="mb-1">IVA assolta ai sensi dell'art.10 dpr 633/72.</p>
        <p className="mb-1">
          Transazione eseguita da <strong>MrPay</strong>.
        </p>
      </div> */}
    </div>
  )
}

export default CreateGiftCardTicket
