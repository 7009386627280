import React, { useEffect, useState, useRef } from "react"
import UseCode from "routes/views/UseCode"
import AuthActions from "redux-store/models/auth"
import {connect} from "react-redux"
import { debounce } from "lodash"

const MobileScanner = (props) => {
    const { vis, toggleScanner, onCodeChange,scannedBarcode, setScannedBarcode } = props
    const [scanVal, setScanVal] = useState(scannedBarcode)
    const scanValRef = useRef();
    useEffect(() => {
      if (scannedBarcode) {
        toggleScanner(true)
        onCodeChange(scannedBarcode)
        setScannedBarcode(null)
        toggleScanner(false)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scannedBarcode])
    const onChange = () => {
      onCodeChange(scanValRef.current)
      if(scanValRef.current) toggleScanner(false)
      // toggleScanner(false);
    }
    const delayedCallback = debounce(onChange, 1000)

    useEffect(()=>{
      scanValRef.current=scanVal;
      delayedCallback()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[scanVal])

    return (
      !!vis && (
        <div className="backDrop" onClick={() => toggleScanner(false)}>
          <UseCode
            defaultValue={scannedBarcode}
            value={scanVal}
            onChange={(e) => setScanVal(e?.target?.value)}
          />
        </div>
      )
    )
}

const mapsStateToProps = (state) => ({
  scannedBarcode: state.auth.scannedBarcode,
})


export default connect(mapsStateToProps, { ...AuthActions })(MobileScanner)
