import React, { useState, useEffect, useCallback } from "react"
import Actions from "./Actions"
import { bolletinoSubmit } from "../bolletinoSubmit"
import { HeaderByService, FooterByService } from "../BolletiniHeadFooter"
import { Checkbox, Menu, Modal } from "antd"
import { Form } from "antd"
import images from "themes/images"
import { connect } from "react-redux"
import { AuthActions, MainActions } from "redux-store/models"
import $ from "jquery"
import _ from "lodash"
import { shouldShowSepafinLogin } from "utils/HelperFunc"
import Condizioni from "../Condizioni"
import MobileScanner from "./MobileScanner"
import "../newStyl.css"
import {
  MrPayBolletiniRightForm,
  MPayBolletiniLeftForm,
  SepafinBolletiniRightForm,
  SepafinBolletiniLeftForm,
} from "../BolletiniForms"
import { BoloAutoLeftForm } from "../BoloAutoForms"
import { PagoPaLeftForm, PagoPaLeftFormMrPay } from "../PagoPaForms"
import {
  SepafinMavRavLeftForm,
  SepafinMavRavRightForm,
  MrPayMavRavLeftForm,
  MrPayMavRavRightForm,
  EPayMavRavLeftForm,
  EPayMavRavRightForm,
} from "../MavRavForm"
import { LeftFreccia, RightFreccia } from "../BoloFrecciaForms"
import ConfirmPaymentModal from "../ConfirmPaymentModal"
import { withRouter } from "react-router-dom"
import BollettinoPrivacyLinks from "./BollettinoPrivacyLinks"
import { parseImportoValue } from "../../../utils/HelperFunc"

const Bolletino = (props) => {
  const {
    togglePopUp,
    bolletiniLoading,
    BookingBoletinoInfo,
    service_id,
    service_Id,
    detailByContoCorrente,
    setBolletiniLoading,
    getBolletiniBianchi,
    getBolletiniPremercati,
    form,
    setShowSepafinLogin,
    setMavRav,
    setPagoPa,
    mrPayPagoPa,
    setFreccia,
    fetchBolletini,
    setServiceId,
    barcodeData,
    service_s,
    helper,
    getCompanyNameByContoCorrento,
    isSepafin,
    setBookingsBolMr,
    getBookingsBolMr,
    history,
    accountInfo,
    getBolletiniAuto,
    setServiceS,
  } = props
  const [scannerVisibility, setScannerVisibility] = useState(false)
  const [paymentConfirmation, setPaymentConfirmation] = useState(false)
  const privacyLinkRead = true
  const [condizioniShow, setCondizioniShow] = useState(false)
  const [codiceBolShow, setCodiceBolShow] = useState(false)
  const [lastRequestTipology, setLastRequestTipology] = useState()
  const informativoLinkRead = true
  const { getFieldValue, getFieldDecorator, setFieldsValue } = form
  const { codiceFiscaleOrPartitaIvaIsRequired, newFields, causaleRequired } =
    detailByContoCorrente || {}
  const formInputSameProps = {
    barcodeData,
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    service_id,
    newFields,
    causaleRequired,
    isSepafin,
  }

  const accountData = JSON.parse(localStorage.getItem("accountDataB")) || {}
  const pagamentiServices = accountData?.dashboard?.pagamenti_module?.services
  const isEPay = accountData?.profile?.supplier === "E-Pay"

  const getCompanyNameBy = useCallback(
    (contoCorrentoValue, tipologia) => {
      setLastRequestTipology(tipologia)
      getCompanyNameByContoCorrento(contoCorrentoValue, tipologia)
    },
    [setLastRequestTipology, getCompanyNameByContoCorrento]
  )

  const contoCorentoChange = (contoCorrentoValue) => {
    const tipologia = getFieldValue("tipologia")
    getCompanyNameBy(contoCorrentoValue, tipologia)
  }
  const delayedCallback = _.debounce(contoCorentoChange, 400)

  useEffect(() => {
    const tipologia = getFieldValue("tipologia")
    if (
      tipologia &&
      lastRequestTipology &&
      tipologia !== lastRequestTipology &&
      (isSepafin || !service_id)
    ) {
      getCompanyNameBy($("#bolletino_numero_conto_corrente").val(), tipologia)
    }
  }, [getFieldValue, lastRequestTipology, getCompanyNameBy, isSepafin])

  useEffect(() => {
    setBookingsBolMr(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setBookingsBolMr, getFieldValue("id_prenotazione")])

  useEffect(() => {
    if (
      !["SBOL001", "SBOL002"].includes(service_id) &&
      accountInfo.profile &&
      shouldShowSepafinLogin(accountInfo.profile.username) &&
      accountInfo.profile.role.name !== "user" &&
      isSepafin
    ) {
      setShowSepafinLogin(true)
    }
  }, [isSepafin, service_id, setShowSepafinLogin])

  useEffect(() => {
    const onChangeCallback = (contoCorrentoValue) =>
      delayedCallback(contoCorrentoValue)
    if (isSepafin || !service_id) {
      $("#bolletino_numero_conto_corrente").focusout(function () {
        onChangeCallback($(this).val())
      })
    }
  }, [delayedCallback, isSepafin])

  const cancelBolletino = () => {
    togglePopUp(false)
    setServiceS([])
  }
  const toggleScanner = (vis) =>
    setScannerVisibility(vis !== undefined ? vis : !scannerVisibility)
  const toggleCondizioni = () => setCondizioniShow(!condizioniShow)
  const submitBolletino = (e) =>
    submit(e, isFrecciaOrMavRavSep() && (() => setPaymentConfirmation(true)))
  const hideCodiceBollInfo = () => setCodiceBolShow(false)
  const showCodiceBollInfo = () => setCodiceBolShow(true)
  const hidePaymentConfirmation = () => setPaymentConfirmation(false)

  const isFrecciaOrMavRavSep = () =>
    ["SBOL003", "SBOL004", "SBOL007"].includes(service_id)
  const clearFields = () => {
    form.resetFields()
    if (isFrecciaOrMavRavSep()) setPaymentConfirmation(false)
  }
  const submit = (e, callback, isPos) => {
    const { codiceFiscaleOrPartitaIvaIsRequired, newFields } =
      detailByContoCorrente || {}
    const pdfUnread =
      (!privacyLinkRead || !informativoLinkRead) &&
      !["SBOL001", "SBOL002"].includes(service_id)

    const submitByServiceId = {
      SBOL003: setMavRav,
      SBOL004: setMavRav,
      SPPA001: setPagoPa,
      SBOL006: setPagoPa,
      SBOL007: setFreccia,
      SBOL001: fetchBolletini,
      SBOL002: fetchBolletini,
      BOL001: getBolletiniBianchi,
      BOL002: getBolletiniPremercati,
      BOL003: getBolletiniPremercati,
      BOL004: getBolletiniPremercati,
      BOL007: mrPayPagoPa,
      [pagamentiServices["Paytipper Bill payments no edit Miniprogram"]]:
        getBolletiniBianchi,
      [pagamentiServices["Paytipper Bill payments"]]: getBolletiniPremercati,
      [pagamentiServices["Paytipper RAV"]]: getBolletiniPremercati,
      [pagamentiServices["Paytipper MAV"]]: getBolletiniPremercati,
      103185: mrPayPagoPa,
      [pagamentiServices["Paytipper Bollo auto"]]: getBolletiniAuto,
    }

    const submitFunction = submitByServiceId[service_id || "SBOL001"]
    console.log(service_s)
    const isMrPay = service_s.provider_name === "MrPay"
    // console.log("submitFunction",service_id,submitFunction)
    return bolletinoSubmit(
      e,
      service_id || "SBOL001",
      form,
      submitFunction,
      clearFields,
      codiceFiscaleOrPartitaIvaIsRequired,
      setShowSepafinLogin,
      isPos,
      pdfUnread,
      callback,
      newFields,
      isMrPay,
      setBolletiniLoading,
      accountInfo.profile?.username || "",
      accountInfo.profile.id
    )
  }

  const mrPayScanBarcode = (barcode = "") => {
    if (!barcode) return

    let parsedData

    try {
      parsedData = JSON.parse(barcode)
    } catch (error) {
      console.error("JSON Parsing Error: ", error)
      parsedData = null
    }

    const setFormFields = (data) => {
      const {
        importo: importoValue,
        codice_identificativo,
        numero_conto_corrente,
        tipo_bollettino,
        causale,
        intestato_a,
        eseguito_da,
        provincia,
        indirizzo,
        citta,
        cap,
        scan_code,
      } = data

      const { importo, importo_cent } = parseImportoValue(importoValue)

      form.setFieldsValue({
        scan_code: scan_code || null,
        codice_identificativo: codice_identificativo || null,
        importo: importo || null,
        importo_cent: importo_cent || "00",
        numero_conto_corrente: numero_conto_corrente || null,
        tipologia: tipo_bollettino || null,
        causale: causale || null,
        intestato_a: intestato_a || null,
        eseguito_da: eseguito_da || null,
        provincia: provincia || null,
        via_piazza: indirizzo || null,
        citta: citta || null,
        cap: cap || null,
      })
    }

    if (parsedData && parsedData.service_id) {
      setFormFields(parsedData)
    } else if (typeof barcode === "object") {
      setFormFields(barcode)
    } else if (typeof barcode === "string") {
      const getBarcodePart = (start, length) =>
        barcode.substring(start, start + length)
      const parseNumber = (str) => parseInt(str, 10) || 0

      const counter1 = parseNumber(getBarcodePart(0, 2))
      const codiceIdf = getBarcodePart(2, counter1)
      const counter2 = parseNumber(getBarcodePart(20, 2))
      const sulCC = getBarcodePart(22, counter2)

      const counter3 = parseNumber(getBarcodePart(34, 2))
      const shuma = parseFloat(getBarcodePart(36, counter3)) || 0
      const counter4 = parseNumber(getBarcodePart(46, 1))
      const tipologia = getBarcodePart(47, counter4)

      const { importo, importo_cent } = parseImportoValue(shuma.toFixed(2))

      form.setFieldsValue({
        codice_identificativo: codiceIdf || null,
        importo: importo || null,
        importo_cent: importo_cent || "00",
        numero_conto_corrente: sulCC || null,
        tipologia: tipologia || null,
      })
    } else {
      form.setFieldsValue({
        codice_identificativo: null,
        importo: null,
        importo_cent: null,
        numero_conto_corrente: null,
        tipologia: null,
      })
    }
  }

  const getBarcodePart = (barcode, beforePartIndex, charLength = 2) => {
    const startIndex = beforePartIndex + charLength
    const stringCharacters = parseInt(barcode.substring(beforePartIndex, startIndex))
    const endIndex = stringCharacters + startIndex
    const stringPart =
      barcode.length >= endIndex && barcode.substring(startIndex, endIndex)
    return { endIndex, stringPart }
  }
  const sepafinScanBarcode = async (barcode) => {
    if (!barcode) return
    const barcodePart = (beforePartIndex, charLength = 2) =>
      getBarcodePart(barcode, beforePartIndex, charLength)
    const { endIndex: codiceIndex, stringPart: codice_identificativo } =
      await barcodePart(0)
    const { endIndex: correnteIndex, stringPart: numero_conto_corrente } =
      await barcodePart(codiceIndex)
    const { endIndex: importoIndex, stringPart: importo } = await barcodePart(
      correnteIndex
    )
    const { stringPart: tipologia } = await barcodePart(importoIndex, 1)
    await form.setFieldsValue({ ...(tipologia ? { tipologia } : {}) })
    await form.setFieldsValue({
      ...(codice_identificativo ? { codice_identificativo } : {}),
      ...(numero_conto_corrente ? { numero_conto_corrente } : {}),
      ...(importo ? { importo: parseFloat(importo) } : {}),
    })
  }
  const scanBarcode = isSepafin ? sepafinScanBarcode : mrPayScanBarcode
  // let barcode = 'PAGOPA|002|140210050700032300|00488410010|0000014829';

  const getActions = () => {
    return (
      <Actions
        helper={helper}
        isSepafin={isSepafin}
        toggleScanner={toggleScanner}
        cancel={cancelBolletino}
        submit={
          ((service_id !== "BOL001" &&
            service_id !==
              pagamentiServices["Paytipper Bill payments no edit Miniprogram"]) ||
            (getFieldValue("id_prenotazione") && BookingBoletinoInfo)) &&
          submitBolletino
        }
        bolletiniLoading={bolletiniLoading}
        service_id={service_id}
      />
    )
  }
  const onCodeChange = (e) => {
    setFieldsValue({ code: e?.target ? e?.target?.value : e })
  }

  const hasCodiceFiscale =
    !!detailByContoCorrente && !!codiceFiscaleOrPartitaIvaIsRequired
  const imageLogo =
    service_id === "SPPA001"
      ? "PAGO_PA"
      : service_id === "SBOL006"
      ? "BOLO_AUTO"
      : "BOLLETINO"
  return (
    <div
      className={`Bolletini new ${service_id}`}
      style={{ marginTop: !service_id ? "100px" : "0" }}
    >
      <Modal
        title={null}
        visible={paymentConfirmation}
        className="is-confirm base64"
        onOk={hidePaymentConfirmation}
        onCancel={hidePaymentConfirmation}
        footer={null}
      >
        <div className="printModal confirmMdoal aaa">
          <ConfirmPaymentModal
            toggleBodyClass
            pay={submit}
            payPos={(e, pos) => submit(e, null, pos)}
            cancel={hidePaymentConfirmation}
            importo={getFieldValue("importo")}
            commissione={2}
            accountInfo={accountInfo}
          />
        </div>
      </Modal>
      <div className="Bolletini-Header">
        <span>{service_s.name}</span>
        <span>
          <img src={images[imageLogo]} alt={imageLogo} />
        </span>
      </div>
      {!service_id ? (
        <HeaderByService service_id={"SBOL001"} />
      ) : (
        isSepafin && <HeaderByService service_id={service_id} />
      )}
      {/* {isSepafin && <HeaderByService service_id={service_id} />} */}

      <div className="Bolletini-Form">
        <Form>
          <Modal
            title="Codice bolletino info"
            visible={codiceBolShow}
            className="bolletino-info-modal"
            onOk={hideCodiceBollInfo}
            onCancel={hideCodiceBollInfo}
          >
            <img src={images["codicebolletino"]} alt="codicebolletino" />
          </Modal>
          {/* <Modal title="Scan Barcode" visible={barcodeInput} className="bolletino-info-modal" onOk={hideBarcodeInput}>
            <input onChange={scanBarcode} type="text" id="barcodeInp" placeholder="barcode" />
          </Modal> */}
          <MobileScanner
            vis={scannerVisibility}
            toggleScanner={toggleScanner}
            onCodeChange={
              service_id === "SBOL001" ||
              service_id === "BOL007" ||
              service_id === "103185"
                ? onCodeChange
                : scanBarcode
            }
          />
          <FormSides
            detailByContoCorrente={detailByContoCorrente}
            BookingBoletinoInfo={BookingBoletinoInfo}
            showCodiceBollInfo={showCodiceBollInfo}
            isSepafin={isSepafin}
            service_id={service_id}
            formProps={{
              ...formInputSameProps,
              setServiceId,
              service_s,
              hasCodiceFiscale,
              getBookingsBolMr,
              BookingBoletinoInfo,
            }}
            pagamentiServices={pagamentiServices}
          >
            {/* {!isSepafin &&
              service_id &&
              service_id !== pagamentiServices["Paytipper PagoPA"] &&
              service_id !== pagamentiServices["Paytipper Bollo auto"] &&
              service_id !== "BOL007" &&
              (service_id !== "BOL001" && service_id !== pagamentiServices["Paytipper Bill payments no edit Miniprogram"] ||
                (getFieldValue("id_prenotazione") && BookingBoletinoInfo)) && (
                <>
                  <div className="Condizioni">
                    <Menu
                      mode="inline"
                      selectedKeys={condizioniShow ? ["condizioni"] : []}
                    >
                      <Menu.SubMenu key="condizioni" title="Condizioni d'uso">
                        <Menu.Item key="1">
                          <Condizioni />
                        </Menu.Item>
                      </Menu.SubMenu>
                    </Menu>
                    <Checkbox>
                      La persona che hai di fronte non è il intestatario del
                      pagamento del bollo
                    </Checkbox>
                  </div>
                </>
              )} */}
          </FormSides>
        </Form>
      </div>
      {(isSepafin || !service_id) && <FooterByService service_id={service_id} />}
      {getActions()}
      {/* {scannerVisibility && service_id == "BOL002" && (
          <div
            className="backDrop"
            onClick={() => {
              setScannerVisibility(false)
            }}
          >
              <div
              className="vptCont"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              <i className="fal fa-scanner" aria-hidden="true"></i>
              <input onChange={e=>scanBarcode(e.target.value)} type="text" placeholder="barcode"  className="inpT animated slideInTop"/>
              <i className="fal fa-search" onClick={() => {
              setScannerVisibility(false)
            }} aria-hidden="true"></i>
            </div>
          </div>
        )} */}
    </div>
  )
}

const FormSides = (props) => {
  const {
    service_id,
    formProps,
    isSepafin,
    children,
    detailByContoCorrente,
    showCodiceBollInfo,
    pagamentiServices,
  } = props
  const setServiceId = props?.formProps?.setServiceId
  const { companyName } = detailByContoCorrente || {}
  const getFieldValue = (field) =>
    formProps?.getFieldValue && formProps?.getFieldValue(field)

  const leftCompByService = {
    SBOL003: <SepafinMavRavLeftForm setServiceID={setServiceId} />,
    SBOL004: <SepafinMavRavLeftForm setServiceID={setServiceId} />,
    BOL003: <MrPayMavRavLeftForm setServiceID={setServiceId} />,
    BOL004: <MrPayMavRavLeftForm setServiceID={setServiceId} />,
    SBOL006: <BoloAutoLeftForm />,
    SBOL007: <LeftFreccia />,
    SPPA001: <PagoPaLeftForm />,
    BOL007: (
      <PagoPaLeftFormMrPay
        code={getFieldValue("code")}
        codice_aviso={getFieldValue("codice_aviso")}
        ente_creditore={getFieldValue("ente_creditore")}
        importo={getFieldValue("importo")}
        eseguito_da={getFieldValue("eseguito_da")}
      />
    ),
    // EPay
    [pagamentiServices["Paytipper MAV"]]: (
      <EPayMavRavLeftForm setServiceID={setServiceId} />
    ),
    [pagamentiServices["Paytipper RAV"]]: (
      <EPayMavRavLeftForm setServiceID={setServiceId} />
    ),
    [pagamentiServices["Paytipper Bollo auto"]]: <BoloAutoLeftForm />,
    103185: (
      <PagoPaLeftFormMrPay
        code={getFieldValue("code")}
        codice_aviso={getFieldValue("codice_aviso")}
        ente_creditore={getFieldValue("ente_creditore")}
        importo={getFieldValue("importo")}
        eseguito_da={getFieldValue("eseguito_da")}
      />
    ),
  }
  const LeftComponent =
    service_id in leftCompByService ? (
      leftCompByService[service_id]
    ) : (
      <MPayBolletiniLeftForm pagamentiServices={pagamentiServices} />
    )

  const rightCompByService = {
    SBOL003: <SepafinMavRavRightForm />,
    SBOL004: <SepafinMavRavRightForm />,
    BOL003: <MrPayMavRavRightForm />,
    BOL004: <MrPayMavRavRightForm />,
    SBOL006: <img src={images["bolAutoBack"]} alt="bolAutoBack" />,
    SBOL007: <RightFreccia />,
    SPPA001: <img src={images["pagoPaBack"]} alt="pagoPaBack" />,
    BOL007: <img src={images["pagoPaBack"]} alt="pagoPaBack" />,
    // EPay
    [pagamentiServices["Paytipper MAV"]]: <EPayMavRavRightForm />,
    [pagamentiServices["Paytipper RAV"]]: <EPayMavRavRightForm />,
    [pagamentiServices["Paytipper Bollo auto"]]: (
      <img src={images["bolAutoBack"]} alt="bolAutoBack" />
    ),
    103185: <img src={images["pagoPaBack"]} alt="pagoPaBack" />,
  }
  const rightBolletino =
    isSepafin || !service_id ? (
      <SepafinBolletiniRightForm />
    ) : (
      <MrPayBolletiniRightForm pagamentiServices={pagamentiServices} />
    )
  const RightComponent =
    service_id in rightCompByService
      ? rightCompByService[service_id]
      : rightBolletino

  const classNameBySide = (side) =>
    `${side} ${
      service_id === "SBOL006" ? "BA" : service_id === "SPPA001" ? "PA" : ""
    }`
  return (
    <>
      <div className={classNameBySide("Left")}>
        {!(service_id in leftCompByService) && (isSepafin || !service_id) ? (
          <SepafinBolletiniLeftForm
            {...formProps}
            openCodiceBolInfo={showCodiceBollInfo}
          >
            <div className="intestato">
              <span>Intestato a </span>
              <div className={companyName ? "placeholder" : "placeholder"}>
                {detailByContoCorrente
                  ? companyName || "Non è stato possibile trovare il Beneficiario"
                  : "Nome beneficario"}
              </div>
            </div>
          </SepafinBolletiniLeftForm>
        ) : (
          React.cloneElement(LeftComponent, { ...formProps })
        )}
      </div>

      <div className={classNameBySide("Right")}>
        <div className="Inputs">
          {React.cloneElement(RightComponent, { ...formProps })}
        </div>
        {children}
      </div>
    </>
  )
}

const BolletinoFormWrapper = Form.create({ name: "bolletino" })(Bolletino)

const mapsStateToProps = (state) => ({
  bolletiniBianchi: state.auth.bolletiniBianchi,
  barcodeData: state.auth.barcodeData,
  bolletiniPremercati: state.auth.bolletiniPremercati,
  accountInfo: state.auth.accountInfo,
  BookingBoletinoInfo: state.auth.BookingBoletinoInfo,
  service_s: state.auth.service_s,
  detailByContoCorrente: state.auth.detailByContoCorrente,
  isSepafin: !!state.auth?.accountInfo?.profile?.hasSepafinCredentials,
  service_Id: state.auth.service_id,
  bolletiniLoading: state.auth.bolletiniLoading,
})

export default withRouter(
  connect(mapsStateToProps, { ...AuthActions, ...MainActions })(BolletinoFormWrapper)
)
