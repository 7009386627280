import React from "react"

const AgenziePerformanti = () => {
  const mostPerformantAgencies = [
    {
      key: 1,
      name: "Rnbruni",
      value: "10.345,26",
    },
    {
      key: 2,
      name: "Rnbruni",
      value: "10.345,26",
    },
    {
      key: 3,
      name: "Rnbruni",
      value: "10.345,26",
    },
    {
      key: 4,
      name: "Rnbruni",
      value: "10.345,26",
    },
    {
      key: 5,
      name: "Rnbruni",
      value: "10.345,26",
    },
    {
      key: 6,
      name: "Rnbruni",
      value: "10.345,26",
    },
    {
      key: 7,
      name: "Rnbruni",
      value: "10.345,26",
    },
    {
      key: 8,
      name: "Rnbruni",
      value: "10.345,26",
    },
    {
      key: 9,
      name: "Rnbruni",
      value: "10.345,26",
    },
  ]
  return (
    <div className="agenzie-performanti">
      <div className="statistiche-header">Agenzie più performanti</div>
      <div className="agenzie-performanti-list">
        {mostPerformantAgencies.map((option) => (
          <div className="list-option" key={option.key}>
            <span className="key">{option.key}</span>
            <span className="name">{option.name}</span>
            <span className="value">{option.value}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AgenziePerformanti
