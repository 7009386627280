import React, { useEffect } from "react"
import NumpadForm from "./modals/NumpadForm"
import BolletiniBianchi from "./modals/BolletiniBianchi"
import BolloAuto from "./modals/BolloAuto"
import PagoPa from "./modals/PagoPa"
import MavRav from "./modals/MavRav"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"

const ServicesForms = ({
  activeService,
  activeCategory,
  setService,
  favorites,
  toggleFavorite,
  setServiceS,
  services,
  service_s,
  companyServices,
  setCompanyServices,
}) => {
  let allFavServices = []
  Object.keys(favorites).forEach((item) => {
    Object.keys(favorites[item]).forEach((subitem) => {
      allFavServices.push(subitem)
    })
  })

  console.log(service_s, activeService)

  return (
    <div className="servicesForms">
      {companyServices && !service_s?.services_as_company ? (
        <NumpadForm
          noNumbers={true}
          setService={setService}
          activeCategory={activeCategory}
          activeService={activeService}
          toggleFavorite={toggleFavorite}
          allFavServices={allFavServices}
        />
      ) : [
          "BOL001",
          "SBOL001",
          "SBOLL",
          "BOL002",
          "SBOL002",
          "3611776",
          "110067",
          "106037",
          "103859",
        ].includes(service_s?.id) ? (
        <BolletiniBianchi
          services={services}
          setService={setService}
          activeService={activeService}
          toggleFavorite={toggleFavorite}
          allFavServices={allFavServices}
        />
      ) : [
          "SMAVRAV",
          "SBOL004",
          "SBOL003",
          "BOL004",
          "BOL003",
          "106163",
          "106161",
          "102964",
          "102965",
        ].includes(service_s?.id) ? (
        <MavRav
          setService={setService}
          activeService={activeService}
          toggleFavorite={toggleFavorite}
          allFavServices={allFavServices}
        />
      ) : ["SPAGPA", "SPPA001", "BOL007", "3619836", "103185"].includes(
          service_s?.id
        ) ? (
        <PagoPa
          setService={setService}
          activeService={activeService}
          toggleFavorite={toggleFavorite}
          allFavServices={allFavServices}
        />
      ) : ["SBOLLA", "SBOL006", "106071"].includes(service_s?.id) ? (
        <BolloAuto
          setService={setService}
          activeService={activeService}
          toggleFavorite={toggleFavorite}
          allFavServices={allFavServices}
        />
      ) : (
        <RedirectDashboard
          service_s={service_s}
          setServiceS={setServiceS}
          setCompanyServices={setCompanyServices}
        />
      )}
    </div>
  )
}
const RedirectDashboard = ({ service_s, setServiceS, setCompanyServices }) => {
  useEffect(() => {
    setTimeout(() => {
      setServiceS([])
      setCompanyServices([])
    }, 3000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="serviceSoon">
      Servizio {service_s?.name} {` `}disponibile a breve. Reindirizzamento alla
      schermata iniziale ...
    </div>
  )
}
const mstp = ({ main: { services }, auth }, props) => {
  return {
    services: props.services || services,
    isSepafin: !!auth?.accountInfo?.profile?.hasSepafinCredentials,
  }
}
export default connect(mstp, { ...MainActions, ...AuthActions })(ServicesForms)
