import React from "react"
import { Form, Input } from "antd"

const CodiceFiscaleInput = ({ codFisInps, setCodFisInps, isValidCodiceFiscale }) => {
  const getValidationStatus = () => {
    if (codFisInps && codFisInps.length === 16) {
      if (!isValidCodiceFiscale(codFisInps)) {
        return "error"
      }
      return "success"
    }
    if (codFisInps && codFisInps.length !== 16) {
      return "error"
    }
    return ""
  }

  return (
    <div className="CodiceFiscale">
      <label>
        Codice Fiscale <span>*</span>
      </label>
      <Form>
        <Form.Item
          hasFeedback
          name="codice_fiscale"
          validateStatus={getValidationStatus()}
          rules={[
            { required: true, message: "Inserire Codice Fiscale!" },
            {
              validator: (rule, value) => {
                if (value && value.length !== 16) {
                  return Promise.reject(
                    "Il Codice Fiscale deve essere lungo 16 caratteri!"
                  )
                }
                return Promise.resolve()
              },
            },
            {
              pattern: /^[A-Z]{6}\d{2}[A-EHLMPRST]{1}\d{2}[A-Z]{1}\d{3}[A-Za-z]{1}$/,
              message: "Il formato del Codice Fiscale non è corretto!",
            },
          ]}
        >
          <Input
            value={codFisInps}
            maxLength={16}
            onChange={(e) => setCodFisInps(e.target.value.toUpperCase())}
            placeholder="Inserisci Codice Fiscale"
          />
        </Form.Item>
      </Form>
    </div>
  )
}

export default CodiceFiscaleInput
