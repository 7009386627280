import React, { Fragment } from "react"
import { docType } from "config"
import { Form } from "antd"
import { Input, Button, DatePicker, Select, Checkbox } from "antd"
import moment from "moment"
import uniqBy from "lodash/uniqBy"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { AuthActions, MainActions } from "redux-store/models"
import AdressAutoFinder from "shared-components/AdressAutoFinder/AdressAutoFinder"
import "../../themes/css-register/register.css"
import countriesArray from "config/countryArr"
import VirtualizedSelect from "react-virtualized-select"
import { Header } from "shared-components"
import "react-select/dist/react-select.css"
import "react-virtualized-select/styles.css"
import { InlineShareButtons } from "sharethis-reactjs"
import { getCopy } from "utils"
import FloatingLabelInput from "../../shared-components/FloatingLabelInput/FloatingLabelInput"
import NewRegisterAgency from "../../shared-components/adminSharedComp/NewRegisterAgency/NewRegisterAgency"

const { Option } = Select

class RegisterEndUser extends React.Component {
  state = {
    visible: true,
    locationData: {},
    comuniSelected: {},
    nazione: "ITALIA",
    province_of_birth: "",
    city_of_birth: "",
    nazioneDiResidenca: "",
    residence_province: "",
    residence_city: "",
    agency_nazione: "",
    agency_comune: "",
    agency_province: "",
    tipoDocumento: "",
    fileType: 0,
    cardView: 0,
    nascita: "",
    imageUrl: "",
    imageUrl2: "",
    loading: false,
    pagamensileInp: "",
    costoanualeInp: "",
    recieve_emails: false,
    privacy_policy: false,
    codFisInps: "",
    valuess2: "",
    skin_default_rent: "",
    token: "",
    shareIcons: false,
    selectedRegisterType: "",
    cordinateReq: "",
  }

  // componentDidMount() {
  //   fetch("https://ipapi.co/json")
  //     .then((res) => res.json())
  //     .then((data) => {
  //       this.setState({
  //         locationData: data,
  //         cordinateReq: `${data.latitude},${data.longitude}`,
  //       })
  //       this.props.form.setFieldsValue({
  //         cordinate: `${data.latitude},${data.longitude}`,
  //       })
  //     })

  //   // if (this.props.match.params.token) {
  //   //   this.setState({ token: this.props.match.params.token })
  //   // } else {
  //   //   this.props.getRegisterToken(
  //   //     this.props.accountInfo.profile.account_id,
  //   //     this.setToken
  //   //   )
  //   // }

  //   if (!this.props.match.params.token) {
  //     this.props.form.setFieldsValue({
  //       pagmens: this.props.accountInfo.profile.skin_default_rent
  //         ? this.props.accountInfo.profile.skin_default_rent
  //         : "",
  //     })
  //   }
  // }

  handleAddressChange = async (value) => {
    console.log("Input value:", value)

    if (!value) {
      console.log("No input value. Clearing coordinates.")
      this.setState({ cordinateReq: "" })
      this.props.form.setFieldsValue({ cordinate: "" })
      return
    }

    const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
      value
    )}&format=json&limit=1`

    try {
      const response = await fetch(url)
      const data = await response.json()

      if (data.length > 0) {
        const { lat, lon } = data[0]
        this.setState({
          cordinateReq: `${lat},${lon}`,
        })
        this.props.form.setFieldsValue({
          cordinate: `${lat}, ${lon}`,
        })
      } else {
        console.error("No results found")
        this.setState({ cordinateReq: "" })
        this.props.form.setFieldsValue({ cordinate: "" })
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error)

      this.setState({ cordinateReq: "" })
      this.props.form.setFieldsValue({ cordinate: "" })
    }
  }

  validateVat = async (value) => {
    const soapMessage = `
      <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:urn="urn:ec.europa.eu:taxud:vies:services:checkVat:types">
        <soapenv:Header/>
        <soapenv:Body>
          <urn:checkVat>
            <urn:countryCode>IT</urn:countryCode>
            <urn:vatNumber>${value}</urn:vatNumber>
          </urn:checkVat>
        </soapenv:Body>
      </soapenv:Envelope>
    `

    try {
      const response = await fetch(
        "https://ec.europa.eu/taxation_customs/vies/services/checkVatService",
        {
          method: "POST",
          headers: {
            "Content-Type": "text/xml; charset=UTF-8",
            Accept: "text/xml",
          },
          body: soapMessage,
        }
      )

      const textResponse = await response.text()
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(textResponse, "text/xml")

      return xmlDoc.getElementsByTagName("valid")[0].textContent === "true"
    } catch {
      return false
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.props.form.validateFieldsAndScroll((err, values) => {
      console.log(values)
      if (!err) {
        this.props.getRegister(
          values.first_name,
          values.last_name,
          values.nickname,
          values.email,
          values.gender,
          this.state.codFisInps,
          moment(values.birthday).format("YYYY-MM-DD"),
          this.state.nazione,
          this.state.province_of_birth,
          this.state.city_of_birth,
          this.state.nazioneDiResidenca,
          this.state.residence_province,
          this.state.residence_city,
          values.address,
          values.cap,
          values.identity_id,
          values.identity_type,
          values.number_prefix || "+39",
          values.number,
          this.state.imageUrl,
          this.state.imageUrl2,
          "agency",
          values.aRagSoc,
          values.aInsegna,
          values.aPhone,
          values.aAdress,
          this.state.agency_comune,
          this.state.agency_province,
          values.aCap,
          values.aPiva,
          values.aFcode,
          values.confirm_password,
          values.password,
          values.rilasciato_da,
          values.luogo_di_rilascio,
          values.data_di_rilascio,
          values.data_di_scadenza,
          this.state.cordinateReq,
          this.state.agency_nazione,
          values.pagmens,
          this.state.privacy_policy,
          this.state.recieve_emails,
          null,
          "",
          values.wallet,
          values.tipo_di_mensile,
          this.props.activeSkinId,
          this.props.match.params.token
        )

        var that = this

        setTimeout(function () {
          that.setState({ visible: true })
        }, 1000)
      }
    })
  }

  validateCodiceFiscale = (e) => {
    const codFisInps = e
    const str = codFisInps

    const fiscalCodeKey = str.substring(str.length - 5, str.length - 1)
    const sexKey = parseInt(str.substring(9, 11), 10)
    const yearBKey = str.substring(6, 8)
    const monthBKey = str.substring(8, 9)

    let month = ""
    let year = 1900 + parseInt(yearBKey, 10)
    let day = sexKey % 40

    const monthMap = {
      A: "01",
      B: "02",
      C: "03",
      D: "04",
      E: "05",
      H: "06",
      L: "07",
      M: "08",
      P: "09",
      R: "10",
      S: "11",
      T: "12",
    }

    month = monthMap[monthBKey.toUpperCase()]

    if (sexKey > 40) {
      this.props.form.setFieldsValue({ gender: "F" })
    } else {
      this.props.form.setFieldsValue({ gender: "M" })
    }

    this.props.form.setFieldsValue({
      birthday: moment(`${day}-${parseInt(month)}-${year}`, "DD-MM-YYYY"),
    })

    countriesArray
      .filter((comune) => comune.codeKey.toString() === fiscalCodeKey.toString())
      .map((comune) => {
        this.setState({
          comuniSelected: comune,
          nazione: comune.nazione,
          province_of_birth: comune.sigla,
          city_of_birth: comune.provincia,
        })

        return comune
      })
  }

  receiveEmailsChange = (e) => {
    this.setState({ recieve_emails: e.target.checked })
  }

  privacyPolicyChange = (e) => {
    this.setState({ privacy_policy: e.target.checked })
  }

  render() {
    const { register, form } = this.props
    const { getFieldDecorator, getFieldValue } = form

    const {
      nazione,
      nazioneDiResidenca,
      residence_city,
      residence_province,
      agency_nazione,
      agency_comune,
      agency_province,
    } = this.state

    const accountId = this.props.accountInfo?.profile?.account_id

    const url =
      this.state.selectedRegisterType === "complete"
        ? `${window.location.href}t/${accountId}`
        : `${window.location.origin}/#/register-user-token/${accountId}`

    const allNazione = uniqBy(countriesArray, "nazione")

    const nazioneList = []
    if (allNazione && allNazione.length > 0) {
      for (let i = 0; i < allNazione.length; i++) {
        nazioneList.push(
          <Option key={allNazione[i].nazione} value={allNazione[i].nazione}>
            {allNazione[i].nazione}
          </Option>
        )
      }
    }

    let city_of_birth = []
    if (countriesArray) {
      city_of_birth = countriesArray
        .filter((items) => items.nazione === nazione.toUpperCase())
        .map((items) => {
          return {
            label: items.provincia,
            value: items.provincia,
          }
        })
    }

    let province_of_birthOptions = []
    if (countriesArray) {
      province_of_birthOptions = countriesArray
        .filter((items) => items.nazione === nazione.toUpperCase())
        .map((items) => {
          return {
            label: items.sigla,
            value: items.sigla,
          }
        })
    }

    const handleNazione = (e) => {
      this.setState({ nazione: e })
    }

    /************ RESIDENCA **********/

    const dateFormat = "DD/MM/YYYY"
    return (
      <Fragment>
        {!this.props.accountInfo?.profile?.activity_id &&
          this.props.accountInfo?.profile?.role?.name !== "main_admin" && (
            <Header></Header>
          )}
          <NewRegisterAgency/>
        {/* <Form
          className="newReg registerAgenzia"
          style={{ marginTop: 10, maxWidth: "unset" }}
          onSubmit={this.handleSubmit}
        >
          <div className="newReg--header">Registra Agenzia</div>
          <div className="newReg--row">
            <div className="newReg--row__col">
              <div className="itemCol full">
                <Form.Item hasFeedback>
                  {getFieldDecorator("codFisInps", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire codice fiscale!",
                        whitespace: true,
                      },
                      {
                        pattern: new RegExp(
                          "^[A-Z]{6}\\d{2}[A-EHLMPRST]{1}\\d{2}[A-Z]{1}\\d{3}[A-Z]{1}$"
                        ),
                        message:
                          "Codice fiscale non valido! Deve essere nel formato corretto.",
                      },
                    ],
                  })(
                    <FloatingLabelInput
                      label="Codice Fiscale"
                      onChange={(e) => {
                        this.validateCodiceFiscale(e.target.value)
                        this.setState({ codFisInps: e.target.value })
                      }}
                    />
                  )}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <Form.Item hasFeedback>
                  {getFieldDecorator("first_name", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire nome!",
                        whitespace: true,
                      },
                    ],
                  })(<FloatingLabelInput label="Nome" />)}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <Form.Item hasFeedback>
                  {getFieldDecorator("last_name", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire cognome!",
                        whitespace: true,
                      },
                    ],
                  })(<FloatingLabelInput label="Cognome" />)}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <Form.Item hasFeedback>
                  {getFieldDecorator("gender", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire sesso!",
                      },
                    ],
                  })(
                    <Select placeholder="Sesso">
                      <Option value="M">Maschile</Option>
                      <Option value="F">Femminile</Option>
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <Form.Item hasFeedback name="emaaail">
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        type: "email",
                        message: "L'input non è valido E-mail!",
                      },
                      {
                        required: true,
                        message: "Inserisci la tua e-mail!",
                      },
                    ],
                  })(<FloatingLabelInput label="Email" />)}
                </Form.Item>
              </div>
              <div className="itemCol full">
                <Form.Item>
                  {getFieldDecorator("birthday", {
                    initialValue:
                      this.state.nascita !== "" &&
                      moment(this.state.nascita, dateFormat).isValid()
                        ? moment(this.state.nascita, dateFormat)
                        : null,
                    rules: [
                      {
                        required: true,
                        message: "Inserisci data di nascita",
                      },
                    ],
                  })(
                    <DatePicker
                      // disabledDate={(current) => {
                      //   return current && current > moment().add(-18, "years");
                      // }}
                      placeholder="Data di nascita"
                      format={("DD/MM/YYYY", "DD/MM/YYYY")}
                    />
                  )}
                </Form.Item>
              </div>
              <div className="itemCol full">
                <Form.Item hasFeedback>
                  {nazione === "" && (
                    <Select onChange={(e) => handleNazione(e)}>{nazioneList}</Select>
                  )}
                  {nazione !== "" && (
                    <Select
                      defaultValue={this.state.nazione}
                      onChange={(e) => handleNazione(e)}
                    >
                      {nazioneList}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="itemCol full">
                <Form.Item hasFeedback>
                  <VirtualizedSelect
                    placeholder="Comune di nascita"
                    options={city_of_birth}
                    onChange={(city_of_birth) =>
                      this.setState({ city_of_birth: city_of_birth.value })
                    }
                    value={this.state.city_of_birth}
                    maxHeight={100}
                  />
                </Form.Item>
              </div>
              <div className="itemCol full">
                <Form.Item hasFeedback>
                  <VirtualizedSelect
                    placeholder="Provincia"
                    options={province_of_birthOptions}
                    onChange={(province_of_birth) =>
                      this.setState({
                        province_of_birth: province_of_birth.value,
                      })
                    }
                    value={this.state.province_of_birth}
                    maxHeight={100}
                  />
                </Form.Item>
              </div>
              <div className="itemCol full">
                <Form.Item hasFeedback>
                  {getFieldDecorator("address", {
                    rules: [
                      {
                        required: true,
                        message: "Inserisci il tuo indirizzo!",
                        whitespace: true,
                      },
                    ],
                  })(
                    <FloatingLabelInput
                      label="Indirizzo"
                      onBlur={(e) => this.handleAddressChange(e.target.value)}
                    />
                  )}
                </Form.Item>
              </div>
              <AdressAutoFinder
                form={this.props.form}
                nazione={nazioneDiResidenca}
                province={residence_province}
                comune={residence_city}
                setNazione={(nazione) => {
                  this.setState({ nazioneDiResidenca: nazione })
                }}
                setProvince={(province) => {
                  this.setState({ residence_province: province })
                }}
                setComune={(comune) => {
                  this.setState({ residence_city: comune })
                }}
              />
              {this.props.screenWidth >= 800 && (
                <Agreement
                  privacyPolicyChange={this.privacyPolicyChange}
                  receiveEmailsChange={this.receiveEmailsChange}
                />
              )}
            </div>
            <div className="newReg--row__col">
              <div className="itemCol full">
                <Form.Item hasFeedback>
                  {getFieldDecorator("aRagSoc", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire ragione sociale!",
                        whitespace: true,
                      },
                    ],
                  })(<FloatingLabelInput label="Ragione sociale" />)}
                </Form.Item>
              </div>
              <div className="itemCol full">
                <Form.Item hasFeedback>
                  {getFieldDecorator("aInsegna", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire insegna!",
                        whitespace: true,
                      },
                    ],
                  })(<FloatingLabelInput label="Insegna" />)}
                </Form.Item>
              </div>

              <div className="itemCol full">
                <Form.Item hasFeedback>
                  {getFieldDecorator("aPhone", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire numero di telefono",
                        whitespace: true,
                      },
                    ],
                  })(<FloatingLabelInput label="Telefono agenzia" />)}
                </Form.Item>
              </div>

              <div className="itemCol full">
                <Form.Item hasFeedback>
                  {getFieldDecorator("cordinate", {
                    rules: [
                      {
                        required: false,
                        whitespace: true,
                        message: "Inserisci le cordinate!",
                      },
                    ],
                  })(<FloatingLabelInput label="Cordinate" />)}
                </Form.Item>
              </div>

              <div className="itemCol semi">
                <Form.Item hasFeedback>
                  {getFieldDecorator("aAdress", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire la sede overativa",
                        whitespace: true,
                      },
                    ],
                  })(<FloatingLabelInput label="Sede operativa" />)}
                </Form.Item>
              </div>
              <div className="itemCol semi ">
                <Form.Item hasFeedback>
                  {getFieldDecorator("aFcode", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire codice",
                        whitespace: true,
                      },
                      {
                        pattern: new RegExp(
                          "^[A-Z]{6}\\d{2}[A-EHLMPRST]{1}\\d{2}[A-Z]{1}\\d{3}[A-Z]{1}$"
                        ),
                        message:
                          "Codice fiscale non valido! Deve essere nel formato corretto.",
                      },
                    ],
                  })(<FloatingLabelInput label="Codice fiscale agenzia" />)}
                </Form.Item>
              </div>
              <AdressAutoFinder
                titles={{
                  nazione: "Nazione (Agenzia)",
                  province: "Provincia (Agenzia)",
                  comune: "Comune",
                  cap: "Cap (Agenzia)",
                }}
                capFormKey="aCap"
                form={this.props.form}
                nazione={agency_nazione}
                province={agency_province}
                comune={agency_comune}
                setNazione={(nazione) => {
                  this.setState({ agency_nazione: nazione })
                }}
                setProvince={(province) => {
                  this.setState({ agency_province: province })
                }}
                setComune={(comune) => {
                  this.setState({ agency_comune: comune })
                }}
              />
              <div className="itemCol full">
                <Form.Item hasFeedback>
                  {getFieldDecorator("aPiva", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire p.Iva",
                        whitespace: true,
                      },
                      // {
                      //   validator: async (_, value) => {
                      //     if (!value) {
                      //       return Promise.reject("P.Iva is required.")
                      //     }

                      //     const isValid = await validateVat(value)
                      //     if (isValid) {
                      //       return Promise.resolve()
                      //     } else {
                      //       return Promise.reject("Invalid P.Iva number.")
                      //     }
                      //   },
                      // },
                    ],
                  })(
                    <FloatingLabelInput
                      label="P.Iva"
                      onBlur={(e) => this.validateVat(e.target.value)}
                    />
                  )}
                </Form.Item>
              </div>
              {/* <div className="itemCol semi piva">
                <Form.Item>
                  {getFieldDecorator("pivaVerifica", {
                    initialValue: "b",
                    rules: [
                      {
                        required: false,
                        whitespace: true,
                      },
                    ],
                  })(
                    <Select initialValue="b" placeholder="Verifica P.iva">
                      <Option value="a">Validato</Option>
                      <Option value="b">Non Validato</Option>
                    </Select>
                  )}
                  <i className="fas fa-file-check"></i>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://telematici.agenziaentrate.gov.it/VerificaPIVA/Scegli.do?parameter=verificaPiva"
                  >
                    <i className="fas fa-link"></i>
                  </a>
                </Form.Item>
              </div> */}

              {/* <div className="itemCol full">
                <Form.Item hasFeedback>
                  {getFieldDecorator("pagmens", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire Pagamento Mensile",
                        whitespace: true,
                      },
                    ],
                  })(<FloatingLabelInput type="number" label="Pagamento mensile" />)}
                </Form.Item>
              </div>
              <div className="itemCol full">
                <Form.Item hasFeedback>
                  {getFieldDecorator("tipo_di_mensile", {
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                      },
                    ],
                  })(
                    <Select placeholder="Tipo di Pagamento Mensile">
                      <Option value="annually">Annuale</Option>
                      <Option value="monthly">Mensile</Option>
                    </Select>
                  )}
                </Form.Item>
              </div>
            </div>
            <div className="newReg--row__col">
              <div className="itemCol full">
                <Form.Item hasFeedback>
                  {getFieldDecorator(
                    "wallet",
                    {}
                  )(<FloatingLabelInput type="number" label="Aggiungi Credito" />)}
                </Form.Item>
              </div>
              <div className="itemCol full">
                <Form.Item hasFeedback>
                  {getFieldDecorator("nickname", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire un nome utente valido!",
                      },
                      {
                        validator: (a, b, c) => {
                          if (getFieldValue("nickname").length >= 8) {
                            c()
                          } else {
                            c("Username è corto!")
                          }
                        },
                      },
                    ],
                  })(<FloatingLabelInput label="Username" />)}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <Form.Item hasFeedback>
                  {getFieldDecorator("password", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire password!",
                      },
                    ],
                  })(<FloatingLabelInput type="password" label="Password" />)}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <Form.Item hasFeedback>
                  {getFieldDecorator("confirm_password", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire password!",
                      },
                      {
                        validator: (a, b, c) => {
                          const { form } = this.props
                          if (
                            getFieldValue("password") ===
                            getFieldValue("confirm_password")
                          ) {
                            c()
                          } else {
                            c("Conferma password errata!")
                          }
                        },
                      },
                    ],
                  })(<FloatingLabelInput label="Conferma Password" />)}
                </Form.Item>
              </div>
              <div className="itemCol full">
                <Form.Item hasFeedback>
                  {getFieldDecorator("number", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire il numero di cellulare!",
                      },
                    ],
                  })(<FloatingLabelInput label="Cellulare" />)}
                </Form.Item>
              </div>
              <div className="itemCol full">
                <Form.Item>
                  {getFieldDecorator("identity_type", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire tipo di documenti!",
                      },
                    ],
                  })(
                    <Select
                      onChange={() => {
                        form.validateFields(["identity_id"])
                      }}
                      placeholder="Tipo documento"
                    >
                      {docType.map((doc) => {
                        return (
                          <Option key={doc.id} value={doc.id}>
                            {doc.name}
                          </Option>
                        )
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="itemCol full">
                <Form.Item hasFeedback>
                  {getFieldDecorator("identity_id", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire il numero di documento!",
                        whitespace: true,
                      },
                      {
                        pattern:
                          getFieldValue("identity_type") === "1"
                            ? /^[A-Z]{2}\d{5}[A-Z]{2}$|^[A-Z]{2}\d{7}$/ // Carta d'identità
                            : getFieldValue("identity_type") === "2"
                            ? /^[A-Z]{2}\d{7}$/ // Patente di guida
                            : /^[A-Z]{2}\d{5}[A-Z]{2}$|^[A-Z]{2}\d{7}$/, // Passaporto
                        message: "Inserisci un documento valido",
                      },
                    ],
                  })(<FloatingLabelInput label="Numero documento" />)}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <Form.Item hasFeedback>
                  {getFieldDecorator("rilasciato_da", {
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                      },
                    ],
                  })(
                    <Select placeholder="Rilasciato da">
                      <Option value="1">Comune</Option>
                      <Option value="10">Motorizzazione</Option>
                      <Option value="13">Questura</Option>
                      <Option value="14">Polizia</Option>
                      <Option value="16">Commissariato</Option>
                      <Option value="19">Altro</Option>
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <Form.Item hasFeedback>
                  {getFieldDecorator("luogo_di_rilascio", {
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: "Inserire il luogo di rilascio",
                      },
                    ],
                  })(<FloatingLabelInput label="Luogo di rilascio" />)}
                </Form.Item>
              </div>
              <div className="itemCol full">
                <Form.Item hasFeedback>
                  {getFieldDecorator("data_di_rilascio", {
                    initialValue: moment(this.state.nascita, dateFormat).isValid()
                      ? moment(this.state.nascita, dateFormat)
                      : null,
                    rules: [
                      {
                        required: true,
                        message: "Inserisci una data valida!",
                      },
                    ],
                  })(
                    <DatePicker
                      format={("DD/MM/YYYY", "DD/MM/YYYY")}
                      placeholder=" Data di rilascio"
                    />
                  )}
                </Form.Item>
              </div>
              <div className="itemCol full">
                <Form.Item hasFeedback>
                  {getFieldDecorator("data_di_scadenza", {
                    initialValue: moment(this.state.nascita, dateFormat).isValid()
                      ? moment(this.state.nascita, dateFormat)
                      : null,
                    rules: [
                      {
                        required: true,
                        message: "Inserisci una data valida!",
                      },
                    ],
                  })(
                    <DatePicker
                      format={("DD/MM/YYYY", "DD/MM/YYYY")}
                      placeholder="  Data di scadenza"
                    />
                  )}
                </Form.Item>
              </div>

              <div className="itemCol full">
                {register.message && (
                  <React.Fragment>
                    {register.role ? (
                      <React.Fragment>
                        <div className="Nmessage S">
                          <i className="fas fa-check-circle" aria-hidden="true"></i>
                          {register.message}
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className="Nmessage E">
                          <i className="fas fa-times-circle" aria-hidden="true"></i>
                          {register.message}
                        </div>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </div>
              {this.props.screenWidth < 800 && (
                <Agreement
                  privacyPolicyChange={this.privacyPolicyChange}
                  receiveEmailsChange={this.receiveEmailsChange}
                />
              )}
              <div className="registerContainer">
                <div className="itemCol semi">
                  <Button
                    type="primary"
                    className="SubmitButton Agency"
                    htmlType="submit"
                  >
                    Registrati
                  </Button>
                </div>
                <div className="itemCol semi">
                  {!this.props.match.params.token &&
                    this.props.accountInfo?.profile?.role?.name !== "main_admin" && (
                      <div
                        className="shareRegister"
                        style={{ marginTop: 7, width: "100%", maxWidth: "unset" }}
                      >
                        <div
                          onClick={() => {
                            this.setState({ shareIcons: !this.state.shareIcons })
                            this.setState({ selectedRegisterType: "" })
                          }}
                          style={{
                            height: "40px",
                            maxWidth: "unset",
                            width: "100%",
                          }}
                        >
                          <i className="fal fa-share" aria-hidden="true"></i>
                          Condividere
                        </div>

                        {this.state.shareIcons && (
                          <div
                            className="overlay"
                            onClick={() => this.setState({ shareIcons: false })}
                          >
                            <div
                              className="options-btns"
                              onClick={(e) => e.stopPropagation()} 
                            >
                              {this.state.selectedRegisterType ? (
                                <>
                                  <i
                                    className="fal fa-copy"
                                    onClick={() => {
                                      getCopy(url)
                                    }}
                                  ></i>

                                  <InlineShareButtons
                                    config={{
                                      alignment: "center",
                                      color: "social",
                                      enabled: true,
                                      font_size: 16,
                                      language: "en",
                                      url: url,
                                      networks: ["whatsapp", "telegram", "email"],
                                      padding: 12,
                                      radius: 4,
                                      show_total: true,
                                      size: 40,
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  <Button
                                    type="primary"
                                    className="simple-reg-btn"
                                    onClick={() =>
                                      this.setState({
                                        selectedRegisterType: "simple",
                                      })
                                    }
                                  >
                                    Registrazione Semplice
                                  </Button>
                                  <Button
                                    type="primary"
                                    className="simple-reg-btn"
                                    onClick={() =>
                                      this.setState({
                                        selectedRegisterType: "complete",
                                      })
                                    }
                                  >
                                    Registrazione Completa
                                  </Button>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </Form> */}
      </Fragment> 
    )
  }
}

const Agreement = ({ privacyPolicyChange, receiveEmailsChange }) => {
  return (
    <div>
      <div className="itemCol full">
        <Checkbox onChange={privacyPolicyChange} className="checkbox-privacy">
          Dichiaro di aver letto e accettato l'
          <a
            href="/informativa-trattamento-dati-personali.pdf"
            rel="noopener noreferrer"
            target="_blank"
          >
            Informativa sulla Privacy
          </a>{" "}
          e i
          <a
            href="/termini-e-condizioni.pdf"
            rel="noopener noreferrer"
            target="_blank"
          >
            {" "}
            Termini e Condizioni
          </a>{" "}
          del servizio, e acconsento al trattamento dei miei dati personali per
          l'erogazione del servizio in conformità al GDPR.
        </Checkbox>
      </div>
      <div className="itemCol full">
        <Checkbox onChange={receiveEmailsChange}>
          Desidero ricevere comunicazioni commerciali e promozionali.
        </Checkbox>
      </div>
    </div>
  )
}

const InfoUser = Form.create({ name: "infoUser" })(RegisterEndUser)

const mapsStateToProps = ({ auth, main }) => ({
  personalInfo: auth.personalInfo,
  register: auth.register,
  accountInfo: auth.accountInfo,
  screenWidth: main.screenWidth,
  activeSkinId: main.activeSkinId,
})

export default withRouter(
  connect(mapsStateToProps, { ...AuthActions, ...MainActions })(InfoUser)
)
