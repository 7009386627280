import { Button, Form, Input, Switch } from "antd"
import React from "react"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"

class AdminConfigura extends React.Component {
  state = {
    modes: [],
    password: "",
    type: "",
    disabled: false,
    visible: true,
  }

  handleChange = (type) => {
    const { modes, disabled } = this.state
    var newModes = modes.map((ele) => {
      if (ele.type === type) {
        ele.active = !ele.active
      } else {
        ele.active = false
      }
      return ele
    })

    var activeType = newModes.find((value) => value.active === true)
    var isVisible = activeType ? true : false

    if (disabled) {
      isVisible =
        newModes.find((value) => value.type === type).active === false ? true : false
    }

    this.setState({
      modes: newModes,
      type: activeType ? activeType.type : type,
      visible: isVisible,
    })
  }

  handlePassword = (e) => {
    this.setState({ password: e.target.value })
  }

  componentDidUpdate(prevProps) {
    if (this.props.systemModes !== prevProps.systemModes) {
      const resp = this.props.systemModes
      const newModes = resp.map((value) => {
        var type = value.type.split("_")
        return {
          type: value.type,
          name:
            type[0].charAt(0).toUpperCase() +
            type[0].slice(1) +
            " " +
            type[1].charAt(0).toUpperCase() +
            type[1].slice(1),
          active: value.active > 0 ? true : false,
        }
      })

      const disactive = newModes.find((value) => value.active === true)
      this.setState({
        modes: newModes,
        disabled: disactive ? true : false,
        type: disactive ? disactive.type : "",
        visible: false,
      })
    }
  }

  handleSubmit = () => {
    const { type, password } = this.state
    this.props.updateSystemMode(type, password)
    this.setState({ password: "" })
  }

  render() {
    const { modes, password, disabled, type } = this.state
    return (
      <div className="systemMode">
        <h3>Panic Mode</h3>
        <div className="sysMode">
          <div className="modes">
            {modes.map((ele) => {
              return (
                <div key={ele.type}>
                  <h6>{ele.name}</h6>
                  <Form.Item>
                    <Switch
                      // checkedChildren={ele.name}
                      // unCheckedChildren={ele.name}
                      checked={ele.active}
                      disabled={
                        disabled && !ele.active && type !== ele.type ? true : false
                      }
                      onChange={() => this.handleChange(ele.type)}
                    />
                  </Form.Item>
                </div>
              )
            })}
          </div>
          <div style={{ display: "block" }}>
            <Form.Item label="Password" hasFeedback>
              <Input.Password value={password} onChange={this.handlePassword} />
            </Form.Item>
            <Button type="primary" onClick={this.handleSubmit} block>
              Submit
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

const mapsStateToProps = (state) => ({
  accountInfo: state.auth.accountInfo,
  userRole: state.auth.accountInfo.profile.role.name,
  getSystemModes: state.main.getSystemModes,
  systemModes: state.main.systemModes,
})

export default connect(mapsStateToProps, { ...MainActions, ...AuthActions })(
  AdminConfigura
)
