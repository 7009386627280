import React from "react";
import RadioInput from "./RadioInput";
import "./style.css"

const RadioGroup = props => {
  const { data, value, setValue, color, activeColor, radioInputClass, borderColor, ...otherProps } = props;
  return (
    <div {...otherProps}>
      {data.map(({ id, name, disabled, tooltipMessage }) => (
        <RadioInput
          className={radioInputClass}
          key={id}
          color={color}
          activeColor={activeColor}
          onClick={() => !disabled && setValue(id)}
          isActive={value === id}
          borderColor={borderColor}
          {...{ name, disabled, tooltipMessage }}
        />
      ))}
    </div>
  );
};

RadioGroup.defaultProps = {
  color: "Gray",
  activeColor: "Primary",
  borderColor: "LightGray",
};

export default RadioGroup;
