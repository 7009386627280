import React from "react"
import { baseUrlImg } from "../../config/api"

const isValidURL = (url) => {
  const regex = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/gm
  return regex.test(url)
}

const PrintTicketTopUp = ({ arr }) => {
  const receiptData = JSON.parse(arr.receipt) || []

  const getLastValue = (data) => {
    return data[data.length - 1]
  }

  const receiptLink = getLastValue(receiptData)
  const dataWithoutLink = isValidURL(receiptLink)
    ? receiptData.slice(0, receiptData.length - 1)
    : receiptData

  const renderReceiptLines = () => {
    return dataWithoutLink && Array.isArray(dataWithoutLink)
      ? dataWithoutLink.map((line, index) => {
          if (line === "") {
            return <div key={index} style={{ height: "10px" }}></div>
          } else {
            const parts = line.split(":")
            if (parts.length > 1) {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    whiteSpace: "pre-wrap",
                    paddingLeft: "5px",
                    fontFamily: "Roboto Mono, monospace",
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Roboto Mono, monospace",
                    }}
                  >
                    {parts[0]}:
                  </div>
                  <div style={{ fontFamily: "Roboto Mono, monospace" }}>
                    {parts[1]}
                  </div>
                </div>
              )
            } else {
              return (
                <div
                  key={index}
                  style={{
                    whiteSpace: "pre-wrap",
                    paddingLeft: "5px",
                    fontFamily: "Roboto Mono, monospace",
                  }}
                >
                  {line}
                </div>
              )
            }
          }
        })
      : null
  }

  return (
    <div className="pin-receipt">
      <div className="receipt-info">
        <img src={`${baseUrlImg}/${arr.logo}`} alt="service logo" />
        <div className="receipt-data">{renderReceiptLines()}</div>
      </div>

      <div className="receipt-data" style={{ width: "100%" }}>
        {isValidURL(receiptLink) && (
          <a href={receiptLink} target="_blank" rel="noopener noreferrer">
            {receiptLink}
          </a>
        )}
      </div>
    </div>
  )
}

export default PrintTicketTopUp
