import React, { Component } from "react";
import { AuthActions } from "redux-store/models";
import { connect } from "react-redux";
import Voli from "./Voli";
import Treni from "./Treni";
import Eventi from "./Eventi";
import ShopOnline from "./ShopOnline";
import Energia from "./Energia";
import { Tooltip } from "antd";
import { iconsFromType } from "utils/HelperFunc";
import Enagic from "./Enagic";
import Facileristrutturare from "./Facileristrutturare";
import Assicurazioni from "./Assicurazioni";

export class DetailRow extends Component {
  state = {
    isOpen: false,
  };
  
  setModal = () => this.setState({ isOpen: true });

  generateTicketDetailInfos = () => {
    const { TicketByTcketId } = this.props;
    return (
      <React.Fragment>
        {TicketByTcketId && TicketByTcketId.type === 1 && (
          <Voli
            TicketByTcketId={TicketByTcketId}
            typee={this.props.TicketByTcketId.type}
            updateDataForm={this.props.updateDataForm}
            ticketId={TicketByTcketId.id}
            editable={this.props.editable}
          />
        )}
        {TicketByTcketId && TicketByTcketId.type === 2 && (
          <Treni
            TicketByTcketId={TicketByTcketId}
            typee={this.props.TicketByTcketId.type}
            updateDataForm={this.props.updateDataForm}
            ticketId={TicketByTcketId.id}
            editable={this.props.editable}
          />
        )}
        {TicketByTcketId && TicketByTcketId.type === 3 && (
          <Eventi
            TicketByTcketId={TicketByTcketId}
            typee={this.props.TicketByTcketId.type}
            updateDataForm={this.props.updateDataForm}
            ticketId={TicketByTcketId.id}
            editable={this.props.editable}
          />
        )}
        {TicketByTcketId && TicketByTcketId.type === 4 && (
          <ShopOnline
            TicketByTcketId={TicketByTcketId}
            typee={this.props.TicketByTcketId.type}
            updateDataForm={this.props.updateDataForm}
            ticketId={TicketByTcketId.id}
            editable={this.props.editable}
          />
        )}
        {TicketByTcketId && TicketByTcketId.type === 8 && (
          <Energia
            TicketByTcketId={TicketByTcketId}
            typee={this.props.TicketByTcketId.type}
            updateDataForm={this.props.updateDataForm}
            ticketId={TicketByTcketId.id}
            editable={this.props.editable}
          />
        )}
        {TicketByTcketId && TicketByTcketId.type === 12 && (
          <Enagic
            TicketByTcketId={TicketByTcketId}
            typee={this.props.TicketByTcketId.type}
            updateDataForm={this.props.updateDataForm}
            ticketId={TicketByTcketId.id}
            editable={this.props.editable}
          />
        )}
        {TicketByTcketId && TicketByTcketId.type === 13 && (
          <Facileristrutturare
            TicketByTcketId={TicketByTcketId}
            typee={this.props.TicketByTcketId.type}
            updateDataForm={this.props.updateDataForm}
            ticketId={TicketByTcketId.id}
            editable={this.props.editable}
          />
        )}
         {TicketByTcketId && TicketByTcketId.type === 10 && (
          <Assicurazioni
            TicketByTcketId={TicketByTcketId}
            typee={this.props.TicketByTcketId.type}
            updateDataForm={this.props.updateDataForm}
            ticketId={TicketByTcketId.id}
            editable={this.props.editable}
          />
        )}
      </React.Fragment>
    );
  };

  render() {
    const { ticket, TicketByTcketId, allRoles, screenWidth, accountInfo } = this.props;

    return (
      ticket && (
        <React.Fragment>
          <div
            className="ticketDetails--row animated fadeIn"
            style={{
              zIndex: ticket?.id === TicketByTcketId?.id && "2",
              backgroundColor: ticket?.id === TicketByTcketId?.id && "#ffe8dd",
            }}
          >
            <span className="status">
              <Tooltip title={ticket.status}>
                <div data-status={`${ticket.status}`}>
                  <span></span>
                </div>
              </Tooltip>
            </span>
            <span>
              {screenWidth <= 600 ? (
                <span>{ticket.nome_agenzia}</span>
              ) : (
                <React.Fragment>
                  <Tooltip title={ticket.type}>
                    <i
                      className={`fas fa-${
                        ticket.type === "Treni"
                          ? "train"
                          : ticket.type === "Eventi"
                          ? "ticket-alt"
                          : ticket.type === "Voli"
                          ? "plane"
                          : ticket.type === "Shop"
                          ? "shopping-bag"
                          : ticket.type === "Luce / Gas"
                          ? "lightbulb"
                          : ticket.type === "Enagic"
                          ? "faucet-drip"
                          : ticket.nome_agenzia === "facileristrutturare"
                          ? "digging"
                          : ticket.type === "Assicurazioni"
                          ? "file-alt"
                          : ""
                      }`}
                      aria-hidden="true"
                    ></i>
                  </Tooltip>
                  Prenotazione{" "}
                  <div>
                    {ticket.nome_agenzia === "facileristrutturare"
                      ? "facile ristrutturare"
                      : ticket.nome_agenzia}
                  </div>{" "}
                  {ticket.status === "Nuova Richiesta" && (
                    <div className="new">New</div>
                  )}
                </React.Fragment>
              )}
            </span>
            <span>{ticket.skin}</span>
            <span>
              <i className={`${allRoles[ticket.role]}`} />
              {ticket.user}
            </span>
            <span>BP-{ticket.id}</span>
            <span>{ticket.updated_at}</span>
            <span className="options">
              {/* <i className="fal fa-file-pdf"></i> */}
              <Tooltip title="Presto Subito">
                {" "}
                <div>
                  <i className="fal fa-archive"></i>
                </div>
              </Tooltip>
              <Tooltip title="Presto Subito">
                {" "}
                <div>
                  <i className="fal fa-trash" aria-hidden="true"></i>
                </div>
              </Tooltip>
              {screenWidth <= 600 ? (
                <i
                  className="fal fa-eye"
                  onClick={() => {
                    this.props.getTicketByTicketId(ticket.id);
                  }}
                />
              ) : (
                <div
                  className="toggler"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (TicketByTcketId && TicketByTcketId.id === ticket.id) {
                      this.props.setTicketByTicketId(null);
                    } else {
                      this.props.getTicketByTicketId(ticket.id);
                      this.setModal();
                    }
                  }}
                >
                  <i
                    className={`fal fa-chevron-${
                      TicketByTcketId && TicketByTcketId.id === ticket.id
                        ? "up"
                        : "down"
                    }`}
                    aria-hidden="true"
                  ></i>
                  {TicketByTcketId && TicketByTcketId.id === ticket.id
                    ? "CHIUDI"
                    : "DETTAGLI"}
                </div>
              )}
            </span>
          </div>
          {TicketByTcketId &&
            TicketByTcketId.id === ticket.id &&
            (screenWidth > 600 ? (
              <>
                {" "}
                {this.state.isOpen && (
                  <div
                    className="backDrop"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (TicketByTcketId && TicketByTcketId.id === ticket.id) {
                        this.props.setTicketByTicketId(null);
                      } else {
                        this.props.getTicketByTicketId(ticket.id);
                        this.setModal();
                      }
                    }}
                  ></div>
                )}
                <div className="ticketDetails--infos animated fadeIn">
                  <div className="ticketDetails--infos__body">
                    <div className="ticketDetails--infos__header">
                      <h5>{ticket.nome_agenzia.toUpperCase()}</h5>
                      <div>
                        <span>
                          ID: <span className="ticket--id">BP-{ticket.id}</span>
                        </span>
                        <span>
                          PRESA A CARICO{" "}
                          <i
                            className="fal fa-check-circle"
                            aria-hidden="true"
                          ></i>{" "}
                        </span>
                      </div>
                    </div>{" "}
                    {this.generateTicketDetailInfos()}
                 
                  </div>
                </div>
              </>
            ) : (
              <div className="ticketDetailsModal">
                <div
                  className="backDrop"
                  onClick={() => this.props.setTicketByTicketId(null)}
                />

                <div className="content">
                  <div className="head">
                    <div>
                      <i
                        className={`${
                          iconsFromType[ticket.nome_agenzia.toLowerCase()]
                        }`}
                      />
                      <span>{ticket.nome_agenzia}</span>
                    </div>
                    <i
                      className="fal fa-times"
                      onClick={() => this.props.setTicketByTicketId(null)}
                    />
                  </div>

                  {this.generateTicketDetailInfos()}
                </div>
              </div>
            ))}
        </React.Fragment>
      )
    );
  }
}
const mstp = (state) => {
  return {
    TicketByTcketId: state.auth.TicketByTcketId,
    screenWidth: state.main.screenWidth,
    accountInfo: state.auth.accountInfo,
  };
};
export default connect(mstp, AuthActions)(DetailRow);
