import React from "react"
import { Button } from "antd"
import { baseUrlImg } from "../../config/api"
import images from "themes/images"
import { connect } from "react-redux"
import { MainActions } from "redux-store/models"

function ModulePopUpBack({
  service_s,
  handleFlip,
  togglePopUp,
  hasPhoneNumber,
  showNumPadCost,
  cfServices
}) {
  const colorBg = service_s?.services?.[0]?.colorBg
  const colorTesto = service_s?.services?.[0]?.colorTesto
  let content = service_s?.services?.[0]?.content

  // Replace existing text color styles with colorTesto
  content = content?.replace(
    /color:\s*rgb\(\d+,\s*\d+,\s*\d+\);?/g,
    `color: ${colorTesto};`
  )

  // Remove background color styles from any element
  content = content?.replace(/background-color:\s*rgb\(\d+,\s*\d+,\s*\d+\);?/g, "")

  // Remove background color styles specifically from `a` tags
  content = content?.replace(
    /(<a[^>]*style="[^"]*)background-color:\s*[^;]+;?/g,
    "$1"
  )

  // Apply colorTesto to the links
  content = content?.replace(
    /(<a[^>]*style=")([^"]*)"/g,
    `$1$2; color: ${colorTesto};"`
  )

  return (
    <div
      className={`leftCol_Module Popup4 ${!hasPhoneNumber ? "has-img" : ""} ${
        showNumPadCost ? "has-custom-cost" : ""
      } ${cfServices ? " cf-input" : ""}`}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <div className="row">
        <div className="info-icon">
          <span onClick={handleFlip}>
            <i className="far fa-arrow-left"></i>
          </span>
        </div>
        <div className="Logo">
          <div className="Logo--Help">
            <img
              src={
                service_s && service_s?.services && service_s?.services[0]?.icon
                  ? `${baseUrlImg}/${
                      service_s?.services[0]?.icon || "uncategorized/placeholder.jpg"
                    }`
                  : images[service_s.id]
              }
              alt=""
            />
          </div>
        </div>
        <div className="Close">
          <span className="CloseButton" onClick={() => togglePopUp(false)}>
            <img src={images.close} alt="" />
          </span>
        </div>
        <div
          className="content"
          style={{
            backgroundColor: colorBg,
            color: colorTesto,
            overflow: "auto",
          }}
        >
          <div>
            <span className="title">Guida all'uso</span>

            <div
              className="extra-info"
              style={{
                textAlign: "justify",
              }}
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            ></div>
          </div>

          <Button onClick={handleFlip} style={{ color: colorBg }}>
            Chiudi
          </Button>
        </div>
      </div>
    </div>
  )
}

export default connect(null, MainActions)(ModulePopUpBack)
