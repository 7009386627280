import React from "react";
import { connect } from "react-redux";
import AuthActions from "redux-store/models/auth";
import "./adminNewStatistiche.css";
import StatisticheMensili from "./StatisticheMensili"
import StatisticheAnnuali from "./StatisticheAnnuali"
import StatisticheReportMensile from "./StatisticheReportMensile"

class AdminNewStatistiche extends React.Component {

  render() {
    return (
     <div className="statistiche-container">
        <StatisticheMensili {...this.props}/>
        <StatisticheAnnuali />
         <StatisticheReportMensile {...this.props}/>
     </div>
    );
  }
}
const mapStateToProps = (state) => ({
  accountInfo: state.auth.accountInfo,
});
export default connect(mapStateToProps, AuthActions)(AdminNewStatistiche);
