import React from "react";
import images from "themes/images";
import { connect } from "react-redux";
import AuthActions from "redux-store/models/auth";
import CommonBolletinoWrapper from "./CommonBolletinoWrapper";
import {
  BoloAutoLeftForm,
} from "shared-components/ModulePopUp/BoloAutoForms"

const BolloAutoForm = ({ setService, activeService, setPagoPa, allFavServices, getBolletiniAuto }) => {
  const commonBolletinoProps = {
    title: "BOLO AUTO",
    favService: "BOLL",
    image:  <img src={images["bolAutoBack"]} alt=""/>,
  };

  const accountData = JSON.parse(localStorage.getItem("accountDataB")) || {}
  const isEPay = accountData?.profile?.supplier === "E-Pay"

  return (
    <CommonBolletinoWrapper submit={isEPay ? getBolletiniAuto : setPagoPa} {...{ commonBolletinoProps, activeService, setService, allFavServices }}>
       {({ getFieldDecorator, getFieldValue }, barcode) => (
          <BoloAutoLeftForm
            mobile
            barcodeData={barcode}
            {...{ getFieldDecorator, getFieldValue }}
          />
      )}
    </CommonBolletinoWrapper>
  );
};

export default connect(null, AuthActions)(BolloAutoForm);
