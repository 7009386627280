import React, { Component } from "react"
import { notification } from "antd"
import MyInput from "./Input"
import FormSubmiter from "./FormSubmiter"
import TelefonoInput from "./TelefonoInput"

class Assicurazioni extends Component {
  state = {
    nome: this.props.TicketByTcketId.nome,
    cognome: this.props.TicketByTcketId.cognome,
    email: this.props.TicketByTcketId.email,
    extra_data: this.props.TicketByTcketId.note,
    telefono: this.props.TicketByTcketId.telefono,
    targa: this.props.TicketByTcketId.targa,
    tipo: this.props.TicketByTcketId.tipo,
    valore: this.props.TicketByTcketId.valore,
    situazione: this.props.TicketByTcketId.situazione,
    citta: this.props.TicketByTcketId.citta,
    cap: this.props.TicketByTcketId.cap,
    accesories: this.props.TicketByTcketId.accesories,
  }

  resetState = (msg) => {
    if (!msg.error) {
      notification["success"]({
        message: "Azione completata",
        description: msg.msg,
        placement: "bottomRight",
      })
    } else {
      notification["error"]({
        message: msg.msg[0],
        description: msg.msg[1],
        placement: "bottomRight",
        duration: 5,
      })
    }
  }
  //   submitData = () => {
  //     const {
  //       nome,
  //       cognome,
  //       email,
  //       extra_data,
  //       telefono,
  //       targa,
  //       tipo,
  //       valore,
  //       situazione,
  //     } = this.state
  //     this.props.updateDataForm(
  //       this.props.typee,
  //       link,
  //       nome_agenzia,
  //       extra_data,
  //       null,
  //       null,
  //       this.resetState,
  //       "",
  //       "",
  //       "",
  //       destinazione,
  //       "",
  //       "",
  //       "",
  //       "",
  //       "",
  //       "",
  //       categoria,
  //       descrizione_categoria,
  //       quantity,
  //       name,
  //       email,
  //       telefono,
  //       parseFloat(price),
  //       this.props.ticketId
  //     )
  //   }
  render() {
    const { editable } = this.props

    const {
      nome,
      cognome,
      email,
      extra_data,
      telefono,
      targa,
      tipo,
      valore,
      situazione,
      citta,
      cap,
      accesories,
    } = this.state

    console.log(accesories)

    return (
      <React.Fragment>
        <div className="formBody">
          <div className="formBody--col">
            <MyInput
              labelName={"Nome"}
              type={"text"}
              editable={editable}
              value={nome}
              handleChange={(e) => {
                this.setState({ nome: e.target.value })
              }}
            />
            <MyInput
              labelName={"Cognome"}
              type={"text"}
              editable={editable}
              value={cognome}
              handleChange={(e) => {
                this.setState({ cognome: e.target.value })
              }}
            />
            <TelefonoInput
              nome={nome}
              editable={editable}
              value={telefono}
              set={(val) => {
                this.setState({ telefono: val })
              }}
            />
            <MyInput
              labelName={"Email"}
              type={"text"}
              editable={editable}
              value={email}
              handleChange={(e) => {
                this.setState({ email: e.target.value })
              }}
            />
            <MyInput
              labelName={"Accesories"}
              type={"text"}
              editable={editable}
              value={accesories.join(", ")}
              handleChange={(e) => {
                const accesories = e.target.value.split(", ")
                this.setState({ accesories })
              }}
            />

            <MyInput
              labelName={"Notes"}
              type={"text"}
              editable={editable}
              value={extra_data}
              handleChange={(e) => {
                this.setState({ extra_data: e.target.value })
              }}
            />
          </div>
          <div className="formBody--col">
            <MyInput
              labelName={"Cap"}
              type={"text"}
              editable={editable}
              value={cap}
              handleChange={(e) => {
                this.setState({ cap: e.target.value })
              }}
            />
            <MyInput
              labelName={"Citta"}
              type={"text"}
              editable={editable}
              value={citta}
              handleChange={(e) => {
                this.setState({ citta: e.target.value })
              }}
            />
            <MyInput
              labelName={"Tipo"}
              type={"text"}
              editable={editable}
              value={tipo}
              handleChange={(e) => {
                this.setState({ tipo: e.target.value })
              }}
            />
            <MyInput
              labelName={"Targa"}
              type={"text"}
              editable={editable}
              value={targa}
              handleChange={(e) => {
                this.setState({ targa: e.target.value })
              }}
            />
            <MyInput
              labelName={"Situazione"}
              type={"text"}
              editable={editable}
              value={situazione}
              handleChange={(e) => {
                this.setState({ situazione: e.target.value })
              }}
            />
            <MyInput
              labelName={"Valore"}
              type={"text"}
              editable={editable}
              value={valore}
              handleChange={(e) => {
                this.setState({ valore: e.target.value })
              }}
            />
          </div>
        </div>
        <FormSubmiter
          //   price={price}
          priceChange={(e) => {
            this.setState({ price: e })
          }}
          //   sendOffert={this.submitData}
        />
      </React.Fragment>
    )
  }
}

export default Assicurazioni
