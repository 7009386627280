import React, { useCallback } from "react"
import { connect } from "react-redux"
import { baseUrlImg } from "../../../config/api"
import { MainActions, AuthActions } from "redux-store/models"

const CompaniesFavorite = ({
  favourites,
  screenWidth,
  getCompanyServices,
  toggleFavorite,
  togglePopUp,
  setCompanyServices,
}) => {
  const favouriteClick = useCallback(
    async (e, companyId) => {
      e.stopPropagation()
      e.preventDefault()

      await toggleFavorite(companyId, "remove")
    },
    [toggleFavorite]
  )

  const onMouseEnter = useCallback((companyId) => {
    const el = document.querySelector(`[data-id="${companyId}comp"]`)
    if (el) el.classList.add("hover")
  }, [])

  const onMouseLeave = useCallback((companyId) => {
    const el = document.querySelector(`[data-id="${companyId}comp"]`)
    if (el) el.classList.remove("hover")
  }, [])

  return (
    <div className="Favorites">
      {favourites &&
        Object.keys(favourites).map((categoryKey) => {
          const category = favourites[categoryKey]
          return Object.keys(category)
            .filter((key) => key !== "name" && key !== "group")
            .map((serviceKey) => {
              const service = category[serviceKey]
              return (
                <div
                  className="mobileServices--body__item"
                  key={serviceKey}
                  onClick={() => {
                    console.log(service)
                    if (!service.services) {
                      getCompanyServices(serviceKey)
                    } else {
                      setCompanyServices(service)
                    }
                    togglePopUp(true)
                  }}
                >
                  <img
                    src={`${baseUrlImg}/${service.services[0]?.icon}`}
                    alt={service.name}
                  />
                  <span>{service.name}</span>
                  {screenWidth > 1024 && (
                    <i
                      onMouseEnter={() => onMouseEnter(serviceKey)}
                      onMouseLeave={() => onMouseLeave(serviceKey)}
                      onClick={(e) => favouriteClick(e, serviceKey)}
                      data-id={`${serviceKey}comp`}
                      className={`fal fa-star favourite`}
                    />
                  )}
                </div>
              )
            })
        })}
    </div>
  )
}

const mapsStateToProps = (state) => ({
  accountInfo: state.auth.accountInfo,
  screenWidth: state.main.screenWidth,
  skinExtras: state.auth.skinExtras,
})

export default connect(mapsStateToProps, { ...MainActions, ...AuthActions })(
  CompaniesFavorite
)
