import React, { useState, useEffect } from "react"
import { Menu, Icon, Button } from "antd"
import { connect } from "react-redux"
import MainActions from "redux-store/models/main"
import AuthActions from "redux-store/models/auth"
import images from "../../../themes/images"
import { get } from "lodash"
import { withRouter } from "react-router-dom"
import "./AdminMenu.css"
import { openChat } from "../../Chat/Chat"
import { baseUrlImg } from "../../../config/api"

const { SubMenu } = Menu

const AdminMenu = (props) => {
  const {
    skinList,
    setActiveSkinId,
    setActiveSkinName,
    history,
    accountInfo,
    screenWidth,
    activeSkinId,
    activeSkinName,
    logOut,
    menuSkinVisible,
  } = props
  console.log(skinList)

  const [selectedNavKey, setSelectedNavKey] = useState([])
  const [selectedSkinKey, setSelectedSkinKey] = useState([])

  const role = get(accountInfo, "profile.role.name")

  const handleLogout = () => {
    logOut()
    window.location.hash = "login"
  }

  // Menu items
  const menuItems = [
    {
      key: "providers",
      path: "/back-office/providers",
      icon: <img src={require("../../../assets/images/icons/providers.svg")} />,
      label: "Providers",
      roles: ["main_admin"],
    },
    {
      key: "reg-agenzia",
      path: "/back-office/registraAgenzia",
      icon: <Icon type="plus" />,
      label: "Registra Agenzia",
      roles: ["super_admin", "agent"],
    },
    {
      key: "reg-agente",
      path: "/back-office/registraAgente",
      icon: <Icon type="plus" />,
      label: "Registra Agente",
      roles: ["super_admin"],
    },
    {
      key: "servizi",
      path: "/back-office/servizi",
      icon: <img src={require("../../../assets/images/icons/shop.svg")} />,
      label: "Servizi",
      roles: ["main_admin"],
    },
    {
      key: "categorie",
      path: "/back-office/category",
      icon: <img src={require("../../../assets/images/icons/category.svg")} />,
      label: "Categorie",
      roles: ["main_admin"],
    },
    {
      key: "gallery",
      path: "/back-office/gallery",
      icon: <img src={require("../../../assets/images/icons/gallery.svg")} />,
      label: "Media",
      roles: ["main_admin", "support"],
    },
    {
      key: "dropshipping",
      path: "/back-office/ordine-shop",
      icon: <img src={require("../../../assets/images/icons/dropshipping.svg")} />,
      label: "Dropshipping",
      roles: ["main_admin", "super_admin"],
    },
    {
      key: "finanziario-section",
      label: "Finanziario",
      isSection: true,
      roles: ["main_admin", "super_admin", "agent", "support"],
    },
    {
      key: "payment",
      path: "/back-office/movimenti",
      icon: <img src={require("../../../assets/images/icons/payment.svg")} />,
      label: "Payment",
      roles: ["main_admin", "super_admin", "agent", "support"],
    },
    {
      key: "utenti",
      path: "/back-office/utenti",
      icon: <img src={require("../../../assets/images/icons/users.svg")} />,
      label: "Utenti",
      roles: ["main_admin", "super_admin", "agent"],
    },
    {
      key: "statistiche",
      path: "/back-office/statistiche",
      icon: <img src={require("../../../assets/images/icons/statistiche.svg")} />,
      label: "Statistiche",
      roles: ["main_admin", "super_admin", "agent", "support"],
    },
    {
      key: "depositi-prelievi",
      path: "/back-office/depositi",
      icon: <img src={require("../../../assets/images/icons/depositi.svg")} />,
      label: "Depositi/Prelievi",
      roles: ["main_admin", "super_admin", "agent"],
    },
    {
      key: "fatture",
      path: "/back-office/fatture",
      icon: <img src={require("../../../assets/images/icons/fatture.svg")} />,
      label: "Fatture",
      roles: ["super_admin", "agent"],
    },
    {
      key: "generale-section",
      label: "Generale",
      isSection: true,
      roles: ["main_admin", "super_admin", "agent"],
    },
    {
      key: "support",
      icon: <img src={require("../../../assets/images/icons/support.svg")} />,
      label: "Support",
      roles: ["main_admin", "super_admin", "agent"],
      onClick: () => openChat(screenWidth, window.innerHeight),
    },
    {
      key: "errori",
      path: "/back-office/support",
      icon: <img src={require("../../../assets/images/icons/error.svg")} />,
      label: "Errori",
      roles: ["main_admin"],
    },
    {
      key: "messaggi",
      path: "/back-office/messaggi",
      icon: <img src={require("../../../assets/images/icons/message.svg")} />,
      label: "Messaggi",
      roles: ["main_admin", "super_admin", "agent"],
    },
    {
      key: "panic-mode",
      path:
        role === "main_admin" ? "/back-office/configure" : "/back-office/configura",
      icon:
        role === "main_admin" ? (
          <img src={require("../../../assets/images/icons/panic-mode.svg")} />
        ) : (
          <Icon type={role === "main_admin" ? "safety" : "user"} />
        ),
      label: role === "main_admin" ? "Panic Mode" : "Dati Utente",
      roles: ["main_admin", "super_admin", "agent"],
    },
    {
      key: "add-skin",
      path: "/back-office/CreateSkin",
      icon: <Icon type="plus" />,
      label: "Aggiungi skin",
      roles: ["main_admin"],
    },
    // {
    //   key: "logout",
    //   path: "/logout",
    //   icon: <Icon type="logout" />,
    //   label: "Log out",
    //   roles: ["main_admin", "super_admin", "agent", "support"],
    //   onClick: () => handleLogout(),
    // },
  ]

  const accessibleMenuItems = menuItems.filter((item) => item.roles.includes(role))

  useEffect(() => {
    if (skinList?.length > 0) {
      const firstSkin = skinList[0]

      const newSkinId = activeSkinId !== "-1" ? activeSkinId : firstSkin.id
      setActiveSkinId(newSkinId)
      setSelectedSkinKey([newSkinId?.toString()])
    }
  }, [skinList])

  useEffect(() => {
    const currentPath = history.location.pathname
    const activeItem = menuItems.find((item) => currentPath.startsWith(item.path))

    if (activeItem) {
      setSelectedNavKey([activeItem.key])
    } else {
      setSelectedNavKey([])
    }
  }, [history.location.pathname])

  const handleNavigation = (path) => {
    history.push(path)
    const activeItem = menuItems.find((item) => item.path === path)
    if (activeItem) {
      setSelectedNavKey([activeItem.key])
    }
  }

  const returnLogo = (id) => {
    return require(`../../../assets/images${id}/logo.svg`)
  }

  const handleClickSkin = (skin, e) => {
    const key = e.key
    console.log(activeSkinName, skin.username)

    setActiveSkinId(activeSkinId === skin.id ? -1 : skin.id)
    setActiveSkinName(activeSkinName === skin.username ? "" : skin.username)

    setSelectedSkinKey((prevSelectedKeys) => {
      if (prevSelectedKeys[0] === key) {
        return []
      } else {
        return [key]
      }
    })
  }

  console.log(activeSkinId, activeSkinName)

  return (
    <div className="admin-menu">
      <div>
        <div className="menu-logo">
          <a href="/#/back-office/utenti">
            {role != "support" && role != "main_admin" && (
              <>
                <img
                  className={"imageHeaderTitle " + accountInfo.profile.activity_name}
                  src={
                    accountInfo.profile.activity_id === 55
                      ? images["logoMain55"]
                      : returnLogo(accountInfo.profile.activity_id)
                  }
                  alt=""
                />
              </>
            )}
            {(role == "support" || role == "main_admin") &&
            activeSkinId !== -1 &&
            activeSkinId !== undefined ? (
              <>
                <div className="admin-header-logo">
                  {" "}
                  <img
                    className={menuSkinVisible ? "icon" : "logo-" + activeSkinId}
                    alt={`logo-${activeSkinId}`}
                    src={
                      menuSkinVisible
                        ? `${baseUrlImg}/icon/${activeSkinName?.toLowerCase()}-icon.svg`
                        : `${baseUrlImg}/logo/${activeSkinName?.toLowerCase()}.svg`
                    }
                  />
                  {/* <span>
                    {" "}
                    {role == "support"
                      ? "SUPPORT PANNEL"
                      : "SERVICES ADMIN PANNEL"}{" "}
                  </span> */}
                </div>
              </>
            ) : (
              <span>
                {(role == "support" || role == "main_admin") && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      className={"logo-" + activeSkinId}
                      alt={`logo-${activeSkinId}`}
                      src={`${baseUrlImg}/logo/Apay-icon.svg`}
                    />
                    <span
                      style={{
                        textTransform: "capitalize",
                        fontStyle: "normal",
                        color: " #292D32",
                        fontWeight: 500,
                        fontSize: 28,
                      }}
                    >
                      Apay
                    </span>
                  </div>

                  // <>
                  //   <span>
                  //     {role === "support"
                  //       ? accountInfo.profile.username === "support_prenotazioni"
                  //         ? "Luce&Gas"
                  //         : "Support"
                  //       : "Admin"}
                  //   </span>
                  //   <span>Panel</span>
                  // </>
                )}
              </span>
            )}
          </a>
        </div>

        <div className="menu-section" style={{ marginTop: 10, marginBottom: 10 }}>
          Menu Principale
        </div>
        {/* Skins Menu */}
        {(role === "main_admin" || role === "support") && (
          <Menu
            selectedKeys={selectedSkinKey}
            mode="inline"
            defaultOpenKeys={["sub1"]}
          >
            <SubMenu
              key="sub1"
              title={
                <span>
                  {/* <Icon type="appstore" /> */}
                  <img src={require("../../../assets/images/icons/skins.svg")} />
                  <span>Skins</span>
                </span>
              }
            >
              {skinList &&
                Array.isArray(skinList) &&
                skinList.map((skin) => (
                  <Menu.Item key={skin.id} onClick={(e) => handleClickSkin(skin, e)}>
                    <div>
                      <img
                        className="skin-icons"
                        src={`${baseUrlImg}/icon/${skin?.username?.toLowerCase()}-icon.svg`}
                        alt=""
                      />
                      {skin.username}
                    </div>
                  </Menu.Item>
                ))}
            </SubMenu>
          </Menu>
        )}

        {/* Navigation Menu */}
        <Menu selectedKeys={selectedNavKey} mode="inline">
          {accessibleMenuItems.map((item) => {
            if (item.isSection) {
              return (
                <div key={item.key} className="menu-section">
                  {item.label}
                </div>
              )
            } else {
              return (
                <Menu.Item
                  key={item.key}
                  onClick={() => {
                    if (item.onClick) {
                      item.onClick()
                    } else {
                      handleNavigation(item.path)
                    }
                  }}
                >
                  <span>
                    {item.icon}
                    <span
                      style={
                        item.key === "panic-mode" && item.label === "Panic Mode"
                          ? { color: "red" }
                          : {}
                      }
                    >
                      {item.label}
                    </span>
                  </span>
                </Menu.Item>
              )
            }
          })}
        </Menu>
        <div
          id="PWA"
          style={{ marginTop: "30px", marginBottom: "30px", cursor: "pointer" }}
        >
          <img src={`${baseUrlImg}/logo/pwa.svg`} height={45} />
        </div>
      </div>
      <Button onClick={handleLogout} className="logout-btn" icon="logout">
        Logout
      </Button>
    </div>
  )
}

const mstp = (state) => ({
  screenWidth: state.main.screenWidth,
  skinList: state.auth.skinList,
  activeSkinId: state.main.activeSkinId,
  activeSkinName: state.main.activeSkinName,
  accountInfo: state.auth.accountInfo,
  screenWidth: state.main.screenWidth,
})

export default connect(mstp, { ...MainActions, ...AuthActions })(
  withRouter(AdminMenu)
)
