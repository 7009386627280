import React, { Component } from "react"
import SpedizioniStep2 from "./SpedizioniStep2"
import SpedizioniStep1 from "./SpedizioniStep1"
import { Steps } from "antd"
import "./spedizioni.css"

const { Step } = Steps
export class Spedizioni extends Component {
  state = {
    currentStep: 0,
  }

  nextStep = () => {
    this.setState((prevState) => ({
      currentStep: prevState.currentStep + 1,
    }))
  }

  previousStep = () => {
    this.setState((prevState) => ({
      currentStep: prevState.currentStep - 1,
    }))
  }

  render() {
    const { currentStep } = this.state
    return (
      <>
        <div className="spedizioniSteps">
          <Steps current={currentStep}>
            <Step title="Passo 1" />
            <Step title="Passo 2" />
          </Steps>
        </div>
        {currentStep === 0 && <SpedizioniStep1 nextStep={this.nextStep} />}
        {currentStep === 1 && (
          <SpedizioniStep2
            nextStep={this.nextStep}
            previousStep={this.previousStep}
          />
        )}
      </>
    )
  }
}

export default Spedizioni
