import React, { useEffect, useState } from "react"
import "./GenderToggle.css"

const GenderToggle = ({ initialGender, onGenderChange }) => {
  const [selectedGender, setSelectedGender] = useState(initialGender)

  const handleGenderChange = (gender) => {
    setSelectedGender(gender)
    onGenderChange(gender)
  }

  useEffect(() => {
    setSelectedGender(initialGender)
  }, [initialGender])

  console.log(selectedGender)
  return (
    <div className="custom-input-container">
      <div className="gender-input-container">
        <div
          className={`gender-option ${selectedGender === "F" ? "selected" : ""}`}
          onClick={() => handleGenderChange("F")}
        >
          Donna
        </div>
        <div className="divider" />
        <div
          className={`gender-option ${selectedGender === "M" ? "selected" : ""}`}
          onClick={() => handleGenderChange("M")}
        >
          Uomo
        </div>
      </div>
    </div>
  )
}

export default GenderToggle
