import React from "react";
import PrenotationForm from "./PrenotationForm";

const finanziamentiInputProps = {
  nome: { extraClass: "semi" },
  cognome: { extraClass: "semi" },
  codice_fiscale: { label: "Codice Fiscale" },
  via: {placeholder:'Indirizo Residenza'},
  nr_civicio: {
    label: "Nr. Civicio",
    extraClass: "semi",
  },
  cap: { extraClass: "semi" },
  citta: { extraClass: "semi" },
  provincia: { extraClass: "semi" },
  telefono: { Icon: () => <i className="fal fa-phone inputI" /> },
  email: { position: "right",label:'E-mail',Icon: () => <i className="fal fa-envelope inputI" />  },
  prodottoRichiesto: {
    initialValue: "Cesione",
    type: "radio",
    position: "right",
    label: "Prodotto Richiesto",
    radioData: [
      { value: "Cesione", label: "Cesione quinto" },
      { value: "Prestito", label: "Prestito personale" },
      { value: "Mutuo" },
    ],
  },
  orario: {
    initialValue: "09:00-12:30",
    type: "radio",
    position: "right",
    label: "Orario Di Disponibilita",
    radioData: [{ value: "09:00-12:30" }, { value: "14:00-18:00" }, { value: "18:00-20:00" }],
  },
  note: {
    type: "notes",
    position: "right",
    label: "Descrizione della richiesta",
    placeholder:' ',
  },
};

const Finanziamenti = (props) => {
  const { nome_agenzia, color, name, goBack, isMobile, activeService } = props;

  const submit = (state,props,callback) => {console.log(state,props,callback)};

  return (
    <PrenotationForm
      inputProps={finanziamentiInputProps}
      title="FINANZIAMENTI - MUTUI - CESSIONE DEL QUINTO"
      description="Richiedi una consulenza gratuita e scopri l’offerta più vantaggiosa per te. Compila il form qui sotto e verrai ricontattato al più presto dai nostri professionisti autorizzati."
      submit={submit}
      {...{ nome_agenzia, color, name, goBack, isMobile, activeService }}
    />
  );
};

export default Finanziamenti;