import React from "react"
import { Tooltip } from "antd"
import moment from "moment"
import { connect } from "react-redux"
import AuthActions from "redux-store/models/auth"
import {Loader } from "shared-components"

const graphForMap = (graphData) => {
  let arrayForMax = []
  let arrayForMap = []
  if (graphData) {
    Object.keys(graphData).forEach((key) => {
      arrayForMax.push(parseFloat(graphData[key].replace(".", "")))
      arrayForMap.push({
        value: parseFloat(graphData[key].replace(".", "")),
        month: parseInt(key.substring(0, key.indexOf("_"))),
        day: parseInt(key.substring(key.indexOf("_") + 1, key.length)),
        year: parseInt(key.split("_")[2]),
        realValue: graphData[key] + " €",
      })
    })
  }
  arrayForMax.sort(function (a, b) {
    return a - b
  })

  return { max: arrayForMax[arrayForMax.length - 1], arrayForMap }
}
const Graph = ({
  graphicData,
  date,
  setDate,
  getPayments,
  activeSkinId,
  setFromDateToDate,
  Filter,
  loadingStatistiche
}) => {
  const max = graphForMap(graphicData).max
  const arrayForMap = graphForMap(graphicData).arrayForMap
  return (
    <div className="Graph">
      {loadingStatistiche && <Loader />}
      {arrayForMap && !loadingStatistiche &&
        Array.isArray(arrayForMap) &&
        arrayForMap.map((graphData) => {
          const { realValue, day, month, year, value } = graphData
          return (
            <Tooltip title={realValue} key={`${year}${month < 10 ? '0'+month : month }${day < 10 ? '0'+day : day}`}>
              <div
                onMouseEnter={() => {
                  setDate(new Date(`${month}-${day}-${year}`))
                }}
                onClick={() => {
                  //console.log("month", month, day);
                  if (window.location.href.includes("movimenti")) {
                    const date = moment(
                      `${new Date(
                        `${month}-${day}-${year}`
                      )}`
                    ).format("YYYY-MM-DD")
                    getPayments("", date, date, 1, 25, activeSkinId , Filter.username,  Filter.service)
                    setFromDateToDate(date)
                  }
                }}
                key={`${realValue}${day}${month}`}
                className={`Graph--Element ${
                  parseInt(month) === date.getMonth() + 1 &&
                  parseInt(day) === date.getDate()
                    ? "active"
                    : ""
                }`}
                style={{
                  height: `${value === 0 ? 0 : (value / max) * 100}%`,
                }}
              ></div>
            </Tooltip>
          )
        })}
      <div className="Date">
        <div>
          <span>{date.getDate()}</span>
          <span>{moment(date).format("MMMM YYYY")}</span>
        </div>
        <div>
          <i className="fal fa-calendar-alt"></i>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  activeSkinId: state.main.activeSkinId,
  loadingStatistiche: state.auth.loadingStatistiche,
})
export default connect(mapStateToProps, AuthActions)(Graph)
