import { Icon, Input, Modal, notification } from "antd" // Import Modal here
import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import DisplayCategory from "../../shared-components/Category/DisplayCategory"
import AddCategoryForm from "../../shared-components/Category/AddCategory"
import axios from "axios"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import { endpoint } from "../../config/api"

const CategoryManagementNew = ({ accountInfo, getServicesTree }) => {
  const history = useHistory()
  const token = accountInfo?.token

  const [selectedId, setSelectedId] = useState(null)

  const showDeleteConfirm = (categoryId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this category?",
      content: `Category with ID: ${categoryId}`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(categoryId)
      },
      onCancel() {
        console.log("Cancelled deletion")
      },
    })
  }

  const handleEdit = (categoryId) => {
    setSelectedId(categoryId)
    console.log(`Edit category with ID: ${categoryId}`)
  }

  const handleDelete = (categoryId) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    }
    axios
      .delete(`${endpoint}/category/delete/${categoryId}`, {
        headers: headers,
      })
      .then(() => {
        notification.success({
          message: "Category deleted successfully!",
        })
        getServicesTree()
      })
      .catch((err) => console.log(err))
  }

  return (
    <div className="category-management">
      <div className="header">
        <div className="title">
          <i className="fal fa-arrow-left" onClick={() => history.goBack()} />
          <div className="title-name">
            <span>Categorie</span>
            <span>Categorie Prodotto</span>
          </div>
        </div>
        <div className="category-search">
          <Input
            placeholder="Cerca in questa pagina"
            suffix={<Icon type="search" />}
          />
        </div>
      </div>
      <div className="description">
        <span>
          Qui puoi gestire le categorie dei prodotti per il tuo negozio. Per
          modificarne l'ordine sul front-end, trascina e rilascia le categorie nella
          posizione desiderata. Per visualizzare un elenco più ampio di categorie,
          clicca sul link "Opzioni schermo" in alto a destra della pagina.
        </span>
      </div>
      <div className="content">
        <AddCategoryForm selectedId={selectedId} setSelectedId={setSelectedId} />
        <DisplayCategory handleEdit={handleEdit} handleDelete={showDeleteConfirm} />
      </div>
    </div>
  )
}

const mapsStateToProps = (state) => ({
  accountInfo: state.auth.accountInfo,
  servicesTree: state.main.servicesTree,
})

export default connect(mapsStateToProps, { ...MainActions, ...AuthActions })(
  CategoryManagementNew
)
