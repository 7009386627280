import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import { withRouter } from "react-router"
import { Button, Collapse } from "antd"
import CompanyCards from "../../../routes/domains/Dashboard/CompanyCards"

const { Panel } = Collapse

const NewServizi = ({
  getServicesTree,
  servicesTree,
  getCategoryServices,
  services,
  setCompanyServices,
  setSubCategories,
  subCategories,
}) => {
  const [expandedKeys, setExpandedKeys] = useState(["62"])
  const [activeSubCategory, setActiveSubCategory] = useState(null)

  const onPanelChange = (keys) => {
    const category = findCategoryById(keys[keys.length - 1], servicesTree)
    setExpandedKeys(keys[keys.length - 1])
    if (keys.length !== 0) {
      if (category.children_recursive.length === 0) {
        getCategoryServices(keys[keys.length - 1])
        setSubCategories(null)
      } else {
        setSubCategories(category.children_recursive)
      }
    } else {
      setCompanyServices([])
    }
  }

  const findCategoryById = (id, services) => {
    for (let service of services) {
      if (service.id == id) {
        return service
      }

      if (service.children_recursive && service.children_recursive.length > 0) {
        const foundInChildren = findCategoryById(id, service.children_recursive)
        if (foundInChildren) {
          return foundInChildren
        }
      }
    }

    return null
  }

  useEffect(() => {
    getServicesTree()
  }, [])

  useEffect(() => {
    if (expandedKeys.length > 0) {
      getCategoryServices(expandedKeys[0])
    }
  }, [])

  useEffect(() => {
    if (subCategories && subCategories.length > 0) {
      const firstSubCategory = subCategories[0]
      setActiveSubCategory(firstSubCategory.id)

      getCategoryServices(firstSubCategory.id)
    }
  }, [subCategories])

  const handleSubCategoryClick = (sub) => {
    setActiveSubCategory(sub.id)

    getCategoryServices(sub.id)
  }

  const renderChildren = (children) => {
    return children?.map((child) => (
      <Panel header={child.name} key={child.id}>
        {services ? (
          <>
            <div
              className="telefoniche-options"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 10,
                gap: 10,
              }}
            >
              {subCategories &&
                subCategories.length > 0 &&
                subCategories.map((sub) => (
                  <Button
                    key={sub.id}
                    onClick={() => handleSubCategoryClick(sub)}
                    className={activeSubCategory === sub.id ? "active" : ""}
                  >
                    {sub.name}
                  </Button>
                ))}
            </div>
            <div className="mobileServices--body">
              <CompanyCards companies={services} />
            </div>
          </>
        ) : (
          <p>No companies found for this category.</p>
        )}
      </Panel>
    ))
  }

  return (
    <Collapse
      defaultActiveKey={["1"]}
      onChange={onPanelChange}
      activeKey={expandedKeys}
    >
      {servicesTree?.map((service) => {
        if (service.parent_id === null && service.children_recursive.length > 0) {
          return renderChildren(service.children_recursive)
        }
        return null
      })}
    </Collapse>
  )
}

const mapsStateToProps = (state) => ({
  accountInfo: state.auth.accountInfo,
  servicesTree: state.main.servicesTree,
  services: state.main.services,
  subCategories: state.main.subCategories,
})

export default connect(mapsStateToProps, { ...MainActions, ...AuthActions })(
  withRouter(NewServizi)
)
