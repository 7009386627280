import React, { useEffect, useState } from "react"
import countriesArray from "config/countryArr"
import {connect} from "react-redux";

import "./detaggliPerLaSpedizione.css"

const DettagliPerLaSpedizione = (props) => {
  const [nome, setNome] = useState(props.nome || "")
  const [codiceFiscale, setCodiceFiscale] = useState(props.codiceFiscale || "")
  const [paese, setPaese] = useState(props.paese || "")
  const [email, setEmail] = useState(props.email || "")
  const [citta, setCitta] = useState(props.citta || "")
  const [cognome, setCognome] = useState(props.cognome || "")
  const [vila, setVila] = useState(props.vila || "")
  const [telefono, setTelefono] = useState(props.telefono || "")
  const [provincia, setProvincia] = useState(props.provincia || "")
  const [cap, setCap] = useState(props.cap || "")
  const [nomeSocieta, setNomeSocieta] = useState(props.nomeSocieta || "")
  const [check, setChecked] = useState(props.check || typeOfDetails[0])

  useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [])

  useEffect(() => {
    if (check === typeOfDetails[0] || check === typeOfDetails[2]) {
      const dataToGet =
        check === typeOfDetails[0]
          ? props.userData.user_data
          : props.userData.agency_data
      setCitta(dataToGet?.city || "")
      setCap(dataToGet?.postcode || "")
      setNome(dataToGet?.first_name || "")
      setCognome(dataToGet?.last_name || "")
      setVila(dataToGet?.address || "")
      setTelefono(dataToGet?.phone || "")
      setEmail(dataToGet?.email || "")
      setCodiceFiscale(dataToGet?.codice_fiscale || "")
      setPaese("ITALIA" || "")
      setProvincia(
        (
          countriesArray.find(
            (country) =>
              country.provincia.toLowerCase() === dataToGet?.city.toLowerCase()
          ) || {}
        ).nazione || ""
      )
    } else {
      setNome("")
      setCodiceFiscale("")
      setPaese("")
      setEmail("")
      setCitta("")
      setCognome("")
      setVila("")
      setTelefono("")
      setProvincia("")
      setCap("")
      setNomeSocieta("")
    }
  }, [check])

  const changeCheck = (index) => {
    setChecked(typeOfDetails[index])
  }

  const generateRightForm=()=>{
    return <React.Fragment>
      <CustomInput
                placeholder="Cognome "
                value={cognome}
                onChange={(cognome) => setCognome(cognome)}
              />
              <CustomInput
                placeholder="Nome della società (opzionale)"
                value={nomeSocieta}
                onChange={(nomeSocieta) => setNomeSocieta(nomeSocieta)}
              />
              <CustomInput
                placeholder="Via e numero"
                value={vila}
                onChange={(vila) => setVila(vila)}
              />
              <CustomInput
                placeholder="Telefono"
                value={telefono}
                onChange={(telefono) => setTelefono(telefono)}
              />
              <CustomInput
                placeholder="Provincia"
                value={provincia}
                onChange={(provincia) => setProvincia(provincia)}
              />
              <CustomInput
                placeholder="C.A.P."
                value={cap}
                onChange={(cap) => setCap(cap)}
              />
    </React.Fragment>
  }
  return (
    <div className="Modal-Spedizione">
      <div className="modalbackdrop" />
      <div className="Spedizione__content">
        <div className="Spedizione__content__header">
          <span>Dettagli PER LA SPEDIZIONE</span>
        </div>
        <div className="Spedizione__content__body">
          <div className="body__section-first">
            <Radio checked={check} setChecked={changeCheck} index={0} />
            <span>Spedire sede attuale</span>
          </div>
          <div className="body__section-second">
            <div className="section-second__left">
              <CustomInput
                placeholder="Nome"
                value={nome}
                onChange={(nome) => setNome(nome)}
              />
              <CustomInput
                placeholder="Codice Fiscale/P.Iva"
                value={codiceFiscale}
                onChange={(codiceFiscale) => setCodiceFiscale(codiceFiscale)}
              />
              <CustomInput
                placeholder="Paese/regione"
                value={paese}
                onChange={(paese) => setPaese(paese)}
              />
              <CustomInput
                placeholder="Indirizzo email"
                value={email}
                onChange={(email) => setEmail(email)}
              />
              <CustomInput
                placeholder="Città"
                value={citta}
                onChange={(citta) => setCitta(citta)}
              />
              {props.screenWidth < 785 && generateRightForm()}
            </div>
            {props.screenWidth >= 785 && <div className="section-second__right">
              {generateRightForm()}
            </div>}
          </div>
        </div>
        <div className="Spedizione__content__footer">
          <div className="footer__checkboxes">
            <div>
              <Radio checked={check} setChecked={changeCheck} index={1} />
              <span>Spedire ad un indirizzo differente?</span>
            </div>
            <div>
              <Radio checked={check} setChecked={changeCheck} index={2} />
              <span>Ritiro presso il punto vendita</span>
            </div>
          </div>
          <div className="footer__buttons">
            <button
              onClick={() => {
                props.setModal(false)
              }}
            >
              Close
            </button>
            <button
              onClick={() => {
                props.setNome(nome)
                props.setCodiceFiscale(codiceFiscale)
                props.setPaese(paese)
                props.setEmail(email)
                props.setCitta(citta)
                props.setCognome(cognome)
                props.setVila(vila)
                props.setProvincia(provincia)
                props.setChecked(check)
                props.setNomeSocieta(nomeSocieta)
                props.setCap(cap)
                props.setTelefono(telefono)
                props.setModal(false)
              }}
            >
              SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const CustomInput = ({ onChange, ...otherProps }) => {
  return (
    <div className={`section__input-wrapper ${otherProps.placeholder}`}>
      <input {...otherProps} onChange={(e) => onChange(e.target.value)} />
    </div>
  )
}

const Radio = ({ checked, setChecked, index }) => {
  return (
    <div
      className={`radio ${checked === typeOfDetails[index] ? "is-checked" : ""}`}
      onClick={() => setChecked(index)}
    />
  )
}

export const typeOfDetails = ["attuale", "differente", "punto_vendita"]

const mapStateToProps=(state)=>({
  screenWidth: state.main.screenWidth,
})

export default connect(mapStateToProps)(DettagliPerLaSpedizione);
