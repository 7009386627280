import React from "react";
import {connect} from "react-redux";
import { openChat } from "shared-components/Chat/Chat";
const InviaPrenotationButton = ({ handleClick, color, title, screenWidth }) => {
  const openChatOnClick = () => openChat(screenWidth);

  return (
    <div className="formsContainer--body__item submit">
      <button style={{ backgroundColor: color }} onClick={() => handleClick(openChatOnClick)}>
        {title ? title : "Invia"}
      </button>
    </div>
  );
};

const mapsStateToProps = (state) => ({
  screenWidth: state.main.screenWidth,
});

export default connect(mapsStateToProps)(InviaPrenotationButton);
