import React, { useState } from "react"
import { Button, Divider, Form, message, Steps } from "antd"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import moment from "moment"
import { getCopy } from "utils"
import { AuthActions, MainActions } from "redux-store/models"
import { InlineShareButtons } from "sharethis-reactjs"
import { useHistory } from "react-router-dom"
import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"
import "./NewRegisterAgency.css"

const { Step } = Steps

const NewRegisterAgency = (props) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [formData, setFormData] = useState({
    codice_fiscale: "",
    gender: "F",
    nazione: "ITALIA",
    card_view: "1",
    first_name: "",
    last_name: "",
  })
  const [shareIcons, setShareIcons] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedRegisterType, setSelectedRegisterType] = useState("")
  const history = useHistory()

  const { form, getRegister, match, accountInfo } = props

  const accountId = accountInfo?.profile?.account_id
  const url =
    selectedRegisterType === "complete"
      ? `${window.location.href}t/${accountId}`
      : `${window.location.origin}/#/register-user-token/${accountId}`

  const handleNext = () => {
    form
      .validateFields()
      .then((values) => {
        setFormData((prevData) => ({
          ...prevData,
          ...values,
        }))
        setCurrentStep(currentStep + 1)
      })
      .catch(() => {
        message.error("Compilare tutti i campi richiesti.")
      })
  }

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (props.activeSkinId === -1) {
          message.error("Per favore, seleziona una skin.")
          return
        }

        const finalData = {
          ...formData,
          ...values,
        }

        setFormData(finalData)
        console.log("Submitted values:", finalData)
        getRegister(
          finalData.first_name,
          finalData.last_name,
          finalData.nickname,
          finalData.email,
          finalData.gender,
          finalData.codice_fiscale,
          moment(finalData.birthday).format("YYYY-MM-DD"),
          finalData.nazione,
          finalData.province_of_birth.value,
          finalData.city_of_birth.value,
          finalData.nazioneDiResidenca,
          finalData.residence_province,
          finalData.residence_city,
          finalData.address,
          finalData.cap,
          finalData.identity_id,
          finalData.identity_type,
          finalData.number_prefix || "+39",
          finalData.number,
          finalData.imageUrl,
          finalData.imageUrl2,
          "agency",
          finalData.aRagSoc,
          finalData.aInsegna,
          finalData.aPhone,
          finalData.aAdress,
          finalData.agency_comune,
          finalData.agency_province,
          finalData.aCap,
          finalData.aPiva,
          finalData.aFcode,
          finalData.confirm_password,
          finalData.password,
          finalData.rilasciato_da,
          finalData.luogo_di_rilascio,
          moment(finalData.data_di_rilascio).format("YYYY-MM-DD"),
          moment(finalData.data_di_scadenza).format("YYYY-MM-DD"),
          finalData.cordinateReq,
          finalData.agency_nazione,
          finalData.pagmens,
          finalData.privacy_policy,
          finalData.recieve_emails,
          null,
          "",
          finalData.wallet,
          finalData.tipo_di_mensile,
          props.activeSkinId,
          props.match.params.token,
          accountId || "",
          setLoading,
          form.resetFields
        )
      })
      .catch(() => {
        message.error("Compilare tutti i campi richiesti.")
      })
  }

  const handleStepChange = (nextStep) => {
    if (nextStep > currentStep) {
      form
        .validateFields()
        .then((values) => {
          setFormData((prevData) => ({
            ...prevData,
            ...values,
          }))
          setCurrentStep(nextStep)
        })
        .catch(() => {
          message.error("Compilare tutti i campi richiesti.")
        })
    } else {
      setCurrentStep(nextStep)
    }
  }

  return (
    <div className="new-register-agency ">
      <div
        style={{
          marginTop: 20,
          maxWidth: "530px",
          margin: "30px auto",
          cursor: "pointer",
          fontSize: 24,
          display: "flex",
          alignItems: "center",
          gap: 10,
        }}
      >
        <i
          className="fal fa-arrow-left"
          onClick={() => history.goBack()}
          style={{
            padding: 12,
            border: "1px solid #dde2e8",
            borderRadius: 10,
            cursor: "pointer",
            fontSize: 14,
          }}
        />
        Registra Agenzia
      </div>
      <div
        style={{
          marginTop: 20,
          maxWidth: "530px",
          margin: "30px auto",
          marginBottom: 0,
          cursor: "pointer",
        }}
      >
        <Steps
          current={currentStep}
          className="regUser-step"
          style={{ color: "var(--accent-bg)" }}
        >
          <Step
            title={<span className="step-title">Informazioni personali</span>}
            onClick={() => handleStepChange(0)}
          />
          <Step title="Dati agenzia" onClick={() => handleStepChange(1)} />
          <Step title="Dati login" onClick={() => handleStepChange(2)} />
        </Steps>
      </div>
      <Divider className="reg-divider" />

      <Form
        layout="vertical"
        className="newRegistration newReg"
        style={{ marginTop: 20, maxWidth: "530px" }}
      >
        {/* Step 1: Personal Information */}
        {currentStep === 0 && (
          <Step1 form={form} formData={formData} setFormData={setFormData} />
        )}

        {/* Step 2: Birth and Residence */}
        {currentStep === 1 && (
          <Step2 form={form} formData={formData} setFormData={setFormData} />
        )}

        {/* Step 3: Document Information */}
        {currentStep === 2 && (
          <Step3 form={form} setFormData={setFormData} formData={formData} />
        )}

        <div style={{ marginTop: 20, marginBottom: 20 }}>
          {currentStep === 0 &&
            (match.params.token ||
            accountInfo?.profile?.role?.name === "main_admin" ? (
              <Button type="primary" onClick={handleNext} block>
                Procedi
              </Button>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  type="primary"
                  style={{ height: "40px", width: "150px", marginRight: 10 }}
                  onClick={handleNext}
                  block
                >
                  Procedi
                </Button>

                {!match.params.token &&
                  accountInfo?.profile?.role?.name !== "main_admin" && (
                    <div className="shareRegister">
                      <div
                        onClick={() => {
                          setShareIcons(!shareIcons)
                          setSelectedRegisterType("")
                        }}
                      >
                        <i className="fal fa-share-nodes" aria-hidden="true"></i>
                        Condividere
                      </div>
                      {shareIcons && (
                        <div
                          className="overlay"
                          onClick={() => setShareIcons(false)}
                        >
                          <div
                            className="options-btns"
                            onClick={(e) => e.stopPropagation()}
                          >
                            {selectedRegisterType ? (
                              <>
                                <i
                                  className="fal fa-copy"
                                  onClick={() => getCopy(url)}
                                ></i>

                                <InlineShareButtons
                                  config={{
                                    alignment: "center",
                                    color: "social",
                                    enabled: true,
                                    font_size: 16,
                                    language: "en",
                                    url: url,
                                    networks: ["whatsapp", "telegram", "email"],
                                    padding: 12,
                                    radius: 4,
                                    show_total: true,
                                    size: 40,
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <Button
                                  type="primary"
                                  className="simple-reg-btn"
                                  onClick={() => setSelectedRegisterType("simple")}
                                >
                                  Registrazione Semplice
                                </Button>
                                <Button
                                  type="primary"
                                  className="simple-reg-btn"
                                  onClick={() => setSelectedRegisterType("complete")}
                                >
                                  Registrazione Completa
                                </Button>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
              </div>
            ))}

          {currentStep === 1 && (
            <Button type="primary" onClick={handleNext} block>
              Procedi
            </Button>
          )}
          {currentStep === 2 && (
            <Button type="primary" onClick={handleSubmit} block loading={loading}>
              Registrati
            </Button>
          )}
        </div>
      </Form>
    </div>
  )
}

const AgencyUser = Form.create({ name: "AgencyUser" })(NewRegisterAgency)

const mapsStateToProps = ({ auth, main }) => ({
  register: auth.register,
  accountInfo: auth.accountInfo,
  activeSkinId: main.activeSkinId,
})

export default withRouter(
  connect(mapsStateToProps, { ...AuthActions, ...MainActions })(AgencyUser)
)
