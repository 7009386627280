import React, { useEffect } from "react"
import images from "themes/images"
import { baseUrlImg } from "../../../config/api"
import { MainActions } from "redux-store/models"
import { connect } from "react-redux"

const AdminWallet = ({ accountInfo, getProviders, providers }) => {
  useEffect(() => {
    getProviders()
  }, [getProviders])

  if (accountInfo?.profile?.role?.name === "main_admin") {
    return (
      <div className="admin-wallets">
        <div className="admin-wallets-carousel">
          {providers.map((provider, i) => {
            return (
              <div key={i}>
                <img
                  src={`${baseUrlImg}/logo/${provider?.name?.toLowerCase()}.svg`}
                  onError={(e) => {
                    e.target.onerror = null
                    e.target.src = `${baseUrlImg}/icon/${provider?.name?.toLowerCase()}-icon.svg` // Fallback to icon
                  }}
                  alt={`${provider?.name} logo`}
                />

                {/* <span>{wallet} </span> */}
                <span>&euro; {provider.wallet}</span>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
  return null
}

const mapStateToProps = (state) => ({
  screenWidth: state.main.screenWidth,
  providers: state.main.providers,
})

export default connect(mapStateToProps, { ...MainActions })(AdminWallet)
