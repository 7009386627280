import React from "react"
import images from "themes/images"

const Actions = (props) => {
  const {
    bolletiniLoading,
    helper,
    service_id,
    isSepafin,
    toggleScanner,
    cancel,
    submit,
    isPrenotaBolletino,
  } = props
  const servicesCanScan = ["SBOL001"]
  const submitTitle =
    ["SBOL001", "SBOL002"].includes(service_id) || !service_id || isPrenotaBolletino
      ? "Prenota"
      : "Esegui"
  const canScan =
    !isSepafin ||
    !service_id ||
    isPrenotaBolletino ||
    servicesCanScan.includes(service_id)

  return (
    <div className={`Actions ${!canScan ? "two" : ""}`}>
      {canScan && (
        <div className="Actions--Item" onClick={toggleScanner}>
          <h3>Scan</h3>
          <i className="fal fa-scanner"></i>
        </div>
      )}

      {submit && (
        <div
          className="Actions--Item"
          data-disable={bolletiniLoading ? "disable" : "enable"}
          onClick={submit}
          htmltype="submit"
        >
          <h3>
            {isSepafin || !service_id || isPrenotaBolletino ? submitTitle : "esegui"}
          </h3>
          <img src={images.checkSymbol} alt="" />
        </div>
      )}

      {helper && <div className="helper" />}
      <div className="Actions--Item" onClick={cancel}>
        <h3>Annulla</h3>
        <img src={images.close} alt="" />
      </div>
    </div>
  )
}

export default Actions
