import React from "react";

const RadioInput = (props) => {
  const { name, color, activeColor, borderColor, isActive, onClick, disabled, className } = props;
  const colorIfDisabled = (defaultColor) => (disabled ? color : defaultColor);
  const inputColor = colorIfDisabled(isActive ? activeColor : color);

  return (
    <div
      className={`isHovered${colorIfDisabled(activeColor)} radio__group__input ${disabled ? "disabled" : ""} ${
        isActive ? "is-Active" : ""
      } ${className}`}
      onClick={onClick}
    >
      <span className={`input__circle ${isActive ? `color${activeColor}` : `color${colorIfDisabled(borderColor)}`}`}>
        <span className={`input__circle__filled backgroundColor${isActive ? activeColor : "Transparent"}`} />
      </span>
      <span className={`color${inputColor} ${isActive ? "is-font-weight-500" : ""}`}>{name}</span>
    </div>
  );
};

export default RadioInput;