import React from "react"

const CompaniesSearch = ({ onChange }) => {
  return (
    <div className="Search">
      <input type="text" placeholder="Cerca" onChange={onChange} />
      <i className="fal fa-search" />
    </div>
  )
}

export default CompaniesSearch
