import React, { Fragment, useState } from "react"
import ReturnFormItem from "./FormHelperComp"
import { Checkbox, Select } from "antd"
import BollettinoPrivacyLinks from "./Bolletino/BollettinoPrivacyLinks"

export const BoloAutoLeftForm = ({ barcodeData, getFieldDecorator }) => {
  const [showPDF, setShowPDF] = useState(false)

  const handleCheckboxChange = (e) => {
    setShowPDF(e.target.checked)
  }

  return (
    <Fragment>
      <ReturnFormItem
        descName="Tipo Veicolo"
        name="tipo_veicolo"
        message="Please input your tipo veicolo!"
        barcodeData={barcodeData}
        getFieldDecorator={getFieldDecorator}
        defaultValue={"1"}
        NotInput={
          <Select placeholder="Tipo bollettino">
            <Select.Option value={"1"}>Auto</Select.Option>
            <Select.Option value={"4"}>Motociclo</Select.Option>
          </Select>
        }
      />
      <ReturnFormItem
        descName="Targa"
        name="targa"
        message="Inserisci il tuo Targa!"
        barcodeData={barcodeData}
        getFieldDecorator={getFieldDecorator}
      />
     <BollettinoPrivacyLinks/>
    </Fragment>
  )
}
