import React from "react"
import AdminPanelDom from "../domains/adminPanel/adminPanelDom"
import { connect } from "react-redux"
import NewRegisterAgency from "../../shared-components/adminSharedComp/NewRegisterAgency/NewRegisterAgency"
class SuperAdminRegistraAgenzia extends React.Component {
  render() {
    return <AdminPanelDom component={() => <NewRegisterAgency />} {...this.props} />
  }
}
const mapStatToProps = (state) => ({
  activeSkinId: state.main.activeSkinId,
})
export default connect(mapStatToProps, null)(SuperAdminRegistraAgenzia)
