import React from "react"
import { connect } from "react-redux"
import AuthActions from "redux-store/models/auth"
import CommonBolletinoWrapper from "./CommonBolletinoWrapper"
import {
  SepafinMavRavLeftForm,
  SepafinMavRavRightForm,
} from "shared-components/ModulePopUp/MavRavForm"
import {
  EPayMavRavLeftForm,
  EPayMavRavRightForm,
  MrPayMavRavLeftForm,
  MrPayMavRavRightForm,
} from "../../../../shared-components/ModulePopUp/MavRavForm"

const MavRavForm = ({
  setService,
  activeService,
  setMavRav,
  allFavServices,
  getBolletiniPremercati,
}) => {
  const commonBolletinoProps = {
    title: "MAV/RAV",
    favService: "BOLMR",
  }
  const accountData = JSON.parse(localStorage.getItem("accountDataB")) || {}
  const isEPay = accountData?.profile?.supplier === "E-Pay"

  return (
    <CommonBolletinoWrapper
      submit={[setMavRav, getBolletiniPremercati]}
      {...{ commonBolletinoProps, activeService, setService, allFavServices }}
    >
      {(form, barcode, services, isSepafin) => {
        const { getFieldDecorator, getFieldValue } = form
        return (
          <React.Fragment>
            {isSepafin ? (
              <SepafinMavRavLeftForm
                mobile
                barcodeData={barcode}
                setServiceID={setService}
                service_s={services["SPRDPST"]["SMAVRAV"]}
                {...{ getFieldDecorator, getFieldValue }}
              />
            ) : isEPay ? (
              React.cloneElement(
                <EPayMavRavLeftForm
                  mobile
                  barcodeData={barcode}
                  setServiceID={setService}
                  service_s={services.PRDPST.BOLMR}
                  {...{ getFieldDecorator, getFieldValue }}
                />
              )
            ) : (
              React.cloneElement(
                <MrPayMavRavLeftForm
                  mobile
                  barcodeData={barcode}
                  setServiceID={setService}
                  // service_s={services.PRDPST.BOLMR}
                  {...{ getFieldDecorator, getFieldValue }}
                />
              )
            )}
            {isSepafin ? (
              <SepafinMavRavRightForm
                barcodeData={barcode}
                {...{ getFieldDecorator, getFieldValue }}
              />
            ) : isEPay ? (
              <EPayMavRavRightForm   barcodeData={barcode}
              {...{ getFieldDecorator, getFieldValue }}/>
            ) : (
              <MrPayMavRavRightForm
                barcodeData={barcode}
                {...{ getFieldDecorator, getFieldValue }}
              />
            )}
          </React.Fragment>
        )
      }}
    </CommonBolletinoWrapper>
  )
}

export default connect(null, AuthActions)(MavRavForm)
