import React from "react";
import AdminPanelDom from "../domains/adminPanel/adminPanelDom";
import AdminConfigura from "./AdminConfigura";


class AdminConfiguraPanel extends React.Component {
  render() {
    return (
      <AdminPanelDom
     component={()=>
        <AdminConfigura />
        }
        {...this.props}
      />
    );
  }
}

export default AdminConfiguraPanel;