import {
  Button,
  Modal,
  Form,
  Upload,
  Select,
  DatePicker,
  Row,
  Col,
  Icon,
} from "antd"
import React from "react"
import "./AddServiceModal.css"

const { Option } = Select

const AddServiceModal = ({ isVisible = true, onClose, form }) => {
  const { getFieldDecorator, validateFields } = form

  const handleSubmit = () => {
    validateFields((err, values) => {
      if (!err) {
        console.log("Form values:", values)
      }
    })
  }

  return (
    <Modal
      className="add-service-modal"
      title="Importa File per Topup"
      visible={isVisible}
      onCancel={onClose}
      width={500}
      footer={
        <Button type="primary" block onClick={handleSubmit}>
          Importa e genera topup
        </Button>
      }
    >
      <Form layout="vertical" className="add-service-form">
        <Form.Item label="Scegli file">
          {getFieldDecorator("file", {
            rules: [{ required: true, message: "Per favore seleziona un file!" }],
          })(
            <Upload beforeUpload={() => false}>
              <Button>
                <Icon type="upload" /> Scegli file
              </Button>
            </Upload>
          )}
        </Form.Item>

        <Form.Item label="Tipo Ricarica">
          {getFieldDecorator("tipoRicarica", {
            rules: [
              {
                required: true,
                message: "Per favore seleziona un tipo di ricarica!",
              },
            ],
          })(
            <Select placeholder="Seleziona un tipo di ricarica">
              <Option value="ricarica1">Ricarica 1</Option>
              <Option value="ricarica2">Ricarica 2</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Categoria">
          {getFieldDecorator("categoria", {
            rules: [
              { required: true, message: "Per favore seleziona una categoria!" },
            ],
          })(
            <Select placeholder="Seleziona una categoria">
              <Option value="categoria1">Categoria 1</Option>
              <Option value="categoria2">Categoria 2</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Validità in giorni e/o Data di Scadenza">
          {getFieldDecorator("validita", {
            rules: [
              {
                required: true,
                message: "Per favore inserisci una data di scadenza!",
              },
            ],
          })(
            <DatePicker placeholder="Seleziona una data" style={{ width: "100%" }} />
          )}
        </Form.Item>

        <Form.Item>
          <Button type="primary" icon="setting" className="configura-btn">
            Configura Mappatura
          </Button>
        </Form.Item>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Taglio (in €)">
              {getFieldDecorator("taglio", {
                rules: [
                  { required: true, message: "Per favore seleziona il taglio!" },
                ],
              })(
                <Select placeholder="Seleziona un taglio">
                  <Option value="10">€10</Option>
                  <Option value="20">€20</Option>
                  <Option value="50">€50</Option>
                  <Option value="100">€100</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Codice Ricarica">
              {getFieldDecorator("codiceRicarica", {
                rules: [
                  {
                    required: true,
                    message: "Per favore seleziona un codice ricarica!",
                  },
                ],
              })(
                <Select placeholder="Seleziona un codice ricarica">
                  <Option value="codice1">Codice 1</Option>
                  <Option value="codice2">Codice 2</Option>
                  <Option value="codice3">Codice 3</Option>
                  <Option value="codice4">Codice 4</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const AddServiceModalForm = Form.create()(AddServiceModal)
export default AddServiceModalForm
