import React from "react"

const ConfirmationModal = ({ cost, onConfirm, onCancel, messageVisible }) => {
  if (!messageVisible) return null

  return (
    <div className="confermaMsg animated bounce">
      <div className="info">
        Stai eseguendo una ricarica da €{cost}. <br /> Confermi?
      </div>
      <div className="buttons">
        <button onClick={onConfirm}>Si</button>
        <button onClick={onCancel}>No</button>
      </div>
    </div>
  )
}

export default ConfirmationModal
