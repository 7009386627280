import React from "react"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import { Modal } from "antd"
import { Document, Page } from "react-pdf"
import { b64toBlob } from "utils"
import { capitalize } from "lodash"
import PrintTicketPin from "../Tickets/PrintTicketPin"
import PrintTicketTopUp from "../Tickets/PrintTicketTopUp"
import PrintTicketPaysafe from "../Tickets/PrintTicketPaysafe"
import PrintTicketGiftCard from "../Tickets/PrintTicketGiftCard"
import PrintTicketBolletino from "../Tickets/PrintTicketBolletino"
import PrintTicketSpedizioni from "../Tickets/PrintTicketSpedizioni"
import CreateGiftCardTicket from "../Tickets/CreateGiftCardTicket"
import PrintTicketVisure from "../Tickets/PrintTicketVisure"
import BetFlagDirectTicket from "../Tickets/BetFlagDirectTicket"
import TicketLayout from "../Tickets/TicketLayout"

class PrintTicket extends React.Component {
  printPdfReceipt = (data, type) => {
    if (data.receipt_type === "base64") {
      var myBlob = b64toBlob(data.receipt, "application/pdf")
      var blobUrl = URL.createObjectURL(myBlob)
      if (type === "print") {
        window
          .open(
            blobUrl,
            "_blank",
            "toolbar=no,scrollbars=no,resizable=no,top=50,left=500,width=700,height=700"
          )
          .print()
      }
      if (type === "download") {
        const linkSource = `data:application/pdf;base64,${data.receipt}`
        const downloadLink = document.createElement("a")
        const fileName = "Ticket.pdf"

        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
      }
      if (type === "return") {
        return blobUrl
      }
    }
  }

  returnCoffeeStringHtml = (coffeDetails) => {
    let htmlTemplate = ""
    const unAllowedValues = ["created_at", "updated_at", "id", "barcode"]
    Object.keys(coffeDetails).forEach((coffeDetailKey) => {
      if (!unAllowedValues.includes(coffeDetailKey)) {
        htmlTemplate += `<br/>${coffeDetailKey
          .split("_")
          .map((word) => capitalize(word))
          .join(" ")} <div class='marginB'></div><div class='marginC'>${
          coffeDetails[coffeDetailKey]
        }</div><br/>`
      }
    })
    return htmlTemplate
  }

  closeModal = () => {
    this.props.setPaymentsFromCode({})
    this.props.setPrint && this.props.setPrint(false)
    this.props.setBolletiniBianchi([])
    this.props.setShippingOrder({})
    this.props.setRechargeMobile([])
    this.props.togglePopUp(false)
  }

  renderBarcode = (barcode) => (
    <img
      className="barcodeModal"
      src={`https://barcode.tec-it.com/barcode.ashx?data=${
        barcode || 0
      }&code=Code128&multiplebarcodes=false&translate-esc=false&unit=Fit&dpi=96&imagetype=Gif&rotation=0&color=%23000000&bgcolor=%23ffffff&qunit=Mm&quiet=0`}
      alt="Barcode"
    />
  )

  renderQrCode = (barcode) => (
    <img
      className="qrCodeImg"
      src={`https://api.qrserver.com/v1/create-qr-code/?data=https%3A%2F%2F${window.location.host}%2F%23%2Fqr%2F${barcode}&size=420x420&margin=10`}
      alt="QR Code"
    />
  )

  renderFallbackTicket = (arr) => (
    <div
      dangerouslySetInnerHTML={{
        __html: this.getFormattedReceiptHtml(arr),
      }}
    />
  )

  getFormattedReceiptHtml = (arr) => {
    return (
      arr &&
      arr.receipt &&
      arr.receipt
        .replace(/\t/g, "\u00a0")
        .replace(/\n/g, "<br/> ")
        .replace(/\+/g, " ")
        .replace(/: /g, ":<div class='marginB'></div>")
        .replace(
          /<div class='marginB'><\/div>([^>]+)<br\/>/g,
          "<div class='marginB'></div><div class='marginC'>$1</div><br/>"
        )
        .replace(
          /https:\/\/web.my-gate.eu\/v\?c=\w+/g,
          "<a href='$&' target='_blank'>$&</a>"
        )
        .replace(
          /(<img src="[^"]+"[^>]*>)/g,
          '<div style="display: flex; flex-direction: column; align-items: center;"><div>Scansiona il Qr code</div>$1</div>'
        ) + (arr.caffeDetails ? this.returnCoffeeStringHtml(arr.caffeDetails) : "")
    )
  }

  renderPdfReceipt = (arr) => {
    const blobUrl = this.printPdfReceipt(arr, "return")
    return (
      <>
        <Document renderMode="canvas" file={blobUrl}>
          <Page width={380} pageNumber={1} />
        </Document>
        <div className="printBtn" onClick={() => this.printPdfReceipt(arr, "print")}>
          Print Ticket
        </div>
        <div
          className="printBtn"
          onClick={() => this.printPdfReceipt(arr, "download")}
        >
          Download Ticket
        </div>
      </>
    )
  }

  generateTicketBody = (barcode, scontrino) => {
    const { arr } = this.props

    const isVisure = ["JSON", "json_bitnovo"].includes(arr.receipt_type)

    const ticketMapping = {
      base64: this.renderPdfReceipt(arr),
      betflag: <BetFlagDirectTicket arr={arr} />,
      json_spedizionepro: <PrintTicketSpedizioni arr={arr} />,
      PIN: arr?.paysafe ? (
        <PrintTicketPaysafe arr={arr} barcode={barcode} />
      ) : (
        <PrintTicketPin arr={arr} barcode={barcode} />
      ),
      TOPUP: <PrintTicketTopUp arr={arr} barcode={barcode} />,
      EPay: <PrintTicketTopUp arr={arr} barcode={barcode} />,
      MrPayCard: <CreateGiftCardTicket arr={arr} />,
    }

    if (arr.type && arr.type.includes("Bolletino")) {
      ticketMapping["Bolletino"] = <PrintTicketTopUp arr={arr} />
    }

    let ticketComponent = null

    if (arr.receipt_type === "base64") {
      ticketComponent = ticketMapping["base64"]
    } else {
      ticketComponent =
        ticketMapping[arr.type] ||
        ticketMapping[arr.service_type] ||
        ticketMapping[arr.receipt_type] ||
        ticketMapping["Bolletino"] ||
        this.renderFallbackTicket(arr)
    }

    if (isVisure) {
      ticketComponent = <PrintTicketVisure arr={arr} />
    }

    return (
      <TicketLayout arr={arr}>
        {ticketComponent}

        <div
          style={{ marginTop: 20 }}
          dangerouslySetInnerHTML={{
            __html: scontrino ? scontrino : arr.content_scontrino,
          }}
        />

        <div className="barcode-info">
          {isVisure && (
            <p>
              Se non hai dato una mail per scaricare il pdf puoi usare la ricerca
              dello scontrino su{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${window.location.origin}/#/transazioni/qrcodereader`}
              >
                {`${window.location.host}/#/transazioni/qrcodereader`}
              </a>{" "}
            </p>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {/* Barcode */}
            {!(
              arr.type === "betflag-direct" ||
              arr.message === "Reserve processed successfully"
            ) && this.renderBarcode(barcode)}

            {/* QR Code */}
            {!(
              arr.receipt_type === "json_spedizionepro" ||
              arr.type === "betflag-direct" ||
              arr.message === "Reserve processed successfully"
            ) && this.renderQrCode(barcode)}
          </div>
        </div>
      </TicketLayout>
    )
  }

  componentDidUpdate(prevProps) {
    if (prevProps.print !== this.props.print) {
      document.querySelector(".printBtn").click()
    }
  }

  render() {
    const { arr, onlyForPrint, scontrino } = this.props
    const barcode = Array.isArray(arr) ? arr[0]?.barcode : arr?.barcode || 1
    const receipt = Array.isArray(arr) ? arr[0]?.receipt : arr?.receipt

    return (
      !!(barcode && receipt) &&
      (onlyForPrint ? (
        <div style={{ opacity: 0, visibility: "hidden", width: 0, height: 0 }}>
          {this.generateTicketBody(barcode, scontrino)}
        </div>
      ) : (
        <Modal
          width={arr.service_type === "gift_cards" ? 494 : 494}
          title={null}
          visible={
            this.props.screenWidth > 1320 && this.props.forAdmin ? false : true
          }
          onCancel={this.closeModal}
          footer={null}
        >
          {this.generateTicketBody(barcode, scontrino)}
        </Modal>
      ))
    )
  }
}

const mapsStateToProps = (state) => ({
  screenWidth: state.main.screenWidth,
})

export default connect(mapsStateToProps, { ...MainActions, ...AuthActions })(
  PrintTicket
)
