export const graphData = [
  { height: 50, price: "235 $" },
  { height: 10, price: "235 $" },
  { height: 90, price: "235 $" },
  { height: 35, price: "235 $" },
  { height: 20 },
  { height: 70 },
  { height: 50 },
  { height: 40 },
  { height: 20 },
  { height: 10 },
  { height: 40 },
  { height: 20 },
  { height: 40, price: "235 $" },
  { height: 30 },
  { height: 40 },
  { height: 60 },
  { height: 90 },
  { height: 40 },
  { height: 90 },
  { height: 40 },
  { height: 40 },
  { height: 40 },
  { height: 40 },
  { height: 100 },
  { height: 40 },
  { height: 40 },
  { height: 10 },
  { height: 40 },
  { height: 20 },
  { height: 40 },
  { height: 70 },
  { height: 40 },
]
export const Tranzacioni = "0"
export const Commisione = "0"
export const Proviggioni = "0"
export const Aggio = "0"
