import React, { Component } from "react";
import { notification, Checkbox } from "antd";
import images from "themes/images";
import { connect } from "react-redux";
import { AuthActions, MainActions } from "redux-store/models";
import { Item } from "./FormsComponents";

import InviaPrenotationButton from "shared-components/InviaPrenotationButton/InviaPrenotationButton";

const CheckboxGroup = Checkbox.Group;
class Enagic extends Component {
  state = {
    nome: "",
    cognome: "",
    email: "",
    phone: "",
    extra_data: "",
    citta: "",
    options: [
      "Accetto l'Informativa Privacy.*",
      "Acconsento al trattamento dei dati personali a fini di marketing diretto e di",
    ],
    checkedList: [],
    indeterminate: true,
    checkAll: false,
  };
  resetState = (msg) => {
    if (!msg.error) {
      this.setState({
        nome: "",
        cognome: "",
        email: "",
        phone: "",
        extra_data: "",
        citta: "",
      });
      notification["success"]({
        message: "Azione completata",
        description: msg.msg,
        placement: "bottomRight",
      });
    } else {
      notification["error"]({
        message: msg.msg[0],
        description: msg.msg[1],
        placement: "bottomRight",
        duration: 5,
      });
    }
  };
  onChange = (list) => {
    const { options } = this.state;
    this.setState({
      checkedList: list,
      indeterminate: !!list.length && list.length < options.length,
      checkAll: list.length === options.length,
    });
  };

  onCheckAllChange = (e) => {
    const { options } = this.state;
    this.setState({
      checkedList: e.target.checked ? options : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };

  submitData = (onSuccess = () => {}) => {
    const { nome, cognome, email, phone, extra_data, citta } = this.state;
    this.props.buyTicketOnline(
      11,
      "",
      "enagic",
      extra_data,
      "",
      2,
      nome,
      cognome,
      email,
      phone,
      "",
      citta,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      (msg) => {
        this.resetState(msg);
        onSuccess();
      }
    );
  };
  render() {
    const {
      nome,
      cognome,
      email,
      phone,
      extra_data,
      citta,
      options,
      indeterminate,
      checkAll,
      checkedList,
    } = this.state;
    const { nome_agenzia, color, goBack, isMobile } = this.props;
    return (
      <div
        className={`formsContainer--body animated fadeIn OnlineShop${
          isMobile ? "M" : ""
        }`}
      >
        {!isMobile && (
          <div className="leftForm">
            <img src={images[`${nome_agenzia}-bg`]} alt="" className="imgBg" />
            <img
              src={images[`${nome_agenzia}-logo`]}
              alt=""
              className="imgLogo"
            />
          </div>
        )}

        <div className="rightFormOnlShop">
          <div className="rightForm--header">
            {!isMobile && (
              <div className="TitleBack" style={{ color: color }}>
                <i className="fal fa-chevron-left Arrow" onClick={goBack}></i>
                Enagic
              </div>
            )}
            {isMobile && (
              <div className="TitleBack">
                {" "}
                <i className="fal fa-faucet-drip"></i> {nome_agenzia}{" "}
              </div>
            )}

            <img
              className="img-enagic"
              src={images[`${nome_agenzia}-logo`]}
              alt={[`${nome_agenzia}-logo`]}
            />
          </div>
          {/* {isMobile && <div className="AfterTitleBack">Enagic</div>} */}

          <div className="MainForm">
            <div className="MainForm--left">
              <div
                className={`formsContainer--body__item ${isMobile ? "M" : ""}`}
              >
                <div className="label">
                  Nome <span className="red">*</span>
                </div>
                <input
                  value={nome || ""}
                  onChange={(e) => {
                    this.setState({ nome: e.target.value });
                  }}
                  placeholder="Nome"
                  type="text"
                />
              </div>
              <div
                className={`formsContainer--body__item ${isMobile ? "M" : ""}`}
              >
                <div className="label">
                  Cognome <span className="red">*</span>
                </div>
                <input
                  value={cognome || ""}
                  onChange={(e) => {
                    this.setState({ cognome: e.target.value });
                  }}
                  placeholder="Cognome"
                  type="text"
                />
              </div>
              <Item
                label="Email"
                value={email}
                handleChange={(e) => {
                  this.setState({ email: e });
                }}
                placeholder="Email"
              />
              <Item
                label="Telefono"
                value={phone}
                handleChange={(e) => {
                  this.setState({ phone: e });
                }}
                placeholder="Telefono"
              />
              <Item
                label="Citta"
                value={citta}
                handleChange={(e) => {
                  this.setState({ citta: e });
                }}
                placeholder="Citta"
              />
              <div className="formsContainer--body__item ">
                <div className="label checkbox">
                  Selezione Termini e Condizioni <span className="Red">*</span>{" "}
                  <Checkbox
                    indeterminate={indeterminate}
                    onChange={this.onCheckAllChange}
                    checked={checkAll}
                  >
                    Seleziona Tutti
                  </Checkbox>
                </div>

                <CheckboxGroup
                  options={options}
                  value={checkedList}
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="MainForm--right">
              <div className="label">Note Acquisti</div>
              <textarea
                onChange={(e) => {
                  this.setState({ extra_data: e.target.value });
                }}
                placeholder="Note (opzionali)"
                value={extra_data || ""}
              />
              <div className="formsContainer--body__semiCont mt-4">
                <div className="video-container">
                  <img
                    className="enagic-image"
                    src={images.enagicimg1}
                    alt="enagic-img1"
                  />
                  <a
                    href="https://youtu.be/qq-2SqCXP3k"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="enagic-play"
                      src={images.enagicplay}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://youtu.be/qq-2SqCXP3k"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Dimostrazione Acqua Kangen
                  </a>
                </div>
                <div className="video-container">
                  <img
                    className="enagic-image"
                    src={images.enagicimg2}
                    alt="enagic-img2"
                  />
                  <a
                    href="https://youtu.be/_UmWJc1pbLg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="enagic-play"
                      src={images.enagicplay}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://youtu.be/_UmWJc1pbLg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Piano Compensi Enagic
                  </a>
                </div>
              </div>
              <InviaPrenotationButton
                handleClick={this.submitData}
                color={color}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { ...AuthActions, ...MainActions })(Enagic);
