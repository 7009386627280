import React from "react";
import { Header } from "../../shared-components"
import { connect } from "react-redux";
import AuthActions from "redux-store/models/auth";
import AutenticazioneComponent from "routes/domains/Autenticazione";

class Autenticazione extends React.Component {

  render() {
    return (
      <div className="login-component">
        <Header></Header>
        <div className="module container-fluid max-width_modulePopUP">
          <AutenticazioneComponent />
        </div>
      </div>
    );
  }
}

const mapsStateToProps = ({ auth }) => ({});

export default connect(mapsStateToProps, { ...AuthActions })(Autenticazione);
