import { Form, Input } from "antd"
import React from "react"

const BonusModalRow = () => {
  return (
    <div className="semi bonus-row">
      <Form.Item name="da" label="Da">
        <Input placeholder="Da" />
      </Form.Item>
      <Form.Item name="a" label="A">
        <Input placeholder="A" />
      </Form.Item>
      <Form.Item name="bonus" label="Bonus*">
        <Input placeholder="Bonus" suffix={<i className="far fa-percent"></i>} />
      </Form.Item>
    </div>
  )
}

export default BonusModalRow
