const newNazioni = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Anguilla (isola)",
  "Antigua e Barbuda",
  "Arabia Saudita",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaigian",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belgio",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bielorussia",
  "Birmania",
  "Bolivia",
  "Bosnia ed Erzegovina",
  "Botswana",
  "Brasile",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambogia",
  "Camerun",
  "Canada",
  "Capo Verde",
  "Ceuta",
  "Ciad",
  "Cile",
  "Cina",
  "Cipro",
  "Città del Vaticano",
  "Colombia",
  "Comore",
  "Corea del Nord",
  "Corea del Sud",
  "Costa d'Avorio",
  "Costa Rica",
  "Croazia",
  "Cuba",
  "Curaçao",
  "Danimarca",
  "Dominica",
  "Ecuador",
  "Egitto",
  "El Salvador",
  "Emirati Arabi Uniti",
  "Eritrea",
  "ESwatini",
  "Estonia",
  "Etiopia",
  "Fær Øer",
  "Figi",
  "Filippine",
  "Finlandia",
  "Francia",
  "Gabon",
  "Gambia",
  "Georgia",
  "Georgia del Sud e Isole Sandwich Australi",
  "Germania",
  "Ghana",
  "Giamaica",
  "Giappone",
  "Gibilterra",
  "Gibuti",
  "Giordania",
  "Grecia",
  "Grenada",
  "Groenlandia",
  "Guadalupa",
  "Guam",
  "Guatemala",
  "Guinea",
  "Guinea Equatoriale",
  "Guinea-Bissau",
  "Guyana",
  "Guyana francese",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Irlanda",
  "Islanda",
  "Isola di Man",
  "Isole Canarie",
  "Isole Cayman",
  "Isole Cocos e Keeling",
  "Isole Cook",
  "Isola di Natale",
  "Isole Falkland",
  "Isole Marianne Settentrionali",
  "Isole Marshall",
  "Isola Norfolk",
  "Isole Pitcairn",
  "Isole Salomone",
  "Isole Sparse dell'oceano Indiano",
  "Isole Vergini americane",
  "Isole Vergini britanniche",
  "Israele",
  "Italia",
  "Kazakistan",
  "Kenya",
  "Kirghizistan",
  "Kiribati",
  "Kuwait",
  "Laos",
  "Lesotho",
  "Lettonia",
  "Libano",
  "Liberia",
  "Libia",
  "Liechtenstein",
  "Lituania",
  "Lussemburgo",
  "Macao",
  "Macedonia del Nord",
  "Madagascar",
  "Madera",
  "Malawi",
  "Maldive",
  "Malesia",
  "Mali",
  "Malta",
  "Marocco",
  "Martinica",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Melilla",
  "Messico",
  "Moldavia",
  "Mongolia",
  "Montenegro",
  "Montserrat (isola)",
  "Mozambico",
  "Namibia",
  "Nauru",
  "Nepal",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norvegia",
  "Nuova Caledonia",
  "Nuova Zelanda",
  "Oman",
  "Paesi Bassi",
  "Pakistan",
  "Palau (stato)",
  "Stato di Palestina",
  "Panama",
  "Papua Nuova Guinea",
  "Paraguay",
  "Perù",
  "Polinesia Francese",
  "Polonia",
  "Porto Rico",
  "Portogallo",
  "Principato di Monaco",
  "Qatar",
  "Regno Unito",
  "Repubblica Ceca",
  "Repubblica Centrafricana",
  "Repubblica del Congo",
  "Repubblica Democratica del Congo",
  "Repubblica Dominicana",
  "La Riunione",
  "Romania",
  "Ruanda",
  "Russia",
  "Saint Kitts e Nevis",
  "Saint Vincent e Grenadine",
  "Saint-Barthélemy (collettività d'oltremare)",
  "Saint-Martin (Francia) (la pagina non esiste)",
  "Saint-Pierre e Miquelon",
  "Samoa",
  "Samoa Americane",
  "San Marino",
  "Saint Lucia",
  "Sant'Elena, Ascensione e Tristan da Cunha",
  "São Tomé e Príncipe",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (la pagina non esiste)",
  "Siria",
  "Slovacchia",
  "Slovenia",
  "Somalia",
  "Spagna",
  "Sri Lanka",
  "Stati Federati di Micronesia",
  "Stati Uniti d'America",
  "Sudafrica",
  "Sudan",
  "Sudan del Sud",
  "Suriname",
  "Svezia",
  "Svizzera",
  "Tagikistan",
  "Taiwan",
  "Tanzania",
  "Thailandia",
  "Timor Est",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad e Tobago",
  "Tunisia",
  "Turchia",
  "Turkmenistan",
  "Turks e Caicos",
  "Tuvalu",
  "Ucraina",
  "Uganda",
  "Ungheria",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Wallis e Futuna",
  "Yemen",
  "Zambia",
  "Zimbabwe",
].map(n => n.toUpperCase())

export default [
  { codeKey: "A154", sigla: "VI", provincia: "ALBETTONE", nazione: "ITALIA" },
  { codeKey: "A155", sigla: "CZ", provincia: "ALBI", nazione: "ITALIA" },
  { codeKey: "A157", sigla: "TO", provincia: "ALBIANO D'IVREA", nazione: "ITALIA" },
  { codeKey: "A158", sigla: "TN", provincia: "ALBIANO", nazione: "ITALIA" },
  { codeKey: "A159", sigla: "MB", provincia: "ALBIATE", nazione: "ITALIA" },
  { codeKey: "A160", sigla: "CS", provincia: "ALBIDONA", nazione: "ITALIA" },
  { codeKey: "A161", sigla: "PD", provincia: "ALBIGNASEGO", nazione: "ITALIA" },
  { codeKey: "A162", sigla: "RE", provincia: "ALBINEA", nazione: "ITALIA" },
  { codeKey: "A163", sigla: "BG", provincia: "ALBINO", nazione: "ITALIA" },
  { codeKey: "A164", sigla: "CO", provincia: "ALBIOLO", nazione: "ITALIA" },
  { codeKey: "A165", sigla: "SV", provincia: "ALBISSOLA MARINA", nazione: "ITALIA" },
  {
    codeKey: "A166",
    sigla: "SV",
    provincia: "ALBISOLA SUPERIORE",
    nazione: "ITALIA",
  },
  { codeKey: "A167", sigla: "VA", provincia: "ALBIZZATE", nazione: "ITALIA" },
  { codeKey: "A171", sigla: "PV", provincia: "ALBONESE", nazione: "ITALIA" },
  { codeKey: "A172", sigla: "SO", provincia: "ALBOSAGGIA", nazione: "ITALIA" },
  { codeKey: "A173", sigla: "AT", provincia: "ALBUGNANO", nazione: "ITALIA" },
  { codeKey: "A175", sigla: "PV", provincia: "ALBUZZANO", nazione: "ITALIA" },
  { codeKey: "A176", sigla: "TP", provincia: "ALCAMO", nazione: "ITALIA" },
  { codeKey: "A177", sigla: "ME", provincia: "ALCARA LI FUSI", nazione: "ITALIA" },
  { codeKey: "A178", sigla: "TN", provincia: "ALDENO", nazione: "ITALIA" },
  { codeKey: "A179", sigla: "BZ", provincia: "ALDINO", nazione: "ITALIA" },
  { codeKey: "A180", sigla: "OR", provincia: "ALES", nazione: "ITALIA" },
  {
    codeKey: "A181",
    sigla: "AG",
    provincia: "ALESSANDRIA DELLA ROCCA",
    nazione: "ITALIA",
  },
  { codeKey: "A182", sigla: "AL", provincia: "ALESSANDRIA", nazione: "ITALIA" },
  {
    codeKey: "A183",
    sigla: "CS",
    provincia: "ALESSANDRIA DEL CARRETTO",
    nazione: "ITALIA",
  },
  { codeKey: "A184", sigla: "LE", provincia: "ALESSANO", nazione: "ITALIA" },
  { codeKey: "A185", sigla: "LE", provincia: "ALEZIO", nazione: "ITALIA" },
  { codeKey: "A186", sigla: "SA", provincia: "ALFANO", nazione: "ITALIA" },
  { codeKey: "A187", sigla: "AQ", provincia: "ALFEDENA", nazione: "ITALIA" },
  { codeKey: "A188", sigla: "BS", provincia: "ALFIANELLO", nazione: "ITALIA" },
  { codeKey: "A189", sigla: "AL", provincia: "ALFIANO NATTA", nazione: "ITALIA" },
  { codeKey: "A191", sigla: "RA", provincia: "ALFONSINE", nazione: "ITALIA" },
  { codeKey: "A192", sigla: "SS", provincia: "ALGHERO", nazione: "ITALIA" },
  { codeKey: "A193", sigla: "BG", provincia: "ALGUA", nazione: "ITALIA" },
  { codeKey: "A194", sigla: "ME", provincia: "ALI'", nazione: "ITALIA" },
  { codeKey: "A195", sigla: "PA", provincia: "ALIA", nazione: "ITALIA" },
  { codeKey: "A196", sigla: "MT", provincia: "ALIANO", nazione: "ITALIA" },
  { codeKey: "A197", sigla: "AL", provincia: "ALICE BEL COLLE", nazione: "ITALIA" },
  { codeKey: "A198", sigla: "VC", provincia: "ALICE CASTELLO", nazione: "ITALIA" },
  { codeKey: "A199", sigla: "TO", provincia: "ALICE SUPERIORE", nazione: "ITALIA" },
  { codeKey: "A200", sigla: "CE", provincia: "ALIFE", nazione: "ITALIA" },
  { codeKey: "A201", sigla: "ME", provincia: "ALI' TERME", nazione: "ITALIA" },
  { codeKey: "A202", sigla: "PA", provincia: "ALIMENA", nazione: "ITALIA" },
  { codeKey: "A203", sigla: "PA", provincia: "ALIMINUSA", nazione: "ITALIA" },
  { codeKey: "A204", sigla: "OR", provincia: "ALLAI", nazione: "ITALIA" },
  { codeKey: "A205", sigla: "AO", provincia: "ALLEIN", nazione: "ITALIA" },
  { codeKey: "A206", sigla: "BL", provincia: "ALLEGHE", nazione: "ITALIA" },
  { codeKey: "A207", sigla: "TR", provincia: "ALLERONA", nazione: "ITALIA" },
  { codeKey: "A208", sigla: "LE", provincia: "ALLISTE", nazione: "ITALIA" },
  { codeKey: "A210", sigla: "RM", provincia: "ALLUMIERE", nazione: "ITALIA" },
  {
    codeKey: "A211",
    sigla: "AL",
    provincia: "ALLUVIONI CAMBIO'",
    nazione: "ITALIA",
  },
  { codeKey: "A214", sigla: "BG", provincia: "ALME'", nazione: "ITALIA" },
  { codeKey: "A215", sigla: "BG", provincia: "VILLA D'ALME'", nazione: "ITALIA" },
  {
    codeKey: "A216",
    sigla: "BG",
    provincia: "ALMENNO SAN BARTOLOMEO",
    nazione: "ITALIA",
  },
  {
    codeKey: "A217",
    sigla: "BG",
    provincia: "ALMENNO SAN SALVATORE",
    nazione: "ITALIA",
  },
  { codeKey: "A218", sigla: "TO", provincia: "ALMESE", nazione: "ITALIA" },
  { codeKey: "A220", sigla: "VI", provincia: "ALONTE", nazione: "ITALIA" },
  { codeKey: "A221", sigla: "TO", provincia: "ALPETTE", nazione: "ITALIA" },
  { codeKey: "A222", sigla: "TO", provincia: "ALPIGNANO", nazione: "ITALIA" },
  { codeKey: "A223", sigla: "PC", provincia: "ALSENO", nazione: "ITALIA" },
  { codeKey: "A224", sigla: "CO", provincia: "ALSERIO", nazione: "ITALIA" },
  { codeKey: "A225", sigla: "BA", provincia: "ALTAMURA", nazione: "ITALIA" },
  { codeKey: "A226", sigla: "SV", provincia: "ALTARE", nazione: "ITALIA" },
  {
    codeKey: "A227",
    sigla: "AL",
    provincia: "ALTAVILLA MONFERRATO",
    nazione: "ITALIA",
  },
  { codeKey: "A228", sigla: "AV", provincia: "ALTAVILLA IRPINA", nazione: "ITALIA" },
  {
    codeKey: "A229",
    sigla: "PA",
    provincia: "ALTAVILLA MILICIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "A230",
    sigla: "SA",
    provincia: "ALTAVILLA SILENTINA",
    nazione: "ITALIA",
  },
  {
    codeKey: "A231",
    sigla: "VI",
    provincia: "ALTAVILLA VICENTINA",
    nazione: "ITALIA",
  },
  { codeKey: "A233", sigla: "FM", provincia: "ALTIDONA", nazione: "ITALIA" },
  { codeKey: "A234", sigla: "CS", provincia: "ALTILIA", nazione: "ITALIA" },
  { codeKey: "A235", sigla: "CH", provincia: "ALTINO", nazione: "ITALIA" },
  { codeKey: "A236", sigla: "VI", provincia: "ALTISSIMO", nazione: "ITALIA" },
  { codeKey: "A237", sigla: "TV", provincia: "ALTIVOLE", nazione: "ITALIA" },
  { codeKey: "A238", sigla: "CN", provincia: "ALTO", nazione: "ITALIA" },
  { codeKey: "A239", sigla: "PA", provincia: "ALTOFONTE", nazione: "ITALIA" },
  { codeKey: "A240", sigla: "CS", provincia: "ALTOMONTE", nazione: "ITALIA" },
  { codeKey: "A241", sigla: "LU", provincia: "ALTOPASCIO", nazione: "ITALIA" },
  { codeKey: "A242", sigla: "TR", provincia: "ALVIANO", nazione: "ITALIA" },
  { codeKey: "A243", sigla: "CE", provincia: "ALVIGNANO", nazione: "ITALIA" },
  { codeKey: "A244", sigla: "FR", provincia: "ALVITO", nazione: "ITALIA" },
  { codeKey: "A245", sigla: "AL", provincia: "ALZANO SCRIVIA", nazione: "ITALIA" },
  { codeKey: "A246", sigla: "BG", provincia: "ALZANO LOMBARDO", nazione: "ITALIA" },
  { codeKey: "A249", sigla: "CO", provincia: "ALZATE BRIANZA", nazione: "ITALIA" },
  { codeKey: "A251", sigla: "SA", provincia: "AMALFI", nazione: "ITALIA" },
  { codeKey: "A252", sigla: "FM", provincia: "AMANDOLA", nazione: "ITALIA" },
  { codeKey: "A253", sigla: "CS", provincia: "AMANTEA", nazione: "ITALIA" },
  { codeKey: "A254", sigla: "UD", provincia: "AMARO", nazione: "ITALIA" },
  { codeKey: "A255", sigla: "CZ", provincia: "AMARONI", nazione: "ITALIA" },
  { codeKey: "A256", sigla: "FR", provincia: "AMASENO", nazione: "ITALIA" },
  { codeKey: "A257", sigla: "CZ", provincia: "AMATO", nazione: "ITALIA" },
  { codeKey: "A258", sigla: "RI", provincia: "AMATRICE", nazione: "ITALIA" },
  { codeKey: "A259", sigla: "BG", provincia: "AMBIVERE", nazione: "ITALIA" },
  { codeKey: "A260", sigla: "TN", provincia: "AMBLAR", nazione: "ITALIA" },
  { codeKey: "A261", sigla: "SP", provincia: "AMEGLIA", nazione: "ITALIA" },
  { codeKey: "A262", sigla: "TR", provincia: "AMELIA", nazione: "ITALIA" },
  { codeKey: "A263", sigla: "CS", provincia: "AMENDOLARA", nazione: "ITALIA" },
  { codeKey: "A264", sigla: "NO", provincia: "AMENO", nazione: "ITALIA" },
  { codeKey: "A265", sigla: "BN", provincia: "AMOROSI", nazione: "ITALIA" },
  {
    codeKey: "A266",
    sigla: "BL",
    provincia: "CORTINA D'AMPEZZO",
    nazione: "ITALIA",
  },
  { codeKey: "A267", sigla: "UD", provincia: "AMPEZZO", nazione: "ITALIA" },
  { codeKey: "A268", sigla: "NA", provincia: "ANACAPRI", nazione: "ITALIA" },
  { codeKey: "A269", sigla: "FR", provincia: "ANAGNI", nazione: "ITALIA" },
  { codeKey: "A270", sigla: "TE", provincia: "ANCARANO", nazione: "ITALIA" },
  { codeKey: "A271", sigla: "AN", provincia: "ANCONA", nazione: "ITALIA" },
  { codeKey: "A272", sigla: "CZ", provincia: "ANDALI", nazione: "ITALIA" },
  {
    codeKey: "A273",
    sigla: "SO",
    provincia: "ANDALO VALTELLINO",
    nazione: "ITALIA",
  },
  { codeKey: "A274", sigla: "TN", provincia: "ANDALO", nazione: "ITALIA" },
  { codeKey: "A275", sigla: "TO", provincia: "ANDEZENO", nazione: "ITALIA" },
  { codeKey: "A278", sigla: "SV", provincia: "ANDORA", nazione: "ITALIA" },
  { codeKey: "A280", sigla: "BI", provincia: "ANDORNO MICCA", nazione: "ITALIA" },
  { codeKey: "A281", sigla: "LE", provincia: "ANDRANO", nazione: "ITALIA" },
  { codeKey: "A282", sigla: "TO", provincia: "ANDRATE", nazione: "ITALIA" },
  { codeKey: "A283", sigla: "PN", provincia: "ANDREIS", nazione: "ITALIA" },
  { codeKey: "A284", sigla: "AV", provincia: "ANDRETTA", nazione: "ITALIA" },
  { codeKey: "A285", sigla: "BT", provincia: "ANDRIA", nazione: "ITALIA" },
  { codeKey: "A286", sigla: "BZ", provincia: "ANDRIANO", nazione: "ITALIA" },
  { codeKey: "A287", sigla: "SS", provincia: "ANELA", nazione: "ITALIA" },
  { codeKey: "A288", sigla: "BS", provincia: "ANFO", nazione: "ITALIA" },
  { codeKey: "A290", sigla: "VA", provincia: "ANGERA", nazione: "ITALIA" },
  { codeKey: "A291", sigla: "AR", provincia: "ANGHIARI", nazione: "ITALIA" },
  { codeKey: "A292", sigla: "VR", provincia: "ANGIARI", nazione: "ITALIA" },
  { codeKey: "A293", sigla: "BS", provincia: "ANGOLO TERME", nazione: "ITALIA" },
  { codeKey: "A294", sigla: "SA", provincia: "ANGRI", nazione: "ITALIA" },
  { codeKey: "A295", sigla: "TO", provincia: "ANGROGNA", nazione: "ITALIA" },
  {
    codeKey: "A296",
    sigla: "PD",
    provincia: "ANGUILLARA VENETA",
    nazione: "ITALIA",
  },
  {
    codeKey: "A297",
    sigla: "RM",
    provincia: "ANGUILLARA SABAZIA",
    nazione: "ITALIA",
  },
  { codeKey: "A299", sigla: "CR", provincia: "ANNICCO", nazione: "ITALIA" },
  {
    codeKey: "A300",
    sigla: "AT",
    provincia: "CASTELLO DI ANNONE",
    nazione: "ITALIA",
  },
  {
    codeKey: "A301",
    sigla: "LC",
    provincia: "ANNONE DI BRIANZA",
    nazione: "ITALIA",
  },
  { codeKey: "A302", sigla: "VE", provincia: "ANNONE VENETO", nazione: "ITALIA" },
  { codeKey: "A303", sigla: "RC", provincia: "ANOIA", nazione: "ITALIA" },
  { codeKey: "A304", sigla: "BG", provincia: "ANTEGNATE", nazione: "ITALIA" },
  {
    codeKey: "A305",
    sigla: "AO",
    provincia: "ANTEY-SAINT-ANDRE'",
    nazione: "ITALIA",
  },
  { codeKey: "A306", sigla: "BZ", provincia: "ANTERIVO", nazione: "ITALIA" },
  { codeKey: "A308", sigla: "AO", provincia: "LA MAGDELEINE", nazione: "ITALIA" },
  { codeKey: "A309", sigla: "RM", provincia: "ANTICOLI CORRADO", nazione: "ITALIA" },
  { codeKey: "A310", sigla: "FR", provincia: "FIUGGI", nazione: "ITALIA" },
  { codeKey: "A312", sigla: "AT", provincia: "ANTIGNANO", nazione: "ITALIA" },
  { codeKey: "A313", sigla: "ME", provincia: "ANTILLO", nazione: "ITALIA" },
  { codeKey: "A314", sigla: "RC", provincia: "ANTONIMINA", nazione: "ITALIA" },
  { codeKey: "A315", sigla: "RI", provincia: "ANTRODOCO", nazione: "ITALIA" },
  {
    codeKey: "A317",
    sigla: "VB",
    provincia: "ANTRONA SCHIERANCO",
    nazione: "ITALIA",
  },
  {
    codeKey: "A318",
    sigla: "AQ",
    provincia: "ANVERSA DEGLI ABRUZZI",
    nazione: "ITALIA",
  },
  { codeKey: "A319", sigla: "CO", provincia: "ANZANO DEL PARCO", nazione: "ITALIA" },
  { codeKey: "A320", sigla: "FG", provincia: "ANZANO DI PUGLIA", nazione: "ITALIA" },
  { codeKey: "A321", sigla: "PZ", provincia: "ANZI", nazione: "ITALIA" },
  { codeKey: "A323", sigla: "RM", provincia: "ANZIO", nazione: "ITALIA" },
  {
    codeKey: "A324",
    sigla: "BO",
    provincia: "ANZOLA DELL'EMILIA",
    nazione: "ITALIA",
  },
  { codeKey: "A325", sigla: "VB", provincia: "ANZOLA D'OSSOLA", nazione: "ITALIA" },
  { codeKey: "A326", sigla: "AO", provincia: "AOSTA", nazione: "ITALIA" },
  { codeKey: "A327", sigla: "PU", provincia: "APECCHIO", nazione: "ITALIA" },
  { codeKey: "A328", sigla: "BN", provincia: "APICE", nazione: "ITALIA" },
  { codeKey: "A329", sigla: "MC", provincia: "APIRO", nazione: "ITALIA" },
  { codeKey: "A330", sigla: "BN", provincia: "APOLLOSA", nazione: "ITALIA" },
  {
    codeKey: "A332",
    sigla: "BZ",
    provincia: "APPIANO SULLA STRADA DEL VINO",
    nazione: "ITALIA",
  },
  { codeKey: "A333", sigla: "CO", provincia: "APPIANO GENTILE", nazione: "ITALIA" },
  { codeKey: "A334", sigla: "MC", provincia: "APPIGNANO", nazione: "ITALIA" },
  {
    codeKey: "A335",
    sigla: "AP",
    provincia: "APPIGNANO DEL TRONTO",
    nazione: "ITALIA",
  },
  { codeKey: "A337", sigla: "SO", provincia: "APRICA", nazione: "ITALIA" },
  { codeKey: "A338", sigla: "IM", provincia: "APRICALE", nazione: "ITALIA" },
  { codeKey: "A339", sigla: "FG", provincia: "APRICENA", nazione: "ITALIA" },
  { codeKey: "A340", sigla: "CS", provincia: "APRIGLIANO", nazione: "ITALIA" },
  { codeKey: "A341", sigla: "LT", provincia: "APRILIA", nazione: "ITALIA" },
  { codeKey: "A343", sigla: "SA", provincia: "AQUARA", nazione: "ITALIA" },
  {
    codeKey: "A344",
    sigla: "IM",
    provincia: "AQUILA D'ARROSCIA",
    nazione: "ITALIA",
  },
  { codeKey: "A345", sigla: "AQ", provincia: "L'AQUILA", nazione: "ITALIA" },
  { codeKey: "A346", sigla: "UD", provincia: "AQUILEIA", nazione: "ITALIA" },
  { codeKey: "A347", sigla: "AV", provincia: "AQUILONIA", nazione: "ITALIA" },
  { codeKey: "A348", sigla: "FR", provincia: "AQUINO", nazione: "ITALIA" },
  { codeKey: "A350", sigla: "LE", provincia: "ARADEO", nazione: "ITALIA" },
  { codeKey: "A351", sigla: "AG", provincia: "ARAGONA", nazione: "ITALIA" },
  { codeKey: "A352", sigla: "AT", provincia: "ARAMENGO", nazione: "ITALIA" },
  { codeKey: "A354", sigla: "PN", provincia: "ARBA", nazione: "ITALIA" },
  { codeKey: "A355", sigla: "OG", provincia: "TORTOLI'", nazione: "ITALIA" },
  { codeKey: "A357", sigla: "OR", provincia: "ARBOREA", nazione: "ITALIA" },
  { codeKey: "A358", sigla: "VC", provincia: "ARBORIO", nazione: "ITALIA" },
  { codeKey: "A359", sigla: "VS", provincia: "ARBUS", nazione: "ITALIA" },
  { codeKey: "A360", sigla: "TV", provincia: "ARCADE", nazione: "ITALIA" },
  { codeKey: "A363", sigla: "FR", provincia: "ARCE", nazione: "ITALIA" },
  { codeKey: "A365", sigla: "BG", provincia: "ARCENE", nazione: "ITALIA" },
  { codeKey: "A366", sigla: "AN", provincia: "ARCEVIA", nazione: "ITALIA" },
  { codeKey: "A367", sigla: "CH", provincia: "ARCHI", nazione: "ITALIA" },
  {
    codeKey: "A368",
    sigla: "OR",
    provincia: "SAN NICOLO' D'ARCIDANO",
    nazione: "ITALIA",
  },
  { codeKey: "A369", sigla: "GR", provincia: "ARCIDOSSO", nazione: "ITALIA" },
  { codeKey: "A370", sigla: "RM", provincia: "ARCINAZZO ROMANO", nazione: "ITALIA" },
  { codeKey: "A371", sigla: "VA", provincia: "ARCISATE", nazione: "ITALIA" },
  { codeKey: "A372", sigla: "TN", provincia: "ARCO", nazione: "ITALIA" },
  { codeKey: "A373", sigla: "SP", provincia: "ARCOLA", nazione: "ITALIA" },
  { codeKey: "A374", sigla: "VR", provincia: "ARCOLE", nazione: "ITALIA" },
  { codeKey: "A375", sigla: "MI", provincia: "ARCONATE", nazione: "ITALIA" },
  { codeKey: "A376", sigla: "MB", provincia: "ARCORE", nazione: "ITALIA" },
  { codeKey: "A377", sigla: "VI", provincia: "ARCUGNANO", nazione: "ITALIA" },
  { codeKey: "A379", sigla: "SS", provincia: "ARDARA", nazione: "ITALIA" },
  { codeKey: "A380", sigla: "OR", provincia: "ARDAULI", nazione: "ITALIA" },
  { codeKey: "A382", sigla: "SO", provincia: "ARDENNO", nazione: "ITALIA" },
  { codeKey: "A383", sigla: "BG", provincia: "ARDESIO", nazione: "ITALIA" },
  { codeKey: "A385", sigla: "RC", provincia: "ARDORE", nazione: "ITALIA" },
  { codeKey: "A386", sigla: "VV", provincia: "ARENA", nazione: "ITALIA" },
  { codeKey: "A387", sigla: "PV", provincia: "ARENA PO", nazione: "ITALIA" },
  { codeKey: "A388", sigla: "GE", provincia: "ARENZANO", nazione: "ITALIA" },
  { codeKey: "A389", sigla: "MI", provincia: "ARESE", nazione: "ITALIA" },
  { codeKey: "A390", sigla: "AR", provincia: "AREZZO", nazione: "ITALIA" },
  { codeKey: "A391", sigla: "CO", provincia: "ARGEGNO", nazione: "ITALIA" },
  { codeKey: "A392", sigla: "BO", provincia: "ARGELATO", nazione: "ITALIA" },
  { codeKey: "A393", sigla: "FE", provincia: "ARGENTA", nazione: "ITALIA" },
  { codeKey: "A394", sigla: "CN", provincia: "ARGENTERA", nazione: "ITALIA" },
  { codeKey: "A396", sigla: "CN", provincia: "ARGUELLO", nazione: "ITALIA" },
  { codeKey: "A397", sigla: "CZ", provincia: "ARGUSTO", nazione: "ITALIA" },
  { codeKey: "A398", sigla: "CH", provincia: "ARI", nazione: "ITALIA" },
  { codeKey: "A399", sigla: "AV", provincia: "ARIANO IRPINO", nazione: "ITALIA" },
  {
    codeKey: "A400",
    sigla: "RO",
    provincia: "ARIANO NEL POLESINE",
    nazione: "ITALIA",
  },
  { codeKey: "A401", sigla: "RM", provincia: "ARICCIA", nazione: "ITALIA" },
  { codeKey: "A402", sigla: "CH", provincia: "ARIELLI", nazione: "ITALIA" },
  { codeKey: "A403", sigla: "CE", provincia: "ARIENZO", nazione: "ITALIA" },
  { codeKey: "A405", sigla: "TO", provincia: "ARIGNANO", nazione: "ITALIA" },
  { codeKey: "A407", sigla: "NU", provincia: "ARITZO", nazione: "ITALIA" },
  { codeKey: "A409", sigla: "VB", provincia: "ARIZZANO", nazione: "ITALIA" },
  { codeKey: "A412", sigla: "VT", provincia: "ARLENA DI CASTRO", nazione: "ITALIA" },
  { codeKey: "A413", sigla: "MI", provincia: "ARLUNO", nazione: "ITALIA" },
  { codeKey: "A414", sigla: "NO", provincia: "ARMENO", nazione: "ITALIA" },
  { codeKey: "A415", sigla: "PZ", provincia: "ARMENTO", nazione: "ITALIA" },
  { codeKey: "A418", sigla: "IM", provincia: "ARMO", nazione: "ITALIA" },
  { codeKey: "A419", sigla: "CA", provincia: "ARMUNGIA", nazione: "ITALIA" },
  { codeKey: "A421", sigla: "FR", provincia: "ARNARA", nazione: "ITALIA" },
  { codeKey: "A422", sigla: "SV", provincia: "ARNASCO", nazione: "ITALIA" },
  { codeKey: "A424", sigla: "AO", provincia: "ARNAD", nazione: "ITALIA" },
  { codeKey: "A425", sigla: "LE", provincia: "ARNESANO", nazione: "ITALIA" },
  { codeKey: "A427", sigla: "VB", provincia: "AROLA", nazione: "ITALIA" },
  { codeKey: "A429", sigla: "NO", provincia: "ARONA", nazione: "ITALIA" },
  { codeKey: "A430", sigla: "CO", provincia: "AROSIO", nazione: "ITALIA" },
  { codeKey: "A431", sigla: "BN", provincia: "ARPAIA", nazione: "ITALIA" },
  { codeKey: "A432", sigla: "BN", provincia: "ARPAISE", nazione: "ITALIA" },
  { codeKey: "A433", sigla: "FR", provincia: "ARPINO", nazione: "ITALIA" },
  { codeKey: "A434", sigla: "PD", provincia: "ARQUA' PETRARCA", nazione: "ITALIA" },
  { codeKey: "A435", sigla: "RO", provincia: "ARQUA' POLESINE", nazione: "ITALIA" },
  { codeKey: "A436", sigla: "AL", provincia: "ARQUATA SCRIVIA", nazione: "ITALIA" },
  {
    codeKey: "A437",
    sigla: "AP",
    provincia: "ARQUATA DEL TRONTO",
    nazione: "ITALIA",
  },
  { codeKey: "A438", sigla: "PD", provincia: "ARRE", nazione: "ITALIA" },
  { codeKey: "A439", sigla: "TR", provincia: "ARRONE", nazione: "ITALIA" },
  { codeKey: "A440", sigla: "BG", provincia: "ARZAGO D'ADDA", nazione: "ITALIA" },
  { codeKey: "A441", sigla: "VA", provincia: "ARSAGO SEPRIO", nazione: "ITALIA" },
  { codeKey: "A443", sigla: "BL", provincia: "ARSIE'", nazione: "ITALIA" },
  { codeKey: "A444", sigla: "VI", provincia: "ARSIERO", nazione: "ITALIA" },
  { codeKey: "A445", sigla: "TE", provincia: "ARSITA", nazione: "ITALIA" },
  { codeKey: "A446", sigla: "RM", provincia: "ARSOLI", nazione: "ITALIA" },
  { codeKey: "A447", sigla: "UD", provincia: "ARTA TERME", nazione: "ITALIA" },
  { codeKey: "A448", sigla: "UD", provincia: "ARTEGNA", nazione: "ITALIA" },
  { codeKey: "A449", sigla: "RM", provincia: "ARTENA", nazione: "ITALIA" },
  { codeKey: "A451", sigla: "BS", provincia: "ARTOGNE", nazione: "ITALIA" },
  { codeKey: "A452", sigla: "AO", provincia: "ARVIER", nazione: "ITALIA" },
  { codeKey: "A453", sigla: "OT", provincia: "ARZACHENA", nazione: "ITALIA" },
  { codeKey: "A454", sigla: "OG", provincia: "ARZANA", nazione: "ITALIA" },
  { codeKey: "A455", sigla: "NA", provincia: "ARZANO", nazione: "ITALIA" },
  { codeKey: "A458", sigla: "PD", provincia: "ARZERGRANDE", nazione: "ITALIA" },
  { codeKey: "A459", sigla: "VI", provincia: "ARZIGNANO", nazione: "ITALIA" },
  { codeKey: "A460", sigla: "SA", provincia: "ASCEA", nazione: "ITALIA" },
  { codeKey: "A461", sigla: "SI", provincia: "ASCIANO", nazione: "ITALIA" },
  { codeKey: "A462", sigla: "AP", provincia: "ASCOLI PICENO", nazione: "ITALIA" },
  { codeKey: "A463", sigla: "FG", provincia: "ASCOLI SATRIANO", nazione: "ITALIA" },
  { codeKey: "A464", sigla: "RI", provincia: "ASCREA", nazione: "ITALIA" },
  { codeKey: "A465", sigla: "VI", provincia: "ASIAGO", nazione: "ITALIA" },
  {
    codeKey: "A466",
    sigla: "VC",
    provincia: "ASIGLIANO VERCELLESE",
    nazione: "ITALIA",
  },
  { codeKey: "A467", sigla: "VI", provincia: "ASIGLIANO VENETO", nazione: "ITALIA" },
  { codeKey: "A468", sigla: "SI", provincia: "SINALUNGA", nazione: "ITALIA" },
  { codeKey: "A470", sigla: "MN", provincia: "ASOLA", nazione: "ITALIA" },
  { codeKey: "A471", sigla: "TV", provincia: "ASOLO", nazione: "ITALIA" },
  { codeKey: "A472", sigla: "RI", provincia: "CASPERIA", nazione: "ITALIA" },
  { codeKey: "A473", sigla: "MI", provincia: "ASSAGO", nazione: "ITALIA" },
  { codeKey: "A474", sigla: "CA", provincia: "ASSEMINI", nazione: "ITALIA" },
  { codeKey: "A475", sigla: "PG", provincia: "ASSISI", nazione: "ITALIA" },
  { codeKey: "A476", sigla: "CO", provincia: "ASSO", nazione: "ITALIA" },
  { codeKey: "A477", sigla: "OR", provincia: "ASSOLO", nazione: "ITALIA" },
  { codeKey: "A478", sigla: "EN", provincia: "ASSORO", nazione: "ITALIA" },
  { codeKey: "A479", sigla: "AT", provincia: "ASTI", nazione: "ITALIA" },
  { codeKey: "A480", sigla: "OR", provincia: "ASUNI", nazione: "ITALIA" },
  { codeKey: "A481", sigla: "AQ", provincia: "ATELETA", nazione: "ITALIA" },
  { codeKey: "A482", sigla: "PZ", provincia: "ATELLA", nazione: "ITALIA" },
  { codeKey: "A484", sigla: "SA", provincia: "ATENA LUCANA", nazione: "ITALIA" },
  { codeKey: "A485", sigla: "CH", provincia: "ATESSA", nazione: "ITALIA" },
  { codeKey: "A486", sigla: "FR", provincia: "ATINA", nazione: "ITALIA" },
  { codeKey: "A487", sigla: "SA", provincia: "ATRANI", nazione: "ITALIA" },
  { codeKey: "A488", sigla: "TE", provincia: "ATRI", nazione: "ITALIA" },
  { codeKey: "A489", sigla: "AV", provincia: "ATRIPALDA", nazione: "ITALIA" },
  { codeKey: "A490", sigla: "TR", provincia: "ATTIGLIANO", nazione: "ITALIA" },
  { codeKey: "A491", sigla: "UD", provincia: "ATTIMIS", nazione: "ITALIA" },
  { codeKey: "A492", sigla: "NU", provincia: "ATZARA", nazione: "ITALIA" },
  { codeKey: "A493", sigla: "PU", provincia: "AUDITORE", nazione: "ITALIA" },
  { codeKey: "A494", sigla: "SR", provincia: "AUGUSTA", nazione: "ITALIA" },
  { codeKey: "A495", sigla: "SA", provincia: "AULETTA", nazione: "ITALIA" },
  { codeKey: "A496", sigla: "MS", provincia: "AULLA", nazione: "ITALIA" },
  { codeKey: "A497", sigla: "VB", provincia: "AURANO", nazione: "ITALIA" },
  { codeKey: "A499", sigla: "IM", provincia: "AURIGO", nazione: "ITALIA" },
  {
    codeKey: "A501",
    sigla: "BL",
    provincia: "AURONZO DI CADORE",
    nazione: "ITALIA",
  },
  { codeKey: "A502", sigla: "FR", provincia: "AUSONIA", nazione: "ITALIA" },
  { codeKey: "A503", sigla: "NU", provincia: "AUSTIS", nazione: "ITALIA" },
  { codeKey: "A506", sigla: "GE", provincia: "AVEGNO", nazione: "ITALIA" },
  { codeKey: "A507", sigla: "BZ", provincia: "AVELENGO", nazione: "ITALIA" },
  { codeKey: "A508", sigla: "AV", provincia: "AVELLA", nazione: "ITALIA" },
  { codeKey: "A509", sigla: "AV", provincia: "AVELLINO", nazione: "ITALIA" },
  { codeKey: "A511", sigla: "BG", provincia: "AVERARA", nazione: "ITALIA" },
  { codeKey: "A512", sigla: "CE", provincia: "AVERSA", nazione: "ITALIA" },
  { codeKey: "A514", sigla: "TA", provincia: "AVETRANA", nazione: "ITALIA" },
  { codeKey: "A515", sigla: "AQ", provincia: "AVEZZANO", nazione: "ITALIA" },
  { codeKey: "A516", sigla: "PN", provincia: "AVIANO", nazione: "ITALIA" },
  { codeKey: "A517", sigla: "BG", provincia: "AVIATICO", nazione: "ITALIA" },
  { codeKey: "A518", sigla: "TO", provincia: "AVIGLIANA", nazione: "ITALIA" },
  { codeKey: "A519", sigla: "PZ", provincia: "AVIGLIANO", nazione: "ITALIA" },
  { codeKey: "A520", sigla: "TN", provincia: "AVIO", nazione: "ITALIA" },
  { codeKey: "A521", sigla: "AO", provincia: "AVISE", nazione: "ITALIA" },
  { codeKey: "A522", sigla: "SR", provincia: "AVOLA", nazione: "ITALIA" },
  { codeKey: "A523", sigla: "AL", provincia: "AVOLASCA", nazione: "ITALIA" },
  { codeKey: "A525", sigla: "TO", provincia: "AZEGLIO", nazione: "ITALIA" },
  { codeKey: "A526", sigla: "CR", provincia: "AZZANELLO", nazione: "ITALIA" },
  { codeKey: "A527", sigla: "AT", provincia: "AZZANO D'ASTI", nazione: "ITALIA" },
  { codeKey: "A528", sigla: "BG", provincia: "AZZANO SAN PAOLO", nazione: "ITALIA" },
  { codeKey: "A529", sigla: "BS", provincia: "AZZANO MELLA", nazione: "ITALIA" },
  { codeKey: "A530", sigla: "PN", provincia: "AZZANO DECIMO", nazione: "ITALIA" },
  { codeKey: "A531", sigla: "VA", provincia: "AZZATE", nazione: "ITALIA" },
  { codeKey: "A532", sigla: "VA", provincia: "AZZIO", nazione: "ITALIA" },
  { codeKey: "A533", sigla: "BG", provincia: "AZZONE", nazione: "ITALIA" },
  { codeKey: "A534", sigla: "VB", provincia: "BACENO", nazione: "ITALIA" },
  { codeKey: "A535", sigla: "NA", provincia: "BACOLI", nazione: "ITALIA" },
  { codeKey: "A536", sigla: "IM", provincia: "BADALUCCO", nazione: "ITALIA" },
  { codeKey: "A537", sigla: "BZ", provincia: "BADIA", nazione: "ITALIA" },
  { codeKey: "A538", sigla: "PV", provincia: "BADIA PAVESE", nazione: "ITALIA" },
  { codeKey: "A539", sigla: "RO", provincia: "BADIA POLESINE", nazione: "ITALIA" },
  { codeKey: "A540", sigla: "VR", provincia: "BADIA CALAVENA", nazione: "ITALIA" },
  { codeKey: "A541", sigla: "AR", provincia: "BADIA TEDALDA", nazione: "ITALIA" },
  { codeKey: "A542", sigla: "CZ", provincia: "BADOLATO", nazione: "ITALIA" },
  { codeKey: "A544", sigla: "RC", provincia: "BAGALADI", nazione: "ITALIA" },
  { codeKey: "A546", sigla: "PA", provincia: "BAGHERIA", nazione: "ITALIA" },
  { codeKey: "A547", sigla: "RA", provincia: "BAGNACAVALLO", nazione: "ITALIA" },
  { codeKey: "A550", sigla: "PV", provincia: "BAGNARIA", nazione: "ITALIA" },
  {
    codeKey: "A551",
    sigla: "RA",
    provincia: "BAGNARA DI ROMAGNA",
    nazione: "ITALIA",
  },
  { codeKey: "A552", sigla: "RC", provincia: "BAGNARA CALABRA", nazione: "ITALIA" },
  { codeKey: "A553", sigla: "UD", provincia: "BAGNARIA ARSA", nazione: "ITALIA" },
  { codeKey: "A555", sigla: "CN", provincia: "BAGNASCO", nazione: "ITALIA" },
  { codeKey: "A557", sigla: "BG", provincia: "BAGNATICA", nazione: "ITALIA" },
  { codeKey: "A558", sigla: "BO", provincia: "PORRETTA TERME", nazione: "ITALIA" },
  { codeKey: "A560", sigla: "LU", provincia: "BAGNI DI LUCCA", nazione: "ITALIA" },
  {
    codeKey: "A561",
    sigla: "PT",
    provincia: "MONTECATINI TERME",
    nazione: "ITALIA",
  },
  {
    codeKey: "A562",
    sigla: "PI",
    provincia: "SAN GIULIANO TERME",
    nazione: "ITALIA",
  },
  { codeKey: "A564", sigla: "FI", provincia: "BAGNO A RIPOLI", nazione: "ITALIA" },
  { codeKey: "A565", sigla: "FC", provincia: "BAGNO DI ROMAGNA", nazione: "ITALIA" },
  { codeKey: "A566", sigla: "AV", provincia: "BAGNOLI IRPINO", nazione: "ITALIA" },
  {
    codeKey: "A567",
    sigla: "IS",
    provincia: "BAGNOLI DEL TRIGNO",
    nazione: "ITALIA",
  },
  { codeKey: "A568", sigla: "PD", provincia: "BAGNOLI DI SOPRA", nazione: "ITALIA" },
  { codeKey: "A569", sigla: "BS", provincia: "BAGNOLO MELLA", nazione: "ITALIA" },
  { codeKey: "A570", sigla: "CR", provincia: "BAGNOLO CREMASCO", nazione: "ITALIA" },
  { codeKey: "A571", sigla: "CN", provincia: "BAGNOLO PIEMONTE", nazione: "ITALIA" },
  {
    codeKey: "A572",
    sigla: "LE",
    provincia: "BAGNOLO DEL SALENTO",
    nazione: "ITALIA",
  },
  { codeKey: "A573", sigla: "RE", provincia: "BAGNOLO IN PIANO", nazione: "ITALIA" },
  { codeKey: "A574", sigla: "RO", provincia: "BAGNOLO DI PO", nazione: "ITALIA" },
  { codeKey: "A575", sigla: "MN", provincia: "BAGNOLO SAN VITO", nazione: "ITALIA" },
  { codeKey: "A576", sigla: "MS", provincia: "BAGNONE", nazione: "ITALIA" },
  { codeKey: "A577", sigla: "VT", provincia: "BAGNOREGIO", nazione: "ITALIA" },
  { codeKey: "A578", sigla: "BS", provincia: "BAGOLINO", nazione: "ITALIA" },
  { codeKey: "A579", sigla: "CE", provincia: "BAIA E LATINA", nazione: "ITALIA" },
  { codeKey: "A580", sigla: "AV", provincia: "BAIANO", nazione: "ITALIA" },
  { codeKey: "A581", sigla: "IM", provincia: "BAIARDO", nazione: "ITALIA" },
  { codeKey: "A584", sigla: "TO", provincia: "BAIRO", nazione: "ITALIA" },
  { codeKey: "A586", sigla: "RE", provincia: "BAISO", nazione: "ITALIA" },
  { codeKey: "A587", sigla: "TO", provincia: "BALANGERO", nazione: "ITALIA" },
  {
    codeKey: "A588",
    sigla: "AT",
    provincia: "BALDICHIERI D'ASTI",
    nazione: "ITALIA",
  },
  {
    codeKey: "A589",
    sigla: "CN",
    provincia: "BALDISSERO D'ALBA",
    nazione: "ITALIA",
  },
  {
    codeKey: "A590",
    sigla: "TO",
    provincia: "BALDISSERO CANAVESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "A591",
    sigla: "TO",
    provincia: "BALDISSERO TORINESE",
    nazione: "ITALIA",
  },
  { codeKey: "A592", sigla: "PA", provincia: "BALESTRATE", nazione: "ITALIA" },
  { codeKey: "A593", sigla: "SV", provincia: "BALESTRINO", nazione: "ITALIA" },
  { codeKey: "A594", sigla: "LC", provincia: "BALLABIO", nazione: "ITALIA" },
  { codeKey: "A597", sigla: "CA", provincia: "BALLAO", nazione: "ITALIA" },
  { codeKey: "A599", sigla: "TO", provincia: "BALME", nazione: "ITALIA" },
  { codeKey: "A600", sigla: "VC", provincia: "BALMUCCIA", nazione: "ITALIA" },
  { codeKey: "A601", sigla: "VC", provincia: "BALOCCO", nazione: "ITALIA" },
  { codeKey: "A603", sigla: "AQ", provincia: "BALSORANO", nazione: "ITALIA" },
  { codeKey: "A604", sigla: "PZ", provincia: "BALVANO", nazione: "ITALIA" },
  { codeKey: "A605", sigla: "AL", provincia: "BALZOLA", nazione: "ITALIA" },
  { codeKey: "A606", sigla: "SS", provincia: "BANARI", nazione: "ITALIA" },
  { codeKey: "A607", sigla: "TO", provincia: "BANCHETTE", nazione: "ITALIA" },
  { codeKey: "A609", sigla: "OR", provincia: "VILLA VERDE", nazione: "ITALIA" },
  { codeKey: "A610", sigla: "VB", provincia: "BANNIO ANZINO", nazione: "ITALIA" },
  { codeKey: "A612", sigla: "PZ", provincia: "BANZI", nazione: "ITALIA" },
  { codeKey: "A613", sigla: "PD", provincia: "BAONE", nazione: "ITALIA" },
  { codeKey: "A614", sigla: "OR", provincia: "BARADILI", nazione: "ITALIA" },
  { codeKey: "A615", sigla: "PZ", provincia: "BARAGIANO", nazione: "ITALIA" },
  { codeKey: "A616", sigla: "CB", provincia: "BARANELLO", nazione: "ITALIA" },
  { codeKey: "A617", sigla: "NA", provincia: "BARANO D'ISCHIA", nazione: "ITALIA" },
  { codeKey: "A618", sigla: "MI", provincia: "BARANZATE", nazione: "ITALIA" },
  { codeKey: "A619", sigla: "VA", provincia: "BARASSO", nazione: "ITALIA" },
  {
    codeKey: "A621",
    sigla: "OR",
    provincia: "BARATILI SAN PIETRO",
    nazione: "ITALIA",
  },
  { codeKey: "A625", sigla: "TO", provincia: "BARBANIA", nazione: "ITALIA" },
  { codeKey: "A626", sigla: "AN", provincia: "BARBARA", nazione: "ITALIA" },
  {
    codeKey: "A627",
    sigla: "VI",
    provincia: "BARBARANO VICENTINO",
    nazione: "ITALIA",
  },
  { codeKey: "A628", sigla: "VT", provincia: "BARBARANO ROMANO", nazione: "ITALIA" },
  { codeKey: "A629", sigla: "CN", provincia: "BARBARESCO", nazione: "ITALIA" },
  { codeKey: "A630", sigla: "BS", provincia: "BARBARIGA", nazione: "ITALIA" },
  { codeKey: "A631", sigla: "BG", provincia: "BARBATA", nazione: "ITALIA" },
  {
    codeKey: "A632",
    sigla: "FI",
    provincia: "BARBERINO DI MUGELLO",
    nazione: "ITALIA",
  },
  {
    codeKey: "A633",
    sigla: "FI",
    provincia: "BARBERINO VAL D'ELSA",
    nazione: "ITALIA",
  },
  { codeKey: "A634", sigla: "PV", provincia: "BARBIANELLO", nazione: "ITALIA" },
  { codeKey: "A635", sigla: "BZ", provincia: "BARBIANO", nazione: "ITALIA" },
  { codeKey: "A637", sigla: "PD", provincia: "BARBONA", nazione: "ITALIA" },
  {
    codeKey: "A638",
    sigla: "ME",
    provincia: "BARCELLONA POZZO DI GOTTO",
    nazione: "ITALIA",
  },
  { codeKey: "A639", sigla: "PU", provincia: "BARCHI", nazione: "ITALIA" },
  { codeKey: "A640", sigla: "PN", provincia: "BARCIS", nazione: "ITALIA" },
  { codeKey: "A643", sigla: "AO", provincia: "BARD", nazione: "ITALIA" },
  { codeKey: "A645", sigla: "VA", provincia: "BARDELLO", nazione: "ITALIA" },
  { codeKey: "A646", sigla: "PR", provincia: "BARDI", nazione: "ITALIA" },
  { codeKey: "A647", sigla: "SV", provincia: "BARDINETO", nazione: "ITALIA" },
  { codeKey: "A650", sigla: "VR", provincia: "BARDOLINO", nazione: "ITALIA" },
  { codeKey: "A651", sigla: "TO", provincia: "BARDONECCHIA", nazione: "ITALIA" },
  { codeKey: "A652", sigla: "MI", provincia: "BAREGGIO", nazione: "ITALIA" },
  { codeKey: "A653", sigla: "NO", provincia: "BARENGO", nazione: "ITALIA" },
  { codeKey: "A655", sigla: "OR", provincia: "BARESSA", nazione: "ITALIA" },
  { codeKey: "A656", sigla: "AQ", provincia: "BARETE", nazione: "ITALIA" },
  { codeKey: "A657", sigla: "LU", provincia: "BARGA", nazione: "ITALIA" },
  { codeKey: "A658", sigla: "GE", provincia: "BARGAGLI", nazione: "ITALIA" },
  { codeKey: "A660", sigla: "CN", provincia: "BARGE", nazione: "ITALIA" },
  { codeKey: "A661", sigla: "BS", provincia: "BARGHE", nazione: "ITALIA" },
  { codeKey: "A662", sigla: "BA", provincia: "BARI", nazione: "ITALIA" },
  { codeKey: "A663", sigla: "OG", provincia: "BARI SARDO", nazione: "ITALIA" },
  { codeKey: "A664", sigla: "BG", provincia: "BARIANO", nazione: "ITALIA" },
  { codeKey: "A665", sigla: "BO", provincia: "BARICELLA", nazione: "ITALIA" },
  { codeKey: "A666", sigla: "PZ", provincia: "BARILE", nazione: "ITALIA" },
  { codeKey: "A667", sigla: "AQ", provincia: "BARISCIANO", nazione: "ITALIA" },
  { codeKey: "A668", sigla: "MB", provincia: "BARLASSINA", nazione: "ITALIA" },
  { codeKey: "A669", sigla: "BT", provincia: "BARLETTA", nazione: "ITALIA" },
  { codeKey: "A670", sigla: "CO", provincia: "BARNI", nazione: "ITALIA" },
  { codeKey: "A671", sigla: "CN", provincia: "BAROLO", nazione: "ITALIA" },
  { codeKey: "A673", sigla: "TO", provincia: "BARONE CANAVESE", nazione: "ITALIA" },
  { codeKey: "A674", sigla: "SA", provincia: "BARONISSI", nazione: "ITALIA" },
  { codeKey: "A676", sigla: "EN", provincia: "BARRAFRANCA", nazione: "ITALIA" },
  { codeKey: "A677", sigla: "CA", provincia: "BARRALI", nazione: "ITALIA" },
  { codeKey: "A678", sigla: "AQ", provincia: "BARREA", nazione: "ITALIA" },
  { codeKey: "A681", sigla: "VS", provincia: "BARUMINI", nazione: "ITALIA" },
  { codeKey: "A683", sigla: "LC", provincia: "BARZAGO", nazione: "ITALIA" },
  { codeKey: "A684", sigla: "BG", provincia: "BARZANA", nazione: "ITALIA" },
  { codeKey: "A686", sigla: "LC", provincia: "BARZANO'", nazione: "ITALIA" },
  { codeKey: "A687", sigla: "LC", provincia: "BARZIO", nazione: "ITALIA" },
  { codeKey: "A689", sigla: "AL", provincia: "BASALUZZO", nazione: "ITALIA" },
  { codeKey: "A690", sigla: "PV", provincia: "BASCAPE'", nazione: "ITALIA" },
  { codeKey: "A691", sigla: "TR", provincia: "BASCHI", nazione: "ITALIA" },
  { codeKey: "A692", sigla: "TE", provincia: "BASCIANO", nazione: "ITALIA" },
  { codeKey: "A694", sigla: "TN", provincia: "BASELGA DI PINE'", nazione: "ITALIA" },
  { codeKey: "A696", sigla: "BN", provincia: "BASELICE", nazione: "ITALIA" },
  { codeKey: "A697", sigla: "MI", provincia: "BASIANO", nazione: "ITALIA" },
  { codeKey: "A698", sigla: "ME", provincia: "BASICO'", nazione: "ITALIA" },
  { codeKey: "A699", sigla: "MI", provincia: "BASIGLIO", nazione: "ITALIA" },
  { codeKey: "A700", sigla: "UD", provincia: "BASILIANO", nazione: "ITALIA" },
  { codeKey: "A701", sigla: "VT", provincia: "VASANELLO", nazione: "ITALIA" },
  {
    codeKey: "A702",
    sigla: "BS",
    provincia: "BASSANO BRESCIANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "A703",
    sigla: "VI",
    provincia: "BASSANO DEL GRAPPA",
    nazione: "ITALIA",
  },
  { codeKey: "A704", sigla: "VT", provincia: "BASSANO ROMANO", nazione: "ITALIA" },
  {
    codeKey: "A705",
    sigla: "VA",
    provincia: "TRONZANO LAGO MAGGIORE",
    nazione: "ITALIA",
  },
  {
    codeKey: "A706",
    sigla: "VT",
    provincia: "BASSANO IN TEVERINA",
    nazione: "ITALIA",
  },
  { codeKey: "A707", sigla: "LT", provincia: "BASSIANO", nazione: "ITALIA" },
  { codeKey: "A708", sigla: "AL", provincia: "BASSIGNANA", nazione: "ITALIA" },
  { codeKey: "A709", sigla: "CN", provincia: "BASTIA MONDOVI'", nazione: "ITALIA" },
  { codeKey: "A710", sigla: "PG", provincia: "BASTIA UMBRA", nazione: "ITALIA" },
  {
    codeKey: "A712",
    sigla: "PV",
    provincia: "BASTIDA PANCARANA",
    nazione: "ITALIA",
  },
  { codeKey: "A713", sigla: "MO", provincia: "BASTIGLIA", nazione: "ITALIA" },
  { codeKey: "A714", sigla: "PD", provincia: "BATTAGLIA TERME", nazione: "ITALIA" },
  { codeKey: "A716", sigla: "CN", provincia: "BATTIFOLLO", nazione: "ITALIA" },
  { codeKey: "A717", sigla: "SA", provincia: "BATTIPAGLIA", nazione: "ITALIA" },
  { codeKey: "A718", sigla: "PV", provincia: "BATTUDA", nazione: "ITALIA" },
  { codeKey: "A719", sigla: "PA", provincia: "BAUCINA", nazione: "ITALIA" },
  { codeKey: "A720", sigla: "FR", provincia: "BOVILLE ERNICA", nazione: "ITALIA" },
  { codeKey: "A721", sigla: "OR", provincia: "BAULADU", nazione: "ITALIA" },
  { codeKey: "A722", sigla: "OG", provincia: "BAUNEI", nazione: "ITALIA" },
  { codeKey: "A725", sigla: "VB", provincia: "BAVENO", nazione: "ITALIA" },
  { codeKey: "A728", sigla: "VA", provincia: "BEDERO VALCUVIA", nazione: "ITALIA" },
  { codeKey: "A729", sigla: "BS", provincia: "BEDIZZOLE", nazione: "ITALIA" },
  { codeKey: "A730", sigla: "TN", provincia: "BEDOLLO", nazione: "ITALIA" },
  { codeKey: "A731", sigla: "PR", provincia: "BEDONIA", nazione: "ITALIA" },
  { codeKey: "A732", sigla: "BG", provincia: "BEDULITA", nazione: "ITALIA" },
  { codeKey: "A733", sigla: "VB", provincia: "BEE", nazione: "ITALIA" },
  { codeKey: "A734", sigla: "TO", provincia: "BEINASCO", nazione: "ITALIA" },
  { codeKey: "A735", sigla: "CN", provincia: "BEINETTE", nazione: "ITALIA" },
  { codeKey: "A736", sigla: "CZ", provincia: "BELCASTRO", nazione: "ITALIA" },
  { codeKey: "A737", sigla: "VR", provincia: "BELFIORE", nazione: "ITALIA" },
  {
    codeKey: "A738",
    sigla: "AL",
    provincia: "BELFORTE MONFERRATO",
    nazione: "ITALIA",
  },
  {
    codeKey: "A739",
    sigla: "MC",
    provincia: "BELFORTE DEL CHIENTI",
    nazione: "ITALIA",
  },
  {
    codeKey: "A740",
    sigla: "PU",
    provincia: "BELFORTE ALL'ISAURO",
    nazione: "ITALIA",
  },
  { codeKey: "A741", sigla: "PV", provincia: "BELGIOIOSO", nazione: "ITALIA" },
  { codeKey: "A742", sigla: "VB", provincia: "BELGIRATE", nazione: "ITALIA" },
  { codeKey: "A743", sigla: "PZ", provincia: "BELLA", nazione: "ITALIA" },
  { codeKey: "A745", sigla: "LC", provincia: "BELLANO", nazione: "ITALIA" },
  { codeKey: "A746", sigla: "TE", provincia: "BELLANTE", nazione: "ITALIA" },
  {
    codeKey: "A747",
    sigla: "RN",
    provincia: "BELLARIA-IGEA MARINA",
    nazione: "ITALIA",
  },
  { codeKey: "A749", sigla: "RM", provincia: "BELLEGRA", nazione: "ITALIA" },
  { codeKey: "A750", sigla: "CN", provincia: "BELLINO", nazione: "ITALIA" },
  {
    codeKey: "A751",
    sigla: "MI",
    provincia: "BELLINZAGO LOMBARDO",
    nazione: "ITALIA",
  },
  {
    codeKey: "A752",
    sigla: "NO",
    provincia: "BELLINZAGO NOVARESE",
    nazione: "ITALIA",
  },
  { codeKey: "A755", sigla: "CE", provincia: "BELLONA", nazione: "ITALIA" },
  { codeKey: "A756", sigla: "SA", provincia: "BELLOSGUARDO", nazione: "ITALIA" },
  { codeKey: "A757", sigla: "BL", provincia: "BELLUNO", nazione: "ITALIA" },
  { codeKey: "A759", sigla: "MB", provincia: "BELLUSCO", nazione: "ITALIA" },
  { codeKey: "A760", sigla: "FM", provincia: "BELMONTE PICENO", nazione: "ITALIA" },
  {
    codeKey: "A761",
    sigla: "IS",
    provincia: "BELMONTE DEL SANNIO",
    nazione: "ITALIA",
  },
  { codeKey: "A762", sigla: "CS", provincia: "BELMONTE CALABRO", nazione: "ITALIA" },
  {
    codeKey: "A763",
    sigla: "FR",
    provincia: "BELMONTE CASTELLO",
    nazione: "ITALIA",
  },
  {
    codeKey: "A764",
    sigla: "PA",
    provincia: "BELMONTE MEZZAGNO",
    nazione: "ITALIA",
  },
  {
    codeKey: "A765",
    sigla: "RI",
    provincia: "BELMONTE IN SABINA",
    nazione: "ITALIA",
  },
  { codeKey: "A766", sigla: "CT", provincia: "BELPASSO", nazione: "ITALIA" },
  { codeKey: "A768", sigla: "CS", provincia: "BELSITO", nazione: "ITALIA" },
  {
    codeKey: "A769",
    sigla: "AN",
    provincia: "BELVEDERE OSTRENSE",
    nazione: "ITALIA",
  },
  { codeKey: "A770", sigla: "AT", provincia: "BELVEGLIO", nazione: "ITALIA" },
  {
    codeKey: "A771",
    sigla: "BO",
    provincia: "LIZZANO IN BELVEDERE",
    nazione: "ITALIA",
  },
  {
    codeKey: "A772",
    sigla: "KR",
    provincia: "BELVEDERE DI SPINELLO",
    nazione: "ITALIA",
  },
  {
    codeKey: "A773",
    sigla: "CS",
    provincia: "BELVEDERE MARITTIMO",
    nazione: "ITALIA",
  },
  { codeKey: "A774", sigla: "CN", provincia: "BELVEDERE LANGHE", nazione: "ITALIA" },
  { codeKey: "A776", sigla: "NU", provincia: "BELVI", nazione: "ITALIA" },
  { codeKey: "A777", sigla: "SO", provincia: "BEMA", nazione: "ITALIA" },
  { codeKey: "A778", sigla: "CO", provincia: "BENE LARIO", nazione: "ITALIA" },
  { codeKey: "A779", sigla: "CN", provincia: "BENE VAGIENNA", nazione: "ITALIA" },
  { codeKey: "A780", sigla: "RC", provincia: "BENESTARE", nazione: "ITALIA" },
  { codeKey: "A781", sigla: "SS", provincia: "BENETUTTI", nazione: "ITALIA" },
  { codeKey: "A782", sigla: "CN", provincia: "BENEVELLO", nazione: "ITALIA" },
  { codeKey: "A783", sigla: "BN", provincia: "BENEVENTO", nazione: "ITALIA" },
  { codeKey: "A784", sigla: "BI", provincia: "BENNA", nazione: "ITALIA" },
  { codeKey: "A785", sigla: "BO", provincia: "BENTIVOGLIO", nazione: "ITALIA" },
  { codeKey: "A786", sigla: "BG", provincia: "BERBENNO", nazione: "ITALIA" },
  {
    codeKey: "A787",
    sigla: "SO",
    provincia: "BERBENNO DI VALTELLINA",
    nazione: "ITALIA",
  },
  { codeKey: "A788", sigla: "PR", provincia: "BERCETO", nazione: "ITALIA" },
  { codeKey: "A789", sigla: "OT", provincia: "BERCHIDDA", nazione: "ITALIA" },
  {
    codeKey: "A791",
    sigla: "CO",
    provincia: "BEREGAZZO CON FIGLIARO",
    nazione: "ITALIA",
  },
  { codeKey: "A792", sigla: "PV", provincia: "BEREGUARDO", nazione: "ITALIA" },
  { codeKey: "A793", sigla: "AL", provincia: "BERGAMASCO", nazione: "ITALIA" },
  { codeKey: "A794", sigla: "BG", provincia: "BERGAMO", nazione: "ITALIA" },
  { codeKey: "A795", sigla: "RO", provincia: "BERGANTINO", nazione: "ITALIA" },
  { codeKey: "A796", sigla: "SV", provincia: "BERGEGGI", nazione: "ITALIA" },
  { codeKey: "A798", sigla: "CN", provincia: "BERGOLO", nazione: "ITALIA" },
  { codeKey: "A799", sigla: "BS", provincia: "BERLINGO", nazione: "ITALIA" },
  { codeKey: "A801", sigla: "MT", provincia: "BERNALDA", nazione: "ITALIA" },
  { codeKey: "A802", sigla: "MB", provincia: "BERNAREGGIO", nazione: "ITALIA" },
  { codeKey: "A804", sigla: "MI", provincia: "BERNATE TICINO", nazione: "ITALIA" },
  { codeKey: "A805", sigla: "CN", provincia: "BERNEZZO", nazione: "ITALIA" },
  { codeKey: "A806", sigla: "FE", provincia: "BERRA", nazione: "ITALIA" },
  { codeKey: "A809", sigla: "FC", provincia: "BERTINORO", nazione: "ITALIA" },
  { codeKey: "A810", sigla: "UD", provincia: "BERTIOLO", nazione: "ITALIA" },
  { codeKey: "A811", sigla: "LO", provincia: "BERTONICO", nazione: "ITALIA" },
  {
    codeKey: "A812",
    sigla: "AT",
    provincia: "BERZANO DI SAN PIETRO",
    nazione: "ITALIA",
  },
  {
    codeKey: "A813",
    sigla: "AL",
    provincia: "BERZANO DI TORTONA",
    nazione: "ITALIA",
  },
  { codeKey: "A815", sigla: "BG", provincia: "BERZO SAN FERMO", nazione: "ITALIA" },
  { codeKey: "A816", sigla: "BS", provincia: "BERZO DEMO", nazione: "ITALIA" },
  { codeKey: "A817", sigla: "BS", provincia: "BERZO INFERIORE", nazione: "ITALIA" },
  {
    codeKey: "A818",
    sigla: "MB",
    provincia: "BESANA IN BRIANZA",
    nazione: "ITALIA",
  },
  { codeKey: "A819", sigla: "VA", provincia: "BESANO", nazione: "ITALIA" },
  { codeKey: "A820", sigla: "MI", provincia: "BESATE", nazione: "ITALIA" },
  { codeKey: "A821", sigla: "TN", provincia: "BESENELLO", nazione: "ITALIA" },
  { codeKey: "A823", sigla: "PC", provincia: "BESENZONE", nazione: "ITALIA" },
  { codeKey: "A825", sigla: "VA", provincia: "BESNATE", nazione: "ITALIA" },
  { codeKey: "A826", sigla: "VA", provincia: "BESOZZO", nazione: "ITALIA" },
  { codeKey: "A827", sigla: "SS", provincia: "BESSUDE", nazione: "ITALIA" },
  { codeKey: "A831", sigla: "PC", provincia: "BETTOLA", nazione: "ITALIA" },
  { codeKey: "A832", sigla: "PG", provincia: "BETTONA", nazione: "ITALIA" },
  { codeKey: "A834", sigla: "VB", provincia: "BEURA-CARDEZZA", nazione: "ITALIA" },
  { codeKey: "A835", sigla: "PG", provincia: "BEVAGNA", nazione: "ITALIA" },
  { codeKey: "A836", sigla: "SP", provincia: "BEVERINO", nazione: "ITALIA" },
  { codeKey: "A837", sigla: "VR", provincia: "BEVILACQUA", nazione: "ITALIA" },
  { codeKey: "A841", sigla: "CT", provincia: "BIANCAVILLA", nazione: "ITALIA" },
  { codeKey: "A842", sigla: "CS", provincia: "BIANCHI", nazione: "ITALIA" },
  { codeKey: "A843", sigla: "RC", provincia: "BIANCO", nazione: "ITALIA" },
  { codeKey: "A844", sigla: "NO", provincia: "BIANDRATE", nazione: "ITALIA" },
  { codeKey: "A845", sigla: "VA", provincia: "BIANDRONNO", nazione: "ITALIA" },
  { codeKey: "A846", sigla: "BG", provincia: "BIANZANO", nazione: "ITALIA" },
  { codeKey: "A847", sigla: "VC", provincia: "BIANZE'", nazione: "ITALIA" },
  { codeKey: "A848", sigla: "SO", provincia: "BIANZONE", nazione: "ITALIA" },
  { codeKey: "A849", sigla: "MB", provincia: "BIASSONO", nazione: "ITALIA" },
  { codeKey: "A850", sigla: "RE", provincia: "BIBBIANO", nazione: "ITALIA" },
  { codeKey: "A851", sigla: "AR", provincia: "BIBBIENA", nazione: "ITALIA" },
  { codeKey: "A852", sigla: "LI", provincia: "BIBBONA", nazione: "ITALIA" },
  { codeKey: "A853", sigla: "TO", provincia: "BIBIANA", nazione: "ITALIA" },
  { codeKey: "A854", sigla: "FG", provincia: "BICCARI", nazione: "ITALIA" },
  { codeKey: "A855", sigla: "UD", provincia: "BICINICCO", nazione: "ITALIA" },
  { codeKey: "A856", sigla: "OR", provincia: "BIDONI'", nazione: "ITALIA" },
  { codeKey: "A857", sigla: "VT", provincia: "BLERA", nazione: "ITALIA" },
  { codeKey: "A859", sigla: "BI", provincia: "BIELLA", nazione: "ITALIA" },
  { codeKey: "A861", sigla: "BS", provincia: "BIENNO", nazione: "ITALIA" },
  { codeKey: "A863", sigla: "TN", provincia: "BIENO", nazione: "ITALIA" },
  { codeKey: "A864", sigla: "PI", provincia: "BIENTINA", nazione: "ITALIA" },
  { codeKey: "A866", sigla: "MN", provincia: "BIGARELLO", nazione: "ITALIA" },
  { codeKey: "A870", sigla: "CO", provincia: "BINAGO", nazione: "ITALIA" },
  { codeKey: "A872", sigla: "MI", provincia: "BINASCO", nazione: "ITALIA" },
  { codeKey: "A874", sigla: "BA", provincia: "BINETTO", nazione: "ITALIA" },
  { codeKey: "A876", sigla: "BI", provincia: "BIOGLIO", nazione: "ITALIA" },
  { codeKey: "A877", sigla: "AO", provincia: "BIONAZ", nazione: "ITALIA" },
  { codeKey: "A878", sigla: "BS", provincia: "BIONE", nazione: "ITALIA" },
  { codeKey: "A880", sigla: "NU", provincia: "BIRORI", nazione: "ITALIA" },
  { codeKey: "A881", sigla: "AV", provincia: "BISACCIA", nazione: "ITALIA" },
  { codeKey: "A882", sigla: "PA", provincia: "BISACQUINO", nazione: "ITALIA" },
  { codeKey: "A883", sigla: "BT", provincia: "BISCEGLIE", nazione: "ITALIA" },
  { codeKey: "A884", sigla: "AQ", provincia: "BISEGNA", nazione: "ITALIA" },
  { codeKey: "A885", sigla: "TE", provincia: "BISENTI", nazione: "ITALIA" },
  { codeKey: "A887", sigla: "CS", provincia: "BISIGNANO", nazione: "ITALIA" },
  { codeKey: "A889", sigla: "AL", provincia: "BISTAGNO", nazione: "ITALIA" },
  { codeKey: "A891", sigla: "VA", provincia: "BISUSCHIO", nazione: "ITALIA" },
  { codeKey: "A892", sigla: "BA", provincia: "BITETTO", nazione: "ITALIA" },
  { codeKey: "A893", sigla: "BA", provincia: "BITONTO", nazione: "ITALIA" },
  { codeKey: "A894", sigla: "BA", provincia: "BITRITTO", nazione: "ITALIA" },
  { codeKey: "A895", sigla: "NU", provincia: "BITTI", nazione: "ITALIA" },
  { codeKey: "A896", sigla: "AG", provincia: "BIVONA", nazione: "ITALIA" },
  { codeKey: "A897", sigla: "RC", provincia: "BIVONGI", nazione: "ITALIA" },
  { codeKey: "A898", sigla: "CO", provincia: "BIZZARONE", nazione: "ITALIA" },
  {
    codeKey: "A902",
    sigla: "TN",
    provincia: "BLEGGIO SUPERIORE",
    nazione: "ITALIA",
  },
  { codeKey: "A903", sigla: "BG", provincia: "BLELLO", nazione: "ITALIA" },
  { codeKey: "A904", sigla: "CO", provincia: "BLESSAGNO", nazione: "ITALIA" },
  { codeKey: "A905", sigla: "CO", provincia: "BLEVIO", nazione: "ITALIA" },
  { codeKey: "A906", sigla: "PD", provincia: "BOARA PISANI", nazione: "ITALIA" },
  { codeKey: "A909", sigla: "PC", provincia: "BOBBIO", nazione: "ITALIA" },
  { codeKey: "A910", sigla: "TO", provincia: "BOBBIO PELLICE", nazione: "ITALIA" },
  { codeKey: "A911", sigla: "NO", provincia: "BOCA", nazione: "ITALIA" },
  { codeKey: "A912", sigla: "CS", provincia: "BOCCHIGLIERO", nazione: "ITALIA" },
  { codeKey: "A914", sigla: "VC", provincia: "BOCCIOLETO", nazione: "ITALIA" },
  { codeKey: "A916", sigla: "TN", provincia: "BOCENAGO", nazione: "ITALIA" },
  { codeKey: "A918", sigla: "VA", provincia: "BODIO LOMNAGO", nazione: "ITALIA" },
  { codeKey: "A919", sigla: "LO", provincia: "BOFFALORA D'ADDA", nazione: "ITALIA" },
  {
    codeKey: "A920",
    sigla: "MI",
    provincia: "BOFFALORA SOPRA TICINO",
    nazione: "ITALIA",
  },
  { codeKey: "A922", sigla: "GE", provincia: "BOGLIASCO", nazione: "ITALIA" },
  { codeKey: "A925", sigla: "VB", provincia: "BOGNANCO", nazione: "ITALIA" },
  { codeKey: "A929", sigla: "NO", provincia: "BOGOGNO", nazione: "ITALIA" },
  { codeKey: "A930", sigla: "CB", provincia: "BOIANO", nazione: "ITALIA" },
  { codeKey: "A931", sigla: "SV", provincia: "BOISSANO", nazione: "ITALIA" },
  { codeKey: "A932", sigla: "SP", provincia: "BOLANO", nazione: "ITALIA" },
  { codeKey: "A933", sigla: "TN", provincia: "BOLBENO", nazione: "ITALIA" },
  { codeKey: "A937", sigla: "BG", provincia: "BOLGARE", nazione: "ITALIA" },
  { codeKey: "A940", sigla: "MI", provincia: "BOLLATE", nazione: "ITALIA" },
  { codeKey: "A941", sigla: "TO", provincia: "BOLLENGO", nazione: "ITALIA" },
  { codeKey: "A942", sigla: "MT", provincia: "NOVA SIRI", nazione: "ITALIA" },
  { codeKey: "A944", sigla: "BO", provincia: "BOLOGNA", nazione: "ITALIA" },
  { codeKey: "A945", sigla: "PE", provincia: "BOLOGNANO", nazione: "ITALIA" },
  { codeKey: "A946", sigla: "PA", provincia: "BOLOGNETTA", nazione: "ITALIA" },
  { codeKey: "A947", sigla: "MC", provincia: "BOLOGNOLA", nazione: "ITALIA" },
  { codeKey: "A948", sigla: "NU", provincia: "BOLOTANA", nazione: "ITALIA" },
  { codeKey: "A949", sigla: "VT", provincia: "BOLSENA", nazione: "ITALIA" },
  { codeKey: "A950", sigla: "BG", provincia: "BOLTIERE", nazione: "ITALIA" },
  { codeKey: "A952", sigla: "BZ", provincia: "BOLZANO", nazione: "ITALIA" },
  { codeKey: "A953", sigla: "NO", provincia: "BOLZANO NOVARESE", nazione: "ITALIA" },
  {
    codeKey: "A954",
    sigla: "VI",
    provincia: "BOLZANO VICENTINO",
    nazione: "ITALIA",
  },
  { codeKey: "A955", sigla: "VT", provincia: "BOMARZO", nazione: "ITALIA" },
  { codeKey: "A956", sigla: "CH", provincia: "BOMBA", nazione: "ITALIA" },
  { codeKey: "A957", sigla: "CL", provincia: "BOMPENSIERE", nazione: "ITALIA" },
  { codeKey: "A958", sigla: "PA", provincia: "BOMPIETRO", nazione: "ITALIA" },
  { codeKey: "A959", sigla: "MO", provincia: "BOMPORTO", nazione: "ITALIA" },
  { codeKey: "A960", sigla: "OR", provincia: "BONARCADO", nazione: "ITALIA" },
  { codeKey: "A961", sigla: "SP", provincia: "BONASSOLA", nazione: "ITALIA" },
  { codeKey: "A962", sigla: "BG", provincia: "BONATE SOTTO", nazione: "ITALIA" },
  { codeKey: "A963", sigla: "BG", provincia: "BONATE SOPRA", nazione: "ITALIA" },
  { codeKey: "A964", sigla: "VR", provincia: "BONAVIGO", nazione: "ITALIA" },
  { codeKey: "A965", sigla: "FE", provincia: "BONDENO", nazione: "ITALIA" },
  { codeKey: "A967", sigla: "TN", provincia: "BONDO", nazione: "ITALIA" },
  { codeKey: "A968", sigla: "TN", provincia: "BONDONE", nazione: "ITALIA" },
  { codeKey: "A970", sigla: "BN", provincia: "BONEA", nazione: "ITALIA" },
  { codeKey: "A971", sigla: "CB", provincia: "BONEFRO", nazione: "ITALIA" },
  { codeKey: "A972", sigla: "CR", provincia: "BONEMERSE", nazione: "ITALIA" },
  { codeKey: "A973", sigla: "CS", provincia: "BONIFATI", nazione: "ITALIA" },
  { codeKey: "A975", sigla: "AV", provincia: "BONITO", nazione: "ITALIA" },
  { codeKey: "A976", sigla: "SS", provincia: "BONNANARO", nazione: "ITALIA" },
  { codeKey: "A977", sigla: "SS", provincia: "BONO", nazione: "ITALIA" },
  { codeKey: "A978", sigla: "SS", provincia: "BONORVA", nazione: "ITALIA" },
  { codeKey: "A979", sigla: "CN", provincia: "BONVICINO", nazione: "ITALIA" },
  { codeKey: "A981", sigla: "RI", provincia: "BORBONA", nazione: "ITALIA" },
  { codeKey: "A982", sigla: "BL", provincia: "BORCA DI CADORE", nazione: "ITALIA" },
  { codeKey: "A983", sigla: "UD", provincia: "BORDANO", nazione: "ITALIA" },
  { codeKey: "A984", sigla: "IM", provincia: "BORDIGHERA", nazione: "ITALIA" },
  { codeKey: "A986", sigla: "CR", provincia: "BORDOLANO", nazione: "ITALIA" },
  { codeKey: "A987", sigla: "PR", provincia: "BORE", nazione: "ITALIA" },
  { codeKey: "A988", sigla: "RE", provincia: "BORETTO", nazione: "ITALIA" },
  { codeKey: "A989", sigla: "PV", provincia: "BORGARELLO", nazione: "ITALIA" },
  { codeKey: "A990", sigla: "TO", provincia: "BORGARO TORINESE", nazione: "ITALIA" },
  { codeKey: "A991", sigla: "PA", provincia: "BORGETTO", nazione: "ITALIA" },
  {
    codeKey: "A992",
    sigla: "SP",
    provincia: "BORGHETTO DI VARA",
    nazione: "ITALIA",
  },
  {
    codeKey: "A993",
    sigla: "IM",
    provincia: "BORGHETTO D'ARROSCIA",
    nazione: "ITALIA",
  },
  { codeKey: "A001", sigla: "PD", provincia: "ABANO TERME", nazione: "ITALIA" },
  { codeKey: "A004", sigla: "LO", provincia: "ABBADIA CERRETO", nazione: "ITALIA" },
  { codeKey: "A005", sigla: "LC", provincia: "ABBADIA LARIANA", nazione: "ITALIA" },
  {
    codeKey: "A006",
    sigla: "SI",
    provincia: "ABBADIA SAN SALVATORE",
    nazione: "ITALIA",
  },
  { codeKey: "A007", sigla: "OR", provincia: "ABBASANTA", nazione: "ITALIA" },
  { codeKey: "A008", sigla: "PE", provincia: "ABBATEGGIO", nazione: "ITALIA" },
  { codeKey: "A010", sigla: "MI", provincia: "ABBIATEGRASSO", nazione: "ITALIA" },
  { codeKey: "A012", sigla: "PT", provincia: "ABETONE", nazione: "ITALIA" },
  { codeKey: "A013", sigla: "PZ", provincia: "ABRIOLA", nazione: "ITALIA" },
  { codeKey: "A014", sigla: "RG", provincia: "ACATE", nazione: "ITALIA" },
  { codeKey: "A015", sigla: "FG", provincia: "ACCADIA", nazione: "ITALIA" },
  { codeKey: "A016", sigla: "CN", provincia: "ACCEGLIO", nazione: "ITALIA" },
  { codeKey: "A017", sigla: "MT", provincia: "ACCETTURA", nazione: "ITALIA" },
  { codeKey: "A018", sigla: "AQ", provincia: "ACCIANO", nazione: "ITALIA" },
  { codeKey: "A019", sigla: "RI", provincia: "ACCUMOLI", nazione: "ITALIA" },
  { codeKey: "A020", sigla: "PZ", provincia: "ACERENZA", nazione: "ITALIA" },
  { codeKey: "A022", sigla: "BZ", provincia: "CERMES", nazione: "ITALIA" },
  { codeKey: "A023", sigla: "SA", provincia: "ACERNO", nazione: "ITALIA" },
  { codeKey: "A024", sigla: "NA", provincia: "ACERRA", nazione: "ITALIA" },
  { codeKey: "A025", sigla: "CT", provincia: "ACI BONACCORSI", nazione: "ITALIA" },
  { codeKey: "A026", sigla: "CT", provincia: "ACI CASTELLO", nazione: "ITALIA" },
  { codeKey: "A027", sigla: "CT", provincia: "ACI CATENA", nazione: "ITALIA" },
  { codeKey: "A028", sigla: "CT", provincia: "ACIREALE", nazione: "ITALIA" },
  { codeKey: "A029", sigla: "CT", provincia: "ACI SANT'ANTONIO", nazione: "ITALIA" },
  { codeKey: "A031", sigla: "MC", provincia: "ACQUACANINA", nazione: "ITALIA" },
  { codeKey: "A032", sigla: "FR", provincia: "ACQUAFONDATA", nazione: "ITALIA" },
  { codeKey: "A033", sigla: "CS", provincia: "ACQUAFORMOSA", nazione: "ITALIA" },
  { codeKey: "A034", sigla: "BS", provincia: "ACQUAFREDDA", nazione: "ITALIA" },
  { codeKey: "A035", sigla: "PU", provincia: "ACQUALAGNA", nazione: "ITALIA" },
  {
    codeKey: "A038",
    sigla: "MN",
    provincia: "ACQUANEGRA SUL CHIESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "A039",
    sigla: "CR",
    provincia: "ACQUANEGRA CREMONESE",
    nazione: "ITALIA",
  },
  { codeKey: "A040", sigla: "VT", provincia: "ACQUAPENDENTE", nazione: "ITALIA" },
  { codeKey: "A041", sigla: "CS", provincia: "ACQUAPPESA", nazione: "ITALIA" },
  {
    codeKey: "A042",
    sigla: "LE",
    provincia: "ACQUARICA DEL CAPO",
    nazione: "ITALIA",
  },
  { codeKey: "A043", sigla: "VV", provincia: "ACQUARO", nazione: "ITALIA" },
  { codeKey: "A044", sigla: "AP", provincia: "ACQUASANTA TERME", nazione: "ITALIA" },
  { codeKey: "A045", sigla: "TR", provincia: "ACQUASPARTA", nazione: "ITALIA" },
  { codeKey: "A047", sigla: "AP", provincia: "ACQUAVIVA PICENA", nazione: "ITALIA" },
  {
    codeKey: "A048",
    sigla: "BA",
    provincia: "ACQUAVIVA DELLE FONTI",
    nazione: "ITALIA",
  },
  {
    codeKey: "A049",
    sigla: "CL",
    provincia: "ACQUAVIVA PLATANI",
    nazione: "ITALIA",
  },
  {
    codeKey: "A050",
    sigla: "CB",
    provincia: "ACQUAVIVA COLLECROCE",
    nazione: "ITALIA",
  },
  {
    codeKey: "A051",
    sigla: "IS",
    provincia: "ACQUAVIVA D'ISERNIA",
    nazione: "ITALIA",
  },
  { codeKey: "A052", sigla: "AL", provincia: "ACQUI TERME", nazione: "ITALIA" },
  { codeKey: "A053", sigla: "CS", provincia: "ACRI", nazione: "ITALIA" },
  { codeKey: "A054", sigla: "FR", provincia: "ACUTO", nazione: "ITALIA" },
  { codeKey: "A055", sigla: "BA", provincia: "ADELFIA", nazione: "ITALIA" },
  { codeKey: "A056", sigla: "CT", provincia: "ADRANO", nazione: "ITALIA" },
  {
    codeKey: "A057",
    sigla: "BG",
    provincia: "ADRARA SAN MARTINO",
    nazione: "ITALIA",
  },
  { codeKey: "A058", sigla: "BG", provincia: "ADRARA SAN ROCCO", nazione: "ITALIA" },
  { codeKey: "A059", sigla: "RO", provincia: "ADRIA", nazione: "ITALIA" },
  { codeKey: "A060", sigla: "BS", provincia: "ADRO", nazione: "ITALIA" },
  { codeKey: "A061", sigla: "VR", provincia: "AFFI", nazione: "ITALIA" },
  { codeKey: "A062", sigla: "RM", provincia: "AFFILE", nazione: "ITALIA" },
  { codeKey: "A064", sigla: "NA", provincia: "AFRAGOLA", nazione: "ITALIA" },
  { codeKey: "A065", sigla: "RC", provincia: "AFRICO", nazione: "ITALIA" },
  { codeKey: "A067", sigla: "PC", provincia: "AGAZZANO", nazione: "ITALIA" },
  { codeKey: "A068", sigla: "NA", provincia: "AGEROLA", nazione: "ITALIA" },
  { codeKey: "A069", sigla: "OT", provincia: "AGGIUS", nazione: "ITALIA" },
  { codeKey: "A070", sigla: "EN", provincia: "AGIRA", nazione: "ITALIA" },
  { codeKey: "A071", sigla: "PT", provincia: "AGLIANA", nazione: "ITALIA" },
  { codeKey: "A072", sigla: "AT", provincia: "AGLIANO TERME", nazione: "ITALIA" },
  { codeKey: "A074", sigla: "TO", provincia: "AGLIE'", nazione: "ITALIA" },
  { codeKey: "A075", sigla: "PD", provincia: "AGNA", nazione: "ITALIA" },
  { codeKey: "A076", sigla: "CR", provincia: "AGNADELLO", nazione: "ITALIA" },
  { codeKey: "A077", sigla: "RC", provincia: "AGNANA CALABRA", nazione: "ITALIA" },
  { codeKey: "A080", sigla: "IS", provincia: "AGNONE", nazione: "ITALIA" },
  { codeKey: "A081", sigla: "FR", provincia: "VILLA LATINA", nazione: "ITALIA" },
  { codeKey: "A082", sigla: "BS", provincia: "AGNOSINE", nazione: "ITALIA" },
  { codeKey: "A083", sigla: "BL", provincia: "AGORDO", nazione: "ITALIA" },
  { codeKey: "A084", sigla: "RM", provincia: "AGOSTA", nazione: "ITALIA" },
  { codeKey: "A085", sigla: "VA", provincia: "AGRA", nazione: "ITALIA" },
  { codeKey: "A087", sigla: "MB", provincia: "AGRATE BRIANZA", nazione: "ITALIA" },
  { codeKey: "A088", sigla: "NO", provincia: "AGRATE CONTURBIA", nazione: "ITALIA" },
  { codeKey: "A089", sigla: "AG", provincia: "AGRIGENTO", nazione: "ITALIA" },
  { codeKey: "A091", sigla: "SA", provincia: "AGROPOLI", nazione: "ITALIA" },
  { codeKey: "A092", sigla: "AN", provincia: "AGUGLIANO", nazione: "ITALIA" },
  { codeKey: "A093", sigla: "VI", provincia: "AGUGLIARO", nazione: "ITALIA" },
  { codeKey: "A094", sigla: "AO", provincia: "AYAS", nazione: "ITALIA" },
  { codeKey: "A096", sigla: "MB", provincia: "AICURZIO", nazione: "ITALIA" },
  { codeKey: "A097", sigla: "OR", provincia: "AIDOMAGGIORE", nazione: "ITALIA" },
  { codeKey: "A098", sigla: "EN", provincia: "AIDONE", nazione: "ITALIA" },
  { codeKey: "A100", sigla: "AQ", provincia: "AIELLI", nazione: "ITALIA" },
  {
    codeKey: "A101",
    sigla: "AV",
    provincia: "AIELLO DEL SABATO",
    nazione: "ITALIA",
  },
  { codeKey: "A102", sigla: "CS", provincia: "AIELLO CALABRO", nazione: "ITALIA" },
  {
    codeKey: "A103",
    sigla: "UD",
    provincia: "AIELLO DEL FRIULI",
    nazione: "ITALIA",
  },
  { codeKey: "A105", sigla: "CS", provincia: "AIETA", nazione: "ITALIA" },
  { codeKey: "A106", sigla: "CE", provincia: "AILANO", nazione: "ITALIA" },
  { codeKey: "A107", sigla: "BI", provincia: "AILOCHE", nazione: "ITALIA" },
  { codeKey: "A108", sigla: "AO", provincia: "AYMAVILLES", nazione: "ITALIA" },
  { codeKey: "A109", sigla: "TO", provincia: "AIRASCA", nazione: "ITALIA" },
  { codeKey: "A110", sigla: "BN", provincia: "AIROLA", nazione: "ITALIA" },
  { codeKey: "A111", sigla: "IM", provincia: "AIROLE", nazione: "ITALIA" },
  { codeKey: "A112", sigla: "LC", provincia: "AIRUNO", nazione: "ITALIA" },
  { codeKey: "A113", sigla: "CN", provincia: "AISONE", nazione: "ITALIA" },
  { codeKey: "A115", sigla: "OT", provincia: "ALA' DEI SARDI", nazione: "ITALIA" },
  { codeKey: "A116", sigla: "TN", provincia: "ALA", nazione: "ITALIA" },
  { codeKey: "A117", sigla: "TO", provincia: "ALA DI STURA", nazione: "ITALIA" },
  { codeKey: "A118", sigla: "PV", provincia: "ALAGNA", nazione: "ITALIA" },
  { codeKey: "A119", sigla: "VC", provincia: "ALAGNA VALSESIA", nazione: "ITALIA" },
  { codeKey: "A120", sigla: "PE", provincia: "ALANNO", nazione: "ITALIA" },
  { codeKey: "A121", sigla: "BL", provincia: "ALANO DI PIAVE", nazione: "ITALIA" },
  { codeKey: "A122", sigla: "SV", provincia: "ALASSIO", nazione: "ITALIA" },
  { codeKey: "A123", sigla: "FR", provincia: "ALATRI", nazione: "ITALIA" },
  { codeKey: "A124", sigla: "CN", provincia: "ALBA", nazione: "ITALIA" },
  { codeKey: "A125", sigla: "TE", provincia: "ALBA ADRIATICA", nazione: "ITALIA" },
  { codeKey: "A126", sigla: "OR", provincia: "ALBAGIARA", nazione: "ITALIA" },
  { codeKey: "A127", sigla: "MI", provincia: "ALBAIRATE", nazione: "ITALIA" },
  { codeKey: "A128", sigla: "SA", provincia: "ALBANELLA", nazione: "ITALIA" },
  {
    codeKey: "A129",
    sigla: "BG",
    provincia: "ALBANO SANT'ALESSANDRO",
    nazione: "ITALIA",
  },
  {
    codeKey: "A130",
    sigla: "VC",
    provincia: "ALBANO VERCELLESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "A131",
    sigla: "PZ",
    provincia: "ALBANO DI LUCANIA",
    nazione: "ITALIA",
  },
  { codeKey: "A132", sigla: "RM", provincia: "ALBANO LAZIALE", nazione: "ITALIA" },
  {
    codeKey: "A134",
    sigla: "PV",
    provincia: "ALBAREDO ARNABOLDI",
    nazione: "ITALIA",
  },
  {
    codeKey: "A135",
    sigla: "SO",
    provincia: "ALBAREDO PER SAN MARCO",
    nazione: "ITALIA",
  },
  { codeKey: "A137", sigla: "VR", provincia: "ALBAREDO D'ADIGE", nazione: "ITALIA" },
  { codeKey: "A138", sigla: "PR", provincia: "ALBARETO", nazione: "ITALIA" },
  {
    codeKey: "A139",
    sigla: "CN",
    provincia: "ALBARETTO DELLA TORRE",
    nazione: "ITALIA",
  },
  { codeKey: "A143", sigla: "CO", provincia: "ALBAVILLA", nazione: "ITALIA" },
  { codeKey: "A145", sigla: "SV", provincia: "ALBENGA", nazione: "ITALIA" },
  { codeKey: "A146", sigla: "AL", provincia: "ALBERA LIGURE", nazione: "ITALIA" },
  { codeKey: "A149", sigla: "BA", provincia: "ALBEROBELLO", nazione: "ITALIA" },
  { codeKey: "A150", sigla: "FG", provincia: "ALBERONA", nazione: "ITALIA" },
  {
    codeKey: "A153",
    sigla: "CO",
    provincia: "ALBESE CON CASSANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "A995",
    sigla: "LO",
    provincia: "BORGHETTO LODIGIANO",
    nazione: "ITALIA",
  },
  { codeKey: "A996", sigla: "RI", provincia: "BORGO VELINO", nazione: "ITALIA" },
  {
    codeKey: "A998",
    sigla: "AL",
    provincia: "BORGHETTO DI BORBERA",
    nazione: "ITALIA",
  },
  {
    codeKey: "A999",
    sigla: "SV",
    provincia: "BORGHETTO SANTO SPIRITO",
    nazione: "ITALIA",
  },
  { codeKey: "B001", sigla: "FC", provincia: "BORGHI", nazione: "ITALIA" },
  { codeKey: "B002", sigla: "CZ", provincia: "BORGIA", nazione: "ITALIA" },
  { codeKey: "B003", sigla: "TO", provincia: "BORGIALLO", nazione: "ITALIA" },
  { codeKey: "B005", sigla: "SV", provincia: "BORGIO VEREZZI", nazione: "ITALIA" },
  { codeKey: "B006", sigla: "TN", provincia: "BORGO VALSUGANA", nazione: "ITALIA" },
  { codeKey: "B007", sigla: "LU", provincia: "BORGO A MOZZANO", nazione: "ITALIA" },
  { codeKey: "B008", sigla: "RI", provincia: "BORGOROSE", nazione: "ITALIA" },
  { codeKey: "B009", sigla: "VC", provincia: "BORGO D'ALE", nazione: "ITALIA" },
  { codeKey: "B010", sigla: "BG", provincia: "BORGO DI TERZO", nazione: "ITALIA" },
  { codeKey: "B012", sigla: "MN", provincia: "MOTTEGGIANA", nazione: "ITALIA" },
  {
    codeKey: "B013",
    sigla: "MN",
    provincia: "BORGOFRANCO SUL PO",
    nazione: "ITALIA",
  },
  { codeKey: "B014", sigla: "PV", provincia: "SUARDI", nazione: "ITALIA" },
  {
    codeKey: "B015",
    sigla: "TO",
    provincia: "BORGOFRANCO D'IVREA",
    nazione: "ITALIA",
  },
  { codeKey: "B016", sigla: "NO", provincia: "BORGOLAVEZZARO", nazione: "ITALIA" },
  {
    codeKey: "B017",
    sigla: "LO",
    provincia: "BORGO SAN GIOVANNI",
    nazione: "ITALIA",
  },
  { codeKey: "B018", sigla: "CN", provincia: "BORGOMALE", nazione: "ITALIA" },
  { codeKey: "B019", sigla: "NO", provincia: "BORGOMANERO", nazione: "ITALIA" },
  { codeKey: "B020", sigla: "IM", provincia: "BORGOMARO", nazione: "ITALIA" },
  { codeKey: "B021", sigla: "TO", provincia: "BORGOMASINO", nazione: "ITALIA" },
  { codeKey: "B024", sigla: "TO", provincia: "BORGONE SUSA", nazione: "ITALIA" },
  {
    codeKey: "B025",
    sigla: "PC",
    provincia: "BORGONOVO VAL TIDONE",
    nazione: "ITALIA",
  },
  { codeKey: "B026", sigla: "PU", provincia: "BORGO PACE", nazione: "ITALIA" },
  { codeKey: "B028", sigla: "PV", provincia: "BORGO PRIOLO", nazione: "ITALIA" },
  {
    codeKey: "B029",
    sigla: "AL",
    provincia: "BORGORATTO ALESSANDRINO",
    nazione: "ITALIA",
  },
  {
    codeKey: "B030",
    sigla: "PV",
    provincia: "BORGORATTO MORMOROLO",
    nazione: "ITALIA",
  },
  { codeKey: "B031", sigla: "PD", provincia: "BORGORICCO", nazione: "ITALIA" },
  {
    codeKey: "B033",
    sigla: "CN",
    provincia: "BORGO SAN DALMAZZO",
    nazione: "ITALIA",
  },
  { codeKey: "B034", sigla: "PR", provincia: "FIDENZA", nazione: "ITALIA" },
  {
    codeKey: "B035",
    sigla: "BS",
    provincia: "BORGO SAN GIACOMO",
    nazione: "ITALIA",
  },
  {
    codeKey: "B036",
    sigla: "FI",
    provincia: "BORGO SAN LORENZO",
    nazione: "ITALIA",
  },
  {
    codeKey: "B037",
    sigla: "AL",
    provincia: "BORGO SAN MARTINO",
    nazione: "ITALIA",
  },
  { codeKey: "B038", sigla: "PV", provincia: "BORGO SAN SIRO", nazione: "ITALIA" },
  { codeKey: "B040", sigla: "BS", provincia: "BORGOSATOLLO", nazione: "ITALIA" },
  { codeKey: "B041", sigla: "VC", provincia: "BORGOSESIA", nazione: "ITALIA" },
  {
    codeKey: "B042",
    sigla: "PR",
    provincia: "BORGO VAL DI TARO",
    nazione: "ITALIA",
  },
  { codeKey: "B043", sigla: "NO", provincia: "BORGO TICINO", nazione: "ITALIA" },
  { codeKey: "B044", sigla: "BO", provincia: "BORGO TOSSIGNANO", nazione: "ITALIA" },
  { codeKey: "B046", sigla: "VC", provincia: "BORGO VERCELLI", nazione: "ITALIA" },
  { codeKey: "B048", sigla: "SV", provincia: "BORMIDA", nazione: "ITALIA" },
  { codeKey: "B049", sigla: "SO", provincia: "BORMIO", nazione: "ITALIA" },
  { codeKey: "B051", sigla: "PV", provincia: "BORNASCO", nazione: "ITALIA" },
  { codeKey: "B054", sigla: "BS", provincia: "BORNO", nazione: "ITALIA" },
  { codeKey: "B055", sigla: "OR", provincia: "BORONEDDU", nazione: "ITALIA" },
  { codeKey: "B056", sigla: "NU", provincia: "BORORE", nazione: "ITALIA" },
  { codeKey: "B057", sigla: "CH", provincia: "BORRELLO", nazione: "ITALIA" },
  { codeKey: "B058", sigla: "BI", provincia: "BORRIANA", nazione: "ITALIA" },
  { codeKey: "B061", sigla: "TV", provincia: "BORSO DEL GRAPPA", nazione: "ITALIA" },
  { codeKey: "B062", sigla: "NU", provincia: "BORTIGALI", nazione: "ITALIA" },
  { codeKey: "B063", sigla: "OT", provincia: "BORTIGIADAS", nazione: "ITALIA" },
  { codeKey: "B064", sigla: "SS", provincia: "BORUTTA", nazione: "ITALIA" },
  { codeKey: "B067", sigla: "GE", provincia: "BORZONASCA", nazione: "ITALIA" },
  { codeKey: "B068", sigla: "NU", provincia: "BOSA", nazione: "ITALIA" },
  { codeKey: "B069", sigla: "RO", provincia: "BOSARO", nazione: "ITALIA" },
  { codeKey: "B070", sigla: "VR", provincia: "BOSCHI SANT'ANNA", nazione: "ITALIA" },
  { codeKey: "B071", sigla: "AL", provincia: "BOSCO MARENGO", nazione: "ITALIA" },
  {
    codeKey: "B073",
    sigla: "VR",
    provincia: "BOSCO CHIESANUOVA",
    nazione: "ITALIA",
  },
  { codeKey: "B075", sigla: "TO", provincia: "BOSCONERO", nazione: "ITALIA" },
  { codeKey: "B076", sigla: "NA", provincia: "BOSCOREALE", nazione: "ITALIA" },
  { codeKey: "B077", sigla: "NA", provincia: "BOSCOTRECASE", nazione: "ITALIA" },
  { codeKey: "B078", sigla: "TN", provincia: "BOSENTINO", nazione: "ITALIA" },
  { codeKey: "B079", sigla: "CN", provincia: "BOSIA", nazione: "ITALIA" },
  { codeKey: "B080", sigla: "AL", provincia: "BOSIO", nazione: "ITALIA" },
  { codeKey: "B081", sigla: "LC", provincia: "BOSISIO PARINI", nazione: "ITALIA" },
  { codeKey: "B082", sigla: "PV", provincia: "BOSNASCO", nazione: "ITALIA" },
  { codeKey: "B083", sigla: "BG", provincia: "BOSSICO", nazione: "ITALIA" },
  { codeKey: "B084", sigla: "CN", provincia: "BOSSOLASCO", nazione: "ITALIA" },
  { codeKey: "B085", sigla: "CZ", provincia: "BOTRICELLO", nazione: "ITALIA" },
  { codeKey: "B086", sigla: "LE", provincia: "BOTRUGNO", nazione: "ITALIA" },
  { codeKey: "B088", sigla: "BG", provincia: "BOTTANUCO", nazione: "ITALIA" },
  { codeKey: "B091", sigla: "BS", provincia: "BOTTICINO", nazione: "ITALIA" },
  { codeKey: "B094", sigla: "SS", provincia: "BOTTIDDA", nazione: "ITALIA" },
  { codeKey: "B097", sigla: "RC", provincia: "BOVA", nazione: "ITALIA" },
  { codeKey: "B098", sigla: "RC", provincia: "BOVALINO", nazione: "ITALIA" },
  { codeKey: "B099", sigla: "RC", provincia: "BOVA MARINA", nazione: "ITALIA" },
  { codeKey: "B100", sigla: "BS", provincia: "BOVEGNO", nazione: "ITALIA" },
  { codeKey: "B101", sigla: "CN", provincia: "BOVES", nazione: "ITALIA" },
  { codeKey: "B102", sigla: "BS", provincia: "BOVEZZO", nazione: "ITALIA" },
  { codeKey: "B104", sigla: "FG", provincia: "BOVINO", nazione: "ITALIA" },
  { codeKey: "B105", sigla: "MB", provincia: "BOVISIO-MASCIAGO", nazione: "ITALIA" },
  { codeKey: "B106", sigla: "PD", provincia: "BOVOLENTA", nazione: "ITALIA" },
  { codeKey: "B107", sigla: "VR", provincia: "BOVOLONE", nazione: "ITALIA" },
  { codeKey: "B109", sigla: "AL", provincia: "BOZZOLE", nazione: "ITALIA" },
  { codeKey: "B110", sigla: "MN", provincia: "BOZZOLO", nazione: "ITALIA" },
  { codeKey: "B111", sigla: "CN", provincia: "BRA", nazione: "ITALIA" },
  { codeKey: "B112", sigla: "BG", provincia: "BRACCA", nazione: "ITALIA" },
  { codeKey: "B114", sigla: "RM", provincia: "BRACCIANO", nazione: "ITALIA" },
  { codeKey: "B115", sigla: "SA", provincia: "BRACIGLIANO", nazione: "ITALIA" },
  { codeKey: "B116", sigla: "BZ", provincia: "BRAIES", nazione: "ITALIA" },
  {
    codeKey: "B117",
    sigla: "PV",
    provincia: "BRALLO DI PREGOLA",
    nazione: "ITALIA",
  },
  { codeKey: "B118", sigla: "RC", provincia: "BRANCALEONE", nazione: "ITALIA" },
  { codeKey: "B120", sigla: "BS", provincia: "BRANDICO", nazione: "ITALIA" },
  { codeKey: "B121", sigla: "TO", provincia: "BRANDIZZO", nazione: "ITALIA" },
  { codeKey: "B123", sigla: "BG", provincia: "BRANZI", nazione: "ITALIA" },
  { codeKey: "B124", sigla: "BS", provincia: "BRAONE", nazione: "ITALIA" },
  { codeKey: "B126", sigla: "VA", provincia: "BREBBIA", nazione: "ITALIA" },
  { codeKey: "B128", sigla: "TV", provincia: "BREDA DI PIAVE", nazione: "ITALIA" },
  { codeKey: "B129", sigla: "CR", provincia: "CASTELVERDE", nazione: "ITALIA" },
  { codeKey: "B131", sigla: "VA", provincia: "BREGANO", nazione: "ITALIA" },
  { codeKey: "B132", sigla: "VI", provincia: "BREGANZE", nazione: "ITALIA" },
  { codeKey: "B134", sigla: "CO", provincia: "BREGNANO", nazione: "ITALIA" },
  { codeKey: "B135", sigla: "TN", provincia: "BREGUZZO", nazione: "ITALIA" },
  { codeKey: "B136", sigla: "VC", provincia: "BREIA", nazione: "ITALIA" },
  { codeKey: "B137", sigla: "BG", provincia: "BREMBATE", nazione: "ITALIA" },
  {
    codeKey: "B138",
    sigla: "BG",
    provincia: "BREMBATE DI SOPRA",
    nazione: "ITALIA",
  },
  { codeKey: "B141", sigla: "LO", provincia: "BREMBIO", nazione: "ITALIA" },
  { codeKey: "B142", sigla: "PV", provincia: "BREME", nazione: "ITALIA" },
  { codeKey: "B143", sigla: "VI", provincia: "BRENDOLA", nazione: "ITALIA" },
  { codeKey: "B144", sigla: "CO", provincia: "BRENNA", nazione: "ITALIA" },
  { codeKey: "B145", sigla: "BZ", provincia: "BRENNERO", nazione: "ITALIA" },
  { codeKey: "B149", sigla: "BS", provincia: "BRENO", nazione: "ITALIA" },
  { codeKey: "B150", sigla: "VA", provincia: "BRENTA", nazione: "ITALIA" },
  { codeKey: "B152", sigla: "VR", provincia: "BRENTINO BELLUNO", nazione: "ITALIA" },
  { codeKey: "B153", sigla: "TN", provincia: "BRENTONICO", nazione: "ITALIA" },
  {
    codeKey: "B154",
    sigla: "VR",
    provincia: "BRENZONE SUL GARDA",
    nazione: "ITALIA",
  },
  { codeKey: "B156", sigla: "RE", provincia: "BRESCELLO", nazione: "ITALIA" },
  { codeKey: "B157", sigla: "BS", provincia: "BRESCIA", nazione: "ITALIA" },
  { codeKey: "B158", sigla: "TN", provincia: "BRESIMO", nazione: "ITALIA" },
  {
    codeKey: "B159",
    sigla: "PV",
    provincia: "BRESSANA BOTTARONE",
    nazione: "ITALIA",
  },
  { codeKey: "B160", sigla: "BZ", provincia: "BRESSANONE", nazione: "ITALIA" },
  { codeKey: "B161", sigla: "VI", provincia: "BRESSANVIDO", nazione: "ITALIA" },
  { codeKey: "B162", sigla: "MI", provincia: "BRESSO", nazione: "ITALIA" },
  { codeKey: "B165", sigla: "TN", provincia: "BREZ", nazione: "ITALIA" },
  { codeKey: "B166", sigla: "VA", provincia: "BREZZO DI BEDERO", nazione: "ITALIA" },
  { codeKey: "B167", sigla: "CN", provincia: "BRIAGLIA", nazione: "ITALIA" },
  { codeKey: "B169", sigla: "VV", provincia: "BRIATICO", nazione: "ITALIA" },
  { codeKey: "B171", sigla: "TO", provincia: "BRICHERASIO", nazione: "ITALIA" },
  { codeKey: "B172", sigla: "CO", provincia: "BRIENNO", nazione: "ITALIA" },
  { codeKey: "B173", sigla: "PZ", provincia: "BRIENZA", nazione: "ITALIA" },
  { codeKey: "B175", sigla: "CN", provincia: "BRIGA ALTA", nazione: "ITALIA" },
  { codeKey: "B176", sigla: "NO", provincia: "BRIGA NOVARESE", nazione: "ITALIA" },
  {
    codeKey: "B178",
    sigla: "BG",
    provincia: "BRIGNANO GERA D'ADDA",
    nazione: "ITALIA",
  },
  {
    codeKey: "B179",
    sigla: "AL",
    provincia: "BRIGNANO-FRASCATA",
    nazione: "ITALIA",
  },
  { codeKey: "B180", sigla: "BR", provincia: "BRINDISI", nazione: "ITALIA" },
  {
    codeKey: "B181",
    sigla: "PZ",
    provincia: "BRINDISI MONTAGNA",
    nazione: "ITALIA",
  },
  { codeKey: "B182", sigla: "VA", provincia: "BRINZIO", nazione: "ITALIA" },
  { codeKey: "B183", sigla: "NO", provincia: "BRIONA", nazione: "ITALIA" },
  { codeKey: "B184", sigla: "BS", provincia: "BRIONE", nazione: "ITALIA" },
  { codeKey: "B185", sigla: "TN", provincia: "BRIONE", nazione: "ITALIA" },
  { codeKey: "B187", sigla: "MB", provincia: "BRIOSCO", nazione: "ITALIA" },
  { codeKey: "B188", sigla: "RA", provincia: "BRISIGHELLA", nazione: "ITALIA" },
  {
    codeKey: "B191",
    sigla: "VA",
    provincia: "BRISSAGO-VALTRAVAGLIA",
    nazione: "ITALIA",
  },
  { codeKey: "B192", sigla: "AO", provincia: "BRISSOGNE", nazione: "ITALIA" },
  { codeKey: "B193", sigla: "PE", provincia: "BRITTOLI", nazione: "ITALIA" },
  { codeKey: "B194", sigla: "LC", provincia: "BRIVIO", nazione: "ITALIA" },
  { codeKey: "B195", sigla: "FR", provincia: "BROCCOSTELLA", nazione: "ITALIA" },
  {
    codeKey: "B196",
    sigla: "VI",
    provincia: "BROGLIANO",
    nazione: "ITALIA",
  },
  { codeKey: "B197", sigla: "VV", provincia: "BROGNATURO", nazione: "ITALIA" },
  { codeKey: "B198", sigla: "ME", provincia: "BROLO", nazione: "ITALIA" },
  { codeKey: "B200", sigla: "CN", provincia: "BRONDELLO", nazione: "ITALIA" },
  { codeKey: "B201", sigla: "PV", provincia: "BRONI", nazione: "ITALIA" },
  { codeKey: "B202", sigla: "CT", provincia: "BRONTE", nazione: "ITALIA" },
  { codeKey: "B203", sigla: "BZ", provincia: "BRONZOLO", nazione: "ITALIA" },
  { codeKey: "B204", sigla: "CN", provincia: "BROSSASCO", nazione: "ITALIA" },
  { codeKey: "B205", sigla: "TO", provincia: "BROSSO", nazione: "ITALIA" },
  {
    codeKey: "B207",
    sigla: "VB",
    provincia: "BROVELLO-CARPUGNINO",
    nazione: "ITALIA",
  },
  { codeKey: "B209", sigla: "TO", provincia: "BROZOLO", nazione: "ITALIA" },
  { codeKey: "B212", sigla: "MB", provincia: "BRUGHERIO", nazione: "ITALIA" },
  { codeKey: "B213", sigla: "PD", provincia: "BRUGINE", nazione: "ITALIA" },
  { codeKey: "B214", sigla: "SP", provincia: "BRUGNATO", nazione: "ITALIA" },
  { codeKey: "B215", sigla: "PN", provincia: "BRUGNERA", nazione: "ITALIA" },
  { codeKey: "B216", sigla: "TO", provincia: "BRUINO", nazione: "ITALIA" },
  { codeKey: "B217", sigla: "BG", provincia: "BRUMANO", nazione: "ITALIA" },
  { codeKey: "B218", sigla: "CO", provincia: "BRUNATE", nazione: "ITALIA" },
  { codeKey: "B219", sigla: "VA", provincia: "BRUNELLO", nazione: "ITALIA" },
  { codeKey: "B220", sigla: "BZ", provincia: "BRUNICO", nazione: "ITALIA" },
  { codeKey: "B221", sigla: "AT", provincia: "BRUNO", nazione: "ITALIA" },
  { codeKey: "B223", sigla: "BG", provincia: "BRUSAPORTO", nazione: "ITALIA" },
  { codeKey: "B225", sigla: "TO", provincia: "BRUSASCO", nazione: "ITALIA" },
  { codeKey: "B227", sigla: "NA", provincia: "BRUSCIANO", nazione: "ITALIA" },
  { codeKey: "B228", sigla: "VA", provincia: "BRUSIMPIANO", nazione: "ITALIA" },
  { codeKey: "B229", sigla: "BI", provincia: "BRUSNENGO", nazione: "ITALIA" },
  { codeKey: "B230", sigla: "AO", provincia: "BRUSSON", nazione: "ITALIA" },
  { codeKey: "B232", sigla: "TO", provincia: "BRUZOLO", nazione: "ITALIA" },
  {
    codeKey: "B234",
    sigla: "RC",
    provincia: "BRUZZANO ZEFFIRIO",
    nazione: "ITALIA",
  },
  { codeKey: "B235", sigla: "MI", provincia: "BUBBIANO", nazione: "ITALIA" },
  { codeKey: "B236", sigla: "AT", provincia: "BUBBIO", nazione: "ITALIA" },
  { codeKey: "B237", sigla: "SR", provincia: "BUCCHERI", nazione: "ITALIA" },
  { codeKey: "B238", sigla: "CH", provincia: "BUCCHIANICO", nazione: "ITALIA" },
  { codeKey: "B239", sigla: "BN", provincia: "BUCCIANO", nazione: "ITALIA" },
  { codeKey: "B240", sigla: "MI", provincia: "BUCCINASCO", nazione: "ITALIA" },
  { codeKey: "B242", sigla: "SA", provincia: "BUCCINO", nazione: "ITALIA" },
  { codeKey: "B243", sigla: "AR", provincia: "BUCINE", nazione: "ITALIA" },
  { codeKey: "B246", sigla: "OT", provincia: "BUDDUSO'", nazione: "ITALIA" },
  { codeKey: "B247", sigla: "PN", provincia: "BUDOIA", nazione: "ITALIA" },
  { codeKey: "B248", sigla: "OT", provincia: "BUDONI", nazione: "ITALIA" },
  { codeKey: "B249", sigla: "BO", provincia: "BUDRIO", nazione: "ITALIA" },
  { codeKey: "B250", sigla: "CI", provincia: "BUGGERRU", nazione: "ITALIA" },
  { codeKey: "B251", sigla: "PT", provincia: "BUGGIANO", nazione: "ITALIA" },
  { codeKey: "B255", sigla: "SO", provincia: "BUGLIO IN MONTE", nazione: "ITALIA" },
  { codeKey: "B256", sigla: "AQ", provincia: "BUGNARA", nazione: "ITALIA" },
  { codeKey: "B258", sigla: "VA", provincia: "BUGUGGIATE", nazione: "ITALIA" },
  { codeKey: "B259", sigla: "UD", provincia: "BUJA", nazione: "ITALIA" },
  { codeKey: "B261", sigla: "LC", provincia: "BULCIAGO", nazione: "ITALIA" },
  { codeKey: "B262", sigla: "CO", provincia: "BULGAROGRASSO", nazione: "ITALIA" },
  { codeKey: "B264", sigla: "SS", provincia: "BULTEI", nazione: "ITALIA" },
  { codeKey: "B265", sigla: "SS", provincia: "BULZI", nazione: "ITALIA" },
  { codeKey: "B266", sigla: "SA", provincia: "BUONABITACOLO", nazione: "ITALIA" },
  { codeKey: "B267", sigla: "BN", provincia: "BUONALBERGO", nazione: "ITALIA" },
  {
    codeKey: "B268",
    sigla: "CH",
    provincia: "MONTEBELLO SUL SANGRO",
    nazione: "ITALIA",
  },
  { codeKey: "B269", sigla: "SI", provincia: "BUONCONVENTO", nazione: "ITALIA" },
  { codeKey: "B270", sigla: "CS", provincia: "BUONVICINO", nazione: "ITALIA" },
  {
    codeKey: "B272",
    sigla: "MB",
    provincia: "BURAGO DI MOLGORA",
    nazione: "ITALIA",
  },
  { codeKey: "B274", sigla: "CA", provincia: "BURCEI", nazione: "ITALIA" },
  { codeKey: "B275", sigla: "AG", provincia: "BURGIO", nazione: "ITALIA" },
  { codeKey: "B276", sigla: "SS", provincia: "BURGOS", nazione: "ITALIA" },
  { codeKey: "B278", sigla: "TO", provincia: "BURIASCO", nazione: "ITALIA" },
  { codeKey: "B279", sigla: "TO", provincia: "BUROLO", nazione: "ITALIA" },
  { codeKey: "B280", sigla: "VC", provincia: "BURONZO", nazione: "ITALIA" },
  { codeKey: "B281", sigla: "OR", provincia: "BUSACHI", nazione: "ITALIA" },
  { codeKey: "B282", sigla: "GE", provincia: "BUSALLA", nazione: "ITALIA" },
  { codeKey: "B283", sigla: "RE", provincia: "BUSANA", nazione: "ITALIA" },
  { codeKey: "B284", sigla: "TO", provincia: "BUSANO", nazione: "ITALIA" },
  { codeKey: "B285", sigla: "CN", provincia: "BUSCA", nazione: "ITALIA" },
  { codeKey: "B286", sigla: "MI", provincia: "BUSCATE", nazione: "ITALIA" },
  { codeKey: "B287", sigla: "SR", provincia: "BUSCEMI", nazione: "ITALIA" },
  { codeKey: "B288", sigla: "TP", provincia: "BUSETO PALIZZOLO", nazione: "ITALIA" },
  { codeKey: "B289", sigla: "MB", provincia: "BUSNAGO", nazione: "ITALIA" },
  { codeKey: "B292", sigla: "MI", provincia: "BUSSERO", nazione: "ITALIA" },
  { codeKey: "B293", sigla: "PR", provincia: "BUSSETO", nazione: "ITALIA" },
  { codeKey: "B294", sigla: "PE", provincia: "BUSSI SUL TIRINO", nazione: "ITALIA" },
  { codeKey: "B295", sigla: "CB", provincia: "BUSSO", nazione: "ITALIA" },
  { codeKey: "B296", sigla: "VR", provincia: "BUSSOLENGO", nazione: "ITALIA" },
  { codeKey: "B297", sigla: "TO", provincia: "BUSSOLENO", nazione: "ITALIA" },
  { codeKey: "B300", sigla: "VA", provincia: "BUSTO ARSIZIO", nazione: "ITALIA" },
  { codeKey: "B301", sigla: "MI", provincia: "BUSTO GAROLFO", nazione: "ITALIA" },
  { codeKey: "B302", sigla: "CL", provincia: "BUTERA", nazione: "ITALIA" },
  { codeKey: "B303", sigla: "PI", provincia: "BUTI", nazione: "ITALIA" },
  { codeKey: "B304", sigla: "VR", provincia: "BUTTAPIETRA", nazione: "ITALIA" },
  { codeKey: "B305", sigla: "TO", provincia: "BUTTIGLIERA ALTA", nazione: "ITALIA" },
  {
    codeKey: "B306",
    sigla: "AT",
    provincia: "BUTTIGLIERA D'ASTI",
    nazione: "ITALIA",
  },
  { codeKey: "B309", sigla: "UD", provincia: "BUTTRIO", nazione: "ITALIA" },
  {
    codeKey: "B310",
    sigla: "BG",
    provincia: "SAN PAOLO D'ARGON",
    nazione: "ITALIA",
  },
  { codeKey: "B311", sigla: "AL", provincia: "CABELLA LIGURE", nazione: "ITALIA" },
  {
    codeKey: "B312",
    sigla: "VA",
    provincia: "CASTELLO CABIAGLIO",
    nazione: "ITALIA",
  },
  { codeKey: "B313", sigla: "CO", provincia: "CABIATE", nazione: "ITALIA" },
  { codeKey: "B314", sigla: "OR", provincia: "CABRAS", nazione: "ITALIA" },
  { codeKey: "B315", sigla: "PA", provincia: "CACCAMO", nazione: "ITALIA" },
  { codeKey: "B317", sigla: "IS", provincia: "POGGIO SANNITA", nazione: "ITALIA" },
  { codeKey: "B319", sigla: "KR", provincia: "CACCURI", nazione: "ITALIA" },
  { codeKey: "B320", sigla: "CR", provincia: "CA' D'ANDREA", nazione: "ITALIA" },
  {
    codeKey: "B326",
    sigla: "VA",
    provincia: "CADEGLIANO-VICONAGO",
    nazione: "ITALIA",
  },
  {
    codeKey: "B328",
    sigla: "RE",
    provincia: "CADELBOSCO DI SOPRA",
    nazione: "ITALIA",
  },
  { codeKey: "B332", sigla: "PC", provincia: "CADEO", nazione: "ITALIA" },
  { codeKey: "B335", sigla: "TN", provincia: "CADERZONE", nazione: "ITALIA" },
  { codeKey: "B345", sigla: "PD", provincia: "CADONEGHE", nazione: "ITALIA" },
  { codeKey: "B346", sigla: "CO", provincia: "CADORAGO", nazione: "ITALIA" },
  { codeKey: "B347", sigla: "VA", provincia: "CADREZZATE", nazione: "ITALIA" },
  {
    codeKey: "B349",
    sigla: "TV",
    provincia: "CAERANO DI SAN MARCO",
    nazione: "ITALIA",
  },
  { codeKey: "B350", sigla: "TO", provincia: "CAFASSE", nazione: "ITALIA" },
  { codeKey: "B351", sigla: "SA", provincia: "CAGGIANO", nazione: "ITALIA" },
  { codeKey: "B352", sigla: "PU", provincia: "CAGLI", nazione: "ITALIA" },
  { codeKey: "B354", sigla: "CA", provincia: "CAGLIARI", nazione: "ITALIA" },
  { codeKey: "B355", sigla: "CO", provincia: "CAGLIO", nazione: "ITALIA" },
  { codeKey: "B357", sigla: "FG", provincia: "CAGNANO VARANO", nazione: "ITALIA" },
  { codeKey: "B358", sigla: "AQ", provincia: "CAGNANO AMITERNO", nazione: "ITALIA" },
  { codeKey: "B359", sigla: "CO", provincia: "CAGNO", nazione: "ITALIA" },
  { codeKey: "B360", sigla: "TN", provincia: "CAGNO'", nazione: "ITALIA" },
  { codeKey: "B361", sigla: "CE", provincia: "CAIANELLO", nazione: "ITALIA" },
  { codeKey: "B362", sigla: "CE", provincia: "CAIAZZO", nazione: "ITALIA" },
  { codeKey: "B364", sigla: "BZ", provincia: "CAINES", nazione: "ITALIA" },
  { codeKey: "B365", sigla: "BS", provincia: "CAINO", nazione: "ITALIA" },
  { codeKey: "B366", sigla: "SO", provincia: "CAIOLO", nazione: "ITALIA" },
  { codeKey: "B367", sigla: "AV", provincia: "CAIRANO", nazione: "ITALIA" },
  { codeKey: "B368", sigla: "VA", provincia: "CAIRATE", nazione: "ITALIA" },
  { codeKey: "B369", sigla: "SV", provincia: "CAIRO MONTENOTTE", nazione: "ITALIA" },
  { codeKey: "B371", sigla: "NA", provincia: "CAIVANO", nazione: "ITALIA" },
  { codeKey: "B374", sigla: "AV", provincia: "CALABRITTO", nazione: "ITALIA" },
  {
    codeKey: "B375",
    sigla: "BL",
    provincia: "CALALZO DI CADORE",
    nazione: "ITALIA",
  },
  { codeKey: "B376", sigla: "AT", provincia: "CALAMANDRANA", nazione: "ITALIA" },
  { codeKey: "B377", sigla: "AG", provincia: "CALAMONACI", nazione: "ITALIA" },
  { codeKey: "B378", sigla: "OT", provincia: "CALANGIANUS", nazione: "ITALIA" },
  { codeKey: "B379", sigla: "RC", provincia: "CALANNA", nazione: "ITALIA" },
  {
    codeKey: "B380",
    sigla: "VB",
    provincia: "CALASCA-CASTIGLIONE",
    nazione: "ITALIA",
  },
  { codeKey: "B381", sigla: "EN", provincia: "CALASCIBETTA", nazione: "ITALIA" },
  { codeKey: "B382", sigla: "AQ", provincia: "CALASCIO", nazione: "ITALIA" },
  { codeKey: "B383", sigla: "CI", provincia: "CALASETTA", nazione: "ITALIA" },
  { codeKey: "B384", sigla: "CT", provincia: "CALATABIANO", nazione: "ITALIA" },
  {
    codeKey: "B385",
    sigla: "TP",
    provincia: "CALATAFIMI SEGESTA",
    nazione: "ITALIA",
  },
  { codeKey: "B386", sigla: "TN", provincia: "CALAVINO", nazione: "ITALIA" },
  { codeKey: "B387", sigla: "PV", provincia: "LUNGAVILLA", nazione: "ITALIA" },
  { codeKey: "B388", sigla: "VT", provincia: "CALCATA", nazione: "ITALIA" },
  {
    codeKey: "B389",
    sigla: "TN",
    provincia: "CALCERANICA AL LAGO",
    nazione: "ITALIA",
  },
  { codeKey: "B390", sigla: "PI", provincia: "CALCI", nazione: "ITALIA" },
  { codeKey: "B391", sigla: "MT", provincia: "CALCIANO", nazione: "ITALIA" },
  { codeKey: "B392", sigla: "PI", provincia: "CALCINAIA", nazione: "ITALIA" },
  { codeKey: "B393", sigla: "BG", provincia: "CALCINATE", nazione: "ITALIA" },
  { codeKey: "B394", sigla: "BS", provincia: "CALCINATO", nazione: "ITALIA" },
  { codeKey: "B395", sigla: "BG", provincia: "CALCIO", nazione: "ITALIA" },
  { codeKey: "B396", sigla: "LC", provincia: "CALCO", nazione: "ITALIA" },
  {
    codeKey: "B397",
    sigla: "BZ",
    provincia: "CALDARO SULLA STRADA DEL VINO",
    nazione: "ITALIA",
  },
  { codeKey: "B398", sigla: "MC", provincia: "CALDAROLA", nazione: "ITALIA" },
  {
    codeKey: "B399",
    sigla: "BO",
    provincia: "CALDERARA DI RENO",
    nazione: "ITALIA",
  },
  { codeKey: "B400", sigla: "TN", provincia: "CALDES", nazione: "ITALIA" },
  { codeKey: "B402", sigla: "VR", provincia: "CALDIERO", nazione: "ITALIA" },
  { codeKey: "B403", sigla: "VI", provincia: "CALDOGNO", nazione: "ITALIA" },
  { codeKey: "B404", sigla: "TN", provincia: "CALDONAZZO", nazione: "ITALIA" },
  { codeKey: "B405", sigla: "PC", provincia: "CALENDASCO", nazione: "ITALIA" },
  { codeKey: "B406", sigla: "FI", provincia: "CALENZANO", nazione: "ITALIA" },
  { codeKey: "B408", sigla: "PR", provincia: "CALESTANO", nazione: "ITALIA" },
  { codeKey: "B409", sigla: "SV", provincia: "CALICE LIGURE", nazione: "ITALIA" },
  {
    codeKey: "B410",
    sigla: "SP",
    provincia: "CALICE AL CORNOVIGLIO",
    nazione: "ITALIA",
  },
  { codeKey: "B413", sigla: "LE", provincia: "CALIMERA", nazione: "ITALIA" },
  { codeKey: "B415", sigla: "AV", provincia: "CALITRI", nazione: "ITALIA" },
  { codeKey: "B416", sigla: "SV", provincia: "CALIZZANO", nazione: "ITALIA" },
  { codeKey: "B417", sigla: "BI", provincia: "CALLABIANA", nazione: "ITALIA" },
  { codeKey: "B418", sigla: "AT", provincia: "CALLIANO", nazione: "ITALIA" },
  { codeKey: "B419", sigla: "TN", provincia: "CALLIANO", nazione: "ITALIA" },
  { codeKey: "B423", sigla: "LC", provincia: "CALOLZIOCORTE", nazione: "ITALIA" },
  { codeKey: "B424", sigla: "CS", provincia: "CALOPEZZATI", nazione: "ITALIA" },
  { codeKey: "B425", sigla: "AT", provincia: "CALOSSO", nazione: "ITALIA" },
  { codeKey: "B426", sigla: "CS", provincia: "CALOVETO", nazione: "ITALIA" },
  { codeKey: "B427", sigla: "AG", provincia: "CALTABELLOTTA", nazione: "ITALIA" },
  { codeKey: "B428", sigla: "CT", provincia: "CALTAGIRONE", nazione: "ITALIA" },
  { codeKey: "B429", sigla: "CL", provincia: "CALTANISSETTA", nazione: "ITALIA" },
  { codeKey: "B430", sigla: "PA", provincia: "CALTAVUTURO", nazione: "ITALIA" },
  { codeKey: "B431", sigla: "NO", provincia: "CALTIGNAGA", nazione: "ITALIA" },
  { codeKey: "B432", sigla: "RO", provincia: "CALTO", nazione: "ITALIA" },
  { codeKey: "B433", sigla: "VI", provincia: "CALTRANO", nazione: "ITALIA" },
  { codeKey: "B434", sigla: "BG", provincia: "CALUSCO D'ADDA", nazione: "ITALIA" },
  { codeKey: "B435", sigla: "TO", provincia: "CALUSO", nazione: "ITALIA" },
  {
    codeKey: "B436",
    sigla: "BS",
    provincia: "CALVAGESE DELLA RIVIERA",
    nazione: "ITALIA",
  },
  { codeKey: "B437", sigla: "SA", provincia: "CALVANICO", nazione: "ITALIA" },
  { codeKey: "B439", sigla: "CR", provincia: "CALVATONE", nazione: "ITALIA" },
  { codeKey: "B440", sigla: "PZ", provincia: "CALVELLO", nazione: "ITALIA" },
  { codeKey: "B441", sigla: "VI", provincia: "CALVENE", nazione: "ITALIA" },
  { codeKey: "B442", sigla: "BG", provincia: "CALVENZANO", nazione: "ITALIA" },
  { codeKey: "B443", sigla: "PZ", provincia: "CALVERA", nazione: "ITALIA" },
  { codeKey: "B444", sigla: "BN", provincia: "CALVI", nazione: "ITALIA" },
  { codeKey: "B445", sigla: "CE", provincia: "CALVI RISORTA", nazione: "ITALIA" },
  {
    codeKey: "B446",
    sigla: "TR",
    provincia: "CALVI DELL'UMBRIA",
    nazione: "ITALIA",
  },
  { codeKey: "B447", sigla: "PV", provincia: "CALVIGNANO", nazione: "ITALIA" },
  { codeKey: "B448", sigla: "MI", provincia: "CALVIGNASCO", nazione: "ITALIA" },
  { codeKey: "B450", sigla: "BS", provincia: "CALVISANO", nazione: "ITALIA" },
  { codeKey: "B452", sigla: "NA", provincia: "CALVIZZANO", nazione: "ITALIA" },
  {
    codeKey: "B453",
    sigla: "AL",
    provincia: "CAMAGNA MONFERRATO",
    nazione: "ITALIA",
  },
  { codeKey: "B455", sigla: "LU", provincia: "CAMAIORE", nazione: "ITALIA" },
  { codeKey: "B456", sigla: "LO", provincia: "CAMAIRAGO", nazione: "ITALIA" },
  { codeKey: "B457", sigla: "BI", provincia: "CAMANDONA", nazione: "ITALIA" },
  { codeKey: "B460", sigla: "AG", provincia: "CAMASTRA", nazione: "ITALIA" },
  { codeKey: "B461", sigla: "MI", provincia: "CAMBIAGO", nazione: "ITALIA" },
  { codeKey: "B462", sigla: "TO", provincia: "CAMBIANO", nazione: "ITALIA" },
  { codeKey: "B463", sigla: "VB", provincia: "CAMBIASCA", nazione: "ITALIA" },
  { codeKey: "B465", sigla: "BI", provincia: "CAMBURZANO", nazione: "ITALIA" },
  {
    codeKey: "B466",
    sigla: "IS",
    provincia: "SANT'ELENA SANNITA",
    nazione: "ITALIA",
  },
  { codeKey: "B467", sigla: "CN", provincia: "CAMERANA", nazione: "ITALIA" },
  { codeKey: "B468", sigla: "AN", provincia: "CAMERANO", nazione: "ITALIA" },
  { codeKey: "B469", sigla: "AT", provincia: "CAMERANO CASASCO", nazione: "ITALIA" },
  { codeKey: "B470", sigla: "AN", provincia: "CAMERATA PICENA", nazione: "ITALIA" },
  {
    codeKey: "B471",
    sigla: "BG",
    provincia: "CAMERATA CORNELLO",
    nazione: "ITALIA",
  },
  { codeKey: "B472", sigla: "RM", provincia: "CAMERATA NUOVA", nazione: "ITALIA" },
  { codeKey: "B473", sigla: "NO", provincia: "CAMERI", nazione: "ITALIA" },
  { codeKey: "B474", sigla: "MC", provincia: "CAMERINO", nazione: "ITALIA" },
  { codeKey: "B476", sigla: "SA", provincia: "CAMEROTA", nazione: "ITALIA" },
  { codeKey: "B477", sigla: "CE", provincia: "CAMIGLIANO", nazione: "ITALIA" },
  { codeKey: "B479", sigla: "PC", provincia: "CAMINATA", nazione: "ITALIA" },
  { codeKey: "B481", sigla: "RC", provincia: "CAMINI", nazione: "ITALIA" },
  { codeKey: "B482", sigla: "AL", provincia: "CAMINO", nazione: "ITALIA" },
  {
    codeKey: "B483",
    sigla: "UD",
    provincia: "CAMINO AL TAGLIAMENTO",
    nazione: "ITALIA",
  },
  { codeKey: "B484", sigla: "CR", provincia: "CAMISANO", nazione: "ITALIA" },
  {
    codeKey: "B485",
    sigla: "VI",
    provincia: "CAMISANO VICENTINO",
    nazione: "ITALIA",
  },
  { codeKey: "B486", sigla: "AG", provincia: "CAMMARATA", nazione: "ITALIA" },
  { codeKey: "B489", sigla: "CN", provincia: "CAMO", nazione: "ITALIA" },
  { codeKey: "B490", sigla: "GE", provincia: "CAMOGLI", nazione: "ITALIA" },
  { codeKey: "B491", sigla: "AO", provincia: "CHAMOIS", nazione: "ITALIA" },
  { codeKey: "B492", sigla: "SA", provincia: "CAMPAGNA", nazione: "ITALIA" },
  { codeKey: "B493", sigla: "VE", provincia: "CAMPAGNA LUPIA", nazione: "ITALIA" },
  {
    codeKey: "B494",
    sigla: "CE",
    provincia: "CASTEL CAMPAGNANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "B496",
    sigla: "RM",
    provincia: "CAMPAGNANO DI ROMA",
    nazione: "ITALIA",
  },
  { codeKey: "B497", sigla: "GR", provincia: "CAMPAGNATICO", nazione: "ITALIA" },
  {
    codeKey: "B498",
    sigla: "CR",
    provincia: "CAMPAGNOLA CREMASCA",
    nazione: "ITALIA",
  },
  {
    codeKey: "B499",
    sigla: "RE",
    provincia: "CAMPAGNOLA EMILIA",
    nazione: "ITALIA",
  },
  { codeKey: "B500", sigla: "CS", provincia: "CAMPANA", nazione: "ITALIA" },
  { codeKey: "B501", sigla: "MB", provincia: "CAMPARADA", nazione: "ITALIA" },
  { codeKey: "B502", sigla: "RE", provincia: "CAMPEGINE", nazione: "ITALIA" },
  {
    codeKey: "B504",
    sigla: "PG",
    provincia: "CAMPELLO SUL CLITUNNO",
    nazione: "ITALIA",
  },
  { codeKey: "B505", sigla: "VC", provincia: "CAMPERTOGNO", nazione: "ITALIA" },
  { codeKey: "B506", sigla: "LE", provincia: "CAMPI SALENTINA", nazione: "ITALIA" },
  { codeKey: "B507", sigla: "FI", provincia: "CAMPI BISENZIO", nazione: "ITALIA" },
  { codeKey: "B508", sigla: "BI", provincia: "CAMPIGLIA CERVO", nazione: "ITALIA" },
  {
    codeKey: "B509",
    sigla: "LI",
    provincia: "CAMPIGLIA MARITTIMA",
    nazione: "ITALIA",
  },
  { codeKey: "B510", sigla: "TO", provincia: "VALPRATO SOANA", nazione: "ITALIA" },
  {
    codeKey: "B511",
    sigla: "VI",
    provincia: "CAMPIGLIA DEI BERICI",
    nazione: "ITALIA",
  },
  {
    codeKey: "B512",
    sigla: "TO",
    provincia: "CAMPIGLIONE FENILE",
    nazione: "ITALIA",
  },
  {
    codeKey: "B513",
    sigla: "CO",
    provincia: "CAMPIONE D'ITALIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "B514",
    sigla: "TN",
    provincia: "CAMPITELLO DI FASSA",
    nazione: "ITALIA",
  },
  { codeKey: "B515", sigla: "TE", provincia: "CAMPLI", nazione: "ITALIA" },
  { codeKey: "B516", sigla: "RC", provincia: "CAMPO CALABRO", nazione: "ITALIA" },
  { codeKey: "B519", sigla: "CB", provincia: "CAMPOBASSO", nazione: "ITALIA" },
  {
    codeKey: "B520",
    sigla: "AG",
    provincia: "CAMPOBELLO DI LICATA",
    nazione: "ITALIA",
  },
  {
    codeKey: "B521",
    sigla: "TP",
    provincia: "CAMPOBELLO DI MAZARA",
    nazione: "ITALIA",
  },
  { codeKey: "B522", sigla: "CB", provincia: "CAMPOCHIARO", nazione: "ITALIA" },
  { codeKey: "B524", sigla: "PD", provincia: "CAMPODARSEGO", nazione: "ITALIA" },
  { codeKey: "B525", sigla: "TN", provincia: "CAMPODENNO", nazione: "ITALIA" },
  { codeKey: "B526", sigla: "AQ", provincia: "CAMPO DI GIOVE", nazione: "ITALIA" },
  { codeKey: "B527", sigla: "LT", provincia: "CAMPODIMELE", nazione: "ITALIA" },
  { codeKey: "B528", sigla: "CB", provincia: "CAMPODIPIETRA", nazione: "ITALIA" },
  { codeKey: "B529", sigla: "BZ", provincia: "CAMPO DI TRENS", nazione: "ITALIA" },
  { codeKey: "B530", sigla: "SO", provincia: "CAMPODOLCINO", nazione: "ITALIA" },
  { codeKey: "B531", sigla: "PD", provincia: "CAMPODORO", nazione: "ITALIA" },
  {
    codeKey: "B532",
    sigla: "PA",
    provincia: "CAMPOFELICE DI ROCCELLA",
    nazione: "ITALIA",
  },
  {
    codeKey: "B533",
    sigla: "PA",
    provincia: "CAMPOFELICE DI FITALIA",
    nazione: "ITALIA",
  },
  { codeKey: "B534", sigla: "FM", provincia: "CAMPOFILONE", nazione: "ITALIA" },
  { codeKey: "B535", sigla: "PA", provincia: "CAMPOFIORITO", nazione: "ITALIA" },
  { codeKey: "B536", sigla: "UD", provincia: "CAMPOFORMIDO", nazione: "ITALIA" },
  { codeKey: "B537", sigla: "CL", provincia: "CAMPOFRANCO", nazione: "ITALIA" },
  { codeKey: "B538", sigla: "GE", provincia: "CAMPO LIGURE", nazione: "ITALIA" },
  { codeKey: "B539", sigla: "MO", provincia: "CAMPOGALLIANO", nazione: "ITALIA" },
  { codeKey: "B540", sigla: "AO", provincia: "CHAMPORCHER", nazione: "ITALIA" },
  { codeKey: "B541", sigla: "BN", provincia: "CAMPOLATTARO", nazione: "ITALIA" },
  {
    codeKey: "B542",
    sigla: "BN",
    provincia: "CAMPOLI DEL MONTE TABURNO",
    nazione: "ITALIA",
  },
  {
    codeKey: "B543",
    sigla: "FR",
    provincia: "CAMPOLI APPENNINO",
    nazione: "ITALIA",
  },
  { codeKey: "B544", sigla: "CB", provincia: "CAMPOLIETO", nazione: "ITALIA" },
  {
    codeKey: "B546",
    sigla: "VE",
    provincia: "CAMPOLONGO MAGGIORE",
    nazione: "ITALIA",
  },
  {
    codeKey: "B547",
    sigla: "VI",
    provincia: "CAMPOLONGO SUL BRENTA",
    nazione: "ITALIA",
  },
  { codeKey: "B549", sigla: "PZ", provincia: "CAMPOMAGGIORE", nazione: "ITALIA" },
  { codeKey: "B550", sigla: "CB", provincia: "CAMPOMARINO", nazione: "ITALIA" },
  { codeKey: "B551", sigla: "GE", provincia: "CAMPOMORONE", nazione: "ITALIA" },
  { codeKey: "B553", sigla: "LI", provincia: "CAMPO NELL'ELBA", nazione: "ITALIA" },
  { codeKey: "B554", sigla: "VE", provincia: "CAMPONOGARA", nazione: "ITALIA" },
  { codeKey: "B555", sigla: "SA", provincia: "CAMPORA", nazione: "ITALIA" },
  { codeKey: "B556", sigla: "PA", provincia: "CAMPOREALE", nazione: "ITALIA" },
  { codeKey: "B557", sigla: "LU", provincia: "CAMPORGIANO", nazione: "ITALIA" },
  { codeKey: "B559", sigla: "IM", provincia: "CAMPOROSSO", nazione: "ITALIA" },
  {
    codeKey: "B561",
    sigla: "CT",
    provincia: "CAMPOROTONDO ETNEO",
    nazione: "ITALIA",
  },
  {
    codeKey: "B562",
    sigla: "MC",
    provincia: "CAMPOROTONDO DI FIASTRONE",
    nazione: "ITALIA",
  },
  { codeKey: "B563", sigla: "PD", provincia: "CAMPOSAMPIERO", nazione: "ITALIA" },
  {
    codeKey: "B564",
    sigla: "PD",
    provincia: "CAMPO SAN MARTINO",
    nazione: "ITALIA",
  },
  { codeKey: "B565", sigla: "NA", provincia: "CAMPOSANO", nazione: "ITALIA" },
  { codeKey: "B566", sigla: "MO", provincia: "CAMPOSANTO", nazione: "ITALIA" },
  { codeKey: "B567", sigla: "PV", provincia: "CAMPOSPINOSO", nazione: "ITALIA" },
  { codeKey: "B569", sigla: "AQ", provincia: "CAMPOTOSTO", nazione: "ITALIA" },
  { codeKey: "B570", sigla: "BZ", provincia: "CAMPO TURES", nazione: "ITALIA" },
  { codeKey: "B572", sigla: "BO", provincia: "CAMUGNANO", nazione: "ITALIA" },
  { codeKey: "B573", sigla: "CN", provincia: "CANALE", nazione: "ITALIA" },
  { codeKey: "B574", sigla: "BL", provincia: "CANALE D'AGORDO", nazione: "ITALIA" },
  { codeKey: "B576", sigla: "RM", provincia: "CANALE MONTERANO", nazione: "ITALIA" },
  { codeKey: "B577", sigla: "TN", provincia: "CANAL SAN BOVO", nazione: "ITALIA" },
  { codeKey: "B578", sigla: "RO", provincia: "CANARO", nazione: "ITALIA" },
  { codeKey: "B579", sigla: "TN", provincia: "CANAZEI", nazione: "ITALIA" },
  { codeKey: "B580", sigla: "PZ", provincia: "CANCELLARA", nazione: "ITALIA" },
  {
    codeKey: "B581",
    sigla: "CE",
    provincia: "CANCELLO ED ARNONE",
    nazione: "ITALIA",
  },
  { codeKey: "B582", sigla: "RO", provincia: "CANDA", nazione: "ITALIA" },
  { codeKey: "B584", sigla: "FG", provincia: "CANDELA", nazione: "ITALIA" },
  { codeKey: "B586", sigla: "BI", provincia: "CANDELO", nazione: "ITALIA" },
  { codeKey: "B587", sigla: "PV", provincia: "CANDIA LOMELLINA", nazione: "ITALIA" },
  { codeKey: "B588", sigla: "TO", provincia: "CANDIA CANAVESE", nazione: "ITALIA" },
  { codeKey: "B589", sigla: "PD", provincia: "CANDIANA", nazione: "ITALIA" },
  { codeKey: "B590", sigla: "AV", provincia: "CANDIDA", nazione: "ITALIA" },
  { codeKey: "B591", sigla: "RC", provincia: "CANDIDONI", nazione: "ITALIA" },
  { codeKey: "B592", sigla: "TO", provincia: "CANDIOLO", nazione: "ITALIA" },
  { codeKey: "B593", sigla: "MI", provincia: "CANEGRATE", nazione: "ITALIA" },
  { codeKey: "B594", sigla: "AT", provincia: "CANELLI", nazione: "ITALIA" },
  { codeKey: "B595", sigla: "RI", provincia: "ORVINIO", nazione: "ITALIA" },
  { codeKey: "B597", sigla: "VT", provincia: "CANEPINA", nazione: "ITALIA" },
  { codeKey: "B598", sigla: "PN", provincia: "CANEVA", nazione: "ITALIA" },
  { codeKey: "B599", sigla: "PV", provincia: "CANEVINO", nazione: "ITALIA" },
  { codeKey: "B602", sigla: "AG", provincia: "CANICATTI'", nazione: "ITALIA" },
  {
    codeKey: "B603",
    sigla: "SR",
    provincia: "CANICATTINI BAGNI",
    nazione: "ITALIA",
  },
  { codeKey: "B604", sigla: "VT", provincia: "CANINO", nazione: "ITALIA" },
  { codeKey: "B605", sigla: "TO", provincia: "CANISCHIO", nazione: "ITALIA" },
  { codeKey: "B606", sigla: "AQ", provincia: "CANISTRO", nazione: "ITALIA" },
  { codeKey: "B607", sigla: "CS", provincia: "CANNA", nazione: "ITALIA" },
  { codeKey: "B608", sigla: "SA", provincia: "CANNALONGA", nazione: "ITALIA" },
  { codeKey: "B609", sigla: "PG", provincia: "CANNARA", nazione: "ITALIA" },
  { codeKey: "B610", sigla: "VB", provincia: "CANNERO RIVIERA", nazione: "ITALIA" },
  {
    codeKey: "B612",
    sigla: "MN",
    provincia: "CANNETO SULL'OGLIO",
    nazione: "ITALIA",
  },
  { codeKey: "B613", sigla: "PV", provincia: "CANNETO PAVESE", nazione: "ITALIA" },
  { codeKey: "B615", sigla: "VB", provincia: "CANNOBIO", nazione: "ITALIA" },
  { codeKey: "B616", sigla: "LE", provincia: "CANNOLE", nazione: "ITALIA" },
  { codeKey: "B617", sigla: "RC", provincia: "CANOLO", nazione: "ITALIA" },
  { codeKey: "B618", sigla: "BG", provincia: "CANONICA D'ADDA", nazione: "ITALIA" },
  { codeKey: "B619", sigla: "BT", provincia: "CANOSA DI PUGLIA", nazione: "ITALIA" },
  { codeKey: "B620", sigla: "CH", provincia: "CANOSA SANNITA", nazione: "ITALIA" },
  { codeKey: "B621", sigla: "CN", provincia: "CANOSIO", nazione: "ITALIA" },
  { codeKey: "B624", sigla: "AQ", provincia: "CANSANO", nazione: "ITALIA" },
  { codeKey: "B626", sigla: "PO", provincia: "CANTAGALLO", nazione: "ITALIA" },
  { codeKey: "B627", sigla: "RI", provincia: "CANTALICE", nazione: "ITALIA" },
  { codeKey: "B628", sigla: "TO", provincia: "CANTALUPA", nazione: "ITALIA" },
  { codeKey: "B629", sigla: "AL", provincia: "CANTALUPO LIGURE", nazione: "ITALIA" },
  {
    codeKey: "B630",
    sigla: "IS",
    provincia: "CANTALUPO NEL SANNIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "B631",
    sigla: "RI",
    provincia: "CANTALUPO IN SABINA",
    nazione: "ITALIA",
  },
  { codeKey: "B632", sigla: "RM", provincia: "MANDELA", nazione: "ITALIA" },
  { codeKey: "B633", sigla: "AT", provincia: "CANTARANA", nazione: "ITALIA" },
  { codeKey: "B634", sigla: "VA", provincia: "CANTELLO", nazione: "ITALIA" },
  { codeKey: "B635", sigla: "RM", provincia: "CANTERANO", nazione: "ITALIA" },
  { codeKey: "B636", sigla: "PU", provincia: "CANTIANO", nazione: "ITALIA" },
  { codeKey: "B637", sigla: "TO", provincia: "CANTOIRA", nazione: "ITALIA" },
  { codeKey: "B639", sigla: "CO", provincia: "CANTU'", nazione: "ITALIA" },
  { codeKey: "B640", sigla: "TE", provincia: "CANZANO", nazione: "ITALIA" },
  { codeKey: "B641", sigla: "CO", provincia: "CANZO", nazione: "ITALIA" },
  { codeKey: "B642", sigla: "VE", provincia: "CAORLE", nazione: "ITALIA" },
  { codeKey: "B643", sigla: "PC", provincia: "CAORSO", nazione: "ITALIA" },
  { codeKey: "B644", sigla: "SA", provincia: "CAPACCIO", nazione: "ITALIA" },
  { codeKey: "B645", sigla: "PA", provincia: "CAPACI", nazione: "ITALIA" },
  { codeKey: "B646", sigla: "GR", provincia: "CAPALBIO", nazione: "ITALIA" },
  { codeKey: "B647", sigla: "PI", provincia: "CAPANNOLI", nazione: "ITALIA" },
  { codeKey: "B648", sigla: "LU", provincia: "CAPANNORI", nazione: "ITALIA" },
  { codeKey: "B649", sigla: "RM", provincia: "CAPENA", nazione: "ITALIA" },
  { codeKey: "B650", sigla: "CR", provincia: "CAPERGNANICA", nazione: "ITALIA" },
  { codeKey: "B651", sigla: "AQ", provincia: "CAPESTRANO", nazione: "ITALIA" },
  {
    codeKey: "B653",
    sigla: "CO",
    provincia: "CAPIAGO INTIMIANO",
    nazione: "ITALIA",
  },
  { codeKey: "B655", sigla: "VV", provincia: "CAPISTRANO", nazione: "ITALIA" },
  { codeKey: "B656", sigla: "AQ", provincia: "CAPISTRELLO", nazione: "ITALIA" },
  { codeKey: "B658", sigla: "AQ", provincia: "CAPITIGNANO", nazione: "ITALIA" },
  { codeKey: "B660", sigla: "ME", provincia: "CAPIZZI", nazione: "ITALIA" },
  { codeKey: "B661", sigla: "BG", provincia: "CAPIZZONE", nazione: "ITALIA" },
  { codeKey: "B662", sigla: "BL", provincia: "PONTE NELLE ALPI", nazione: "ITALIA" },
  { codeKey: "B663", sigla: "VT", provincia: "CAPODIMONTE", nazione: "ITALIA" },
  { codeKey: "B664", sigla: "BS", provincia: "CAPO DI PONTE", nazione: "ITALIA" },
  { codeKey: "B666", sigla: "ME", provincia: "CAPO D'ORLANDO", nazione: "ITALIA" },
  { codeKey: "B667", sigla: "CE", provincia: "CAPODRISE", nazione: "ITALIA" },
  { codeKey: "B669", sigla: "LI", provincia: "CAPOLIVERI", nazione: "ITALIA" },
  { codeKey: "B670", sigla: "AR", provincia: "CAPOLONA", nazione: "ITALIA" },
  { codeKey: "B671", sigla: "MB", provincia: "CAPONAGO", nazione: "ITALIA" },
  { codeKey: "B672", sigla: "AQ", provincia: "CAPORCIANO", nazione: "ITALIA" },
  { codeKey: "B674", sigla: "AV", provincia: "CAPOSELE", nazione: "ITALIA" },
  { codeKey: "B675", sigla: "CA", provincia: "CAPOTERRA", nazione: "ITALIA" },
  { codeKey: "B676", sigla: "BS", provincia: "CAPOVALLE", nazione: "ITALIA" },
  { codeKey: "B677", sigla: "AQ", provincia: "CAPPADOCIA", nazione: "ITALIA" },
  {
    codeKey: "B678",
    sigla: "TV",
    provincia: "CAPPELLA MAGGIORE",
    nazione: "ITALIA",
  },
  { codeKey: "B679", sigla: "CR", provincia: "CAPPELLA CANTONE", nazione: "ITALIA" },
  {
    codeKey: "B680",
    sigla: "CR",
    provincia: "CAPPELLA DE' PICENARDI",
    nazione: "ITALIA",
  },
  {
    codeKey: "B681",
    sigla: "PE",
    provincia: "CAPPELLE SUL TAVO",
    nazione: "ITALIA",
  },
  { codeKey: "B682", sigla: "IS", provincia: "CAPRACOTTA", nazione: "ITALIA" },
  { codeKey: "B684", sigla: "FI", provincia: "CAPRAIA E LIMITE", nazione: "ITALIA" },
  { codeKey: "B685", sigla: "LI", provincia: "CAPRAIA ISOLA", nazione: "ITALIA" },
  { codeKey: "B686", sigla: "CR", provincia: "CAPRALBA", nazione: "ITALIA" },
  {
    codeKey: "B687",
    sigla: "RM",
    provincia: "CAPRANICA PRENESTINA",
    nazione: "ITALIA",
  },
  { codeKey: "B688", sigla: "VT", provincia: "CAPRANICA", nazione: "ITALIA" },
  { codeKey: "B689", sigla: "BO", provincia: "MARZABOTTO", nazione: "ITALIA" },
  {
    codeKey: "B690",
    sigla: "LE",
    provincia: "CAPRARICA DI LECCE",
    nazione: "ITALIA",
  },
  { codeKey: "B691", sigla: "VT", provincia: "CAPRAROLA", nazione: "ITALIA" },
  { codeKey: "B692", sigla: "CN", provincia: "CAPRAUNA", nazione: "ITALIA" },
  {
    codeKey: "B693",
    sigla: "AR",
    provincia: "CAPRESE MICHELANGELO",
    nazione: "ITALIA",
  },
  { codeKey: "B694", sigla: "VB", provincia: "CAPREZZO", nazione: "ITALIA" },
  { codeKey: "B695", sigla: "ME", provincia: "CAPRI LEONE", nazione: "ITALIA" },
  { codeKey: "B696", sigla: "NA", provincia: "CAPRI", nazione: "ITALIA" },
  { codeKey: "B697", sigla: "TN", provincia: "CAPRIANA", nazione: "ITALIA" },
  {
    codeKey: "B698",
    sigla: "BS",
    provincia: "CAPRIANO DEL COLLE",
    nazione: "ITALIA",
  },
  { codeKey: "B701", sigla: "AL", provincia: "CAPRIATA D'ORBA", nazione: "ITALIA" },
  {
    codeKey: "B703",
    sigla: "BG",
    provincia: "CAPRIATE SAN GERVASIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "B704",
    sigla: "CE",
    provincia: "CAPRIATI A VOLTURNO",
    nazione: "ITALIA",
  },
  { codeKey: "B705", sigla: "TO", provincia: "CAPRIE", nazione: "ITALIA" },
  { codeKey: "B706", sigla: "AV", provincia: "CAPRIGLIA IRPINA", nazione: "ITALIA" },
  { codeKey: "B707", sigla: "AT", provincia: "CAPRIGLIO", nazione: "ITALIA" },
  { codeKey: "B708", sigla: "BI", provincia: "CAPRILE", nazione: "ITALIA" },
  { codeKey: "B709", sigla: "VR", provincia: "CAPRINO VERONESE", nazione: "ITALIA" },
  {
    codeKey: "B710",
    sigla: "BG",
    provincia: "CAPRINO BERGAMASCO",
    nazione: "ITALIA",
  },
  { codeKey: "B711", sigla: "BS", provincia: "CAPRIOLO", nazione: "ITALIA" },
  {
    codeKey: "B712",
    sigla: "GO",
    provincia: "CAPRIVA DEL FRIULI",
    nazione: "ITALIA",
  },
  { codeKey: "B715", sigla: "CE", provincia: "CAPUA", nazione: "ITALIA" },
  { codeKey: "B716", sigla: "BA", provincia: "CAPURSO", nazione: "ITALIA" },
  {
    codeKey: "B717",
    sigla: "CZ",
    provincia: "CARAFFA DI CATANZARO",
    nazione: "ITALIA",
  },
  {
    codeKey: "B718",
    sigla: "RC",
    provincia: "CARAFFA DEL BIANCO",
    nazione: "ITALIA",
  },
  { codeKey: "B719", sigla: "CN", provincia: "CARAGLIO", nazione: "ITALIA" },
  {
    codeKey: "B720",
    sigla: "CN",
    provincia: "CARAMAGNA PIEMONTE",
    nazione: "ITALIA",
  },
  { codeKey: "B722", sigla: "PE", provincia: "CARAMANICO TERME", nazione: "ITALIA" },
  { codeKey: "B723", sigla: "TN", provincia: "CARANO", nazione: "ITALIA" },
  { codeKey: "B724", sigla: "FG", provincia: "CARAPELLE", nazione: "ITALIA" },
  {
    codeKey: "B725",
    sigla: "AQ",
    provincia: "CARAPELLE CALVISIO",
    nazione: "ITALIA",
  },
  { codeKey: "B726", sigla: "GE", provincia: "CARASCO", nazione: "ITALIA" },
  { codeKey: "B727", sigla: "AP", provincia: "CARASSAI", nazione: "ITALIA" },
  { codeKey: "B729", sigla: "MB", provincia: "CARATE BRIANZA", nazione: "ITALIA" },
  { codeKey: "B730", sigla: "CO", provincia: "CARATE URIO", nazione: "ITALIA" },
  { codeKey: "B731", sigla: "BG", provincia: "CARAVAGGIO", nazione: "ITALIA" },
  { codeKey: "B732", sigla: "VA", provincia: "CARAVATE", nazione: "ITALIA" },
  { codeKey: "B733", sigla: "TO", provincia: "CARAVINO", nazione: "ITALIA" },
  { codeKey: "B734", sigla: "IM", provincia: "CARAVONICA", nazione: "ITALIA" },
  { codeKey: "B735", sigla: "VT", provincia: "CARBOGNANO", nazione: "ITALIA" },
  {
    codeKey: "B736",
    sigla: "AL",
    provincia: "CARBONARA SCRIVIA",
    nazione: "ITALIA",
  },
  { codeKey: "B738", sigla: "CA", provincia: "VILLASIMIUS", nazione: "ITALIA" },
  { codeKey: "B739", sigla: "MN", provincia: "CARBONARA DI PO", nazione: "ITALIA" },
  {
    codeKey: "B740",
    sigla: "NA",
    provincia: "CARBONARA DI NOLA",
    nazione: "ITALIA",
  },
  {
    codeKey: "B741",
    sigla: "PV",
    provincia: "CARBONARA AL TICINO",
    nazione: "ITALIA",
  },
  { codeKey: "B742", sigla: "CO", provincia: "CARBONATE", nazione: "ITALIA" },
  { codeKey: "B743", sigla: "PZ", provincia: "CARBONE", nazione: "ITALIA" },
  { codeKey: "B744", sigla: "TV", provincia: "CARBONERA", nazione: "ITALIA" },
  { codeKey: "B745", sigla: "CI", provincia: "CARBONIA", nazione: "ITALIA" },
  { codeKey: "B748", sigla: "SV", provincia: "CARCARE", nazione: "ITALIA" },
  { codeKey: "B749", sigla: "PD", provincia: "CARCERI", nazione: "ITALIA" },
  { codeKey: "B752", sigla: "VC", provincia: "CARCOFORO", nazione: "ITALIA" },
  { codeKey: "B754", sigla: "VA", provincia: "CARDANO AL CAMPO", nazione: "ITALIA" },
  { codeKey: "B755", sigla: "CN", provincia: "CARDE'", nazione: "ITALIA" },
  { codeKey: "B756", sigla: "RC", provincia: "CARDETO", nazione: "ITALIA" },
  { codeKey: "B758", sigla: "CZ", provincia: "CARDINALE", nazione: "ITALIA" },
  { codeKey: "B759", sigla: "NA", provincia: "CARDITO", nazione: "ITALIA" },
  { codeKey: "B760", sigla: "LU", provincia: "CAREGGINE", nazione: "ITALIA" },
  { codeKey: "B762", sigla: "TO", provincia: "CAREMA", nazione: "ITALIA" },
  { codeKey: "B763", sigla: "LC", provincia: "CARENNO", nazione: "ITALIA" },
  { codeKey: "B765", sigla: "AL", provincia: "CARENTINO", nazione: "ITALIA" },
  { codeKey: "B766", sigla: "RC", provincia: "CARERI", nazione: "ITALIA" },
  { codeKey: "B767", sigla: "VC", provincia: "CARESANA", nazione: "ITALIA" },
  { codeKey: "B768", sigla: "VC", provincia: "CARESANABLOT", nazione: "ITALIA" },
  { codeKey: "B769", sigla: "AL", provincia: "CAREZZANO", nazione: "ITALIA" },
  { codeKey: "B771", sigla: "KR", provincia: "CARFIZZI", nazione: "ITALIA" },
  { codeKey: "B772", sigla: "SS", provincia: "CARGEGHE", nazione: "ITALIA" },
  { codeKey: "B774", sigla: "CS", provincia: "CARIATI", nazione: "ITALIA" },
  { codeKey: "B776", sigla: "AV", provincia: "CARIFE", nazione: "ITALIA" },
  { codeKey: "B777", sigla: "TO", provincia: "CARIGNANO", nazione: "ITALIA" },
  { codeKey: "B778", sigla: "CO", provincia: "CARIMATE", nazione: "ITALIA" },
  { codeKey: "B779", sigla: "CE", provincia: "CARINARO", nazione: "ITALIA" },
  { codeKey: "B780", sigla: "PA", provincia: "CARINI", nazione: "ITALIA" },
  { codeKey: "B781", sigla: "CE", provincia: "CARINOLA", nazione: "ITALIA" },
  { codeKey: "B782", sigla: "VC", provincia: "CARISIO", nazione: "ITALIA" },
  { codeKey: "B783", sigla: "TN", provincia: "CARISOLO", nazione: "ITALIA" },
  { codeKey: "B784", sigla: "FG", provincia: "CARLANTINO", nazione: "ITALIA" },
  { codeKey: "B785", sigla: "CO", provincia: "CARLAZZO", nazione: "ITALIA" },
  { codeKey: "B787", sigla: "SR", provincia: "CARLENTINI", nazione: "ITALIA" },
  { codeKey: "B788", sigla: "UD", provincia: "CARLINO", nazione: "ITALIA" },
  { codeKey: "B789", sigla: "CI", provincia: "CARLOFORTE", nazione: "ITALIA" },
  { codeKey: "B790", sigla: "CZ", provincia: "CARLOPOLI", nazione: "ITALIA" },
  { codeKey: "B791", sigla: "TO", provincia: "CARMAGNOLA", nazione: "ITALIA" },
  { codeKey: "B792", sigla: "LE", provincia: "CARMIANO", nazione: "ITALIA" },
  { codeKey: "B794", sigla: "PO", provincia: "CARMIGNANO", nazione: "ITALIA" },
  {
    codeKey: "B795",
    sigla: "PD",
    provincia: "CARMIGNANO DI BRENTA",
    nazione: "ITALIA",
  },
  { codeKey: "B796", sigla: "VA", provincia: "CARNAGO", nazione: "ITALIA" },
  { codeKey: "B798", sigla: "MB", provincia: "CARNATE", nazione: "ITALIA" },
  {
    codeKey: "B799",
    sigla: "BZ",
    provincia: "CORNEDO ALL'ISARCO",
    nazione: "ITALIA",
  },
  {
    codeKey: "B801",
    sigla: "BG",
    provincia: "CAROBBIO DEGLI ANGELI",
    nazione: "ITALIA",
  },
  { codeKey: "B802", sigla: "CS", provincia: "CAROLEI", nazione: "ITALIA" },
  { codeKey: "B803", sigla: "BG", provincia: "CARONA", nazione: "ITALIA" },
  { codeKey: "B804", sigla: "ME", provincia: "CARONIA", nazione: "ITALIA" },
  {
    codeKey: "B805",
    sigla: "VA",
    provincia: "CARONNO PERTUSELLA",
    nazione: "ITALIA",
  },
  { codeKey: "B807", sigla: "VA", provincia: "CARONNO VARESINO", nazione: "ITALIA" },
  { codeKey: "B808", sigla: "TA", provincia: "CAROSINO", nazione: "ITALIA" },
  { codeKey: "B809", sigla: "BR", provincia: "CAROVIGNO", nazione: "ITALIA" },
  { codeKey: "B810", sigla: "IS", provincia: "CAROVILLI", nazione: "ITALIA" },
  {
    codeKey: "B812",
    sigla: "PC",
    provincia: "CARPANETO PIACENTINO",
    nazione: "ITALIA",
  },
  { codeKey: "B813", sigla: "CS", provincia: "CARPANZANO", nazione: "ITALIA" },
  { codeKey: "B814", sigla: "IM", provincia: "CARPASIO", nazione: "ITALIA" },
  { codeKey: "B816", sigla: "PU", provincia: "CARPEGNA", nazione: "ITALIA" },
  { codeKey: "B817", sigla: "BS", provincia: "CARPENEDOLO", nazione: "ITALIA" },
  { codeKey: "B818", sigla: "AL", provincia: "CARPENETO", nazione: "ITALIA" },
  { codeKey: "B819", sigla: "MO", provincia: "CARPI", nazione: "ITALIA" },
  { codeKey: "B820", sigla: "MI", provincia: "CARPIANO", nazione: "ITALIA" },
  {
    codeKey: "B822",
    sigla: "LE",
    provincia: "CARPIGNANO SALENTINO",
    nazione: "ITALIA",
  },
  { codeKey: "B823", sigla: "NO", provincia: "CARPIGNANO SESIA", nazione: "ITALIA" },
  { codeKey: "B824", sigla: "PV", provincia: "CURA CARPIGNANO", nazione: "ITALIA" },
  { codeKey: "B825", sigla: "RE", provincia: "CARPINETI", nazione: "ITALIA" },
  {
    codeKey: "B826",
    sigla: "CH",
    provincia: "CARPINETO SINELLO",
    nazione: "ITALIA",
  },
  {
    codeKey: "B827",
    sigla: "PE",
    provincia: "CARPINETO DELLA NORA",
    nazione: "ITALIA",
  },
  { codeKey: "B828", sigla: "RM", provincia: "CARPINETO ROMANO", nazione: "ITALIA" },
  { codeKey: "B829", sigla: "FG", provincia: "CARPINO", nazione: "ITALIA" },
  { codeKey: "B830", sigla: "IS", provincia: "CARPINONE", nazione: "ITALIA" },
  { codeKey: "B832", sigla: "MS", provincia: "CARRARA", nazione: "ITALIA" },
  { codeKey: "B835", sigla: "VI", provincia: "CARRE'", nazione: "ITALIA" },
  { codeKey: "B836", sigla: "AL", provincia: "CARREGA LIGURE", nazione: "ITALIA" },
  { codeKey: "B838", sigla: "SP", provincia: "CARRO", nazione: "ITALIA" },
  { codeKey: "B839", sigla: "SP", provincia: "CARRODANO", nazione: "ITALIA" },
  { codeKey: "B840", sigla: "AL", provincia: "CARROSIO", nazione: "ITALIA" },
  { codeKey: "B841", sigla: "CN", provincia: "CARRU'", nazione: "ITALIA" },
  { codeKey: "B842", sigla: "AQ", provincia: "CARSOLI", nazione: "ITALIA" },
  { codeKey: "B844", sigla: "VI", provincia: "CARTIGLIANO", nazione: "ITALIA" },
  { codeKey: "B845", sigla: "CN", provincia: "CARTIGNANO", nazione: "ITALIA" },
  { codeKey: "B846", sigla: "PU", provincia: "CARTOCETO", nazione: "ITALIA" },
  { codeKey: "B847", sigla: "AL", provincia: "CARTOSIO", nazione: "ITALIA" },
  { codeKey: "B848", sigla: "PD", provincia: "CARTURA", nazione: "ITALIA" },
  { codeKey: "B850", sigla: "MI", provincia: "CARUGATE", nazione: "ITALIA" },
  { codeKey: "B851", sigla: "CO", provincia: "CARUGO", nazione: "ITALIA" },
  { codeKey: "B853", sigla: "CH", provincia: "CARUNCHIO", nazione: "ITALIA" },
  { codeKey: "B854", sigla: "BG", provincia: "CARVICO", nazione: "ITALIA" },
  { codeKey: "B856", sigla: "TN", provincia: "CARZANO", nazione: "ITALIA" },
  { codeKey: "B857", sigla: "KR", provincia: "CASABONA", nazione: "ITALIA" },
  { codeKey: "B858", sigla: "CB", provincia: "CASACALENDA", nazione: "ITALIA" },
  { codeKey: "B859", sigla: "CH", provincia: "CASACANDITELLA", nazione: "ITALIA" },
  { codeKey: "B860", sigla: "CE", provincia: "CASAGIOVE", nazione: "ITALIA" },
  { codeKey: "B861", sigla: "CH", provincia: "CASALANGUIDA", nazione: "ITALIA" },
  { codeKey: "B862", sigla: "FR", provincia: "CASALATTICO", nazione: "ITALIA" },
  { codeKey: "B864", sigla: "NO", provincia: "CASALBELTRAME", nazione: "ITALIA" },
  { codeKey: "B865", sigla: "CH", provincia: "CASALBORDINO", nazione: "ITALIA" },
  { codeKey: "B866", sigla: "AV", provincia: "CASALBORE", nazione: "ITALIA" },
  { codeKey: "B867", sigla: "TO", provincia: "CASALBORGONE", nazione: "ITALIA" },
  { codeKey: "B868", sigla: "SA", provincia: "CASALBUONO", nazione: "ITALIA" },
  {
    codeKey: "B869",
    sigla: "CR",
    provincia: "CASALBUTTANO ED UNITI",
    nazione: "ITALIA",
  },
  { codeKey: "B870", sigla: "AL", provincia: "CASAL CERMELLI", nazione: "ITALIA" },
  { codeKey: "B871", sigla: "CB", provincia: "CASALCIPRANO", nazione: "ITALIA" },
  {
    codeKey: "B872",
    sigla: "CE",
    provincia: "CASAL DI PRINCIPE",
    nazione: "ITALIA",
  },
  { codeKey: "B873", sigla: "BN", provincia: "CASALDUNI", nazione: "ITALIA" },
  { codeKey: "B875", sigla: "VA", provincia: "CASALE LITTA", nazione: "ITALIA" },
  {
    codeKey: "B876",
    sigla: "VB",
    provincia: "CASALE CORTE CERRO",
    nazione: "ITALIA",
  },
  {
    codeKey: "B877",
    sigla: "PD",
    provincia: "CASALE DI SCODOSIA",
    nazione: "ITALIA",
  },
  { codeKey: "B878", sigla: "PI", provincia: "CASALE MARITTIMO", nazione: "ITALIA" },
  { codeKey: "B879", sigla: "TV", provincia: "CASALE SUL SILE", nazione: "ITALIA" },
  {
    codeKey: "B880",
    sigla: "BO",
    provincia: "CASALECCHIO DI RENO",
    nazione: "ITALIA",
  },
  {
    codeKey: "B881",
    sigla: "CR",
    provincia: "CASALE CREMASCO-VIDOLASCO",
    nazione: "ITALIA",
  },
  { codeKey: "B882", sigla: "AL", provincia: "CASALEGGIO BOIRO", nazione: "ITALIA" },
  {
    codeKey: "B883",
    sigla: "NO",
    provincia: "CASALEGGIO NOVARA",
    nazione: "ITALIA",
  },
  {
    codeKey: "B885",
    sigla: "AL",
    provincia: "CASALE MONFERRATO",
    nazione: "ITALIA",
  },
  { codeKey: "B886", sigla: "VR", provincia: "CASALEONE", nazione: "ITALIA" },
  {
    codeKey: "B887",
    sigla: "LO",
    provincia: "CASALETTO LODIGIANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "B888",
    sigla: "SA",
    provincia: "CASALETTO SPARTANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "B889",
    sigla: "CR",
    provincia: "CASALETTO CEREDANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "B890",
    sigla: "CR",
    provincia: "CASALETTO DI SOPRA",
    nazione: "ITALIA",
  },
  { codeKey: "B891", sigla: "CR", provincia: "CASALETTO VAPRIO", nazione: "ITALIA" },
  { codeKey: "B892", sigla: "BO", provincia: "CASALFIUMANESE", nazione: "ITALIA" },
  { codeKey: "B893", sigla: "RE", provincia: "CASALGRANDE", nazione: "ITALIA" },
  { codeKey: "B894", sigla: "CN", provincia: "CASALGRASSO", nazione: "ITALIA" },
  { codeKey: "B895", sigla: "SA", provincia: "CASAL VELINO", nazione: "ITALIA" },
  { codeKey: "B896", sigla: "CH", provincia: "CASALINCONTRADA", nazione: "ITALIA" },
  { codeKey: "B897", sigla: "NO", provincia: "CASALINO", nazione: "ITALIA" },
  { codeKey: "B898", sigla: "CR", provincia: "CASALMAGGIORE", nazione: "ITALIA" },
  { codeKey: "B899", sigla: "LO", provincia: "CASALMAIOCCO", nazione: "ITALIA" },
  { codeKey: "B900", sigla: "CR", provincia: "CASALMORANO", nazione: "ITALIA" },
  { codeKey: "B901", sigla: "MN", provincia: "CASALMORO", nazione: "ITALIA" },
  { codeKey: "B902", sigla: "AL", provincia: "CASALNOCETO", nazione: "ITALIA" },
  { codeKey: "B903", sigla: "CS", provincia: "VILLAPIANA", nazione: "ITALIA" },
  {
    codeKey: "B904",
    sigla: "FG",
    provincia: "CASALNUOVO MONTEROTARO",
    nazione: "ITALIA",
  },
  {
    codeKey: "B905",
    sigla: "NA",
    provincia: "CASALNUOVO DI NAPOLI",
    nazione: "ITALIA",
  },
  {
    codeKey: "B906",
    sigla: "PZ",
    provincia: "SAN PAOLO ALBANESE",
    nazione: "ITALIA",
  },
  { codeKey: "B907", sigla: "MN", provincia: "CASALOLDO", nazione: "ITALIA" },
  { codeKey: "B910", sigla: "LO", provincia: "CASALPUSTERLENGO", nazione: "ITALIA" },
  { codeKey: "B911", sigla: "MN", provincia: "CASALROMANO", nazione: "ITALIA" },
  { codeKey: "B912", sigla: "PD", provincia: "CASALSERUGO", nazione: "ITALIA" },
  {
    codeKey: "B914",
    sigla: "CR",
    provincia: "POZZAGLIO ED UNITI",
    nazione: "ITALIA",
  },
  { codeKey: "B915", sigla: "BT", provincia: "TRINITAPOLI", nazione: "ITALIA" },
  { codeKey: "B916", sigla: "CE", provincia: "CASALUCE", nazione: "ITALIA" },
  {
    codeKey: "B917",
    sigla: "FG",
    provincia: "CASALVECCHIO DI PUGLIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "B918",
    sigla: "ME",
    provincia: "CASALVECCHIO SICULO",
    nazione: "ITALIA",
  },
  { codeKey: "B919", sigla: "FR", provincia: "CASALVIERI", nazione: "ITALIA" },
  { codeKey: "B920", sigla: "NO", provincia: "CASALVOLONE", nazione: "ITALIA" },
  { codeKey: "B921", sigla: "VA", provincia: "CASALZUIGNO", nazione: "ITALIA" },
  { codeKey: "B922", sigla: "NA", provincia: "CASAMARCIANO", nazione: "ITALIA" },
  { codeKey: "B923", sigla: "BA", provincia: "CASAMASSIMA", nazione: "ITALIA" },
  {
    codeKey: "B924",
    sigla: "NA",
    provincia: "CASAMICCIOLA TERME",
    nazione: "ITALIA",
  },
  { codeKey: "B925", sigla: "NA", provincia: "CASANDRINO", nazione: "ITALIA" },
  { codeKey: "B927", sigla: "SV", provincia: "CASANOVA LERRONE", nazione: "ITALIA" },
  { codeKey: "B928", sigla: "VC", provincia: "CASANOVA ELVO", nazione: "ITALIA" },
  { codeKey: "B929", sigla: "PV", provincia: "CASANOVA LONATI", nazione: "ITALIA" },
  { codeKey: "B932", sigla: "RM", provincia: "CASAPE", nazione: "ITALIA" },
  { codeKey: "B933", sigla: "BI", provincia: "CASAPINTA", nazione: "ITALIA" },
  { codeKey: "B934", sigla: "RI", provincia: "CASAPROTA", nazione: "ITALIA" },
  { codeKey: "B935", sigla: "CE", provincia: "CASAPULLA", nazione: "ITALIA" },
  { codeKey: "B936", sigla: "LE", provincia: "CASARANO", nazione: "ITALIA" },
  { codeKey: "B937", sigla: "LC", provincia: "CASARGO", nazione: "ITALIA" },
  { codeKey: "B938", sigla: "MI", provincia: "CASARILE", nazione: "ITALIA" },
  { codeKey: "B939", sigla: "GE", provincia: "CASARZA LIGURE", nazione: "ITALIA" },
  {
    codeKey: "B940",
    sigla: "PN",
    provincia: "CASARSA DELLA DELIZIA",
    nazione: "ITALIA",
  },
  { codeKey: "B941", sigla: "AL", provincia: "CASASCO", nazione: "ITALIA" },
  {
    codeKey: "B942",
    sigla: "CO",
    provincia: "CASASCO D'INTELVI",
    nazione: "ITALIA",
  },
  { codeKey: "B943", sigla: "LC", provincia: "CASATENOVO", nazione: "ITALIA" },
  { codeKey: "B945", sigla: "PV", provincia: "CASATISMA", nazione: "ITALIA" },
  { codeKey: "B946", sigla: "NA", provincia: "CASAVATORE", nazione: "ITALIA" },
  { codeKey: "B947", sigla: "BG", provincia: "CASAZZA", nazione: "ITALIA" },
  { codeKey: "B948", sigla: "PG", provincia: "CASCIA", nazione: "ITALIA" },
  { codeKey: "B949", sigla: "VA", provincia: "CASCIAGO", nazione: "ITALIA" },
  { codeKey: "B950", sigla: "PI", provincia: "CASCINA", nazione: "ITALIA" },
  {
    codeKey: "B952",
    sigla: "VC",
    provincia: "SAN GIACOMO VERCELLESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "B953",
    sigla: "TO",
    provincia: "CASCINETTE D'IVREA",
    nazione: "ITALIA",
  },
  { codeKey: "B954", sigla: "PV", provincia: "CASEI GEROLA", nazione: "ITALIA" },
  { codeKey: "B955", sigla: "TO", provincia: "CASELETTE", nazione: "ITALIA" },
  { codeKey: "B956", sigla: "GE", provincia: "CASELLA", nazione: "ITALIA" },
  { codeKey: "B958", sigla: "LO", provincia: "CASELLE LURANI", nazione: "ITALIA" },
  {
    codeKey: "B959",
    sigla: "SA",
    provincia: "CASELLE IN PITTARI",
    nazione: "ITALIA",
  },
  { codeKey: "B960", sigla: "TO", provincia: "CASELLE TORINESE", nazione: "ITALIA" },
  { codeKey: "B961", sigla: "LO", provincia: "CASELLE LANDI", nazione: "ITALIA" },
  { codeKey: "B962", sigla: "FI", provincia: "SCANDICCI", nazione: "ITALIA" },
  { codeKey: "B963", sigla: "CE", provincia: "CASERTA", nazione: "ITALIA" },
  { codeKey: "B965", sigla: "TV", provincia: "CASIER", nazione: "ITALIA" },
  { codeKey: "B966", sigla: "RC", provincia: "CASIGNANA", nazione: "ITALIA" },
  { codeKey: "B967", sigla: "RE", provincia: "CASINA", nazione: "ITALIA" },
  { codeKey: "B968", sigla: "KR", provincia: "CASTELSILANO", nazione: "ITALIA" },
  { codeKey: "B969", sigla: "BO", provincia: "CASTEL DI CASIO", nazione: "ITALIA" },
  { codeKey: "B971", sigla: "BG", provincia: "CASIRATE D'ADDA", nazione: "ITALIA" },
  { codeKey: "B974", sigla: "CO", provincia: "CASLINO D'ERBA", nazione: "ITALIA" },
  {
    codeKey: "B977",
    sigla: "CO",
    provincia: "CASNATE CON BERNATE",
    nazione: "ITALIA",
  },
  { codeKey: "B978", sigla: "BG", provincia: "CASNIGO", nazione: "ITALIA" },
  {
    codeKey: "B979",
    sigla: "MS",
    provincia: "CASOLA IN LUNIGIANA",
    nazione: "ITALIA",
  },
  { codeKey: "B980", sigla: "NA", provincia: "CASOLA DI NAPOLI", nazione: "ITALIA" },
  { codeKey: "B982", sigla: "RA", provincia: "CASOLA VALSENIO", nazione: "ITALIA" },
  { codeKey: "B983", sigla: "CS", provincia: "CASOLE BRUZIO", nazione: "ITALIA" },
  { codeKey: "B984", sigla: "SI", provincia: "CASOLE D'ELSA", nazione: "ITALIA" },
  { codeKey: "B985", sigla: "CH", provincia: "CASOLI", nazione: "ITALIA" },
  {
    codeKey: "B987",
    sigla: "VA",
    provincia: "CASORATE SEMPIONE",
    nazione: "ITALIA",
  },
  { codeKey: "B988", sigla: "PV", provincia: "CASORATE PRIMO", nazione: "ITALIA" },
  { codeKey: "B989", sigla: "MI", provincia: "CASOREZZO", nazione: "ITALIA" },
  { codeKey: "B990", sigla: "NA", provincia: "CASORIA", nazione: "ITALIA" },
  { codeKey: "B991", sigla: "AT", provincia: "CASORZO", nazione: "ITALIA" },
  { codeKey: "B993", sigla: "SO", provincia: "CASPOGGIO", nazione: "ITALIA" },
  { codeKey: "B994", sigla: "UD", provincia: "CASSACCO", nazione: "ITALIA" },
  { codeKey: "B996", sigla: "LC", provincia: "CASSAGO BRIANZA", nazione: "ITALIA" },
  { codeKey: "B997", sigla: "AV", provincia: "CASSANO IRPINO", nazione: "ITALIA" },
  {
    codeKey: "B998",
    sigla: "BA",
    provincia: "CASSANO DELLE MURGE",
    nazione: "ITALIA",
  },
  { codeKey: "B999", sigla: "VA", provincia: "CASSANO VALCUVIA", nazione: "ITALIA" },
  {
    codeKey: "C002",
    sigla: "CS",
    provincia: "CASSANO ALLO IONIO",
    nazione: "ITALIA",
  },
  { codeKey: "C003", sigla: "MI", provincia: "CASSANO D'ADDA", nazione: "ITALIA" },
  { codeKey: "C004", sigla: "VA", provincia: "CASSANO MAGNAGO", nazione: "ITALIA" },
  { codeKey: "C005", sigla: "AL", provincia: "CASSANO SPINOLA", nazione: "ITALIA" },
  { codeKey: "C006", sigla: "SR", provincia: "CASSARO", nazione: "ITALIA" },
  { codeKey: "C007", sigla: "BG", provincia: "CASSIGLIO", nazione: "ITALIA" },
  { codeKey: "C013", sigla: "MI", provincia: "PERO", nazione: "ITALIA" },
  {
    codeKey: "C014",
    sigla: "MI",
    provincia: "CASSINA DE' PECCHI",
    nazione: "ITALIA",
  },
  { codeKey: "C020", sigla: "CO", provincia: "CASSINA RIZZARDI", nazione: "ITALIA" },
  { codeKey: "C022", sigla: "AT", provincia: "CASSINASCO", nazione: "ITALIA" },
  {
    codeKey: "C024",
    sigla: "LC",
    provincia: "CASSINA VALSASSINA",
    nazione: "ITALIA",
  },
  { codeKey: "C027", sigla: "AL", provincia: "CASSINE", nazione: "ITALIA" },
  { codeKey: "C030", sigla: "AL", provincia: "CASSINELLE", nazione: "ITALIA" },
  {
    codeKey: "C033",
    sigla: "MI",
    provincia: "CASSINETTA DI LUGAGNANO",
    nazione: "ITALIA",
  },
  { codeKey: "C034", sigla: "FR", provincia: "CASSINO", nazione: "ITALIA" },
  { codeKey: "C037", sigla: "VI", provincia: "CASSOLA", nazione: "ITALIA" },
  { codeKey: "C038", sigla: "PV", provincia: "CASSOLNOVO", nazione: "ITALIA" },
  { codeKey: "C040", sigla: "TE", provincia: "CASTEL CASTAGNA", nazione: "ITALIA" },
  { codeKey: "C041", sigla: "VR", provincia: "CASTAGNARO", nazione: "ITALIA" },
  {
    codeKey: "C044",
    sigla: "LI",
    provincia: "CASTAGNETO CARDUCCI",
    nazione: "ITALIA",
  },
  { codeKey: "C045", sigla: "TO", provincia: "CASTAGNETO PO", nazione: "ITALIA" },
  { codeKey: "C046", sigla: "CN", provincia: "CASTAGNITO", nazione: "ITALIA" },
  {
    codeKey: "C047",
    sigla: "AT",
    provincia: "CASTAGNOLE MONFERRATO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C048",
    sigla: "TO",
    provincia: "CASTAGNOLE PIEMONTE",
    nazione: "ITALIA",
  },
  {
    codeKey: "C049",
    sigla: "AT",
    provincia: "CASTAGNOLE DELLE LANZE",
    nazione: "ITALIA",
  },
  { codeKey: "C050", sigla: "PV", provincia: "CASTANA", nazione: "ITALIA" },
  { codeKey: "C051", sigla: "ME", provincia: "CASTELL'UMBERTO", nazione: "ITALIA" },
  { codeKey: "C052", sigla: "MI", provincia: "CASTANO PRIMO", nazione: "ITALIA" },
  { codeKey: "C053", sigla: "PV", provincia: "CASTEGGIO", nazione: "ITALIA" },
  { codeKey: "C055", sigla: "BS", provincia: "CASTEGNATO", nazione: "ITALIA" },
  { codeKey: "C056", sigla: "VI", provincia: "CASTEGNERO", nazione: "ITALIA" },
  { codeKey: "C057", sigla: "PD", provincia: "CASTELBALDO", nazione: "ITALIA" },
  { codeKey: "C058", sigla: "AV", provincia: "CASTEL BARONIA", nazione: "ITALIA" },
  { codeKey: "C059", sigla: "MN", provincia: "CASTELBELFORTE", nazione: "ITALIA" },
  { codeKey: "C060", sigla: "AN", provincia: "CASTELBELLINO", nazione: "ITALIA" },
  {
    codeKey: "C062",
    sigla: "BZ",
    provincia: "CASTELBELLO-CIARDES",
    nazione: "ITALIA",
  },
  { codeKey: "C063", sigla: "SV", provincia: "CASTELBIANCO", nazione: "ITALIA" },
  { codeKey: "C064", sigla: "AT", provincia: "CASTEL BOGLIONE", nazione: "ITALIA" },
  { codeKey: "C065", sigla: "RA", provincia: "CASTEL BOLOGNESE", nazione: "ITALIA" },
  { codeKey: "C066", sigla: "CB", provincia: "CASTELBOTTACCIO", nazione: "ITALIA" },
  { codeKey: "C067", sigla: "PA", provincia: "CASTELBUONO", nazione: "ITALIA" },
  { codeKey: "C069", sigla: "SA", provincia: "CASTELCIVITA", nazione: "ITALIA" },
  { codeKey: "C070", sigla: "FM", provincia: "SERVIGLIANO", nazione: "ITALIA" },
  { codeKey: "C072", sigla: "BS", provincia: "CASTELCOVATI", nazione: "ITALIA" },
  { codeKey: "C073", sigla: "TV", provincia: "CASTELCUCCO", nazione: "ITALIA" },
  { codeKey: "C074", sigla: "PA", provincia: "CASTELDACCIA", nazione: "ITALIA" },
  { codeKey: "C075", sigla: "BO", provincia: "CASTEL D'AIANO", nazione: "ITALIA" },
  { codeKey: "C076", sigla: "MN", provincia: "CASTEL D'ARIO", nazione: "ITALIA" },
  { codeKey: "C078", sigla: "VR", provincia: "CASTEL D'AZZANO", nazione: "ITALIA" },
  { codeKey: "C079", sigla: "BG", provincia: "CASTELLI CALEPIO", nazione: "ITALIA" },
  { codeKey: "C080", sigla: "RN", provincia: "CASTELDELCI", nazione: "ITALIA" },
  { codeKey: "C081", sigla: "CN", provincia: "CASTELDELFINO", nazione: "ITALIA" },
  {
    codeKey: "C082",
    sigla: "IS",
    provincia: "CASTEL DEL GIUDICE",
    nazione: "ITALIA",
  },
  { codeKey: "C083", sigla: "AQ", provincia: "CASTEL DEL MONTE", nazione: "ITALIA" },
  { codeKey: "C085", sigla: "GR", provincia: "CASTEL DEL PIANO", nazione: "ITALIA" },
  { codeKey: "C086", sigla: "BO", provincia: "CASTEL DEL RIO", nazione: "ITALIA" },
  { codeKey: "C089", sigla: "CR", provincia: "CASTELDIDONE", nazione: "ITALIA" },
  { codeKey: "C090", sigla: "AQ", provincia: "CASTEL DI IERI", nazione: "ITALIA" },
  { codeKey: "C091", sigla: "CT", provincia: "CASTEL DI IUDICA", nazione: "ITALIA" },
  { codeKey: "C093", sigla: "AP", provincia: "CASTEL DI LAMA", nazione: "ITALIA" },
  { codeKey: "C094", sigla: "ME", provincia: "CASTEL DI LUCIO", nazione: "ITALIA" },
  { codeKey: "C096", sigla: "AQ", provincia: "CASTEL DI SANGRO", nazione: "ITALIA" },
  { codeKey: "C097", sigla: "CE", provincia: "CASTEL DI SASSO", nazione: "ITALIA" },
  { codeKey: "C098", sigla: "RI", provincia: "CASTEL DI TORA", nazione: "ITALIA" },
  { codeKey: "C100", sigla: "AN", provincia: "CASTELFIDARDO", nazione: "ITALIA" },
  { codeKey: "C101", sigla: "FI", provincia: "CASTELFIORENTINO", nazione: "ITALIA" },
  { codeKey: "C102", sigla: "AR", provincia: "CASTEL FOCOGNANO", nazione: "ITALIA" },
  { codeKey: "C103", sigla: "TN", provincia: "CASTELFONDO", nazione: "ITALIA" },
  { codeKey: "C104", sigla: "LT", provincia: "CASTELFORTE", nazione: "ITALIA" },
  { codeKey: "C105", sigla: "AV", provincia: "CASTELFRANCI", nazione: "ITALIA" },
  {
    codeKey: "C106",
    sigla: "BN",
    provincia: "CASTELFRANCO IN MISCANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C107",
    sigla: "MO",
    provincia: "CASTELFRANCO EMILIA",
    nazione: "ITALIA",
  },
  { codeKey: "C108", sigla: "CS", provincia: "CASTROLIBERO", nazione: "ITALIA" },
  { codeKey: "C110", sigla: "IM", provincia: "CASTEL VITTORIO", nazione: "ITALIA" },
  {
    codeKey: "C111",
    sigla: "TV",
    provincia: "CASTELFRANCO VENETO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C113",
    sigla: "PI",
    provincia: "CASTELFRANCO DI SOTTO",
    nazione: "ITALIA",
  },
  { codeKey: "C114", sigla: "CH", provincia: "CASTEL FRENTANO", nazione: "ITALIA" },
  { codeKey: "C115", sigla: "CR", provincia: "CASTEL GABBIANO", nazione: "ITALIA" },
  { codeKey: "C116", sigla: "RM", provincia: "CASTEL GANDOLFO", nazione: "ITALIA" },
  { codeKey: "C117", sigla: "TR", provincia: "CASTEL GIORGIO", nazione: "ITALIA" },
  { codeKey: "C118", sigla: "MN", provincia: "CASTEL GOFFREDO", nazione: "ITALIA" },
  { codeKey: "C119", sigla: "VI", provincia: "CASTELGOMBERTO", nazione: "ITALIA" },
  { codeKey: "C120", sigla: "PZ", provincia: "CASTELGRANDE", nazione: "ITALIA" },
  {
    codeKey: "C121",
    sigla: "BO",
    provincia: "CASTEL GUELFO DI BOLOGNA",
    nazione: "ITALIA",
  },
  { codeKey: "C122", sigla: "RO", provincia: "CASTELGUGLIELMO", nazione: "ITALIA" },
  { codeKey: "C123", sigla: "CH", provincia: "CASTELGUIDONE", nazione: "ITALIA" },
  { codeKey: "C125", sigla: "SA", provincia: "CASTELLABATE", nazione: "ITALIA" },
  { codeKey: "C126", sigla: "AQ", provincia: "CASTELLAFIUME", nazione: "ITALIA" },
  { codeKey: "C127", sigla: "AT", provincia: "CASTELL'ALFERO", nazione: "ITALIA" },
  { codeKey: "C128", sigla: "TE", provincia: "CASTELLALTO", nazione: "ITALIA" },
  {
    codeKey: "C129",
    sigla: "NA",
    provincia: "CASTELLAMMARE DI STABIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C130",
    sigla: "TP",
    provincia: "CASTELLAMMARE DEL GOLFO",
    nazione: "ITALIA",
  },
  { codeKey: "C133", sigla: "TO", provincia: "CASTELLAMONTE", nazione: "ITALIA" },
  {
    codeKey: "C134",
    sigla: "BA",
    provincia: "CASTELLANA GROTTE",
    nazione: "ITALIA",
  },
  {
    codeKey: "C135",
    sigla: "PA",
    provincia: "CASTELLANA SICULA",
    nazione: "ITALIA",
  },
  { codeKey: "C136", sigla: "TA", provincia: "CASTELLANETA", nazione: "ITALIA" },
  { codeKey: "C137", sigla: "AL", provincia: "CASTELLANIA", nazione: "ITALIA" },
  { codeKey: "C139", sigla: "VA", provincia: "CASTELLANZA", nazione: "ITALIA" },
  { codeKey: "C140", sigla: "CN", provincia: "CASTELLAR", nazione: "ITALIA" },
  { codeKey: "C141", sigla: "RE", provincia: "CASTELLARANO", nazione: "ITALIA" },
  {
    codeKey: "C142",
    sigla: "AL",
    provincia: "CASTELLAR GUIDOBONO",
    nazione: "ITALIA",
  },
  { codeKey: "C143", sigla: "IM", provincia: "CASTELLARO", nazione: "ITALIA" },
  { codeKey: "C145", sigla: "PC", provincia: "CASTELL'ARQUATO", nazione: "ITALIA" },
  { codeKey: "C147", sigla: "GR", provincia: "CASTELL'AZZARA", nazione: "ITALIA" },
  {
    codeKey: "C148",
    sigla: "AL",
    provincia: "CASTELLAZZO BORMIDA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C149",
    sigla: "NO",
    provincia: "CASTELLAZZO NOVARESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "C152",
    sigla: "AN",
    provincia: "CASTELLEONE DI SUASA",
    nazione: "ITALIA",
  },
  { codeKey: "C153", sigla: "CR", provincia: "CASTELLEONE", nazione: "ITALIA" },
  { codeKey: "C154", sigla: "AT", provincia: "CASTELLERO", nazione: "ITALIA" },
  {
    codeKey: "C155",
    sigla: "BI",
    provincia: "CASTELLETTO CERVO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C156",
    sigla: "AL",
    provincia: "CASTELLETTO D'ERRO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C157",
    sigla: "PV",
    provincia: "CASTELLETTO DI BRANDUZZO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C158",
    sigla: "AL",
    provincia: "CASTELLETTO D'ORBA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C160",
    sigla: "AL",
    provincia: "CASTELLETTO MERLI",
    nazione: "ITALIA",
  },
  {
    codeKey: "C161",
    sigla: "AT",
    provincia: "CASTELLETTO MOLINA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C162",
    sigla: "AL",
    provincia: "CASTELLETTO MONFERRATO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C165",
    sigla: "CN",
    provincia: "CASTELLETTO STURA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C166",
    sigla: "NO",
    provincia: "CASTELLETTO SOPRA TICINO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C167",
    sigla: "CN",
    provincia: "CASTELLETTO UZZONE",
    nazione: "ITALIA",
  },
  { codeKey: "C169", sigla: "TE", provincia: "CASTELLI", nazione: "ITALIA" },
  {
    codeKey: "C172",
    sigla: "SI",
    provincia: "CASTELLINA IN CHIANTI",
    nazione: "ITALIA",
  },
  { codeKey: "C173", sigla: "CN", provincia: "CASTELLINALDO", nazione: "ITALIA" },
  {
    codeKey: "C174",
    sigla: "PI",
    provincia: "CASTELLINA MARITTIMA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C175",
    sigla: "CB",
    provincia: "CASTELLINO DEL BIFERNO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C176",
    sigla: "CN",
    provincia: "CASTELLINO TANARO",
    nazione: "ITALIA",
  },
  { codeKey: "C177", sigla: "FR", provincia: "CASTELLIRI", nazione: "ITALIA" },
  {
    codeKey: "C178",
    sigla: "CE",
    provincia: "CASTELLO DEL MATESE",
    nazione: "ITALIA",
  },
  { codeKey: "C181", sigla: "VA", provincia: "CASTELVECCANA", nazione: "ITALIA" },
  { codeKey: "C183", sigla: "TN", provincia: "CASTEL CONDINO", nazione: "ITALIA" },
  {
    codeKey: "C184",
    sigla: "PV",
    provincia: "CASTELLO D'AGOGNA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C185",
    sigla: "BO",
    provincia: "CASTELLO D'ARGILE",
    nazione: "ITALIA",
  },
  {
    codeKey: "C186",
    sigla: "SO",
    provincia: "CASTELLO DELL'ACQUA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C187",
    sigla: "LC",
    provincia: "CASTELLO DI BRIANZA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C188",
    sigla: "NA",
    provincia: "CASTELLO DI CISTERNA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C189",
    sigla: "TN",
    provincia: "CASTELLO-MOLINA DI FIEMME",
    nazione: "ITALIA",
  },
  {
    codeKey: "C190",
    sigla: "TV",
    provincia: "CASTELLO DI GODEGO",
    nazione: "ITALIA",
  },
  { codeKey: "C194", sigla: "TN", provincia: "CASTELLO TESINO", nazione: "ITALIA" },
  { codeKey: "C195", sigla: "MN", provincia: "CASTELLUCCHIO", nazione: "ITALIA" },
  { codeKey: "C197", sigla: "CB", provincia: "CASTELMAURO", nazione: "ITALIA" },
  {
    codeKey: "C198",
    sigla: "FG",
    provincia: "CASTELLUCCIO DEI SAURI",
    nazione: "ITALIA",
  },
  {
    codeKey: "C199",
    sigla: "PZ",
    provincia: "CASTELLUCCIO INFERIORE",
    nazione: "ITALIA",
  },
  { codeKey: "C200", sigla: "IS", provincia: "CASTELVERRINO", nazione: "ITALIA" },
  {
    codeKey: "C201",
    sigla: "PZ",
    provincia: "CASTELLUCCIO SUPERIORE",
    nazione: "ITALIA",
  },
  {
    codeKey: "C202",
    sigla: "FG",
    provincia: "CASTELLUCCIO VALMAGGIORE",
    nazione: "ITALIA",
  },
  { codeKey: "C203", sigla: "RM", provincia: "CASTEL MADAMA", nazione: "ITALIA" },
  { codeKey: "C204", sigla: "BO", provincia: "CASTEL MAGGIORE", nazione: "ITALIA" },
  { codeKey: "C205", sigla: "CN", provincia: "CASTELMAGNO", nazione: "ITALIA" },
  { codeKey: "C206", sigla: "CO", provincia: "CASTELMARTE", nazione: "ITALIA" },
  { codeKey: "C207", sigla: "RO", provincia: "CASTELMASSA", nazione: "ITALIA" },
  { codeKey: "C208", sigla: "BS", provincia: "CASTEL MELLA", nazione: "ITALIA" },
  { codeKey: "C209", sigla: "PZ", provincia: "CASTELMEZZANO", nazione: "ITALIA" },
  { codeKey: "C210", sigla: "ME", provincia: "CASTELMOLA", nazione: "ITALIA" },
  { codeKey: "C211", sigla: "CE", provincia: "CASTEL MORRONE", nazione: "ITALIA" },
  { codeKey: "C213", sigla: "PV", provincia: "CASTELNOVETTO", nazione: "ITALIA" },
  {
    codeKey: "C214",
    sigla: "CN",
    provincia: "CASTELNUOVO DI CEVA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C215",
    sigla: "RO",
    provincia: "CASTELNOVO BARIANO",
    nazione: "ITALIA",
  },
  { codeKey: "C216", sigla: "TN", provincia: "CASTELNUOVO", nazione: "ITALIA" },
  {
    codeKey: "C217",
    sigla: "PN",
    provincia: "CASTELNOVO DEL FRIULI",
    nazione: "ITALIA",
  },
  {
    codeKey: "C218",
    sigla: "RE",
    provincia: "CASTELNOVO DI SOTTO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C219",
    sigla: "RE",
    provincia: "CASTELNOVO NE' MONTI",
    nazione: "ITALIA",
  },
  {
    codeKey: "C220",
    sigla: "CO",
    provincia: "CASTELNUOVO BOZZENTE",
    nazione: "ITALIA",
  },
  {
    codeKey: "C222",
    sigla: "FG",
    provincia: "CASTELNUOVO DELLA DAUNIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C223",
    sigla: "FR",
    provincia: "CASTELNUOVO PARANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C224",
    sigla: "RI",
    provincia: "CASTELNUOVO DI FARFA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C225",
    sigla: "VR",
    provincia: "CASTELNUOVO DEL GARDA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C226",
    sigla: "AT",
    provincia: "CASTELNUOVO BELBO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C227",
    sigla: "SI",
    provincia: "CASTELNUOVO BERARDENGA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C228",
    sigla: "LO",
    provincia: "CASTELNUOVO BOCCA D'ADDA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C229",
    sigla: "AL",
    provincia: "CASTELNUOVO BORMIDA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C230",
    sigla: "AT",
    provincia: "CASTELNUOVO CALCEA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C231",
    sigla: "SA",
    provincia: "CASTELNUOVO CILENTO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C232",
    sigla: "AT",
    provincia: "CASTELNUOVO DON BOSCO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C235",
    sigla: "SA",
    provincia: "CASTELNUOVO DI CONZA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C236",
    sigla: "LU",
    provincia: "CASTELNUOVO DI GARFAGNANA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C237",
    sigla: "RM",
    provincia: "CASTELNUOVO DI PORTO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C240",
    sigla: "SP",
    provincia: "CASTELNUOVO MAGRA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C241",
    sigla: "TO",
    provincia: "CASTELNUOVO NIGRA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C242",
    sigla: "MO",
    provincia: "CASTELNUOVO RANGONE",
    nazione: "ITALIA",
  },
  {
    codeKey: "C243",
    sigla: "AL",
    provincia: "CASTELNUOVO SCRIVIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C244",
    sigla: "PI",
    provincia: "CASTELNUOVO VAL DI CECINA",
    nazione: "ITALIA",
  },
  { codeKey: "C245", sigla: "BN", provincia: "CASTELPAGANO", nazione: "ITALIA" },
  { codeKey: "C246", sigla: "IS", provincia: "CASTELPETROSO", nazione: "ITALIA" },
  { codeKey: "C247", sigla: "IS", provincia: "CASTELPIZZUTO", nazione: "ITALIA" },
  { codeKey: "C248", sigla: "AN", provincia: "CASTELPLANIO", nazione: "ITALIA" },
  { codeKey: "C250", sigla: "BN", provincia: "CASTELPOTO", nazione: "ITALIA" },
  { codeKey: "C251", sigla: "MC", provincia: "CASTELRAIMONDO", nazione: "ITALIA" },
  { codeKey: "C252", sigla: "PG", provincia: "CASTEL RITALDI", nazione: "ITALIA" },
  { codeKey: "C253", sigla: "AT", provincia: "CASTEL ROCCHERO", nazione: "ITALIA" },
  { codeKey: "C254", sigla: "BZ", provincia: "CASTELROTTO", nazione: "ITALIA" },
  { codeKey: "C255", sigla: "BG", provincia: "CASTEL ROZZONE", nazione: "ITALIA" },
  {
    codeKey: "C259",
    sigla: "SA",
    provincia: "CASTEL SAN GIORGIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C261",
    sigla: "PC",
    provincia: "CASTEL SAN GIOVANNI",
    nazione: "ITALIA",
  },
  {
    codeKey: "C262",
    sigla: "SA",
    provincia: "CASTEL SAN LORENZO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C263",
    sigla: "AR",
    provincia: "CASTEL SAN NICCOLO'",
    nazione: "ITALIA",
  },
  {
    codeKey: "C265",
    sigla: "BO",
    provincia: "CASTEL SAN PIETRO TERME",
    nazione: "ITALIA",
  },
  {
    codeKey: "C266",
    sigla: "RM",
    provincia: "CASTEL SAN PIETRO ROMANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C267",
    sigla: "MC",
    provincia: "CASTELSANTANGELO SUL NERA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C268",
    sigla: "RI",
    provincia: "CASTEL SANT'ANGELO",
    nazione: "ITALIA",
  },
  { codeKey: "C269", sigla: "VT", provincia: "CASTEL SANT'ELIA", nazione: "ITALIA" },
  {
    codeKey: "C270",
    sigla: "IS",
    provincia: "CASTEL SAN VINCENZO",
    nazione: "ITALIA",
  },
  { codeKey: "C271", sigla: "PZ", provincia: "CASTELSARACENO", nazione: "ITALIA" },
  { codeKey: "C272", sigla: "SS", provincia: "CASTELSARDO", nazione: "ITALIA" },
  { codeKey: "C273", sigla: "VA", provincia: "CASTELSEPRIO", nazione: "ITALIA" },
  { codeKey: "C274", sigla: "AL", provincia: "CASTELSPINA", nazione: "ITALIA" },
  { codeKey: "C275", sigla: "AG", provincia: "CASTELTERMINI", nazione: "ITALIA" },
  {
    codeKey: "C276",
    sigla: "SV",
    provincia: "CASTELVECCHIO DI ROCCA BARBENA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C278",
    sigla: "AQ",
    provincia: "CASTELVECCHIO CALVISIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C279",
    sigla: "AQ",
    provincia: "CASTELVECCHIO SUBEQUO",
    nazione: "ITALIA",
  },
  { codeKey: "C280", sigla: "BN", provincia: "CASTELVENERE", nazione: "ITALIA" },
  { codeKey: "C282", sigla: "AO", provincia: "VERRES", nazione: "ITALIA" },
  {
    codeKey: "C283",
    sigla: "AV",
    provincia: "CASTELVETERE SUL CALORE",
    nazione: "ITALIA",
  },
  {
    codeKey: "C284",
    sigla: "BN",
    provincia: "CASTELVETERE IN VAL FORTORE",
    nazione: "ITALIA",
  },
  { codeKey: "C285", sigla: "RC", provincia: "CAULONIA", nazione: "ITALIA" },
  { codeKey: "C286", sigla: "TP", provincia: "CASTELVETRANO", nazione: "ITALIA" },
  {
    codeKey: "C287",
    sigla: "MO",
    provincia: "CASTELVETRO DI MODENA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C288",
    sigla: "PC",
    provincia: "CASTELVETRO PIACENTINO",
    nazione: "ITALIA",
  },
  { codeKey: "C289", sigla: "TR", provincia: "CASTEL VISCARDO", nazione: "ITALIA" },
  { codeKey: "C290", sigla: "CR", provincia: "CASTELVISCONTI", nazione: "ITALIA" },
  { codeKey: "C291", sigla: "CE", provincia: "CASTEL VOLTURNO", nazione: "ITALIA" },
  { codeKey: "C292", sigla: "BO", provincia: "CASTENASO", nazione: "ITALIA" },
  { codeKey: "C293", sigla: "BS", provincia: "CASTENEDOLO", nazione: "ITALIA" },
  { codeKey: "C294", sigla: "AO", provincia: "CHATILLON", nazione: "ITALIA" },
  {
    codeKey: "C296",
    sigla: "BO",
    provincia: "CASTIGLIONE DEI PEPOLI",
    nazione: "ITALIA",
  },
  {
    codeKey: "C297",
    sigla: "CT",
    provincia: "CASTIGLIONE DI SICILIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C298",
    sigla: "CH",
    provincia: "CASTIGLIONE MESSER MARINO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C299",
    sigla: "CO",
    provincia: "CASTIGLIONE D'INTELVI",
    nazione: "ITALIA",
  },
  {
    codeKey: "C300",
    sigla: "VA",
    provincia: "CASTIGLIONE OLONA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C301",
    sigla: "CS",
    provincia: "CASTIGLIONE COSENTINO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C302",
    sigla: "GE",
    provincia: "CASTIGLIONE CHIAVARESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "C303",
    sigla: "LU",
    provincia: "CASTIGLIONE DI GARFAGNANA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C304",
    sigla: "LO",
    provincia: "CASTIGLIONE D'ADDA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C306",
    sigla: "SA",
    provincia: "CASTIGLIONE DEL GENOVESI",
    nazione: "ITALIA",
  },
  {
    codeKey: "C307",
    sigla: "TO",
    provincia: "CASTIGLIONE TORINESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "C308",
    sigla: "PE",
    provincia: "CASTIGLIONE A CASAURIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C309",
    sigla: "PG",
    provincia: "CASTIGLIONE DEL LAGO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C310",
    sigla: "GR",
    provincia: "CASTIGLIONE DELLA PESCAIA",
    nazione: "ITALIA",
  },
  { codeKey: "C311", sigla: "TE", provincia: "COLLEDARA", nazione: "ITALIA" },
  {
    codeKey: "C312",
    sigla: "MN",
    provincia: "CASTIGLIONE DELLE STIVIERE",
    nazione: "ITALIA",
  },
  {
    codeKey: "C313",
    sigla: "SI",
    provincia: "CASTIGLIONE D'ORCIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C314",
    sigla: "CN",
    provincia: "CASTIGLIONE FALLETTO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C315",
    sigla: "VT",
    provincia: "CASTIGLIONE IN TEVERINA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C316",
    sigla: "TE",
    provincia: "CASTIGLIONE MESSER RAIMONDO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C317",
    sigla: "CN",
    provincia: "CASTIGLIONE TINELLA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C318",
    sigla: "AR",
    provincia: "CASTIGLION FIBOCCHI",
    nazione: "ITALIA",
  },
  {
    codeKey: "C319",
    sigla: "AR",
    provincia: "CASTIGLION FIORENTINO",
    nazione: "ITALIA",
  },
  { codeKey: "C321", sigla: "AP", provincia: "CASTIGNANO", nazione: "ITALIA" },
  { codeKey: "C322", sigla: "TE", provincia: "CASTILENTI", nazione: "ITALIA" },
  { codeKey: "C323", sigla: "CN", provincia: "CASTINO", nazione: "ITALIA" },
  {
    codeKey: "C324",
    sigla: "BG",
    provincia: "CASTIONE DELLA PRESOLANA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C325",
    sigla: "SO",
    provincia: "CASTIONE ANDEVENNO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C327",
    sigla: "UD",
    provincia: "CASTIONS DI STRADA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C329",
    sigla: "LO",
    provincia: "CASTIRAGA VIDARDO",
    nazione: "ITALIA",
  },
  { codeKey: "C330", sigla: "BS", provincia: "CASTO", nazione: "ITALIA" },
  { codeKey: "C331", sigla: "AP", provincia: "CASTORANO", nazione: "ITALIA" },
  { codeKey: "C332", sigla: "BS", provincia: "CASTREZZATO", nazione: "ITALIA" },
  { codeKey: "C334", sigla: "LE", provincia: "CASTRI DI LECCE", nazione: "ITALIA" },
  {
    codeKey: "C335",
    sigla: "LE",
    provincia: "CASTRIGNANO DE' GRECI",
    nazione: "ITALIA",
  },
  {
    codeKey: "C336",
    sigla: "LE",
    provincia: "CASTRIGNANO DEL CAPO",
    nazione: "ITALIA",
  },
  { codeKey: "C337", sigla: "BG", provincia: "CASTRO", nazione: "ITALIA" },
  {
    codeKey: "C338",
    sigla: "FR",
    provincia: "CASTRO DEI VOLSCI",
    nazione: "ITALIA",
  },
  {
    codeKey: "C339",
    sigla: "FC",
    provincia: "CASTROCARO TERME E TERRA DEL SOLE",
    nazione: "ITALIA",
  },
  { codeKey: "C340", sigla: "FR", provincia: "CASTROCIELO", nazione: "ITALIA" },
  { codeKey: "C341", sigla: "AG", provincia: "CASTROFILIPPO", nazione: "ITALIA" },
  { codeKey: "C342", sigla: "EN", provincia: "ENNA", nazione: "ITALIA" },
  { codeKey: "C343", sigla: "VA", provincia: "CASTRONNO", nazione: "ITALIA" },
  {
    codeKey: "C344",
    sigla: "PA",
    provincia: "CASTRONOVO DI SICILIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C345",
    sigla: "PZ",
    provincia: "CASTRONUOVO DI SANT'ANDREA",
    nazione: "ITALIA",
  },
  { codeKey: "C346", sigla: "CB", provincia: "CASTROPIGNANO", nazione: "ITALIA" },
  { codeKey: "C347", sigla: "ME", provincia: "CASTROREALE", nazione: "ITALIA" },
  { codeKey: "C348", sigla: "CS", provincia: "CASTROREGIO", nazione: "ITALIA" },
  { codeKey: "C349", sigla: "CS", provincia: "CASTROVILLARI", nazione: "ITALIA" },
  { codeKey: "C351", sigla: "CT", provincia: "CATANIA", nazione: "ITALIA" },
  { codeKey: "C352", sigla: "CZ", provincia: "CATANZARO", nazione: "ITALIA" },
  { codeKey: "C353", sigla: "EN", provincia: "CATENANUOVA", nazione: "ITALIA" },
  { codeKey: "C354", sigla: "PE", provincia: "CATIGNANO", nazione: "ITALIA" },
  {
    codeKey: "C356",
    sigla: "AG",
    provincia: "CATTOLICA ERACLEA",
    nazione: "ITALIA",
  },
  { codeKey: "C357", sigla: "RN", provincia: "CATTOLICA", nazione: "ITALIA" },
  { codeKey: "C359", sigla: "BN", provincia: "CAUTANO", nazione: "ITALIA" },
  { codeKey: "C360", sigla: "PV", provincia: "CAVA MANARA", nazione: "ITALIA" },
  { codeKey: "C361", sigla: "SA", provincia: "CAVA DE' TIRRENI", nazione: "ITALIA" },
  { codeKey: "C362", sigla: "LO", provincia: "CAVACURTA", nazione: "ITALIA" },
  { codeKey: "C363", sigla: "BI", provincia: "CAVAGLIA'", nazione: "ITALIA" },
  { codeKey: "C364", sigla: "NO", provincia: "CAVAGLIETTO", nazione: "ITALIA" },
  {
    codeKey: "C365",
    sigla: "NO",
    provincia: "CAVAGLIO D'AGOGNA",
    nazione: "ITALIA",
  },
  { codeKey: "C367", sigla: "VB", provincia: "CAVAGLIO-SPOCCIA", nazione: "ITALIA" },
  { codeKey: "C369", sigla: "TO", provincia: "CAVAGNOLO", nazione: "ITALIA" },
  { codeKey: "C370", sigla: "VR", provincia: "CAVAION VERONESE", nazione: "ITALIA" },
  { codeKey: "C372", sigla: "TN", provincia: "CAVALESE", nazione: "ITALIA" },
  { codeKey: "C374", sigla: "CO", provincia: "CAVALLASCA", nazione: "ITALIA" },
  { codeKey: "C375", sigla: "CN", provincia: "CAVALLERLEONE", nazione: "ITALIA" },
  { codeKey: "C376", sigla: "CN", provincia: "CAVALLERMAGGIORE", nazione: "ITALIA" },
  { codeKey: "C377", sigla: "LE", provincia: "CAVALLINO", nazione: "ITALIA" },
  { codeKey: "C378", sigla: "NO", provincia: "CAVALLIRIO", nazione: "ITALIA" },
  { codeKey: "C380", sigla: "TN", provincia: "CAVARENO", nazione: "ITALIA" },
  { codeKey: "C381", sigla: "CO", provincia: "CAVARGNA", nazione: "ITALIA" },
  {
    codeKey: "C382",
    sigla: "VA",
    provincia: "CAVARIA CON PREMEZZO",
    nazione: "ITALIA",
  },
  { codeKey: "C383", sigla: "VE", provincia: "CAVARZERE", nazione: "ITALIA" },
  { codeKey: "C384", sigla: "TV", provincia: "CAVASO DEL TOMBA", nazione: "ITALIA" },
  { codeKey: "C385", sigla: "PN", provincia: "CAVASSO NUOVO", nazione: "ITALIA" },
  { codeKey: "C387", sigla: "AL", provincia: "CAVATORE", nazione: "ITALIA" },
  { codeKey: "C388", sigla: "VE", provincia: "JESOLO", nazione: "ITALIA" },
  { codeKey: "C389", sigla: "UD", provincia: "CAVAZZO CARNICO", nazione: "ITALIA" },
  { codeKey: "C390", sigla: "RM", provincia: "CAVE", nazione: "ITALIA" },
  { codeKey: "C392", sigla: "TN", provincia: "CAVEDAGO", nazione: "ITALIA" },
  { codeKey: "C393", sigla: "TN", provincia: "CAVEDINE", nazione: "ITALIA" },
  { codeKey: "C394", sigla: "LO", provincia: "CAVENAGO D'ADDA", nazione: "ITALIA" },
  {
    codeKey: "C395",
    sigla: "MB",
    provincia: "CAVENAGO DI BRIANZA",
    nazione: "ITALIA",
  },
  { codeKey: "C396", sigla: "BG", provincia: "CAVERNAGO", nazione: "ITALIA" },
  { codeKey: "C398", sigla: "MO", provincia: "CAVEZZO", nazione: "ITALIA" },
  { codeKey: "C400", sigla: "TN", provincia: "CAVIZZANA", nazione: "ITALIA" },
  { codeKey: "C404", sigla: "TO", provincia: "CAVOUR", nazione: "ITALIA" },
  { codeKey: "C405", sigla: "RE", provincia: "CAVRIAGO", nazione: "ITALIA" },
  { codeKey: "C406", sigla: "MN", provincia: "CAVRIANA", nazione: "ITALIA" },
  { codeKey: "C407", sigla: "AR", provincia: "CAVRIGLIA", nazione: "ITALIA" },
  {
    codeKey: "C408",
    sigla: "BS",
    provincia: "CAZZAGO SAN MARTINO",
    nazione: "ITALIA",
  },
  { codeKey: "C409", sigla: "VA", provincia: "CAZZAGO BRABBIA", nazione: "ITALIA" },
  {
    codeKey: "C410",
    sigla: "BG",
    provincia: "CAZZANO SANT'ANDREA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C412",
    sigla: "VR",
    provincia: "CAZZANO DI TRAMIGNA",
    nazione: "ITALIA",
  },
  { codeKey: "C413", sigla: "FR", provincia: "CECCANO", nazione: "ITALIA" },
  { codeKey: "C414", sigla: "PV", provincia: "CECIMA", nazione: "ITALIA" },
  { codeKey: "C415", sigla: "LI", provincia: "CECINA", nazione: "ITALIA" },
  { codeKey: "C417", sigla: "BS", provincia: "CEDEGOLO", nazione: "ITALIA" },
  { codeKey: "C418", sigla: "SO", provincia: "CEDRASCO", nazione: "ITALIA" },
  { codeKey: "C420", sigla: "PA", provincia: "CEFALA' DIANA", nazione: "ITALIA" },
  { codeKey: "C421", sigla: "PA", provincia: "CEFALU'", nazione: "ITALIA" },
  { codeKey: "C422", sigla: "VE", provincia: "CEGGIA", nazione: "ITALIA" },
  { codeKey: "C424", sigla: "BR", provincia: "CEGLIE MESSAPICA", nazione: "ITALIA" },
  { codeKey: "C426", sigla: "AQ", provincia: "CELANO", nazione: "ITALIA" },
  {
    codeKey: "C428",
    sigla: "CH",
    provincia: "CELENZA SUL TRIGNO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C429",
    sigla: "FG",
    provincia: "CELENZA VALFORTORE",
    nazione: "ITALIA",
  },
  { codeKey: "C430", sigla: "CS", provincia: "CELICO", nazione: "ITALIA" },
  { codeKey: "C432", sigla: "AL", provincia: "CELLA MONTE", nazione: "ITALIA" },
  { codeKey: "C435", sigla: "CR", provincia: "CELLA DATI", nazione: "ITALIA" },
  { codeKey: "C436", sigla: "BA", provincia: "CELLAMARE", nazione: "ITALIA" },
  { codeKey: "C437", sigla: "CS", provincia: "CELLARA", nazione: "ITALIA" },
  { codeKey: "C438", sigla: "AT", provincia: "CELLARENGO", nazione: "ITALIA" },
  { codeKey: "C439", sigla: "BS", provincia: "CELLATICA", nazione: "ITALIA" },
  { codeKey: "C440", sigla: "AT", provincia: "CELLE ENOMONDO", nazione: "ITALIA" },
  { codeKey: "C441", sigla: "CN", provincia: "CELLE DI MACRA", nazione: "ITALIA" },
  { codeKey: "C442", sigla: "FG", provincia: "CELLE SAN VITO", nazione: "ITALIA" },
  { codeKey: "C443", sigla: "SV", provincia: "CELLE LIGURE", nazione: "ITALIA" },
  {
    codeKey: "C444",
    sigla: "SA",
    provincia: "CELLE DI BULGHERIA",
    nazione: "ITALIA",
  },
  { codeKey: "C446", sigla: "VT", provincia: "CELLENO", nazione: "ITALIA" },
  { codeKey: "C447", sigla: "VT", provincia: "CELLERE", nazione: "ITALIA" },
  {
    codeKey: "C448",
    sigla: "BR",
    provincia: "CELLINO SAN MARCO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C449",
    sigla: "TE",
    provincia: "CELLINO ATTANASIO",
    nazione: "ITALIA",
  },
  { codeKey: "C450", sigla: "VC", provincia: "CELLIO", nazione: "ITALIA" },
  { codeKey: "C452", sigla: "TN", provincia: "CEMBRA", nazione: "ITALIA" },
  { codeKey: "C453", sigla: "CZ", provincia: "CENADI", nazione: "ITALIA" },
  { codeKey: "C456", sigla: "BG", provincia: "CENATE SOPRA", nazione: "ITALIA" },
  { codeKey: "C457", sigla: "BG", provincia: "CENATE SOTTO", nazione: "ITALIA" },
  {
    codeKey: "C458",
    sigla: "BL",
    provincia: "CENCENIGHE AGORDINO",
    nazione: "ITALIA",
  },
  { codeKey: "C459", sigla: "BG", provincia: "CENE", nazione: "ITALIA" },
  { codeKey: "C461", sigla: "RO", provincia: "CENESELLI", nazione: "ITALIA" },
  { codeKey: "C463", sigla: "SV", provincia: "CENGIO", nazione: "ITALIA" },
  { codeKey: "C466", sigla: "CN", provincia: "CENTALLO", nazione: "ITALIA" },
  {
    codeKey: "C467",
    sigla: "TN",
    provincia: "CENTA SAN NICOLO'",
    nazione: "ITALIA",
  },
  { codeKey: "C469", sigla: "FE", provincia: "CENTO", nazione: "ITALIA" },
  { codeKey: "C470", sigla: "SA", provincia: "CENTOLA", nazione: "ITALIA" },
  { codeKey: "C471", sigla: "EN", provincia: "CENTURIPE", nazione: "ITALIA" },
  { codeKey: "C472", sigla: "CZ", provincia: "CENTRACHE", nazione: "ITALIA" },
  { codeKey: "C474", sigla: "PE", provincia: "CEPAGATTI", nazione: "ITALIA" },
  { codeKey: "C476", sigla: "BN", provincia: "CEPPALONI", nazione: "ITALIA" },
  { codeKey: "C478", sigla: "VB", provincia: "CEPPO MORELLI", nazione: "ITALIA" },
  { codeKey: "C479", sigla: "FR", provincia: "CEPRANO", nazione: "ITALIA" },
  { codeKey: "C480", sigla: "EN", provincia: "CERAMI", nazione: "ITALIA" },
  { codeKey: "C481", sigla: "GE", provincia: "CERANESI", nazione: "ITALIA" },
  { codeKey: "C482", sigla: "CO", provincia: "CERANO D'INTELVI", nazione: "ITALIA" },
  { codeKey: "C483", sigla: "NO", provincia: "CERANO", nazione: "ITALIA" },
  { codeKey: "C484", sigla: "PV", provincia: "CERANOVA", nazione: "ITALIA" },
  { codeKey: "C485", sigla: "SA", provincia: "CERASO", nazione: "ITALIA" },
  { codeKey: "C486", sigla: "CB", provincia: "CERCEMAGGIORE", nazione: "ITALIA" },
  { codeKey: "C487", sigla: "TO", provincia: "CERCENASCO", nazione: "ITALIA" },
  { codeKey: "C488", sigla: "CB", provincia: "CERCEPICCOLA", nazione: "ITALIA" },
  {
    codeKey: "C489",
    sigla: "CS",
    provincia: "CERCHIARA DI CALABRIA",
    nazione: "ITALIA",
  },
  { codeKey: "C492", sigla: "AQ", provincia: "CERCHIO", nazione: "ITALIA" },
  { codeKey: "C493", sigla: "SO", provincia: "CERCINO", nazione: "ITALIA" },
  { codeKey: "C494", sigla: "UD", provincia: "CERCIVENTO", nazione: "ITALIA" },
  { codeKey: "C495", sigla: "NA", provincia: "CERCOLA", nazione: "ITALIA" },
  { codeKey: "C496", sigla: "PA", provincia: "CERDA", nazione: "ITALIA" },
  { codeKey: "C497", sigla: "TO", provincia: "CERES", nazione: "ITALIA" },
  { codeKey: "C498", sigla: "VR", provincia: "CEREA", nazione: "ITALIA" },
  { codeKey: "C500", sigla: "RO", provincia: "CEREGNANO", nazione: "ITALIA" },
  { codeKey: "C501", sigla: "KR", provincia: "CERENZIA", nazione: "ITALIA" },
  { codeKey: "C502", sigla: "MN", provincia: "CERESARA", nazione: "ITALIA" },
  { codeKey: "C503", sigla: "AL", provincia: "CERESETO", nazione: "ITALIA" },
  { codeKey: "C504", sigla: "CN", provincia: "CERESOLE ALBA", nazione: "ITALIA" },
  { codeKey: "C505", sigla: "TO", provincia: "CERESOLE REALE", nazione: "ITALIA" },
  { codeKey: "C506", sigla: "BG", provincia: "CERETE", nazione: "ITALIA" },
  { codeKey: "C507", sigla: "AL", provincia: "CERRETO GRUE", nazione: "ITALIA" },
  {
    codeKey: "C508",
    sigla: "PV",
    provincia: "CERETTO LOMELLINA",
    nazione: "ITALIA",
  },
  { codeKey: "C509", sigla: "PV", provincia: "CERGNAGO", nazione: "ITALIA" },
  { codeKey: "C510", sigla: "SV", provincia: "CERIALE", nazione: "ITALIA" },
  { codeKey: "C511", sigla: "IM", provincia: "CERIANA", nazione: "ITALIA" },
  { codeKey: "C512", sigla: "MB", provincia: "CERIANO LAGHETTO", nazione: "ITALIA" },
  { codeKey: "C513", sigla: "PC", provincia: "CERIGNALE", nazione: "ITALIA" },
  { codeKey: "C514", sigla: "FG", provincia: "CERIGNOLA", nazione: "ITALIA" },
  { codeKey: "C515", sigla: "CS", provincia: "CERISANO", nazione: "ITALIA" },
  { codeKey: "C516", sigla: "CO", provincia: "CERMENATE", nazione: "ITALIA" },
  { codeKey: "C517", sigla: "TE", provincia: "CERMIGNANO", nazione: "ITALIA" },
  { codeKey: "C518", sigla: "RM", provincia: "CERRETO LAZIALE", nazione: "ITALIA" },
  { codeKey: "C520", sigla: "CO", provincia: "CERNOBBIO", nazione: "ITALIA" },
  {
    codeKey: "C521",
    sigla: "LC",
    provincia: "CERNUSCO LOMBARDONE",
    nazione: "ITALIA",
  },
  {
    codeKey: "C523",
    sigla: "MI",
    provincia: "CERNUSCO SUL NAVIGLIO",
    nazione: "ITALIA",
  },
  { codeKey: "C524", sigla: "AN", provincia: "CERRETO D'ESI", nazione: "ITALIA" },
  { codeKey: "C525", sigla: "BN", provincia: "CERRETO SANNITA", nazione: "ITALIA" },
  { codeKey: "C526", sigla: "BI", provincia: "CERRETO CASTELLO", nazione: "ITALIA" },
  {
    codeKey: "C527",
    sigla: "PG",
    provincia: "CERRETO DI SPOLETO",
    nazione: "ITALIA",
  },
  { codeKey: "C528", sigla: "AT", provincia: "CERRETO D'ASTI", nazione: "ITALIA" },
  { codeKey: "C529", sigla: "FI", provincia: "CERRETO GUIDI", nazione: "ITALIA" },
  { codeKey: "C530", sigla: "CN", provincia: "CERRETTO LANGHE", nazione: "ITALIA" },
  { codeKey: "C531", sigla: "AL", provincia: "CERRINA", nazione: "ITALIA" },
  { codeKey: "C532", sigla: "BI", provincia: "CERRIONE", nazione: "ITALIA" },
  { codeKey: "C533", sigla: "AT", provincia: "CERRO TANARO", nazione: "ITALIA" },
  {
    codeKey: "C534",
    sigla: "IS",
    provincia: "CERRO AL VOLTURNO",
    nazione: "ITALIA",
  },
  { codeKey: "C536", sigla: "MI", provincia: "CERRO AL LAMBRO", nazione: "ITALIA" },
  { codeKey: "C537", sigla: "MI", provincia: "CERRO MAGGIORE", nazione: "ITALIA" },
  { codeKey: "C538", sigla: "VR", provincia: "CERRO VERONESE", nazione: "ITALIA" },
  { codeKey: "C539", sigla: "PZ", provincia: "CERSOSIMO", nazione: "ITALIA" },
  { codeKey: "C540", sigla: "FI", provincia: "CERTALDO", nazione: "ITALIA" },
  { codeKey: "C541", sigla: "PV", provincia: "CERTOSA DI PAVIA", nazione: "ITALIA" },
  { codeKey: "C542", sigla: "CZ", provincia: "CERVA", nazione: "ITALIA" },
  { codeKey: "C543", sigla: "RM", provincia: "CERVARA DI ROMA", nazione: "ITALIA" },
  {
    codeKey: "C544",
    sigla: "PD",
    provincia: "CERVARESE SANTA CROCE",
    nazione: "ITALIA",
  },
  { codeKey: "C545", sigla: "FR", provincia: "CERVARO", nazione: "ITALIA" },
  { codeKey: "C547", sigla: "CN", provincia: "CERVASCA", nazione: "ITALIA" },
  { codeKey: "C548", sigla: "VC", provincia: "CERVATTO", nazione: "ITALIA" },
  { codeKey: "C549", sigla: "BS", provincia: "CERVENO", nazione: "ITALIA" },
  { codeKey: "C550", sigla: "CN", provincia: "CERVERE", nazione: "ITALIA" },
  { codeKey: "C551", sigla: "PV", provincia: "CERVESINA", nazione: "ITALIA" },
  { codeKey: "C552", sigla: "RM", provincia: "CERVETERI", nazione: "ITALIA" },
  { codeKey: "C553", sigla: "RA", provincia: "CERVIA", nazione: "ITALIA" },
  { codeKey: "C554", sigla: "CS", provincia: "CERVICATI", nazione: "ITALIA" },
  {
    codeKey: "C555",
    sigla: "LO",
    provincia: "CERVIGNANO D'ADDA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C556",
    sigla: "UD",
    provincia: "CERVIGNANO DEL FRIULI",
    nazione: "ITALIA",
  },
  { codeKey: "C557", sigla: "AV", provincia: "CERVINARA", nazione: "ITALIA" },
  { codeKey: "C558", sigla: "CE", provincia: "CERVINO", nazione: "ITALIA" },
  { codeKey: "C559", sigla: "IM", provincia: "CERVO", nazione: "ITALIA" },
  { codeKey: "C560", sigla: "CS", provincia: "CERZETO", nazione: "ITALIA" },
  { codeKey: "C561", sigla: "CE", provincia: "CESA", nazione: "ITALIA" },
  { codeKey: "C562", sigla: "BL", provincia: "LENTIAI", nazione: "ITALIA" },
  { codeKey: "C563", sigla: "LC", provincia: "CESANA BRIANZA", nazione: "ITALIA" },
  { codeKey: "C564", sigla: "TO", provincia: "CESANA TORINESE", nazione: "ITALIA" },
  { codeKey: "C565", sigla: "MI", provincia: "CESANO BOSCONE", nazione: "ITALIA" },
  { codeKey: "C566", sigla: "MB", provincia: "CESANO MADERNO", nazione: "ITALIA" },
  { codeKey: "C567", sigla: "VB", provincia: "CESARA", nazione: "ITALIA" },
  { codeKey: "C568", sigla: "ME", provincia: "CESARO'", nazione: "ITALIA" },
  { codeKey: "C569", sigla: "MI", provincia: "CESATE", nazione: "ITALIA" },
  { codeKey: "C573", sigla: "FC", provincia: "CESENA", nazione: "ITALIA" },
  { codeKey: "C574", sigla: "FC", provincia: "CESENATICO", nazione: "ITALIA" },
  { codeKey: "C576", sigla: "AV", provincia: "CESINALI", nazione: "ITALIA" },
  { codeKey: "C577", sigla: "BL", provincia: "CESIOMAGGIORE", nazione: "ITALIA" },
  { codeKey: "C578", sigla: "IM", provincia: "CESIO", nazione: "ITALIA" },
  { codeKey: "C580", sigla: "TV", provincia: "CESSALTO", nazione: "ITALIA" },
  { codeKey: "C581", sigla: "VV", provincia: "CESSANITI", nazione: "ITALIA" },
  { codeKey: "C582", sigla: "MC", provincia: "CESSAPALOMBO", nazione: "ITALIA" },
  { codeKey: "C583", sigla: "AT", provincia: "CESSOLE", nazione: "ITALIA" },
  { codeKey: "C584", sigla: "SA", provincia: "CETARA", nazione: "ITALIA" },
  { codeKey: "C585", sigla: "BS", provincia: "CETO", nazione: "ITALIA" },
  { codeKey: "C587", sigla: "SI", provincia: "CETONA", nazione: "ITALIA" },
  { codeKey: "C588", sigla: "CS", provincia: "CETRARO", nazione: "ITALIA" },
  { codeKey: "C589", sigla: "CN", provincia: "CEVA", nazione: "ITALIA" },
  { codeKey: "C591", sigla: "BS", provincia: "CEVO", nazione: "ITALIA" },
  {
    codeKey: "C593",
    sigla: "AO",
    provincia: "CHALLAND-SAINT-ANSELME",
    nazione: "ITALIA",
  },
  {
    codeKey: "C594",
    sigla: "AO",
    provincia: "CHALLAND-SAINT-VICTOR",
    nazione: "ITALIA",
  },
  { codeKey: "C595", sigla: "AO", provincia: "CHAMBAVE", nazione: "ITALIA" },
  { codeKey: "C596", sigla: "AO", provincia: "CHAMPDEPRAZ", nazione: "ITALIA" },
  { codeKey: "C598", sigla: "AO", provincia: "CHARVENSOD", nazione: "ITALIA" },
  { codeKey: "C599", sigla: "CN", provincia: "CHERASCO", nazione: "ITALIA" },
  { codeKey: "C600", sigla: "SS", provincia: "CHEREMULE", nazione: "ITALIA" },
  { codeKey: "C604", sigla: "TO", provincia: "CHIALAMBERTO", nazione: "ITALIA" },
  { codeKey: "C605", sigla: "VI", provincia: "CHIAMPO", nazione: "ITALIA" },
  { codeKey: "C606", sigla: "AV", provincia: "CHIANCHE", nazione: "ITALIA" },
  { codeKey: "C608", sigla: "SI", provincia: "CHIANCIANO TERME", nazione: "ITALIA" },
  { codeKey: "C609", sigla: "PI", provincia: "CHIANNI", nazione: "ITALIA" },
  { codeKey: "C610", sigla: "TO", provincia: "CHIANOCCO", nazione: "ITALIA" },
  {
    codeKey: "C612",
    sigla: "RG",
    provincia: "CHIARAMONTE GULFI",
    nazione: "ITALIA",
  },
  { codeKey: "C613", sigla: "SS", provincia: "CHIARAMONTI", nazione: "ITALIA" },
  { codeKey: "C614", sigla: "TV", provincia: "CHIARANO", nazione: "ITALIA" },
  { codeKey: "C615", sigla: "AN", provincia: "CHIARAVALLE", nazione: "ITALIA" },
  {
    codeKey: "C616",
    sigla: "CZ",
    provincia: "CHIARAVALLE CENTRALE",
    nazione: "ITALIA",
  },
  { codeKey: "C618", sigla: "BS", provincia: "CHIARI", nazione: "ITALIA" },
  { codeKey: "C619", sigla: "PZ", provincia: "CHIAROMONTE", nazione: "ITALIA" },
  { codeKey: "C620", sigla: "IS", provincia: "CHIAUCI", nazione: "ITALIA" },
  { codeKey: "C621", sigla: "GE", provincia: "CHIAVARI", nazione: "ITALIA" },
  { codeKey: "C623", sigla: "SO", provincia: "CHIAVENNA", nazione: "ITALIA" },
  { codeKey: "C624", sigla: "TO", provincia: "CHIAVERANO", nazione: "ITALIA" },
  { codeKey: "C625", sigla: "BZ", provincia: "CHIENES", nazione: "ITALIA" },
  { codeKey: "C627", sigla: "TO", provincia: "CHIERI", nazione: "ITALIA" },
  {
    codeKey: "C628",
    sigla: "SO",
    provincia: "CHIESA IN VALMALENCO",
    nazione: "ITALIA",
  },
  { codeKey: "C629", sigla: "TO", provincia: "CHIESANUOVA", nazione: "ITALIA" },
  { codeKey: "C630", sigla: "BL", provincia: "CHIES D'ALPAGO", nazione: "ITALIA" },
  {
    codeKey: "C631",
    sigla: "PT",
    provincia: "CHIESINA UZZANESE",
    nazione: "ITALIA",
  },
  { codeKey: "C632", sigla: "CH", provincia: "CHIETI", nazione: "ITALIA" },
  { codeKey: "C633", sigla: "FG", provincia: "CHIEUTI", nazione: "ITALIA" },
  { codeKey: "C634", sigla: "CR", provincia: "CHIEVE", nazione: "ITALIA" },
  { codeKey: "C635", sigla: "BG", provincia: "CHIGNOLO D'ISOLA", nazione: "ITALIA" },
  { codeKey: "C637", sigla: "PV", provincia: "CHIGNOLO PO", nazione: "ITALIA" },
  { codeKey: "C638", sigla: "VE", provincia: "CHIOGGIA", nazione: "ITALIA" },
  { codeKey: "C639", sigla: "TO", provincia: "CHIOMONTE", nazione: "ITALIA" },
  { codeKey: "C640", sigla: "PN", provincia: "CHIONS", nazione: "ITALIA" },
  { codeKey: "C641", sigla: "UD", provincia: "CHIOPRIS VISCONE", nazione: "ITALIA" },
  { codeKey: "C648", sigla: "AR", provincia: "CHITIGNANO", nazione: "ITALIA" },
  { codeKey: "C649", sigla: "BG", provincia: "CHIUDUNO", nazione: "ITALIA" },
  { codeKey: "C650", sigla: "VI", provincia: "CHIUPPANO", nazione: "ITALIA" },
  { codeKey: "C651", sigla: "SO", provincia: "CHIURO", nazione: "ITALIA" },
  { codeKey: "C652", sigla: "BZ", provincia: "CHIUSA", nazione: "ITALIA" },
  { codeKey: "C653", sigla: "CN", provincia: "CHIUSA DI PESIO", nazione: "ITALIA" },
  { codeKey: "C654", sigla: "PA", provincia: "CHIUSA SCLAFANI", nazione: "ITALIA" },
  {
    codeKey: "C655",
    sigla: "TO",
    provincia: "CHIUSA DI SAN MICHELE",
    nazione: "ITALIA",
  },
  { codeKey: "C656", sigla: "UD", provincia: "CHIUSAFORTE", nazione: "ITALIA" },
  { codeKey: "C657", sigla: "IM", provincia: "CHIUSANICO", nazione: "ITALIA" },
  { codeKey: "C658", sigla: "AT", provincia: "CHIUSANO D'ASTI", nazione: "ITALIA" },
  {
    codeKey: "C659",
    sigla: "AV",
    provincia: "CHIUSANO DI SAN DOMENICO",
    nazione: "ITALIA",
  },
  { codeKey: "C660", sigla: "IM", provincia: "CHIUSAVECCHIA", nazione: "ITALIA" },
  { codeKey: "C661", sigla: "SI", provincia: "CHIUSDINO", nazione: "ITALIA" },
  { codeKey: "C662", sigla: "SI", provincia: "CHIUSI", nazione: "ITALIA" },
  {
    codeKey: "C663",
    sigla: "AR",
    provincia: "CHIUSI DELLA VERNA",
    nazione: "ITALIA",
  },
  { codeKey: "C665", sigla: "TO", provincia: "CHIVASSO", nazione: "ITALIA" },
  { codeKey: "C668", sigla: "AG", provincia: "CIANCIANA", nazione: "ITALIA" },
  { codeKey: "C669", sigla: "RE", provincia: "CANOSSA", nazione: "ITALIA" },
  {
    codeKey: "C670",
    sigla: "TV",
    provincia: "CROCETTA DEL MONTELLO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C672",
    sigla: "BL",
    provincia: "CIBIANA DI CADORE",
    nazione: "ITALIA",
  },
  { codeKey: "C673", sigla: "GE", provincia: "CICAGNA", nazione: "ITALIA" },
  { codeKey: "C674", sigla: "CZ", provincia: "CICALA", nazione: "ITALIA" },
  { codeKey: "C675", sigla: "NA", provincia: "CICCIANO", nazione: "ITALIA" },
  { codeKey: "C676", sigla: "SA", provincia: "CICERALE", nazione: "ITALIA" },
  { codeKey: "C677", sigla: "RM", provincia: "CICILIANO", nazione: "ITALIA" },
  { codeKey: "C678", sigla: "CR", provincia: "CICOGNOLO", nazione: "ITALIA" },
  { codeKey: "C679", sigla: "TO", provincia: "CICONIO", nazione: "ITALIA" },
  { codeKey: "C680", sigla: "VC", provincia: "CIGLIANO", nazione: "ITALIA" },
  { codeKey: "C681", sigla: "CN", provincia: "CIGLIE'", nazione: "ITALIA" },
  { codeKey: "C684", sigla: "PV", provincia: "CIGOGNOLA", nazione: "ITALIA" },
  { codeKey: "C685", sigla: "BS", provincia: "CIGOLE", nazione: "ITALIA" },
  { codeKey: "C686", sigla: "PV", provincia: "CILAVEGNA", nazione: "ITALIA" },
  { codeKey: "C689", sigla: "TV", provincia: "CIMADOLMO", nazione: "ITALIA" },
  { codeKey: "C691", sigla: "BS", provincia: "CIMBERGO", nazione: "ITALIA" },
  { codeKey: "C694", sigla: "TN", provincia: "CIMEGO", nazione: "ITALIA" },
  { codeKey: "C695", sigla: "RC", provincia: "CIMINA'", nazione: "ITALIA" },
  { codeKey: "C696", sigla: "PA", provincia: "CIMINNA", nazione: "ITALIA" },
  { codeKey: "C697", sigla: "NA", provincia: "CIMITILE", nazione: "ITALIA" },
  {
    codeKey: "C698",
    sigla: "BS",
    provincia: "TAVERNOLE SUL MELLA",
    nazione: "ITALIA",
  },
  { codeKey: "C699", sigla: "PN", provincia: "CIMOLAIS", nazione: "ITALIA" },
  { codeKey: "C700", sigla: "TN", provincia: "CIMONE", nazione: "ITALIA" },
  { codeKey: "C701", sigla: "AT", provincia: "CINAGLIO", nazione: "ITALIA" },
  { codeKey: "C702", sigla: "RM", provincia: "CINETO ROMANO", nazione: "ITALIA" },
  { codeKey: "C703", sigla: "CR", provincia: "CINGIA DE' BOTTI", nazione: "ITALIA" },
  { codeKey: "C704", sigla: "MC", provincia: "CINGOLI", nazione: "ITALIA" },
  { codeKey: "C705", sigla: "GR", provincia: "CINIGIANO", nazione: "ITALIA" },
  {
    codeKey: "C707",
    sigla: "MI",
    provincia: "CINISELLO BALSAMO",
    nazione: "ITALIA",
  },
  { codeKey: "C708", sigla: "PA", provincia: "CINISI", nazione: "ITALIA" },
  { codeKey: "C709", sigla: "SO", provincia: "CINO", nazione: "ITALIA" },
  { codeKey: "C710", sigla: "RC", provincia: "CINQUEFRONDI", nazione: "ITALIA" },
  { codeKey: "C711", sigla: "TO", provincia: "CINTANO", nazione: "ITALIA" },
  { codeKey: "C712", sigla: "TN", provincia: "CINTE TESINO", nazione: "ITALIA" },
  { codeKey: "C713", sigla: "PD", provincia: "CINTO EUGANEO", nazione: "ITALIA" },
  {
    codeKey: "C714",
    sigla: "VE",
    provincia: "CINTO CAOMAGGIORE",
    nazione: "ITALIA",
  },
  { codeKey: "C715", sigla: "TO", provincia: "CINZANO", nazione: "ITALIA" },
  { codeKey: "C716", sigla: "CE", provincia: "CIORLANO", nazione: "ITALIA" },
  {
    codeKey: "C717",
    sigla: "CS",
    provincia: "SANTA MARIA DEL CEDRO",
    nazione: "ITALIA",
  },
  { codeKey: "C718", sigla: "IM", provincia: "CIPRESSA", nazione: "ITALIA" },
  { codeKey: "C719", sigla: "BN", provincia: "CIRCELLO", nazione: "ITALIA" },
  { codeKey: "C722", sigla: "TO", provincia: "CIRIE'", nazione: "ITALIA" },
  { codeKey: "C723", sigla: "MT", provincia: "CIRIGLIANO", nazione: "ITALIA" },
  { codeKey: "C724", sigla: "CO", provincia: "CIRIMIDO", nazione: "ITALIA" },
  { codeKey: "C725", sigla: "KR", provincia: "CIRO'", nazione: "ITALIA" },
  { codeKey: "C726", sigla: "KR", provincia: "CIRO' MARINA", nazione: "ITALIA" },
  { codeKey: "C727", sigla: "TN", provincia: "CIS", nazione: "ITALIA" },
  {
    codeKey: "C728",
    sigla: "BG",
    provincia: "CISANO BERGAMASCO",
    nazione: "ITALIA",
  },
  { codeKey: "C729", sigla: "SV", provincia: "CISANO SUL NEVA", nazione: "ITALIA" },
  { codeKey: "C730", sigla: "BG", provincia: "CISERANO", nazione: "ITALIA" },
  { codeKey: "C732", sigla: "VA", provincia: "CISLAGO", nazione: "ITALIA" },
  { codeKey: "C733", sigla: "MI", provincia: "CISLIANO", nazione: "ITALIA" },
  {
    codeKey: "C734",
    sigla: "VI",
    provincia: "CISMON DEL GRAPPA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C735",
    sigla: "TV",
    provincia: "CISON DI VALMARINO",
    nazione: "ITALIA",
  },
  { codeKey: "C738", sigla: "CN", provincia: "CISSONE", nazione: "ITALIA" },
  { codeKey: "C739", sigla: "AT", provincia: "CISTERNA D'ASTI", nazione: "ITALIA" },
  {
    codeKey: "C740",
    sigla: "LT",
    provincia: "CISTERNA DI LATINA",
    nazione: "ITALIA",
  },
  { codeKey: "C741", sigla: "BR", provincia: "CISTERNINO", nazione: "ITALIA" },
  { codeKey: "C742", sigla: "PG", provincia: "CITERNA", nazione: "ITALIA" },
  { codeKey: "C743", sigla: "PD", provincia: "CITTADELLA", nazione: "ITALIA" },
  {
    codeKey: "C744",
    sigla: "PG",
    provincia: "CITTA' DELLA PIEVE",
    nazione: "ITALIA",
  },
  {
    codeKey: "C745",
    sigla: "PG",
    provincia: "CITTA' DI CASTELLO",
    nazione: "ITALIA",
  },
  { codeKey: "C746", sigla: "RI", provincia: "CITTADUCALE", nazione: "ITALIA" },
  { codeKey: "C747", sigla: "RC", provincia: "CITTANOVA", nazione: "ITALIA" },
  { codeKey: "C749", sigla: "RI", provincia: "CITTAREALE", nazione: "ITALIA" },
  {
    codeKey: "C750",
    sigla: "PE",
    provincia: "CITTA' SANT'ANGELO",
    nazione: "ITALIA",
  },
  { codeKey: "C751", sigla: "VA", provincia: "CITTIGLIO", nazione: "ITALIA" },
  { codeKey: "C752", sigla: "LC", provincia: "CIVATE", nazione: "ITALIA" },
  { codeKey: "C755", sigla: "IM", provincia: "CIVEZZA", nazione: "ITALIA" },
  { codeKey: "C756", sigla: "TN", provincia: "CIVEZZANO", nazione: "ITALIA" },
  { codeKey: "C757", sigla: "VC", provincia: "CIVIASCO", nazione: "ITALIA" },
  {
    codeKey: "C758",
    sigla: "UD",
    provincia: "CIVIDALE DEL FRIULI",
    nazione: "ITALIA",
  },
  {
    codeKey: "C759",
    sigla: "BG",
    provincia: "CIVIDATE AL PIANO",
    nazione: "ITALIA",
  },
  { codeKey: "C760", sigla: "BS", provincia: "CIVIDATE CAMUNO", nazione: "ITALIA" },
  { codeKey: "C763", sigla: "CS", provincia: "CIVITA", nazione: "ITALIA" },
  {
    codeKey: "C764",
    sigla: "CB",
    provincia: "CIVITACAMPOMARANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C765",
    sigla: "VT",
    provincia: "CIVITA CASTELLANA",
    nazione: "ITALIA",
  },
  { codeKey: "C766", sigla: "AQ", provincia: "CIVITA D'ANTINO", nazione: "ITALIA" },
  { codeKey: "C767", sigla: "RM", provincia: "LANUVIO", nazione: "ITALIA" },
  { codeKey: "C768", sigla: "CH", provincia: "CIVITALUPARELLA", nazione: "ITALIA" },
  {
    codeKey: "C769",
    sigla: "IS",
    provincia: "CIVITANOVA DEL SANNIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C770",
    sigla: "MC",
    provincia: "CIVITANOVA MARCHE",
    nazione: "ITALIA",
  },
  { codeKey: "C771", sigla: "PE", provincia: "CIVITAQUANA", nazione: "ITALIA" },
  { codeKey: "C772", sigla: "CB", provincia: "DURONIA", nazione: "ITALIA" },
  { codeKey: "C773", sigla: "RM", provincia: "CIVITAVECCHIA", nazione: "ITALIA" },
  {
    codeKey: "C774",
    sigla: "AR",
    provincia: "CIVITELLA IN VAL DI CHIANA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C776",
    sigla: "CH",
    provincia: "CIVITELLA MESSER RAIMONDO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C777",
    sigla: "FC",
    provincia: "CIVITELLA DI ROMAGNA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C778",
    sigla: "AQ",
    provincia: "CIVITELLA ALFEDENA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C779",
    sigla: "PE",
    provincia: "CIVITELLA CASANOVA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C780",
    sigla: "VT",
    provincia: "CIVITELLA D'AGLIANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C781",
    sigla: "TE",
    provincia: "CIVITELLA DEL TRONTO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C782",
    sigla: "GR",
    provincia: "CIVITELLA PAGANICO",
    nazione: "ITALIA",
  },
  { codeKey: "C783", sigla: "AQ", provincia: "CIVITELLA ROVETO", nazione: "ITALIA" },
  {
    codeKey: "C784",
    sigla: "RM",
    provincia: "CIVITELLA SAN PAOLO",
    nazione: "ITALIA",
  },
  { codeKey: "C785", sigla: "SO", provincia: "CIVO", nazione: "ITALIA" },
  {
    codeKey: "C787",
    sigla: "CO",
    provincia: "CLAINO CON OSTENO",
    nazione: "ITALIA",
  },
  { codeKey: "C789", sigla: "BG", provincia: "UBIALE CLANEZZO", nazione: "ITALIA" },
  { codeKey: "C790", sigla: "PN", provincia: "CLAUT", nazione: "ITALIA" },
  { codeKey: "C791", sigla: "PN", provincia: "CLAUZETTO", nazione: "ITALIA" },
  { codeKey: "C792", sigla: "CN", provincia: "CLAVESANA", nazione: "ITALIA" },
  { codeKey: "C793", sigla: "TO", provincia: "CLAVIERE", nazione: "ITALIA" },
  { codeKey: "C794", sigla: "TN", provincia: "CLES", nazione: "ITALIA" },
  { codeKey: "C795", sigla: "CS", provincia: "CLETO", nazione: "ITALIA" },
  { codeKey: "C796", sigla: "VA", provincia: "CLIVIO", nazione: "ITALIA" },
  { codeKey: "C797", sigla: "TN", provincia: "CLOZ", nazione: "ITALIA" },
  { codeKey: "C800", sigla: "BG", provincia: "CLUSONE", nazione: "ITALIA" },
  {
    codeKey: "C801",
    sigla: "TO",
    provincia: "COASSOLO TORINESE",
    nazione: "ITALIA",
  },
  { codeKey: "C803", sigla: "TO", provincia: "COAZZE", nazione: "ITALIA" },
  { codeKey: "C804", sigla: "AT", provincia: "COAZZOLO", nazione: "ITALIA" },
  { codeKey: "C806", sigla: "BS", provincia: "COCCAGLIO", nazione: "ITALIA" },
  { codeKey: "C807", sigla: "AT", provincia: "COCCONATO", nazione: "ITALIA" },
  {
    codeKey: "C810",
    sigla: "VA",
    provincia: "COCQUIO-TREVISAGO",
    nazione: "ITALIA",
  },
  { codeKey: "C811", sigla: "AQ", provincia: "COCULLO", nazione: "ITALIA" },
  { codeKey: "C812", sigla: "PD", provincia: "CODEVIGO", nazione: "ITALIA" },
  { codeKey: "C813", sigla: "PV", provincia: "CODEVILLA", nazione: "ITALIA" },
  { codeKey: "C814", sigla: "FE", provincia: "CODIGORO", nazione: "ITALIA" },
  { codeKey: "C815", sigla: "TV", provincia: "CODOGNE'", nazione: "ITALIA" },
  { codeKey: "C816", sigla: "LO", provincia: "CODOGNO", nazione: "ITALIA" },
  { codeKey: "C817", sigla: "UD", provincia: "CODROIPO", nazione: "ITALIA" },
  { codeKey: "C818", sigla: "SS", provincia: "CODRONGIANUS", nazione: "ITALIA" },
  { codeKey: "C819", sigla: "BI", provincia: "COGGIOLA", nazione: "ITALIA" },
  { codeKey: "C820", sigla: "MB", provincia: "COGLIATE", nazione: "ITALIA" },
  { codeKey: "C821", sigla: "AO", provincia: "COGNE", nazione: "ITALIA" },
  { codeKey: "C823", sigla: "GE", provincia: "COGOLETO", nazione: "ITALIA" },
  {
    codeKey: "C824",
    sigla: "VI",
    provincia: "COGOLLO DEL CENGIO",
    nazione: "ITALIA",
  },
  { codeKey: "C826", sigla: "GE", provincia: "COGORNO", nazione: "ITALIA" },
  { codeKey: "C829", sigla: "NO", provincia: "COLAZZA", nazione: "ITALIA" },
  { codeKey: "C835", sigla: "BG", provincia: "COLERE", nazione: "ITALIA" },
  { codeKey: "C836", sigla: "FR", provincia: "COLFELICE", nazione: "ITALIA" },
  { codeKey: "C838", sigla: "PC", provincia: "COLI", nazione: "ITALIA" },
  { codeKey: "C839", sigla: "LC", provincia: "COLICO", nazione: "ITALIA" },
  { codeKey: "C840", sigla: "RE", provincia: "COLLAGNA", nazione: "ITALIA" },
  { codeKey: "C841", sigla: "RI", provincia: "COLLALTO SABINO", nazione: "ITALIA" },
  { codeKey: "C844", sigla: "AQ", provincia: "COLLARMELE", nazione: "ITALIA" },
  { codeKey: "C845", sigla: "PG", provincia: "COLLAZZONE", nazione: "ITALIA" },
  { codeKey: "C846", sigla: "BN", provincia: "COLLE SANNITA", nazione: "ITALIA" },
  {
    codeKey: "C847",
    sigla: "SI",
    provincia: "COLLE DI VAL D'ELSA",
    nazione: "ITALIA",
  },
  { codeKey: "C848", sigla: "TV", provincia: "COLLE UMBERTO", nazione: "ITALIA" },
  { codeKey: "C850", sigla: "BS", provincia: "COLLEBEATO", nazione: "ITALIA" },
  { codeKey: "C851", sigla: "LC", provincia: "COLLE BRIANZA", nazione: "ITALIA" },
  { codeKey: "C852", sigla: "PR", provincia: "COLLECCHIO", nazione: "ITALIA" },
  { codeKey: "C853", sigla: "PE", provincia: "COLLECORVINO", nazione: "ITALIA" },
  { codeKey: "C854", sigla: "CB", provincia: "COLLE D'ANCHISE", nazione: "ITALIA" },
  { codeKey: "C855", sigla: "CH", provincia: "COLLEDIMACINE", nazione: "ITALIA" },
  { codeKey: "C856", sigla: "CH", provincia: "COLLEDIMEZZO", nazione: "ITALIA" },
  { codeKey: "C857", sigla: "RI", provincia: "COLLE DI TORA", nazione: "ITALIA" },
  { codeKey: "C858", sigla: "RM", provincia: "COLLEFERRO", nazione: "ITALIA" },
  { codeKey: "C859", sigla: "RI", provincia: "COLLEGIOVE", nazione: "ITALIA" },
  { codeKey: "C860", sigla: "TO", provincia: "COLLEGNO", nazione: "ITALIA" },
  { codeKey: "C862", sigla: "AQ", provincia: "COLLELONGO", nazione: "ITALIA" },
  { codeKey: "C864", sigla: "FR", provincia: "COLLEPARDO", nazione: "ITALIA" },
  { codeKey: "C865", sigla: "LE", provincia: "COLLEPASSO", nazione: "ITALIA" },
  { codeKey: "C866", sigla: "AQ", provincia: "COLLEPIETRO", nazione: "ITALIA" },
  {
    codeKey: "C867",
    sigla: "TO",
    provincia: "COLLERETTO CASTELNUOVO",
    nazione: "ITALIA",
  },
  {
    codeKey: "C868",
    sigla: "TO",
    provincia: "COLLERETTO GIACOSA",
    nazione: "ITALIA",
  },
  { codeKey: "C869", sigla: "LI", provincia: "COLLESALVETTI", nazione: "ITALIA" },
  { codeKey: "C870", sigla: "FR", provincia: "COLLE SAN MAGNO", nazione: "ITALIA" },
  { codeKey: "C871", sigla: "PA", provincia: "COLLESANO", nazione: "ITALIA" },
  {
    codeKey: "C872",
    sigla: "BL",
    provincia: "COLLE SANTA LUCIA",
    nazione: "ITALIA",
  },
  { codeKey: "C875", sigla: "CB", provincia: "COLLETORTO", nazione: "ITALIA" },
  { codeKey: "C876", sigla: "RI", provincia: "COLLEVECCHIO", nazione: "ITALIA" },
  { codeKey: "C877", sigla: "AP", provincia: "COLLI DEL TRONTO", nazione: "ITALIA" },
  { codeKey: "C878", sigla: "IS", provincia: "COLLI A VOLTURNO", nazione: "ITALIA" },
  { codeKey: "C879", sigla: "SA", provincia: "COLLIANO", nazione: "ITALIA" },
  { codeKey: "C880", sigla: "RI", provincia: "COLLI SUL VELINO", nazione: "ITALIA" },
  { codeKey: "C882", sigla: "VS", provincia: "COLLINAS", nazione: "ITALIA" },
  { codeKey: "C883", sigla: "BS", provincia: "COLLIO", nazione: "ITALIA" },
  { codeKey: "C884", sigla: "VC", provincia: "COLLOBIANO", nazione: "ITALIA" },
  {
    codeKey: "C885",
    sigla: "UD",
    provincia: "COLLOREDO DI MONTE ALBANO",
    nazione: "ITALIA",
  },
  { codeKey: "C886", sigla: "MC", provincia: "COLMURANO", nazione: "ITALIA" },
  { codeKey: "C888", sigla: "MT", provincia: "COLOBRARO", nazione: "ITALIA" },
  { codeKey: "C890", sigla: "VR", provincia: "COLOGNA VENETA", nazione: "ITALIA" },
  { codeKey: "C893", sigla: "BS", provincia: "COLOGNE", nazione: "ITALIA" },
  { codeKey: "C894", sigla: "BG", provincia: "COLOGNO AL SERIO", nazione: "ITALIA" },
  { codeKey: "C895", sigla: "MI", provincia: "COLOGNO MONZESE", nazione: "ITALIA" },
  {
    codeKey: "C897",
    sigla: "VR",
    provincia: "COLOGNOLA AI COLLI",
    nazione: "ITALIA",
  },
  { codeKey: "C900", sigla: "RM", provincia: "COLONNA", nazione: "ITALIA" },
  { codeKey: "C901", sigla: "TE", provincia: "COLONNELLA", nazione: "ITALIA" },
  { codeKey: "C902", sigla: "CO", provincia: "COLONNO", nazione: "ITALIA" },
  { codeKey: "C903", sigla: "SO", provincia: "COLORINA", nazione: "ITALIA" },
  { codeKey: "C904", sigla: "PR", provincia: "COLORNO", nazione: "ITALIA" },
  { codeKey: "C905", sigla: "CS", provincia: "COLOSIMI", nazione: "ITALIA" },
  { codeKey: "C908", sigla: "MI", provincia: "COLTURANO", nazione: "ITALIA" },
  { codeKey: "C910", sigla: "BG", provincia: "COLZATE", nazione: "ITALIA" },
  { codeKey: "C911", sigla: "VA", provincia: "COMABBIO", nazione: "ITALIA" },
  { codeKey: "C912", sigla: "FE", provincia: "COMACCHIO", nazione: "ITALIA" },
  { codeKey: "C914", sigla: "MS", provincia: "COMANO", nazione: "ITALIA" },
  { codeKey: "C917", sigla: "LO", provincia: "COMAZZO", nazione: "ITALIA" },
  { codeKey: "C918", sigla: "UD", provincia: "COMEGLIANS", nazione: "ITALIA" },
  {
    codeKey: "C919",
    sigla: "BL",
    provincia: "SANTO STEFANO DI CADORE",
    nazione: "ITALIA",
  },
  {
    codeKey: "C920",
    sigla: "BL",
    provincia: "COMELICO SUPERIORE",
    nazione: "ITALIA",
  },
  { codeKey: "C922", sigla: "VA", provincia: "COMERIO", nazione: "ITALIA" },
  {
    codeKey: "C925",
    sigla: "BS",
    provincia: "COMEZZANO-CIZZAGO",
    nazione: "ITALIA",
  },
  { codeKey: "C926", sigla: "NO", provincia: "COMIGNAGO", nazione: "ITALIA" },
  { codeKey: "C927", sigla: "RG", provincia: "COMISO", nazione: "ITALIA" },
  { codeKey: "C928", sigla: "AG", provincia: "COMITINI", nazione: "ITALIA" },
  { codeKey: "C929", sigla: "NA", provincia: "COMIZIANO", nazione: "ITALIA" },
  { codeKey: "C930", sigla: "MN", provincia: "COMMESSAGGIO", nazione: "ITALIA" },
  { codeKey: "C931", sigla: "TN", provincia: "COMMEZZADURA", nazione: "ITALIA" },
  { codeKey: "C933", sigla: "CO", provincia: "COMO", nazione: "ITALIA" },
  { codeKey: "C934", sigla: "PR", provincia: "COMPIANO", nazione: "ITALIA" },
  { codeKey: "C935", sigla: "AP", provincia: "COMUNANZA", nazione: "ITALIA" },
  { codeKey: "C936", sigla: "CO", provincia: "VALSOLDA", nazione: "ITALIA" },
  { codeKey: "C937", sigla: "BG", provincia: "COMUN NUOVO", nazione: "ITALIA" },
  { codeKey: "C938", sigla: "VE", provincia: "CONA", nazione: "ITALIA" },
  {
    codeKey: "C939",
    sigla: "CE",
    provincia: "CONCA DELLA CAMPANIA",
    nazione: "ITALIA",
  },
  { codeKey: "C940", sigla: "SA", provincia: "CONCA DEI MARINI", nazione: "ITALIA" },
  { codeKey: "C941", sigla: "IS", provincia: "CONCA CASALE", nazione: "ITALIA" },
  { codeKey: "C943", sigla: "VR", provincia: "CONCAMARISE", nazione: "ITALIA" },
  { codeKey: "C946", sigla: "RI", provincia: "CONCERVIANO", nazione: "ITALIA" },
  { codeKey: "C948", sigla: "BS", provincia: "CONCESIO", nazione: "ITALIA" },
  { codeKey: "C949", sigla: "VI", provincia: "CONCO", nazione: "ITALIA" },
  {
    codeKey: "C950",
    sigla: "VE",
    provincia: "CONCORDIA SAGITTARIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "C951",
    sigla: "MO",
    provincia: "CONCORDIA SULLA SECCHIA",
    nazione: "ITALIA",
  },
  { codeKey: "C952", sigla: "MB", provincia: "CONCOREZZO", nazione: "ITALIA" },
  { codeKey: "C953", sigla: "TN", provincia: "CONDINO", nazione: "ITALIA" },
  { codeKey: "C954", sigla: "RC", provincia: "CONDOFURI", nazione: "ITALIA" },
  { codeKey: "C955", sigla: "TO", provincia: "CONDOVE", nazione: "ITALIA" },
  { codeKey: "C956", sigla: "ME", provincia: "CONDRO'", nazione: "ITALIA" },
  { codeKey: "C957", sigla: "TV", provincia: "CONEGLIANO", nazione: "ITALIA" },
  { codeKey: "C958", sigla: "PV", provincia: "CONFIENZA", nazione: "ITALIA" },
  { codeKey: "C959", sigla: "RI", provincia: "CONFIGNI", nazione: "ITALIA" },
  { codeKey: "C960", sigla: "CZ", provincia: "CONFLENTI", nazione: "ITALIA" },
  { codeKey: "C962", sigla: "AL", provincia: "CONIOLO", nazione: "ITALIA" },
  { codeKey: "C963", sigla: "RA", provincia: "CONSELICE", nazione: "ITALIA" },
  { codeKey: "C964", sigla: "PD", provincia: "CONSELVE", nazione: "ITALIA" },
  {
    codeKey: "C968",
    sigla: "PA",
    provincia: "CONTESSA ENTELLINA",
    nazione: "ITALIA",
  },
  { codeKey: "C969", sigla: "RI", provincia: "CONTIGLIANO", nazione: "ITALIA" },
  { codeKey: "C971", sigla: "AV", provincia: "CONTRADA", nazione: "ITALIA" },
  { codeKey: "C972", sigla: "TE", provincia: "CONTROGUERRA", nazione: "ITALIA" },
  { codeKey: "C973", sigla: "SA", provincia: "CONTRONE", nazione: "ITALIA" },
  { codeKey: "C974", sigla: "SA", provincia: "CONTURSI TERME", nazione: "ITALIA" },
  { codeKey: "C975", sigla: "BA", provincia: "CONVERSANO", nazione: "ITALIA" },
  {
    codeKey: "C976",
    sigla: "AV",
    provincia: "CONZA DELLA CAMPANIA",
    nazione: "ITALIA",
  },
  { codeKey: "C977", sigla: "AL", provincia: "CONZANO", nazione: "ITALIA" },
  { codeKey: "C978", sigla: "LE", provincia: "COPERTINO", nazione: "ITALIA" },
  { codeKey: "C979", sigla: "PV", provincia: "COPIANO", nazione: "ITALIA" },
  { codeKey: "C980", sigla: "FE", provincia: "COPPARO", nazione: "ITALIA" },
  { codeKey: "C982", sigla: "PV", provincia: "CORANA", nazione: "ITALIA" },
  { codeKey: "C983", sigla: "BA", provincia: "CORATO", nazione: "ITALIA" },
  { codeKey: "C984", sigla: "SA", provincia: "CORBARA", nazione: "ITALIA" },
  { codeKey: "C986", sigla: "MI", provincia: "CORBETTA", nazione: "ITALIA" },
  { codeKey: "C987", sigla: "RO", provincia: "CORBOLA", nazione: "ITALIA" },
  { codeKey: "C988", sigla: "VT", provincia: "CORCHIANO", nazione: "ITALIA" },
  { codeKey: "C990", sigla: "PG", provincia: "CORCIANO", nazione: "ITALIA" },
  { codeKey: "C991", sigla: "PN", provincia: "CORDENONS", nazione: "ITALIA" },
  { codeKey: "C992", sigla: "TV", provincia: "CORDIGNANO", nazione: "ITALIA" },
  { codeKey: "C993", sigla: "PN", provincia: "CORDOVADO", nazione: "ITALIA" },
  { codeKey: "C995", sigla: "GE", provincia: "COREGLIA LIGURE", nazione: "ITALIA" },
  {
    codeKey: "C996",
    sigla: "LU",
    provincia: "COREGLIA ANTELMINELLI",
    nazione: "ITALIA",
  },
  { codeKey: "C998", sigla: "FR", provincia: "CORENO AUSONIO", nazione: "ITALIA" },
  { codeKey: "C999", sigla: "AQ", provincia: "CORFINIO", nazione: "ITALIA" },
  { codeKey: "D003", sigla: "LT", provincia: "CORI", nazione: "ITALIA" },
  { codeKey: "D004", sigla: "RN", provincia: "CORIANO", nazione: "ITALIA" },
  {
    codeKey: "D005",
    sigla: "CS",
    provincia: "CORIGLIANO CALABRO",
    nazione: "ITALIA",
  },
  {
    codeKey: "D006",
    sigla: "LE",
    provincia: "CORIGLIANO D'OTRANTO",
    nazione: "ITALIA",
  },
  { codeKey: "D007", sigla: "AN", provincia: "CORINALDO", nazione: "ITALIA" },
  { codeKey: "D008", sigla: "TO", provincia: "CORIO", nazione: "ITALIA" },
  { codeKey: "D009", sigla: "PA", provincia: "CORLEONE", nazione: "ITALIA" },
  {
    codeKey: "D010",
    sigla: "PZ",
    provincia: "CORLETO PERTICARA",
    nazione: "ITALIA",
  },
  { codeKey: "D011", sigla: "SA", provincia: "CORLETO MONFORTE", nazione: "ITALIA" },
  { codeKey: "D012", sigla: "AO", provincia: "COURMAYEUR", nazione: "ITALIA" },
  { codeKey: "D013", sigla: "MI", provincia: "CORMANO", nazione: "ITALIA" },
  { codeKey: "D014", sigla: "GO", provincia: "CORMONS", nazione: "ITALIA" },
  { codeKey: "D015", sigla: "BG", provincia: "CORNA IMAGNA", nazione: "ITALIA" },
  { codeKey: "D016", sigla: "BG", provincia: "CORNALBA", nazione: "ITALIA" },
  { codeKey: "D018", sigla: "MI", provincia: "CORNAREDO", nazione: "ITALIA" },
  { codeKey: "D019", sigla: "MB", provincia: "CORNATE D'ADDA", nazione: "ITALIA" },
  {
    codeKey: "D020",
    sigla: "VI",
    provincia: "CORNEDO VICENTINO",
    nazione: "ITALIA",
  },
  {
    codeKey: "D021",
    sigla: "LO",
    provincia: "CORNEGLIANO LAUDENSE",
    nazione: "ITALIA",
  },
  {
    codeKey: "D022",
    sigla: "CN",
    provincia: "CORNELIANO D'ALBA",
    nazione: "ITALIA",
  },
  { codeKey: "D024", sigla: "VT", provincia: "TARQUINIA", nazione: "ITALIA" },
  { codeKey: "D026", sigla: "PR", provincia: "CORNIGLIO", nazione: "ITALIA" },
  { codeKey: "D027", sigla: "UD", provincia: "CORNO DI ROSAZZO", nazione: "ITALIA" },
  { codeKey: "D028", sigla: "LO", provincia: "CORNO GIOVINE", nazione: "ITALIA" },
  { codeKey: "D029", sigla: "LO", provincia: "CORNOVECCHIO", nazione: "ITALIA" },
  { codeKey: "D030", sigla: "TV", provincia: "CORNUDA", nazione: "ITALIA" },
  { codeKey: "D033", sigla: "MI", provincia: "MORIMONDO", nazione: "ITALIA" },
  { codeKey: "D037", sigla: "RE", provincia: "CORREGGIO", nazione: "ITALIA" },
  { codeKey: "D038", sigla: "MB", provincia: "CORREZZANA", nazione: "ITALIA" },
  { codeKey: "D040", sigla: "PD", provincia: "CORREZZOLA", nazione: "ITALIA" },
  { codeKey: "D041", sigla: "CO", provincia: "CORRIDO", nazione: "ITALIA" },
  { codeKey: "D042", sigla: "MC", provincia: "CORRIDONIA", nazione: "ITALIA" },
  { codeKey: "D043", sigla: "TE", provincia: "CORROPOLI", nazione: "ITALIA" },
  { codeKey: "D044", sigla: "LE", provincia: "CORSANO", nazione: "ITALIA" },
  { codeKey: "D045", sigla: "MI", provincia: "CORSICO", nazione: "ITALIA" },
  { codeKey: "D046", sigla: "AT", provincia: "CORSIONE", nazione: "ITALIA" },
  {
    codeKey: "D048",
    sigla: "BZ",
    provincia: "CORTACCIA SULLA STRADA DEL VINO",
    nazione: "ITALIA",
  },
  { codeKey: "D049", sigla: "CZ", provincia: "CORTALE", nazione: "ITALIA" },
  { codeKey: "D050", sigla: "AT", provincia: "CORTANDONE", nazione: "ITALIA" },
  { codeKey: "D051", sigla: "AT", provincia: "CORTANZE", nazione: "ITALIA" },
  { codeKey: "D052", sigla: "AT", provincia: "CORTAZZONE", nazione: "ITALIA" },
  {
    codeKey: "D054",
    sigla: "PC",
    provincia: "CORTE BRUGNATELLA",
    nazione: "ITALIA",
  },
  {
    codeKey: "D056",
    sigla: "CR",
    provincia: "CORTE DE' CORTESI CON CIGNONE",
    nazione: "ITALIA",
  },
  { codeKey: "D057", sigla: "CR", provincia: "CORTE DE' FRATI", nazione: "ITALIA" },
  { codeKey: "D058", sigla: "BS", provincia: "CORTE FRANCA", nazione: "ITALIA" },
  { codeKey: "D061", sigla: "PC", provincia: "CORTEMAGGIORE", nazione: "ITALIA" },
  { codeKey: "D062", sigla: "CN", provincia: "CORTEMILIA", nazione: "ITALIA" },
  { codeKey: "D064", sigla: "BS", provincia: "CORTENO GOLGI", nazione: "ITALIA" },
  { codeKey: "D065", sigla: "LC", provincia: "CORTENOVA", nazione: "ITALIA" },
  { codeKey: "D066", sigla: "BG", provincia: "CORTENUOVA", nazione: "ITALIA" },
  { codeKey: "D067", sigla: "PV", provincia: "CORTEOLONA", nazione: "ITALIA" },
  { codeKey: "D068", sigla: "LO", provincia: "CORTE PALASIO", nazione: "ITALIA" },
  { codeKey: "D072", sigla: "AT", provincia: "CORTIGLIONE", nazione: "ITALIA" },
  {
    codeKey: "D075",
    sigla: "BZ",
    provincia: "CORTINA SULLA STRADA DEL VINO",
    nazione: "ITALIA",
  },
  { codeKey: "D076", sigla: "TE", provincia: "CORTINO", nazione: "ITALIA" },
  { codeKey: "D077", sigla: "AR", provincia: "CORTONA", nazione: "ITALIA" },
  { codeKey: "D078", sigla: "PE", provincia: "CORVARA", nazione: "ITALIA" },
  { codeKey: "D079", sigla: "BZ", provincia: "CORVARA IN BADIA", nazione: "ITALIA" },
  {
    codeKey: "D081",
    sigla: "PV",
    provincia: "CORVINO SAN QUIRICO",
    nazione: "ITALIA",
  },
  { codeKey: "D082", sigla: "BS", provincia: "CORZANO", nazione: "ITALIA" },
  { codeKey: "D085", sigla: "UD", provincia: "COSEANO", nazione: "ITALIA" },
  { codeKey: "D086", sigla: "CS", provincia: "COSENZA", nazione: "ITALIA" },
  { codeKey: "D087", sigla: "IM", provincia: "COSIO D'ARROSCIA", nazione: "ITALIA" },
  { codeKey: "D088", sigla: "SO", provincia: "COSIO VALTELLINO", nazione: "ITALIA" },
  { codeKey: "D089", sigla: "RC", provincia: "COSOLETO", nazione: "ITALIA" },
  { codeKey: "D092", sigla: "TO", provincia: "COSSANO CANAVESE", nazione: "ITALIA" },
  { codeKey: "D093", sigla: "CN", provincia: "COSSANO BELBO", nazione: "ITALIA" },
  { codeKey: "D094", sigla: "BI", provincia: "COSSATO", nazione: "ITALIA" },
  { codeKey: "D095", sigla: "SV", provincia: "COSSERIA", nazione: "ITALIA" },
  { codeKey: "D096", sigla: "AP", provincia: "COSSIGNANO", nazione: "ITALIA" },
  { codeKey: "D099", sigla: "VB", provincia: "COSSOGNO", nazione: "ITALIA" },
  { codeKey: "D100", sigla: "SS", provincia: "COSSOINE", nazione: "ITALIA" },
  { codeKey: "D101", sigla: "AT", provincia: "COSSOMBRATO", nazione: "ITALIA" },
  { codeKey: "D102", sigla: "AL", provincia: "COSTA VESCOVATO", nazione: "ITALIA" },
  {
    codeKey: "D103",
    sigla: "BG",
    provincia: "COSTA VALLE IMAGNA",
    nazione: "ITALIA",
  },
  { codeKey: "D105", sigla: "RO", provincia: "COSTA DI ROVIGO", nazione: "ITALIA" },
  { codeKey: "D107", sigla: "VI", provincia: "COSTABISSARA", nazione: "ITALIA" },
  { codeKey: "D108", sigla: "PG", provincia: "COSTACCIARO", nazione: "ITALIA" },
  { codeKey: "D109", sigla: "PV", provincia: "COSTA DE' NOBILI", nazione: "ITALIA" },
  { codeKey: "D110", sigla: "BG", provincia: "COSTA DI MEZZATE", nazione: "ITALIA" },
  { codeKey: "D111", sigla: "BG", provincia: "COSTA SERINA", nazione: "ITALIA" },
  { codeKey: "D112", sigla: "LC", provincia: "COSTA MASNAGA", nazione: "ITALIA" },
  { codeKey: "D113", sigla: "VC", provincia: "COSTANZANA", nazione: "ITALIA" },
  { codeKey: "D114", sigla: "IM", provincia: "COSTARAINERA", nazione: "ITALIA" },
  { codeKey: "D117", sigla: "BG", provincia: "COSTA VOLPINO", nazione: "ITALIA" },
  { codeKey: "D118", sigla: "VR", provincia: "COSTERMANO", nazione: "ITALIA" },
  {
    codeKey: "D119",
    sigla: "AT",
    provincia: "COSTIGLIOLE D'ASTI",
    nazione: "ITALIA",
  },
  {
    codeKey: "D120",
    sigla: "CN",
    provincia: "COSTIGLIOLE SALUZZO",
    nazione: "ITALIA",
  },
  { codeKey: "D121", sigla: "RA", provincia: "COTIGNOLA", nazione: "ITALIA" },
  { codeKey: "D122", sigla: "KR", provincia: "CROTONE", nazione: "ITALIA" },
  { codeKey: "D123", sigla: "KR", provincia: "COTRONEI", nazione: "ITALIA" },
  { codeKey: "D124", sigla: "RI", provincia: "COTTANELLO", nazione: "ITALIA" },
  { codeKey: "D126", sigla: "BG", provincia: "COVO", nazione: "ITALIA" },
  { codeKey: "D127", sigla: "PV", provincia: "COZZO", nazione: "ITALIA" },
  { codeKey: "D128", sigla: "MT", provincia: "CRACO", nazione: "ITALIA" },
  {
    codeKey: "D131",
    sigla: "LC",
    provincia: "CRANDOLA VALSASSINA",
    nazione: "ITALIA",
  },
  { codeKey: "D132", sigla: "VC", provincia: "CRAVAGLIANA", nazione: "ITALIA" },
  { codeKey: "D133", sigla: "CN", provincia: "CRAVANZANA", nazione: "ITALIA" },
  { codeKey: "D134", sigla: "VB", provincia: "CRAVEGGIA", nazione: "ITALIA" },
  { codeKey: "D136", sigla: "VI", provincia: "CREAZZO", nazione: "ITALIA" },
  { codeKey: "D137", sigla: "CH", provincia: "CRECCHIO", nazione: "ITALIA" },
  { codeKey: "D139", sigla: "BG", provincia: "CREDARO", nazione: "ITALIA" },
  { codeKey: "D141", sigla: "CR", provincia: "CREDERA RUBBIANO", nazione: "ITALIA" },
  { codeKey: "D142", sigla: "CR", provincia: "CREMA", nazione: "ITALIA" },
  { codeKey: "D143", sigla: "LC", provincia: "CREMELLA", nazione: "ITALIA" },
  { codeKey: "D144", sigla: "VA", provincia: "CREMENAGA", nazione: "ITALIA" },
  { codeKey: "D145", sigla: "LC", provincia: "CREMENO", nazione: "ITALIA" },
  { codeKey: "D147", sigla: "CO", provincia: "CREMIA", nazione: "ITALIA" },
  { codeKey: "D149", sigla: "AL", provincia: "CREMOLINO", nazione: "ITALIA" },
  { codeKey: "D150", sigla: "CR", provincia: "CREMONA", nazione: "ITALIA" },
  { codeKey: "D151", sigla: "CR", provincia: "CREMOSANO", nazione: "ITALIA" },
  { codeKey: "D154", sigla: "VC", provincia: "CRESCENTINO", nazione: "ITALIA" },
  { codeKey: "D156", sigla: "VI", provincia: "CRESPADORO", nazione: "ITALIA" },
  {
    codeKey: "D157",
    sigla: "TV",
    provincia: "CRESPANO DEL GRAPPA",
    nazione: "ITALIA",
  },
  { codeKey: "D159", sigla: "LO", provincia: "CRESPIATICA", nazione: "ITALIA" },
  { codeKey: "D161", sigla: "RO", provincia: "CRESPINO", nazione: "ITALIA" },
  { codeKey: "D162", sigla: "NO", provincia: "CRESSA", nazione: "ITALIA" },
  { codeKey: "D165", sigla: "BI", provincia: "CREVACUORE", nazione: "ITALIA" },
  { codeKey: "D166", sigla: "BO", provincia: "CREVALCORE", nazione: "ITALIA" },
  { codeKey: "D168", sigla: "VB", provincia: "CREVOLADOSSOLA", nazione: "ITALIA" },
  { codeKey: "D170", sigla: "NA", provincia: "CRISPANO", nazione: "ITALIA" },
  { codeKey: "D171", sigla: "TA", provincia: "CRISPIANO", nazione: "ITALIA" },
  { codeKey: "D172", sigla: "CN", provincia: "CRISSOLO", nazione: "ITALIA" },
  { codeKey: "D175", sigla: "GE", provincia: "CROCEFIESCHI", nazione: "ITALIA" },
  { codeKey: "D177", sigla: "VB", provincia: "CRODO", nazione: "ITALIA" },
  { codeKey: "D179", sigla: "TE", provincia: "CROGNALETO", nazione: "ITALIA" },
  { codeKey: "D180", sigla: "CS", provincia: "CROPALATI", nazione: "ITALIA" },
  { codeKey: "D181", sigla: "CZ", provincia: "CROPANI", nazione: "ITALIA" },
  { codeKey: "D182", sigla: "BI", provincia: "CROSA", nazione: "ITALIA" },
  { codeKey: "D184", sigla: "CS", provincia: "CROSIA", nazione: "ITALIA" },
  {
    codeKey: "D185",
    sigla: "VA",
    provincia: "CROSIO DELLA VALLE",
    nazione: "ITALIA",
  },
  { codeKey: "D186", sigla: "CR", provincia: "CROTTA D'ADDA", nazione: "ITALIA" },
  { codeKey: "D187", sigla: "VC", provincia: "CROVA", nazione: "ITALIA" },
  { codeKey: "D188", sigla: "TN", provincia: "CROVIANA", nazione: "ITALIA" },
  { codeKey: "D189", sigla: "KR", provincia: "CRUCOLI", nazione: "ITALIA" },
  { codeKey: "D192", sigla: "VA", provincia: "CUASSO AL MONTE", nazione: "ITALIA" },
  { codeKey: "D193", sigla: "VR", provincia: "VERONELLA", nazione: "ITALIA" },
  {
    codeKey: "D194",
    sigla: "AL",
    provincia: "CUCCARO MONFERRATO",
    nazione: "ITALIA",
  },
  { codeKey: "D195", sigla: "SA", provincia: "CUCCARO VETERE", nazione: "ITALIA" },
  { codeKey: "D196", sigla: "CO", provincia: "CUCCIAGO", nazione: "ITALIA" },
  { codeKey: "D197", sigla: "TO", provincia: "CUCEGLIO", nazione: "ITALIA" },
  { codeKey: "D198", sigla: "MI", provincia: "CUGGIONO", nazione: "ITALIA" },
  {
    codeKey: "D199",
    sigla: "VA",
    provincia: "CUGLIATE-FABIASCO",
    nazione: "ITALIA",
  },
  { codeKey: "D200", sigla: "OR", provincia: "CUGLIERI", nazione: "ITALIA" },
  { codeKey: "D201", sigla: "PE", provincia: "CUGNOLI", nazione: "ITALIA" },
  { codeKey: "D202", sigla: "TO", provincia: "CUMIANA", nazione: "ITALIA" },
  {
    codeKey: "D203",
    sigla: "CR",
    provincia: "CUMIGNANO SUL NAVIGLIO",
    nazione: "ITALIA",
  },
  { codeKey: "D204", sigla: "VA", provincia: "CUNARDO", nazione: "ITALIA" },
  { codeKey: "D205", sigla: "CN", provincia: "CUNEO", nazione: "ITALIA" },
  { codeKey: "D206", sigla: "TN", provincia: "CUNEVO", nazione: "ITALIA" },
  { codeKey: "D207", sigla: "AT", provincia: "CUNICO", nazione: "ITALIA" },
  { codeKey: "D208", sigla: "TO", provincia: "CUORGNE'", nazione: "ITALIA" },
  { codeKey: "D209", sigla: "CH", provincia: "CUPELLO", nazione: "ITALIA" },
  { codeKey: "D210", sigla: "AP", provincia: "CUPRA MARITTIMA", nazione: "ITALIA" },
  { codeKey: "D211", sigla: "AN", provincia: "CUPRAMONTANA", nazione: "ITALIA" },
  { codeKey: "D214", sigla: "OR", provincia: "CURCURIS", nazione: "ITALIA" },
  { codeKey: "D216", sigla: "NO", provincia: "CUREGGIO", nazione: "ITALIA" },
  {
    codeKey: "D217",
    sigla: "VA",
    provincia: "CURIGLIA CON MONTEVIASCO",
    nazione: "ITALIA",
  },
  { codeKey: "D218", sigla: "CZ", provincia: "CURINGA", nazione: "ITALIA" },
  { codeKey: "D219", sigla: "BI", provincia: "CURINO", nazione: "ITALIA" },
  { codeKey: "D221", sigla: "BG", provincia: "CURNO", nazione: "ITALIA" },
  { codeKey: "D222", sigla: "BZ", provincia: "CURON VENOSTA", nazione: "ITALIA" },
  { codeKey: "D223", sigla: "LE", provincia: "CURSI", nazione: "ITALIA" },
  { codeKey: "D225", sigla: "VB", provincia: "CURSOLO-ORASSO", nazione: "ITALIA" },
  { codeKey: "D226", sigla: "PD", provincia: "CURTAROLO", nazione: "ITALIA" },
  { codeKey: "D227", sigla: "MN", provincia: "CURTATONE", nazione: "ITALIA" },
  { codeKey: "D228", sigla: "CE", provincia: "CURTI", nazione: "ITALIA" },
  { codeKey: "D229", sigla: "MI", provincia: "CUSAGO", nazione: "ITALIA" },
  { codeKey: "D230", sigla: "BN", provincia: "CUSANO MUTRI", nazione: "ITALIA" },
  { codeKey: "D231", sigla: "MI", provincia: "CUSANO MILANINO", nazione: "ITALIA" },
  { codeKey: "D232", sigla: "CO", provincia: "CUSINO", nazione: "ITALIA" },
  { codeKey: "D233", sigla: "BG", provincia: "CUSIO", nazione: "ITALIA" },
  { codeKey: "D234", sigla: "TP", provincia: "CUSTONACI", nazione: "ITALIA" },
  { codeKey: "D235", sigla: "PT", provincia: "CUTIGLIANO", nazione: "ITALIA" },
  { codeKey: "D236", sigla: "KR", provincia: "CUTRO", nazione: "ITALIA" },
  { codeKey: "D237", sigla: "LE", provincia: "CUTROFIANO", nazione: "ITALIA" },
  { codeKey: "D238", sigla: "VA", provincia: "CUVEGLIO", nazione: "ITALIA" },
  { codeKey: "D239", sigla: "VA", provincia: "CUVIO", nazione: "ITALIA" },
  { codeKey: "D243", sigla: "TN", provincia: "DAIANO", nazione: "ITALIA" },
  { codeKey: "D244", sigla: "MI", provincia: "DAIRAGO", nazione: "ITALIA" },
  { codeKey: "D245", sigla: "BG", provincia: "DALMINE", nazione: "ITALIA" },
  { codeKey: "D246", sigla: "TN", provincia: "DAMBEL", nazione: "ITALIA" },
  { codeKey: "D247", sigla: "BL", provincia: "DANTA DI CADORE", nazione: "ITALIA" },
  { codeKey: "D250", sigla: "TN", provincia: "DARE'", nazione: "ITALIA" },
  {
    codeKey: "D251",
    sigla: "BS",
    provincia: "DARFO BOARIO TERME",
    nazione: "ITALIA",
  },
  { codeKey: "D253", sigla: "VV", provincia: "DASA'", nazione: "ITALIA" },
  { codeKey: "D255", sigla: "GE", provincia: "DAVAGNA", nazione: "ITALIA" },
  { codeKey: "D256", sigla: "VA", provincia: "DAVERIO", nazione: "ITALIA" },
  { codeKey: "D257", sigla: "CZ", provincia: "DAVOLI", nazione: "ITALIA" },
  { codeKey: "D258", sigla: "SO", provincia: "DAZIO", nazione: "ITALIA" },
  { codeKey: "D259", sigla: "CA", provincia: "DECIMOMANNU", nazione: "ITALIA" },
  { codeKey: "D260", sigla: "CA", provincia: "DECIMOPUTZU", nazione: "ITALIA" },
  { codeKey: "D261", sigla: "CZ", provincia: "DECOLLATURA", nazione: "ITALIA" },
  { codeKey: "D264", sigla: "SV", provincia: "DEGO", nazione: "ITALIA" },
  { codeKey: "D265", sigla: "SP", provincia: "DEIVA MARINA", nazione: "ITALIA" },
  { codeKey: "D266", sigla: "SO", provincia: "DELEBIO", nazione: "ITALIA" },
  { codeKey: "D267", sigla: "CL", provincia: "DELIA", nazione: "ITALIA" },
  { codeKey: "D268", sigla: "RC", provincia: "DELIANUOVA", nazione: "ITALIA" },
  { codeKey: "D269", sigla: "FG", provincia: "DELICETO", nazione: "ITALIA" },
  { codeKey: "D270", sigla: "BS", provincia: "DELLO", nazione: "ITALIA" },
  { codeKey: "D271", sigla: "CN", provincia: "DEMONTE", nazione: "ITALIA" },
  { codeKey: "D272", sigla: "AL", provincia: "DENICE", nazione: "ITALIA" },
  { codeKey: "D273", sigla: "TN", provincia: "DENNO", nazione: "ITALIA" },
  { codeKey: "D277", sigla: "AL", provincia: "DERNICE", nazione: "ITALIA" },
  { codeKey: "D278", sigla: "CR", provincia: "DEROVERE", nazione: "ITALIA" },
  { codeKey: "D279", sigla: "PG", provincia: "DERUTA", nazione: "ITALIA" },
  { codeKey: "D280", sigla: "LC", provincia: "DERVIO", nazione: "ITALIA" },
  { codeKey: "D281", sigla: "VC", provincia: "DESANA", nazione: "ITALIA" },
  {
    codeKey: "D284",
    sigla: "BS",
    provincia: "DESENZANO DEL GARDA",
    nazione: "ITALIA",
  },
  { codeKey: "D286", sigla: "MB", provincia: "DESIO", nazione: "ITALIA" },
  { codeKey: "D287", sigla: "NU", provincia: "DESULO", nazione: "ITALIA" },
  { codeKey: "D289", sigla: "CS", provincia: "DIAMANTE", nazione: "ITALIA" },
  { codeKey: "D290", sigla: "CS", provincia: "SCIGLIANO", nazione: "ITALIA" },
  { codeKey: "D291", sigla: "CN", provincia: "DIANO D'ALBA", nazione: "ITALIA" },
  { codeKey: "D292", sigla: "SA", provincia: "TEGGIANO", nazione: "ITALIA" },
  { codeKey: "D293", sigla: "IM", provincia: "DIANO ARENTINO", nazione: "ITALIA" },
  { codeKey: "D296", sigla: "IM", provincia: "DIANO CASTELLO", nazione: "ITALIA" },
  { codeKey: "D297", sigla: "IM", provincia: "DIANO MARINA", nazione: "ITALIA" },
  { codeKey: "D298", sigla: "IM", provincia: "DIANO SAN PIETRO", nazione: "ITALIA" },
  { codeKey: "D299", sigla: "FI", provincia: "DICOMANO", nazione: "ITALIA" },
  { codeKey: "D300", sigla: "UD", provincia: "DIGNANO", nazione: "ITALIA" },
  { codeKey: "D302", sigla: "TN", provincia: "DIMARO", nazione: "ITALIA" },
  { codeKey: "D303", sigla: "VV", provincia: "DINAMI", nazione: "ITALIA" },
  { codeKey: "D304", sigla: "CS", provincia: "DIPIGNANO", nazione: "ITALIA" },
  { codeKey: "D305", sigla: "LE", provincia: "DISO", nazione: "ITALIA" },
  { codeKey: "D309", sigla: "NO", provincia: "DIVIGNANO", nazione: "ITALIA" },
  { codeKey: "D310", sigla: "CO", provincia: "DIZZASCO", nazione: "ITALIA" },
  { codeKey: "D311", sigla: "BZ", provincia: "DOBBIACO", nazione: "ITALIA" },
  {
    codeKey: "D312",
    sigla: "GO",
    provincia: "DOBERDO' DEL LAGO",
    nazione: "ITALIA",
  },
  { codeKey: "D314", sigla: "CN", provincia: "DOGLIANI", nazione: "ITALIA" },
  { codeKey: "D315", sigla: "CH", provincia: "DOGLIOLA", nazione: "ITALIA" },
  { codeKey: "D316", sigla: "UD", provincia: "DOGNA", nazione: "ITALIA" },
  { codeKey: "D317", sigla: "VR", provincia: "DOLCE'", nazione: "ITALIA" },
  { codeKey: "D318", sigla: "IM", provincia: "DOLCEACQUA", nazione: "ITALIA" },
  { codeKey: "D319", sigla: "IM", provincia: "DOLCEDO", nazione: "ITALIA" },
  {
    codeKey: "D321",
    sigla: "GO",
    provincia: "DOLEGNA DEL COLLIO",
    nazione: "ITALIA",
  },
  { codeKey: "D323", sigla: "CA", provincia: "DOLIANOVA", nazione: "ITALIA" },
  {
    codeKey: "D324",
    sigla: "TS",
    provincia: "SAN DORLIGO DELLA VALLE",
    nazione: "ITALIA",
  },
  { codeKey: "D325", sigla: "VE", provincia: "DOLO", nazione: "ITALIA" },
  { codeKey: "D327", sigla: "LC", provincia: "DOLZAGO", nazione: "ITALIA" },
  { codeKey: "D328", sigla: "CS", provincia: "DOMANICO", nazione: "ITALIA" },
  { codeKey: "D329", sigla: "CO", provincia: "DOMASO", nazione: "ITALIA" },
  {
    codeKey: "D330",
    sigla: "BL",
    provincia: "DOMEGGE DI CADORE",
    nazione: "ITALIA",
  },
  { codeKey: "D331", sigla: "AV", provincia: "DOMICELLA", nazione: "ITALIA" },
  { codeKey: "D332", sigla: "VB", provincia: "DOMODOSSOLA", nazione: "ITALIA" },
  { codeKey: "D333", sigla: "CA", provincia: "DOMUS DE MARIA", nazione: "ITALIA" },
  { codeKey: "D334", sigla: "CI", provincia: "DOMUSNOVAS", nazione: "ITALIA" },
  { codeKey: "D336", sigla: "TN", provincia: "DON", nazione: "ITALIA" },
  { codeKey: "D338", sigla: "AO", provincia: "DONNAS", nazione: "ITALIA" },
  { codeKey: "D339", sigla: "BI", provincia: "DONATO", nazione: "ITALIA" },
  { codeKey: "D341", sigla: "CO", provincia: "DONGO", nazione: "ITALIA" },
  { codeKey: "D344", sigla: "CA", provincia: "DONORI", nazione: "ITALIA" },
  { codeKey: "D345", sigla: "NU", provincia: "DORGALI", nazione: "ITALIA" },
  { codeKey: "D346", sigla: "LC", provincia: "DORIO", nazione: "ITALIA" },
  { codeKey: "D347", sigla: "NO", provincia: "DORMELLETTO", nazione: "ITALIA" },
  { codeKey: "D348", sigla: "PV", provincia: "DORNO", nazione: "ITALIA" },
  { codeKey: "D350", sigla: "BI", provincia: "DORZANO", nazione: "ITALIA" },
  { codeKey: "D351", sigla: "MN", provincia: "DOSOLO", nazione: "ITALIA" },
  { codeKey: "D352", sigla: "BG", provincia: "DOSSENA", nazione: "ITALIA" },
  { codeKey: "D355", sigla: "CO", provincia: "DOSSO DEL LIRO", nazione: "ITALIA" },
  { codeKey: "D356", sigla: "AO", provincia: "DOUES", nazione: "ITALIA" },
  { codeKey: "D357", sigla: "FC", provincia: "DOVADOLA", nazione: "ITALIA" },
  { codeKey: "D358", sigla: "CR", provincia: "DOVERA", nazione: "ITALIA" },
  { codeKey: "D360", sigla: "BO", provincia: "DOZZA", nazione: "ITALIA" },
  { codeKey: "D361", sigla: "CE", provincia: "DRAGONI", nazione: "ITALIA" },
  { codeKey: "D364", sigla: "VV", provincia: "DRAPIA", nazione: "ITALIA" },
  { codeKey: "D365", sigla: "TN", provincia: "DRENA", nazione: "ITALIA" },
  { codeKey: "D366", sigla: "UD", provincia: "DRENCHIA", nazione: "ITALIA" },
  { codeKey: "D367", sigla: "MI", provincia: "DRESANO", nazione: "ITALIA" },
  { codeKey: "D370", sigla: "CR", provincia: "DRIZZONA", nazione: "ITALIA" },
  { codeKey: "D371", sigla: "TN", provincia: "DRO", nazione: "ITALIA" },
  { codeKey: "D372", sigla: "CN", provincia: "DRONERO", nazione: "ITALIA" },
  { codeKey: "D373", sigla: "TO", provincia: "DRUENTO", nazione: "ITALIA" },
  { codeKey: "D374", sigla: "VB", provincia: "DRUOGNO", nazione: "ITALIA" },
  { codeKey: "D376", sigla: "NU", provincia: "DUALCHI", nazione: "ITALIA" },
  { codeKey: "D377", sigla: "SO", provincia: "DUBINO", nazione: "ITALIA" },
  { codeKey: "D379", sigla: "VI", provincia: "DUEVILLE", nazione: "ITALIA" },
  { codeKey: "D380", sigla: "BN", provincia: "DUGENTA", nazione: "ITALIA" },
  { codeKey: "D383", sigla: "TS", provincia: "DUINO-AURISINA", nazione: "ITALIA" },
  { codeKey: "D384", sigla: "VA", provincia: "DUMENZA", nazione: "ITALIA" },
  { codeKey: "D385", sigla: "VA", provincia: "DUNO", nazione: "ITALIA" },
  { codeKey: "D386", sigla: "BN", provincia: "DURAZZANO", nazione: "ITALIA" },
  {
    codeKey: "D388",
    sigla: "AT",
    provincia: "DUSINO SAN MICHELE",
    nazione: "ITALIA",
  },
  { codeKey: "D390", sigla: "SA", provincia: "EBOLI", nazione: "ITALIA" },
  { codeKey: "D391", sigla: "BS", provincia: "EDOLO", nazione: "ITALIA" },
  { codeKey: "D392", sigla: "BZ", provincia: "EGNA", nazione: "ITALIA" },
  { codeKey: "D394", sigla: "PE", provincia: "ELICE", nazione: "ITALIA" },
  { codeKey: "D395", sigla: "OG", provincia: "ELINI", nazione: "ITALIA" },
  { codeKey: "D398", sigla: "LC", provincia: "ELLO", nazione: "ITALIA" },
  { codeKey: "D399", sigla: "CA", provincia: "ELMAS", nazione: "ITALIA" },
  { codeKey: "D401", sigla: "CN", provincia: "ELVA", nazione: "ITALIA" },
  { codeKey: "D402", sigla: "AO", provincia: "EMARESE", nazione: "ITALIA" },
  { codeKey: "D403", sigla: "FI", provincia: "EMPOLI", nazione: "ITALIA" },
  { codeKey: "D406", sigla: "BG", provincia: "ENDINE GAIANO", nazione: "ITALIA" },
  { codeKey: "D407", sigla: "VI", provincia: "ENEGO", nazione: "ITALIA" },
  { codeKey: "D408", sigla: "UD", provincia: "ENEMONZO", nazione: "ITALIA" },
  { codeKey: "D410", sigla: "CN", provincia: "ENTRACQUE", nazione: "ITALIA" },
  { codeKey: "D411", sigla: "BG", provincia: "ENTRATICO", nazione: "ITALIA" },
  { codeKey: "D412", sigla: "CN", provincia: "ENVIE", nazione: "ITALIA" },
  { codeKey: "D414", sigla: "PZ", provincia: "EPISCOPIA", nazione: "ITALIA" },
  { codeKey: "D415", sigla: "VE", provincia: "ERACLEA", nazione: "ITALIA" },
  { codeKey: "D416", sigla: "CO", provincia: "ERBA", nazione: "ITALIA" },
  { codeKey: "D419", sigla: "VR", provincia: "ERBE'", nazione: "ITALIA" },
  { codeKey: "D420", sigla: "VR", provincia: "ERBEZZO", nazione: "ITALIA" },
  { codeKey: "D421", sigla: "BS", provincia: "ERBUSCO", nazione: "ITALIA" },
  { codeKey: "D422", sigla: "BR", provincia: "ERCHIE", nazione: "ITALIA" },
  { codeKey: "D423", sigla: "TP", provincia: "ERICE", nazione: "ITALIA" },
  { codeKey: "D424", sigla: "SV", provincia: "ERLI", nazione: "ITALIA" },
  { codeKey: "D426", sigla: "PN", provincia: "ERTO E CASSO", nazione: "ITALIA" },
  { codeKey: "D428", sigla: "LC", provincia: "ERVE", nazione: "ITALIA" },
  { codeKey: "D429", sigla: "MC", provincia: "ESANATOGLIA", nazione: "ITALIA" },
  { codeKey: "D430", sigla: "NU", provincia: "ESCALAPLANO", nazione: "ITALIA" },
  { codeKey: "D431", sigla: "NU", provincia: "ESCOLCA", nazione: "ITALIA" },
  { codeKey: "D433", sigla: "TO", provincia: "EXILLES", nazione: "ITALIA" },
  { codeKey: "D434", sigla: "BS", provincia: "ESINE", nazione: "ITALIA" },
  { codeKey: "D436", sigla: "LC", provincia: "ESINO LARIO", nazione: "ITALIA" },
  { codeKey: "D440", sigla: "FR", provincia: "ESPERIA", nazione: "ITALIA" },
  { codeKey: "D441", sigla: "SS", provincia: "ESPORLATU", nazione: "ITALIA" },
  { codeKey: "D442", sigla: "PD", provincia: "ESTE", nazione: "ITALIA" },
  { codeKey: "D443", sigla: "NU", provincia: "ESTERZILI", nazione: "ITALIA" },
  { codeKey: "D444", sigla: "AO", provincia: "ETROUBLES", nazione: "ITALIA" },
  { codeKey: "D445", sigla: "CO", provincia: "EUPILIO", nazione: "ITALIA" },
  { codeKey: "D447", sigla: "AL", provincia: "FABBRICA CURONE", nazione: "ITALIA" },
  { codeKey: "D450", sigla: "RE", provincia: "FABBRICO", nazione: "ITALIA" },
  { codeKey: "D451", sigla: "AN", provincia: "FABRIANO", nazione: "ITALIA" },
  { codeKey: "D452", sigla: "VT", provincia: "FABRICA DI ROMA", nazione: "ITALIA" },
  { codeKey: "D453", sigla: "VV", provincia: "FABRIZIA", nazione: "ITALIA" },
  { codeKey: "D454", sigla: "TR", provincia: "FABRO", nazione: "ITALIA" },
  { codeKey: "D455", sigla: "UD", provincia: "FAEDIS", nazione: "ITALIA" },
  { codeKey: "D456", sigla: "SO", provincia: "FAEDO VALTELLINO", nazione: "ITALIA" },
  { codeKey: "D457", sigla: "TN", provincia: "FAEDO", nazione: "ITALIA" },
  { codeKey: "D458", sigla: "RA", provincia: "FAENZA", nazione: "ITALIA" },
  { codeKey: "D459", sigla: "FG", provincia: "FAETO", nazione: "ITALIA" },
  { codeKey: "D461", sigla: "UD", provincia: "FAGAGNA", nazione: "ITALIA" },
  { codeKey: "D462", sigla: "CO", provincia: "FAGGETO LARIO", nazione: "ITALIA" },
  { codeKey: "D463", sigla: "TA", provincia: "FAGGIANO", nazione: "ITALIA" },
  { codeKey: "D464", sigla: "CS", provincia: "FAGNANO CASTELLO", nazione: "ITALIA" },
  { codeKey: "D465", sigla: "AQ", provincia: "FAGNANO ALTO", nazione: "ITALIA" },
  { codeKey: "D467", sigla: "VA", provincia: "FAGNANO OLONA", nazione: "ITALIA" },
  {
    codeKey: "D468",
    sigla: "TN",
    provincia: "FAI DELLA PAGANELLA",
    nazione: "ITALIA",
  },
  { codeKey: "D469", sigla: "BN", provincia: "FAICCHIO", nazione: "ITALIA" },
  { codeKey: "D470", sigla: "BL", provincia: "FALCADE", nazione: "ITALIA" },
  {
    codeKey: "D471",
    sigla: "CE",
    provincia: "FALCIANO DEL MASSICO",
    nazione: "ITALIA",
  },
  {
    codeKey: "D472",
    sigla: "AN",
    provincia: "FALCONARA MARITTIMA",
    nazione: "ITALIA",
  },
  {
    codeKey: "D473",
    sigla: "CS",
    provincia: "FALCONARA ALBANESE",
    nazione: "ITALIA",
  },
  { codeKey: "D474", sigla: "ME", provincia: "FALCONE", nazione: "ITALIA" },
  { codeKey: "D475", sigla: "VT", provincia: "FALERIA", nazione: "ITALIA" },
  { codeKey: "D476", sigla: "CZ", provincia: "FALERNA", nazione: "ITALIA" },
  { codeKey: "D477", sigla: "FM", provincia: "FALERONE", nazione: "ITALIA" },
  { codeKey: "D480", sigla: "CH", provincia: "FALLO", nazione: "ITALIA" },
  { codeKey: "D481", sigla: "VB", provincia: "FALMENTA", nazione: "ITALIA" },
  { codeKey: "D482", sigla: "CO", provincia: "FALOPPIO", nazione: "ITALIA" },
  { codeKey: "D483", sigla: "FR", provincia: "FALVATERRA", nazione: "ITALIA" },
  { codeKey: "D484", sigla: "BZ", provincia: "FALZES", nazione: "ITALIA" },
  { codeKey: "D486", sigla: "MO", provincia: "FANANO", nazione: "ITALIA" },
  { codeKey: "D487", sigla: "PN", provincia: "FANNA", nazione: "ITALIA" },
  { codeKey: "D488", sigla: "PU", provincia: "FANO", nazione: "ITALIA" },
  { codeKey: "D489", sigla: "TE", provincia: "FANO ADRIANO", nazione: "ITALIA" },
  { codeKey: "D490", sigla: "BG", provincia: "FARA GERA D'ADDA", nazione: "ITALIA" },
  {
    codeKey: "D491",
    sigla: "BG",
    provincia: "FARA OLIVANA CON SOLA",
    nazione: "ITALIA",
  },
  { codeKey: "D492", sigla: "NO", provincia: "FARA NOVARESE", nazione: "ITALIA" },
  { codeKey: "D493", sigla: "RI", provincia: "FARA IN SABINA", nazione: "ITALIA" },
  {
    codeKey: "D494",
    sigla: "CH",
    provincia: "FARA FILIORUM PETRI",
    nazione: "ITALIA",
  },
  { codeKey: "D495", sigla: "CH", provincia: "FARA SAN MARTINO", nazione: "ITALIA" },
  { codeKey: "D496", sigla: "VI", provincia: "FARA VICENTINO", nazione: "ITALIA" },
  { codeKey: "D497", sigla: "PZ", provincia: "FARDELLA", nazione: "ITALIA" },
  { codeKey: "D499", sigla: "CN", provincia: "FARIGLIANO", nazione: "ITALIA" },
  { codeKey: "D501", sigla: "PE", provincia: "FARINDOLA", nazione: "ITALIA" },
  { codeKey: "D502", sigla: "PC", provincia: "FARINI", nazione: "ITALIA" },
  { codeKey: "D503", sigla: "VT", provincia: "FARNESE", nazione: "ITALIA" },
  { codeKey: "D504", sigla: "GO", provincia: "FARRA D'ISONZO", nazione: "ITALIA" },
  { codeKey: "D505", sigla: "TV", provincia: "FARRA DI SOLIGO", nazione: "ITALIA" },
  { codeKey: "D506", sigla: "BL", provincia: "FARRA D'ALPAGO", nazione: "ITALIA" },
  { codeKey: "D508", sigla: "BR", provincia: "FASANO", nazione: "ITALIA" },
  { codeKey: "D509", sigla: "GE", provincia: "FASCIA", nazione: "ITALIA" },
  { codeKey: "D510", sigla: "PI", provincia: "FAUGLIA", nazione: "ITALIA" },
  { codeKey: "D511", sigla: "CN", provincia: "FAULE", nazione: "ITALIA" },
  {
    codeKey: "D512",
    sigla: "GE",
    provincia: "FAVALE DI MALVARO",
    nazione: "ITALIA",
  },
  { codeKey: "D513", sigla: "MT", provincia: "VALSINNI", nazione: "ITALIA" },
  { codeKey: "D514", sigla: "AG", provincia: "FAVARA", nazione: "ITALIA" },
  { codeKey: "D516", sigla: "TN", provincia: "FAVER", nazione: "ITALIA" },
  { codeKey: "D518", sigla: "TP", provincia: "FAVIGNANA", nazione: "ITALIA" },
  { codeKey: "D520", sigla: "TO", provincia: "FAVRIA", nazione: "ITALIA" },
  { codeKey: "D522", sigla: "SV", provincia: "ORCO FEGLINO", nazione: "ITALIA" },
  { codeKey: "D523", sigla: "CN", provincia: "FEISOGLIO", nazione: "ITALIA" },
  { codeKey: "D524", sigla: "TO", provincia: "FELETTO", nazione: "ITALIA" },
  { codeKey: "D526", sigla: "PR", provincia: "FELINO", nazione: "ITALIA" },
  { codeKey: "D527", sigla: "SA", provincia: "FELITTO", nazione: "ITALIA" },
  { codeKey: "D528", sigla: "AL", provincia: "FELIZZANO", nazione: "ITALIA" },
  { codeKey: "D529", sigla: "MN", provincia: "FELONICA", nazione: "ITALIA" },
  { codeKey: "D530", sigla: "BL", provincia: "FELTRE", nazione: "ITALIA" },
  { codeKey: "D531", sigla: "CO", provincia: "FENEGRO'", nazione: "ITALIA" },
  { codeKey: "D532", sigla: "TO", provincia: "FENESTRELLE", nazione: "ITALIA" },
  { codeKey: "D537", sigla: "AO", provincia: "FENIS", nazione: "ITALIA" },
  { codeKey: "D538", sigla: "TR", provincia: "FERENTILLO", nazione: "ITALIA" },
  { codeKey: "D539", sigla: "FR", provincia: "FERENTINO", nazione: "ITALIA" },
  { codeKey: "D540", sigla: "SR", provincia: "FERLA", nazione: "ITALIA" },
  { codeKey: "D541", sigla: "PU", provincia: "FERMIGNANO", nazione: "ITALIA" },
  { codeKey: "D542", sigla: "FM", provincia: "FERMO", nazione: "ITALIA" },
  { codeKey: "D543", sigla: "VA", provincia: "FERNO", nazione: "ITALIA" },
  { codeKey: "D544", sigla: "CZ", provincia: "FEROLETO ANTICO", nazione: "ITALIA" },
  {
    codeKey: "D545",
    sigla: "RC",
    provincia: "FEROLETO DELLA CHIESA",
    nazione: "ITALIA",
  },
  { codeKey: "D546", sigla: "CZ", provincia: "PIANOPOLI", nazione: "ITALIA" },
  { codeKey: "D547", sigla: "MT", provincia: "FERRANDINA", nazione: "ITALIA" },
  { codeKey: "D548", sigla: "FE", provincia: "FERRARA", nazione: "ITALIA" },
  {
    codeKey: "D549",
    sigla: "VR",
    provincia: "FERRARA DI MONTE BALDO",
    nazione: "ITALIA",
  },
  { codeKey: "D550", sigla: "CB", provincia: "FERRAZZANO", nazione: "ITALIA" },
  {
    codeKey: "D551",
    sigla: "VA",
    provincia: "FERRERA DI VARESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "D552",
    sigla: "PV",
    provincia: "FERRERA ERBOGNONE",
    nazione: "ITALIA",
  },
  { codeKey: "D553", sigla: "TO", provincia: "MONCENISIO", nazione: "ITALIA" },
  { codeKey: "D554", sigla: "AT", provincia: "FERRERE", nazione: "ITALIA" },
  { codeKey: "D555", sigla: "PC", provincia: "FERRIERE", nazione: "ITALIA" },
  { codeKey: "D557", sigla: "RC", provincia: "FERRUZZANO", nazione: "ITALIA" },
  { codeKey: "D559", sigla: "AL", provincia: "FRACONALTO", nazione: "ITALIA" },
  { codeKey: "D560", sigla: "RI", provincia: "FIAMIGNANO", nazione: "ITALIA" },
  { codeKey: "D561", sigla: "RM", provincia: "FIANO ROMANO", nazione: "ITALIA" },
  { codeKey: "D562", sigla: "TO", provincia: "FIANO", nazione: "ITALIA" },
  { codeKey: "D564", sigla: "MC", provincia: "FIASTRA", nazione: "ITALIA" },
  { codeKey: "D565", sigla: "TN", provincia: "FIAVE'", nazione: "ITALIA" },
  {
    codeKey: "D566",
    sigla: "MC",
    provincia: "POGGIO SAN VICINO",
    nazione: "ITALIA",
  },
  { codeKey: "D567", sigla: "PA", provincia: "FICARAZZI", nazione: "ITALIA" },
  { codeKey: "D568", sigla: "RO", provincia: "FICAROLO", nazione: "ITALIA" },
  { codeKey: "D569", sigla: "ME", provincia: "FICARRA", nazione: "ITALIA" },
  { codeKey: "D570", sigla: "TR", provincia: "FICULLE", nazione: "ITALIA" },
  {
    codeKey: "D571",
    sigla: "BZ",
    provincia: "FIE' ALLO SCILIAR",
    nazione: "ITALIA",
  },
  {
    codeKey: "D572",
    sigla: "TN",
    provincia: "FIERA DI PRIMIERO",
    nazione: "ITALIA",
  },
  { codeKey: "D573", sigla: "TN", provincia: "FIEROZZO", nazione: "ITALIA" },
  { codeKey: "D574", sigla: "CR", provincia: "FIESCO", nazione: "ITALIA" },
  { codeKey: "D575", sigla: "FI", provincia: "FIESOLE", nazione: "ITALIA" },
  { codeKey: "D576", sigla: "BS", provincia: "FIESSE", nazione: "ITALIA" },
  {
    codeKey: "D577",
    sigla: "RO",
    provincia: "FIESSO UMBERTIANO",
    nazione: "ITALIA",
  },
  { codeKey: "D578", sigla: "VE", provincia: "FIESSO D'ARTICO", nazione: "ITALIA" },
  { codeKey: "D579", sigla: "CO", provincia: "FIGINO SERENZA", nazione: "ITALIA" },
  {
    codeKey: "D582",
    sigla: "CS",
    provincia: "FIGLINE VEGLIATURO",
    nazione: "ITALIA",
  },
  { codeKey: "D585", sigla: "OR", provincia: "GONNOSNO'", nazione: "ITALIA" },
  { codeKey: "D586", sigla: "RM", provincia: "FILACCIANO", nazione: "ITALIA" },
  { codeKey: "D587", sigla: "VV", provincia: "FILADELFIA", nazione: "ITALIA" },
  { codeKey: "D588", sigla: "BG", provincia: "FILAGO", nazione: "ITALIA" },
  { codeKey: "D589", sigla: "VV", provincia: "FILANDARI", nazione: "ITALIA" },
  { codeKey: "D590", sigla: "MS", provincia: "FILATTIERA", nazione: "ITALIA" },
  { codeKey: "D591", sigla: "FR", provincia: "FILETTINO", nazione: "ITALIA" },
  { codeKey: "D592", sigla: "CH", provincia: "FILETTO", nazione: "ITALIA" },
  { codeKey: "D593", sigla: "PZ", provincia: "FILIANO", nazione: "ITALIA" },
  { codeKey: "D594", sigla: "PV", provincia: "FILIGHERA", nazione: "ITALIA" },
  { codeKey: "D595", sigla: "IS", provincia: "FILIGNANO", nazione: "ITALIA" },
  { codeKey: "D596", sigla: "VV", provincia: "FILOGASO", nazione: "ITALIA" },
  { codeKey: "D597", sigla: "AN", provincia: "FILOTTRANO", nazione: "ITALIA" },
  { codeKey: "D599", sigla: "MO", provincia: "FINALE EMILIA", nazione: "ITALIA" },
  { codeKey: "D600", sigla: "SV", provincia: "FINALE LIGURE", nazione: "ITALIA" },
  { codeKey: "D604", sigla: "BG", provincia: "FINO DEL MONTE", nazione: "ITALIA" },
  { codeKey: "D605", sigla: "CO", provincia: "FINO MORNASCO", nazione: "ITALIA" },
  { codeKey: "D606", sigla: "BG", provincia: "FIORANO AL SERIO", nazione: "ITALIA" },
  { codeKey: "D607", sigla: "MO", provincia: "FIORANO MODENESE", nazione: "ITALIA" },
  { codeKey: "D608", sigla: "TO", provincia: "FIORANO CANAVESE", nazione: "ITALIA" },
  { codeKey: "D609", sigla: "MC", provincia: "FIORDIMONTE", nazione: "ITALIA" },
  {
    codeKey: "D611",
    sigla: "PC",
    provincia: "FIORENZUOLA D'ARDA",
    nazione: "ITALIA",
  },
  { codeKey: "D612", sigla: "FI", provincia: "FIRENZE", nazione: "ITALIA" },
  { codeKey: "D613", sigla: "FI", provincia: "FIRENZUOLA", nazione: "ITALIA" },
  { codeKey: "D614", sigla: "CS", provincia: "FIRMO", nazione: "ITALIA" },
  { codeKey: "D615", sigla: "SA", provincia: "FISCIANO", nazione: "ITALIA" },
  { codeKey: "D617", sigla: "MO", provincia: "FIUMALBO", nazione: "ITALIA" },
  { codeKey: "D619", sigla: "RC", provincia: "FIUMARA", nazione: "ITALIA" },
  { codeKey: "D621", sigla: "PN", provincia: "FIUME VENETO", nazione: "ITALIA" },
  { codeKey: "D622", sigla: "ME", provincia: "FIUMEDINISI", nazione: "ITALIA" },
  {
    codeKey: "D623",
    sigla: "CT",
    provincia: "FIUMEFREDDO DI SICILIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "D624",
    sigla: "CS",
    provincia: "FIUMEFREDDO BRUZIO",
    nazione: "ITALIA",
  },
  { codeKey: "D627", sigla: "UD", provincia: "FIUMICELLO", nazione: "ITALIA" },
  { codeKey: "D628", sigla: "MC", provincia: "FIUMINATA", nazione: "ITALIA" },
  { codeKey: "D629", sigla: "MS", provincia: "FIVIZZANO", nazione: "ITALIA" },
  { codeKey: "D630", sigla: "UD", provincia: "FLAIBANO", nazione: "ITALIA" },
  { codeKey: "D631", sigla: "TN", provincia: "FLAVON", nazione: "ITALIA" },
  { codeKey: "D634", sigla: "BS", provincia: "FLERO", nazione: "ITALIA" },
  { codeKey: "D635", sigla: "ME", provincia: "FLORESTA", nazione: "ITALIA" },
  { codeKey: "D636", sigla: "SR", provincia: "FLORIDIA", nazione: "ITALIA" },
  { codeKey: "D637", sigla: "SS", provincia: "FLORINAS", nazione: "ITALIA" },
  { codeKey: "D638", sigla: "AV", provincia: "FLUMERI", nazione: "ITALIA" },
  { codeKey: "D639", sigla: "CI", provincia: "FLUMINIMAGGIORE", nazione: "ITALIA" },
  { codeKey: "D640", sigla: "NU", provincia: "FLUSSIO", nazione: "ITALIA" },
  { codeKey: "D641", sigla: "VC", provincia: "FOBELLO", nazione: "ITALIA" },
  { codeKey: "D643", sigla: "FG", provincia: "FOGGIA", nazione: "ITALIA" },
  { codeKey: "D644", sigla: "BN", provincia: "FOGLIANISE", nazione: "ITALIA" },
  {
    codeKey: "D645",
    sigla: "GO",
    provincia: "FOGLIANO REDIPUGLIA",
    nazione: "ITALIA",
  },
  { codeKey: "D646", sigla: "TO", provincia: "FOGLIZZO", nazione: "ITALIA" },
  {
    codeKey: "D649",
    sigla: "AR",
    provincia: "FOIANO DELLA CHIANA",
    nazione: "ITALIA",
  },
  {
    codeKey: "D650",
    sigla: "BN",
    provincia: "FOIANO DI VAL FORTORE",
    nazione: "ITALIA",
  },
  { codeKey: "D651", sigla: "TN", provincia: "FOLGARIA", nazione: "ITALIA" },
  { codeKey: "D652", sigla: "AP", provincia: "FOLIGNANO", nazione: "ITALIA" },
  { codeKey: "D653", sigla: "PG", provincia: "FOLIGNO", nazione: "ITALIA" },
  { codeKey: "D654", sigla: "TV", provincia: "FOLLINA", nazione: "ITALIA" },
  { codeKey: "D655", sigla: "SP", provincia: "FOLLO", nazione: "ITALIA" },
  { codeKey: "D656", sigla: "GR", provincia: "FOLLONICA", nazione: "ITALIA" },
  { codeKey: "D660", sigla: "LO", provincia: "FOMBIO", nazione: "ITALIA" },
  {
    codeKey: "D661",
    sigla: "ME",
    provincia: "FONDACHELLI-FANTINA",
    nazione: "ITALIA",
  },
  { codeKey: "D662", sigla: "LT", provincia: "FONDI", nazione: "ITALIA" },
  { codeKey: "D663", sigla: "TN", provincia: "FONDO", nazione: "ITALIA" },
  { codeKey: "D665", sigla: "NU", provincia: "FONNI", nazione: "ITALIA" },
  { codeKey: "D666", sigla: "AO", provincia: "FONTAINEMORE", nazione: "ITALIA" },
  { codeKey: "D667", sigla: "FR", provincia: "FONTANA LIRI", nazione: "ITALIA" },
  { codeKey: "D668", sigla: "BO", provincia: "FONTANELICE", nazione: "ITALIA" },
  { codeKey: "D670", sigla: "PN", provincia: "FONTANAFREDDA", nazione: "ITALIA" },
  { codeKey: "D671", sigla: "AV", provincia: "FONTANAROSA", nazione: "ITALIA" },
  { codeKey: "D672", sigla: "BG", provincia: "FONTANELLA", nazione: "ITALIA" },
  { codeKey: "D673", sigla: "PR", provincia: "FONTANELLATO", nazione: "ITALIA" },
  { codeKey: "D674", sigla: "TV", provincia: "FONTANELLE", nazione: "ITALIA" },
  {
    codeKey: "D675",
    sigla: "NO",
    provincia: "FONTANETO D'AGOGNA",
    nazione: "ITALIA",
  },
  { codeKey: "D676", sigla: "VC", provincia: "FONTANETTO PO", nazione: "ITALIA" },
  { codeKey: "D677", sigla: "GE", provincia: "FONTANIGORDA", nazione: "ITALIA" },
  { codeKey: "D678", sigla: "AT", provincia: "FONTANILE", nazione: "ITALIA" },
  { codeKey: "D679", sigla: "PD", provincia: "FONTANIVA", nazione: "ITALIA" },
  { codeKey: "D680", sigla: "TV", provincia: "FONTE", nazione: "ITALIA" },
  { codeKey: "D681", sigla: "AQ", provincia: "FONTECCHIO", nazione: "ITALIA" },
  { codeKey: "D682", sigla: "FR", provincia: "FONTECHIARI", nazione: "ITALIA" },
  { codeKey: "D683", sigla: "CE", provincia: "FONTEGRECA", nazione: "ITALIA" },
  { codeKey: "D684", sigla: "BG", provincia: "FONTENO", nazione: "ITALIA" },
  { codeKey: "D685", sigla: "PR", provincia: "FONTEVIVO", nazione: "ITALIA" },
  { codeKey: "D686", sigla: "BL", provincia: "FONZASO", nazione: "ITALIA" },
  { codeKey: "D688", sigla: "BG", provincia: "FOPPOLO", nazione: "ITALIA" },
  { codeKey: "D689", sigla: "RI", provincia: "FORANO", nazione: "ITALIA" },
  {
    codeKey: "D690",
    sigla: "CH",
    provincia: "SAN GIOVANNI TEATINO",
    nazione: "ITALIA",
  },
  { codeKey: "D691", sigla: "AP", provincia: "FORCE", nazione: "ITALIA" },
  { codeKey: "D693", sigla: "BN", provincia: "FORCHIA", nazione: "ITALIA" },
  { codeKey: "D694", sigla: "SO", provincia: "FORCOLA", nazione: "ITALIA" },
  { codeKey: "D695", sigla: "OR", provincia: "FORDONGIANUS", nazione: "ITALIA" },
  { codeKey: "D696", sigla: "PZ", provincia: "FORENZA", nazione: "ITALIA" },
  { codeKey: "D697", sigla: "BG", provincia: "FORESTO SPARSO", nazione: "ITALIA" },
  {
    codeKey: "D700",
    sigla: "UD",
    provincia: "FORGARIA NEL FRIULI",
    nazione: "ITALIA",
  },
  { codeKey: "D701", sigla: "AV", provincia: "FORINO", nazione: "ITALIA" },
  { codeKey: "D702", sigla: "NA", provincia: "FORIO", nazione: "ITALIA" },
  {
    codeKey: "D703",
    sigla: "IS",
    provincia: "FORLI' DEL SANNIO",
    nazione: "ITALIA",
  },
  { codeKey: "D704", sigla: "FC", provincia: "FORLI'", nazione: "ITALIA" },
  { codeKey: "D705", sigla: "FC", provincia: "FORLIMPOPOLI", nazione: "ITALIA" },
  { codeKey: "D706", sigla: "VB", provincia: "FORMAZZA", nazione: "ITALIA" },
  { codeKey: "D707", sigla: "RM", provincia: "FORMELLO", nazione: "ITALIA" },
  { codeKey: "D708", sigla: "LT", provincia: "FORMIA", nazione: "ITALIA" },
  { codeKey: "D709", sigla: "CE", provincia: "FORMICOLA", nazione: "ITALIA" },
  { codeKey: "D710", sigla: "CR", provincia: "FORMIGARA", nazione: "ITALIA" },
  { codeKey: "D711", sigla: "MO", provincia: "FORMIGINE", nazione: "ITALIA" },
  { codeKey: "D712", sigla: "VC", provincia: "FORMIGLIANA", nazione: "ITALIA" },
  { codeKey: "D713", sigla: "FE", provincia: "FORMIGNANA", nazione: "ITALIA" },
  { codeKey: "D714", sigla: "TN", provincia: "FORNACE", nazione: "ITALIA" },
  { codeKey: "D715", sigla: "IS", provincia: "FORNELLI", nazione: "ITALIA" },
  {
    codeKey: "D717",
    sigla: "VI",
    provincia: "TONEZZA DEL CIMONE",
    nazione: "ITALIA",
  },
  { codeKey: "D718", sigla: "UD", provincia: "FORNI AVOLTRI", nazione: "ITALIA" },
  { codeKey: "D719", sigla: "UD", provincia: "FORNI DI SOPRA", nazione: "ITALIA" },
  { codeKey: "D720", sigla: "UD", provincia: "FORNI DI SOTTO", nazione: "ITALIA" },
  { codeKey: "D725", sigla: "TO", provincia: "FORNO CANAVESE", nazione: "ITALIA" },
  { codeKey: "D726", sigla: "BL", provincia: "FORNO DI ZOLDO", nazione: "ITALIA" },
  {
    codeKey: "D727",
    sigla: "BG",
    provincia: "FORNOVO SAN GIOVANNI",
    nazione: "ITALIA",
  },
  { codeKey: "D728", sigla: "PR", provincia: "FORNOVO DI TARO", nazione: "ITALIA" },
  { codeKey: "D730", sigla: "LU", provincia: "FORTE DEI MARMI", nazione: "ITALIA" },
  { codeKey: "D731", sigla: "BZ", provincia: "FORTEZZA", nazione: "ITALIA" },
  { codeKey: "D732", sigla: "PV", provincia: "FORTUNAGO", nazione: "ITALIA" },
  { codeKey: "D733", sigla: "ME", provincia: "FORZA D'AGRO'", nazione: "ITALIA" },
  { codeKey: "D734", sigla: "LU", provincia: "FOSCIANDORA", nazione: "ITALIA" },
  { codeKey: "D735", sigla: "MS", provincia: "FOSDINOVO", nazione: "ITALIA" },
  { codeKey: "D736", sigla: "AQ", provincia: "FOSSA", nazione: "ITALIA" },
  { codeKey: "D737", sigla: "CB", provincia: "FOSSALTO", nazione: "ITALIA" },
  { codeKey: "D738", sigla: "CH", provincia: "FOSSACESIA", nazione: "ITALIA" },
  {
    codeKey: "D740",
    sigla: "VE",
    provincia: "FOSSALTA DI PIAVE",
    nazione: "ITALIA",
  },
  {
    codeKey: "D741",
    sigla: "VE",
    provincia: "FOSSALTA DI PORTOGRUARO",
    nazione: "ITALIA",
  },
  { codeKey: "D742", sigla: "CN", provincia: "FOSSANO", nazione: "ITALIA" },
  { codeKey: "D744", sigla: "CZ", provincia: "FOSSATO SERRALTA", nazione: "ITALIA" },
  { codeKey: "D745", sigla: "PG", provincia: "FOSSATO DI VICO", nazione: "ITALIA" },
  {
    codeKey: "D746",
    sigla: "RC",
    provincia: "MONTEBELLO IONICO",
    nazione: "ITALIA",
  },
  { codeKey: "D748", sigla: "VE", provincia: "FOSSO'", nazione: "ITALIA" },
  { codeKey: "D749", sigla: "PU", provincia: "FOSSOMBRONE", nazione: "ITALIA" },
  { codeKey: "D750", sigla: "VI", provincia: "FOZA", nazione: "ITALIA" },
  { codeKey: "D751", sigla: "CN", provincia: "FRABOSA SOPRANA", nazione: "ITALIA" },
  { codeKey: "D752", sigla: "CN", provincia: "FRABOSA SOTTANA", nazione: "ITALIA" },
  { codeKey: "D754", sigla: "TA", provincia: "FRAGAGNANO", nazione: "ITALIA" },
  { codeKey: "D755", sigla: "BN", provincia: "FRAGNETO L'ABATE", nazione: "ITALIA" },
  {
    codeKey: "D756",
    sigla: "BN",
    provincia: "FRAGNETO MONFORTE",
    nazione: "ITALIA",
  },
  { codeKey: "D757", sigla: "CH", provincia: "FRAINE", nazione: "ITALIA" },
  { codeKey: "D758", sigla: "SP", provincia: "FRAMURA", nazione: "ITALIA" },
  {
    codeKey: "D759",
    sigla: "AL",
    provincia: "FRANCAVILLA BISIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "D760",
    sigla: "FM",
    provincia: "FRANCAVILLA D'ETE",
    nazione: "ITALIA",
  },
  {
    codeKey: "D761",
    sigla: "BR",
    provincia: "FRANCAVILLA FONTANA",
    nazione: "ITALIA",
  },
  {
    codeKey: "D762",
    sigla: "VV",
    provincia: "FRANCAVILLA ANGITOLA",
    nazione: "ITALIA",
  },
  {
    codeKey: "D763",
    sigla: "CH",
    provincia: "FRANCAVILLA AL MARE",
    nazione: "ITALIA",
  },
  {
    codeKey: "D764",
    sigla: "CS",
    provincia: "FRANCAVILLA MARITTIMA",
    nazione: "ITALIA",
  },
  {
    codeKey: "D765",
    sigla: "ME",
    provincia: "FRANCAVILLA DI SICILIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "D766",
    sigla: "PZ",
    provincia: "FRANCAVILLA IN SINNI",
    nazione: "ITALIA",
  },
  { codeKey: "D767", sigla: "VV", provincia: "FRANCICA", nazione: "ITALIA" },
  { codeKey: "D768", sigla: "SR", provincia: "FRANCOFONTE", nazione: "ITALIA" },
  { codeKey: "D769", sigla: "CE", provincia: "FRANCOLISE", nazione: "ITALIA" },
  { codeKey: "D770", sigla: "AL", provincia: "FRASCARO", nazione: "ITALIA" },
  { codeKey: "D771", sigla: "PV", provincia: "FRASCAROLO", nazione: "ITALIA" },
  { codeKey: "D773", sigla: "RM", provincia: "FRASCATI", nazione: "ITALIA" },
  { codeKey: "D774", sigla: "CS", provincia: "FRASCINETO", nazione: "ITALIA" },
  { codeKey: "D775", sigla: "TN", provincia: "FRASSILONGO", nazione: "ITALIA" },
  {
    codeKey: "D776",
    sigla: "RO",
    provincia: "FRASSINELLE POLESINE",
    nazione: "ITALIA",
  },
  {
    codeKey: "D777",
    sigla: "AL",
    provincia: "FRASSINELLO MONFERRATO",
    nazione: "ITALIA",
  },
  { codeKey: "D780", sigla: "AL", provincia: "FRASSINETO PO", nazione: "ITALIA" },
  { codeKey: "D781", sigla: "TO", provincia: "FRASSINETTO", nazione: "ITALIA" },
  { codeKey: "D782", sigla: "CN", provincia: "FRASSINO", nazione: "ITALIA" },
  { codeKey: "D783", sigla: "MO", provincia: "FRASSINORO", nazione: "ITALIA" },
  { codeKey: "D784", sigla: "BN", provincia: "FRASSO TELESINO", nazione: "ITALIA" },
  { codeKey: "D785", sigla: "RI", provincia: "FRASSO SABINO", nazione: "ITALIA" },
  { codeKey: "D786", sigla: "PG", provincia: "UMBERTIDE", nazione: "ITALIA" },
  { codeKey: "D787", sigla: "PG", provincia: "FRATTA TODINA", nazione: "ITALIA" },
  { codeKey: "D788", sigla: "RO", provincia: "FRATTA POLESINE", nazione: "ITALIA" },
  { codeKey: "D789", sigla: "NA", provincia: "FRATTAMAGGIORE", nazione: "ITALIA" },
  { codeKey: "D790", sigla: "NA", provincia: "FRATTAMINORE", nazione: "ITALIA" },
  { codeKey: "D791", sigla: "PU", provincia: "FRATTE ROSA", nazione: "ITALIA" },
  { codeKey: "D793", sigla: "ME", provincia: "FRAZZANO'", nazione: "ITALIA" },
  { codeKey: "D794", sigla: "TV", provincia: "FREGONA", nazione: "ITALIA" },
  { codeKey: "D796", sigla: "CH", provincia: "FRESAGRANDINARIA", nazione: "ITALIA" },
  { codeKey: "D797", sigla: "AL", provincia: "FRESONARA", nazione: "ITALIA" },
  { codeKey: "D798", sigla: "AV", provincia: "FRIGENTO", nazione: "ITALIA" },
  { codeKey: "D799", sigla: "CE", provincia: "FRIGNANO", nazione: "ITALIA" },
  { codeKey: "D801", sigla: "CE", provincia: "VILLA DI BRIANO", nazione: "ITALIA" },
  { codeKey: "D802", sigla: "AT", provincia: "FRINCO", nazione: "ITALIA" },
  { codeKey: "D803", sigla: "CH", provincia: "FRISA", nazione: "ITALIA" },
  { codeKey: "D804", sigla: "PN", provincia: "FRISANCO", nazione: "ITALIA" },
  { codeKey: "D805", sigla: "TO", provincia: "FRONT", nazione: "ITALIA" },
  { codeKey: "D807", sigla: "PU", provincia: "FRONTINO", nazione: "ITALIA" },
  { codeKey: "D808", sigla: "PU", provincia: "FRONTONE", nazione: "ITALIA" },
  { codeKey: "D810", sigla: "FR", provincia: "FROSINONE", nazione: "ITALIA" },
  { codeKey: "D811", sigla: "IS", provincia: "FROSOLONE", nazione: "ITALIA" },
  { codeKey: "D812", sigla: "TO", provincia: "FROSSASCO", nazione: "ITALIA" },
  { codeKey: "D813", sigla: "AL", provincia: "FRUGAROLO", nazione: "ITALIA" },
  { codeKey: "D814", sigla: "AL", provincia: "FUBINE", nazione: "ITALIA" },
  { codeKey: "D815", sigla: "FI", provincia: "FUCECCHIO", nazione: "ITALIA" },
  {
    codeKey: "D817",
    sigla: "BG",
    provincia: "FUIPIANO VALLE IMAGNA",
    nazione: "ITALIA",
  },
  { codeKey: "D818", sigla: "VR", provincia: "FUMANE", nazione: "ITALIA" },
  { codeKey: "D819", sigla: "FR", provincia: "FUMONE", nazione: "ITALIA" },
  { codeKey: "D821", sigla: "BZ", provincia: "FUNES", nazione: "ITALIA" },
  { codeKey: "D823", sigla: "CH", provincia: "FURCI", nazione: "ITALIA" },
  { codeKey: "D824", sigla: "ME", provincia: "FURCI SICULO", nazione: "ITALIA" },
  { codeKey: "D825", sigla: "ME", provincia: "FURNARI", nazione: "ITALIA" },
  { codeKey: "D826", sigla: "SA", provincia: "FURORE", nazione: "ITALIA" },
  { codeKey: "D827", sigla: "VS", provincia: "FURTEI", nazione: "ITALIA" },
  { codeKey: "D828", sigla: "CS", provincia: "FUSCALDO", nazione: "ITALIA" },
  { codeKey: "D829", sigla: "RA", provincia: "FUSIGNANO", nazione: "ITALIA" },
  { codeKey: "D830", sigla: "SO", provincia: "FUSINE", nazione: "ITALIA" },
  { codeKey: "D832", sigla: "SA", provincia: "FUTANI", nazione: "ITALIA" },
  {
    codeKey: "D834",
    sigla: "CR",
    provincia: "GABBIONETA BINANUOVA",
    nazione: "ITALIA",
  },
  { codeKey: "D835", sigla: "AL", provincia: "GABIANO", nazione: "ITALIA" },
  { codeKey: "D836", sigla: "PU", provincia: "GABICCE MARE", nazione: "ITALIA" },
  { codeKey: "D839", sigla: "AO", provincia: "GABY", nazione: "ITALIA" },
  {
    codeKey: "D841",
    sigla: "CR",
    provincia: "GADESCO PIEVE DELMONA",
    nazione: "ITALIA",
  },
  { codeKey: "D842", sigla: "NU", provincia: "GADONI", nazione: "ITALIA" },
  { codeKey: "D843", sigla: "LT", provincia: "GAETA", nazione: "ITALIA" },
  { codeKey: "D844", sigla: "ME", provincia: "GAGGI", nazione: "ITALIA" },
  { codeKey: "D845", sigla: "MI", provincia: "GAGGIANO", nazione: "ITALIA" },
  { codeKey: "D847", sigla: "BO", provincia: "GAGGIO MONTANO", nazione: "ITALIA" },
  { codeKey: "D848", sigla: "BI", provincia: "GAGLIANICO", nazione: "ITALIA" },
  {
    codeKey: "D849",
    sigla: "EN",
    provincia: "GAGLIANO CASTELFERRATO",
    nazione: "ITALIA",
  },
  { codeKey: "D850", sigla: "AQ", provincia: "GAGLIANO ATERNO", nazione: "ITALIA" },
  {
    codeKey: "D851",
    sigla: "LE",
    provincia: "GAGLIANO DEL CAPO",
    nazione: "ITALIA",
  },
  { codeKey: "D852", sigla: "CZ", provincia: "GAGLIATO", nazione: "ITALIA" },
  { codeKey: "D853", sigla: "MC", provincia: "GAGLIOLE", nazione: "ITALIA" },
  { codeKey: "D854", sigla: "TV", provincia: "GAIARINE", nazione: "ITALIA" },
  { codeKey: "D855", sigla: "RO", provincia: "GAIBA", nazione: "ITALIA" },
  { codeKey: "D856", sigla: "CN", provincia: "GAIOLA", nazione: "ITALIA" },
  {
    codeKey: "D858",
    sigla: "SI",
    provincia: "GAIOLE IN CHIANTI",
    nazione: "ITALIA",
  },
  { codeKey: "D859", sigla: "OG", provincia: "GAIRO", nazione: "ITALIA" },
  { codeKey: "D860", sigla: "BZ", provincia: "GAIS", nazione: "ITALIA" },
  { codeKey: "D861", sigla: "ME", provincia: "GALATI MAMERTINO", nazione: "ITALIA" },
  { codeKey: "D862", sigla: "LE", provincia: "GALATINA", nazione: "ITALIA" },
  { codeKey: "D863", sigla: "LE", provincia: "GALATONE", nazione: "ITALIA" },
  { codeKey: "D864", sigla: "RC", provincia: "GALATRO", nazione: "ITALIA" },
  { codeKey: "D865", sigla: "LC", provincia: "GALBIATE", nazione: "ITALIA" },
  { codeKey: "D867", sigla: "FC", provincia: "GALEATA", nazione: "ITALIA" },
  { codeKey: "D868", sigla: "LO", provincia: "GALGAGNANO", nazione: "ITALIA" },
  { codeKey: "D869", sigla: "VA", provincia: "GALLARATE", nazione: "ITALIA" },
  { codeKey: "D870", sigla: "VT", provincia: "GALLESE", nazione: "ITALIA" },
  {
    codeKey: "D871",
    sigla: "VA",
    provincia: "GALLIATE LOMBARDO",
    nazione: "ITALIA",
  },
  { codeKey: "D872", sigla: "NO", provincia: "GALLIATE", nazione: "ITALIA" },
  { codeKey: "D873", sigla: "PV", provincia: "GALLIAVOLA", nazione: "ITALIA" },
  { codeKey: "D874", sigla: "LU", provincia: "GALLICANO", nazione: "ITALIA" },
  {
    codeKey: "D875",
    sigla: "RM",
    provincia: "GALLICANO NEL LAZIO",
    nazione: "ITALIA",
  },
  { codeKey: "D876", sigla: "PZ", provincia: "GALLICCHIO", nazione: "ITALIA" },
  { codeKey: "D878", sigla: "BO", provincia: "GALLIERA", nazione: "ITALIA" },
  { codeKey: "D879", sigla: "PD", provincia: "GALLIERA VENETA", nazione: "ITALIA" },
  { codeKey: "D881", sigla: "FR", provincia: "GALLINARO", nazione: "ITALIA" },
  { codeKey: "D882", sigla: "VI", provincia: "GALLIO", nazione: "ITALIA" },
  { codeKey: "D883", sigla: "LE", provincia: "GALLIPOLI", nazione: "ITALIA" },
  { codeKey: "D884", sigla: "CE", provincia: "GALLO MATESE", nazione: "ITALIA" },
  { codeKey: "D885", sigla: "ME", provincia: "GALLODORO", nazione: "ITALIA" },
  { codeKey: "D886", sigla: "CE", provincia: "GALLUCCIO", nazione: "ITALIA" },
  { codeKey: "D888", sigla: "NU", provincia: "GALTELLI", nazione: "ITALIA" },
  { codeKey: "D889", sigla: "PD", provincia: "GALZIGNANO TERME", nazione: "ITALIA" },
  { codeKey: "D890", sigla: "AL", provincia: "GAMALERO", nazione: "ITALIA" },
  { codeKey: "D891", sigla: "BS", provincia: "GAMBARA", nazione: "ITALIA" },
  { codeKey: "D892", sigla: "PV", provincia: "GAMBARANA", nazione: "ITALIA" },
  { codeKey: "D894", sigla: "CN", provincia: "GAMBASCA", nazione: "ITALIA" },
  { codeKey: "D895", sigla: "FI", provincia: "GAMBASSI TERME", nazione: "ITALIA" },
  { codeKey: "D896", sigla: "CB", provincia: "GAMBATESA", nazione: "ITALIA" },
  { codeKey: "D897", sigla: "VI", provincia: "GAMBELLARA", nazione: "ITALIA" },
  { codeKey: "D898", sigla: "CH", provincia: "GAMBERALE", nazione: "ITALIA" },
  { codeKey: "D899", sigla: "FC", provincia: "GAMBETTOLA", nazione: "ITALIA" },
  { codeKey: "D901", sigla: "PV", provincia: "GAMBOLO'", nazione: "ITALIA" },
  { codeKey: "D902", sigla: "VI", provincia: "GAMBUGLIANO", nazione: "ITALIA" },
  { codeKey: "D903", sigla: "BG", provincia: "GANDELLINO", nazione: "ITALIA" },
  { codeKey: "D905", sigla: "BG", provincia: "GANDINO", nazione: "ITALIA" },
  { codeKey: "D906", sigla: "BG", provincia: "GANDOSSO", nazione: "ITALIA" },
  { codeKey: "D907", sigla: "PA", provincia: "GANGI", nazione: "ITALIA" },
  { codeKey: "D909", sigla: "MT", provincia: "GARAGUSO", nazione: "ITALIA" },
  { codeKey: "D910", sigla: "AL", provincia: "GARBAGNA", nazione: "ITALIA" },
  {
    codeKey: "D911",
    sigla: "NO",
    provincia: "GARBAGNA NOVARESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "D912",
    sigla: "MI",
    provincia: "GARBAGNATE MILANESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "D913",
    sigla: "LC",
    provincia: "GARBAGNATE MONASTERO",
    nazione: "ITALIA",
  },
  { codeKey: "D915", sigla: "VR", provincia: "GARDA", nazione: "ITALIA" },
  { codeKey: "D917", sigla: "BS", provincia: "GARDONE RIVIERA", nazione: "ITALIA" },
  {
    codeKey: "D918",
    sigla: "BS",
    provincia: "GARDONE VAL TROMPIA",
    nazione: "ITALIA",
  },
  { codeKey: "D920", sigla: "CN", provincia: "GARESSIO", nazione: "ITALIA" },
  { codeKey: "D921", sigla: "NO", provincia: "GARGALLO", nazione: "ITALIA" },
  { codeKey: "D923", sigla: "BZ", provincia: "GARGAZZONE", nazione: "ITALIA" },
  { codeKey: "D924", sigla: "BS", provincia: "GARGNANO", nazione: "ITALIA" },
  { codeKey: "D925", sigla: "PV", provincia: "GARLASCO", nazione: "ITALIA" },
  { codeKey: "D926", sigla: "LC", provincia: "GARLATE", nazione: "ITALIA" },
  { codeKey: "D927", sigla: "SV", provincia: "GARLENDA", nazione: "ITALIA" },
  { codeKey: "D928", sigla: "TN", provincia: "GARNIGA TERME", nazione: "ITALIA" },
  { codeKey: "D930", sigla: "CO", provincia: "GARZENO", nazione: "ITALIA" },
  { codeKey: "D931", sigla: "TO", provincia: "GARZIGLIANA", nazione: "ITALIA" },
  { codeKey: "D932", sigla: "CZ", provincia: "GASPERINA", nazione: "ITALIA" },
  { codeKey: "D933", sigla: "TO", provincia: "GASSINO TORINESE", nazione: "ITALIA" },
  { codeKey: "D934", sigla: "RE", provincia: "GATTATICO", nazione: "ITALIA" },
  { codeKey: "D935", sigla: "FC", provincia: "GATTEO", nazione: "ITALIA" },
  { codeKey: "D937", sigla: "NO", provincia: "GATTICO", nazione: "ITALIA" },
  { codeKey: "D938", sigla: "VC", provincia: "GATTINARA", nazione: "ITALIA" },
  { codeKey: "D940", sigla: "BS", provincia: "GAVARDO", nazione: "ITALIA" },
  { codeKey: "D941", sigla: "AL", provincia: "GAVAZZANA", nazione: "ITALIA" },
  { codeKey: "D942", sigla: "RO", provincia: "GAVELLO", nazione: "ITALIA" },
  { codeKey: "D943", sigla: "BG", provincia: "GAVERINA TERME", nazione: "ITALIA" },
  { codeKey: "D944", sigla: "AL", provincia: "GAVI", nazione: "ITALIA" },
  { codeKey: "D945", sigla: "RM", provincia: "GAVIGNANO", nazione: "ITALIA" },
  { codeKey: "D946", sigla: "VA", provincia: "GAVIRATE", nazione: "ITALIA" },
  { codeKey: "D947", sigla: "NU", provincia: "GAVOI", nazione: "ITALIA" },
  { codeKey: "D948", sigla: "GR", provincia: "GAVORRANO", nazione: "ITALIA" },
  {
    codeKey: "D949",
    sigla: "MN",
    provincia: "GAZOLDO DEGLI IPPOLITI",
    nazione: "ITALIA",
  },
  { codeKey: "D951", sigla: "VA", provincia: "GAZZADA SCHIANNO", nazione: "ITALIA" },
  { codeKey: "D952", sigla: "BG", provincia: "GAZZANIGA", nazione: "ITALIA" },
  { codeKey: "D956", sigla: "PD", provincia: "GAZZO PADOVANO", nazione: "ITALIA" },
  { codeKey: "D957", sigla: "VR", provincia: "GAZZO VERONESE", nazione: "ITALIA" },
  { codeKey: "D958", sigla: "PC", provincia: "GAZZOLA", nazione: "ITALIA" },
  { codeKey: "D959", sigla: "MN", provincia: "GAZZUOLO", nazione: "ITALIA" },
  { codeKey: "D960", sigla: "CL", provincia: "GELA", nazione: "ITALIA" },
  { codeKey: "D961", sigla: "RN", provincia: "GEMMANO", nazione: "ITALIA" },
  {
    codeKey: "D962",
    sigla: "UD",
    provincia: "GEMONA DEL FRIULI",
    nazione: "ITALIA",
  },
  { codeKey: "D963", sigla: "VA", provincia: "GEMONIO", nazione: "ITALIA" },
  { codeKey: "D964", sigla: "RM", provincia: "GENAZZANO", nazione: "ITALIA" },
  { codeKey: "D965", sigla: "AN", provincia: "GENGA", nazione: "ITALIA" },
  { codeKey: "D966", sigla: "CR", provincia: "GENIVOLTA", nazione: "ITALIA" },
  { codeKey: "D967", sigla: "CN", provincia: "GENOLA", nazione: "ITALIA" },
  { codeKey: "D968", sigla: "NU", provincia: "GENONI", nazione: "ITALIA" },
  { codeKey: "D969", sigla: "GE", provincia: "GENOVA", nazione: "ITALIA" },
  { codeKey: "D970", sigla: "VS", provincia: "GENURI", nazione: "ITALIA" },
  {
    codeKey: "D971",
    sigla: "PZ",
    provincia: "GENZANO DI LUCANIA",
    nazione: "ITALIA",
  },
  { codeKey: "D972", sigla: "RM", provincia: "GENZANO DI ROMA", nazione: "ITALIA" },
  { codeKey: "D973", sigla: "PV", provincia: "GENZONE", nazione: "ITALIA" },
  { codeKey: "D974", sigla: "CO", provincia: "GERA LARIO", nazione: "ITALIA" },
  { codeKey: "D975", sigla: "RC", provincia: "GERACE", nazione: "ITALIA" },
  { codeKey: "D976", sigla: "RC", provincia: "LOCRI", nazione: "ITALIA" },
  { codeKey: "D977", sigla: "PA", provincia: "GERACI SICULO", nazione: "ITALIA" },
  { codeKey: "D978", sigla: "RM", provincia: "GERANO", nazione: "ITALIA" },
  { codeKey: "D980", sigla: "PV", provincia: "GERENZAGO", nazione: "ITALIA" },
  { codeKey: "D981", sigla: "VA", provincia: "GERENZANO", nazione: "ITALIA" },
  { codeKey: "D982", sigla: "NU", provincia: "GERGEI", nazione: "ITALIA" },
  { codeKey: "D983", sigla: "TO", provincia: "GERMAGNANO", nazione: "ITALIA" },
  { codeKey: "D984", sigla: "VB", provincia: "GERMAGNO", nazione: "ITALIA" },
  { codeKey: "D987", sigla: "VA", provincia: "GERMIGNAGA", nazione: "ITALIA" },
  { codeKey: "D988", sigla: "VV", provincia: "GEROCARNE", nazione: "ITALIA" },
  { codeKey: "D990", sigla: "SO", provincia: "GEROLA ALTA", nazione: "ITALIA" },
  {
    codeKey: "D993",
    sigla: "CR",
    provincia: "GERRE DE' CAPRIOLI",
    nazione: "ITALIA",
  },
  { codeKey: "D994", sigla: "CA", provincia: "GESICO", nazione: "ITALIA" },
  { codeKey: "D995", sigla: "MI", provincia: "GESSATE", nazione: "ITALIA" },
  { codeKey: "D996", sigla: "CH", provincia: "GESSOPALENA", nazione: "ITALIA" },
  { codeKey: "D997", sigla: "VS", provincia: "GESTURI", nazione: "ITALIA" },
  { codeKey: "D998", sigla: "AV", provincia: "GESUALDO", nazione: "ITALIA" },
  { codeKey: "D999", sigla: "BS", provincia: "GHEDI", nazione: "ITALIA" },
  { codeKey: "E001", sigla: "NO", provincia: "GHEMME", nazione: "ITALIA" },
  { codeKey: "E003", sigla: "VB", provincia: "GHIFFA", nazione: "ITALIA" },
  { codeKey: "E004", sigla: "OR", provincia: "GHILARZA", nazione: "ITALIA" },
  { codeKey: "E006", sigla: "BG", provincia: "GHISALBA", nazione: "ITALIA" },
  { codeKey: "E007", sigla: "VC", provincia: "GHISLARENGO", nazione: "ITALIA" },
  {
    codeKey: "E008",
    sigla: "RO",
    provincia: "GIACCIANO CON BARUCHELLA",
    nazione: "ITALIA",
  },
  { codeKey: "E009", sigla: "TO", provincia: "GIAGLIONE", nazione: "ITALIA" },
  { codeKey: "E010", sigla: "BS", provincia: "GIANICO", nazione: "ITALIA" },
  { codeKey: "E011", sigla: "CE", provincia: "GIANO VETUSTO", nazione: "ITALIA" },
  {
    codeKey: "E012",
    sigla: "PG",
    provincia: "GIANO DELL'UMBRIA",
    nazione: "ITALIA",
  },
  { codeKey: "E013", sigla: "PA", provincia: "GIARDINELLO", nazione: "ITALIA" },
  { codeKey: "E014", sigla: "ME", provincia: "GIARDINI-NAXOS", nazione: "ITALIA" },
  { codeKey: "E015", sigla: "AL", provincia: "GIAROLE", nazione: "ITALIA" },
  { codeKey: "E016", sigla: "RG", provincia: "GIARRATANA", nazione: "ITALIA" },
  { codeKey: "E017", sigla: "CT", provincia: "GIARRE", nazione: "ITALIA" },
  { codeKey: "E019", sigla: "SS", provincia: "GIAVE", nazione: "ITALIA" },
  { codeKey: "E020", sigla: "TO", provincia: "GIAVENO", nazione: "ITALIA" },
  {
    codeKey: "E021",
    sigla: "TV",
    provincia: "GIAVERA DEL MONTELLO",
    nazione: "ITALIA",
  },
  { codeKey: "E022", sigla: "CI", provincia: "GIBA", nazione: "ITALIA" },
  { codeKey: "E023", sigla: "TP", provincia: "GIBELLINA", nazione: "ITALIA" },
  { codeKey: "E024", sigla: "BI", provincia: "GIFFLENGA", nazione: "ITALIA" },
  { codeKey: "E025", sigla: "RC", provincia: "GIFFONE", nazione: "ITALIA" },
  {
    codeKey: "E026",
    sigla: "SA",
    provincia: "GIFFONI SEI CASALI",
    nazione: "ITALIA",
  },
  {
    codeKey: "E027",
    sigla: "SA",
    provincia: "GIFFONI VALLE PIANA",
    nazione: "ITALIA",
  },
  { codeKey: "E028", sigla: "VB", provincia: "GIGNESE", nazione: "ITALIA" },
  { codeKey: "E029", sigla: "AO", provincia: "GIGNOD", nazione: "ITALIA" },
  { codeKey: "E030", sigla: "CB", provincia: "GILDONE", nazione: "ITALIA" },
  { codeKey: "E031", sigla: "CZ", provincia: "GIMIGLIANO", nazione: "ITALIA" },
  { codeKey: "E033", sigla: "PZ", provincia: "GINESTRA", nazione: "ITALIA" },
  {
    codeKey: "E034",
    sigla: "BN",
    provincia: "GINESTRA DEGLI SCHIAVONI",
    nazione: "ITALIA",
  },
  { codeKey: "E036", sigla: "TA", provincia: "GINOSA", nazione: "ITALIA" },
  { codeKey: "E037", sigla: "SA", provincia: "GIOI", nazione: "ITALIA" },
  { codeKey: "E038", sigla: "BA", provincia: "GIOIA DEL COLLE", nazione: "ITALIA" },
  { codeKey: "E039", sigla: "CE", provincia: "GIOIA SANNITICA", nazione: "ITALIA" },
  { codeKey: "E040", sigla: "AQ", provincia: "GIOIA DEI MARSI", nazione: "ITALIA" },
  { codeKey: "E041", sigla: "RC", provincia: "GIOIA TAURO", nazione: "ITALIA" },
  { codeKey: "E043", sigla: "ME", provincia: "GIOIOSA MAREA", nazione: "ITALIA" },
  { codeKey: "E044", sigla: "RC", provincia: "GIOIOSA IONICA", nazione: "ITALIA" },
  { codeKey: "E045", sigla: "TR", provincia: "GIOVE", nazione: "ITALIA" },
  { codeKey: "E047", sigla: "BA", provincia: "GIOVINAZZO", nazione: "ITALIA" },
  { codeKey: "E048", sigla: "TN", provincia: "GIOVO", nazione: "ITALIA" },
  { codeKey: "E049", sigla: "OG", provincia: "GIRASOLE", nazione: "ITALIA" },
  { codeKey: "E050", sigla: "CZ", provincia: "GIRIFALCO", nazione: "ITALIA" },
  { codeKey: "E052", sigla: "CH", provincia: "GISSI", nazione: "ITALIA" },
  { codeKey: "E053", sigla: "LE", provincia: "GIUGGIANELLO", nazione: "ITALIA" },
  {
    codeKey: "E054",
    sigla: "NA",
    provincia: "GIUGLIANO IN CAMPANIA",
    nazione: "ITALIA",
  },
  { codeKey: "E055", sigla: "PA", provincia: "GIULIANA", nazione: "ITALIA" },
  { codeKey: "E056", sigla: "CH", provincia: "GIULIANO TEATINO", nazione: "ITALIA" },
  { codeKey: "E057", sigla: "FR", provincia: "GIULIANO DI ROMA", nazione: "ITALIA" },
  { codeKey: "E058", sigla: "TE", provincia: "GIULIANOVA", nazione: "ITALIA" },
  { codeKey: "E060", sigla: "SA", provincia: "GIUNGANO", nazione: "ITALIA" },
  { codeKey: "E061", sigla: "LE", provincia: "GIURDIGNANO", nazione: "ITALIA" },
  { codeKey: "E062", sigla: "PV", provincia: "GIUSSAGO", nazione: "ITALIA" },
  { codeKey: "E063", sigla: "MB", provincia: "GIUSSANO", nazione: "ITALIA" },
  { codeKey: "E064", sigla: "SV", provincia: "GIUSTENICE", nazione: "ITALIA" },
  { codeKey: "E065", sigla: "TN", provincia: "GIUSTINO", nazione: "ITALIA" },
  { codeKey: "E066", sigla: "SV", provincia: "GIUSVALLA", nazione: "ITALIA" },
  { codeKey: "E067", sigla: "TO", provincia: "GIVOLETTO", nazione: "ITALIA" },
  { codeKey: "E068", sigla: "CZ", provincia: "GIZZERIA", nazione: "ITALIA" },
  { codeKey: "E069", sigla: "BZ", provincia: "GLORENZA", nazione: "ITALIA" },
  { codeKey: "E070", sigla: "SP", provincia: "SESTA GODANO", nazione: "ITALIA" },
  {
    codeKey: "E071",
    sigla: "TV",
    provincia: "GODEGA DI SANT'URBANO",
    nazione: "ITALIA",
  },
  { codeKey: "E072", sigla: "PV", provincia: "GODIASCO", nazione: "ITALIA" },
  { codeKey: "E074", sigla: "PA", provincia: "GODRANO", nazione: "ITALIA" },
  { codeKey: "E078", sigla: "MN", provincia: "GOITO", nazione: "ITALIA" },
  { codeKey: "E079", sigla: "VA", provincia: "GOLASECCA", nazione: "ITALIA" },
  { codeKey: "E081", sigla: "PV", provincia: "GOLFERENZO", nazione: "ITALIA" },
  { codeKey: "E082", sigla: "CR", provincia: "GOMBITO", nazione: "ITALIA" },
  { codeKey: "E083", sigla: "UD", provincia: "GONARS", nazione: "ITALIA" },
  { codeKey: "E084", sigla: "CA", provincia: "GONI", nazione: "ITALIA" },
  { codeKey: "E085", sigla: "VS", provincia: "GONNOSFANADIGA", nazione: "ITALIA" },
  { codeKey: "E086", sigla: "CI", provincia: "GONNESA", nazione: "ITALIA" },
  { codeKey: "E087", sigla: "OR", provincia: "GONNOSCODINA", nazione: "ITALIA" },
  { codeKey: "E088", sigla: "OR", provincia: "GONNOSTRAMATZA", nazione: "ITALIA" },
  { codeKey: "E089", sigla: "MN", provincia: "GONZAGA", nazione: "ITALIA" },
  { codeKey: "E090", sigla: "SO", provincia: "GORDONA", nazione: "ITALIA" },
  { codeKey: "E091", sigla: "RM", provincia: "GORGA", nazione: "ITALIA" },
  {
    codeKey: "E092",
    sigla: "TV",
    provincia: "GORGO AL MONTICANO",
    nazione: "ITALIA",
  },
  { codeKey: "E093", sigla: "MT", provincia: "GORGOGLIONE", nazione: "ITALIA" },
  { codeKey: "E094", sigla: "MI", provincia: "GORGONZOLA", nazione: "ITALIA" },
  { codeKey: "E096", sigla: "AQ", provincia: "GORIANO SICOLI", nazione: "ITALIA" },
  { codeKey: "E098", sigla: "GO", provincia: "GORIZIA", nazione: "ITALIA" },
  { codeKey: "E100", sigla: "BG", provincia: "GORLAGO", nazione: "ITALIA" },
  { codeKey: "E101", sigla: "VA", provincia: "GORLA MAGGIORE", nazione: "ITALIA" },
  { codeKey: "E102", sigla: "VA", provincia: "GORLA MINORE", nazione: "ITALIA" },
  { codeKey: "E103", sigla: "BG", provincia: "GORLE", nazione: "ITALIA" },
  { codeKey: "E104", sigla: "VA", provincia: "GORNATE-OLONA", nazione: "ITALIA" },
  { codeKey: "E106", sigla: "BG", provincia: "GORNO", nazione: "ITALIA" },
  { codeKey: "E107", sigla: "FE", provincia: "GORO", nazione: "ITALIA" },
  { codeKey: "E109", sigla: "GE", provincia: "GORRETO", nazione: "ITALIA" },
  { codeKey: "E111", sigla: "CN", provincia: "GORZEGNO", nazione: "ITALIA" },
  { codeKey: "E113", sigla: "BL", provincia: "GOSALDO", nazione: "ITALIA" },
  { codeKey: "E114", sigla: "PC", provincia: "GOSSOLENGO", nazione: "ITALIA" },
  { codeKey: "E115", sigla: "CN", provincia: "GOTTASECCA", nazione: "ITALIA" },
  { codeKey: "E116", sigla: "BS", provincia: "GOTTOLENGO", nazione: "ITALIA" },
  { codeKey: "E118", sigla: "CN", provincia: "GOVONE", nazione: "ITALIA" },
  { codeKey: "E120", sigla: "NO", provincia: "GOZZANO", nazione: "ITALIA" },
  { codeKey: "E122", sigla: "PU", provincia: "GRADARA", nazione: "ITALIA" },
  {
    codeKey: "E124",
    sigla: "GO",
    provincia: "GRADISCA D'ISONZO",
    nazione: "ITALIA",
  },
  { codeKey: "E125", sigla: "GO", provincia: "GRADO", nazione: "ITALIA" },
  { codeKey: "E126", sigla: "VT", provincia: "GRADOLI", nazione: "ITALIA" },
  { codeKey: "E127", sigla: "LO", provincia: "GRAFFIGNANA", nazione: "ITALIA" },
  { codeKey: "E128", sigla: "VT", provincia: "GRAFFIGNANO", nazione: "ITALIA" },
  { codeKey: "E130", sigla: "BI", provincia: "GRAGLIA", nazione: "ITALIA" },
  { codeKey: "E131", sigla: "NA", provincia: "GRAGNANO", nazione: "ITALIA" },
  {
    codeKey: "E132",
    sigla: "PC",
    provincia: "GRAGNANO TREBBIENSE",
    nazione: "ITALIA",
  },
  { codeKey: "E133", sigla: "CT", provincia: "GRAMMICHELE", nazione: "ITALIA" },
  { codeKey: "E134", sigla: "AT", provincia: "GRANA", nazione: "ITALIA" },
  { codeKey: "E135", sigla: "BO", provincia: "GRANAGLIONE", nazione: "ITALIA" },
  {
    codeKey: "E136",
    sigla: "BO",
    provincia: "GRANAROLO DELL'EMILIA",
    nazione: "ITALIA",
  },
  { codeKey: "E138", sigla: "VI", provincia: "GRANCONA", nazione: "ITALIA" },
  { codeKey: "E139", sigla: "CO", provincia: "GRANDATE", nazione: "ITALIA" },
  {
    codeKey: "E141",
    sigla: "CO",
    provincia: "GRANDOLA ED UNITI",
    nazione: "ITALIA",
  },
  { codeKey: "E142", sigla: "ME", provincia: "GRANITI", nazione: "ITALIA" },
  {
    codeKey: "E143",
    sigla: "NO",
    provincia: "GRANOZZO CON MONTICELLO",
    nazione: "ITALIA",
  },
  { codeKey: "E144", sigla: "VA", provincia: "GRANTOLA", nazione: "ITALIA" },
  { codeKey: "E145", sigla: "PD", provincia: "GRANTORTO", nazione: "ITALIA" },
  { codeKey: "E146", sigla: "PD", provincia: "GRANZE", nazione: "ITALIA" },
  { codeKey: "E147", sigla: "MT", provincia: "GRASSANO", nazione: "ITALIA" },
  { codeKey: "E148", sigla: "BG", provincia: "GRASSOBBIO", nazione: "ITALIA" },
  { codeKey: "E149", sigla: "PA", provincia: "GRATTERI", nazione: "ITALIA" },
  { codeKey: "E150", sigla: "TN", provincia: "GRAUNO", nazione: "ITALIA" },
  {
    codeKey: "E152",
    sigla: "PV",
    provincia: "GRAVELLONA LOMELLINA",
    nazione: "ITALIA",
  },
  { codeKey: "E153", sigla: "VB", provincia: "GRAVELLONA TOCE", nazione: "ITALIA" },
  { codeKey: "E154", sigla: "TO", provincia: "GRAVERE", nazione: "ITALIA" },
  {
    codeKey: "E155",
    sigla: "BA",
    provincia: "GRAVINA IN PUGLIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "E156",
    sigla: "CT",
    provincia: "GRAVINA DI CATANIA",
    nazione: "ITALIA",
  },
  { codeKey: "E158", sigla: "CE", provincia: "GRAZZANISE", nazione: "ITALIA" },
  {
    codeKey: "E159",
    sigla: "AT",
    provincia: "GRAZZANO BADOGLIO",
    nazione: "ITALIA",
  },
  { codeKey: "E160", sigla: "RI", provincia: "GRECCIO", nazione: "ITALIA" },
  { codeKey: "E161", sigla: "AV", provincia: "GRECI", nazione: "ITALIA" },
  { codeKey: "E163", sigla: "VC", provincia: "GREGGIO", nazione: "ITALIA" },
  { codeKey: "E164", sigla: "AL", provincia: "GREMIASCO", nazione: "ITALIA" },
  { codeKey: "E165", sigla: "AO", provincia: "GRESSAN", nazione: "ITALIA" },
  {
    codeKey: "E167",
    sigla: "AO",
    provincia: "GRESSONEY-LA-TRINITE'",
    nazione: "ITALIA",
  },
  {
    codeKey: "E168",
    sigla: "AO",
    provincia: "GRESSONEY-SAINT-JEAN",
    nazione: "ITALIA",
  },
  { codeKey: "E169", sigla: "FI", provincia: "GREVE IN CHIANTI", nazione: "ITALIA" },
  { codeKey: "E170", sigla: "MI", provincia: "GREZZAGO", nazione: "ITALIA" },
  { codeKey: "E171", sigla: "VR", provincia: "GREZZANA", nazione: "ITALIA" },
  { codeKey: "E172", sigla: "CO", provincia: "GRIANTE", nazione: "ITALIA" },
  {
    codeKey: "E173",
    sigla: "CE",
    provincia: "GRICIGNANO DI AVERSA",
    nazione: "ITALIA",
  },
  { codeKey: "E177", sigla: "NO", provincia: "GRIGNASCO", nazione: "ITALIA" },
  { codeKey: "E178", sigla: "TN", provincia: "GRIGNO", nazione: "ITALIA" },
  { codeKey: "E179", sigla: "UD", provincia: "GRIMACCO", nazione: "ITALIA" },
  { codeKey: "E180", sigla: "CS", provincia: "GRIMALDI", nazione: "ITALIA" },
  { codeKey: "E182", sigla: "CN", provincia: "GRINZANE CAVOUR", nazione: "ITALIA" },
  {
    codeKey: "E184",
    sigla: "VI",
    provincia: "GRISIGNANO DI ZOCCO",
    nazione: "ITALIA",
  },
  { codeKey: "E185", sigla: "CS", provincia: "GRISOLIA", nazione: "ITALIA" },
  { codeKey: "E187", sigla: "BO", provincia: "GRIZZANA MORANDI", nazione: "ITALIA" },
  { codeKey: "E188", sigla: "AL", provincia: "GROGNARDO", nazione: "ITALIA" },
  { codeKey: "E189", sigla: "BG", provincia: "GROMO", nazione: "ITALIA" },
  { codeKey: "E191", sigla: "AL", provincia: "GRONDONA", nazione: "ITALIA" },
  { codeKey: "E192", sigla: "BG", provincia: "GRONE", nazione: "ITALIA" },
  { codeKey: "E193", sigla: "CR", provincia: "GRONTARDO", nazione: "ITALIA" },
  { codeKey: "E195", sigla: "PV", provincia: "GROPELLO CAIROLI", nazione: "ITALIA" },
  { codeKey: "E196", sigla: "PC", provincia: "GROPPARELLO", nazione: "ITALIA" },
  { codeKey: "E199", sigla: "TO", provincia: "GROSCAVALLO", nazione: "ITALIA" },
  { codeKey: "E200", sigla: "SO", provincia: "GROSIO", nazione: "ITALIA" },
  { codeKey: "E201", sigla: "SO", provincia: "GROSOTTO", nazione: "ITALIA" },
  { codeKey: "E202", sigla: "GR", provincia: "GROSSETO", nazione: "ITALIA" },
  { codeKey: "E203", sigla: "TO", provincia: "GROSSO", nazione: "ITALIA" },
  { codeKey: "E204", sigla: "RM", provincia: "GROTTAFERRATA", nazione: "ITALIA" },
  { codeKey: "E205", sigla: "TA", provincia: "GROTTAGLIE", nazione: "ITALIA" },
  { codeKey: "E206", sigla: "AV", provincia: "GROTTAMINARDA", nazione: "ITALIA" },
  { codeKey: "E207", sigla: "AP", provincia: "GROTTAMMARE", nazione: "ITALIA" },
  { codeKey: "E208", sigla: "FM", provincia: "GROTTAZZOLINA", nazione: "ITALIA" },
  { codeKey: "E209", sigla: "AG", provincia: "GROTTE", nazione: "ITALIA" },
  { codeKey: "E210", sigla: "VT", provincia: "GROTTE DI CASTRO", nazione: "ITALIA" },
  { codeKey: "E212", sigla: "RC", provincia: "GROTTERIA", nazione: "ITALIA" },
  { codeKey: "E213", sigla: "MT", provincia: "GROTTOLE", nazione: "ITALIA" },
  { codeKey: "E214", sigla: "AV", provincia: "GROTTOLELLA", nazione: "ITALIA" },
  { codeKey: "E215", sigla: "VE", provincia: "GRUARO", nazione: "ITALIA" },
  { codeKey: "E216", sigla: "TO", provincia: "GRUGLIASCO", nazione: "ITALIA" },
  {
    codeKey: "E217",
    sigla: "CR",
    provincia: "GRUMELLO CREMONESE ED UNITI",
    nazione: "ITALIA",
  },
  {
    codeKey: "E219",
    sigla: "BG",
    provincia: "GRUMELLO DEL MONTE",
    nazione: "ITALIA",
  },
  { codeKey: "E221", sigla: "PZ", provincia: "GRUMENTO NOVA", nazione: "ITALIA" },
  { codeKey: "E222", sigla: "TN", provincia: "GRUMES", nazione: "ITALIA" },
  { codeKey: "E223", sigla: "BA", provincia: "GRUMO APPULA", nazione: "ITALIA" },
  { codeKey: "E224", sigla: "NA", provincia: "GRUMO NEVANO", nazione: "ITALIA" },
  {
    codeKey: "E226",
    sigla: "VI",
    provincia: "GRUMOLO DELLE ABBADESSE",
    nazione: "ITALIA",
  },
  { codeKey: "E227", sigla: "LE", provincia: "GUAGNANO", nazione: "ITALIA" },
  { codeKey: "E228", sigla: "MC", provincia: "GUALDO", nazione: "ITALIA" },
  { codeKey: "E229", sigla: "PG", provincia: "GUALDO CATTANEO", nazione: "ITALIA" },
  { codeKey: "E230", sigla: "PG", provincia: "GUALDO TADINO", nazione: "ITALIA" },
  { codeKey: "E232", sigla: "RE", provincia: "GUALTIERI", nazione: "ITALIA" },
  {
    codeKey: "E233",
    sigla: "ME",
    provincia: "GUALTIERI SICAMINO'",
    nazione: "ITALIA",
  },
  { codeKey: "E234", sigla: "CA", provincia: "GUAMAGGIORE", nazione: "ITALIA" },
  { codeKey: "E235", sigla: "CO", provincia: "GUANZATE", nazione: "ITALIA" },
  { codeKey: "E236", sigla: "FR", provincia: "GUARCINO", nazione: "ITALIA" },
  { codeKey: "E237", sigla: "VC", provincia: "GUARDABOSONE", nazione: "ITALIA" },
  { codeKey: "E238", sigla: "LO", provincia: "GUARDAMIGLIO", nazione: "ITALIA" },
  { codeKey: "E239", sigla: "CZ", provincia: "GUARDAVALLE", nazione: "ITALIA" },
  { codeKey: "E240", sigla: "RO", provincia: "GUARDA VENETA", nazione: "ITALIA" },
  { codeKey: "E241", sigla: "TR", provincia: "GUARDEA", nazione: "ITALIA" },
  {
    codeKey: "E242",
    sigla: "CS",
    provincia: "GUARDIA PIEMONTESE",
    nazione: "ITALIA",
  },
  { codeKey: "E243", sigla: "CH", provincia: "GUARDIAGRELE", nazione: "ITALIA" },
  { codeKey: "E244", sigla: "CB", provincia: "GUARDIALFIERA", nazione: "ITALIA" },
  { codeKey: "E245", sigla: "AV", provincia: "GUARDIA LOMBARDI", nazione: "ITALIA" },
  {
    codeKey: "E246",
    sigla: "PZ",
    provincia: "GUARDIA PERTICARA",
    nazione: "ITALIA",
  },
  { codeKey: "E248", sigla: "CB", provincia: "GUARDIAREGIA", nazione: "ITALIA" },
  {
    codeKey: "E249",
    sigla: "BN",
    provincia: "GUARDIA SANFRAMONDI",
    nazione: "ITALIA",
  },
  { codeKey: "E250", sigla: "PI", provincia: "GUARDISTALLO", nazione: "ITALIA" },
  { codeKey: "E251", sigla: "CN", provincia: "GUARENE", nazione: "ITALIA" },
  { codeKey: "E252", sigla: "CA", provincia: "GUASILA", nazione: "ITALIA" },
  { codeKey: "E253", sigla: "RE", provincia: "GUASTALLA", nazione: "ITALIA" },
  { codeKey: "E255", sigla: "AL", provincia: "GUAZZORA", nazione: "ITALIA" },
  { codeKey: "E256", sigla: "PG", provincia: "GUBBIO", nazione: "ITALIA" },
  { codeKey: "E258", sigla: "MI", provincia: "GUDO VISCONTI", nazione: "ITALIA" },
  { codeKey: "E259", sigla: "CB", provincia: "GUGLIONESI", nazione: "ITALIA" },
  { codeKey: "E261", sigla: "MN", provincia: "GUIDIZZOLO", nazione: "ITALIA" },
  {
    codeKey: "E263",
    sigla: "RM",
    provincia: "GUIDONIA MONTECELIO",
    nazione: "ITALIA",
  },
  { codeKey: "E264", sigla: "MO", provincia: "GUIGLIA", nazione: "ITALIA" },
  { codeKey: "E265", sigla: "PV", provincia: "SIZIANO", nazione: "ITALIA" },
  { codeKey: "E266", sigla: "CH", provincia: "GUILMI", nazione: "ITALIA" },
  { codeKey: "E269", sigla: "VB", provincia: "GURRO", nazione: "ITALIA" },
  { codeKey: "E270", sigla: "VS", provincia: "GUSPINI", nazione: "ITALIA" },
  { codeKey: "E271", sigla: "BS", provincia: "GUSSAGO", nazione: "ITALIA" },
  { codeKey: "E272", sigla: "CR", provincia: "GUSSOLA", nazione: "ITALIA" },
  { codeKey: "E273", sigla: "AO", provincia: "HONE", nazione: "ITALIA" },
  { codeKey: "E274", sigla: "CZ", provincia: "JACURSO", nazione: "ITALIA" },
  { codeKey: "E280", sigla: "BS", provincia: "IDRO", nazione: "ITALIA" },
  { codeKey: "E281", sigla: "CI", provincia: "IGLESIAS", nazione: "ITALIA" },
  { codeKey: "E282", sigla: "CN", provincia: "IGLIANO", nazione: "ITALIA" },
  { codeKey: "E283", sigla: "OG", provincia: "ILBONO", nazione: "ITALIA" },
  { codeKey: "E284", sigla: "VR", provincia: "ILLASI", nazione: "ITALIA" },
  { codeKey: "E285", sigla: "SS", provincia: "ILLORAI", nazione: "ITALIA" },
  { codeKey: "E287", sigla: "LC", provincia: "IMBERSAGO", nazione: "ITALIA" },
  { codeKey: "E288", sigla: "TN", provincia: "IMER", nazione: "ITALIA" },
  { codeKey: "E289", sigla: "BO", provincia: "IMOLA", nazione: "ITALIA" },
  { codeKey: "E290", sigla: "IM", provincia: "IMPERIA", nazione: "ITALIA" },
  { codeKey: "E291", sigla: "FI", provincia: "IMPRUNETA", nazione: "ITALIA" },
  { codeKey: "E292", sigla: "VA", provincia: "INARZO", nazione: "ITALIA" },
  {
    codeKey: "E295",
    sigla: "AT",
    provincia: "INCISA SCAPACCINO",
    nazione: "ITALIA",
  },
  { codeKey: "E297", sigla: "BS", provincia: "INCUDINE", nazione: "ITALIA" },
  { codeKey: "E299", sigla: "VA", provincia: "INDUNO OLONA", nazione: "ITALIA" },
  { codeKey: "E301", sigla: "TO", provincia: "INGRIA", nazione: "ITALIA" },
  { codeKey: "E304", sigla: "VB", provincia: "INTRAGNA", nazione: "ITALIA" },
  { codeKey: "E305", sigla: "LC", provincia: "INTROBIO", nazione: "ITALIA" },
  { codeKey: "E306", sigla: "AO", provincia: "INTROD", nazione: "ITALIA" },
  { codeKey: "E307", sigla: "AQ", provincia: "INTRODACQUA", nazione: "ITALIA" },
  { codeKey: "E308", sigla: "LC", provincia: "INTROZZO", nazione: "ITALIA" },
  { codeKey: "E309", sigla: "CO", provincia: "INVERIGO", nazione: "ITALIA" },
  {
    codeKey: "E310",
    sigla: "PV",
    provincia: "INVERNO E MONTELEONE",
    nazione: "ITALIA",
  },
  { codeKey: "E311", sigla: "TO", provincia: "INVERSO PINASCA", nazione: "ITALIA" },
  { codeKey: "E313", sigla: "MI", provincia: "INVERUNO", nazione: "ITALIA" },
  { codeKey: "E314", sigla: "NO", provincia: "INVORIO", nazione: "ITALIA" },
  { codeKey: "E317", sigla: "MI", provincia: "INZAGO", nazione: "ITALIA" },
  {
    codeKey: "E320",
    sigla: "FE",
    provincia: "JOLANDA DI SAVOIA",
    nazione: "ITALIA",
  },
  { codeKey: "E321", sigla: "VV", provincia: "IONADI", nazione: "ITALIA" },
  { codeKey: "E323", sigla: "NU", provincia: "IRGOLI", nazione: "ITALIA" },
  { codeKey: "E325", sigla: "BS", provincia: "IRMA", nazione: "ITALIA" },
  { codeKey: "E326", sigla: "MT", provincia: "IRSINA", nazione: "ITALIA" },
  { codeKey: "E327", sigla: "CN", provincia: "ISASCA", nazione: "ITALIA" },
  { codeKey: "E328", sigla: "CZ", provincia: "ISCA SULLO IONIO", nazione: "ITALIA" },
  { codeKey: "E329", sigla: "NA", provincia: "ISCHIA", nazione: "ITALIA" },
  { codeKey: "E330", sigla: "VT", provincia: "ISCHIA DI CASTRO", nazione: "ITALIA" },
  { codeKey: "E332", sigla: "FG", provincia: "ISCHITELLA", nazione: "ITALIA" },
  { codeKey: "E333", sigla: "BS", provincia: "ISEO", nazione: "ITALIA" },
  { codeKey: "E334", sigla: "TN", provincia: "ISERA", nazione: "ITALIA" },
  { codeKey: "E335", sigla: "IS", provincia: "ISERNIA", nazione: "ITALIA" },
  { codeKey: "E336", sigla: "NU", provincia: "ISILI", nazione: "ITALIA" },
  { codeKey: "E337", sigla: "PA", provincia: "ISNELLO", nazione: "ITALIA" },
  { codeKey: "E338", sigla: "AT", provincia: "ISOLA D'ASTI", nazione: "ITALIA" },
  {
    codeKey: "E339",
    sigla: "KR",
    provincia: "ISOLA DI CAPO RIZZUTO",
    nazione: "ITALIA",
  },
  { codeKey: "E340", sigla: "FR", provincia: "ISOLA DEL LIRI", nazione: "ITALIA" },
  {
    codeKey: "E341",
    sigla: "GE",
    provincia: "ISOLA DEL CANTONE",
    nazione: "ITALIA",
  },
  { codeKey: "E342", sigla: "SO", provincia: "MADESIMO", nazione: "ITALIA" },
  {
    codeKey: "E343",
    sigla: "TE",
    provincia: "ISOLA DEL GRAN SASSO D'ITALIA",
    nazione: "ITALIA",
  },
  { codeKey: "E345", sigla: "TO", provincia: "ISOLABELLA", nazione: "ITALIA" },
  { codeKey: "E346", sigla: "IM", provincia: "ISOLABONA", nazione: "ITALIA" },
  { codeKey: "E348", sigla: "GR", provincia: "ISOLA DEL GIGLIO", nazione: "ITALIA" },
  {
    codeKey: "E349",
    sigla: "VR",
    provincia: "ISOLA DELLA SCALA",
    nazione: "ITALIA",
  },
  {
    codeKey: "E350",
    sigla: "PA",
    provincia: "ISOLA DELLE FEMMINE",
    nazione: "ITALIA",
  },
  { codeKey: "E351", sigla: "PU", provincia: "ISOLA DEL PIANO", nazione: "ITALIA" },
  { codeKey: "E353", sigla: "BG", provincia: "ISOLA DI FONDRA", nazione: "ITALIA" },
  { codeKey: "E354", sigla: "VI", provincia: "ISOLA VICENTINA", nazione: "ITALIA" },
  { codeKey: "E356", sigla: "CR", provincia: "ISOLA DOVARESE", nazione: "ITALIA" },
  { codeKey: "E358", sigla: "VR", provincia: "ISOLA RIZZA", nazione: "ITALIA" },
  {
    codeKey: "E360",
    sigla: "AL",
    provincia: "ISOLA SANT'ANTONIO",
    nazione: "ITALIA",
  },
  { codeKey: "E363", sigla: "FG", provincia: "ISOLE TREMITI", nazione: "ITALIA" },
  { codeKey: "E364", sigla: "BS", provincia: "ISORELLA", nazione: "ITALIA" },
  { codeKey: "E365", sigla: "SA", provincia: "ISPANI", nazione: "ITALIA" },
  { codeKey: "E366", sigla: "RG", provincia: "ISPICA", nazione: "ITALIA" },
  { codeKey: "E367", sigla: "VA", provincia: "ISPRA", nazione: "ITALIA" },
  { codeKey: "E368", sigla: "TO", provincia: "ISSIGLIO", nazione: "ITALIA" },
  { codeKey: "E369", sigla: "AO", provincia: "ISSIME", nazione: "ITALIA" },
  { codeKey: "E370", sigla: "BG", provincia: "ISSO", nazione: "ITALIA" },
  { codeKey: "E371", sigla: "AO", provincia: "ISSOGNE", nazione: "ITALIA" },
  { codeKey: "E372", sigla: "CH", provincia: "VASTO", nazione: "ITALIA" },
  { codeKey: "E373", sigla: "TV", provincia: "ISTRANA", nazione: "ITALIA" },
  { codeKey: "E374", sigla: "ME", provincia: "ITALA", nazione: "ITALIA" },
  { codeKey: "E375", sigla: "LT", provincia: "ITRI", nazione: "ITALIA" },
  { codeKey: "E376", sigla: "SS", provincia: "ITTIREDDU", nazione: "ITALIA" },
  { codeKey: "E377", sigla: "SS", provincia: "ITTIRI", nazione: "ITALIA" },
  { codeKey: "E378", sigla: "TN", provincia: "IVANO FRACENA", nazione: "ITALIA" },
  { codeKey: "E379", sigla: "TO", provincia: "IVREA", nazione: "ITALIA" },
  { codeKey: "E380", sigla: "CR", provincia: "IZANO", nazione: "ITALIA" },
  { codeKey: "E381", sigla: "CB", provincia: "JELSI", nazione: "ITALIA" },
  { codeKey: "E382", sigla: "RM", provincia: "JENNE", nazione: "ITALIA" },
  { codeKey: "E386", sigla: "VA", provincia: "JERAGO CON ORAGO", nazione: "ITALIA" },
  { codeKey: "E387", sigla: "OG", provincia: "JERZU", nazione: "ITALIA" },
  { codeKey: "E388", sigla: "AN", provincia: "JESI", nazione: "ITALIA" },
  { codeKey: "E389", sigla: "VV", provincia: "JOPPOLO", nazione: "ITALIA" },
  {
    codeKey: "E390",
    sigla: "AG",
    provincia: "JOPPOLO GIANCAXIO",
    nazione: "ITALIA",
  },
  { codeKey: "E391", sigla: "AO", provincia: "JOVENCAN", nazione: "ITALIA" },
  { codeKey: "E392", sigla: "RM", provincia: "LABICO", nazione: "ITALIA" },
  { codeKey: "E393", sigla: "RI", provincia: "LABRO", nazione: "ITALIA" },
  { codeKey: "E394", sigla: "TO", provincia: "LA CASSA", nazione: "ITALIA" },
  { codeKey: "E395", sigla: "MI", provincia: "LACCHIARELLA", nazione: "ITALIA" },
  { codeKey: "E396", sigla: "NA", provincia: "LACCO AMENO", nazione: "ITALIA" },
  { codeKey: "E397", sigla: "AV", provincia: "LACEDONIA", nazione: "ITALIA" },
  { codeKey: "E398", sigla: "BZ", provincia: "LACES", nazione: "ITALIA" },
  { codeKey: "E400", sigla: "NU", provincia: "LACONI", nazione: "ITALIA" },
  { codeKey: "E401", sigla: "SS", provincia: "LAERRU", nazione: "ITALIA" },
  { codeKey: "E402", sigla: "RC", provincia: "LAGANADI", nazione: "ITALIA" },
  { codeKey: "E403", sigla: "VI", provincia: "LAGHI", nazione: "ITALIA" },
  { codeKey: "E405", sigla: "CO", provincia: "LAGLIO", nazione: "ITALIA" },
  { codeKey: "E406", sigla: "CN", provincia: "LAGNASCO", nazione: "ITALIA" },
  { codeKey: "E407", sigla: "CS", provincia: "LAGO", nazione: "ITALIA" },
  { codeKey: "E409", sigla: "PZ", provincia: "LAGONEGRO", nazione: "ITALIA" },
  { codeKey: "E410", sigla: "FE", provincia: "LAGOSANTO", nazione: "ITALIA" },
  { codeKey: "E412", sigla: "BZ", provincia: "LAGUNDO", nazione: "ITALIA" },
  { codeKey: "E413", sigla: "PI", provincia: "LAJATICO", nazione: "ITALIA" },
  { codeKey: "E414", sigla: "SV", provincia: "LAIGUEGLIA", nazione: "ITALIA" },
  { codeKey: "E415", sigla: "MI", provincia: "LAINATE", nazione: "ITALIA" },
  { codeKey: "E416", sigla: "CO", provincia: "LAINO", nazione: "ITALIA" },
  { codeKey: "E417", sigla: "CS", provincia: "LAINO BORGO", nazione: "ITALIA" },
  { codeKey: "E419", sigla: "CS", provincia: "LAINO CASTELLO", nazione: "ITALIA" },
  { codeKey: "E420", sigla: "BZ", provincia: "LAION", nazione: "ITALIA" },
  { codeKey: "E421", sigla: "BZ", provincia: "LAIVES", nazione: "ITALIA" },
  { codeKey: "E422", sigla: "BG", provincia: "LALLIO", nazione: "ITALIA" },
  { codeKey: "E423", sigla: "TO", provincia: "LA LOGGIA", nazione: "ITALIA" },
  { codeKey: "E424", sigla: "CH", provincia: "LAMA DEI PELIGNI", nazione: "ITALIA" },
  { codeKey: "E425", sigla: "OT", provincia: "LA MADDALENA", nazione: "ITALIA" },
  { codeKey: "E426", sigla: "MO", provincia: "LAMA MOCOGNO", nazione: "ITALIA" },
  { codeKey: "E428", sigla: "CO", provincia: "LAMBRUGO", nazione: "ITALIA" },
  { codeKey: "E429", sigla: "BL", provincia: "LAMON", nazione: "ITALIA" },
  { codeKey: "E430", sigla: "CN", provincia: "LA MORRA", nazione: "ITALIA" },
  {
    codeKey: "E431",
    sigla: "AG",
    provincia: "LAMPEDUSA E LINOSA",
    nazione: "ITALIA",
  },
  { codeKey: "E432", sigla: "PT", provincia: "LAMPORECCHIO", nazione: "ITALIA" },
  { codeKey: "E433", sigla: "VC", provincia: "LAMPORO", nazione: "ITALIA" },
  { codeKey: "E434", sigla: "BZ", provincia: "LANA", nazione: "ITALIA" },
  { codeKey: "E435", sigla: "CH", provincia: "LANCIANO", nazione: "ITALIA" },
  { codeKey: "E436", sigla: "NO", provincia: "LANDIONA", nazione: "ITALIA" },
  { codeKey: "E437", sigla: "PV", provincia: "LANDRIANO", nazione: "ITALIA" },
  { codeKey: "E438", sigla: "PR", provincia: "LANGHIRANO", nazione: "ITALIA" },
  { codeKey: "E439", sigla: "PV", provincia: "LANGOSCO", nazione: "ITALIA" },
  { codeKey: "E441", sigla: "OG", provincia: "LANUSEI", nazione: "ITALIA" },
  { codeKey: "E443", sigla: "SO", provincia: "LANZADA", nazione: "ITALIA" },
  { codeKey: "E444", sigla: "CO", provincia: "LANZO D'INTELVI", nazione: "ITALIA" },
  { codeKey: "E445", sigla: "TO", provincia: "LANZO TORINESE", nazione: "ITALIA" },
  { codeKey: "E447", sigla: "FM", provincia: "LAPEDONA", nazione: "ITALIA" },
  { codeKey: "E448", sigla: "AV", provincia: "LAPIO", nazione: "ITALIA" },
  { codeKey: "E450", sigla: "CS", provincia: "LAPPANO", nazione: "ITALIA" },
  { codeKey: "E451", sigla: "PT", provincia: "LARCIANO", nazione: "ITALIA" },
  { codeKey: "E452", sigla: "TN", provincia: "LARDARO", nazione: "ITALIA" },
  { codeKey: "E454", sigla: "PV", provincia: "LARDIRAGO", nazione: "ITALIA" },
  { codeKey: "E456", sigla: "CB", provincia: "LARINO", nazione: "ITALIA" },
  { codeKey: "E457", sigla: "BZ", provincia: "LASA", nazione: "ITALIA" },
  { codeKey: "E458", sigla: "AO", provincia: "LA SALLE", nazione: "ITALIA" },
  { codeKey: "E459", sigla: "PA", provincia: "LASCARI", nazione: "ITALIA" },
  { codeKey: "E461", sigla: "TN", provincia: "LASINO", nazione: "ITALIA" },
  { codeKey: "E462", sigla: "CO", provincia: "LASNIGO", nazione: "ITALIA" },
  { codeKey: "E463", sigla: "SP", provincia: "LA SPEZIA", nazione: "ITALIA" },
  { codeKey: "E464", sigla: "VS", provincia: "LAS PLASSAS", nazione: "ITALIA" },
  { codeKey: "E465", sigla: "VI", provincia: "LASTEBASSE", nazione: "ITALIA" },
  { codeKey: "E466", sigla: "FI", provincia: "LASTRA A SIGNA", nazione: "ITALIA" },
  { codeKey: "E467", sigla: "VT", provincia: "LATERA", nazione: "ITALIA" },
  { codeKey: "E468", sigla: "AR", provincia: "LATERINA", nazione: "ITALIA" },
  { codeKey: "E469", sigla: "TA", provincia: "LATERZA", nazione: "ITALIA" },
  { codeKey: "E470", sigla: "AO", provincia: "LA THUILE", nazione: "ITALIA" },
  { codeKey: "E471", sigla: "BR", provincia: "LATIANO", nazione: "ITALIA" },
  { codeKey: "E472", sigla: "LT", provincia: "LATINA", nazione: "ITALIA" },
  { codeKey: "E473", sigla: "UD", provincia: "LATISANA", nazione: "ITALIA" },
  { codeKey: "E474", sigla: "PZ", provincia: "LATRONICO", nazione: "ITALIA" },
  { codeKey: "E475", sigla: "CS", provincia: "LATTARICO", nazione: "ITALIA" },
  { codeKey: "E476", sigla: "UD", provincia: "LAUCO", nazione: "ITALIA" },
  {
    codeKey: "E479",
    sigla: "RC",
    provincia: "LAUREANA DI BORRELLO",
    nazione: "ITALIA",
  },
  { codeKey: "E480", sigla: "SA", provincia: "LAUREANA CILENTO", nazione: "ITALIA" },
  { codeKey: "E481", sigla: "BZ", provincia: "LAUREGNO", nazione: "ITALIA" },
  { codeKey: "E482", sigla: "PZ", provincia: "LAURENZANA", nazione: "ITALIA" },
  { codeKey: "E483", sigla: "PZ", provincia: "LAURIA", nazione: "ITALIA" },
  { codeKey: "E484", sigla: "TO", provincia: "LAURIANO", nazione: "ITALIA" },
  { codeKey: "E485", sigla: "SA", provincia: "LAURINO", nazione: "ITALIA" },
  { codeKey: "E486", sigla: "SA", provincia: "LAURITO", nazione: "ITALIA" },
  { codeKey: "E487", sigla: "AV", provincia: "LAURO", nazione: "ITALIA" },
  { codeKey: "E488", sigla: "GE", provincia: "LAVAGNA", nazione: "ITALIA" },
  { codeKey: "E489", sigla: "VR", provincia: "LAVAGNO", nazione: "ITALIA" },
  {
    codeKey: "E490",
    sigla: "BL",
    provincia: "LA VALLE AGORDINA",
    nazione: "ITALIA",
  },
  { codeKey: "E491", sigla: "BZ", provincia: "LA VALLE", nazione: "ITALIA" },
  { codeKey: "E492", sigla: "TN", provincia: "LAVARONE", nazione: "ITALIA" },
  { codeKey: "E493", sigla: "PZ", provincia: "LAVELLO", nazione: "ITALIA" },
  {
    codeKey: "E494",
    sigla: "VA",
    provincia: "LAVENA PONTE TRESA",
    nazione: "ITALIA",
  },
  { codeKey: "E496", sigla: "VA", provincia: "LAVENO-MOMBELLO", nazione: "ITALIA" },
  { codeKey: "E497", sigla: "BS", provincia: "LAVENONE", nazione: "ITALIA" },
  { codeKey: "E498", sigla: "SA", provincia: "LAVIANO", nazione: "ITALIA" },
  { codeKey: "E500", sigla: "TN", provincia: "LAVIS", nazione: "ITALIA" },
  { codeKey: "E502", sigla: "VR", provincia: "LAZISE", nazione: "ITALIA" },
  { codeKey: "E504", sigla: "MB", provincia: "LAZZATE", nazione: "ITALIA" },
  { codeKey: "E505", sigla: "AQ", provincia: "LECCE NEI MARSI", nazione: "ITALIA" },
  { codeKey: "E506", sigla: "LE", provincia: "LECCE", nazione: "ITALIA" },
  { codeKey: "E507", sigla: "LC", provincia: "LECCO", nazione: "ITALIA" },
  { codeKey: "E509", sigla: "BG", provincia: "LEFFE", nazione: "ITALIA" },
  { codeKey: "E510", sigla: "VA", provincia: "LEGGIUNO", nazione: "ITALIA" },
  { codeKey: "E512", sigla: "VR", provincia: "LEGNAGO", nazione: "ITALIA" },
  { codeKey: "E514", sigla: "MI", provincia: "LEGNANO", nazione: "ITALIA" },
  { codeKey: "E515", sigla: "PD", provincia: "LEGNARO", nazione: "ITALIA" },
  { codeKey: "E517", sigla: "NU", provincia: "LEI", nazione: "ITALIA" },
  { codeKey: "E518", sigla: "TO", provincia: "LEINI", nazione: "ITALIA" },
  { codeKey: "E519", sigla: "GE", provincia: "LEIVI", nazione: "ITALIA" },
  { codeKey: "E520", sigla: "TO", provincia: "LEMIE", nazione: "ITALIA" },
  { codeKey: "E522", sigla: "RO", provincia: "LENDINARA", nazione: "ITALIA" },
  { codeKey: "E523", sigla: "ME", provincia: "LENI", nazione: "ITALIA" },
  { codeKey: "E524", sigla: "BG", provincia: "LENNA", nazione: "ITALIA" },
  { codeKey: "E526", sigla: "BS", provincia: "LENO", nazione: "ITALIA" },
  { codeKey: "E527", sigla: "LT", provincia: "LENOLA", nazione: "ITALIA" },
  { codeKey: "E528", sigla: "VC", provincia: "LENTA", nazione: "ITALIA" },
  { codeKey: "E529", sigla: "VA", provincia: "OSMATE", nazione: "ITALIA" },
  {
    codeKey: "E530",
    sigla: "MB",
    provincia: "LENTATE SUL SEVESO",
    nazione: "ITALIA",
  },
  { codeKey: "E531", sigla: "CH", provincia: "LENTELLA", nazione: "ITALIA" },
  { codeKey: "E532", sigla: "SR", provincia: "LENTINI", nazione: "ITALIA" },
  { codeKey: "E535", sigla: "RI", provincia: "LEONESSA", nazione: "ITALIA" },
  { codeKey: "E536", sigla: "EN", provincia: "LEONFORTE", nazione: "ITALIA" },
  { codeKey: "E537", sigla: "TA", provincia: "LEPORANO", nazione: "ITALIA" },
  { codeKey: "E538", sigla: "LE", provincia: "LEQUILE", nazione: "ITALIA" },
  { codeKey: "E539", sigla: "CN", provincia: "LEQUIO TANARO", nazione: "ITALIA" },
  { codeKey: "E540", sigla: "CN", provincia: "LEQUIO BERRIA", nazione: "ITALIA" },
  { codeKey: "E541", sigla: "PA", provincia: "LERCARA FRIDDI", nazione: "ITALIA" },
  { codeKey: "E542", sigla: "SP", provincia: "LERICI", nazione: "ITALIA" },
  { codeKey: "E543", sigla: "AL", provincia: "LERMA", nazione: "ITALIA" },
  { codeKey: "E544", sigla: "NO", provincia: "LESA", nazione: "ITALIA" },
  { codeKey: "E546", sigla: "CN", provincia: "LESEGNO", nazione: "ITALIA" },
  {
    codeKey: "E547",
    sigla: "PR",
    provincia: "LESIGNANO DE' BAGNI",
    nazione: "ITALIA",
  },
  { codeKey: "E548", sigla: "PR", provincia: "TERENZO", nazione: "ITALIA" },
  { codeKey: "E549", sigla: "FG", provincia: "LESINA", nazione: "ITALIA" },
  { codeKey: "E550", sigla: "MB", provincia: "LESMO", nazione: "ITALIA" },
  { codeKey: "E551", sigla: "TO", provincia: "LESSOLO", nazione: "ITALIA" },
  { codeKey: "E552", sigla: "BI", provincia: "LESSONA", nazione: "ITALIA" },
  { codeKey: "E553", sigla: "UD", provincia: "LESTIZZA", nazione: "ITALIA" },
  { codeKey: "E554", sigla: "CE", provincia: "LETINO", nazione: "ITALIA" },
  { codeKey: "E555", sigla: "ME", provincia: "LETOJANNI", nazione: "ITALIA" },
  { codeKey: "E557", sigla: "NA", provincia: "LETTERE", nazione: "ITALIA" },
  { codeKey: "E558", sigla: "PE", provincia: "LETTOMANOPPELLO", nazione: "ITALIA" },
  { codeKey: "E559", sigla: "CH", provincia: "LETTOPALENA", nazione: "ITALIA" },
  { codeKey: "E560", sigla: "SP", provincia: "LEVANTO", nazione: "ITALIA" },
  { codeKey: "E562", sigla: "BG", provincia: "LEVATE", nazione: "ITALIA" },
  { codeKey: "E563", sigla: "LE", provincia: "LEVERANO", nazione: "ITALIA" },
  { codeKey: "E564", sigla: "CN", provincia: "LEVICE", nazione: "ITALIA" },
  { codeKey: "E565", sigla: "TN", provincia: "LEVICO TERME", nazione: "ITALIA" },
  { codeKey: "E566", sigla: "TO", provincia: "LEVONE", nazione: "ITALIA" },
  { codeKey: "E569", sigla: "CO", provincia: "LEZZENO", nazione: "ITALIA" },
  { codeKey: "E570", sigla: "CE", provincia: "LIBERI", nazione: "ITALIA" },
  { codeKey: "E571", sigla: "ME", provincia: "LIBRIZZI", nazione: "ITALIA" },
  { codeKey: "E573", sigla: "AG", provincia: "LICATA", nazione: "ITALIA" },
  { codeKey: "E574", sigla: "MS", provincia: "LICCIANA NARDI", nazione: "ITALIA" },
  { codeKey: "E576", sigla: "RM", provincia: "LICENZA", nazione: "ITALIA" },
  { codeKey: "E578", sigla: "CT", provincia: "LICODIA EUBEA", nazione: "ITALIA" },
  { codeKey: "E581", sigla: "LC", provincia: "LIERNA", nazione: "ITALIA" },
  { codeKey: "E583", sigla: "VC", provincia: "LIGNANA", nazione: "ITALIA" },
  {
    codeKey: "E584",
    sigla: "UD",
    provincia: "LIGNANO SABBIADORO",
    nazione: "ITALIA",
  },
  { codeKey: "E585", sigla: "RE", provincia: "LIGONCHIO", nazione: "ITALIA" },
  { codeKey: "E586", sigla: "UD", provincia: "LIGOSULLO", nazione: "ITALIA" },
  { codeKey: "E587", sigla: "AO", provincia: "LILLIANES", nazione: "ITALIA" },
  { codeKey: "E588", sigla: "BL", provincia: "LIMANA", nazione: "ITALIA" },
  { codeKey: "E589", sigla: "BN", provincia: "LIMATOLA", nazione: "ITALIA" },
  { codeKey: "E590", sigla: "VV", provincia: "LIMBADI", nazione: "ITALIA" },
  { codeKey: "E591", sigla: "MB", provincia: "LIMBIATE", nazione: "ITALIA" },
  { codeKey: "E592", sigla: "PD", provincia: "LIMENA", nazione: "ITALIA" },
  { codeKey: "E593", sigla: "CO", provincia: "LIMIDO COMASCO", nazione: "ITALIA" },
  { codeKey: "E594", sigla: "ME", provincia: "LIMINA", nazione: "ITALIA" },
  { codeKey: "E596", sigla: "BS", provincia: "LIMONE SUL GARDA", nazione: "ITALIA" },
  { codeKey: "E597", sigla: "CN", provincia: "LIMONE PIEMONTE", nazione: "ITALIA" },
  { codeKey: "E599", sigla: "CB", provincia: "LIMOSANO", nazione: "ITALIA" },
  { codeKey: "E600", sigla: "PV", provincia: "LINAROLO", nazione: "ITALIA" },
  { codeKey: "E602", sigla: "CT", provincia: "LINGUAGLOSSA", nazione: "ITALIA" },
  { codeKey: "E605", sigla: "AV", provincia: "LIONI", nazione: "ITALIA" },
  { codeKey: "E606", sigla: "ME", provincia: "LIPARI", nazione: "ITALIA" },
  { codeKey: "E607", sigla: "CO", provincia: "LIPOMO", nazione: "ITALIA" },
  { codeKey: "E608", sigla: "PV", provincia: "LIRIO", nazione: "ITALIA" },
  { codeKey: "E610", sigla: "MI", provincia: "LISCATE", nazione: "ITALIA" },
  { codeKey: "E611", sigla: "CH", provincia: "LISCIA", nazione: "ITALIA" },
  { codeKey: "E613", sigla: "PG", provincia: "LISCIANO NICCONE", nazione: "ITALIA" },
  { codeKey: "E614", sigla: "TN", provincia: "LISIGNAGO", nazione: "ITALIA" },
  { codeKey: "E615", sigla: "CN", provincia: "LISIO", nazione: "ITALIA" },
  { codeKey: "E617", sigla: "MB", provincia: "LISSONE", nazione: "ITALIA" },
  { codeKey: "E618", sigla: "CL", provincia: "MILENA", nazione: "ITALIA" },
  { codeKey: "E620", sigla: "NA", provincia: "LIVERI", nazione: "ITALIA" },
  { codeKey: "E621", sigla: "SO", provincia: "LIVIGNO", nazione: "ITALIA" },
  {
    codeKey: "E622",
    sigla: "BL",
    provincia: "LIVINALLONGO DEL COL DI LANA",
    nazione: "ITALIA",
  },
  { codeKey: "E623", sigla: "CO", provincia: "LIVO", nazione: "ITALIA" },
  { codeKey: "E624", sigla: "TN", provincia: "LIVO", nazione: "ITALIA" },
  { codeKey: "E625", sigla: "LI", provincia: "LIVORNO", nazione: "ITALIA" },
  { codeKey: "E626", sigla: "VC", provincia: "LIVORNO FERRARIS", nazione: "ITALIA" },
  { codeKey: "E627", sigla: "LO", provincia: "LIVRAGA", nazione: "ITALIA" },
  { codeKey: "E629", sigla: "LE", provincia: "LIZZANELLO", nazione: "ITALIA" },
  { codeKey: "E630", sigla: "TA", provincia: "LIZZANO", nazione: "ITALIA" },
  { codeKey: "E632", sigla: "SV", provincia: "LOANO", nazione: "ITALIA" },
  { codeKey: "E633", sigla: "AT", provincia: "LOAZZOLO", nazione: "ITALIA" },
  { codeKey: "E635", sigla: "TO", provincia: "LOCANA", nazione: "ITALIA" },
  { codeKey: "E638", sigla: "CO", provincia: "LOCATE VARESINO", nazione: "ITALIA" },
  {
    codeKey: "E639",
    sigla: "MI",
    provincia: "LOCATE DI TRIULZI",
    nazione: "ITALIA",
  },
  { codeKey: "E640", sigla: "BG", provincia: "LOCATELLO", nazione: "ITALIA" },
  { codeKey: "E644", sigla: "OG", provincia: "LOCERI", nazione: "ITALIA" },
  { codeKey: "E645", sigla: "BA", provincia: "LOCOROTONDO", nazione: "ITALIA" },
  { codeKey: "E646", sigla: "NU", provincia: "LOCULI", nazione: "ITALIA" },
  { codeKey: "E647", sigla: "NU", provincia: "LODE'", nazione: "ITALIA" },
  { codeKey: "E648", sigla: "LO", provincia: "LODI", nazione: "ITALIA" },
  { codeKey: "E649", sigla: "NU", provincia: "LODINE", nazione: "ITALIA" },
  { codeKey: "E651", sigla: "LO", provincia: "LODI VECCHIO", nazione: "ITALIA" },
  { codeKey: "E652", sigla: "BS", provincia: "LODRINO", nazione: "ITALIA" },
  { codeKey: "E654", sigla: "BS", provincia: "LOGRATO", nazione: "ITALIA" },
  { codeKey: "E655", sigla: "BO", provincia: "LOIANO", nazione: "ITALIA" },
  { codeKey: "E656", sigla: "LC", provincia: "LOMAGNA", nazione: "ITALIA" },
  { codeKey: "E659", sigla: "CO", provincia: "LOMAZZO", nazione: "ITALIA" },
  { codeKey: "E660", sigla: "TO", provincia: "LOMBARDORE", nazione: "ITALIA" },
  { codeKey: "E661", sigla: "TO", provincia: "LOMBRIASCO", nazione: "ITALIA" },
  { codeKey: "E662", sigla: "PV", provincia: "LOMELLO", nazione: "ITALIA" },
  { codeKey: "E664", sigla: "TN", provincia: "LONA LASES", nazione: "ITALIA" },
  { codeKey: "E665", sigla: "VA", provincia: "LONATE CEPPINO", nazione: "ITALIA" },
  { codeKey: "E666", sigla: "VA", provincia: "LONATE POZZOLO", nazione: "ITALIA" },
  { codeKey: "E667", sigla: "BS", provincia: "LONATO", nazione: "ITALIA" },
  { codeKey: "E668", sigla: "FI", provincia: "LONDA", nazione: "ITALIA" },
  { codeKey: "E669", sigla: "IS", provincia: "LONGANO", nazione: "ITALIA" },
  { codeKey: "E671", sigla: "VI", provincia: "LONGARE", nazione: "ITALIA" },
  { codeKey: "E673", sigla: "BS", provincia: "LONGHENA", nazione: "ITALIA" },
  { codeKey: "E674", sigla: "ME", provincia: "LONGI", nazione: "ITALIA" },
  { codeKey: "E675", sigla: "FC", provincia: "LONGIANO", nazione: "ITALIA" },
  { codeKey: "E677", sigla: "CS", provincia: "LONGOBARDI", nazione: "ITALIA" },
  { codeKey: "E678", sigla: "CS", provincia: "LONGOBUCCO", nazione: "ITALIA" },
  {
    codeKey: "E679",
    sigla: "CO",
    provincia: "LONGONE AL SEGRINO",
    nazione: "ITALIA",
  },
  { codeKey: "E680", sigla: "LI", provincia: "PORTO AZZURRO", nazione: "ITALIA" },
  { codeKey: "E681", sigla: "RI", provincia: "LONGONE SABINO", nazione: "ITALIA" },
  { codeKey: "E682", sigla: "VI", provincia: "LONIGO", nazione: "ITALIA" },
  { codeKey: "E683", sigla: "TO", provincia: "LORANZE'", nazione: "ITALIA" },
  { codeKey: "E684", sigla: "PD", provincia: "LOREGGIA", nazione: "ITALIA" },
  { codeKey: "E685", sigla: "VB", provincia: "LOREGLIA", nazione: "ITALIA" },
  {
    codeKey: "E687",
    sigla: "BL",
    provincia: "LORENZAGO DI CADORE",
    nazione: "ITALIA",
  },
  { codeKey: "E689", sigla: "RO", provincia: "LOREO", nazione: "ITALIA" },
  { codeKey: "E690", sigla: "AN", provincia: "LORETO", nazione: "ITALIA" },
  { codeKey: "E691", sigla: "PE", provincia: "LORETO APRUTINO", nazione: "ITALIA" },
  { codeKey: "E692", sigla: "TV", provincia: "LORIA", nazione: "ITALIA" },
  { codeKey: "E693", sigla: "AR", provincia: "LORO CIUFFENNA", nazione: "ITALIA" },
  { codeKey: "E694", sigla: "MC", provincia: "LORO PICENO", nazione: "ITALIA" },
  { codeKey: "E695", sigla: "GE", provincia: "LORSICA", nazione: "ITALIA" },
  { codeKey: "E698", sigla: "BS", provincia: "LOSINE", nazione: "ITALIA" },
  { codeKey: "E700", sigla: "OG", provincia: "LOTZORAI", nazione: "ITALIA" },
  { codeKey: "E704", sigla: "BG", provincia: "LOVERE", nazione: "ITALIA" },
  { codeKey: "E705", sigla: "SO", provincia: "LOVERO", nazione: "ITALIA" },
  { codeKey: "E706", sigla: "BS", provincia: "LOZIO", nazione: "ITALIA" },
  { codeKey: "E707", sigla: "VA", provincia: "LOZZA", nazione: "ITALIA" },
  { codeKey: "E708", sigla: "BL", provincia: "LOZZO DI CADORE", nazione: "ITALIA" },
  { codeKey: "E709", sigla: "PD", provincia: "LOZZO ATESTINO", nazione: "ITALIA" },
  { codeKey: "E711", sigla: "VC", provincia: "LOZZOLO", nazione: "ITALIA" },
  { codeKey: "E712", sigla: "AL", provincia: "LU", nazione: "ITALIA" },
  { codeKey: "E713", sigla: "VT", provincia: "LUBRIANO", nazione: "ITALIA" },
  { codeKey: "E714", sigla: "AG", provincia: "LUCCA SICULA", nazione: "ITALIA" },
  { codeKey: "E715", sigla: "LU", provincia: "LUCCA", nazione: "ITALIA" },
  { codeKey: "E716", sigla: "FG", provincia: "LUCERA", nazione: "ITALIA" },
  { codeKey: "E718", sigla: "AR", provincia: "LUCIGNANO", nazione: "ITALIA" },
  { codeKey: "E719", sigla: "IM", provincia: "LUCINASCO", nazione: "ITALIA" },
  { codeKey: "E722", sigla: "CB", provincia: "LUCITO", nazione: "ITALIA" },
  { codeKey: "E723", sigla: "AQ", provincia: "LUCO DEI MARSI", nazione: "ITALIA" },
  { codeKey: "E724", sigla: "AQ", provincia: "LUCOLI", nazione: "ITALIA" },
  {
    codeKey: "E726",
    sigla: "PC",
    provincia: "LUGAGNANO VAL D'ARDA",
    nazione: "ITALIA",
  },
  { codeKey: "E727", sigla: "TO", provincia: "LUGNACCO", nazione: "ITALIA" },
  {
    codeKey: "E729",
    sigla: "TR",
    provincia: "LUGNANO IN TEVERINA",
    nazione: "ITALIA",
  },
  { codeKey: "E730", sigla: "RA", provincia: "LUGO", nazione: "ITALIA" },
  { codeKey: "E731", sigla: "VI", provincia: "LUGO DI VICENZA", nazione: "ITALIA" },
  { codeKey: "E734", sigla: "VA", provincia: "LUINO", nazione: "ITALIA" },
  { codeKey: "E735", sigla: "CO", provincia: "LUISAGO", nazione: "ITALIA" },
  { codeKey: "E736", sigla: "NU", provincia: "LULA", nazione: "ITALIA" },
  { codeKey: "E737", sigla: "GE", provincia: "LUMARZO", nazione: "ITALIA" },
  { codeKey: "E738", sigla: "BS", provincia: "LUMEZZANE", nazione: "ITALIA" },
  { codeKey: "E742", sigla: "VS", provincia: "LUNAMATRONA", nazione: "ITALIA" },
  { codeKey: "E743", sigla: "PU", provincia: "LUNANO", nazione: "ITALIA" },
  { codeKey: "E745", sigla: "CS", provincia: "LUNGRO", nazione: "ITALIA" },
  { codeKey: "E746", sigla: "AV", provincia: "LUOGOSANO", nazione: "ITALIA" },
  { codeKey: "E747", sigla: "OT", provincia: "LUOGOSANTO", nazione: "ITALIA" },
  { codeKey: "E748", sigla: "CB", provincia: "LUPARA", nazione: "ITALIA" },
  { codeKey: "E749", sigla: "CO", provincia: "LURAGO D'ERBA", nazione: "ITALIA" },
  { codeKey: "E750", sigla: "CO", provincia: "LURAGO MARINONE", nazione: "ITALIA" },
  { codeKey: "E751", sigla: "BG", provincia: "LURANO", nazione: "ITALIA" },
  { codeKey: "E752", sigla: "OT", provincia: "LURAS", nazione: "ITALIA" },
  { codeKey: "E753", sigla: "CO", provincia: "LURATE CACCIVIO", nazione: "ITALIA" },
  { codeKey: "E754", sigla: "CE", provincia: "LUSCIANO", nazione: "ITALIA" },
  { codeKey: "E757", sigla: "TN", provincia: "LUSERNA", nazione: "ITALIA" },
  {
    codeKey: "E758",
    sigla: "TO",
    provincia: "LUSERNA SAN GIOVANNI",
    nazione: "ITALIA",
  },
  { codeKey: "E759", sigla: "TO", provincia: "LUSERNETTA", nazione: "ITALIA" },
  { codeKey: "E760", sigla: "UD", provincia: "LUSEVERA", nazione: "ITALIA" },
  { codeKey: "E761", sigla: "RO", provincia: "LUSIA", nazione: "ITALIA" },
  { codeKey: "E762", sigla: "VI", provincia: "LUSIANA", nazione: "ITALIA" },
  { codeKey: "E763", sigla: "TO", provincia: "LUSIGLIE'", nazione: "ITALIA" },
  { codeKey: "E764", sigla: "BZ", provincia: "LUSON", nazione: "ITALIA" },
  { codeKey: "E767", sigla: "SA", provincia: "LUSTRA", nazione: "ITALIA" },
  { codeKey: "E769", sigla: "VA", provincia: "LUVINATE", nazione: "ITALIA" },
  { codeKey: "E770", sigla: "BG", provincia: "LUZZANA", nazione: "ITALIA" },
  { codeKey: "E772", sigla: "RE", provincia: "LUZZARA", nazione: "ITALIA" },
  { codeKey: "E773", sigla: "CS", provincia: "LUZZI", nazione: "ITALIA" },
  { codeKey: "E777", sigla: "LO", provincia: "MACCASTORNA", nazione: "ITALIA" },
  {
    codeKey: "E778",
    sigla: "IS",
    provincia: "MACCHIA D'ISERNIA",
    nazione: "ITALIA",
  },
  { codeKey: "E779", sigla: "IS", provincia: "MACCHIAGODENA", nazione: "ITALIA" },
  {
    codeKey: "E780",
    sigla: "CB",
    provincia: "MACCHIA VALFORTORE",
    nazione: "ITALIA",
  },
  { codeKey: "E782", sigla: "TO", provincia: "MACELLO", nazione: "ITALIA" },
  { codeKey: "E783", sigla: "MC", provincia: "MACERATA", nazione: "ITALIA" },
  {
    codeKey: "E784",
    sigla: "CE",
    provincia: "MACERATA CAMPANIA",
    nazione: "ITALIA",
  },
  { codeKey: "E785", sigla: "PU", provincia: "MACERATA FELTRIA", nazione: "ITALIA" },
  { codeKey: "E786", sigla: "MB", provincia: "MACHERIO", nazione: "ITALIA" },
  { codeKey: "E787", sigla: "BS", provincia: "MACLODIO", nazione: "ITALIA" },
  { codeKey: "E788", sigla: "NU", provincia: "MACOMER", nazione: "ITALIA" },
  { codeKey: "E789", sigla: "CN", provincia: "MACRA", nazione: "ITALIA" },
  { codeKey: "E790", sigla: "VB", provincia: "MACUGNAGA", nazione: "ITALIA" },
  { codeKey: "E791", sigla: "CE", provincia: "MADDALONI", nazione: "ITALIA" },
  { codeKey: "E793", sigla: "CR", provincia: "MADIGNANO", nazione: "ITALIA" },
  { codeKey: "E794", sigla: "BG", provincia: "MADONE", nazione: "ITALIA" },
  {
    codeKey: "E795",
    sigla: "VB",
    provincia: "MADONNA DEL SASSO",
    nazione: "ITALIA",
  },
  { codeKey: "E798", sigla: "LT", provincia: "MAENZA", nazione: "ITALIA" },
  { codeKey: "E799", sigla: "CB", provincia: "MAFALDA", nazione: "ITALIA" },
  { codeKey: "E800", sigla: "BS", provincia: "MAGASA", nazione: "ITALIA" },
  { codeKey: "E801", sigla: "MI", provincia: "MAGENTA", nazione: "ITALIA" },
  { codeKey: "E803", sigla: "NO", provincia: "MAGGIORA", nazione: "ITALIA" },
  { codeKey: "E804", sigla: "PV", provincia: "MAGHERNO", nazione: "ITALIA" },
  { codeKey: "E805", sigla: "PG", provincia: "MAGIONE", nazione: "ITALIA" },
  { codeKey: "E806", sigla: "CZ", provincia: "MAGISANO", nazione: "ITALIA" },
  {
    codeKey: "E807",
    sigla: "FM",
    provincia: "MAGLIANO DI TENNA",
    nazione: "ITALIA",
  },
  { codeKey: "E808", sigla: "CN", provincia: "MAGLIANO ALPI", nazione: "ITALIA" },
  { codeKey: "E809", sigla: "CN", provincia: "MAGLIANO ALFIERI", nazione: "ITALIA" },
  {
    codeKey: "E810",
    sigla: "GR",
    provincia: "MAGLIANO IN TOSCANA",
    nazione: "ITALIA",
  },
  {
    codeKey: "E811",
    sigla: "AQ",
    provincia: "MAGLIANO DE' MARSI",
    nazione: "ITALIA",
  },
  { codeKey: "E812", sigla: "RI", provincia: "MAGLIANO SABINA", nazione: "ITALIA" },
  { codeKey: "E813", sigla: "RM", provincia: "MAGLIANO ROMANO", nazione: "ITALIA" },
  { codeKey: "E814", sigla: "SA", provincia: "MAGLIANO VETERE", nazione: "ITALIA" },
  { codeKey: "E815", sigla: "LE", provincia: "MAGLIE", nazione: "ITALIA" },
  { codeKey: "E816", sigla: "SV", provincia: "MAGLIOLO", nazione: "ITALIA" },
  { codeKey: "E817", sigla: "TO", provincia: "MAGLIONE", nazione: "ITALIA" },
  { codeKey: "E818", sigla: "MN", provincia: "MAGNACAVALLO", nazione: "ITALIA" },
  { codeKey: "E819", sigla: "MI", provincia: "MAGNAGO", nazione: "ITALIA" },
  {
    codeKey: "E820",
    sigla: "UD",
    provincia: "MAGNANO IN RIVIERA",
    nazione: "ITALIA",
  },
  { codeKey: "E821", sigla: "BI", provincia: "MAGNANO", nazione: "ITALIA" },
  { codeKey: "E825", sigla: "NU", provincia: "MAGOMADAS", nazione: "ITALIA" },
  {
    codeKey: "E829",
    sigla: "BZ",
    provincia: "MAGRE' SULLA STRADA DEL VINO",
    nazione: "ITALIA",
  },
  { codeKey: "E830", sigla: "CO", provincia: "MAGREGLIO", nazione: "ITALIA" },
  { codeKey: "E833", sigla: "UD", provincia: "MAJANO", nazione: "ITALIA" },
  { codeKey: "E834", sigla: "CZ", provincia: "MAIDA", nazione: "ITALIA" },
  { codeKey: "E835", sigla: "CS", provincia: "MAIERA'", nazione: "ITALIA" },
  { codeKey: "E836", sigla: "VV", provincia: "MAIERATO", nazione: "ITALIA" },
  {
    codeKey: "E837",
    sigla: "AN",
    provincia: "MAIOLATI SPONTINI",
    nazione: "ITALIA",
  },
  { codeKey: "E838", sigla: "RN", provincia: "MAIOLO", nazione: "ITALIA" },
  { codeKey: "E839", sigla: "SA", provincia: "MAIORI", nazione: "ITALIA" },
  { codeKey: "E840", sigla: "LO", provincia: "MAIRAGO", nazione: "ITALIA" },
  { codeKey: "E841", sigla: "BS", provincia: "MAIRANO", nazione: "ITALIA" },
  { codeKey: "E842", sigla: "SP", provincia: "MAISSANA", nazione: "ITALIA" },
  { codeKey: "E843", sigla: "CR", provincia: "MALAGNINO", nazione: "ITALIA" },
  { codeKey: "E844", sigla: "BO", provincia: "MALALBERGO", nazione: "ITALIA" },
  {
    codeKey: "E847",
    sigla: "UD",
    provincia: "MALBORGHETTO-VALBRUNA",
    nazione: "ITALIA",
  },
  { codeKey: "E848", sigla: "VR", provincia: "MALCESINE", nazione: "ITALIA" },
  { codeKey: "E850", sigla: "TN", provincia: "MALE'", nazione: "ITALIA" },
  { codeKey: "E851", sigla: "BS", provincia: "MALEGNO", nazione: "ITALIA" },
  { codeKey: "E852", sigla: "LO", provincia: "MALEO", nazione: "ITALIA" },
  { codeKey: "E853", sigla: "VB", provincia: "MALESCO", nazione: "ITALIA" },
  { codeKey: "E854", sigla: "CT", provincia: "MALETTO", nazione: "ITALIA" },
  { codeKey: "E855", sigla: "ME", provincia: "MALFA", nazione: "ITALIA" },
  { codeKey: "E856", sigla: "VA", provincia: "MALGESSO", nazione: "ITALIA" },
  { codeKey: "E858", sigla: "LC", provincia: "MALGRATE", nazione: "ITALIA" },
  { codeKey: "E859", sigla: "CS", provincia: "MALITO", nazione: "ITALIA" },
  { codeKey: "E860", sigla: "SV", provincia: "MALLARE", nazione: "ITALIA" },
  { codeKey: "E862", sigla: "BZ", provincia: "MALLES VENOSTA", nazione: "ITALIA" },
  { codeKey: "E863", sigla: "VA", provincia: "MALNATE", nazione: "ITALIA" },
  { codeKey: "E864", sigla: "VI", provincia: "MALO", nazione: "ITALIA" },
  { codeKey: "E865", sigla: "BS", provincia: "MALONNO", nazione: "ITALIA" },
  { codeKey: "E866", sigla: "TN", provincia: "MALOSCO", nazione: "ITALIA" },
  { codeKey: "E868", sigla: "AP", provincia: "MALTIGNANO", nazione: "ITALIA" },
  { codeKey: "E869", sigla: "ME", provincia: "MALVAGNA", nazione: "ITALIA" },
  { codeKey: "E870", sigla: "AL", provincia: "MALVICINO", nazione: "ITALIA" },
  { codeKey: "E872", sigla: "CS", provincia: "MALVITO", nazione: "ITALIA" },
  { codeKey: "E873", sigla: "RC", provincia: "MAMMOLA", nazione: "ITALIA" },
  { codeKey: "E874", sigla: "NU", provincia: "MAMOIADA", nazione: "ITALIA" },
  { codeKey: "E875", sigla: "GR", provincia: "MANCIANO", nazione: "ITALIA" },
  { codeKey: "E876", sigla: "ME", provincia: "MANDANICI", nazione: "ITALIA" },
  { codeKey: "E877", sigla: "CA", provincia: "MANDAS", nazione: "ITALIA" },
  { codeKey: "E878", sigla: "CS", provincia: "MANDATORICCIO", nazione: "ITALIA" },
  {
    codeKey: "E879",
    sigla: "LC",
    provincia: "MANDELLO DEL LARIO",
    nazione: "ITALIA",
  },
  { codeKey: "E880", sigla: "NO", provincia: "MANDELLO VITTA", nazione: "ITALIA" },
  { codeKey: "E882", sigla: "TA", provincia: "MANDURIA", nazione: "ITALIA" },
  {
    codeKey: "E883",
    sigla: "BS",
    provincia: "MANERBA DEL GARDA",
    nazione: "ITALIA",
  },
  { codeKey: "E884", sigla: "BS", provincia: "MANERBIO", nazione: "ITALIA" },
  { codeKey: "E885", sigla: "FG", provincia: "MANFREDONIA", nazione: "ITALIA" },
  { codeKey: "E887", sigla: "CN", provincia: "MANGO", nazione: "ITALIA" },
  { codeKey: "E888", sigla: "CS", provincia: "MANGONE", nazione: "ITALIA" },
  { codeKey: "E889", sigla: "PN", provincia: "MANIAGO", nazione: "ITALIA" },
  { codeKey: "E891", sigla: "AV", provincia: "MANOCALZATI", nazione: "ITALIA" },
  { codeKey: "E892", sigla: "PE", provincia: "MANOPPELLO", nazione: "ITALIA" },
  { codeKey: "E893", sigla: "TV", provincia: "MANSUE'", nazione: "ITALIA" },
  { codeKey: "E894", sigla: "CN", provincia: "MANTA", nazione: "ITALIA" },
  { codeKey: "E896", sigla: "SO", provincia: "MANTELLO", nazione: "ITALIA" },
  { codeKey: "E897", sigla: "MN", provincia: "MANTOVA", nazione: "ITALIA" },
  { codeKey: "E899", sigla: "UD", provincia: "MANZANO", nazione: "ITALIA" },
  { codeKey: "E900", sigla: "RM", provincia: "MANZIANA", nazione: "ITALIA" },
  { codeKey: "E901", sigla: "BG", provincia: "MAPELLO", nazione: "ITALIA" },
  { codeKey: "E902", sigla: "SS", provincia: "MARA", nazione: "ITALIA" },
  { codeKey: "E903", sigla: "CA", provincia: "MARACALAGONIS", nazione: "ITALIA" },
  { codeKey: "E904", sigla: "MO", provincia: "MARANELLO", nazione: "ITALIA" },
  {
    codeKey: "E905",
    sigla: "MO",
    provincia: "MARANO SUL PANARO",
    nazione: "ITALIA",
  },
  { codeKey: "E906", sigla: "NA", provincia: "MARANO DI NAPOLI", nazione: "ITALIA" },
  { codeKey: "E907", sigla: "NO", provincia: "MARANO TICINO", nazione: "ITALIA" },
  { codeKey: "E908", sigla: "RM", provincia: "MARANO EQUO", nazione: "ITALIA" },
  { codeKey: "E910", sigla: "UD", provincia: "MARANO LAGUNARE", nazione: "ITALIA" },
  {
    codeKey: "E911",
    sigla: "VR",
    provincia: "MARANO DI VALPOLICELLA",
    nazione: "ITALIA",
  },
  { codeKey: "E912", sigla: "VI", provincia: "MARANO VICENTINO", nazione: "ITALIA" },
  {
    codeKey: "E914",
    sigla: "CS",
    provincia: "MARANO MARCHESATO",
    nazione: "ITALIA",
  },
  {
    codeKey: "E915",
    sigla: "CS",
    provincia: "MARANO PRINCIPATO",
    nazione: "ITALIA",
  },
  { codeKey: "E917", sigla: "AT", provincia: "MARANZANA", nazione: "ITALIA" },
  { codeKey: "E919", sigla: "PZ", provincia: "MARATEA", nazione: "ITALIA" },
  {
    codeKey: "E921",
    sigla: "MI",
    provincia: "MARCALLO CON CASONE",
    nazione: "ITALIA",
  },
  { codeKey: "E922", sigla: "MN", provincia: "MARCARIA", nazione: "ITALIA" },
  { codeKey: "E923", sigla: "CZ", provincia: "MARCEDUSA", nazione: "ITALIA" },
  { codeKey: "E924", sigla: "RM", provincia: "MARCELLINA", nazione: "ITALIA" },
  { codeKey: "E925", sigla: "CZ", provincia: "MARCELLINARA", nazione: "ITALIA" },
  { codeKey: "E927", sigla: "RI", provincia: "MARCETELLI", nazione: "ITALIA" },
  { codeKey: "E928", sigla: "BS", provincia: "MARCHENO", nazione: "ITALIA" },
  { codeKey: "E929", sigla: "VA", provincia: "MARCHIROLO", nazione: "ITALIA" },
  { codeKey: "E930", sigla: "LI", provincia: "MARCIANA", nazione: "ITALIA" },
  { codeKey: "E931", sigla: "LI", provincia: "MARCIANA MARINA", nazione: "ITALIA" },
  { codeKey: "E932", sigla: "CE", provincia: "MARCIANISE", nazione: "ITALIA" },
  {
    codeKey: "E933",
    sigla: "AR",
    provincia: "MARCIANO DELLA CHIANA",
    nazione: "ITALIA",
  },
  { codeKey: "E934", sigla: "PV", provincia: "MARCIGNAGO", nazione: "ITALIA" },
  { codeKey: "E936", sigla: "VE", provincia: "MARCON", nazione: "ITALIA" },
  { codeKey: "E938", sigla: "BZ", provincia: "MAREBBE", nazione: "ITALIA" },
  { codeKey: "E939", sigla: "CN", provincia: "MARENE", nazione: "ITALIA" },
  { codeKey: "E940", sigla: "TV", provincia: "MARENO DI PIAVE", nazione: "ITALIA" },
  { codeKey: "E941", sigla: "TO", provincia: "MARENTINO", nazione: "ITALIA" },
  { codeKey: "E944", sigla: "AT", provincia: "MARETTO", nazione: "ITALIA" },
  { codeKey: "E945", sigla: "CN", provincia: "MARGARITA", nazione: "ITALIA" },
  {
    codeKey: "E946",
    sigla: "BT",
    provincia: "MARGHERITA DI SAVOIA",
    nazione: "ITALIA",
  },
  { codeKey: "E947", sigla: "LC", provincia: "MARGNO", nazione: "ITALIA" },
  {
    codeKey: "E949",
    sigla: "MN",
    provincia: "MARIANA MANTOVANA",
    nazione: "ITALIA",
  },
  { codeKey: "E951", sigla: "CO", provincia: "MARIANO COMENSE", nazione: "ITALIA" },
  {
    codeKey: "E952",
    sigla: "GO",
    provincia: "MARIANO DEL FRIULI",
    nazione: "ITALIA",
  },
  { codeKey: "E953", sigla: "CL", provincia: "MARIANOPOLI", nazione: "ITALIA" },
  { codeKey: "E954", sigla: "NA", provincia: "MARIGLIANELLA", nazione: "ITALIA" },
  { codeKey: "E955", sigla: "NA", provincia: "MARIGLIANO", nazione: "ITALIA" },
  {
    codeKey: "E956",
    sigla: "RC",
    provincia: "MARINA DI GIOIOSA IONICA",
    nazione: "ITALIA",
  },
  { codeKey: "E957", sigla: "PA", provincia: "MARINEO", nazione: "ITALIA" },
  { codeKey: "E958", sigla: "RM", provincia: "MARINO", nazione: "ITALIA" },
  { codeKey: "E959", sigla: "BZ", provincia: "MARLENGO", nazione: "ITALIA" },
  { codeKey: "E960", sigla: "PT", provincia: "MARLIANA", nazione: "ITALIA" },
  { codeKey: "E961", sigla: "BS", provincia: "MARMENTINO", nazione: "ITALIA" },
  { codeKey: "E962", sigla: "MN", provincia: "MARMIROLO", nazione: "ITALIA" },
  { codeKey: "E963", sigla: "CN", provincia: "MARMORA", nazione: "ITALIA" },
  { codeKey: "E965", sigla: "VA", provincia: "MARNATE", nazione: "ITALIA" },
  { codeKey: "E967", sigla: "BS", provincia: "MARONE", nazione: "ITALIA" },
  { codeKey: "E968", sigla: "RC", provincia: "MAROPATI", nazione: "ITALIA" },
  { codeKey: "E970", sigla: "VI", provincia: "MAROSTICA", nazione: "ITALIA" },
  { codeKey: "E971", sigla: "FI", provincia: "MARRADI", nazione: "ITALIA" },
  { codeKey: "E972", sigla: "OR", provincia: "MARRUBIU", nazione: "ITALIA" },
  { codeKey: "E973", sigla: "CN", provincia: "MARSAGLIA", nazione: "ITALIA" },
  { codeKey: "E974", sigla: "TP", provincia: "MARSALA", nazione: "ITALIA" },
  { codeKey: "E975", sigla: "PG", provincia: "MARSCIANO", nazione: "ITALIA" },
  { codeKey: "E976", sigla: "PZ", provincia: "MARSICO NUOVO", nazione: "ITALIA" },
  { codeKey: "E977", sigla: "PZ", provincia: "MARSICOVETERE", nazione: "ITALIA" },
  { codeKey: "E978", sigla: "VT", provincia: "MARTA", nazione: "ITALIA" },
  { codeKey: "E979", sigla: "LE", provincia: "MARTANO", nazione: "ITALIA" },
  { codeKey: "E980", sigla: "VE", provincia: "MARTELLAGO", nazione: "ITALIA" },
  { codeKey: "E981", sigla: "BZ", provincia: "MARTELLO", nazione: "ITALIA" },
  { codeKey: "E982", sigla: "UD", provincia: "MARTIGNACCO", nazione: "ITALIA" },
  { codeKey: "E983", sigla: "CR", provincia: "MARTIGNANA DI PO", nazione: "ITALIA" },
  { codeKey: "E984", sigla: "LE", provincia: "MARTIGNANO", nazione: "ITALIA" },
  { codeKey: "E986", sigla: "TA", provincia: "MARTINA FRANCA", nazione: "ITALIA" },
  { codeKey: "E987", sigla: "BG", provincia: "MARTINENGO", nazione: "ITALIA" },
  { codeKey: "E988", sigla: "CN", provincia: "MARTINIANA PO", nazione: "ITALIA" },
  { codeKey: "E989", sigla: "TE", provincia: "MARTINSICURO", nazione: "ITALIA" },
  { codeKey: "E990", sigla: "CZ", provincia: "MARTIRANO", nazione: "ITALIA" },
  {
    codeKey: "E991",
    sigla: "CZ",
    provincia: "MARTIRANO LOMBARDO",
    nazione: "ITALIA",
  },
  { codeKey: "E992", sigla: "SS", provincia: "MARTIS", nazione: "ITALIA" },
  { codeKey: "E993", sigla: "RC", provincia: "MARTONE", nazione: "ITALIA" },
  { codeKey: "E994", sigla: "LO", provincia: "MARUDO", nazione: "ITALIA" },
  { codeKey: "E995", sigla: "TA", provincia: "MARUGGIO", nazione: "ITALIA" },
  { codeKey: "E997", sigla: "AV", provincia: "MARZANO DI NOLA", nazione: "ITALIA" },
  { codeKey: "E998", sigla: "CE", provincia: "MARZANO APPIO", nazione: "ITALIA" },
  { codeKey: "E999", sigla: "PV", provincia: "MARZANO", nazione: "ITALIA" },
  { codeKey: "F001", sigla: "CS", provincia: "MARZI", nazione: "ITALIA" },
  { codeKey: "F002", sigla: "VA", provincia: "MARZIO", nazione: "ITALIA" },
  { codeKey: "F003", sigla: "MI", provincia: "MASATE", nazione: "ITALIA" },
  { codeKey: "F004", sigla: "CT", provincia: "MASCALI", nazione: "ITALIA" },
  { codeKey: "F005", sigla: "CT", provincia: "MASCALUCIA", nazione: "ITALIA" },
  { codeKey: "F006", sigla: "PZ", provincia: "MASCHITO", nazione: "ITALIA" },
  { codeKey: "F007", sigla: "VA", provincia: "MASCIAGO PRIMO", nazione: "ITALIA" },
  { codeKey: "F009", sigla: "TV", provincia: "MASER", nazione: "ITALIA" },
  { codeKey: "F010", sigla: "VB", provincia: "MASERA", nazione: "ITALIA" },
  {
    codeKey: "F011",
    sigla: "PD",
    provincia: "MASERA' DI PADOVA",
    nazione: "ITALIA",
  },
  {
    codeKey: "F012",
    sigla: "TV",
    provincia: "MASERADA SUL PIAVE",
    nazione: "ITALIA",
  },
  { codeKey: "F013", sigla: "PD", provincia: "MASI", nazione: "ITALIA" },
  { codeKey: "F015", sigla: "AL", provincia: "MASIO", nazione: "ITALIA" },
  { codeKey: "F016", sigla: "FE", provincia: "MASI TORELLO", nazione: "ITALIA" },
  { codeKey: "F017", sigla: "CO", provincia: "MASLIANICO", nazione: "ITALIA" },
  { codeKey: "F019", sigla: "VI", provincia: "MASON VICENTINO", nazione: "ITALIA" },
  { codeKey: "F020", sigla: "GE", provincia: "MASONE", nazione: "ITALIA" },
  { codeKey: "F021", sigla: "FM", provincia: "MASSA FERMANA", nazione: "ITALIA" },
  { codeKey: "F022", sigla: "AQ", provincia: "MASSA D'ALBE", nazione: "ITALIA" },
  { codeKey: "F023", sigla: "MS", provincia: "MASSA", nazione: "ITALIA" },
  { codeKey: "F024", sigla: "PG", provincia: "MASSA MARTANA", nazione: "ITALIA" },
  { codeKey: "F025", sigla: "PT", provincia: "MASSA E COZZILE", nazione: "ITALIA" },
  { codeKey: "F027", sigla: "TA", provincia: "MASSAFRA", nazione: "ITALIA" },
  { codeKey: "F028", sigla: "LO", provincia: "MASSALENGO", nazione: "ITALIA" },
  { codeKey: "F029", sigla: "RA", provincia: "MASSA LOMBARDA", nazione: "ITALIA" },
  { codeKey: "F030", sigla: "NA", provincia: "MASSA LUBRENSE", nazione: "ITALIA" },
  { codeKey: "F032", sigla: "GR", provincia: "MASSA MARITTIMA", nazione: "ITALIA" },
  { codeKey: "F033", sigla: "PD", provincia: "MASSANZAGO", nazione: "ITALIA" },
  { codeKey: "F035", sigla: "LU", provincia: "MASSAROSA", nazione: "ITALIA" },
  { codeKey: "F037", sigla: "BI", provincia: "MASSAZZA", nazione: "ITALIA" },
  { codeKey: "F041", sigla: "TO", provincia: "MASSELLO", nazione: "ITALIA" },
  { codeKey: "F042", sigla: "BI", provincia: "MASSERANO", nazione: "ITALIA" },
  {
    codeKey: "F043",
    sigla: "CE",
    provincia: "SAN MARCO EVANGELISTA",
    nazione: "ITALIA",
  },
  { codeKey: "F044", sigla: "AP", provincia: "MASSIGNANO", nazione: "ITALIA" },
  { codeKey: "F045", sigla: "TN", provincia: "MASSIMENO", nazione: "ITALIA" },
  { codeKey: "F046", sigla: "SV", provincia: "MASSIMINO", nazione: "ITALIA" },
  { codeKey: "F047", sigla: "NO", provincia: "MASSINO VISCONTI", nazione: "ITALIA" },
  { codeKey: "F048", sigla: "VB", provincia: "MASSIOLA", nazione: "ITALIA" },
  { codeKey: "F050", sigla: "OR", provincia: "MASULLAS", nazione: "ITALIA" },
  { codeKey: "F051", sigla: "MC", provincia: "MATELICA", nazione: "ITALIA" },
  { codeKey: "F052", sigla: "MT", provincia: "MATERA", nazione: "ITALIA" },
  { codeKey: "F053", sigla: "TO", provincia: "MATHI", nazione: "ITALIA" },
  { codeKey: "F054", sigla: "LE", provincia: "MATINO", nazione: "ITALIA" },
  { codeKey: "F055", sigla: "CB", provincia: "MATRICE", nazione: "ITALIA" },
  { codeKey: "F058", sigla: "TO", provincia: "MATTIE", nazione: "ITALIA" },
  { codeKey: "F059", sigla: "FG", provincia: "MATTINATA", nazione: "ITALIA" },
  { codeKey: "F061", sigla: "TP", provincia: "MAZARA DEL VALLO", nazione: "ITALIA" },
  { codeKey: "F063", sigla: "BS", provincia: "MAZZANO", nazione: "ITALIA" },
  { codeKey: "F064", sigla: "RM", provincia: "MAZZANO ROMANO", nazione: "ITALIA" },
  { codeKey: "F065", sigla: "CL", provincia: "MAZZARINO", nazione: "ITALIA" },
  {
    codeKey: "F066",
    sigla: "ME",
    provincia: "MAZZARRA' SANT'ANDREA",
    nazione: "ITALIA",
  },
  { codeKey: "F067", sigla: "TO", provincia: "MAZZE'", nazione: "ITALIA" },
  { codeKey: "F068", sigla: "TN", provincia: "MAZZIN", nazione: "ITALIA" },
  {
    codeKey: "F070",
    sigla: "SO",
    provincia: "MAZZO DI VALTELLINA",
    nazione: "ITALIA",
  },
  { codeKey: "F073", sigla: "NU", provincia: "MEANA SARDO", nazione: "ITALIA" },
  { codeKey: "F074", sigla: "TO", provincia: "MEANA DI SUSA", nazione: "ITALIA" },
  { codeKey: "F078", sigla: "MB", provincia: "MEDA", nazione: "ITALIA" },
  { codeKey: "F080", sigla: "PV", provincia: "MEDE", nazione: "ITALIA" },
  { codeKey: "F081", sigla: "GO", provincia: "MEDEA", nazione: "ITALIA" },
  { codeKey: "F082", sigla: "PR", provincia: "MEDESANO", nazione: "ITALIA" },
  { codeKey: "F083", sigla: "BO", provincia: "MEDICINA", nazione: "ITALIA" },
  { codeKey: "F084", sigla: "MI", provincia: "MEDIGLIA", nazione: "ITALIA" },
  { codeKey: "F085", sigla: "BG", provincia: "MEDOLAGO", nazione: "ITALIA" },
  { codeKey: "F086", sigla: "MN", provincia: "MEDOLE", nazione: "ITALIA" },
  { codeKey: "F087", sigla: "MO", provincia: "MEDOLLA", nazione: "ITALIA" },
  {
    codeKey: "F088",
    sigla: "TV",
    provincia: "MEDUNA DI LIVENZA",
    nazione: "ITALIA",
  },
  { codeKey: "F089", sigla: "PN", provincia: "MEDUNO", nazione: "ITALIA" },
  {
    codeKey: "F091",
    sigla: "PD",
    provincia: "MEGLIADINO SAN FIDENZIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "F092",
    sigla: "PD",
    provincia: "MEGLIADINO SAN VITALE",
    nazione: "ITALIA",
  },
  { codeKey: "F093", sigla: "NO", provincia: "MEINA", nazione: "ITALIA" },
  { codeKey: "F094", sigla: "BL", provincia: "MEL", nazione: "ITALIA" },
  { codeKey: "F095", sigla: "RO", provincia: "MELARA", nazione: "ITALIA" },
  { codeKey: "F096", sigla: "AL", provincia: "MELAZZO", nazione: "ITALIA" },
  { codeKey: "F097", sigla: "FC", provincia: "MELDOLA", nazione: "ITALIA" },
  { codeKey: "F098", sigla: "GE", provincia: "MELE", nazione: "ITALIA" },
  { codeKey: "F100", sigla: "MI", provincia: "MELEGNANO", nazione: "ITALIA" },
  { codeKey: "F101", sigla: "LE", provincia: "MELENDUGNO", nazione: "ITALIA" },
  { codeKey: "F102", sigla: "LO", provincia: "MELETI", nazione: "ITALIA" },
  { codeKey: "F104", sigla: "PZ", provincia: "MELFI", nazione: "ITALIA" },
  { codeKey: "F105", sigla: "RC", provincia: "MELICUCCA'", nazione: "ITALIA" },
  { codeKey: "F106", sigla: "RC", provincia: "MELICUCCO", nazione: "ITALIA" },
  { codeKey: "F107", sigla: "SR", provincia: "MELILLI", nazione: "ITALIA" },
  { codeKey: "F108", sigla: "KR", provincia: "MELISSA", nazione: "ITALIA" },
  { codeKey: "F109", sigla: "LE", provincia: "MELISSANO", nazione: "ITALIA" },
  { codeKey: "F110", sigla: "AV", provincia: "MELITO IRPINO", nazione: "ITALIA" },
  { codeKey: "F111", sigla: "NA", provincia: "MELITO DI NAPOLI", nazione: "ITALIA" },
  {
    codeKey: "F112",
    sigla: "RC",
    provincia: "MELITO DI PORTO SALVO",
    nazione: "ITALIA",
  },
  { codeKey: "F113", sigla: "BN", provincia: "MELIZZANO", nazione: "ITALIA" },
  { codeKey: "F114", sigla: "CN", provincia: "MELLE", nazione: "ITALIA" },
  { codeKey: "F115", sigla: "SO", provincia: "MELLO", nazione: "ITALIA" },
  { codeKey: "F116", sigla: "TV", provincia: "SILEA", nazione: "ITALIA" },
  { codeKey: "F117", sigla: "LE", provincia: "MELPIGNANO", nazione: "ITALIA" },
  { codeKey: "F118", sigla: "BZ", provincia: "MELTINA", nazione: "ITALIA" },
  { codeKey: "F119", sigla: "MI", provincia: "MELZO", nazione: "ITALIA" },
  { codeKey: "F120", sigla: "CO", provincia: "MENAGGIO", nazione: "ITALIA" },
  { codeKey: "F121", sigla: "SO", provincia: "MENAROLA", nazione: "ITALIA" },
  { codeKey: "F122", sigla: "PV", provincia: "MENCONICO", nazione: "ITALIA" },
  { codeKey: "F123", sigla: "IM", provincia: "MENDATICA", nazione: "ITALIA" },
  { codeKey: "F125", sigla: "CS", provincia: "MENDICINO", nazione: "ITALIA" },
  { codeKey: "F126", sigla: "AG", provincia: "MENFI", nazione: "ITALIA" },
  { codeKey: "F127", sigla: "RM", provincia: "MENTANA", nazione: "ITALIA" },
  { codeKey: "F130", sigla: "VE", provincia: "MEOLO", nazione: "ITALIA" },
  { codeKey: "F131", sigla: "AL", provincia: "MERANA", nazione: "ITALIA" },
  { codeKey: "F132", sigla: "BZ", provincia: "MERANO", nazione: "ITALIA" },
  { codeKey: "F133", sigla: "LC", provincia: "MERATE", nazione: "ITALIA" },
  { codeKey: "F134", sigla: "VA", provincia: "MERCALLO", nazione: "ITALIA" },
  {
    codeKey: "F135",
    sigla: "PU",
    provincia: "MERCATELLO SUL METAURO",
    nazione: "ITALIA",
  },
  { codeKey: "F136", sigla: "PU", provincia: "MERCATINO CONCA", nazione: "ITALIA" },
  { codeKey: "F137", sigla: "RN", provincia: "NOVAFELTRIA", nazione: "ITALIA" },
  {
    codeKey: "F138",
    sigla: "SA",
    provincia: "MERCATO SAN SEVERINO",
    nazione: "ITALIA",
  },
  { codeKey: "F139", sigla: "FC", provincia: "MERCATO SARACENO", nazione: "ITALIA" },
  { codeKey: "F140", sigla: "TO", provincia: "MERCENASCO", nazione: "ITALIA" },
  { codeKey: "F141", sigla: "AV", provincia: "MERCOGLIANO", nazione: "ITALIA" },
  { codeKey: "F144", sigla: "UD", provincia: "MERETO DI TOMBA", nazione: "ITALIA" },
  { codeKey: "F145", sigla: "AN", provincia: "MERGO", nazione: "ITALIA" },
  { codeKey: "F146", sigla: "VB", provincia: "MERGOZZO", nazione: "ITALIA" },
  { codeKey: "F147", sigla: "ME", provincia: "MERI'", nazione: "ITALIA" },
  { codeKey: "F148", sigla: "PD", provincia: "MERLARA", nazione: "ITALIA" },
  { codeKey: "F149", sigla: "LO", provincia: "MERLINO", nazione: "ITALIA" },
  { codeKey: "F151", sigla: "CO", provincia: "MERONE", nazione: "ITALIA" },
  { codeKey: "F152", sigla: "BR", provincia: "MESAGNE", nazione: "ITALIA" },
  { codeKey: "F153", sigla: "SO", provincia: "MESE", nazione: "ITALIA" },
  { codeKey: "F154", sigla: "VA", provincia: "MESENZANA", nazione: "ITALIA" },
  { codeKey: "F155", sigla: "MI", provincia: "MESERO", nazione: "ITALIA" },
  { codeKey: "F156", sigla: "FE", provincia: "MESOLA", nazione: "ITALIA" },
  { codeKey: "F157", sigla: "KR", provincia: "MESORACA", nazione: "ITALIA" },
  { codeKey: "F158", sigla: "ME", provincia: "MESSINA", nazione: "ITALIA" },
  { codeKey: "F161", sigla: "PD", provincia: "MESTRINO", nazione: "ITALIA" },
  { codeKey: "F162", sigla: "NA", provincia: "META", nazione: "ITALIA" },
  { codeKey: "F164", sigla: "TO", provincia: "MEUGLIANO", nazione: "ITALIA" },
  { codeKey: "F165", sigla: "MB", provincia: "MEZZAGO", nazione: "ITALIA" },
  {
    codeKey: "F167",
    sigla: "BI",
    provincia: "MEZZANA MORTIGLIENGO",
    nazione: "ITALIA",
  },
  { codeKey: "F168", sigla: "TN", provincia: "MEZZANA", nazione: "ITALIA" },
  { codeKey: "F170", sigla: "PV", provincia: "MEZZANA BIGLI", nazione: "ITALIA" },
  {
    codeKey: "F171",
    sigla: "PV",
    provincia: "MEZZANA RABATTONE",
    nazione: "ITALIA",
  },
  { codeKey: "F172", sigla: "VR", provincia: "MEZZANE DI SOTTO", nazione: "ITALIA" },
  { codeKey: "F173", sigla: "GE", provincia: "MEZZANEGO", nazione: "ITALIA" },
  { codeKey: "F174", sigla: "PR", provincia: "MEZZANI", nazione: "ITALIA" },
  { codeKey: "F175", sigla: "PV", provincia: "MEZZANINO", nazione: "ITALIA" },
  { codeKey: "F176", sigla: "TN", provincia: "MEZZANO", nazione: "ITALIA" },
  { codeKey: "F182", sigla: "TO", provincia: "MEZZENILE", nazione: "ITALIA" },
  { codeKey: "F183", sigla: "TN", provincia: "MEZZOCORONA", nazione: "ITALIA" },
  { codeKey: "F184", sigla: "PA", provincia: "MEZZOJUSO", nazione: "ITALIA" },
  { codeKey: "F186", sigla: "BG", provincia: "MEZZOLDO", nazione: "ITALIA" },
  { codeKey: "F187", sigla: "TN", provincia: "MEZZOLOMBARDO", nazione: "ITALIA" },
  { codeKey: "F188", sigla: "NO", provincia: "MEZZOMERICO", nazione: "ITALIA" },
  { codeKey: "F189", sigla: "BI", provincia: "MIAGLIANO", nazione: "ITALIA" },
  { codeKey: "F190", sigla: "TV", provincia: "MIANE", nazione: "ITALIA" },
  { codeKey: "F191", sigla: "NO", provincia: "MIASINO", nazione: "ITALIA" },
  { codeKey: "F192", sigla: "VB", provincia: "MIAZZINA", nazione: "ITALIA" },
  { codeKey: "F193", sigla: "RI", provincia: "MICIGLIANO", nazione: "ITALIA" },
  { codeKey: "F194", sigla: "LE", provincia: "MIGGIANO", nazione: "ITALIA" },
  { codeKey: "F196", sigla: "CH", provincia: "MIGLIANICO", nazione: "ITALIA" },
  { codeKey: "F200", sigla: "CZ", provincia: "MIGLIERINA", nazione: "ITALIA" },
  { codeKey: "F201", sigla: "MT", provincia: "MIGLIONICO", nazione: "ITALIA" },
  { codeKey: "F202", sigla: "GE", provincia: "MIGNANEGO", nazione: "ITALIA" },
  {
    codeKey: "F203",
    sigla: "CE",
    provincia: "MIGNANO MONTE LUNGO",
    nazione: "ITALIA",
  },
  { codeKey: "F205", sigla: "MI", provincia: "MILANO", nazione: "ITALIA" },
  { codeKey: "F206", sigla: "ME", provincia: "MILAZZO", nazione: "ITALIA" },
  { codeKey: "F207", sigla: "VV", provincia: "MILETO", nazione: "ITALIA" },
  { codeKey: "F208", sigla: "OR", provincia: "MILIS", nazione: "ITALIA" },
  {
    codeKey: "F209",
    sigla: "CT",
    provincia: "MILITELLO IN VAL DI CATANIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "F210",
    sigla: "ME",
    provincia: "MILITELLO ROSMARINO",
    nazione: "ITALIA",
  },
  { codeKey: "F213", sigla: "SV", provincia: "MILLESIMO", nazione: "ITALIA" },
  { codeKey: "F214", sigla: "CT", provincia: "MILO", nazione: "ITALIA" },
  { codeKey: "F216", sigla: "BS", provincia: "MILZANO", nazione: "ITALIA" },
  { codeKey: "F217", sigla: "CT", provincia: "MINEO", nazione: "ITALIA" },
  { codeKey: "F218", sigla: "VR", provincia: "MINERBE", nazione: "ITALIA" },
  { codeKey: "F219", sigla: "BO", provincia: "MINERBIO", nazione: "ITALIA" },
  { codeKey: "F220", sigla: "BT", provincia: "MINERVINO MURGE", nazione: "ITALIA" },
  {
    codeKey: "F221",
    sigla: "LE",
    provincia: "MINERVINO DI LECCE",
    nazione: "ITALIA",
  },
  { codeKey: "F223", sigla: "SA", provincia: "MINORI", nazione: "ITALIA" },
  { codeKey: "F224", sigla: "LT", provincia: "MINTURNO", nazione: "ITALIA" },
  { codeKey: "F225", sigla: "LU", provincia: "MINUCCIANO", nazione: "ITALIA" },
  { codeKey: "F226", sigla: "SV", provincia: "MIOGLIA", nazione: "ITALIA" },
  { codeKey: "F229", sigla: "VE", provincia: "MIRA", nazione: "ITALIA" },
  { codeKey: "F230", sigla: "AV", provincia: "MIRABELLA ECLANO", nazione: "ITALIA" },
  {
    codeKey: "F231",
    sigla: "CT",
    provincia: "MIRABELLA IMBACCARI",
    nazione: "ITALIA",
  },
  {
    codeKey: "F232",
    sigla: "AL",
    provincia: "MIRABELLO MONFERRATO",
    nazione: "ITALIA",
  },
  {
    codeKey: "F233",
    sigla: "CB",
    provincia: "MIRABELLO SANNITICO",
    nazione: "ITALIA",
  },
  { codeKey: "F235", sigla: "FE", provincia: "MIRABELLO", nazione: "ITALIA" },
  { codeKey: "F238", sigla: "PV", provincia: "MIRADOLO TERME", nazione: "ITALIA" },
  { codeKey: "F239", sigla: "IS", provincia: "MIRANDA", nazione: "ITALIA" },
  { codeKey: "F240", sigla: "MO", provincia: "MIRANDOLA", nazione: "ITALIA" },
  { codeKey: "F241", sigla: "VE", provincia: "MIRANO", nazione: "ITALIA" },
  { codeKey: "F242", sigla: "ME", provincia: "MIRTO", nazione: "ITALIA" },
  {
    codeKey: "F243",
    sigla: "BG",
    provincia: "MISANO DI GERA D'ADDA",
    nazione: "ITALIA",
  },
  { codeKey: "F244", sigla: "RN", provincia: "MISANO ADRIATICO", nazione: "ITALIA" },
  { codeKey: "F246", sigla: "PA", provincia: "MISILMERI", nazione: "ITALIA" },
  { codeKey: "F247", sigla: "MB", provincia: "MISINTO", nazione: "ITALIA" },
  { codeKey: "F248", sigla: "LC", provincia: "MISSAGLIA", nazione: "ITALIA" },
  { codeKey: "F249", sigla: "PZ", provincia: "MISSANELLO", nazione: "ITALIA" },
  { codeKey: "F250", sigla: "CT", provincia: "MISTERBIANCO", nazione: "ITALIA" },
  { codeKey: "F251", sigla: "ME", provincia: "MISTRETTA", nazione: "ITALIA" },
  { codeKey: "F254", sigla: "AT", provincia: "MOASCA", nazione: "ITALIA" },
  { codeKey: "F256", sigla: "GE", provincia: "MOCONESI", nazione: "ITALIA" },
  { codeKey: "F257", sigla: "MO", provincia: "MODENA", nazione: "ITALIA" },
  { codeKey: "F258", sigla: "RG", provincia: "MODICA", nazione: "ITALIA" },
  { codeKey: "F259", sigla: "FC", provincia: "MODIGLIANA", nazione: "ITALIA" },
  {
    codeKey: "F260",
    sigla: "LO",
    provincia: "TAVAZZANO CON VILLAVESCO",
    nazione: "ITALIA",
  },
  { codeKey: "F261", sigla: "NU", provincia: "MODOLO", nazione: "ITALIA" },
  { codeKey: "F262", sigla: "BA", provincia: "MODUGNO", nazione: "ITALIA" },
  { codeKey: "F263", sigla: "TN", provincia: "MOENA", nazione: "ITALIA" },
  { codeKey: "F265", sigla: "LC", provincia: "MOGGIO", nazione: "ITALIA" },
  { codeKey: "F266", sigla: "UD", provincia: "MOGGIO UDINESE", nazione: "ITALIA" },
  { codeKey: "F267", sigla: "MN", provincia: "MOGLIA", nazione: "ITALIA" },
  { codeKey: "F268", sigla: "MC", provincia: "MOGLIANO", nazione: "ITALIA" },
  { codeKey: "F269", sigla: "TV", provincia: "MOGLIANO VENETO", nazione: "ITALIA" },
  { codeKey: "F270", sigla: "OR", provincia: "MOGORELLA", nazione: "ITALIA" },
  { codeKey: "F271", sigla: "OR", provincia: "RUINAS", nazione: "ITALIA" },
  { codeKey: "F272", sigla: "OR", provincia: "MOGORO", nazione: "ITALIA" },
  { codeKey: "F274", sigla: "BN", provincia: "MOIANO", nazione: "ITALIA" },
  { codeKey: "F275", sigla: "UD", provincia: "MOIMACCO", nazione: "ITALIA" },
  { codeKey: "F276", sigla: "BG", provincia: "MOIO DE' CALVI", nazione: "ITALIA" },
  { codeKey: "F277", sigla: "ME", provincia: "MOIO ALCANTARA", nazione: "ITALIA" },
  {
    codeKey: "F278",
    sigla: "SA",
    provincia: "MOIO DELLA CIVITELLA",
    nazione: "ITALIA",
  },
  { codeKey: "F279", sigla: "CN", provincia: "MOIOLA", nazione: "ITALIA" },
  { codeKey: "F280", sigla: "BA", provincia: "MOLA DI BARI", nazione: "ITALIA" },
  { codeKey: "F281", sigla: "AL", provincia: "MOLARE", nazione: "ITALIA" },
  { codeKey: "F283", sigla: "LU", provincia: "MOLAZZANA", nazione: "ITALIA" },
  { codeKey: "F284", sigla: "BA", provincia: "MOLFETTA", nazione: "ITALIA" },
  { codeKey: "F287", sigla: "BN", provincia: "MOLINARA", nazione: "ITALIA" },
  { codeKey: "F288", sigla: "BO", provincia: "MOLINELLA", nazione: "ITALIA" },
  { codeKey: "F290", sigla: "IM", provincia: "MOLINI DI TRIORA", nazione: "ITALIA" },
  { codeKey: "F293", sigla: "AL", provincia: "MOLINO DEI TORTI", nazione: "ITALIA" },
  { codeKey: "F294", sigla: "CB", provincia: "MOLISE", nazione: "ITALIA" },
  { codeKey: "F295", sigla: "PZ", provincia: "MOLITERNO", nazione: "ITALIA" },
  { codeKey: "F297", sigla: "VC", provincia: "MOLLIA", nazione: "ITALIA" },
  { codeKey: "F299", sigla: "AG", provincia: "PORTO EMPEDOCLE", nazione: "ITALIA" },
  { codeKey: "F301", sigla: "RC", provincia: "MOLOCHIO", nazione: "ITALIA" },
  { codeKey: "F304", sigla: "LC", provincia: "MOLTENO", nazione: "ITALIA" },
  { codeKey: "F305", sigla: "CO", provincia: "MOLTRASIO", nazione: "ITALIA" },
  { codeKey: "F306", sigla: "VI", provincia: "MOLVENA", nazione: "ITALIA" },
  { codeKey: "F307", sigla: "TN", provincia: "MOLVENO", nazione: "ITALIA" },
  { codeKey: "F308", sigla: "AT", provincia: "MOMBALDONE", nazione: "ITALIA" },
  { codeKey: "F309", sigla: "CN", provincia: "MOMBARCARO", nazione: "ITALIA" },
  { codeKey: "F310", sigla: "PU", provincia: "MOMBAROCCIO", nazione: "ITALIA" },
  { codeKey: "F311", sigla: "AT", provincia: "MOMBARUZZO", nazione: "ITALIA" },
  { codeKey: "F312", sigla: "CN", provincia: "MOMBASIGLIO", nazione: "ITALIA" },
  {
    codeKey: "F313",
    sigla: "AL",
    provincia: "MOMBELLO MONFERRATO",
    nazione: "ITALIA",
  },
  {
    codeKey: "F315",
    sigla: "TO",
    provincia: "MOMBELLO DI TORINO",
    nazione: "ITALIA",
  },
  { codeKey: "F316", sigla: "AT", provincia: "MOMBERCELLI", nazione: "ITALIA" },
  { codeKey: "F317", sigla: "NO", provincia: "MOMO", nazione: "ITALIA" },
  { codeKey: "F318", sigla: "TO", provincia: "MOMPANTERO", nazione: "ITALIA" },
  { codeKey: "F319", sigla: "RI", provincia: "MOMPEO", nazione: "ITALIA" },
  { codeKey: "F320", sigla: "AL", provincia: "MOMPERONE", nazione: "ITALIA" },
  { codeKey: "F322", sigla: "CB", provincia: "MONACILIONI", nazione: "ITALIA" },
  { codeKey: "F323", sigla: "AT", provincia: "MONALE", nazione: "ITALIA" },
  { codeKey: "F324", sigla: "RC", provincia: "MONASTERACE", nazione: "ITALIA" },
  {
    codeKey: "F325",
    sigla: "AT",
    provincia: "MONASTERO BORMIDA",
    nazione: "ITALIA",
  },
  {
    codeKey: "F326",
    sigla: "CN",
    provincia: "MONASTERO DI VASCO",
    nazione: "ITALIA",
  },
  {
    codeKey: "F327",
    sigla: "TO",
    provincia: "MONASTERO DI LANZO",
    nazione: "ITALIA",
  },
  {
    codeKey: "F328",
    sigla: "BG",
    provincia: "MONASTEROLO DEL CASTELLO",
    nazione: "ITALIA",
  },
  {
    codeKey: "F329",
    sigla: "CN",
    provincia: "MONASTEROLO CASOTTO",
    nazione: "ITALIA",
  },
  {
    codeKey: "F330",
    sigla: "CN",
    provincia: "MONASTEROLO DI SAVIGLIANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "F332",
    sigla: "TV",
    provincia: "MONASTIER DI TREVISO",
    nazione: "ITALIA",
  },
  { codeKey: "F333", sigla: "CA", provincia: "MONASTIR", nazione: "ITALIA" },
  { codeKey: "F335", sigla: "TO", provincia: "MONCALIERI", nazione: "ITALIA" },
  { codeKey: "F336", sigla: "AT", provincia: "MONCALVO", nazione: "ITALIA" },
  { codeKey: "F337", sigla: "AL", provincia: "MONCESTINO", nazione: "ITALIA" },
  { codeKey: "F338", sigla: "CN", provincia: "MONCHIERO", nazione: "ITALIA" },
  {
    codeKey: "F340",
    sigla: "PR",
    provincia: "MONCHIO DELLE CORTI",
    nazione: "ITALIA",
  },
  { codeKey: "F341", sigla: "TN", provincia: "MONCLASSICO", nazione: "ITALIA" },
  { codeKey: "F342", sigla: "VC", provincia: "MONCRIVELLO", nazione: "ITALIA" },
  {
    codeKey: "F343",
    sigla: "AT",
    provincia: "MONCUCCO TORINESE",
    nazione: "ITALIA",
  },
  { codeKey: "F346", sigla: "RN", provincia: "MONDAINO", nazione: "ITALIA" },
  { codeKey: "F347", sigla: "PU", provincia: "MONDAVIO", nazione: "ITALIA" },
  { codeKey: "F348", sigla: "PU", provincia: "MONDOLFO", nazione: "ITALIA" },
  { codeKey: "F351", sigla: "CN", provincia: "MONDOVI'", nazione: "ITALIA" },
  { codeKey: "F352", sigla: "CE", provincia: "MONDRAGONE", nazione: "ITALIA" },
  { codeKey: "F354", sigla: "GE", provincia: "MONEGLIA", nazione: "ITALIA" },
  { codeKey: "F355", sigla: "CN", provincia: "MONESIGLIO", nazione: "ITALIA" },
  { codeKey: "F356", sigla: "GO", provincia: "MONFALCONE", nazione: "ITALIA" },
  { codeKey: "F357", sigla: "MO", provincia: "SERRAMAZZONI", nazione: "ITALIA" },
  { codeKey: "F358", sigla: "CN", provincia: "MONFORTE D'ALBA", nazione: "ITALIA" },
  {
    codeKey: "F359",
    sigla: "ME",
    provincia: "MONFORTE SAN GIORGIO",
    nazione: "ITALIA",
  },
  { codeKey: "F360", sigla: "TV", provincia: "MONFUMO", nazione: "ITALIA" },
  { codeKey: "F361", sigla: "AT", provincia: "MONGARDINO", nazione: "ITALIA" },
  { codeKey: "F363", sigla: "BO", provincia: "MONGHIDORO", nazione: "ITALIA" },
  { codeKey: "F364", sigla: "VV", provincia: "MONGIANA", nazione: "ITALIA" },
  {
    codeKey: "F365",
    sigla: "AL",
    provincia: "MONGIARDINO LIGURE",
    nazione: "ITALIA",
  },
  { codeKey: "F367", sigla: "AO", provincia: "MONTJOVET", nazione: "ITALIA" },
  { codeKey: "F368", sigla: "ME", provincia: "MONGIUFFI MELIA", nazione: "ITALIA" },
  { codeKey: "F369", sigla: "BI", provincia: "MONGRANDO", nazione: "ITALIA" },
  { codeKey: "F370", sigla: "CS", provincia: "MONGRASSANO", nazione: "ITALIA" },
  { codeKey: "F371", sigla: "BZ", provincia: "MONGUELFO-TESIDO", nazione: "ITALIA" },
  { codeKey: "F372", sigla: "CO", provincia: "MONGUZZO", nazione: "ITALIA" },
  { codeKey: "F373", sigla: "BS", provincia: "MONIGA DEL GARDA", nazione: "ITALIA" },
  { codeKey: "F374", sigla: "AL", provincia: "MONLEALE", nazione: "ITALIA" },
  { codeKey: "F375", sigla: "BS", provincia: "MONNO", nazione: "ITALIA" },
  { codeKey: "F376", sigla: "BA", provincia: "MONOPOLI", nazione: "ITALIA" },
  { codeKey: "F377", sigla: "PA", provincia: "MONREALE", nazione: "ITALIA" },
  { codeKey: "F378", sigla: "TS", provincia: "MONRUPINO", nazione: "ITALIA" },
  {
    codeKey: "F379",
    sigla: "FM",
    provincia: "MONSAMPIETRO MORICO",
    nazione: "ITALIA",
  },
  {
    codeKey: "F380",
    sigla: "AP",
    provincia: "MONSAMPOLO DEL TRONTO",
    nazione: "ITALIA",
  },
  { codeKey: "F381", sigla: "AN", provincia: "MONSANO", nazione: "ITALIA" },
  { codeKey: "F382", sigla: "PD", provincia: "MONSELICE", nazione: "ITALIA" },
  { codeKey: "F383", sigla: "CA", provincia: "MONSERRATO", nazione: "ITALIA" },
  { codeKey: "F384", sigla: "PT", provincia: "MONSUMMANO TERME", nazione: "ITALIA" },
  { codeKey: "F385", sigla: "CN", provincia: "MONTA'", nazione: "ITALIA" },
  { codeKey: "F386", sigla: "AT", provincia: "MONTABONE", nazione: "ITALIA" },
  { codeKey: "F387", sigla: "AL", provincia: "MONTACUTO", nazione: "ITALIA" },
  { codeKey: "F390", sigla: "AT", provincia: "MONTAFIA", nazione: "ITALIA" },
  { codeKey: "F391", sigla: "CB", provincia: "MONTAGANO", nazione: "ITALIA" },
  { codeKey: "F392", sigla: "BZ", provincia: "MONTAGNA", nazione: "ITALIA" },
  {
    codeKey: "F393",
    sigla: "SO",
    provincia: "MONTAGNA IN VALTELLINA",
    nazione: "ITALIA",
  },
  { codeKey: "F394", sigla: "PD", provincia: "MONTAGNANA", nazione: "ITALIA" },
  { codeKey: "F395", sigla: "ME", provincia: "MONTAGNAREALE", nazione: "ITALIA" },
  { codeKey: "F396", sigla: "TN", provincia: "MONTAGNE", nazione: "ITALIA" },
  { codeKey: "F397", sigla: "AV", provincia: "MONTAGUTO", nazione: "ITALIA" },
  { codeKey: "F398", sigla: "FI", provincia: "MONTAIONE", nazione: "ITALIA" },
  {
    codeKey: "F399",
    sigla: "MT",
    provincia: "MONTALBANO JONICO",
    nazione: "ITALIA",
  },
  {
    codeKey: "F400",
    sigla: "ME",
    provincia: "MONTALBANO ELICONA",
    nazione: "ITALIA",
  },
  { codeKey: "F401", sigla: "AN", provincia: "OSTRA", nazione: "ITALIA" },
  { codeKey: "F402", sigla: "SI", provincia: "MONTALCINO", nazione: "ITALIA" },
  { codeKey: "F403", sigla: "AL", provincia: "MONTALDEO", nazione: "ITALIA" },
  { codeKey: "F404", sigla: "AL", provincia: "MONTALDO BORMIDA", nazione: "ITALIA" },
  {
    codeKey: "F405",
    sigla: "CN",
    provincia: "MONTALDO DI MONDOVI'",
    nazione: "ITALIA",
  },
  { codeKey: "F406", sigla: "IM", provincia: "MONTALTO LIGURE", nazione: "ITALIA" },
  {
    codeKey: "F407",
    sigla: "TO",
    provincia: "MONTALDO TORINESE",
    nazione: "ITALIA",
  },
  { codeKey: "F408", sigla: "CN", provincia: "MONTALDO ROERO", nazione: "ITALIA" },
  {
    codeKey: "F409",
    sigla: "AT",
    provincia: "MONTALDO SCARAMPI",
    nazione: "ITALIA",
  },
  { codeKey: "F410", sigla: "PT", provincia: "MONTALE", nazione: "ITALIA" },
  { codeKey: "F411", sigla: "TO", provincia: "MONTALENGHE", nazione: "ITALIA" },
  { codeKey: "F414", sigla: "AG", provincia: "MONTALLEGRO", nazione: "ITALIA" },
  {
    codeKey: "F415",
    sigla: "AP",
    provincia: "MONTALTO DELLE MARCHE",
    nazione: "ITALIA",
  },
  { codeKey: "F416", sigla: "CS", provincia: "MONTALTO UFFUGO", nazione: "ITALIA" },
  { codeKey: "F417", sigla: "PV", provincia: "MONTALTO PAVESE", nazione: "ITALIA" },
  {
    codeKey: "F419",
    sigla: "VT",
    provincia: "MONTALTO DI CASTRO",
    nazione: "ITALIA",
  },
  { codeKey: "F420", sigla: "TO", provincia: "MONTALTO DORA", nazione: "ITALIA" },
  { codeKey: "F422", sigla: "TO", provincia: "MONTANARO", nazione: "ITALIA" },
  {
    codeKey: "F423",
    sigla: "LO",
    provincia: "MONTANASO LOMBARDO",
    nazione: "ITALIA",
  },
  { codeKey: "F424", sigla: "CN", provincia: "MONTANERA", nazione: "ITALIA" },
  { codeKey: "F426", sigla: "SA", provincia: "MONTANO ANTILIA", nazione: "ITALIA" },
  { codeKey: "F427", sigla: "CO", provincia: "MONTANO LUCINO", nazione: "ITALIA" },
  { codeKey: "F428", sigla: "FM", provincia: "MONTAPPONE", nazione: "ITALIA" },
  { codeKey: "F429", sigla: "IS", provincia: "MONTAQUILA", nazione: "ITALIA" },
  { codeKey: "F430", sigla: "RI", provincia: "MONTASOLA", nazione: "ITALIA" },
  { codeKey: "F432", sigla: "CZ", provincia: "MONTAURO", nazione: "ITALIA" },
  { codeKey: "F433", sigla: "CH", provincia: "MONTAZZOLI", nazione: "ITALIA" },
  { codeKey: "F434", sigla: "CR", provincia: "MONTE CREMASCO", nazione: "ITALIA" },
  { codeKey: "F437", sigla: "GR", provincia: "MONTE ARGENTARIO", nazione: "ITALIA" },
  {
    codeKey: "F440",
    sigla: "PV",
    provincia: "MONTEBELLO DELLA BATTAGLIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "F441",
    sigla: "PE",
    provincia: "MONTEBELLO DI BERTONA",
    nazione: "ITALIA",
  },
  {
    codeKey: "F442",
    sigla: "VI",
    provincia: "MONTEBELLO VICENTINO",
    nazione: "ITALIA",
  },
  { codeKey: "F443", sigla: "TV", provincia: "MONTEBELLUNA", nazione: "ITALIA" },
  { codeKey: "F445", sigla: "GE", provincia: "MONTEBRUNO", nazione: "ITALIA" },
  { codeKey: "F446", sigla: "RI", provincia: "MONTEBUONO", nazione: "ITALIA" },
  {
    codeKey: "F448",
    sigla: "AV",
    provincia: "MONTECALVO IRPINO",
    nazione: "ITALIA",
  },
  {
    codeKey: "F449",
    sigla: "PV",
    provincia: "MONTECALVO VERSIGGIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "F450",
    sigla: "PU",
    provincia: "MONTECALVO IN FOGLIA",
    nazione: "ITALIA",
  },
  { codeKey: "F452", sigla: "LU", provincia: "MONTECARLO", nazione: "ITALIA" },
  { codeKey: "F453", sigla: "AN", provincia: "MONTECAROTTO", nazione: "ITALIA" },
  { codeKey: "F454", sigla: "MC", provincia: "MONTECASSIANO", nazione: "ITALIA" },
  { codeKey: "F455", sigla: "AL", provincia: "MONTECASTELLO", nazione: "ITALIA" },
  {
    codeKey: "F456",
    sigla: "PG",
    provincia: "MONTE CASTELLO DI VIBIO",
    nazione: "ITALIA",
  },
  { codeKey: "F457", sigla: "TR", provincia: "MONTECASTRILLI", nazione: "ITALIA" },
  {
    codeKey: "F458",
    sigla: "PI",
    provincia: "MONTECATINI VAL DI CECINA",
    nazione: "ITALIA",
  },
  { codeKey: "F460", sigla: "MC", provincia: "MONTE CAVALLO", nazione: "ITALIA" },
  {
    codeKey: "F461",
    sigla: "VR",
    provincia: "MONTECCHIA DI CROSARA",
    nazione: "ITALIA",
  },
  { codeKey: "F462", sigla: "TR", provincia: "MONTECCHIO", nazione: "ITALIA" },
  {
    codeKey: "F463",
    sigla: "RE",
    provincia: "MONTECCHIO EMILIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "F464",
    sigla: "VI",
    provincia: "MONTECCHIO MAGGIORE",
    nazione: "ITALIA",
  },
  {
    codeKey: "F465",
    sigla: "VI",
    provincia: "MONTECCHIO PRECALCINO",
    nazione: "ITALIA",
  },
  { codeKey: "F467", sigla: "PU", provincia: "MONTE CERIGNONE", nazione: "ITALIA" },
  {
    codeKey: "F468",
    sigla: "AT",
    provincia: "MONTECHIARO D'ASTI",
    nazione: "ITALIA",
  },
  {
    codeKey: "F469",
    sigla: "AL",
    provincia: "MONTECHIARO D'ACQUI",
    nazione: "ITALIA",
  },
  { codeKey: "F471", sigla: "BS", provincia: "MONTICHIARI", nazione: "ITALIA" },
  { codeKey: "F473", sigla: "PR", provincia: "MONTECHIARUGOLO", nazione: "ITALIA" },
  { codeKey: "F474", sigla: "PU", provincia: "MONTECICCARDO", nazione: "ITALIA" },
  { codeKey: "F475", sigla: "CB", provincia: "MONTECILFONE", nazione: "ITALIA" },
  { codeKey: "F476", sigla: "RN", provincia: "MONTE COLOMBO", nazione: "ITALIA" },
  { codeKey: "F477", sigla: "RM", provincia: "MONTECOMPATRI", nazione: "ITALIA" },
  { codeKey: "F478", sigla: "PU", provincia: "MONTECOPIOLO", nazione: "ITALIA" },
  { codeKey: "F479", sigla: "SA", provincia: "MONTECORICE", nazione: "ITALIA" },
  {
    codeKey: "F480",
    sigla: "SA",
    provincia: "MONTECORVINO PUGLIANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "F481",
    sigla: "SA",
    provincia: "MONTECORVINO ROVELLA",
    nazione: "ITALIA",
  },
  { codeKey: "F482", sigla: "MC", provincia: "MONTECOSARO", nazione: "ITALIA" },
  { codeKey: "F483", sigla: "VB", provincia: "MONTECRESTESE", nazione: "ITALIA" },
  { codeKey: "F484", sigla: "MO", provincia: "MONTECRETO", nazione: "ITALIA" },
  { codeKey: "F486", sigla: "VI", provincia: "MONTE DI MALO", nazione: "ITALIA" },
  { codeKey: "F487", sigla: "AP", provincia: "MONTEDINOVE", nazione: "ITALIA" },
  { codeKey: "F488", sigla: "NA", provincia: "MONTE DI PROCIDA", nazione: "ITALIA" },
  { codeKey: "F489", sigla: "CL", provincia: "MONTEDORO", nazione: "ITALIA" },
  { codeKey: "F491", sigla: "AV", provincia: "MONTEFALCIONE", nazione: "ITALIA" },
  { codeKey: "F492", sigla: "PG", provincia: "MONTEFALCO", nazione: "ITALIA" },
  {
    codeKey: "F493",
    sigla: "FM",
    provincia: "MONTEFALCONE APPENNINO",
    nazione: "ITALIA",
  },
  {
    codeKey: "F494",
    sigla: "BN",
    provincia: "MONTEFALCONE DI VAL FORTORE",
    nazione: "ITALIA",
  },
  {
    codeKey: "F495",
    sigla: "CB",
    provincia: "MONTEFALCONE NEL SANNIO",
    nazione: "ITALIA",
  },
  { codeKey: "F496", sigla: "MC", provincia: "MONTEFANO", nazione: "ITALIA" },
  { codeKey: "F497", sigla: "PU", provincia: "MONTEFELCINO", nazione: "ITALIA" },
  { codeKey: "F498", sigla: "CH", provincia: "MONTEFERRANTE", nazione: "ITALIA" },
  { codeKey: "F499", sigla: "VT", provincia: "MONTEFIASCONE", nazione: "ITALIA" },
  { codeKey: "F500", sigla: "TE", provincia: "MONTEFINO", nazione: "ITALIA" },
  {
    codeKey: "F501",
    sigla: "AP",
    provincia: "MONTEFIORE DELL'ASO",
    nazione: "ITALIA",
  },
  { codeKey: "F502", sigla: "RN", provincia: "MONTEFIORE CONCA", nazione: "ITALIA" },
  { codeKey: "F503", sigla: "MO", provincia: "MONTEFIORINO", nazione: "ITALIA" },
  { codeKey: "F504", sigla: "RM", provincia: "MONTEFLAVIO", nazione: "ITALIA" },
  {
    codeKey: "F506",
    sigla: "AV",
    provincia: "MONTEFORTE IRPINO",
    nazione: "ITALIA",
  },
  {
    codeKey: "F507",
    sigla: "SA",
    provincia: "MONTEFORTE CILENTO",
    nazione: "ITALIA",
  },
  {
    codeKey: "F508",
    sigla: "VR",
    provincia: "MONTEFORTE D'ALPONE",
    nazione: "ITALIA",
  },
  { codeKey: "F509", sigla: "FM", provincia: "MONTEFORTINO", nazione: "ITALIA" },
  { codeKey: "F510", sigla: "TR", provincia: "MONTEFRANCO", nazione: "ITALIA" },
  { codeKey: "F511", sigla: "AV", provincia: "MONTEFREDANE", nazione: "ITALIA" },
  { codeKey: "F512", sigla: "AV", provincia: "MONTEFUSCO", nazione: "ITALIA" },
  { codeKey: "F513", sigla: "TR", provincia: "MONTEGABBIONE", nazione: "ITALIA" },
  { codeKey: "F514", sigla: "VI", provincia: "MONTEGALDA", nazione: "ITALIA" },
  { codeKey: "F515", sigla: "VI", provincia: "MONTEGALDELLA", nazione: "ITALIA" },
  { codeKey: "F516", sigla: "AP", provincia: "MONTEGALLO", nazione: "ITALIA" },
  { codeKey: "F517", sigla: "FM", provincia: "MONTE GIBERTO", nazione: "ITALIA" },
  { codeKey: "F518", sigla: "AL", provincia: "MONTEGIOCO", nazione: "ITALIA" },
  { codeKey: "F519", sigla: "CS", provincia: "MONTEGIORDANO", nazione: "ITALIA" },
  { codeKey: "F520", sigla: "FM", provincia: "MONTEGIORGIO", nazione: "ITALIA" },
  { codeKey: "F522", sigla: "FM", provincia: "MONTEGRANARO", nazione: "ITALIA" },
  { codeKey: "F523", sigla: "RN", provincia: "MONTEGRIDOLFO", nazione: "ITALIA" },
  {
    codeKey: "F524",
    sigla: "PU",
    provincia: "MONTE GRIMANO TERME",
    nazione: "ITALIA",
  },
  {
    codeKey: "F526",
    sigla: "VA",
    provincia: "MONTEGRINO VALTRAVAGLIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "F527",
    sigla: "AT",
    provincia: "MONTEGROSSO D'ASTI",
    nazione: "ITALIA",
  },
  {
    codeKey: "F528",
    sigla: "IM",
    provincia: "MONTEGROSSO PIAN LATTE",
    nazione: "ITALIA",
  },
  {
    codeKey: "F529",
    sigla: "PD",
    provincia: "MONTEGROTTO TERME",
    nazione: "ITALIA",
  },
  { codeKey: "F531", sigla: "TA", provincia: "MONTEIASI", nazione: "ITALIA" },
  { codeKey: "F532", sigla: "BS", provincia: "MONTE ISOLA", nazione: "ITALIA" },
  { codeKey: "F533", sigla: "PU", provincia: "MONTELABBATE", nazione: "ITALIA" },
  { codeKey: "F534", sigla: "RM", provincia: "MONTELANICO", nazione: "ITALIA" },
  { codeKey: "F535", sigla: "CH", provincia: "MONTELAPIANO", nazione: "ITALIA" },
  {
    codeKey: "F536",
    sigla: "FM",
    provincia: "MONTELEONE DI FERMO",
    nazione: "ITALIA",
  },
  { codeKey: "F537", sigla: "VV", provincia: "VIBO VALENTIA", nazione: "ITALIA" },
  {
    codeKey: "F538",
    sigla: "FG",
    provincia: "MONTELEONE DI PUGLIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "F540",
    sigla: "PG",
    provincia: "MONTELEONE DI SPOLETO",
    nazione: "ITALIA",
  },
  {
    codeKey: "F541",
    sigla: "RI",
    provincia: "MONTELEONE SABINO",
    nazione: "ITALIA",
  },
  {
    codeKey: "F542",
    sigla: "SS",
    provincia: "MONTELEONE ROCCA DORIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "F543",
    sigla: "TR",
    provincia: "MONTELEONE D'ORVIETO",
    nazione: "ITALIA",
  },
  { codeKey: "F544", sigla: "PA", provincia: "MONTELEPRE", nazione: "ITALIA" },
  { codeKey: "F545", sigla: "RM", provincia: "MONTELIBRETTI", nazione: "ITALIA" },
  { codeKey: "F546", sigla: "AV", provincia: "MONTELLA", nazione: "ITALIA" },
  { codeKey: "F547", sigla: "BG", provincia: "MONTELLO", nazione: "ITALIA" },
  { codeKey: "F548", sigla: "CB", provincia: "MONTELONGO", nazione: "ITALIA" },
  { codeKey: "F549", sigla: "FM", provincia: "MONTELPARO", nazione: "ITALIA" },
  { codeKey: "F550", sigla: "CN", provincia: "MONTELUPO ALBESE", nazione: "ITALIA" },
  {
    codeKey: "F551",
    sigla: "FI",
    provincia: "MONTELUPO FIORENTINO",
    nazione: "ITALIA",
  },
  { codeKey: "F552", sigla: "MC", provincia: "MONTELUPONE", nazione: "ITALIA" },
  {
    codeKey: "F553",
    sigla: "PA",
    provincia: "MONTEMAGGIORE BELSITO",
    nazione: "ITALIA",
  },
  {
    codeKey: "F555",
    sigla: "PU",
    provincia: "MONTEMAGGIORE AL METAURO",
    nazione: "ITALIA",
  },
  { codeKey: "F556", sigla: "AT", provincia: "MONTEMAGNO", nazione: "ITALIA" },
  {
    codeKey: "F557",
    sigla: "BN",
    provincia: "SANT'ARCANGELO TRIMONTE",
    nazione: "ITALIA",
  },
  {
    codeKey: "F558",
    sigla: "CN",
    provincia: "MONTEMALE DI CUNEO",
    nazione: "ITALIA",
  },
  { codeKey: "F559", sigla: "AV", provincia: "MONTEMARANO", nazione: "ITALIA" },
  { codeKey: "F560", sigla: "AN", provincia: "MONTEMARCIANO", nazione: "ITALIA" },
  { codeKey: "F561", sigla: "LC", provincia: "MONTE MARENZO", nazione: "ITALIA" },
  { codeKey: "F562", sigla: "AL", provincia: "MONTEMARZINO", nazione: "ITALIA" },
  { codeKey: "F563", sigla: "TA", provincia: "MONTEMESOLA", nazione: "ITALIA" },
  { codeKey: "F564", sigla: "CO", provincia: "MONTEMEZZO", nazione: "ITALIA" },
  { codeKey: "F565", sigla: "AR", provincia: "MONTEMIGNAIO", nazione: "ITALIA" },
  { codeKey: "F566", sigla: "AV", provincia: "MONTEMILETTO", nazione: "ITALIA" },
  { codeKey: "F567", sigla: "MC", provincia: "POLLENZA", nazione: "ITALIA" },
  { codeKey: "F568", sigla: "PZ", provincia: "MONTEMILONE", nazione: "ITALIA" },
  { codeKey: "F569", sigla: "CB", provincia: "MONTEMITRO", nazione: "ITALIA" },
  { codeKey: "F570", sigla: "AP", provincia: "MONTEMONACO", nazione: "ITALIA" },
  { codeKey: "F572", sigla: "PO", provincia: "MONTEMURLO", nazione: "ITALIA" },
  { codeKey: "F573", sigla: "PZ", provincia: "MONTEMURRO", nazione: "ITALIA" },
  { codeKey: "F574", sigla: "UD", provincia: "MONTENARS", nazione: "ITALIA" },
  {
    codeKey: "F576",
    sigla: "CB",
    provincia: "MONTENERO DI BISACCIA",
    nazione: "ITALIA",
  },
  { codeKey: "F578", sigla: "CH", provincia: "MONTENERODOMO", nazione: "ITALIA" },
  { codeKey: "F579", sigla: "RI", provincia: "MONTENERO SABINO", nazione: "ITALIA" },
  {
    codeKey: "F580",
    sigla: "IS",
    provincia: "MONTENERO VAL COCCHIARA",
    nazione: "ITALIA",
  },
  { codeKey: "F581", sigla: "AN", provincia: "OSTRA VETERE", nazione: "ITALIA" },
  { codeKey: "F582", sigla: "CH", provincia: "MONTEODORISIO", nazione: "ITALIA" },
  {
    codeKey: "F585",
    sigla: "TE",
    provincia: "ROSETO DEGLI ABRUZZI",
    nazione: "ITALIA",
  },
  { codeKey: "F586", sigla: "CZ", provincia: "MONTEPAONE", nazione: "ITALIA" },
  { codeKey: "F587", sigla: "TA", provincia: "MONTEPARANO", nazione: "ITALIA" },
  { codeKey: "F589", sigla: "PU", provincia: "MONTE PORZIO", nazione: "ITALIA" },
  {
    codeKey: "F590",
    sigla: "RM",
    provincia: "MONTE PORZIO CATONE",
    nazione: "ITALIA",
  },
  { codeKey: "F591", sigla: "AP", provincia: "MONTEPRANDONE", nazione: "ITALIA" },
  { codeKey: "F592", sigla: "SI", provincia: "MONTEPULCIANO", nazione: "ITALIA" },
  { codeKey: "F594", sigla: "AR", provincia: "MONTERCHI", nazione: "ITALIA" },
  { codeKey: "F595", sigla: "AQ", provincia: "MONTEREALE", nazione: "ITALIA" },
  {
    codeKey: "F596",
    sigla: "PN",
    provincia: "MONTEREALE VALCELLINA",
    nazione: "ITALIA",
  },
  { codeKey: "F597", sigla: "BO", provincia: "MONTERENZIO", nazione: "ITALIA" },
  { codeKey: "F598", sigla: "SI", provincia: "MONTERIGGIONI", nazione: "ITALIA" },
  { codeKey: "F599", sigla: "FM", provincia: "MONTE RINALDO", nazione: "ITALIA" },
  { codeKey: "F600", sigla: "AN", provincia: "MONTE ROBERTO", nazione: "ITALIA" },
  { codeKey: "F601", sigla: "IS", provincia: "MONTERODUNI", nazione: "ITALIA" },
  { codeKey: "F603", sigla: "VT", provincia: "MONTE ROMANO", nazione: "ITALIA" },
  {
    codeKey: "F604",
    sigla: "LE",
    provincia: "MONTERONI DI LECCE",
    nazione: "ITALIA",
  },
  {
    codeKey: "F605",
    sigla: "SI",
    provincia: "MONTERONI D'ARBIA",
    nazione: "ITALIA",
  },
  { codeKey: "F606", sigla: "VT", provincia: "MONTEROSI", nazione: "ITALIA" },
  {
    codeKey: "F607",
    sigla: "VV",
    provincia: "MONTEROSSO CALABRO",
    nazione: "ITALIA",
  },
  { codeKey: "F608", sigla: "CN", provincia: "MONTEROSSO GRANA", nazione: "ITALIA" },
  {
    codeKey: "F609",
    sigla: "SP",
    provincia: "MONTEROSSO AL MARE",
    nazione: "ITALIA",
  },
  { codeKey: "F610", sigla: "RG", provincia: "MONTEROSSO ALMO", nazione: "ITALIA" },
  { codeKey: "F611", sigla: "RM", provincia: "MONTEROTONDO", nazione: "ITALIA" },
  {
    codeKey: "F612",
    sigla: "GR",
    provincia: "MONTEROTONDO MARITTIMO",
    nazione: "ITALIA",
  },
  { codeKey: "F614", sigla: "FM", provincia: "MONTERUBBIANO", nazione: "ITALIA" },
  { codeKey: "F616", sigla: "LT", provincia: "MONTE SAN BIAGIO", nazione: "ITALIA" },
  {
    codeKey: "F618",
    sigla: "SA",
    provincia: "MONTE SAN GIACOMO",
    nazione: "ITALIA",
  },
  {
    codeKey: "F619",
    sigla: "RI",
    provincia: "MONTE SAN GIOVANNI IN SABINA",
    nazione: "ITALIA",
  },
  {
    codeKey: "F620",
    sigla: "FR",
    provincia: "MONTE SAN GIOVANNI CAMPANO",
    nazione: "ITALIA",
  },
  { codeKey: "F621", sigla: "MC", provincia: "MONTE SAN GIUSTO", nazione: "ITALIA" },
  {
    codeKey: "F622",
    sigla: "MC",
    provincia: "MONTE SAN MARTINO",
    nazione: "ITALIA",
  },
  {
    codeKey: "F623",
    sigla: "LE",
    provincia: "MONTESANO SALENTINO",
    nazione: "ITALIA",
  },
  {
    codeKey: "F625",
    sigla: "SA",
    provincia: "MONTESANO SULLA MARCELLANA",
    nazione: "ITALIA",
  },
  {
    codeKey: "F626",
    sigla: "FM",
    provincia: "MONTE SAN PIETRANGELI",
    nazione: "ITALIA",
  },
  { codeKey: "F627", sigla: "BO", provincia: "MONTE SAN PIETRO", nazione: "ITALIA" },
  { codeKey: "F628", sigla: "AR", provincia: "MONTE SAN SAVINO", nazione: "ITALIA" },
  {
    codeKey: "F629",
    sigla: "PG",
    provincia: "MONTE SANTA MARIA TIBERINA",
    nazione: "ITALIA",
  },
  {
    codeKey: "F631",
    sigla: "FG",
    provincia: "MONTE SANT'ANGELO",
    nazione: "ITALIA",
  },
  { codeKey: "F632", sigla: "MC", provincia: "POTENZA PICENA", nazione: "ITALIA" },
  { codeKey: "F634", sigla: "AN", provincia: "MONTE SAN VITO", nazione: "ITALIA" },
  { codeKey: "F636", sigla: "BN", provincia: "MONTESARCHIO", nazione: "ITALIA" },
  { codeKey: "F637", sigla: "MT", provincia: "MONTESCAGLIOSO", nazione: "ITALIA" },
  { codeKey: "F638", sigla: "PV", provincia: "MONTESCANO", nazione: "ITALIA" },
  { codeKey: "F639", sigla: "VB", provincia: "MONTESCHENO", nazione: "ITALIA" },
  { codeKey: "F640", sigla: "PI", provincia: "MONTESCUDAIO", nazione: "ITALIA" },
  { codeKey: "F641", sigla: "RN", provincia: "MONTESCUDO", nazione: "ITALIA" },
  { codeKey: "F642", sigla: "MO", provincia: "MONTESE", nazione: "ITALIA" },
  { codeKey: "F644", sigla: "PV", provincia: "MONTESEGALE", nazione: "ITALIA" },
  { codeKey: "F646", sigla: "PE", provincia: "MONTESILVANO", nazione: "ITALIA" },
  { codeKey: "F648", sigla: "FI", provincia: "MONTESPERTOLI", nazione: "ITALIA" },
  { codeKey: "F651", sigla: "TO", provincia: "MONTEU DA PO", nazione: "ITALIA" },
  { codeKey: "F653", sigla: "FM", provincia: "MONTE URANO", nazione: "ITALIA" },
  { codeKey: "F654", sigla: "CN", provincia: "MONTEU ROERO", nazione: "ITALIA" },
  { codeKey: "F655", sigla: "AG", provincia: "MONTEVAGO", nazione: "ITALIA" },
  { codeKey: "F656", sigla: "AR", provincia: "MONTEVARCHI", nazione: "ITALIA" },
  { codeKey: "F657", sigla: "LC", provincia: "MONTEVECCHIA", nazione: "ITALIA" },
  { codeKey: "F660", sigla: "AV", provincia: "MONTEVERDE", nazione: "ITALIA" },
  {
    codeKey: "F661",
    sigla: "PI",
    provincia: "MONTEVERDI MARITTIMO",
    nazione: "ITALIA",
  },
  { codeKey: "F662", sigla: "VI", provincia: "MONTEVIALE", nazione: "ITALIA" },
  {
    codeKey: "F664",
    sigla: "FM",
    provincia: "MONTE VIDON COMBATTE",
    nazione: "ITALIA",
  },
  {
    codeKey: "F665",
    sigla: "FM",
    provincia: "MONTE VIDON CORRADO",
    nazione: "ITALIA",
  },
  { codeKey: "F666", sigla: "CN", provincia: "MONTEZEMOLO", nazione: "ITALIA" },
  { codeKey: "F667", sigla: "OT", provincia: "MONTI", nazione: "ITALIA" },
  { codeKey: "F668", sigla: "FC", provincia: "MONTIANO", nazione: "ITALIA" },
  {
    codeKey: "F669",
    sigla: "CN",
    provincia: "MONTICELLO D'ALBA",
    nazione: "ITALIA",
  },
  {
    codeKey: "F670",
    sigla: "PV",
    provincia: "MONTICELLI PAVESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "F671",
    sigla: "PC",
    provincia: "MONTICELLI D'ONGINA",
    nazione: "ITALIA",
  },
  {
    codeKey: "F672",
    sigla: "BS",
    provincia: "MONTICELLI BRUSATI",
    nazione: "ITALIA",
  },
  {
    codeKey: "F674",
    sigla: "LC",
    provincia: "MONTICELLO BRIANZA",
    nazione: "ITALIA",
  },
  {
    codeKey: "F675",
    sigla: "VI",
    provincia: "MONTICELLO CONTE OTTO",
    nazione: "ITALIA",
  },
  { codeKey: "F676", sigla: "SI", provincia: "MONTICIANO", nazione: "ITALIA" },
  { codeKey: "F677", sigla: "GR", provincia: "MONTIERI", nazione: "ITALIA" },
  { codeKey: "F679", sigla: "MS", provincia: "MONTIGNOSO", nazione: "ITALIA" },
  { codeKey: "F680", sigla: "BS", provincia: "MONTIRONE", nazione: "ITALIA" },
  { codeKey: "F681", sigla: "CR", provincia: "MONTODINE", nazione: "ITALIA" },
  { codeKey: "F682", sigla: "GE", provincia: "MONTOGGIO", nazione: "ITALIA" },
  { codeKey: "F685", sigla: "PG", provincia: "MONTONE", nazione: "ITALIA" },
  {
    codeKey: "F686",
    sigla: "PI",
    provincia: "MONTOPOLI IN VAL D'ARNO",
    nazione: "ITALIA",
  },
  {
    codeKey: "F687",
    sigla: "RI",
    provincia: "MONTOPOLI DI SABINA",
    nazione: "ITALIA",
  },
  { codeKey: "F688", sigla: "CO", provincia: "MONTORFANO", nazione: "ITALIA" },
  {
    codeKey: "F689",
    sigla: "CB",
    provincia: "MONTORIO NEI FRENTANI",
    nazione: "ITALIA",
  },
  {
    codeKey: "F690",
    sigla: "TE",
    provincia: "MONTORIO AL VOMANO",
    nazione: "ITALIA",
  },
  { codeKey: "F692", sigla: "RM", provincia: "MONTORIO ROMANO", nazione: "ITALIA" },
  {
    codeKey: "F696",
    sigla: "VI",
    provincia: "MONTORSO VICENTINO",
    nazione: "ITALIA",
  },
  { codeKey: "F697", sigla: "FM", provincia: "MONTOTTONE", nazione: "ITALIA" },
  { codeKey: "F698", sigla: "NU", provincia: "MONTRESTA", nazione: "ITALIA" },
  { codeKey: "F701", sigla: "PV", provincia: "MONTU' BECCARIA", nazione: "ITALIA" },
  { codeKey: "F703", sigla: "VA", provincia: "MONVALLE", nazione: "ITALIA" },
  { codeKey: "F704", sigla: "MB", provincia: "MONZA", nazione: "ITALIA" },
  { codeKey: "F705", sigla: "MN", provincia: "MONZAMBANO", nazione: "ITALIA" },
  { codeKey: "F706", sigla: "BO", provincia: "MONZUNO", nazione: "ITALIA" },
  { codeKey: "F707", sigla: "AL", provincia: "MORANO SUL PO", nazione: "ITALIA" },
  { codeKey: "F708", sigla: "CS", provincia: "MORANO CALABRO", nazione: "ITALIA" },
  { codeKey: "F709", sigla: "AT", provincia: "MORANSENGO", nazione: "ITALIA" },
  { codeKey: "F710", sigla: "GO", provincia: "MORARO", nazione: "ITALIA" },
  { codeKey: "F711", sigla: "VA", provincia: "MORAZZONE", nazione: "ITALIA" },
  { codeKey: "F712", sigla: "SO", provincia: "MORBEGNO", nazione: "ITALIA" },
  { codeKey: "F713", sigla: "AL", provincia: "MORBELLO", nazione: "ITALIA" },
  {
    codeKey: "F715",
    sigla: "RN",
    provincia: "MORCIANO DI ROMAGNA",
    nazione: "ITALIA",
  },
  {
    codeKey: "F716",
    sigla: "LE",
    provincia: "MORCIANO DI LEUCA",
    nazione: "ITALIA",
  },
  { codeKey: "F717", sigla: "BN", provincia: "MORCONE", nazione: "ITALIA" },
  { codeKey: "F718", sigla: "BO", provincia: "MORDANO", nazione: "ITALIA" },
  { codeKey: "F720", sigla: "BG", provincia: "MORENGO", nazione: "ITALIA" },
  { codeKey: "F721", sigla: "SS", provincia: "MORES", nazione: "ITALIA" },
  { codeKey: "F722", sigla: "FM", provincia: "MORESCO", nazione: "ITALIA" },
  { codeKey: "F723", sigla: "CN", provincia: "MORETTA", nazione: "ITALIA" },
  { codeKey: "F724", sigla: "PC", provincia: "MORFASSO", nazione: "ITALIA" },
  { codeKey: "F725", sigla: "TV", provincia: "MORGANO", nazione: "ITALIA" },
  { codeKey: "F726", sigla: "AO", provincia: "MORGEX", nazione: "ITALIA" },
  { codeKey: "F727", sigla: "OR", provincia: "MORGONGIORI", nazione: "ITALIA" },
  { codeKey: "F728", sigla: "TN", provincia: "MORI", nazione: "ITALIA" },
  {
    codeKey: "F729",
    sigla: "TV",
    provincia: "MORIAGO DELLA BATTAGLIA",
    nazione: "ITALIA",
  },
  { codeKey: "F730", sigla: "RM", provincia: "MORICONE", nazione: "ITALIA" },
  { codeKey: "F731", sigla: "SA", provincia: "MORIGERATI", nazione: "ITALIA" },
  { codeKey: "F732", sigla: "AQ", provincia: "MORINO", nazione: "ITALIA" },
  {
    codeKey: "F733",
    sigla: "TO",
    provincia: "MORIONDO TORINESE",
    nazione: "ITALIA",
  },
  { codeKey: "F734", sigla: "RM", provincia: "MORLUPO", nazione: "ITALIA" },
  { codeKey: "F735", sigla: "CS", provincia: "MORMANNO", nazione: "ITALIA" },
  { codeKey: "F736", sigla: "VA", provincia: "MORNAGO", nazione: "ITALIA" },
  { codeKey: "F737", sigla: "AL", provincia: "MORNESE", nazione: "ITALIA" },
  { codeKey: "F738", sigla: "BG", provincia: "MORNICO AL SERIO", nazione: "ITALIA" },
  { codeKey: "F739", sigla: "PV", provincia: "MORNICO LOSANA", nazione: "ITALIA" },
  { codeKey: "F740", sigla: "FR", provincia: "MOROLO", nazione: "ITALIA" },
  { codeKey: "F743", sigla: "CN", provincia: "MOROZZO", nazione: "ITALIA" },
  { codeKey: "F744", sigla: "AV", provincia: "MORRA DE SANCTIS", nazione: "ITALIA" },
  { codeKey: "F745", sigla: "AN", provincia: "MORRO D'ALBA", nazione: "ITALIA" },
  { codeKey: "F746", sigla: "RI", provincia: "MORRO REATINO", nazione: "ITALIA" },
  { codeKey: "F747", sigla: "TE", provincia: "MORRO D'ORO", nazione: "ITALIA" },
  {
    codeKey: "F748",
    sigla: "CB",
    provincia: "MORRONE DEL SANNIO",
    nazione: "ITALIA",
  },
  { codeKey: "F749", sigla: "MC", provincia: "MORROVALLE", nazione: "ITALIA" },
  {
    codeKey: "F750",
    sigla: "PN",
    provincia: "MORSANO AL TAGLIAMENTO",
    nazione: "ITALIA",
  },
  { codeKey: "F751", sigla: "AL", provincia: "MORSASCO", nazione: "ITALIA" },
  { codeKey: "F754", sigla: "PV", provincia: "MORTARA", nazione: "ITALIA" },
  { codeKey: "F756", sigla: "UD", provincia: "MORTEGLIANO", nazione: "ITALIA" },
  { codeKey: "F758", sigla: "LC", provincia: "MORTERONE", nazione: "ITALIA" },
  { codeKey: "F760", sigla: "UD", provincia: "MORUZZO", nazione: "ITALIA" },
  { codeKey: "F761", sigla: "CR", provincia: "MOSCAZZANO", nazione: "ITALIA" },
  { codeKey: "F762", sigla: "AV", provincia: "MOSCHIANO", nazione: "ITALIA" },
  {
    codeKey: "F764",
    sigla: "TE",
    provincia: "MOSCIANO SANT'ANGELO",
    nazione: "ITALIA",
  },
  { codeKey: "F765", sigla: "PE", provincia: "MOSCUFO", nazione: "ITALIA" },
  { codeKey: "F766", sigla: "BZ", provincia: "MOSO IN PASSIRIA", nazione: "ITALIA" },
  { codeKey: "F767", sigla: "GO", provincia: "MOSSA", nazione: "ITALIA" },
  { codeKey: "F768", sigla: "VI", provincia: "MOSSANO", nazione: "ITALIA" },
  { codeKey: "F770", sigla: "TV", provincia: "MOTTA DI LIVENZA", nazione: "ITALIA" },
  { codeKey: "F771", sigla: "CR", provincia: "MOTTA BALUFFI", nazione: "ITALIA" },
  { codeKey: "F772", sigla: "ME", provincia: "MOTTA CAMASTRA", nazione: "ITALIA" },
  { codeKey: "F773", sigla: "ME", provincia: "MOTTA D'AFFERMO", nazione: "ITALIA" },
  { codeKey: "F774", sigla: "VC", provincia: "MOTTA DE' CONTI", nazione: "ITALIA" },
  { codeKey: "F775", sigla: "CS", provincia: "MOTTAFOLLONE", nazione: "ITALIA" },
  { codeKey: "F776", sigla: "BI", provincia: "MOTTALCIATA", nazione: "ITALIA" },
  {
    codeKey: "F777",
    sigla: "FG",
    provincia: "MOTTA MONTECORVINO",
    nazione: "ITALIA",
  },
  {
    codeKey: "F779",
    sigla: "RC",
    provincia: "MOTTA SAN GIOVANNI",
    nazione: "ITALIA",
  },
  {
    codeKey: "F780",
    sigla: "CZ",
    provincia: "MOTTA SANTA LUCIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "F781",
    sigla: "CT",
    provincia: "MOTTA SANT'ANASTASIA",
    nazione: "ITALIA",
  },
  { codeKey: "F783", sigla: "MI", provincia: "MOTTA VISCONTI", nazione: "ITALIA" },
  { codeKey: "F784", sigla: "TA", provincia: "MOTTOLA", nazione: "ITALIA" },
  { codeKey: "F785", sigla: "CH", provincia: "MOZZAGROGNA", nazione: "ITALIA" },
  { codeKey: "F786", sigla: "BG", provincia: "MOZZANICA", nazione: "ITALIA" },
  { codeKey: "F788", sigla: "CO", provincia: "MOZZATE", nazione: "ITALIA" },
  { codeKey: "F789", sigla: "VR", provincia: "MOZZECANE", nazione: "ITALIA" },
  { codeKey: "F791", sigla: "BG", provincia: "MOZZO", nazione: "ITALIA" },
  { codeKey: "F793", sigla: "MC", provincia: "MUCCIA", nazione: "ITALIA" },
  { codeKey: "F795", sigla: "TS", provincia: "MUGGIA", nazione: "ITALIA" },
  { codeKey: "F797", sigla: "MB", provincia: "MUGGIO'", nazione: "ITALIA" },
  {
    codeKey: "F798",
    sigla: "AV",
    provincia: "MUGNANO DEL CARDINALE",
    nazione: "ITALIA",
  },
  {
    codeKey: "F799",
    sigla: "NA",
    provincia: "MUGNANO DI NAPOLI",
    nazione: "ITALIA",
  },
  { codeKey: "F801", sigla: "LO", provincia: "MULAZZANO", nazione: "ITALIA" },
  { codeKey: "F802", sigla: "MS", provincia: "MULAZZO", nazione: "ITALIA" },
  { codeKey: "F804", sigla: "MN", provincia: "VILLA POMA", nazione: "ITALIA" },
  { codeKey: "F806", sigla: "BS", provincia: "MURA", nazione: "ITALIA" },
  { codeKey: "F808", sigla: "CA", provincia: "MURAVERA", nazione: "ITALIA" },
  { codeKey: "F809", sigla: "CN", provincia: "MURAZZANO", nazione: "ITALIA" },
  { codeKey: "F810", sigla: "VI", provincia: "SALCEDO", nazione: "ITALIA" },
  { codeKey: "F811", sigla: "CN", provincia: "MURELLO", nazione: "ITALIA" },
  { codeKey: "F813", sigla: "SV", provincia: "MURIALDO", nazione: "ITALIA" },
  { codeKey: "F814", sigla: "AL", provincia: "MURISENGO", nazione: "ITALIA" },
  { codeKey: "F815", sigla: "SI", provincia: "MURLO", nazione: "ITALIA" },
  { codeKey: "F816", sigla: "LE", provincia: "MURO LECCESE", nazione: "ITALIA" },
  { codeKey: "F817", sigla: "PZ", provincia: "MURO LUCANO", nazione: "ITALIA" },
  { codeKey: "F818", sigla: "SS", provincia: "MUROS", nazione: "ITALIA" },
  { codeKey: "F820", sigla: "BS", provincia: "MUSCOLINE", nazione: "ITALIA" },
  { codeKey: "F822", sigla: "CI", provincia: "MUSEI", nazione: "ITALIA" },
  { codeKey: "F826", sigla: "VE", provincia: "MUSILE DI PIAVE", nazione: "ITALIA" },
  { codeKey: "F828", sigla: "CO", provincia: "MUSSO", nazione: "ITALIA" },
  { codeKey: "F829", sigla: "VI", provincia: "MUSSOLENTE", nazione: "ITALIA" },
  { codeKey: "F830", sigla: "CL", provincia: "MUSSOMELI", nazione: "ITALIA" },
  { codeKey: "F831", sigla: "TE", provincia: "PINETO", nazione: "ITALIA" },
  {
    codeKey: "F832",
    sigla: "UD",
    provincia: "MUZZANA DEL TURGNANO",
    nazione: "ITALIA",
  },
  { codeKey: "F833", sigla: "BI", provincia: "MUZZANO", nazione: "ITALIA" },
  { codeKey: "F835", sigla: "TN", provincia: "NAGO-TORBOLE", nazione: "ITALIA" },
  { codeKey: "F836", sigla: "BZ", provincia: "NALLES", nazione: "ITALIA" },
  { codeKey: "F837", sigla: "TN", provincia: "NANNO", nazione: "ITALIA" },
  { codeKey: "F838", sigla: "VI", provincia: "NANTO", nazione: "ITALIA" },
  { codeKey: "F839", sigla: "NA", provincia: "NAPOLI", nazione: "ITALIA" },
  { codeKey: "F840", sigla: "OR", provincia: "NARBOLIA", nazione: "ITALIA" },
  { codeKey: "F841", sigla: "CI", provincia: "NARCAO", nazione: "ITALIA" },
  { codeKey: "F842", sigla: "LE", provincia: "NARDO'", nazione: "ITALIA" },
  { codeKey: "F843", sigla: "VV", provincia: "NARDODIPACE", nazione: "ITALIA" },
  { codeKey: "F844", sigla: "TR", provincia: "NARNI", nazione: "ITALIA" },
  { codeKey: "F845", sigla: "AG", provincia: "NARO", nazione: "ITALIA" },
  { codeKey: "F846", sigla: "CN", provincia: "NARZOLE", nazione: "ITALIA" },
  { codeKey: "F847", sigla: "SV", provincia: "NASINO", nazione: "ITALIA" },
  { codeKey: "F848", sigla: "ME", provincia: "NASO", nazione: "ITALIA" },
  { codeKey: "F849", sigla: "BZ", provincia: "NATURNO", nazione: "ITALIA" },
  { codeKey: "F851", sigla: "BS", provincia: "NAVE", nazione: "ITALIA" },
  { codeKey: "F852", sigla: "AQ", provincia: "NAVELLI", nazione: "ITALIA" },
  { codeKey: "F853", sigla: "TN", provincia: "NAVE SAN ROCCO", nazione: "ITALIA" },
  { codeKey: "F856", sigla: "BZ", provincia: "NAZ-SCIAVES", nazione: "ITALIA" },
  { codeKey: "F857", sigla: "RM", provincia: "NAZZANO", nazione: "ITALIA" },
  { codeKey: "F858", sigla: "GE", provincia: "NE", nazione: "ITALIA" },
  { codeKey: "F859", sigla: "NO", provincia: "NEBBIUNO", nazione: "ITALIA" },
  { codeKey: "F861", sigla: "VR", provincia: "NEGRAR", nazione: "ITALIA" },
  { codeKey: "F862", sigla: "GE", provincia: "NEIRONE", nazione: "ITALIA" },
  { codeKey: "F863", sigla: "CN", provincia: "NEIVE", nazione: "ITALIA" },
  { codeKey: "F864", sigla: "BG", provincia: "NEMBRO", nazione: "ITALIA" },
  { codeKey: "F865", sigla: "RM", provincia: "NEMI", nazione: "ITALIA" },
  { codeKey: "F866", sigla: "PZ", provincia: "NEMOLI", nazione: "ITALIA" },
  { codeKey: "F867", sigla: "OR", provincia: "NEONELI", nazione: "ITALIA" },
  { codeKey: "F868", sigla: "VT", provincia: "NEPI", nazione: "ITALIA" },
  { codeKey: "F870", sigla: "TE", provincia: "NERETO", nazione: "ITALIA" },
  { codeKey: "F871", sigla: "RM", provincia: "NEROLA", nazione: "ITALIA" },
  {
    codeKey: "F872",
    sigla: "TV",
    provincia: "NERVESA DELLA BATTAGLIA",
    nazione: "ITALIA",
  },
  { codeKey: "F874", sigla: "MI", provincia: "NERVIANO", nazione: "ITALIA" },
  { codeKey: "F876", sigla: "RI", provincia: "NESPOLO", nazione: "ITALIA" },
  { codeKey: "F877", sigla: "CO", provincia: "NESSO", nazione: "ITALIA" },
  { codeKey: "F878", sigla: "BI", provincia: "NETRO", nazione: "ITALIA" },
  { codeKey: "F880", sigla: "RM", provincia: "NETTUNO", nazione: "ITALIA" },
  { codeKey: "F881", sigla: "LE", provincia: "NEVIANO", nazione: "ITALIA" },
  {
    codeKey: "F882",
    sigla: "PR",
    provincia: "NEVIANO DEGLI ARDUINI",
    nazione: "ITALIA",
  },
  { codeKey: "F883", sigla: "CN", provincia: "NEVIGLIE", nazione: "ITALIA" },
  { codeKey: "F884", sigla: "BS", provincia: "NIARDO", nazione: "ITALIA" },
  { codeKey: "F885", sigla: "PC", provincia: "NIBBIANO", nazione: "ITALIA" },
  { codeKey: "F886", sigla: "NO", provincia: "NIBBIOLA", nazione: "ITALIA" },
  { codeKey: "F887", sigla: "LC", provincia: "NIBIONNO", nazione: "ITALIA" },
  { codeKey: "F889", sigla: "TO", provincia: "NICHELINO", nazione: "ITALIA" },
  { codeKey: "F890", sigla: "CT", provincia: "NICOLOSI", nazione: "ITALIA" },
  { codeKey: "F891", sigla: "PV", provincia: "NICORVO", nazione: "ITALIA" },
  { codeKey: "F892", sigla: "EN", provincia: "NICOSIA", nazione: "ITALIA" },
  { codeKey: "F893", sigla: "VV", provincia: "NICOTERA", nazione: "ITALIA" },
  { codeKey: "F894", sigla: "CN", provincia: "NIELLA BELBO", nazione: "ITALIA" },
  { codeKey: "F895", sigla: "CN", provincia: "NIELLA TANARO", nazione: "ITALIA" },
  { codeKey: "F898", sigla: "UD", provincia: "NIMIS", nazione: "ITALIA" },
  { codeKey: "F899", sigla: "CL", provincia: "NISCEMI", nazione: "ITALIA" },
  { codeKey: "F900", sigla: "EN", provincia: "NISSORIA", nazione: "ITALIA" },
  { codeKey: "F901", sigla: "ME", provincia: "NIZZA DI SICILIA", nazione: "ITALIA" },
  { codeKey: "F902", sigla: "AT", provincia: "NIZZA MONFERRATO", nazione: "ITALIA" },
  { codeKey: "F904", sigla: "VE", provincia: "NOALE", nazione: "ITALIA" },
  { codeKey: "F906", sigla: "TO", provincia: "NOASCA", nazione: "ITALIA" },
  { codeKey: "F907", sigla: "CS", provincia: "NOCARA", nazione: "ITALIA" },
  { codeKey: "F908", sigla: "PE", provincia: "NOCCIANO", nazione: "ITALIA" },
  { codeKey: "F910", sigla: "CZ", provincia: "NOCERA TERINESE", nazione: "ITALIA" },
  { codeKey: "F911", sigla: "PG", provincia: "NOCERA UMBRA", nazione: "ITALIA" },
  { codeKey: "F912", sigla: "SA", provincia: "NOCERA INFERIORE", nazione: "ITALIA" },
  { codeKey: "F913", sigla: "SA", provincia: "NOCERA SUPERIORE", nazione: "ITALIA" },
  { codeKey: "F914", sigla: "PR", provincia: "NOCETO", nazione: "ITALIA" },
  { codeKey: "F915", sigla: "BA", provincia: "NOCI", nazione: "ITALIA" },
  { codeKey: "F916", sigla: "LE", provincia: "NOCIGLIA", nazione: "ITALIA" },
  { codeKey: "F917", sigla: "PZ", provincia: "NOEPOLI", nazione: "ITALIA" },
  { codeKey: "F918", sigla: "VR", provincia: "NOGARA", nazione: "ITALIA" },
  { codeKey: "F920", sigla: "TN", provincia: "NOGAREDO", nazione: "ITALIA" },
  { codeKey: "F921", sigla: "VR", provincia: "NOGAROLE ROCCA", nazione: "ITALIA" },
  {
    codeKey: "F922",
    sigla: "VI",
    provincia: "NOGAROLE VICENTINO",
    nazione: "ITALIA",
  },
  { codeKey: "F923", sigla: "BA", provincia: "NOICATTARO", nazione: "ITALIA" },
  { codeKey: "F924", sigla: "NA", provincia: "NOLA", nazione: "ITALIA" },
  { codeKey: "F925", sigla: "TO", provincia: "NOLE", nazione: "ITALIA" },
  { codeKey: "F926", sigla: "SV", provincia: "NOLI", nazione: "ITALIA" },
  { codeKey: "F927", sigla: "TO", provincia: "NOMAGLIO", nazione: "ITALIA" },
  { codeKey: "F929", sigla: "TN", provincia: "NOMI", nazione: "ITALIA" },
  { codeKey: "F930", sigla: "MO", provincia: "NONANTOLA", nazione: "ITALIA" },
  { codeKey: "F931", sigla: "TO", provincia: "NONE", nazione: "ITALIA" },
  { codeKey: "F932", sigla: "VB", provincia: "NONIO", nazione: "ITALIA" },
  { codeKey: "F933", sigla: "NU", provincia: "NORAGUGUME", nazione: "ITALIA" },
  { codeKey: "F934", sigla: "OR", provincia: "NORBELLO", nazione: "ITALIA" },
  { codeKey: "F935", sigla: "PG", provincia: "NORCIA", nazione: "ITALIA" },
  { codeKey: "F937", sigla: "LT", provincia: "NORMA", nazione: "ITALIA" },
  { codeKey: "F939", sigla: "MI", provincia: "NOSATE", nazione: "ITALIA" },
  { codeKey: "F941", sigla: "BG", provincia: "PONTE NOSSA", nazione: "ITALIA" },
  { codeKey: "F942", sigla: "TE", provincia: "NOTARESCO", nazione: "ITALIA" },
  { codeKey: "F943", sigla: "SR", provincia: "NOTO", nazione: "ITALIA" },
  { codeKey: "F944", sigla: "MB", provincia: "NOVA MILANESE", nazione: "ITALIA" },
  { codeKey: "F947", sigla: "TN", provincia: "NOVALEDO", nazione: "ITALIA" },
  { codeKey: "F948", sigla: "TO", provincia: "NOVALESA", nazione: "ITALIA" },
  { codeKey: "F949", sigla: "BZ", provincia: "NOVA LEVANTE", nazione: "ITALIA" },
  { codeKey: "F950", sigla: "BZ", provincia: "NOVA PONENTE", nazione: "ITALIA" },
  {
    codeKey: "F951",
    sigla: "ME",
    provincia: "NOVARA DI SICILIA",
    nazione: "ITALIA",
  },
  { codeKey: "F952", sigla: "NO", provincia: "NOVARA", nazione: "ITALIA" },
  { codeKey: "F955", sigla: "MI", provincia: "NOVATE MILANESE", nazione: "ITALIA" },
  { codeKey: "F956", sigla: "SO", provincia: "NOVATE MEZZOLA", nazione: "ITALIA" },
  { codeKey: "F957", sigla: "VI", provincia: "NOVE", nazione: "ITALIA" },
  { codeKey: "F958", sigla: "CO", provincia: "NOVEDRATE", nazione: "ITALIA" },
  { codeKey: "F960", sigla: "RE", provincia: "NOVELLARA", nazione: "ITALIA" },
  { codeKey: "F961", sigla: "CN", provincia: "NOVELLO", nazione: "ITALIA" },
  { codeKey: "F962", sigla: "PD", provincia: "NOVENTA PADOVANA", nazione: "ITALIA" },
  { codeKey: "F963", sigla: "VE", provincia: "NOVENTA DI PIAVE", nazione: "ITALIA" },
  {
    codeKey: "F964",
    sigla: "VI",
    provincia: "NOVENTA VICENTINA",
    nazione: "ITALIA",
  },
  { codeKey: "F965", sigla: "AL", provincia: "NOVI LIGURE", nazione: "ITALIA" },
  { codeKey: "F966", sigla: "MO", provincia: "NOVI DI MODENA", nazione: "ITALIA" },
  { codeKey: "F967", sigla: "SA", provincia: "NOVI VELIA", nazione: "ITALIA" },
  { codeKey: "F968", sigla: "MI", provincia: "NOVIGLIO", nazione: "ITALIA" },
  { codeKey: "F970", sigla: "LE", provincia: "NOVOLI", nazione: "ITALIA" },
  { codeKey: "F972", sigla: "CN", provincia: "NUCETTO", nazione: "ITALIA" },
  {
    codeKey: "F974",
    sigla: "OR",
    provincia: "NUGHEDU SANTA VITTORIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "F975",
    sigla: "SS",
    provincia: "NUGHEDU SAN NICOLO'",
    nazione: "ITALIA",
  },
  { codeKey: "F976", sigla: "SS", provincia: "NULE", nazione: "ITALIA" },
  { codeKey: "F977", sigla: "SS", provincia: "NULVI", nazione: "ITALIA" },
  { codeKey: "F978", sigla: "AN", provincia: "NUMANA", nazione: "ITALIA" },
  { codeKey: "F979", sigla: "NU", provincia: "NUORO", nazione: "ITALIA" },
  { codeKey: "F980", sigla: "OR", provincia: "NURACHI", nazione: "ITALIA" },
  { codeKey: "F981", sigla: "NU", provincia: "NURAGUS", nazione: "ITALIA" },
  { codeKey: "F982", sigla: "NU", provincia: "NURALLAO", nazione: "ITALIA" },
  { codeKey: "F983", sigla: "CA", provincia: "NURAMINIS", nazione: "ITALIA" },
  { codeKey: "F985", sigla: "OR", provincia: "NURECI", nazione: "ITALIA" },
  { codeKey: "F986", sigla: "NU", provincia: "NURRI", nazione: "ITALIA" },
  { codeKey: "F987", sigla: "AO", provincia: "NUS", nazione: "ITALIA" },
  { codeKey: "F988", sigla: "AV", provincia: "NUSCO", nazione: "ITALIA" },
  { codeKey: "F989", sigla: "BS", provincia: "NUVOLENTO", nazione: "ITALIA" },
  { codeKey: "F990", sigla: "BS", provincia: "NUVOLERA", nazione: "ITALIA" },
  { codeKey: "F991", sigla: "CI", provincia: "NUXIS", nazione: "ITALIA" },
  {
    codeKey: "F992",
    sigla: "BI",
    provincia: "OCCHIEPPO INFERIORE",
    nazione: "ITALIA",
  },
  {
    codeKey: "F993",
    sigla: "BI",
    provincia: "OCCHIEPPO SUPERIORE",
    nazione: "ITALIA",
  },
  { codeKey: "F994", sigla: "RO", provincia: "OCCHIOBELLO", nazione: "ITALIA" },
  { codeKey: "F995", sigla: "AL", provincia: "OCCIMIANO", nazione: "ITALIA" },
  { codeKey: "F996", sigla: "AQ", provincia: "OCRE", nazione: "ITALIA" },
  { codeKey: "F997", sigla: "AL", provincia: "ODALENGO GRANDE", nazione: "ITALIA" },
  { codeKey: "F998", sigla: "AL", provincia: "ODALENGO PICCOLO", nazione: "ITALIA" },
  { codeKey: "F999", sigla: "TV", provincia: "ODERZO", nazione: "ITALIA" },
  { codeKey: "G001", sigla: "BS", provincia: "ODOLO", nazione: "ITALIA" },
  { codeKey: "G002", sigla: "AQ", provincia: "OFENA", nazione: "ITALIA" },
  { codeKey: "G003", sigla: "AN", provincia: "OFFAGNA", nazione: "ITALIA" },
  { codeKey: "G004", sigla: "CR", provincia: "OFFANENGO", nazione: "ITALIA" },
  { codeKey: "G005", sigla: "AP", provincia: "OFFIDA", nazione: "ITALIA" },
  { codeKey: "G006", sigla: "BS", provincia: "OFFLAGA", nazione: "ITALIA" },
  { codeKey: "G007", sigla: "VB", provincia: "OGGEBBIO", nazione: "ITALIA" },
  {
    codeKey: "G008",
    sigla: "VA",
    provincia: "OGGIONA CON SANTO STEFANO",
    nazione: "ITALIA",
  },
  { codeKey: "G009", sigla: "LC", provincia: "OGGIONO", nazione: "ITALIA" },
  { codeKey: "G010", sigla: "TO", provincia: "OGLIANICO", nazione: "ITALIA" },
  {
    codeKey: "G011",
    sigla: "SA",
    provincia: "OGLIASTRO CILENTO",
    nazione: "ITALIA",
  },
  { codeKey: "G012", sigla: "AO", provincia: "OYACE", nazione: "ITALIA" },
  { codeKey: "G015", sigla: "OT", provincia: "OLBIA", nazione: "ITALIA" },
  { codeKey: "G016", sigla: "VC", provincia: "OLCENENGO", nazione: "ITALIA" },
  { codeKey: "G018", sigla: "VC", provincia: "OLDENICO", nazione: "ITALIA" },
  { codeKey: "G019", sigla: "NO", provincia: "OLEGGIO", nazione: "ITALIA" },
  { codeKey: "G020", sigla: "NO", provincia: "OLEGGIO CASTELLO", nazione: "ITALIA" },
  {
    codeKey: "G021",
    sigla: "PV",
    provincia: "OLEVANO DI LOMELLINA",
    nazione: "ITALIA",
  },
  { codeKey: "G022", sigla: "RM", provincia: "OLEVANO ROMANO", nazione: "ITALIA" },
  {
    codeKey: "G023",
    sigla: "SA",
    provincia: "OLEVANO SUL TUSCIANO",
    nazione: "ITALIA",
  },
  { codeKey: "G025", sigla: "CO", provincia: "OLGIATE COMASCO", nazione: "ITALIA" },
  { codeKey: "G026", sigla: "LC", provincia: "OLGIATE MOLGORA", nazione: "ITALIA" },
  { codeKey: "G028", sigla: "VA", provincia: "OLGIATE OLONA", nazione: "ITALIA" },
  { codeKey: "G030", sigla: "LC", provincia: "OLGINATE", nazione: "ITALIA" },
  { codeKey: "G031", sigla: "NU", provincia: "OLIENA", nazione: "ITALIA" },
  { codeKey: "G032", sigla: "PV", provincia: "OLIVA GESSI", nazione: "ITALIA" },
  { codeKey: "G034", sigla: "CZ", provincia: "OLIVADI", nazione: "ITALIA" },
  { codeKey: "G036", sigla: "ME", provincia: "OLIVERI", nazione: "ITALIA" },
  { codeKey: "G037", sigla: "MT", provincia: "OLIVETO LUCANO", nazione: "ITALIA" },
  { codeKey: "G039", sigla: "SA", provincia: "OLIVETO CITRA", nazione: "ITALIA" },
  { codeKey: "G040", sigla: "LC", provincia: "OLIVETO LARIO", nazione: "ITALIA" },
  {
    codeKey: "G041",
    sigla: "IM",
    provincia: "OLIVETTA SAN MICHELE",
    nazione: "ITALIA",
  },
  { codeKey: "G042", sigla: "AL", provincia: "OLIVOLA", nazione: "ITALIA" },
  { codeKey: "G043", sigla: "OR", provincia: "OLLASTRA", nazione: "ITALIA" },
  { codeKey: "G044", sigla: "NU", provincia: "OLLOLAI", nazione: "ITALIA" },
  { codeKey: "G045", sigla: "AO", provincia: "OLLOMONT", nazione: "ITALIA" },
  { codeKey: "G046", sigla: "SS", provincia: "OLMEDO", nazione: "ITALIA" },
  { codeKey: "G047", sigla: "CR", provincia: "OLMENETA", nazione: "ITALIA" },
  { codeKey: "G048", sigla: "AT", provincia: "OLMO GENTILE", nazione: "ITALIA" },
  { codeKey: "G049", sigla: "BG", provincia: "OLMO AL BREMBO", nazione: "ITALIA" },
  { codeKey: "G050", sigla: "BG", provincia: "OLTRE IL COLLE", nazione: "ITALIA" },
  { codeKey: "G054", sigla: "BG", provincia: "OLTRESSENDA ALTA", nazione: "ITALIA" },
  {
    codeKey: "G056",
    sigla: "CO",
    provincia: "OLTRONA DI SAN MAMETTE",
    nazione: "ITALIA",
  },
  { codeKey: "G058", sigla: "NU", provincia: "OLZAI", nazione: "ITALIA" },
  { codeKey: "G061", sigla: "BS", provincia: "OME", nazione: "ITALIA" },
  { codeKey: "G062", sigla: "VB", provincia: "OMEGNA", nazione: "ITALIA" },
  { codeKey: "G063", sigla: "SA", provincia: "OMIGNANO", nazione: "ITALIA" },
  { codeKey: "G064", sigla: "NU", provincia: "ONANI", nazione: "ITALIA" },
  { codeKey: "G065", sigla: "VT", provincia: "ONANO", nazione: "ITALIA" },
  { codeKey: "G066", sigla: "CN", provincia: "ONCINO", nazione: "ITALIA" },
  { codeKey: "G068", sigla: "BG", provincia: "ONETA", nazione: "ITALIA" },
  { codeKey: "G070", sigla: "NU", provincia: "ONIFAI", nazione: "ITALIA" },
  { codeKey: "G071", sigla: "NU", provincia: "ONIFERI", nazione: "ITALIA" },
  { codeKey: "G074", sigla: "BS", provincia: "ONO SAN PIETRO", nazione: "ITALIA" },
  { codeKey: "G075", sigla: "BG", provincia: "ONORE", nazione: "ITALIA" },
  { codeKey: "G076", sigla: "SV", provincia: "ONZO", nazione: "ITALIA" },
  { codeKey: "G078", sigla: "MI", provincia: "OPERA", nazione: "ITALIA" },
  { codeKey: "G079", sigla: "AQ", provincia: "OPI", nazione: "ITALIA" },
  { codeKey: "G080", sigla: "VR", provincia: "OPPEANO", nazione: "ITALIA" },
  { codeKey: "G081", sigla: "PZ", provincia: "OPPIDO LUCANO", nazione: "ITALIA" },
  { codeKey: "G082", sigla: "RC", provincia: "OPPIDO MAMERTINA", nazione: "ITALIA" },
  { codeKey: "G083", sigla: "BZ", provincia: "ORA", nazione: "ITALIA" },
  { codeKey: "G084", sigla: "NU", provincia: "ORANI", nazione: "ITALIA" },
  { codeKey: "G086", sigla: "CB", provincia: "ORATINO", nazione: "ITALIA" },
  { codeKey: "G087", sigla: "TO", provincia: "ORBASSANO", nazione: "ITALIA" },
  { codeKey: "G088", sigla: "GR", provincia: "ORBETELLO", nazione: "ITALIA" },
  {
    codeKey: "G089",
    sigla: "PU",
    provincia: "ORCIANO DI PESARO",
    nazione: "ITALIA",
  },
  { codeKey: "G090", sigla: "PI", provincia: "ORCIANO PISANO", nazione: "ITALIA" },
  { codeKey: "G093", sigla: "GE", provincia: "ORERO", nazione: "ITALIA" },
  { codeKey: "G095", sigla: "VI", provincia: "ORGIANO", nazione: "ITALIA" },
  { codeKey: "G096", sigla: "LO", provincia: "PIEVE FISSIRAGA", nazione: "ITALIA" },
  { codeKey: "G097", sigla: "NU", provincia: "ORGOSOLO", nazione: "ITALIA" },
  { codeKey: "G098", sigla: "BR", provincia: "ORIA", nazione: "ITALIA" },
  { codeKey: "G102", sigla: "AQ", provincia: "ORICOLA", nazione: "ITALIA" },
  { codeKey: "G103", sigla: "VA", provincia: "ORIGGIO", nazione: "ITALIA" },
  { codeKey: "G105", sigla: "VA", provincia: "ORINO", nazione: "ITALIA" },
  { codeKey: "G107", sigla: "LO", provincia: "ORIO LITTA", nazione: "ITALIA" },
  { codeKey: "G108", sigla: "BG", provincia: "ORIO AL SERIO", nazione: "ITALIA" },
  { codeKey: "G109", sigla: "TO", provincia: "ORIO CANAVESE", nazione: "ITALIA" },
  { codeKey: "G110", sigla: "CS", provincia: "ORIOLO", nazione: "ITALIA" },
  { codeKey: "G111", sigla: "VT", provincia: "ORIOLO ROMANO", nazione: "ITALIA" },
  { codeKey: "G113", sigla: "OR", provincia: "ORISTANO", nazione: "ITALIA" },
  { codeKey: "G114", sigla: "CN", provincia: "ORMEA", nazione: "ITALIA" },
  { codeKey: "G115", sigla: "TV", provincia: "ORMELLE", nazione: "ITALIA" },
  { codeKey: "G116", sigla: "MB", provincia: "ORNAGO", nazione: "ITALIA" },
  { codeKey: "G117", sigla: "VB", provincia: "ORNAVASSO", nazione: "ITALIA" },
  { codeKey: "G118", sigla: "BG", provincia: "ORNICA", nazione: "ITALIA" },
  { codeKey: "G119", sigla: "NU", provincia: "OROSEI", nazione: "ITALIA" },
  { codeKey: "G120", sigla: "NU", provincia: "OROTELLI", nazione: "ITALIA" },
  { codeKey: "G121", sigla: "SA", provincia: "ORRIA", nazione: "ITALIA" },
  { codeKey: "G122", sigla: "NU", provincia: "ORROLI", nazione: "ITALIA" },
  { codeKey: "G123", sigla: "TV", provincia: "ORSAGO", nazione: "ITALIA" },
  { codeKey: "G124", sigla: "AL", provincia: "ORSARA BORMIDA", nazione: "ITALIA" },
  { codeKey: "G125", sigla: "FG", provincia: "ORSARA DI PUGLIA", nazione: "ITALIA" },
  { codeKey: "G126", sigla: "CO", provincia: "ORSENIGO", nazione: "ITALIA" },
  { codeKey: "G128", sigla: "CH", provincia: "ORSOGNA", nazione: "ITALIA" },
  { codeKey: "G129", sigla: "CS", provincia: "ORSOMARSO", nazione: "ITALIA" },
  { codeKey: "G130", sigla: "CE", provincia: "ORTA DI ATELLA", nazione: "ITALIA" },
  { codeKey: "G131", sigla: "FG", provincia: "ORTA NOVA", nazione: "ITALIA" },
  { codeKey: "G133", sigla: "CA", provincia: "ORTACESUS", nazione: "ITALIA" },
  { codeKey: "G134", sigla: "NO", provincia: "ORTA SAN GIULIO", nazione: "ITALIA" },
  { codeKey: "G135", sigla: "VT", provincia: "ORTE", nazione: "ITALIA" },
  { codeKey: "G136", sigla: "LE", provincia: "ORTELLE", nazione: "ITALIA" },
  { codeKey: "G137", sigla: "FM", provincia: "ORTEZZANO", nazione: "ITALIA" },
  {
    codeKey: "G139",
    sigla: "AR",
    provincia: "ORTIGNANO RAGGIOLO",
    nazione: "ITALIA",
  },
  { codeKey: "G140", sigla: "BZ", provincia: "ORTISEI", nazione: "ITALIA" },
  { codeKey: "G141", sigla: "CH", provincia: "ORTONA", nazione: "ITALIA" },
  { codeKey: "G142", sigla: "AQ", provincia: "ORTONA DEI MARSI", nazione: "ITALIA" },
  { codeKey: "G143", sigla: "SP", provincia: "ORTONOVO", nazione: "ITALIA" },
  { codeKey: "G144", sigla: "SV", provincia: "ORTOVERO", nazione: "ITALIA" },
  { codeKey: "G145", sigla: "AQ", provincia: "ORTUCCHIO", nazione: "ITALIA" },
  { codeKey: "G146", sigla: "NU", provincia: "ORTUERI", nazione: "ITALIA" },
  { codeKey: "G147", sigla: "NU", provincia: "ORUNE", nazione: "ITALIA" },
  { codeKey: "G148", sigla: "TR", provincia: "ORVIETO", nazione: "ITALIA" },
  { codeKey: "G149", sigla: "BS", provincia: "ORZINUOVI", nazione: "ITALIA" },
  { codeKey: "G150", sigla: "BS", provincia: "ORZIVECCHI", nazione: "ITALIA" },
  { codeKey: "G151", sigla: "TO", provincia: "OSASCO", nazione: "ITALIA" },
  { codeKey: "G152", sigla: "TO", provincia: "OSASIO", nazione: "ITALIA" },
  { codeKey: "G153", sigla: "OT", provincia: "OSCHIRI", nazione: "ITALIA" },
  { codeKey: "G154", sigla: "NU", provincia: "OSIDDA", nazione: "ITALIA" },
  { codeKey: "G155", sigla: "SV", provincia: "OSIGLIA", nazione: "ITALIA" },
  { codeKey: "G156", sigla: "SS", provincia: "OSILO", nazione: "ITALIA" },
  { codeKey: "G157", sigla: "AN", provincia: "OSIMO", nazione: "ITALIA" },
  { codeKey: "G158", sigla: "OG", provincia: "OSINI", nazione: "ITALIA" },
  { codeKey: "G159", sigla: "BG", provincia: "OSIO SOPRA", nazione: "ITALIA" },
  { codeKey: "G160", sigla: "BG", provincia: "OSIO SOTTO", nazione: "ITALIA" },
  { codeKey: "G161", sigla: "LC", provincia: "OSNAGO", nazione: "ITALIA" },
  { codeKey: "G163", sigla: "UD", provincia: "OSOPPO", nazione: "ITALIA" },
  { codeKey: "G164", sigla: "IM", provincia: "OSPEDALETTI", nazione: "ITALIA" },
  {
    codeKey: "G165",
    sigla: "AV",
    provincia: "OSPEDALETTO D'ALPINOLO",
    nazione: "ITALIA",
  },
  {
    codeKey: "G166",
    sigla: "LO",
    provincia: "OSPEDALETTO LODIGIANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "G167",
    sigla: "PD",
    provincia: "OSPEDALETTO EUGANEO",
    nazione: "ITALIA",
  },
  { codeKey: "G168", sigla: "TN", provincia: "OSPEDALETTO", nazione: "ITALIA" },
  {
    codeKey: "G169",
    sigla: "BL",
    provincia: "OSPITALE DI CADORE",
    nazione: "ITALIA",
  },
  { codeKey: "G170", sigla: "BS", provincia: "OSPITALETTO", nazione: "ITALIA" },
  { codeKey: "G171", sigla: "LO", provincia: "OSSAGO LODIGIANO", nazione: "ITALIA" },
  { codeKey: "G173", sigla: "TN", provincia: "OSSANA", nazione: "ITALIA" },
  { codeKey: "G178", sigla: "SS", provincia: "OSSI", nazione: "ITALIA" },
  { codeKey: "G179", sigla: "BS", provincia: "OSSIMO", nazione: "ITALIA" },
  { codeKey: "G181", sigla: "MI", provincia: "OSSONA", nazione: "ITALIA" },
  { codeKey: "G183", sigla: "CN", provincia: "OSTANA", nazione: "ITALIA" },
  { codeKey: "G184", sigla: "FE", provincia: "OSTELLATO", nazione: "ITALIA" },
  { codeKey: "G185", sigla: "CR", provincia: "OSTIANO", nazione: "ITALIA" },
  { codeKey: "G186", sigla: "MN", provincia: "OSTIGLIA", nazione: "ITALIA" },
  { codeKey: "G187", sigla: "BR", provincia: "OSTUNI", nazione: "ITALIA" },
  { codeKey: "G188", sigla: "LE", provincia: "OTRANTO", nazione: "ITALIA" },
  { codeKey: "G189", sigla: "TR", provincia: "OTRICOLI", nazione: "ITALIA" },
  { codeKey: "G190", sigla: "NA", provincia: "OTTAVIANO", nazione: "ITALIA" },
  { codeKey: "G191", sigla: "NU", provincia: "OTTANA", nazione: "ITALIA" },
  { codeKey: "G192", sigla: "SA", provincia: "OTTATI", nazione: "ITALIA" },
  { codeKey: "G193", sigla: "AL", provincia: "OTTIGLIO", nazione: "ITALIA" },
  { codeKey: "G194", sigla: "PV", provincia: "OTTOBIANO", nazione: "ITALIA" },
  { codeKey: "G195", sigla: "PC", provincia: "OTTONE", nazione: "ITALIA" },
  { codeKey: "G196", sigla: "TO", provincia: "OULX", nazione: "ITALIA" },
  { codeKey: "G197", sigla: "AL", provincia: "OVADA", nazione: "ITALIA" },
  { codeKey: "G198", sigla: "UD", provincia: "OVARO", nazione: "ITALIA" },
  { codeKey: "G199", sigla: "AL", provincia: "OVIGLIO", nazione: "ITALIA" },
  { codeKey: "G200", sigla: "AQ", provincia: "OVINDOLI", nazione: "ITALIA" },
  { codeKey: "G201", sigla: "NU", provincia: "OVODDA", nazione: "ITALIA" },
  { codeKey: "G202", sigla: "TO", provincia: "OZEGNA", nazione: "ITALIA" },
  { codeKey: "G203", sigla: "SS", provincia: "OZIERI", nazione: "ITALIA" },
  {
    codeKey: "G204",
    sigla: "AL",
    provincia: "OZZANO MONFERRATO",
    nazione: "ITALIA",
  },
  {
    codeKey: "G205",
    sigla: "BO",
    provincia: "OZZANO DELL'EMILIA",
    nazione: "ITALIA",
  },
  { codeKey: "G206", sigla: "MI", provincia: "OZZERO", nazione: "ITALIA" },
  { codeKey: "G207", sigla: "VS", provincia: "PABILLONIS", nazione: "ITALIA" },
  { codeKey: "G208", sigla: "TP", provincia: "PACECO", nazione: "ITALIA" },
  { codeKey: "G209", sigla: "ME", provincia: "PACE DEL MELA", nazione: "ITALIA" },
  { codeKey: "G210", sigla: "AQ", provincia: "PACENTRO", nazione: "ITALIA" },
  { codeKey: "G211", sigla: "SR", provincia: "PACHINO", nazione: "ITALIA" },
  { codeKey: "G212", sigla: "PG", provincia: "PACIANO", nazione: "ITALIA" },
  {
    codeKey: "G213",
    sigla: "BS",
    provincia: "PADENGHE SUL GARDA",
    nazione: "ITALIA",
  },
  { codeKey: "G214", sigla: "TN", provincia: "PADERGNONE", nazione: "ITALIA" },
  { codeKey: "G215", sigla: "AL", provincia: "PADERNA", nazione: "ITALIA" },
  {
    codeKey: "G217",
    sigla: "BS",
    provincia: "PADERNO FRANCIACORTA",
    nazione: "ITALIA",
  },
  { codeKey: "G218", sigla: "LC", provincia: "PADERNO D'ADDA", nazione: "ITALIA" },
  { codeKey: "G220", sigla: "MI", provincia: "PADERNO DUGNANO", nazione: "ITALIA" },
  {
    codeKey: "G221",
    sigla: "TV",
    provincia: "PADERNO DEL GRAPPA",
    nazione: "ITALIA",
  },
  {
    codeKey: "G222",
    sigla: "CR",
    provincia: "PADERNO PONCHIELLI",
    nazione: "ITALIA",
  },
  { codeKey: "G223", sigla: "LC", provincia: "ROBBIATE", nazione: "ITALIA" },
  { codeKey: "G224", sigla: "PD", provincia: "PADOVA", nazione: "ITALIA" },
  { codeKey: "G225", sigla: "SS", provincia: "PADRIA", nazione: "ITALIA" },
  { codeKey: "G226", sigla: "SA", provincia: "PADULA", nazione: "ITALIA" },
  { codeKey: "G227", sigla: "BN", provincia: "PADULI", nazione: "ITALIA" },
  { codeKey: "G228", sigla: "CN", provincia: "PAESANA", nazione: "ITALIA" },
  { codeKey: "G229", sigla: "TV", provincia: "PAESE", nazione: "ITALIA" },
  { codeKey: "G230", sigla: "SA", provincia: "PAGANI", nazione: "ITALIA" },
  { codeKey: "G232", sigla: "RI", provincia: "PAGANICO SABINO", nazione: "ITALIA" },
  { codeKey: "G233", sigla: "BG", provincia: "PAGAZZANO", nazione: "ITALIA" },
  { codeKey: "G234", sigla: "ME", provincia: "PAGLIARA", nazione: "ITALIA" },
  { codeKey: "G237", sigla: "CH", provincia: "PAGLIETA", nazione: "ITALIA" },
  { codeKey: "G238", sigla: "UD", provincia: "PAGNACCO", nazione: "ITALIA" },
  { codeKey: "G240", sigla: "CN", provincia: "PAGNO", nazione: "ITALIA" },
  { codeKey: "G241", sigla: "LC", provincia: "PAGNONA", nazione: "ITALIA" },
  {
    codeKey: "G242",
    sigla: "AV",
    provincia: "PAGO DEL VALLO DI LAURO",
    nazione: "ITALIA",
  },
  { codeKey: "G243", sigla: "BN", provincia: "PAGO VEIANO", nazione: "ITALIA" },
  { codeKey: "G247", sigla: "BS", provincia: "PAISCO LOVENO", nazione: "ITALIA" },
  { codeKey: "G248", sigla: "BS", provincia: "PAITONE", nazione: "ITALIA" },
  { codeKey: "G249", sigla: "BG", provincia: "PALADINA", nazione: "ITALIA" },
  { codeKey: "G250", sigla: "MO", provincia: "PALAGANO", nazione: "ITALIA" },
  { codeKey: "G251", sigla: "TA", provincia: "PALAGIANELLO", nazione: "ITALIA" },
  { codeKey: "G252", sigla: "TA", provincia: "PALAGIANO", nazione: "ITALIA" },
  { codeKey: "G253", sigla: "CT", provincia: "PALAGONIA", nazione: "ITALIA" },
  { codeKey: "G254", sigla: "PI", provincia: "PALAIA", nazione: "ITALIA" },
  { codeKey: "G255", sigla: "PR", provincia: "PALANZANO", nazione: "ITALIA" },
  { codeKey: "G257", sigla: "CB", provincia: "PALATA", nazione: "ITALIA" },
  { codeKey: "G258", sigla: "OT", provincia: "PALAU", nazione: "ITALIA" },
  { codeKey: "G259", sigla: "BG", provincia: "PALAZZAGO", nazione: "ITALIA" },
  { codeKey: "G260", sigla: "CR", provincia: "PALAZZO PIGNANO", nazione: "ITALIA" },
  {
    codeKey: "G261",
    sigla: "PZ",
    provincia: "PALAZZO SAN GERVASIO",
    nazione: "ITALIA",
  },
  { codeKey: "G262", sigla: "TO", provincia: "PALAZZO CANAVESE", nazione: "ITALIA" },
  { codeKey: "G263", sigla: "PA", provincia: "PALAZZO ADRIANO", nazione: "ITALIA" },
  {
    codeKey: "G264",
    sigla: "BS",
    provincia: "PALAZZOLO SULL'OGLIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "G266",
    sigla: "VC",
    provincia: "PALAZZOLO VERCELLESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "G267",
    sigla: "SR",
    provincia: "PALAZZOLO ACREIDE",
    nazione: "ITALIA",
  },
  {
    codeKey: "G268",
    sigla: "UD",
    provincia: "PALAZZOLO DELLO STELLA",
    nazione: "ITALIA",
  },
  {
    codeKey: "G270",
    sigla: "FI",
    provincia: "PALAZZUOLO SUL SENIO",
    nazione: "ITALIA",
  },
  { codeKey: "G271", sigla: "CH", provincia: "PALENA", nazione: "ITALIA" },
  { codeKey: "G272", sigla: "CZ", provincia: "PALERMITI", nazione: "ITALIA" },
  { codeKey: "G273", sigla: "PA", provincia: "PALERMO", nazione: "ITALIA" },
  { codeKey: "G274", sigla: "RM", provincia: "PALESTRINA", nazione: "ITALIA" },
  { codeKey: "G275", sigla: "PV", provincia: "PALESTRO", nazione: "ITALIA" },
  { codeKey: "G276", sigla: "FR", provincia: "PALIANO", nazione: "ITALIA" },
  { codeKey: "G277", sigla: "RC", provincia: "PALIZZI", nazione: "ITALIA" },
  { codeKey: "G278", sigla: "KR", provincia: "PALLAGORIO", nazione: "ITALIA" },
  { codeKey: "G280", sigla: "VB", provincia: "PALLANZENO", nazione: "ITALIA" },
  { codeKey: "G281", sigla: "SV", provincia: "PALLARE", nazione: "ITALIA" },
  {
    codeKey: "G282",
    sigla: "AG",
    provincia: "PALMA DI MONTECHIARO",
    nazione: "ITALIA",
  },
  { codeKey: "G283", sigla: "NA", provincia: "PALMA CAMPANIA", nazione: "ITALIA" },
  { codeKey: "G284", sigla: "UD", provincia: "PALMANOVA", nazione: "ITALIA" },
  { codeKey: "G285", sigla: "LE", provincia: "PALMARIGGI", nazione: "ITALIA" },
  { codeKey: "G286", sigla: "OR", provincia: "PALMAS ARBOREA", nazione: "ITALIA" },
  {
    codeKey: "G287",
    sigla: "CI",
    provincia: "SAN GIOVANNI SUERGIU",
    nazione: "ITALIA",
  },
  { codeKey: "G288", sigla: "RC", provincia: "PALMI", nazione: "ITALIA" },
  { codeKey: "G289", sigla: "AP", provincia: "PALMIANO", nazione: "ITALIA" },
  { codeKey: "G290", sigla: "CH", provincia: "PALMOLI", nazione: "ITALIA" },
  { codeKey: "G291", sigla: "BA", provincia: "PALO DEL COLLE", nazione: "ITALIA" },
  { codeKey: "G292", sigla: "SA", provincia: "PALOMONTE", nazione: "ITALIA" },
  { codeKey: "G293", sigla: "RM", provincia: "PALOMBARA SABINA", nazione: "ITALIA" },
  { codeKey: "G294", sigla: "CH", provincia: "PALOMBARO", nazione: "ITALIA" },
  { codeKey: "G295", sigla: "BG", provincia: "PALOSCO", nazione: "ITALIA" },
  {
    codeKey: "G296",
    sigla: "TN",
    provincia: "PALU' DEL FERSINA",
    nazione: "ITALIA",
  },
  { codeKey: "G297", sigla: "VR", provincia: "PALU'", nazione: "ITALIA" },
  { codeKey: "G298", sigla: "CS", provincia: "PALUDI", nazione: "ITALIA" },
  { codeKey: "G299", sigla: "BZ", provincia: "PLAUS", nazione: "ITALIA" },
  { codeKey: "G300", sigla: "UD", provincia: "PALUZZA", nazione: "ITALIA" },
  { codeKey: "G302", sigla: "CN", provincia: "PAMPARATO", nazione: "ITALIA" },
  { codeKey: "G303", sigla: "TO", provincia: "PANCALIERI", nazione: "ITALIA" },
  { codeKey: "G304", sigla: "PV", provincia: "PANCARANA", nazione: "ITALIA" },
  { codeKey: "G305", sigla: "TN", provincia: "PANCHIA'", nazione: "ITALIA" },
  { codeKey: "G306", sigla: "CR", provincia: "PANDINO", nazione: "ITALIA" },
  { codeKey: "G307", sigla: "CS", provincia: "PANETTIERI", nazione: "ITALIA" },
  { codeKey: "G308", sigla: "PG", provincia: "PANICALE", nazione: "ITALIA" },
  { codeKey: "G309", sigla: "NA", provincia: "VILLARICCA", nazione: "ITALIA" },
  { codeKey: "G311", sigla: "BN", provincia: "PANNARANO", nazione: "ITALIA" },
  { codeKey: "G312", sigla: "FG", provincia: "PANNI", nazione: "ITALIA" },
  { codeKey: "G315", sigla: "TP", provincia: "PANTELLERIA", nazione: "ITALIA" },
  { codeKey: "G316", sigla: "MI", provincia: "PANTIGLIATE", nazione: "ITALIA" },
  { codeKey: "G317", sigla: "CS", provincia: "PAOLA", nazione: "ITALIA" },
  { codeKey: "G318", sigla: "BN", provincia: "PAOLISI", nazione: "ITALIA" },
  { codeKey: "G319", sigla: "TP", provincia: "VALDERICE", nazione: "ITALIA" },
  { codeKey: "G320", sigla: "CS", provincia: "PAPASIDERO", nazione: "ITALIA" },
  { codeKey: "G323", sigla: "RO", provincia: "PAPOZZE", nazione: "ITALIA" },
  { codeKey: "G324", sigla: "MI", provincia: "PARABIAGO", nazione: "ITALIA" },
  { codeKey: "G325", sigla: "LE", provincia: "PARABITA", nazione: "ITALIA" },
  { codeKey: "G327", sigla: "BS", provincia: "PARATICO", nazione: "ITALIA" },
  { codeKey: "G328", sigla: "BZ", provincia: "PARCINES", nazione: "ITALIA" },
  { codeKey: "G330", sigla: "TO", provincia: "PARELLA", nazione: "ITALIA" },
  { codeKey: "G331", sigla: "CS", provincia: "PARENTI", nazione: "ITALIA" },
  { codeKey: "G333", sigla: "CE", provincia: "PARETE", nazione: "ITALIA" },
  { codeKey: "G334", sigla: "AL", provincia: "PARETO", nazione: "ITALIA" },
  { codeKey: "G335", sigla: "VV", provincia: "PARGHELIA", nazione: "ITALIA" },
  { codeKey: "G336", sigla: "LC", provincia: "PARLASCO", nazione: "ITALIA" },
  { codeKey: "G337", sigla: "PR", provincia: "PARMA", nazione: "ITALIA" },
  { codeKey: "G338", sigla: "AL", provincia: "PARODI LIGURE", nazione: "ITALIA" },
  { codeKey: "G339", sigla: "CN", provincia: "PAROLDO", nazione: "ITALIA" },
  { codeKey: "G340", sigla: "AV", provincia: "PAROLISE", nazione: "ITALIA" },
  { codeKey: "G342", sigla: "PV", provincia: "PARONA", nazione: "ITALIA" },
  { codeKey: "G344", sigla: "TR", provincia: "PARRANO", nazione: "ITALIA" },
  { codeKey: "G346", sigla: "BG", provincia: "PARRE", nazione: "ITALIA" },
  { codeKey: "G347", sigla: "TP", provincia: "PARTANNA", nazione: "ITALIA" },
  { codeKey: "G348", sigla: "PA", provincia: "PARTINICO", nazione: "ITALIA" },
  { codeKey: "G349", sigla: "NO", provincia: "PARUZZARO", nazione: "ITALIA" },
  { codeKey: "G350", sigla: "BG", provincia: "PARZANICA", nazione: "ITALIA" },
  { codeKey: "G352", sigla: "UD", provincia: "PASIAN DI PRATO", nazione: "ITALIA" },
  {
    codeKey: "G353",
    sigla: "PN",
    provincia: "PASIANO DI PORDENONE",
    nazione: "ITALIA",
  },
  { codeKey: "G354", sigla: "BS", provincia: "PASPARDO", nazione: "ITALIA" },
  {
    codeKey: "G358",
    sigla: "AT",
    provincia: "PASSERANO MARMORITO",
    nazione: "ITALIA",
  },
  {
    codeKey: "G359",
    sigla: "PG",
    provincia: "PASSIGNANO SUL TRASIMENO",
    nazione: "ITALIA",
  },
  { codeKey: "G361", sigla: "BS", provincia: "PASSIRANO", nazione: "ITALIA" },
  { codeKey: "G362", sigla: "FR", provincia: "PASTENA", nazione: "ITALIA" },
  { codeKey: "G364", sigla: "CE", provincia: "PASTORANO", nazione: "ITALIA" },
  { codeKey: "G365", sigla: "VR", provincia: "PASTRENGO", nazione: "ITALIA" },
  { codeKey: "G367", sigla: "AL", provincia: "PASTURANA", nazione: "ITALIA" },
  { codeKey: "G368", sigla: "LC", provincia: "PASTURO", nazione: "ITALIA" },
  { codeKey: "G370", sigla: "AV", provincia: "PATERNOPOLI", nazione: "ITALIA" },
  { codeKey: "G371", sigla: "CT", provincia: "PATERNO'", nazione: "ITALIA" },
  { codeKey: "G372", sigla: "CS", provincia: "PATERNO CALABRO", nazione: "ITALIA" },
  { codeKey: "G374", sigla: "FR", provincia: "PATRICA", nazione: "ITALIA" },
  { codeKey: "G376", sigla: "SS", provincia: "PATTADA", nazione: "ITALIA" },
  { codeKey: "G377", sigla: "ME", provincia: "PATTI", nazione: "ITALIA" },
  { codeKey: "G378", sigla: "LE", provincia: "PATU'", nazione: "ITALIA" },
  { codeKey: "G379", sigla: "OR", provincia: "PAU", nazione: "ITALIA" },
  { codeKey: "G381", sigla: "UD", provincia: "PAULARO", nazione: "ITALIA" },
  { codeKey: "G382", sigla: "VS", provincia: "PAULI ARBAREI", nazione: "ITALIA" },
  {
    codeKey: "G383",
    sigla: "CA",
    provincia: "SAN NICOLO' GERREI",
    nazione: "ITALIA",
  },
  { codeKey: "G384", sigla: "OR", provincia: "PAULILATINO", nazione: "ITALIA" },
  { codeKey: "G385", sigla: "MI", provincia: "PAULLO", nazione: "ITALIA" },
  { codeKey: "G386", sigla: "BN", provincia: "PAUPISI", nazione: "ITALIA" },
  { codeKey: "G387", sigla: "TO", provincia: "PAVAROLO", nazione: "ITALIA" },
  { codeKey: "G388", sigla: "PV", provincia: "PAVIA", nazione: "ITALIA" },
  { codeKey: "G389", sigla: "UD", provincia: "PAVIA DI UDINE", nazione: "ITALIA" },
  { codeKey: "G391", sigla: "BS", provincia: "PAVONE DEL MELLA", nazione: "ITALIA" },
  { codeKey: "G392", sigla: "TO", provincia: "PAVONE CANAVESE", nazione: "ITALIA" },
  {
    codeKey: "G393",
    sigla: "MO",
    provincia: "PAVULLO NEL FRIGNANO",
    nazione: "ITALIA",
  },
  { codeKey: "G394", sigla: "RC", provincia: "PAZZANO", nazione: "ITALIA" },
  { codeKey: "G395", sigla: "PI", provincia: "PECCIOLI", nazione: "ITALIA" },
  { codeKey: "G396", sigla: "TO", provincia: "PECCO", nazione: "ITALIA" },
  {
    codeKey: "G397",
    sigla: "AL",
    provincia: "PECETTO DI VALENZA",
    nazione: "ITALIA",
  },
  { codeKey: "G398", sigla: "TO", provincia: "PECETTO TORINESE", nazione: "ITALIA" },
  { codeKey: "G399", sigla: "PC", provincia: "PECORARA", nazione: "ITALIA" },
  { codeKey: "G400", sigla: "CS", provincia: "PEDACE", nazione: "ITALIA" },
  { codeKey: "G402", sigla: "CT", provincia: "PEDARA", nazione: "ITALIA" },
  { codeKey: "G403", sigla: "FM", provincia: "PEDASO", nazione: "ITALIA" },
  { codeKey: "G404", sigla: "BL", provincia: "PEDAVENA", nazione: "ITALIA" },
  { codeKey: "G406", sigla: "VI", provincia: "PEDEMONTE", nazione: "ITALIA" },
  { codeKey: "G407", sigla: "BS", provincia: "SAN PAOLO", nazione: "ITALIA" },
  { codeKey: "G408", sigla: "TV", provincia: "PEDEROBBA", nazione: "ITALIA" },
  { codeKey: "G410", sigla: "SO", provincia: "PEDESINA", nazione: "ITALIA" },
  { codeKey: "G411", sigla: "CS", provincia: "PEDIVIGLIANO", nazione: "ITALIA" },
  { codeKey: "G412", sigla: "BG", provincia: "PEDRENGO", nazione: "ITALIA" },
  { codeKey: "G415", sigla: "CO", provincia: "PEGLIO", nazione: "ITALIA" },
  { codeKey: "G416", sigla: "PU", provincia: "PEGLIO", nazione: "ITALIA" },
  { codeKey: "G417", sigla: "MN", provincia: "PEGOGNAGA", nazione: "ITALIA" },
  { codeKey: "G418", sigla: "BG", provincia: "PEIA", nazione: "ITALIA" },
  { codeKey: "G419", sigla: "TN", provincia: "PEIO", nazione: "ITALIA" },
  { codeKey: "G420", sigla: "FI", provincia: "PELAGO", nazione: "ITALIA" },
  { codeKey: "G421", sigla: "NO", provincia: "PELLA", nazione: "ITALIA" },
  {
    codeKey: "G424",
    sigla: "PR",
    provincia: "PELLEGRINO PARMENSE",
    nazione: "ITALIA",
  },
  { codeKey: "G426", sigla: "SA", provincia: "PELLEZZANO", nazione: "ITALIA" },
  { codeKey: "G427", sigla: "CO", provincia: "PELLIO INTELVI", nazione: "ITALIA" },
  { codeKey: "G428", sigla: "TN", provincia: "PELLIZZANO", nazione: "ITALIA" },
  { codeKey: "G429", sigla: "TN", provincia: "PELUGO", nazione: "ITALIA" },
  { codeKey: "G430", sigla: "AT", provincia: "PENANGO", nazione: "ITALIA" },
  { codeKey: "G431", sigla: "SO", provincia: "POGGIRIDENTI", nazione: "ITALIA" },
  {
    codeKey: "G432",
    sigla: "TR",
    provincia: "PENNA IN TEVERINA",
    nazione: "ITALIA",
  },
  { codeKey: "G433", sigla: "RN", provincia: "PENNABILLI", nazione: "ITALIA" },
  { codeKey: "G434", sigla: "CH", provincia: "PENNADOMO", nazione: "ITALIA" },
  { codeKey: "G435", sigla: "CH", provincia: "PENNAPIEDIMONTE", nazione: "ITALIA" },
  {
    codeKey: "G436",
    sigla: "MC",
    provincia: "PENNA SAN GIOVANNI",
    nazione: "ITALIA",
  },
  {
    codeKey: "G437",
    sigla: "TE",
    provincia: "PENNA SANT'ANDREA",
    nazione: "ITALIA",
  },
  { codeKey: "G438", sigla: "PE", provincia: "PENNE", nazione: "ITALIA" },
  { codeKey: "G439", sigla: "CZ", provincia: "PENTONE", nazione: "ITALIA" },
  { codeKey: "G441", sigla: "CH", provincia: "PERANO", nazione: "ITALIA" },
  {
    codeKey: "G442",
    sigla: "BL",
    provincia: "PERAROLO DI CADORE",
    nazione: "ITALIA",
  },
  { codeKey: "G443", sigla: "BZ", provincia: "PERCA", nazione: "ITALIA" },
  { codeKey: "G444", sigla: "RM", provincia: "PERCILE", nazione: "ITALIA" },
  { codeKey: "G445", sigla: "OG", provincia: "PERDASDEFOGU", nazione: "ITALIA" },
  { codeKey: "G446", sigla: "CI", provincia: "PERDAXIUS", nazione: "ITALIA" },
  { codeKey: "G447", sigla: "SA", provincia: "PERDIFUMO", nazione: "ITALIA" },
  { codeKey: "G449", sigla: "AQ", provincia: "PERETO", nazione: "ITALIA" },
  { codeKey: "G450", sigla: "SS", provincia: "PERFUGAS", nazione: "ITALIA" },
  { codeKey: "G451", sigla: "AR", provincia: "PERGINE VALDARNO", nazione: "ITALIA" },
  {
    codeKey: "G452",
    sigla: "TN",
    provincia: "PERGINE VALSUGANA",
    nazione: "ITALIA",
  },
  { codeKey: "G453", sigla: "PU", provincia: "PERGOLA", nazione: "ITALIA" },
  { codeKey: "G454", sigla: "IM", provincia: "PERINALDO", nazione: "ITALIA" },
  { codeKey: "G455", sigla: "SA", provincia: "PERITO", nazione: "ITALIA" },
  { codeKey: "G456", sigla: "LC", provincia: "PERLEDO", nazione: "ITALIA" },
  { codeKey: "G457", sigla: "CN", provincia: "PERLETTO", nazione: "ITALIA" },
  { codeKey: "G458", sigla: "CN", provincia: "PERLO", nazione: "ITALIA" },
  { codeKey: "G459", sigla: "AO", provincia: "PERLOZ", nazione: "ITALIA" },
  { codeKey: "G461", sigla: "PD", provincia: "PERNUMIA", nazione: "ITALIA" },
  { codeKey: "G462", sigla: "TO", provincia: "PEROSA CANAVESE", nazione: "ITALIA" },
  { codeKey: "G463", sigla: "TO", provincia: "PEROSA ARGENTINA", nazione: "ITALIA" },
  { codeKey: "G465", sigla: "TO", provincia: "PERRERO", nazione: "ITALIA" },
  {
    codeKey: "G467",
    sigla: "BO",
    provincia: "SAN GIOVANNI IN PERSICETO",
    nazione: "ITALIA",
  },
  { codeKey: "G469", sigla: "CR", provincia: "PERSICO DOSIMO", nazione: "ITALIA" },
  { codeKey: "G471", sigla: "VC", provincia: "PERTENGO", nazione: "ITALIA" },
  { codeKey: "G474", sigla: "BS", provincia: "PERTICA ALTA", nazione: "ITALIA" },
  { codeKey: "G475", sigla: "BS", provincia: "PERTICA BASSA", nazione: "ITALIA" },
  { codeKey: "G476", sigla: "SA", provincia: "PERTOSA", nazione: "ITALIA" },
  { codeKey: "G477", sigla: "TO", provincia: "PERTUSIO", nazione: "ITALIA" },
  { codeKey: "G478", sigla: "PG", provincia: "PERUGIA", nazione: "ITALIA" },
  { codeKey: "G479", sigla: "PU", provincia: "PESARO", nazione: "ITALIA" },
  { codeKey: "G480", sigla: "LU", provincia: "PESCAGLIA", nazione: "ITALIA" },
  { codeKey: "G481", sigla: "VR", provincia: "PESCANTINA", nazione: "ITALIA" },
  { codeKey: "G482", sigla: "PE", provincia: "PESCARA", nazione: "ITALIA" },
  {
    codeKey: "G483",
    sigla: "CR",
    provincia: "PESCAROLO ED UNITI",
    nazione: "ITALIA",
  },
  { codeKey: "G484", sigla: "AQ", provincia: "PESCASSEROLI", nazione: "ITALIA" },
  { codeKey: "G485", sigla: "LC", provincia: "PESCATE", nazione: "ITALIA" },
  { codeKey: "G486", sigla: "IS", provincia: "PESCHE", nazione: "ITALIA" },
  { codeKey: "G487", sigla: "FG", provincia: "PESCHICI", nazione: "ITALIA" },
  {
    codeKey: "G488",
    sigla: "MI",
    provincia: "PESCHIERA BORROMEO",
    nazione: "ITALIA",
  },
  {
    codeKey: "G489",
    sigla: "VR",
    provincia: "PESCHIERA DEL GARDA",
    nazione: "ITALIA",
  },
  { codeKey: "G491", sigla: "PT", provincia: "PESCIA", nazione: "ITALIA" },
  { codeKey: "G492", sigla: "AQ", provincia: "PESCINA", nazione: "ITALIA" },
  { codeKey: "G493", sigla: "AQ", provincia: "PESCOCOSTANZO", nazione: "ITALIA" },
  { codeKey: "G494", sigla: "BN", provincia: "PESCO SANNITA", nazione: "ITALIA" },
  { codeKey: "G495", sigla: "IS", provincia: "PESCOLANCIANO", nazione: "ITALIA" },
  { codeKey: "G496", sigla: "PZ", provincia: "PESCOPAGANO", nazione: "ITALIA" },
  { codeKey: "G497", sigla: "IS", provincia: "PESCOPENNATARO", nazione: "ITALIA" },
  { codeKey: "G498", sigla: "RI", provincia: "PESCOROCCHIANO", nazione: "ITALIA" },
  { codeKey: "G499", sigla: "PE", provincia: "PESCOSANSONESCO", nazione: "ITALIA" },
  { codeKey: "G500", sigla: "FR", provincia: "PESCOSOLIDO", nazione: "ITALIA" },
  {
    codeKey: "G502",
    sigla: "MI",
    provincia: "PESSANO CON BORNAGO",
    nazione: "ITALIA",
  },
  {
    codeKey: "G504",
    sigla: "CR",
    provincia: "PESSINA CREMONESE",
    nazione: "ITALIA",
  },
  { codeKey: "G505", sigla: "TO", provincia: "PESSINETTO", nazione: "ITALIA" },
  { codeKey: "G506", sigla: "CB", provincia: "PETACCIATO", nazione: "ITALIA" },
  { codeKey: "G507", sigla: "RI", provincia: "TURANIA", nazione: "ITALIA" },
  {
    codeKey: "G508",
    sigla: "KR",
    provincia: "PETILIA POLICASTRO",
    nazione: "ITALIA",
  },
  { codeKey: "G509", sigla: "SA", provincia: "PETINA", nazione: "ITALIA" },
  { codeKey: "G510", sigla: "PA", provincia: "PETRALIA SOPRANA", nazione: "ITALIA" },
  { codeKey: "G511", sigla: "PA", provincia: "PETRALIA SOTTANA", nazione: "ITALIA" },
  {
    codeKey: "G512",
    sigla: "CB",
    provincia: "PETRELLA TIFERNINA",
    nazione: "ITALIA",
  },
  { codeKey: "G513", sigla: "RI", provincia: "PETRELLA SALTO", nazione: "ITALIA" },
  { codeKey: "G514", sigla: "PU", provincia: "PETRIANO", nazione: "ITALIA" },
  { codeKey: "G515", sigla: "MC", provincia: "PETRIOLO", nazione: "ITALIA" },
  { codeKey: "G516", sigla: "FM", provincia: "PETRITOLI", nazione: "ITALIA" },
  { codeKey: "G517", sigla: "CZ", provincia: "PETRIZZI", nazione: "ITALIA" },
  { codeKey: "G518", sigla: "CZ", provincia: "PETRONA'", nazione: "ITALIA" },
  { codeKey: "G519", sigla: "AV", provincia: "PETRURO IRPINO", nazione: "ITALIA" },
  { codeKey: "G520", sigla: "NO", provincia: "PETTENASCO", nazione: "ITALIA" },
  { codeKey: "G521", sigla: "BI", provincia: "PETTINENGO", nazione: "ITALIA" },
  { codeKey: "G522", sigla: "ME", provincia: "PETTINEO", nazione: "ITALIA" },
  {
    codeKey: "G523",
    sigla: "IS",
    provincia: "PETTORANELLO DEL MOLISE",
    nazione: "ITALIA",
  },
  {
    codeKey: "G524",
    sigla: "AQ",
    provincia: "PETTORANO SUL GIZIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "G525",
    sigla: "RO",
    provincia: "PETTORAZZA GRIMANI",
    nazione: "ITALIA",
  },
  { codeKey: "G526", sigla: "CN", provincia: "PEVERAGNO", nazione: "ITALIA" },
  { codeKey: "G528", sigla: "VC", provincia: "PEZZANA", nazione: "ITALIA" },
  { codeKey: "G529", sigla: "BS", provincia: "PEZZAZE", nazione: "ITALIA" },
  {
    codeKey: "G532",
    sigla: "CN",
    provincia: "PEZZOLO VALLE UZZONE",
    nazione: "ITALIA",
  },
  { codeKey: "G534", sigla: "PD", provincia: "PIACENZA D'ADIGE", nazione: "ITALIA" },
  { codeKey: "G535", sigla: "PC", provincia: "PIACENZA", nazione: "ITALIA" },
  { codeKey: "G536", sigla: "CR", provincia: "PIADENA", nazione: "ITALIA" },
  { codeKey: "G537", sigla: "PU", provincia: "PIAGGE", nazione: "ITALIA" },
  { codeKey: "G538", sigla: "SA", provincia: "PIAGGINE", nazione: "ITALIA" },
  {
    codeKey: "G540",
    sigla: "SA",
    provincia: "VALLE DELL'ANGELO",
    nazione: "ITALIA",
  },
  {
    codeKey: "G541",
    sigla: "CE",
    provincia: "PIANA DI MONTE VERNA",
    nazione: "ITALIA",
  },
  { codeKey: "G542", sigla: "SV", provincia: "PIANA CRIXIA", nazione: "ITALIA" },
  {
    codeKey: "G543",
    sigla: "PA",
    provincia: "PIANA DEGLI ALBANESI",
    nazione: "ITALIA",
  },
  { codeKey: "G545", sigla: "AO", provincia: "PONTBOSET", nazione: "ITALIA" },
  { codeKey: "G546", sigla: "BS", provincia: "PIAN CAMUNO", nazione: "ITALIA" },
  { codeKey: "G547", sigla: "SI", provincia: "PIANCASTAGNAIO", nazione: "ITALIA" },
  { codeKey: "G549", sigla: "BS", provincia: "PIANCOGNO", nazione: "ITALIA" },
  { codeKey: "G551", sigla: "PU", provincia: "PIANDIMELETO", nazione: "ITALIA" },
  { codeKey: "G553", sigla: "CS", provincia: "PIANE CRATI", nazione: "ITALIA" },
  { codeKey: "G555", sigla: "PE", provincia: "PIANELLA", nazione: "ITALIA" },
  {
    codeKey: "G556",
    sigla: "CO",
    provincia: "PIANELLO DEL LARIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "G557",
    sigla: "PC",
    provincia: "PIANELLO VAL TIDONE",
    nazione: "ITALIA",
  },
  { codeKey: "G558", sigla: "CR", provincia: "PIANENGO", nazione: "ITALIA" },
  { codeKey: "G559", sigla: "TO", provincia: "PIANEZZA", nazione: "ITALIA" },
  { codeKey: "G560", sigla: "VI", provincia: "PIANEZZE", nazione: "ITALIA" },
  { codeKey: "G561", sigla: "CN", provincia: "PIANFEI", nazione: "ITALIA" },
  { codeKey: "G564", sigla: "BG", provincia: "PIANICO", nazione: "ITALIA" },
  { codeKey: "G565", sigla: "VE", provincia: "PIANIGA", nazione: "ITALIA" },
  {
    codeKey: "G566",
    sigla: "BO",
    provincia: "SAN BENEDETTO VAL DI SAMBRO",
    nazione: "ITALIA",
  },
  {
    codeKey: "G568",
    sigla: "NA",
    provincia: "PIANO DI SORRENTO",
    nazione: "ITALIA",
  },
  { codeKey: "G570", sigla: "BO", provincia: "PIANORO", nazione: "ITALIA" },
  { codeKey: "G571", sigla: "VT", provincia: "PIANSANO", nazione: "ITALIA" },
  { codeKey: "G572", sigla: "SO", provincia: "PIANTEDO", nazione: "ITALIA" },
  { codeKey: "G574", sigla: "BG", provincia: "PIARIO", nazione: "ITALIA" },
  { codeKey: "G575", sigla: "CN", provincia: "PIASCO", nazione: "ITALIA" },
  { codeKey: "G576", sigla: "SO", provincia: "PIATEDA", nazione: "ITALIA" },
  { codeKey: "G577", sigla: "BI", provincia: "PIATTO", nazione: "ITALIA" },
  { codeKey: "G579", sigla: "BG", provincia: "PIAZZA BREMBANA", nazione: "ITALIA" },
  { codeKey: "G580", sigla: "EN", provincia: "PIAZZA ARMERINA", nazione: "ITALIA" },
  {
    codeKey: "G582",
    sigla: "LU",
    provincia: "PIAZZA AL SERCHIO",
    nazione: "ITALIA",
  },
  { codeKey: "G583", sigla: "BG", provincia: "PIAZZATORRE", nazione: "ITALIA" },
  {
    codeKey: "G587",
    sigla: "PD",
    provincia: "PIAZZOLA SUL BRENTA",
    nazione: "ITALIA",
  },
  { codeKey: "G588", sigla: "BG", provincia: "PIAZZOLO", nazione: "ITALIA" },
  { codeKey: "G589", sigla: "PE", provincia: "PICCIANO", nazione: "ITALIA" },
  { codeKey: "G590", sigla: "PZ", provincia: "PICERNO", nazione: "ITALIA" },
  { codeKey: "G591", sigla: "FR", provincia: "PICINISCO", nazione: "ITALIA" },
  { codeKey: "G592", sigla: "FR", provincia: "PICO", nazione: "ITALIA" },
  { codeKey: "G593", sigla: "AT", provincia: "PIEA", nazione: "ITALIA" },
  { codeKey: "G594", sigla: "BI", provincia: "PIEDICAVALLO", nazione: "ITALIA" },
  {
    codeKey: "G596",
    sigla: "CE",
    provincia: "PIEDIMONTE MATESE",
    nazione: "ITALIA",
  },
  { codeKey: "G597", sigla: "CT", provincia: "PIEDIMONTE ETNEO", nazione: "ITALIA" },
  {
    codeKey: "G598",
    sigla: "FR",
    provincia: "PIEDIMONTE SAN GERMANO",
    nazione: "ITALIA",
  },
  { codeKey: "G600", sigla: "VB", provincia: "PIEDIMULERA", nazione: "ITALIA" },
  { codeKey: "G601", sigla: "PG", provincia: "PIEGARO", nazione: "ITALIA" },
  { codeKey: "G602", sigla: "SI", provincia: "PIENZA", nazione: "ITALIA" },
  { codeKey: "G603", sigla: "CR", provincia: "PIERANICA", nazione: "ITALIA" },
  {
    codeKey: "G604",
    sigla: "FG",
    provincia: "PIETRAMONTECORVINO",
    nazione: "ITALIA",
  },
  { codeKey: "G605", sigla: "SV", provincia: "PIETRA LIGURE", nazione: "ITALIA" },
  { codeKey: "G606", sigla: "IS", provincia: "PIETRABBONDANTE", nazione: "ITALIA" },
  { codeKey: "G607", sigla: "IM", provincia: "PIETRABRUNA", nazione: "ITALIA" },
  { codeKey: "G608", sigla: "TE", provincia: "PIETRACAMELA", nazione: "ITALIA" },
  { codeKey: "G609", sigla: "CB", provincia: "PIETRACATELLA", nazione: "ITALIA" },
  { codeKey: "G610", sigla: "CB", provincia: "PIETRACUPA", nazione: "ITALIA" },
  { codeKey: "G611", sigla: "AV", provincia: "PIETRADEFUSI", nazione: "ITALIA" },
  {
    codeKey: "G612",
    sigla: "PV",
    provincia: "PIETRA DE' GIORGI",
    nazione: "ITALIA",
  },
  { codeKey: "G613", sigla: "CH", provincia: "PIETRAFERRAZZANA", nazione: "ITALIA" },
  {
    codeKey: "G614",
    sigla: "PZ",
    provincia: "SATRIANO DI LUCANIA",
    nazione: "ITALIA",
  },
  { codeKey: "G615", sigla: "CS", provincia: "PIETRAFITTA", nazione: "ITALIA" },
  { codeKey: "G616", sigla: "PZ", provincia: "PIETRAGALLA", nazione: "ITALIA" },
  { codeKey: "G618", sigla: "PG", provincia: "PIETRALUNGA", nazione: "ITALIA" },
  { codeKey: "G619", sigla: "AL", provincia: "PIETRA MARAZZI", nazione: "ITALIA" },
  { codeKey: "G620", sigla: "CE", provincia: "PIETRAMELARA", nazione: "ITALIA" },
  { codeKey: "G621", sigla: "PE", provincia: "PIETRANICO", nazione: "ITALIA" },
  { codeKey: "G622", sigla: "CS", provincia: "PIETRAPAOLA", nazione: "ITALIA" },
  { codeKey: "G623", sigla: "PZ", provincia: "PIETRAPERTOSA", nazione: "ITALIA" },
  { codeKey: "G624", sigla: "EN", provincia: "PIETRAPERZIA", nazione: "ITALIA" },
  { codeKey: "G625", sigla: "CN", provincia: "PIETRAPORZIO", nazione: "ITALIA" },
  { codeKey: "G626", sigla: "BN", provincia: "PIETRAROJA", nazione: "ITALIA" },
  { codeKey: "G627", sigla: "PU", provincia: "PIETRARUBBIA", nazione: "ITALIA" },
  { codeKey: "G628", sigla: "LU", provincia: "PIETRASANTA", nazione: "ITALIA" },
  { codeKey: "G629", sigla: "AV", provincia: "PIETRASTORNINA", nazione: "ITALIA" },
  { codeKey: "G630", sigla: "CE", provincia: "PIETRAVAIRANO", nazione: "ITALIA" },
  { codeKey: "G631", sigla: "BN", provincia: "PIETRELCINA", nazione: "ITALIA" },
  { codeKey: "G632", sigla: "IM", provincia: "PIEVE DI TECO", nazione: "ITALIA" },
  { codeKey: "G633", sigla: "MN", provincia: "PIEVE DI CORIANO", nazione: "ITALIA" },
  { codeKey: "G634", sigla: "MI", provincia: "PIEVE EMANUELE", nazione: "ITALIA" },
  { codeKey: "G635", sigla: "PV", provincia: "PIEVE ALBIGNOLA", nazione: "ITALIA" },
  { codeKey: "G636", sigla: "PT", provincia: "PIEVE A NIEVOLE", nazione: "ITALIA" },
  { codeKey: "G637", sigla: "MC", provincia: "PIEVEBOVIGLIANA", nazione: "ITALIA" },
  { codeKey: "G638", sigla: "BL", provincia: "PIEVE D'ALPAGO", nazione: "ITALIA" },
  { codeKey: "G639", sigla: "PV", provincia: "PIEVE DEL CAIRO", nazione: "ITALIA" },
  { codeKey: "G641", sigla: "TN", provincia: "PIEVE DI BONO", nazione: "ITALIA" },
  { codeKey: "G642", sigla: "BL", provincia: "PIEVE DI CADORE", nazione: "ITALIA" },
  { codeKey: "G643", sigla: "BO", provincia: "PIEVE DI CENTO", nazione: "ITALIA" },
  { codeKey: "G645", sigla: "TV", provincia: "PIEVE DI SOLIGO", nazione: "ITALIA" },
  { codeKey: "G646", sigla: "GE", provincia: "PIEVE LIGURE", nazione: "ITALIA" },
  { codeKey: "G647", sigla: "CR", provincia: "PIEVE D'OLMI", nazione: "ITALIA" },
  { codeKey: "G648", sigla: "LU", provincia: "PIEVE FOSCIANA", nazione: "ITALIA" },
  { codeKey: "G649", sigla: "MO", provincia: "PIEVEPELAGO", nazione: "ITALIA" },
  {
    codeKey: "G650",
    sigla: "PV",
    provincia: "PIEVE PORTO MORONE",
    nazione: "ITALIA",
  },
  {
    codeKey: "G651",
    sigla: "CR",
    provincia: "PIEVE SAN GIACOMO",
    nazione: "ITALIA",
  },
  {
    codeKey: "G653",
    sigla: "AR",
    provincia: "PIEVE SANTO STEFANO",
    nazione: "ITALIA",
  },
  { codeKey: "G654", sigla: "RE", provincia: "RAMISETO", nazione: "ITALIA" },
  { codeKey: "G656", sigla: "TN", provincia: "PIEVE TESINO", nazione: "ITALIA" },
  { codeKey: "G657", sigla: "MC", provincia: "PIEVE TORINA", nazione: "ITALIA" },
  { codeKey: "G658", sigla: "VB", provincia: "PIEVE VERGONTE", nazione: "ITALIA" },
  { codeKey: "G659", sigla: "FR", provincia: "PIGLIO", nazione: "ITALIA" },
  { codeKey: "G660", sigla: "IM", provincia: "PIGNA", nazione: "ITALIA" },
  {
    codeKey: "G661",
    sigla: "CE",
    provincia: "PIGNATARO MAGGIORE",
    nazione: "ITALIA",
  },
  {
    codeKey: "G662",
    sigla: "FR",
    provincia: "PIGNATARO INTERAMNA",
    nazione: "ITALIA",
  },
  { codeKey: "G663", sigla: "PZ", provincia: "PIGNOLA", nazione: "ITALIA" },
  { codeKey: "G664", sigla: "SP", provincia: "PIGNONE", nazione: "ITALIA" },
  { codeKey: "G665", sigla: "CO", provincia: "PIGRA", nazione: "ITALIA" },
  { codeKey: "G666", sigla: "VC", provincia: "PILA", nazione: "ITALIA" },
  { codeKey: "G669", sigla: "CA", provincia: "PIMENTEL", nazione: "ITALIA" },
  { codeKey: "G670", sigla: "NA", provincia: "PIMONTE", nazione: "ITALIA" },
  { codeKey: "G671", sigla: "PV", provincia: "PINAROLO PO", nazione: "ITALIA" },
  { codeKey: "G672", sigla: "TO", provincia: "PINASCA", nazione: "ITALIA" },
  { codeKey: "G673", sigla: "RO", provincia: "PINCARA", nazione: "ITALIA" },
  { codeKey: "G674", sigla: "TO", provincia: "PINEROLO", nazione: "ITALIA" },
  { codeKey: "G676", sigla: "AT", provincia: "PINO D'ASTI", nazione: "ITALIA" },
  { codeKey: "G678", sigla: "TO", provincia: "PINO TORINESE", nazione: "ITALIA" },
  {
    codeKey: "G680",
    sigla: "PN",
    provincia: "PINZANO AL TAGLIAMENTO",
    nazione: "ITALIA",
  },
  { codeKey: "G681", sigla: "TN", provincia: "PINZOLO", nazione: "ITALIA" },
  { codeKey: "G682", sigla: "PU", provincia: "PIOBBICO", nazione: "ITALIA" },
  { codeKey: "G683", sigla: "CN", provincia: "PIOBESI D'ALBA", nazione: "ITALIA" },
  { codeKey: "G684", sigla: "TO", provincia: "PIOBESI TORINESE", nazione: "ITALIA" },
  { codeKey: "G685", sigla: "VC", provincia: "PIODE", nazione: "ITALIA" },
  { codeKey: "G686", sigla: "MI", provincia: "PIOLTELLO", nazione: "ITALIA" },
  { codeKey: "G687", sigla: "LI", provincia: "PIOMBINO", nazione: "ITALIA" },
  { codeKey: "G688", sigla: "PD", provincia: "PIOMBINO DESE", nazione: "ITALIA" },
  { codeKey: "G690", sigla: "MC", provincia: "PIORACO", nazione: "ITALIA" },
  { codeKey: "G691", sigla: "TO", provincia: "PIOSSASCO", nazione: "ITALIA" },
  { codeKey: "G692", sigla: "AT", provincia: "PIOVA' MASSAIA", nazione: "ITALIA" },
  { codeKey: "G693", sigla: "PD", provincia: "PIOVE DI SACCO", nazione: "ITALIA" },
  {
    codeKey: "G694",
    sigla: "VI",
    provincia: "PIOVENE ROCCHETTE",
    nazione: "ITALIA",
  },
  { codeKey: "G695", sigla: "AL", provincia: "PIOVERA", nazione: "ITALIA" },
  { codeKey: "G696", sigla: "PC", provincia: "PIOZZANO", nazione: "ITALIA" },
  { codeKey: "G697", sigla: "CN", provincia: "PIOZZO", nazione: "ITALIA" },
  { codeKey: "G698", sigla: "LT", provincia: "PRIVERNO", nazione: "ITALIA" },
  { codeKey: "G699", sigla: "ME", provincia: "PIRAINO", nazione: "ITALIA" },
  { codeKey: "G702", sigla: "PI", provincia: "PISA", nazione: "ITALIA" },
  { codeKey: "G703", sigla: "NO", provincia: "PISANO", nazione: "ITALIA" },
  { codeKey: "G704", sigla: "RM", provincia: "PISONIANO", nazione: "ITALIA" },
  { codeKey: "G705", sigla: "TO", provincia: "PISCINA", nazione: "ITALIA" },
  { codeKey: "G707", sigla: "SA", provincia: "PISCIOTTA", nazione: "ITALIA" },
  { codeKey: "G710", sigla: "BS", provincia: "PISOGNE", nazione: "ITALIA" },
  { codeKey: "G712", sigla: "MT", provincia: "PISTICCI", nazione: "ITALIA" },
  { codeKey: "G713", sigla: "PT", provincia: "PISTOIA", nazione: "ITALIA" },
  { codeKey: "G715", sigla: "PT", provincia: "PITEGLIO", nazione: "ITALIA" },
  { codeKey: "G716", sigla: "GR", provincia: "PITIGLIANO", nazione: "ITALIA" },
  { codeKey: "G717", sigla: "MN", provincia: "PIUBEGA", nazione: "ITALIA" },
  { codeKey: "G718", sigla: "SO", provincia: "PIURO", nazione: "ITALIA" },
  { codeKey: "G719", sigla: "TO", provincia: "PIVERONE", nazione: "ITALIA" },
  { codeKey: "G720", sigla: "PV", provincia: "PIZZALE", nazione: "ITALIA" },
  { codeKey: "G721", sigla: "CR", provincia: "PIZZIGHETTONE", nazione: "ITALIA" },
  { codeKey: "G722", sigla: "VV", provincia: "PIZZO", nazione: "ITALIA" },
  { codeKey: "G724", sigla: "CH", provincia: "PIZZOFERRATO", nazione: "ITALIA" },
  { codeKey: "G726", sigla: "AQ", provincia: "PIZZOLI", nazione: "ITALIA" },
  { codeKey: "G727", sigla: "IS", provincia: "PIZZONE", nazione: "ITALIA" },
  { codeKey: "G728", sigla: "VV", provincia: "PIZZONI", nazione: "ITALIA" },
  { codeKey: "G729", sigla: "RC", provincia: "PLACANICA", nazione: "ITALIA" },
  { codeKey: "G733", sigla: "CS", provincia: "PLATACI", nazione: "ITALIA" },
  { codeKey: "G734", sigla: "CZ", provincia: "PLATANIA", nazione: "ITALIA" },
  { codeKey: "G735", sigla: "RC", provincia: "PLATI'", nazione: "ITALIA" },
  { codeKey: "G736", sigla: "UD", provincia: "TAIPANA", nazione: "ITALIA" },
  { codeKey: "G737", sigla: "CO", provincia: "PLESIO", nazione: "ITALIA" },
  { codeKey: "G740", sigla: "SS", provincia: "PLOAGHE", nazione: "ITALIA" },
  { codeKey: "G741", sigla: "SV", provincia: "PLODIO", nazione: "ITALIA" },
  { codeKey: "G742", sigla: "CN", provincia: "POCAPAGLIA", nazione: "ITALIA" },
  { codeKey: "G743", sigla: "UD", provincia: "POCENIA", nazione: "ITALIA" },
  { codeKey: "G746", sigla: "MS", provincia: "PODENZANA", nazione: "ITALIA" },
  { codeKey: "G747", sigla: "PC", provincia: "PODENZANO", nazione: "ITALIA" },
  { codeKey: "G749", sigla: "FR", provincia: "POFI", nazione: "ITALIA" },
  { codeKey: "G751", sigla: "LE", provincia: "POGGIARDO", nazione: "ITALIA" },
  { codeKey: "G752", sigla: "SI", provincia: "POGGIBONSI", nazione: "ITALIA" },
  { codeKey: "G753", sigla: "MN", provincia: "POGGIO RUSCO", nazione: "ITALIA" },
  { codeKey: "G754", sigla: "PO", provincia: "POGGIO A CAIANO", nazione: "ITALIA" },
  { codeKey: "G756", sigla: "RI", provincia: "POGGIO BUSTONE", nazione: "ITALIA" },
  { codeKey: "G757", sigla: "RI", provincia: "POGGIO CATINO", nazione: "ITALIA" },
  { codeKey: "G758", sigla: "PG", provincia: "POGGIODOMO", nazione: "ITALIA" },
  { codeKey: "G760", sigla: "CH", provincia: "POGGIOFIORITO", nazione: "ITALIA" },
  { codeKey: "G761", sigla: "FG", provincia: "POGGIO IMPERIALE", nazione: "ITALIA" },
  { codeKey: "G762", sigla: "NA", provincia: "POGGIOMARINO", nazione: "ITALIA" },
  { codeKey: "G763", sigla: "RI", provincia: "POGGIO MIRTETO", nazione: "ITALIA" },
  { codeKey: "G764", sigla: "RI", provincia: "POGGIO MOIANO", nazione: "ITALIA" },
  { codeKey: "G765", sigla: "RI", provincia: "POGGIO NATIVO", nazione: "ITALIA" },
  { codeKey: "G766", sigla: "AQ", provincia: "POGGIO PICENZE", nazione: "ITALIA" },
  { codeKey: "G767", sigla: "TP", provincia: "POGGIOREALE", nazione: "ITALIA" },
  { codeKey: "G768", sigla: "FE", provincia: "POGGIO RENATICO", nazione: "ITALIA" },
  { codeKey: "G769", sigla: "BA", provincia: "POGGIORSINI", nazione: "ITALIA" },
  {
    codeKey: "G770",
    sigla: "RI",
    provincia: "POGGIO SAN LORENZO",
    nazione: "ITALIA",
  },
  {
    codeKey: "G771",
    sigla: "AN",
    provincia: "POGGIO SAN MARCELLO",
    nazione: "ITALIA",
  },
  {
    codeKey: "G772",
    sigla: "MI",
    provincia: "POGLIANO MILANESE",
    nazione: "ITALIA",
  },
  { codeKey: "G773", sigla: "CO", provincia: "POGNANA LARIO", nazione: "ITALIA" },
  { codeKey: "G774", sigla: "BG", provincia: "POGNANO", nazione: "ITALIA" },
  { codeKey: "G775", sigla: "NO", provincia: "POGNO", nazione: "ITALIA" },
  { codeKey: "G776", sigla: "VI", provincia: "POIANA MAGGIORE", nazione: "ITALIA" },
  { codeKey: "G777", sigla: "TO", provincia: "POIRINO", nazione: "ITALIA" },
  { codeKey: "G779", sigla: "BS", provincia: "POLAVENO", nazione: "ITALIA" },
  { codeKey: "G780", sigla: "PN", provincia: "POLCENIGO", nazione: "ITALIA" },
  { codeKey: "G782", sigla: "RO", provincia: "POLESELLA", nazione: "ITALIA" },
  {
    codeKey: "G783",
    sigla: "PR",
    provincia: "POLESINE PARMENSE",
    nazione: "ITALIA",
  },
  { codeKey: "G784", sigla: "RM", provincia: "POLI", nazione: "ITALIA" },
  { codeKey: "G785", sigla: "VV", provincia: "POLIA", nazione: "ITALIA" },
  { codeKey: "G786", sigla: "MT", provincia: "POLICORO", nazione: "ITALIA" },
  { codeKey: "G787", sigla: "BA", provincia: "POLIGNANO A MARE", nazione: "ITALIA" },
  {
    codeKey: "G788",
    sigla: "PC",
    provincia: "SAN PIETRO IN CERRO",
    nazione: "ITALIA",
  },
  { codeKey: "G789", sigla: "MO", provincia: "POLINAGO", nazione: "ITALIA" },
  { codeKey: "G790", sigla: "TR", provincia: "POLINO", nazione: "ITALIA" },
  { codeKey: "G791", sigla: "RC", provincia: "POLISTENA", nazione: "ITALIA" },
  { codeKey: "G792", sigla: "PA", provincia: "POLIZZI GENEROSA", nazione: "ITALIA" },
  { codeKey: "G793", sigla: "SA", provincia: "POLLA", nazione: "ITALIA" },
  { codeKey: "G794", sigla: "AO", provincia: "POLLEIN", nazione: "ITALIA" },
  { codeKey: "G795", sigla: "NA", provincia: "POLLENA TROCCHIA", nazione: "ITALIA" },
  { codeKey: "G796", sigla: "SA", provincia: "POLLICA", nazione: "ITALIA" },
  { codeKey: "G797", sigla: "PA", provincia: "POLLINA", nazione: "ITALIA" },
  { codeKey: "G798", sigla: "BI", provincia: "POLLONE", nazione: "ITALIA" },
  { codeKey: "G799", sigla: "CH", provincia: "POLLUTRI", nazione: "ITALIA" },
  { codeKey: "G800", sigla: "CN", provincia: "POLONGHERA", nazione: "ITALIA" },
  {
    codeKey: "G801",
    sigla: "BS",
    provincia: "POLPENAZZE DEL GARDA",
    nazione: "ITALIA",
  },
  { codeKey: "G802", sigla: "PD", provincia: "POLVERARA", nazione: "ITALIA" },
  { codeKey: "G803", sigla: "AN", provincia: "POLVERIGI", nazione: "ITALIA" },
  { codeKey: "G804", sigla: "PI", provincia: "POMARANCE", nazione: "ITALIA" },
  { codeKey: "G805", sigla: "TO", provincia: "POMARETTO", nazione: "ITALIA" },
  { codeKey: "G806", sigla: "MT", provincia: "POMARICO", nazione: "ITALIA" },
  {
    codeKey: "G807",
    sigla: "AL",
    provincia: "POMARO MONFERRATO",
    nazione: "ITALIA",
  },
  { codeKey: "G808", sigla: "TN", provincia: "POMAROLO", nazione: "ITALIA" },
  { codeKey: "G809", sigla: "NO", provincia: "POMBIA", nazione: "ITALIA" },
  { codeKey: "G811", sigla: "RM", provincia: "POMEZIA", nazione: "ITALIA" },
  {
    codeKey: "G812",
    sigla: "NA",
    provincia: "POMIGLIANO D'ARCO",
    nazione: "ITALIA",
  },
  { codeKey: "G813", sigla: "NA", provincia: "POMPEI", nazione: "ITALIA" },
  { codeKey: "G814", sigla: "IM", provincia: "POMPEIANA", nazione: "ITALIA" },
  { codeKey: "G815", sigla: "BS", provincia: "POMPIANO", nazione: "ITALIA" },
  { codeKey: "G816", sigla: "MN", provincia: "POMPONESCO", nazione: "ITALIA" },
  { codeKey: "G817", sigla: "OR", provincia: "POMPU", nazione: "ITALIA" },
  { codeKey: "G818", sigla: "BS", provincia: "PONCARALE", nazione: "ITALIA" },
  { codeKey: "G820", sigla: "BI", provincia: "PONDERANO", nazione: "ITALIA" },
  { codeKey: "G821", sigla: "CO", provincia: "PONNA", nazione: "ITALIA" },
  { codeKey: "G822", sigla: "PI", provincia: "PONSACCO", nazione: "ITALIA" },
  { codeKey: "G823", sigla: "PD", provincia: "PONSO", nazione: "ITALIA" },
  { codeKey: "G825", sigla: "FI", provincia: "PONTASSIEVE", nazione: "ITALIA" },
  { codeKey: "G826", sigla: "TO", provincia: "PONT CANAVESE", nazione: "ITALIA" },
  { codeKey: "G827", sigla: "BN", provincia: "PONTE", nazione: "ITALIA" },
  {
    codeKey: "G829",
    sigla: "SO",
    provincia: "PONTE IN VALTELLINA",
    nazione: "ITALIA",
  },
  { codeKey: "G830", sigla: "BZ", provincia: "PONTE GARDENA", nazione: "ITALIA" },
  { codeKey: "G831", sigla: "UD", provincia: "PONTEBBA", nazione: "ITALIA" },
  { codeKey: "G833", sigla: "PT", provincia: "PONTE BUGGIANESE", nazione: "ITALIA" },
  {
    codeKey: "G834",
    sigla: "SA",
    provincia: "PONTECAGNANO FAIANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "G836",
    sigla: "RO",
    provincia: "PONTECCHIO POLESINE",
    nazione: "ITALIA",
  },
  { codeKey: "G837", sigla: "CN", provincia: "PONTECHIANALE", nazione: "ITALIA" },
  { codeKey: "G838", sigla: "FR", provincia: "PONTECORVO", nazione: "ITALIA" },
  { codeKey: "G839", sigla: "AL", provincia: "PONTECURONE", nazione: "ITALIA" },
  { codeKey: "G840", sigla: "IM", provincia: "PONTEDASSIO", nazione: "ITALIA" },
  { codeKey: "G842", sigla: "PC", provincia: "PONTE DELL'OLIO", nazione: "ITALIA" },
  { codeKey: "G843", sigla: "PI", provincia: "PONTEDERA", nazione: "ITALIA" },
  { codeKey: "G844", sigla: "BS", provincia: "PONTE DI LEGNO", nazione: "ITALIA" },
  { codeKey: "G846", sigla: "TV", provincia: "PONTE DI PIAVE", nazione: "ITALIA" },
  { codeKey: "G847", sigla: "CO", provincia: "PONTE LAMBRO", nazione: "ITALIA" },
  { codeKey: "G848", sigla: "BN", provincia: "PONTELANDOLFO", nazione: "ITALIA" },
  { codeKey: "G849", sigla: "CE", provincia: "PONTELATONE", nazione: "ITALIA" },
  { codeKey: "G850", sigla: "PD", provincia: "PONTELONGO", nazione: "ITALIA" },
  { codeKey: "G851", sigla: "PV", provincia: "PONTE NIZZA", nazione: "ITALIA" },
  { codeKey: "G852", sigla: "PC", provincia: "PONTENURE", nazione: "ITALIA" },
  { codeKey: "G853", sigla: "BG", provincia: "PONTERANICA", nazione: "ITALIA" },
  {
    codeKey: "G854",
    sigla: "AO",
    provincia: "PONT-SAINT-MARTIN",
    nazione: "ITALIA",
  },
  {
    codeKey: "G855",
    sigla: "PD",
    provincia: "PONTE SAN NICOLO'",
    nazione: "ITALIA",
  },
  { codeKey: "G856", sigla: "BG", provincia: "PONTE SAN PIETRO", nazione: "ITALIA" },
  { codeKey: "G858", sigla: "AL", provincia: "PONTESTURA", nazione: "ITALIA" },
  { codeKey: "G859", sigla: "BS", provincia: "PONTEVICO", nazione: "ITALIA" },
  { codeKey: "G860", sigla: "AO", provincia: "PONTEY", nazione: "ITALIA" },
  { codeKey: "G861", sigla: "AL", provincia: "PONTI", nazione: "ITALIA" },
  { codeKey: "G862", sigla: "MN", provincia: "PONTI SUL MINCIO", nazione: "ITALIA" },
  { codeKey: "G864", sigla: "BG", provincia: "PONTIDA", nazione: "ITALIA" },
  { codeKey: "G865", sigla: "LT", provincia: "PONTINIA", nazione: "ITALIA" },
  { codeKey: "G866", sigla: "SV", provincia: "PONTINVREA", nazione: "ITALIA" },
  { codeKey: "G867", sigla: "BG", provincia: "PONTIROLO NUOVO", nazione: "ITALIA" },
  { codeKey: "G869", sigla: "BS", provincia: "PONTOGLIO", nazione: "ITALIA" },
  { codeKey: "G870", sigla: "MS", provincia: "PONTREMOLI", nazione: "ITALIA" },
  { codeKey: "G871", sigla: "LT", provincia: "PONZA", nazione: "ITALIA" },
  {
    codeKey: "G872",
    sigla: "AL",
    provincia: "PONZANO MONFERRATO",
    nazione: "ITALIA",
  },
  { codeKey: "G873", sigla: "FM", provincia: "PONZANO DI FERMO", nazione: "ITALIA" },
  { codeKey: "G874", sigla: "RM", provincia: "PONZANO ROMANO", nazione: "ITALIA" },
  { codeKey: "G875", sigla: "TV", provincia: "PONZANO VENETO", nazione: "ITALIA" },
  { codeKey: "G877", sigla: "AL", provincia: "PONZONE", nazione: "ITALIA" },
  { codeKey: "G878", sigla: "PE", provincia: "POPOLI", nazione: "ITALIA" },
  { codeKey: "G879", sigla: "AR", provincia: "POPPI", nazione: "ITALIA" },
  { codeKey: "G881", sigla: "TR", provincia: "PORANO", nazione: "ITALIA" },
  { codeKey: "G882", sigla: "LU", provincia: "PORCARI", nazione: "ITALIA" },
  { codeKey: "G886", sigla: "PN", provincia: "PORCIA", nazione: "ITALIA" },
  { codeKey: "G887", sigla: "SA", provincia: "STELLA CILENTO", nazione: "ITALIA" },
  { codeKey: "G888", sigla: "PN", provincia: "PORDENONE", nazione: "ITALIA" },
  { codeKey: "G889", sigla: "CO", provincia: "PORLEZZA", nazione: "ITALIA" },
  { codeKey: "G890", sigla: "IM", provincia: "PORNASSIO", nazione: "ITALIA" },
  { codeKey: "G891", sigla: "UD", provincia: "PORPETTO", nazione: "ITALIA" },
  { codeKey: "G894", sigla: "AT", provincia: "PORTACOMARO", nazione: "ITALIA" },
  { codeKey: "G895", sigla: "PV", provincia: "PORTALBERA", nazione: "ITALIA" },
  { codeKey: "G900", sigla: "TO", provincia: "PORTE", nazione: "ITALIA" },
  { codeKey: "G902", sigla: "NA", provincia: "PORTICI", nazione: "ITALIA" },
  {
    codeKey: "G903",
    sigla: "CE",
    provincia: "PORTICO DI CASERTA",
    nazione: "ITALIA",
  },
  {
    codeKey: "G904",
    sigla: "FC",
    provincia: "PORTICO E SAN BENEDETTO",
    nazione: "ITALIA",
  },
  { codeKey: "G905", sigla: "RC", provincia: "PORTIGLIOLA", nazione: "ITALIA" },
  { codeKey: "G906", sigla: "VA", provincia: "PORTO CERESIO", nazione: "ITALIA" },
  {
    codeKey: "G907",
    sigla: "VA",
    provincia: "PORTO VALTRAVAGLIA",
    nazione: "ITALIA",
  },
  { codeKey: "G909", sigla: "TV", provincia: "PORTOBUFFOLE'", nazione: "ITALIA" },
  { codeKey: "G910", sigla: "CB", provincia: "PORTOCANNONE", nazione: "ITALIA" },
  { codeKey: "G912", sigla: "LI", provincia: "PORTOFERRAIO", nazione: "ITALIA" },
  { codeKey: "G913", sigla: "GE", provincia: "PORTOFINO", nazione: "ITALIA" },
  { codeKey: "G914", sigla: "VE", provincia: "PORTOGRUARO", nazione: "ITALIA" },
  { codeKey: "G916", sigla: "FE", provincia: "PORTOMAGGIORE", nazione: "ITALIA" },
  { codeKey: "G917", sigla: "MN", provincia: "PORTO MANTOVANO", nazione: "ITALIA" },
  { codeKey: "G919", sigla: "MC", provincia: "PORTO RECANATI", nazione: "ITALIA" },
  {
    codeKey: "G920",
    sigla: "FM",
    provincia: "PORTO SAN GIORGIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "G921",
    sigla: "FM",
    provincia: "PORTO SANT'ELPIDIO",
    nazione: "ITALIA",
  },
  { codeKey: "G922", sigla: "CI", provincia: "PORTOSCUSO", nazione: "ITALIA" },
  { codeKey: "G923", sigla: "RO", provincia: "PORTO TOLLE", nazione: "ITALIA" },
  { codeKey: "G924", sigla: "SS", provincia: "PORTO TORRES", nazione: "ITALIA" },
  { codeKey: "G925", sigla: "SP", provincia: "PORTOVENERE", nazione: "ITALIA" },
  { codeKey: "G926", sigla: "RO", provincia: "PORTO VIRO", nazione: "ITALIA" },
  { codeKey: "G927", sigla: "BI", provincia: "PORTULA", nazione: "ITALIA" },
  { codeKey: "G929", sigla: "NU", provincia: "POSADA", nazione: "ITALIA" },
  { codeKey: "G931", sigla: "VI", provincia: "POSINA", nazione: "ITALIA" },
  { codeKey: "G932", sigla: "SA", provincia: "POSITANO", nazione: "ITALIA" },
  { codeKey: "G933", sigla: "TV", provincia: "POSSAGNO", nazione: "ITALIA" },
  { codeKey: "G934", sigla: "RI", provincia: "POSTA", nazione: "ITALIA" },
  { codeKey: "G935", sigla: "FR", provincia: "POSTA FIBRENO", nazione: "ITALIA" },
  { codeKey: "G936", sigla: "BZ", provincia: "POSTAL", nazione: "ITALIA" },
  { codeKey: "G937", sigla: "SO", provincia: "POSTALESIO", nazione: "ITALIA" },
  { codeKey: "G939", sigla: "SA", provincia: "POSTIGLIONE", nazione: "ITALIA" },
  { codeKey: "G940", sigla: "VC", provincia: "POSTUA", nazione: "ITALIA" },
  { codeKey: "G942", sigla: "PZ", provincia: "POTENZA", nazione: "ITALIA" },
  { codeKey: "G943", sigla: "VI", provincia: "POVE DEL GRAPPA", nazione: "ITALIA" },
  { codeKey: "G944", sigla: "TV", provincia: "POVEGLIANO", nazione: "ITALIA" },
  {
    codeKey: "G945",
    sigla: "VR",
    provincia: "POVEGLIANO VERONESE",
    nazione: "ITALIA",
  },
  { codeKey: "G947", sigla: "RE", provincia: "POVIGLIO", nazione: "ITALIA" },
  { codeKey: "G949", sigla: "UD", provincia: "POVOLETTO", nazione: "ITALIA" },
  { codeKey: "G950", sigla: "TN", provincia: "POZZA DI FASSA", nazione: "ITALIA" },
  { codeKey: "G951", sigla: "RI", provincia: "POZZAGLIA SABINA", nazione: "ITALIA" },
  { codeKey: "G953", sigla: "RG", provincia: "POZZALLO", nazione: "ITALIA" },
  { codeKey: "G954", sigla: "IS", provincia: "POZZILLI", nazione: "ITALIA" },
  { codeKey: "G955", sigla: "MI", provincia: "POZZO D'ADDA", nazione: "ITALIA" },
  { codeKey: "G957", sigla: "VI", provincia: "POZZOLEONE", nazione: "ITALIA" },
  { codeKey: "G959", sigla: "BS", provincia: "POZZOLENGO", nazione: "ITALIA" },
  { codeKey: "G960", sigla: "AL", provincia: "POZZOL GROPPO", nazione: "ITALIA" },
  {
    codeKey: "G961",
    sigla: "AL",
    provincia: "POZZOLO FORMIGARO",
    nazione: "ITALIA",
  },
  { codeKey: "G962", sigla: "SS", provincia: "POZZOMAGGIORE", nazione: "ITALIA" },
  { codeKey: "G963", sigla: "PD", provincia: "POZZONOVO", nazione: "ITALIA" },
  { codeKey: "G964", sigla: "NA", provincia: "POZZUOLI", nazione: "ITALIA" },
  {
    codeKey: "G965",
    sigla: "MI",
    provincia: "POZZUOLO MARTESANA",
    nazione: "ITALIA",
  },
  {
    codeKey: "G966",
    sigla: "UD",
    provincia: "POZZUOLO DEL FRIULI",
    nazione: "ITALIA",
  },
  { codeKey: "G968", sigla: "BG", provincia: "PRADALUNGA", nazione: "ITALIA" },
  { codeKey: "G969", sigla: "UD", provincia: "PRADAMANO", nazione: "ITALIA" },
  { codeKey: "G970", sigla: "CN", provincia: "PRADLEVES", nazione: "ITALIA" },
  { codeKey: "G972", sigla: "BO", provincia: "SASSO MARCONI", nazione: "ITALIA" },
  { codeKey: "G973", sigla: "TO", provincia: "PRAGELATO", nazione: "ITALIA" },
  { codeKey: "G974", sigla: "BI", provincia: "PRAY", nazione: "ITALIA" },
  { codeKey: "G975", sigla: "CS", provincia: "PRAIA A MARE", nazione: "ITALIA" },
  { codeKey: "G976", sigla: "SA", provincia: "PRAIANO", nazione: "ITALIA" },
  { codeKey: "G977", sigla: "BS", provincia: "PRALBOINO", nazione: "ITALIA" },
  { codeKey: "G978", sigla: "TO", provincia: "PRALI", nazione: "ITALIA" },
  { codeKey: "G979", sigla: "TO", provincia: "PRALORMO", nazione: "ITALIA" },
  { codeKey: "G980", sigla: "BI", provincia: "PRALUNGO", nazione: "ITALIA" },
  { codeKey: "G981", sigla: "VE", provincia: "PRAMAGGIORE", nazione: "ITALIA" },
  { codeKey: "G982", sigla: "TO", provincia: "PRAMOLLO", nazione: "ITALIA" },
  { codeKey: "G985", sigla: "VC", provincia: "PRAROLO", nazione: "ITALIA" },
  { codeKey: "G986", sigla: "TO", provincia: "PRAROSTINO", nazione: "ITALIA" },
  { codeKey: "G987", sigla: "AL", provincia: "PRASCO", nazione: "ITALIA" },
  { codeKey: "G988", sigla: "TO", provincia: "PRASCORSANO", nazione: "ITALIA" },
  {
    codeKey: "G990",
    sigla: "AV",
    provincia: "PRATA DI PRINCIPATO ULTRA",
    nazione: "ITALIA",
  },
  { codeKey: "G991", sigla: "CE", provincia: "PRATA SANNITA", nazione: "ITALIA" },
  {
    codeKey: "G992",
    sigla: "AQ",
    provincia: "PRATA D'ANSIDONIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "G993",
    sigla: "SO",
    provincia: "PRATA CAMPORTACCIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "G994",
    sigla: "PN",
    provincia: "PRATA DI PORDENONE",
    nazione: "ITALIA",
  },
  { codeKey: "G995", sigla: "CE", provincia: "PRATELLA", nazione: "ITALIA" },
  { codeKey: "G997", sigla: "TO", provincia: "PRATIGLIONE", nazione: "ITALIA" },
  { codeKey: "G999", sigla: "PO", provincia: "PRATO", nazione: "ITALIA" },
  { codeKey: "H001", sigla: "NO", provincia: "PRATO SESIA", nazione: "ITALIA" },
  { codeKey: "H002", sigla: "UD", provincia: "PRATO CARNICO", nazione: "ITALIA" },
  {
    codeKey: "H004",
    sigla: "BZ",
    provincia: "PRATO ALLO STELVIO",
    nazione: "ITALIA",
  },
  { codeKey: "H006", sigla: "AV", provincia: "PRATOLA SERRA", nazione: "ITALIA" },
  { codeKey: "H007", sigla: "AQ", provincia: "PRATOLA PELIGNA", nazione: "ITALIA" },
  { codeKey: "H010", sigla: "PN", provincia: "PRAVISDOMINI", nazione: "ITALIA" },
  { codeKey: "H011", sigla: "CN", provincia: "PRAZZO", nazione: "ITALIA" },
  { codeKey: "H013", sigla: "RC", provincia: "SAMO", nazione: "ITALIA" },
  { codeKey: "H014", sigla: "UD", provincia: "PRECENICCO", nazione: "ITALIA" },
  { codeKey: "H015", sigla: "PG", provincia: "PRECI", nazione: "ITALIA" },
  { codeKey: "H017", sigla: "FC", provincia: "PREDAPPIO", nazione: "ITALIA" },
  { codeKey: "H018", sigla: "TN", provincia: "PREDAZZO", nazione: "ITALIA" },
  { codeKey: "H019", sigla: "BZ", provincia: "PREDOI", nazione: "ITALIA" },
  { codeKey: "H020", sigla: "BG", provincia: "PREDORE", nazione: "ITALIA" },
  { codeKey: "H021", sigla: "AL", provincia: "PREDOSA", nazione: "ITALIA" },
  { codeKey: "H022", sigla: "TV", provincia: "PREGANZIOL", nazione: "ITALIA" },
  {
    codeKey: "H026",
    sigla: "MI",
    provincia: "PREGNANA MILANESE",
    nazione: "ITALIA",
  },
  { codeKey: "H027", sigla: "IM", provincia: "PRELA'", nazione: "ITALIA" },
  { codeKey: "H028", sigla: "LC", provincia: "PREMANA", nazione: "ITALIA" },
  { codeKey: "H029", sigla: "UD", provincia: "PREMARIACCO", nazione: "ITALIA" },
  { codeKey: "H030", sigla: "VB", provincia: "PREMENO", nazione: "ITALIA" },
  { codeKey: "H033", sigla: "VB", provincia: "PREMIA", nazione: "ITALIA" },
  { codeKey: "H034", sigla: "FC", provincia: "PREMILCUORE", nazione: "ITALIA" },
  { codeKey: "H036", sigla: "BG", provincia: "PREMOLO", nazione: "ITALIA" },
  {
    codeKey: "H037",
    sigla: "VB",
    provincia: "PREMOSELLO-CHIOVENDA",
    nazione: "ITALIA",
  },
  { codeKey: "H038", sigla: "UD", provincia: "PREONE", nazione: "ITALIA" },
  { codeKey: "H039", sigla: "TN", provincia: "PREORE", nazione: "ITALIA" },
  { codeKey: "H040", sigla: "UD", provincia: "PREPOTTO", nazione: "ITALIA" },
  {
    codeKey: "H042",
    sigla: "AO",
    provincia: "PRE'-SAINT-DIDIER",
    nazione: "ITALIA",
  },
  { codeKey: "H043", sigla: "BS", provincia: "PRESEGLIE", nazione: "ITALIA" },
  { codeKey: "H045", sigla: "CE", provincia: "PRESENZANO", nazione: "ITALIA" },
  { codeKey: "H046", sigla: "BG", provincia: "PRESEZZO", nazione: "ITALIA" },
  { codeKey: "H047", sigla: "LE", provincia: "PRESICCE", nazione: "ITALIA" },
  { codeKey: "H048", sigla: "VR", provincia: "PRESSANA", nazione: "ITALIA" },
  { codeKey: "H050", sigla: "BS", provincia: "PRESTINE", nazione: "ITALIA" },
  { codeKey: "H052", sigla: "CH", provincia: "PRETORO", nazione: "ITALIA" },
  { codeKey: "H055", sigla: "BS", provincia: "PREVALLE", nazione: "ITALIA" },
  { codeKey: "H056", sigla: "AQ", provincia: "PREZZA", nazione: "ITALIA" },
  { codeKey: "H057", sigla: "TN", provincia: "PREZZO", nazione: "ITALIA" },
  { codeKey: "H059", sigla: "CN", provincia: "PRIERO", nazione: "ITALIA" },
  {
    codeKey: "H061",
    sigla: "MO",
    provincia: "PRIGNANO SULLA SECCHIA",
    nazione: "ITALIA",
  },
  { codeKey: "H062", sigla: "SA", provincia: "PRIGNANO CILENTO", nazione: "ITALIA" },
  { codeKey: "H063", sigla: "LC", provincia: "PRIMALUNA", nazione: "ITALIA" },
  { codeKey: "H068", sigla: "CN", provincia: "PRIOCCA", nazione: "ITALIA" },
  { codeKey: "H069", sigla: "CN", provincia: "PRIOLA", nazione: "ITALIA" },
  { codeKey: "H070", sigla: "PA", provincia: "PRIZZI", nazione: "ITALIA" },
  { codeKey: "H071", sigla: "VT", provincia: "PROCENO", nazione: "ITALIA" },
  { codeKey: "H072", sigla: "NA", provincia: "PROCIDA", nazione: "ITALIA" },
  { codeKey: "H073", sigla: "GE", provincia: "PROPATA", nazione: "ITALIA" },
  { codeKey: "H074", sigla: "CO", provincia: "PROSERPIO", nazione: "ITALIA" },
  { codeKey: "H076", sigla: "LT", provincia: "PROSSEDI", nazione: "ITALIA" },
  {
    codeKey: "H077",
    sigla: "BS",
    provincia: "PROVAGLIO VAL SABBIA",
    nazione: "ITALIA",
  },
  { codeKey: "H078", sigla: "BS", provincia: "PROVAGLIO D'ISEO", nazione: "ITALIA" },
  { codeKey: "H081", sigla: "BZ", provincia: "PROVES", nazione: "ITALIA" },
  { codeKey: "H083", sigla: "CB", provincia: "PROVVIDENTI", nazione: "ITALIA" },
  { codeKey: "H085", sigla: "CN", provincia: "PRUNETTO", nazione: "ITALIA" },
  {
    codeKey: "H086",
    sigla: "BS",
    provincia: "PUEGNAGO SUL GARDA",
    nazione: "ITALIA",
  },
  { codeKey: "H087", sigla: "BN", provincia: "PUGLIANELLO", nazione: "ITALIA" },
  { codeKey: "H088", sigla: "CA", provincia: "PULA", nazione: "ITALIA" },
  { codeKey: "H089", sigla: "UD", provincia: "PULFERO", nazione: "ITALIA" },
  { codeKey: "H090", sigla: "TA", provincia: "PULSANO", nazione: "ITALIA" },
  { codeKey: "H091", sigla: "BG", provincia: "PUMENENGO", nazione: "ITALIA" },
  { codeKey: "H092", sigla: "BL", provincia: "PUOS D'ALPAGO", nazione: "ITALIA" },
  { codeKey: "H094", sigla: "CO", provincia: "PUSIANO", nazione: "ITALIA" },
  { codeKey: "H095", sigla: "SS", provincia: "PUTIFIGARI", nazione: "ITALIA" },
  { codeKey: "H096", sigla: "BA", provincia: "PUTIGNANO", nazione: "ITALIA" },
  { codeKey: "H097", sigla: "AV", provincia: "QUADRELLE", nazione: "ITALIA" },
  { codeKey: "H098", sigla: "CH", provincia: "QUADRI", nazione: "ITALIA" },
  { codeKey: "H100", sigla: "TO", provincia: "QUAGLIUZZO", nazione: "ITALIA" },
  { codeKey: "H101", sigla: "NA", provincia: "QUALIANO", nazione: "ITALIA" },
  { codeKey: "H102", sigla: "AT", provincia: "QUARANTI", nazione: "ITALIA" },
  { codeKey: "H103", sigla: "BI", provincia: "QUAREGNA", nazione: "ITALIA" },
  { codeKey: "H104", sigla: "AL", provincia: "QUARGNENTO", nazione: "ITALIA" },
  { codeKey: "H106", sigla: "VB", provincia: "QUARNA SOPRA", nazione: "ITALIA" },
  { codeKey: "H107", sigla: "VB", provincia: "QUARNA SOTTO", nazione: "ITALIA" },
  { codeKey: "H108", sigla: "VC", provincia: "QUARONA", nazione: "ITALIA" },
  { codeKey: "H109", sigla: "PT", provincia: "QUARRATA", nazione: "ITALIA" },
  { codeKey: "H110", sigla: "AO", provincia: "QUART", nazione: "ITALIA" },
  { codeKey: "H114", sigla: "NA", provincia: "QUARTO", nazione: "ITALIA" },
  { codeKey: "H117", sigla: "VE", provincia: "QUARTO D'ALTINO", nazione: "ITALIA" },
  {
    codeKey: "H118",
    sigla: "CA",
    provincia: "QUARTU SANT'ELENA",
    nazione: "ITALIA",
  },
  { codeKey: "H119", sigla: "CA", provincia: "QUARTUCCIU", nazione: "ITALIA" },
  { codeKey: "H120", sigla: "TO", provincia: "QUASSOLO", nazione: "ITALIA" },
  { codeKey: "H121", sigla: "AL", provincia: "QUATTORDIO", nazione: "ITALIA" },
  { codeKey: "H122", sigla: "RE", provincia: "QUATTRO CASTELLA", nazione: "ITALIA" },
  { codeKey: "H126", sigla: "SV", provincia: "QUILIANO", nazione: "ITALIA" },
  { codeKey: "H127", sigla: "TO", provincia: "QUINCINETTO", nazione: "ITALIA" },
  { codeKey: "H128", sigla: "AV", provincia: "QUINDICI", nazione: "ITALIA" },
  { codeKey: "H129", sigla: "MN", provincia: "QUINGENTOLE", nazione: "ITALIA" },
  { codeKey: "H130", sigla: "CR", provincia: "QUINTANO", nazione: "ITALIA" },
  {
    codeKey: "H131",
    sigla: "TV",
    provincia: "QUINTO DI TREVISO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H132",
    sigla: "VC",
    provincia: "QUINTO VERCELLESE",
    nazione: "ITALIA",
  },
  { codeKey: "H134", sigla: "VI", provincia: "QUINTO VICENTINO", nazione: "ITALIA" },
  { codeKey: "H140", sigla: "BS", provincia: "QUINZANO D'OGLIO", nazione: "ITALIA" },
  { codeKey: "H143", sigla: "MN", provincia: "QUISTELLO", nazione: "ITALIA" },
  { codeKey: "H145", sigla: "BI", provincia: "QUITTENGO", nazione: "ITALIA" },
  { codeKey: "H146", sigla: "TN", provincia: "RABBI", nazione: "ITALIA" },
  { codeKey: "H147", sigla: "LE", provincia: "RACALE", nazione: "ITALIA" },
  { codeKey: "H148", sigla: "AG", provincia: "RACALMUTO", nazione: "ITALIA" },
  { codeKey: "H150", sigla: "CN", provincia: "RACCONIGI", nazione: "ITALIA" },
  { codeKey: "H151", sigla: "ME", provincia: "RACCUJA", nazione: "ITALIA" },
  { codeKey: "H152", sigla: "BZ", provincia: "RACINES", nazione: "ITALIA" },
  { codeKey: "H153", sigla: "SI", provincia: "RADDA IN CHIANTI", nazione: "ITALIA" },
  { codeKey: "H154", sigla: "CT", provincia: "RADDUSA", nazione: "ITALIA" },
  { codeKey: "H156", sigla: "SI", provincia: "RADICOFANI", nazione: "ITALIA" },
  { codeKey: "H157", sigla: "SI", provincia: "RADICONDOLI", nazione: "ITALIA" },
  { codeKey: "H159", sigla: "AG", provincia: "RAFFADALI", nazione: "ITALIA" },
  { codeKey: "H161", sigla: "UD", provincia: "RAGOGNA", nazione: "ITALIA" },
  { codeKey: "H162", sigla: "TN", provincia: "RAGOLI", nazione: "ITALIA" },
  { codeKey: "H163", sigla: "RG", provincia: "RAGUSA", nazione: "ITALIA" },
  { codeKey: "H165", sigla: "CE", provincia: "RUVIANO", nazione: "ITALIA" },
  { codeKey: "H166", sigla: "AQ", provincia: "RAIANO", nazione: "ITALIA" },
  { codeKey: "H168", sigla: "CT", provincia: "RAMACCA", nazione: "ITALIA" },
  { codeKey: "H171", sigla: "CO", provincia: "RAMPONIO VERNA", nazione: "ITALIA" },
  { codeKey: "H173", sigla: "VA", provincia: "RANCIO VALCUVIA", nazione: "ITALIA" },
  { codeKey: "H174", sigla: "VA", provincia: "RANCO", nazione: "ITALIA" },
  { codeKey: "H175", sigla: "CT", provincia: "RANDAZZO", nazione: "ITALIA" },
  { codeKey: "H176", sigla: "BG", provincia: "RANICA", nazione: "ITALIA" },
  { codeKey: "H177", sigla: "BG", provincia: "RANZANICO", nazione: "ITALIA" },
  { codeKey: "H180", sigla: "IM", provincia: "RANZO", nazione: "ITALIA" },
  { codeKey: "H182", sigla: "FM", provincia: "RAPAGNANO", nazione: "ITALIA" },
  { codeKey: "H183", sigla: "GE", provincia: "RAPALLO", nazione: "ITALIA" },
  { codeKey: "H184", sigla: "CH", provincia: "RAPINO", nazione: "ITALIA" },
  { codeKey: "H185", sigla: "SI", provincia: "RAPOLANO TERME", nazione: "ITALIA" },
  { codeKey: "H186", sigla: "PZ", provincia: "RAPOLLA", nazione: "ITALIA" },
  { codeKey: "H187", sigla: "PZ", provincia: "RAPONE", nazione: "ITALIA" },
  { codeKey: "H188", sigla: "VC", provincia: "RASSA", nazione: "ITALIA" },
  { codeKey: "H189", sigla: "BZ", provincia: "RASUN-ANTERSELVA", nazione: "ITALIA" },
  { codeKey: "H192", sigla: "SO", provincia: "RASURA", nazione: "ITALIA" },
  { codeKey: "H194", sigla: "AG", provincia: "RAVANUSA", nazione: "ITALIA" },
  { codeKey: "H195", sigla: "MO", provincia: "RAVARINO", nazione: "ITALIA" },
  { codeKey: "H196", sigla: "UD", provincia: "RAVASCLETTO", nazione: "ITALIA" },
  { codeKey: "H198", sigla: "SA", provincia: "RAVELLO", nazione: "ITALIA" },
  { codeKey: "H199", sigla: "RA", provincia: "RAVENNA", nazione: "ITALIA" },
  { codeKey: "H200", sigla: "UD", provincia: "RAVEO", nazione: "ITALIA" },
  { codeKey: "H202", sigla: "CE", provincia: "RAVISCANINA", nazione: "ITALIA" },
  { codeKey: "H203", sigla: "VB", provincia: "RE", nazione: "ITALIA" },
  { codeKey: "H204", sigla: "PV", provincia: "REA", nazione: "ITALIA" },
  { codeKey: "H205", sigla: "AG", provincia: "REALMONTE", nazione: "ITALIA" },
  { codeKey: "H206", sigla: "UD", provincia: "REANA DEL ROIALE", nazione: "ITALIA" },
  { codeKey: "H207", sigla: "TO", provincia: "REANO", nazione: "ITALIA" },
  { codeKey: "H210", sigla: "CE", provincia: "RECALE", nazione: "ITALIA" },
  { codeKey: "H211", sigla: "MC", provincia: "RECANATI", nazione: "ITALIA" },
  { codeKey: "H212", sigla: "GE", provincia: "RECCO", nazione: "ITALIA" },
  { codeKey: "H213", sigla: "NO", provincia: "RECETTO", nazione: "ITALIA" },
  { codeKey: "H214", sigla: "VI", provincia: "RECOARO TERME", nazione: "ITALIA" },
  { codeKey: "H216", sigla: "PV", provincia: "REDAVALLE", nazione: "ITALIA" },
  { codeKey: "H218", sigla: "MN", provincia: "REDONDESCO", nazione: "ITALIA" },
  { codeKey: "H219", sigla: "AT", provincia: "REFRANCORE", nazione: "ITALIA" },
  { codeKey: "H220", sigla: "TV", provincia: "REFRONTOLO", nazione: "ITALIA" },
  { codeKey: "H221", sigla: "EN", provincia: "REGALBUTO", nazione: "ITALIA" },
  { codeKey: "H222", sigla: "FI", provincia: "REGGELLO", nazione: "ITALIA" },
  {
    codeKey: "H223",
    sigla: "RE",
    provincia: "REGGIO NELL'EMILIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "H224",
    sigla: "RC",
    provincia: "REGGIO DI CALABRIA",
    nazione: "ITALIA",
  },
  { codeKey: "H225", sigla: "RE", provincia: "REGGIOLO", nazione: "ITALIA" },
  { codeKey: "H227", sigla: "BN", provincia: "REINO", nazione: "ITALIA" },
  { codeKey: "H228", sigla: "ME", provincia: "REITANO", nazione: "ITALIA" },
  { codeKey: "H229", sigla: "UD", provincia: "REMANZACCO", nazione: "ITALIA" },
  { codeKey: "H230", sigla: "BS", provincia: "REMEDELLO", nazione: "ITALIA" },
  { codeKey: "H233", sigla: "MB", provincia: "RENATE", nazione: "ITALIA" },
  { codeKey: "H235", sigla: "CS", provincia: "RENDE", nazione: "ITALIA" },
  { codeKey: "H236", sigla: "BZ", provincia: "RENON", nazione: "ITALIA" },
  { codeKey: "H238", sigla: "TV", provincia: "RESANA", nazione: "ITALIA" },
  { codeKey: "H240", sigla: "MI", provincia: "RESCALDINA", nazione: "ITALIA" },
  { codeKey: "H242", sigla: "UD", provincia: "RESIA", nazione: "ITALIA" },
  { codeKey: "H243", sigla: "NA", provincia: "ERCOLANO", nazione: "ITALIA" },
  { codeKey: "H244", sigla: "UD", provincia: "RESIUTTA", nazione: "ITALIA" },
  { codeKey: "H245", sigla: "CL", provincia: "RESUTTANO", nazione: "ITALIA" },
  { codeKey: "H246", sigla: "PV", provincia: "RETORBIDO", nazione: "ITALIA" },
  { codeKey: "H247", sigla: "CN", provincia: "REVELLO", nazione: "ITALIA" },
  { codeKey: "H248", sigla: "MN", provincia: "REVERE", nazione: "ITALIA" },
  {
    codeKey: "H250",
    sigla: "AT",
    provincia: "REVIGLIASCO D'ASTI",
    nazione: "ITALIA",
  },
  { codeKey: "H253", sigla: "TV", provincia: "REVINE LAGO", nazione: "ITALIA" },
  { codeKey: "H254", sigla: "TN", provincia: "REVO'", nazione: "ITALIA" },
  { codeKey: "H255", sigla: "CO", provincia: "REZZAGO", nazione: "ITALIA" },
  { codeKey: "H256", sigla: "BS", provincia: "REZZATO", nazione: "ITALIA" },
  { codeKey: "H257", sigla: "IM", provincia: "REZZO", nazione: "ITALIA" },
  { codeKey: "H258", sigla: "GE", provincia: "REZZOAGLIO", nazione: "ITALIA" },
  { codeKey: "H259", sigla: "CO", provincia: "VAL REZZO", nazione: "ITALIA" },
  {
    codeKey: "H262",
    sigla: "AO",
    provincia: "RHEMES-NOTRE-DAME",
    nazione: "ITALIA",
  },
  {
    codeKey: "H263",
    sigla: "AO",
    provincia: "RHEMES-SAINT-GEORGES",
    nazione: "ITALIA",
  },
  { codeKey: "H264", sigla: "MI", provincia: "RHO", nazione: "ITALIA" },
  { codeKey: "H265", sigla: "RC", provincia: "RIACE", nazione: "ITALIA" },
  { codeKey: "H266", sigla: "SV", provincia: "RIALTO", nazione: "ITALIA" },
  { codeKey: "H267", sigla: "RM", provincia: "RIANO", nazione: "ITALIA" },
  { codeKey: "H268", sigla: "CE", provincia: "RIARDO", nazione: "ITALIA" },
  { codeKey: "H269", sigla: "AG", provincia: "RIBERA", nazione: "ITALIA" },
  { codeKey: "H270", sigla: "TO", provincia: "RIBORDONE", nazione: "ITALIA" },
  { codeKey: "H271", sigla: "VV", provincia: "RICADI", nazione: "ITALIA" },
  { codeKey: "H272", sigla: "AL", provincia: "RICALDONE", nazione: "ITALIA" },
  { codeKey: "H273", sigla: "CB", provincia: "RICCIA", nazione: "ITALIA" },
  { codeKey: "H274", sigla: "RN", provincia: "RICCIONE", nazione: "ITALIA" },
  {
    codeKey: "H275",
    sigla: "SP",
    provincia: "RICCO' DEL GOLFO DI SPEZIA",
    nazione: "ITALIA",
  },
  { codeKey: "H276", sigla: "CR", provincia: "RICENGO", nazione: "ITALIA" },
  { codeKey: "H277", sigla: "SA", provincia: "RICIGLIANO", nazione: "ITALIA" },
  { codeKey: "H280", sigla: "TV", provincia: "RIESE PIO X", nazione: "ITALIA" },
  { codeKey: "H281", sigla: "CL", provincia: "RIESI", nazione: "ITALIA" },
  { codeKey: "H282", sigla: "RI", provincia: "RIETI", nazione: "ITALIA" },
  { codeKey: "H284", sigla: "BZ", provincia: "RIFIANO", nazione: "ITALIA" },
  { codeKey: "H285", sigla: "CN", provincia: "RIFREDDO", nazione: "ITALIA" },
  {
    codeKey: "H286",
    sigla: "FI",
    provincia: "RIGNANO SULL'ARNO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H287",
    sigla: "FG",
    provincia: "RIGNANO GARGANICO",
    nazione: "ITALIA",
  },
  { codeKey: "H288", sigla: "RM", provincia: "RIGNANO FLAMINIO", nazione: "ITALIA" },
  { codeKey: "H289", sigla: "UD", provincia: "RIGOLATO", nazione: "ITALIA" },
  {
    codeKey: "H291",
    sigla: "VC",
    provincia: "RIMA SAN GIUSEPPE",
    nazione: "ITALIA",
  },
  { codeKey: "H292", sigla: "VC", provincia: "RIMASCO", nazione: "ITALIA" },
  { codeKey: "H293", sigla: "VC", provincia: "RIMELLA", nazione: "ITALIA" },
  { codeKey: "H294", sigla: "RN", provincia: "RIMINI", nazione: "ITALIA" },
  { codeKey: "H297", sigla: "LI", provincia: "RIO NELL'ELBA", nazione: "ITALIA" },
  { codeKey: "H298", sigla: "RE", provincia: "RIO SALICETO", nazione: "ITALIA" },
  { codeKey: "H299", sigla: "BZ", provincia: "RIO DI PUSTERIA", nazione: "ITALIA" },
  { codeKey: "H300", sigla: "RM", provincia: "RIOFREDDO", nazione: "ITALIA" },
  { codeKey: "H301", sigla: "OR", provincia: "RIOLA SARDO", nazione: "ITALIA" },
  { codeKey: "H302", sigla: "RA", provincia: "RIOLO TERME", nazione: "ITALIA" },
  { codeKey: "H303", sigla: "MO", provincia: "RIOLUNATO", nazione: "ITALIA" },
  { codeKey: "H304", sigla: "SP", provincia: "RIOMAGGIORE", nazione: "ITALIA" },
  { codeKey: "H305", sigla: "LI", provincia: "RIO MARINA", nazione: "ITALIA" },
  {
    codeKey: "H307",
    sigla: "PZ",
    provincia: "RIONERO IN VULTURE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H308",
    sigla: "IS",
    provincia: "RIONERO SANNITICO",
    nazione: "ITALIA",
  },
  { codeKey: "H311", sigla: "CB", provincia: "RIPABOTTONI", nazione: "ITALIA" },
  { codeKey: "H312", sigla: "PZ", provincia: "RIPACANDIDA", nazione: "ITALIA" },
  { codeKey: "H313", sigla: "CB", provincia: "RIPALIMOSANI", nazione: "ITALIA" },
  { codeKey: "H314", sigla: "CR", provincia: "RIPALTA ARPINA", nazione: "ITALIA" },
  { codeKey: "H315", sigla: "CR", provincia: "RIPALTA CREMASCA", nazione: "ITALIA" },
  { codeKey: "H316", sigla: "CR", provincia: "RIPALTA GUERINA", nazione: "ITALIA" },
  { codeKey: "H319", sigla: "PI", provincia: "RIPARBELLA", nazione: "ITALIA" },
  { codeKey: "H320", sigla: "CH", provincia: "RIPA TEATINA", nazione: "ITALIA" },
  { codeKey: "H321", sigla: "AP", provincia: "RIPATRANSONE", nazione: "ITALIA" },
  { codeKey: "H323", sigla: "MC", provincia: "RIPE SAN GINESIO", nazione: "ITALIA" },
  { codeKey: "H324", sigla: "FR", provincia: "RIPI", nazione: "ITALIA" },
  { codeKey: "H325", sigla: "CT", provincia: "RIPOSTO", nazione: "ITALIA" },
  { codeKey: "H326", sigla: "CN", provincia: "RITTANA", nazione: "ITALIA" },
  {
    codeKey: "H327",
    sigla: "BL",
    provincia: "RIVAMONTE AGORDINO",
    nazione: "ITALIA",
  },
  { codeKey: "H328", sigla: "IM", provincia: "RIVA LIGURE", nazione: "ITALIA" },
  { codeKey: "H329", sigla: "VC", provincia: "RIVA VALDOBBIA", nazione: "ITALIA" },
  { codeKey: "H330", sigla: "TN", provincia: "RIVA DEL GARDA", nazione: "ITALIA" },
  { codeKey: "H331", sigla: "BG", provincia: "RIVA DI SOLTO", nazione: "ITALIA" },
  { codeKey: "H333", sigla: "TO", provincia: "RIVALBA", nazione: "ITALIA" },
  { codeKey: "H334", sigla: "AL", provincia: "RIVALTA BORMIDA", nazione: "ITALIA" },
  {
    codeKey: "H335",
    sigla: "TO",
    provincia: "RIVALTA DI TORINO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H336",
    sigla: "PV",
    provincia: "RIVANAZZANO TERME",
    nazione: "ITALIA",
  },
  {
    codeKey: "H337",
    sigla: "TO",
    provincia: "RIVA PRESSO CHIERI",
    nazione: "ITALIA",
  },
  { codeKey: "H338", sigla: "TO", provincia: "RIVARA", nazione: "ITALIA" },
  {
    codeKey: "H340",
    sigla: "TO",
    provincia: "RIVAROLO CANAVESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H341",
    sigla: "CR",
    provincia: "RIVAROLO DEL RE ED UNITI",
    nazione: "ITALIA",
  },
  {
    codeKey: "H342",
    sigla: "MN",
    provincia: "RIVAROLO MANTOVANO",
    nazione: "ITALIA",
  },
  { codeKey: "H343", sigla: "AL", provincia: "RIVARONE", nazione: "ITALIA" },
  { codeKey: "H344", sigla: "TO", provincia: "RIVAROSSA", nazione: "ITALIA" },
  { codeKey: "H346", sigla: "VC", provincia: "RIVE", nazione: "ITALIA" },
  { codeKey: "H347", sigla: "UD", provincia: "RIVE D'ARCANO", nazione: "ITALIA" },
  { codeKey: "H348", sigla: "PZ", provincia: "RIVELLO", nazione: "ITALIA" },
  { codeKey: "H350", sigla: "PC", provincia: "RIVERGARO", nazione: "ITALIA" },
  { codeKey: "H353", sigla: "AQ", provincia: "RIVISONDOLI", nazione: "ITALIA" },
  { codeKey: "H354", sigla: "RI", provincia: "RIVODUTRI", nazione: "ITALIA" },
  { codeKey: "H355", sigla: "TO", provincia: "RIVOLI", nazione: "ITALIA" },
  { codeKey: "H356", sigla: "VR", provincia: "RIVOLI VERONESE", nazione: "ITALIA" },
  { codeKey: "H357", sigla: "CR", provincia: "RIVOLTA D'ADDA", nazione: "ITALIA" },
  { codeKey: "H359", sigla: "RC", provincia: "RIZZICONI", nazione: "ITALIA" },
  { codeKey: "H360", sigla: "FE", provincia: "RO", nazione: "ITALIA" },
  { codeKey: "H361", sigla: "VI", provincia: "ROANA", nazione: "ITALIA" },
  { codeKey: "H362", sigla: "CN", provincia: "ROASCHIA", nazione: "ITALIA" },
  { codeKey: "H363", sigla: "CN", provincia: "ROASCIO", nazione: "ITALIA" },
  { codeKey: "H364", sigla: "VC", provincia: "ROVASENDA", nazione: "ITALIA" },
  { codeKey: "H365", sigla: "VC", provincia: "ROASIO", nazione: "ITALIA" },
  { codeKey: "H366", sigla: "AT", provincia: "ROATTO", nazione: "ITALIA" },
  { codeKey: "H367", sigla: "TO", provincia: "ROBASSOMERO", nazione: "ITALIA" },
  { codeKey: "H369", sigla: "PV", provincia: "ROBBIO", nazione: "ITALIA" },
  {
    codeKey: "H371",
    sigla: "MI",
    provincia: "ROBECCHETTO CON INDUNO",
    nazione: "ITALIA",
  },
  { codeKey: "H372", sigla: "CR", provincia: "ROBECCO D'OGLIO", nazione: "ITALIA" },
  {
    codeKey: "H373",
    sigla: "MI",
    provincia: "ROBECCO SUL NAVIGLIO",
    nazione: "ITALIA",
  },
  { codeKey: "H375", sigla: "PV", provincia: "ROBECCO PAVESE", nazione: "ITALIA" },
  { codeKey: "H376", sigla: "AT", provincia: "ROBELLA", nazione: "ITALIA" },
  { codeKey: "H377", sigla: "CN", provincia: "ROBILANTE", nazione: "ITALIA" },
  { codeKey: "H378", sigla: "CN", provincia: "ROBURENT", nazione: "ITALIA" },
  { codeKey: "H379", sigla: "BL", provincia: "ROCCA PIETORE", nazione: "ITALIA" },
  { codeKey: "H380", sigla: "ME", provincia: "ROCCAVALDINA", nazione: "ITALIA" },
  { codeKey: "H382", sigla: "AV", provincia: "ROCCABASCERANA", nazione: "ITALIA" },
  { codeKey: "H383", sigla: "KR", provincia: "ROCCABERNARDA", nazione: "ITALIA" },
  { codeKey: "H384", sigla: "PR", provincia: "ROCCABIANCA", nazione: "ITALIA" },
  { codeKey: "H385", sigla: "CN", provincia: "ROCCABRUNA", nazione: "ITALIA" },
  { codeKey: "H386", sigla: "TO", provincia: "ROCCA CANAVESE", nazione: "ITALIA" },
  { codeKey: "H387", sigla: "RM", provincia: "ROCCA CANTERANO", nazione: "ITALIA" },
  { codeKey: "H389", sigla: "AQ", provincia: "ROCCACASALE", nazione: "ITALIA" },
  { codeKey: "H390", sigla: "AP", provincia: "ROCCAFLUVIONE", nazione: "ITALIA" },
  { codeKey: "H391", sigla: "CN", provincia: "ROCCA CIGLIE'", nazione: "ITALIA" },
  { codeKey: "H392", sigla: "AT", provincia: "ROCCA D'ARAZZO", nazione: "ITALIA" },
  { codeKey: "H393", sigla: "FR", provincia: "ROCCADARCE", nazione: "ITALIA" },
  { codeKey: "H394", sigla: "SA", provincia: "ROCCADASPIDE", nazione: "ITALIA" },
  { codeKey: "H395", sigla: "CN", provincia: "ROCCA DE' BALDI", nazione: "ITALIA" },
  { codeKey: "H396", sigla: "PV", provincia: "ROCCA DE' GIORGI", nazione: "ITALIA" },
  { codeKey: "H398", sigla: "CE", provincia: "ROCCA D'EVANDRO", nazione: "ITALIA" },
  { codeKey: "H399", sigla: "AQ", provincia: "ROCCA DI BOTTE", nazione: "ITALIA" },
  { codeKey: "H400", sigla: "AQ", provincia: "ROCCA DI CAMBIO", nazione: "ITALIA" },
  { codeKey: "H401", sigla: "RM", provincia: "ROCCA DI CAVE", nazione: "ITALIA" },
  { codeKey: "H402", sigla: "AQ", provincia: "ROCCA DI MEZZO", nazione: "ITALIA" },
  { codeKey: "H403", sigla: "KR", provincia: "ROCCA DI NETO", nazione: "ITALIA" },
  { codeKey: "H404", sigla: "RM", provincia: "ROCCA DI PAPA", nazione: "ITALIA" },
  { codeKey: "H405", sigla: "ME", provincia: "ROCCAFIORITA", nazione: "ITALIA" },
  {
    codeKey: "H406",
    sigla: "AL",
    provincia: "ROCCAFORTE LIGURE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H407",
    sigla: "CN",
    provincia: "ROCCAFORTE MONDOVI'",
    nazione: "ITALIA",
  },
  {
    codeKey: "H408",
    sigla: "RC",
    provincia: "ROCCAFORTE DEL GRECO",
    nazione: "ITALIA",
  },
  { codeKey: "H409", sigla: "TA", provincia: "ROCCAFORZATA", nazione: "ITALIA" },
  { codeKey: "H410", sigla: "BS", provincia: "ROCCAFRANCA", nazione: "ITALIA" },
  { codeKey: "H411", sigla: "RM", provincia: "ROCCAGIOVINE", nazione: "ITALIA" },
  { codeKey: "H412", sigla: "SA", provincia: "ROCCAGLORIOSA", nazione: "ITALIA" },
  { codeKey: "H413", sigla: "LT", provincia: "ROCCAGORGA", nazione: "ITALIA" },
  { codeKey: "H414", sigla: "AL", provincia: "ROCCA GRIMALDA", nazione: "ITALIA" },
  { codeKey: "H416", sigla: "CS", provincia: "ROCCA IMPERIALE", nazione: "ITALIA" },
  { codeKey: "H417", sigla: "GR", provincia: "ROCCALBEGNA", nazione: "ITALIA" },
  { codeKey: "H418", sigla: "ME", provincia: "ROCCALUMERA", nazione: "ITALIA" },
  { codeKey: "H420", sigla: "IS", provincia: "ROCCAMANDOLFI", nazione: "ITALIA" },
  { codeKey: "H421", sigla: "LT", provincia: "ROCCA MASSIMA", nazione: "ITALIA" },
  { codeKey: "H422", sigla: "PA", provincia: "ROCCAMENA", nazione: "ITALIA" },
  { codeKey: "H423", sigla: "CE", provincia: "ROCCAMONFINA", nazione: "ITALIA" },
  { codeKey: "H424", sigla: "CH", provincia: "ROCCAMONTEPIANO", nazione: "ITALIA" },
  { codeKey: "H425", sigla: "PE", provincia: "ROCCAMORICE", nazione: "ITALIA" },
  { codeKey: "H426", sigla: "PZ", provincia: "ROCCANOVA", nazione: "ITALIA" },
  { codeKey: "H427", sigla: "RI", provincia: "ROCCANTICA", nazione: "ITALIA" },
  { codeKey: "H428", sigla: "PA", provincia: "ROCCAPALUMBA", nazione: "ITALIA" },
  { codeKey: "H429", sigla: "AQ", provincia: "ROCCA PIA", nazione: "ITALIA" },
  { codeKey: "H431", sigla: "SA", provincia: "ROCCAPIEMONTE", nazione: "ITALIA" },
  { codeKey: "H432", sigla: "RM", provincia: "ROCCA PRIORA", nazione: "ITALIA" },
  { codeKey: "H433", sigla: "NA", provincia: "ROCCARAINOLA", nazione: "ITALIA" },
  { codeKey: "H434", sigla: "AQ", provincia: "ROCCARASO", nazione: "ITALIA" },
  { codeKey: "H436", sigla: "CE", provincia: "ROCCAROMANA", nazione: "ITALIA" },
  {
    codeKey: "H437",
    sigla: "FC",
    provincia: "ROCCA SAN CASCIANO",
    nazione: "ITALIA",
  },
  { codeKey: "H438", sigla: "AV", provincia: "ROCCA SAN FELICE", nazione: "ITALIA" },
  {
    codeKey: "H439",
    sigla: "CH",
    provincia: "ROCCA SAN GIOVANNI",
    nazione: "ITALIA",
  },
  {
    codeKey: "H440",
    sigla: "TE",
    provincia: "ROCCA SANTA MARIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "H441",
    sigla: "RM",
    provincia: "ROCCA SANTO STEFANO",
    nazione: "ITALIA",
  },
  { codeKey: "H442", sigla: "CH", provincia: "ROCCASCALEGNA", nazione: "ITALIA" },
  { codeKey: "H443", sigla: "FR", provincia: "ROCCASECCA", nazione: "ITALIA" },
  {
    codeKey: "H444",
    sigla: "LT",
    provincia: "ROCCASECCA DEI VOLSCI",
    nazione: "ITALIA",
  },
  { codeKey: "H445", sigla: "IS", provincia: "ROCCASICURA", nazione: "ITALIA" },
  { codeKey: "H446", sigla: "RI", provincia: "ROCCA SINIBALDA", nazione: "ITALIA" },
  { codeKey: "H447", sigla: "CN", provincia: "ROCCASPARVERA", nazione: "ITALIA" },
  { codeKey: "H448", sigla: "CH", provincia: "ROCCASPINALVETI", nazione: "ITALIA" },
  { codeKey: "H449", sigla: "GR", provincia: "ROCCASTRADA", nazione: "ITALIA" },
  { codeKey: "H450", sigla: "PV", provincia: "ROCCA SUSELLA", nazione: "ITALIA" },
  { codeKey: "H451", sigla: "AT", provincia: "ROCCAVERANO", nazione: "ITALIA" },
  { codeKey: "H452", sigla: "SV", provincia: "ROCCAVIGNALE", nazione: "ITALIA" },
  { codeKey: "H453", sigla: "CN", provincia: "ROCCAVIONE", nazione: "ITALIA" },
  { codeKey: "H454", sigla: "CB", provincia: "ROCCAVIVARA", nazione: "ITALIA" },
  {
    codeKey: "H455",
    sigla: "ME",
    provincia: "ROCCELLA VALDEMONE",
    nazione: "ITALIA",
  },
  { codeKey: "H456", sigla: "RC", provincia: "ROCCELLA IONICA", nazione: "ITALIA" },
  {
    codeKey: "H458",
    sigla: "IS",
    provincia: "ROCCHETTA A VOLTURNO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H459",
    sigla: "CE",
    provincia: "ROCCHETTA E CROCE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H460",
    sigla: "IM",
    provincia: "ROCCHETTA NERVINA",
    nazione: "ITALIA",
  },
  {
    codeKey: "H461",
    sigla: "SP",
    provincia: "ROCCHETTA DI VARA",
    nazione: "ITALIA",
  },
  { codeKey: "H462", sigla: "CN", provincia: "ROCCHETTA BELBO", nazione: "ITALIA" },
  { codeKey: "H465", sigla: "AL", provincia: "ROCCHETTA LIGURE", nazione: "ITALIA" },
  {
    codeKey: "H466",
    sigla: "AT",
    provincia: "ROCCHETTA PALAFEA",
    nazione: "ITALIA",
  },
  {
    codeKey: "H467",
    sigla: "FG",
    provincia: "ROCCHETTA SANT'ANTONIO",
    nazione: "ITALIA",
  },
  { codeKey: "H468", sigla: "AT", provincia: "ROCCHETTA TANARO", nazione: "ITALIA" },
  { codeKey: "H470", sigla: "MI", provincia: "RODANO", nazione: "ITALIA" },
  { codeKey: "H472", sigla: "CN", provincia: "RODDI", nazione: "ITALIA" },
  { codeKey: "H473", sigla: "CN", provincia: "RODDINO", nazione: "ITALIA" },
  { codeKey: "H474", sigla: "CN", provincia: "RODELLO", nazione: "ITALIA" },
  { codeKey: "H475", sigla: "BZ", provincia: "RODENGO", nazione: "ITALIA" },
  { codeKey: "H477", sigla: "BS", provincia: "RODENGO-SAIANO", nazione: "ITALIA" },
  { codeKey: "H478", sigla: "CO", provincia: "RODERO", nazione: "ITALIA" },
  { codeKey: "H479", sigla: "ME", provincia: "RODI' MILICI", nazione: "ITALIA" },
  { codeKey: "H480", sigla: "FG", provincia: "RODI GARGANICO", nazione: "ITALIA" },
  { codeKey: "H481", sigla: "MN", provincia: "RODIGO", nazione: "ITALIA" },
  { codeKey: "H484", sigla: "BS", provincia: "ROE' VOLCIANO", nazione: "ITALIA" },
  { codeKey: "H485", sigla: "SA", provincia: "ROFRANO", nazione: "ITALIA" },
  { codeKey: "H486", sigla: "LC", provincia: "ROGENO", nazione: "ITALIA" },
  { codeKey: "H488", sigla: "CS", provincia: "ROGGIANO GRAVINA", nazione: "ITALIA" },
  { codeKey: "H489", sigla: "RC", provincia: "ROGHUDI", nazione: "ITALIA" },
  { codeKey: "H490", sigla: "CS", provincia: "ROGLIANO", nazione: "ITALIA" },
  { codeKey: "H491", sigla: "PV", provincia: "ROGNANO", nazione: "ITALIA" },
  { codeKey: "H492", sigla: "BG", provincia: "ROGNO", nazione: "ITALIA" },
  { codeKey: "H493", sigla: "SO", provincia: "ROGOLO", nazione: "ITALIA" },
  { codeKey: "H494", sigla: "RM", provincia: "ROIATE", nazione: "ITALIA" },
  { codeKey: "H495", sigla: "CH", provincia: "ROIO DEL SANGRO", nazione: "ITALIA" },
  { codeKey: "H497", sigla: "AO", provincia: "ROISAN", nazione: "ITALIA" },
  { codeKey: "H498", sigla: "TO", provincia: "ROLETTO", nazione: "ITALIA" },
  { codeKey: "H500", sigla: "RE", provincia: "ROLO", nazione: "ITALIA" },
  { codeKey: "H501", sigla: "RM", provincia: "ROMA", nazione: "ITALIA" },
  { codeKey: "H502", sigla: "NO", provincia: "ROMAGNANO SESIA", nazione: "ITALIA" },
  {
    codeKey: "H503",
    sigla: "SA",
    provincia: "ROMAGNANO AL MONTE",
    nazione: "ITALIA",
  },
  { codeKey: "H505", sigla: "PV", provincia: "ROMAGNESE", nazione: "ITALIA" },
  { codeKey: "H506", sigla: "TN", provincia: "ROMALLO", nazione: "ITALIA" },
  { codeKey: "H507", sigla: "SS", provincia: "ROMANA", nazione: "ITALIA" },
  { codeKey: "H508", sigla: "CR", provincia: "ROMANENGO", nazione: "ITALIA" },
  {
    codeKey: "H509",
    sigla: "BG",
    provincia: "ROMANO DI LOMBARDIA",
    nazione: "ITALIA",
  },
  { codeKey: "H511", sigla: "TO", provincia: "ROMANO CANAVESE", nazione: "ITALIA" },
  {
    codeKey: "H512",
    sigla: "VI",
    provincia: "ROMANO D'EZZELINO",
    nazione: "ITALIA",
  },
  { codeKey: "H514", sigla: "GO", provincia: "ROMANS D'ISONZO", nazione: "ITALIA" },
  { codeKey: "H516", sigla: "VV", provincia: "ROMBIOLO", nazione: "ITALIA" },
  { codeKey: "H517", sigla: "TN", provincia: "ROMENO", nazione: "ITALIA" },
  { codeKey: "H518", sigla: "NO", provincia: "ROMENTINO", nazione: "ITALIA" },
  { codeKey: "H519", sigla: "ME", provincia: "ROMETTA", nazione: "ITALIA" },
  { codeKey: "H521", sigla: "CO", provincia: "RONAGO", nazione: "ITALIA" },
  { codeKey: "H522", sigla: "VR", provincia: "RONCA'", nazione: "ITALIA" },
  { codeKey: "H523", sigla: "TV", provincia: "RONCADE", nazione: "ITALIA" },
  { codeKey: "H525", sigla: "BS", provincia: "RONCADELLE", nazione: "ITALIA" },
  { codeKey: "H527", sigla: "PV", provincia: "RONCARO", nazione: "ITALIA" },
  { codeKey: "H528", sigla: "TN", provincia: "RONCEGNO", nazione: "ITALIA" },
  { codeKey: "H529", sigla: "MB", provincia: "RONCELLO", nazione: "ITALIA" },
  {
    codeKey: "H531",
    sigla: "GO",
    provincia: "RONCHI DEI LEGIONARI",
    nazione: "ITALIA",
  },
  { codeKey: "H532", sigla: "TN", provincia: "RONCHI VALSUGANA", nazione: "ITALIA" },
  { codeKey: "H533", sigla: "UD", provincia: "RONCHIS", nazione: "ITALIA" },
  { codeKey: "H534", sigla: "VT", provincia: "RONCIGLIONE", nazione: "ITALIA" },
  { codeKey: "H535", sigla: "BG", provincia: "RONCOBELLO", nazione: "ITALIA" },
  { codeKey: "H536", sigla: "GE", provincia: "RONCO SCRIVIA", nazione: "ITALIA" },
  { codeKey: "H537", sigla: "MB", provincia: "RONCO BRIANTINO", nazione: "ITALIA" },
  { codeKey: "H538", sigla: "BI", provincia: "RONCO BIELLESE", nazione: "ITALIA" },
  { codeKey: "H539", sigla: "TO", provincia: "RONCO CANAVESE", nazione: "ITALIA" },
  { codeKey: "H540", sigla: "VR", provincia: "RONCO ALL'ADIGE", nazione: "ITALIA" },
  { codeKey: "H541", sigla: "MN", provincia: "RONCOFERRARO", nazione: "ITALIA" },
  { codeKey: "H542", sigla: "FC", provincia: "RONCOFREDDO", nazione: "ITALIA" },
  { codeKey: "H544", sigla: "BG", provincia: "RONCOLA", nazione: "ITALIA" },
  { codeKey: "H545", sigla: "TN", provincia: "RONCONE", nazione: "ITALIA" },
  { codeKey: "H546", sigla: "GE", provincia: "RONDANINA", nazione: "ITALIA" },
  { codeKey: "H547", sigla: "TO", provincia: "RONDISSONE", nazione: "ITALIA" },
  { codeKey: "H549", sigla: "VC", provincia: "RONSECCO", nazione: "ITALIA" },
  { codeKey: "H552", sigla: "TN", provincia: "RONZONE", nazione: "ITALIA" },
  { codeKey: "H553", sigla: "BI", provincia: "ROPPOLO", nazione: "ITALIA" },
  { codeKey: "H554", sigla: "TO", provincia: "RORA'", nazione: "ITALIA" },
  { codeKey: "H555", sigla: "TO", provincia: "ROURE", nazione: "ITALIA" },
  { codeKey: "H556", sigla: "VI", provincia: "ROSA'", nazione: "ITALIA" },
  { codeKey: "H558", sigla: "RC", provincia: "ROSARNO", nazione: "ITALIA" },
  { codeKey: "H559", sigla: "PV", provincia: "ROSASCO", nazione: "ITALIA" },
  { codeKey: "H560", sigla: "MI", provincia: "ROSATE", nazione: "ITALIA" },
  { codeKey: "H561", sigla: "BI", provincia: "ROSAZZA", nazione: "ITALIA" },
  { codeKey: "H562", sigla: "PE", provincia: "ROSCIANO", nazione: "ITALIA" },
  { codeKey: "H564", sigla: "SA", provincia: "ROSCIGNO", nazione: "ITALIA" },
  { codeKey: "H565", sigla: "CS", provincia: "ROSE", nazione: "ITALIA" },
  { codeKey: "H566", sigla: "CH", provincia: "ROSELLO", nazione: "ITALIA" },
  {
    codeKey: "H568",
    sigla: "FG",
    provincia: "ROSETO VALFORTORE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H569",
    sigla: "AL",
    provincia: "ROSIGNANO MONFERRATO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H570",
    sigla: "LI",
    provincia: "ROSIGNANO MARITTIMO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H572",
    sigla: "CS",
    provincia: "ROSETO CAPO SPULICO",
    nazione: "ITALIA",
  },
  { codeKey: "H573", sigla: "RO", provincia: "ROSOLINA", nazione: "ITALIA" },
  { codeKey: "H574", sigla: "SR", provincia: "ROSOLINI", nazione: "ITALIA" },
  { codeKey: "H575", sigla: "AN", provincia: "ROSORA", nazione: "ITALIA" },
  { codeKey: "H577", sigla: "VC", provincia: "ROSSA", nazione: "ITALIA" },
  { codeKey: "H578", sigla: "CN", provincia: "ROSSANA", nazione: "ITALIA" },
  { codeKey: "H579", sigla: "CS", provincia: "ROSSANO", nazione: "ITALIA" },
  { codeKey: "H580", sigla: "VI", provincia: "ROSSANO VENETO", nazione: "ITALIA" },
  { codeKey: "H581", sigla: "GE", provincia: "ROSSIGLIONE", nazione: "ITALIA" },
  { codeKey: "H583", sigla: "TO", provincia: "ROSTA", nazione: "ITALIA" },
  { codeKey: "H584", sigla: "BG", provincia: "ROTA D'IMAGNA", nazione: "ITALIA" },
  { codeKey: "H585", sigla: "CS", provincia: "ROTA GRECA", nazione: "ITALIA" },
  { codeKey: "H588", sigla: "AP", provincia: "ROTELLA", nazione: "ITALIA" },
  { codeKey: "H589", sigla: "CB", provincia: "ROTELLO", nazione: "ITALIA" },
  { codeKey: "H590", sigla: "PZ", provincia: "ROTONDA", nazione: "ITALIA" },
  { codeKey: "H591", sigla: "MT", provincia: "ROTONDELLA", nazione: "ITALIA" },
  { codeKey: "H592", sigla: "AV", provincia: "ROTONDI", nazione: "ITALIA" },
  { codeKey: "H593", sigla: "PC", provincia: "ROTTOFRENO", nazione: "ITALIA" },
  { codeKey: "H594", sigla: "VI", provincia: "ROTZO", nazione: "ITALIA" },
  { codeKey: "H598", sigla: "BS", provincia: "ROVATO", nazione: "ITALIA" },
  { codeKey: "H599", sigla: "GE", provincia: "ROVEGNO", nazione: "ITALIA" },
  { codeKey: "H601", sigla: "CO", provincia: "ROVELLASCA", nazione: "ITALIA" },
  { codeKey: "H602", sigla: "CO", provincia: "ROVELLO PORRO", nazione: "ITALIA" },
  { codeKey: "H604", sigla: "MN", provincia: "ROVERBELLA", nazione: "ITALIA" },
  { codeKey: "H606", sigla: "VR", provincia: "ROVERCHIARA", nazione: "ITALIA" },
  {
    codeKey: "H607",
    sigla: "TN",
    provincia: "ROVERE' DELLA LUNA",
    nazione: "ITALIA",
  },
  { codeKey: "H608", sigla: "VR", provincia: "ROVERE' VERONESE", nazione: "ITALIA" },
  {
    codeKey: "H609",
    sigla: "PN",
    provincia: "ROVEREDO IN PIANO",
    nazione: "ITALIA",
  },
  { codeKey: "H610", sigla: "VR", provincia: "ROVEREDO DI GUA'", nazione: "ITALIA" },
  { codeKey: "H612", sigla: "TN", provincia: "ROVERETO", nazione: "ITALIA" },
  { codeKey: "H614", sigla: "PV", provincia: "ROVESCALA", nazione: "ITALIA" },
  { codeKey: "H615", sigla: "BG", provincia: "ROVETTA", nazione: "ITALIA" },
  { codeKey: "H618", sigla: "RM", provincia: "ROVIANO", nazione: "ITALIA" },
  { codeKey: "H620", sigla: "RO", provincia: "ROVIGO", nazione: "ITALIA" },
  { codeKey: "H621", sigla: "CS", provincia: "ROVITO", nazione: "ITALIA" },
  { codeKey: "H622", sigla: "PD", provincia: "ROVOLON", nazione: "ITALIA" },
  { codeKey: "H623", sigla: "MI", provincia: "ROZZANO", nazione: "ITALIA" },
  { codeKey: "H625", sigla: "PD", provincia: "RUBANO", nazione: "ITALIA" },
  { codeKey: "H627", sigla: "TO", provincia: "RUBIANA", nazione: "ITALIA" },
  { codeKey: "H628", sigla: "RE", provincia: "RUBIERA", nazione: "ITALIA" },
  { codeKey: "H629", sigla: "UD", provincia: "RUDA", nazione: "ITALIA" },
  { codeKey: "H630", sigla: "BS", provincia: "RUDIANO", nazione: "ITALIA" },
  { codeKey: "H631", sigla: "TO", provincia: "RUEGLIO", nazione: "ITALIA" },
  { codeKey: "H632", sigla: "LE", provincia: "RUFFANO", nazione: "ITALIA" },
  { codeKey: "H633", sigla: "CN", provincia: "RUFFIA", nazione: "ITALIA" },
  { codeKey: "H634", sigla: "TN", provincia: "RUFFRE'", nazione: "ITALIA" },
  { codeKey: "H635", sigla: "FI", provincia: "RUFINA", nazione: "ITALIA" },
  { codeKey: "H637", sigla: "PV", provincia: "RUINO", nazione: "ITALIA" },
  { codeKey: "H639", sigla: "TN", provincia: "RUMO", nazione: "ITALIA" },
  { codeKey: "H641", sigla: "PZ", provincia: "RUOTI", nazione: "ITALIA" },
  { codeKey: "H642", sigla: "RA", provincia: "RUSSI", nazione: "ITALIA" },
  { codeKey: "H643", sigla: "BA", provincia: "RUTIGLIANO", nazione: "ITALIA" },
  { codeKey: "H644", sigla: "SA", provincia: "RUTINO", nazione: "ITALIA" },
  { codeKey: "H645", sigla: "BA", provincia: "RUVO DI PUGLIA", nazione: "ITALIA" },
  { codeKey: "H646", sigla: "PZ", provincia: "RUVO DEL MONTE", nazione: "ITALIA" },
  { codeKey: "H647", sigla: "LT", provincia: "SABAUDIA", nazione: "ITALIA" },
  { codeKey: "H648", sigla: "VC", provincia: "SABBIA", nazione: "ITALIA" },
  { codeKey: "H650", sigla: "BS", provincia: "SABBIO CHIESE", nazione: "ITALIA" },
  { codeKey: "H652", sigla: "MN", provincia: "SABBIONETA", nazione: "ITALIA" },
  { codeKey: "H654", sigla: "SA", provincia: "SACCO", nazione: "ITALIA" },
  { codeKey: "H655", sigla: "PD", provincia: "SACCOLONGO", nazione: "ITALIA" },
  { codeKey: "H657", sigla: "PN", provincia: "SACILE", nazione: "ITALIA" },
  { codeKey: "H658", sigla: "RM", provincia: "SACROFANO", nazione: "ITALIA" },
  { codeKey: "H659", sigla: "NU", provincia: "SADALI", nazione: "ITALIA" },
  { codeKey: "H661", sigla: "NU", provincia: "SAGAMA", nazione: "ITALIA" },
  { codeKey: "H662", sigla: "BI", provincia: "SAGLIANO MICCA", nazione: "ITALIA" },
  { codeKey: "H665", sigla: "GO", provincia: "SAGRADO", nazione: "ITALIA" },
  { codeKey: "H666", sigla: "TN", provincia: "SAGRON MIS", nazione: "ITALIA" },
  { codeKey: "H669", sigla: "AO", provincia: "SAINT-CHRISTOPHE", nazione: "ITALIA" },
  { codeKey: "H670", sigla: "AO", provincia: "SAINT-DENIS", nazione: "ITALIA" },
  { codeKey: "H671", sigla: "AO", provincia: "SAINT-MARCEL", nazione: "ITALIA" },
  { codeKey: "H672", sigla: "AO", provincia: "SAINT-NICOLAS", nazione: "ITALIA" },
  { codeKey: "H673", sigla: "AO", provincia: "SAINT-OYEN", nazione: "ITALIA" },
  { codeKey: "H674", sigla: "AO", provincia: "SAINT-PIERRE", nazione: "ITALIA" },
  {
    codeKey: "H675",
    sigla: "AO",
    provincia: "SAINT-RHEMY-EN-BOSSES",
    nazione: "ITALIA",
  },
  { codeKey: "H676", sigla: "AO", provincia: "SAINT-VINCENT", nazione: "ITALIA" },
  { codeKey: "H677", sigla: "AL", provincia: "SALA MONFERRATO", nazione: "ITALIA" },
  { codeKey: "H678", sigla: "BO", provincia: "SALA BOLOGNESE", nazione: "ITALIA" },
  { codeKey: "H679", sigla: "CO", provincia: "SALA COMACINA", nazione: "ITALIA" },
  { codeKey: "H681", sigla: "BI", provincia: "SALA BIELLESE", nazione: "ITALIA" },
  { codeKey: "H682", sigla: "PR", provincia: "SALA BAGANZA", nazione: "ITALIA" },
  { codeKey: "H683", sigla: "SA", provincia: "SALA CONSILINA", nazione: "ITALIA" },
  { codeKey: "H684", sigla: "TO", provincia: "SALBERTRAND", nazione: "ITALIA" },
  { codeKey: "H686", sigla: "SA", provincia: "SALENTO", nazione: "ITALIA" },
  { codeKey: "H687", sigla: "MT", provincia: "SALANDRA", nazione: "ITALIA" },
  { codeKey: "H688", sigla: "TP", provincia: "SALAPARUTA", nazione: "ITALIA" },
  { codeKey: "H689", sigla: "RO", provincia: "SALARA", nazione: "ITALIA" },
  { codeKey: "H690", sigla: "VC", provincia: "SALASCO", nazione: "ITALIA" },
  { codeKey: "H691", sigla: "TO", provincia: "SALASSA", nazione: "ITALIA" },
  { codeKey: "H693", sigla: "CB", provincia: "SALCITO", nazione: "ITALIA" },
  { codeKey: "H694", sigla: "AL", provincia: "SALE", nazione: "ITALIA" },
  {
    codeKey: "H695",
    sigla: "CN",
    provincia: "SALE DELLE LANGHE",
    nazione: "ITALIA",
  },
  { codeKey: "H699", sigla: "BS", provincia: "SALE MARASINO", nazione: "ITALIA" },
  { codeKey: "H700", sigla: "TP", provincia: "SALEMI", nazione: "ITALIA" },
  {
    codeKey: "H701",
    sigla: "LO",
    provincia: "SALERANO SUL LAMBRO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H702",
    sigla: "TO",
    provincia: "SALERANO CANAVESE",
    nazione: "ITALIA",
  },
  { codeKey: "H703", sigla: "SA", provincia: "SALERNO", nazione: "ITALIA" },
  {
    codeKey: "H704",
    sigla: "CN",
    provincia: "SALE SAN GIOVANNI",
    nazione: "ITALIA",
  },
  { codeKey: "H705", sigla: "PD", provincia: "SALETTO", nazione: "ITALIA" },
  { codeKey: "H706", sigla: "TV", provincia: "SALGAREDA", nazione: "ITALIA" },
  { codeKey: "H707", sigla: "VC", provincia: "SALI VERCELLESE", nazione: "ITALIA" },
  { codeKey: "H708", sigla: "LE", provincia: "SALICE SALENTINO", nazione: "ITALIA" },
  { codeKey: "H710", sigla: "CN", provincia: "SALICETO", nazione: "ITALIA" },
  {
    codeKey: "H712",
    sigla: "VR",
    provincia: "SAN MAURO DI SALINE",
    nazione: "ITALIA",
  },
  { codeKey: "H713", sigla: "RI", provincia: "SALISANO", nazione: "ITALIA" },
  { codeKey: "H714", sigla: "VR", provincia: "SALIZZOLE", nazione: "ITALIA" },
  { codeKey: "H715", sigla: "PE", provincia: "SALLE", nazione: "ITALIA" },
  { codeKey: "H716", sigla: "CN", provincia: "SALMOUR", nazione: "ITALIA" },
  { codeKey: "H717", sigla: "BS", provincia: "SALO'", nazione: "ITALIA" },
  { codeKey: "H719", sigla: "BZ", provincia: "SALORNO", nazione: "ITALIA" },
  {
    codeKey: "H720",
    sigla: "PR",
    provincia: "SALSOMAGGIORE TERME",
    nazione: "ITALIA",
  },
  { codeKey: "H721", sigla: "PU", provincia: "SALTARA", nazione: "ITALIA" },
  { codeKey: "H723", sigla: "VA", provincia: "SALTRIO", nazione: "ITALIA" },
  { codeKey: "H724", sigla: "RN", provincia: "SALUDECIO", nazione: "ITALIA" },
  { codeKey: "H725", sigla: "VC", provincia: "SALUGGIA", nazione: "ITALIA" },
  { codeKey: "H726", sigla: "BI", provincia: "SALUSSOLA", nazione: "ITALIA" },
  { codeKey: "H727", sigla: "CN", provincia: "SALUZZO", nazione: "ITALIA" },
  { codeKey: "H729", sigla: "LE", provincia: "SALVE", nazione: "ITALIA" },
  {
    codeKey: "H730",
    sigla: "PZ",
    provincia: "SAVOIA DI LUCANIA",
    nazione: "ITALIA",
  },
  { codeKey: "H731", sigla: "CR", provincia: "SALVIROLA", nazione: "ITALIA" },
  { codeKey: "H732", sigla: "SA", provincia: "SALVITELLE", nazione: "ITALIA" },
  { codeKey: "H733", sigla: "AV", provincia: "SALZA IRPINA", nazione: "ITALIA" },
  {
    codeKey: "H734",
    sigla: "TO",
    provincia: "SALZA DI PINEROLO",
    nazione: "ITALIA",
  },
  { codeKey: "H735", sigla: "VE", provincia: "SALZANO", nazione: "ITALIA" },
  { codeKey: "H736", sigla: "VA", provincia: "SAMARATE", nazione: "ITALIA" },
  { codeKey: "H738", sigla: "VS", provincia: "SAMASSI", nazione: "ITALIA" },
  { codeKey: "H739", sigla: "CA", provincia: "SAMATZAI", nazione: "ITALIA" },
  {
    codeKey: "H743",
    sigla: "AG",
    provincia: "SAMBUCA DI SICILIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "H744",
    sigla: "PT",
    provincia: "SAMBUCA PISTOIESE",
    nazione: "ITALIA",
  },
  { codeKey: "H745", sigla: "RM", provincia: "SAMBUCI", nazione: "ITALIA" },
  { codeKey: "H746", sigla: "CN", provincia: "SAMBUCO", nazione: "ITALIA" },
  {
    codeKey: "H749",
    sigla: "BA",
    provincia: "SAMMICHELE DI BARI",
    nazione: "ITALIA",
  },
  { codeKey: "H752", sigla: "SO", provincia: "SAMOLACO", nazione: "ITALIA" },
  { codeKey: "H753", sigla: "TO", provincia: "SAMONE", nazione: "ITALIA" },
  { codeKey: "H754", sigla: "TN", provincia: "SAMONE", nazione: "ITALIA" },
  { codeKey: "H755", sigla: "CN", provincia: "SAMPEYRE", nazione: "ITALIA" },
  { codeKey: "H756", sigla: "OR", provincia: "SAMUGHEO", nazione: "ITALIA" },
  { codeKey: "H757", sigla: "LE", provincia: "SANARICA", nazione: "ITALIA" },
  {
    codeKey: "H760",
    sigla: "CO",
    provincia: "SAN BARTOLOMEO VAL CAVARGNA",
    nazione: "ITALIA",
  },
  {
    codeKey: "H763",
    sigla: "IM",
    provincia: "SAN BARTOLOMEO AL MARE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H764",
    sigla: "BN",
    provincia: "SAN BARTOLOMEO IN GALDO",
    nazione: "ITALIA",
  },
  { codeKey: "H765", sigla: "CS", provincia: "SAN BASILE", nazione: "ITALIA" },
  { codeKey: "H766", sigla: "CA", provincia: "SAN BASILIO", nazione: "ITALIA" },
  { codeKey: "H767", sigla: "CR", provincia: "SAN BASSANO", nazione: "ITALIA" },
  { codeKey: "H768", sigla: "RO", provincia: "SAN BELLINO", nazione: "ITALIA" },
  {
    codeKey: "H769",
    sigla: "AP",
    provincia: "SAN BENEDETTO DEL TRONTO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H770",
    sigla: "CN",
    provincia: "SAN BENEDETTO BELBO",
    nazione: "ITALIA",
  },
  { codeKey: "H771", sigla: "MN", provincia: "SAN BENEDETTO PO", nazione: "ITALIA" },
  {
    codeKey: "H772",
    sigla: "AQ",
    provincia: "SAN BENEDETTO DEI MARSI",
    nazione: "ITALIA",
  },
  {
    codeKey: "H773",
    sigla: "AQ",
    provincia: "SAN BENEDETTO IN PERILLIS",
    nazione: "ITALIA",
  },
  {
    codeKey: "H774",
    sigla: "CS",
    provincia: "SAN BENEDETTO ULLANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H775",
    sigla: "TO",
    provincia: "SAN BENIGNO CANAVESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H777",
    sigla: "VB",
    provincia: "SAN BERNARDINO VERBANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H778",
    sigla: "AG",
    provincia: "SAN BIAGIO PLATANI",
    nazione: "ITALIA",
  },
  {
    codeKey: "H779",
    sigla: "FR",
    provincia: "SAN BIAGIO SARACINISCO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H780",
    sigla: "IM",
    provincia: "SAN BIAGIO DELLA CIMA",
    nazione: "ITALIA",
  },
  {
    codeKey: "H781",
    sigla: "TV",
    provincia: "SAN BIAGIO DI CALLALTA",
    nazione: "ITALIA",
  },
  { codeKey: "H782", sigla: "CB", provincia: "SAN BIASE", nazione: "ITALIA" },
  { codeKey: "H783", sigla: "VR", provincia: "SAN BONIFACIO", nazione: "ITALIA" },
  { codeKey: "H784", sigla: "CH", provincia: "SAN BUONO", nazione: "ITALIA" },
  { codeKey: "H785", sigla: "VV", provincia: "SAN CALOGERO", nazione: "ITALIA" },
  { codeKey: "H786", sigla: "BZ", provincia: "SAN CANDIDO", nazione: "ITALIA" },
  {
    codeKey: "H787",
    sigla: "GO",
    provincia: "SAN CANZIAN D'ISONZO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H789",
    sigla: "TO",
    provincia: "SAN CARLO CANAVESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H790",
    sigla: "SI",
    provincia: "SAN CASCIANO DEI BAGNI",
    nazione: "ITALIA",
  },
  {
    codeKey: "H791",
    sigla: "FI",
    provincia: "SAN CASCIANO IN VAL DI PESA",
    nazione: "ITALIA",
  },
  { codeKey: "H792", sigla: "CL", provincia: "SAN CATALDO", nazione: "ITALIA" },
  {
    codeKey: "H793",
    sigla: "LE",
    provincia: "SAN CESARIO DI LECCE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H794",
    sigla: "MO",
    provincia: "SAN CESARIO SUL PANARO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H795",
    sigla: "PZ",
    provincia: "SAN CHIRICO NUOVO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H796",
    sigla: "PZ",
    provincia: "SAN CHIRICO RAPARO",
    nazione: "ITALIA",
  },
  { codeKey: "H797", sigla: "PA", provincia: "SAN CIPIRELLO", nazione: "ITALIA" },
  {
    codeKey: "H798",
    sigla: "CE",
    provincia: "SAN CIPRIANO D'AVERSA",
    nazione: "ITALIA",
  },
  { codeKey: "H799", sigla: "PV", provincia: "SAN CIPRIANO PO", nazione: "ITALIA" },
  {
    codeKey: "H800",
    sigla: "SA",
    provincia: "SAN CIPRIANO PICENTINO",
    nazione: "ITALIA",
  },
  { codeKey: "H801", sigla: "RN", provincia: "SAN CLEMENTE", nazione: "ITALIA" },
  {
    codeKey: "H802",
    sigla: "GE",
    provincia: "SAN COLOMBANO CERTENOLI",
    nazione: "ITALIA",
  },
  {
    codeKey: "H803",
    sigla: "MI",
    provincia: "SAN COLOMBANO AL LAMBRO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H804",
    sigla: "TO",
    provincia: "SAN COLOMBANO BELMONTE",
    nazione: "ITALIA",
  },
  { codeKey: "H805", sigla: "CT", provincia: "SAN CONO", nazione: "ITALIA" },
  {
    codeKey: "H806",
    sigla: "CS",
    provincia: "SAN COSMO ALBANESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H807",
    sigla: "VV",
    provincia: "SAN COSTANTINO CALABRO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H808",
    sigla: "PZ",
    provincia: "SAN COSTANTINO ALBANESE",
    nazione: "ITALIA",
  },
  { codeKey: "H809", sigla: "PU", provincia: "SAN COSTANZO", nazione: "ITALIA" },
  { codeKey: "H810", sigla: "AL", provincia: "SAN CRISTOFORO", nazione: "ITALIA" },
  {
    codeKey: "H811",
    sigla: "AT",
    provincia: "SAN DAMIANO D'ASTI",
    nazione: "ITALIA",
  },
  {
    codeKey: "H812",
    sigla: "CN",
    provincia: "SAN DAMIANO MACRA",
    nazione: "ITALIA",
  },
  {
    codeKey: "H814",
    sigla: "PV",
    provincia: "SAN DAMIANO AL COLLE",
    nazione: "ITALIA",
  },
  { codeKey: "H815", sigla: "CR", provincia: "SAN DANIELE PO", nazione: "ITALIA" },
  {
    codeKey: "H816",
    sigla: "UD",
    provincia: "SAN DANIELE DEL FRIULI",
    nazione: "ITALIA",
  },
  {
    codeKey: "H818",
    sigla: "CS",
    provincia: "SAN DEMETRIO CORONE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H819",
    sigla: "AQ",
    provincia: "SAN DEMETRIO NE' VESTINI",
    nazione: "ITALIA",
  },
  { codeKey: "H820", sigla: "TO", provincia: "SAN DIDERO", nazione: "ITALIA" },
  { codeKey: "H821", sigla: "BI", provincia: "SANDIGLIANO", nazione: "ITALIA" },
  { codeKey: "H822", sigla: "BR", provincia: "SAN DONACI", nazione: "ITALIA" },
  {
    codeKey: "H823",
    sigla: "VE",
    provincia: "SAN DONA' DI PIAVE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H824",
    sigla: "FR",
    provincia: "SAN DONATO VAL DI COMINO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H825",
    sigla: "CS",
    provincia: "SAN DONATO DI NINEA",
    nazione: "ITALIA",
  },
  {
    codeKey: "H826",
    sigla: "LE",
    provincia: "SAN DONATO DI LECCE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H827",
    sigla: "MI",
    provincia: "SAN DONATO MILANESE",
    nazione: "ITALIA",
  },
  { codeKey: "H829", sigla: "VI", provincia: "SANDRIGO", nazione: "ITALIA" },
  {
    codeKey: "H830",
    sigla: "CO",
    provincia: "SAN FEDELE INTELVI",
    nazione: "ITALIA",
  },
  { codeKey: "H831", sigla: "PZ", provincia: "SAN FELE", nazione: "ITALIA" },
  {
    codeKey: "H833",
    sigla: "CB",
    provincia: "SAN FELICE DEL MOLISE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H834",
    sigla: "CE",
    provincia: "SAN FELICE A CANCELLO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H835",
    sigla: "MO",
    provincia: "SAN FELICE SUL PANARO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H836",
    sigla: "LT",
    provincia: "SAN FELICE CIRCEO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H838",
    sigla: "BS",
    provincia: "SAN FELICE DEL BENACO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H839",
    sigla: "BT",
    provincia: "SAN FERDINANDO DI PUGLIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "H840",
    sigla: "CO",
    provincia: "SAN FERMO DELLA BATTAGLIA",
    nazione: "ITALIA",
  },
  { codeKey: "H841", sigla: "CS", provincia: "SAN FILI", nazione: "ITALIA" },
  {
    codeKey: "H842",
    sigla: "ME",
    provincia: "SAN FILIPPO DEL MELA",
    nazione: "ITALIA",
  },
  { codeKey: "H843", sigla: "TV", provincia: "SAN FIOR", nazione: "ITALIA" },
  { codeKey: "H844", sigla: "LO", provincia: "SAN FIORANO", nazione: "ITALIA" },
  {
    codeKey: "H845",
    sigla: "GO",
    provincia: "SAN FLORIANO DEL COLLIO",
    nazione: "ITALIA",
  },
  { codeKey: "H846", sigla: "CZ", provincia: "SAN FLORO", nazione: "ITALIA" },
  {
    codeKey: "H847",
    sigla: "TO",
    provincia: "SAN FRANCESCO AL CAMPO",
    nazione: "ITALIA",
  },
  { codeKey: "H848", sigla: "OT", provincia: "AGLIENTU", nazione: "ITALIA" },
  { codeKey: "H850", sigla: "ME", provincia: "SAN FRATELLO", nazione: "ITALIA" },
  { codeKey: "H851", sigla: "CN", provincia: "SANFRE'", nazione: "ITALIA" },
  { codeKey: "H852", sigla: "CN", provincia: "SANFRONT", nazione: "ITALIA" },
  { codeKey: "H855", sigla: "TO", provincia: "SANGANO", nazione: "ITALIA" },
  {
    codeKey: "H856",
    sigla: "VS",
    provincia: "SAN GAVINO MONREALE",
    nazione: "ITALIA",
  },
  { codeKey: "H857", sigla: "TR", provincia: "SAN GEMINI", nazione: "ITALIA" },
  {
    codeKey: "H858",
    sigla: "BZ",
    provincia: "SAN GENESIO ATESINO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H859",
    sigla: "PV",
    provincia: "SAN GENESIO ED UNITI",
    nazione: "ITALIA",
  },
  {
    codeKey: "H860",
    sigla: "NA",
    provincia: "SAN GENNARO VESUVIANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H861",
    sigla: "VC",
    provincia: "SAN GERMANO VERCELLESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H862",
    sigla: "TO",
    provincia: "SAN GERMANO CHISONE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H863",
    sigla: "VI",
    provincia: "SAN GERMANO DEI BERICI",
    nazione: "ITALIA",
  },
  {
    codeKey: "H865",
    sigla: "BS",
    provincia: "SAN GERVASIO BRESCIANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H867",
    sigla: "CB",
    provincia: "SAN GIACOMO DEGLI SCHIAVONI",
    nazione: "ITALIA",
  },
  {
    codeKey: "H868",
    sigla: "SO",
    provincia: "SAN GIACOMO FILIPPO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H870",
    sigla: "MN",
    provincia: "SAN GIACOMO DELLE SEGNATE",
    nazione: "ITALIA",
  },
  { codeKey: "H872", sigla: "VA", provincia: "SANGIANO", nazione: "ITALIA" },
  { codeKey: "H873", sigla: "TO", provincia: "SAN GILLIO", nazione: "ITALIA" },
  { codeKey: "H875", sigla: "SI", provincia: "SAN GIMIGNANO", nazione: "ITALIA" },
  { codeKey: "H876", sigla: "MC", provincia: "SAN GINESIO", nazione: "ITALIA" },
  { codeKey: "H877", sigla: "CS", provincia: "SANGINETO", nazione: "ITALIA" },
  {
    codeKey: "H878",
    sigla: "AL",
    provincia: "SAN GIORGIO MONFERRATO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H880",
    sigla: "FR",
    provincia: "SAN GIORGIO A LIRI",
    nazione: "ITALIA",
  },
  {
    codeKey: "H881",
    sigla: "CS",
    provincia: "SAN GIORGIO ALBANESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H882",
    sigla: "TA",
    provincia: "SAN GIORGIO IONICO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H883",
    sigla: "MN",
    provincia: "SAN GIORGIO DI MANTOVA",
    nazione: "ITALIA",
  },
  {
    codeKey: "H884",
    sigla: "MI",
    provincia: "SAN GIORGIO SU LEGNANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H885",
    sigla: "PV",
    provincia: "SAN GIORGIO DI LOMELLINA",
    nazione: "ITALIA",
  },
  {
    codeKey: "H886",
    sigla: "PU",
    provincia: "SAN GIORGIO DI PESARO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H887",
    sigla: "PC",
    provincia: "SAN GIORGIO PIACENTINO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H888",
    sigla: "MT",
    provincia: "SAN GIORGIO LUCANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H889",
    sigla: "RC",
    provincia: "SAN GIORGIO MORGETO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H890",
    sigla: "TO",
    provincia: "SAN GIORGIO CANAVESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H891",
    sigla: "PN",
    provincia: "SAN GIORGIO DELLA RICHINVELDA",
    nazione: "ITALIA",
  },
  {
    codeKey: "H892",
    sigla: "NA",
    provincia: "SAN GIORGIO A CREMANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H893",
    sigla: "PD",
    provincia: "SAN GIORGIO DELLE PERTICHE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H894",
    sigla: "BN",
    provincia: "SAN GIORGIO DEL SANNIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H895",
    sigla: "UD",
    provincia: "SAN GIORGIO DI NOGARO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H896",
    sigla: "BO",
    provincia: "SAN GIORGIO DI PIANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H897",
    sigla: "PD",
    provincia: "SAN GIORGIO IN BOSCO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H898",
    sigla: "BN",
    provincia: "SAN GIORGIO LA MOLARA",
    nazione: "ITALIA",
  },
  {
    codeKey: "H899",
    sigla: "AT",
    provincia: "SAN GIORGIO SCARAMPI",
    nazione: "ITALIA",
  },
  {
    codeKey: "H900",
    sigla: "TO",
    provincia: "SAN GIORIO DI SUSA",
    nazione: "ITALIA",
  },
  {
    codeKey: "H901",
    sigla: "AR",
    provincia: "SAN GIOVANNI VALDARNO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H903",
    sigla: "RC",
    provincia: "SAN GIOVANNI DI GERACE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H906",
    sigla: "UD",
    provincia: "SAN GIOVANNI AL NATISONE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H907",
    sigla: "SA",
    provincia: "SAN GIOVANNI A PIRO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H910",
    sigla: "BG",
    provincia: "SAN GIOVANNI BIANCO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H911",
    sigla: "SI",
    provincia: "SAN GIOVANNI D'ASSO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H912",
    sigla: "MN",
    provincia: "SAN GIOVANNI DEL DOSSO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H913",
    sigla: "VT",
    provincia: "VILLA SAN GIOVANNI IN TUSCIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "H914",
    sigla: "AG",
    provincia: "SAN GIOVANNI GEMINI",
    nazione: "ITALIA",
  },
  {
    codeKey: "H916",
    sigla: "VR",
    provincia: "SAN GIOVANNI ILARIONE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H917",
    sigla: "FR",
    provincia: "SAN GIOVANNI INCARICO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H918",
    sigla: "CR",
    provincia: "SAN GIOVANNI IN CROCE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H919",
    sigla: "CS",
    provincia: "SAN GIOVANNI IN FIORE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H920",
    sigla: "CB",
    provincia: "SAN GIOVANNI IN GALDO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H921",
    sigla: "RN",
    provincia: "SAN GIOVANNI IN MARIGNANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H922",
    sigla: "CT",
    provincia: "SAN GIOVANNI LA PUNTA",
    nazione: "ITALIA",
  },
  {
    codeKey: "H923",
    sigla: "CH",
    provincia: "SAN GIOVANNI LIPIONI",
    nazione: "ITALIA",
  },
  {
    codeKey: "H924",
    sigla: "VR",
    provincia: "SAN GIOVANNI LUPATOTO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H926",
    sigla: "FG",
    provincia: "SAN GIOVANNI ROTONDO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H928",
    sigla: "CB",
    provincia: "SAN GIULIANO DEL SANNIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H929",
    sigla: "CB",
    provincia: "SAN GIULIANO DI PUGLIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "H930",
    sigla: "MI",
    provincia: "SAN GIULIANO MILANESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H931",
    sigla: "NA",
    provincia: "SAN GIUSEPPE VESUVIANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H933",
    sigla: "PA",
    provincia: "SAN GIUSEPPE JATO",
    nazione: "ITALIA",
  },
  { codeKey: "H935", sigla: "PG", provincia: "SAN GIUSTINO", nazione: "ITALIA" },
  {
    codeKey: "H936",
    sigla: "TO",
    provincia: "SAN GIUSTO CANAVESE",
    nazione: "ITALIA",
  },
  { codeKey: "H937", sigla: "FI", provincia: "SAN GODENZO", nazione: "ITALIA" },
  {
    codeKey: "H938",
    sigla: "BL",
    provincia: "SAN GREGORIO NELLE ALPI",
    nazione: "ITALIA",
  },
  {
    codeKey: "H939",
    sigla: "CE",
    provincia: "SAN GREGORIO MATESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H940",
    sigla: "CT",
    provincia: "SAN GREGORIO DI CATANIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "H941",
    sigla: "VV",
    provincia: "SAN GREGORIO D'IPPONA",
    nazione: "ITALIA",
  },
  {
    codeKey: "H942",
    sigla: "RM",
    provincia: "SAN GREGORIO DA SASSOLA",
    nazione: "ITALIA",
  },
  {
    codeKey: "H943",
    sigla: "SA",
    provincia: "SAN GREGORIO MAGNO",
    nazione: "ITALIA",
  },
  { codeKey: "H944", sigla: "VR", provincia: "SANGUINETTO", nazione: "ITALIA" },
  {
    codeKey: "H945",
    sigla: "BO",
    provincia: "SAN LAZZARO DI SAVENA",
    nazione: "ITALIA",
  },
  { codeKey: "H949", sigla: "RN", provincia: "SAN LEO", nazione: "ITALIA" },
  { codeKey: "H951", sigla: "UD", provincia: "SAN LEONARDO", nazione: "ITALIA" },
  {
    codeKey: "H952",
    sigla: "BZ",
    provincia: "SAN LEONARDO IN PASSIRIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "H953",
    sigla: "BN",
    provincia: "SAN LEUCIO DEL SANNIO",
    nazione: "ITALIA",
  },
  { codeKey: "H955", sigla: "BN", provincia: "SAN LORENZELLO", nazione: "ITALIA" },
  {
    codeKey: "H956",
    sigla: "BZ",
    provincia: "SAN LORENZO DI SEBATO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H957",
    sigla: "IM",
    provincia: "SAN LORENZO AL MARE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H958",
    sigla: "PU",
    provincia: "SAN LORENZO IN CAMPO",
    nazione: "ITALIA",
  },
  { codeKey: "H959", sigla: "RC", provincia: "SAN LORENZO", nazione: "ITALIA" },
  {
    codeKey: "H961",
    sigla: "CS",
    provincia: "SAN LORENZO BELLIZZI",
    nazione: "ITALIA",
  },
  {
    codeKey: "H962",
    sigla: "CS",
    provincia: "SAN LORENZO DEL VALLO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H964",
    sigla: "GO",
    provincia: "SAN LORENZO ISONTINO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H967",
    sigla: "BN",
    provincia: "SAN LORENZO MAGGIORE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H969",
    sigla: "VT",
    provincia: "SAN LORENZO NUOVO",
    nazione: "ITALIA",
  },
  { codeKey: "H970", sigla: "RC", provincia: "SAN LUCA", nazione: "ITALIA" },
  { codeKey: "H971", sigla: "CS", provincia: "SAN LUCIDO", nazione: "ITALIA" },
  { codeKey: "H973", sigla: "BN", provincia: "SAN LUPO", nazione: "ITALIA" },
  { codeKey: "H974", sigla: "VS", provincia: "SANLURI", nazione: "ITALIA" },
  {
    codeKey: "H975",
    sigla: "AV",
    provincia: "SAN MANGO SUL CALORE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H976",
    sigla: "CZ",
    provincia: "SAN MANGO D'AQUINO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H977",
    sigla: "SA",
    provincia: "SAN MANGO PIEMONTE",
    nazione: "ITALIA",
  },
  { codeKey: "H978", sigla: "CE", provincia: "SAN MARCELLINO", nazione: "ITALIA" },
  { codeKey: "H979", sigla: "AN", provincia: "SAN MARCELLO", nazione: "ITALIA" },
  {
    codeKey: "H980",
    sigla: "PT",
    provincia: "SAN MARCELLO PISTOIESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H981",
    sigla: "CS",
    provincia: "SAN MARCO ARGENTANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H982",
    sigla: "ME",
    provincia: "SAN MARCO D'ALUNZIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "H984",
    sigla: "BN",
    provincia: "SAN MARCO DEI CAVOTI",
    nazione: "ITALIA",
  },
  {
    codeKey: "H985",
    sigla: "FG",
    provincia: "SAN MARCO IN LAMIS",
    nazione: "ITALIA",
  },
  {
    codeKey: "H986",
    sigla: "FG",
    provincia: "SAN MARCO LA CATOLA",
    nazione: "ITALIA",
  },
  {
    codeKey: "H987",
    sigla: "AT",
    provincia: "SAN MARTINO ALFIERI",
    nazione: "ITALIA",
  },
  {
    codeKey: "H988",
    sigla: "BZ",
    provincia: "SAN MARTINO IN BADIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "H989",
    sigla: "BZ",
    provincia: "SAN MARTINO IN PASSIRIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "H990",
    sigla: "CB",
    provincia: "SAN MARTINO IN PENSILIS",
    nazione: "ITALIA",
  },
  {
    codeKey: "H991",
    sigla: "CH",
    provincia: "SAN MARTINO SULLA MARRUCINA",
    nazione: "ITALIA",
  },
  {
    codeKey: "H992",
    sigla: "CS",
    provincia: "SAN MARTINO DI FINITA",
    nazione: "ITALIA",
  },
  {
    codeKey: "H994",
    sigla: "PZ",
    provincia: "SAN MARTINO D'AGRI",
    nazione: "ITALIA",
  },
  {
    codeKey: "H996",
    sigla: "RO",
    provincia: "SAN MARTINO DI VENEZZE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H997",
    sigla: "TO",
    provincia: "SAN MARTINO CANAVESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "H999",
    sigla: "PN",
    provincia: "SAN MARTINO AL TAGLIAMENTO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I002",
    sigla: "BN",
    provincia: "SAN MARTINO SANNITA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I003",
    sigla: "VR",
    provincia: "SAN MARTINO BUON ALBERGO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I005",
    sigla: "MN",
    provincia: "SAN MARTINO DALL'ARGINE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I007",
    sigla: "CR",
    provincia: "SAN MARTINO DEL LAGO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I008",
    sigla: "PD",
    provincia: "SAN MARTINO DI LUPARI",
    nazione: "ITALIA",
  },
  {
    codeKey: "I011",
    sigla: "RE",
    provincia: "SAN MARTINO IN RIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I012",
    sigla: "LO",
    provincia: "SAN MARTINO IN STRADA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I014",
    sigla: "PV",
    provincia: "SAN MARTINO SICCOMARIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I016",
    sigla: "AV",
    provincia: "SAN MARTINO VALLE CAUDINA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I017",
    sigla: "AT",
    provincia: "SAN MARZANO OLIVETO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I018",
    sigla: "TA",
    provincia: "SAN MARZANO DI SAN GIUSEPPE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I019",
    sigla: "SA",
    provincia: "SAN MARZANO SUL SARNO",
    nazione: "ITALIA",
  },
  { codeKey: "I023", sigla: "CB", provincia: "SAN MASSIMO", nazione: "ITALIA" },
  {
    codeKey: "I024",
    sigla: "TO",
    provincia: "SAN MAURIZIO CANAVESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I025",
    sigla: "NO",
    provincia: "SAN MAURIZIO D'OPAGLIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I026",
    sigla: "KR",
    provincia: "SAN MAURO MARCHESATO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I027",
    sigla: "FC",
    provincia: "SAN MAURO PASCOLI",
    nazione: "ITALIA",
  },
  {
    codeKey: "I028",
    sigla: "PA",
    provincia: "SAN MAURO CASTELVERDE",
    nazione: "ITALIA",
  },
  { codeKey: "I029", sigla: "MT", provincia: "SAN MAURO FORTE", nazione: "ITALIA" },
  {
    codeKey: "I030",
    sigla: "TO",
    provincia: "SAN MAURO TORINESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I031",
    sigla: "SA",
    provincia: "SAN MAURO CILENTO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I032",
    sigla: "SA",
    provincia: "SAN MAURO LA BRUCA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I034",
    sigla: "AV",
    provincia: "SAN MICHELE DI SERINO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I035",
    sigla: "CT",
    provincia: "SAN MICHELE DI GANZARIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I037",
    sigla: "CN",
    provincia: "SAN MICHELE MONDOVI'",
    nazione: "ITALIA",
  },
  {
    codeKey: "I040",
    sigla: "VE",
    provincia: "SAN MICHELE AL TAGLIAMENTO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I042",
    sigla: "TN",
    provincia: "SAN MICHELE ALL'ADIGE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I045",
    sigla: "BR",
    provincia: "SAN MICHELE SALENTINO",
    nazione: "ITALIA",
  },
  { codeKey: "I046", sigla: "PI", provincia: "SAN MINIATO", nazione: "ITALIA" },
  { codeKey: "I047", sigla: "VI", provincia: "SAN NAZARIO", nazione: "ITALIA" },
  {
    codeKey: "I048",
    sigla: "PV",
    provincia: "SANNAZZARO DE' BURGONDI",
    nazione: "ITALIA",
  },
  { codeKey: "I049", sigla: "BN", provincia: "SAN NAZZARO", nazione: "ITALIA" },
  {
    codeKey: "I051",
    sigla: "CO",
    provincia: "SAN NAZZARO VAL CAVARGNA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I052",
    sigla: "NO",
    provincia: "SAN NAZZARO SESIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I053",
    sigla: "BA",
    provincia: "SANNICANDRO DI BARI",
    nazione: "ITALIA",
  },
  {
    codeKey: "I054",
    sigla: "FG",
    provincia: "SANNICANDRO GARGANICO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I056",
    sigla: "CE",
    provincia: "SAN NICOLA LA STRADA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I057",
    sigla: "KR",
    provincia: "SAN NICOLA DELL'ALTO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I058",
    sigla: "VV",
    provincia: "SAN NICOLA DA CRISSA",
    nazione: "ITALIA",
  },
  { codeKey: "I059", sigla: "LE", provincia: "SANNICOLA", nazione: "ITALIA" },
  {
    codeKey: "I060",
    sigla: "CS",
    provincia: "SAN NICOLA ARCELLA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I061",
    sigla: "AV",
    provincia: "SAN NICOLA BARONIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I062",
    sigla: "BN",
    provincia: "SAN NICOLA MANFREDI",
    nazione: "ITALIA",
  },
  {
    codeKey: "I063",
    sigla: "BL",
    provincia: "SAN NICOLO' DI COMELICO",
    nazione: "ITALIA",
  },
  { codeKey: "I065", sigla: "BZ", provincia: "SAN PANCRAZIO", nazione: "ITALIA" },
  {
    codeKey: "I066",
    sigla: "BR",
    provincia: "SAN PANCRAZIO SALENTINO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I071",
    sigla: "AN",
    provincia: "SAN PAOLO DI JESI",
    nazione: "ITALIA",
  },
  {
    codeKey: "I072",
    sigla: "FG",
    provincia: "SAN PAOLO DI CIVITATE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I073",
    sigla: "NA",
    provincia: "SAN PAOLO BEL SITO",
    nazione: "ITALIA",
  },
  { codeKey: "I074", sigla: "BI", provincia: "SAN PAOLO CERVO", nazione: "ITALIA" },
  {
    codeKey: "I076",
    sigla: "AT",
    provincia: "SAN PAOLO SOLBRITO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I079",
    sigla: "BG",
    provincia: "SAN PELLEGRINO TERME",
    nazione: "ITALIA",
  },
  {
    codeKey: "I082",
    sigla: "GO",
    provincia: "SAN PIER D'ISONZO",
    nazione: "ITALIA",
  },
  { codeKey: "I084", sigla: "ME", provincia: "SAN PIER NICETO", nazione: "ITALIA" },
  { codeKey: "I086", sigla: "ME", provincia: "SAN PIERO PATTI", nazione: "ITALIA" },
  {
    codeKey: "I088",
    sigla: "BL",
    provincia: "SAN PIETRO DI CADORE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I089",
    sigla: "SA",
    provincia: "SAN PIETRO AL TANAGRO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I090",
    sigla: "TO",
    provincia: "SAN PIETRO VAL LEMINA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I092",
    sigla: "UD",
    provincia: "SAN PIETRO AL NATISONE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I093",
    sigla: "CZ",
    provincia: "SAN PIETRO A MAIDA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I095",
    sigla: "CZ",
    provincia: "SAN PIETRO APOSTOLO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I096",
    sigla: "IS",
    provincia: "SAN PIETRO AVELLANA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I098",
    sigla: "CT",
    provincia: "SAN PIETRO CLARENZA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I102",
    sigla: "RC",
    provincia: "SAN PIETRO DI CARIDA'",
    nazione: "ITALIA",
  },
  {
    codeKey: "I103",
    sigla: "TV",
    provincia: "SAN PIETRO DI FELETTO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I105",
    sigla: "VR",
    provincia: "SAN PIETRO DI MORUBIO",
    nazione: "ITALIA",
  },
  { codeKey: "I107", sigla: "PD", provincia: "SAN PIETRO IN GU", nazione: "ITALIA" },
  {
    codeKey: "I108",
    sigla: "CS",
    provincia: "SAN PIETRO IN AMANTEA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I109",
    sigla: "VR",
    provincia: "SAN PIETRO IN CARIANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I110",
    sigla: "BO",
    provincia: "SAN PIETRO IN CASALE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I113",
    sigla: "CE",
    provincia: "SAN PIETRO INFINE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I114",
    sigla: "CS",
    provincia: "SAN PIETRO IN GUARANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I115",
    sigla: "LE",
    provincia: "SAN PIETRO IN LAMA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I116",
    sigla: "NO",
    provincia: "SAN PIETRO MOSEZZO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I117",
    sigla: "VI",
    provincia: "SAN PIETRO MUSSOLINO",
    nazione: "ITALIA",
  },
  { codeKey: "I118", sigla: "CA", provincia: "VILLA SAN PIETRO", nazione: "ITALIA" },
  {
    codeKey: "I119",
    sigla: "BR",
    provincia: "SAN PIETRO VERNOTICO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I120",
    sigla: "PD",
    provincia: "SAN PIETRO VIMINARIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I121",
    sigla: "AQ",
    provincia: "SAN PIO DELLE CAMERE",
    nazione: "ITALIA",
  },
  { codeKey: "I122", sigla: "CB", provincia: "SAN POLO MATESE", nazione: "ITALIA" },
  { codeKey: "I123", sigla: "RE", provincia: "SAN POLO D'ENZA", nazione: "ITALIA" },
  {
    codeKey: "I124",
    sigla: "TV",
    provincia: "SAN POLO DI PIAVE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I125",
    sigla: "RM",
    provincia: "SAN POLO DEI CAVALIERI",
    nazione: "ITALIA",
  },
  { codeKey: "I126", sigla: "TO", provincia: "SAN PONSO", nazione: "ITALIA" },
  { codeKey: "I128", sigla: "MO", provincia: "SAN POSSIDONIO", nazione: "ITALIA" },
  { codeKey: "I129", sigla: "AV", provincia: "SAN POTITO ULTRA", nazione: "ITALIA" },
  {
    codeKey: "I130",
    sigla: "CE",
    provincia: "SAN POTITO SANNITICO",
    nazione: "ITALIA",
  },
  { codeKey: "I131", sigla: "CE", provincia: "SAN PRISCO", nazione: "ITALIA" },
  { codeKey: "I132", sigla: "RC", provincia: "SAN PROCOPIO", nazione: "ITALIA" },
  { codeKey: "I133", sigla: "MO", provincia: "SAN PROSPERO", nazione: "ITALIA" },
  {
    codeKey: "I135",
    sigla: "SI",
    provincia: "SAN QUIRICO D'ORCIA",
    nazione: "ITALIA",
  },
  { codeKey: "I136", sigla: "PN", provincia: "SAN QUIRINO", nazione: "ITALIA" },
  {
    codeKey: "I137",
    sigla: "TO",
    provincia: "SAN RAFFAELE CIMENA",
    nazione: "ITALIA",
  },
  { codeKey: "I138", sigla: "IM", provincia: "SAN REMO", nazione: "ITALIA" },
  { codeKey: "I139", sigla: "RC", provincia: "SAN ROBERTO", nazione: "ITALIA" },
  {
    codeKey: "I140",
    sigla: "LO",
    provincia: "SAN ROCCO AL PORTO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I142",
    sigla: "LU",
    provincia: "SAN ROMANO IN GARFAGNANA",
    nazione: "ITALIA",
  },
  { codeKey: "I143", sigla: "SA", provincia: "SAN RUFO", nazione: "ITALIA" },
  {
    codeKey: "I144",
    sigla: "AL",
    provincia: "SAN SALVATORE MONFERRATO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I145",
    sigla: "BN",
    provincia: "SAN SALVATORE TELESINO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I147",
    sigla: "ME",
    provincia: "SAN SALVATORE DI FITALIA",
    nazione: "ITALIA",
  },
  { codeKey: "I148", sigla: "CH", provincia: "SAN SALVO", nazione: "ITALIA" },
  {
    codeKey: "I150",
    sigla: "AL",
    provincia: "SAN SEBASTIANO CURONE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I151",
    sigla: "NA",
    provincia: "SAN SEBASTIANO AL VESUVIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I152",
    sigla: "TO",
    provincia: "SAN SEBASTIANO DA PO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I153",
    sigla: "PR",
    provincia: "SAN SECONDO PARMENSE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I154",
    sigla: "TO",
    provincia: "SAN SECONDO DI PINEROLO",
    nazione: "ITALIA",
  },
  { codeKey: "I155", sigla: "AR", provincia: "SANSEPOLCRO", nazione: "ITALIA" },
  {
    codeKey: "I156",
    sigla: "MC",
    provincia: "SAN SEVERINO MARCHE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I157",
    sigla: "PZ",
    provincia: "SAN SEVERINO LUCANO",
    nazione: "ITALIA",
  },
  { codeKey: "I158", sigla: "FG", provincia: "SAN SEVERO", nazione: "ITALIA" },
  { codeKey: "I162", sigla: "CO", provincia: "SAN SIRO", nazione: "ITALIA" },
  {
    codeKey: "I163",
    sigla: "AV",
    provincia: "SAN SOSSIO BARONIA",
    nazione: "ITALIA",
  },
  { codeKey: "I164", sigla: "CZ", provincia: "SAN SOSTENE", nazione: "ITALIA" },
  { codeKey: "I165", sigla: "CS", provincia: "SAN SOSTI", nazione: "ITALIA" },
  { codeKey: "I166", sigla: "CA", provincia: "SAN SPERATE", nazione: "ITALIA" },
  { codeKey: "I168", sigla: "BG", provincia: "SANTA BRIGIDA", nazione: "ITALIA" },
  {
    codeKey: "I169",
    sigla: "CL",
    provincia: "SANTA CATERINA VILLARMOSA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I170",
    sigla: "CZ",
    provincia: "SANTA CATERINA DELLO IONIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I171",
    sigla: "CS",
    provincia: "SANTA CATERINA ALBANESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I172",
    sigla: "LE",
    provincia: "SANTA CESAREA TERME",
    nazione: "ITALIA",
  },
  {
    codeKey: "I173",
    sigla: "BZ",
    provincia: "SANTA CRISTINA VALGARDENA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I174",
    sigla: "PA",
    provincia: "SANTA CRISTINA GELA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I175",
    sigla: "PV",
    provincia: "SANTA CRISTINA E BISSONE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I176",
    sigla: "RC",
    provincia: "SANTA CRISTINA D'ASPROMONTE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I177",
    sigla: "PI",
    provincia: "SANTA CROCE SULL'ARNO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I178",
    sigla: "RG",
    provincia: "SANTA CROCE CAMERINA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I179",
    sigla: "BN",
    provincia: "SANTA CROCE DEL SANNIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I181",
    sigla: "CB",
    provincia: "SANTA CROCE DI MAGLIANO",
    nazione: "ITALIA",
  },
  { codeKey: "I182", sigla: "CI", provincia: "SANTADI", nazione: "ITALIA" },
  {
    codeKey: "I183",
    sigla: "CS",
    provincia: "SANTA DOMENICA TALAO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I184",
    sigla: "ME",
    provincia: "SANTA DOMENICA VITTORIA",
    nazione: "ITALIA",
  },
  { codeKey: "I185", sigla: "AG", provincia: "SANTA ELISABETTA", nazione: "ITALIA" },
  { codeKey: "I187", sigla: "GR", provincia: "SANTA FIORA", nazione: "ITALIA" },
  { codeKey: "I188", sigla: "PA", provincia: "SANTA FLAVIA", nazione: "ITALIA" },
  { codeKey: "I189", sigla: "IS", provincia: "SANT'AGAPITO", nazione: "ITALIA" },
  {
    codeKey: "I190",
    sigla: "AL",
    provincia: "SANT'AGATA FOSSILI",
    nazione: "ITALIA",
  },
  {
    codeKey: "I191",
    sigla: "BO",
    provincia: "SANT'AGATA BOLOGNESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I192",
    sigla: "CS",
    provincia: "SANT'AGATA DI ESARO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I193",
    sigla: "FG",
    provincia: "SANT'AGATA DI PUGLIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I196",
    sigla: "RA",
    provincia: "SANT'AGATA SUL SANTERNO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I197",
    sigla: "BN",
    provincia: "SANT'AGATA DE' GOTI",
    nazione: "ITALIA",
  },
  {
    codeKey: "I198",
    sigla: "RC",
    provincia: "SANT'AGATA DEL BIANCO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I199",
    sigla: "ME",
    provincia: "SANT'AGATA DI MILITELLO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I201",
    sigla: "RN",
    provincia: "SANT'AGATA FELTRIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I202",
    sigla: "CT",
    provincia: "SANT'AGATA LI BATTIATI",
    nazione: "ITALIA",
  },
  { codeKey: "I203", sigla: "PV", provincia: "SANTA GIULETTA", nazione: "ITALIA" },
  { codeKey: "I205", sigla: "OR", provincia: "SANTA GIUSTA", nazione: "ITALIA" },
  { codeKey: "I206", sigla: "BL", provincia: "SANTA GIUSTINA", nazione: "ITALIA" },
  {
    codeKey: "I207",
    sigla: "PD",
    provincia: "SANTA GIUSTINA IN COLLE",
    nazione: "ITALIA",
  },
  { codeKey: "I208", sigla: "NA", provincia: "SANT'AGNELLO", nazione: "ITALIA" },
  { codeKey: "I209", sigla: "FE", provincia: "SANT'AGOSTINO", nazione: "ITALIA" },
  {
    codeKey: "I210",
    sigla: "CN",
    provincia: "SANT'ALBANO STURA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I213",
    sigla: "PV",
    provincia: "SANT'ALESSIO CON VIALONE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I214",
    sigla: "RC",
    provincia: "SANT'ALESSIO IN ASPROMONTE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I215",
    sigla: "ME",
    provincia: "SANT'ALESSIO SICULO",
    nazione: "ITALIA",
  },
  { codeKey: "I216", sigla: "CT", provincia: "SANT'ALFIO", nazione: "ITALIA" },
  { codeKey: "I217", sigla: "PI", provincia: "SANTA LUCE", nazione: "ITALIA" },
  {
    codeKey: "I219",
    sigla: "AV",
    provincia: "SANTA LUCIA DI SERINO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I220",
    sigla: "ME",
    provincia: "SANTA LUCIA DEL MELA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I221",
    sigla: "TV",
    provincia: "SANTA LUCIA DI PIAVE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I224",
    sigla: "AG",
    provincia: "SANTA MARGHERITA DI BELICE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I225",
    sigla: "GE",
    provincia: "SANTA MARGHERITA LIGURE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I226",
    sigla: "PD",
    provincia: "SANTA MARGHERITA D'ADIGE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I230",
    sigla: "PV",
    provincia: "SANTA MARGHERITA DI STAFFORA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I232",
    sigla: "PI",
    provincia: "SANTA MARIA A MONTE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I233",
    sigla: "CE",
    provincia: "SANTA MARIA A VICO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I234",
    sigla: "CE",
    provincia: "SANTA MARIA CAPUA VETERE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I236",
    sigla: "PV",
    provincia: "TRAVACO' SICCOMARIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I237",
    sigla: "PV",
    provincia: "SANTA MARIA DELLA VERSA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I238",
    sigla: "IS",
    provincia: "SANTA MARIA DEL MOLISE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I240",
    sigla: "CT",
    provincia: "SANTA MARIA DI LICODIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I242",
    sigla: "VE",
    provincia: "SANTA MARIA DI SALA",
    nazione: "ITALIA",
  },
  { codeKey: "I243", sigla: "LC", provincia: "SANTA MARIA HOE'", nazione: "ITALIA" },
  {
    codeKey: "I244",
    sigla: "CH",
    provincia: "SANTA MARIA IMBARO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I247",
    sigla: "CE",
    provincia: "SANTA MARIA LA FOSSA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I248",
    sigla: "UD",
    provincia: "SANTA MARIA LA LONGA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I249",
    sigla: "VB",
    provincia: "SANTA MARIA MAGGIORE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I251",
    sigla: "AN",
    provincia: "SANTA MARIA NUOVA",
    nazione: "ITALIA",
  },
  { codeKey: "I253", sigla: "SA", provincia: "SANTA MARINA", nazione: "ITALIA" },
  {
    codeKey: "I254",
    sigla: "ME",
    provincia: "SANTA MARINA SALINA",
    nazione: "ITALIA",
  },
  { codeKey: "I255", sigla: "RM", provincia: "SANTA MARINELLA", nazione: "ITALIA" },
  {
    codeKey: "I256",
    sigla: "FR",
    provincia: "SANT'AMBROGIO SUL GARIGLIANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I258",
    sigla: "TO",
    provincia: "SANT'AMBROGIO DI TORINO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I259",
    sigla: "VR",
    provincia: "SANT'AMBROGIO DI VALPOLICELLA",
    nazione: "ITALIA",
  },
  { codeKey: "I260", sigla: "SA", provincia: "SANTOMENNA", nazione: "ITALIA" },
  { codeKey: "I261", sigla: "CE", provincia: "SAN TAMMARO", nazione: "ITALIA" },
  { codeKey: "I262", sigla: "NA", provincia: "SANT'ANASTASIA", nazione: "ITALIA" },
  {
    codeKey: "I263",
    sigla: "PG",
    provincia: "SANT'ANATOLIA DI NARCO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I264",
    sigla: "AV",
    provincia: "SANT'ANDREA DI CONZA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I265",
    sigla: "FR",
    provincia: "SANT'ANDREA DEL GARIGLIANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I266",
    sigla: "CZ",
    provincia: "SANT'ANDREA APOSTOLO DELLO IONIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I271",
    sigla: "CA",
    provincia: "SANT'ANDREA FRIUS",
    nazione: "ITALIA",
  },
  {
    codeKey: "I273",
    sigla: "CE",
    provincia: "SANT'ANGELO D'ALIFE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I274",
    sigla: "LO",
    provincia: "SANT'ANGELO LODIGIANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I275",
    sigla: "PD",
    provincia: "SANT'ANGELO DI PIOVE DI SACCO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I276",
    sigla: "PV",
    provincia: "SANT'ANGELO LOMELLINA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I277",
    sigla: "BN",
    provincia: "SANT'ANGELO A CUPOLO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I278",
    sigla: "SA",
    provincia: "SANT'ANGELO A FASANELLA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I279",
    sigla: "AV",
    provincia: "SANT'ANGELO ALL'ESCA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I280",
    sigla: "AV",
    provincia: "SANT'ANGELO A SCALA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I281",
    sigla: "AV",
    provincia: "SANT'ANGELO DEI LOMBARDI",
    nazione: "ITALIA",
  },
  {
    codeKey: "I282",
    sigla: "IS",
    provincia: "SANT'ANGELO DEL PESCO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I283",
    sigla: "ME",
    provincia: "SANT'ANGELO DI BROLO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I284",
    sigla: "RM",
    provincia: "SANT'ANGELO ROMANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I286",
    sigla: "MC",
    provincia: "SANT'ANGELO IN PONTANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I287",
    sigla: "PU",
    provincia: "SANT'ANGELO IN VADO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I288",
    sigla: "PZ",
    provincia: "SANT'ANGELO LE FRATTE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I289",
    sigla: "CB",
    provincia: "SANT'ANGELO LIMOSANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I290",
    sigla: "AG",
    provincia: "SANT'ANGELO MUXARO",
    nazione: "ITALIA",
  },
  { codeKey: "I291", sigla: "TP", provincia: "SANTA NINFA", nazione: "ITALIA" },
  {
    codeKey: "I292",
    sigla: "VR",
    provincia: "SANT'ANNA D'ALFAEDO",
    nazione: "ITALIA",
  },
  { codeKey: "I293", sigla: "NA", provincia: "SANT'ANTIMO", nazione: "ITALIA" },
  { codeKey: "I294", sigla: "CI", provincia: "SANT'ANTIOCO", nazione: "ITALIA" },
  {
    codeKey: "I296",
    sigla: "TO",
    provincia: "SANT'ANTONINO DI SUSA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I298",
    sigla: "OR",
    provincia: "VILLA SANT'ANTONIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I300",
    sigla: "NA",
    provincia: "SANT'ANTONIO ABATE",
    nazione: "ITALIA",
  },
  { codeKey: "I301", sigla: "AV", provincia: "SANTA PAOLINA", nazione: "ITALIA" },
  { codeKey: "I302", sigla: "FR", provincia: "SANT'APOLLINARE", nazione: "ITALIA" },
  {
    codeKey: "I304",
    sigla: "RN",
    provincia: "SANTARCANGELO DI ROMAGNA",
    nazione: "ITALIA",
  },
  { codeKey: "I305", sigla: "PZ", provincia: "SANT'ARCANGELO", nazione: "ITALIA" },
  { codeKey: "I306", sigla: "CE", provincia: "SANT'ARPINO", nazione: "ITALIA" },
  { codeKey: "I307", sigla: "SA", provincia: "SANT'ARSENIO", nazione: "ITALIA" },
  { codeKey: "I308", sigla: "KR", provincia: "SANTA SEVERINA", nazione: "ITALIA" },
  {
    codeKey: "I309",
    sigla: "CS",
    provincia: "SANTA SOFIA D'EPIRO",
    nazione: "ITALIA",
  },
  { codeKey: "I310", sigla: "FC", provincia: "SANTA SOFIA", nazione: "ITALIA" },
  {
    codeKey: "I311",
    sigla: "ME",
    provincia: "SANTA TERESA DI RIVA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I312",
    sigla: "OT",
    provincia: "SANTA TERESA GALLURA",
    nazione: "ITALIA",
  },
  { codeKey: "I314", sigla: "CT", provincia: "SANTA VENERINA", nazione: "ITALIA" },
  {
    codeKey: "I315",
    sigla: "FM",
    provincia: "SANTA VITTORIA IN MATENANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I316",
    sigla: "CN",
    provincia: "SANTA VITTORIA D'ALBA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I317",
    sigla: "SA",
    provincia: "SANT'EGIDIO DEL MONTE ALBINO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I318",
    sigla: "TE",
    provincia: "SANT'EGIDIO ALLA VIBRATA",
    nazione: "ITALIA",
  },
  { codeKey: "I319", sigla: "PD", provincia: "SANT'ELENA", nazione: "ITALIA" },
  {
    codeKey: "I320",
    sigla: "CB",
    provincia: "SANT'ELIA A PIANISI",
    nazione: "ITALIA",
  },
  {
    codeKey: "I321",
    sigla: "FR",
    provincia: "SANT'ELIA FIUMERAPIDO",
    nazione: "ITALIA",
  },
  { codeKey: "I322", sigla: "CZ", provincia: "VALLEFIORITA", nazione: "ITALIA" },
  {
    codeKey: "I324",
    sigla: "FM",
    provincia: "SANT'ELPIDIO A MARE",
    nazione: "ITALIA",
  },
  { codeKey: "I326", sigla: "AQ", provincia: "SANTE MARIE", nazione: "ITALIA" },
  { codeKey: "I327", sigla: "TO", provincia: "SANTENA", nazione: "ITALIA" },
  { codeKey: "I328", sigla: "ME", provincia: "SAN TEODORO", nazione: "ITALIA" },
  { codeKey: "I329", sigla: "OT", provincia: "SAN TEODORO", nazione: "ITALIA" },
  {
    codeKey: "I330",
    sigla: "BA",
    provincia: "SANTERAMO IN COLLE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I332",
    sigla: "PE",
    provincia: "SANT'EUFEMIA A MAIELLA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I333",
    sigla: "RC",
    provincia: "SANT'EUFEMIA D'ASPROMONTE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I335",
    sigla: "CH",
    provincia: "SANT'EUSANIO DEL SANGRO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I336",
    sigla: "AQ",
    provincia: "SANT'EUSANIO FORCONESE",
    nazione: "ITALIA",
  },
  { codeKey: "I337", sigla: "VC", provincia: "SANTHIA'", nazione: "ITALIA" },
  {
    codeKey: "I339",
    sigla: "LT",
    provincia: "SANTI COSMA E DAMIANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I341",
    sigla: "RC",
    provincia: "SANT'ILARIO DELLO IONIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I342",
    sigla: "RE",
    provincia: "SANT'ILARIO D'ENZA",
    nazione: "ITALIA",
  },
  { codeKey: "I344", sigla: "PU", provincia: "SANT'IPPOLITO", nazione: "ITALIA" },
  { codeKey: "I345", sigla: "BL", provincia: "ZOLDO ALTO", nazione: "ITALIA" },
  { codeKey: "I346", sigla: "GE", provincia: "SANT'OLCESE", nazione: "ITALIA" },
  {
    codeKey: "I347",
    sigla: "BL",
    provincia: "SAN TOMASO AGORDINO",
    nazione: "ITALIA",
  },
  { codeKey: "I348", sigla: "TE", provincia: "SANT'OMERO", nazione: "ITALIA" },
  { codeKey: "I350", sigla: "VV", provincia: "SANT'ONOFRIO", nazione: "ITALIA" },
  { codeKey: "I351", sigla: "FR", provincia: "SANTOPADRE", nazione: "ITALIA" },
  { codeKey: "I352", sigla: "RM", provincia: "SANT'ORESTE", nazione: "ITALIA" },
  { codeKey: "I353", sigla: "VI", provincia: "SANTORSO", nazione: "ITALIA" },
  {
    codeKey: "I354",
    sigla: "TN",
    provincia: "SANT'ORSOLA TERME",
    nazione: "ITALIA",
  },
  {
    codeKey: "I356",
    sigla: "AG",
    provincia: "SANTO STEFANO QUISQUINA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I357",
    sigla: "AV",
    provincia: "SANTO STEFANO DEL SOLE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I359",
    sigla: "CS",
    provincia: "SANTO STEFANO DI ROGLIANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I360",
    sigla: "AQ",
    provincia: "SANTO STEFANO DI SESSANIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I361",
    sigla: "MI",
    provincia: "SANTO STEFANO TICINO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I362",
    sigla: "LO",
    provincia: "SANTO STEFANO LODIGIANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I363",
    sigla: "SP",
    provincia: "SANTO STEFANO DI MAGRA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I364",
    sigla: "FR",
    provincia: "VILLA SANTO STEFANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I365",
    sigla: "IM",
    provincia: "SANTO STEFANO AL MARE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I367",
    sigla: "CN",
    provincia: "SANTO STEFANO BELBO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I368",
    sigla: "GE",
    provincia: "SANTO STEFANO D'AVETO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I370",
    sigla: "ME",
    provincia: "SANTO STEFANO DI CAMASTRA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I371",
    sigla: "RC",
    provincia: "SANTO STEFANO IN ASPROMONTE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I372",
    sigla: "CN",
    provincia: "SANTO STEFANO ROERO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I373",
    sigla: "VE",
    provincia: "SANTO STINO DI LIVENZA",
    nazione: "ITALIA",
  },
  { codeKey: "I374", sigla: "OR", provincia: "SANTU LUSSURGIU", nazione: "ITALIA" },
  { codeKey: "I375", sigla: "PD", provincia: "SANT'URBANO", nazione: "ITALIA" },
  {
    codeKey: "I376",
    sigla: "PE",
    provincia: "SAN VALENTINO IN ABRUZZO CITERIORE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I377",
    sigla: "SA",
    provincia: "SAN VALENTINO TORIO",
    nazione: "ITALIA",
  },
  { codeKey: "I381", sigla: "TR", provincia: "SAN VENANZO", nazione: "ITALIA" },
  { codeKey: "I382", sigla: "TV", provincia: "SAN VENDEMIANO", nazione: "ITALIA" },
  { codeKey: "I384", sigla: "OR", provincia: "SAN VERO MILIS", nazione: "ITALIA" },
  {
    codeKey: "I388",
    sigla: "CS",
    provincia: "SAN VINCENZO LA COSTA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I389",
    sigla: "AQ",
    provincia: "SAN VINCENZO VALLE ROVETO",
    nazione: "ITALIA",
  },
  { codeKey: "I390", sigla: "LI", provincia: "SAN VINCENZO", nazione: "ITALIA" },
  { codeKey: "I391", sigla: "NA", provincia: "SAN VITALIANO", nazione: "ITALIA" },
  {
    codeKey: "I392",
    sigla: "BL",
    provincia: "SAN VITO DI CADORE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I393",
    sigla: "CZ",
    provincia: "SAN VITO SULLO IONIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I394",
    sigla: "CH",
    provincia: "SAN VITO CHIETINO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I396",
    sigla: "BR",
    provincia: "SAN VITO DEI NORMANNI",
    nazione: "ITALIA",
  },
  { codeKey: "I400", sigla: "RM", provincia: "SAN VITO ROMANO", nazione: "ITALIA" },
  {
    codeKey: "I401",
    sigla: "VI",
    provincia: "SAN VITO DI LEGUZZANO",
    nazione: "ITALIA",
  },
  { codeKey: "I402", sigla: "CA", provincia: "SAN VITO", nazione: "ITALIA" },
  {
    codeKey: "I403",
    sigla: "PN",
    provincia: "SAN VITO AL TAGLIAMENTO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I404",
    sigla: "UD",
    provincia: "SAN VITO AL TORRE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I405",
    sigla: "UD",
    provincia: "SAN VITO DI FAGAGNA",
    nazione: "ITALIA",
  },
  { codeKey: "I407", sigla: "TP", provincia: "SAN VITO LO CAPO", nazione: "ITALIA" },
  {
    codeKey: "I408",
    sigla: "FR",
    provincia: "SAN VITTORE DEL LAZIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I409",
    sigla: "MI",
    provincia: "SAN VITTORE OLONA",
    nazione: "ITALIA",
  },
  { codeKey: "I410", sigla: "SA", provincia: "SANZA", nazione: "ITALIA" },
  { codeKey: "I411", sigla: "TN", provincia: "SANZENO", nazione: "ITALIA" },
  {
    codeKey: "I412",
    sigla: "BS",
    provincia: "SAN ZENO NAVIGLIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I414",
    sigla: "VR",
    provincia: "SAN ZENO DI MONTAGNA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I415",
    sigla: "MI",
    provincia: "SAN ZENONE AL LAMBRO",
    nazione: "ITALIA",
  },
  { codeKey: "I416", sigla: "PV", provincia: "SAN ZENONE AL PO", nazione: "ITALIA" },
  {
    codeKey: "I417",
    sigla: "TV",
    provincia: "SAN ZENONE DEGLI EZZELINI",
    nazione: "ITALIA",
  },
  { codeKey: "I418", sigla: "PD", provincia: "SAONARA", nazione: "ITALIA" },
  { codeKey: "I420", sigla: "ME", provincia: "SAPONARA", nazione: "ITALIA" },
  { codeKey: "I421", sigla: "BL", provincia: "SAPPADA", nazione: "ITALIA" },
  { codeKey: "I422", sigla: "SA", provincia: "SAPRI", nazione: "ITALIA" },
  { codeKey: "I423", sigla: "CS", provincia: "SARACENA", nazione: "ITALIA" },
  { codeKey: "I424", sigla: "RM", provincia: "SARACINESCO", nazione: "ITALIA" },
  { codeKey: "I425", sigla: "VI", provincia: "SARCEDO", nazione: "ITALIA" },
  { codeKey: "I426", sigla: "PZ", provincia: "SARCONI", nazione: "ITALIA" },
  { codeKey: "I428", sigla: "VS", provincia: "SARDARA", nazione: "ITALIA" },
  { codeKey: "I429", sigla: "AL", provincia: "SARDIGLIANO", nazione: "ITALIA" },
  { codeKey: "I430", sigla: "VI", provincia: "SAREGO", nazione: "ITALIA" },
  { codeKey: "I431", sigla: "BZ", provincia: "SARENTINO", nazione: "ITALIA" },
  { codeKey: "I432", sigla: "AL", provincia: "SAREZZANO", nazione: "ITALIA" },
  { codeKey: "I433", sigla: "BS", provincia: "SAREZZO", nazione: "ITALIA" },
  { codeKey: "I434", sigla: "PC", provincia: "SARMATO", nazione: "ITALIA" },
  { codeKey: "I435", sigla: "TV", provincia: "SARMEDE", nazione: "ITALIA" },
  { codeKey: "I436", sigla: "MC", provincia: "SARNANO", nazione: "ITALIA" },
  { codeKey: "I437", sigla: "BG", provincia: "SARNICO", nazione: "ITALIA" },
  { codeKey: "I438", sigla: "SA", provincia: "SARNO", nazione: "ITALIA" },
  { codeKey: "I439", sigla: "TN", provincia: "SARNONICO", nazione: "ITALIA" },
  { codeKey: "I441", sigla: "VA", provincia: "SARONNO", nazione: "ITALIA" },
  { codeKey: "I442", sigla: "AO", provincia: "SARRE", nazione: "ITALIA" },
  { codeKey: "I443", sigla: "CA", provincia: "SARROCH", nazione: "ITALIA" },
  { codeKey: "I444", sigla: "FC", provincia: "SARSINA", nazione: "ITALIA" },
  { codeKey: "I445", sigla: "SI", provincia: "SARTEANO", nazione: "ITALIA" },
  {
    codeKey: "I447",
    sigla: "PV",
    provincia: "SARTIRANA LOMELLINA",
    nazione: "ITALIA",
  },
  { codeKey: "I448", sigla: "NU", provincia: "SARULE", nazione: "ITALIA" },
  { codeKey: "I449", sigla: "SP", provincia: "SARZANA", nazione: "ITALIA" },
  { codeKey: "I451", sigla: "SA", provincia: "SASSANO", nazione: "ITALIA" },
  { codeKey: "I452", sigla: "SS", provincia: "SASSARI", nazione: "ITALIA" },
  { codeKey: "I453", sigla: "SV", provincia: "SASSELLO", nazione: "ITALIA" },
  { codeKey: "I454", sigla: "LI", provincia: "SASSETTA", nazione: "ITALIA" },
  { codeKey: "I455", sigla: "BN", provincia: "SASSINORO", nazione: "ITALIA" },
  {
    codeKey: "I457",
    sigla: "PZ",
    provincia: "SASSO DI CASTALDA",
    nazione: "ITALIA",
  },
  { codeKey: "I459", sigla: "PU", provincia: "SASSOCORVARO", nazione: "ITALIA" },
  { codeKey: "I460", sigla: "PU", provincia: "SASSOFELTRIO", nazione: "ITALIA" },
  { codeKey: "I461", sigla: "AN", provincia: "SASSOFERRATO", nazione: "ITALIA" },
  { codeKey: "I462", sigla: "MO", provincia: "SASSUOLO", nazione: "ITALIA" },
  { codeKey: "I463", sigla: "CZ", provincia: "SATRIANO", nazione: "ITALIA" },
  { codeKey: "I464", sigla: "UD", provincia: "SAURIS", nazione: "ITALIA" },
  { codeKey: "I465", sigla: "TO", provincia: "SAUZE DI CESANA", nazione: "ITALIA" },
  { codeKey: "I466", sigla: "TO", provincia: "SAUZE D'OULX", nazione: "ITALIA" },
  { codeKey: "I467", sigla: "TA", provincia: "SAVA", nazione: "ITALIA" },
  { codeKey: "I468", sigla: "KR", provincia: "SAVELLI", nazione: "ITALIA" },
  { codeKey: "I469", sigla: "NA", provincia: "SAVIANO", nazione: "ITALIA" },
  { codeKey: "I470", sigla: "CN", provincia: "SAVIGLIANO", nazione: "ITALIA" },
  { codeKey: "I471", sigla: "AV", provincia: "SAVIGNANO IRPINO", nazione: "ITALIA" },
  {
    codeKey: "I472",
    sigla: "FC",
    provincia: "SAVIGNANO SUL RUBICONE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I473",
    sigla: "MO",
    provincia: "SAVIGNANO SUL PANARO",
    nazione: "ITALIA",
  },
  { codeKey: "I475", sigla: "GE", provincia: "SAVIGNONE", nazione: "ITALIA" },
  {
    codeKey: "I476",
    sigla: "BS",
    provincia: "SAVIORE DELL'ADAMELLO",
    nazione: "ITALIA",
  },
  { codeKey: "I477", sigla: "ME", provincia: "SAVOCA", nazione: "ITALIA" },
  { codeKey: "I478", sigla: "UD", provincia: "SAVOGNA", nazione: "ITALIA" },
  { codeKey: "I479", sigla: "GO", provincia: "SAVOGNA D'ISONZO", nazione: "ITALIA" },
  { codeKey: "I480", sigla: "SV", provincia: "SAVONA", nazione: "ITALIA" },
  { codeKey: "I482", sigla: "PE", provincia: "SCAFA", nazione: "ITALIA" },
  { codeKey: "I483", sigla: "SA", provincia: "SCAFATI", nazione: "ITALIA" },
  { codeKey: "I484", sigla: "CN", provincia: "SCAGNELLO", nazione: "ITALIA" },
  { codeKey: "I485", sigla: "CS", provincia: "SCALA COELI", nazione: "ITALIA" },
  { codeKey: "I486", sigla: "SA", provincia: "SCALA", nazione: "ITALIA" },
  { codeKey: "I487", sigla: "PV", provincia: "SCALDASOLE", nazione: "ITALIA" },
  { codeKey: "I489", sigla: "CS", provincia: "SCALEA", nazione: "ITALIA" },
  { codeKey: "I490", sigla: "TO", provincia: "SCALENGHE", nazione: "ITALIA" },
  { codeKey: "I492", sigla: "ME", provincia: "SCALETTA ZANCLEA", nazione: "ITALIA" },
  { codeKey: "I493", sigla: "AV", provincia: "SCAMPITELLA", nazione: "ITALIA" },
  { codeKey: "I494", sigla: "KR", provincia: "SCANDALE", nazione: "ITALIA" },
  { codeKey: "I496", sigla: "RE", provincia: "SCANDIANO", nazione: "ITALIA" },
  {
    codeKey: "I497",
    sigla: "CR",
    provincia: "SCANDOLARA RAVARA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I498",
    sigla: "CR",
    provincia: "SCANDOLARA RIPA D'OGLIO",
    nazione: "ITALIA",
  },
  { codeKey: "I499", sigla: "RI", provincia: "SCANDRIGLIA", nazione: "ITALIA" },
  { codeKey: "I501", sigla: "AQ", provincia: "SCANNO", nazione: "ITALIA" },
  {
    codeKey: "I503",
    sigla: "OR",
    provincia: "SCANO DI MONTIFERRO",
    nazione: "ITALIA",
  },
  { codeKey: "I504", sigla: "GR", provincia: "SCANSANO", nazione: "ITALIA" },
  { codeKey: "I506", sigla: "BG", provincia: "SCANZOROSCIATE", nazione: "ITALIA" },
  { codeKey: "I507", sigla: "IS", provincia: "SCAPOLI", nazione: "ITALIA" },
  { codeKey: "I510", sigla: "GR", provincia: "SCARLINO", nazione: "ITALIA" },
  { codeKey: "I511", sigla: "TO", provincia: "SCARMAGNO", nazione: "ITALIA" },
  { codeKey: "I512", sigla: "CN", provincia: "SCARNAFIGI", nazione: "ITALIA" },
  { codeKey: "I519", sigla: "BZ", provincia: "SCENA", nazione: "ITALIA" },
  { codeKey: "I520", sigla: "CH", provincia: "SCERNI", nazione: "ITALIA" },
  {
    codeKey: "I522",
    sigla: "PG",
    provincia: "SCHEGGIA E PASCELUPO",
    nazione: "ITALIA",
  },
  { codeKey: "I523", sigla: "PG", provincia: "SCHEGGINO", nazione: "ITALIA" },
  {
    codeKey: "I526",
    sigla: "CH",
    provincia: "SCHIAVI DI ABRUZZO",
    nazione: "ITALIA",
  },
  { codeKey: "I527", sigla: "VI", provincia: "SCHIAVON", nazione: "ITALIA" },
  { codeKey: "I529", sigla: "CO", provincia: "SCHIGNANO", nazione: "ITALIA" },
  { codeKey: "I530", sigla: "BG", provincia: "SCHILPARIO", nazione: "ITALIA" },
  { codeKey: "I531", sigla: "VI", provincia: "SCHIO", nazione: "ITALIA" },
  { codeKey: "I532", sigla: "MN", provincia: "SCHIVENOGLIA", nazione: "ITALIA" },
  { codeKey: "I533", sigla: "AG", provincia: "SCIACCA", nazione: "ITALIA" },
  { codeKey: "I534", sigla: "PA", provincia: "SCIARA", nazione: "ITALIA" },
  { codeKey: "I535", sigla: "RG", provincia: "SCICLI", nazione: "ITALIA" },
  { codeKey: "I536", sigla: "RC", provincia: "SCIDO", nazione: "ITALIA" },
  { codeKey: "I537", sigla: "RC", provincia: "SCILLA", nazione: "ITALIA" },
  { codeKey: "I538", sigla: "PA", provincia: "SCILLATO", nazione: "ITALIA" },
  { codeKey: "I539", sigla: "TO", provincia: "SCIOLZE", nazione: "ITALIA" },
  { codeKey: "I540", sigla: "NA", provincia: "SCISCIANO", nazione: "ITALIA" },
  { codeKey: "I541", sigla: "PA", provincia: "SCLAFANI BAGNI", nazione: "ITALIA" },
  { codeKey: "I543", sigla: "AQ", provincia: "SCONTRONE", nazione: "ITALIA" },
  { codeKey: "I544", sigla: "VC", provincia: "SCOPA", nazione: "ITALIA" },
  { codeKey: "I545", sigla: "VC", provincia: "SCOPELLO", nazione: "ITALIA" },
  { codeKey: "I546", sigla: "AQ", provincia: "SCOPPITO", nazione: "ITALIA" },
  { codeKey: "I548", sigla: "CT", provincia: "SCORDIA", nazione: "ITALIA" },
  { codeKey: "I549", sigla: "LE", provincia: "SCORRANO", nazione: "ITALIA" },
  { codeKey: "I551", sigla: "VE", provincia: "SCORZE'", nazione: "ITALIA" },
  {
    codeKey: "I553",
    sigla: "AQ",
    provincia: "SCURCOLA MARSICANA",
    nazione: "ITALIA",
  },
  { codeKey: "I554", sigla: "TN", provincia: "SCURELLE", nazione: "ITALIA" },
  { codeKey: "I555", sigla: "AT", provincia: "SCURZOLENGO", nazione: "ITALIA" },
  { codeKey: "I556", sigla: "IM", provincia: "SEBORGA", nazione: "ITALIA" },
  { codeKey: "I558", sigla: "AQ", provincia: "SECINARO", nazione: "ITALIA" },
  { codeKey: "I559", sigla: "LE", provincia: "SECLI'", nazione: "ITALIA" },
  { codeKey: "I561", sigla: "LO", provincia: "SECUGNAGO", nazione: "ITALIA" },
  { codeKey: "I562", sigla: "UD", provincia: "SEDEGLIANO", nazione: "ITALIA" },
  { codeKey: "I563", sigla: "BL", provincia: "SEDICO", nazione: "ITALIA" },
  { codeKey: "I564", sigla: "OR", provincia: "SEDILO", nazione: "ITALIA" },
  { codeKey: "I565", sigla: "SS", provincia: "SEDINI", nazione: "ITALIA" },
  { codeKey: "I566", sigla: "MI", provincia: "SEDRIANO", nazione: "ITALIA" },
  { codeKey: "I567", sigla: "BG", provincia: "SEDRINA", nazione: "ITALIA" },
  { codeKey: "I569", sigla: "MC", provincia: "SEFRO", nazione: "ITALIA" },
  { codeKey: "I570", sigla: "VS", provincia: "SEGARIU", nazione: "ITALIA" },
  { codeKey: "I571", sigla: "GR", provincia: "SEGGIANO", nazione: "ITALIA" },
  { codeKey: "I573", sigla: "RM", provincia: "SEGNI", nazione: "ITALIA" },
  { codeKey: "I576", sigla: "TN", provincia: "SEGONZANO", nazione: "ITALIA" },
  { codeKey: "I577", sigla: "MI", provincia: "SEGRATE", nazione: "ITALIA" },
  { codeKey: "I578", sigla: "TV", provincia: "SEGUSINO", nazione: "ITALIA" },
  { codeKey: "I580", sigla: "CA", provincia: "SELARGIUS", nazione: "ITALIA" },
  { codeKey: "I581", sigla: "RI", provincia: "SELCI", nazione: "ITALIA" },
  { codeKey: "I582", sigla: "CA", provincia: "SELEGAS", nazione: "ITALIA" },
  { codeKey: "I585", sigla: "PG", provincia: "SELLANO", nazione: "ITALIA" },
  { codeKey: "I588", sigla: "BS", provincia: "SELLERO", nazione: "ITALIA" },
  { codeKey: "I589", sigla: "CZ", provincia: "SELLIA", nazione: "ITALIA" },
  { codeKey: "I590", sigla: "CZ", provincia: "SELLIA MARINA", nazione: "ITALIA" },
  {
    codeKey: "I591",
    sigla: "BZ",
    provincia: "SELVA DI VAL GARDENA",
    nazione: "ITALIA",
  },
  { codeKey: "I592", sigla: "BL", provincia: "SELVA DI CADORE", nazione: "ITALIA" },
  { codeKey: "I593", sigla: "BZ", provincia: "SELVA DEI MOLINI", nazione: "ITALIA" },
  { codeKey: "I594", sigla: "VR", provincia: "SELVA DI PROGNO", nazione: "ITALIA" },
  {
    codeKey: "I595",
    sigla: "PD",
    provincia: "SELVAZZANO DENTRO",
    nazione: "ITALIA",
  },
  { codeKey: "I596", sigla: "BI", provincia: "SELVE MARCONE", nazione: "ITALIA" },
  { codeKey: "I597", sigla: "BG", provincia: "SELVINO", nazione: "ITALIA" },
  { codeKey: "I598", sigla: "SS", provincia: "SEMESTENE", nazione: "ITALIA" },
  { codeKey: "I599", sigla: "PV", provincia: "SEMIANA", nazione: "ITALIA" },
  { codeKey: "I600", sigla: "RC", provincia: "SEMINARA", nazione: "ITALIA" },
  { codeKey: "I601", sigla: "GR", provincia: "SEMPRONIANO", nazione: "ITALIA" },
  { codeKey: "I602", sigla: "MI", provincia: "SENAGO", nazione: "ITALIA" },
  {
    codeKey: "I603",
    sigla: "BZ",
    provincia: "SENALE-SAN FELICE",
    nazione: "ITALIA",
  },
  { codeKey: "I604", sigla: "BZ", provincia: "SENALES", nazione: "ITALIA" },
  { codeKey: "I605", sigla: "OR", provincia: "SENEGHE", nazione: "ITALIA" },
  { codeKey: "I606", sigla: "AV", provincia: "SENERCHIA", nazione: "ITALIA" },
  { codeKey: "I607", sigla: "BS", provincia: "SENIGA", nazione: "ITALIA" },
  { codeKey: "I608", sigla: "AN", provincia: "SENIGALLIA", nazione: "ITALIA" },
  { codeKey: "I609", sigla: "OR", provincia: "SENIS", nazione: "ITALIA" },
  { codeKey: "I610", sigla: "PZ", provincia: "SENISE", nazione: "ITALIA" },
  { codeKey: "I611", sigla: "CO", provincia: "SENNA COMASCO", nazione: "ITALIA" },
  { codeKey: "I612", sigla: "LO", provincia: "SENNA LODIGIANA", nazione: "ITALIA" },
  { codeKey: "I613", sigla: "OR", provincia: "SENNARIOLO", nazione: "ITALIA" },
  { codeKey: "I614", sigla: "SS", provincia: "SENNORI", nazione: "ITALIA" },
  { codeKey: "I615", sigla: "CA", provincia: "SENORBI'", nazione: "ITALIA" },
  { codeKey: "I618", sigla: "CB", provincia: "SEPINO", nazione: "ITALIA" },
  { codeKey: "I619", sigla: "VB", provincia: "SEPPIANA", nazione: "ITALIA" },
  { codeKey: "I621", sigla: "PN", provincia: "SEQUALS", nazione: "ITALIA" },
  { codeKey: "I622", sigla: "LU", provincia: "SERAVEZZA", nazione: "ITALIA" },
  { codeKey: "I624", sigla: "CA", provincia: "SERDIANA", nazione: "ITALIA" },
  { codeKey: "I625", sigla: "MB", provincia: "SEREGNO", nazione: "ITALIA" },
  { codeKey: "I626", sigla: "BL", provincia: "SEREN DEL GRAPPA", nazione: "ITALIA" },
  { codeKey: "I627", sigla: "CR", provincia: "SERGNANO", nazione: "ITALIA" },
  { codeKey: "I628", sigla: "BG", provincia: "SERIATE", nazione: "ITALIA" },
  { codeKey: "I629", sigla: "BG", provincia: "SERINA", nazione: "ITALIA" },
  { codeKey: "I630", sigla: "AV", provincia: "SERINO", nazione: "ITALIA" },
  { codeKey: "I631", sigla: "BS", provincia: "SERLE", nazione: "ITALIA" },
  { codeKey: "I632", sigla: "MN", provincia: "SERMIDE", nazione: "ITALIA" },
  { codeKey: "I633", sigla: "BS", provincia: "SIRMIONE", nazione: "ITALIA" },
  { codeKey: "I634", sigla: "LT", provincia: "SERMONETA", nazione: "ITALIA" },
  {
    codeKey: "I635",
    sigla: "TV",
    provincia: "SERNAGLIA DELLA BATTAGLIA",
    nazione: "ITALIA",
  },
  { codeKey: "I636", sigla: "SO", provincia: "SERNIO", nazione: "ITALIA" },
  { codeKey: "I637", sigla: "AT", provincia: "SEROLE", nazione: "ITALIA" },
  { codeKey: "I639", sigla: "VV", provincia: "SERRA SAN BRUNO", nazione: "ITALIA" },
  { codeKey: "I640", sigla: "GE", provincia: "SERRA RICCO'", nazione: "ITALIA" },
  { codeKey: "I641", sigla: "FG", provincia: "SERRACAPRIOLA", nazione: "ITALIA" },
  { codeKey: "I642", sigla: "CS", provincia: "SERRA D'AIELLO", nazione: "ITALIA" },
  { codeKey: "I643", sigla: "AN", provincia: "SERRA DE' CONTI", nazione: "ITALIA" },
  { codeKey: "I644", sigla: "CL", provincia: "SERRADIFALCO", nazione: "ITALIA" },
  {
    codeKey: "I645",
    sigla: "AL",
    provincia: "SERRALUNGA DI CREA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I646",
    sigla: "CN",
    provincia: "SERRALUNGA D'ALBA",
    nazione: "ITALIA",
  },
  { codeKey: "I647", sigla: "VS", provincia: "SERRAMANNA", nazione: "ITALIA" },
  { codeKey: "I648", sigla: "SA", provincia: "SERRAMEZZANA", nazione: "ITALIA" },
  { codeKey: "I649", sigla: "PE", provincia: "SERRAMONACESCA", nazione: "ITALIA" },
  { codeKey: "I650", sigla: "CS", provincia: "SERRA PEDACE", nazione: "ITALIA" },
  { codeKey: "I651", sigla: "MC", provincia: "SERRAPETRONA", nazione: "ITALIA" },
  { codeKey: "I652", sigla: "NA", provincia: "SERRARA FONTANA", nazione: "ITALIA" },
  {
    codeKey: "I653",
    sigla: "AN",
    provincia: "SERRA SAN QUIRICO",
    nazione: "ITALIA",
  },
  {
    codeKey: "I654",
    sigla: "PU",
    provincia: "SERRA SANT'ABBONDIO",
    nazione: "ITALIA",
  },
  { codeKey: "I655", sigla: "CZ", provincia: "SERRASTRETTA", nazione: "ITALIA" },
  { codeKey: "I656", sigla: "RC", provincia: "SERRATA", nazione: "ITALIA" },
  {
    codeKey: "I657",
    sigla: "AL",
    provincia: "SERRAVALLE SCRIVIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "I659",
    sigla: "CN",
    provincia: "SERRAVALLE LANGHE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I660",
    sigla: "PT",
    provincia: "SERRAVALLE PISTOIESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I661",
    sigla: "MC",
    provincia: "SERRAVALLE DI CHIENTI",
    nazione: "ITALIA",
  },
  { codeKey: "I662", sigla: "MN", provincia: "SERRAVALLE A PO", nazione: "ITALIA" },
  { codeKey: "I663", sigla: "VC", provincia: "SERRAVALLE SESIA", nazione: "ITALIA" },
  { codeKey: "I666", sigla: "SA", provincia: "SERRE", nazione: "ITALIA" },
  { codeKey: "I667", sigla: "VS", provincia: "SERRENTI", nazione: "ITALIA" },
  { codeKey: "I668", sigla: "NU", provincia: "SERRI", nazione: "ITALIA" },
  { codeKey: "I669", sigla: "FR", provincia: "SERRONE", nazione: "ITALIA" },
  { codeKey: "I670", sigla: "PU", provincia: "SERRUNGARINA", nazione: "ITALIA" },
  { codeKey: "I671", sigla: "CZ", provincia: "SERSALE", nazione: "ITALIA" },
  { codeKey: "I673", sigla: "BL", provincia: "SOVRAMONTE", nazione: "ITALIA" },
  { codeKey: "I676", sigla: "CE", provincia: "SESSA AURUNCA", nazione: "ITALIA" },
  { codeKey: "I677", sigla: "SA", provincia: "SESSA CILENTO", nazione: "ITALIA" },
  { codeKey: "I678", sigla: "AT", provincia: "SESSAME", nazione: "ITALIA" },
  {
    codeKey: "I679",
    sigla: "IS",
    provincia: "SESSANO DEL MOLISE",
    nazione: "ITALIA",
  },
  { codeKey: "I681", sigla: "AR", provincia: "SESTINO", nazione: "ITALIA" },
  { codeKey: "I682", sigla: "IS", provincia: "SESTO CAMPANO", nazione: "ITALIA" },
  { codeKey: "I683", sigla: "CR", provincia: "SESTO ED UNITI", nazione: "ITALIA" },
  { codeKey: "I684", sigla: "FI", provincia: "SESTO FIORENTINO", nazione: "ITALIA" },
  { codeKey: "I686", sigla: "PN", provincia: "SESTO AL REGHENA", nazione: "ITALIA" },
  { codeKey: "I687", sigla: "BZ", provincia: "SESTO", nazione: "ITALIA" },
  { codeKey: "I688", sigla: "VA", provincia: "SESTO CALENDE", nazione: "ITALIA" },
  { codeKey: "I689", sigla: "MO", provincia: "SESTOLA", nazione: "ITALIA" },
  {
    codeKey: "I690",
    sigla: "MI",
    provincia: "SESTO SAN GIOVANNI",
    nazione: "ITALIA",
  },
  { codeKey: "I692", sigla: "TO", provincia: "SESTRIERE", nazione: "ITALIA" },
  { codeKey: "I693", sigla: "GE", provincia: "SESTRI LEVANTE", nazione: "ITALIA" },
  { codeKey: "I695", sigla: "CA", provincia: "SESTU", nazione: "ITALIA" },
  { codeKey: "I696", sigla: "MI", provincia: "SETTALA", nazione: "ITALIA" },
  { codeKey: "I697", sigla: "FR", provincia: "SETTEFRATI", nazione: "ITALIA" },
  { codeKey: "I698", sigla: "AT", provincia: "SETTIME", nazione: "ITALIA" },
  {
    codeKey: "I699",
    sigla: "CA",
    provincia: "SETTIMO SAN PIETRO",
    nazione: "ITALIA",
  },
  { codeKey: "I700", sigla: "MI", provincia: "SETTIMO MILANESE", nazione: "ITALIA" },
  { codeKey: "I701", sigla: "TO", provincia: "SETTIMO ROTTARO", nazione: "ITALIA" },
  { codeKey: "I702", sigla: "TO", provincia: "SETTIMO VITTONE", nazione: "ITALIA" },
  { codeKey: "I703", sigla: "TO", provincia: "SETTIMO TORINESE", nazione: "ITALIA" },
  { codeKey: "I704", sigla: "CZ", provincia: "SETTINGIANO", nazione: "ITALIA" },
  { codeKey: "I705", sigla: "VS", provincia: "SETZU", nazione: "ITALIA" },
  { codeKey: "I706", sigla: "OG", provincia: "SEUI", nazione: "ITALIA" },
  { codeKey: "I707", sigla: "NU", provincia: "SEULO", nazione: "ITALIA" },
  { codeKey: "I709", sigla: "MB", provincia: "SEVESO", nazione: "ITALIA" },
  { codeKey: "I711", sigla: "AL", provincia: "SEZZADIO", nazione: "ITALIA" },
  { codeKey: "I712", sigla: "LT", provincia: "SEZZE", nazione: "ITALIA" },
  { codeKey: "I714", sigla: "TN", provincia: "SFRUZ", nazione: "ITALIA" },
  { codeKey: "I715", sigla: "TS", provincia: "SGONICO", nazione: "ITALIA" },
  { codeKey: "I716", sigla: "FR", provincia: "SGURGOLA", nazione: "ITALIA" },
  { codeKey: "I717", sigla: "OR", provincia: "SIAMAGGIORE", nazione: "ITALIA" },
  { codeKey: "I718", sigla: "OR", provincia: "SIAMANNA", nazione: "ITALIA" },
  { codeKey: "I720", sigla: "SA", provincia: "SIANO", nazione: "ITALIA" },
  { codeKey: "I721", sigla: "OR", provincia: "SIAPICCIA", nazione: "ITALIA" },
  { codeKey: "I723", sigla: "AG", provincia: "SICULIANA", nazione: "ITALIA" },
  { codeKey: "I724", sigla: "VS", provincia: "SIDDI", nazione: "ITALIA" },
  { codeKey: "I725", sigla: "RC", provincia: "SIDERNO", nazione: "ITALIA" },
  { codeKey: "I726", sigla: "SI", provincia: "SIENA", nazione: "ITALIA" },
  { codeKey: "I727", sigla: "PG", provincia: "SIGILLO", nazione: "ITALIA" },
  { codeKey: "I728", sigla: "FI", provincia: "SIGNA", nazione: "ITALIA" },
  { codeKey: "I729", sigla: "BZ", provincia: "SILANDRO", nazione: "ITALIA" },
  { codeKey: "I730", sigla: "NU", provincia: "SILANUS", nazione: "ITALIA" },
  { codeKey: "I732", sigla: "SS", provincia: "SILIGO", nazione: "ITALIA" },
  { codeKey: "I734", sigla: "CA", provincia: "SILIQUA", nazione: "ITALIA" },
  { codeKey: "I735", sigla: "CA", provincia: "SILIUS", nazione: "ITALIA" },
  { codeKey: "I736", sigla: "NO", provincia: "SILLAVENGO", nazione: "ITALIA" },
  { codeKey: "I738", sigla: "AL", provincia: "SILVANO D'ORBA", nazione: "ITALIA" },
  { codeKey: "I739", sigla: "PV", provincia: "SILVANO PIETRA", nazione: "ITALIA" },
  { codeKey: "I741", sigla: "TE", provincia: "SILVI", nazione: "ITALIA" },
  { codeKey: "I742", sigla: "OR", provincia: "SIMALA", nazione: "ITALIA" },
  { codeKey: "I743", sigla: "OR", provincia: "SIMAXIS", nazione: "ITALIA" },
  { codeKey: "I744", sigla: "VV", provincia: "SIMBARIO", nazione: "ITALIA" },
  { codeKey: "I745", sigla: "CZ", provincia: "SIMERI CRICHI", nazione: "ITALIA" },
  { codeKey: "I747", sigla: "ME", provincia: "SINAGRA", nazione: "ITALIA" },
  { codeKey: "I748", sigla: "NU", provincia: "SINDIA", nazione: "ITALIA" },
  { codeKey: "I749", sigla: "OR", provincia: "SINI", nazione: "ITALIA" },
  { codeKey: "I750", sigla: "CN", provincia: "SINIO", nazione: "ITALIA" },
  { codeKey: "I751", sigla: "NU", provincia: "SINISCOLA", nazione: "ITALIA" },
  { codeKey: "I752", sigla: "CA", provincia: "SINNAI", nazione: "ITALIA" },
  { codeKey: "I753", sigla: "RC", provincia: "SINOPOLI", nazione: "ITALIA" },
  { codeKey: "I754", sigla: "SR", provincia: "SIRACUSA", nazione: "ITALIA" },
  { codeKey: "I756", sigla: "AV", provincia: "SIRIGNANO", nazione: "ITALIA" },
  { codeKey: "I757", sigla: "OR", provincia: "SIRIS", nazione: "ITALIA" },
  { codeKey: "I758", sigla: "AN", provincia: "SIROLO", nazione: "ITALIA" },
  { codeKey: "I759", sigla: "LC", provincia: "SIRONE", nazione: "ITALIA" },
  { codeKey: "I760", sigla: "TN", provincia: "SIROR", nazione: "ITALIA" },
  { codeKey: "I761", sigla: "LC", provincia: "SIRTORI", nazione: "ITALIA" },
  { codeKey: "I765", sigla: "CA", provincia: "SIURGUS DONIGALA", nazione: "ITALIA" },
  { codeKey: "I767", sigla: "NO", provincia: "SIZZANO", nazione: "ITALIA" },
  { codeKey: "I771", sigla: "BZ", provincia: "SLUDERNO", nazione: "ITALIA" },
  { codeKey: "I774", sigla: "FM", provincia: "SMERILLO", nazione: "ITALIA" },
  { codeKey: "I775", sigla: "VR", provincia: "SOAVE", nazione: "ITALIA" },
  { codeKey: "I777", sigla: "UD", provincia: "SOCCHIEVE", nazione: "ITALIA" },
  { codeKey: "I778", sigla: "OR", provincia: "SODDI'", nazione: "ITALIA" },
  {
    codeKey: "I779",
    sigla: "FC",
    provincia: "SOGLIANO AL RUBICONE",
    nazione: "ITALIA",
  },
  { codeKey: "I780", sigla: "LE", provincia: "SOGLIANO CAVOUR", nazione: "ITALIA" },
  { codeKey: "I781", sigla: "AT", provincia: "SOGLIO", nazione: "ITALIA" },
  { codeKey: "I782", sigla: "BS", provincia: "SOIANO DEL LAGO", nazione: "ITALIA" },
  { codeKey: "I783", sigla: "VI", provincia: "SOLAGNA", nazione: "ITALIA" },
  { codeKey: "I785", sigla: "SR", provincia: "SOLARINO", nazione: "ITALIA" },
  { codeKey: "I786", sigla: "MI", provincia: "SOLARO", nazione: "ITALIA" },
  { codeKey: "I787", sigla: "RA", provincia: "SOLAROLO", nazione: "ITALIA" },
  {
    codeKey: "I790",
    sigla: "CR",
    provincia: "SOLAROLO RAINERIO",
    nazione: "ITALIA",
  },
  { codeKey: "I791", sigla: "OR", provincia: "SOLARUSSA", nazione: "ITALIA" },
  { codeKey: "I792", sigla: "CO", provincia: "SOLBIATE", nazione: "ITALIA" },
  { codeKey: "I793", sigla: "VA", provincia: "SOLBIATE ARNO", nazione: "ITALIA" },
  { codeKey: "I794", sigla: "VA", provincia: "SOLBIATE OLONA", nazione: "ITALIA" },
  { codeKey: "I796", sigla: "IM", provincia: "SOLDANO", nazione: "ITALIA" },
  { codeKey: "I797", sigla: "CA", provincia: "SOLEMINIS", nazione: "ITALIA" },
  { codeKey: "I798", sigla: "AL", provincia: "SOLERO", nazione: "ITALIA" },
  { codeKey: "I799", sigla: "PD", provincia: "SOLESINO", nazione: "ITALIA" },
  { codeKey: "I800", sigla: "LE", provincia: "SOLETO", nazione: "ITALIA" },
  { codeKey: "I801", sigla: "MN", provincia: "SOLFERINO", nazione: "ITALIA" },
  { codeKey: "I802", sigla: "MO", provincia: "SOLIERA", nazione: "ITALIA" },
  { codeKey: "I803", sigla: "PR", provincia: "SOLIGNANO", nazione: "ITALIA" },
  { codeKey: "I804", sigla: "AQ", provincia: "SULMONA", nazione: "ITALIA" },
  { codeKey: "I805", sigla: "AV", provincia: "SOLOFRA", nazione: "ITALIA" },
  { codeKey: "I808", sigla: "AL", provincia: "SOLONGHELLO", nazione: "ITALIA" },
  { codeKey: "I809", sigla: "BN", provincia: "SOLOPACA", nazione: "ITALIA" },
  { codeKey: "I812", sigla: "BG", provincia: "SOLTO COLLINA", nazione: "ITALIA" },
  { codeKey: "I813", sigla: "BG", provincia: "SOLZA", nazione: "ITALIA" },
  { codeKey: "I815", sigla: "LO", provincia: "SOMAGLIA", nazione: "ITALIA" },
  { codeKey: "I817", sigla: "CN", provincia: "SOMANO", nazione: "ITALIA" },
  { codeKey: "I819", sigla: "VA", provincia: "SOMMA LOMBARDO", nazione: "ITALIA" },
  { codeKey: "I820", sigla: "NA", provincia: "SOMMA VESUVIANA", nazione: "ITALIA" },
  { codeKey: "I821", sigla: "VR", provincia: "SOMMACAMPAGNA", nazione: "ITALIA" },
  {
    codeKey: "I822",
    sigla: "CN",
    provincia: "SOMMARIVA DEL BOSCO",
    nazione: "ITALIA",
  },
  { codeKey: "I823", sigla: "CN", provincia: "SOMMARIVA PERNO", nazione: "ITALIA" },
  { codeKey: "I824", sigla: "CL", provincia: "SOMMATINO", nazione: "ITALIA" },
  { codeKey: "I825", sigla: "PV", provincia: "SOMMO", nazione: "ITALIA" },
  { codeKey: "I826", sigla: "VR", provincia: "SONA", nazione: "ITALIA" },
  { codeKey: "I827", sigla: "CR", provincia: "SONCINO", nazione: "ITALIA" },
  { codeKey: "I828", sigla: "SO", provincia: "SONDALO", nazione: "ITALIA" },
  { codeKey: "I829", sigla: "SO", provincia: "SONDRIO", nazione: "ITALIA" },
  { codeKey: "I830", sigla: "BG", provincia: "SONGAVAZZO", nazione: "ITALIA" },
  { codeKey: "I831", sigla: "BS", provincia: "SONICO", nazione: "ITALIA" },
  { codeKey: "I832", sigla: "LT", provincia: "SONNINO", nazione: "ITALIA" },
  { codeKey: "I835", sigla: "BI", provincia: "SOPRANA", nazione: "ITALIA" },
  { codeKey: "I838", sigla: "FR", provincia: "SORA", nazione: "ITALIA" },
  { codeKey: "I839", sigla: "TN", provincia: "SORAGA", nazione: "ITALIA" },
  { codeKey: "I840", sigla: "PR", provincia: "SORAGNA", nazione: "ITALIA" },
  { codeKey: "I841", sigla: "GR", provincia: "SORANO", nazione: "ITALIA" },
  { codeKey: "I843", sigla: "AV", provincia: "SORBO SERPICO", nazione: "ITALIA" },
  { codeKey: "I844", sigla: "CZ", provincia: "SORBO SAN BASILE", nazione: "ITALIA" },
  { codeKey: "I845", sigla: "PR", provincia: "SORBOLO", nazione: "ITALIA" },
  { codeKey: "I847", sigla: "BI", provincia: "SORDEVOLO", nazione: "ITALIA" },
  { codeKey: "I848", sigla: "LO", provincia: "SORDIO", nazione: "ITALIA" },
  { codeKey: "I849", sigla: "CR", provincia: "SORESINA", nazione: "ITALIA" },
  { codeKey: "I850", sigla: "VR", provincia: "SORGA'", nazione: "ITALIA" },
  { codeKey: "I851", sigla: "NU", provincia: "SORGONO", nazione: "ITALIA" },
  { codeKey: "I852", sigla: "GE", provincia: "SORI", nazione: "ITALIA" },
  { codeKey: "I853", sigla: "VV", provincia: "SORIANELLO", nazione: "ITALIA" },
  { codeKey: "I854", sigla: "VV", provincia: "SORIANO CALABRO", nazione: "ITALIA" },
  {
    codeKey: "I855",
    sigla: "VT",
    provincia: "SORIANO NEL CIMINO",
    nazione: "ITALIA",
  },
  { codeKey: "I856", sigla: "CO", provincia: "SORICO", nazione: "ITALIA" },
  { codeKey: "I857", sigla: "NO", provincia: "SORISO", nazione: "ITALIA" },
  { codeKey: "I858", sigla: "BG", provincia: "SORISOLE", nazione: "ITALIA" },
  { codeKey: "I860", sigla: "CO", provincia: "SORMANO", nazione: "ITALIA" },
  { codeKey: "I861", sigla: "OR", provincia: "SORRADILE", nazione: "ITALIA" },
  { codeKey: "I862", sigla: "NA", provincia: "SORRENTO", nazione: "ITALIA" },
  { codeKey: "I863", sigla: "SS", provincia: "SORSO", nazione: "ITALIA" },
  { codeKey: "I864", sigla: "SR", provincia: "SORTINO", nazione: "ITALIA" },
  { codeKey: "I865", sigla: "CR", provincia: "SOSPIRO", nazione: "ITALIA" },
  { codeKey: "I866", sigla: "BL", provincia: "SOSPIROLO", nazione: "ITALIA" },
  { codeKey: "I867", sigla: "VI", provincia: "SOSSANO", nazione: "ITALIA" },
  { codeKey: "I868", sigla: "BI", provincia: "SOSTEGNO", nazione: "ITALIA" },
  {
    codeKey: "I869",
    sigla: "BG",
    provincia: "SOTTO IL MONTE GIOVANNI XXIII",
    nazione: "ITALIA",
  },
  { codeKey: "I871", sigla: "TN", provincia: "SOVER", nazione: "ITALIA" },
  { codeKey: "I872", sigla: "CZ", provincia: "SOVERATO", nazione: "ITALIA" },
  { codeKey: "I873", sigla: "BG", provincia: "SOVERE", nazione: "ITALIA" },
  { codeKey: "I874", sigla: "CZ", provincia: "SOVERIA MANNELLI", nazione: "ITALIA" },
  { codeKey: "I875", sigla: "CZ", provincia: "SOVERIA SIMERI", nazione: "ITALIA" },
  { codeKey: "I876", sigla: "BL", provincia: "SOVERZENE", nazione: "ITALIA" },
  { codeKey: "I877", sigla: "SI", provincia: "SOVICILLE", nazione: "ITALIA" },
  { codeKey: "I878", sigla: "MB", provincia: "SOVICO", nazione: "ITALIA" },
  { codeKey: "I879", sigla: "VI", provincia: "SOVIZZO", nazione: "ITALIA" },
  { codeKey: "I880", sigla: "NO", provincia: "SOZZAGO", nazione: "ITALIA" },
  { codeKey: "I881", sigla: "ME", provincia: "SPADAFORA", nazione: "ITALIA" },
  { codeKey: "I884", sigla: "VV", provincia: "SPADOLA", nazione: "ITALIA" },
  { codeKey: "I885", sigla: "CE", provincia: "SPARANISE", nazione: "ITALIA" },
  { codeKey: "I886", sigla: "TO", provincia: "SPARONE", nazione: "ITALIA" },
  { codeKey: "I887", sigla: "LE", provincia: "SPECCHIA", nazione: "ITALIA" },
  { codeKey: "I888", sigla: "PG", provincia: "SPELLO", nazione: "ITALIA" },
  { codeKey: "I889", sigla: "TN", provincia: "SPERA", nazione: "ITALIA" },
  { codeKey: "I891", sigla: "EN", provincia: "SPERLINGA", nazione: "ITALIA" },
  { codeKey: "I892", sigla: "LT", provincia: "SPERLONGA", nazione: "ITALIA" },
  { codeKey: "I893", sigla: "AV", provincia: "SPERONE", nazione: "ITALIA" },
  { codeKey: "I894", sigla: "PV", provincia: "SPESSA", nazione: "ITALIA" },
  {
    codeKey: "I895",
    sigla: "CS",
    provincia: "SPEZZANO ALBANESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "I896",
    sigla: "CS",
    provincia: "SPEZZANO DELLA SILA",
    nazione: "ITALIA",
  },
  { codeKey: "I898", sigla: "CS", provincia: "SPEZZANO PICCOLO", nazione: "ITALIA" },
  { codeKey: "I899", sigla: "TN", provincia: "SPIAZZO", nazione: "ITALIA" },
  {
    codeKey: "I901",
    sigla: "AL",
    provincia: "SPIGNO MONFERRATO",
    nazione: "ITALIA",
  },
  { codeKey: "I902", sigla: "LT", provincia: "SPIGNO SATURNIA", nazione: "ITALIA" },
  { codeKey: "I903", sigla: "MO", provincia: "SPILAMBERTO", nazione: "ITALIA" },
  { codeKey: "I904", sigla: "PN", provincia: "SPILIMBERGO", nazione: "ITALIA" },
  { codeKey: "I905", sigla: "VV", provincia: "SPILINGA", nazione: "ITALIA" },
  { codeKey: "I906", sigla: "CR", provincia: "SPINADESCO", nazione: "ITALIA" },
  { codeKey: "I907", sigla: "BT", provincia: "SPINAZZOLA", nazione: "ITALIA" },
  { codeKey: "I908", sigla: "VE", provincia: "SPINEA", nazione: "ITALIA" },
  { codeKey: "I909", sigla: "CR", provincia: "SPINEDA", nazione: "ITALIA" },
  { codeKey: "I910", sigla: "CB", provincia: "SPINETE", nazione: "ITALIA" },
  { codeKey: "I911", sigla: "AL", provincia: "SPINETO SCRIVIA", nazione: "ITALIA" },
  { codeKey: "I912", sigla: "AP", provincia: "SPINETOLI", nazione: "ITALIA" },
  { codeKey: "I914", sigla: "CR", provincia: "SPINO D'ADDA", nazione: "ITALIA" },
  { codeKey: "I916", sigla: "BG", provincia: "SPINONE AL LAGO", nazione: "ITALIA" },
  { codeKey: "I917", sigla: "PZ", provincia: "SPINOSO", nazione: "ITALIA" },
  { codeKey: "I919", sigla: "BG", provincia: "SPIRANO", nazione: "ITALIA" },
  { codeKey: "I921", sigla: "PG", provincia: "SPOLETO", nazione: "ITALIA" },
  { codeKey: "I922", sigla: "PE", provincia: "SPOLTORE", nazione: "ITALIA" },
  { codeKey: "I923", sigla: "LE", provincia: "SPONGANO", nazione: "ITALIA" },
  { codeKey: "I924", sigla: "TN", provincia: "SPORMAGGIORE", nazione: "ITALIA" },
  { codeKey: "I925", sigla: "TN", provincia: "SPORMINORE", nazione: "ITALIA" },
  { codeKey: "I926", sigla: "SV", provincia: "SPOTORNO", nazione: "ITALIA" },
  { codeKey: "I927", sigla: "TV", provincia: "SPRESIANO", nazione: "ITALIA" },
  { codeKey: "I928", sigla: "SO", provincia: "SPRIANA", nazione: "ITALIA" },
  { codeKey: "I929", sigla: "CZ", provincia: "SQUILLACE", nazione: "ITALIA" },
  { codeKey: "I930", sigla: "LE", provincia: "SQUINZANO", nazione: "ITALIA" },
  { codeKey: "I932", sigla: "AN", provincia: "STAFFOLO", nazione: "ITALIA" },
  { codeKey: "I935", sigla: "CR", provincia: "STAGNO LOMBARDO", nazione: "ITALIA" },
  { codeKey: "I936", sigla: "RC", provincia: "STAITI", nazione: "ITALIA" },
  { codeKey: "I937", sigla: "CZ", provincia: "STALETTI", nazione: "ITALIA" },
  { codeKey: "I938", sigla: "PD", provincia: "STANGHELLA", nazione: "ITALIA" },
  { codeKey: "I939", sigla: "GO", provincia: "STARANZANO", nazione: "ITALIA" },
  { codeKey: "I941", sigla: "AL", provincia: "STAZZANO", nazione: "ITALIA" },
  { codeKey: "I942", sigla: "LU", provincia: "STAZZEMA", nazione: "ITALIA" },
  { codeKey: "I943", sigla: "CO", provincia: "STAZZONA", nazione: "ITALIA" },
  { codeKey: "I945", sigla: "VV", provincia: "STEFANACONI", nazione: "ITALIA" },
  { codeKey: "I946", sigla: "SV", provincia: "STELLA", nazione: "ITALIA" },
  { codeKey: "I947", sigla: "SV", provincia: "STELLANELLO", nazione: "ITALIA" },
  { codeKey: "I948", sigla: "BZ", provincia: "STELVIO", nazione: "ITALIA" },
  { codeKey: "I949", sigla: "TN", provincia: "STENICO", nazione: "ITALIA" },
  { codeKey: "I950", sigla: "LE", provincia: "STERNATIA", nazione: "ITALIA" },
  { codeKey: "I951", sigla: "BG", provincia: "STEZZANO", nazione: "ITALIA" },
  { codeKey: "I953", sigla: "RO", provincia: "STIENTA", nazione: "ITALIA" },
  { codeKey: "I954", sigla: "MT", provincia: "STIGLIANO", nazione: "ITALIA" },
  { codeKey: "I955", sigla: "RC", provincia: "STIGNANO", nazione: "ITALIA" },
  { codeKey: "I956", sigla: "RC", provincia: "STILO", nazione: "ITALIA" },
  { codeKey: "I959", sigla: "RI", provincia: "STIMIGLIANO", nazione: "ITALIA" },
  { codeKey: "I960", sigla: "SA", provincia: "STIO", nazione: "ITALIA" },
  { codeKey: "I962", sigla: "FG", provincia: "STORNARA", nazione: "ITALIA" },
  { codeKey: "I963", sigla: "FG", provincia: "STORNARELLA", nazione: "ITALIA" },
  { codeKey: "I964", sigla: "TN", provincia: "STORO", nazione: "ITALIA" },
  { codeKey: "I965", sigla: "VE", provincia: "STRA", nazione: "ITALIA" },
  { codeKey: "I968", sigla: "PV", provincia: "STRADELLA", nazione: "ITALIA" },
  { codeKey: "I969", sigla: "TO", provincia: "STRAMBINELLO", nazione: "ITALIA" },
  { codeKey: "I970", sigla: "TO", provincia: "STRAMBINO", nazione: "ITALIA" },
  { codeKey: "I973", sigla: "FR", provincia: "STRANGOLAGALLI", nazione: "ITALIA" },
  { codeKey: "I974", sigla: "UD", provincia: "STREGNA", nazione: "ITALIA" },
  { codeKey: "I975", sigla: "TN", provincia: "STREMBO", nazione: "ITALIA" },
  { codeKey: "I976", sigla: "VB", provincia: "STRESA", nazione: "ITALIA" },
  { codeKey: "I977", sigla: "AL", provincia: "STREVI", nazione: "ITALIA" },
  { codeKey: "I978", sigla: "NA", provincia: "STRIANO", nazione: "ITALIA" },
  { codeKey: "I979", sigla: "TN", provincia: "STRIGNO", nazione: "ITALIA" },
  { codeKey: "I980", sigla: "BI", provincia: "STRONA", nazione: "ITALIA" },
  { codeKey: "I981", sigla: "TR", provincia: "STRONCONE", nazione: "ITALIA" },
  { codeKey: "I982", sigla: "KR", provincia: "STRONGOLI", nazione: "ITALIA" },
  { codeKey: "I984", sigla: "VC", provincia: "STROPPIANA", nazione: "ITALIA" },
  { codeKey: "I985", sigla: "CN", provincia: "STROPPO", nazione: "ITALIA" },
  { codeKey: "I986", sigla: "BG", provincia: "STROZZA", nazione: "ITALIA" },
  { codeKey: "I990", sigla: "AV", provincia: "STURNO", nazione: "ITALIA" },
  { codeKey: "I991", sigla: "AR", provincia: "SUBBIANO", nazione: "ITALIA" },
  { codeKey: "I992", sigla: "RM", provincia: "SUBIACO", nazione: "ITALIA" },
  { codeKey: "I993", sigla: "CE", provincia: "SUCCIVO", nazione: "ITALIA" },
  { codeKey: "I994", sigla: "LC", provincia: "SUEGLIO", nazione: "ITALIA" },
  { codeKey: "I995", sigla: "CA", provincia: "SUELLI", nazione: "ITALIA" },
  { codeKey: "I996", sigla: "LC", provincia: "SUELLO", nazione: "ITALIA" },
  { codeKey: "I997", sigla: "BG", provincia: "SUISIO", nazione: "ITALIA" },
  { codeKey: "I998", sigla: "MB", provincia: "SULBIATE", nazione: "ITALIA" },
  { codeKey: "L002", sigla: "BS", provincia: "SULZANO", nazione: "ITALIA" },
  { codeKey: "L003", sigla: "VA", provincia: "SUMIRAGO", nazione: "ITALIA" },
  { codeKey: "L004", sigla: "AV", provincia: "SUMMONTE", nazione: "ITALIA" },
  { codeKey: "L006", sigla: "NU", provincia: "SUNI", nazione: "ITALIA" },
  { codeKey: "L007", sigla: "NO", provincia: "SUNO", nazione: "ITALIA" },
  { codeKey: "L008", sigla: "LE", provincia: "SUPERSANO", nazione: "ITALIA" },
  { codeKey: "L009", sigla: "FR", provincia: "SUPINO", nazione: "ITALIA" },
  { codeKey: "L010", sigla: "LE", provincia: "SURANO", nazione: "ITALIA" },
  { codeKey: "L011", sigla: "LE", provincia: "SURBO", nazione: "ITALIA" },
  { codeKey: "L013", sigla: "TO", provincia: "SUSA", nazione: "ITALIA" },
  { codeKey: "L014", sigla: "TV", provincia: "SUSEGANA", nazione: "ITALIA" },
  { codeKey: "L015", sigla: "MN", provincia: "SUSTINENTE", nazione: "ITALIA" },
  { codeKey: "L016", sigla: "CL", provincia: "SUTERA", nazione: "ITALIA" },
  { codeKey: "L017", sigla: "VT", provincia: "SUTRI", nazione: "ITALIA" },
  { codeKey: "L018", sigla: "UD", provincia: "SUTRIO", nazione: "ITALIA" },
  { codeKey: "L019", sigla: "LI", provincia: "SUVERETO", nazione: "ITALIA" },
  { codeKey: "L020", sigla: "MN", provincia: "SUZZARA", nazione: "ITALIA" },
  { codeKey: "L022", sigla: "LC", provincia: "TACENO", nazione: "ITALIA" },
  { codeKey: "L023", sigla: "OR", provincia: "TADASUNI", nazione: "ITALIA" },
  { codeKey: "L024", sigla: "IM", provincia: "TAGGIA", nazione: "ITALIA" },
  { codeKey: "L025", sigla: "AQ", provincia: "TAGLIACOZZO", nazione: "ITALIA" },
  { codeKey: "L026", sigla: "RO", provincia: "TAGLIO DI PO", nazione: "ITALIA" },
  {
    codeKey: "L027",
    sigla: "AL",
    provincia: "TAGLIOLO MONFERRATO",
    nazione: "ITALIA",
  },
  { codeKey: "L030", sigla: "BL", provincia: "TAIBON AGORDINO", nazione: "ITALIA" },
  { codeKey: "L032", sigla: "VA", provincia: "TAINO", nazione: "ITALIA" },
  { codeKey: "L034", sigla: "RN", provincia: "TALAMELLO", nazione: "ITALIA" },
  { codeKey: "L035", sigla: "SO", provincia: "TALAMONA", nazione: "ITALIA" },
  { codeKey: "L036", sigla: "OG", provincia: "TALANA", nazione: "ITALIA" },
  { codeKey: "L037", sigla: "BG", provincia: "TALEGGIO", nazione: "ITALIA" },
  { codeKey: "L038", sigla: "AR", provincia: "TALLA", nazione: "ITALIA" },
  { codeKey: "L039", sigla: "UD", provincia: "TALMASSONS", nazione: "ITALIA" },
  { codeKey: "L040", sigla: "BL", provincia: "TAMBRE", nazione: "ITALIA" },
  { codeKey: "L042", sigla: "ME", provincia: "TAORMINA", nazione: "ITALIA" },
  { codeKey: "L046", sigla: "RI", provincia: "TARANO", nazione: "ITALIA" },
  { codeKey: "L047", sigla: "CH", provincia: "TARANTA PELIGNA", nazione: "ITALIA" },
  { codeKey: "L048", sigla: "CN", provincia: "TARANTASCA", nazione: "ITALIA" },
  { codeKey: "L049", sigla: "TA", provincia: "TARANTO", nazione: "ITALIA" },
  { codeKey: "L050", sigla: "UD", provincia: "TARCENTO", nazione: "ITALIA" },
  { codeKey: "L055", sigla: "CS", provincia: "TARSIA", nazione: "ITALIA" },
  { codeKey: "L056", sigla: "SO", provincia: "TARTANO", nazione: "ITALIA" },
  { codeKey: "L057", sigla: "UD", provincia: "TARVISIO", nazione: "ITALIA" },
  { codeKey: "L058", sigla: "TV", provincia: "TARZO", nazione: "ITALIA" },
  { codeKey: "L059", sigla: "AL", provincia: "TASSAROLO", nazione: "ITALIA" },
  { codeKey: "L060", sigla: "TN", provincia: "TASSULLO", nazione: "ITALIA" },
  { codeKey: "L061", sigla: "AV", provincia: "TAURANO", nazione: "ITALIA" },
  { codeKey: "L062", sigla: "AV", provincia: "TAURASI", nazione: "ITALIA" },
  { codeKey: "L063", sigla: "RC", provincia: "TAURIANOVA", nazione: "ITALIA" },
  { codeKey: "L064", sigla: "LE", provincia: "TAURISANO", nazione: "ITALIA" },
  { codeKey: "L065", sigla: "UD", provincia: "TAVAGNACCO", nazione: "ITALIA" },
  { codeKey: "L066", sigla: "TO", provincia: "TAVAGNASCO", nazione: "ITALIA" },
  {
    codeKey: "L067",
    sigla: "FI",
    provincia: "TAVARNELLE VAL DI PESA",
    nazione: "ITALIA",
  },
  { codeKey: "L069", sigla: "CB", provincia: "TAVENNA", nazione: "ITALIA" },
  { codeKey: "L070", sigla: "CZ", provincia: "TAVERNA", nazione: "ITALIA" },
  { codeKey: "L071", sigla: "CO", provincia: "TAVERNERIO", nazione: "ITALIA" },
  {
    codeKey: "L073",
    sigla: "BG",
    provincia: "TAVERNOLA BERGAMASCA",
    nazione: "ITALIA",
  },
  { codeKey: "L074", sigla: "LE", provincia: "TAVIANO", nazione: "ITALIA" },
  { codeKey: "L075", sigla: "BI", provincia: "TAVIGLIANO", nazione: "ITALIA" },
  { codeKey: "L078", sigla: "PU", provincia: "TAVOLETO", nazione: "ITALIA" },
  { codeKey: "L081", sigla: "PU", provincia: "TAVULLIA", nazione: "ITALIA" },
  { codeKey: "L082", sigla: "PZ", provincia: "TEANA", nazione: "ITALIA" },
  { codeKey: "L083", sigla: "CE", provincia: "TEANO", nazione: "ITALIA" },
  { codeKey: "L084", sigla: "SO", provincia: "TEGLIO", nazione: "ITALIA" },
  { codeKey: "L085", sigla: "VE", provincia: "TEGLIO VENETO", nazione: "ITALIA" },
  { codeKey: "L086", sigla: "BN", provincia: "TELESE TERME", nazione: "ITALIA" },
  { codeKey: "L087", sigla: "BG", provincia: "TELGATE", nazione: "ITALIA" },
  { codeKey: "L088", sigla: "OT", provincia: "TELTI", nazione: "ITALIA" },
  { codeKey: "L089", sigla: "TN", provincia: "TELVE", nazione: "ITALIA" },
  { codeKey: "L090", sigla: "TN", provincia: "TELVE DI SOPRA", nazione: "ITALIA" },
  { codeKey: "L093", sigla: "OT", provincia: "TEMPIO PAUSANIA", nazione: "ITALIA" },
  { codeKey: "L094", sigla: "BS", provincia: "TEMU'", nazione: "ITALIA" },
  { codeKey: "L096", sigla: "TN", provincia: "TENNA", nazione: "ITALIA" },
  { codeKey: "L097", sigla: "TN", provincia: "TENNO", nazione: "ITALIA" },
  { codeKey: "L100", sigla: "PD", provincia: "TEOLO", nazione: "ITALIA" },
  { codeKey: "L102", sigla: "AV", provincia: "TEORA", nazione: "ITALIA" },
  { codeKey: "L103", sigla: "TE", provincia: "TERAMO", nazione: "ITALIA" },
  { codeKey: "L104", sigla: "NO", provincia: "TERDOBBIATE", nazione: "ITALIA" },
  { codeKey: "L105", sigla: "FR", provincia: "TERELLE", nazione: "ITALIA" },
  { codeKey: "L106", sigla: "BZ", provincia: "TERENTO", nazione: "ITALIA" },
  { codeKey: "L107", sigla: "TN", provincia: "TERLAGO", nazione: "ITALIA" },
  { codeKey: "L108", sigla: "BZ", provincia: "TERLANO", nazione: "ITALIA" },
  { codeKey: "L109", sigla: "BA", provincia: "TERLIZZI", nazione: "ITALIA" },
  {
    codeKey: "L111",
    sigla: "BZ",
    provincia: "TERMENO SULLA STRADA DEL VINO",
    nazione: "ITALIA",
  },
  { codeKey: "L112", sigla: "PA", provincia: "TERMINI IMERESE", nazione: "ITALIA" },
  { codeKey: "L113", sigla: "CB", provincia: "TERMOLI", nazione: "ITALIA" },
  { codeKey: "L115", sigla: "VA", provincia: "TERNATE", nazione: "ITALIA" },
  { codeKey: "L116", sigla: "BI", provincia: "TERNENGO", nazione: "ITALIA" },
  { codeKey: "L117", sigla: "TR", provincia: "TERNI", nazione: "ITALIA" },
  { codeKey: "L118", sigla: "BG", provincia: "TERNO D'ISOLA", nazione: "ITALIA" },
  { codeKey: "L120", sigla: "LT", provincia: "TERRACINA", nazione: "ITALIA" },
  { codeKey: "L121", sigla: "TN", provincia: "TERRAGNOLO", nazione: "ITALIA" },
  { codeKey: "L122", sigla: "OR", provincia: "TERRALBA", nazione: "ITALIA" },
  {
    codeKey: "L123",
    sigla: "AR",
    provincia: "TERRANUOVA BRACCIOLINI",
    nazione: "ITALIA",
  },
  {
    codeKey: "L124",
    sigla: "CS",
    provincia: "TERRANOVA DA SIBARI",
    nazione: "ITALIA",
  },
  {
    codeKey: "L125",
    sigla: "LO",
    provincia: "TERRANOVA DEI PASSERINI",
    nazione: "ITALIA",
  },
  {
    codeKey: "L126",
    sigla: "PZ",
    provincia: "TERRANOVA DI POLLINO",
    nazione: "ITALIA",
  },
  {
    codeKey: "L127",
    sigla: "RC",
    provincia: "TERRANOVA SAPPO MINULIO",
    nazione: "ITALIA",
  },
  { codeKey: "L131", sigla: "PA", provincia: "TERRASINI", nazione: "ITALIA" },
  {
    codeKey: "L132",
    sigla: "PD",
    provincia: "TERRASSA PADOVANA",
    nazione: "ITALIA",
  },
  { codeKey: "L134", sigla: "CS", provincia: "TERRAVECCHIA", nazione: "ITALIA" },
  { codeKey: "L136", sigla: "VR", provincia: "TERRAZZO", nazione: "ITALIA" },
  { codeKey: "L137", sigla: "TN", provincia: "TERRES", nazione: "ITALIA" },
  { codeKey: "L138", sigla: "PI", provincia: "TERRICCIOLA", nazione: "ITALIA" },
  { codeKey: "L139", sigla: "AL", provincia: "TERRUGGIA", nazione: "ITALIA" },
  { codeKey: "L140", sigla: "OG", provincia: "TERTENIA", nazione: "ITALIA" },
  { codeKey: "L142", sigla: "NA", provincia: "TERZIGNO", nazione: "ITALIA" },
  { codeKey: "L143", sigla: "AL", provincia: "TERZO", nazione: "ITALIA" },
  {
    codeKey: "L144",
    sigla: "UD",
    provincia: "TERZO DI AQUILEIA",
    nazione: "ITALIA",
  },
  { codeKey: "L145", sigla: "TN", provincia: "TERZOLAS", nazione: "ITALIA" },
  { codeKey: "L146", sigla: "IM", provincia: "TERZORIO", nazione: "ITALIA" },
  { codeKey: "L147", sigla: "TN", provincia: "TESERO", nazione: "ITALIA" },
  { codeKey: "L149", sigla: "BZ", provincia: "TESIMO", nazione: "ITALIA" },
  { codeKey: "L150", sigla: "VT", provincia: "TESSENNANO", nazione: "ITALIA" },
  { codeKey: "L152", sigla: "SV", provincia: "TESTICO", nazione: "ITALIA" },
  { codeKey: "L153", sigla: "NU", provincia: "TETI", nazione: "ITALIA" },
  { codeKey: "L154", sigla: "CA", provincia: "TEULADA", nazione: "ITALIA" },
  { codeKey: "L155", sigla: "CE", provincia: "TEVEROLA", nazione: "ITALIA" },
  { codeKey: "L156", sigla: "VI", provincia: "TEZZE SUL BRENTA", nazione: "ITALIA" },
  { codeKey: "L157", sigla: "VI", provincia: "THIENE", nazione: "ITALIA" },
  { codeKey: "L158", sigla: "SS", provincia: "THIESI", nazione: "ITALIA" },
  { codeKey: "L160", sigla: "NU", provincia: "TIANA", nazione: "ITALIA" },
  { codeKey: "L164", sigla: "CR", provincia: "TICENGO", nazione: "ITALIA" },
  { codeKey: "L165", sigla: "AL", provincia: "TICINETO", nazione: "ITALIA" },
  { codeKey: "L166", sigla: "LE", provincia: "TIGGIANO", nazione: "ITALIA" },
  { codeKey: "L167", sigla: "GE", provincia: "TIGLIETO", nazione: "ITALIA" },
  { codeKey: "L168", sigla: "AT", provincia: "TIGLIOLE", nazione: "ITALIA" },
  { codeKey: "L169", sigla: "BS", provincia: "TIGNALE", nazione: "ITALIA" },
  { codeKey: "L172", sigla: "NU", provincia: "TINNURA", nazione: "ITALIA" },
  {
    codeKey: "L173",
    sigla: "AQ",
    provincia: "TIONE DEGLI ABRUZZI",
    nazione: "ITALIA",
  },
  { codeKey: "L174", sigla: "TN", provincia: "TIONE DI TRENTO", nazione: "ITALIA" },
  { codeKey: "L175", sigla: "SO", provincia: "TIRANO", nazione: "ITALIA" },
  { codeKey: "L176", sigla: "BZ", provincia: "TIRES", nazione: "ITALIA" },
  { codeKey: "L177", sigla: "CZ", provincia: "TIRIOLO", nazione: "ITALIA" },
  { codeKey: "L178", sigla: "BZ", provincia: "TIROLO", nazione: "ITALIA" },
  { codeKey: "L180", sigla: "SS", provincia: "TISSI", nazione: "ITALIA" },
  { codeKey: "L181", sigla: "PZ", provincia: "TITO", nazione: "ITALIA" },
  { codeKey: "L182", sigla: "RM", provincia: "TIVOLI", nazione: "ITALIA" },
  {
    codeKey: "L183",
    sigla: "PR",
    provincia: "TIZZANO VAL PARMA",
    nazione: "ITALIA",
  },
  { codeKey: "L184", sigla: "RE", provincia: "TOANO", nazione: "ITALIA" },
  { codeKey: "L185", sigla: "BN", provincia: "TOCCO CAUDIO", nazione: "ITALIA" },
  {
    codeKey: "L186",
    sigla: "PE",
    provincia: "TOCCO DA CASAURIA",
    nazione: "ITALIA",
  },
  { codeKey: "L187", sigla: "VB", provincia: "TOCENO", nazione: "ITALIA" },
  { codeKey: "L188", sigla: "PG", provincia: "TODI", nazione: "ITALIA" },
  { codeKey: "L189", sigla: "RI", provincia: "TOFFIA", nazione: "ITALIA" },
  { codeKey: "L190", sigla: "SV", provincia: "TOIRANO", nazione: "ITALIA" },
  { codeKey: "L191", sigla: "MC", provincia: "TOLENTINO", nazione: "ITALIA" },
  { codeKey: "L192", sigla: "RM", provincia: "TOLFA", nazione: "ITALIA" },
  { codeKey: "L193", sigla: "BI", provincia: "TOLLEGNO", nazione: "ITALIA" },
  { codeKey: "L194", sigla: "CH", provincia: "TOLLO", nazione: "ITALIA" },
  { codeKey: "L195", sigla: "UD", provincia: "TOLMEZZO", nazione: "ITALIA" },
  { codeKey: "L197", sigla: "PZ", provincia: "TOLVE", nazione: "ITALIA" },
  { codeKey: "L199", sigla: "PD", provincia: "TOMBOLO", nazione: "ITALIA" },
  { codeKey: "L200", sigla: "TN", provincia: "TON", nazione: "ITALIA" },
  { codeKey: "L201", sigla: "TN", provincia: "TONADICO", nazione: "ITALIA" },
  { codeKey: "L202", sigla: "NU", provincia: "TONARA", nazione: "ITALIA" },
  { codeKey: "L203", sigla: "AT", provincia: "TONCO", nazione: "ITALIA" },
  { codeKey: "L204", sigla: "AT", provincia: "TONENGO", nazione: "ITALIA" },
  { codeKey: "L205", sigla: "CE", provincia: "TORA E PICCILLI", nazione: "ITALIA" },
  { codeKey: "L206", sigla: "CS", provincia: "TORANO CASTELLO", nazione: "ITALIA" },
  { codeKey: "L207", sigla: "TE", provincia: "TORANO NUOVO", nazione: "ITALIA" },
  { codeKey: "L210", sigla: "BS", provincia: "TORBOLE CASAGLIA", nazione: "ITALIA" },
  { codeKey: "L211", sigla: "TN", provincia: "TORCEGNO", nazione: "ITALIA" },
  { codeKey: "L212", sigla: "SA", provincia: "TORCHIARA", nazione: "ITALIA" },
  { codeKey: "L213", sigla: "BR", provincia: "TORCHIAROLO", nazione: "ITALIA" },
  {
    codeKey: "L214",
    sigla: "AV",
    provincia: "TORELLA DEI LOMBARDI",
    nazione: "ITALIA",
  },
  {
    codeKey: "L215",
    sigla: "CB",
    provincia: "TORELLA DEL SANNIO",
    nazione: "ITALIA",
  },
  { codeKey: "L216", sigla: "PG", provincia: "TORGIANO", nazione: "ITALIA" },
  { codeKey: "L217", sigla: "AO", provincia: "TORGNON", nazione: "ITALIA" },
  { codeKey: "L218", sigla: "CH", provincia: "TORINO DI SANGRO", nazione: "ITALIA" },
  { codeKey: "L219", sigla: "TO", provincia: "TORINO", nazione: "ITALIA" },
  { codeKey: "L220", sigla: "BA", provincia: "TORITTO", nazione: "ITALIA" },
  {
    codeKey: "L221",
    sigla: "CR",
    provincia: "TORLINO VIMERCATI",
    nazione: "ITALIA",
  },
  { codeKey: "L223", sigla: "NO", provincia: "TORNACO", nazione: "ITALIA" },
  { codeKey: "L224", sigla: "CH", provincia: "TORNARECCIO", nazione: "ITALIA" },
  { codeKey: "L225", sigla: "CR", provincia: "TORNATA", nazione: "ITALIA" },
  { codeKey: "L227", sigla: "AQ", provincia: "TORNIMPARTE", nazione: "ITALIA" },
  { codeKey: "L228", sigla: "CO", provincia: "TORNO", nazione: "ITALIA" },
  { codeKey: "L229", sigla: "PR", provincia: "TORNOLO", nazione: "ITALIA" },
  { codeKey: "L230", sigla: "CB", provincia: "TORO", nazione: "ITALIA" },
  { codeKey: "L231", sigla: "NU", provincia: "TORPE'", nazione: "ITALIA" },
  { codeKey: "L233", sigla: "SA", provincia: "TORRACA", nazione: "ITALIA" },
  { codeKey: "L235", sigla: "SS", provincia: "TORRALBA", nazione: "ITALIA" },
  { codeKey: "L237", sigla: "PV", provincia: "TORRAZZA COSTE", nazione: "ITALIA" },
  {
    codeKey: "L238",
    sigla: "TO",
    provincia: "TORRAZZA PIEMONTE",
    nazione: "ITALIA",
  },
  { codeKey: "L239", sigla: "BI", provincia: "TORRAZZO", nazione: "ITALIA" },
  {
    codeKey: "L240",
    sigla: "CZ",
    provincia: "TORRE DI RUGGIERO",
    nazione: "ITALIA",
  },
  { codeKey: "L241", sigla: "CN", provincia: "TORRE MONDOVI'", nazione: "ITALIA" },
  { codeKey: "L243", sigla: "FR", provincia: "TORRE CAJETANI", nazione: "ITALIA" },
  {
    codeKey: "L244",
    sigla: "SO",
    provincia: "TORRE DI SANTA MARIA",
    nazione: "ITALIA",
  },
  { codeKey: "L245", sigla: "NA", provincia: "TORRE ANNUNZIATA", nazione: "ITALIA" },
  { codeKey: "L246", sigla: "UD", provincia: "TORREANO", nazione: "ITALIA" },
  { codeKey: "L247", sigla: "TO", provincia: "TORRE CANAVESE", nazione: "ITALIA" },
  { codeKey: "L248", sigla: "VI", provincia: "TORREBELVICINO", nazione: "ITALIA" },
  {
    codeKey: "L250",
    sigla: "PV",
    provincia: "TORRE BERETTI E CASTELLARO",
    nazione: "ITALIA",
  },
  { codeKey: "L251", sigla: "BG", provincia: "TORRE BOLDONE", nazione: "ITALIA" },
  { codeKey: "L252", sigla: "CN", provincia: "TORRE BORMIDA", nazione: "ITALIA" },
  { codeKey: "L253", sigla: "CH", provincia: "TORREBRUNA", nazione: "ITALIA" },
  { codeKey: "L254", sigla: "BN", provincia: "TORRECUSO", nazione: "ITALIA" },
  { codeKey: "L256", sigla: "PV", provincia: "TORRE D'ARESE", nazione: "ITALIA" },
  { codeKey: "L257", sigla: "LC", provincia: "TORRE DE' BUSI", nazione: "ITALIA" },
  {
    codeKey: "L258",
    sigla: "CR",
    provincia: "TORRE DE' PICENARDI",
    nazione: "ITALIA",
  },
  { codeKey: "L259", sigla: "NA", provincia: "TORRE DEL GRECO", nazione: "ITALIA" },
  { codeKey: "L262", sigla: "PV", provincia: "TORRE DE' NEGRI", nazione: "ITALIA" },
  {
    codeKey: "L263",
    sigla: "PE",
    provincia: "TORRE DE' PASSERI",
    nazione: "ITALIA",
  },
  { codeKey: "L265", sigla: "BG", provincia: "TORRE DE' ROVERI", nazione: "ITALIA" },
  { codeKey: "L267", sigla: "VE", provincia: "TORRE DI MOSTO", nazione: "ITALIA" },
  { codeKey: "L269", sigla: "PV", provincia: "TORRE D'ISOLA", nazione: "ITALIA" },
  { codeKey: "L270", sigla: "PD", provincia: "TORREGLIA", nazione: "ITALIA" },
  { codeKey: "L271", sigla: "ME", provincia: "TORREGROTTA", nazione: "ITALIA" },
  { codeKey: "L272", sigla: "AV", provincia: "TORRE LE NOCELLE", nazione: "ITALIA" },
  { codeKey: "L273", sigla: "FG", provincia: "TORREMAGGIORE", nazione: "ITALIA" },
  { codeKey: "L274", sigla: "SA", provincia: "TORRE ORSAIA", nazione: "ITALIA" },
  {
    codeKey: "L276",
    sigla: "BG",
    provincia: "TORRE PALLAVICINA",
    nazione: "ITALIA",
  },
  { codeKey: "L277", sigla: "TO", provincia: "TORRE PELLICE", nazione: "ITALIA" },
  {
    codeKey: "L278",
    sigla: "CN",
    provincia: "TORRE SAN GIORGIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "L279",
    sigla: "FM",
    provincia: "TORRE SAN PATRIZIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "L280",
    sigla: "BR",
    provincia: "TORRE SANTA SUSANNA",
    nazione: "ITALIA",
  },
  { codeKey: "L281", sigla: "CN", provincia: "TORRESINA", nazione: "ITALIA" },
  { codeKey: "L282", sigla: "PA", provincia: "TORRETTA", nazione: "ITALIA" },
  {
    codeKey: "L284",
    sigla: "CH",
    provincia: "TORREVECCHIA TEATINA",
    nazione: "ITALIA",
  },
  { codeKey: "L285", sigla: "PV", provincia: "TORREVECCHIA PIA", nazione: "ITALIA" },
  { codeKey: "L286", sigla: "RI", provincia: "TORRI IN SABINA", nazione: "ITALIA" },
  { codeKey: "L287", sigla: "VR", provincia: "TORRI DEL BENACO", nazione: "ITALIA" },
  { codeKey: "L290", sigla: "FR", provincia: "TORRICE", nazione: "ITALIA" },
  {
    codeKey: "L291",
    sigla: "CH",
    provincia: "TORRICELLA PELIGNA",
    nazione: "ITALIA",
  },
  {
    codeKey: "L292",
    sigla: "PV",
    provincia: "TORRICELLA VERZATE",
    nazione: "ITALIA",
  },
  {
    codeKey: "L293",
    sigla: "RI",
    provincia: "TORRICELLA IN SABINA",
    nazione: "ITALIA",
  },
  { codeKey: "L294", sigla: "TA", provincia: "TORRICELLA", nazione: "ITALIA" },
  {
    codeKey: "L295",
    sigla: "TE",
    provincia: "TORRICELLA SICURA",
    nazione: "ITALIA",
  },
  {
    codeKey: "L296",
    sigla: "CR",
    provincia: "TORRICELLA DEL PIZZO",
    nazione: "ITALIA",
  },
  {
    codeKey: "L297",
    sigla: "VI",
    provincia: "TORRI DI QUARTESOLO",
    nazione: "ITALIA",
  },
  { codeKey: "L298", sigla: "GE", provincia: "TORRIGLIA", nazione: "ITALIA" },
  { codeKey: "L299", sigla: "PR", provincia: "TORRILE", nazione: "ITALIA" },
  { codeKey: "L301", sigla: "AV", provincia: "TORRIONI", nazione: "ITALIA" },
  { codeKey: "L302", sigla: "RM", provincia: "TORRITA TIBERINA", nazione: "ITALIA" },
  { codeKey: "L303", sigla: "SI", provincia: "TORRITA DI SIENA", nazione: "ITALIA" },
  { codeKey: "L304", sigla: "AL", provincia: "TORTONA", nazione: "ITALIA" },
  { codeKey: "L305", sigla: "CS", provincia: "TORTORA", nazione: "ITALIA" },
  { codeKey: "L306", sigla: "SA", provincia: "TORTORELLA", nazione: "ITALIA" },
  { codeKey: "L307", sigla: "TE", provincia: "TORTORETO", nazione: "ITALIA" },
  { codeKey: "L308", sigla: "ME", provincia: "TORTORICI", nazione: "ITALIA" },
  { codeKey: "L309", sigla: "UD", provincia: "TORVISCOSA", nazione: "ITALIA" },
  { codeKey: "L310", sigla: "VT", provincia: "TUSCANIA", nazione: "ITALIA" },
  {
    codeKey: "L312",
    sigla: "BS",
    provincia: "TOSCOLANO-MADERNO",
    nazione: "ITALIA",
  },
  { codeKey: "L314", sigla: "TE", provincia: "TOSSICIA", nazione: "ITALIA" },
  { codeKey: "L315", sigla: "SV", provincia: "TOVO SAN GIACOMO", nazione: "ITALIA" },
  {
    codeKey: "L316",
    sigla: "SO",
    provincia: "TOVO DI SANT'AGATA",
    nazione: "ITALIA",
  },
  { codeKey: "L317", sigla: "PA", provincia: "TRABIA", nazione: "ITALIA" },
  { codeKey: "L319", sigla: "VA", provincia: "TRADATE", nazione: "ITALIA" },
  { codeKey: "L321", sigla: "OR", provincia: "TRAMATZA", nazione: "ITALIA" },
  { codeKey: "L322", sigla: "TN", provincia: "TRAMBILENO", nazione: "ITALIA" },
  { codeKey: "L323", sigla: "SA", provincia: "TRAMONTI", nazione: "ITALIA" },
  {
    codeKey: "L324",
    sigla: "PN",
    provincia: "TRAMONTI DI SOPRA",
    nazione: "ITALIA",
  },
  {
    codeKey: "L325",
    sigla: "PN",
    provincia: "TRAMONTI DI SOTTO",
    nazione: "ITALIA",
  },
  { codeKey: "L326", sigla: "PZ", provincia: "TRAMUTOLA", nazione: "ITALIA" },
  { codeKey: "L327", sigla: "TO", provincia: "TRANA", nazione: "ITALIA" },
  { codeKey: "L328", sigla: "BT", provincia: "TRANI", nazione: "ITALIA" },
  { codeKey: "L329", sigla: "TN", provincia: "TRANSACQUA", nazione: "ITALIA" },
  { codeKey: "L330", sigla: "SO", provincia: "TRAONA", nazione: "ITALIA" },
  { codeKey: "L331", sigla: "TP", provincia: "TRAPANI", nazione: "ITALIA" },
  { codeKey: "L332", sigla: "PA", provincia: "TRAPPETO", nazione: "ITALIA" },
  { codeKey: "L333", sigla: "VB", provincia: "TRAREGO VIGGIONA", nazione: "ITALIA" },
  { codeKey: "L334", sigla: "AQ", provincia: "TRASACCO", nazione: "ITALIA" },
  { codeKey: "L335", sigla: "UD", provincia: "TRASAGHIS", nazione: "ITALIA" },
  { codeKey: "L336", sigla: "VB", provincia: "TRASQUERA", nazione: "ITALIA" },
  { codeKey: "L337", sigla: "CI", provincia: "TRATALIAS", nazione: "ITALIA" },
  { codeKey: "L338", sigla: "TO", provincia: "TRAUSELLA", nazione: "ITALIA" },
  { codeKey: "L339", sigla: "BS", provincia: "TRAVAGLIATO", nazione: "ITALIA" },
  { codeKey: "L340", sigla: "TO", provincia: "TRAVES", nazione: "ITALIA" },
  { codeKey: "L342", sigla: "VA", provincia: "TRAVEDONA-MONATE", nazione: "ITALIA" },
  { codeKey: "L345", sigla: "TO", provincia: "TRAVERSELLA", nazione: "ITALIA" },
  { codeKey: "L346", sigla: "PR", provincia: "TRAVERSETOLO", nazione: "ITALIA" },
  { codeKey: "L347", sigla: "PN", provincia: "TRAVESIO", nazione: "ITALIA" },
  { codeKey: "L348", sigla: "PC", provincia: "TRAVO", nazione: "ITALIA" },
  { codeKey: "L349", sigla: "PD", provincia: "TREBASELEGHE", nazione: "ITALIA" },
  { codeKey: "L353", sigla: "CS", provincia: "TREBISACCE", nazione: "ITALIA" },
  { codeKey: "L355", sigla: "CT", provincia: "TRECASTAGNI", nazione: "ITALIA" },
  { codeKey: "L356", sigla: "NO", provincia: "TRECATE", nazione: "ITALIA" },
  { codeKey: "L357", sigla: "PZ", provincia: "TRECCHINA", nazione: "ITALIA" },
  { codeKey: "L359", sigla: "RO", provincia: "TRECENTA", nazione: "ITALIA" },
  { codeKey: "L361", sigla: "FC", provincia: "TREDOZIO", nazione: "ITALIA" },
  { codeKey: "L363", sigla: "CH", provincia: "TREGLIO", nazione: "ITALIA" },
  { codeKey: "L364", sigla: "VR", provincia: "TREGNAGO", nazione: "ITALIA" },
  { codeKey: "L366", sigla: "MC", provincia: "TREIA", nazione: "ITALIA" },
  { codeKey: "L367", sigla: "CN", provincia: "TREISO", nazione: "ITALIA" },
  { codeKey: "L368", sigla: "LC", provincia: "TREMENICO", nazione: "ITALIA" },
  {
    codeKey: "L369",
    sigla: "CT",
    provincia: "TREMESTIERI ETNEO",
    nazione: "ITALIA",
  },
  {
    codeKey: "L372",
    sigla: "BS",
    provincia: "TREMOSINE SUL GARDA",
    nazione: "ITALIA",
  },
  { codeKey: "L375", sigla: "CS", provincia: "TRENTA", nazione: "ITALIA" },
  { codeKey: "L377", sigla: "SA", provincia: "TRENTINARA", nazione: "ITALIA" },
  { codeKey: "L378", sigla: "TN", provincia: "TRENTO", nazione: "ITALIA" },
  { codeKey: "L379", sigla: "CE", provincia: "TRENTOLA DUCENTA", nazione: "ITALIA" },
  { codeKey: "L380", sigla: "BS", provincia: "TRENZANO", nazione: "ITALIA" },
  { codeKey: "L381", sigla: "UD", provincia: "TREPPO CARNICO", nazione: "ITALIA" },
  { codeKey: "L382", sigla: "UD", provincia: "TREPPO GRANDE", nazione: "ITALIA" },
  { codeKey: "L383", sigla: "LE", provincia: "TREPUZZI", nazione: "ITALIA" },
  { codeKey: "L384", sigla: "SI", provincia: "TREQUANDA", nazione: "ITALIA" },
  { codeKey: "L386", sigla: "MS", provincia: "TRESANA", nazione: "ITALIA" },
  {
    codeKey: "L388",
    sigla: "BG",
    provincia: "TRESCORE BALNEARIO",
    nazione: "ITALIA",
  },
  {
    codeKey: "L389",
    sigla: "CR",
    provincia: "TRESCORE CREMASCO",
    nazione: "ITALIA",
  },
  { codeKey: "L390", sigla: "FE", provincia: "TRESIGALLO", nazione: "ITALIA" },
  { codeKey: "L392", sigla: "SO", provincia: "TRESIVIO", nazione: "ITALIA" },
  { codeKey: "L393", sigla: "OR", provincia: "TRESNURAGHES", nazione: "ITALIA" },
  { codeKey: "L396", sigla: "VR", provincia: "TREVENZUOLO", nazione: "ITALIA" },
  { codeKey: "L397", sigla: "PG", provincia: "TREVI", nazione: "ITALIA" },
  { codeKey: "L398", sigla: "FR", provincia: "TREVI NEL LAZIO", nazione: "ITALIA" },
  { codeKey: "L399", sigla: "AV", provincia: "TREVICO", nazione: "ITALIA" },
  { codeKey: "L400", sigla: "BG", provincia: "TREVIGLIO", nazione: "ITALIA" },
  {
    codeKey: "L401",
    sigla: "RM",
    provincia: "TREVIGNANO ROMANO",
    nazione: "ITALIA",
  },
  { codeKey: "L402", sigla: "TV", provincia: "TREVIGNANO", nazione: "ITALIA" },
  { codeKey: "L403", sigla: "AL", provincia: "TREVILLE", nazione: "ITALIA" },
  { codeKey: "L404", sigla: "BG", provincia: "TREVIOLO", nazione: "ITALIA" },
  {
    codeKey: "L406",
    sigla: "BS",
    provincia: "TREVISO BRESCIANO",
    nazione: "ITALIA",
  },
  { codeKey: "L407", sigla: "TV", provincia: "TREVISO", nazione: "ITALIA" },
  { codeKey: "L408", sigla: "MI", provincia: "TREZZANO ROSA", nazione: "ITALIA" },
  {
    codeKey: "L409",
    sigla: "MI",
    provincia: "TREZZANO SUL NAVIGLIO",
    nazione: "ITALIA",
  },
  { codeKey: "L410", sigla: "CN", provincia: "TREZZO TINELLA", nazione: "ITALIA" },
  { codeKey: "L411", sigla: "MI", provincia: "TREZZO SULL'ADDA", nazione: "ITALIA" },
  { codeKey: "L413", sigla: "CO", provincia: "TREZZONE", nazione: "ITALIA" },
  { codeKey: "L414", sigla: "PD", provincia: "TRIBANO", nazione: "ITALIA" },
  { codeKey: "L415", sigla: "MI", provincia: "TRIBIANO", nazione: "ITALIA" },
  { codeKey: "L416", sigla: "GE", provincia: "TRIBOGNA", nazione: "ITALIA" },
  { codeKey: "L418", sigla: "MT", provincia: "TRICARICO", nazione: "ITALIA" },
  { codeKey: "L419", sigla: "LE", provincia: "TRICASE", nazione: "ITALIA" },
  { codeKey: "L420", sigla: "VC", provincia: "TRICERRO", nazione: "ITALIA" },
  { codeKey: "L421", sigla: "UD", provincia: "TRICESIMO", nazione: "ITALIA" },
  { codeKey: "L422", sigla: "BL", provincia: "TRICHIANA", nazione: "ITALIA" },
  { codeKey: "L423", sigla: "OG", provincia: "TRIEI", nazione: "ITALIA" },
  { codeKey: "L424", sigla: "TS", provincia: "TRIESTE", nazione: "ITALIA" },
  { codeKey: "L425", sigla: "BA", provincia: "TRIGGIANO", nazione: "ITALIA" },
  { codeKey: "L426", sigla: "CR", provincia: "TRIGOLO", nazione: "ITALIA" },
  { codeKey: "L427", sigla: "CN", provincia: "TRINITA'", nazione: "ITALIA" },
  {
    codeKey: "L428",
    sigla: "OT",
    provincia: "TRINITA' D'AGULTU E VIGNOLA",
    nazione: "ITALIA",
  },
  { codeKey: "L429", sigla: "VC", provincia: "TRINO VERCELLESE", nazione: "ITALIA" },
  { codeKey: "L430", sigla: "IM", provincia: "TRIORA", nazione: "ITALIA" },
  { codeKey: "L431", sigla: "ME", provincia: "TRIPI", nazione: "ITALIA" },
  { codeKey: "L432", sigla: "AL", provincia: "TRISOBBIO", nazione: "ITALIA" },
  { codeKey: "L433", sigla: "VI", provincia: "TRISSINO", nazione: "ITALIA" },
  { codeKey: "L434", sigla: "MB", provincia: "TRIUGGIO", nazione: "ITALIA" },
  { codeKey: "L435", sigla: "CB", provincia: "TRIVENTO", nazione: "ITALIA" },
  { codeKey: "L436", sigla: "BI", provincia: "TRIVERO", nazione: "ITALIA" },
  { codeKey: "L437", sigla: "FR", provincia: "TRIVIGLIANO", nazione: "ITALIA" },
  {
    codeKey: "L438",
    sigla: "UD",
    provincia: "TRIVIGNANO UDINESE",
    nazione: "ITALIA",
  },
  { codeKey: "L439", sigla: "PZ", provincia: "TRIVIGNO", nazione: "ITALIA" },
  { codeKey: "L440", sigla: "PV", provincia: "TRIVOLZIO", nazione: "ITALIA" },
  {
    codeKey: "L444",
    sigla: "BZ",
    provincia: "TRODENA NEL PARCO NATURALE",
    nazione: "ITALIA",
  },
  { codeKey: "L445", sigla: "TO", provincia: "TROFARELLO", nazione: "ITALIA" },
  { codeKey: "L447", sigla: "FG", provincia: "TROIA", nazione: "ITALIA" },
  { codeKey: "L448", sigla: "EN", provincia: "TROINA", nazione: "ITALIA" },
  { codeKey: "L449", sigla: "PV", provincia: "TROMELLO", nazione: "ITALIA" },
  { codeKey: "L450", sigla: "VB", provincia: "TRONTANO", nazione: "ITALIA" },
  {
    codeKey: "L451",
    sigla: "VC",
    provincia: "TRONZANO VERCELLESE",
    nazione: "ITALIA",
  },
  { codeKey: "L452", sigla: "VV", provincia: "TROPEA", nazione: "ITALIA" },
  { codeKey: "L453", sigla: "PV", provincia: "TROVO", nazione: "ITALIA" },
  { codeKey: "L454", sigla: "MI", provincia: "TRUCCAZZANO", nazione: "ITALIA" },
  { codeKey: "L455", sigla: "BZ", provincia: "TUBRE", nazione: "ITALIA" },
  { codeKey: "L457", sigla: "TN", provincia: "TUENNO", nazione: "ITALIA" },
  { codeKey: "L458", sigla: "CB", provincia: "TUFARA", nazione: "ITALIA" },
  { codeKey: "L459", sigla: "CH", provincia: "TUFILLO", nazione: "ITALIA" },
  { codeKey: "L460", sigla: "NA", provincia: "TUFINO", nazione: "ITALIA" },
  { codeKey: "L461", sigla: "AV", provincia: "TUFO", nazione: "ITALIA" },
  { codeKey: "L462", sigla: "LE", provincia: "TUGLIE", nazione: "ITALIA" },
  { codeKey: "L463", sigla: "VS", provincia: "TUILI", nazione: "ITALIA" },
  { codeKey: "L464", sigla: "SS", provincia: "TULA", nazione: "ITALIA" },
  {
    codeKey: "L466",
    sigla: "PG",
    provincia: "TUORO SUL TRASIMENO",
    nazione: "ITALIA",
  },
  { codeKey: "L468", sigla: "BS", provincia: "VALVESTINO", nazione: "ITALIA" },
  { codeKey: "L469", sigla: "LO", provincia: "TURANO LODIGIANO", nazione: "ITALIA" },
  { codeKey: "L470", sigla: "CO", provincia: "TURATE", nazione: "ITALIA" },
  { codeKey: "L471", sigla: "MI", provincia: "TURBIGO", nazione: "ITALIA" },
  { codeKey: "L472", sigla: "BA", provincia: "TURI", nazione: "ITALIA" },
  { codeKey: "L473", sigla: "VS", provincia: "TURRI", nazione: "ITALIA" },
  { codeKey: "L474", sigla: "GO", provincia: "TURRIACO", nazione: "ITALIA" },
  { codeKey: "L475", sigla: "PE", provincia: "TURRIVALIGNANI", nazione: "ITALIA" },
  { codeKey: "L477", sigla: "MT", provincia: "TURSI", nazione: "ITALIA" },
  { codeKey: "L478", sigla: "ME", provincia: "TUSA", nazione: "ITALIA" },
  { codeKey: "L480", sigla: "VA", provincia: "UBOLDO", nazione: "ITALIA" },
  { codeKey: "L482", sigla: "ME", provincia: "UCRIA", nazione: "ITALIA" },
  { codeKey: "L483", sigla: "UD", provincia: "UDINE", nazione: "ITALIA" },
  { codeKey: "L484", sigla: "LE", provincia: "UGENTO", nazione: "ITALIA" },
  {
    codeKey: "L485",
    sigla: "LE",
    provincia: "UGGIANO LA CHIESA",
    nazione: "ITALIA",
  },
  { codeKey: "L487", sigla: "CO", provincia: "UGGIATE-TREVANO", nazione: "ITALIA" },
  { codeKey: "L488", sigla: "OR", provincia: "ULA' TIRSO", nazione: "ITALIA" },
  { codeKey: "L489", sigla: "OG", provincia: "ULASSAI", nazione: "ITALIA" },
  { codeKey: "L490", sigla: "BZ", provincia: "ULTIMO", nazione: "ITALIA" },
  { codeKey: "L492", sigla: "KR", provincia: "UMBRIATICO", nazione: "ITALIA" },
  { codeKey: "L494", sigla: "BS", provincia: "URAGO D'OGLIO", nazione: "ITALIA" },
  { codeKey: "L496", sigla: "OR", provincia: "URAS", nazione: "ITALIA" },
  { codeKey: "L497", sigla: "PD", provincia: "URBANA", nazione: "ITALIA" },
  { codeKey: "L498", sigla: "PU", provincia: "URBANIA", nazione: "ITALIA" },
  { codeKey: "L499", sigla: "SV", provincia: "URBE", nazione: "ITALIA" },
  { codeKey: "L500", sigla: "PU", provincia: "URBINO", nazione: "ITALIA" },
  { codeKey: "L501", sigla: "MC", provincia: "URBISAGLIA", nazione: "ITALIA" },
  { codeKey: "L502", sigla: "BG", provincia: "URGNANO", nazione: "ITALIA" },
  { codeKey: "L503", sigla: "SS", provincia: "URI", nazione: "ITALIA" },
  { codeKey: "L505", sigla: "CB", provincia: "URURI", nazione: "ITALIA" },
  { codeKey: "L506", sigla: "OG", provincia: "URZULEI", nazione: "ITALIA" },
  { codeKey: "L507", sigla: "GE", provincia: "USCIO", nazione: "ITALIA" },
  { codeKey: "L508", sigla: "OR", provincia: "USELLUS", nazione: "ITALIA" },
  { codeKey: "L509", sigla: "SS", provincia: "USINI", nazione: "ITALIA" },
  { codeKey: "L511", sigla: "MB", provincia: "USMATE VELATE", nazione: "ITALIA" },
  { codeKey: "L512", sigla: "CA", provincia: "USSANA", nazione: "ITALIA" },
  { codeKey: "L513", sigla: "VS", provincia: "USSARAMANNA", nazione: "ITALIA" },
  { codeKey: "L514", sigla: "OG", provincia: "USSASSAI", nazione: "ITALIA" },
  { codeKey: "L515", sigla: "TO", provincia: "USSEAUX", nazione: "ITALIA" },
  { codeKey: "L516", sigla: "TO", provincia: "USSEGLIO", nazione: "ITALIA" },
  { codeKey: "L517", sigla: "MC", provincia: "USSITA", nazione: "ITALIA" },
  { codeKey: "L519", sigla: "PA", provincia: "USTICA", nazione: "ITALIA" },
  { codeKey: "L521", sigla: "CA", provincia: "UTA", nazione: "ITALIA" },
  { codeKey: "L522", sigla: "PT", provincia: "UZZANO", nazione: "ITALIA" },
  {
    codeKey: "L524",
    sigla: "CS",
    provincia: "VACCARIZZO ALBANESE",
    nazione: "ITALIA",
  },
  { codeKey: "L525", sigla: "RI", provincia: "VACONE", nazione: "ITALIA" },
  { codeKey: "L526", sigla: "CH", provincia: "VACRI", nazione: "ITALIA" },
  { codeKey: "L527", sigla: "BZ", provincia: "VADENA", nazione: "ITALIA" },
  { codeKey: "L528", sigla: "SV", provincia: "VADO LIGURE", nazione: "ITALIA" },
  { codeKey: "L529", sigla: "FI", provincia: "VAGLIA", nazione: "ITALIA" },
  { codeKey: "L531", sigla: "AT", provincia: "VAGLIO SERRA", nazione: "ITALIA" },
  {
    codeKey: "L532",
    sigla: "PZ",
    provincia: "VAGLIO BASILICATA",
    nazione: "ITALIA",
  },
  { codeKey: "L533", sigla: "LU", provincia: "VAGLI SOTTO", nazione: "ITALIA" },
  { codeKey: "L535", sigla: "CR", provincia: "VAIANO CREMASCO", nazione: "ITALIA" },
  { codeKey: "L537", sigla: "PO", provincia: "VAIANO", nazione: "ITALIA" },
  { codeKey: "L538", sigla: "TO", provincia: "VAIE", nazione: "ITALIA" },
  { codeKey: "L539", sigla: "CR", provincia: "VAILATE", nazione: "ITALIA" },
  { codeKey: "L540", sigla: "CE", provincia: "VAIRANO PATENORA", nazione: "ITALIA" },
  { codeKey: "L544", sigla: "BG", provincia: "VALBONDIONE", nazione: "ITALIA" },
  { codeKey: "L545", sigla: "BG", provincia: "VALBREMBO", nazione: "ITALIA" },
  { codeKey: "L546", sigla: "GE", provincia: "VALBREVENNA", nazione: "ITALIA" },
  { codeKey: "L547", sigla: "CO", provincia: "VALBRONA", nazione: "ITALIA" },
  { codeKey: "L548", sigla: "TO", provincia: "VICO CANAVESE", nazione: "ITALIA" },
  { codeKey: "L550", sigla: "TN", provincia: "VALDA", nazione: "ITALIA" },
  { codeKey: "L551", sigla: "VI", provincia: "VALDAGNO", nazione: "ITALIA" },
  { codeKey: "L552", sigla: "BZ", provincia: "VALDAORA", nazione: "ITALIA" },
  { codeKey: "L554", sigla: "VI", provincia: "VALDASTICO", nazione: "ITALIA" },
  { codeKey: "L555", sigla: "TO", provincia: "VAL DELLA TORRE", nazione: "ITALIA" },
  { codeKey: "L556", sigla: "BI", provincia: "VALDENGO", nazione: "ITALIA" },
  { codeKey: "L557", sigla: "SO", provincia: "VALDIDENTRO", nazione: "ITALIA" },
  { codeKey: "L558", sigla: "CN", provincia: "VALDIERI", nazione: "ITALIA" },
  { codeKey: "L561", sigla: "ME", provincia: "VALDINA", nazione: "ITALIA" },
  { codeKey: "L562", sigla: "PV", provincia: "VAL DI NIZZA", nazione: "ITALIA" },
  { codeKey: "L563", sigla: "SO", provincia: "VALDISOTTO", nazione: "ITALIA" },
  { codeKey: "L564", sigla: "BZ", provincia: "VAL DI VIZZE", nazione: "ITALIA" },
  { codeKey: "L565", sigla: "TV", provincia: "VALDOBBIADENE", nazione: "ITALIA" },
  { codeKey: "L566", sigla: "VC", provincia: "VALDUGGIA", nazione: "ITALIA" },
  {
    codeKey: "L567",
    sigla: "VR",
    provincia: "VALEGGIO SUL MINCIO",
    nazione: "ITALIA",
  },
  { codeKey: "L568", sigla: "PV", provincia: "VALEGGIO", nazione: "ITALIA" },
  { codeKey: "L569", sigla: "VT", provincia: "VALENTANO", nazione: "ITALIA" },
  { codeKey: "L570", sigla: "AL", provincia: "VALENZA", nazione: "ITALIA" },
  { codeKey: "L571", sigla: "BA", provincia: "VALENZANO", nazione: "ITALIA" },
  { codeKey: "L572", sigla: "LO", provincia: "VALERA FRATTA", nazione: "ITALIA" },
  { codeKey: "L573", sigla: "PG", provincia: "VALFABBRICA", nazione: "ITALIA" },
  { codeKey: "L574", sigla: "AT", provincia: "VALFENERA", nazione: "ITALIA" },
  { codeKey: "L575", sigla: "TN", provincia: "VALFLORIANA", nazione: "ITALIA" },
  { codeKey: "L576", sigla: "SO", provincia: "VALFURVA", nazione: "ITALIA" },
  { codeKey: "L577", sigla: "VA", provincia: "VALGANNA", nazione: "ITALIA" },
  { codeKey: "L578", sigla: "TO", provincia: "VALGIOIE", nazione: "ITALIA" },
  { codeKey: "L579", sigla: "BG", provincia: "VALGOGLIO", nazione: "ITALIA" },
  { codeKey: "L580", sigla: "CN", provincia: "VALGRANA", nazione: "ITALIA" },
  { codeKey: "L581", sigla: "LC", provincia: "VALGREGHENTINO", nazione: "ITALIA" },
  { codeKey: "L582", sigla: "AO", provincia: "VALGRISENCHE", nazione: "ITALIA" },
  {
    codeKey: "L583",
    sigla: "EN",
    provincia: "VALGUARNERA CAROPEPE",
    nazione: "ITALIA",
  },
  { codeKey: "L584", sigla: "BL", provincia: "VALLADA AGORDINA", nazione: "ITALIA" },
  { codeKey: "L586", sigla: "BI", provincia: "VALLANZENGO", nazione: "ITALIA" },
  { codeKey: "L588", sigla: "TN", provincia: "VALLARSA", nazione: "ITALIA" },
  { codeKey: "L589", sigla: "AV", provincia: "VALLATA", nazione: "ITALIA" },
  { codeKey: "L590", sigla: "BL", provincia: "VALLE DI CADORE", nazione: "ITALIA" },
  {
    codeKey: "L591",
    sigla: "CE",
    provincia: "VALLE DI MADDALONI",
    nazione: "ITALIA",
  },
  { codeKey: "L593", sigla: "PV", provincia: "VALLE LOMELLINA", nazione: "ITALIA" },
  { codeKey: "L594", sigla: "CE", provincia: "VALLE AGRICOLA", nazione: "ITALIA" },
  { codeKey: "L595", sigla: "BZ", provincia: "VALLE AURINA", nazione: "ITALIA" },
  { codeKey: "L596", sigla: "IM", provincia: "VALLEBONA", nazione: "ITALIA" },
  { codeKey: "L597", sigla: "TE", provincia: "VALLE CASTELLANA", nazione: "ITALIA" },
  { codeKey: "L598", sigla: "FR", provincia: "VALLECORSA", nazione: "ITALIA" },
  { codeKey: "L599", sigla: "IM", provincia: "VALLECROSIA", nazione: "ITALIA" },
  { codeKey: "L601", sigla: "BZ", provincia: "VALLE DI CASIES", nazione: "ITALIA" },
  { codeKey: "L603", sigla: "PA", provincia: "VALLEDOLMO", nazione: "ITALIA" },
  { codeKey: "L604", sigla: "SS", provincia: "VALLEDORIA", nazione: "ITALIA" },
  { codeKey: "L605", sigla: "FR", provincia: "VALLEMAIO", nazione: "ITALIA" },
  { codeKey: "L606", sigla: "BI", provincia: "VALLE MOSSO", nazione: "ITALIA" },
  { codeKey: "L607", sigla: "VV", provincia: "VALLELONGA", nazione: "ITALIA" },
  {
    codeKey: "L609",
    sigla: "CL",
    provincia: "VALLELUNGA PRATAMENO",
    nazione: "ITALIA",
  },
  { codeKey: "L611", sigla: "RM", provincia: "VALLEPIETRA", nazione: "ITALIA" },
  { codeKey: "L612", sigla: "VT", provincia: "VALLERANO", nazione: "ITALIA" },
  { codeKey: "L613", sigla: "CA", provincia: "VALLERMOSA", nazione: "ITALIA" },
  { codeKey: "L614", sigla: "FR", provincia: "VALLEROTONDA", nazione: "ITALIA" },
  { codeKey: "L616", sigla: "AV", provincia: "VALLESACCARDA", nazione: "ITALIA" },
  { codeKey: "L617", sigla: "PV", provincia: "VALLE SALIMBENE", nazione: "ITALIA" },
  {
    codeKey: "L620",
    sigla: "BI",
    provincia: "VALLE SAN NICOLAO",
    nazione: "ITALIA",
  },
  { codeKey: "L623", sigla: "BG", provincia: "VALLEVE", nazione: "ITALIA" },
  {
    codeKey: "L624",
    sigla: "VI",
    provincia: "VALLI DEL PASUBIO",
    nazione: "ITALIA",
  },
  { codeKey: "L625", sigla: "RM", provincia: "VALLINFREDA", nazione: "ITALIA" },
  { codeKey: "L626", sigla: "BS", provincia: "VALLIO TERME", nazione: "ITALIA" },
  { codeKey: "L627", sigla: "PG", provincia: "VALLO DI NERA", nazione: "ITALIA" },
  {
    codeKey: "L628",
    sigla: "SA",
    provincia: "VALLO DELLA LUCANIA",
    nazione: "ITALIA",
  },
  { codeKey: "L629", sigla: "TO", provincia: "VALLO TORINESE", nazione: "ITALIA" },
  { codeKey: "L631", sigla: "CN", provincia: "VALLORIATE", nazione: "ITALIA" },
  { codeKey: "L633", sigla: "AL", provincia: "VALMACCA", nazione: "ITALIA" },
  { codeKey: "L634", sigla: "LC", provincia: "VALMADRERA", nazione: "ITALIA" },
  { codeKey: "L636", sigla: "CN", provincia: "VALMALA", nazione: "ITALIA" },
  { codeKey: "L638", sigla: "SO", provincia: "VAL MASINO", nazione: "ITALIA" },
  { codeKey: "L639", sigla: "RM", provincia: "VALMONTONE", nazione: "ITALIA" },
  { codeKey: "L640", sigla: "CO", provincia: "VALMOREA", nazione: "ITALIA" },
  { codeKey: "L641", sigla: "PR", provincia: "VALMOZZOLA", nazione: "ITALIA" },
  { codeKey: "L642", sigla: "BG", provincia: "VALNEGRA", nazione: "ITALIA" },
  { codeKey: "L643", sigla: "AO", provincia: "VALPELLINE", nazione: "ITALIA" },
  { codeKey: "L644", sigla: "TO", provincia: "VALPERGA", nazione: "ITALIA" },
  { codeKey: "L647", sigla: "AO", provincia: "VALSAVARENCHE", nazione: "ITALIA" },
  { codeKey: "L650", sigla: "VI", provincia: "VALSTAGNA", nazione: "ITALIA" },
  { codeKey: "L651", sigla: "VB", provincia: "VALSTRONA", nazione: "ITALIA" },
  { codeKey: "L653", sigla: "PG", provincia: "VALTOPINA", nazione: "ITALIA" },
  { codeKey: "L654", sigla: "AO", provincia: "VALTOURNENCHE", nazione: "ITALIA" },
  { codeKey: "L655", sigla: "BG", provincia: "VALTORTA", nazione: "ITALIA" },
  { codeKey: "L656", sigla: "SA", provincia: "VALVA", nazione: "ITALIA" },
  { codeKey: "L658", sigla: "CT", provincia: "VALVERDE", nazione: "ITALIA" },
  { codeKey: "L659", sigla: "PV", provincia: "VALVERDE", nazione: "ITALIA" },
  { codeKey: "L660", sigla: "BZ", provincia: "VANDOIES", nazione: "ITALIA" },
  { codeKey: "L664", sigla: "MI", provincia: "VANZAGHELLO", nazione: "ITALIA" },
  { codeKey: "L665", sigla: "MI", provincia: "VANZAGO", nazione: "ITALIA" },
  {
    codeKey: "L666",
    sigla: "VB",
    provincia: "VANZONE CON SAN CARLO",
    nazione: "ITALIA",
  },
  { codeKey: "L667", sigla: "MI", provincia: "VAPRIO D'ADDA", nazione: "ITALIA" },
  { codeKey: "L668", sigla: "NO", provincia: "VAPRIO D'AGOGNA", nazione: "ITALIA" },
  { codeKey: "L669", sigla: "VC", provincia: "VARALLO", nazione: "ITALIA" },
  { codeKey: "L670", sigla: "NO", provincia: "VARALLO POMBIA", nazione: "ITALIA" },
  { codeKey: "L671", sigla: "VA", provincia: "VARANO BORGHI", nazione: "ITALIA" },
  {
    codeKey: "L672",
    sigla: "PR",
    provincia: "VARANO DE' MELEGARI",
    nazione: "ITALIA",
  },
  { codeKey: "L673", sigla: "RC", provincia: "VARAPODIO", nazione: "ITALIA" },
  { codeKey: "L675", sigla: "SV", provincia: "VARAZZE", nazione: "ITALIA" },
  { codeKey: "L676", sigla: "RI", provincia: "VARCO SABINO", nazione: "ITALIA" },
  { codeKey: "L677", sigla: "MB", provincia: "VAREDO", nazione: "ITALIA" },
  { codeKey: "L678", sigla: "TN", provincia: "VARENA", nazione: "ITALIA" },
  { codeKey: "L680", sigla: "LC", provincia: "VARENNA", nazione: "ITALIA" },
  { codeKey: "L681", sigla: "SP", provincia: "VARESE LIGURE", nazione: "ITALIA" },
  { codeKey: "L682", sigla: "VA", provincia: "VARESE", nazione: "ITALIA" },
  { codeKey: "L685", sigla: "TO", provincia: "VARISELLA", nazione: "ITALIA" },
  { codeKey: "L686", sigla: "UD", provincia: "VARMO", nazione: "ITALIA" },
  { codeKey: "L687", sigla: "BZ", provincia: "VARNA", nazione: "ITALIA" },
  { codeKey: "L689", sigla: "PR", provincia: "VARSI", nazione: "ITALIA" },
  { codeKey: "L690", sigla: "PV", provincia: "VARZI", nazione: "ITALIA" },
  { codeKey: "L691", sigla: "VB", provincia: "VARZO", nazione: "ITALIA" },
  { codeKey: "L693", sigla: "IM", provincia: "VASIA", nazione: "ITALIA" },
  { codeKey: "L696", sigla: "IS", provincia: "VASTOGIRARDI", nazione: "ITALIA" },
  { codeKey: "L697", sigla: "TN", provincia: "VATTARO", nazione: "ITALIA" },
  { codeKey: "L698", sigla: "TO", provincia: "VAUDA CANAVESE", nazione: "ITALIA" },
  { codeKey: "L699", sigla: "VV", provincia: "VAZZANO", nazione: "ITALIA" },
  { codeKey: "L700", sigla: "TV", provincia: "VAZZOLA", nazione: "ITALIA" },
  { codeKey: "L702", sigla: "PI", provincia: "VECCHIANO", nazione: "ITALIA" },
  { codeKey: "L703", sigla: "VA", provincia: "VEDANO OLONA", nazione: "ITALIA" },
  { codeKey: "L704", sigla: "MB", provincia: "VEDANO AL LAMBRO", nazione: "ITALIA" },
  { codeKey: "L706", sigla: "TV", provincia: "VEDELAGO", nazione: "ITALIA" },
  { codeKey: "L707", sigla: "BG", provincia: "VEDESETA", nazione: "ITALIA" },
  {
    codeKey: "L709",
    sigla: "MB",
    provincia: "VEDUGGIO CON COLZANO",
    nazione: "ITALIA",
  },
  { codeKey: "L710", sigla: "PD", provincia: "VEGGIANO", nazione: "ITALIA" },
  { codeKey: "L711", sigla: "LE", provincia: "VEGLIE", nazione: "ITALIA" },
  { codeKey: "L712", sigla: "BI", provincia: "VEGLIO", nazione: "ITALIA" },
  { codeKey: "L713", sigla: "VT", provincia: "VEJANO", nazione: "ITALIA" },
  { codeKey: "L715", sigla: "CO", provincia: "VELESO", nazione: "ITALIA" },
  {
    codeKey: "L716",
    sigla: "PV",
    provincia: "VELEZZO LOMELLINA",
    nazione: "ITALIA",
  },
  { codeKey: "L719", sigla: "RM", provincia: "VELLETRI", nazione: "ITALIA" },
  { codeKey: "L720", sigla: "PV", provincia: "VELLEZZO BELLINI", nazione: "ITALIA" },
  { codeKey: "L722", sigla: "VR", provincia: "VELO VERONESE", nazione: "ITALIA" },
  { codeKey: "L723", sigla: "VI", provincia: "VELO D'ASTICO", nazione: "ITALIA" },
  { codeKey: "L724", sigla: "BZ", provincia: "VELTURNO", nazione: "ITALIA" },
  { codeKey: "L725", sigla: "IS", provincia: "VENAFRO", nazione: "ITALIA" },
  { codeKey: "L726", sigla: "TO", provincia: "VENAUS", nazione: "ITALIA" },
  { codeKey: "L727", sigla: "TO", provincia: "VENARIA REALE", nazione: "ITALIA" },
  { codeKey: "L728", sigla: "AP", provincia: "VENAROTTA", nazione: "ITALIA" },
  { codeKey: "L729", sigla: "CN", provincia: "VENASCA", nazione: "ITALIA" },
  { codeKey: "L730", sigla: "SV", provincia: "VENDONE", nazione: "ITALIA" },
  { codeKey: "L731", sigla: "LC", provincia: "VENDROGNO", nazione: "ITALIA" },
  {
    codeKey: "L733",
    sigla: "VA",
    provincia: "VENEGONO INFERIORE",
    nazione: "ITALIA",
  },
  {
    codeKey: "L734",
    sigla: "VA",
    provincia: "VENEGONO SUPERIORE",
    nazione: "ITALIA",
  },
  { codeKey: "L735", sigla: "ME", provincia: "VENETICO", nazione: "ITALIA" },
  { codeKey: "L736", sigla: "VE", provincia: "VENEZIA", nazione: "ITALIA" },
  { codeKey: "L737", sigla: "CO", provincia: "VENIANO", nazione: "ITALIA" },
  { codeKey: "L738", sigla: "PZ", provincia: "VENOSA", nazione: "ITALIA" },
  { codeKey: "L739", sigla: "AV", provincia: "VENTICANO", nazione: "ITALIA" },
  {
    codeKey: "L740",
    sigla: "PA",
    provincia: "VENTIMIGLIA DI SICILIA",
    nazione: "ITALIA",
  },
  { codeKey: "L741", sigla: "IM", provincia: "VENTIMIGLIA", nazione: "ITALIA" },
  { codeKey: "L742", sigla: "LT", provincia: "VENTOTENE", nazione: "ITALIA" },
  { codeKey: "L743", sigla: "UD", provincia: "VENZONE", nazione: "ITALIA" },
  { codeKey: "L744", sigla: "MB", provincia: "VERANO BRIANZA", nazione: "ITALIA" },
  { codeKey: "L745", sigla: "BZ", provincia: "VERANO", nazione: "ITALIA" },
  { codeKey: "L746", sigla: "VB", provincia: "VERBANIA", nazione: "ITALIA" },
  { codeKey: "L747", sigla: "CS", provincia: "VERBICARO", nazione: "ITALIA" },
  { codeKey: "L748", sigla: "CO", provincia: "VERCANA", nazione: "ITALIA" },
  { codeKey: "L749", sigla: "SO", provincia: "VERCEIA", nazione: "ITALIA" },
  { codeKey: "L750", sigla: "VC", provincia: "VERCELLI", nazione: "ITALIA" },
  { codeKey: "L751", sigla: "LC", provincia: "VERCURAGO", nazione: "ITALIA" },
  { codeKey: "L752", sigla: "BG", provincia: "VERDELLINO", nazione: "ITALIA" },
  { codeKey: "L753", sigla: "BG", provincia: "VERDELLO", nazione: "ITALIA" },
  { codeKey: "L758", sigla: "CN", provincia: "VERDUNO", nazione: "ITALIA" },
  { codeKey: "L762", sigla: "BO", provincia: "VERGATO", nazione: "ITALIA" },
  { codeKey: "L764", sigla: "FC", provincia: "VERGHERETO", nazione: "ITALIA" },
  { codeKey: "L765", sigla: "VA", provincia: "VERGIATE", nazione: "ITALIA" },
  { codeKey: "L768", sigla: "MI", provincia: "VERMEZZO", nazione: "ITALIA" },
  { codeKey: "L769", sigla: "TN", provincia: "VERMIGLIO", nazione: "ITALIA" },
  { codeKey: "L771", sigla: "CN", provincia: "VERNANTE", nazione: "ITALIA" },
  { codeKey: "L772", sigla: "PC", provincia: "VERNASCA", nazione: "ITALIA" },
  { codeKey: "L773", sigla: "MI", provincia: "VERNATE", nazione: "ITALIA" },
  { codeKey: "L774", sigla: "SP", provincia: "VERNAZZA", nazione: "ITALIA" },
  { codeKey: "L775", sigla: "PO", provincia: "VERNIO", nazione: "ITALIA" },
  { codeKey: "L776", sigla: "LE", provincia: "VERNOLE", nazione: "ITALIA" },
  { codeKey: "L777", sigla: "BS", provincia: "VEROLANUOVA", nazione: "ITALIA" },
  { codeKey: "L778", sigla: "BS", provincia: "VEROLAVECCHIA", nazione: "ITALIA" },
  { codeKey: "L779", sigla: "TO", provincia: "VEROLENGO", nazione: "ITALIA" },
  { codeKey: "L780", sigla: "FR", provincia: "VEROLI", nazione: "ITALIA" },
  { codeKey: "L781", sigla: "VR", provincia: "VERONA", nazione: "ITALIA" },
  { codeKey: "L783", sigla: "AO", provincia: "VERRAYES", nazione: "ITALIA" },
  { codeKey: "L784", sigla: "PV", provincia: "VERRETTO", nazione: "ITALIA" },
  { codeKey: "L785", sigla: "BI", provincia: "VERRONE", nazione: "ITALIA" },
  { codeKey: "L787", sigla: "TO", provincia: "VERRUA SAVOIA", nazione: "ITALIA" },
  { codeKey: "L788", sigla: "PV", provincia: "VERRUA PO", nazione: "ITALIA" },
  {
    codeKey: "L792",
    sigla: "CO",
    provincia: "VERTEMATE CON MINOPRIO",
    nazione: "ITALIA",
  },
  { codeKey: "L795", sigla: "BG", provincia: "VERTOVA", nazione: "ITALIA" },
  { codeKey: "L797", sigla: "RN", provincia: "VERUCCHIO", nazione: "ITALIA" },
  { codeKey: "L798", sigla: "NO", provincia: "VERUNO", nazione: "ITALIA" },
  { codeKey: "L799", sigla: "SO", provincia: "VERVIO", nazione: "ITALIA" },
  { codeKey: "L801", sigla: "UD", provincia: "VERZEGNIS", nazione: "ITALIA" },
  { codeKey: "L802", sigla: "KR", provincia: "VERZINO", nazione: "ITALIA" },
  { codeKey: "L804", sigla: "CN", provincia: "VERZUOLO", nazione: "ITALIA" },
  { codeKey: "L805", sigla: "PD", provincia: "VESCOVANA", nazione: "ITALIA" },
  { codeKey: "L806", sigla: "CR", provincia: "VESCOVATO", nazione: "ITALIA" },
  { codeKey: "L807", sigla: "AT", provincia: "VESIME", nazione: "ITALIA" },
  { codeKey: "L808", sigla: "NO", provincia: "VESPOLATE", nazione: "ITALIA" },
  { codeKey: "L809", sigla: "IM", provincia: "VESSALICO", nazione: "ITALIA" },
  { codeKey: "L810", sigla: "VR", provincia: "VESTENANOVA", nazione: "ITALIA" },
  { codeKey: "L811", sigla: "TO", provincia: "VESTIGNE'", nazione: "ITALIA" },
  { codeKey: "L812", sigla: "BS", provincia: "VESTONE", nazione: "ITALIA" },
  { codeKey: "L813", sigla: "LC", provincia: "VESTRENO", nazione: "ITALIA" },
  { codeKey: "L814", sigla: "VT", provincia: "VETRALLA", nazione: "ITALIA" },
  { codeKey: "L815", sigla: "RE", provincia: "VETTO", nazione: "ITALIA" },
  { codeKey: "L816", sigla: "BS", provincia: "VEZZA D'OGLIO", nazione: "ITALIA" },
  { codeKey: "L817", sigla: "CN", provincia: "VEZZA D'ALBA", nazione: "ITALIA" },
  { codeKey: "L819", sigla: "SP", provincia: "VEZZANO LIGURE", nazione: "ITALIA" },
  {
    codeKey: "L820",
    sigla: "RE",
    provincia: "VEZZANO SUL CROSTOLO",
    nazione: "ITALIA",
  },
  { codeKey: "L821", sigla: "TN", provincia: "VEZZANO", nazione: "ITALIA" },
  { codeKey: "L823", sigla: "SV", provincia: "VEZZI PORTIO", nazione: "ITALIA" },
  { codeKey: "L826", sigla: "MN", provincia: "VIADANA", nazione: "ITALIA" },
  { codeKey: "L827", sigla: "BG", provincia: "VIADANICA", nazione: "ITALIA" },
  { codeKey: "L828", sigla: "CT", provincia: "VIAGRANDE", nazione: "ITALIA" },
  { codeKey: "L829", sigla: "AT", provincia: "VIALE D'ASTI", nazione: "ITALIA" },
  { codeKey: "L830", sigla: "TO", provincia: "VIALFRE'", nazione: "ITALIA" },
  { codeKey: "L831", sigla: "RE", provincia: "VIANO", nazione: "ITALIA" },
  { codeKey: "L833", sigla: "LU", provincia: "VIAREGGIO", nazione: "ITALIA" },
  { codeKey: "L834", sigla: "AT", provincia: "VIARIGI", nazione: "ITALIA" },
  { codeKey: "L835", sigla: "SA", provincia: "VIBONATI", nazione: "ITALIA" },
  { codeKey: "L836", sigla: "FR", provincia: "VICALVI", nazione: "ITALIA" },
  { codeKey: "L837", sigla: "PA", provincia: "VICARI", nazione: "ITALIA" },
  { codeKey: "L838", sigla: "FI", provincia: "VICCHIO", nazione: "ITALIA" },
  { codeKey: "L840", sigla: "VI", provincia: "VICENZA", nazione: "ITALIA" },
  { codeKey: "L841", sigla: "CN", provincia: "VICOFORTE", nazione: "ITALIA" },
  { codeKey: "L842", sigla: "FG", provincia: "VICO DEL GARGANO", nazione: "ITALIA" },
  { codeKey: "L843", sigla: "FR", provincia: "VICO NEL LAZIO", nazione: "ITALIA" },
  { codeKey: "L844", sigla: "CE", provincia: "VILLA LITERNO", nazione: "ITALIA" },
  { codeKey: "L845", sigla: "NA", provincia: "VICO EQUENSE", nazione: "ITALIA" },
  { codeKey: "L846", sigla: "PE", provincia: "VICOLI", nazione: "ITALIA" },
  { codeKey: "L847", sigla: "NO", provincia: "VICOLUNGO", nazione: "ITALIA" },
  { codeKey: "L848", sigla: "PC", provincia: "ZIANO PIACENTINO", nazione: "ITALIA" },
  { codeKey: "L850", sigla: "PI", provincia: "VICOPISANO", nazione: "ITALIA" },
  { codeKey: "L851", sigla: "RM", provincia: "VICOVARO", nazione: "ITALIA" },
  { codeKey: "L854", sigla: "PV", provincia: "VIDIGULFO", nazione: "ITALIA" },
  { codeKey: "L856", sigla: "TV", provincia: "VIDOR", nazione: "ITALIA" },
  { codeKey: "L857", sigla: "TO", provincia: "VIDRACCO", nazione: "ITALIA" },
  { codeKey: "L858", sigla: "FG", provincia: "VIESTE", nazione: "ITALIA" },
  {
    codeKey: "L859",
    sigla: "PZ",
    provincia: "VIETRI DI POTENZA",
    nazione: "ITALIA",
  },
  { codeKey: "L860", sigla: "SA", provincia: "VIETRI SUL MARE", nazione: "ITALIA" },
  { codeKey: "L864", sigla: "VB", provincia: "VIGANELLA", nazione: "ITALIA" },
  {
    codeKey: "L865",
    sigla: "BG",
    provincia: "VIGANO SAN MARTINO",
    nazione: "ITALIA",
  },
  { codeKey: "L866", sigla: "LC", provincia: "VIGANO'", nazione: "ITALIA" },
  {
    codeKey: "L868",
    sigla: "FE",
    provincia: "VIGARANO MAINARDA",
    nazione: "ITALIA",
  },
  { codeKey: "L869", sigla: "VR", provincia: "VIGASIO", nazione: "ITALIA" },
  { codeKey: "L872", sigla: "PV", provincia: "VIGEVANO", nazione: "ITALIA" },
  { codeKey: "L873", sigla: "PZ", provincia: "VIGGIANELLO", nazione: "ITALIA" },
  { codeKey: "L874", sigla: "PZ", provincia: "VIGGIANO", nazione: "ITALIA" },
  { codeKey: "L876", sigla: "VA", provincia: "VIGGIU'", nazione: "ITALIA" },
  {
    codeKey: "L878",
    sigla: "PD",
    provincia: "VIGHIZZOLO D'ESTE",
    nazione: "ITALIA",
  },
  { codeKey: "L879", sigla: "AT", provincia: "VIGLIANO D'ASTI", nazione: "ITALIA" },
  {
    codeKey: "L880",
    sigla: "BI",
    provincia: "VIGLIANO BIELLESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "L881",
    sigla: "AL",
    provincia: "VIGNALE MONFERRATO",
    nazione: "ITALIA",
  },
  { codeKey: "L882", sigla: "VT", provincia: "VIGNANELLO", nazione: "ITALIA" },
  { codeKey: "L883", sigla: "MI", provincia: "VIGNATE", nazione: "ITALIA" },
  { codeKey: "L885", sigla: "MO", provincia: "VIGNOLA", nazione: "ITALIA" },
  { codeKey: "L886", sigla: "TN", provincia: "VIGNOLA FALESINA", nazione: "ITALIA" },
  { codeKey: "L887", sigla: "AL", provincia: "VIGNOLE BORBERA", nazione: "ITALIA" },
  { codeKey: "L888", sigla: "CN", provincia: "VIGNOLO", nazione: "ITALIA" },
  { codeKey: "L889", sigla: "VB", provincia: "VIGNONE", nazione: "ITALIA" },
  { codeKey: "L890", sigla: "BL", provincia: "VIGO DI CADORE", nazione: "ITALIA" },
  { codeKey: "L892", sigla: "PD", provincia: "VIGODARZERE", nazione: "ITALIA" },
  { codeKey: "L893", sigla: "TN", provincia: "VIGO DI FASSA", nazione: "ITALIA" },
  { codeKey: "L894", sigla: "BG", provincia: "VIGOLO", nazione: "ITALIA" },
  { codeKey: "L896", sigla: "TN", provincia: "VIGOLO VATTARO", nazione: "ITALIA" },
  { codeKey: "L897", sigla: "PC", provincia: "VIGOLZONE", nazione: "ITALIA" },
  { codeKey: "L898", sigla: "TO", provincia: "VIGONE", nazione: "ITALIA" },
  { codeKey: "L899", sigla: "VE", provincia: "VIGONOVO", nazione: "ITALIA" },
  { codeKey: "L900", sigla: "PD", provincia: "VIGONZA", nazione: "ITALIA" },
  { codeKey: "L903", sigla: "TN", provincia: "VIGO RENDENA", nazione: "ITALIA" },
  { codeKey: "L904", sigla: "AL", provincia: "VIGUZZOLO", nazione: "ITALIA" },
  {
    codeKey: "L905",
    sigla: "FR",
    provincia: "VILLA SANTA LUCIA",
    nazione: "ITALIA",
  },
  { codeKey: "L906", sigla: "VB", provincia: "VILLADOSSOLA", nazione: "ITALIA" },
  {
    codeKey: "L907",
    sigla: "SO",
    provincia: "VILLA DI CHIAVENNA",
    nazione: "ITALIA",
  },
  { codeKey: "L908", sigla: "SO", provincia: "VILLA DI TIRANO", nazione: "ITALIA" },
  { codeKey: "L909", sigla: "UD", provincia: "VILLA SANTINA", nazione: "ITALIA" },
  { codeKey: "L910", sigla: "TN", provincia: "VILLA AGNEDO", nazione: "ITALIA" },
  { codeKey: "L912", sigla: "VR", provincia: "VILLA BARTOLOMEA", nazione: "ITALIA" },
  { codeKey: "L913", sigla: "LU", provincia: "VILLA BASILICA", nazione: "ITALIA" },
  { codeKey: "L915", sigla: "BZ", provincia: "VILLABASSA", nazione: "ITALIA" },
  { codeKey: "L916", sigla: "PA", provincia: "VILLABATE", nazione: "ITALIA" },
  { codeKey: "L917", sigla: "PV", provincia: "VILLA BISCOSSI", nazione: "ITALIA" },
  { codeKey: "L919", sigla: "BS", provincia: "VILLA CARCINA", nazione: "ITALIA" },
  { codeKey: "L920", sigla: "BR", provincia: "VILLA CASTELLI", nazione: "ITALIA" },
  { codeKey: "L922", sigla: "PE", provincia: "VILLA CELIERA", nazione: "ITALIA" },
  { codeKey: "L923", sigla: "BS", provincia: "VILLACHIARA", nazione: "ITALIA" },
  { codeKey: "L924", sigla: "VS", provincia: "VILLACIDRO", nazione: "ITALIA" },
  {
    codeKey: "L926",
    sigla: "LU",
    provincia: "VILLA COLLEMANDINA",
    nazione: "ITALIA",
  },
  { codeKey: "L928", sigla: "MI", provincia: "VILLA CORTESE", nazione: "ITALIA" },
  { codeKey: "L929", sigla: "BG", provincia: "VILLA D'ADDA", nazione: "ITALIA" },
  { codeKey: "L931", sigla: "AL", provincia: "VILLADEATI", nazione: "ITALIA" },
  { codeKey: "L933", sigla: "BI", provincia: "VILLA DEL BOSCO", nazione: "ITALIA" },
  { codeKey: "L934", sigla: "PD", provincia: "VILLA DEL CONTE", nazione: "ITALIA" },
  { codeKey: "L936", sigla: "BG", provincia: "VILLA DI SERIO", nazione: "ITALIA" },
  { codeKey: "L937", sigla: "PD", provincia: "VILLA ESTENSE", nazione: "ITALIA" },
  { codeKey: "L938", sigla: "BG", provincia: "VILLA D'OGNA", nazione: "ITALIA" },
  { codeKey: "L939", sigla: "RO", provincia: "VILLADOSE", nazione: "ITALIA" },
  { codeKey: "L942", sigla: "CN", provincia: "VILLAFALLETTO", nazione: "ITALIA" },
  { codeKey: "L943", sigla: "IM", provincia: "VILLA FARALDI", nazione: "ITALIA" },
  {
    codeKey: "L944",
    sigla: "AG",
    provincia: "VILLAFRANCA SICULA",
    nazione: "ITALIA",
  },
  {
    codeKey: "L945",
    sigla: "AT",
    provincia: "VILLAFRANCA D'ASTI",
    nazione: "ITALIA",
  },
  {
    codeKey: "L946",
    sigla: "MS",
    provincia: "VILLAFRANCA IN LUNIGIANA",
    nazione: "ITALIA",
  },
  {
    codeKey: "L947",
    sigla: "PD",
    provincia: "VILLAFRANCA PADOVANA",
    nazione: "ITALIA",
  },
  {
    codeKey: "L948",
    sigla: "TO",
    provincia: "VILLAFRANCA PIEMONTE",
    nazione: "ITALIA",
  },
  {
    codeKey: "L949",
    sigla: "VR",
    provincia: "VILLAFRANCA DI VERONA",
    nazione: "ITALIA",
  },
  {
    codeKey: "L950",
    sigla: "ME",
    provincia: "VILLAFRANCA TIRRENA",
    nazione: "ITALIA",
  },
  { codeKey: "L951", sigla: "PA", provincia: "VILLAFRATI", nazione: "ITALIA" },
  { codeKey: "L952", sigla: "VI", provincia: "VILLAGA", nazione: "ITALIA" },
  {
    codeKey: "L953",
    sigla: "OG",
    provincia: "VILLAGRANDE STRISAILI",
    nazione: "ITALIA",
  },
  { codeKey: "L956", sigla: "CO", provincia: "VILLA GUARDIA", nazione: "ITALIA" },
  { codeKey: "L957", sigla: "TN", provincia: "VILLA LAGARINA", nazione: "ITALIA" },
  { codeKey: "L958", sigla: "AQ", provincia: "VILLALAGO", nazione: "ITALIA" },
  { codeKey: "L959", sigla: "CL", provincia: "VILLALBA", nazione: "ITALIA" },
  { codeKey: "L961", sigla: "CH", provincia: "VILLALFONSINA", nazione: "ITALIA" },
  { codeKey: "L963", sigla: "AL", provincia: "VILLALVERNIA", nazione: "ITALIA" },
  { codeKey: "L964", sigla: "CH", provincia: "VILLAMAGNA", nazione: "ITALIA" },
  { codeKey: "L965", sigla: "AV", provincia: "VILLAMAINA", nazione: "ITALIA" },
  { codeKey: "L966", sigla: "VS", provincia: "VILLAMAR", nazione: "ITALIA" },
  { codeKey: "L967", sigla: "RO", provincia: "VILLAMARZANA", nazione: "ITALIA" },
  { codeKey: "L968", sigla: "CI", provincia: "VILLAMASSARGIA", nazione: "ITALIA" },
  { codeKey: "L969", sigla: "RE", provincia: "VILLA MINOZZO", nazione: "ITALIA" },
  { codeKey: "L970", sigla: "AL", provincia: "VILLAMIROGLIO", nazione: "ITALIA" },
  { codeKey: "L971", sigla: "BZ", provincia: "VILLANDRO", nazione: "ITALIA" },
  {
    codeKey: "L972",
    sigla: "AL",
    provincia: "VILLANOVA MONFERRATO",
    nazione: "ITALIA",
  },
  {
    codeKey: "L973",
    sigla: "AV",
    provincia: "VILLANOVA DEL BATTISTA",
    nazione: "ITALIA",
  },
  {
    codeKey: "L974",
    sigla: "CN",
    provincia: "VILLANOVA MONDOVI'",
    nazione: "ITALIA",
  },
  {
    codeKey: "L975",
    sigla: "SV",
    provincia: "VILLANOVA D'ALBENGA",
    nazione: "ITALIA",
  },
  {
    codeKey: "L977",
    sigla: "LO",
    provincia: "VILLANOVA DEL SILLARO",
    nazione: "ITALIA",
  },
  {
    codeKey: "L978",
    sigla: "BI",
    provincia: "VILLANOVA BIELLESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "L979",
    sigla: "PD",
    provincia: "VILLANOVA DI CAMPOSAMPIERO",
    nazione: "ITALIA",
  },
  {
    codeKey: "L980",
    sigla: "PC",
    provincia: "VILLANOVA SULL'ARDA",
    nazione: "ITALIA",
  },
  { codeKey: "L981", sigla: "AO", provincia: "VILLENEUVE", nazione: "ITALIA" },
  {
    codeKey: "L982",
    sigla: "TO",
    provincia: "VILLANOVA CANAVESE",
    nazione: "ITALIA",
  },
  {
    codeKey: "L983",
    sigla: "PV",
    provincia: "VILLANOVA D'ARDENGHI",
    nazione: "ITALIA",
  },
  { codeKey: "L984", sigla: "AT", provincia: "VILLANOVA D'ASTI", nazione: "ITALIA" },
  {
    codeKey: "L985",
    sigla: "RO",
    provincia: "VILLANOVA DEL GHEBBO",
    nazione: "ITALIA",
  },
  { codeKey: "L986", sigla: "VS", provincia: "VILLANOVAFORRU", nazione: "ITALIA" },
  { codeKey: "L987", sigla: "VS", provincia: "VILLANOVAFRANCA", nazione: "ITALIA" },
  {
    codeKey: "L988",
    sigla: "RO",
    provincia: "VILLANOVA MARCHESANA",
    nazione: "ITALIA",
  },
  {
    codeKey: "L989",
    sigla: "SS",
    provincia: "VILLANOVA MONTELEONE",
    nazione: "ITALIA",
  },
  { codeKey: "L990", sigla: "CN", provincia: "VILLANOVA SOLARO", nazione: "ITALIA" },
  {
    codeKey: "L991",
    sigla: "OR",
    provincia: "VILLANOVA TRUSCHEDU",
    nazione: "ITALIA",
  },
  { codeKey: "L992", sigla: "NU", provincia: "VILLANOVATULO", nazione: "ITALIA" },
  { codeKey: "L994", sigla: "PV", provincia: "VILLANTERIO", nazione: "ITALIA" },
  {
    codeKey: "L995",
    sigla: "BS",
    provincia: "VILLANUOVA SUL CLISI",
    nazione: "ITALIA",
  },
  { codeKey: "L998", sigla: "CA", provincia: "VILLAPUTZU", nazione: "ITALIA" },
  { codeKey: "L999", sigla: "TO", provincia: "VILLAR DORA", nazione: "ITALIA" },
  { codeKey: "M002", sigla: "TO", provincia: "VILLARBASSE", nazione: "ITALIA" },
  { codeKey: "M003", sigla: "VC", provincia: "VILLARBOIT", nazione: "ITALIA" },
  { codeKey: "M004", sigla: "TO", provincia: "VILLAREGGIA", nazione: "ITALIA" },
  { codeKey: "M006", sigla: "TN", provincia: "VILLA RENDENA", nazione: "ITALIA" },
  {
    codeKey: "M007",
    sigla: "TO",
    provincia: "VILLAR FOCCHIARDO",
    nazione: "ITALIA",
  },
  { codeKey: "M009", sigla: "AL", provincia: "VILLAROMAGNANO", nazione: "ITALIA" },
  { codeKey: "M011", sigla: "EN", provincia: "VILLAROSA", nazione: "ITALIA" },
  { codeKey: "M013", sigla: "TO", provincia: "VILLAR PELLICE", nazione: "ITALIA" },
  { codeKey: "M014", sigla: "TO", provincia: "VILLAR PEROSA", nazione: "ITALIA" },
  {
    codeKey: "M015",
    sigla: "CN",
    provincia: "VILLAR SAN COSTANZO",
    nazione: "ITALIA",
  },
  { codeKey: "M016", sigla: "CA", provincia: "VILLASALTO", nazione: "ITALIA" },
  { codeKey: "M017", sigla: "MB", provincia: "VILLASANTA", nazione: "ITALIA" },
  {
    codeKey: "M018",
    sigla: "RC",
    provincia: "VILLA SAN GIOVANNI",
    nazione: "ITALIA",
  },
  {
    codeKey: "M019",
    sigla: "AT",
    provincia: "VILLA SAN SECONDO",
    nazione: "ITALIA",
  },
  {
    codeKey: "M021",
    sigla: "AQ",
    provincia: "VILLA SANTA LUCIA DEGLI ABRUZZI",
    nazione: "ITALIA",
  },
  {
    codeKey: "M022",
    sigla: "CH",
    provincia: "VILLA SANTA MARIA",
    nazione: "ITALIA",
  },
  {
    codeKey: "M023",
    sigla: "AQ",
    provincia: "VILLA SANT'ANGELO",
    nazione: "ITALIA",
  },
  { codeKey: "M025", sigla: "CA", provincia: "VILLASOR", nazione: "ITALIA" },
  { codeKey: "M026", sigla: "CA", provincia: "VILLASPECIOSA", nazione: "ITALIA" },
  { codeKey: "M027", sigla: "TO", provincia: "VILLASTELLONE", nazione: "ITALIA" },
  { codeKey: "M028", sigla: "VC", provincia: "VILLATA", nazione: "ITALIA" },
  { codeKey: "M030", sigla: "OR", provincia: "VILLAURBANA", nazione: "ITALIA" },
  { codeKey: "M031", sigla: "AQ", provincia: "VILLAVALLELONGA", nazione: "ITALIA" },
  { codeKey: "M032", sigla: "VI", provincia: "VILLAVERLA", nazione: "ITALIA" },
  { codeKey: "M034", sigla: "UD", provincia: "VILLA VICENTINA", nazione: "ITALIA" },
  { codeKey: "M041", sigla: "AQ", provincia: "VILLETTA BARREA", nazione: "ITALIA" },
  { codeKey: "M042", sigla: "VB", provincia: "VILLETTE", nazione: "ITALIA" },
  { codeKey: "M043", sigla: "GO", provincia: "VILLESSE", nazione: "ITALIA" },
  { codeKey: "M044", sigla: "MN", provincia: "VILLIMPENTA", nazione: "ITALIA" },
  { codeKey: "M045", sigla: "BG", provincia: "VILLONGO", nazione: "ITALIA" },
  { codeKey: "M048", sigla: "TV", provincia: "VILLORBA", nazione: "ITALIA" },
  {
    codeKey: "M050",
    sigla: "BG",
    provincia: "VILMINORE DI SCALVE",
    nazione: "ITALIA",
  },
  { codeKey: "M052", sigla: "MB", provincia: "VIMERCATE", nazione: "ITALIA" },
  { codeKey: "M053", sigla: "MI", provincia: "VIMODRONE", nazione: "ITALIA" },
  { codeKey: "M055", sigla: "CN", provincia: "VINADIO", nazione: "ITALIA" },
  { codeKey: "M057", sigla: "CB", provincia: "VINCHIATURO", nazione: "ITALIA" },
  { codeKey: "M058", sigla: "AT", provincia: "VINCHIO", nazione: "ITALIA" },
  { codeKey: "M059", sigla: "FI", provincia: "VINCI", nazione: "ITALIA" },
  { codeKey: "M060", sigla: "TO", provincia: "VINOVO", nazione: "ITALIA" },
  { codeKey: "M062", sigla: "NO", provincia: "VINZAGLIO", nazione: "ITALIA" },
  { codeKey: "M063", sigla: "CN", provincia: "VIOLA", nazione: "ITALIA" },
  { codeKey: "M065", sigla: "BS", provincia: "VIONE", nazione: "ITALIA" },
  { codeKey: "M067", sigla: "BZ", provincia: "VIPITENO", nazione: "ITALIA" },
  { codeKey: "M069", sigla: "TO", provincia: "VIRLE PIEMONTE", nazione: "ITALIA" },
  { codeKey: "M070", sigla: "BS", provincia: "VISANO", nazione: "ITALIA" },
  { codeKey: "M071", sigla: "TO", provincia: "VISCHE", nazione: "ITALIA" },
  { codeKey: "M072", sigla: "NA", provincia: "VISCIANO", nazione: "ITALIA" },
  { codeKey: "M073", sigla: "UD", provincia: "VISCO", nazione: "ITALIA" },
  { codeKey: "M077", sigla: "AL", provincia: "VISONE", nazione: "ITALIA" },
  { codeKey: "M078", sigla: "MC", provincia: "VISSO", nazione: "ITALIA" },
  { codeKey: "M079", sigla: "PV", provincia: "VISTARINO", nazione: "ITALIA" },
  { codeKey: "M080", sigla: "TO", provincia: "VISTRORIO", nazione: "ITALIA" },
  { codeKey: "M081", sigla: "TP", provincia: "VITA", nazione: "ITALIA" },
  { codeKey: "M082", sigla: "VT", provincia: "VITERBO", nazione: "ITALIA" },
  { codeKey: "M083", sigla: "FR", provincia: "VITICUSO", nazione: "ITALIA" },
  { codeKey: "M085", sigla: "PN", provincia: "VITO D'ASIO", nazione: "ITALIA" },
  { codeKey: "M086", sigla: "VT", provincia: "VITORCHIANO", nazione: "ITALIA" },
  { codeKey: "M088", sigla: "RG", provincia: "VITTORIA", nazione: "ITALIA" },
  { codeKey: "M089", sigla: "TV", provincia: "VITTORIO VENETO", nazione: "ITALIA" },
  { codeKey: "M090", sigla: "AQ", provincia: "VITTORITO", nazione: "ITALIA" },
  { codeKey: "M091", sigla: "MI", provincia: "VITTUONE", nazione: "ITALIA" },
  { codeKey: "M092", sigla: "CE", provincia: "VITULAZIO", nazione: "ITALIA" },
  { codeKey: "M093", sigla: "BN", provincia: "VITULANO", nazione: "ITALIA" },
  { codeKey: "M094", sigla: "TO", provincia: "VIU'", nazione: "ITALIA" },
  { codeKey: "M095", sigla: "RM", provincia: "VIVARO ROMANO", nazione: "ITALIA" },
  { codeKey: "M096", sigla: "PN", provincia: "VIVARO", nazione: "ITALIA" },
  { codeKey: "M098", sigla: "BI", provincia: "VIVERONE", nazione: "ITALIA" },
  { codeKey: "M100", sigla: "CT", provincia: "VIZZINI", nazione: "ITALIA" },
  { codeKey: "M101", sigla: "VA", provincia: "VIZZOLA TICINO", nazione: "ITALIA" },
  {
    codeKey: "M102",
    sigla: "MI",
    provincia: "VIZZOLO PREDABISSI",
    nazione: "ITALIA",
  },
  { codeKey: "M103", sigla: "PD", provincia: "VO", nazione: "ITALIA" },
  { codeKey: "M104", sigla: "BS", provincia: "VOBARNO", nazione: "ITALIA" },
  { codeKey: "M105", sigla: "GE", provincia: "VOBBIA", nazione: "ITALIA" },
  { codeKey: "M106", sigla: "VC", provincia: "VOCCA", nazione: "ITALIA" },
  { codeKey: "M108", sigla: "BL", provincia: "VODO DI CADORE", nazione: "ITALIA" },
  { codeKey: "M109", sigla: "PV", provincia: "VOGHERA", nazione: "ITALIA" },
  { codeKey: "M110", sigla: "FE", provincia: "VOGHIERA", nazione: "ITALIA" },
  { codeKey: "M111", sigla: "VB", provincia: "VOGOGNA", nazione: "ITALIA" },
  { codeKey: "M113", sigla: "TN", provincia: "VOLANO", nazione: "ITALIA" },
  { codeKey: "M115", sigla: "NA", provincia: "VOLLA", nazione: "ITALIA" },
  { codeKey: "M116", sigla: "CR", provincia: "VOLONGO", nazione: "ITALIA" },
  {
    codeKey: "M118",
    sigla: "TV",
    provincia: "VOLPAGO DEL MONTELLO",
    nazione: "ITALIA",
  },
  { codeKey: "M119", sigla: "PV", provincia: "VOLPARA", nazione: "ITALIA" },
  { codeKey: "M120", sigla: "AL", provincia: "VOLPEDO", nazione: "ITALIA" },
  { codeKey: "M121", sigla: "AL", provincia: "VOLPEGLINO", nazione: "ITALIA" },
  { codeKey: "M122", sigla: "TO", provincia: "VOLPIANO", nazione: "ITALIA" },
  { codeKey: "M123", sigla: "AL", provincia: "VOLTAGGIO", nazione: "ITALIA" },
  { codeKey: "M124", sigla: "BL", provincia: "VOLTAGO AGORDINO", nazione: "ITALIA" },
  { codeKey: "M125", sigla: "MN", provincia: "VOLTA MANTOVANA", nazione: "ITALIA" },
  { codeKey: "M126", sigla: "PI", provincia: "VOLTERRA", nazione: "ITALIA" },
  { codeKey: "M127", sigla: "CR", provincia: "VOLTIDO", nazione: "ITALIA" },
  { codeKey: "M130", sigla: "AV", provincia: "VOLTURARA IRPINA", nazione: "ITALIA" },
  { codeKey: "M131", sigla: "FG", provincia: "VOLTURARA APPULA", nazione: "ITALIA" },
  { codeKey: "M132", sigla: "FG", provincia: "VOLTURINO", nazione: "ITALIA" },
  { codeKey: "M133", sigla: "TO", provincia: "VOLVERA", nazione: "ITALIA" },
  { codeKey: "M136", sigla: "CN", provincia: "VOTTIGNASCO", nazione: "ITALIA" },
  { codeKey: "M138", sigla: "VV", provincia: "ZACCANOPOLI", nazione: "ITALIA" },
  { codeKey: "M139", sigla: "CT", provincia: "ZAFFERANA ETNEA", nazione: "ITALIA" },
  { codeKey: "M140", sigla: "CZ", provincia: "ZAGARISE", nazione: "ITALIA" },
  { codeKey: "M141", sigla: "RM", provincia: "ZAGAROLO", nazione: "ITALIA" },
  { codeKey: "M142", sigla: "TN", provincia: "ZAMBANA", nazione: "ITALIA" },
  { codeKey: "M143", sigla: "VV", provincia: "ZAMBRONE", nazione: "ITALIA" },
  { codeKey: "M144", sigla: "BG", provincia: "ZANDOBBIO", nazione: "ITALIA" },
  { codeKey: "M145", sigla: "VI", provincia: "ZANE'", nazione: "ITALIA" },
  { codeKey: "M147", sigla: "BG", provincia: "ZANICA", nazione: "ITALIA" },
  { codeKey: "M150", sigla: "PV", provincia: "ZAVATTARELLO", nazione: "ITALIA" },
  { codeKey: "M152", sigla: "PV", provincia: "ZECCONE", nazione: "ITALIA" },
  { codeKey: "M153", sigla: "OR", provincia: "ZEDDIANI", nazione: "ITALIA" },
  { codeKey: "M156", sigla: "CO", provincia: "ZELBIO", nazione: "ITALIA" },
  {
    codeKey: "M158",
    sigla: "LO",
    provincia: "ZELO BUON PERSICO",
    nazione: "ITALIA",
  },
  { codeKey: "M160", sigla: "MI", provincia: "ZELO SURRIGONE", nazione: "ITALIA" },
  { codeKey: "M161", sigla: "PV", provincia: "ZEME", nazione: "ITALIA" },
  { codeKey: "M162", sigla: "PV", provincia: "ZENEVREDO", nazione: "ITALIA" },
  { codeKey: "M163", sigla: "TV", provincia: "ZENSON DI PIAVE", nazione: "ITALIA" },
  { codeKey: "M165", sigla: "PC", provincia: "ZERBA", nazione: "ITALIA" },
  { codeKey: "M166", sigla: "PV", provincia: "ZERBO", nazione: "ITALIA" },
  { codeKey: "M167", sigla: "PV", provincia: "ZERBOLO'", nazione: "ITALIA" },
  { codeKey: "M168", sigla: "OR", provincia: "ZERFALIU", nazione: "ITALIA" },
  { codeKey: "M169", sigla: "MS", provincia: "ZERI", nazione: "ITALIA" },
  { codeKey: "M170", sigla: "VI", provincia: "ZERMEGHEDO", nazione: "ITALIA" },
  { codeKey: "M171", sigla: "TV", provincia: "ZERO BRANCO", nazione: "ITALIA" },
  { codeKey: "M172", sigla: "VR", provincia: "ZEVIO", nazione: "ITALIA" },
  { codeKey: "M173", sigla: "TN", provincia: "ZIANO DI FIEMME", nazione: "ITALIA" },
  { codeKey: "M174", sigla: "PR", provincia: "ZIBELLO", nazione: "ITALIA" },
  {
    codeKey: "M176",
    sigla: "MI",
    provincia: "ZIBIDO SAN GIACOMO",
    nazione: "ITALIA",
  },
  { codeKey: "M177", sigla: "SP", provincia: "ZIGNAGO", nazione: "ITALIA" },
  { codeKey: "M178", sigla: "VR", provincia: "ZIMELLA", nazione: "ITALIA" },
  { codeKey: "M179", sigla: "BI", provincia: "ZIMONE", nazione: "ITALIA" },
  { codeKey: "M180", sigla: "PV", provincia: "ZINASCO", nazione: "ITALIA" },
  { codeKey: "M182", sigla: "GE", provincia: "ZOAGLI", nazione: "ITALIA" },
  { codeKey: "M183", sigla: "MO", provincia: "ZOCCA", nazione: "ITALIA" },
  { codeKey: "M184", sigla: "BG", provincia: "ZOGNO", nazione: "ITALIA" },
  { codeKey: "M185", sigla: "BO", provincia: "ZOLA PREDOSA", nazione: "ITALIA" },
  { codeKey: "M187", sigla: "LE", provincia: "ZOLLINO", nazione: "ITALIA" },
  { codeKey: "M188", sigla: "BS", provincia: "ZONE", nazione: "ITALIA" },
  { codeKey: "M189", sigla: "BL", provincia: "ZOPPE' DI CADORE", nazione: "ITALIA" },
  { codeKey: "M190", sigla: "PN", provincia: "ZOPPOLA", nazione: "ITALIA" },
  { codeKey: "M194", sigla: "VI", provincia: "ZOVENCEDO", nazione: "ITALIA" },
  { codeKey: "M196", sigla: "BI", provincia: "ZUBIENA", nazione: "ITALIA" },
  { codeKey: "M197", sigla: "SV", provincia: "ZUCCARELLO", nazione: "ITALIA" },
  { codeKey: "M198", sigla: "TN", provincia: "ZUCLO", nazione: "ITALIA" },
  { codeKey: "M199", sigla: "VI", provincia: "ZUGLIANO", nazione: "ITALIA" },
  { codeKey: "M200", sigla: "UD", provincia: "ZUGLIO", nazione: "ITALIA" },
  { codeKey: "M201", sigla: "BI", provincia: "ZUMAGLIA", nazione: "ITALIA" },
  { codeKey: "M202", sigla: "CS", provincia: "ZUMPANO", nazione: "ITALIA" },
  { codeKey: "M203", sigla: "AV", provincia: "ZUNGOLI", nazione: "ITALIA" },
  { codeKey: "M204", sigla: "VV", provincia: "ZUNGRI", nazione: "ITALIA" },
  { codeKey: "M207", sigla: "RM", provincia: "LARIANO", nazione: "ITALIA" },
  { codeKey: "M208", sigla: "CZ", provincia: "LAMEZIA TERME", nazione: "ITALIA" },
  { codeKey: "M209", sigla: "CI", provincia: "SANT'ANNA ARRESI", nazione: "ITALIA" },
  { codeKey: "M210", sigla: "ME", provincia: "TERME VIGLIATORE", nazione: "ITALIA" },
  { codeKey: "M211", sigla: "ME", provincia: "ACQUEDOLCI", nazione: "ITALIA" },
  { codeKey: "M212", sigla: "RM", provincia: "LADISPOLI", nazione: "ITALIA" },
  { codeKey: "M213", sigla: "RM", provincia: "ARDEA", nazione: "ITALIA" },
  { codeKey: "M214", sigla: "OT", provincia: "BADESI", nazione: "ITALIA" },
  {
    codeKey: "M253",
    sigla: "SA",
    provincia: "SICIGNANO DEGLI ALBURNI",
    nazione: "ITALIA",
  },
  { codeKey: "M255", sigla: "AQ", provincia: "MOLINA ATERNO", nazione: "ITALIA" },
  { codeKey: "M256", sigla: "MT", provincia: "SCANZANO JONICO", nazione: "ITALIA" },
  {
    codeKey: "M257",
    sigla: "SR",
    provincia: "PORTOPALO DI CAPO PASSERO",
    nazione: "ITALIA",
  },
  { codeKey: "M258", sigla: "TR", provincia: "AVIGLIANO UMBRO", nazione: "ITALIA" },
  { codeKey: "M259", sigla: "SS", provincia: "VIDDALBA", nazione: "ITALIA" },
  { codeKey: "M260", sigla: "CE", provincia: "CASAPESENNA", nazione: "ITALIA" },
  { codeKey: "M261", sigla: "LE", provincia: "CASTRO", nazione: "ITALIA" },
  { codeKey: "M262", sigla: "CE", provincia: "CELLOLE", nazione: "ITALIA" },
  { codeKey: "M263", sigla: "LE", provincia: "PORTO CESAREO", nazione: "ITALIA" },
  { codeKey: "M264", sigla: "LE", provincia: "SAN CASSIANO", nazione: "ITALIA" },
  { codeKey: "M265", sigla: "PN", provincia: "VAJONT", nazione: "ITALIA" },
  { codeKey: "M266", sigla: "FG", provincia: "ORDONA", nazione: "ITALIA" },
  { codeKey: "M267", sigla: "FG", provincia: "ZAPPONETA", nazione: "ITALIA" },
  { codeKey: "M268", sigla: "PA", provincia: "BLUFI", nazione: "ITALIA" },
  { codeKey: "M269", sigla: "PZ", provincia: "PATERNO", nazione: "ITALIA" },
  { codeKey: "M270", sigla: "CI", provincia: "MASAINAS", nazione: "ITALIA" },
  { codeKey: "M271", sigla: "CT", provincia: "MAZZARRONE", nazione: "ITALIA" },
  { codeKey: "M272", sigla: "RM", provincia: "CIAMPINO", nazione: "ITALIA" },
  {
    codeKey: "M273",
    sigla: "NA",
    provincia: "SANTA MARIA LA CARITA'",
    nazione: "ITALIA",
  },
  { codeKey: "M274", sigla: "OT", provincia: "GOLFO ARANCI", nazione: "ITALIA" },
  {
    codeKey: "M275",
    sigla: "OT",
    provincia: "LOIRI PORTO SAN PAOLO",
    nazione: "ITALIA",
  },
  {
    codeKey: "M276",
    sigla: "OT",
    provincia: "SANT'ANTONIO DI GALLURA",
    nazione: "ITALIA",
  },
  { codeKey: "M277", sigla: "RC", provincia: "SAN FERDINANDO", nazione: "ITALIA" },
  { codeKey: "M278", sigla: "CI", provincia: "VILLAPERUCCIO", nazione: "ITALIA" },
  { codeKey: "M279", sigla: "SR", provincia: "PRIOLO GARGALLO", nazione: "ITALIA" },
  { codeKey: "M280", sigla: "NA", provincia: "TRECASE", nazione: "ITALIA" },
  { codeKey: "M281", sigla: "TP", provincia: "PETROSINO", nazione: "ITALIA" },
  { codeKey: "M282", sigla: "SS", provincia: "TERGU", nazione: "ITALIA" },
  { codeKey: "M283", sigla: "CT", provincia: "MANIACE", nazione: "ITALIA" },
  {
    codeKey: "M284",
    sigla: "SS",
    provincia: "SANTA MARIA COGHINAS",
    nazione: "ITALIA",
  },
  { codeKey: "M285", sigla: "OG", provincia: "CARDEDU", nazione: "ITALIA" },
  { codeKey: "M286", sigla: "ME", provincia: "TORRENOVA", nazione: "ITALIA" },
  { codeKey: "M287", sigla: "CT", provincia: "RAGALNA", nazione: "ITALIA" },
  { codeKey: "M288", sigla: "CA", provincia: "CASTIADAS", nazione: "ITALIA" },
  { codeKey: "M289", sigla: "NA", provincia: "MASSA DI SOMMA", nazione: "ITALIA" },
  { codeKey: "M290", sigla: "SS", provincia: "STINTINO", nazione: "ITALIA" },
  { codeKey: "M291", sigla: "CI", provincia: "PISCINAS", nazione: "ITALIA" },
  { codeKey: "M292", sigla: "SS", provincia: "ERULA", nazione: "ITALIA" },
  { codeKey: "M294", sigla: "SA", provincia: "BELLIZZI", nazione: "ITALIA" },
  { codeKey: "M295", sigla: "RM", provincia: "SAN CESAREO", nazione: "ITALIA" },
  { codeKey: "M297", sigla: "RM", provincia: "FIUMICINO", nazione: "ITALIA" },
  { codeKey: "M298", sigla: "TA", provincia: "STATTE", nazione: "ITALIA" },
  { codeKey: "M300", sigla: "PD", provincia: "DUE CARRARE", nazione: "ITALIA" },
  { codeKey: "M301", sigla: "OT", provincia: "PADRU", nazione: "ITALIA" },
  {
    codeKey: "M302",
    sigla: "AT",
    provincia: "MONTIGLIO MONFERRATO",
    nazione: "ITALIA",
  },
  { codeKey: "M303", sigla: "TN", provincia: "RONZO-CHIENIS", nazione: "ITALIA" },
  { codeKey: "M304", sigla: "BI", provincia: "MOSSO", nazione: "ITALIA" },
  {
    codeKey: "M308",
    sigla: "VE",
    provincia: "CAVALLINO-TREPORTI",
    nazione: "ITALIA",
  },
  { codeKey: "M309", sigla: "RM", provincia: "FONTE NUOVA", nazione: "ITALIA" },
  {
    codeKey: "M311",
    sigla: "UD",
    provincia: "CAMPOLONGO TAPOGLIANO",
    nazione: "ITALIA",
  },
  { codeKey: "M313", sigla: "TN", provincia: "LEDRO", nazione: "ITALIA" },
  { codeKey: "M314", sigla: "TN", provincia: "COMANO TERME", nazione: "ITALIA" },
  {
    codeKey: "M315",
    sigla: "CO",
    provincia: "GRAVEDONA  ED UNITI",
    nazione: "ITALIA",
  },
  { codeKey: "M316", sigla: "TO", provincia: "MAPPANO", nazione: "ITALIA" },
  { codeKey: "M317", sigla: "UD", provincia: "RIVIGNANO TEOR", nazione: "ITALIA" },
  { codeKey: "M318", sigla: "AN", provincia: "TRECASTELLI", nazione: "ITALIA" },
  {
    codeKey: "M319",
    sigla: "LU",
    provincia: "FABBRICHE DI VERGEMOLI",
    nazione: "ITALIA",
  },
  { codeKey: "M320", sigla: "BO", provincia: "VALSAMOGGIA", nazione: "ITALIA" },
  {
    codeKey: "M321",
    sigla: "FI",
    provincia: "FIGLINE E INCISA VALDARNO",
    nazione: "ITALIA",
  },
  {
    codeKey: "M322",
    sigla: "AR",
    provincia: "CASTELFRANCO PIANDISCO'",
    nazione: "ITALIA",
  },
  { codeKey: "M323", sigla: "FE", provincia: "FISCAGLIA", nazione: "ITALIA" },
  { codeKey: "M324", sigla: "RN", provincia: "POGGIO TORRIANA", nazione: "ITALIA" },
  { codeKey: "M325", sigla: "PR", provincia: "SISSA TRECASALI", nazione: "ITALIA" },
  {
    codeKey: "M326",
    sigla: "FI",
    provincia: "SCARPERIA E SAN PIERO",
    nazione: "ITALIA",
  },
  {
    codeKey: "M327",
    sigla: "PI",
    provincia: "CASCIANA TERME LARI",
    nazione: "ITALIA",
  },
  {
    codeKey: "M328",
    sigla: "PI",
    provincia: "CRESPINA LORENZANA",
    nazione: "ITALIA",
  },
  {
    codeKey: "M329",
    sigla: "AR",
    provincia: "PRATOVECCHIO STIA",
    nazione: "ITALIA",
  },
  { codeKey: "M330", sigla: "AV", provincia: "MONTORO", nazione: "ITALIA" },
  { codeKey: "M331", sigla: "PU", provincia: "VALLEFOGLIA", nazione: "ITALIA" },
  { codeKey: "M332", sigla: "BL", provincia: "QUERO VAS", nazione: "ITALIA" },
  {
    codeKey: "M333",
    sigla: "BG",
    provincia: "SANT'OMOBONO TERME",
    nazione: "ITALIA",
  },
  { codeKey: "M334", sigla: "BG", provincia: "VAL BREMBILLA", nazione: "ITALIA" },
  { codeKey: "M335", sigla: "CO", provincia: "BELLAGIO", nazione: "ITALIA" },
  { codeKey: "M336", sigla: "CO", provincia: "COLVERDE", nazione: "ITALIA" },
  { codeKey: "M337", sigla: "LC", provincia: "VERDERIO", nazione: "ITALIA" },
  {
    codeKey: "M338",
    sigla: "PV",
    provincia: "CORNALE E BASTIDA",
    nazione: "ITALIA",
  },
  {
    codeKey: "M339",
    sigla: "VA",
    provincia: "MACCAGNO CON PINO E VEDDASCA",
    nazione: "ITALIA",
  },
  { codeKey: "M340", sigla: "MN", provincia: "BORGO VIRGILIO", nazione: "ITALIA" },
  { codeKey: "M341", sigla: "CO", provincia: "TREMEZZINA", nazione: "ITALIA" },
  { codeKey: "M342", sigla: "BL", provincia: "LONGARONE", nazione: "ITALIA" },
  { codeKey: "M343", sigla: "TN", provincia: "VALDAONE", nazione: "ITALIA" },
  { codeKey: "M344", sigla: "TN", provincia: "PREDAIA", nazione: "ITALIA" },
  {
    codeKey: "M345",
    sigla: "TN",
    provincia: "SAN LORENZO DORSINO",
    nazione: "ITALIA",
  },
  { codeKey: "M346", sigla: "PN", provincia: "VALVASONE ARZENE", nazione: "ITALIA" },
  {
    codeKey: "M347",
    sigla: "LU",
    provincia: "SILLANO GIUNCUGNANO",
    nazione: "ITALIA",
  },
  {
    codeKey: "M348",
    sigla: "LC",
    provincia: "LA VALLETTA BRIANZA",
    nazione: "ITALIA",
  },
  { codeKey: "Z100", sigla: "EE", provincia: "ALBANIA", nazione: "ALBANIA" },
  { codeKey: "Z101", sigla: "EE", provincia: "ANDORRA", nazione: "ANDORRA" },
  { codeKey: "Z102", sigla: "EE", provincia: "AUSTRIA", nazione: "AUSTRIA" },
  { codeKey: "Z103", sigla: "EE", provincia: "BELGIO", nazione: "BELGIO" },
  { codeKey: "Z104", sigla: "EE", provincia: "BULGARIA", nazione: "BULGARIA" },
  {
    codeKey: "Z105",
    sigla: "EE",
    provincia: "CECOSLOVACCHIA",
    nazione: "CECOSLOVACCHIA",
  },
  {
    codeKey: "Z105",
    sigla: "EE",
    provincia: "CECOSLOVACCHIA",
    nazione: "CECOSLOVACCHIA",
  },
  {
    codeKey: "Z106",
    sigla: "EE",
    provincia: "CITTA' DEL VATICANO",
    nazione: "CITTA' DEL VATICANO",
  },
  { codeKey: "Z107", sigla: "EE", provincia: "DANIMARCA", nazione: "DANIMARCA" },
  {
    codeKey: "Z108",
    sigla: "EE",
    provincia: "FAER OER (ISOLE)",
    nazione: "FAER OER (ISOLE)",
  },
  { codeKey: "Z109", sigla: "EE", provincia: "FINLANDIA", nazione: "FINLANDIA" },
  { codeKey: "Z110", sigla: "EE", provincia: "FRANCIA", nazione: "FRANCIA" },
  {
    codeKey: "Z111",
    sigla: "EE",
    provincia: "GERMANIA REPUBBLICA DEMOCRATICA",
    nazione: "GERMANIA REPUBBLICA DEMOCRATICA",
  },
  { codeKey: "Z112", sigla: "EE", provincia: "GERMANIA", nazione: "GERMANIA" },
  {
    codeKey: "Z112",
    sigla: "EE",
    provincia: "GERMANIA REPUBBLICA FEDERALE",
    nazione: "GERMANIA REPUBBLICA FEDERALE",
  },
  { codeKey: "Z113", sigla: "EE", provincia: "GIBILTERRA", nazione: "GIBILTERRA" },
  {
    codeKey: "Z114",
    sigla: "EE",
    provincia: "GRAN BRETAGNA E IRLANDA DEL NORD",
    nazione: "GRAN BRETAGNA E IRLANDA DEL NORD",
  },
  { codeKey: "Z115", sigla: "EE", provincia: "GRECIA", nazione: "GRECIA" },
  {
    codeKey: "Z116",
    sigla: "EE",
    provincia: "IRLANDA=EIRE",
    nazione: "IRLANDA=EIRE",
  },
  { codeKey: "Z117", sigla: "EE", provincia: "ISLANDA", nazione: "ISLANDA" },
  { codeKey: "Z118", sigla: "EE", provincia: "IUGOSLAVIA", nazione: "IUGOSLAVIA" },
  { codeKey: "Z118", sigla: "EE", provincia: "IUGOSLAVIA", nazione: "IUGOSLAVIA" },
  { codeKey: "Z118", sigla: "EE", provincia: "IUGOSLAVIA", nazione: "IUGOSLAVIA" },
  { codeKey: "Z118", sigla: "EE", provincia: "IUGOSLAVIA", nazione: "IUGOSLAVIA" },
  { codeKey: "Z118", sigla: "EE", provincia: "IUGOSLAVIA", nazione: "IUGOSLAVIA" },
  { codeKey: "Z118", sigla: "EE", provincia: "IUGOSLAVIA", nazione: "IUGOSLAVIA" },
  { codeKey: "Z118", sigla: "EE", provincia: "IUGOSLAVIA", nazione: "IUGOSLAVIA" },
  { codeKey: "Z118", sigla: "EE", provincia: "IUGOSLAVIA", nazione: "IUGOSLAVIA" },
  {
    codeKey: "Z119",
    sigla: "EE",
    provincia: "LIECHTENSTEIN",
    nazione: "LIECHTENSTEIN",
  },
  { codeKey: "Z120", sigla: "EE", provincia: "LUSSEMBURGO", nazione: "LUSSEMBURGO" },
  { codeKey: "Z121", sigla: "EE", provincia: "MALTA", nazione: "MALTA" },
  { codeKey: "Z122", sigla: "EE", provincia: "MAN (ISOLA)", nazione: "MAN (ISOLA)" },
  { codeKey: "Z123", sigla: "EE", provincia: "MONACO", nazione: "MONACO" },
  {
    codeKey: "Z124",
    sigla: "EE",
    provincia: "NORMANNE (ISOLE)=ISOLE DEL CANALE",
    nazione: "NORMANNE (ISOLE)=ISOLE DEL CANALE",
  },
  { codeKey: "Z125", sigla: "EE", provincia: "NORVEGIA", nazione: "NORVEGIA" },
  { codeKey: "Z126", sigla: "EE", provincia: "PAESI BASSI", nazione: "PAESI BASSI" },
  {
    codeKey: "Z127",
    sigla: "EE",
    provincia: "REPUBBLICA DI POLONIA",
    nazione: "REPUBBLICA DI POLONIA",
  },
  { codeKey: "Z127", sigla: "EE", provincia: "POLONIA", nazione: "POLONIA" },
  { codeKey: "Z128", sigla: "EE", provincia: "PORTOGALLO", nazione: "PORTOGALLO" },
  { codeKey: "Z129", sigla: "EE", provincia: "ROMANIA", nazione: "ROMANIA" },
  { codeKey: "Z130", sigla: "EE", provincia: "SAN MARINO", nazione: "SAN MARINO" },
  { codeKey: "Z131", sigla: "EE", provincia: "SPAGNA", nazione: "SPAGNA" },
  { codeKey: "Z132", sigla: "EE", provincia: "SVEZIA", nazione: "SVEZIA" },
  { codeKey: "Z133", sigla: "EE", provincia: "SVIZZERA", nazione: "SVIZZERA" },
  { codeKey: "Z134", sigla: "EE", provincia: "UNGHERIA", nazione: "UNGHERIA" },
  {
    codeKey: "Z135",
    sigla: "EE",
    provincia: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
    nazione: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
  },
  {
    codeKey: "Z135",
    sigla: "EE",
    provincia: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
    nazione: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
  },
  {
    codeKey: "Z135",
    sigla: "EE",
    provincia: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
    nazione: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
  },
  {
    codeKey: "Z135",
    sigla: "EE",
    provincia: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
    nazione: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
  },
  {
    codeKey: "Z135",
    sigla: "EE",
    provincia: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
    nazione: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
  },
  {
    codeKey: "Z135",
    sigla: "EE",
    provincia: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
    nazione: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
  },
  {
    codeKey: "Z135",
    sigla: "EE",
    provincia: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
    nazione: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
  },
  {
    codeKey: "Z135",
    sigla: "EE",
    provincia: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
    nazione: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
  },
  {
    codeKey: "Z135",
    sigla: "EE",
    provincia: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
    nazione: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
  },
  {
    codeKey: "Z135",
    sigla: "EE",
    provincia: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
    nazione: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
  },
  {
    codeKey: "Z135",
    sigla: "EE",
    provincia: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
    nazione: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
  },
  {
    codeKey: "Z135",
    sigla: "EE",
    provincia: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
    nazione: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
  },
  {
    codeKey: "Z135",
    sigla: "EE",
    provincia: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
    nazione: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
  },
  {
    codeKey: "Z135",
    sigla: "EE",
    provincia: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
    nazione: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
  },
  {
    codeKey: "Z135",
    sigla: "EE",
    provincia: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
    nazione: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
  },
  {
    codeKey: "Z135",
    sigla: "EE",
    provincia: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
    nazione: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
  },
  {
    codeKey: "Z135",
    sigla: "EE",
    provincia: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
    nazione: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
  },
  {
    codeKey: "Z135",
    sigla: "EE",
    provincia: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
    nazione: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
  },
  {
    codeKey: "Z135",
    sigla: "EE",
    provincia: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
    nazione: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
  },
  {
    codeKey: "Z135",
    sigla: "EE",
    provincia: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
    nazione: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
  },
  {
    codeKey: "Z135",
    sigla: "EE",
    provincia: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
    nazione: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
  },
  {
    codeKey: "Z135",
    sigla: "EE",
    provincia: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
    nazione: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
  },
  {
    codeKey: "Z135",
    sigla: "EE",
    provincia: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
    nazione: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
  },
  {
    codeKey: "Z135",
    sigla: "EE",
    provincia: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
    nazione: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
  },
  {
    codeKey: "Z135",
    sigla: "EE",
    provincia: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
    nazione: "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
  },
  { codeKey: "Z136", sigla: "EE", provincia: "GEORGIA", nazione: "GEORGIA" },
  { codeKey: "Z137", sigla: "EE", provincia: "ARMENIA", nazione: "ARMENIA" },
  { codeKey: "Z138", sigla: "EE", provincia: "UCRAINA", nazione: "UCRAINA" },
  {
    codeKey: "Z139",
    sigla: "EE",
    provincia: "BIELORUSSIA=RUSSIA BIANCA",
    nazione: "BIELORUSSIA=RUSSIA BIANCA",
  },
  { codeKey: "Z140", sigla: "EE", provincia: "MOLDAVIA", nazione: "MOLDAVIA" },
  { codeKey: "Z141", sigla: "EE", provincia: "AZERBAIGIAN", nazione: "AZERBAIGIAN" },
  {
    codeKey: "Z142",
    sigla: "EE",
    provincia: "KIRGHIZISTAN",
    nazione: "KIRGHIZISTAN",
  },
  { codeKey: "Z143", sigla: "EE", provincia: "UZBEKISTAN", nazione: "UZBEKISTAN" },
  { codeKey: "Z144", sigla: "EE", provincia: "ESTONIA", nazione: "ESTONIA" },
  { codeKey: "Z145", sigla: "EE", provincia: "LETTONIA", nazione: "LETTONIA" },
  { codeKey: "Z146", sigla: "EE", provincia: "LITUANIA", nazione: "LITUANIA" },
  { codeKey: "Z147", sigla: "EE", provincia: "TAGIKISTAN", nazione: "TAGIKISTAN" },
  { codeKey: "Z148", sigla: "EE", provincia: "MACEDONIA", nazione: "MACEDONIA" },
  { codeKey: "Z149", sigla: "EE", provincia: "CROAZIA", nazione: "CROAZIA" },
  { codeKey: "Z150", sigla: "EE", provincia: "SLOVENIA", nazione: "SLOVENIA" },
  {
    codeKey: "Z151",
    sigla: "EE",
    provincia: "TURKEMENISTAN",
    nazione: "TURKEMENISTAN",
  },
  { codeKey: "Z152", sigla: "EE", provincia: "KAZAKISTAN", nazione: "KAZAKISTAN" },
  {
    codeKey: "Z153",
    sigla: "EE",
    provincia: "BOSNIA ED ERZEGOVINA",
    nazione: "BOSNIA ED ERZEGOVINA",
  },
  {
    codeKey: "Z154",
    sigla: "EE",
    provincia: "RUSSIA=FEDERAZIONE RUSSA",
    nazione: "RUSSIA=FEDERAZIONE RUSSA",
  },
  { codeKey: "Z155", sigla: "EE", provincia: "SLOVACCHIA", nazione: "SLOVACCHIA" },
  {
    codeKey: "Z156",
    sigla: "EE",
    provincia: "CECA REPUBBLICA",
    nazione: "CECA REPUBBLICA",
  },
  {
    codeKey: "Z157",
    sigla: "EE",
    provincia: "SERBIA E MONTENEGRO",
    nazione: "SERBIA E MONTENEGRO",
  },
  { codeKey: "Z158", sigla: "EE", provincia: "SERBIA", nazione: "SERBIA" },
  { codeKey: "Z159", sigla: "EE", provincia: "MONTENEGRO", nazione: "MONTENEGRO" },
  { codeKey: "Z160", sigla: "EE", provincia: "KOSSOVO", nazione: "KOSSOVO" },
  {
    codeKey: "Z161",
    sigla: "EE",
    provincia: "TERRITORI PALESTINESI",
    nazione: "TERRITORI PALESTINESI",
  },
  { codeKey: "Z200", sigla: "EE", provincia: "AFGHANISTAN", nazione: "AFGHANISTAN" },
  {
    codeKey: "Z201",
    sigla: "EE",
    provincia: "ARABIA MERIDIONALE FEDERAZIONE",
    nazione: "ARABIA MERIDIONALE FEDERAZIONE",
  },
  {
    codeKey: "Z202",
    sigla: "EE",
    provincia: "ARABIA MERIDIONALE PROTETTORATO",
    nazione: "ARABIA MERIDIONALE PROTETTORATO",
  },
  {
    codeKey: "Z203",
    sigla: "EE",
    provincia: "ARABIA SAUDITA",
    nazione: "ARABIA SAUDITA",
  },
  { codeKey: "Z204", sigla: "EE", provincia: "BAHREIN", nazione: "BAHREIN" },
  { codeKey: "Z204", sigla: "EE", provincia: "BAHREIN", nazione: "BAHREIN" },
  { codeKey: "Z205", sigla: "EE", provincia: "BHUTAN", nazione: "BHUTAN" },
  { codeKey: "Z206", sigla: "EE", provincia: "BIRMANIA", nazione: "BIRMANIA" },
  { codeKey: "Z207", sigla: "EE", provincia: "BRUNEI", nazione: "BRUNEI" },
  { codeKey: "Z207", sigla: "EE", provincia: "BRUNEI", nazione: "BRUNEI" },
  { codeKey: "Z208", sigla: "EE", provincia: "CAMBOGIA", nazione: "CAMBOGIA" },
  { codeKey: "Z209", sigla: "EE", provincia: "SRI LANKA", nazione: "SRI LANKA" },
  { codeKey: "Z209", sigla: "EE", provincia: "CEYLON", nazione: "CEYLON" },
  {
    codeKey: "Z210",
    sigla: "EE",
    provincia: "CINA REPUBBLICA POPOLARE",
    nazione: "CINA REPUBBLICA POPOLARE",
  },
  { codeKey: "Z211", sigla: "EE", provincia: "CIPRO", nazione: "CIPRO" },
  {
    codeKey: "Z212",
    sigla: "EE",
    provincia: "COCOS (ISOLE)",
    nazione: "COCOS (ISOLE)",
  },
  {
    codeKey: "Z213",
    sigla: "EE",
    provincia: "COREA DEL SUD",
    nazione: "COREA DEL SUD",
  },
  {
    codeKey: "Z214",
    sigla: "EE",
    provincia: "COREA DEL NORD",
    nazione: "COREA DEL NORD",
  },
  {
    codeKey: "Z215",
    sigla: "EE",
    provincia: "COSTA DEI PIRATI=TRUCIAL STATES",
    nazione: "COSTA DEI PIRATI=TRUCIAL STATES",
  },
  {
    codeKey: "Z215",
    sigla: "EE",
    provincia: "EMIRATI ARABI UNITI",
    nazione: "EMIRATI ARABI UNITI",
  },
  { codeKey: "Z216", sigla: "EE", provincia: "FILIPPINE", nazione: "FILIPPINE" },
  {
    codeKey: "Z217",
    sigla: "EE",
    provincia: "REPUBBLICA DELLA CINA NAZIONALE=TAIWAN",
    nazione: "REPUBBLICA DELLA CINA NAZIONALE=TAIWAN",
  },
  {
    codeKey: "Z217",
    sigla: "EE",
    provincia: "FORMOSA=TAIWAN",
    nazione: "FORMOSA=TAIWAN",
  },
  {
    codeKey: "Z218",
    sigla: "EE",
    provincia: "GAZA (TERRITORIO DI)",
    nazione: "GAZA (TERRITORIO DI)",
  },
  { codeKey: "Z219", sigla: "EE", provincia: "GIAPPONE", nazione: "GIAPPONE" },
  { codeKey: "Z220", sigla: "EE", provincia: "GIORDANIA", nazione: "GIORDANIA" },
  { codeKey: "Z221", sigla: "EE", provincia: "HONG KONG", nazione: "HONG KONG" },
  { codeKey: "Z222", sigla: "EE", provincia: "INDIA", nazione: "INDIA" },
  { codeKey: "Z223", sigla: "EE", provincia: "INDONESIA", nazione: "INDONESIA" },
  { codeKey: "Z224", sigla: "EE", provincia: "IRAN", nazione: "IRAN" },
  { codeKey: "Z225", sigla: "EE", provincia: "IRAQ", nazione: "IRAQ" },
  { codeKey: "Z226", sigla: "EE", provincia: "ISRAELE", nazione: "ISRAELE" },
  { codeKey: "Z227", sigla: "EE", provincia: "KUWAIT", nazione: "KUWAIT" },
  { codeKey: "Z228", sigla: "EE", provincia: "LAOS", nazione: "LAOS" },
  { codeKey: "Z229", sigla: "EE", provincia: "LIBANO", nazione: "LIBANO" },
  { codeKey: "Z230", sigla: "EE", provincia: "MALAYSIA", nazione: "MALAYSIA" },
  { codeKey: "Z230", sigla: "EE", provincia: "MALAYSIA", nazione: "MALAYSIA" },
  { codeKey: "Z231", sigla: "EE", provincia: "MACAO", nazione: "MACAO" },
  { codeKey: "Z232", sigla: "EE", provincia: "MALDIVE", nazione: "MALDIVE" },
  { codeKey: "Z233", sigla: "EE", provincia: "MONGOLIA", nazione: "MONGOLIA" },
  { codeKey: "Z234", sigla: "EE", provincia: "NEPAL", nazione: "NEPAL" },
  { codeKey: "Z235", sigla: "EE", provincia: "OMAN", nazione: "OMAN" },
  { codeKey: "Z236", sigla: "EE", provincia: "PAKISTAN", nazione: "PAKISTAN" },
  { codeKey: "Z237", sigla: "EE", provincia: "QATAR", nazione: "QATAR" },
  { codeKey: "Z237", sigla: "EE", provincia: "QATAR", nazione: "QATAR" },
  {
    codeKey: "Z238",
    sigla: "EE",
    provincia: "RYUKYU (ISOLE)",
    nazione: "RYUKYU (ISOLE)",
  },
  { codeKey: "Z239", sigla: "EE", provincia: "SIKKIM", nazione: "SIKKIM" },
  { codeKey: "Z240", sigla: "EE", provincia: "SIRIA", nazione: "SIRIA" },
  { codeKey: "Z241", sigla: "EE", provincia: "THAILANDIA", nazione: "THAILANDIA" },
  {
    codeKey: "Z242",
    sigla: "EE",
    provincia: "TIMOR (ISOLA)",
    nazione: "TIMOR (ISOLA)",
  },
  { codeKey: "Z243", sigla: "EE", provincia: "TURCHIA", nazione: "TURCHIA" },
  {
    codeKey: "Z244",
    sigla: "EE",
    provincia: "VIETNAM DEL SUD",
    nazione: "VIETNAM DEL SUD",
  },
  {
    codeKey: "Z245",
    sigla: "EE",
    provincia: "VIETNAM DEL NORD",
    nazione: "VIETNAM DEL NORD",
  },
  { codeKey: "Z246", sigla: "EE", provincia: "YEMEN", nazione: "YEMEN" },
  { codeKey: "Z247", sigla: "EE", provincia: "MALAYSIA", nazione: "MALAYSIA" },
  { codeKey: "Z248", sigla: "EE", provincia: "SINGAPORE", nazione: "SINGAPORE" },
  { codeKey: "Z249", sigla: "EE", provincia: "BANGLADESH", nazione: "BANGLADESH" },
  {
    codeKey: "Z250",
    sigla: "EE",
    provincia: "YEMEN REPUBBLICA DEMOCRATICA POPOLARE",
    nazione: "YEMEN REPUBBLICA DEMOCRATICA POPOLARE",
  },
  { codeKey: "Z251", sigla: "EE", provincia: "VIETNAM", nazione: "VIETNAM" },
  { codeKey: "Z252", sigla: "EE", provincia: "ARMENIA", nazione: "ARMENIA" },
  { codeKey: "Z253", sigla: "EE", provincia: "AZERBAIGIAN", nazione: "AZERBAIGIAN" },
  { codeKey: "Z254", sigla: "EE", provincia: "GEORGIA", nazione: "GEORGIA" },
  { codeKey: "Z255", sigla: "EE", provincia: "KAZAKISTAN", nazione: "KAZAKISTAN" },
  {
    codeKey: "Z256",
    sigla: "EE",
    provincia: "KIRGHIZISTAN",
    nazione: "KIRGHIZISTAN",
  },
  { codeKey: "Z257", sigla: "EE", provincia: "TAGIKISTAN", nazione: "TAGIKISTAN" },
  {
    codeKey: "Z258",
    sigla: "EE",
    provincia: "TURKEMENISTAN",
    nazione: "TURKEMENISTAN",
  },
  { codeKey: "Z259", sigla: "EE", provincia: "UZBEKISTAN", nazione: "UZBEKISTAN" },
  {
    codeKey: "Z300",
    sigla: "EE",
    provincia: "AFRICA DEL SUD-OVEST",
    nazione: "AFRICA DEL SUD-OVEST",
  },
  {
    codeKey: "Z300",
    sigla: "EE",
    provincia: "NAMIBIA=AFRICA DEL SUD OVEST",
    nazione: "NAMIBIA=AFRICA DEL SUD OVEST",
  },
  { codeKey: "Z300", sigla: "EE", provincia: "NAMIBIA", nazione: "NAMIBIA" },
  { codeKey: "Z301", sigla: "EE", provincia: "ALGERIA", nazione: "ALGERIA" },
  { codeKey: "Z302", sigla: "EE", provincia: "ANGOLA", nazione: "ANGOLA" },
  { codeKey: "Z302", sigla: "EE", provincia: "ANGOLA", nazione: "ANGOLA" },
  {
    codeKey: "Z303",
    sigla: "EE",
    provincia: "BASUTOLAND-SUD AFRICA BRITANNICO",
    nazione: "BASUTOLAND-SUD AFRICA BRITANNICO",
  },
  {
    codeKey: "Z304",
    sigla: "EE",
    provincia: "BECIUANIA-SUD AFRICA BRITANNICO",
    nazione: "BECIUANIA-SUD AFRICA BRITANNICO",
  },
  { codeKey: "Z305", sigla: "EE", provincia: "BURUNDI", nazione: "BURUNDI" },
  { codeKey: "Z306", sigla: "EE", provincia: "CAMERUN", nazione: "CAMERUN" },
  { codeKey: "Z307", sigla: "EE", provincia: "CAPO VERDE", nazione: "CAPO VERDE" },
  {
    codeKey: "Z307",
    sigla: "EE",
    provincia: "CAPO VERDE (ISOLE)",
    nazione: "CAPO VERDE (ISOLE)",
  },
  {
    codeKey: "Z308",
    sigla: "EE",
    provincia: "CENTRAFRICANA REPUBBLICA",
    nazione: "CENTRAFRICANA REPUBBLICA",
  },
  {
    codeKey: "Z308",
    sigla: "EE",
    provincia: "CENTROAFRICANA REPUBBLICA",
    nazione: "CENTROAFRICANA REPUBBLICA",
  },
  {
    codeKey: "Z308",
    sigla: "EE",
    provincia: "CENTROAFRICANO IMPERO",
    nazione: "CENTROAFRICANO IMPERO",
  },
  { codeKey: "Z309", sigla: "EE", provincia: "CIAD", nazione: "CIAD" },
  {
    codeKey: "Z310",
    sigla: "EE",
    provincia: "COMORE (ISOLE)",
    nazione: "COMORE (ISOLE)",
  },
  {
    codeKey: "Z310",
    sigla: "EE",
    provincia: "COMORE (ISOLE)",
    nazione: "COMORE (ISOLE)",
  },
  {
    codeKey: "Z310",
    sigla: "EE",
    provincia: "COMORE (ISOLE)",
    nazione: "COMORE (ISOLE)",
  },
  { codeKey: "Z310", sigla: "EE", provincia: "COMORE", nazione: "COMORE" },
  {
    codeKey: "Z311",
    sigla: "EE",
    provincia: "CONGO REPUBBLICA POPOLARE",
    nazione: "CONGO REPUBBLICA POPOLARE",
  },
  {
    codeKey: "Z311",
    sigla: "EE",
    provincia: "CONGO BRAZZAVILLE",
    nazione: "CONGO BRAZZAVILLE",
  },
  {
    codeKey: "Z312",
    sigla: "EE",
    provincia: "CONGO REPUBBLICA DEMOCRATICA",
    nazione: "CONGO REPUBBLICA DEMOCRATICA",
  },
  { codeKey: "Z312", sigla: "EE", provincia: "ZAIRE", nazione: "ZAIRE" },
  {
    codeKey: "Z312",
    sigla: "EE",
    provincia: "CONGO LEOPOLDVILLE",
    nazione: "CONGO LEOPOLDVILLE",
  },
  {
    codeKey: "Z313",
    sigla: "EE",
    provincia: "COSTA D'AVORIO",
    nazione: "COSTA D'AVORIO",
  },
  {
    codeKey: "Z314",
    sigla: "EE",
    provincia: "DAHOMEY=BENIN",
    nazione: "DAHOMEY=BENIN",
  },
  { codeKey: "Z314", sigla: "EE", provincia: "BENIN", nazione: "BENIN" },
  { codeKey: "Z315", sigla: "EE", provincia: "ETIOPIA", nazione: "ETIOPIA" },
  { codeKey: "Z315", sigla: "EE", provincia: "ETIOPIA", nazione: "ETIOPIA" },
  { codeKey: "Z315", sigla: "EE", provincia: "ETIOPIA", nazione: "ETIOPIA" },
  { codeKey: "Z316", sigla: "EE", provincia: "GABON", nazione: "GABON" },
  { codeKey: "Z317", sigla: "EE", provincia: "GAMBIA", nazione: "GAMBIA" },
  { codeKey: "Z318", sigla: "EE", provincia: "GHANA", nazione: "GHANA" },
  { codeKey: "Z319", sigla: "EE", provincia: "GUINEA", nazione: "GUINEA" },
  {
    codeKey: "Z320",
    sigla: "EE",
    provincia: "GUINEA PORTOGHESE",
    nazione: "GUINEA PORTOGHESE",
  },
  {
    codeKey: "Z320",
    sigla: "EE",
    provincia: "GUINEA BISSAU",
    nazione: "GUINEA BISSAU",
  },
  {
    codeKey: "Z321",
    sigla: "EE",
    provincia: "GUINEA SPAGNOLA",
    nazione: "GUINEA SPAGNOLA",
  },
  {
    codeKey: "Z321",
    sigla: "EE",
    provincia: "GUINEA EQUATORIALE",
    nazione: "GUINEA EQUATORIALE",
  },
  { codeKey: "Z322", sigla: "EE", provincia: "KENYA", nazione: "KENYA" },
  { codeKey: "Z323", sigla: "EE", provincia: "IFNI", nazione: "IFNI" },
  { codeKey: "Z323", sigla: "EE", provincia: "IFNI", nazione: "IFNI" },
  {
    codeKey: "Z324",
    sigla: "EE",
    provincia: "LA REUNION (ISOLA)",
    nazione: "LA REUNION (ISOLA)",
  },
  { codeKey: "Z325", sigla: "EE", provincia: "LIBERIA", nazione: "LIBERIA" },
  { codeKey: "Z326", sigla: "EE", provincia: "LIBIA", nazione: "LIBIA" },
  { codeKey: "Z327", sigla: "EE", provincia: "MADAGASCAR", nazione: "MADAGASCAR" },
  { codeKey: "Z328", sigla: "EE", provincia: "MALAWI", nazione: "MALAWI" },
  { codeKey: "Z329", sigla: "EE", provincia: "MALI", nazione: "MALI" },
  { codeKey: "Z330", sigla: "EE", provincia: "MAROCCO", nazione: "MAROCCO" },
  { codeKey: "Z331", sigla: "EE", provincia: "MAURITANIA", nazione: "MAURITANIA" },
  {
    codeKey: "Z332",
    sigla: "EE",
    provincia: "MAURIZIO (ISOLE)",
    nazione: "MAURIZIO (ISOLE)",
  },
  { codeKey: "Z332", sigla: "EE", provincia: "MAURIZIO", nazione: "MAURIZIO" },
  { codeKey: "Z333", sigla: "EE", provincia: "MOZAMBICO", nazione: "MOZAMBICO" },
  { codeKey: "Z333", sigla: "EE", provincia: "MOZAMBICO", nazione: "MOZAMBICO" },
  { codeKey: "Z334", sigla: "EE", provincia: "NIGER", nazione: "NIGER" },
  { codeKey: "Z335", sigla: "EE", provincia: "NIGERIA", nazione: "NIGERIA" },
  { codeKey: "Z336", sigla: "EE", provincia: "EGITTO", nazione: "EGITTO" },
  { codeKey: "Z337", sigla: "EE", provincia: "RHODESIA", nazione: "RHODESIA" },
  { codeKey: "Z337", sigla: "EE", provincia: "ZIMBABWE", nazione: "ZIMBABWE" },
  { codeKey: "Z338", sigla: "EE", provincia: "RUANDA", nazione: "RUANDA" },
  {
    codeKey: "Z339",
    sigla: "EE",
    provincia: "SAHARA SPAGNOLO",
    nazione: "SAHARA SPAGNOLO",
  },
  {
    codeKey: "Z339",
    sigla: "EE",
    provincia: "SAHARA SPAGNOLO",
    nazione: "SAHARA SPAGNOLO",
  },
  {
    codeKey: "Z340",
    sigla: "EE",
    provincia: "SANT'ELENA (ISOLA)",
    nazione: "SANT'ELENA (ISOLA)",
  },
  {
    codeKey: "Z341",
    sigla: "EE",
    provincia: "SAO TOME' E PRINCIPE",
    nazione: "SAO TOME' E PRINCIPE",
  },
  {
    codeKey: "Z341",
    sigla: "EE",
    provincia: "SAO TOME' E PRINCIPE (ISOLE)",
    nazione: "SAO TOME' E PRINCIPE (ISOLE)",
  },
  { codeKey: "Z342", sigla: "EE", provincia: "SEICELLE", nazione: "SEICELLE" },
  {
    codeKey: "Z342",
    sigla: "EE",
    provincia: "SEICELLE (ISOLE)",
    nazione: "SEICELLE (ISOLE)",
  },
  {
    codeKey: "Z342",
    sigla: "EE",
    provincia: "SEICELLE (ISOLE)",
    nazione: "SEICELLE (ISOLE)",
  },
  { codeKey: "Z343", sigla: "EE", provincia: "SENEGAL", nazione: "SENEGAL" },
  {
    codeKey: "Z344",
    sigla: "EE",
    provincia: "SIERRA LEONE",
    nazione: "SIERRA LEONE",
  },
  { codeKey: "Z345", sigla: "EE", provincia: "SOMALIA", nazione: "SOMALIA" },
  {
    codeKey: "Z346",
    sigla: "EE",
    provincia: "TERRITORIO FRANCESE DEGLI AFAR E DEGLI ISSA",
    nazione: "TERRITORIO FRANCESE DEGLI AFAR E DEGLI ISSA",
  },
  {
    codeKey: "Z346",
    sigla: "EE",
    provincia: "SOMALIA FRANCESE",
    nazione: "SOMALIA FRANCESE",
  },
  {
    codeKey: "Z347",
    sigla: "EE",
    provincia: "SUDAFRICANA REPUBBLICA",
    nazione: "SUDAFRICANA REPUBBLICA",
  },
  {
    codeKey: "Z347",
    sigla: "EE",
    provincia: "SUDAFRICANA REPUBBLICA",
    nazione: "SUDAFRICANA REPUBBLICA",
  },
  {
    codeKey: "Z347",
    sigla: "EE",
    provincia: "SUDAFRICANA REPUBBLICA",
    nazione: "SUDAFRICANA REPUBBLICA",
  },
  {
    codeKey: "Z347",
    sigla: "EE",
    provincia: "SUDAFRICANA REPUBBLICA",
    nazione: "SUDAFRICANA REPUBBLICA",
  },
  {
    codeKey: "Z347",
    sigla: "EE",
    provincia: "SUDAFRICANA REPUBBLICA",
    nazione: "SUDAFRICANA REPUBBLICA",
  },
  {
    codeKey: "Z347",
    sigla: "EE",
    provincia: "SUDAFRICANA REPUBBLICA",
    nazione: "SUDAFRICANA REPUBBLICA",
  },
  {
    codeKey: "Z347",
    sigla: "EE",
    provincia: "SUDAFRICANA REPUBBLICA",
    nazione: "SUDAFRICANA REPUBBLICA",
  },
  {
    codeKey: "Z347",
    sigla: "EE",
    provincia: "SUDAFRICANA REPUBBLICA",
    nazione: "SUDAFRICANA REPUBBLICA",
  },
  { codeKey: "Z348", sigla: "EE", provincia: "SUDAN", nazione: "SUDAN" },
  { codeKey: "Z349", sigla: "EE", provincia: "SWAZILAND", nazione: "SWAZILAND" },
  {
    codeKey: "Z349",
    sigla: "EE",
    provincia: "SWAZILAND-SUDAFRICA BRITANNICO",
    nazione: "SWAZILAND-SUDAFRICA BRITANNICO",
  },
  { codeKey: "Z350", sigla: "EE", provincia: "TANGANICA", nazione: "TANGANICA" },
  { codeKey: "Z351", sigla: "EE", provincia: "TOGO", nazione: "TOGO" },
  { codeKey: "Z352", sigla: "EE", provincia: "TUNISIA", nazione: "TUNISIA" },
  { codeKey: "Z353", sigla: "EE", provincia: "UGANDA", nazione: "UGANDA" },
  { codeKey: "Z354", sigla: "EE", provincia: "BURKINA", nazione: "BURKINA" },
  {
    codeKey: "Z354",
    sigla: "EE",
    provincia: "VOLTA=ALTOVOLTA",
    nazione: "VOLTA=ALTOVOLTA",
  },
  { codeKey: "Z355", sigla: "EE", provincia: "ZAMBIA", nazione: "ZAMBIA" },
  { codeKey: "Z356", sigla: "EE", provincia: "ZANZIBAR", nazione: "ZANZIBAR" },
  { codeKey: "Z357", sigla: "EE", provincia: "TANZANIA", nazione: "TANZANIA" },
  { codeKey: "Z358", sigla: "EE", provincia: "BOTSWANA", nazione: "BOTSWANA" },
  { codeKey: "Z359", sigla: "EE", provincia: "LESOTHO", nazione: "LESOTHO" },
  {
    codeKey: "Z360",
    sigla: "EE",
    provincia: "MAYOTTE (ISOLA)",
    nazione: "MAYOTTE (ISOLA)",
  },
  { codeKey: "Z361", sigla: "EE", provincia: "GIBUTI", nazione: "GIBUTI" },
  {
    codeKey: "Z362",
    sigla: "EE",
    provincia: "SAHARA MERIDIONALE",
    nazione: "SAHARA MERIDIONALE",
  },
  {
    codeKey: "Z363",
    sigla: "EE",
    provincia: "SAHARA SETTENTRIONALE",
    nazione: "SAHARA SETTENTRIONALE",
  },
  {
    codeKey: "Z364",
    sigla: "EE",
    provincia: "BOPHUTHATSWANA",
    nazione: "BOPHUTHATSWANA",
  },
  { codeKey: "Z365", sigla: "EE", provincia: "TRANSKEI", nazione: "TRANSKEI" },
  { codeKey: "Z366", sigla: "EE", provincia: "VENDA", nazione: "VENDA" },
  { codeKey: "Z367", sigla: "EE", provincia: "CISKEI", nazione: "CISKEI" },
  { codeKey: "Z368", sigla: "EE", provincia: "ERITREA", nazione: "ERITREA" },
  { codeKey: "Z369", sigla: "EE", provincia: "NYASALAND", nazione: "NYASALAND" },
  { codeKey: "Z370", sigla: "EE", provincia: "CONGO BELGA", nazione: "CONGO BELGA" },
  {
    codeKey: "Z400",
    sigla: "EE",
    provincia: "BERMUDA (ISOLE)",
    nazione: "BERMUDA (ISOLE)",
  },
  { codeKey: "Z401", sigla: "EE", provincia: "CANADA", nazione: "CANADA" },
  { codeKey: "Z402", sigla: "EE", provincia: "GROENLANDIA", nazione: "GROENLANDIA" },
  {
    codeKey: "Z403",
    sigla: "EE",
    provincia: "SAINT PIERRE ET MIQUELON (ISOLE)",
    nazione: "SAINT PIERRE ET MIQUELON (ISOLE)",
  },
  {
    codeKey: "Z404",
    sigla: "EE",
    provincia: "STATI UNITI D'AMERICA",
    nazione: "STATI UNITI D'AMERICA",
  },
  {
    codeKey: "Z500",
    sigla: "EE",
    provincia: "ANTILLE BRITANNICHE",
    nazione: "ANTILLE BRITANNICHE",
  },
  {
    codeKey: "Z500",
    sigla: "EE",
    provincia: "ANTILLE BRITANNICHE",
    nazione: "ANTILLE BRITANNICHE",
  },
  {
    codeKey: "Z501",
    sigla: "EE",
    provincia: "ANTILLE OLANDESI",
    nazione: "ANTILLE OLANDESI",
  },
  {
    codeKey: "Z502",
    sigla: "EE",
    provincia: "BAHAMA (ISOLE)",
    nazione: "BAHAMA (ISOLE)",
  },
  { codeKey: "Z502", sigla: "EE", provincia: "BAHAMA", nazione: "BAHAMA" },
  { codeKey: "Z503", sigla: "EE", provincia: "COSTA RICA", nazione: "COSTA RICA" },
  { codeKey: "Z504", sigla: "EE", provincia: "CUBA", nazione: "CUBA" },
  {
    codeKey: "Z505",
    sigla: "EE",
    provincia: "DOMINICANA REPUBBLICA",
    nazione: "DOMINICANA REPUBBLICA",
  },
  { codeKey: "Z506", sigla: "EE", provincia: "EL SALVADOR", nazione: "EL SALVADOR" },
  { codeKey: "Z507", sigla: "EE", provincia: "GIAMAICA", nazione: "GIAMAICA" },
  { codeKey: "Z508", sigla: "EE", provincia: "GUADALUPA", nazione: "GUADALUPA" },
  { codeKey: "Z509", sigla: "EE", provincia: "GUATEMALA", nazione: "GUATEMALA" },
  { codeKey: "Z510", sigla: "EE", provincia: "HAITI", nazione: "HAITI" },
  { codeKey: "Z511", sigla: "EE", provincia: "HONDURAS", nazione: "HONDURAS" },
  {
    codeKey: "Z512",
    sigla: "EE",
    provincia: "HONDURAS BRITANNICO",
    nazione: "HONDURAS BRITANNICO",
  },
  { codeKey: "Z512", sigla: "EE", provincia: "BELIZE", nazione: "BELIZE" },
  { codeKey: "Z512", sigla: "EE", provincia: "BELIZE", nazione: "BELIZE" },
  { codeKey: "Z513", sigla: "EE", provincia: "MARTINICA", nazione: "MARTINICA" },
  { codeKey: "Z514", sigla: "EE", provincia: "MESSICO", nazione: "MESSICO" },
  { codeKey: "Z515", sigla: "EE", provincia: "NICARAGUA", nazione: "NICARAGUA" },
  { codeKey: "Z516", sigla: "EE", provincia: "PANAMA'", nazione: "PANAMA'" },
  {
    codeKey: "Z517",
    sigla: "EE",
    provincia: "PANAMA ZONA DEL CANALE",
    nazione: "PANAMA ZONA DEL CANALE",
  },
  {
    codeKey: "Z517",
    sigla: "EE",
    provincia: "PANAMA ZONA DEL CANALE",
    nazione: "PANAMA ZONA DEL CANALE",
  },
  { codeKey: "Z518", sigla: "EE", provincia: "PUERTO RICO", nazione: "PUERTO RICO" },
  {
    codeKey: "Z519",
    sigla: "EE",
    provincia: "TURKS E CAICOS (ISOLE)",
    nazione: "TURKS E CAICOS (ISOLE)",
  },
  {
    codeKey: "Z520",
    sigla: "EE",
    provincia: "VERGINI AMERICANE (ISOLE)",
    nazione: "VERGINI AMERICANE (ISOLE)",
  },
  {
    codeKey: "Z521",
    sigla: "EE",
    provincia: "ANTILLE BRITANNICHE",
    nazione: "ANTILLE BRITANNICHE",
  },
  {
    codeKey: "Z521",
    sigla: "EE",
    provincia: "ANTILLE BRITANNICHE",
    nazione: "ANTILLE BRITANNICHE",
  },
  { codeKey: "Z522", sigla: "EE", provincia: "BARBADOS", nazione: "BARBADOS" },
  {
    codeKey: "Z523",
    sigla: "EE",
    provincia: "ANTILLE BRITANNICHE",
    nazione: "ANTILLE BRITANNICHE",
  },
  {
    codeKey: "Z523",
    sigla: "EE",
    provincia: "ANTILLE BRITANNICHE",
    nazione: "ANTILLE BRITANNICHE",
  },
  {
    codeKey: "Z523",
    sigla: "EE",
    provincia: "ANTILLE BRITANNICHE",
    nazione: "ANTILLE BRITANNICHE",
  },
  {
    codeKey: "Z523",
    sigla: "EE",
    provincia: "ANTILLE BRITANNICHE",
    nazione: "ANTILLE BRITANNICHE",
  },
  {
    codeKey: "Z523",
    sigla: "EE",
    provincia: "ANTILLE BRITANNICHE",
    nazione: "ANTILLE BRITANNICHE",
  },
  {
    codeKey: "Z523",
    sigla: "EE",
    provincia: "ANTILLE BRITANNICHE",
    nazione: "ANTILLE BRITANNICHE",
  },
  {
    codeKey: "Z523",
    sigla: "EE",
    provincia: "ANTILLE BRITANNICHE",
    nazione: "ANTILLE BRITANNICHE",
  },
  {
    codeKey: "Z523",
    sigla: "EE",
    provincia: "ANTILLE BRITANNICHE",
    nazione: "ANTILLE BRITANNICHE",
  },
  {
    codeKey: "Z523",
    sigla: "EE",
    provincia: "ANTILLE BRITANNICHE",
    nazione: "ANTILLE BRITANNICHE",
  },
  { codeKey: "Z524", sigla: "EE", provincia: "GRENADA", nazione: "GRENADA" },
  {
    codeKey: "Z525",
    sigla: "EE",
    provincia: "VERGINI BRITANNICHE (ISOLE)",
    nazione: "VERGINI BRITANNICHE (ISOLE)",
  },
  { codeKey: "Z526", sigla: "EE", provincia: "DOMINICA", nazione: "DOMINICA" },
  { codeKey: "Z527", sigla: "EE", provincia: "SAINT LUCIA", nazione: "SAINT LUCIA" },
  {
    codeKey: "Z528",
    sigla: "EE",
    provincia: "SAINT VINCENT E GRENADINE",
    nazione: "SAINT VINCENT E GRENADINE",
  },
  {
    codeKey: "Z529",
    sigla: "EE",
    provincia: "ANGUILLA (ISOLA)",
    nazione: "ANGUILLA (ISOLA)",
  },
  {
    codeKey: "Z530",
    sigla: "EE",
    provincia: "CAYMAN (ISOLE)",
    nazione: "CAYMAN (ISOLE)",
  },
  { codeKey: "Z531", sigla: "EE", provincia: "MONTSERRAT", nazione: "MONTSERRAT" },
  {
    codeKey: "Z532",
    sigla: "EE",
    provincia: "ANTIGUA E BARBUDA",
    nazione: "ANTIGUA E BARBUDA",
  },
  {
    codeKey: "Z533",
    sigla: "EE",
    provincia: "SAINT KITTS E NEVIS=SAINT CHRISTOPHER E NEVIS",
    nazione: "SAINT KITTS E NEVIS=SAINT CHRISTOPHER E NEVIS",
  },
  { codeKey: "Z600", sigla: "EE", provincia: "ARGENTINA", nazione: "ARGENTINA" },
  { codeKey: "Z601", sigla: "EE", provincia: "BOLIVIA", nazione: "BOLIVIA" },
  { codeKey: "Z602", sigla: "EE", provincia: "BRASILE", nazione: "BRASILE" },
  { codeKey: "Z603", sigla: "EE", provincia: "CILE", nazione: "CILE" },
  { codeKey: "Z604", sigla: "EE", provincia: "COLOMBIA", nazione: "COLOMBIA" },
  { codeKey: "Z605", sigla: "EE", provincia: "ECUADOR", nazione: "ECUADOR" },
  {
    codeKey: "Z606",
    sigla: "EE",
    provincia: "GUYANA BRITANNICA",
    nazione: "GUYANA BRITANNICA",
  },
  { codeKey: "Z606", sigla: "EE", provincia: "GUYANA", nazione: "GUYANA" },
  {
    codeKey: "Z607",
    sigla: "EE",
    provincia: "GUAYANA=GUIANA FRANCESE",
    nazione: "GUAYANA=GUIANA FRANCESE",
  },
  {
    codeKey: "Z608",
    sigla: "EE",
    provincia: "GUAYANA OLANDESE",
    nazione: "GUAYANA OLANDESE",
  },
  { codeKey: "Z608", sigla: "EE", provincia: "SURINAME", nazione: "SURINAME" },
  {
    codeKey: "Z609",
    sigla: "EE",
    provincia: "MALVINE=FALKLAND (ISOLE)",
    nazione: "MALVINE=FALKLAND (ISOLE)",
  },
  { codeKey: "Z610", sigla: "EE", provincia: "PARAGUAY", nazione: "PARAGUAY" },
  { codeKey: "Z611", sigla: "EE", provincia: "PERU'", nazione: "PERU'" },
  {
    codeKey: "Z612",
    sigla: "EE",
    provincia: "TRINIDAD E TOBAGO",
    nazione: "TRINIDAD E TOBAGO",
  },
  { codeKey: "Z613", sigla: "EE", provincia: "URUGUAY", nazione: "URUGUAY" },
  { codeKey: "Z614", sigla: "EE", provincia: "VENEZUELA", nazione: "VENEZUELA" },
  { codeKey: "Z700", sigla: "EE", provincia: "AUSTRALIA", nazione: "AUSTRALIA" },
  {
    codeKey: "Z701",
    sigla: "EE",
    provincia: "CAROLINE (ISOLE)=STATI FEDERATI DI MICRONESIA",
    nazione: "CAROLINE (ISOLE)=STATI FEDERATI DI MICRONESIA",
  },
  {
    codeKey: "Z701",
    sigla: "EE",
    provincia: "CAROLINE (ISOLE)",
    nazione: "CAROLINE (ISOLE)",
  },
  {
    codeKey: "Z701",
    sigla: "EE",
    provincia: "CAROLINE (ISOLE)",
    nazione: "CAROLINE (ISOLE)",
  },
  {
    codeKey: "Z702",
    sigla: "EE",
    provincia: "CHRISTMAS (ISOLA)",
    nazione: "CHRISTMAS (ISOLA)",
  },
  {
    codeKey: "Z703",
    sigla: "EE",
    provincia: "COOK (ISOLE)",
    nazione: "COOK (ISOLE)",
  },
  { codeKey: "Z704", sigla: "EE", provincia: "FIGI=VITI", nazione: "FIGI=VITI" },
  {
    codeKey: "Z704",
    sigla: "EE",
    provincia: "FIGI=VITI (ISOLE)",
    nazione: "FIGI=VITI (ISOLE)",
  },
  {
    codeKey: "Z705",
    sigla: "EE",
    provincia: "GILBERT E ELLICE (ISOLE)",
    nazione: "GILBERT E ELLICE (ISOLE)",
  },
  {
    codeKey: "Z705",
    sigla: "EE",
    provincia: "GILBERT E ELLICE (ISOLE)",
    nazione: "GILBERT E ELLICE (ISOLE)",
  },
  {
    codeKey: "Z706",
    sigla: "EE",
    provincia: "GUAM (ISOLA)",
    nazione: "GUAM (ISOLA)",
  },
  {
    codeKey: "Z707",
    sigla: "EE",
    provincia: "IRIAN OCCIDENTALE",
    nazione: "IRIAN OCCIDENTALE",
  },
  {
    codeKey: "Z708",
    sigla: "EE",
    provincia: "MACQUARIE (ISOLE)",
    nazione: "MACQUARIE (ISOLE)",
  },
  {
    codeKey: "Z709",
    sigla: "EE",
    provincia: "MARCUS (ISOLE)",
    nazione: "MARCUS (ISOLE)",
  },
  {
    codeKey: "Z710",
    sigla: "EE",
    provincia: "MARIANNE (ISOLE)",
    nazione: "MARIANNE (ISOLE)",
  },
  {
    codeKey: "Z711",
    sigla: "EE",
    provincia: "MARSHALL (ISOLE)",
    nazione: "MARSHALL (ISOLE)",
  },
  { codeKey: "Z711", sigla: "EE", provincia: "MARSHALL", nazione: "MARSHALL" },
  {
    codeKey: "Z712",
    sigla: "EE",
    provincia: "MIDWAY (ISOLE)",
    nazione: "MIDWAY (ISOLE)",
  },
  { codeKey: "Z713", sigla: "EE", provincia: "NAURU", nazione: "NAURU" },
  {
    codeKey: "Z713",
    sigla: "EE",
    provincia: "NAURU (ISOLE)",
    nazione: "NAURU (ISOLE)",
  },
  {
    codeKey: "Z714",
    sigla: "EE",
    provincia: "NIUE=SAVAGE (ISOLE)",
    nazione: "NIUE=SAVAGE (ISOLE)",
  },
  {
    codeKey: "Z715",
    sigla: "EE",
    provincia: "NORFOLK (ISOLE E ISOLE DEL MAR DEI CORALLI)",
    nazione: "NORFOLK (ISOLE E ISOLE DEL MAR DEI CORALLI)",
  },
  {
    codeKey: "Z716",
    sigla: "EE",
    provincia: "NUOVA CALEDONIA (ISOLE E DIPENDENZE)",
    nazione: "NUOVA CALEDONIA (ISOLE E DIPENDENZE)",
  },
  {
    codeKey: "Z717",
    sigla: "EE",
    provincia: "NUOVE EBRIDI (ISOLE CONDOMINIO FRANCO-INGLESE)",
    nazione: "NUOVE EBRIDI (ISOLE CONDOMINIO FRANCO-INGLESE)",
  },
  {
    codeKey: "Z718",
    sigla: "EE",
    provincia: "NUOVA GUINEA",
    nazione: "NUOVA GUINEA",
  },
  {
    codeKey: "Z719",
    sigla: "EE",
    provincia: "NUOVA ZELANDA",
    nazione: "NUOVA ZELANDA",
  },
  { codeKey: "Z720", sigla: "EE", provincia: "PAPUASIA", nazione: "PAPUASIA" },
  {
    codeKey: "Z721",
    sigla: "EE",
    provincia: "ISOLE CILENE (PASQUA E SALA Y GOMEZ)",
    nazione: "ISOLE CILENE (PASQUA E SALA Y GOMEZ)",
  },
  {
    codeKey: "Z721",
    sigla: "EE",
    provincia: "PASQUA (ISOLA)",
    nazione: "PASQUA (ISOLA)",
  },
  {
    codeKey: "Z722",
    sigla: "EE",
    provincia: "PITCAIRN (E DIPENDENZE)",
    nazione: "PITCAIRN (E DIPENDENZE)",
  },
  {
    codeKey: "Z723",
    sigla: "EE",
    provincia: "POLINESIA FRANCESE (ISOLE)",
    nazione: "POLINESIA FRANCESE (ISOLE)",
  },
  { codeKey: "Z724", sigla: "EE", provincia: "SALOMONE", nazione: "SALOMONE" },
  {
    codeKey: "Z724",
    sigla: "EE",
    provincia: "SALOMONE (ISOLE)",
    nazione: "SALOMONE (ISOLE)",
  },
  {
    codeKey: "Z725",
    sigla: "EE",
    provincia: "SAMOA AMERICANE (ISOLE)",
    nazione: "SAMOA AMERICANE (ISOLE)",
  },
  { codeKey: "Z726", sigla: "EE", provincia: "SAMOA", nazione: "SAMOA" },
  {
    codeKey: "Z727",
    sigla: "EE",
    provincia: "TOKELAU=ISOLE DELL'UNIONE",
    nazione: "TOKELAU=ISOLE DELL'UNIONE",
  },
  {
    codeKey: "Z728",
    sigla: "EE",
    provincia: "TONGA=DEGLI AMICI (ISOLE)",
    nazione: "TONGA=DEGLI AMICI (ISOLE)",
  },
  {
    codeKey: "Z728",
    sigla: "EE",
    provincia: "TONGA=ISOLE DEGLI AMICI",
    nazione: "TONGA=ISOLE DEGLI AMICI",
  },
  {
    codeKey: "Z729",
    sigla: "EE",
    provincia: "WALLIS E FUTUNA (ISOLE)",
    nazione: "WALLIS E FUTUNA (ISOLE)",
  },
  {
    codeKey: "Z730",
    sigla: "EE",
    provincia: "PAPUA NUOVA GUINEA",
    nazione: "PAPUA NUOVA GUINEA",
  },
  { codeKey: "Z731", sigla: "EE", provincia: "KIRIBATI", nazione: "KIRIBATI" },
  { codeKey: "Z732", sigla: "EE", provincia: "TUVALU", nazione: "TUVALU" },
  { codeKey: "Z733", sigla: "EE", provincia: "VANUATU", nazione: "VANUATU" },
  { codeKey: "Z734", sigla: "EE", provincia: "PALAU", nazione: "PALAU" },
  {
    codeKey: "Z734",
    sigla: "EE",
    provincia: "PALAU REPUBBLICA",
    nazione: "PALAU REPUBBLICA",
  },
  {
    codeKey: "Z735",
    sigla: "EE",
    provincia: "MICRONESIA STATI FEDERATI",
    nazione: "MICRONESIA STATI FEDERATI",
  },
  {
    codeKey: "Z800",
    sigla: "EE",
    provincia: "DIPENDENZE CANADESI",
    nazione: "DIPENDENZE CANADESI",
  },
  {
    codeKey: "Z801",
    sigla: "EE",
    provincia: "DIPENDENZE NORVEGESI ARTICHE",
    nazione: "DIPENDENZE NORVEGESI ARTICHE",
  },
  {
    codeKey: "Z802",
    sigla: "EE",
    provincia: "DIPENDENZE SOVIETICHE",
    nazione: "DIPENDENZE SOVIETICHE",
  },
  {
    codeKey: "Z802",
    sigla: "EE",
    provincia: "DIPENDENZE RUSSE",
    nazione: "DIPENDENZE RUSSE",
  },
  {
    codeKey: "Z900",
    sigla: "EE",
    provincia: "DIPENDENZE AUSTRALIANE",
    nazione: "DIPENDENZE AUSTRALIANE",
  },
  {
    codeKey: "Z901",
    sigla: "EE",
    provincia: "DIPENDENZE BRITANNICHE",
    nazione: "DIPENDENZE BRITANNICHE",
  },
  {
    codeKey: "Z902",
    sigla: "EE",
    provincia: "DIPENDENZE FRANCESI",
    nazione: "DIPENDENZE FRANCESI",
  },
  {
    codeKey: "Z903",
    sigla: "EE",
    provincia: "DIPENDENZE NEOZELANDESI",
    nazione: "DIPENDENZE NEOZELANDESI",
  },
  {
    codeKey: "Z904",
    sigla: "EE",
    provincia: "DIPENDENZE NORVEGESI ANTARTICHE",
    nazione: "DIPENDENZE NORVEGESI ANTARTICHE",
  },
  {
    codeKey: "Z905",
    sigla: "EE",
    provincia: "DIPENDENZE STATUNITENSI",
    nazione: "DIPENDENZE STATUNITENSI",
  },
  {
    codeKey: "Z906",
    sigla: "EE",
    provincia: "DIPENDENZE SUDAFRICANE",
    nazione: "DIPENDENZE SUDAFRICANE",
  },
  { codeKey: "Z907", sigla: "EE", provincia: "SUD SUDAN", nazione: "SUD SUDAN" },
].filter(({nazione})=>newNazioni.includes(nazione));
