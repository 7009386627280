import React from "react"

import { connect } from "react-redux"

import AuthActions from "redux-store/models/auth"
import LoginComponent from "routes/domains/Login"
import { Header } from "../../shared-components"

class Login extends React.Component {
  componentDidMount() {
    if (localStorage.getItem("accountDataB")?.includes("object")) {
      localStorage.setItem("accountDataB", null)
    }
  }

  render() {
    return (
      <div className="login-component">
      <Header></Header>
      <div
          className="module container-fluid max-width_modulePopUP"
        >
        
             <LoginComponent />

      </div>
    </div>
    )
    
  }
}

const mapsStateToProps = ({ auth }) => ({})

export default connect(mapsStateToProps, { ...AuthActions })(Login)
