import React, { useEffect, useState } from "react";
import images from "themes/images";
import AuthActions from "redux-store/models/auth";
import { connect } from "react-redux";
import { Modal } from "shared-components";

import "./style.css";

const SepafinLogin = (props) => {
  const { modalProps, email, setEmail, password, setPassword, sepafinForgetPassword, sepafinLogin , accountInfo } = props;
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [forgetEmail, setForgetEmail] = useState("");
  const [forgetOrLogin, setForgetOrLogin] = useState("login");

  const emailProps = {
    login: { value: email, onChange: setEmail },
    forget: { value: forgetEmail, onChange: (e)=>setForgetEmail(e.target.value) },
  };

  const isLoginForm=forgetOrLogin === "login" 

  const toggleForm = () => setForgetOrLogin(isLoginForm ? "forget" : "login");
  const toggleInputVisbility = () => setPasswordVisibility(!passwordVisibility);

  const submit = () => {
    if (isLoginForm) {
      sepafinLogin(email, password, accountInfo.profile.username);
    } else {
      sepafinForgetPassword(forgetEmail);
    }
  };

  useEffect(() => {
    setPassword("");
    setEmail("");
  }, [setEmail, setPassword]);

  return (
    <Modal {...{ ...(modalProps || {}) }} className="sepafin-login-modal" show={true}>
      <div className="sepafin-login">
        <img src={images["SepafinLogo"]} alt="sepafin logo" />
        <p>Inserire la password che ti è stata inviata per email da Sepafin all’attivazione del conto agenzia</p>
        <label>Sepafin Username</label>
        <div className="input-container">
          <input type="text" name="email" {...emailProps[forgetOrLogin]} />
        </div>
       
       {isLoginForm && <>
        <label>Password</label>
        <div className="input-container">
          <input
            type={passwordVisibility ? "text" : "password"}
            name="password"
            value={password}
            onChange={setPassword}
          />
          <i className={`fal fa-eye${passwordVisibility ? "" : "slash"}`} onChange={toggleInputVisbility} />
        </div>
       </>}
        <div className="sepafin-login__submit">
          <button onClick={toggleForm}>{`${isLoginForm ? "Forget Password" : "Log in"}`}</button>
          <button onClick={submit}>Accedi</button>
        </div>
      </div>
    </Modal>
  );
};

const mapsStateToProps = (state) => ({
  accountInfo: state.auth.accountInfo
});

export default connect(mapsStateToProps, AuthActions)(SepafinLogin);