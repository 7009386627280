import { Checkbox, Form, Icon, Select, Tooltip } from "antd"
import React, { useEffect } from "react"
import FloatingLabelInput from "../FloatingLabelInput/FloatingLabelInput"
import DateComponent from "../DateComponent/DateComponent"
import { docType } from "../../config"
import Dragger from "antd/lib/upload/Dragger"
import { draggerProps, toBase64 } from "../../routes/domains/AdminLogin/Step1"
import CardViewToggle from "../CardViewToggle/CardViewToggle"

const { Option } = Select

function Step3({ form, formData, setFormData }) {
  const { getFieldError, getFieldDecorator, getFieldValue } = form

  useEffect(() => {
    const { day: birthDay, month: birthMonth } = formData.birthday || {}
    const { year: releaseYear } = formData.data_di_rilascio || {}

    if (birthDay && birthMonth && releaseYear) {
      const expirationYear = parseInt(releaseYear, 10) + 10

      setFormData((prevState) => ({
        ...prevState,
        data_di_scadenza: {
          day: birthDay,
          month: birthMonth,
          year: expirationYear.toString(),
        },
      }))
    }
  }, [formData.birthday, formData.data_di_rilascio, setFormData])

  return (
    <div>
      <Form.Item validateStatus={getFieldError("identity_type") ? "error" : ""}>
        {getFieldDecorator("identity_type", {
          initialValue: formData.identity_type,
          rules: [{ required: true, message: "Inserisci il tipo documento!" }],
        })(
          <Select
            placeholder="Tipo documento"
            onChange={(value) => {
              setFormData((prevState) => ({
                ...prevState,
                identity_type: value,
              }))
              form.validateFields(["identity_id"])
            }}
          >
            {docType.map((doc) => (
              <Option key={doc.id} value={doc.id}>
                {doc.name}
              </Option>
            ))}
          </Select>
        )}
        {getFieldError("identity_type") && (
          <Tooltip title={getFieldError("identity_type")[0]} placement="right">
            <Icon
              type="exclamation-circle"
              style={{
                color: "red",
                position: "absolute",
                zIndex: 3,
                top: 7,
                right: 30,
              }}
            />
          </Tooltip>
        )}
      </Form.Item>

      <Form.Item
        style={{ marginTop: 25 }}
        validateStatus={getFieldError("identity_id") ? "error" : ""}
      >
        {getFieldDecorator("identity_id", {
          initialValue: formData.identity_id,
          rules: [
            { required: true, message: "Inserisci il numero documento" },
            {
              pattern:
                getFieldValue("identity_type") === "1"
                  ? /^[A-Z]{2}\d{5}[A-Z]{2}$|^[A-Z]{2}\d{7}$/ // Carta d'identità
                  : getFieldValue("identity_type") === "2"
                  ? /^[A-Z]{2}\d{7}$/ // Patente di guida
                  : /^[A-Z]{2}\d{5}[A-Z]{2}$|^[A-Z]{2}\d{7}$/, // Passaporto
              message: "Inserisci un documento valido",
            },
          ],
        })(<FloatingLabelInput label="Numero documento" />)}
        {getFieldError("identity_id") && (
          <Tooltip title={getFieldError("identity_id")[0]} placement="right">
            <Icon
              type="exclamation-circle"
              style={{
                color: "red",
                position: "absolute",
                zIndex: 3,
                top: 18,
                right: 10,
              }}
            />
          </Tooltip>
        )}
      </Form.Item>

      <div className="Form.itemCol full">
        <Form.Item validateStatus={getFieldError("card_view") ? "error" : ""}>
          {getFieldDecorator("card_view", {
            initialValue: formData.card_view,
            rules: [{ required: true, message: "Inserisci il card view" }],
          })(
            <CardViewToggle
              initialCard={formData.card_view}
              onCardChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  card_view: e,
                }))
              }
            />
            // <Select
            //   placeholder="Document View"
            //   onChange={(value) =>
            //     setFormData((prevState) => ({
            //       ...prevState,
            //       card_view: value,
            //     }))
            //   }
            // >
            //   <Option value="1">Front</Option>
            //   <Option value="2">Back</Option>
            // </Select>
          )}
          {getFieldError("card_view") && (
            <Tooltip title={getFieldError("card_view")[0]} placement="right">
              <Icon
                type="exclamation-circle"
                style={{
                  color: "red",
                  position: "absolute",
                  zIndex: 3,
                  top: 5,
                  right: 30,
                }}
              />
            </Tooltip>
          )}
        </Form.Item>

        {parseInt(formData.card_view) === 1 && !formData.imageUrl && (
          <Dragger
            height={100}
            maxCount={1}
            {...draggerProps}
            onChange={async (e) => {
              const file = e.file.originFileObj
              const Base64 = await toBase64(file)
              setFormData((prevState) => ({
                ...prevState,
                imageUrl: Base64,
              }))
            }}
          >
            <p className="ant-upload-drag-icon">
              <i className="fal fa-cloud-upload"></i>
            </p>
            <p className="ant-upload-text">Inserire document</p>
          </Dragger>
        )}

        {/* Dragger for Back Image */}
        {parseInt(formData.card_view) === 2 && !formData.imageUrl2 && (
          <Dragger
            height={100}
            maxCount={1}
            {...draggerProps}
            onChange={async (e) => {
              const file = e.file.originFileObj
              const Base64 = await toBase64(file)
              setFormData((prevState) => ({
                ...prevState,
                imageUrl2: Base64,
              }))
            }}
          >
            <p className="ant-upload-drag-icon">
              <i className="fal fa-cloud-upload"></i>
            </p>
            <p className="ant-upload-text">Inserire document</p>
          </Dragger>
        )}

        {/* Container to display uploaded images */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            width: "100%",
          }}
        >
          {/* Front Image */}
          {formData.imageUrl && (
            <div
              className="logo-uploaded-field"
              style={{ width: "50%", display: "flex", justifyContent: "center" }}
            >
              <div>
                <img width={100} src={formData.imageUrl} alt="frontImage" />
                <p
                  onClick={() => {
                    setFormData((prevState) => ({
                      ...prevState,
                      imageUrl: "",
                    }))
                  }}
                >
                  Remove <i className="fal fa-trash"></i>
                </p>
              </div>
            </div>
          )}

          {/* Back Image */}
          {formData.imageUrl2 && (
            <div
              className="logo-uploaded-field"
              style={{ width: "50%", display: "flex", justifyContent: "center" }}
            >
              <div>
                <img width={100} src={formData.imageUrl2} alt="backImage" />
                <p
                  onClick={() => {
                    setFormData((prevState) => ({
                      ...prevState,
                      imageUrl2: "",
                    }))
                  }}
                >
                  Remove <i className="fal fa-trash"></i>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <Form.Item
        validateStatus={getFieldError("rilasciato_da") ? "error" : ""}
        style={{ marginTop: 20 }}
      >
        {getFieldDecorator("rilasciato_da", {
          rules: [
            {
              required: true,
              whitespace: true,
              message: "Inserisci il rilasciato da",
            },
          ],
          initialValue: formData.rilasciato_da,
        })(
          <Select
            placeholder="Rilasciato da"
            onChange={(value) => {
              setFormData((prevState) => ({
                ...prevState,
                rilasciato_da: value,
              }))
            }}
          >
            <Option value="1">Comune</Option>
            <Option value="10">Motorizzazione</Option>
            <Option value="13">Questura</Option>
            <Option value="14">Polizia</Option>
            <Option value="16">Commissariato</Option>
            <Option value="19">Altro</Option>
          </Select>
        )}
        {getFieldError("rilasciato_da") && (
          <Tooltip title={getFieldError("rilasciato_da")[0]} placement="right">
            <Icon
              type="exclamation-circle"
              style={{
                color: "red",
                position: "absolute",
                zIndex: 3,
                top: 7,
                right: 30,
              }}
            />
          </Tooltip>
        )}
      </Form.Item>

      <Form.Item
        style={{ marginTop: 25 }}
        validateStatus={getFieldError("luogo_di_rilascio") ? "error" : ""}
      >
        {getFieldDecorator("luogo_di_rilascio", {
          rules: [
            {
              required: true,
              whitespace: true,
              message: "Inserisci il luogo di rilascio!",
            },
          ],
          initialValue: formData.luogo_di_rilascio,
        })(<FloatingLabelInput label="Luogo di rilascio" />)}
        {getFieldError("luogo_di_rilascio") && (
          <Tooltip title={getFieldError("luogo_di_rilascio")[0]} placement="right">
            <Icon
              type="exclamation-circle"
              style={{
                color: "red",
                position: "absolute",
                zIndex: 3,
                top: 18,
                right: 10,
              }}
            />
          </Tooltip>
        )}
      </Form.Item>

      <DateComponent
        form={form}
        formData={formData}
        setFormData={setFormData}
        fieldName="data_di_rilascio"
        label="Data di rilascio"
      />

      <DateComponent
        form={form}
        formData={formData}
        setFormData={setFormData}
        fieldName="data_di_scadenza"
        label="Data di scadenza"
      />

      <Checkbox
        className="checkbox-privacy"
        style={{ marginBottom: 10 }}
        onChange={(e) => {
          setFormData((prevState) => ({
            ...prevState,
            privacy_policy: e.target.checked,
          }))
        }}
      >
        Dichiaro di aver letto e accettato l'
        <a
          href="/informativa-trattamento-dati-personali.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          Informativa sulla Privacy
        </a>{" "}
        e i
        <a
          href="/termini-e-condizioni.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          {" "}
          Termini e Condizioni
        </a>{" "}
        del servizio, e acconsento al trattamento dei miei dati personali per
        l'erogazione del servizio in conformità al GDPR.
      </Checkbox>

      <Checkbox
        onChange={(e) => {
          setFormData((prevState) => ({
            ...prevState,
            recieve_emails: e.target.checked,
          }))
        }}
      >
        Desidero ricevere comunicazioni commerciali e promozionali.
      </Checkbox>
    </div>
  )
}

export default Step3
