import React from "react";
import AdminListaUtenti from "../domains/adminPanel/adminListaUtenti/AdminListaUtenti";
import AdminPanelDom from "../domains/adminPanel/adminPanelDom";
import NewAdminUtenti from "../domains/adminPanel/adminListaUtenti/NewAdminUtenti";
import { connect } from "react-redux";
class AdminPanelListaUtenti extends React.Component {
  render() {
    return (
      <AdminPanelDom
     component={({MessaggiComponent})=>
          <NewAdminUtenti
            forAdmin={true} MessaggiComponent={MessaggiComponent}
            activeSkinId={this.props.activeSkinId}
          />
        }
        {...this.props}
      />
    );
  }
}
const mapStatToProps = (state) => ({
  activeSkinId: state.main.activeSkinId,
});
export default connect(mapStatToProps, null)(AdminPanelListaUtenti);