import React from "react"
import _ from "lodash"
import { Azioni, Header } from "shared-components"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import { get, isObject } from "lodash"
import { docType } from "config"
import images from "themes/images"
import moment from "moment"
class Configura extends React.Component {
  state = {
    pw1: "",
    pw2: "",
    pw3: "",
    pwVis1: false,
    pwVis2: false,
    pwVis3: false,
    hasError: false,
  }
  setPw = (e, index) => this.setState({ [`pw${index}`]: e.target.value })
  setPwVis = (index) =>
    this.setState((state) => ({ [`pwVis${index}`]: !state[`pwVis${index}`] }))
  setPwVisSep = (index) =>
    this.setState((state) => ({ [`pwVisSep${index}`]: !state[`pwVisSep${index}`] }))

  setPw1 = (e) => this.setPw(e, 1)
  setPw2 = (e) => this.setPw(e, 2)
  setPw3 = (e) => this.setPw(e, 3)
  setPwVis1 = () => this.setPwVis(1)
  setPwVis2 = () => this.setPwVis(2)
  setPwVis3 = () => this.setPwVis(3)
  setPwVisSep1 = () => this.setPwVisSep(1)
  setPwVisSep2 = () => this.setPwVisSep(2)

  componentDidMount() {
    const { accountInfo, usersConfigura } = this.props

    if (get(accountInfo, "profile.id") && Object.keys(usersConfigura).length < 1) {
      this.props.getConfigura(accountInfo.profile.id)
    }
  }

  handleSubmit = () => {
    const { pw1, pw2, pw3 } = this.state
    if (pw2 === pw3) {
      this.props.getChangedPassword(pw1, pw3)
      this.setState({ hasError: "ok" })
    } else {
      this.setState({ hasError: true })
    }
  }

  changeSepafinPassword = () => {
    const { SepafinUsername, Password } = this.state
    this.props.sepafinCredentials(SepafinUsername, Password)
  }

  render() {
    const { usersConfigura, pwError, userRole, accountInfo } = this.props
    const {
      a_ragione_sociale,
      a_p_iva,
      phone,
      a_comune_code,
      a_insegna,
      cap,
      personal_number,
      email,
      a_address,
      a_city,
      first_name,
      comune_code,
      birth_place,
      last_name,
      document_number,
      birthday,
      address,
      a_phone,
    } = usersConfigura || {}

    let name = ""
    docType.forEach((el) => {
      if (el.id === parseInt(usersConfigura.document_type)) {
        name = el.name
      }
    })

    const isSepaUser = accountInfo.profile.sepafin_active === 1

    console.log(isSepaUser)

    return (
      <div className={"Container" + (this.props.isMobile ? " mobileConfigura" : "")}>
        {!this.props.isMobile &&
          this.props.accountInfo?.profile.role.name !== "super_admin" &&
          this.props.accountInfo?.profile.role.name !== "agent" && <Header />}
        <div className="container-fluid overview ">
          {!this.props.isMobile &&
            this.props.accountInfo?.profile.role.name !== "super_admin" &&
            this.props.accountInfo?.profile.role.name !== "agent" && (
              <Azioni activeMain="support" active="configura"></Azioni>
            )}
          <div className="panels-container">
            <div className="row no-gutters maxWidth">
              <div className="col-md-12 configura">
                <Title
                  iconClass="store"
                  text={
                    userRole === "user" ? "Dettagli Uttente" : "Dati Punto Vendita"
                  }
                />
                <div className="row no-gutters inputsConf">
                  <div className="col-md-4">
                    <FormItem label="Ragione Sociale" value={a_ragione_sociale} />
                    <FormItem label="Partita Iva" value={a_p_iva} />
                    <FormItem label="Provincia" value={a_comune_code} />
                    <FormItem label="Telefono" value={phone} />
                  </div>
                  <div className="col-md-4">
                    <FormItem label="Insegna" value={a_insegna} />
                    <FormItem label="Codice Fiscale" value={personal_number} />
                    <FormItem label="Cap" value={cap} />
                  </div>
                  <div className="col-md-4">
                    <FormItem label="Email" value={email} />
                    <FormItem label="Indirizzo" value={a_address} />
                    <FormItem label="Località" value={a_city} />
                  </div>
                </div>
                <Title iconClass="user" text={"Rappresentante Legale"} />
                <div className="row no-gutters inputsConf">
                  <div className="col-md-4">
                    <FormItem label="Nome" value={first_name} />
                    <FormItem label="Tipo Documento" value={name} />
                    <FormItem label="Provincia" value={comune_code} />
                    <FormItem label="Comune di nascita" value={birth_place} />
                  </div>
                  <div className="col-md-4">
                    <FormItem label="Cognome" value={last_name} />
                    <FormItem label="Numero Documento" value={document_number} />
                    <FormItem label="Cap" value={cap} />
                    <FormItem
                      label="Data di nascita"
                      value={moment(birthday).format("DD/MM/YYYY")}
                    />
                  </div>
                  <div className="col-md-4">
                    <FormItem label="Codice Fiscale" value={personal_number} />
                    <FormItem label="Indirizzo" value={address} />
                    <FormItem label="Telefono" value={a_phone} />
                  </div>
                </div>
                <Title iconClass="key" text="Cambio Password" />
                <div className="row no-gutters inputsConf">
                  <h4>Sicurezza della password:</h4>
                  <p>
                    Scegli una password efficace e non riutilizzarla per altri
                    account. Se cambi la password, il tuo account verrà disconnesso
                    sui tuoi dispositivi, con alcune eccezioni. Usa almeno 8
                    caratteri. Non utilizzare una password di un altro sito o troppo
                    ovvia.{" "}
                  </p>
                  {[
                    "Password Attuale",
                    "Nuova Password",
                    "Conferma la nuova password",
                  ].map((passwordType, index) => (
                    <div className="col-md-3" key={passwordType}>
                      <FormItem
                        className="password"
                        label={passwordType}
                        disabled={false}
                        value={this.state[`pw${index + 1}`]}
                        onChange={this[[`setPw${index + 1}`]]}
                        type={this.state[`pwVis${index + 1}`] ? "text" : "password"}
                      />
                      <i
                        onClick={this[`setPwVis${index + 1}`]}
                        className={`fal fa-eye${
                          this.state[`pwVis${index + 1}`] ? "" : "-slash"
                        }`}
                      />
                    </div>
                  ))}
                  <div className="col-md-1">
                    <button className="vaiBtn" onClick={this.handleSubmit}>
                      Esegui
                    </button>
                  </div>
                </div>
                {isSepaUser && (
                  <>
                    <Title
                      src={images["SepafinLogo"]}
                      text="Sepafin Services Login"
                    />
                    <div className="row no-gutters inputsConf">
                      {["Sepafin Username", "Password"].map((label, index) => (
                        <div className="col-md-4 sepafin" key={label}>
                          <FormItem
                            label={label}
                            className="password sepafin"
                            disabled={false}
                            value={this.state[`${label.replace(" ", "")}`]}
                            onChange={(e) =>
                              this.setState({
                                [`${label.replace(" ", "")}`]: e.target.value,
                              })
                            }
                            type={
                              this.state[`pwVisSep${index + 1}`]
                                ? "text"
                                : "password"
                            }
                          />
                          <i
                            onClick={this[`setPwVisSep${index + 1}`]}
                            className={`fal fa-eye${
                              this.state[`pwVisSep${index + 1}`] ? "" : "-slash"
                            }`}
                          />
                        </div>
                      ))}
                      <div className="col-md-1 sepafin">
                        <button
                          className="vaiBtn sepafin"
                          onClick={this.changeSepafinPassword}
                        >
                          Salva
                        </button>
                      </div>
                    </div>
                  </>
                )}

                <div className="hasError">
                  {isObject(pwError) ? (
                    <React.Fragment>
                      {Object.values(pwError).map((error, ind) => {
                        return (
                          <span>
                            {ind + 1}.{error}
                          </span>
                        )
                      })}
                    </React.Fragment>
                  ) : (
                    <span>{pwError}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--Chat icon botm right corner--> */}
        <div className="chatSticky">
          <img src="img/chatSticky.svg" alt="" />
        </div>
      </div>
    )
  }
}

const FormItem = ({ label, value, defaultValue, ...otherProps }) => (
  <div className="configura__form-item">
    <label>{label}</label>
    <input
      type="text"
      defaultValue={defaultValue || ""}
      value={value || defaultValue || ""}
      disabled
      {...otherProps}
    />
  </div>
)

const Title = ({ iconClass, text, src }) => (
  <div className="titleConf">
    {src ? <img src={src} alt="" /> : <i className={`fal fa-${iconClass}`}></i>}
    <h5>{text}</h5>
  </div>
)

const mapsStateToProps = (state) => ({
  accountInfo: state.auth.accountInfo,
  usersConfigura: state.auth.usersConfigura,
  pwError: state.auth.pwError,
  userRole: state.auth.accountInfo.profile.role.name,
})

export default connect(mapsStateToProps, { ...MainActions, ...AuthActions })(
  Configura
)
