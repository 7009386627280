import React from "react"
import { connect } from "react-redux"
import { Header, Footer } from "shared-componentsMobile"
import AuthActions from "redux-store/models/auth"
import BolletiniBianchi from "./BolletiniBianchi"

const PrenotaBolletini = () => {
  const accountData = JSON.parse(localStorage.getItem("accountDataB")) || {}

  const pagamentiServices = accountData?.dashboard?.pagamenti_module?.module_ids
  return (
    <div>
      <Header />
      <BolletiniBianchi
        activeService="SBOL001"
        isPrenotaBolletino={true}
        pagamentiServices={pagamentiServices}
      />
      <Footer />
    </div>
  )
}

const mapsStateToProps = (state) => ({
  BookingBoletinoInfo: state.auth.BookingBoletinoInfo,
  detailByContoCorrente: state.auth.detailByContoCorrente,
})

export default connect(mapsStateToProps, AuthActions)(PrenotaBolletini)
