import React from "react";
import AdminPanelDom from "../domains/adminPanel/adminPanelDom";
import { connect } from "react-redux";
import NewDepositiAdmin from "./NewDepositiAdmin";

class AdminPanelDepositi extends React.Component {
  render() {
    return (
      <AdminPanelDom
     component={({MessaggiComponent})=>
         <NewDepositiAdmin forAdmin={true} MessaggiComponent={MessaggiComponent}
         activeSkinId={this.props.activeSkinId}/>
        }
        {...this.props}
      />
    );
  }
}
const mapStatToProps = (state) => ({
  activeSkinId: state.main.activeSkinId,
});
export default connect(mapStatToProps, null)(AdminPanelDepositi);