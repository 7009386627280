import React from "react"
import images from "themes/images"
import { isSepafinSelector } from "utils/HelperFunc"
import { connect } from "react-redux"

const TopWrapper = ({ services, setService, activeService }) => {
  return (
    <div className="wrapperTop">
      {services.map((service) => {
        return (
          <div
            key={service.id}
            onClick={() => setService(service)}
            className={`bolletini--services__item${
              activeService.id === service.id ? " active" : ""
            }`}
          >
            <img
              src={
                ["SBOL006", "SPPA001", "SPAGF24"].includes(service.id)
                  ? images[`${service.id}-Black-Mobile`]
                  : images[`BOLL-Black-Mobile`]
              }
              alt=""
            />
            <span>{service.name}</span>
          </div>
        )
      })}
    </div>
  )
}

const mstp = (state) => ({
  isSepafin: isSepafinSelector(state),
})

export default connect(mstp, null)(TopWrapper)
