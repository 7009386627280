import { Form, Select } from "antd"
import React from "react"
import FloatingLabelInput from "../../FloatingLabelInput/FloatingLabelInput"
import { uniqBy } from "lodash"
import countriesArray from "config/countryArr"
import AdressAutoFinder from "../../AdressAutoFinder/AdressAutoFinder"

const { Option } = Select

function Step2({ form, formData, setFormData }) {
  const { getFieldDecorator } = form

  const handleInputChange = (field) => (e) => {
    e.persist()
    setFormData((prevState) => ({
      ...prevState,
      [field]: e.target.value,
    }))
  }

  const allNazione = uniqBy(countriesArray, "nazione")

  const nazioneList = []
  if (allNazione && allNazione.length > 0) {
    for (let i = 0; i < allNazione.length; i++) {
      nazioneList.push(
        <Option key={allNazione[i].nazione} value={allNazione[i].nazione}>
          {allNazione[i].nazione}
        </Option>
      )
    }
  }

  return (
    <>
      <Form.Item hasFeedback>
        {getFieldDecorator("aRagSoc", {
          initialValue: formData.aRagSoc,
          rules: [{ required: true, message: "Inserire nome!", whitespace: true }],
        })(
          <FloatingLabelInput
            label="Ragione sociale"
            onChange={(e) => {
              handleInputChange("aRagSoc")(e)
            }}
          />
        )}
      </Form.Item>
      <Form.Item hasFeedback>
        {getFieldDecorator("aPiva", {
          initialValue: formData.aPiva,
          rules: [
            {
              required: true,
              message: "Inserire P.Iva",
              whitespace: true,
            },
            {
              pattern: /^\d{11}$/,
              message: "La P.Iva deve avere esattamente 11 numeri!",
            },
          ],
        })(
          <FloatingLabelInput
            label="P.Iva"
            onChange={(e) => {
              handleInputChange("aPiva")(e)
            }}
          />
        )}
      </Form.Item>

      <Form.Item hasFeedback>
        {getFieldDecorator("aAdress", {
          initialValue: formData.aAdress,
          rules: [
            {
              required: true,
              message: "Inserisci il tuo indirizzo!",
              whitespace: true,
            },
          ],
        })(
          <FloatingLabelInput
            label="Indirizzo"
            onChange={(e) => {
              handleInputChange("aAdress")(e)
            }}
          />
        )}
      </Form.Item>

      <AdressAutoFinder
        titles={{
          nazione: "Nazione",
          province: "Comune code ",
          comune: "Cita",
          cap: "Cap",
        }}
        capFormKey="aCap"
        form={form}
        nazione={formData.agent_nazione}
        province={formData.agent_province}
        comune={formData.agent_comune}
        setNazione={(nazione) => {
          setFormData((prevState) => ({
            ...prevState,
            agent_nazione: nazione,
          }))
        }}
        setProvince={(province) => {
          setFormData((prevState) => ({
            ...prevState,
            agent_province: province,
          }))
        }}
        setComune={(comune) => {
          setFormData((prevState) => ({
            ...prevState,
            agent_comune: comune,
          }))
        }}
      />

      <Form.Item hasFeedback style={{ marginBottom: 16 }}>
        <div className="itemCol semi" style={{ width: "50%" }}>
          <div className="inputLabel">Calcolo utile agente:</div>

          <div className="percentageInp">
            <div className="percentageInp--wrapper">
              <span
                onClick={() => {
                  setFormData((prevState) => {
                    const newValue = (parseInt(formData.percentageInp) || 0) - 10
                    return {
                      ...prevState,
                      percentageInp: newValue < 0 ? 0 : newValue,
                    }
                  })
                }}
              >
                -
              </span>
              <input
                type="text"
                value={isNaN(formData.percentageInp) ? 0 : formData.percentageInp}
                onChange={(e) => {
                  const value = parseInt(e.target.value)
                  setFormData((prevState) => ({
                    ...prevState,
                    percentageInp: isNaN(value) || value < 0 ? 0 : value,
                  }))
                }}
              />
              <span
                onClick={() => {
                  setFormData((prevState) => ({
                    ...prevState,
                    percentageInp: (parseInt(formData.percentageInp) || 0) + 10,
                  }))
                }}
              >
                +
              </span>
            </div>
            <span>
              Percentuale del utile calcolato sulle provigioni maturate dalle agenzie
              aperte
            </span>
          </div>
        </div>
      </Form.Item>
    </>
  )
}

export default Step2
