import React, { Component, Fragment } from "react";
import { notification } from "antd";
import MyInput from "./Input";
import FormSubmiter from "./FormSubmiter";
import { Radio, Checkbox, Select } from "antd";
import TelefonoInput from "./TelefonoInput";

const CheckboxGroup = Checkbox.Group;
const { Option } = Select;

class Facileristrutturare extends Component {
  state = {
    nome: "",
    cognome: "",
    email: "",
    phone: "",
    citta: "",
    cap: "",
    codice_sconto: "",
    exta_data: "",
    contact_time: "1",
    price: "0",
    superfice: "",
    nr_bagni: "",
    controsoffitti: "1",
    nr_condizionatori: "",
    impianti: "1",
    options: [
      "Accetto l'Informatva Privacy di Facileristrutturare.",
      "Acconsento al trattamento dei dati personali a fini di marketing diretto e di",
      "Acconsento al trattamento dei miei dati per l'invio di comunicazioni promozionali da parte di Facile Ristrutturare S.p.A mediante i seguenti mezzi",
      "Acconsento al trattamento dei miei dati personali per scopi di profilazione finlizzata alla personalizzazione del marketing diretto e alla pubblicità",
      "Acconsento alla cessione dei miei dati a soggetti terzi appartenenti alle categorie di seguito indicate per scopi di marketing diretto e l'elaborazione",
      "Acconsento all profilazione commerciale da parte dei soggeti terzi indicati",
    ],
    checkedList: [],
    indeterminate: true,
    checkAll: false,
  };
  componentDidMount() {
    const { TicketByTcketId } = this.props;
    this.setState({
      ...TicketByTcketId,
    });
  }
  componentDidUpdate(prevProps) {
    const { TicketByTcketId } = this.props;
    if (prevProps.TicketByTcketId !== TicketByTcketId) {
      this.setState({
        ...TicketByTcketId,
      });
    }
  }

  resetState = (msg) => {
    if (!msg.error) {
      notification["success"]({
        message: "Azione completata",
        description: msg.msg,
        placement: "bottomRight",
      });
    } else {
      notification["error"]({
        message: msg.msg[0],
        description: msg.msg[1],
        placement: "bottomRight",
        duration: 5,
      });
    }
  };

  onChange = (list) => {
    const { options } = this.state;
    this.setState({ checkedList: list });
    this.setState({
      indeterminate: !!list.length && list.length < options.length,
    });
    this.setState({ checkAll: list.length === options.length });
  };

  onCheckAllChange = (e) => {
    const { options } = this.state;
    this.setState({ checkedList: e.target.checked ? options : [] });
    this.setState({ indeterminate: false });
    this.setState({ checkAll: e.target.checked });
  };

  submitData = () => {
    const {
      nome,
      cognome,
      email,
      phone,
      citta,
      cap,
      codice_sconto,
      extra_data,
      contact_time,
      superfice,
      price,
      nr_bagni,
      controsoffitti,
      nr_condizionatori,
      impianti,
      checkedList,
    } = this.state;
    this.props.updateDataForm(
      this.props.typee,
      null,
      this.props.TicketByTcketId.nome_agenzia,
      extra_data,
      null,
      null,
      this.resetState,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      nome,
      email,
      phone,
      price,
      this.props.ticketId,
      2,
      cognome,
      phone,
      null,
      citta,
      null,
      null,
      null,
      cap,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      codice_sconto,
      contact_time,
      superfice,
      nr_bagni,
      controsoffitti,
      nr_condizionatori,
      impianti,
      checkedList
    );
  };
  render() {
    const { editable } = this.props;
    const {
      nome,
      cognome,
      email,
      phone,
      citta,
      cap,
      codice_sconto,
      price,
      extra_data,
      contact_time,
      superfice,
      nr_bagni,
      controsoffitti,
      nr_condizionatori,
      impianti,
    } = this.state;
    return (
      <React.Fragment>
        <div className="formBody">
          <div className="formBody--col">
            <Fragment>
              <MyInput
                labelName={"Nome:"}
                type={"text"}
                editable={editable}
                value={nome}
                handleChange={(e) => {
                  this.setState({ nome: e.target.value });
                }}
              />
              <MyInput
                labelName={"Cognome:"}
                type={"text"}
                editable={editable}
                value={cognome}
                handleChange={(e) => {
                  this.setState({ cognome: e.target.value });
                }}
              />
              <MyInput
                labelName={"Email:"}
                type={"text"}
                editable={editable}
                value={email}
                handleChange={(e) => {
                  this.setState({ email: e.target.value });
                }}
              />
              <TelefonoInput
                nome={nome}
                editable={editable}
                value={phone}
                set={(val) => {
                  this.setState({ phone: val });
                }}
              />
              <MyInput
                labelName={"Citta:"}
                type={"text"}
                editable={editable}
                value={citta}
                handleChange={(e) => {
                  this.setState({ citta: e.target.value });
                }}
              />
              <MyInput
                labelName={"C.A.P:"}
                type={"text"}
                editable={editable}
                value={cap}
                handleChange={(e) => {
                  this.setState({ cap: e.target.value });
                }}
              />
              <MyInput
                labelName={"Codice Sconto:"}
                type={"text"}
                editable={editable}
                value={codice_sconto}
                handleChange={(e) => {
                  this.setState({ codice_sconto: e.target.value });
                }}
              />
              <div className="itemCol full">
                <label className="inputLabel">NOTE:</label>
                <textarea
                  onChange={(e) => {
                    this.setState({ extra_data: e.target.value });
                  }}
                  value={extra_data || ""}
                />{" "}
              </div>
            </Fragment>
          </div>
          <div className="formBody--col">
            <Fragment>
            <div className="itemCol full">
            <label className="inputLabel" >
                Quando preferiresti essere ricontattato?
              </label>
              <Select
                onChange={(value) => {
                  this.setState({
                    contact_time: value,
                  });
                }}
                value={contact_time}
              >
                <Option value="1">09:00-12:30</Option>
                <Option value="2">14:00-18:00</Option>
                <Option value="3">18:00-20:00</Option>
              </Select>
              </div>
            <MyInput
              labelName={"Superfice:"}
              type={"text"}
              editable={editable}
              value={superfice}
              handleChange={(e) => {
                this.setState({ superfice: e.target.value });
              }}
            />
              <MyInput
              labelName={"Numero di Bagni:"}
              type={"text"}
              editable={editable}
              value={nr_bagni}
              handleChange={(e) => {
                this.setState({ nr_bagni: e.target.value });
              }}
            />
             <div className="itemCol full">
             <label className="inputLabel">Consegna a:</label>
              <Radio.Group
                onChange={(e) => {
                  this.setState({
                    controsoffitti: e.target.value,
                  });
                }}
                disabled
                value={controsoffitti}
              >
                <Radio value={"1"}>Si</Radio>
                <Radio value={"2"}>No</Radio>
              </Radio.Group>
              </div>
              <MyInput
              labelName={"Condizionatori:"}
              type={"text"}
              editable={editable}
              value={nr_condizionatori}
              handleChange={(e) => {
                this.setState({ nr_condizionatori: e.target.value });
              }}
            />
             <div className="itemCol full">
              <label className="inputLabel">Impianti da Rifare: </label>
              <Radio.Group
                onChange={(e) => {
                  this.setState({
                    impianti: e.target.value,
                  });
                }}
                disabled
                value={impianti}
              >
                <Radio value={"1"}>Elettrico</Radio>
                <Radio value={"2"}>Idraulico</Radio>
                <Radio value={"3"}>Gas</Radio>
                <Radio value={"4"}>Termoidrau</Radio>
              </Radio.Group>
            </div> 
              
            </Fragment>
          </div>
        </div>
        <FormSubmiter 
        price={price}
        priceChange={(e) => {
          this.setState({ price: e })
        }}
        sendOffert={this.submitData} />
      </React.Fragment>
    );
  }
}

export default Facileristrutturare;
