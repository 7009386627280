import { Modal } from "shared-components"
import React from "react"
import "./styles.css"
const NotifichePopup = ({ titolo, text, show, hide }) => {
  return (
    <span className="notifiche">
      <Modal
        tittle={
          <div className="universalModal--title">
            <h3 className="title">NOTIFICHE</h3>
            <h5 className="subtitle">{titolo}</h5>
          </div>
        }
        show={show}
        hide={hide}
      >
        <div className="NotifichePopup">
          <div className="NotifichePopup__body">{text}</div>
          <div className="NotifichePopup__buttons">
            <button>
              <i className="fal fa-check" />
              <span>LETTO</span>
            </button>
            <button>
              <i className="fal fa-times" />
              <span>CANCELLA</span>
            </button>
          </div>
        </div>
      </Modal>
    </span>
  )
}
export default NotifichePopup
