import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import { Button, Checkbox, Icon, Input, Select, Table, notification } from "antd"
import images from "themes/images"
import ContiGiocoModal from "../../shared-components/adminSharedComp/adminModals/ContiGiocoModal/ContiGiocoModal"
import AdminSubServizzi from "../domains/adminPanel/AdminSubServizzi/AdminSubServizzi"
import { withRouter } from "react-router"
import AddServiceModalForm from "../../shared-components/adminSharedComp/adminModals/AddServiceModal/AddServiceModal"
import { useParams } from "react-router-dom"
import { baseUrlImg } from "../../config/api"
import ServiziModal from "../../shared-components/adminSharedComp/adminModals/ServiziModal/ServiziModal"

const { Option } = Select

const NewServicesAdmin = (props) => {
  const {
    activeSkinId,
    screenWidth,
    MessaggiComponent,
    getAllServices,
    allServices,
    servicesLoader,
    getSelectedCompany,
    getContiAccount,
    setContiAccount,
    setNewContiStatus,
    UpdateServicesChangeStatus,
    changeServiceCategory,
    setActiveSkinId,
    setActiveSkinName,
    skinList,
    history,
    getContiGiocoServices,
    contiGiocoServices,
    updateServiceName,
    getProviders,
    providers,
    selectedCompany,
  } = props

  const { provider } = useParams()

  const [selectedSupplier, setSelectedSupplier] = useState(provider || "MrPay")
  const [filterName, setFilterName] = useState("")
  const [activeCategory, setActiveCategory] = useState("TUTTI")
  const [openModal, setOpenModal] = useState(false)
  const [service, setService] = useState("")
  const [isEditing, setIsEditing] = useState(false)
  const [editedName, setEditedName] = useState("")
  const [loading, setLoading] = useState(false)
  const [focusedRow, setFocusedRow] = useState(null)
  const [addServiceModalVisible, setAddServiceModalVisible] = useState(false)
  const [contiGiocoIds, setContiGiocoIds] = useState([])
  const [serviziModal, setServiziModal] = useState(false)

  const [visibleColumns, setVisibleColumns] = useState(() => {
    const savedColumns = localStorage.getItem("visibleColumns")
    return savedColumns
      ? JSON.parse(savedColumns)
      : {
          "Nome Servizio": true,
          Category: true,
          Provvigione: true,
          Impostazioni: true,
          Icon: false,
          Logo: false,
          Scontrino: false,
          Background: false,
          Status: true,
          Impostazioni: true,
        }
  })

  useEffect(() => {
    localStorage.setItem("visibleColumns", JSON.stringify(visibleColumns))
  }, [visibleColumns])

  useEffect(() => {
    if (skinList?.length > 0) {
      const firstSkin = skinList[0]
      if (activeSkinId === -1) {
        setActiveSkinId(firstSkin.id)
        setActiveSkinName(firstSkin.username)
        getAllServices(firstSkin.id, selectedSupplier)
      } else {
        getAllServices(activeSkinId, selectedSupplier)
      }
    }
  }, [activeSkinId, skinList])

  useEffect(() => {
    getContiGiocoServices()
    getProviders()
    history.push(`/back-office/servizi/${selectedSupplier}`)
  }, [])

  useEffect(() => {
    if (contiGiocoServices.length > 0)
      setContiGiocoIds(contiGiocoServices.map((record) => record.service_id))
  }, [contiGiocoServices])

  const handleUpdateServiceName = (id) => {
    updateServiceName(id, editedName, setLoading)
  }

  const handleCheckboxChange = (e, columnKey) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [columnKey]: e.target.checked,
    }))
  }

  const handleSettingsClick = async (record) => {
    if (record.category_id === 33) {
      window.location.href = `${window.origin + window.location.hash}/${record.id}`
    } else {
      await getSelectedCompany(record.id)
      window.location.href = `${window.origin + window.location.hash}/edit/${
        record.id
      }`
    }
  }

  const columns =
    screenWidth < 500
      ? [
          {
            title: "Nome servizio",
            dataIndex: "full_name",
            key: "full_name",
            ellipsis: true,
            width: "50%",
          },
          {
            title: "Provvigione",
            dataIndex: "provvigione",
            key: "provvigione",
            ellipsis: true,
            align: "right",
            width: "25%",
            render: (text) => `${text}€`,
          },
          {
            title: "Impostazioni",
            dataIndex: "impostazioni",
            key: "impostazioni",
            width: "25%",
            ellipsis: true,
            align: "center",
            render: (text, record) => (
              <div className="icons">
                <i
                  className="fal fa-cog"
                  onClick={() => handleSettingsClick(record)}
                />
              </div>
            ),
          },
        ]
      : [
          visibleColumns["Nome Servizio"] && {
            title: () => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <span>Nome servizio</span>
                  <i
                    className="fa-regular fa-pen-to-square"
                    style={{ position: "absolute", right: "0px", cursor: "pointer" }}
                    onClick={() => setIsEditing(!isEditing)}
                  ></i>
                </div>
              )
            },
            dataIndex: "full_name",
            key: "full_name",
            ellipsis: true,
            width: "16%",
            render: (text, record) => {
              return isEditing ? (
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Input
                    defaultValue={text}
                    onChange={(e) => setEditedName(e.target.value)}
                    style={{ width: "100%", paddingRight: 20 }}
                    onFocus={() => setFocusedRow(record.id)}
                  />

                  {focusedRow === record.id && (
                    <Icon
                      type={loading ? "loading" : "save"}
                      className="save-icon"
                      style={{
                        fontSize: 18,
                        position: "absolute",
                        right: "10px",
                        cursor: "pointer",
                        color: "#0078ff",
                      }}
                      onClick={() => handleUpdateServiceName(record.id)}
                    />
                  )}
                </div>
              ) : (
                <div>{text}</div>
              )
            },
          },
          visibleColumns.Category && {
            title: "Category",
            dataIndex: "category",
            key: "category",
            ellipsis: true,
            width: "12%",
            render: (text, record) => (
              <Select
                defaultValue={record.category}
                onChange={(value) => {
                  changeServiceCategory(record.id, value)
                }}
                style={{ width: "100%" }}
              >
                {allServices?.categories?.map((c) => (
                  <Option value={c.id} key={c.id}>
                    {c.full_name}
                  </Option>
                ))}
              </Select>
            ),
          },

          visibleColumns.Icon && {
            title: "Icona",
            dataIndex: "icon",
            key: "icon",
            ellipsis: true,
            width: screenWidth < 1490 ? "8%" : "12%",
            render: (text, record) => (
              <>
                <img
                  src={
                    record.icon
                      ? `${baseUrlImg}/${record.icon}`
                      : images["placeholder"]
                  }
                  alt="icon"
                  width={30}
                  height={30}
                  style={{ objectFit: "contain", marginRight: 10 }}
                  onClick={async () => {
                    await getSelectedCompany(record.id)
                    setServiziModal(true)
                  }}
                />

                <a
                  href={`${baseUrlImg}/${record.icon}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="image-link"
                >
                  {text?.split("/").pop()}
                </a>
              </>
            ),
          },
          visibleColumns.Logo && {
            title: "Logo",
            dataIndex: "logo",
            key: "logo",
            ellipsis: true,
            width: screenWidth < 1490 ? "8%" : "12%",
            render: (text, record) => (
              <>
                <img
                  src={
                    // selectedSupplier === "E-Pay"
                    //   ?
                    record.logo
                      ? `${baseUrlImg}/${record.logo}`
                      : images["placeholder"]
                    // : images[record.name]
                  }
                  alt="logo"
                  width={30}
                  height={30}
                  style={{ objectFit: "contain", marginRight: 10 }}
                  onClick={async () => {
                    await getSelectedCompany(record.id)
                    setServiziModal(true)
                  }}
                />
                <a
                  href={`${baseUrlImg}/${record.logo}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="image-link"
                >
                  {text?.split("/").pop()}
                </a>
              </>
            ),
          },
          visibleColumns.Scontrino && {
            title: "Logo scontrino",
            dataIndex: "scontrino",
            key: "scontrino",
            ellipsis: true,
            width: screenWidth < 1490 ? "8%" : "12%",
            render: (text, record) => (
              <>
                <img
                  src={
                    record.scontrino
                      ? `${baseUrlImg}/${record.scontrino}`
                      : images["placeholder"]
                  }
                  alt="logo"
                  width={30}
                  height={30}
                  style={{ objectFit: "contain", marginRight: 10 }}
                  onClick={async () => {
                    await getSelectedCompany(record.id)
                    setServiziModal(true)
                  }}
                />
                <a
                  href={`${baseUrlImg}/${record.scontrino}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="image-link"
                >
                  {text?.split("/").pop()}
                </a>
              </>
            ),
          },
          visibleColumns.Background && {
            title: "Background",
            dataIndex: "background",
            key: "background",
            width: screenWidth < 1490 ? "8%" : "12%",
            ellipsis: true,
            render: (text, record) => (
              <>
                <img
                  src={
                    record.background
                      ? `${baseUrlImg}/${record.background}`
                      : images["placeholder"]
                  }
                  alt="logo"
                  width={30}
                  height={30}
                  style={{ objectFit: "contain", marginRight: 10 }}
                  onClick={async () => {
                    await getSelectedCompany(record.id)
                    setServiziModal(true)
                  }}
                />
                <a
                  href={`${baseUrlImg}/${record.background}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="image-link"
                >
                  {text?.split("/").pop()}
                </a>
              </>
            ),
          },

          visibleColumns.Provvigione && {
            title: "Provvigione",
            dataIndex: "provvigione",
            key: "provvigione",
            ellipsis: true,
            align: "left",
            width: "12%",
            render: (text) => `${text.join(", ")}`,
          },
          visibleColumns.Status && {
            title: "Status",
            dataIndex: "active",
            key: "active",
            ellipsis: screenWidth < 1490 ? true : false,
            align: "center",
            width: "16%",
            render: (text, record) => (
              <>
                <Button
                  className={`${record.active === 1 ? "button-active" : ""}`}
                  style={{ marginRight: 3 }}
                  onClick={() => {
                    if (record.active === 0) {
                      UpdateServicesChangeStatus(
                        record.name,
                        record.full_name,
                        record.id,
                        true,
                        activeSkinId,
                        async () => {
                          // await notification["success"]({
                          //   message:
                          //     "Lo stato viene modificato con successo - L'impostazione del servizio è stata modificata.",
                          //   description:
                          //     "Il servizio è stato abilitato con successo.",
                          // })
                          await getAllServices(activeSkinId, selectedSupplier)
                        }
                      )
                    } else {
                      notification["success"]({
                        message: "Lo stato e gia attivato",
                      })
                    }
                  }}
                >
                  ACTIVE
                </Button>
                <Button
                  className={`${record.active === 0 ? "button-inactive" : ""}`}
                  style={{ marginLeft: 3 }}
                  onClick={() => {
                    if (record.active === 1) {
                      UpdateServicesChangeStatus(
                        record.name,
                        record.full_name,
                        record.id,
                        false,
                        activeSkinId,
                        async () => {
                          // await notification["error"]({
                          //   message:
                          //     "Lo stato viene modificato con successo - L'impostazione del servizio è stata modificata. ",
                          //   description:
                          //     " Il servizio è stato disabilitato con successo.",
                          // })
                          await getAllServices(activeSkinId, selectedSupplier)
                        }
                      )
                    } else {
                      notification["error"]({
                        message: "Lo stato e gia disattivato",
                      })
                    }
                  }}
                >
                  INACTIVE
                </Button>
              </>
            ),
          },
          visibleColumns.Impostazioni && {
            title: "Impostazioni",
            dataIndex: "impostazioni",
            key: "impostazioni",
            width: "7%",
            ellipsis: true,
            align: "center",
            render: (text, record) => {
              return (
                <div className="icons">
                  <i
                    className="fal fa-cog"
                    onClick={() => handleSettingsClick(record)}
                  />
                  {contiGiocoIds.includes(record.service_id) && (
                    <i
                      className="fal fa-user"
                      onClick={async () => {
                        await getContiAccount(record.service_id)
                        setOpenModal(true)
                        setService(record)
                      }}
                    />
                  )}
                </div>
              )
            },
          },
        ].filter(Boolean)

  const data =
    allServices &&
    allServices?.companies
      ?.filter(
        (s) =>
          s.full_name.toLowerCase().includes(filterName.toLowerCase()) &&
          (activeCategory === "TUTTI" || s.category_id === activeCategory)
      )
      .map((s) => {
        const service = allServices.services.find(
          (service) => service.company_id === s.number_id
        )
        const service_id = service ? service.service_id : null

        return {
          id: s.number_id,
          full_name: s.full_name,
          name: s.name,
          category: s.category_full_name,
          icon: s.icon,
          logo: s.logo,
          scontrino: s.scontrino,
          background: s.background,
          provvigione: s.services_agency_guadagno,
          service_id,
          active: s.active,
          category_id: s.category_id,
        }
      })

  const id = props.match.params.id

  const filterVisureServicesByCompanyId = (companyId) =>
    (allServices?.services || []).filter(
      ({ company_id }) => company_id === Number(companyId)
    )

  if (!isNaN(id)) {
    return (
      <AdminSubServizzi
        services={filterVisureServicesByCompanyId(id)}
        serviceSelected={id}
      />
    )
  }

  return (
    <>
      <ServiziModal
        open={serviziModal}
        onClose={() => setServiziModal(false)}
        selectedCompany={selectedCompany}
      />
      <ContiGiocoModal
        open={openModal}
        onClose={() => {
          setOpenModal(false)
          setContiAccount(null)
          setNewContiStatus(null)
        }}
        service={service}
      />
      <AddServiceModalForm
        isVisible={addServiceModalVisible}
        onClose={() => setAddServiceModalVisible(false)}
      />
      <div
        className="Container Annunci AllTransazioni AllServices"
        style={{ paddingBottom: screenWidth < 500 && "100px", width: "100%" }}
      >
        {MessaggiComponent}
        <div className="container-fluid overview">
          <div className="panels-container">
            <div className="sort-annunci adminAnnunci ">
              <div className="filters">
                <h1 className="headerTop">
                  <Icon type="shop" />
                  Servizi
                </h1>
                <div style={{ display: "flex" }}>
                  <Select
                    defaultValue={selectedSupplier}
                    onChange={(value) => {
                      setSelectedSupplier(value)
                      getAllServices(activeSkinId, value)
                      history.push(`/back-office/servizi/${value}`)
                    }}
                  >
                    {providers &&
                      providers.map((provider) => (
                        <Option value={provider.name} key={provider.name}>
                          <img
                            width={30}
                            height={30}
                            src={`${baseUrlImg}/icon/${provider.name.toLowerCase()}-icon.svg`}
                          />{" "}
                          {provider.name}
                        </Option>
                      ))}
                  </Select>
                  <Select
                    defaultValue={activeCategory}
                    onChange={(value) => {
                      setActiveCategory(value)
                    }}
                  >
                    <Option value="TUTTI">TUTTI</Option>
                    {allServices?.categories?.map((c) => (
                      <Option value={c.id} key={c.id}>
                        {c.full_name}
                      </Option>
                    ))}
                  </Select>
                  <Button
                    icon="plus"
                    type="primary"
                    className="add-service-btn"
                    // onClick={() => history.push("/back-office/add-product")}
                    onClick={() => setAddServiceModalVisible(true)}
                  >
                    Aggiungi Servizio
                  </Button>
                  <Input
                    className="search-service"
                    placeholder="Cerca Servizi "
                    type="text"
                    suffix={<Icon type="search" />}
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                  />
                </div>
              </div>
              {screenWidth > 600 && (
                <div className="hide-columns">
                  {Object.keys(visibleColumns).map((colKey) => (
                    <Checkbox
                      key={colKey}
                      checked={visibleColumns[colKey]}
                      onChange={(e) => handleCheckboxChange(e, colKey)}
                    >
                      {colKey}
                    </Checkbox>
                  ))}
                </div>
              )}
            </div>
            {servicesLoader && <div className="loaderAdmin"></div>}

            {!servicesLoader && (
              <Table
                className="table-striped-rows"
                style={{ width: "100%" }}
                columns={columns}
                pagination={false}
                showHeader={true}
                dataSource={data}
                rowKey={(record) => record.id}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  screenWidth: state.main.screenWidth,
  activeSkinId: state.main.activeSkinId,
  allServices: state.auth.allServices,
  servicesLoader: state.auth.servicesLoader,
  selectedCompany: state.main.selectedCompany,
  skinList: state.auth.skinList,
  contiGiocoServices: state.auth.contiGiocoServices,
  providers: state.main.providers,
})

export default connect(mapStateToProps, { ...MainActions, ...AuthActions })(
  withRouter(NewServicesAdmin)
)
