import React from "react"

const Modal = (props) =>
  props.show ? (
    <div className={`universalModal ${props.className || ""}`}>
      <div className="content">
        <div className="universalModal--header">
          {props.tittle && typeof props.tittle === "string" ? (
            <div className="universalModal--title">
              <h3>{props.tittle}</h3>
            </div>
          ) : (
            props.tittle
          )}
          { !props.disableOutsideClick && <button onClick={props.hide}>
            <i className="far fa-times" />
          </button>}
        </div>
        <div className="universalModal--main">{props.children}</div>
      </div>
      <div onClick={props.disableOutsideClick ? null : props.hide} className="modalbackdrop"></div>
    </div>
  ) : null

export default Modal
