import React, { useEffect, useState } from "react"
import { Modal, Form, Input, Button, Icon } from "antd"
import { connect } from "react-redux"
import { MainActions } from "redux-store/models"

const EditMainProviderModal = ({
  visible,
  provider,
  onClose,
  form,
  updateProvider,
}) => {
  const { getFieldDecorator, validateFields, setFieldsValue } = form
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (provider) {
      setFieldsValue({
        name: provider.name,
        categories: provider.categories || "",
        wallet: provider.wallet,
        url: provider.url || "",
        username: provider.username || "",
        password: provider.password || "",
      })
    }
  }, [provider, setFieldsValue])


  const handleSubmit = () => {
    validateFields((err, values) => {
      if (!err) {
        updateProvider(
          values.name,
          values.categories,
          values.wallet,
          values.status,
          values.url,
          values.username,
          values.password,
          provider.id,
          setLoading,
          onClose
        )
      }
    })
  }

  return (
    <Modal
      className="add-service-modal"
      title="Edit Provider"
      visible={visible}
      onCancel={onClose}
      width={500}
      footer={
        <Button type="primary" block onClick={handleSubmit} loading={loading}>
          Update Provider
        </Button>
      }
    >
      {provider && (
        <Form layout="vertical" className="add-service-form">
          <Form.Item label="Provider Name">
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Please enter the provider name!",
                },
              ],
            })(<Input />)}
          </Form.Item>

          <Form.Item label="Categories">
            {getFieldDecorator("categories", {
              rules: [
                {
                  required: false,
                  message: "Please enter categories if applicable!",
                },
              ],
            })(<Input placeholder="Enter categories" />)}
          </Form.Item>

          <Form.Item label="Wallet Balance">
            {getFieldDecorator("wallet", {
              rules: [
                {
                  required: true,
                  message: "Please enter the wallet balance!",
                },
              ],
            })(<Input type="number" />)}
          </Form.Item>

          <Form.Item label="URL">
            {getFieldDecorator("url", {
              rules: [
                {
                  required: true,
                  message: "Please enter the URL!",
                },
              ],
            })(<Input type="text" />)}
          </Form.Item>

          <Form.Item label="Username">
            {getFieldDecorator("username", {
              rules: [
                {
                  required: true,
                  message: "Please enter the username!",
                },
              ],
            })(<Input type="text" suffix={<Icon type="user" />} />)}
          </Form.Item>

          <Form.Item label="Password">
            {getFieldDecorator("password", {
              rules: [
                {
                  required: true,
                  message: "Please enter the password!",
                },
              ],
            })(<Input.Password />)}
          </Form.Item>
        </Form>
      )}
    </Modal>
  )
}

const EditProviderForm = Form.create()(EditMainProviderModal)

const mapsStateToProps = ({ main }) => ({})

export default connect(mapsStateToProps, MainActions)(EditProviderForm)
