import _, { capitalize } from "lodash";
import $ from "jquery";
import React, { useMemo, useState, useEffect } from "react";
import { notification, Radio, Select } from "antd";
import images from "themes/images";
import { Item } from "./FormsComponents";
import InviaPrenotationButton from "shared-components/InviaPrenotationButton/InviaPrenotationButton";
const { Option } = Select;

const getInitialState = (stateDetails) => {
  let newStateDetails = _.cloneDeep(stateDetails);
  Object.keys(newStateDetails).forEach((prop) => {
    const { initialValue, type } = stateDetails[prop] || {};
    newStateDetails[prop] = initialValue === undefined && (!type || type === "text") ? "" : initialValue;
  });
  return newStateDetails;
};

const PrenotationForm = (props) => {
  const { inputProps, nome_agenzia, color, title, description, name, goBack, isMobile, activeService, submit } = props;
  const [style, setStyle] = useState({ color: color });
  const hasDescription = title && description;
  const [state, setState] = useState(getInitialState(inputProps));

  const resetState = (msg) => {
    if (!msg.error) {
      setState(getInitialState(inputProps));
      notification["success"]({
        message: "Azione completata",
        description: msg.msg,
        placement: "bottomRight",
      });
    } else {
      notification["error"]({
        message: msg.msg[0],
        description: msg.msg[1],
        placement: "bottomRight",
        duration: 5,
      });
    }
  };
  const submitData = () => {
    submit(state, props, resetState);
  };

  const rightInputs = useMemo(
    () => inputProps && Object.keys(inputProps).filter((key) => inputProps[key].position === "right"),
    [inputProps]
  );
  const leftInputs = useMemo(
    () =>
      inputProps &&
      Object.keys(inputProps).filter((key) => {
        const position = inputProps[key].position;
        return !position || position === "left";
      }),
    [inputProps]
  );

  return (
    <div className="formsContainer--body animated fadeIn auto">
      {!isMobile && (
        <div className="leftForm">
          <img src={images[`${nome_agenzia}-bg`]} alt="" className="imgBg" />
          <img src={images[`${nome_agenzia}-logo`]} alt="" className="imgLogo" />
        </div>
      )}

      <div className="rightForm auto">
        <div className="rightForm--header">
          {!isMobile && (
            <div className="TitleBack" style={style}>
              <i className="fal fa-chevron-left Arrow" onClick={goBack}></i>
              {name}
            </div>
          )}

          {isMobile && (
            <div className="TitleBack">
              <i className="fal fa-file-alt"></i> {activeService}
            </div>
          )}
          <img
              style={{ width: "45px" }}
              src={images[`${nome_agenzia}-logo`]}
              alt=""
            />
        </div>
        {hasDescription && (
          <div className="rightForm--description">
            <h5 className="title">{title}</h5>
            <p className="description">{description}</p>
          </div>
        )}
        <div className={`rightForm--left wrap ${hasDescription ? "has-description" : ""}`}>
          <InputsByFields fields={leftInputs} {...{ inputProps, setState, state }} />
        </div>
        <div className={`rightForm--right wrap ${hasDescription ? "has-description" : ""}`}>
          <div>
            <InputsByFields fields={rightInputs} {...{ inputProps, setState, state }} />
             <InviaPrenotationButton handleClick={submitData} color={color} />
          </div>
         
        </div>
      </div>
    </div>
  );
};

const InputsByFields = (props) => {
  const { fields, inputProps, setState, state } = props;

  useEffect(() => {
    if ((fields || []).find((i) => inputProps && inputProps[i] && inputProps[i].mode === "multiple")) {
      $("body").addClass("multiselect-dropdown-open");
    }
    return () => {
      $("body").removeClass("multiselect-dropdown-open");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="inputs-wrapper">
      {(fields || []).map((input) => {
        const { type, label, extraClass, radioData, Icon, dropdownData, allowClear, mode, placeholder } =
          inputProps[input];
        const handleChange = (e) => setState({ ...state, [input]: e });
        const onChange = (e) => setState({ ...state, [input]: e.target.value });
        let value = state && state[input];
        const values = typeof value === "string" ? [value] : value;
        const labelText = label || capitalize(input);
        const placeholderText=placeholder || capitalize(labelText);

        return type === "dropdown" ? (
          <div className={`formsContainer--body__item ${extraClass}`}>
            <div className="label">{labelText}</div>
            <div className="select-wrapper">
              {mode === "multiple" && (
                <div className="select-wrapper__placeholder">
                  <span>{!values.length ? "Select" : values.map((id) => id.substring(0, 3)).join(" , ")}</span>
                  <i className="fal fa-chevron-down" />
                </div>
              )}
              <Select onChange={handleChange} defaultValue={value || ""} {...{ mode, allowClear }}>
                {dropdownData.map(({ value, label }) => (
                  <Option key={value} value={value}>
                    {label || value}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        ) : type === "radio" ? (
          <div className={`formsContainer--body__item Ali ${input}`}>
            <div className="label">
              {labelText}
              <span className="Red">*</span>
            </div>
            <Radio.Group onChange={onChange} value={value}>
              {radioData.map(({ value, label }) => (
                <Radio key={value} value={value}>
                  {label || value}
                </Radio>
              ))}
            </Radio.Group>
          </div>
        ) : (
          <Item
            {...{
              label: labelText,
              handleChange,
              extraClass,
              value,
              Icon,
              type,
              placeholder:placeholderText,
            }}
          />
        );
      })}
    </div>
  );
};

export default PrenotationForm;
