import { Button, Icon, Input, message, notification, Table } from "antd"
import React, { useEffect, useState } from "react"
import images from "themes/images"
import { baseUrlImg, endpoint } from "../../config/api"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { MainActions, AuthActions } from "redux-store/models"
import axios from "axios"
import AddProviderForm from "../../shared-components/adminSharedComp/adminModals/AddProvider/AddProvider"
import CreateMainProvider from "../../shared-components/adminSharedComp/adminModals/MainProvider/CreateMainProvider"
import EditMainProvider from "../../shared-components/adminSharedComp/adminModals/MainProvider/EditMainProvider"

const ProvidersAdmin = (props) => {
  const { screenWidth, history, getProviders, providers, accountInfo } = props
  const [searchQuery, setSearchQuery] = useState("")
  const [isVisibleProviderModal, setIsVisibleProviderModal] = useState(false)
  const [addMainProvider, setAddMainProvider] = useState(false)
  const [editProviderVisible, setEditProviderVisible] = useState(false)
  const [selectedProvider, setSelectedProvider] = useState({})

  const token = accountInfo?.token

  useEffect(() => {
    getProviders()
  }, [getProviders])

  const filteredData = providers.filter((provider) =>
    provider.name.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const handleUpdateProvider = (status, id) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    }
    axios
      .put(
        `${endpoint}/providers/${id}`,
        {
          status,
        },
        {
          headers,
        }
      )
      .then(() => {
        notification.success({
          message: "Provider Updated",
          description: "The provider information has been updated successfully.",
          duration: 2,
        })
        getProviders()
      })
      .catch((error) => {
        message.error("Failed to update provider!")
      })
  }

  const columns =
    screenWidth > 600
      ? [
          {
            title: "Nome Provider",
            dataIndex: "name",
            ellipsis: true,
            width: "10%",
          },
          {
            title: "Icons/Logo",
            dataIndex: "icon",
            render: (icon, record) => {
              return (
                <div>
                  <img
                    src={`${baseUrlImg}/icon/${record.name}-icon.svg`}
                    alt="icon"
                    style={{ width: "30px", height: "30px", marginRight: "10px" }}
                  />
                  <span>{record.name}-icon.svg</span>
                </div>
              )
            },
            ellipsis: true,
            width: "20%",
          },
          {
            title: "Category",
            dataIndex: "categories",
            render: (categories) => (
              <span>{categories ? categories : "No categories available"}</span>
            ),
            ellipsis: true,
            width: screenWidth > 1200 ? "20%" : "20%",
          },
          {
            title: "Credito",
            dataIndex: "wallet",
            render: (wallet) => <span>€ {parseFloat(wallet).toFixed(2)}</span>,
            ellipsis: true,
            width: "12%",
            align: "right",
          },
          {
            title: "Status",
            dataIndex: "status",
            render: (status, record) => (
              <img
                onClick={() =>
                  handleUpdateProvider(record.status === "1" ? "0" : "1", record.id)
                }
                src={images[status === "1" ? "activeBtn" : "inactiveBtn"]}
                className="activate-btn"
                alt="Activate Button"
              />
            ),
            width: "12%",
            align: "center",
          },
          {
            title: "Aggiungi",
            dataIndex: "aggiungi",
            render: (text, record) => {
              return record.name === "Exalogic" ? (
                <Button
                  type="primary"
                  icon="plus"
                  className="add-provider"
                  onClick={() => setIsVisibleProviderModal(true)}
                >
                  Add
                </Button>
              ) : null
            },
            width: "12%",
            align: "center",
          },
          {
            title: "Impostazioni",
            render: (text, record) => (
              <Button
                icon="setting"
                // onClick={() => history.push(`/back-office/servizi/${record.name}`)}
                onClick={() => {
                  setSelectedProvider(record)
                  setEditProviderVisible(true)
                }}
              >
                Settings
              </Button>
            ),
            ellipsis: true,
            width: "10%",
            align: "center",
          },
        ]
      : [
          {
            title: "Nome Provider",
            dataIndex: "name",
            ellipsis: true,
          },
          {
            title: "Credito",
            dataIndex: "wallet",
            render: (wallet) => <span>€ {parseFloat(wallet).toFixed(2)}</span>,
            ellipsis: true,
            align: "right",
          },
          {
            title: "Status",
            dataIndex: "status",
            render: (status) => (
              <img
                src={images[status === "1" ? "activeBtn" : "inactiveBtn"]}
                className="activate-btn"
                alt="Activate Button"
                height={15}
              />
            ),
            align: "center",
          },
          {
            title: "Impostazioni",
            render: (text, record) => (
              <Button
                icon="setting"
                onClick={() => {
                  setSelectedProvider(record)
                  setEditProviderVisible(true)
                }}
              ></Button>
            ),
            ellipsis: true,
            align: "center",
          },
        ]

  return (
    <>
      <CreateMainProvider
        isVisible={addMainProvider}
        onClose={() => setAddMainProvider(false)}
      />
      <AddProviderForm
        isVisible={isVisibleProviderModal}
        onClose={() => setIsVisibleProviderModal(false)}
      />

      <EditMainProvider
        visible={editProviderVisible}
        provider={selectedProvider}
        onClose={() => setEditProviderVisible(false)}
        getProviders={getProviders}
      />
      <div className="Container Annunci AllServices providers-admin">
        <div className="container-fluid overview">
          <div className="panels-container">
            <div className="provider-header">
              <div className="title">
                <Icon type="bank" /> Providers
              </div>
              <div className="filters">
                <Button
                  type="primary"
                  icon="plus"
                  onClick={() => setAddMainProvider(true)}
                >
                  Aggiungi Provider
                </Button>
                <Input
                  placeholder="Cerca"
                  suffix={<Icon type="search" />}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            <div className="providers-table">
              <Table
                dataSource={filteredData.map((provider) => ({
                  ...provider,
                  key: provider.id,
                }))}
                columns={columns}
                pagination={false}
                className="table-striped-rows"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  screenWidth: state.main.screenWidth,
  providers: state.main.providers,
  accountInfo: state.auth.accountInfo,
})

export default connect(mapStateToProps, { ...MainActions, ...AuthActions })(
  withRouter(ProvidersAdmin)
)
