import React, { Fragment } from "react"
import { docType } from "config"
import { Form } from "antd"
import { Input, Button, DatePicker, Select, Checkbox } from "antd"
import moment from "moment"
import uniqBy from "lodash/uniqBy"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import AuthActions from "redux-store/models/auth"
import { capitalize } from "lodash"
import "../../themes/css-register/register.css"
import countriesArray from "config/countryArr"
import VirtualizedSelect from "react-virtualized-select"
import { Header } from "shared-components"
import AdressAutoFinder from "shared-components/AdressAutoFinder/AdressAutoFinder"
import "react-select/dist/react-select.css"
import "react-virtualized-select/styles.css"
import FloatingLabelInput from "../../shared-components/FloatingLabelInput/FloatingLabelInput"

const { Option } = Select

const dateFormat = "DD/MM/YYYY"

class RegisterEndUser extends React.Component {
  state = {
    visible: true,
    locationData: {},
    comuniSelected: {},
    nazione: "",
    province_of_birth: "",
    city_of_birth: "",
    percentageInp: "",
    nazioneDiResidenca: "",
    residence_province: "",
    agent_nazione: "",
    agent_comune: "",
    agent_province: "",
    residence_city: "",
    codFisInps: "",
    tipoDocumento: "",
    fileType: 0,
    cardView: 0,
    sesso: "",
    nascita: "",
    imageUrl: "",
    imageUrl2: "",
    loading: false,
    step: 1,
    pagamensileInp: "",
    costoanualeInp: "",
    recieve_emails: false,
    privacy_policy: false,
  }


  validateCodiceFiscale = (e) => {
    const codFisInps = e
    const str = codFisInps

    const fiscalCodeKey = str.substring(str.length - 5, str.length - 1)
    const sexKey = parseInt(str.substring(9, 11), 10)
    const yearBKey = str.substring(6, 8)
    const monthBKey = str.substring(8, 9)

    let month = ""
    let year = 1900 + parseInt(yearBKey, 10)
    let day = sexKey % 40

    const monthMap = {
      A: "01",
      B: "02",
      C: "03",
      D: "04",
      E: "05",
      H: "06",
      L: "07",
      M: "08",
      P: "09",
      R: "10",
      S: "11",
      T: "12",
    }

    month = monthMap[monthBKey.toUpperCase()]

    if (sexKey > 40) {
      this.props.form.setFieldsValue({ gender: "F" })
    } else {
      this.props.form.setFieldsValue({ gender: "M" })
    }

    this.props.form.setFieldsValue({
      birthday: moment(`${day}-${parseInt(month)}-${year}`, "DD-MM-YYYY"),
    })

    countriesArray
      .filter((comune) => comune.codeKey.toString() === fiscalCodeKey.toString())
      .map((comune) => {
        this.setState({
          comuniSelected: comune,
          nazione: comune.nazione,
          province_of_birth: comune.sigla,
          city_of_birth: comune.provincia,
        })

        return comune
      })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err && this.state.percentageInp) {
        this.props.getRegister(
          values.first_name,
          values.last_name,
          values.nickname, // for username
          values.email,
          values.gender,
          this.state.codFisInps,
          moment(values.birthday).format("YYYY-MM-DD"),
          this.state.nazione,
          this.state.province_of_birth,
          this.state.city_of_birth,
          this.state.nazioneDiResidenca,
          this.state.residence_province,
          this.state.residence_city,
          values.address,
          values.cap,
          values.identity_id,
          values.identity_type,
          values.number_prefix,
          values.number,
          this.state.imageUrl,
          this.state.imageUrl2,
          "agent",
          values.aRagSoc,
          values.aInsegna,
          values.aPhone,
          values.aAdress,
          this.state.agent_comune,
          this.state.agent_province,
          values.aCap,
          values.aPiva,
          values.aFcode,
          values.confirm_password,
          values.password,
          values.rilasciato_da,
          values.luogo_di_rilascio,
          values.data_di_rilascio,
          values.data_di_scadenza,
          this.state.cordinateReq,
          this.state.agent_nazione,
          values.pagmens,
          this.state.privacy_policy,
          this.state.recieve_emails,
          this.state.percentageInp,
          "",
          "",
          "",
          this.props.activeSkinId
        )

        var that = this

        setTimeout(function () {
          that.setState({ visible: true })
        }, 1000)
      }
    })
  }
  render() {
    const { register, form } = this.props
    const { getFieldDecorator, getFieldValue } = form

    const {
      nazione,
      nazioneDiResidenca,
      residence_province,
      residence_city,
      agent_nazione,
      agent_comune,
      agent_province,
    } = this.state

    const allNazione = uniqBy(countriesArray, "nazione")

    const nazioneList = []
    if (allNazione && allNazione.length > 0) {
      for (let i = 0; i < allNazione.length; i++) {
        nazioneList.push(
          <Option key={allNazione[i].nazione} value={allNazione[i].nazione}>
            {allNazione[i].nazione}
          </Option>
        )
      }
    }

    let city_of_birth = []
    if (countriesArray) {
      city_of_birth = countriesArray
        .filter((items) => items.nazione === nazione.toUpperCase())
        .map((items) => {
          return {
            label: items.provincia,
            value: items.provincia,
          }
        })
    }

    let province_of_birthOptions = []
    if (countriesArray) {
      province_of_birthOptions = countriesArray
        .filter((items) => items.nazione === nazione.toUpperCase())
        .map((items) => {
          return {
            label: items.sigla,
            value: items.sigla,
          }
        })
    }

    const handleNazione = (e) => {
      this.setState({ nazione: e })
    }

    /************ RESIDENCA **********/
    const number_prefix = getFieldDecorator("number_prefix", {
      initialValue: "+39",
    })(<Input style={{ width: 70 }}></Input>)
    return (
      <Fragment>
        {!this.props.accountInfo?.profile?.activity_id &&
          this.props.accountInfo?.profile?.role?.name !== "main_admin" && (
            <Header></Header>
          )}

        <Form
          className="newReg registerAgenzia"
          style={{ marginTop: 10, maxWidth: "unset" }}
          onSubmit={this.handleSubmit}
        >
          <div className="newReg--header">Registra Agente</div>
          <div className="newReg--row" style={{borderBottom: "unset"}}>
            <div className="newReg--row__col">
              <div className="itemCol full">
                <Form.Item hasFeedback>
                  {getFieldDecorator("codFisInps", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire codice fiscale!",
                        whitespace: true,
                      },
                      {
                        pattern: new RegExp(
                          "^[A-Z]{6}\\d{2}[A-EHLMPRST]{1}\\d{2}[A-Z]{1}\\d{3}[A-Z]{1}$"
                        ),
                        message:
                          "Codice fiscale non valido! Deve essere nel formato corretto.",
                      },
                    ],
                  })(
                    <FloatingLabelInput
                      label="Codice Fiscale"
                      onChange={(e) => {
                        this.validateCodiceFiscale(e.target.value)
                        this.setState({ codFisInps: e.target.value })
                      }}
                    />
                  )}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <Form.Item hasFeedback>
                  {getFieldDecorator("first_name", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire nome!",
                        whitespace: true,
                      },
                    ],
                  })(<FloatingLabelInput label="Nome" />)}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <Form.Item hasFeedback>
                  {getFieldDecorator("last_name", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire cognome!",
                        whitespace: true,
                      },
                    ],
                  })(<FloatingLabelInput label="Cognome" />)}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <Form.Item hasFeedback>
                  {getFieldDecorator("gender", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire sesso!",
                      },
                    ],
                  })(
                    <Select placeholder="Sesso">
                      <Option value="M">Maschile</Option>
                      <Option value="F">Femminile</Option>
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <Form.Item hasFeedback>
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        type: "email",
                        message: "E-mail non è valido!",
                      },
                      {
                        required: true,
                        message: "Inserisci la tua e-mail!",
                      },
                    ],
                  })(
                    <FloatingLabelInput
                      label="Email"
                    />
                  )}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <Form.Item>
                  {getFieldDecorator("birthday", {
                    initialValue:
                      this.state.nascita !== "" &&
                      moment(this.state.nascita, dateFormat).isValid()
                        ? moment(this.state.nascita, dateFormat)
                        : null,
                    rules: [{ required: true }],
                  })(
                    <DatePicker
                      // disabledDate={(current) => {
                      //   return current && current > moment().add(-18, "years");
                      // }}
                      placeholder="Data di nascita"
                      format={("DD/MM/YYYY", "DD/MM/YYYY")}
                    />
                  )}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <Form.Item hasFeedback>
                  {nazione === "" && (
                    <Select placeholder="Nazione" onChange={(e) => handleNazione(e)}>
                      {nazioneList}
                    </Select>
                  )}
                  {nazione !== "" && (
                    <Select
                      placeholder="Nazione"
                      defaultValue={this.state.nazione}
                      onChange={(e) => handleNazione(e)}
                    >
                      {nazioneList}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <Form.Item hasFeedback>
                  <VirtualizedSelect
                    options={city_of_birth}
                    onChange={(city_of_birth) =>
                      this.setState({ city_of_birth: city_of_birth.value })
                    }
                    value={this.state.city_of_birth}
                    maxHeight={100}
                  />
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <Form.Item hasFeedback>
                  <VirtualizedSelect
                    placeholder="Provnicia"
                    options={province_of_birthOptions}
                    onChange={(province_of_birth) =>
                      this.setState({
                        province_of_birth: province_of_birth.value,
                      })
                    }
                    value={this.state.province_of_birth}
                    maxHeight={100}
                  />
                </Form.Item>
              </div>
              <div className="itemCol full">
                <Form.Item hasFeedback>
                  {getFieldDecorator("address", {
                    rules: [
                      {
                        required: true,
                        message: "Inserisci il indirizzo di residenza!",
                        whitespace: true,
                      },
                    ],
                  })(<FloatingLabelInput label="Indirizzo di residenza" />)}
                </Form.Item>
              </div>
              <AdressAutoFinder
                form={this.props.form}
                nazione={nazioneDiResidenca}
                province={residence_province}
                comune={residence_city}
                setNazione={(nazione) => {
                  this.setState({ nazioneDiResidenca: nazione })
                }}
                setProvince={(province) => {
                  this.setState({ residence_province: province })
                }}
                setComune={(comune) => {
                  this.setState({ residence_city: comune })
                }}
              />{" "}
            </div>
            <div className="newReg--row__col">
              <div className="itemCol full">
                <Form.Item>
                  {getFieldDecorator("aRagSoc", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire ragione sociale!",
                        whitespace: true,
                      },
                    ],
                  })(<FloatingLabelInput label="Ragione sociale" />)}
                </Form.Item>
              </div>

              <div className="itemCol semi">
                <Form.Item>
                  {getFieldDecorator("aPiva", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire p.Iva",
                        whitespace: true,
                      },
                    ],
                  })(<FloatingLabelInput label="P.Iva" />)}
                </Form.Item>
              </div>
              <div className="itemCol semi piva">
                <Form.Item>
                  {getFieldDecorator("pivaVerifica", {
                    rules: [
                      {
                        required: false,
                        whitespace: true,
                      },
                    ],
                  })(
                    <div>
                      <Select placeholder="Verifica P.iva">
                        <Option value="a">Validato</Option>
                        <Option value="b">Non Validato</Option>
                      </Select>
                    </div>
                  )}
                  <i className="fas fa-file-check"></i>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://telematici.agenziaentrate.gov.it/VerificaPIVA/Scegli.do?parameter=verificaPiva"
                  >
                    <i className="fas fa-link"></i>
                  </a>
                </Form.Item>
              </div>

              {/* pagamensileInp : '',
              costoanualeInp : '' */}
              <div className="itemCol full ">
                <Form.Item hasFeedback>
                  {getFieldDecorator("aAdress", {
                    rules: [
                      {
                        required: true,
                        message: "Inserisci il tuo indirizzo!",
                        whitespace: true,
                      },
                    ],
                  })(<FloatingLabelInput label="Indirizzo" />)}
                </Form.Item>
              </div>
              <AdressAutoFinder
                titles={{
                  nazione: "Nazione",
                  province: "Comune code ",
                  comune: "Cita",
                  cap: "Cap",
                }}
                capFormKey="aCap"
                form={this.props.form}
                nazione={agent_nazione}
                province={agent_province}
                comune={agent_comune}
                setNazione={(nazione) => {
                  this.setState({ agent_nazione: nazione })
                }}
                setProvince={(province) => {
                  this.setState({ agent_province: province })
                }}
                setComune={(comune) => {
                  this.setState({ agent_comune: comune })
                }}
              />
                <Form.Item hasFeedback>
              <div className="itemCol semi">
                <div className="inputLabel">
                  Calcolo utile agente:<span>*</span>
                </div>

                <div className="percentageInp">
                  <div className="percentageInp--wrapper">
                    <span
                      onClick={() => {
                        this.setState({
                          percentageInp:
                            (parseInt(this.state.percentageInp) || 0) - 10,
                        })
                      }}
                    >
                      -
                    </span>
                    <input
                      type="text"
                      value={
                        isNaN(this.state.percentageInp)
                          ? 0
                          : this.state.percentageInp
                      }
                      onChange={(e) => {
                        this.setState({ percentageInp: e.target.value })
                      }}
                    />
                    <span
                      onClick={() => {
                        this.setState({
                          percentageInp:
                            (parseInt(this.state.percentageInp) || 0) + 10,
                        })
                      }}
                    >
                      +
                    </span>
                  </div>
                  <span>
                    Percentuale del utile calcolato sulle provigioni maturate dalle
                    agenzie aperte
                  </span>
                </div>
              </div>
              </Form.Item>
            </div>
            <div className="newReg--row__col">
              <div className="itemCol full">
                <Form.Item hasFeedback>
                  {getFieldDecorator("nickname", {
                    rules: [
                      {
                        required: true,
                        message: "Inserisci un nome utente valido!",
                      },
                      {
                        validator: (a, b, c) => {
                          const { form } = this.props
                          if (form.getFieldValue("nickname").length >= 8) {
                            c()
                          } else {
                            c("Username è corto!")
                          }
                        },
                      },
                    ],
                  })(<FloatingLabelInput label="Username" />)}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <Form.Item hasFeedback>
                  {getFieldDecorator("password", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire password!",
                      },
                    ],
                  })(<FloatingLabelInput type="password" label="Password" />)}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <Form.Item hasFeedback>
                  {getFieldDecorator("confirm_password", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire password!",
                      },
                      {
                        validator: (a, b, c) => {
                          const { form } = this.props
                          if (
                            form.getFieldValue("password") ===
                            form.getFieldValue("confirm_password")
                          ) {
                            c()
                          } else {
                            c("Conferma password errata!")
                          }
                        },
                      },
                    ],
                  })(
                    <FloatingLabelInput type="password" label="Conferma password" />
                  )}
                </Form.Item>
              </div>
              <div className="itemCol full">
                <Form.Item hasFeedback>
                  {getFieldDecorator("number", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire il numero di cellulare!",
                      },
                    ],
                  })(<FloatingLabelInput label="Cellulare" />)}
                </Form.Item>
              </div>
              <div className="itemCol full">
                <Form.Item>
                  {getFieldDecorator("identity_type", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire il tipo di documento!",
                      },
                    ],
                  })(
                    <Select
                      onChange={() => {
                        form.validateFields(["identity_id"])
                      }}
                      placeholder="Tipo Documento"
                    >
                      {docType.map((doc) => {
                        return (
                          <Option key={doc.id} value={doc.id}>
                            {doc.name}
                          </Option>
                        )
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="itemCol full">
                <Form.Item hasFeedback>
                  {getFieldDecorator("identity_id", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire il numero di documento!",
                        whitespace: true,
                      },
                      {
                        pattern:
                          getFieldValue("identity_type") === "1"
                            ? /^[A-Z]{2}\d{5}[A-Z]{2}$|^[A-Z]{2}\d{7}$/ // Carta d'identità
                            : getFieldValue("identity_type") === "2"
                            ? /^[A-Z]{2}\d{7}$/ // Patente di guida
                            : /^[A-Z]{2}\d{5}[A-Z]{2}$|^[A-Z]{2}\d{7}$/, // Passaporto
                        message: "Inserisci un documento valido",
                      },
                    ],
                  })(<FloatingLabelInput label="Numero Documento" />)}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <Form.Item>
                  {getFieldDecorator("rilasciato_da", {
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                      },
                    ],
                  })(
                    <Select placeholder="Rilasciato da">
                      <Option value="1">Comune</Option>
                      <Option value="10">Motorizzazione</Option>
                      <Option value="13">Questura</Option>
                      <Option value="14">Polizia</Option>
                      <Option value="16">Commissariato</Option>
                      <Option value="19">Altro</Option>
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <Form.Item>
                  {getFieldDecorator("luogo_di_rilascio", {
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: "Inserisci il luogo di rilascio!",
                      },
                    ],
                  })(<FloatingLabelInput label="Luogo di rilascio" />)}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <Form.Item>
                  {getFieldDecorator("data_di_rilascio", {
                    initialValue: moment(this.state.nascita, dateFormat).isValid()
                      ? moment(this.state.nascita, dateFormat)
                      : null,
                    rules: [
                      {
                        required: true,
                        message: "Inserisci una data valida!",
                      },
                    ],
                  })(
                    <DatePicker
                      format={("DD/MM/YYYY", "DD/MM/YYYY")}
                      placeholder="Data di rilascio"
                    />
                  )}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                {/* <div className="inputLabel">
                  Data di scadenza<span>*</span>
                </div> */}
                <Form.Item>
                  {getFieldDecorator("data_di_scadenza", {
                    initialValue: moment(this.state.nascita, dateFormat).isValid()
                      ? moment(this.state.nascita, dateFormat)
                      : null,
                    rules: [
                      {
                        required: true,
                        message: "Inserisci una data valida!",
                      },
                    ],
                  })(
                    <DatePicker
                      format={("DD/MM/YYYY", "DD/MM/YYYY")}
                      placeholder=" Data di scadenza"
                    />
                  )}
                </Form.Item>
              </div>

              <div className="itemCol full">
                {register.message && (
                  <React.Fragment>
                    {register.role ? (
                      <React.Fragment>
                        <div className="Nmessage S">
                          <i className="fas fa-check-circle" aria-hidden="true"></i>
                          {capitalize(register.message)}
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className="Nmessage E">
                          <i className="fas fa-times-circle" aria-hidden="true"></i>
                          {capitalize(register.message)}
                        </div>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          <div className="newReg--row">
            <div className="newReg--row__col">
              <Checkbox
                onChange={(e) => {
                  this.setState({ privacy_policy: e.target.checked })
                }}
              >
                Dichiaro di aver letto e accettato l'
                <a
                  href="/informativa-trattamento-dati-personali.pdf"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Informativa sulla Privacy
                </a>{" "}
                e i
                <a
                  href="/termini-e-condizioni.pdf"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {" "}
                  Termini e Condizioni
                </a>{" "}
                del servizio, e acconsento al trattamento dei miei dati personali per
                l'erogazione del servizio in conformità al GDPR.
              </Checkbox>
            </div>
            <div className="newReg--row__col">
              <Checkbox
                onChange={(e) => {
                  this.setState({ recieve_emails: e.target.checked })
                }}
              >
                Desidero ricevere comunicazioni commerciali e promozionali.
              </Checkbox>
            </div>
            <div className="newReg--row__col submitcol">
              <Button type="primary" className="SubmitButton" htmlType="submit">
                Registrati
              </Button>
            </div>
          </div>
        </Form>
      </Fragment>
    )
  }
}

const InfoUser = Form.create({ name: "infoUser" })(RegisterEndUser)

const mapsStateToProps = ({ auth, main }) => ({
  personalInfo: auth.personalInfo,
  register: auth.register,
  accountInfo: auth.accountInfo,
  screenWidth: main.screenWidth,
  activeSkinId: main.activeSkinId,
})

export default withRouter(connect(mapsStateToProps, { ...AuthActions })(InfoUser))
