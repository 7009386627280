import React, { Fragment } from "react"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import ModulePopUp1 from "./ModulePopUp1"
import { withRouter } from "react-router-dom"
import "./style.css"
import NewModulePopUp4 from "./NewModulePopUp4"

class ModulePopUp extends React.Component {
  render() {
    const { isShowing, bolletiniBianchi, companyServices, service_s } = this.props
    let service

    if (companyServices && companyServices?.services) {
      service = companyServices?.services[0]
    }

    return isShowing ? (
      <Fragment>
        {service_s?.services_as_company ? (
          <ModulePopUp1
            service_id={service_s.id}
            bolletiniBianchi={bolletiniBianchi}
          />
        ) : service ? (
          <NewModulePopUp4 service={service} />
        ) : null}
      </Fragment>
    ) : null
  }
}

const mapsStateToProps = (state) => ({
  isShowing: state.main.isShowing,
  service: state.auth.service_id,
  bolletiniBianchi: state.auth.bolletiniBianchi,
  service_s: state.auth.service_s,
  companyServices: state.main.companyServices,
})

export default withRouter(
  connect(mapsStateToProps, { ...MainActions, ...AuthActions })(ModulePopUp)
)
