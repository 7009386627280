import React, { useEffect, useState } from "react"
import { AuthActions } from "redux-store/models"
import { connect } from "react-redux"
import "./style.scss"
import images from "../../themes/images"
import { baseUrlImg } from "../../config/api"

const PopupMessage = ({ popupData, readAds }) => {
  const [active, setState] = useState(false)
  useEffect(() => {
    if (popupData.id) {
      setState(true)
    }
  }, [popupData])

  const skinName = localStorage.getItem("skin_name") || ""

  return (
    active && (
      <React.Fragment>
        {popupData.type == "HTML" && (
          <div className="msgPopup">
            <div className="msgPopup--content">
              <span
                className="close"
                onClick={() => {
                  setState(false)
                  readAds(popupData.id)
                }}
              >
                &times;
              </span>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: popupData.text }}
              ></div>
            </div>
          </div>
        )}
        {popupData.type != "HTML" && (
          <div className="messaggi-popup">
            <div className="backDropPopup" />
            <div className="messaggi-popup__content_text">
              {/* <i className="fal fa-times" onClick={() => {
                  setState(false)
                  readAds(
                    popupData.id
                  )
                }}></i> */}

              <div className="content__body_modal">
                <img
                  className="skinLogo"
                  src={`${baseUrlImg}/logo/${skinName}.svg`}
                  alt="Logo"
                />
                <h3>{popupData.title}</h3>
                {popupData.type == "Text" && (
                  <h6 className="body__label">{popupData.text}</h6>
                )}
              </div>
              <div className="content__footer_text">
                <button
                  onClick={() => {
                    setState(false)
                    readAds(popupData.id)
                  }}
                >
                  Confermare
                </button>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    )
  )
}

export default connect(null, AuthActions)(PopupMessage)
