import React, { useEffect, useState } from "react";
import images from "themes/images";
import "./internetVelocePopup.css";
import { Button, Checkbox, Form, Icon, Input, Tooltip } from "antd";
import { AuthActions } from "redux-store/models";
import { connect } from "react-redux";

const InternetVelocePopup = (props) => {
  const { visible, onClose } = props;
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [name, setName] = useState("");
  const [indirizo, setIndirizo] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    const lastShown = localStorage.getItem("popupLastShown");
    const now = new Date().getTime();

    if (!lastShown || now - lastShown > 24 * 60 * 60 * 1000) {
      if (visible) {
        setTimeout(() => {
          setIsPopupVisible(true);
          localStorage.setItem("popupLastShown", now);
        }, 100);
      } else {
        setIsPopupVisible(false);
      }
    }
  }, [visible]);

  const onSubmit = () => {
    props.internetRegister(name, indirizo, phone, props.accountInfo);
  };

  return (
    <>
      {visible && !props.accountInfo.profile.filled_internet_form && isPopupVisible && (
        <div
          className={`internet-container ${isPopupVisible ? "visible" : ""}`}
          onClick={onClose}
        >
          <div className="internet-popup" onClick={(e) => e.stopPropagation()}>
            <div className="left">
              <img
                className="bg-img"
                src={images["internet_popup"]}
                alt="internet-veloce"
              />
              <img
                className="popup-img"
                src={images["internet_popup_img"]}
                alt="internet-veloce"
              />
            </div>
            <div className="right">
              <div className="text">
                <h4 className="title">INTERNET IPERVELOCE</h4>
                <h5 className="subtitle">Stanco di avere internet lento?</h5>
                <p className="description">
                  Ti offriamo Internet iperveloce ovunque, anche nei piccoli comuni e
                  viaggi veloce e sicuro ad un prezzo bomba! Compila i dati per
                  valutare gratuitamente la copertura sulla tua zona, per poi essere
                  contattato da un tecnico specializzato.
                </p>
              </div>
              <div className="form">
                <Form>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Inserisci il tuo nome!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Nome Cognome"
                      prefix={<Icon type="user" />}
                      value={name}
                      type="text"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    name="indirizo"
                    rules={[
                      {
                        required: true,
                        message: "Inserisci il tuo indirizo!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Indirizo"
                      prefix={<Icon type="home" />}
                      suffix={
                        <Tooltip title="Inserisci il tuo indirizzo per verificare la velocità di copertura">
                          <Icon
                            type="question-circle-o"
                            style={{
                              color: "#009DE8",
                              fontSize: 18,
                              cursor: "pointer",
                            }}
                          />
                        </Tooltip>
                      }
                      value={indirizo}
                      onChange={(e) => setIndirizo(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Inserisci il tuo numero di telefono!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Numero di telefono"
                      prefix={<Icon type="mobile" />}
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Checkbox className="checkbox">
                      Ho letto e accetto <a href="">l’informativa sulla privacy</a>{" "}
                    </Checkbox>
                  </Form.Item>
                  <Form.Item>
                    <Button onClick={onSubmit} type="primary">Invia</Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  accountInfo: state.auth.accountInfo,
});

export default connect(mapStateToProps, { ...AuthActions })(InternetVelocePopup);
