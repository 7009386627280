import React from "react";
import images from "../../themes/images";

const NumPadForPhoneNumber = ({ phoneNumber, setPhoneNumber }) => {
  const clear = () => {
    setPhoneNumber(""); 
  };

  const clearOne = () => {
    setPhoneNumber((prevPhone) => prevPhone.slice(0, -1)); 
  };


  const addNr = (num) => {
    setPhoneNumber((prevPhone) => prevPhone + num);
  };

  return (
    <div className="NumPadContainer">
      <div className="NumPd">
        <span>+39</span>
        <input
          type="text"
          placeholder="_ _ _  _ _ _ _ _ _ _"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <i className="fas fa-address-book"></i>
      </div>
      <div className="Numbers">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, "C", 0, <img src={images["arrow-back"]} alt="back" />].map((num) => (
          <span
            key={num}
            id={`num${num}`}
            onClick={() =>
              num === "C" ? clear() : num.type === "img" ? clearOne() : addNr(num)
            }
          >
            {num}
          </span>
        ))}
      </div>
    </div>
  );
};

export default NumPadForPhoneNumber;
