import React, { Component } from "react";
import { connect } from "react-redux";
import { AuthActions, MainActions } from "redux-store/models";
import SingleError from "./SingleError";
import moment from "moment";
//import CalendarRangePicker from "shared-components/CalendarRangePicker/CalendarRangePicker";
import "./style.css";
import { Form } from "antd";
import { Select, Pagination } from "antd";
//import { toDate } from "date-fns/esm";
const { Option } = Select;

export class SupportBody extends Component {
  state = {
    //filteredArray: null,
    selectedFilter: 3,
    skin_name: "",
    // userNameFilter: "",
    // utenteFilter: "",
    // servizioFilter: "",
    perPage: 25,
    page_number: 1,
    current_page: 1,
    from: "",
    to: "",
    fromLabel: "",
    toLabel: "",
    picker: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
        color: "var(--accent-bg)",
      },
    ],
    isCalendarOpen: false,
  };

  componentDidMount() {
    const {activeSkinName} = this.props;
    const {perPage, page_number, from, to}  = this.state
    this.props.getErrors( perPage,
      page_number,
      activeSkinName,
      from !== "" ? from : "",
      to !== "" ? to : "");
  }

  //   setSkins = () => { this.setState(prevState=> ({
  //     skins: [...prevState.skins, "TUTTI"]
  //   })
  // }

  setCalendar = (val) => {
    this.setState({ isCalendarOpen: val });
  };

  // filterUsernameByStringValue = (errors, string) => {
  //   const result = errors.filter((error) => {
  //     return error.username
  //       ? error.username.toLowerCase().includes(string.toLowerCase())
  //       : false;
  //   });
  //   return result;
  // };
  // filterUtenteByStringValue = (errors, string) => {
  //   const result = errors.filter((error) => {
  //     return error.user_full_name
  //       ? error.user_full_name.toLowerCase().includes(string.toLowerCase())
  //       : false;
  //   });
  //   return result;
  // };
  // filterServizioByStringValue = (errors, string) => {
  //   const result = errors.filter((error) => {
  //     return error.title
  //       ? error.title.toLowerCase().includes(string.toLowerCase())
  //       : false;
  //   });
  //   return result;
  // };
  // filterByDate = (errors, startDate, endDate) => {
  //   const result = errors.filter((error) => {
  //     const errorDate = new Date(error.full_time).getTime();
  //     const startDateFilter = new Date(startDate).getTime();
  //     const endDateFilter = new Date(endDate).getTime();
  //     return errorDate <= endDateFilter && startDateFilter >= errorDate;
  //   });
  //   return result;
  // };

  componentDidUpdate(prevProps) {
    const {perPage, page_number, from, to}  = this.state
    const {activeSkinName} = this.props
    if (this.props.fromDate && this.props.fromDate !== prevProps.fromDate) {
      const label = moment(this.props.fromDate, "DD/MM/YYYY");

      this.setState({
        from: this.props.fromDate,
        to: this.props.fromDate,
        fromLabel: label,
        toLabel: label,
        perPage: 25,
      });
    }
    if (this.props.activeSkinName !== prevProps.activeSkinName) {
      this.props.getErrors( perPage,
        page_number,
        activeSkinName,
        from !== "" ? from : "",
        to !== "" ? to : "");
    }
    
    // if (
    //   this.state.filteredArray == null &&
    //   this.props.errors &&
    //   Array.isArray(this.props.errors) &&
    //   this.props.errors.length >= 1
    // ) {
    //   this.setState({ filteredArray: this.props.errors });
    // }
  }

  handleSearch = (value) => {
    const { skins } = this.props;
    if (value && skins) {
      let res = skins.filter((skin) =>
        skin.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      );
      this.setState({ skins: res });
    } else {
      this.setState({ skins: [] });
    }
  };

  //skin name
  handleChange = (value) => {
    this.setState({ skin_name: value });
    const { perPage, page_number, from, to } = this.state;

    this.props.getErrors(
      perPage,
      page_number,
      value === "TUTTI" ? "" : value,
      from !== "" ? from : "",
      to !== "" ? to : ""
    );
  };

  //data ora
  handleSubmit = () => {
    const { perPage, page_number, skin_name, from, to } = this.state;

    this.props.getErrors(
      perPage,
      page_number,
      skin_name !== "TUTTI" ? skin_name : "",
      from,
      to
    );
  };

  changeSelected = (filter) => {
    const { perPage, page_number, skin_name } = this.state;
    this.setState({ selectedFilter: filter });

    if (filter === 0) {
      const fromDate = moment().format("YYYY-MM-DD");

      this.setState({
        fromLabel: "",
        toLabel: "",
        from: fromDate,
        to: fromDate,
      });

      this.props.getErrors(
        skin_name !== "" ? skin_name : "",
        page_number,
        perPage,
        fromDate,
        fromDate
      );
    }

    if (filter === 1) {
      const fromDate = moment().subtract(1, "days").format("YYYY-MM-DD");
      const toDate = moment().subtract(1, "days").format("YYYY-MM-DD");

      this.setState({
        fromLabel: "",
        toLabel: "",
        from: fromDate,
        to: toDate,
      });

      this.props.getErrors(
        skin_name !== "" ? skin_name : "",
        page_number,
        perPage,
        fromDate,
        toDate
      );
    }

    if (filter === 2) {
      const fromDate = moment()
        .subtract(7, "days")
        .startOf("day")
        .format("YYYY-MM-DD");
      const toDate = moment().format("YYYY-MM-DD");

      this.setState({
        fromLabel: "",
        toLabel: "",
        from: fromDate,
        to: toDate,
      });

      this.props.getErrors(
        skin_name !== "" ? skin_name : "",
        page_number,
        perPage,
        fromDate,
        toDate
      );
    }

    if (filter === 3) {
      this.setState({
        from: "",
        to: "",
        fromLabel: "",
        toLabel: "",
      });

      this.props.getErrors(
        skin_name !== "" ? skin_name : "",
        "",
        "",
        1,
        perPage
      );
    }
  };

  render() {
    const {
      perPage,
      page_number,
      current_page,
      // fromLabel,
      // toLabel,
      // isCalendarOpen,
      // picker,
      skin_name,
      from,
      to,
    } = this.state;
    const {
      deleteError,
      total_pages,
      getErrors,
      errors,
      ErrLoading,
    } = this.props;

    return (
      <div className="accountInfo">
        {this.props.MessaggiComponent}
        {/* <Select
          onChange={(value) => {
            this.setState({ skinFilter: value });
          }}
          defaultValue={"Selezionare Evento"}
        >
          <Option value="0">Selezionare Evento</Option>
          <Option value="1">Concerti</Option>
          <Option value="2">Sport</Option>
          <Option value="3">Museo</Option>
          <Option value="4">Teatro</Option>
          <Option value="5">Altro</Option>
        </Select> */}
        {/* <input
          type="text"
          placeholder="username"
          value={this.state.userNameFilter}
          onChange={(e) => {
            this.setState({
              userNameFilter: e.target.value,
            });
            this.setState({
              filteredArray: this.filterUsernameByStringValue(
                this.props.errors,
                e.target.value
              ),
            });
          }}
        />
        <input
          type="text"
          placeholder="utente"
          value={this.state.utenteFilter}
          onChange={(e) => {
            this.setState({
              utenteFilter: e.target.value,
            });
            this.setState({
              filteredArray: this.filterUtenteByStringValue(
                this.props.errors,
                e.target.value
              ),
            });
          }}
        />
        <input
          type="text"
          placeholder="servizio"
          value={this.state.servizioFilter}
          onChange={(e) => {
            this.setState({
              servizioFilter: e.target.value,
            });
            this.setState({
              filteredArray: this.filterServizioByStringValue(
                this.props.errors,
                e.target.value
              ),
            });
          }}
        /> */}
        {!ErrLoading ? (
          <div className="userList mt-n5">
            <div className="userList--AllUsers ">
              {/* <div className=" datepics sort-trasazioni sort-annunci  custom-header border-0 "> */}
                {/* {isCalendarOpen && (
                  <CalendarRangePicker
                    setStateFunc={(item) => {
                      this.setState({
                        picker: [item.selection],
                        from: moment(item.selection.startDate).format(
                          "YYYY-MM-DD"
                        ),
                        to: moment(item.selection.endDate).format("YYYY-MM-DD"),
                        fromLabel: moment(item.selection.startDate).format(
                          "DD/MM/YYYY"
                        ),
                        toLabel: moment(item.selection.endDate).format(
                          "DD/MM/YYYY"
                        ),
                      });
                    }}
                    setStateFuncEmpty={() => {
                      this.setState({
                        from: "",
                        to: "",
                        fromLabel: "",
                        toLabel: "",
                      });

                      this.props.getErrors({});
                    }}
                    picker={picker}
                    setCalendar={this.setCalendar}
                    handleSubmit={this.handleSubmit}
                  />
                )} */}
               <Form onSubmit={this.handleSubmit} className="filters">
                  {/* <React.Fragment>
                    <div className="dal">
                      {
                        <>
                          <Form.Item>
                            <React.Fragment>
                              <Select
                                showSearch
                                showArrow={false}
                                defaultActiveFirstOption={false}
                                filterOption={true}
                                onSearch={this.handleSearch}
                                onChange={this.handleChange}
                                placeholder={"Skin (TUTTI)"}
                              >
                                <Option value={"TUTTI"}>TUTTI</Option>
                                {this.props.skins &&
                                  this.props.skins.length > 0 &&
                                  this.props.skins.map((skin) => (
                                    <Option value={skin} key={skin}>
                                      {skin}
                                    </Option>
                                  ))}
                              </Select>{" "}
                              <i className="fal fa-search"></i>
                            </React.Fragment>
                          </Form.Item>{" "}
                        </>
                      }
                    </div>
                  </React.Fragment> */}
                  {/* <div
                    className="dal calendar"
                    type="text"
                    onClick={() => {
                      this.setCalendar(true);
                    }}
                  >
                    <span>
                      {fromLabel
                        ? `${fromLabel} - ${toLabel}`
                        : "Seleziona la data"}
                      <i className="fal fa-calendar-alt"></i>
                    </span>
                  </div> */}

                  {/* <React.Fragment>
                      <div className="dal">
                        <Form.Item>
                          <Select
                            showSearch
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={true}
                            onSearch={this.handleSearch}
                            placeholder="Username"
                          >
                            {this.props.errors &&
                              this.props.errors.length > 0 &&
                              this.props.errors.map((error) => (
                                <Option key={error}>
                                  {error.user_full_name}
                                </Option>
                              ))}
                          </Select>{" "}
                          <i className="fal fa-search"></i>
                        </Form.Item>
                      </div>
                    </React.Fragment> */}
                </Form>
              {/* </div> */}
{this.props.screenWidth < 420 ? ( <div className="header mobile">
                <span>Data Ora</span>
                <span>Skin</span>
                <span>Servizio</span>
                <span>Causale</span>
                <span className="deleteError">Delete</span>
              </div>): ( <div className="header ">
                <span>Data Ora</span>
                <span>Skin</span>
                <span>username</span>
                <span>utente</span>

                <span>Servizio</span>
                <span>Causale</span>
                <span className="deleteError">Delete</span>
              </div>)}
              

              {errors
                ? errors.map((error) => (
                    <SingleError
                      key={error.id}
                      deleteError={deleteError}
                      getErrors={getErrors}
                      perPage={perPage}
                      page_number={page_number}
                      error={error}
                    />
                  ))
                : null}
            </div>
          </div>
        ) : (
          <div className="loaderAdmin">Loading...</div>
        )}{" "}
        <div className="paginationWrapper">
          <Pagination
            onChange={(e) => {
              getErrors(
                perPage,
                e,
                this.props.activeSkinName !== "" ?  this.props.activeSkinName : "",
                from !== "" ? from : "",
                to !== "" ? to : ""
              );
              this.setState({
                current_page: e,
              });
            }}
            total={total_pages ? total_pages * 10 : 10}
            current={current_page}
          />
          <Select
            defaultValue={25}
            onChange={(e) => {
              this.setState({ perPage: parseInt(e), clickedPage: 1 }, () => {
                getErrors(
                  e,
                  page_number,
                  this.props.activeSkinName !== "" ? this.props.activeSkinName : "",
                  from !== "" ? from : "",
                  to !== "" ? to : ""
                );
              });
            }}
            value={perPage}
          >
            <Option value={10}>10 / Pagina</Option>
            <Option value={25}>25 / Pagina</Option>
            <Option value={50}>50 / Pagina</Option>
          </Select>
        </div>
      </div>
    );
  }
}

const _ = (state) => {
  return {
    skins: state.auth.errors.skins,
    errors: state.auth.errors.errors,
    total_pages: state.auth.errors.total_pages,
    ErrLoading: state.auth.ErrLoading,
    screenWidth: state.main.screenWidth,
    activeSkinName: state.main.activeSkinName,
    activeSkinId: state.main.activeSkinId
  };
};

export default connect(_, { ...MainActions, ...AuthActions })(SupportBody);

