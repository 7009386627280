import React, { Fragment } from "react"
import { Header } from "shared-components"
import NewRegisterAgency from "../../shared-components/adminSharedComp/NewRegisterAgency/NewRegisterAgency"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import AuthActions from "redux-store/models/auth"
import "react-select/dist/react-select.css"
import "react-virtualized-select/styles.css"

class RegisterAgency extends React.Component {
  render() {
    return (
      <Fragment>
        {!this.props.accountInfo?.profile?.activity_id &&
          this.props.accountInfo?.profile?.role?.name !== "main_admin" && (
            <Header></Header>
          )}
        <NewRegisterAgency />
      </Fragment>
    )
  }
}

const mapsStateToProps = ({ auth }) => ({
  accountInfo: auth.accountInfo,
})

export default withRouter(
  connect(mapsStateToProps, { ...AuthActions })(RegisterAgency)
)
