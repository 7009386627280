import React from "react";
import DatePicker from "shared-components/DatePicker/DatePicker";
import moment from "moment";
import { Select } from "antd";

const {Option} = Select
const VoliUserForm = ({
  handleChangeName,
  handleChangeCognome,
  handleChangeDate,
  handleChangeTel,
  handleChangeEmail,
  handleChangeBirthPlace,
  handleChangeProvince,
  handleChangeSesso,
  isChild,
  isAdult,
  ind,
  bigliettiForm,
}) => {
  return (
    <div className={isChild ? "child" : isAdult ? "adult" : ""}>
      <div className="header">
        {isAdult ? `Adulto ${ind + 1}` : isChild ? bigliettiForm ? `Ragazzo ${ind + 1}`: `Bambino ${ind + 1}`   : ""}
      </div>
      {bigliettiForm ? (
        <>
          <div className="formsContainer--body__semiCont">
            {" "}
            <div className="formsContainer--body__item semi">
              <div className="label">
                Nome<span className="Red">*</span>
              </div>
              <input
                type="text"
                placeholder="Nome"
                onChange={(e) => {
                  isAdult
                    ? handleChangeName(
                        {
                          [`adult${ind + 1}`]: {
                            name: e.target.value,
                          },
                        },
                        `adult${ind + 1}`
                      )
                    : handleChangeName(
                        {
                          [`child${ind + 1}`]: {
                            name: e.target.value,
                          },
                        },
                        `child${ind + 1}`
                      );
                }}
              />
            </div>
            <div className="formsContainer--body__item semi ">
              <div className="label">
                Cognome<span className="Red">*</span>
              </div>
              <input
                type="text"
                placeholder="Cognome"
                onChange={(e) => {
                  isAdult
                    ? handleChangeCognome(
                        {
                          [`adult${ind + 1}`]: {
                            cogname: e.target.value,
                          },
                        },
                        `adult${ind + 1}`
                      )
                    : handleChangeCognome(
                        {
                          [`child${ind + 1}`]: {
                            cogname: e.target.value,
                          },
                        },
                        `child${ind + 1}`
                      );
                }}
              />
            </div>
          </div>
          <div className="formsContainer--body__semiCont">
            <div className="formsContainer--body__item semi">
              <div className="label">
                Data di nascità <span className="Red">*</span>
              </div>
              <DatePicker
                  onChange={(e) => {
                    isAdult
                      ? handleChangeDate(
                          {
                            [`adult${ind + 1}`]: {
                              date: moment(e).format("DD/MM/YYYY"),
                            },
                          },
                          `adult${ind + 1}`
                        )
                      : handleChangeDate(
                          {
                            [`child${ind + 1}`]: {
                              date: moment(e).format("DD/MM/YYYY"),
                            },
                          },
                          `child${ind + 1}`
                        );
                  }}
                  format={("DD/MM/YYYY", "DD/MM/YYYY")}
              />
            </div>
            <div className="formsContainer--body__item semi ">
              <div className="label">
                Sesso<span className="Red">*</span>
              </div>
              <Select
                defaultValue={"Sesso"}
                onChange={(e) => {
                  isAdult
                    ? handleChangeSesso(
                        {
                          [`adult${ind + 1}`]: {
                            sesso: e
                          },
                        },
                        `adult${ind + 1}`
                      )
                    : handleChangeSesso(
                        {
                          [`child${ind + 1}`]: {
                            sesso: e
                          },
                        },
                        `child${ind + 1}`
                      );
                }}
              >
               <Option value="M">Maschile</Option>
               <Option value="F">Femminile</Option>
              </Select>
            </div>
          </div>
          <div className="formsContainer--body__semiCont">
            {" "}
            <div className="formsContainer--body__item semi">
              <div className="label">
                Luogo di nascità <span className="Red">*</span>
              </div>
              <input type="text"  onChange={(e) => {
                  isAdult
                    ? handleChangeBirthPlace(
                        {
                          [`adult${ind + 1}`]: {
                            birthPlace: e.target.value,
                          },
                        },
                        `adult${ind + 1}`
                      )
                    : handleChangeBirthPlace(
                        {
                          [`child${ind + 1}`]: {
                            birthPlace: e.target.value,
                          },
                        },
                        `child${ind + 1}`
                      );
                }}/>
            </div>
            <div className="formsContainer--body__item semi ">
              <div className="label">
                Sigla Provincia di nascità <span className="Red">*</span>
              </div>
              <input type="text" onChange={(e) => {
                  isAdult
                    ? handleChangeProvince(
                        {
                          [`adult${ind + 1}`]: {
                            province: e.target.value,
                          },
                        },
                        `adult${ind + 1}`
                      )
                    : handleChangeProvince(
                        {
                          [`child${ind + 1}`]: {
                            province: e.target.value,
                          },
                        },
                        `child${ind + 1}`
                      );
                }}/>
            </div>
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className={"formsContainer--body__item"}>
            <div className="label"> Nome</div>
            <input
              type="text"
              onChange={(e) => {
                isAdult
                  ? handleChangeName(
                      {
                        [`adult${ind + 1}`]: {
                          name: e.target.value,
                        },
                      },
                      `adult${ind + 1}`
                    )
                  : handleChangeName(
                      {
                        [`child${ind + 1}`]: {
                          name: e.target.value,
                        },
                      },
                      `child${ind + 1}`
                    );
              }}
            />
          </div>
          <div className="formsContainer--body__item">
            <div className="label"> Cognome</div>
            <input
              type="text"
              onChange={(e) => {
                isAdult
                  ? handleChangeCognome(
                      {
                        [`adult${ind + 1}`]: {
                          cogname: e.target.value,
                        },
                      },
                      `adult${ind + 1}`
                    )
                  : handleChangeCognome(
                      {
                        [`child${ind + 1}`]: {
                          cogname: e.target.value,
                        },
                      },
                      `child${ind + 1}`
                    );
              }}
            />
          </div>
          <div className="formsContainer--body__item">
            <div className="label"> Data Di Nascita</div>

            <DatePicker
              onChange={(e) => {
                isAdult
                  ? handleChangeDate(
                      {
                        [`adult${ind + 1}`]: {
                          date: moment(e).format("DD/MM/YYYY"),
                        },
                      },
                      `adult${ind + 1}`
                    )
                  : handleChangeDate(
                      {
                        [`child${ind + 1}`]: {
                          date: moment(e).format("DD/MM/YYYY"),
                        },
                      },
                      `child${ind + 1}`
                    );
              }}
              format={("DD/MM/YYYY", "DD/MM/YYYY")}
            />
          </div>
          {ind === 0 && isAdult && (
            <div className="formsContainer--body__item">
              <div className="label">Telefono</div>
              <input
                type="text"
                onChange={(e) =>
                  handleChangeTel(
                    {
                      [`adult${ind + 1}`]: {
                        tel: e.target.value,
                      },
                    },
                    `adult${ind + 1}`
                  )
                }
              />
            </div>
          )}
          {ind === 0 && isAdult && (
            <div className="formsContainer--body__item">
              <div className="label"> E-mail</div>
              <input
                type="text"
                onChange={(e) =>
                  handleChangeEmail(
                    {
                      [`adult${ind + 1}`]: {
                        email: e.target.value,
                      },
                    },
                    `adult${ind + 1}`
                  )
                }
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default VoliUserForm;
