import React from "react"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import "antd/dist/antd.css"
import moment from "moment"
import { Azioni, Header, Loader } from "shared-components"
import "react-date-range/dist/styles.css" // main style file
import "react-date-range/dist/theme/default.css"
import { isArray } from "lodash"
import CalendarRangePicker from "shared-components/CalendarRangePicker/CalendarRangePicker"
import ModalResponsiveForTables from "shared-components/ModalResponsiveForTables/ModalResponsiveForTables"
import ModalRespForDepositi from "shared-components/ModalResponsiveForTables/ModalRespForDepositi"
import { numberWithCommas } from "utils/HelperFunc"
import ModalRow from "shared-components/ModalResponsiveForTables/ModalRow"
import {
  Select,
  Pagination,
  Button,
  Input,
  Tooltip,
  Icon,
  Upload,
  InputNumber,
  Modal,
  notification,
  Row,
  Col,
  Form,
  DatePicker,
} from "antd"
import { withRouter } from "react-router"
// import { requireTransfer } from "../../services/auth";

const importoValues = [250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750, 800]

const { Option } = Select
const { confirm } = Modal
class Depositi extends React.Component {
  state = {
    visible: false,
    indexT: null,
    from: "",
    to: "",
    modalDetails: "",
    fromLabel: "",
    toLabel: "",
    perPage: 25,
    showModalResponsive: false,
    importo: 250,
    document: "",
    imageVisible: false,
    fileList: [],
    visibleDesktop: false,
    picker: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
        color: "var(--accent-bg)",
      },
    ],
    isCalendarOpen: false,
  }
  setCalendar = (val) => {
    this.setState({ isCalendarOpen: val })
  }
  showModal = (index, barcode, name, address, phone) => {
    this.setState({
      visible: true,
      indexT: index,
      barcode,
      name,
      address,
      phone,
    })
  }

  showModalDesktop = (data) => {
    if (data.id)
      this.setState({
        visibleDesktop: true,
        modalDetails: data,
      })
  }
  componentWillUnmount() {
    if (this.props.forAdmin === true) {
      this.props.openModalForAdmin(false)
      this.props.editModalDetails({})
    }
    this.props.setFromDateToDate(null)
  }
  activateModalForAdmin = (item, index) => {
    this.props.openModalForAdmin(true)
    this.props.editModalDetails({
      index,
      barcode: item.barcode,
      agency_name: item.agency_name,
      address: item.agency_address,
    })
  }

  componentDidUpdate(prevProps) {
    const { from, to, perPage } = this.state
    const { activeSkinId, accountInfo } = this.props
    if (this.props.activeSkinId !== prevProps.activeSkinId && this.props.forAdmin) {
      this.props.getRequestedRefills(
        1,
        perPage,
        accountInfo?.profile?.role?.name === "super_admin"
          ? accountInfo.profile.activity_id
          : activeSkinId != -1
          ? activeSkinId
          : null,
        from || "",
        to || ""
      )
    }
    if (this.props.fromDate && this.props.fromDate !== prevProps.fromDate) {
      const label = moment(this.props.fromDate, "DD/MM/YYYY")

      this.setState({
        from: this.props.fromDate,
        to: this.props.fromDate,
        fromLabel: label,
        toLabel: label,
        perPage: 25,
      })
    }
  }

  handleSubmit = async (e) => {
    const { to, from, perPage } = this.state
    e.preventDefault()
    //call getter to populate the table
    this.props.getRequestedRefills(
      1,
      perPage,
      this.props.accountInfo?.profile?.role?.name === "super_admin"
        ? this.props.accountInfo.profile.activity_id
        : this.props.activeSkinId != -1
        ? this.props.activeSkinId
        : null,
      from,
      to
    )
  }

  componentDidMount() {
    this.props.getRequestedRefills(
      1,
      25,
      this.props.accountInfo?.profile?.role?.name === "super_admin"
        ? this.props.accountInfo.profile.activity_id
        : this.props.activeSkinId !== -1
        ? this.props.activeSkinId
        : null
    )
  }

  handleImporto = (e) => {
    this.setState({ importo: e })
  }

  getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener("load", () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  beforeUpload = (file) => {
    const isValid =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf"
    if (!isValid) {
      notification["error"]({
        message: "File insertion",
        description: "Please insert a valid file like PDF,JPG or PNG",
        placement: "topRight",
      })
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      notification["error"]({
        message: "File insertion",
        description: "Please insert a valid smaller than 2M",
        placement: "topRight",
      })
    }
    return isValid && isLt2M
  }

  handleChange = (info) => {
    let fileList = [...info.fileList]

    fileList = fileList.slice(-1)
    this.setState({ fileList })
    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url
      }
      return file
    })
    if (
      info.file.status === "done" &&
      (info.file.type === "image/jpeg" ||
        info.file.type === "image/png" ||
        info.file.type === "application/pdf")
    ) {
      this.getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          document: imageUrl,
        })
      )
    }
  }

  handleRequest = (e) => {
    const { requireTransfer } = this.props
    var errori = false
    if (this.state.document == "") {
      errori = true
      notification["error"]({
        message: "File insertion",
        description: "Please insert a valid file like PDF,JPG or PNG",
        placement: "topRight",
      })
    }
    if (isNaN(this.state.importo)) {
      errori = true
      notification["error"]({
        message: "Importo insertion",
        description: "Please insert a valid importo",
        placement: "topRight",
      })
    }

    if (!errori) {
      this.setState({ document: "", fileList: [] })
      requireTransfer(parseInt(this.state.importo), this.state.document)
    }
  }

  showConfirmAccept = (id) => {
    const { requireRefillStatus } = this.props
    confirm({
      title: "Pagamento ricevuto! Accetta e ricarica il conto.",
      cancelText: "Cancella",
      onOk() {
        requireRefillStatus(id, "accepted")
      },
      onCancel() {},
    })
  }

  showConfirmDecline = (id) => {
    const { requireRefillStatus } = this.props
    confirm({
      title: "Vuoi annullare questa richiesta di ricarica conto?",
      cancelText: "Cancella",
      onOk() {
        requireRefillStatus(id, "rejected")
      },
      onCancel() {},
    })
  }

  info = (id) => {
    this.props.getTransferDocument(id)
    this.setState({ imageVisible: true })
  }
  handleCancel = (e) => {
    this.setState({
      imageVisible: false,
      visibleDesktop: false,
    })
  }

  render() {
    const { forAdmin, screenWidth } = this.props
    const {
      picker,
      isCalendarOpen,
      fromLabel,
      toLabel,
      to,
      from,
      perPage,
      modalDetails,
      importo,
      imageVisible,
    } = this.state

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }
    const {
      refills,
      accountInfo,
      loadingPayments,
      refillsPages,
      getRequestedRefills,
      activeSkinId,
      transferDocument,
    } = this.props

    const refillsO =
      refills &&
      isArray(refills) &&
      refills.sort(function (a, b) {
        return new Date(b.eseguito_il) - new Date(a.eseguito_il)
      })
    return (
      <div
        className={`${forAdmin === true ? "" : "Container User-Depositi"}`}
        style={
          forAdmin === true
            ? { background: "transparent", width: "100%", height: "0px" }
            : { width: "auto" }
        }
      >
        {this.props.SameModals}

        <Modal
          title={"Vegli Detagli - " + modalDetails.title}
          visible={this.state.visibleDesktop}
          onCancel={this.handleCancel}
          footer={false}
        >
          <p>ID : {modalDetails.id}</p>
          <p>
            STATUS :{" "}
            {modalDetails.importance?.split("_")[1]
              ? modalDetails.importance.split("_")[1] === "true"
                ? "READ"
                : "UNREAD"
              : "No Record"}
          </p>
          <p>SENT : {modalDetails.created_at}</p>
          <p>READ : {modalDetails.updated_at}</p>
          <p>MESSAGE : {modalDetails.text}</p>
        </Modal>

        <Modal
          visible={imageVisible}
          onCancel={this.handleCancel}
          footer={null}
          width={700}
        >
          {transferDocument.type === "image" && (
            <img
              style={{ width: "100%", marginTop: "20px" }}
              src={transferDocument.document}
              alt=""
            />
          )}
          {transferDocument.type === "application" && (
            <div>
              <iframe
                title="transferDoc"
                style={{
                  width: "100%",
                  marginTop: "20px",
                  height: "50vh",
                  overflow: "hidden",
                }}
                src={transferDocument.document}
              ></iframe>
            </div>
          )}
        </Modal>

        {this.props.forAdmin === true ? null : (
          <React.Fragment>
            <Header></Header>
          </React.Fragment>
        )}
        {this.state.showModalResponsive === true &&
          this.props.screenWidth <= 1024 &&
          forAdmin && (
            <ModalResponsiveForTables
              Close={(e) => {
                this.setState({
                  modalDetails: "",
                  showModalResponsive: false,
                })
              }}
              Header={
                <React.Fragment>
                  <i className="fal fa-credit-card" aria-hidden="true"></i>
                  <span>{modalDetails.agency_name}</span>
                </React.Fragment>
              }
              beforeFooter={null}
              Footer={null}
              Rows={
                <React.Fragment>
                  <div className="OtherRow">
                    <ModalRow title="Mittente" data={modalDetails.mittente} />
                    <ModalRow
                      title="Destinatario"
                      data={modalDetails.destinatario}
                    />
                    <ModalRow title="Eseguito" data={modalDetails.eseguito_il} />
                    <ModalRow title="Caricato" data={modalDetails.caricato_il} />
                    <ModalRow title="Metodo" data={modalDetails.metodo} />
                    <ModalRow
                      title="Importo"
                      data={numberWithCommas(modalDetails.amount)}
                    />
                    <ModalRow
                      title="Commissione"
                      data={numberWithCommas(modalDetails.commissione)}
                    />
                    <ModalRow
                      title="Stato"
                      data={
                        modalDetails.stato === "accepted"
                          ? "Accettato"
                          : modalDetails.stato === "rejected"
                          ? "Rifiutato"
                          : "In Attesa"
                      }
                    />
                    <ModalRow
                      title="Ricevuta"
                      data={
                        modalDetails.document ? (
                          <a style={{ textDecoration: "underline" }}>
                            Apri l'allegato
                          </a>
                        ) : (
                          "Non prevenuto"
                        )
                      }
                      handleClick={() => this.info(modalDetails.id)}
                    />
                  </div>
                  {modalDetails.message?.title && (
                    <div className="OtherRow">
                      <ModalRow title="Title" data={modalDetails.message.title} />
                      <ModalRow title="Message" data={modalDetails.message.text} />
                      <ModalRow
                        title="Status"
                        data={
                          modalDetails.message?.importance?.split("_")[1]
                            ? modalDetails.message.importance.split("_")[1] ===
                              "true"
                              ? "READ"
                              : "UNREAD"
                            : "No Record"
                        }
                      />
                      <ModalRow
                        title="Sent"
                        data={modalDetails.message.created_at}
                      />
                      <ModalRow
                        title="Read"
                        data={modalDetails.message.updated_at}
                      />
                    </div>
                  )}
                </React.Fragment>
              }
            />
          )}
        {this.state.showModalResponsive === true &&
          this.props.screenWidth <= 800 &&
          !forAdmin && (
            <ModalRespForDepositi
              Close={(e) => {
                this.setState({
                  modalDetails: "",
                  showModalResponsive: false,
                })
              }}
              mobilePopUpData={modalDetails}
              exception={"sign"}
            />
          )}
        <div className="container-fluid overview ">
          {!forAdmin && <Azioni activeMain="contabilita" active="depositi"></Azioni>}

          <div className="panels-container">
            {!forAdmin && (
              <>
                {/* <div className="max-width border-0 depositi depositi-header">
              <h6>PROCEDURA PER LA RICARICA DEL BORSELLINO ELETTRONICO</h6>
              <p>Suggeriamo ai gentili partner, di utilizzare la modalità sotto descritta per assicurarsi un trasferimento più veloce delle somme versate e quindi un più veloce accredito.
              Le ricariche verranno caricate sul borsellino nei seguenti orari:</p>
              <span className="color">Lunedì - Venerdì dalle h. 09.00 alle h. 18.00 Sabato dalle h. 09.00 alle h. 13.00</span>
             
              <div className="max-width border-0">
              <p>Ricarica tramite bonifico bancario: </p>
              <p><span>BancoPosta</span></p>
              <span>MAPE SAS</span> - Viale XXIII Settembre 1845 n. 67 Rimini (RN) Italia
              Iban: <span>IT24J0200824203000100898936</span>  - Indicare causale: <span>“Ricarica Borsellino di User rnhristova”</span> 
              </div> 
            </div> */}

                {/* <div className="sort-annunci max-width border-0 voucher-header">
                  VOUCHER DI RICARICA CONTO
                </div> */}
                <div className="sort-annunci max-width border-0">
                  <div className="voucher-info" style={{ width: "100%" }}>
                    <Row>
                      <Col lg={8}>
                        <p>
                          Importo minimo di deposito:{" "}
                          <span style={{ fontWeight: 500 }}>€ 250.00</span>
                        </p>
                      </Col>
                      <Col lg={8}>
                        <p>
                          Importo massimo di deposito:{" "}
                          <span style={{ fontWeight: 500 }}>€ 2,500.00</span>
                        </p>
                      </Col>
                      <Col lg={8}>
                        <p>
                          Costo commissione (ricarica urgente):{" "}
                          <span style={{ fontWeight: 500 }}>€ 2.00</span>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className=" max-width border-0 importo-values">
                  {importoValues.map((importoVal) => (
                    <div
                      key={importoVal}
                      className={
                        "importo-value " + (importoVal == importo ? "active" : "")
                      }
                      onClick={() => {
                        this.setState({ importo: importoVal })
                      }}
                    >
                      <span>{importoVal.toFixed(2)} €</span>
                    </div>
                  ))}{" "}
                </div>

                <div
                  className="max-width border-0 depositi-form"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Form
                    layout="horizontal"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Form.Item
                      style={{ display: "flex", flexDirection: "row" }}
                      label="Importo *"
                      name="importo"
                    >
                      <InputNumber
                        min={250}
                        onChange={this.handleImporto}
                        value={importo.toFixed(2)}
                        style={{ border: `2px solid var(--accent-bg)` }}
                      />
                    </Form.Item>

                    <Form.Item
                      style={{ display: "flex", flexDirection: "row" }}
                      label="Iban"
                      name="iban"
                    >
                      <Input.Group
                        compact
                        style={{
                          border: `2px solid var(--accent-bg)`,
                          borderRadius: "6px",
                          display: "flex",
                        }}
                      >
                        <Input
                          id="iban"
                          style={{ width: "240px", height: "36px" }}
                          value={this.props.skinExtras.iban}
                          readOnly={true}
                        />
                        <Tooltip title="copy iban">
                          <Button
                            onClick={() =>
                              navigator.clipboard.writeText(
                                this.props.skinExtras.iban
                              )
                            }
                            style={{ height: "36px" }}
                          >
                            <Icon type="copy"></Icon>
                          </Button>
                        </Tooltip>
                      </Input.Group>
                    </Form.Item>

                    <Form.Item
                      label="Swift (BIC)"
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <Input.Group
                        compact
                        style={{
                          border: `2px solid var(--accent-bg)`,
                          borderRadius: "6px",
                        }}
                      >
                        <Input
                          id="swift"
                          value=""
                          readOnly={true}
                          style={{ height: "36px" }}
                        ></Input>
                      </Input.Group>
                    </Form.Item>

                    <Form.Item
                      label="Allega *"
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <Upload
                        method="get"
                        beforeUpload={this.beforeUpload}
                        onChange={this.handleChange}
                        fileList={this.state.fileList}
                        accept="image/jpeg,image/png,application/pdf"
                      >
                        <Button
                          style={{
                            height: "36px",
                            border: `2px solid var(--accent-bg)`,
                          }}
                        >
                          <Icon type="upload"></Icon>Click to Upload
                        </Button>
                      </Upload>
                    </Form.Item>

                    <button
                      style={{
                        color: "white",
                        backgroundColor: `var(--accent-bg)`,
                        border: "none",
                        minWidth: "170px",
                        height: "36px",
                        borderRadius: "3px",
                      }}
                      onClick={this.handleRequest}
                    >
                      Procedi
                    </button>
                  </Form>
                </div>
                {/* <div style={{display:'flex',width:'100%'}} className="max-width border-0">
                            <div style={{width:screenWidth < 940 ? "" : "90%",margin:"auto"}}>
                            <Row>
                            <Col md={3}>
                                  <Form.Item label="IMPORTO*">
                                  <InputNumber
                                    min={250}
                                    onChange={this.handleImporto}
                                    value={importo}
                                  />
                                  </Form.Item>
                            </Col>
                            <Col md={8}>
                                  <Form.Item label="IBAN">
                                  <Input.Group compact>
                                  <Input
                                    id="iban"
                                    style={{width:"235px"}}
                                    value = {this.props.skinExtras.iban}
                                    readOnly = {true}
                                  />
                                   <Tooltip title="copy iban">
                                    <Button onClick={() =>  navigator.clipboard.writeText(this.props.skinExtras.iban)}><Icon type="copy" ></Icon></Button>
                                  </Tooltip>
                                  </Input.Group>
                                  </Form.Item>
                            </Col>
                            <Col md={6}>
                                  <Form.Item label="SWIFT(BIC)">
                                  <Input.Group compact>
                                  <Input
                                    id="swift"
                                    value = ""
                                    readOnly = {true}
                                    style={{width:"150px"}}
                                  >
                                  </Input>
                 
                                  </Input.Group>
                                  </Form.Item>
                            </Col>
                            <Col md={5}>
                                   <Form.Item label="ALLEGA*">
                                     <Upload 
                                      method="get" 
                                      beforeUpload={this.beforeUpload}
                                      onChange={this.handleChange}
                                      fileList={this.state.fileList}
                                      accept="image/jpeg,image/png,application/pdf"
                                     >
                                      <Button><Icon type="upload"></Icon>Click to Upload</Button>
                                    </Upload>
                                    </Form.Item>
                            </Col>
                            <Col md={2}>
                            <button style={{color : "white" , backgroundColor : "#F26521" , border : "none" , width : "100%" , borderRadius : "3px" , marginTop : "55px"}}
                             onClick={this.handleRequest}
                            >
                              Procedi
                            </button>
                            </Col>
                             </Row>
                            </div>
            </div> */}
              </>
            )}
            <div
              className="max-width border-0"
              style={!forAdmin ? { marginTop: "60px" } : null}
            >
              {isCalendarOpen && (
                <CalendarRangePicker
                  setStateFunc={(item) => {
                    this.setState({
                      picker: [item.selection],
                      from: moment(item.selection.startDate).format("YYYY-MM-DD"),
                      to: moment(item.selection.endDate).format("YYYY-MM-DD"),
                      fromLabel: moment(item.selection.startDate).format(
                        "DD/MM/YYYY"
                      ),
                      toLabel: moment(item.selection.endDate).format("DD/MM/YYYY"),
                    })
                  }}
                  setStateFuncEmpty={() => {
                    this.setState({
                      from: "",
                      to: "",
                      fromLabel: "",
                      toLabel: "",
                    })
                  }}
                  picker={picker}
                  setCalendar={this.setCalendar}
                  handleSubmit={this.handleSubmit}
                />
              )}
              <div className="MoviFirst" style={{ width: "100%" }}>
                <h1 className="heading-tab voucher">ULTIME RICHIESTE DI DEPOSITO</h1>
                {/* <div className="DateUtent">
                  <div className="datepics ml-auto mr-2">
                    <Form
                      {...formItemLayout}
                      onSubmit={this.handleSubmit}
                      className="filters"
                    >
                      <div
                        className="dal calendar"
                        type="text"
                        onClick={() => {
                          this.setCalendar(true)
                        }}
                      >
                        <span>
                          {fromLabel
                            ? `${fromLabel} - ${toLabel}`
                            : "Seleziona la data"}
                          <i className="fal fa-calendar-alt"></i>
                        </span>
                      </div>
                    </Form>
                    <div className="codice"></div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="select-date max-width">
              <div className="date-inputs">
                {" "}
                <div className="date-input">
                  <span className="date-input-title">DAL:</span>

                  <DatePicker
                    onChange={(e) => {
                      this.setState({
                        from: moment(e).format("YYYY-MM-DD"),
                      })
                    }}
                    format={("DD/MM/YYYY", "DD/MM/YYYY")}
                  />
                </div>
                <div className="date-input">
                  <span className="date-input-title">AL:</span>
                  <DatePicker
                    onChange={(e) => {
                      this.setState({
                        to: moment(e).format("YYYY-MM-DD"),
                      })
                    }}
                    format={("DD/MM/YYYY", "DD/MM/YYYY")}
                  />
                </div>
              </div>

              <Button
                className="search-btn"
                icon="search"
                onClick={this.handleSubmit}
              >
                RICERCA
              </Button>
            </div>
            <div className="row no-gutters max-width">
              <div className="col-md-12">
                {refills?.message && (
                  <div className="alert alert-danger text-center">
                    {refills?.message}
                  </div>
                )}

                {loadingPayments &&
                  (forAdmin ? <div className="loaderAdmin"></div> : <Loader />)}
                {!loadingPayments && (
                  <table className="transTable Depositi">
                    <thead>
                      <tr>
                        <td className="NoData wsNwpDeposit">ESEGUITO</td>
                        {!forAdmin && (
                          <td className="NoData wsNwpDeposit">DEPOSITATO</td>
                        )}
                        {screenWidth > 960 && forAdmin && (
                          <td className="NoData wsNwpDeposit">BARCODE</td>
                        )}
                        {/* {!forAdmin && <td  className="NoData wsNwpDeposit">MITTENTE e DESTINATARIO</td>} */}
                        {forAdmin && (
                          <>
                            <td className="NoData wsNwpDeposit">DAL</td>
                            <td className="NoBorderLeft wsNwpDeposit"></td>
                            <td className="NoData NoBorderLeft wsNwpDeposit">AL</td>
                          </>
                        )}
                        {screenWidth >= 1380 && (
                          <td className="NoData wsNwpDeposit Desk">
                            METODI DI PAGAMENTO
                          </td>
                        )}
                        <td className="NoData wsNwpDeposit">IMPORTO</td>
                        <td className="NoData wsNwpDeposit Desk">
                          {screenWidth <= 1455 ? "COMM" : "COMMISSIONE"}
                        </td>
                        <td className="NoData wsNwpDeposit Desk">STATO</td>
                        <td className="NoData wsNwpDeposit Desk">RICEVUTA</td>

                        {this.props.screenWidth <= 960 && forAdmin && (
                          <td className="wsNwpDeposit Desk"></td>
                        )}
                        {forAdmin && (
                          <td className="NoData wsNwpDeposit Desk">CARICATO IL</td>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {!refills?.message &&
                      (refillsO || []) &&
                      refillsO?.length === 0 ? (
                        <tr className="NoData">
                          <td colSpan="8">
                            {" "}
                            <i className="fal fa-info-circle"></i>
                            <span>No Data</span>
                          </td>
                        </tr>
                      ) : (
                        (refillsO || []).map((item, index) => {
                          return (
                            <tr
                              key={index}
                              onClick={(e) => {
                                if (e.target.tagName !== "I") {
                                  if (
                                    forAdmin &&
                                    this.props.screenWidth <= 402 &&
                                    ![...e.target.classList].includes("bc")
                                  ) {
                                    this.setState({
                                      showModalResponsive: true,
                                      modalDetails: item,
                                    })
                                  } else if (
                                    !forAdmin &&
                                    this.props.screenWidth <= 800 &&
                                    ![...e.target.classList].includes("bc")
                                  ) {
                                    this.setState({
                                      showModalResponsive: true,
                                      modalDetails: item,
                                    })
                                  }
                                }
                              }}
                            >
                              <td className="NoData wsNwp">
                                {moment(item.eseguito_il).format("DD/MM/YY HH:mm")}
                              </td>
                              {screenWidth > 960 && forAdmin && (
                                <td
                                  className="NoData wsNwp"
                                  onClick={() => {
                                    console.log(item)
                                    this.showModalDesktop(item.message)
                                  }}
                                >
                                  <div
                                    style={{
                                      textDecoration: item.message?.id ? "" : "none",
                                    }}
                                    className="bcDepositi"
                                  >
                                    {item.id}
                                  </div>
                                </td>
                              )}
                              {!forAdmin && (
                                // <td >
                                //   <div style={{textAlign : "center"}}>{item.mittente}  <Icon style={{fontSize:"large" , color : item.metodo === "Deposito" || item.metodo === "Bonifico Bancario"  ? "green"  : "red"}}
                                //   type={item.metodo === "Deposito" || item.metodo === "Bonifico Bancario"  ? "arrow-right"  : "arrow-left" } /> {item.destinatario}</div>
                                // </td>
                                <td className="NoData wsNwp">
                                  {moment(item.caricato_il).format("DD/MM/YY HH:mm")}
                                </td>
                              )}
                              {forAdmin && (
                                <>
                                  <td className="wsNwp NoData">{item.mittente}</td>
                                  <td className="wsNwp">
                                    <Icon
                                      style={{
                                        fontSize: "large",
                                        color: "#5c5c5c",
                                        margin: "auto",
                                      }}
                                      type={
                                        item.metodo === "Deposito" ||
                                        item.metodo === "Bonifico Bancario"
                                          ? "arrow-right"
                                          : "arrow-left"
                                      }
                                    />
                                  </td>
                                  <td
                                    className="wsNwp NoData"
                                    style={{ color: "#5c5c5c" }}
                                  >
                                    {item.destinatario}
                                  </td>
                                </>
                              )}
                              {screenWidth >= 1380 && (
                                <td className="wsNwp Desk">
                                  <div className="wsNwp NoData">{item.metodo}</div>
                                </td>
                              )}
                              <td className="wsNwp right importoTd">
                                <i
                                  className={`fal fa-${
                                    item.metodo === "Deposito" ||
                                    item.metodo === "Bonifico Bancario"
                                      ? "plus"
                                      : "minus"
                                  }-circle fa-sm`}
                                  style={{
                                    color:
                                      item.metodo === "Deposito" ||
                                      item.metodo === "Bonifico Bancario"
                                        ? "#0da90f"
                                        : "#ff0000",
                                  }}
                                />
                                <span>{item.amount}€</span>
                              </td>
                              <td className="NoData wsNwp Desk">
                                {numberWithCommas(item.commissione)}€
                              </td>
                              {forAdmin ? (
                                <td className="wsNwp Desk NoData">
                                  <a
                                    onClick={() =>
                                      item.document ? this.info(item.id) : ""
                                    }
                                    style={{
                                      textDecoration: item.document
                                        ? "underline"
                                        : "none",
                                    }}
                                  >
                                    {item.document
                                      ? "Apri l'allegato"
                                      : "Non pervenuto"}
                                  </a>
                                </td>
                              ) : (
                                <td className="wsNwp Desk NoData">
                                  <a
                                    onClick={() =>
                                      item.document ? this.info(item.id) : ""
                                    }
                                    style={{
                                      textDecoration: item.document
                                        ? "underline"
                                        : "none",
                                    }}
                                  >
                                    {item.document ? (
                                      "Apri l'allegato"
                                    ) : (
                                      <>
                                        <i className="fal fa-paperclip" />
                                        Non pervenuto
                                      </>
                                    )}
                                  </a>
                                </td>
                              )}

                              <td className="wsNwp Desk NoData">
                                {/* {item.stato.toUpperCase()} */}
                                {accountInfo.profile.role.name === "super_admin" &&
                                item.stato === "pending" ? (
                                  <div>
                                    <Button
                                      onClick={() => this.showConfirmAccept(item.id)}
                                      style={{
                                        border: "none",
                                        background: "transparent",
                                      }}
                                    >
                                      <i
                                        style={{ color: "green", fontSize: "18px" }}
                                        className="fal fa-check"
                                      ></i>
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        this.showConfirmDecline(item.id)
                                      }
                                      style={{
                                        border: "none",
                                        background: "transparent",
                                      }}
                                    >
                                      <i
                                        style={{ color: "red", fontSize: "18px" }}
                                        className="fa fa-times"
                                      ></i>
                                    </Button>
                                  </div>
                                ) : item.stato === "accepted" ? (
                                  forAdmin ? (
                                    <Icon
                                      style={{ fontSize: "large", color: "#0da90f" }}
                                      type="check-circle"
                                    />
                                  ) : (
                                    "Accettato"
                                  )
                                ) : item.stato === "rejected" ? (
                                  forAdmin ? (
                                    <Icon
                                      style={{ fontSize: "large", color: "#ff0000" }}
                                      type="close-circle"
                                    />
                                  ) : (
                                    "Rifiutato"
                                  )
                                ) : (
                                  "Pending"
                                )}
                              </td>
                              {this.props.screenWidth <= 960 && forAdmin && (
                                <td
                                  className="wsNwp"
                                  style={{ textAlign: "center" }}
                                  onClick={() => {
                                    this.setState({
                                      showModalResponsive: true,
                                      modalDetails: item,
                                      index: index,
                                    })
                                  }}
                                >
                                  <i className="fal fa-eye"></i>
                                </td>
                              )}
                              {forAdmin && (
                                <td className="wsNwp Desk NoData">
                                  {moment(item.caricato_il).format("DD/MM/YY HH:mm")}
                                </td>
                              )}
                            </tr>
                          )
                        })
                      )}
                    </tbody>
                  </table>
                )}
              </div>
              <div className="paginationWrapper">
                <Pagination
                  onChange={(e) => {
                    getRequestedRefills(
                      e,
                      perPage,
                      accountInfo?.profile?.role?.name === "super_admin"
                        ? accountInfo.profile.activity_id
                        : activeSkinId != -1
                        ? activeSkinId
                        : null,
                      from,
                      to
                    )
                  }}
                  total={
                    Object.keys(refillsPages).length === 0
                      ? 1
                      : refillsPages.total_pages * 10
                  }
                />
                <Select
                  defaultValue={25}
                  onChange={(e) => {
                    this.setState({ perPage: parseInt(e) })
                    getRequestedRefills(
                      1,
                      e,
                      accountInfo?.profile?.role?.name === "super_admin"
                        ? accountInfo.profile.activity_id
                        : activeSkinId != -1
                        ? activeSkinId
                        : null,
                      from,
                      to
                    )
                  }}
                >
                  <Option value={10}>10 / Pagina</Option>
                  <Option value={25}>25 / Pagina</Option>
                  <Option value={50}>50 / Pagina</Option>
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const DepositiF = Form.create({ name: "Depositi" })(Depositi)

const mapsStateToProps = (state) => ({
  isShowing: state.main.isShowing,
  refills: state.auth.requestedRefills,
  loadingPayments: state.auth.loadingPayments,
  accountInfo: state.auth.accountInfo,
  navbarSearch: state.main.navbarSearch,
  skinExtras: state.auth.skinExtras,
  refillsPages: state.auth.refillsPages,
  screenWidth: state.main.screenWidth,
  fromDate: state.auth.fromDate,
  transferDocument: state.auth.transferDocument,
})

export default withRouter(
  connect(mapsStateToProps, { ...MainActions, ...AuthActions })(DepositiF)
)
