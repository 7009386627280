import React, { useState } from "react";
import QrReader from "./QrCode";


const QrCodeReader = (props) => {
  const { children, visibility,close } = props;
  const [data, setData] = useState("No result");

  return (
    <React.Fragment>
      {visibility && <QrReader onSubmit={(d) => setData(d)} close={close}/>}
      {children && children(data)}
    </React.Fragment>
  );
}

export default QrCodeReader;
