export const caffeeImages = {
  CAFFE396:
    "https://www.mamanero.it/wp-content/uploads/2018/12/Caffe╠Ç-tostato-Mars.jpg",
  CAFFE398:
    "https://www.mamanero.it/wp-content/uploads/2018/12/Caffe╠Ç-tostato-Capri.jpg",
  CAFFE399: "https://www.mamanero.it/wp-content/uploads/2018/12/Cialde.jpg",
  CAFFE401:
    "https://www.mamanero.it/wp-content/uploads/2018/12/Kit-150-pz-700x700px.jpg",
  CAFFE404:
    "https://www.mamanero.it/wp-content/uploads/2018/12/Zucchero-Bianco-Bustine.jpg",
  CAFFE405:
    "https://www.mamanero.it/wp-content/uploads/2018/12/Zucchero-Bianco-Stick.jpg",
  CAFFE406:
    "https://www.mamanero.it/wp-content/uploads/2018/12/Zucchero-di-canna-Bustine.jpg",
  CAFFE407:
    "https://www.mamanero.it/wp-content/uploads/2018/12/Zucchero-di-canna-Stick.jpg",
  CAFFE408: "https://www.mamanero.it/wp-content/uploads/2018/12/Pressino.jpg",
  CAFFE409: "https://www.mamanero.it/wp-content/uploads/2018/12/Addolcitore.jpg",
  CAFFE410:
    "https://www.mamanero.it/wp-content/uploads/2018/12/Macchina-Caffe╠Ç.jpg",
  CAFFE490:
    "https://www.mamanero.it/wp-content/uploads/2019/01/mamanero-macchina-caffe-01.jpg",
  CAFFE500: "https://www.mamanero.it/wp-content/uploads/2019/12/05.jpg",
  CAFFE569: "https://www.mamanero.it/wp-content/uploads/2019/12/04.jpg",
  CAFFE580: "https://www.mamanero.it/wp-content/uploads/2019/12/08.jpg",
  CAFFE581: "https://www.mamanero.it/wp-content/uploads/2019/12/01.jpg",
  CAFFE582: "https://www.mamanero.it/wp-content/uploads/2019/12/07.jpg",
  CAFFE588: "https://www.mamanero.it/wp-content/uploads/2019/12/ginseng.jpg",
  CAFFE605:
    "https://www.mamanero.it/wp-content/uploads/2019/12/miele-limone-zenzero.jpg",
  CAFFE606: "https://www.mamanero.it/wp-content/uploads/2019/12/golden-milk.jpg",
  CAFFE607:
    "https://www.mamanero.it/wp-content/uploads/2019/12/capsula-blue-moon.jpg",
  CAFFE608:
    "https://www.mamanero.it/wp-content/uploads/2019/12/capsula-chai-tea.jpg",
  CAFFE609: "https://www.mamanero.it/wp-content/uploads/2019/12/guarana.jpg",
  CAFFE610:
    "https://www.mamanero.it/wp-content/uploads/2019/12/capsula-gianduia.jpg",
  CAFFE611:
    "https://www.mamanero.it/wp-content/uploads/2019/12/mirtillo-e-melograno.jpg",
  CAFFE612: "https://www.mamanero.it/wp-content/uploads/2019/12/minicao.jpg",
  CAFFE613: "https://www.mamanero.it/wp-content/uploads/2019/12/the-al-limone.jpg",
  CAFFE615: "https://www.mamanero.it/wp-content/uploads/2019/12/nocciola.jpg",
  CAFFE616: "https://www.mamanero.it/wp-content/uploads/2019/12/08.jpg",
  CAFFE617: "https://www.mamanero.it/wp-content/uploads/2019/12/the-alla-pesca.jpg",
  CAFFE618: "https://www.mamanero.it/wp-content/uploads/2019/12/ginseng.jpg",
  CAFFE619: "https://www.mamanero.it/wp-content/uploads/2019/12/orzo.jpg",
  CAFFE633: "https://www.mamanero.it/wp-content/uploads/2018/12/Cialde.jpg",
  CAFFE638: "https://www.mamanero.it/wp-content/uploads/2020/11/77.png",
  CAFFE643: "https://www.mamanero.it/wp-content/uploads/2021/02/click-nera123.jpg",
}
