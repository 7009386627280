import React from "react";
import { connect } from "react-redux";
import { AuthActions, MainActions } from "redux-store/models";
import { withRouter } from "react-router-dom";
import { Button, Checkbox, Form, Input, Col, Row } from "antd";
import OtpInput from "./OtpInput";
import "./index.css";
class Autenticazione extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minutes: 15,
      seconds: 0,
      otp: [],
      ind: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  inputFocus = (elmnt) => {
    if (
      elmnt.key === "Delete" ||
      elmnt.key === "Backspace" ||
      elmnt.key === "ArrowLeft"
    ) {
      if (elmnt.key === "ArrowLeft") {
        const next = elmnt.target.tabIndex - 2;
        if (next > -1) {
          elmnt.target.form.elements[next].focus();
        }
      } else {
        if (this.state.otp[this.state.ind] == "") {
          const next = elmnt.target.tabIndex - 1;
          if (next > -1) {
            elmnt.target.form.elements[next].focus();
          }
        } else if (this.state.otp[this.state.ind] == undefined) {
          const next = elmnt.target.tabIndex - 2;
          if (next > -1) {
            elmnt.target.form.elements[next].focus();
          }
        }
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  handleFocus(index) {
    this.setState({ ind: index });
  }

  handleChange(event) {
    const { otp, ind } = this.state;
    otp[ind] = event.target.value;
    this.setState({ otp });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { otp } = this.state;
    const { username, password } = this.props.credentials;
    const device_token = JSON.parse(localStorage.getItem("device_token"));
    if (otp.length === 6 && !otp.includes("")) {
     await this.props.signInByOtp(
        username,
        password,
        device_token,
        true,
        otp.join("")
      );
      if (this.props.match.params["link2"]) {
        if (this.props.match.params["link2"] === "linkTo") {
          window.location.hash = `dashboard/ricariche/${this.props?.match?.params?.c1}/${this.props?.match?.params?.c2}`;
        } else {
          window.location.hash = `dashboard/${
            this.props?.match?.params?.["link2"] || "ricariche"
          }`;
        }
      }
    }
  }

  decreaseTime = () => {
    if (this.state.seconds > 0) {
      this.setState({ seconds: this.state.seconds - 1 });
    } else {
      if (this.state.minutes > 0) {
        this.setState({ minutes: this.state.minutes - 1, seconds: 59 });
      }
    }
  };

  componentDidMount() {
    this.interval = setInterval(this.decreaseTime, 1000);
  }

  componentDidUpdate() {
    const {minutes, seconds} = this.state;
    if (minutes === 0 && seconds === 0) {
      this.props.history.push('/login');
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const validator = {
      require: {
        required: true,
        message: "Required",
      },
    };

    const { otpInfo } = this.props;

    const phoneNr = otpInfo?.content
      ?.substring(
        otpInfo?.content?.indexOf("*"),
        otpInfo?.content?.indexOf(".")
      )
      .replaceAll("*", "X");

    return (
      <Row type="flex" justify="center" align="middle">
        <Col>
          <div className="login-new autorize animated fadeIn">
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{ paddingBottom: "15px" }}
            >
              <Col span={6}>
                <div className="piechart">
                  <div className="piechart-main">
                    <span className="piechart-main-val">
                      {this.state.minutes < 10
                        ? "0" + this.state.minutes
                        : this.state.minutes}
                      :
                      {this.state.seconds < 10
                        ? "0" + this.state.seconds
                        : this.state.seconds}
                    </span>
                  </div>
                  <style>
                    :root
                    {`{--pieValOne:${
                      this.state.seconds === 0
                        ? 0
                        : ((1 - this.state.seconds / 60) * 100).toFixed(2)
                    }}`}
                  </style>
                </div>
              </Col>
            </Row>
            <h6>Controlla il tuo telefono</h6>
            <p className="autorize-info">
              Abbiamo inviato una sms al tuo telefono. L’accesso sarà attivo per{" "}
              <span>
                {this.state.minutes < 10
                  ? "0" + this.state.minutes
                  : this.state.minutes}
                :
                {this.state.seconds < 10
                  ? "0" + this.state.seconds
                  : this.state.seconds}{" "}
                minuti
              </span>
              . Conferma la richiesta d’accesso al nostro sistema.
            </p>
            <div name="autenticazione" className="login-form">
              <Form.Item name="tel" rules={[validator.require]} hasFeedback>
                <label>Nr. di telefono</label>
                <Input
                  placeholder="Nr. di telefono"
                  disabled
                  value={"XXXXXXXXX0144"}
                />
              </Form.Item>
              <Form.Item name="codice" rules={[validator.require]} hasFeedback>
                <label>Inserisci il codice a 6 cifre</label>
                <OtpInput
                  handleChange={this.handleChange}
                  handleFocus={this.handleFocus}
                  inputFocus={this.inputFocus}
                />
              </Form.Item>
              <Form.Item>
                <Checkbox>Non chiedermelo più su questo dispositivo</Checkbox>
              </Form.Item>
              <Button
                type="primary"
                block
                htmlType="submit"
                onClick={this.handleSubmit}
              >
                Continua
              </Button>
            </div>
            <p className="autorize-footer">
              Non ho ricevuto la notifica! <span>Rimanda</span>
            </p>
          </div>
        </Col>
        <div className="login-page-footer">
          <div className="privacy">
            <a>Privacy Notice </a>
            <div className="vl"></div>
            <a>Cookies Notice</a>
            <div className="vl"></div>
            <a>Cookies Settings</a>
          </div>
          <div className="copyright">
            Copyright © {new Date().getFullYear()} Bpoint. All rights reserved.
            Bpoint® is a registered trademark.
          </div>
        </div>
      </Row>
    );
  }
}

const mapsStateToProps = (state) => {
  return {
    loginMsg: state.auth.loginMsg,
    otpInfo: state.auth.otpInfo,
    credentials: state.auth.credentials,
  };
};

export default withRouter(
  connect(mapsStateToProps, { ...AuthActions, ...MainActions })(Autenticazione)
);
