import React, { useEffect, useState } from "react"
import {
  Card,
  Icon,
  Modal,
  Upload,
  Input,
  Form,
  notification,
  Select,
  Button,
  message,
} from "antd"
import { baseUrlImg, endpoint } from "../../config/api"
import { connect } from "react-redux"
import { MainActions } from "redux-store/models"

const { Dragger } = Upload

const { Option } = Select

function Gallery({
  onSelect,
  modalCardStyle,
  selectedImage,
  modalWidth,
  showActions,
  getAllImages,
  images,
  uploadNewImage,
  updateImage,
  deleteImage,
  imageType,
}) {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
  const [imageFiles, setImageFiles] = useState([])
  const [group, setGroup] = useState(imageType ? imageType : "logo")
  const [searchedName, setSearchedName] = useState("")
  const [isHovered, setIsHovered] = useState(null)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [updateImageId, setUpdateImageId] = useState()
  const [editedFileList, setEditedFileList] = useState([])
  const [editedImage, setEditedImage] = useState("")
  const [editedName, setEditedName] = useState("")
  const [editedGroup, setEditedGroup] = useState("")
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [imageToDeleteId, setImageToDeleteId] = useState()

  useEffect(() => {
    getAllImages(group)
  }, [group, imageType])

  const handleNewImageUpload = async () => {
    try {
      const filesData = []

      for (const file of imageFiles) {
        const img = file.originFileObj
        await new Promise((resolve) => {
          getBase64(img, (base64) => {
            const fileName = file.name.replace(/\.[^/.]+$/, "")
            filesData.push({ fileName, base64 })
            resolve()
          })
        })
      }

      await uploadNewImage(filesData, group)
      setImageFiles([])
      setTimeout(async () => {
        await getAllImages(group)
      }, 300)
    } catch (error) {
    } finally {
      setIsUploadModalOpen(false)
    }
  }

  const handleUpdateImage = async () => {
    try {
      await updateImage(
        updateImageId,
        editedName,
        editedGroup,
        editedFileList.length > 0 ? editedFileList : null
      )
      await getAllImages(group)
    } catch (error) {
      console.error(error)
    } finally {
      setEditModalOpen(false)
    }
  }

  const handleDeleteImage = async () => {
    try {
      await deleteImage(imageToDeleteId)
      setTimeout(async () => {
        await getAllImages(group)
      }, 300)
    } catch (error) {
      console.error(error)
    } finally {
      setDeleteModalOpen(false)
    }
  }

  const validateImageType = (file) => {
    const allowedTypes = ["image/png", "image/jpeg", "image/svg+xml"]
    if (allowedTypes.indexOf(file.type) === -1) {
      notification.error({
        message: "Invalid file type",
        description: "Only PNG, JPG, and SVG files are allowed.",
      })
      setImageFiles("")
      return false
    }
    return true
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener("load", () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const handleFileChange = ({ fileList }) => {
    setImageFiles(fileList)
  }

  const openEditModal = (img) => {
    setEditModalOpen(true)
    setUpdateImageId(img.id)
    setEditedName(img.name)
    setEditedGroup(img.group)
  }

  const openDeleteModal = (img) => {
    setDeleteModalOpen(true)
    setImageToDeleteId(img.id)
  }

  const draggerProps = {
    name: "file",
    multiple: true,
    // action: `${endpoint}/gallery-insert`,
    onChange: handleFileChange,
    beforeUpload: () => false,
    fileList: imageFiles,
  }

  return (
    <div className="gallery">
      <div style={{ display: "flex" }} className="filter-row">
        <Input
          placeholder="Cerca"
          value={searchedName}
          onChange={(e) => setSearchedName(e.target.value)}
          style={{ ...modalWidth }}
        />
        <Select
          value={group}
          onChange={(value) => setGroup(value)}
          style={{ ...modalWidth }}
        >
          <Option value="logo">Logo</Option>
          <Option value="scontrino">Logo scontrino</Option>
          <Option value="icon">Icon</Option>
          <Option value="background">Background</Option>
          <Option value="uncategorized">Uncategorized</Option>
        </Select>
        <Button onClick={() => setIsUploadModalOpen(true)} style={{ ...modalWidth }}>
          <Icon type="plus" style={{ fontSize: 20, color: "#bfbfbf" }} />
          Upload
        </Button>
      </div>

      <div className="gallery-images">
        {images &&
          images
            .filter((img) =>
              img?.name?.toLowerCase()?.includes(searchedName?.toLowerCase())
            )
            .sort((a, b) => b.id - a.id)
            .map((img, index) => (
              <div key={index} className="gallery-item">
                <Card
                  key={img.id}
                  hoverable
                  style={{
                    ...modalCardStyle,
                    border:
                      selectedImage &&
                      selectedImage.id === img.id &&
                      "2px solid #0078ff",
                  }}
                  onClick={() => {
                    onSelect(img)
                    showActions && openEditModal(img)
                  }}
                  onMouseEnter={() => setIsHovered(img.id)}
                  onMouseLeave={() => setIsHovered(null)}
                  actions={
                    isHovered === img.id && [
                      <Icon
                        type="setting"
                        key="setting"
                        onClick={() => openEditModal(img)}
                        style={{ fontSize: "20px" }}
                      />,
                      <Icon
                        type="delete"
                        key="edit"
                        style={{ fontSize: "20px" }}
                        onClick={(e) => {
                          openDeleteModal(img)
                          e.stopPropagation()
                        }}
                      />,
                    ]
                  }
                >
                  <img
                    src={baseUrlImg + "/" + img.path_slug}
                    alt="img"
                    style={{ width: "100%" }}
                  />
                </Card>
              </div>
            ))}
      </div>

      <Modal
        title="Upload Image"
        visible={isUploadModalOpen}
        onOk={handleNewImageUpload}
        onCancel={() => {
          setIsUploadModalOpen(false)
        }}
        okText="Upload"
        okButtonProps={{
          style: {
            backgroundColor: "#0078ff",
            color: "#fff",
            borderColor: "#0078ff",
          },
        }}
      >
        <Form>
          {/* <Upload
            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
            listType="picture-card"
            beforeUpload={validateImageType}
            showUploadList={false}
            onChange={handleFileChange}
            multiple={true}
          
          >
            {imageFiles ? (
              <img src={imageFiles} alt="logo" style={{ width: "100%" }} />
            ) : (
              <div>
                <Icon type="plus" />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )}
          </Upload> */}
          <Dragger {...draggerProps}>
            <p className="ant-upload-drag-icon">
              <Icon type="upload" />
            </p>
            <p className="ant-upload-text">
              Trascina oppure seleziona i file per caricare
            </p>
            <p className="ant-upload-hint">
              Viene supportato il caricamento del singolo file o collettivo.
              <p className="ant-upload-hint">
                File accettati: jpg, png, webp, svg, pdf, mp4.
              </p>
              <p className="ant-upload-hint">
                (attenzione alla dimensione massima del file)
              </p>
            </p>
          </Dragger>
          {/* <Form.Item>
            <Input
              placeholder="Image Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Item> */}
          <Select
            value={group}
            onChange={(value) => {
              setGroup(value)
            }}
            style={{ width: "100%", borderColor: "grey", marginTop: 10 }}
          >
            <Option value="logo">Logo</Option>
            <Option value="scontrino">Logo scontrino</Option>
            <Option value="icon">Icon</Option>
            <Option value="background">Background</Option>
          </Select>
        </Form>
      </Modal>
      <Modal
        title="Edit Image"
        visible={editModalOpen}
        onOk={handleUpdateImage}
        onCancel={() => setEditModalOpen(false)}
        okText="Update"
        okButtonProps={{
          style: {
            backgroundColor: "#0078ff",
            color: "#fff",
            borderColor: "#0078ff",
          },
        }}
      >
        <Form>
          <Upload
            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
            listType="picture-card"
            beforeUpload={validateImageType}
            showUploadList={false}
            onChange={async ({ fileList }) => {
              setEditedFileList(fileList)
              const img = fileList[fileList.length - 1].originFileObj
              await new Promise((resolve) => {
                getBase64(img, (base64) => {
                  setEditedImage(base64)
                  resolve()
                })
              })
            }}
            multiple={false}
          >
            {editedImage ? (
              <img src={editedImage} alt="img" style={{ width: "100%" }} />
            ) : (
              <div>
                <Icon type="plus" />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )}
          </Upload>
          {/* <Dragger {...draggerProps}>
            <p className="ant-upload-drag-icon">
              <Icon type="upload" />
            </p>
            <p className="ant-upload-text">
              Trascina oppure seleziona i file per caricare
            </p>
            <p className="ant-upload-hint">
              Viene supportato il caricamento del singolo file o collettivo.
              <p className="ant-upload-hint">
                File accettati: jpg, png, webp, svg, pdf, mp4.
              </p>
              <p className="ant-upload-hint">
                (attenzione alla dimensione massima del file)
              </p>
            </p>
          </Dragger> */}
          <Form.Item>
            <Input
              placeholder="Image Name"
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
            />
          </Form.Item>
          <Select
            value={editedGroup}
            onChange={(value) => {
              setEditedGroup(value)
            }}
            style={{ width: "100%", borderColor: "grey" }}
          >
            <Option value="logo">Logo</Option>
            <Option value="scontrino">Logo scontrino</Option>
            <Option value="icon">Icon</Option>
            <Option value="background">Background</Option>
          </Select>
        </Form>
      </Modal>
      <Modal
        title="Confirm Delete"
        visible={deleteModalOpen}
        onOk={handleDeleteImage}
        onCancel={() => setDeleteModalOpen(false)}
      >
        Are you sure you want to delete this image?
      </Modal>
    </div>
  )
}

const mapStateToProps = (state) => ({
  images: state.main.images,
})
export default connect(mapStateToProps, MainActions)(Gallery)
