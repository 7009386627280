import React from "react"
import { AuthActions } from "redux-store/models"
import { connect } from "react-redux"
import { notification , Upload } from "antd"
import { addLogo } from "services/auth"
const {Dragger} = Upload
export const MySpan = ({
  title,
  iconClass,
  classNm,
  handleChange,
  value,
  component,
}) => (
  <span className={classNm ? classNm+" spanField" : " spanField"}>
    <input
      placeholder={title}
      onChange={(e) => {
        handleChange(e)
      }}
      value={value || ""}
    />
    {component ? component : <i className={iconClass} />}
  </span>
)
export const draggerProps = {
  name: 'file',
  multiple: false,
  listType: 'picture',
};
export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
class Step1 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      removed: false,
      step1: {
        nome_skin: "",
        link_servizi: "",
        upload_logo1: "",
        base64_1:"",
        upload_logo2: "",
        base64_2:"",
        upload_logo3: "",
        base64_3:"",
        upload_logo4: "",
        base64_4:"",
        email: "",
        noreply_email: "",
        indirizzo: "",
        telefono: "",
        home: "",
        chi_siamo: "",
        contatti: "",
        servizi: "",
        affiliazioni: "",
        area_download: "",
        facebook: "",
        instagram: "",
        pinterest: "",
        youtube: "",
        primo_color: "000000",
        secondo_color: "000000",
        background_color:"000000",
        head_color:"000000"
      },
    }
  }

  async handleChangeOnSubmit (){
    const { addEditSkinDetails, addEditSkin, newSkinId, AddExtraData } = this.props
    const { step1 } = this.state
    const {
      email,
      indirizzo,
      telefono,
      home,
      chi_siamo,
      contatti,
      servizi,
      affiliazioni,
      area_download,
      instagram,
      facebook,
      pinterest,
      youtube,
      upload_logo1,
      base64_1,
      base64_2,
      base64_3,
      base64_4,
      primo_color,
      secondo_color,
      background_color,
      head_color
    } = step1
    if(newSkinId)
    try {
      let ifempty = false
      await Object.keys(this.state.step1).forEach((key) => {
        if (!this.state.step1[key] || this.state.step1[key] === "") {
          ifempty = true
        }
      })
      if (ifempty || Object.keys(step1).length < 24) {
        await notification["error"]({
          message: "Ops...",
          description:
            "Non puoi continuare al secondo step ,completi tutti i dati prima",
          duration: 5,
        })
      } else {
        await AddExtraData(
          email,
          indirizzo,
          telefono,
          home,
          chi_siamo,
          contatti,
          servizi,
          affiliazioni,
          area_download,
          facebook,
          instagram,
          pinterest,
          youtube,
          primo_color,
          secondo_color,
          background_color,
          head_color,
          newSkinId,
        )
      }
      if (upload_logo1?.includes(".png")) {
        await addLogo(base64_1, base64_2 , base64_3 , base64_4 ,newSkinId)
      } else {
        await notification["error"]({
          message: "Ops...",
          description: "Il formato dell'immagine dovrebbe essere png",
          duration: 5,
        })
      }
      if (
        this.props.registerSkin?.addExtraDataSucc &&
        this.props.registerSkin?.addSkinSucc
      ) {
        await addEditSkinDetails({
          step1: {
            ...this.state.step1,
          },
          step2: {
            ...(addEditSkin?.step2 ? addEditSkin.step2 : {}),
          },
          step3: {
            ...(addEditSkin?.step3 ? addEditSkin.step3 : {}),
          },
          skinId: addEditSkin?.skinId,
          skinName: addEditSkin?.skinName,
          skinPannel: true,
          stepNumber: addEditSkin?.stepNumber + 1,
        })
      }

    if (
      this.props.registerSkin?.addExtraDataSucc &&
      this.props.registerSkin?.addSkinSucc
    ) {
      await notification["success"]({
        message: "Molto Bene",
        description: "la pelle viene creata con successo",
        duration: 5,
      })
    }
  } catch (error) {
    notification["error"]({
      message: "Ops..",
      description: "Something wrong happened please contact administrator",
      duration: 5,
    })
  }
  }

  async componentDidUpdate(prevProps) {
    const {newSkinId, addEditSkin , addEditSkinDetails} = this.props
    if (prevProps.newSkinId !== newSkinId && newSkinId !== -1) {
      this.handleChangeOnSubmit();
    }

    if( ( (prevProps.registerSkin.addExtraDataSucc !== this.props.registerSkin.addExtraDataSucc) 
    || (prevProps.registerSkin.addSkinSucc !== this.props.registerSkin.addSkinSucc) ) &&
    (this.props.registerSkin?.addExtraDataSucc && this.props.registerSkin?.addSkinSucc)){
        await addEditSkinDetails({
        step1: {
          ...this.state.step1,
        },
        step2: {
          ...(addEditSkin?.step2 ? addEditSkin.step2 : {}),
        },
        step3: {
          ...(addEditSkin?.step3 ? addEditSkin.step3 : {}),
        },
        skinId: addEditSkin?.skinId,
        skinName: addEditSkin?.skinName,
        skinPannel: true,
        stepNumber: addEditSkin?.stepNumber + 1,
      })
    }
  }

  componentDidMount() {
    if (
      !JSON.stringify(this.state.step1) !==
      JSON.stringify(this.props.addEditSkin?.step1)
    ) {
      this.setState({
        step1: { ...this.props.addEditSkin?.step1 },
      })
    }
  }

  async handleSubmit(e){
    const { newSkinId } = this.props
    const { step1 } = this.state
    const {
      nome_skin,
      link_servizi,
      noreply_email,
    } = step1
    e.preventDefault()
      if (
        (link_servizi?.includes("http") || link_servizi?.includes("https") ) &&
        link_servizi?.includes("://")
      ) {
        if(newSkinId === -1){
        await this.props.AddSkinNew(
          nome_skin,
          link_servizi,
          noreply_email,
        )
        }else{
          this.handleChangeOnSubmit();
        }
      } else {
        await notification["error"]({
          message: "Ops...",
          description: "Link Servizi should be link type 'https://",
          duration: 5,
        })
      }
  }

  render() {
    const { addEditSkinDetails, addEditSkin } = this.props
    const { step1 } = this.state
    const {
      nome_skin,
      link_servizi,
      upload_logo1,
      base64_1,
      upload_logo2,
      base64_2,
      upload_logo3,
      base64_3,
      upload_logo4,
      base64_4,
      email,
      noreply_email,
      indirizzo,
      telefono,
      home,
      chi_siamo,
      contatti,
      servizi,
      affiliazioni,
      facebook,
      instagram,
      pinterest,
      youtube,
      area_download,
      primo_color,
      secondo_color,
      background_color,
      head_color
    } = step1
    return (
      <div className="AdminLogin--Step1">
        <i
          className="fal fa-long-arrow-left"
          onClick={() => {
            addEditSkinDetails({
              step1: {
                ...this.state.step1,
                upload_logo: "",
              },
              step2: {
                ...(addEditSkin?.step2 ? addEditSkin.step2 : {}),
              },
              step3: {
                ...(addEditSkin?.step3 ? addEditSkin.step3 : {}),
              },
              skinId: addEditSkin?.skinId,
              skinName: addEditSkin?.skinName,
              skinPannel: true,
              stepNumber: addEditSkin?.stepNumber - 1,
            })
          }}
        ></i>
        <div className="AdminLogin--Step1--Right">
          <div className="AdminLogin--Step1--Right--Important">
            <h1>INFORMAZIONI SITO SERVIZI</h1>
            <MySpan
              title="Nome skin"
              iconClass="fal fa-user"
              handleChange={(e) => {
                this.setState({
                  step1: { ...step1, nome_skin: e.target.value },
                })
              }}
              value={nome_skin}
            />
            <MySpan
              title="Link servizi"
              iconClass="fal fa-globe"
              handleChange={(e) => {
                this.setState({
                  step1: { ...step1, link_servizi: e.target.value },
                })
              }}
              value={link_servizi}
            />
            <MySpan
              title="E-mail"
              iconClass="fal fa-envelope"
              handleChange={(e) => {
                this.setState({
                  step1: { ...step1, email: e.target.value },
                })
              }}
              value={email}
            />
            <MySpan
              title="Noreplay E-mail"
              iconClass="fal fa-envelope"
              handleChange={(e) => {
                this.setState({
                  step1: { ...step1, noreply_email: e.target.value },
                })
              }}
              value={noreply_email}
            />
            <MySpan
              title="Indirizzo"
              iconClass="fal fa-map-marker-alt"
              handleChange={(e) => {
                this.setState({
                  step1: { ...step1, indirizzo: e.target.value },
                })
              }}
              value={indirizzo}
            />
            <MySpan
              title="Telefono"
              iconClass="fal fa-mobile"
              handleChange={(e) => {
                this.setState({
                  step1: { ...step1, telefono: e.target.value },
                })
              }}
              value={telefono}
            />
          </div>
          <div className="AdminLogin--Step1--Right--Skin">
          <h1>URL SITO DI PRESENTAZIONE</h1>
            <MySpan
              title="Home"
              iconClass="fal fa-globe"
              handleChange={(e) => {
                this.setState({
                  step1: { ...step1, home: e.target.value },
                })
              }}
              value={home}
            />
            <MySpan
              title="Chi Siamo"
              iconClass="fal fa-globe"
              handleChange={(e) => {
                this.setState({
                  step1: { ...step1, chi_siamo: e.target.value },
                })
              }}
              value={chi_siamo}
            />
            <MySpan
              title="Contatti"
              iconClass="fal fa-globe"
              handleChange={(e) => {
                this.setState({
                  step1: { ...step1, contatti: e.target.value },
                })
              }}
              value={contatti}
            />
            <MySpan
              title="Servizi"
              iconClass="fal fa-globe"
              handleChange={(e) => {
                this.setState({
                  step1: { ...step1, servizi: e.target.value },
                })
              }}
              value={servizi}
            />
            <MySpan
              title="Affiliazoni"
              iconClass="fal fa-globe"
              handleChange={(e) => {
                this.setState({
                  step1: { ...step1, affiliazioni: e.target.value },
                })
              }}
              value={affiliazioni}
            />

            <MySpan
              title="Area download"
              iconClass="fal fa-globe"
              handleChange={(e) => {
                this.setState({
                  step1: { ...step1, area_download: e.target.value },
                })
              }}
              value={area_download}
            />
          </div>
        </div>
        <div className="AdminLogin--Step1--Left">
           <div className="AdminLogin--Step1--Left--SkinLogo">
           <h1>LOGO</h1>
            <div className="AdminLogin--Step1--Left--SkinLogo--Fields">
            { !upload_logo1 ? <Dragger maxCount={1} {...draggerProps} onChange={async (e) => {
                  const file = e.file.originFileObj
                  const Base64 = await toBase64(file)
                  this.setState({
                    step1: {
                      ...step1,
                      upload_logo1: e.file?.name ? e.file?.name : "",
                      base64_1 : Base64
                    },
                  })
                }}>
              <p className="ant-upload-drag-icon">
              <i className="fal fa-plus-circle" ></i>
              </p>
              <p className="ant-upload-text">Logo a colori</p>
            </Dragger> : 
            <div className="logo-uploaded-field">
              <p>Skin logo con colore :</p>
              <div>
              <img src={base64_1} alt=""/>
              <p onClick={() => {
                this.setState({
                  step1:{
                    ...step1,
                    upload_logo1: "",
                    base64_1: ""
                  }
                })
              }}>Remove <i className="fal fa-trash" ></i></p>
              </div>
            </div>}
            
            { !upload_logo2 ? <Dragger maxCount={1} {...draggerProps} onChange={async (e) => {
                  const file = e.file.originFileObj
                  const Base64 = await toBase64(file)
                  this.setState({
                    step1: {
                      ...step1,
                      upload_logo2: e.file?.name ? e.file?.name : "",
                      base64_2 : Base64
                    },
                  })
                }}>
              <p className="ant-upload-drag-icon">
              <i className="fal fa-plus-circle" ></i>
              </p>
              <p className="ant-upload-text"> Logo bianco</p>
            </Dragger> : 
            <div className="logo-uploaded-field" >
              <p>Skin logo bianco :</p>
              <div >
              <img  src={base64_2} alt="" />
              <p onClick={() => {
                this.setState({
                  step1:{
                    ...step1,
                    upload_logo2: "",
                    base64_2: ""
                  }
                })
              }}>Remove <i className="fal fa-trash" ></i></p>
              </div>
            </div>}

            { !upload_logo3 ? <Dragger maxCount={1} {...draggerProps} onChange={async (e) => {
                  const file = e.file.originFileObj
                  const Base64 = await toBase64(file)
                  this.setState({
                    step1: {
                      ...step1,
                      upload_logo3: e.file?.name ? e.file?.name : "",
                      base64_3 : Base64
                    },
                  })
                }}>
              <p className="ant-upload-drag-icon">
              <i className="fal fa-plus-circle"></i>
              </p>
              <p className="ant-upload-text"> Logo per scontrino</p>
            </Dragger> : 
            <div className="logo-uploaded-field" >
              <p>Skin logo per scontrino :</p>
              <div>
              <img src={base64_3} alt="" />
              <p onClick={() => {
                this.setState({
                  step1:{
                    ...step1,
                    upload_logo3: "",
                    base64_3: ""
                  }
                })
              }}>Remove <i className="fal fa-trash"></i></p>
              </div>
            </div>}

            { !upload_logo4 ? <Dragger maxCount={1} {...draggerProps} onChange={async (e) => {
                  const file = e.file.originFileObj
                  const Base64 = await toBase64(file)
                  this.setState({
                    step1: {
                      ...step1,
                      upload_logo4: e.file?.name ? e.file?.name : "",
                      base64_4 : Base64
                    },
                  })
                }}>
              <p className="ant-upload-drag-icon">
              <i className="fal fa-plus-circle"></i>
              </p>
              <p className="ant-upload-text"> Favicon</p>
            </Dragger> : 
            <div className="logo-uploaded-field">
              <p>Skin favicon :</p>
              <div >
              <img src={base64_4} alt=""/>
              <p onClick={() => {
                this.setState({
                  step1:{
                    ...step1,
                    upload_logo4: "",
                    base64_4: ""
                  }
                })
              }}>Remove <i className="fal fa-trash" ></i></p>
              </div>
            </div>}
            </div>
           </div>
           <div className="AdminLogin--Step1--Left--SkinColor">
            <h1>COLORI GLOBALI</h1>
            <div className="AdminLogin--Step1--Left--SkinColor--Inserts">
            <div className="AdminLogin--Step1--Left--SkinColor--Inserts--Fields">
              <input
                type="color"
                onChange={(e) => {
                  this.setState({
                    step1: { ...step1, primo_color: e.target.value.replace('#','') },
                  })
                }}
                value={'#'+primo_color}
              />
              <p>Primo Colore</p>
            </div>
            <div className="AdminLogin--Step1--Left--SkinColor--Inserts--Fields">
              <input
                type="color"
                name="head"
                onChange={(e) => {
                  this.setState({
                    step1: { ...step1, secondo_color: e.target.value.replace('#','') },
                  })
                }}
                value={'#'+secondo_color}
              />
              <p>Secondo Colore</p>
            </div>
            <div className="AdminLogin--Step1--Left--SkinColor--Inserts--Fields">
              <input
                type="color"
                onChange={(e) => {
                  this.setState({
                    step1: { ...step1, background_color: e.target.value.replace('#','') },
                  })
                }}
                value={'#'+background_color}
              />
              <p>Background Colore</p>
            </div>
            <div className="AdminLogin--Step1--Left--SkinColor--Inserts--Fields">
              <input
                type="color"
                onChange={(e) => {
                  this.setState({
                    step1: { ...step1, head_color: e.target.value.replace('#','') },
                  })
                }}
                value={'#'+head_color}
              />
              <p>Head Colore</p>
            </div>
            </div>
          </div>
           <div className="AdminLogin--Step1--Left--SkinColor">
            <h1>SOCIAL MEDIA</h1>
            <div className="AdminLogin--Step1--Left--SocialMedia--Inserts">
            <MySpan
              title="Facebook"
              component={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7.7"
                  height="15.4"
                  viewBox="0 0 7.7 15.4"
                >
                  <path
                    className="a"
                    d="M132.813,5.294V3.369a.963.963,0,0,1,.962-.962h.963V0h-1.925a2.887,2.887,0,0,0-2.887,2.887V5.294H128V7.7h1.925v7.7h2.887V7.7h1.925l.962-2.406Z"
                    transform="translate(-128)"
                  />
                </svg>
              }
              handleChange={(e) => {
                this.setState({
                  step1: { ...step1, facebook: e.target.value },
                })
              }}
              value={facebook}
            />
            <MySpan
              title="Instagram"
              iconClass="fab fa-instagram"
              handleChange={(e) => {
                this.setState({
                  step1: { ...step1, instagram: e.target.value },
                })
              }}
              value={instagram}
              component={
                <svg xmlns="http://www.w3.org/2000/svg" width="7.7"
                height="15.4" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16"> <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/> </svg>
              }
            />
            <MySpan
              title="Pinterest"
              component={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11.704"
                  height="15.4"
                  viewBox="0 0 11.704 15.4"
                >
                  <path
                    className="a"
                    d="M1.832,8.368c.019-.048.212-.789.226-.839a5.175,5.175,0,0,1-.379-3.14c.277-1.767,2.149-2.635,3.916-2.635v0c2.213,0,3.869,1.233,3.871,3.353,0,1.66-1,3.854-2.706,3.854h0A2.435,2.435,0,0,1,5.176,8.41,1.213,1.213,0,0,1,5.008,7.3a14.684,14.684,0,0,0,.733-2.892A1.092,1.092,0,0,0,4.6,3.206,1.621,1.621,0,0,0,3.113,4.321a3,3,0,0,0,.018,2.186,14.207,14.207,0,0,1-.677,2.969C1.98,11.1,1.331,13.155,1.47,14.3L1.6,15.4l.673-.882A22.983,22.983,0,0,0,4.427,9.38a3.454,3.454,0,0,0,2.522,1.01A5.183,5.183,0,0,0,11.7,5.019C11.637,2.279,9.632,0,6.027,0V0C2.6,0,.6,1.833.109,4.145-.34,6.289.658,7.921,1.832,8.368Zm0,0"
                    transform="translate(0.001)"
                  />
                </svg>
              }
              handleChange={(e) => {
                this.setState({
                  step1: { ...step1, pinterest: e.target.value },
                })
              }}
              value={pinterest}
            />
            <MySpan
              title="Youtube"
              iconClass="fab fa-youtube"
              handleChange={(e) => {
                this.setState({
                  step1: { ...step1, youtube: e.target.value },
                })
              }}
              value={youtube}
              component={
              <svg xmlns="http://www.w3.org/2000/svg" 
              width="16" 
              height="16" 
              fill="currentColor" 
              className="bi bi-youtube" 
              viewBox="0 0 16 16"> 
              <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"/> </svg>}
            />
            </div>
          </div>
          <div className="AdminLogin--Step1--Left--Submit">
          <button
              onClick={(e) => this.handleSubmit(e)}
            >
              PASSO SUCCESSIVO
            </button>
            </div>
        </div>
      </div>
    )
  }
}
const mapsStateToProps = (state) => ({
  addEditSkin: state.auth.addEditSkin,
  newSkinId: state.auth.newSkinId,
  registerSkin: state.auth.registerSkin,
})
export default connect(mapsStateToProps, AuthActions)(Step1)
