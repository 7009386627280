import React, { Component } from "react";
import AuthActions from "redux-store/models/auth";
import { connect } from "react-redux";
import { Tooltip } from "antd";
import AziendaOImpresoForm from "./AziendaOImpresa";
import PersonaFisicaForm from "./PersonaFisica";
import { iconsFromType } from "utils/HelperFunc";

class DetailRowVisure extends Component {
  state = {
    hasDetails: false,
    isOpen: false,
  };

  setModal = () => this.setState({ isOpen: true });

  generateTicketDetailInfos = () => {
    const { VisureById } = this.props;
    return (
      <React.Fragment>
        {VisureById && VisureById.type === 2 && (
          <AziendaOImpresoForm type={VisureById.type} VisureById={VisureById} />
        )}
        {VisureById && VisureById.type === 1 && (
          <PersonaFisicaForm type={VisureById.type} VisureById={VisureById} />
        )}
      </React.Fragment>
    );
  };
  render() {
    const {
      Visure,
      allRoles,
      VisureById,
      screenWidth,
    } = this.props;

    return (
      Visure && (
        <React.Fragment>
          <div
            className="ticketDetails--row animated fadeIn Visura"
            style={{
              zIndex: Visure?.id === VisureById?.id && "2",
              backgroundColor: Visure?.id === VisureById?.id && "#ffe8dd",
            }}
          >
            <span className="status">
              <Tooltip title={Visure.status}>
                <div data-status={`${Visure.status}`}>
                  <span></span>
                </div>
              </Tooltip>
            </span>
            <span>
              {screenWidth ? (
                <span>
                  {Visure.type === "Azienda o Impresa" ? "Azienda" : "Persona"}
                </span>
              ) : (
                <React.Fragment>
                  <Tooltip title={Visure.type}>
                    <i
                      className={`fas fa-${
                        Visure.type === "Persona Fisica"
                          ? "user"
                          : Visure.type === "Azienda o Impresa"
                          ? "building"
                          : ""
                      }`}
                      aria-hidden="true"
                    />
                  </Tooltip>
                  Visura {Visure.type}
                  {Visure.status === "Nuova Richiesta" && (
                    <div className="new">New</div>
                  )}
                </React.Fragment>
              )}
            </span>
            <span>{Visure.skin}</span>
            <span>
              <i className={`${allRoles[Visure.role]}`} />
              {Visure.user}
            </span>
            <span>BP-{Visure.id}</span>
            <span>{Visure.updated_at}</span>
            <span className="options">
              {/* <i className="fal fa-file-pdf"></i> */}
              <Tooltip title="Presto Subito">
                {" "}
                <div>
                  <i className="fal fa-archive"></i>
                </div>
              </Tooltip>
              <Tooltip title="Presto Subito">
                {" "}
                <div>
                  <i className="fal fa-trash" aria-hidden="true"></i>
                </div>
              </Tooltip>
              {screenWidth <= 600 ? (
                <i
                  className="fal fa-eye"
                  onClick={() => {
                    this.props.getVisureByVisureId(Visure.id);
                  }}
                />
              ) : (
                <div
                  className="toggler"
                  onClick={() => {
                    if (VisureById && VisureById.id === Visure.id) {
                      this.props.setVisureByVisureId(null);
                    } else {
                      this.props.getVisureByVisureId(Visure.id);
                      this.setModal();
                    }
                  }}
                >
                  <i
                    className={`fal fa-chevron-${
                      VisureById && VisureById.id === Visure.id ? "up" : "down"
                    }`}
                    aria-hidden="true"
                  ></i>
                  {VisureById && VisureById.id === Visure.id
                    ? "CHIUDI"
                    : "DETTAGLI"}
                </div>
              )}
            </span>
          </div>
          {VisureById &&
            VisureById.id === Visure.id &&
            (screenWidth > 600 ? (
              <>
                {this.state.isOpen && (
                  <div
                    className="backDrop"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (VisureById && VisureById.id === Visure.id) {
                        this.props.setVisureByVisureId(null);
                      } else {
                        this.props.getVisureByVisureId(Visure.id);
                        this.setModal();
                      }
                    }}
                  ></div>
                )}
                <div className="ticketDetails--infos animated fadeIn">
                  <div className="ticketDetails--infos__body">
                    <div className="ticketDetails--infos__header">
                      <h5>{Visure?.nome_agenzia.toUpperCase()}</h5>
                      <div>
                        <span>
                          ID: <span className="ticket--id">BP-{Visure.id}</span>
                        </span>
                        <span>
                          PRESA A CARICO{" "}
                          <i
                            className="fal fa-check-circle"
                            aria-hidden="true"
                          ></i>{" "}
                        </span>
                      </div>
                    </div>
                    {this.generateTicketDetailInfos()}

                    {/* <div className="ticketDetails--infos__footer">
                      <button className="importo">
                        IMPORTO TOTALE{" "}
                        <span className="importo-span">
                          <span>€</span>
                          <span>{VisureById?.total_cost}</span>
                        </span>
                      </button>
                      <button className="accetta">ACCETTA</button>
                      <button className="annulla">ANNULLA</button>
                    </div> */}
                  </div>
                </div>
              </>
            ) : (
              <div className="ticketDetailsModal">
                <div
                  className="backDrop"
                  onClick={() => this.props.setVisureByVisureId(null)}
                />
                <div className="content">
                  <div className="head">
                    <div>
                      <i className={`${iconsFromType["visure"]}`} />
                      <span>VISURE</span>
                    </div>
                    <i
                      className="fal fa-times"
                      onClick={() => this.props.setVisureByVisureId(null)}
                    />
                  </div>
                  {this.generateTicketDetailInfos()}
                </div>
              </div>
            ))}
        </React.Fragment>
      )
    );
  }
}
const mstp = (state) => {
  return {
    VisureById: state.auth.VisureByVisureId,
    screenWidth: state.main.screenWidth,
  };
};
export default connect(mstp, AuthActions)(DetailRowVisure);
