import React from "react"
import { skin } from "config/api"
import { connect } from "react-redux"
import { AuthActions, MainActions } from "redux-store/models"
import { subscribeSocketUser, subscribeSocketSupport } from "config/socket.js"
import { withRouter } from "react-router-dom"
import { Captcha } from "shared-components"
import { notification } from "antd"
import { dontShowRPCH } from "utils/HelperFunc"
import { Button, Form, Input } from "antd"
import { Col, Row } from "antd"
import "./login.css"

class Login extends React.Component {
  cons
  state = {
    userName: "",
    password: "",
    email: "",
    isForgot: false,
    captchaToken: false,
    credentials: { username: "", password: "" },
  }

  handleChangeUsername = (event) => {
    this.setState({ userName: event.target.value })
    this.setState({
      credentials: {
        ...this.state.credentials,
        username: event.target.value,
      },
    })
  }

  handleChangePassword = (event) => {
    this.setState({ password: event.target.value })
    this.setState({
      credentials: {
        ...this.state.credentials,
        password: event.target.value,
      },
    })
  }
  socketCall = (data) => {
    subscribeSocketUser(data.id, this.props)
    if (data.role.name === "support") {
      subscribeSocketSupport(this.props)
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    const { userName, password, email, isForgot, captchaToken } = this.state
    const device_token = JSON.parse(localStorage.getItem("device_token"))

    if (isForgot) {
      this.props.forgotPassword(email)
    } else {
      if ((captchaToken && captchaToken !== "") || dontShowRPCH(isForgot)) {
        await this.props.signInByEmail(
          userName,
          password,
          this.socketCall,
          device_token
        )

        this.props.setUserCredentials(this.state.credentials)
        setTimeout(() => {
          if (this.props?.otpInfo !== undefined) {
            if (this.props?.otpInfo?.need_device_authorization === true) {
              window.location.hash = `/authenticate`
            }
          }
        }, 500)

        //console.log("this.props.match.params", this.props.match.params);
        if (this.props.match.params["link2"]) {
          if (this.props.match.params["link2"] === "linkTo") {
            window.location.hash = `dashboard/ricariche/${this.props?.match?.params?.c1}/${this.props?.match?.params?.c2}`
          } else {
            window.location.hash = `dashboard/${
              this.props?.match?.params?.["link2"] || "ricariche"
            }`
          }
        }
      } else {
        notification["error"]({
          message: "Verifica che non sei un robot (reCAPTCHA)",
          placement: "topRight",
        })
      }
    }
  }

  render() {
    const { isForgot } = this.state
    const { loginMsg, skinExtras } = this.props
    //console.log("this.props.match", this.props?.match?.params?.["link2"]);
    return (
      <Row type="flex" justify="center" align="middle">
        <Col>
          <div className="login-new animated fadeIn">
            {isForgot ? (
              <h6>Recupero credenziali</h6>
            ) : (
              <h6>Accedi con le tue credenziali</h6>
            )}

            {loginMsg ? (
              <div className="loginMsg">{loginMsg}</div>
            ) : (
              <>
                {" "}
                {isForgot ? (
                  <p className="login-register">
                    Per recuperare la tua password, inserisci la tua email
                  </p>
                ) : (
                  <p className="login-register">
                    Non hai un account?{" "}
                    {skin.skin_id === 4 ? (
                      <a href="https://services.planetservizi.it/login#/rut/y6flOjiiBH">
                        <span>REGISTRATI</span>
                      </a>
                    ) : (
                      <span>Registrati</span>
                    )}
                  </p>
                )}
              </>
            )}

            <Form name="user_login" className="login-form" layout="vertical">
              {isForgot ? (
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <label>Email</label>
                  <Input
                    placeholder="Email"
                    type="email"
                    onChange={(e) => this.setState({ email: e.target.value })}
                    value={this.state.email}
                  />
                </Form.Item>
              ) : (
                <>
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <label>Nome Utente</label>
                    <Input
                      placeholder="Nome Utente"
                      onChange={this.handleChangeUsername}
                      value={this.state.userName}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <label>Password</label>
                    <Input.Password
                      placeholder="Password"
                      value={this.state.password}
                      onChange={this.handleChangePassword}
                    />
                  </Form.Item>
                </>
              )}
              {!dontShowRPCH(isForgot) && (
                <Captcha
                  onChange={(token) => this.setState({ captchaToken: token })}
                />
              )}
              <Form.Item>
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  onClick={this.handleSubmit}
                  style={dontShowRPCH(isForgot) ? {} : { marginTop: "30px" }}
                >
                  {isForgot ? "VAI" : "Log in"}
                </Button>
              </Form.Item>
            </Form>
            <p
              className="forgot-pass"
              onClick={() => this.setState({ isForgot: !isForgot })}
            >
              {!isForgot ? " Hai dimenticata il tuo password?" : "Login"}
            </p>
          </div>
        </Col>
        <div className="login-page-footer">
          <div className="privacy">
            <a>Privacy Notice </a>
            <div className="vl"></div>
            <a>Cookies Notice</a>
            <div className="vl"></div>
            <a>Cookies Settings</a>
          </div>
          <div className="copyright">
            Copyright © {new Date().getFullYear()} {skinExtras.name}. All rights
            reserved. {skinExtras.name}® is a registered trademark.
          </div>
        </div>
      </Row>

      // <React.Fragment>
      //   <div className="leftLogin animated fadeIn">
      //     <div className="logoLogin">
      //       <img src={images.logoFooter} alt="" />
      //     </div>
      //   </div>
      //   <form className="login animated fadeIn" method="POST">
      //     <div className="title">
      //       <i className="fal fa-user-circle"></i>
      //       <div>Login</div>
      //     </div>
      //     <ul>
      //       {isForgot ? (
      //         <li>
      //           <input
      //             onKeyDown={(e) => {
      //               if (e.keyCode === 13) {
      //                 this.handleSubmit(e);
      //               }
      //             }}
      //             type="text"
      //             placeholder="E-mail"
      //             name="email"
      //             onChange={(e) => {
      //               this.setState({ email: e.target.value });
      //             }}
      //             id="loginEmail"
      //           />
      //         </li>
      //       ) : (
      //         <>
      //           <li>
      //             <input
      //               onKeyDown={(e) => {
      //                 if (e.keyCode === 13) {
      //                   this.handleSubmit(e);
      //                 }
      //               }}
      //               type="text"
      //               placeholder="Username"
      //               name="username"
      //               onChange={this.handleChangeUsername}
      //               id="loginUserName"
      //             />
      //           </li>
      //           <li>
      //             <input
      //               onKeyDown={(e) => {
      //                 if (e.keyCode === 13) {
      //                   this.handleSubmit(e);
      //                 }
      //               }}
      //               type="password"
      //               placeholder="Password"
      //               name="password"
      //               id="password"
      //               onChange={this.handleChangePassword}
      //             />
      //           </li>
      //         </>
      //       )}
      //       {!dontShowRPCH(isForgot) && <Captcha onChange={(token) => this.setState({ captchaToken:token })} />}
      //       <li>
      //         <div className={`display-flex  ${skin.skin_id !== 4 && "width-100"}`}>
      //           <button type="submit" onClick={this.handleSubmit} className="loginBtn">
      //             {isForgot ? "Vai" : "Login"}
      //           </button>
      //           {skin.skin_id === 4 && (
      //             <a href="https://services.planetservizi.it/login#/rut/y6flOjiiBH">
      //               <span>REGISTRATI</span>
      //             </a>
      //           )}
      //         </div>

      //         <span className="forgotPassword" onClick={() => this.setState({ isForgot: !isForgot })}>
      //           {!isForgot ? "Password dimenticata?" : "Login"}
      //         </span>
      //       </li>
      //     </ul>

      //     {loginMsg && <div className="loginMsg">{loginMsg}</div>}
      //   </form>
      // </React.Fragment>
    )
  }
}

const mapsStateToProps = (state) => {
  return {
    loginMsg: state.auth.loginMsg,
    otpInfo: state.auth.otpInfo,
    skinExtras: state.auth.skinExtras,
  }
}

export default withRouter(
  connect(mapsStateToProps, { ...AuthActions, ...MainActions })(Login)
)
