import $ from "jquery"

export const isLocalHost = Boolean(
  ["localhost", "ngrok", "http://192.168.22.143"].some((location) =>
    (window.location.href || "").includes(location)
  )
)

export const gamesApiByServiceId = {
  BBT001: "bbetVoucher",
  BULL001: "sportbullVoucher",
  MBBT001: "midporteVoucher",
  FLBT001: "flashbetVoucher",
  BETL001: "betalandVoucher",
  FLBT002: "flashbetVoucherFlashPoints",
  AMBT001: "amazingbetVoucher",
  SportBet001: "sportbetVoucher",
  BetflagDirect001: "betflag-direct",
}

export const disallowedCompanies = {
  8: ["Stanleybet", "TotoPay", "SportBull", "Mideporte Betting", "Flashbet"],
  6: ["STBT", "BGAM", "TOTOWB", "MBBT"],
}

export const allRoles = {
  user: "fal fa-user",
  agency: "fas fa-store",
  agent: "fas fa-user-tie",
  super_admin: "fas fa-store",
}
export const BannerColors = {
  BET: "#042842",
  BUL: "#3D0007",
  BBL: "#3D0007",
  BGM: "#03312E",
  BBT: "#ed5a12",
  BWN: "#fec90b",
  PKS: "#d70909",
  PPB: "#e44b23",
  STC: "#00bfd2",
  WHL: "#01153a",
  STB: "#3D0007",
  TPP: "#0B161A",
  FLB: "#012519",
  AMB: "#1caf08",
  BTL: "#2f8b4c",
  default: "var(--accent-bg)",
}
export const docType = [
  {
    id: "1",
    name: "Carta d`identità",
  },
  {
    id: "2",
    name: "Patente guida",
  },
  {
    id: "3",
    name: "Passaporto",
  },
]
export const newAzioniSubmenu = {
  dashboard: [
    {
      id: 1,
      name: "RICARICHE",
      link: "dashboard/ricariche",
      displayRole: ["super_admin", "agency", "agent", "user", "noUser"],
    },
    {
      id: 2,
      name: "PAGAMENTI",
      link: "dashboard/pagamenti",
      displayRole: ["super_admin", "agency", "agent", "user", "noUser"],
    },
    {
      id: 3,
      name: "GIFT CARDS",
      link: "dashboard/gift_cards",
      displayRole: ["super_admin", "agency", "agent", "user", "noUser"],
    },
    {
      id: 8,
      name: "Spedizioni",
      link: "spedizioni",
      displayRole: ["super_admin", "agency", "agent", "user", "noUser"],
    },
    {
      id: 7,
      name: "Visure",
      link: "visure",
      displayRole: ["super_admin", "agency", "agent", "user", "noUser"],
    },
    {
      id: 4,
      name: "Caffè Mamanero",
      link: "dashboard/caffe_mamanero",
      displayRole: ["super_admin", "agency", "agent", "user", "noUser"],
      disallowedSkinIds: [0, 1, 3, 4, 5, 8, 51, 52, 53, 55, 56, 57, 58, 60],
    },
    {
      id: 5,
      name: "PRENOTAZIONI",
      link: "forms",
      displayRole: ["super_admin", "agency", "agent", "user", "noUser"],
    },
    {
      id: 6,
      name: "VOUCHER",
      link: "dashboard/voucher",
      displayRole: ["super_admin", "agency", "agent", "user", "noUser"],
    },
  ],
  support: [
    // test push
    {
      id: 1,
      name: "Dettagli prenotazioni",
      link: "dettagli-prenotazioni",
      displayRole: ["support", "super_admin", "agency", "user"],
    },
    {
      id: 2,
      name: "Configura",
      link: "configura",
      displayRole: ["super_admin", "agency", "agent", "user"],
    },
    {
      id: 3,
      name: "Messaggi",
      link: "annunci",
      displayRole: ["super_admin", "agency", "agent", "user"],
    },
    {
      id: 4,
      name: "Support",
      link: "support",
      displayRole: ["support"],
    },
  ],
  shop: [
    {
      id: 1,
      name: "E-commerce",
      link: "Messaggi/e_commerce",
      displayRole: ["super_admin", "agency", "agent", "user"],
    },
  ],
  contabilita: [
    {
      id: 1,
      name: "Lista Movimenti",
      link: "transazioni",
      displayRole: ["super_admin", "agency", "agent", "user"],
    },
    {
      id: 2,
      name: "Lista Utenti",
      link: "account-info",
      displayRole: ["super_admin", "agency", "agent"],
    },
    {
      id: 3,
      name: "Ricarica Utenti",
      link: "wallet",
      i: "fal fa-wallet",
      displayRole: ["agency"],
    },
    // {
    //   id: 4,
    //   name: "Ricarica Conto",
    //   link: "carica-conto",
    //   displayRole: ["super_admin", "agency", "user"],
    // },
    {
      id: 5,
      name: "Rendi Conto",
      link: "fatture",
      displayRole: ["super_admin", "agency", "agent"],
    },
    {
      id: 6,
      name: "Estratto Conto",
      link: "depositi",
      displayRole: ["super_admin", "agency", "agent", "simple_user"],
    },
    {
      id: 7,
      name: "Ordine shop",
      link: "orders-shop",
      displayRole: ["super_admin", "agency", "agent"],
    },
  ],
}
export const newAzioni = [
  {
    id: 1,
    name: "Servizi",
    link: `${newAzioniSubmenu["dashboard"][0].link}`,
    active: "dashboard",
    i: "fal fa-list-alt",
    displayRole: ["super_admin", "agency", "agent", "user", "noUser"],
  },
  {
    id: 2,
    name: "SUPPORT",
    link: `${newAzioniSubmenu["support"][0].link}`,
    active: "support",
    i: "fal fa-user-headset",
    displayRole: ["super_admin", "agency", "agent", "user", "support"],
  },
  {
    id: 3,
    name: "SHOP",
    link: `products`,
    active: "products",
    i: "fal fa-store",
    displayRole: ["super_admin", "agency", "agent", "user"],
    new: true,
  },
  {
    id: 4,
    name: "Contabilità",
    link: `${newAzioniSubmenu["contabilita"][0].link}`,
    active: "contabilita",
    i: "fal fa-wallet",
    displayRole: ["super_admin", "agency", "agent", "user"],
  },
  {
    id: 5,
    name: "Statistiche",
    link: "reportistica",
    active: "reportistica",
    type: "span",
    i: "fal fa-analytics",
    displayRole: ["super_admin", "agency", "agent", "user"],
  },
  {
    id: 6,
    name: "AREA DOWNLOAD",
    link: "areaDownload",
    active: "areaDownload",
    i: "fal fa-download",
    displayRole: ["super_admin", "agency", "agent"],
  },
]
export const azioni = [
  {
    id: 1,
    name: "messaggi",
    link: "annunci",
    i: "fal fa-comment-alt-lines",
    displayRole: ["super_admin", "agency", "agent", "user"],
  },
  {
    id: 2,
    name: "lista utenti",
    link: "account-info",
    i: "fal fa-user-friends",
    displayRole: ["super_admin", "agency", "agent", "main_admin"],
  },
  {
    id: 8,
    name: "ricarica utenti",
    link: "wallet",
    i: "fal fa-wallet",
    displayRole: ["agency"],
  },
  {
    id: 3,
    name: "lista movimenti",
    link: "transazioni",
    i: "fal fa-file-invoice-dollar",
    displayRole: ["super_admin", "agency", "agent", "user"],
  },
  {
    id: 4,
    name: "estratto conto",
    link: "depositi",
    i: "fal fa-credit-card",
    displayRole: ["agency", "agent", "user"],
  },
  {
    id: 5,
    name: "servizi",
    link: "dashboard",
    i: "fal fa-shopping-cart",
    displayRole: ["super_admin", "agency", "agent", "user"],
  },
  {
    id: 9,
    name: "Prenotazioni",
    link: "forms",
    i: "fab fa-wpforms",
    displayRole: ["super_admin", "user", "agency"],
  },
  // {
  //   id: 12,
  //   name: "Visure",
  //   link: "visure",
  //   i: "fab fa-wpforms",
  //   displayRole: ["super_admin", "user", "agency"],
  // },
  {
    id: 6,
    name: "configura",
    link: "configura",
    i: "fal fa-cogs",
    displayRole: ["super_admin", "agency", "agent", "user"],
  },
  // {
  //   id: 7,
  //   name: "carica conto",
  //   link: "carica-conto",
  //   i: "fal fa-receipt",
  //   displayRole: ["super_admin", "agency", "user"],
  // },
  {
    id: 10,
    name: "Support",
    link: "support",
    i: "fal fa-user-headset",
    displayRole: ["support"],
  },
  {
    id: 11,
    name: "Dettagli Prenotazioni",
    link: "dettagli-prenotazioni",
    i: "fab fa-wpforms",
    displayRole: ["support", "super_admin", "agency", "user"],
  },
  // {
  //   id: 13,
  //   name: "Dettagli Visure",
  //   link: "dettagli-visure",
  //   i: "fab fa-wpforms",
  //   displayRole: ["super_admin", "user", "agency", "support"],
  // },
  {
    id: 13,
    name: "Fatture",
    link: "fatture",
    i: "fas fa-ballot-check",
    displayRole: ["super_admin"],
  },
]
export const administrazioni = [
  {
    id: 1,
    name: "account",
    link: "admin-account",
  },
  {
    id: 2,
    name: "annunci",
    link: "annunci-admin",
  },
  {
    id: 3,
    name: "operazioni",
    link: "operazioni",
  },
  {
    id: 4,
    name: "impostazioni",
    link: "impostazioni",
  },
]

const getServicesBasedOnServiceId = (
  name,
  service_id,
  inMobile,
  costList,
  service_type
) => {
  let serviceCostList = (costList || ["10.00", "25.00", "50.00", "100.00"]).map(
    (price) => ({ cost: `${price}` })
  )
  if (inMobile) {
    serviceCostList.push({ cost: "+", type: "+" })
  }
  return serviceCostList.map((service, index) => ({
    cost: service.cost,
    name: `${name} Voucher`,
    service_id: inMobile ? `${service_id.slice(0, -1)}${index + 1}` : service_id,
    type: service_type ? service_type : service.type ? service.type : "1",
  }))
}
const generateServicesMobileAndDeskt = (
  name,
  service_id,
  key,
  costList,
  service_type
) => {
  return {
    [`${key}`]: [
      ...getServicesBasedOnServiceId(
        name,
        service_id,
        false,
        costList,
        service_type
      ),
    ],
    [`${key}Mobile`]: [
      ...getServicesBasedOnServiceId(name, service_id, true, costList, service_type),
    ],
  }
}

export const betServices = {
  ...generateServicesMobileAndDeskt("BGame", "BGM001", "BGAM", null, "exalogic"),
  ...generateServicesMobileAndDeskt("BBet", "BBT001", "BBET", null, "exalogic"),
  ...generateServicesMobileAndDeskt(
    "SPORTBULL",
    "BULL001",
    "SPORTBULL",
    null,
    "exalogic"
  ),
  ...generateServicesMobileAndDeskt("Mideporte", "MBBT001", "MBBT"),
  ...generateServicesMobileAndDeskt("FlashBet", "FLBT001", "FLBT"),
  ...generateServicesMobileAndDeskt("Betaland", "BETL001", "BETL", null, "exalogic"),
  ...generateServicesMobileAndDeskt(
    "flashbetVoucherFlashPoints",
    "FLBT002",
    "FLBTF"
  ),
  ...generateServicesMobileAndDeskt("AmazingBet", "AMBT001", "AMBT"),
  ...generateServicesMobileAndDeskt("SportBet", "SportBet001", "SPBET"),
  ...generateServicesMobileAndDeskt(
    "360Bet",
    "360BET01",
    "360bet",
    null,
    "exalogic"
  ),
  ...generateServicesMobileAndDeskt(
    "Betflag Direct",
    "BetflagDirect001",
    "Betflag Direct"
  ),
  ...generateServicesMobileAndDeskt(
    "Bitnovo Voucher",
    "CRYPVOUCH001",
    "Bitnovo Voucher",
    ["50.00", "100.00", "250.00", "500.00"]
  ),
  ...generateServicesMobileAndDeskt("Aircash", "ACA000", "AIRCSH"),
  ...generateServicesMobileAndDeskt(
    "Payment System Gift Card",
    "GiftCard001",
    "Payment System Gift Card",
    null,
    "PaymentSystem"
  ),
}

export const editCompanieBackNames = {
  LYCAMD: "LYCA MOBILE",
  TISCA: "TISCALI",
  FlashbetFlashpoints: "Flashbet",
}

// var firebaseConfig = {
//   apiKey: "AIzaSyDMHqopkqlG962b6QbE9TWlu0EW0JZI0I8",
//   authDomain: "bpoint-92398.firebaseapp.com",
//   projectId: "bpoint-92398",
//   storageBucket: "bpoint-92398.appspot.com",
//   messagingSenderId: "449464517232",
//   appId: "1:449464517232:web:9da256e50be6c296e56ab5",
//   measurementId: "G-32SM7G7JGM",
// };
// var app = firebase.initializeApp(firebaseConfig);
// export var db = firebase.firestore(app);

//http://prntscr.com/vmqp68  reset state so next popup states are cleaned
export const resetUserStateChangeFields = {
  changedphone: "",
  changeddocument_type: "",
  changeddocument_number: "",
  rilasciato_da: "",
  luogo_di_rilascio: "",
  data_di_rilascio: "",
  data_di_scadenza: "",
  changedInsegna: "",
  changedCordinate: "",
  changeda_phone: "",
  changedSede_operativa: "",
  changedcomune: "",
  changedprovincia: "",
  changedcap: "",
  changednazione: "",
  changedpagamento_mensile: "",
  password: "",
  confirm_password: "",
  username: "",
  email: "",
  a_ragione_sociale: "",
  a_iva: "",
  first_name: "",
  last_name: "",
  birth_comune_code: "",
  birth_country: "",
  birth_place: "",
  birthday: "",
  city: "",
  gender: "",
  personal_number: "",
  ragione_sociale: "",
  p_iva: "",
  country: "",
  address: "",
  cap: "",
  comune_code: "",
}
//
export const autoConfig = {
  0: {
    name: "Fiat",
    models: ["Fiat 500", "Fiat500X", "Fiat124"],
    colors: ["Tech House Grey", "Glam Coral", "Pasodoble Red", "Epic Blue"],
  },
  1: {
    name: "Citroen",
    models: ["c1", "c2", "c4"],
    colors: ["red", "green", "blue"],
  },
}
const month = new Date().getMonth()
const day = new Date().getDate()
export const isWinter =
  (month === 11 && parseInt(day.toString()) >= 11) ||
  (month === 0 && day.toString().match(/^(1|2|3|4|5)$/g))

const italianChars = {
  À: "A",
  Á: "A",
  È: "E",
  Ì: "I",
  Í: "I",
  Î: "I",
  Ò: "O",
  Ó: "O",
  Ù: "U",
  Ú: "U",
  à: "a",
  á: "a",
  è: "e",
  é: "e",
  ì: "i",
  í: "i",
  î: "i",
  ò: "o",
  ó: "o",
  ù: "u",
  ú: "u",
}
export const removeItalianChar = (word) => {
  let newTXT = word
  Object.keys(italianChars).forEach((key) => {
    if (word.includes(key)) {
      newTXT = newTXT.replace(key, italianChars[key])
    }
  })
  return newTXT
}

const generateSkin = (
  skin_id,
  urlToMatch,
  mainColor,
  skin = skin_id,
  callback = () => {},
  compareFunc = "match",
  stylesSpecifier,
  tabThemeEdit = true
) => {
  return {
    skin_id,
    urlToMatch,
    mainColor,
    compareFunc,
    skin,
    callback,
    stylesSpecifier,
    tabThemeEdit,
  }
}
const googleAnalyticToHeader = (nr) => {
  if (window.location.href.includes("bpoint")) {
    $("head").append(
      `<script async src="https://www.googletagmanager.com/gtag/js?id=UA-172416989-${nr}"></script>`
    )
    $("head").append(
      `<script>window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'UA-172416989-${nr}');</script>`
    )
  }
}
