import React, { Component } from "react"
import "./style.css"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import ShopActions from "redux-store/models/shop"

class ProductItem extends Component {
  addToCart = (e) => {
    e.preventDefault()
    this.props.getToCart(
      this.props.item.prd_supp,
      this.props.item.Product_id,
      "cart",
      1
    )
  }

  render() {
    const { item, location, category } = this.props
    const pathParts = location.pathname.split("/")
    const isCategoryPage = pathParts[1] === "product-filtered"
    const categoryName = isCategoryPage && pathParts[2].replace("__", " | ")

    return (
      <div
        className="products__item"
        // onClick={() => {
        //   this.props.history.push(
        //     `/product/${item.Product_id}/${item.prd_supp}`
        //   );
        // }}
      >
        <a href={`#/product/${item.Product_id}/${item.prd_supp}`}>
          {isCategoryPage ? (
            <p className="category">{categoryName}</p>
          ) : (
            <p className="category">{category}</p>
          )}

          <div className="pBorder">
            <img src={item.Product_Image_1} alt=""></img>
          </div>
          <div className="name">{item.Product_Name}</div>
          <div className="bottom">
            <div className="price">€{item.Product_Price}</div>
            <i className="far fa-shopping-cart" onClick={this.addToCart} />
          </div>
        </a>
      </div>
    )
  }
}

const mpStP = (state) => ({})
export default withRouter(connect(mpStP, ShopActions)(ProductItem))
