import React from "react"

const CategoryList = ({
  categories,
  activeCategory,
  onClickCategory,
  menuClassName,
  skin,
}) => {
  return (
    <aside className={`Categories ${menuClassName}`}>
      <div className="First">SERVIZI</div>
      {categories &&
        Array.isArray(categories) &&
        categories.map((cat) => {
          if (skin.skin_id === 57 && (cat.key === "SCMS" || cat.key === "RICARICHE CONTI GIOCO")) return null
          if (cat.key === "RTELC") return null
          return (
            <div
              key={cat?.key}
              className={`${activeCategory === cat.key ? "active" : ""}`}
              onClick={() => {
                onClickCategory(cat.name, cat.key) 
              }}
              data-cat={`${cat.key}`}
            >
              {cat.name === "VCRYP"
                ? "Binance Voucher"
                : cat.name === "Ricariche Telefoniche Online"
                ? "Ricariche Telefoniche"
                : cat.name}
            </div>
          )
        })}
    </aside>
  )
}

export default CategoryList
