import React, { useState } from "react";
import AuthActions from "redux-store/models/auth"
import { connect } from "react-redux"
import { Upload, message } from 'antd';
import AdminModal from "../AdminModal/AdminModal"

const { Dragger } = Upload;


const NuovoBiller = (props) => {
  const { submit,nuovoBillerVisibility,toggleNuovoBiller } = props;
  const [denominazione, setDenominazione] = useState("");

  const [numero, setNumero] = useState("");
  const [note, setNote] = useState("");

  const close = () =>toggleNuovoBiller(false)
  const onSubmit=()=>submit(denominazione,numero,note)
  const changeDenominazione = (e) => setDenominazione(e.target.value);
  const changeNumero = (e) => setNumero(e.target.value);
  const changeNote = (e) => setNote(e.target.value);


  const draggerProps = {
  name: 'file',
  multiple: false,
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
  },
};

  return (  
    <AdminModal close={close} visibility={nuovoBillerVisibility} selector=".AdminLeftForm--LastBox--Box.biller">
          <div className="content__header">
            <span>Aggiungi Nuovo Biller</span>
            <i className="fal fa-times" onClick={close}></i>
          </div>
          <div className="content__body">
            <div className="body__label title is-required"> Denominazione</div>
            <div className="body__input">
              <input onChange={changeDenominazione} placeholder="Denominazione" />
            </div>
            <div className="body__label title is-required"> Numero Conto Corrente</div>
            <div className="body__input">
              <input onChange={changeNumero} placeholder="Numero Conto Corrente" />
            </div>
            <div className="body__label messagge is-required"> Note</div>
            <div className="body__input is-textarea">
              <textarea onChange={changeNote} placeholder="Note" />
            </div>
             <div className="body__label title is-required"> Allega</div>
            <div className="body__input">
                <Dragger {...draggerProps}>
    <p className="ant-upload-drag-icon">
     <i className="fal fa-cloud-upload"></i>
    </p>
    <p className="ant-upload-text">Drag and drop files here</p>
    <button>Browse files</button>
  </Dragger>
            </div>
         
          </div>

          <div className="content__footer">
            <button onClick={onSubmit}>Registra Biller</button>
          </div>
    </AdminModal>
 
  );
};

NuovoBiller.defaultProps = {
  submit: () => {},
};

const mapStateToProps = (state) => ({
  nuovoBillerVisibility: state.auth.nuovoBillerVisibility,
})

export default connect(mapStateToProps, AuthActions)(NuovoBiller)

