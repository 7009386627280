import React, { Component } from "react"
import { Azioni, Header } from "shared-components"
import FormDetailsDomain from "../domains/FormDetails/FormDetailsDomain"

export class FormDetails extends Component {
  render() {
    return this.props.forAdmin === true ? (
      <FormDetailsDomain />
    ) : (
      <div className="formsDetails" style={{ minHeight: "calc(100vh - 183px)" }}>
        <Header></Header>

        <Azioni activeMain="support" active="dettagli-prenotazioni"></Azioni>
        <FormDetailsDomain />
      </div>
    )
  }
}

export default FormDetails
