import React from "react"
import images from "themes/images"

const TelefonicheOptions = ({ telefonicheOptions, optionId, onChangeCompanies }) => {
  return (
    <div className="telefoniche-options">
      {telefonicheOptions.map((option) => (
        <div
          key={option.optionId}
          className={`option ${option.optionId === optionId ? "active" : ""}`}
          onClick={() => {
            onChangeCompanies(option.name, option.category, option) 
          }}
        >
          <img src={images[option.optionId]} alt={`option-${option.optionId}`} />
          <span>{option.label}</span>
        </div>
      ))}
    </div>
  )
}

export default TelefonicheOptions
