import React from "react"
import { capitalize } from "lodash"
import images from "themes/images"

const PrintTicketVisure = (arr) => {
  const visureReceipt = () =>
    arr.receipt && typeof arr.receipt === "string" && JSON.parse(arr.receipt)

  const keysToMap = [
    { label: "Date/Ora", field: "date_ora" },
    { label: "Codice Fiscale", field: "cd" },
    { label: "Codice Destinario", field: "codiceDestinatario" },
    { label: "Descrizione Ateco", field: "descrizioneAteco" },
    { label: "Data Iscrizione", field: "dataIscirzione" },
    { label: "Codice Ateco", field: "codAteco" },
    { label: "Support code", field: "support_code" },
    { label: "Order id", field: "order_id" },
  ]

  const keysToNotChange = [
    "date_ora",
    "piva",
    "cf",
    "agency_name",
    "agency_address",
    "bitnovo_category",
  ].concat(keysToMap.map(({ field }) => field))

  const visureTicketDetails = () => [
    ...Object.keys(visureReceipt())
      .filter((key) => !keysToNotChange.includes(key))
      .map((key) => ({
        field: key,
        label: capitalize(key),
      })),
    { label: "Date/Ora", field: "date_ora" },
    { label: "Codice Fiscale", field: "cd" },
    { label: "Codice Destinario", field: "codiceDestinatario" },
    { label: "Descrizione Ateco", field: "descrizioneAteco" },
    { label: "Data Iscrizione", field: "dataIscirzione" },
    { label: "Codice Ateco", field: "codAteco" },
    { label: "Support code", field: "support_code" },
    { label: "Order id", field: "order_id" },
  ]

  return (
    <>
      <span className="border-bottom"></span>
      <div>
        <h1>{visureReceipt()["visure_nome"]}</h1>
        {visureTicketDetails().map(({ label, field }) => {
          const canCurrency = ["importo", "iva", "totale"].includes(field)
          const currencyLabel =
            canCurrency &&
            new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(parseFloat(visureReceipt()[field]))

          return (
            field in visureReceipt() && (
              <div className="visure__row" key={label}>
                <label>{label}</label>
                <span>{currencyLabel || visureReceipt()[field]}</span>
              </div>
            )
          )
        })}
        <h2 className="black-background-title">TRANSAZIONE ESEGUITA</h2>

        {arr.receipt_type === "json_bitnovo" && (
          <img
            className="bitnovo-logo"
            src={images["bitnovo-black"]}
            alt="bitnovologo"
          />
        )}
        {arr.receipt_type === "JSON" && !(arr.barcode || "").includes("000024") && (
          <h1 className="disponibile-text">{`Tempo di consegna: ${
            visureReceipt()["consegna"]
          }`}</h1>
        )}

        {arr.receipt_type === "json_bitnovo" && (
          <div className="bitnovo-footer">
            <h5>Come riscattare il PIN:</h5>
            <span className="separator" />
            <p>{`Accedi alla sezione Voucher sull’App Bitnovo o alla sezione Riscattare il Voucher sul sito www.bitnovo.com/it
                - Inserisci il codice PIN ed il tuo Wallet
                - Conferma l’operazione
                Il PIN scade dopo 3 mesi dalla data di acquisto.
                Il PIN non è rimborsabile. Si applicano tutti i termini su
                www.bitnovo.com/it/termini-e-condizioni
                Distributore: Giulida srl - P.Iva ${
                  visureReceipt()["piva"] || "03109830590"
                }`}</p>
          </div>
        )}
      </div>
    </>
  )
}

export default PrintTicketVisure
