import React, { useState } from "react"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import images from "../../themes/images"
import { baseUrlImg } from "../../config/api"
import ModulePopUpBack from "./ModulePopUpBack"
import NumPadForCost from "./NumPadForCost"
import NumPadForPhoneNumber from "./NumPadForPhoneNumber"
import { message } from "antd"
import PrintTicket from "shared-components/ModulePopUp/PrintTicket"
import ConfirmationModal from "./ConfirmationModal/ConfirmationModal"
import ServiceSwiper from "./ServicesSwiper"
import CodiceFiscaleInput from "./CodiceFiscaleInput"

const NewModulePopUp4 = (props) => {
  const {
    togglePopUp,
    setScannedBarcode,
    service,
    accountInfo,
    service_s,
    rechargeMobile,
    serviceType,
    createGiftCard,
    buyExalogic,
    getRechargeMobileSim,
    getRechargeMobile,
    setRechargeMobile,
    setServiceS,
    setCompanyServices,
    getCustomVoucherReq,
  } = props

  const [flipped, setFLipped] = useState(false)
  const [toPrint, setToPrint] = useState(false)
  const [confermaMsg, setConfermaMsg] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState("")
  const [customCost, setCustomCost] = useState("")
  const [serviceMobile, setServiceMobile] = useState(service)
  const [showNumPadCost, setShowNumPadCost] = useState(false)
  const [codFisInps, setCodFisInps] = useState("")

  const hasPhoneNumber =
    serviceMobile?.type === "1" || serviceMobile?.type === "TOPUP"

  const cfServices =
    serviceMobile?.type === "F" ||
    serviceMobile?.type === "Payment System" ||
    serviceMobile?.type === "betflag"

  //Variable cost
  const canChangeCost =
    service.type === "exalogic" ||
    service.type === "Payment System" ||
    service.type === "betflag"

  //Variable cost but not exalogic
  const hasCostZero = service_s?.services?.some(
    (service) => parseInt(service.cost) === 0
  )

  console.log(service_s)

  const allServices = service_s.services || []

  const isValidCodiceFiscale = (codFis) => {
    return (
      codFis.length === 16 &&
      /^[A-Z]{6}\d{2}[A-EHLMPRST]{1}\d{2}[A-Z]{1}\d{3}[A-Z]{1}$/.test(codFis)
    )
  }

  const handleSubmit = (service_id, service_type) => {
    const isSepafin = accountInfo.profile.hasSepafinCredentials

    if (cfServices && !isValidCodiceFiscale(codFisInps)) {
      message.error("Il Codice Fiscale non è valido.")
      return
    }

    if (canChangeCost) {
      //Exalogic
      if (service_type === "exalogic") {
        buyExalogic(
          service_id,
          customCost ? customCost : serviceMobile.cost,
          accountInfo.profile.username,
          codFisInps
        )
      }
      //Betflag Direct
      else if (service_type === "betflag") {
        getCustomVoucherReq(
          service_id,
          customCost ? customCost : serviceMobile.cost,
          codFisInps
        )
      } else {
        //Create gift card
        createGiftCard(customCost ? customCost : serviceMobile.cost, codFisInps)
      }
    } else {
      //E-Pay Reserve
      if (service_type === "TOPUP" || service_type === "PIN") {
        getRechargeMobileSim(
          service_id,
          phoneNumber,
          serviceMobile.cost,
          serviceMobile.ean,
          accountInfo.profile.id
        )
      } else {
        //Mr-Pay Recharge
        getRechargeMobile(
          service_id,
          hasPhoneNumber ? phoneNumber : null,
          null,
          isSepafin,
          accountInfo.profile.username,
          codFisInps,
          customCost ? customCost : serviceMobile.cost
        )
      }
    }
  }

  return (
    <div
      className="modulePopUP modulePopUP1 telRechanrge"
      onClick={() => {
        if (!(rechargeMobile && toPrint)) {
          togglePopUp(false)
          setScannedBarcode(null)
          setRechargeMobile({})
          setServiceS([])
          setCompanyServices([])
        }
      }}
    >
      <div className="flip-container">
        <div className={`flipper ${flipped ? "flipped" : ""}`}>
          <div className="front">
            <div
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
              className={`leftCol_Module Popup4 ${
                !hasPhoneNumber ? "has-img" : ""
              } ${showNumPadCost ? "has-custom-cost" : ""} ${
                cfServices ? " cf-input" : ""
              }`}
            >
              <div className={"row" + (cfServices ? " cf-input-row" : "")}>
                <div className="Close">
                  <span
                    className="CloseButton"
                    onClick={() => {
                      togglePopUp(false)
                      setScannedBarcode(null)
                    }}
                  >
                    <img src={images.close} alt="" />
                  </span>
                </div>
                <div className="info-icon">
                  <span onClick={() => setFLipped(!flipped)}>
                    <i className="fal fa-question-circle"></i>
                  </span>
                </div>
                <div className="Logo">
                  <div className="Logo--Help">
                    <img
                      src={`${baseUrlImg}/${
                        service.icon ? service.icon : "uncategorized/placeholder.jpg"
                      }`}
                      alt=""
                    />
                  </div>
                </div>

                {canChangeCost || hasCostZero ? (
                  showNumPadCost ? (
                    <NumPadForCost
                      customCost={customCost}
                      setCustomCost={setCustomCost}
                    />
                  ) : (
                    <div className={"GamingBanner "}>
                      <div
                        className="img"
                        alt={service_s?.service_id}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <img
                          style={{
                            width: service.background ? "100%" : "50%",
                          }}
                          src={`${baseUrlImg}/${
                            service.background ? service.background : service.icon
                          }`}
                          alt=""
                        />
                      </div>
                    </div>
                  )
                ) : hasPhoneNumber ? (
                  <NumPadForPhoneNumber
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                  />
                ) : (
                  <div className={"GamingBanner "}>
                    <div
                      className="img"
                      alt={service_s?.service_id}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <img
                        style={{
                          width: service.background ? "100%" : "50%",
                        }}
                        src={`${baseUrlImg}/${
                          service.background ? service.background : service.icon
                        }`}
                        alt=""
                      />
                    </div>
                  </div>
                )}

                <ServiceSwiper
                  allServices={allServices}
                  serviceMobile={serviceMobile}
                  canChangeCost={canChangeCost}
                  hasCostZero={hasCostZero}
                  showNumPadCost={showNumPadCost}
                  setShowNumPadCost={setShowNumPadCost}
                  changeService={setServiceMobile}
                  setCustomCost={setCustomCost}
                />

                {cfServices && (
                  <CodiceFiscaleInput
                    codFisInps={codFisInps}
                    setCodFisInps={setCodFisInps}
                    isValidCodiceFiscale={isValidCodiceFiscale}
                  />
                )}

                <h5 className="service-name">{serviceMobile.name}</h5>
                <div className="Confimation Buttons">
                  <span className="stampCup prenota">
                    <h3>Prenota </h3>
                    <i className="fal fa-qrcode" />
                  </span>

                  <span
                    className="stampCup annulla"
                    onClick={() => {
                      togglePopUp(false)
                      setScannedBarcode(null)
                    }}
                  >
                    <h3>Annulla </h3>
                    <i className="fal fa-close" />
                  </span>

                  <span
                    className="stampCup esegui"
                    onClick={() => {
                      if (accountInfo?.token) {
                        if (serviceMobile.type === 1) {
                          handleSubmit(serviceMobile.service_id, serviceMobile.type)

                          setToPrint(true)
                        } else {
                          setConfermaMsg(true)
                        }
                      } else {
                        window.location.hash = "login"
                        togglePopUp(false)
                        setToPrint(true)
                      }
                    }}
                  >
                    <h3>Esegui </h3>
                    <i className="fal fa-check" />
                  </span>
                </div>

                <ConfirmationModal
                  messageVisible={confermaMsg}
                  cost={customCost ? customCost : serviceMobile.cost}
                  onConfirm={() => {
                    handleSubmit(serviceMobile.service_id, serviceMobile.type)
                    setToPrint(true)
                    setConfermaMsg(false)
                  }}
                  onCancel={() => setConfermaMsg(false)}
                />

                {(rechargeMobile?.errors || rechargeMobile?.message) && (
                  <div className="messages">
                    <div className="closeM" onClick={() => setRechargeMobile({})}>
                      chiudi messaggi
                    </div>
                    {rechargeMobile?.errors ? (
                      Object.keys(rechargeMobile?.errors).map((item, index) => {
                        return (
                          <div className="errorM" key={index}>
                            <i className="fad fa-exclamation text-danger"></i>
                            {rechargeMobile?.errors[item]}
                          </div>
                        )
                      })
                    ) : (
                      <div className="infoM">
                        <i className="fad fa-info text-info"></i>{" "}
                        {rechargeMobile?.message}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          {flipped && service_s && (
            <div className="back">
              <ModulePopUpBack
                service_s={service_s}
                handleFlip={() => setFLipped(!flipped)}
                hasPhoneNumber={hasPhoneNumber}
                showNumPadCost={showNumPadCost}
                cfServices={cfServices}
              />
            </div>
          )}

          {rechargeMobile && toPrint && (
            <PrintTicket
              arr={rechargeMobile.return ? rechargeMobile.return : rechargeMobile}
              setPrint={setToPrint}
              scontrino={serviceMobile.content_scontrino}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const mapsStateToProps = (state) => ({
  isShowing: state.main.isShowing,
  service_s: state.main.companyServices,
  serviceType: state.auth.serviceType,
  rechargeMobile: state.auth.rechargeMobile,
  skinExtras: state.auth.skinExtras,
  accountInfo: state.auth.accountInfo,
  serviceType: state.auth.serviceType,
  scannedBarcode: state.auth.scannedBarcode,
})

export default connect(mapsStateToProps, { ...MainActions, ...AuthActions })(
  NewModulePopUp4
)
