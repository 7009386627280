import React from "react"
import { Checkbox } from "antd"
import { MainActions } from "redux-store/models"
import { connect } from "react-redux"

function BollettinoPrivacyLinks(props) {
  const { screenWidth } = props
  const isMobile = screenWidth <= 1025

  return (
    <div style={{ display: "flex", flexDirection: "column", marginTop: 10, marginLeft: isMobile ? "20px" : "0px" }}>
      <Checkbox>
        <a href="https://www." target="_blank" rel="noopener noreferrer">
          <span style={{ textDecoration: "underline", fontSize: 13 }}>
            Foglio Informativo
          </span>
        </a>
      </Checkbox>
      <Checkbox style={{ marginLeft: 0 }}>
        <a href="https://www." target="_blank" rel="noopener noreferrer">
          <span style={{ textDecoration: "underline", fontSize: 13 }}>
            Informativa sulla Privacy
          </span>
        </a>
      </Checkbox>
    </div>
  )
}

const mapsStateToProps = (state, props) => ({
  screenWidth: state.main.screenWidth,
})

export default connect(mapsStateToProps, MainActions)(BollettinoPrivacyLinks)
