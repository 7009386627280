import React, { useState } from "react"
import "./CardViewToggle.css"

const CardViewToggle = ({ initialCard, onCardChange }) => {
  const [selectedCardView, setSelectedCardView] = useState(initialCard)

  const handleCardChange = (view) => {
    setSelectedCardView(view)
    onCardChange(view)
  }

  return (
    <div className="custom-input-container">
      <div className="gender-input-container">
        <div
          className={`gender-option ${selectedCardView === "1" ? "selected" : ""}`}
          onClick={() => handleCardChange("1")}
        >
          Front
        </div>
        <div className="divider" />
        <div
          className={`gender-option ${selectedCardView === "2" ? "selected" : ""}`}
          onClick={() => handleCardChange("2")}
        >
          Back
        </div>
      </div>
    </div>
  )
}

export default CardViewToggle
