import React from "react"
import AdminPanelDom from "../domains/adminPanel/adminPanelDom"
import EditServiceAdmin from "./EditServiceAdmin"

class AdminPanelEditService extends React.Component {
  render() {
    return (
      <AdminPanelDom
        component={({ MessaggiComponent }) => (
          <EditServiceAdmin forAdmin={true}  />
        )}
        {...this.props}
      />
    )
  }
}
export default AdminPanelEditService
