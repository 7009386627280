import React from "react"
import moment from "moment"

const PrintTicketSpedizioni = ({ arr }) => {
  let receipt_spedizioni = {}
  if (arr.receipt_type == "json_spedizionepro")
    receipt_spedizioni = JSON.parse(arr.receipt)

  return (
    <div className="json_spedizionepro">
      <h5 className="d-flex justify-content-center font-weight-bold ">
        {receipt_spedizioni.receipt_name.toUpperCase()}
      </h5>
      <div>
        Data/Ora:{" "}
        <span>
          {" "}
          {moment(receipt_spedizioni.date_ora).format("DD/MM/YYYY HH:mm:ss")}
        </span>
      </div>
      <div>
        Nr: <span>{arr.barcode}</span>
      </div>
      <div>
        Data Ritiro: <span>{receipt_spedizioni.dataRitiroIT}</span>
      </div>
      <div className="font-weight-bold">
        Data Consegna: <span>{receipt_spedizioni.dataConsegnaPrevistaIT}</span>
      </div>
      <div>
        Servizio: <span>{receipt_spedizioni.servizio.toUpperCase()}</span>
      </div>
      <div>
        Corriere: <span>{receipt_spedizioni.corriere.toUpperCase()}</span>
      </div>
      <div>
        Peso:{" "}
        <span>
          {receipt_spedizioni.colli.reduce((total, collo) => {
            return total + collo.pesoReale
          }, 0)}
          kg
        </span>
      </div>
      <div>
        Nr Colli: <span>{receipt_spedizioni.numeroColli}</span>
      </div>
      <div>
        Dimensione pacco:
        <span>
          {receipt_spedizioni.colli
            .map(
              (collo) => `${collo.altezza}x${collo.larghezza}x${collo.profondita}cm`
            )
            .join(", ")}
        </span>
      </div>
      <div className="font-weight-bold mt-3">
        Mitente:{" "}
        <span>
          {receipt_spedizioni.nominativoMittente} {receipt_spedizioni.pressoMittente}
        </span>
      </div>
      <div>
        Indirizzo: <span>{receipt_spedizioni.indirizzoMittente} </span>
      </div>
      <div>
        Provincia: <span>{receipt_spedizioni.provinciaMittente}</span>
      </div>
      <div>
        Cap e Comune:{" "}
        <span>
          {receipt_spedizioni.capMittente} {receipt_spedizioni.comuneMittente}
        </span>
      </div>
      <div>
        Tel: <span>{receipt_spedizioni.telefonoMittente}</span>
      </div>
      <div>
        E-mail: <span>{receipt_spedizioni.emailMittente}</span>
      </div>
      <div className="font-weight-bold mt-3">
        Destinatario:{" "}
        <span>
          {receipt_spedizioni.nominativoDestinatario}{" "}
          {receipt_spedizioni.pressoDestinatario}
        </span>
      </div>
      <div>
        Indirizzo: <span>{receipt_spedizioni.indirizzoDestinatario} </span>
      </div>
      <div>
        Provincia: <span>{receipt_spedizioni.provinciaDestinatario}</span>
      </div>
      <div>
        Cap e Comune:{" "}
        <span>
          {receipt_spedizioni.capDestinatario}{" "}
          {receipt_spedizioni.comuneDestinatario}
        </span>
      </div>
      <div>
        Tel: <span>{receipt_spedizioni.telefonoDestinatario}</span>
      </div>
      <div>
        E-mail: <span>{receipt_spedizioni.emailDestinatario}</span>
      </div>
      <div>
        Importo: <span>{(receipt_spedizioni.totale / 1.22).toFixed(2)} €</span>
      </div>
      <div>
        IVA:
        <span>{(0.22 * (receipt_spedizioni.totale / 1.22)).toFixed(2)} €</span>
      </div>
      <div className="font-weight-bold">
        Totale: <span>{receipt_spedizioni.totale} €</span>
      </div>

      {arr.receipt_type === "json_spedizionepro" && receipt_spedizioni.trackLink && (
        <div className="d-flex flex-column">
          <h3 className="tracklink">TRACK LINK</h3>
          <div className="tracklink">
            {" "}
            <p>
              Se non hai dato una mail per scaricare il pdf puoi usare la ricerca
              dello scontrino su services.bpoint.store/#/qrcodereader oppure eseguire
              scansione del qrcode in destra con il link del documento:
              services.bpoint.store/0000074395699
            </p>{" "}
            <img
              className="qrCodeImg"
              src={
                receipt_spedizioni?.trackLink !== "" && receipt_spedizioni?.trackLink
              }
              alt="track link"
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default PrintTicketSpedizioni
