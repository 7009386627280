import React from "react";
import AdminPanelDom from "../domains/adminPanel/adminPanelDom";
import { connect } from "react-redux";
import RegisterAgent from "./RegisterAgent";

class SuperAdminRegistraAgente extends React.Component {
  render() {
    return (
      <AdminPanelDom
     component={()=>
          <RegisterAgent />
        }
        {...this.props}
      />
    );
  }
}
const mapStatToProps = (state) => ({
  activeSkinId: state.main.activeSkinId,
});
export default connect(mapStatToProps, null)(SuperAdminRegistraAgente);