import React from "react"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import CalendarRangePicker from "shared-components/CalendarRangePicker/CalendarRangePicker"
import moment from "moment"
import {Table } from 'antd';
import "antd/dist/antd.css"
import Pdf from "./Pdf";
import Excel from "./Excel";
import SpanFormater from "../../shared-components/SpanFormater/SpanFormater"

class AllTransazioni extends React.Component {
  constructor() {
    super()
    this.state = {
        from: "",
        to: "",
        fromLabel: "",
        toLabel: "",
        isCalendarOpen : false,
        picker: [
            {
              startDate: new Date(),
              endDate: new Date(),
              key: "selection",
              color: "var(--accent-bg)",
            },
          ],
      }
  }

  setCalendar = (val) => {
    this.setState({ isCalendarOpen: val })
  }

  handleSubmit = (e) => {
    const { to, from } = this.state
    e.preventDefault()
    this.props.getAllPayments(
      from,
      to
    )
  }

  componentDidMount() {
    this.props.getAllPayments(
        this.state.from,
        this.state.to
      )
  }

  render() {
    const { allPaymentsForExcel , loadingPayments, screenWidth } = this.props
      var columns = screenWidth > 1024 ? [
      {
        title: 'Data/Ora',
        dataIndex: 'data_ora',
        key: 'data_ora',
        ellipsis : true,
        width: "12%",
      },
      {
        title: 'Barcode',
        dataIndex: 'barcode',
        key: 'barcode',
        ellipsis : true,
        width: "12%",
        onCell: (record) => {
          return {
            onClick: (ev) => {
              this.props.getCodiceTicket(record.barcode, record.service);
            },
          };
        },
      },
      {
        title: 'Utente',
        dataIndex: 'utente',
        key: 'utente',
        ellipsis: true,
        width: "18%",
      },
      {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
        width: "10%",
        ellipsis: true,
      },
      {
        title: 'Service',
        dataIndex: 'service',
        key: 'service',
        width : "15%", 
        ellipsis: true,
        onCell: (record) => {
          return {
            onClick: (ev) => {
              this.props.getCodiceTicket(record.barcode, record.service);
            },
          };
        },
      },
      {
        title: 'Importo',
        dataIndex: 'importo',
        key: 'importo',
        align : 'right',
        width: "10%",
        ellipsis: true,
      },
      {
        title: 'Commisione',
        dataIndex: 'commisione',
        key: 'commisione',
        align : 'right',
        width: "10%",
        ellipsis: true,
      },
      {
        title: 'Proviggione',
        dataIndex: 'proviggione',
        key: 'proviggione',
        align : 'right',
        width: "10%",
        ellipsis: true,
      }
      ] : screenWidth > 600 ? [
        {
          title: 'Data/Ora',
          dataIndex: 'data_ora',
          key: 'data_ora',
          ellipsis : true,
          width: "15%"
        },
        {
          title: 'Barcode',
          dataIndex: 'barcode',
          key: 'barcode',
          ellipsis : true,
          width: "15%",
          onCell: (record) => {
            return {
              onClick: (ev) => {
                this.props.getCodiceTicket(record.barcode, record.service);
              },
            };
          },
        },
        {
          title: 'Username',
          dataIndex: 'username',
          key: 'username',
          width: "12%",
          ellipsis: true,
        },
        {
          title: 'Utente',
          dataIndex: 'utente',
          key: 'utente',
          ellipsis: true,
          width: "17%"
        },
        {
          title: 'Service',
          dataIndex: 'service',
          key: 'service',
          ellipsis: true,
          width: "16%",
          onCell: (record) => {
            return {
              onClick: (ev) => {
                this.props.getCodiceTicket(record.barcode, record.service);
              },
            };
          },
        },
        {
          title: 'Importo',
          dataIndex: 'importo',
          key: 'importo',
          width: "10%",
          ellipsis: true,
          align : 'right',
        },
      ] : [
       
      {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
        width: "30%",
        ellipsis: true,
      },
      {
        title: 'Service',
        dataIndex: 'service',
        key: 'service',
        width: "50%",
        ellipsis: true,
        onCell: (record) => {
          return {
            onClick: (ev) => {
              this.props.getCodiceTicket(record.barcode, record.service);
            },
          };
        },
      },
      {
        title: 'Importo',
        dataIndex: 'importo',
        key: 'importo',
        width: "20%",
        ellipsis: true,
        align : 'right',
      },];

      var data = [];
    return (
      <div className="Container Annunci AllTransazioni" style={{ paddingBottom: screenWidth < 500 && "100px", width:"100%", }}>
        {this.props.MessaggiComponent}
        <div className="container-fluid overview">
          <div className="panels-container">
            <div className="sort-annunci adminAnnunci ">
            <h1 className="headerTop">Tutti Transazioni</h1>
                      <div
                        className="dal calendar"
                        type="text"
                        onClick={() => {
                          this.setCalendar(true)
                        }}
                      >
                        <span>
                          {this.state.fromLabel
                            ? `${this.state.fromLabel} - ${this.state.toLabel}`
                            : "Seleziona la data"}
                          <i className="fal fa-calendar-alt"></i>
                        </span>
                      </div>
              <div style={{display:"flex",margin:"10px"}}>
              <div className="filesBtns">
                      <Pdf
                        paymentExcelLoading={this.props.paymentExcelLoading}
                        from={this.state.from}
                        to={this.state.to}
                        payments={allPaymentsForExcel}
                        getPaymentsForExcel={this.props.getAllPaymentsForExcel}
                        allSkins = {true}
                      />
                      <Excel
                        from={this.state.from}
                        to={this.state.to}
                        payments={allPaymentsForExcel}
                        allSkins = {true}
                      />
                    </div>
                    {this.state.isCalendarOpen && (
                <CalendarRangePicker
                  setStateFunc={(item) => {
                    this.setState({
                      picker: [item.selection],
                      from: moment(item.selection.startDate).format("YYYY-MM-DD"),
                      to: moment(item.selection.endDate).format("YYYY-MM-DD"),
                      fromLabel: moment(item.selection.startDate).format(
                        "DD/MM/YYYY"
                      ),
                      toLabel: moment(item.selection.endDate).format("DD/MM/YYYY"),
                    })
                  }}
                  setStateFuncEmpty={() => {
                    this.setState({
                      from: "",
                      to: "",
                      fromLabel: "",
                      toLabel: "",
                    })
                  }}
                  picker={this.state.picker}
                  setCalendar={this.setCalendar}
                  handleSubmit={this.handleSubmit}
                />
              )}
              </div>
            </div>
            {loadingPayments &&
                   <div className="loaderAdmin"></div>}
              {this.props.payments && this.props.payments.map((m) => {
                const row = {
                  key: m.Id,
                  data_ora: moment(m.Date_Ora).format("DD/MM/YYYY HH:mm"),
                  barcode : m.Barcode,
                  utente : m.User &&  <SpanFormater
                  myClassName="nomeTd"
                  Word={m?.User}
                  size={35}
                  nrOfRows={1}
                  formatWord={true}
                />,
                  username : m.Username,
                  service : m.Service,
                  importo : m.Importo+"€",
                  commisione : m.Commisione+"€",
                  proviggione : m.Proviggione+"€"
                }
                data.push(row);
              })}
              {!loadingPayments && <Table style={{width:"100%"}} columns={columns} pagination={{ defaultPageSize: 25, showSizeChanger: true, pageSizeOptions: ['10', '25', '50'] }} showHeader={true}  dataSource={data} />}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  screenWidth : state.main.screenWidth,
  payments: state.auth.allPayments,
  loadingPayments: state.auth.loadingPayments,
  allPaymentsForExcel: state.auth.allPaymentsForExcel,
  paymentExcelLoading: state.auth.paymentExcelLoading,
})

export default connect(mapStateToProps, { ...MainActions, ...AuthActions })(AllTransazioni)
