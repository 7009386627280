import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Captcha = (props) => {
  return (
   <ReCAPTCHA
      hl="it"
      sitekey="6LdqkfgcAAAAAKWPO7uXG6C94X1F3XXnjILUgNoC"
      onChange={(token) => props.onChange(token)}
    />
   
  );
};

export default Captcha;
