import React, { Component } from "react";
import { connect } from "react-redux";
import { AuthActions } from "redux-store/models";
import { withRouter } from "react-router-dom";
import Voli from "./Voli";
import Treni from "./Treni";
import Eventi from "./Eventi";
import OnlineShop from "./OnlineShop";
import Bgame from "./Bgame";
import Auto from "./Auto";
import Energia from "./Energia";
import Finanziamenti from "./Finanziamenti";
import Assicurazioni from "./Assicurazioni";
import images from "themes/images";
import "./style.css";
import Facileristrutturare from "./Facileristrutturare";
import Enagic from "./Enagic";
import InternetVelocePopup from "../../../shared-components/internetVelocePopup/InternetVelocePopup";

const Card = ({
  title,
  icon,
  hadnleClick,
  updateState,
  color,
  id,
  typee,
  accountInfo,
  name,
  prestoOnline,
}) => {
  const clickHandler = () => {
    const namee = name.split(" ").join("").toLowerCase();
    if (accountInfo?.token) {
      if (hadnleClick) {
        hadnleClick();
      } else {
        window.location.hash = `forms/${namee}`;
        const nome_agenzia = title;
        updateState({
          isSelected: true,
          nome_agenzia,
          typee: Number(typee || id),
          color,
        });
      }
    } else {
      window.location.hash = "login";
    }
  };

  return (
    <div
      className="formsContainer--cards__item animated fadeIn"
      onClick={clickHandler}
    >
      <img
        className="bgImg"
        src={
          images[
            `${title === "bigliettieria eventi" ? "biglietti" : title}-card`
          ]
        }
        alt=""
      />
      <div className="imageCard">
        <img
          className="logoImg"
          src={
            images[
              `${title === "bigliettieria eventi" ? "biglietti" : title}-logo`
            ]
          }
          alt=""
        />
        {prestoOnline && <div className="coomingsoon">COMMING SOON</div>}
      </div>
      <div className="titleCard">
        {name ? name : title.replace("-", " ")}{" "}
        <i className={`fal fa-${icon}`}></i>
      </div>
    </div>
  );
};

const prenotations = {
  1: {
    nome_agenzia: "expedia",
    typee: 1,
    color: "#11375D",
    icon: "plane",
  },
  2: {
    nome_agenzia: "flixbus",
    typee: 2,
    color: "#588A17",
    icon: "bus",
  },
  3: {
    nome_agenzia: "trenitalia",
    typee: 2,
    color: "#BF0013",
    icon: "subway",
  },
  // 31: {
  //   nome_agenzia: "vivaticket",
  //   typee: 3,
  //   color: "#151515c4",
  //   icon: "ticket-alt",
  // },
  // ...(skin?.skin_id !== 4
  //   ? {
  //     32: {
  //       nome_agenzia: "ticketing",
  //       typee: 3,
  //       color: "#edbf00bf",
  //       icon: "ticket-alt",
  //     },
  //     }
  //   : {}),
  // 33: {
  //   nome_agenzia: "stubhub",
  //   typee: 3,
  //   color: "#3f1d74b5",
  //   icon: "ticket-alt",
  // },
  34: {
    nome_agenzia: "bigliettieria eventi",
    typee: 3,
    color: "#3F1D74",
    icon: "ticket-alt",
  },
  // visure: {
  //   nome_agenzia: "visure",
  //   hadnleClick: () => (window.location.hash = "visure"),
  //   color: "#277d74b5",
  //   icon: "file-alt",
  // },
  4: {
    nome_agenzia: "shop-online",
    typee: 4,
    color: "#F26521",
    icon: "shopping-cart",
  },
  // 5: {
  //   nome_agenzia: "bgame",
  //   typee: 5,
  //   color: "#F26521",
  //   name:"REGISTRAZIONE CONTI GIOCO ONLINE",
  //   icon:"futbol"
  // },
  6: {
    nome_agenzia: "auto",
    typee: 6,
    color: "#22A094",
    icon: "car",
    name: "NOLEGGIO AUTO",
  },
  7: {
    nome_agenzia: "assicurazioni",
    typee: 7,
    icon: "file-alt",
    color: "#707070",
    name: "ASSICURAZIONI",
  },
  8: {
    nome_agenzia: "energia",
    typee: 8,
    color: "#18819B",
    name: `CONTRATTI Luce / Gas`,
    icon: "lightbulb-on",
  },
  9: {
    nome_agenzia: "finanziamenti",
    typee: 9,
    color: "#2075FC",
    name: `Finanziamenti`,
    icon: "coins",
  },
  11: {
    nome_agenzia: "enagic",
    typee: 11,
    color: "#337DCC",
    name: `Enagic`,
    icon: "faucet-drip",
  },
  12: {
    nome_agenzia: "facileristrutturare",
    typee: 12,
    color: "#F19302",
    name: `Facile ristrutturare`,
    icon: "digging",
  },
};
export class FormsBody extends Component {
  state = {
    isSelected: false,
    typee: 0,
    bagaglio: 1,
    nome_agenzia: "",
    color: "",
    visible: true,
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    let id1;
    id === "expedia"
      ? (id1 = 1)
      : id === "flixbus"
      ? (id1 = 2)
      : id === "trenitalia"
      ? (id1 = 3)
      : id === "bigliettieriaeventi"
      ? (id1 = 34)
      : id === "shoponline"
      ? (id1 = 4)
      : id === "noleggioauto"
      ? (id1 = 6)
      : id === "assicurazioni"
      ? (id1 = 7)
      : id === "contrattiluce"
      ? (id1 = 8)
      : id === "finanziamenti"
      ? (id1 = 9)
      : id === "enagic"
      ? (id1 = 11)
      : id === "facileristrutturare"
      ? (id1 = 12)
      : (id1 = 0);

    const { typee, nome_agenzia, color } = prenotations[id1] || {};
    if (id1) this.setState({ typee, isSelected: true, nome_agenzia, color });
  }

  componentDidUpdate(prevProps, prevState) {
    const id = this.props.match.params.id;
    if (prevState.isSelected && id !== prevProps.match.params.id && !id) {
      this.setState({ isSelected: false, typee: 0 });
    }
  }

  updateState = (params) => this.setState({ ...params });
  visureHandleClick = () => (window.location.hash = "visure");
  goBack = () => {
    this.setState({ isSelected: false });
    window.location.hash = "forms";
  };
  isActivePrenot = (id) =>
    this.state.isSelected && parseInt(this.state.typee) === id;

  handleClosePopup = () => {
    this.setState({ visible: false });
  };

  render() {
    const { accountInfo, sendDataForm } = this.props;
    const { typee, isSelected, nome_agenzia, color } = this.state;
    const id = Object.keys(prenotations).find(
      (id) => prenotations[id].nome_agenzia === nome_agenzia
    );
    const name = id && id in prenotations && prenotations[id].name;
    const prenotationParams = {
      goBack: this.goBack,
      name,
      nome_agenzia,
      color,
      typee,
      sendDataForm,
      accountInfo,
    };

    return (
      <>
        <InternetVelocePopup
          visible={this.state.visible}
          onClose={this.handleClosePopup}
        />
      <div className="forms--body">
        <div
          className="formsContainer"
          // style={{
          //   backgroundImage:
          //     isSelected && `url( ${images["biglietti-background"]})`,
          //   backgroundColor: isSelected && "#D2D4D5",
          //   backgroundSize: "cover",
          // }}
        >
          {!isSelected && <h1>Prenotazione</h1>}
          {!isSelected && (
            <div className="formsContainer--cards">
              {[1, 2, 3, 34, "visure", 4, 6, 7, 8, 9, 11, 12].map(
                (prenotationId) => {
                  const prenotation = prenotations[prenotationId] || {};
                  const {
                    nome_agenzia,
                    icon,
                    name,
                    color,
                    hadnleClick,
                    typee,
                    prestoOnline,
                  } = prenotation;
                  const fullName = name
                    ? name
                    : (nome_agenzia || "").replace("-", " ");
                  return (
                    !!(typee || color) && (
                      <Card
                        key={prenotationId}
                        id={prenotationId}
                        typee={typee || prenotationId}
                        updateState={this.updateState}
                        {...{
                          color,
                          icon,
                          name: fullName,
                          accountInfo,
                          prestoOnline,
                          title: nome_agenzia,
                          hadnleClick,
                        }}
                      />
                    )
                  );
                }
              )}
            </div>
          )}

          {this.isActivePrenot(1) && <Voli {...prenotationParams} />}
          {this.isActivePrenot(2) && <Treni {...prenotationParams} />}
          {this.isActivePrenot(3) && <Eventi {...prenotationParams} />}
          {this.isActivePrenot(4) && <OnlineShop {...prenotationParams} />}
          {this.isActivePrenot(5) && <Bgame {...prenotationParams} />}
          {this.isActivePrenot(6) && <Auto {...prenotationParams} />}
          {this.isActivePrenot(7) && <Assicurazioni {...prenotationParams} />}
          {this.isActivePrenot(8) && <Energia {...prenotationParams} />}
          {this.isActivePrenot(9) && <Finanziamenti {...prenotationParams} />}
          {this.isActivePrenot(11) && <Enagic {...prenotationParams} />}
          {this.isActivePrenot(12) && (
            <Facileristrutturare {...prenotationParams} />
          )}
        </div>
      </div>
    </>
    );
  }
}

const mstp = ({ auth: { accountInfo } }) => ({ accountInfo });

export default withRouter(connect(mstp, AuthActions)(FormsBody));
