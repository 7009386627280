import React, { useState } from "react"
import "./style.css"
import $ from "jquery"
import { RadioGroup } from "shared-components"
import ClickOut from "react-onclickout"
import AuthActions from "redux-store/models/auth"
import { connect } from "react-redux"

const MessaggiUser = (props) => {
  const { close, createAds, username, id } = props
  const radioData = [
    { name: "Messaggio", id: 4 },
    { name: "Pop Up", id: 5 },
  ]
  const typeMessage = [
    { name: "Text", id: "Text" },
    { name: "HTML", id: "HTML" },
  ]
  const [categoria, setCategoria] = useState(radioData[0].id)
  const [title, setTitle] = useState("")
  const [message, setMessage] = useState("")
  const [type, setType] = useState(typeMessage[0].id)
  const changeCategoria = (newValueId) => {
    setCategoria(newValueId)
    if (newValueId == 4) {
      setType(typeMessage[0].id)
    }
  }
  const changeTitle = (e) => setTitle(e.target.value)
  const changeMessage = (e) => setMessage(e.target.value)
  const changeType = (newValueId) => setType(newValueId)

  const getMarginBottom = () => {
    const marginb =
      ($(".messaggi-popup").height() - $(".messaggi-popup__content").outerHeight()) /
      2
    return marginb > 0 ? marginb : 0
  }

  const clearState = () => {
    setTitle("")
    setMessage("")
    close()
  }

  const fileUpInput = (e) => {
    const reader = new FileReader()
    const file = e.target.files[0]
    reader.onload = function () {
      setMessage(reader.result)
    }
    reader.readAsText(file)
  }

  const submit = () =>
    createAds({
      importance: categoria,
      title: title,
      text: message,
      specific_skin_id: [],
      user_id: id,
      clearState,
      type,
    })

  return (
    <ClickOut
      onClickOut={(e) => {
        if (!$(".ant-select-dropdown").find(e.target).length) {
          close()
        }
      }}
    >
      <div className="messaggi-popup nuovo-biller">
        <div className="backDrop" onClick={close} />
        <div
          className="messaggi-popup__content"
          // style={{ marginBottom: getMarginBottom() }}
        >
          <div className="content__header">
            <span>Crea privata annuncio per {username}</span>
            <i className="fal fa-times" onClick={close}></i>
          </div>
          <div className="content__body">
            <div className="content__body__radio">
              <div className="body__label is-required"> Categoria Annuncio:</div>
              <RadioGroup
                className="body__radio1"
                data={radioData}
                color="DarkGray"
                activeColor="Admin"
                borderColor="Gray"
                value={categoria}
                setValue={changeCategoria}
              />
            </div>

            <div className="body__label title is-required"> Titolo:</div>
            <div className="body__input">
              <input onChange={changeTitle} />
            </div>
            <div className="content__body__radio">
              <div className="body__label messagge is-required"> Messaggio:</div>
              {categoria == 5 && (
                <RadioGroup
                  className="body__radio1"
                  data={typeMessage}
                  color="DarkGray"
                  activeColor="Admin"
                  borderColor="Gray"
                  value={type}
                  setValue={changeType}
                />
              )}
            </div>
            {type == "Text" && (
              <div>
                <div className="body__input is-textarea">
                  <textarea onChange={changeMessage} />
                </div>
              </div>
            )}
            {categoria == 5 && type == "HTML" && (
              <div>
                <div className="body__input file">
                  <input type="file" onChange={fileUpInput} accept=".html" />
                </div>
              </div>
            )}
          </div>

          <div className="content__footer">
          <button className="annulla-btn" onClick={close}>
              ANNULLA
            </button>
            <button onClick={submit}>INVIA</button>
           
          </div>
        </div>
      </div>
    </ClickOut>
  )
}

export default connect(null, AuthActions)(MessaggiUser)
