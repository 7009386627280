import { Col, Form, Row, Select } from "antd"
import React, { useState } from "react"
import FloatingLabelInput from "../../FloatingLabelInput/FloatingLabelInput"
import { uniqBy } from "lodash"
import countriesArray from "config/countryArr"
import AdressAutoFinder from "../../AdressAutoFinder/AdressAutoFinder"

const { Option } = Select

function Step2({ form, formData, setFormData }) {
  const { getFieldDecorator } = form
  const [suggestedAddresses, setSuggestedAddresses] = useState([])

  const handleInputChange = (field) => (e) => {
    e.persist()
    setFormData((prevState) => ({
      ...prevState,
      [field]: e.target.value,
    }))
  }

  const allNazione = uniqBy(countriesArray, "nazione")

  const nazioneList = []
  if (allNazione && allNazione.length > 0) {
    for (let i = 0; i < allNazione.length; i++) {
      nazioneList.push(
        <Option key={allNazione[i].nazione} value={allNazione[i].nazione}>
          {allNazione[i].nazione}
        </Option>
      )
    }
  }


  const handleAddressChange = async (value) => {
    console.log("Input value:", value)

    if (!value || value.length < 4) {
      console.log("Input value is less than 4 characters. Skipping API call.")
      setSuggestedAddresses([])
      return
    }

    const url = `https://nominatim.openstreetmap.org/search.php?q=${encodeURIComponent(
      value
    )}&polygon_geojson=1&format=jsonv2`

    try {
      const response = await fetch(url)
      const data = await response.json()

      if (data.length > 0) {
        console.log(data)
        setSuggestedAddresses(data)
      } else {
        console.error("No results found")
        setSuggestedAddresses([])
      }
    } catch (error) {
      console.error("Error fetching addresses:", error)
      setSuggestedAddresses([])
    }
  }

  return (
    <>
      <Form.Item hasFeedback>
        {getFieldDecorator("aPiva", {
          initialValue: formData.aPiva,
          rules: [
            {
              required: true,
              message: "Inserire P.Iva",
              whitespace: true,
            },
            {
              pattern: /^\d{11}$/,
              message: "La P.Iva deve avere esattamente 11 numeri!",
            },
          ],
        })(
          <FloatingLabelInput
            label="P.Iva"
            onChange={(e) => {
              handleInputChange("aPiva")(e)
            }}
          />
        )}
      </Form.Item>
      <Form.Item hasFeedback>
        {getFieldDecorator("aRagSoc", {
          initialValue: formData.aRagSoc,
          rules: [{ required: true, message: "Inserire nome!", whitespace: true }],
        })(
          <FloatingLabelInput
            label="Ragione sociale"
            onChange={(e) => {
              handleInputChange("aRagSoc")(e)
            }}
          />
        )}
      </Form.Item>
      <Form.Item hasFeedback>
        {getFieldDecorator("address", {
          initialValue: formData.address,
          rules: [
            {
              required: true,
              message: "Inserisci il tuo indirizzo!",
              whitespace: true,
            },
          ],
        })(
          <FloatingLabelInput
            label="Sede legale"
            onChange={(e) => {
              handleInputChange("address")(e)
            }}
          />
        )}
      </Form.Item>

      <Form.Item hasFeedback>
        {getFieldDecorator("aInsegna", {
          initialValue: formData.aInsegna,
          rules: [{ required: true, message: "Inserire cognome!" }],
        })(
          <FloatingLabelInput
            label="Insegna"
            onChange={(e) => {
              handleInputChange("aInsegna")(e)
            }}
          />
        )}
      </Form.Item>

      <Form.Item hasFeedback style={{ marginBottom: 16 }}>
        {getFieldDecorator("aAdress", {
          initialValue: formData.aAdress,
          rules: [
            {
              required: true,
              message: "Inserire la sede operativa",
              whitespace: true,
            },
          ],
        })(
          <Select
            showSearch
            placeholder="Indirizzo punto vendita"
            value={formData.aAdress}
            onSearch={(e) => {
              setFormData((prevState) => ({
                ...prevState,
                aAdress: e,
              }))
              handleAddressChange(e)
            }}
            onChange={(value) => {
              const selectedSuggestion = suggestedAddresses.find(
                (s) => s.display_name === value
              )

              if (selectedSuggestion) {
                setFormData((prevState) => ({
                  ...prevState,
                  aAdress: selectedSuggestion.display_name,
                  cordinateReq: `${selectedSuggestion.lat},${selectedSuggestion.lon}`,
                }))
                form.validateFields(["aAdress"])
              }
            }}
            notFoundContent={null}
            filterOption={false}
          >
            {suggestedAddresses.map((suggestion) => (
              <Option key={suggestion.place_id} value={suggestion.display_name}>
                {suggestion.display_name}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>

      <AdressAutoFinder
        titles={{
          nazione: "Nazione punto vendita",
          province: "Provincia punto vendita",
          comune: "Comune punto vendita",
          cap: "Cap punto vendita",
        }}
        capFormKey="aCap"
        form={form}
        nazione={formData.nazioneDiResidenca}
        province={formData.residence_province}
        comune={formData.residence_city}
        setNazione={(nazione) => {
          setFormData((prevState) => ({
            ...prevState,
            nazioneDiResidenca: nazione,
          }))
        }}
        setProvince={(province) => {
          setFormData((prevState) => ({
            ...prevState,
            residence_province: province,
          }))
        }}
        setComune={(comune) => {
          setFormData((prevState) => ({
            ...prevState,
            residence_city: comune,
          }))
        }}
      />
      <Form.Item hasFeedback>
        {getFieldDecorator("aPhone", {
          initialValue: formData.aPhone,
          rules: [{ required: true, message: "Inserire numero di telefono!" }],
        })(
          <FloatingLabelInput
            label="Telefono punto vendita"
            onChange={(e) => {
              handleInputChange("aPhone")(e)
            }}
          />
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator("cordinate", {
          initialValue: formData.cordinateReq,
          rules: [
            {
              required: false,
              whitespace: true,
              message: "Inserisci le cordinate!",
            },
          ],
        })(
          <FloatingLabelInput
            label="Cordinate punto vendita"
            onChange={(e) => {
              handleInputChange("cordinateReq")(e)
            }}
          />
        )}
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item hasFeedback>
            {getFieldDecorator("pagmens", {
              initialValue: formData.pagmens,
              rules: [
                {
                  required: true,
                  message: "Inserire Pagamento Mensile",
                  whitespace: true,
                },
              ],
            })(
              <FloatingLabelInput
                type="number"
                label="Pagamento mensile"
                onChange={handleInputChange("pagmens")}
              />
            )}
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item hasFeedback>
            {getFieldDecorator("tipo_di_mensile", {
              initialValue: formData.tipo_di_mensile,
              rules: [
                {
                  required: true,
                  whitespace: true,
                },
              ],
            })(
              <Select
                placeholder="Tipo di Pagamento Mensile"
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    tipo_di_mensile: e,
                  }))
                }}
              >
                <Option value="annually">Annuale</Option>
                <Option value="monthly">Mensile</Option>
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default Step2
