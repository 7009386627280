import React from "react"
import moment from "moment"

const BetFlagDirectTicket = (arr) => {
  return (
    <div className="json_spedizionepro">
      <h5 className="d-flex justify-content-center font-weight-bold ">
        {arr.type.toUpperCase()}
      </h5>
      <div style={{ paddingTop: 10 }}>
        Data/Ora:
        <span>{moment(arr.dataAcquisto).format("DD/MM/YYYY HH:mm:ss")}</span>
      </div>
      <div style={{ paddingTop: 10 }}>
        Importo: <span>{arr.importo}</span>
      </div>
      <div style={{ paddingTop: 10 }}>
        Codice Voucher: <span>{arr.codiceVoucher}</span>
      </div>

      <div style={{ paddingTop: 10 }}>
        ID Transazione: <span>{arr.idTransazione}</span>
      </div>
    </div>
  )
}

export default BetFlagDirectTicket
