import React from "react"

const NumPadForCost = ({ customCost, setCustomCost }) => {
  const clearOne = () => {
    setCustomCost((prevCost) => prevCost.slice(0, -1))
  }

  const addNr = (num) => {
    setCustomCost((prevCost) => prevCost + num)
  }

  return (
    <div className="NumPadContainer bgm">
      <div className="NumPd">
        <input
          className="bgm"
          type="text"
          placeholder="_ _ _ _"
          value={customCost}
          onChange={(e) => setCustomCost(e.target.value)}
        />
      </div>
      <div className="Numbers">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, ".", "0", "x"].map((num) => (
          <span
            key={num}
            id={`num${num}`}
            className={`bgm ${num === "x" ? "x" : ""}`}
            onClick={() => (num === "x" ? clearOne() : addNr(num))}
          >
            {num === "x" ? (
              <span>
                <i className="fal fa-times" />
                <div className="triangle"></div>
              </span>
            ) : (
              num
            )}
          </span>
        ))}
      </div>
    </div>
  )
}

export default NumPadForCost
