import React from "react"
import { connect } from "react-redux"
import MainActions from "redux-store/models/main"
import MamaneroCaffe from "../../views/MamaneroCaffe"
import AuthActions from "redux-store/models/auth"
import "./Dashboard.css"
import images from "themes/images"
import { withRouter } from "react-router-dom"
import { message } from "antd"
import { debounce, flatten } from "lodash"
import SwiperCore, { Navigation, Pagination, Scrollbar } from "swiper"
import { isSepafinSelector, servicesSelector } from "utils/HelperFunc"
import { skin } from "config/api"
import UseCode from "routes/views/UseCode"
import CategoryList from "./CategoryList"
import CompaniesFavorite from "./CompaniesFavorite"
import CompaniesSearch from "./CompaniesSearch"
import TelefonicheOptions from "./TelefonicheOptions"
import CompaniesRender from "./CompaniesRender"
// Import Swiper styles
import "swiper/swiper.min.css"

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar])
class DashboardDom extends React.Component {
  constructor(props) {
    super(props)
    this.searchRef = React.createRef()
  }
  state = {
    Companies: [],
    search: "",
    menuClassName: "notFixed",
    toDisplay: false,
    categoriesTypeSelected: "RTELD",
    categoryActive: "RTELD",
    optionId: "RTELD",
  }

  componentDidMount() {
    const { getServices, getFavorites, scannedBarcode, accountInfo, match } =
      this.props
    getServices()
    getFavorites()
    window.addEventListener("scroll", this.handleScroll)
    // this.checkBarcodeChange(scannedBarcode)

    if (accountInfo?.token && match.params.id !== "caffe_mamanero") {
      this.setFixedMenu()
    }
  }

  async componentDidUpdate(prevProps) {
    const { services, favorites, match, scannedBarcode } = this.props

    if (favorites !== prevProps.favorites) {
      const CategoriesFav = this.FindArrayOfServicesByValue(favorites, "")
      let CompaniesFav = await this.FindServ(CategoriesFav, {})
      CompaniesFav = CategoriesFav.map((cat) => ({
        key: cat.key,
        companies: CompaniesFav[cat.name],
      }))
      this.setState({ CompaniesFav })
    }

    if (services !== prevProps.services || match.url !== prevProps.match.url) {
      const Categories = this.FindArrayOfServicesByValue(services, match.params.id)

      let Companies = await this.FindServ(Categories, {})
      let doesCategoryExist = false
      const { categoryActive } = this.state
      let findCategoryByKey = ""

      Categories.forEach((cat) => {
        if (categoryActive && categoryActive === cat?.key) {
          doesCategoryExist = true
          findCategoryByKey = cat.name
        }
      })

      const CatActiveForState =
        categoryActive && !doesCategoryExist
          ? Categories[0]?.key
          : categoryActive
          ? categoryActive
          : Categories[0]?.key

      const CompanieCategoryForState =
        categoryActive && !doesCategoryExist
          ? Categories[0]?.name
          : findCategoryByKey
          ? findCategoryByKey
          : Categories[0]?.name

      this.setState({
        Companies: Companies[CompanieCategoryForState],
        Categories,
        categoryActive: CatActiveForState,
        categoriesTypeSelected: CatActiveForState,
      })

      if (scannedBarcode && services !== prevProps.services) {
        this.checkBarcodeChange(scannedBarcode)
      }
    }

    if (
      scannedBarcode !== prevProps.scannedBarcode &&
      services &&
      Object.keys(services).length > 0
    ) {
      this.checkBarcodeChange(scannedBarcode)
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  togglePopUp = (val) => {
    this.setState({ toDisplay: val })
  }

  changeServce = async (serviceId, services, serviceName, type, special) => {
    const { setServiceType, setServiceId, setServiceS, togglePopUp, isSepafin } =
      this.props
    if (
      ["SBOL001", "SBOL002"].includes(special?.service_id || serviceId) &&
      isSepafin
    ) {
      this.setState({ hasVPT: true })
    } else {
      if (type === "fav") {
      } else {
        await setServiceType(this.state.categoriesTypeSelected)
      }

      await setServiceId(special ? special : services[0])

      if (this.state.categoryActive !== "PRDPST") {
        await setServiceS({
          name: serviceName,
          id: serviceId,
          services: services,
        })
      }

      await togglePopUp(true)
    }
  }

  handleScroll = () => {
    const { accountInfo, match } = this.props

    if (accountInfo?.token && match.params.id !== "caffe_mamanero") {
      this.setFixedMenu()
    }
  }

  setFixedMenu = () => {
    const catInst = document.querySelector(".Dashboard > .Categories")
    catInst.style.height =
      catInst.getBoundingClientRect().height -
      (catInst.getBoundingClientRect().height +
        186 -
        document.querySelector("footer").getBoundingClientRect().y) +
      "px"

    const { menuClassName } = this.state
    const scrollPoint = document
      .querySelector("#SpecStatistich")
      ?.classList?.contains("min")
      ? 386
      : 486
    const top = window.scrollY || document.scrollTop
    const newMenuClassName = top >= scrollPoint ? "fixed" : "notFixed"

    if (menuClassName !== newMenuClassName) {
      this.setState({ menuClassName: newMenuClassName })
    }
  }

  SplitAndCheckIfIncludes = (name, listOfOptions) => {
    let response = false
    if (name) {
      let newlistOfOptions = listOfOptions.split(",")
      newlistOfOptions.forEach((option) => {
        if (name.includes(option) || name === option) {
          response = true
          return response
        }
      })
      return response
    }
    return response
  }

  FindArrayOfServicesByValue = (object, value = "ricariche", isSearching) => {
    const { accountInfo, history } = this.props
    if (!accountInfo?.profile?.role) {
      if (value !== "ricariche") {
        history.push("/dashboard/ricariche")
        message.info("Per favore fai prima il log in.")
      }
    }
    if (isSearching) {
      return (
        Object.keys(object) &&
        Array.isArray(Object.keys(object)) &&
        Object.keys(object).map((key) => ({ ...object[key], key }))
      )
    } else {
      return (
        Object.keys(object) &&
        Array.isArray(Object.keys(object)) &&
        Object.keys(object)
          .filter((key) => {
            if (object[key].name === "VCRYP" && value === "ricariche") {
              return object[key].group?.toLowerCase() === "richariche" && "ricariche"
            } else {
              return this.SplitAndCheckIfIncludes(
                object[key]?.group?.toLowerCase(),
                value.toLowerCase()
              )
            }
          })
          .map((key) => ({ ...object[key], key }))
      )
    }
  }

  FindServ = (Categories, Companies) => {
    Object.keys(Categories).forEach((id) => {
      Companies[Categories[id].name] = [
        ...Object.keys(Categories[id])
          .filter(
            (key) =>
              key !== "name" &&
              key !== "group" &&
              key !== "key" &&
              key !== "favourite"
          )
          .map((key) => ({
            [key]: {
              ...Categories[id][key],
            },
          })),
      ]
    })
    return Companies
  }

  ChangeCompanies = (CategoryName, CategoryType) => {
    this.setState((state) => ({
      Companies: this.FindServ(state.Categories, state.Companies)[CategoryName],
      categoriesTypeSelected: CategoryType,
      categoryActive: CategoryType,
    }))
  }

  checkBarcodeChange = (scannedBarcode) => {
    if (!scannedBarcode) return

    let parsedData

    try {
      if (this.isJsonString(scannedBarcode)) {
        parsedData = JSON.parse(scannedBarcode)

        if (parsedData && parsedData.service_id) {
          this.handleParsedData(parsedData)
          return
        }
      } else if (typeof scannedBarcode === "object") {
        parsedData = scannedBarcode

        this.handleParsedData(parsedData)
        return
      } else {
        this.handleNonJsonBarcode(scannedBarcode)
      }
    } catch (error) {
      console.error("Error handling barcode:", error)
    }
  }
  isJsonString = (str) => {
    return typeof str === "string" && (str.startsWith("{") || str.startsWith("["))
  }

  handleParsedData = (parsedData) => {
    const { services } = this.props

    if (!parsedData.service_id) return

    this.navigateBasedOnCategory(parsedData.category_id)

    if (parsedData.category_id === "PRDPST") {
      const selectedService = services[parsedData.category_id]["BOLL"].services || []
      this.processService(
        selectedService,
        "BOLL",
        services[parsedData.category_id]["BOLL"].name,
        parsedData.service_id
      )
    } else {
      const selectedService =
        services[parsedData.category_id][parsedData.service_id].services || []
      this.processService(
        selectedService,
        parsedData.service_id,
        services[parsedData.category_id][parsedData.service_id].name,
        parsedData.id
      )
    }
  }

  handleNonJsonBarcode = (scannedBarcode) => {
    const { history, services } = this.props
    const barcodeParts = scannedBarcode.split(/[|§]/)
    const barcodeType = barcodeParts[0]
    const isPagoPA = barcodeType === "PAGOPA"

    history.push("/dashboard/pagamenti")

    const selectedService = services["PRDPST"]["BOLL"].services || []
    const serviceId = isPagoPA ? "BOL007" : "BOL002"
    const serviceName = isPagoPA ? "Bollettini Postali" : "Bollettino Premarcato"

    this.processService(selectedService, "BOLL", serviceName, serviceId)
  }

  navigateBasedOnCategory = (categoryId) => {
    const { history } = this.props

    switch (categoryId) {
      case "PRDPST":
        history.push("/dashboard/pagamenti")
        break
      case "GIFT":
        history.push("/dashboard/gift_cards")
        break
      default:
        history.push("/dashboard/ricariche")
        break
    }
  }

  processService = (selectedService, serviceType, serviceName, serviceId) => {
    const targetService = selectedService.find(
      (service) => service.service_id === serviceId
    )

    if (targetService) {
      this.changeServce(
        serviceType,
        selectedService,
        serviceName,
        null,
        targetService
      )
    }
  }

  onFocusHandler = () => {
    const { services } = this.state
    if (!services) {
      return
    }
    this.setState({
      Companies: flatten(
        Object.values(
          this.FindServ(
            this.FindArrayOfServicesByValue(this.state.services, "", true),
            {}
          )
        )
      ),
    })
  }

  render() {
    const {
      Categories,
      menuClassName,
      search,
      CompaniesFav,
      Companies,
      categoryActive,
      categoriesTypeSelected,
      hasVPT,
      optionId,
    } = this.state
    const {
      accountInfo,
      getCodiceTicket,
      setServiceType,
      isSepafin,
      getFavorites,
      getServices,
      toggleFavorite,
      services,
      skinExtras,
    } = this.props

    const accountData = JSON.parse(localStorage.getItem("accountDataB")) || {}
    const isEPay = accountData?.profile?.supplier === "E-Pay"

    const telefonicheOptions = [
      {
        optionId: "RTELD",
        label: "Ricarica SIM",
        category: "RTELD",
        name: "Ricariche Telefoniche Online",
      },
      {
        optionId: "RTELC",
        label: "Ricarica con PIN",
        category: "RTELC",
        name: "Ricariche Telefoniche PIN",
      },
    ]

    const debounceSearch = debounce(() => {
      this.setState({
        search: this.searchRef?.current?.value,
      })
    }, 300)

    let banners = [
      {
        id: "coffe",
        src: images["caffeBanner"],
        hash: "dashboard/caffe_mamanero",
      },
    ]

    const dissallowedBannersForSkin = {
      ...Object.fromEntries(
        new Map(
          [0, 1, 3, 5, 8, 51, 52, 53, 55].map((skin_id) => [skin_id, "coffee"])
        )
      ),
    }

    banners = banners.filter(
      ({ id }) => !(dissallowedBannersForSkin[skin.skin_id] || []).includes(id)
    )

    return (
      <div className="DContainer maxWidth">
        {hasVPT && (
          <div
            className="backDrop"
            onClick={() => {
              this.setState({ hasVPT: false })
            }}
          >
            <UseCode
              getCodiceTicket={getCodiceTicket}
              paymentNotFinished={true}
              prenotationType="PrenotedBollettini"
            />
          </div>
        )}
        {this.props.match.params.id === "caffe_mamanero" ? (
          <MamaneroCaffe />
        ) : (
          <>
            {skinExtras.name === "Mrpay" && (
              <div className="maxWidth">
                <img
                  src={images["mrPayBanner"]}
                  style={{ width: "100%", marginBottom: 10 }}
                  onClick={() => this.props.history.push(`/dashboard/crypto`)}
                />
              </div>
            )}{" "}
            <div className={`Dashboard ${menuClassName}`}>
              <CategoryList
                categories={Categories}
                activeCategory={categoryActive}
                onClickCategory={this.ChangeCompanies}
                menuClassName={menuClassName}
                skin={skin}
                companies={Companies}
              />
              <div className={`CompaniesAndOther ${menuClassName}`}>
                <CompaniesFavorite
                  CompaniesFav={CompaniesFav}
                  images={images}
                  setServiceType={setServiceType}
                  changeService={this.changeServce}
                  togglePopUp={this.togglePopUp}
                  toggleFavorite={toggleFavorite}
                  getFavorites={getFavorites}
                  getServices={getServices}
                  accountInfo={accountInfo}
                />
                <div className="Comp">
                  <CompaniesSearch
                    categoriesTypeSelected={categoriesTypeSelected}
                    isSepafin={isSepafin}
                    hasVPT={hasVPT}
                    searchRef={this.searchRef}
                    onFocusHandler={() => {
                      this.setState({
                        Companies: flatten(
                          Object.values(
                            this.FindServ(
                              this.FindArrayOfServicesByValue(services, "", true),
                              {}
                            )
                          )
                        ),
                      })
                    }}
                    debounceSearch={debounceSearch}
                  />
                  {categoryActive === "RTELD" && (
                    <TelefonicheOptions
                      telefonicheOptions={telefonicheOptions}
                      optionId={optionId}
                      onChangeCompanies={(name, category, option) => {
                        this.ChangeCompanies(name, category)
                        this.setState({
                          optionId: option.optionId,
                          categoryActive: "RTELD",
                        })
                      }}
                    />
                  )}
                  <div className="Companies">
                    <CompaniesRender
                      Companies={Companies}
                      search={search}
                      toggleFavorite={toggleFavorite}
                      getServices={getServices}
                      accountInfo={accountInfo}
                      changeServce={this.changeServce}
                      togglePopUp={this.togglePopUp}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
}
const mapsStateToProps = (state) => ({
  favorites: state.main.favorites,
  accountInfo: state.auth.accountInfo,
  isSepafin: isSepafinSelector(state),
  services: servicesSelector(state),
  scannedBarcode: state.auth.scannedBarcode,
  skinExtras: state.auth.skinExtras,
})

export default withRouter(
  connect(mapsStateToProps, { ...MainActions, ...AuthActions })(DashboardDom)
)
