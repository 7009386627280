import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Form, Input, Modal, Button, Icon } from "antd"
import AuthActions from "redux-store/models/auth"
import images from "themes/images"
import "./contiGiocoModal.css"

const ContiGiocoModal = ({
  open,
  onClose,
  contiAccount,
  service,
  setNewContiAccount,
  newContiStatus,
  form,
}) => {
  const { getFieldDecorator, validateFields } = form
  const [showPassword, setShowPassword] = useState(false)

  const contiAccountCopy = contiAccount && JSON.parse(JSON.stringify(contiAccount))

  console.log(contiAccountCopy)

  useEffect(() => {
    if (newContiStatus === 200) {
      onClose()
    }
  }, [newContiStatus, onClose])

  useEffect(() => {
    if (contiAccountCopy) {
      form.setFieldsValue({
        username: contiAccountCopy.conti_username,
        password: contiAccountCopy.conti_password,
      })
    }
  }, [contiAccount])

  const handleSave = () => {
    validateFields((err, values) => {
      if (!err) {
        setNewContiAccount(service.service_id, values.username, values.password)
      }
    })
  }

  if (!open) return null

  return (
    <Modal
      className="add-service-modal"
      title={`Cambio Password - ${service.name}`}
      visible={open}
      onCancel={onClose}
      footer={null}
      width={400}
    >
      <div className="add-service-modal-header text-center">
        <img
          src={images[service.name] || images["placeholder"]}
          alt="service"
          className="add-service-modal-logo"
          width={130}
          height={60}
        />
      </div>

      <Form layout="vertical" className="add-service-form">
        <Form.Item label="Username">
          {getFieldDecorator("username", {
            initialValue: contiAccountCopy?.conti_username,
            rules: [{ required: true, message: "Please input your Username!" }],
          })(<Input placeholder="Username" />)}
        </Form.Item>

        <Form.Item label="Password">
          {getFieldDecorator("password", {
            initialValue: contiAccountCopy?.conti_password,
            rules: [{ required: true, message: "Please input your Password!" }],
          })(
            <Input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              suffix={
                <Icon
                  type={showPassword ? "eye" : "eye-invisible"}
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              }
            />
          )}
        </Form.Item>

        <div className="add-service-modal-buttons">
          <Button
            onClick={onClose}
            icon="close"
            className="add-service-modal-button-cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            icon="check"
            className="add-service-modal-button-save"
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  contiAccount: state.auth.contiAccount,
  newContiStatus: state.auth.newContiStatus,
})

const WrappedContiGiocoModal = Form.create()(ContiGiocoModal)

export default connect(mapStateToProps, AuthActions)(WrappedContiGiocoModal)
