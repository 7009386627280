import React, { useCallback } from "react"
import { baseUrlImg } from "../../../config/api"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"

const CompanyCards = ({
  companies,
  screenWidth,
  getCompanyServices,
  toggleFavorite,
  togglePopUp,
  setServiceS,
  getCategoryServices,
  categoryActive,
  subCategories,
  activeSubCategory,
}) => {
  const favouriteClick = async (e, companyName, favourite) => {
    e.stopPropagation()
    e.preventDefault()

    await (favourite
      ? toggleFavorite(companyName, "remove")
      : toggleFavorite(companyName, "set"))

    setTimeout(() => {
      if (subCategories && subCategories.length > 0) {
        getCategoryServices(activeSubCategory)
      } else {
        getCategoryServices(categoryActive)
      }
    }, 300)
  }

  const onMouseEnter = useCallback((companyId) => {
    const el = document.querySelector(`[data-id="${companyId}comp"]`)
    if (el) el.classList.add("hover")
  }, [])

  const onMouseLeave = useCallback((companyId) => {
    const el = document.querySelector(`[data-id="${companyId}comp"]`)
    if (el) el.classList.remove("hover")
  }, [])

  return (
    companies &&
    Array.isArray(companies) &&
    companies.map((company) => (
      <div
        className="mobileServices--body__item"
        key={company.id}
        onClick={() => {
          if (!company.services_as_company) {
            getCompanyServices(company.id)
          } else {
            setServiceS(company)
          }
          togglePopUp(true)
        }}
      >
        <img src={`${baseUrlImg}/${company.icon}`} alt={company.name} />
        <span>{company.full_name}</span>
        {screenWidth > 1024 && (
          <i
            onMouseEnter={() => onMouseEnter(company.id)}
            onMouseLeave={() => onMouseLeave(company.id)}
            onClick={(e) =>
              favouriteClick(e, company.name, company.favourite, company.id)
            }
            data-id={`${company.id}comp`}
            className={`fal fa-star ${company.favourite ? "favourite" : ""}`}
          />
        )}
      </div>
    ))
  )
}

const mapsStateToProps = (state) => ({
  accountInfo: state.auth.accountInfo,
  screenWidth: state.main.screenWidth,
  skinExtras: state.auth.skinExtras,
  subCategories: state.main.subCategories,
})

export default connect(mapsStateToProps, { ...MainActions, ...AuthActions })(
  CompanyCards
)
