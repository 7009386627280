import { Form, Icon, Select, Tooltip } from "antd"
import React, { useState } from "react"
import FloatingLabelInput from "../FloatingLabelInput/FloatingLabelInput"
import DateComponent from "../DateComponent/DateComponent"
import VirtualizedSelect from "react-virtualized-select"
import AdressAutoFinder from "../AdressAutoFinder/AdressAutoFinder"
import countriesArray from "config/countryArr"
import { useEffect } from "react"
import { uniqBy } from "lodash"

const { Option } = Select

function Step2({ form, formData, setFormData }) {
  const { getFieldError, getFieldDecorator } = form
  const [nazioneList, setNazioneList] = useState([])

  useEffect(() => {
    // Extract unique nations
    const allNazione = uniqBy(countriesArray, "nazione")

    // Map unique nations to options
    const options = allNazione.map((country) => (
      <Option key={country.nazione} value={country.nazione}>
        {country.nazione}
      </Option>
    ))

    setNazioneList(options)
  }, [countriesArray])

  const province_of_birthOptions = countriesArray
    .filter((item) => item.nazione === formData.nazione.toUpperCase())
    .map((item) => ({
      label: item.sigla,
      value: item.sigla,
    }))

  const city_of_birthOptions = countriesArray
    .filter((item) => item.nazione === formData.nazione.toUpperCase())
    .map((item) => ({
      label: item.provincia,
      value: item.provincia,
    }))

  const handleNazioneChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      nazione: value,
    }))
  }

  const handleProvinceChange = (province) => {
    setFormData((prevState) => ({
      ...prevState,
      province_of_birth: province.value,
    }))
  }

  const handleCityChange = (city) => {
    setFormData((prevState) => ({
      ...prevState,
      city_of_birth: city.value,
    }))
  }
  return (
    <>
      <DateComponent
        form={form}
        formData={formData}
        setFormData={setFormData}
        fieldName="birthday"
        label="Data di nascita"
      />

      <Form.Item validateStatus={getFieldError("nazione") ? "error" : ""}>
        {getFieldDecorator("nazione", {
          initialValue: formData.nazione,
          rules: [{ required: true, message: "Inserire nazione!" }],
        })(
          <Select placeholder="Nazione di nascita" onChange={handleNazioneChange}>
            {nazioneList}
          </Select>
        )}
        {getFieldError("nazione") && (
          <Tooltip title={getFieldError("nazione")[0]} placement="right">
            <Icon
              type="exclamation-circle"
              style={{
                color: "red",
                position: "absolute",
                zIndex: 3,
                top: 7,
                right: 30,
              }}
            />
          </Tooltip>
        )}
      </Form.Item>

      <div className="Form.itemCol full" style={{ marginTop: 25 }}>
        <Form.Item>
          {getFieldDecorator("province_of_birth", {
            initialValue: formData.province_of_birth,
            rules: [{ required: true, message: "Inserire provincia!" }],
          })(
            <VirtualizedSelect
              placeholder="Provincia di nascita"
              options={province_of_birthOptions}
              onChange={handleProvinceChange}
              maxHeight={100}
            />
          )}
        </Form.Item>
      </div>
      <div className="Form.itemCol full" style={{ marginTop: 15, marginBottom: 15 }}>
        <Form.Item>
          {getFieldDecorator("city_of_birth", {
            initialValue: formData.city_of_birth,
            rules: [{ required: true, message: "Inserire comune!" }],
          })(
            <VirtualizedSelect
              options={city_of_birthOptions}
              onChange={handleCityChange}
              maxHeight={100}
              placeholder="Comune di nascita"
            />
          )}
        </Form.Item>
      </div>
      <AdressAutoFinder
        form={form}
        nazione={formData.nazioneDiResidenca}
        province={formData.residence_province}
        comune={formData.residence_city}
        setNazione={(nazione) => {
          setFormData((prevState) => ({
            ...prevState,
            nazioneDiResidenca: nazione,
          }))
        }}
        setProvince={(province) => {
          setFormData((prevState) => ({
            ...prevState,
            residence_province: province,
          }))
        }}
        setComune={(comune) => {
          setFormData((prevState) => ({
            ...prevState,
            residence_city: comune,
          }))
        }}
      />

      <Form.Item validateStatus={getFieldError("address") ? "error" : ""}>
        {getFieldDecorator("address", {
          initialValue: formData.address,
          rules: [{ required: true, message: "Inserisci il tuo indirizzo!" }],
        })(<FloatingLabelInput label="Indirizzo di residenza" />)}
        {getFieldError("address") && (
          <Tooltip title={getFieldError("address")[0]} placement="right">
            <Icon
              type="exclamation-circle"
              style={{
                color: "red",
                position: "absolute",
                zIndex: 3,
                top: 18,
                right: 10,
              }}
            />
          </Tooltip>
        )}{" "}
      </Form.Item>
    </>
  )
}

export default Step2
