import { Button, Modal, Form, Input, Icon } from "antd"
import React from "react"
import { AuthActions } from "redux-store/models"
import { connect } from "react-redux"

const AddProvider = ({
  isVisible,
  onClose,
  form,
  createExalogicAccount,
  exalogicAccountLoading,
}) => {
  const { getFieldDecorator, validateFields, resetFields } = form

  const handleSubmit = () => {
    validateFields((err, values) => {
      if (!err) {
        let endpointValue = values.endpoint

        try {
          const url = new URL(endpointValue)
          endpointValue = url.hostname
        } catch (e) {
          console.log(e)
        }

        createExalogicAccount(
          values.provider_name,
          values.username,
          values.password,
          endpointValue,
          resetFields,
          onClose
        )
      }
    })
  }

  return (
    <Modal
      className="add-service-modal"
      title="Exalogic provider"
      visible={isVisible}
      onCancel={onClose}
      width={500}
      footer={
        <Button
          type="primary"
          block
          onClick={handleSubmit}
          loading={exalogicAccountLoading}
        >
          Aggiungi servizio
        </Button>
      }
    >
      <Form layout="vertical" className="add-service-form">
        <Form.Item label="Provider services name">
          {getFieldDecorator("provider_name", {
            rules: [
              {
                required: true,
                message: "Per favore inserisci il nome del provider!",
              },
            ],
          })(<Input type="text" />)}
        </Form.Item>
        <Form.Item label="Domain">
          {getFieldDecorator("endpoint", {
            rules: [
              {
                required: true,
                message: "Per favore inserisci l'endpoint!",
              },
            ],
          })(<Input type="text" />)}
        </Form.Item>
        <Form.Item label="Username">
          {getFieldDecorator("username", {
            rules: [
              {
                required: true,
                message: "Per favore inserisci il nome utente!",
              },
            ],
          })(<Input type="text" suffix={<Icon type="user" />} />)}
        </Form.Item>

        <Form.Item label="Password">
          {getFieldDecorator("password", {
            rules: [
              {
                required: true,
                message: "Per favore inserisci la password!",
              },
            ],
          })(<Input.Password />)}
        </Form.Item>
        {/* <Form.Item label="Avviso: Ricarica Necessaria">
          {getFieldDecorator("ricarica", {
            rules: [
              {
                required: false,
                message: "Per favore inserisci la ricarica necessaria!",
              },
            ],
          })(<Input type="text" />)}
        </Form.Item> */}
      </Form>
    </Modal>
  )
}

const AddProviderForm = Form.create()(AddProvider)

const mapsStateToProps = ({ auth }) => ({
  exalogicAccountLoading: auth.exalogicAccountLoading,
})

export default connect(mapsStateToProps, AuthActions)(AddProviderForm)
