import React, { Fragment } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"

const ServiceSwiper = ({
  allServices,
  serviceMobile,
  canChangeCost,
  hasCostZero,
  showNumPadCost,
  setShowNumPadCost,
  setCustomCost,
  changeService,
}) => {
  const servicesLength = Number(allServices?.length)


  const range = (start, end) => {
    const array = []
    for (let i = start; i <= end; i++) {
      array.push(i)
    }
    return array
  }

  return (
    <div
      className={
        "TotalServices " + (hasCostZero && servicesLength < 5 ? "defaultCost" : "")
      }
    >
      <React.Fragment>
        <Swiper
          slidesPerView={
            hasCostZero && servicesLength > 4 ? 4 : Math.min(allServices.length, 5)
          }
        >
          {allServices
            .sort((i, c) => Number(i?.cost) - Number(c?.cost))
            .map((item, index) => {
              const sameCostMobile =
                parseFloat(item?.cost) === parseFloat(serviceMobile.cost)
              const sameIdMobile =
                item.service_id.toString() === serviceMobile.service_id.toString()
              return (
                <SwiperSlide key={`${item.service_id}${index}`}>
                  <Fragment>
                    {(canChangeCost ? sameCostMobile : sameIdMobile) ? (
                      <div
                        className={`${parseFloat(item?.cost)} ${parseFloat(
                          serviceMobile.cost
                        )} serv ${
                          (canChangeCost ? sameCostMobile : sameIdMobile)
                            ? "active"
                            : ""
                        }`}
                        onClick={() => changeService(item)}
                      >
                        <div className="Bottom">
                          <span className="Price">{parseInt(item.cost)}</span>
                          <span className="Euro">€</span>
                        </div>
                        <div className="Upper">
                          <div className="Upper--Left"></div>
                          <div className="Upper--Right"></div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`2t ${parseFloat(item?.cost)} ${parseFloat(
                          serviceMobile.cost
                        )} serv ${
                          (canChangeCost ? sameCostMobile : sameIdMobile)
                            ? "active"
                            : ""
                        }`}
                        onClick={() => changeService(item)}
                      >
                        <span className="Price">{parseInt(item.cost)}</span>
                        <span className="Euro">€</span>
                      </div>
                    )}
                  </Fragment>
                </SwiperSlide>
              )
            })}
        </Swiper>

        {canChangeCost || hasCostZero ? (
          showNumPadCost ? (
            <div
              className="serv"
              onClick={() => {
                setShowNumPadCost(false)
                setCustomCost("")
              }}
            >
              <span>x</span>
            </div>
          ) : (
            <div
              className="serv"
              onClick={() => {
                setShowNumPadCost(true)
              }}
            >
              <span>+</span>
            </div>
          )
        ) : (
          servicesLength < 6 &&
          range(servicesLength + 1, 6).map((item) => (
            <div key={item} className="serv noborder"></div>
          ))
        )}
      </React.Fragment>
    </div>
  )
}

export default ServiceSwiper
