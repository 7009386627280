import { Button, Modal, Form, Input, Icon, Select } from "antd"
import React, { useEffect, useState } from "react"
import { MainActions, AuthActions } from "redux-store/models"
import { connect } from "react-redux"

const { Option } = Select

const CreateListino = ({
  isVisible,
  onClose,
  form,
  createProvider,
  getAllServices,
  activeSkinId,
  categories,
}) => {
  const { getFieldDecorator, validateFields } = form
  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    validateFields((err, values) => {
      if (!err) {
        createProvider(
          values.name,
          values.categories,
          values.wallet,
          values.status,
          values.url,
          values.username,
          values.password,
          setLoading,
          onClose
        )
      }
    })
  }

  useEffect(() => {
    getAllServices(activeSkinId)
  }, [activeSkinId, getAllServices])

  return (
    <Modal
      className="add-service-modal"
      title="Crea Nuovo listino"
      visible={isVisible}
      onCancel={onClose}
      width={500}
      footer={
        <Button type="primary" block onClick={handleSubmit} loading={loading}>
          Crea Listino
        </Button>
      }
    >
      <Form layout="vertical" className="add-service-form">
        <Form.Item label="Nome Listino">
          {getFieldDecorator("name", {
            rules: [
              {
                required: true,
                message: "Please enter the listino name!",
              },
            ],
          })(<Input type="text" />)}
        </Form.Item>

        <Form.Item label="Listino Predefinito">
          {getFieldDecorator("categories", {
            rules: [
              {
                required: true,
                message: "Please select at least one listino!",
              },
            ],
          })(
            <Select
              mode="multiple"
              placeholder="Select categories"
              style={{ width: "100%" }}
            >
              {categories &&
                categories.map((category) => (
                  <Option key={category.id} value={category.id}>
                    {category.name}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>
      </Form>
    </Modal>
  )
}

const CreateListinoForm = Form.create()(CreateListino)

const mapsStateToProps = ({ main, auth }) => ({
  activeSkinId: main.activeSkinId,
  categories: auth.allServices.categories,
})

export default connect(mapsStateToProps, { ...MainActions, ...AuthActions })(
  CreateListinoForm
)
