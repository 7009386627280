import React, { useState } from "react"
import { transferMoney } from "services/auth"
import { connect } from "react-redux"
import MainActions from "redux-store/models/main"
import AuthActions from "redux-store/models/auth"

import "./depositoModal.css"
const DepositoModal = ({
  Close,
  type,
  username,
  id,
  role,
  activeSkinId,
  amountGiven,
  getUsers,
  SecondClose,
  setDepositoModalAdmin,
}) => {
  const amountCheck = amountGiven ? amountGiven : 0
  const [amount, setAmount] = useState(amountCheck)
  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useState("")
  const [tickOrX, setTickOrX] = useState(true)
  // console.log("activeSkinId", activeSkinId);
  console.log(type)
  return (
    <div className="DepositoModal">
      <div className="backDrop" style={{ zIndex: 8 }} onClick={Close}></div>
      <div className="DepositoModal--Container">
        <div className="DepositoModal--Container--Up">
          <div className="DepositoModal--Container--Up--Title">
            {/* {`${
            type === "deposit" ? "DEPOSITO" : "ADDEBITO"
          }`} */}
            <span className={type === "deposit" ? "title-active": ""}  onClick={() => {
                      setDepositoModalAdmin({
                        depositoModalVis: true,
                        type: "deposit",
                        username: username,
                        id: id,
                      })
                    }}>DEPOSITO</span>
            <span className={type === "withdraw" ? "title-active" : ""}  onClick={() => {
                      setDepositoModalAdmin({
                        depositoModalVis: true,
                        type: "withdraw",
                        username: username,
                        id: id,
                      })
                    }}>ADDEBITO</span>
          </div>
          <div className="DepositoModal--Container--Up--Agguntoal">
            {" "}
            <span className="DepositoModal--Container--Up--Agguntoal--Persona">
              <span className="DepositoModal--Container--Up--Agguntoal--Persona--Part1">
                Il credito verrà {type === "deposit" ? "aggiunto a:" : "addebiato da:"}
                
              </span>
              {/* <i className={`${allRoles[itemList.role]}`} /> */}
              <i className="fal fa-user" />
              <span className="DepositoModal--Container--Up--Agguntoal--Persona--Part3">
                {username?.toUpperCase()}
              </span>
            </span>
          </div>
        </div>
        <div className="DepositoModal--Container--Down">
          <div className="DepositoModal--Container--Down--Form">
            <div className="DepositoModal--Container--Down--Form--AmountLine">
              {" "}
              <span className="DepositoModal--Container--Down--Form--AmountLine--Amount">
                Somma
              </span>{" "}
              <div className="DepositoModal--Container--Down--Form--AmountLine--Input">
                <input
                  type="text"
                  placeholder="0.00"
                  onChange={(e) => {
                    setAmount(e.target.value)
                  }}
                  value={amount}
                  className="DepositoModal--Container--Down--Form--AmountLine--Input--Input"
                />{" "}
                <span className="DepositoModal--Container--Down--Form--AmountLine--Input--Euro">
                  {" "}
                  &euro;
                </span>
              </div>
            </div>
            <div className="DepositoModal--Container--Down--Form--NotifyLine">
              {" "}
              <span>Notifica All’utente</span>
              <i
                style={{ cursor: "pointer" }}
                className={`${tickOrX ? "far fa-check" : "fas fa-times"}`}
                onClick={() => {
                  setTickOrX(!tickOrX)
                }}
              ></i>
            </div>

            <textarea
              placeholder="Messaggio"
              onChange={(e) => {
                setMessage(e.target.value)
              }}
              className="DepositoModal--Container--Down--Form--MessageLine"
            />
            <div className="DepositoModal--Container--Down--Form--buttons">
              <button
                className="DepositoModal--Container--Down--Form--buttons--Conferma"
                onClick={async () => {
                  // api
                  await transferMoney(
                    id,
                    parseFloat(amount.replace(",", ".")),
                    type,
                    () => {},
                    role,
                    activeSkinId,
                    message,
                    tickOrX
                  )
                  await Close()
                  if (activeSkinId === -1) {
                    await getUsers(
                      null,
                      {
                        skin_id: 1,
                      },
                      25,
                      1,
                      "LOAD_FALSE"
                    )
                  } else {
                    await getUsers(
                      null,
                      {
                        skin_id: activeSkinId,
                        backoffice: true,
                      },
                      25,
                      1,
                      "LOAD_FALSE"
                    )
                  }
                  if (SecondClose) {
                    await SecondClose()
                  }
                  // console.log("ID:"+id,"AMOUNT:"+amount,"TYPE:"+type,"ACTIVE SKIN ID:"+activeSkinId,"MESSAGE:"+message,"TICKORX:"+tickOrX)
                }}
              >
                CONFERMA
              </button>
              <button
                className="DepositoModal--Container--Down--Form--buttons--Cancel"
                onClick={Close}
              >
                <span>ANNULLA</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  role: state.auth.accountInfo.profile.role,
  activeSkinId: state.main.activeSkinId,
})
export default connect(mapStateToProps,  { ...MainActions, ...AuthActions })(DepositoModal)
