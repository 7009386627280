import React, { useState } from "react";
import Graph from "../../Graph/Graph";
import AuthActions from "redux-store/models/main";
import { connect } from "react-redux";
import "./aRFSD.css";
const AdminRightFormStatisticheDetailsHelper = ({
  graphData,
  Tranzacioni,
  Commisione,
  Proviggioni,
  Aggio,
  Filter,
  role,
}) => {
  const [date, setDate] = useState(new Date());
  return (
    <div className="Statistiche Dropdown">
      <Graph
        graphicData={graphData}
        Filter={Filter}
        date={date}
        setDate={setDate}
      />
      <div className="Tranzacioni">
        <div>
          <span>Transazioni</span> <span>{`${Tranzacioni} `} &euro;</span>{" "}
        </div>
        <div>
          <span>Commissioni</span> <span>{`${Commisione} `} &euro;</span>{" "}
        </div>
        <div>
          <span>Proviggioni</span> <span>{`${Proviggioni} `} &euro;</span>
        </div>
        {role === "main_admin" && (
          <div>
            <span>Aggio</span> <span>{`${Aggio} `} &euro;</span>
          </div>
        )}
      </div>
    </div>
  );
};
class AdminRightFormStatisticheDetails extends React.Component {
  componentWillUnmount() {
    if (this.props.ModalOrNo) {
      this.props.Close({ visibility: false, data: "" });
    }
  }
  render() {
    const {
      graphData,
      Tranzacioni,
      Commisione,
      Proviggioni,
      Aggio,
      ModalOrNo,
      Close,
      Filter,
      menuSkinVisible,
      accountInfo,
    } = this.props;

    return (
      <React.Fragment>
        {ModalOrNo === true ? (
          <div
            className={`${
              !menuSkinVisible ? "RightFormModal Big" : "RightFormModal"
            }`}
          >
            <div
              className="backDrop"
              onClick={() => {
                Close({ visibility: false, data: "" });
              }}
            ></div>
            <div
              className="Close"
              onClick={() => {
                Close({ visibility: false, data: "" });
              }}
            >
              <i className="fal fa-times"></i>
            </div>
            <div className="Header">Statistiche</div>
            <AdminRightFormStatisticheDetailsHelper
              graphData={graphData}
              Tranzacioni={Tranzacioni}
              Commisione={Commisione}
              Proviggioni={Proviggioni}
              Aggio={Aggio}
              Filter={Filter}
              role={accountInfo.profile.role.name}
            />
          </div>
        ) : (
          <AdminRightFormStatisticheDetailsHelper
            graphData={graphData}
            Tranzacioni={Tranzacioni}
            Commisione={Commisione}
            Proviggioni={Proviggioni}
            Aggio={Aggio}
            Filter={Filter}
            role={accountInfo.profile.role.name}
          />
        )}
      </React.Fragment>
    );
  }
}
const mstp = (state) => {
  return {
    accountInfo: state.auth.accountInfo,
  };
};
export default connect(mstp, { ...AuthActions })(
  AdminRightFormStatisticheDetails,
  AdminRightFormStatisticheDetailsHelper
);
