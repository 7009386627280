import React from "react"
import { Redirect, Route } from "react-router-dom"

const PublicRoute = ({
  component: Component,
  isLoggedin,
  role,
  profile,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const { pathname } = props.location

        // Allow access to tickets for both logged in and not logged in users
        if (pathname.startsWith("/qr/")) {
          return <Component {...props} />
        }

        if (
          !isLoggedin &&
          role !== "main_admin" &&
          role !== "support" &&
          role !== "super_admin" &&
          role !== "agent"
        ) {
          return <Component {...props} />
        }

        if (role === "main_admin") {
          return (
            <Redirect
              to={{
                pathname: "/back-office/utenti",
                state: { from: pathname },
              }}
            />
          )
        }

        if (role === "support") {
          return (
            <Redirect
              to={{
                pathname: `/back-office/${
                  profile?.username === "support_prenotazioni"
                    ? "prenotazioni"
                    : "support"
                }`,
                state: { from: pathname },
              }}
            />
          )
        }

        if (role === "super_admin" || role === "agent") {
          return (
            <Redirect
              to={{
                pathname: "/back-office/utenti",
                state: { from: pathname },
              }}
            />
          )
        }
        return (
          <Redirect
            to={{
              pathname: "/dashboard/ricariche",
              state: { from: pathname },
            }}
          />
        )
      }}
    />
  )
}

export default PublicRoute
