import React, { Component } from "react";
import { Select, notification, Radio, Checkbox } from "antd";
import { Item } from "./FormsComponents";
import images from "themes/images";
import InviaPrenotationButton from "shared-components/InviaPrenotationButton/InviaPrenotationButton";
import { connect } from "react-redux";
import { AuthActions, MainActions } from "redux-store/models";

const { Option } = Select;
const CheckboxGroup = Checkbox.Group;

class Facileristrutturare extends Component {
  state = {
    nome: "",
    cognome: "",
    email: "",
    phone: "",
    citta: "",
    cap: "",
    codice_sconto: "",
    extra_data: "",
    contact_time: "1",
    superfice: "",
    nr_bagni: "",
    controsoffitti: "1",
    nr_condizionatori: "",
    impianti: "1",
    options: [
      "Accetto l'",
      "Acconsento al trattamento dei dati personali a fini di marketing diretto e di",
      "Acconsento al trattamento dei miei dati per l'invio di comunicazioni promozionali da parte di Facile Ristrutturare S.p.A mediante i seguenti mezzi",
      "Acconsento al trattamento dei miei dati personali per scopi di profilazione finlizzata alla personalizzazione del marketing diretto e alla pubblicità",
      "Acconsento alla cessione dei miei dati a soggetti terzi appartenenti alle categorie di seguito indicate per scopi di marketing diretto e l'elaborazione",
      "Acconsento all profilazione commerciale da parte dei soggeti terzi indicati",
    ],
    checkedList: [],
    indeterminate: true,
    checkAll: false,
  };

  resetState = (msg) => {
    if (!msg.error) {
      this.setState({
        nome: "",
        cognome: "",
        email: "",
        phone: "",
        citta: "",
        cap: "",
        codice_sconto: "",
        extra_data: "",
        contact_time: "1",
        superfice: "",
        nr_bagni: "",
        controsoffitti: "1",
        nr_condizionatori: "",
        impianti: [],
        checkedList: [],
      });
      notification["success"]({
        message: "Azione completata",
        description: msg.msg,
        placement: "bottomRight",
      });
    } else {
      notification["error"]({
        message: msg.msg[0],
        description: msg.msg[1],
        placement: "bottomRight",
        duration: 5,
      });
    }
  };

  onChange = (list) => {
    const { options } = this.state;
    this.setState({
      checkedList: list,
      indeterminate: !!list.length && list.length < options.length,
      checkAll: list.length === options.length,
    });
  };

  onCheckAllChange = (e) => {
    const { options } = this.state;
    this.setState({
      checkedList: e.target.checked ? options : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };

  submitData = (onSuccess = () => {}) => {
    const {
      nome,
      cognome,
      email,
      phone,
      citta,
      cap,
      codice_sconto,
      extra_data,
      contact_time,
      superfice,
      nr_bagni,
      controsoffitti,
      nr_condizionatori,
      impianti,
      checkedList,
    } = this.state;
    this.props.buyTicketOnline(
      12,
      null,
      "facileristrutturare",
      extra_data,
      null,
      2,
      nome,
      cognome,
      email,
      phone,
      null,
      citta,
      null,
      null,
      null,
      cap,
      null,
      null,
      codice_sconto ? codice_sconto : null,
      contact_time,
      superfice,
      nr_bagni,
      controsoffitti,
      nr_condizionatori ? nr_condizionatori : null,
      impianti,
      checkedList,

      (msg) => {
        this.resetState(msg);
        onSuccess();
      }
    );
  };

  render() {
    const { color, goBack, isMobile, activeService, nome_agenzia } = this.props;
    const {
      nome,
      cognome,
      email,
      phone,
      citta,
      cap,
      extra_data,
      superfice,
      nr_bagni,
      nr_condizionatori,
      codice_sconto,
      contact_time,
      impianti,
      controsoffitti,
      options,
      indeterminate,
      checkAll,
      checkedList,
    } = this.state;

    return (
      <div className="formsContainer--body animated fadeIn auto facile">
        {!isMobile && (
          <div className="leftForm">
            <img src={images[`${nome_agenzia}-bg`]} alt="" className="imgBg" />
            <img
              src={images[`${nome_agenzia}-logo`]}
              alt=""
              className="imgLogo"
            />
          </div>
        )}
        <div className="rightForm auto facile">
          <div className="rightForm--header">
            {!isMobile && (
              <div className="TitleBack" style={{ color: color }}>
                <i className="fal fa-chevron-left Arrow" onClick={goBack}></i>
                FACILE RISTRUTTURARE
              </div>
            )}

            {isMobile && (
              <div className="TitleBack">
                <i className="fal fa-digging"></i> {activeService}{" "}
              </div>
            )}
            <img
              src={images[`${nome_agenzia}-logo`]}
              alt=""
            />
          </div>
          <div className="rightForm--left">
            <Item
              label="Nome"
              value={nome}
              placeholder="Nome"
              type="text"
              handleChange={(e) => {
                this.setState({
                  nome: e,
                });
              }}
            />

            <Item
              label="Cognome"
              value={cognome}
              placeholder="Cognome"
              type="text"
              handleChange={(e) => {
                this.setState({
                  cognome: e,
                });
              }}
            />

            <Item
              label="Email"
              value={email}
              placeholder="Email"
              type="text"
              handleChange={(e) => {
                this.setState({
                  email: e,
                });
              }}
              Icon={() => <i className="fal fa-envelope inputI"></i>}
            />

            <Item
              label="Telefono"
              value={phone}
              placeholder="Telefono"
              type="text"
              handleChange={(e) => {
                this.setState({
                  phone: e,
                });
              }}
              Icon={() => <i className="fal  fa-phone inputI"></i>}
            />

            <Item
              label="Città"
              value={citta}
              placeholder="Città"
              type="text"
              handleChange={(e) => {
                this.setState({
                  citta: e,
                });
              }}
            />

            <Item
              label="C.A.P."
              value={cap}
              placeholder="Codice"
              type="text"
              handleChange={(e) => {
                this.setState({
                  cap: e,
                });
              }}
            />
            <div className="formsContainer--body__item">
              <div className="label">Codice Sconto</div>
              <input
                type="text"
                placeholder="Inserisci il tuo codice sconto"
                value={codice_sconto}
                onChange={(e) =>
                  this.setState({ codice_sconto: e.target.value })
                }
              />
            </div>

            <Item
              label="Note"
              value={extra_data}
              handleChange={(e) => {
                this.setState({
                  extra_data: e,
                });
              }}
              type="notes"
            />
          </div>
          <div className="rightForm--right ">
            <div className="formsContainer--body__item">
              <div className="label">
                Quando Preferiresti Essere Ricontattato?{" "}
                <span className="Red">*</span>
              </div>
              <Select
                value={contact_time}
                placeholder="Quando preferiresti essere ricontattato?"
                onChange={(value) => {
                  this.setState({
                    contact_time: value,
                  });
                }}
              >
                <Option value="1">09:00-12:30</Option>
                <Option value="2">14:00-18:00</Option>
                <Option value="3">18:00-20:00</Option>
              </Select>
            </div>
            <div className="formsContainer--body__semiCont mt-2">
              <div className="formsContainer--body__item semi">
                <div className="label">
                  Indica la Superfice <span className="Red">*</span>
                </div>
                <input
                  type="text"
                  placeholder="Indica la Superfice"
                  value={superfice}
                  onChange={(e) => this.setState({ superfice: e.target.value })}
                />
              </div>
              <div className="formsContainer--body__item semi">
                <div className="label">
                  Numero di Bagni <span className="Red">*</span>
                </div>
                <input
                  type="text"
                  placeholder="Numero di Bagni"
                  value={nr_bagni}
                  onChange={(e) => this.setState({ nr_bagni: e.target.value })}
                />
              </div>
            </div>
            <div className="formsContainer--body__semiCont mt-2 ">
              <div className="formsContainer--body__item semi ">
                <div className="label">
                  Controsoffitti? <span className="Red">*</span>
                </div>
                <div className="custom-radio">
                  <Radio.Group
                    value={controsoffitti}
                    onChange={(e) =>
                      this.setState({ controsoffitti: e.target.value })
                    }
                  >
                    <Radio value={"1"}>Si</Radio>
                    <Radio value={"2"}>No</Radio>
                  </Radio.Group>
                </div>
              </div>
              <div className="formsContainer--body__item semi">
                <div className="label">
                  Condizionatori
                  {/* <span className="Red">*</span> */}
                </div>
                <input
                  type="text"
                  placeholder="Quantità"
                  value={nr_condizionatori}
                  onChange={(e) =>
                    this.setState({ nr_condizionatori: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="formsContainer--body__item Ali">
              <div className="label ">
                Impianti da Rifare <span className="Red">*</span>
              </div>
              <Checkbox.Group
                className="inline-checkbox"
                onChange={(e) => this.setState({ impianti: e })}
                value={impianti}
              >
                <Checkbox value={"1"}>Elettrico</Checkbox>
                <Checkbox value={"2"}>Idraulico</Checkbox>
                <Checkbox value={"3"}>Gas</Checkbox>
                <Checkbox value={"4"}>Termoidraulico</Checkbox>
              </Checkbox.Group>

              {/* <Radio.Group
                value={impianti}
                onChange={(e) => this.setState({ impianti: e.target.value })}
              >
                <Radio value={"1"}>Elettrico</Radio>
                <Radio value={"2"}>Idraulico</Radio>
                <Radio value={"3"}>Gas</Radio>
                <Radio value={"4"}>Termoidraulico</Radio>
              </Radio.Group> */}
            </div>{" "}
            <div className="formsContainer--body__item ">
              <div
                className="label checkbox"
              >
                Selezione Termini e Condizioni <span className="Red">*</span>{" "}
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={this.onCheckAllChange}
                  checked={checkAll}
                >
                  Seleziona Tutti
                </Checkbox>
              </div>
              <CheckboxGroup value={checkedList} onChange={this.onChange}>
                {options.map((option, index) => {
                  if (index === 0) {
                    return (
                      <Checkbox value={option} key={index}>
                        {option}
                        <a
                          href="https://www.facileristrutturare.it/privacy-policy/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="orange-link">
                            Informativa Privacy di Facileristrutturare
                          </span>
                        </a>
                        .*
                      </Checkbox>
                    );
                  }
                  return (
                    <Checkbox value={option} key={index}>
                      {option}
                    </Checkbox>
                  );
                })}
              </CheckboxGroup>
              {/* <CheckboxGroup
                options={options}
                value={checkedList}
                onChange={this.onChange}
              /> */}
            </div>
            <InviaPrenotationButton
              handleClick={this.submitData}
              color={color}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default connect(null, { ...AuthActions, ...MainActions })(
  Facileristrutturare
);
