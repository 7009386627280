import React, { useState } from "react"
import images from "themes/images"
import { capitalize } from "lodash"
import Voli from "routes/domains/Forms/Voli"
import Treni from "routes/domains/Forms/Treni"
import Eventi from "routes/domains/Forms/Eventi"
import OnlineShop from "routes/domains/Forms/OnlineShop"
import Auto from "routes/domains/Forms/Auto"
import Energia from "routes/domains/Forms/Energia"
import Finanziamenti from "routes/domains/Forms/Finanziamenti"
import Enagic from "routes/domains/Forms/Enagic"
import Facileristrutturare from "routes/domains/Forms/Facileristrutturare"
import { withRouter } from "react-router-dom"
import "./style.css"
import Assicurazioni from "../../../routes/domains/Forms/Assicurazioni"

const Item = ({ name, activeService, setService, priorityName, backgroundColor }) => (
  <div 
    onClick={() => {
      setService(name);
    }}
    className={
      "prenotation--services__item" + (activeService === name ? " active" : "")
    }
    style={{backgroundColor: backgroundColor}}
  >
    <img
      className={name}
      src={images[`${name}-mobile`]}
      alt={`${name}-mobile`}
    />
    <span className={name}>{priorityName ? priorityName : capitalize(name)}</span>
  </div>
);

const Prenotazione = ({ match: { params } }) => {
  const { id } = params;
  const [activeService, setService] = useState(id || "expedia");

  React.useEffect(() => {
    document.querySelector(".wrapperServices").scrollLeft =
      document.querySelector(".prenotation--services__item.active").offsetLeft -
      //padding right 18
      18;
  }, [id, activeService])
  return (
    <div className="mobileWrapper prenotation">
      <div className="prenotation--services">
        <div className="wrapperServices">
          <Item
            activeService={activeService}
            setService={setService}
            name="expedia"
            backgroundColor="#FFC60B"
          />
          <Item
            activeService={activeService}
            setService={setService}
            name="flixbus"
            backgroundColor="#73D800"
          />
          <Item
            activeService={activeService}
            setService={setService}
            name="trenitalia"
            backgroundColor="#BF0013"
          />
          <Item
            activeService={activeService}
            setService={setService}
            name="biglietti"
            backgroundColor="#DD738C"
          />
          {/*
          <Item
            activeService={activeService}
            setService={setService}
            name="vivaticket"
          />
          {skin?.skin_id !== 4 &&
          <Item
            activeService={activeService}
            setService={setService}
            name="ticketone"
          /> 
          <Item
            activeService={activeService}
            setService={setService}
            name="stubhub"
          />*/}
          <Item
            activeService={activeService}
            setService={setService}
            name="shop-online"
            priorityName="Online Shop"
            backgroundColor="#0089A2"
          />
          <Item
            activeService={activeService}
            setService={setService}
            name="auto"
            priorityName="Noleggio Auto"
            backgroundColor="#22A094"
          />
          <Item
            activeService={activeService}
            setService={setService}
            name="assicurazioni"
            priorityName="Assicurazioni"
            backgroundColor="#262626"
          />
          {/* <Item
            activeService={activeService}
            setService={setService}
            name="bgame"
            priorityName="Registrazione"
          /> */}
          <Item
            activeService={activeService}
            setService={setService}
            name="luce-gas"
            priorityName="Luce - Gas"
            backgroundColor="#18819B"
          />
          {/* <Item
            activeService={activeService}
            setService={setService}
            name="visure"
            priorityName="Visure"
          /> */}
          <Item
            activeService={activeService}
            setService={setService}
            name="finanziamenti"
            priorityName="Finanziamenti"
            backgroundColor="#2075FC"
          />
          <Item
            activeService={activeService}
            setService={setService}
            name="enagic"
            priorityName="Enagic"
            backgroundColor="#004DA1"
          />
          <Item
            activeService={activeService}
            setService={setService}
            name="facileristrutturare"
            priorityName="Facile Ristrutturare"
            backgroundColor="#F19302"
          />
        </div>
      </div>
      <div className="prenotation--view">
        {activeService === "expedia" && (
          <Voli
            nome_agenzia={activeService}
            typee={1}
            isMobile
            activeService={activeService}
          />
        )}
        {(activeService === "flixbus" || activeService === "trenitalia") && (
          <Treni
            nome_agenzia={activeService}
            typee={2}
            isMobile
            activeService={activeService}
          />
        )}
        {activeService === "biglietti" && (
          <Eventi
            nome_agenzia={activeService}
            typee={3}
            isMobile
            activeService={activeService}
          />
        )}
        {activeService === "shop-online" && (
          <OnlineShop
            nome_agenzia={"Online Shop"}
            typee={4}
            isMobile
            activeService={activeService}
          />
        )}
        {activeService === "auto" && (
          <Auto
            nome_agenzia={"Noleggio Auto"}
            typee={6}
            isMobile
            activeService={activeService}
          />
        )}
        {activeService === "assicurazioni" && (
          <Assicurazioni
            nome_agenzia={"Assicurazioni"}
            typee={7}
            isMobile
            activeService={activeService}
          />
        )}

        {/* {activeService === "bgame" && (
          <Bgame
            nome_agenzia={"bgame"}
            typee={5}
            isMobile
            activeService={activeService}
          />
        )} */}
        {activeService === "luce-gas" && (
          <Energia
            nome_agenzia={"luce-gas"}
            typee={8}
            isMobile
            activeService={activeService}
          />
        )}

        {/* {activeService === "visure" && (
          <FormVisureDomain
            nome_agenzia={"visure"}
            typee={9}
            isMobile
            activeService={activeService}
          />
        )} */}
        {activeService === "finanziamenti" && (
          <Finanziamenti
            nome_agenzia={"finanziamenti"}
            typee={9}
            isMobile
            activeService={activeService}
          />
        )}
        {activeService === "enagic" && (
          <Enagic
            nome_agenzia={"enagic"}
            typee={11}
            isMobile
            activeService={activeService}
          />
        )}
        {activeService === "facileristrutturare" && (
          <Facileristrutturare
            nome_agenzia={"facileristrutturare"}
            typee={12}
            isMobile
            activeService={activeService}
          />
        )}
      </div>
    </div>
  );
};
export default withRouter(Prenotazione)
