import React, { Fragment } from "react"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import { BannerColors } from "config/index"
import images from "../../themes/images"
import { betServices } from "config"
import { notification } from "antd"
import { editCompanieBackNames } from "config/index"
import PrintTicket from "shared-components/ModulePopUp/PrintTicket"
import { Swiper, SwiperSlide } from "swiper/react"
import { getServiceIdIdentifier } from "utils/HelperFunc"
import EpayPrintTicket from "./EpayPrintTicket"
import ModulePopUpBack from "./ModulePopUpBack"
import { baseUrlImg } from "../../config/api"
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';

const CF_SERVICES = [
  "Paysafe PIN",
  "PAYS",
  "PAYSD",
  "Betflag Direct",
  "Payment System Gift Card",
]
class ModulePopUp4 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      serviceMobile: this.props.serviceSelected,
      tel_no: "",
      barcode: "21312",
      toPrint: false,
      confermaMsg: false,
      changeInput: false,
      swiperTouch: false,
      codFisInps: "",
      isValidCodFis: false,
      flipped: false,
    }
    this.timeout = React.createRef()
    this.handleChange = this.handleChange.bind(this)
  }
  modifyName = (name) => {
    if (name in editCompanieBackNames) {
      return editCompanieBackNames[name]
    }
    return name
  }
  range(start, end) {
    var array = []
    for (var i = start; i <= end; i++) {
      array.push(i)
    }
    return array
  }

  updateDefaultService = () => {
    const { scannedBarcode } = this.props
    const { service_id, cost } = this.state.serviceMobile || {}
    const serviceId = (service_id || "").toString()

    if (
      ["CRYPVOUCH001", "BGM001", "BBT001", "MBBT001"].includes(
        serviceId.toString()
      ) &&
      parseInt(cost) === 0
    ) {
      this.changeService({
        cost: serviceId === "CRYPVOUCH001" ? "50.00" : "10.00",
        name: `${this.state?.serviceMobile?.service_id.toString()} Voucher`,
        service_id: this.state?.serviceMobile?.service_id.toString(),
        type: "1",
      })
    }
  }

  componentDidMount() {
    this.updateDefaultService()

    const { scannedBarcode } = this.props

    const parseScannedBarcode = (data) => {
      try {
        return JSON.parse(data)
      } catch (error) {
        return null
      }
    }

    const parsedBarcode =
      typeof scannedBarcode === "string"
        ? parseScannedBarcode(scannedBarcode)
        : scannedBarcode

    if (parsedBarcode && parsedBarcode.phone_number) {
      this.setState((prevState) => {
        if (prevState.tel_no !== parsedBarcode.phone_number) {
          return { tel_no: parsedBarcode.phone_number }
        }
        return null
      })
    }
  }

  componentDidUpdate() {
    this.updateDefaultService()
  }
  hideAlert = () => {
    this.props.setRechargeMobile({})
  }
  setPrint = (val) => {
    this.setState({ toPrint: val })
  }
  handleChange(event) {
    this.setState({ tel_no: event.target.value })
  }

  changeService = (service) => {
    this.setState({ serviceMobile: service })
  }

  PuntoITCheck = (service) => {
    let bol = false
    if (service) {
      if (
        service.match(
          /BGAM|BBET|SPORTBULL|MBBT|MBBT001|BULL001|BBT001|BGM001|FLBT|CRYPVOUCH001|BETL|AMB|SPBET|360bet|Betflag Direct|AIRCSH|Payment System Gift Card|GiftCard001/g
        )
      ) {
        bol = true
      } else {
        bol = false
      }
    }
    return bol
  }

  async handleSubmit(service_id, tel_no, service_type, hasDefaultCost) {
    console.log(service_type)
    const accountData =
      (await JSON.parse(localStorage.getItem("accountDataB"))) || {}
    const isMrPay = (await accountData?.profile?.supplier) === "MrPay"
    const isSepafin = this.props.accountInfo.profile.hasSepafinCredentials

    if (service_id === "CRYPVOUCH001") {
      this.props.buyBitNovoVoucher(
        service_id,
        tel_no,
        null,
        isSepafin,
        this.props.accountInfo.profile.username
      )
    } else {
      if (this.PuntoITCheck(service_id || this.props.service_s.id)) {
        console.log("hehehhe")
        if (tel_no && tel_no < 5) {
          notification["warning"]({
            message: "Warning !",
            description: `l'importo dovrebbe essere superiore a 4 euro`,
            placement: "topRight",
            duration: 4,
          })
        } else {
          if (service_type === "exalogic") {
            this.props.buyExalogic(
              service_id,
              tel_no,
              null,
              isSepafin,
              this.props.accountInfo.profile.username,
              this.state.codFisInps
            )
          }

          if (service_type === "PaymentSystem") {
            this.props.createGiftCard(tel_no, this.state.codFisInps)
          } else {
            this.props.getCustomVoucherReq(
              service_id,
              tel_no,
              null,
              isSepafin,
              this.props.accountInfo.profile.username,
              this.state.codFisInps
            )
          }
        }
      } else {
        if (!isMrPay && !isSepafin && service_type === 1) {
          this.props.getRechargeMobileSim(
            service_id,
            tel_no,
            this.state?.serviceMobile?.cost,
            this.state?.serviceMobile?.ean,
            this.props?.accountInfo?.profile?.id
          )
        } else {
          this.props.getRechargeMobile(
            service_id,
            hasDefaultCost ? null : tel_no,
            null,
            isSepafin,
            this.props.accountInfo?.profile?.username,
            this.state.codFisInps,
            hasDefaultCost ? tel_no : this.state?.serviceMobile?.cost,
            this.state?.serviceMobile?.ean,
            this.props?.accountInfo?.profile?.id
          )
        }
      }
      this.clear()
    }
  }

  addNr = (nr) => {
    if (nr === ".") {
      this.setState({
        tel_no: this.state.tel_no.includes(",")
          ? this.state.tel_no
          : this.state.tel_no.concat(","),
      })
    } else {
      this.setState({ tel_no: this.state.tel_no.concat(nr) })
    }
  }
  replaceNr = (nr) => {
    this.setState({ tel_no: nr.toString() })
  }
  clearOne = () => {
    this.setState((state) => ({ tel_no: state.tel_no.slice(0, -1) }))
  }
  clear = () => {
    this.setState({ tel_no: "", codFisInps: "" })
  }
  componentWillUnmount() {
    this.setState({ changeInput: false })
    if (this.timeout?.current) clearInterval(this.timeout?.current)
  }

  onTouchStart = (e) => this.setState({ swiperTouch: true })
  onTouchEnd = (e) => {
    this.timeout.current = setTimeout(
      () => this.setState({ swiperTouch: false }),
      500
    )
  }

  validateCodiceFiscale = (codiceFiscale) => {
    return codiceFiscale.length === 16 && !codiceFiscale.includes(" ")
  }

  handleFlip = () => {
    const { flipped } = this.state
    this.setState({ flipped: !flipped })
  }

  render() {
    const { service_s, rechargeMobile, service } = this.props
    const {
      serviceMobile,
      tel_no,
      toPrint,
      changeInput,
      codFisInps,
      isValidCodFis,
    } = this.state

    console.log(betServices)

    const accountData = JSON.parse(localStorage.getItem("accountDataB")) || {}
    const isEPay = accountData?.profile?.supplier === "E-Pay"

    // const {serviceType}=this.props;
    // console.log("service", service, rechargeMobile, service_s);

    // const {confermaMsg}=this.state
    // const arr = {
    //   message: "User transactions fetched successfully",
    //   receipt:
    //     " \n RICARICA VODAFONE\n \nTerminalID:             IT016546\n Date:                 17.01.2020\nTime:                   12:20:03\n Trace-Nr.:                103503\nReceipt-Nr.:                5343\n\n--------------------------------\n Codice Transazione\n 97732011\n\nHai acquistato Giga Ricarica 5.\nhai 3 Giga validi per un mese e\ndi ricarica.\nCon Giga Ricarica 10, a 10 euro\nGiga validi per un mese e 9 euro\nricarica.\nI 3 Giga sono validi\nper un mese e si disattivano\nin automatico.\nRiceverai un SMS\n di conferma dell'acquisto e\nsuccessivamente l'SMS di con\nferm dell'attivazione dei 3 Gi\n ga.    I 3 Giga non sono cumulab\nili   con eventuali Giga residui\ndi unGiga Ricarica acquistata e\nntro igiorno precedente. Per inf\n ormazi  vai su voda.it/gigaricar\n ica       Scontrino non fiscale.\n \n NUMERO DI TELEFONO\n - 3406148006 -\n Importo 10 Euro\n \nLA RICARICA VERRA'\n ACCREDITATA ENTRO 24 OR\n E.     Per assistenza chiama il\n190         o vai su www.190.it\nIVA ASSOLTA AI SENSI DELL\n 'EX   ART.74 co.1,lett.d> DPR 63\n 3/72     da Vodafone Italia S.p.\n A            P.Iva 08539010010\n Operazione eseguita da Eur\n onet   Pay ' Transaction service\nsrl         P.Iva 05445540965\n\nTRANSAZIONE ESEGUITA\n\r\n\r\n",
    //   barcode: "0000073721475",
    //   wallet: "983.00",
    // };

    const allServices =
      service_s?.id && service_s?.id in betServices
        ? betServices[service_s.id]
        : service_s.services || []

    const reduxServicesLength = Number((service_s?.services || [])?.length)
    const allServicesLength = Number(allServices?.length)
    const servicesLength =
      service.type.toString() === "B" ? allServicesLength : reduxServicesLength

    const hasCostZero = service_s?.services?.some(
      (service) => parseInt(service.cost) === 0
    )
    const hasCostOrNumber = ["B", "1", "0", "exalogic", "PaymentSystem"].includes(
      service.type.toString()
    )
    const canChangeCost =
      this.PuntoITCheck(service_s.id) ||
      service_s.id === "Bitnovo Voucher" ||
      parseInt(serviceMobile.cost) === 0
    const hasDefaultCost = canChangeCost && !this.state.changeInput
    const costOrNumber =
      (hasCostOrNumber && (hasDefaultCost ? serviceMobile.cost : tel_no)) || 0
    // console.log(!canChangeCost ? serviceMobile.cost : costOrNumber)

    return (
      <div
        className="modulePopUP modulePopUP1 telRechanrge"
        onClick={() => {
          !(rechargeMobile && toPrint) &&
            !this.state.swiperTouch &&
            this.props.togglePopUp(false)
          this.props.setScannedBarcode(null)
        }}
      >
        {" "}
        <div className="flip-container" onClick={() => this.handleFlip()}>
          <div className={`flipper ${this.state.flipped ? "flipped" : ""}`}>
            <div className="front">
              {" "}
              <div
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
                className={`leftCol_Module Popup4 ${
                  this.props.serviceType === "SCMS" ||
                  CF_SERVICES.includes(service_s.id) ||
                  ["0"].includes(service.type.toString())
                    ? "game"
                    : this.PuntoITCheck(service_s.id)
                    ? "small"
                    : ""
                }`}
              >
                <div
                  className={
                    "row" + (CF_SERVICES.includes(service_s.id) ? " paysafe" : "")
                  }
                >
                  {this.state.confermaMsg && (
                    <div className="confermaMsg animated bounce">
                      <div className="info">
                        Stai eseguiendo una ricarica da €
                        {!canChangeCost ? serviceMobile.cost : costOrNumber}
                        . <br /> Confermi?
                      </div>
                      <div className="buttons">
                        <button
                          onClick={() => {
                            this.handleSubmit(
                              serviceMobile.service_id,
                              costOrNumber,
                              serviceMobile.type,
                              canChangeCost
                            )
                            this.setPrint(true)
                            this.setState({ confermaMsg: false })
                          }}
                        >
                          Si
                        </button>
                        <button
                          onClick={() => {
                            this.setState({ confermaMsg: false })
                          }}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="Close">
                    <span
                      className="CloseButton"
                      onClick={() => {
                        this.props.togglePopUp(false)
                        this.props.setScannedBarcode(null)
                      }}
                    >
                      <img src={images.close} alt="" />
                    </span>
                  </div>

                  <div className="info-icon">
                    <span onClick={() => this.handleFlip()}>
                      <i className="fal fa-question-circle"></i>
                    </span>
                  </div>

                  <div className="Logo">
                    <div className="Logo--Help">
                      <img
                        src={
                          isEPay || service.icon
                            ? `${baseUrlImg}/${
                                service.icon || "uncategorized/placeholder.jpg"
                              }`
                            : images[service_s.id]
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="ServiceHeader">
                    {/* <h4>{this.modifyName(service_s.name)}</h4> */}
                    {/* {service.type.toString() === "1" &&
                    !this.PuntoITCheck(service_s.id) ? (
                      <h5>Inserire il numero di telefono da ricaricare</h5>
                    ) : (
                      this.props.serviceType === "SCMS" && (
                        <h5>Seleziona le ricariche in basso ed esegui</h5>
                      )
                    )} */}
                  </div>
                  {((service.type.toString() === "1" ||
                    service.type === "exalogic" ||
                    service.type === "PaymentSystem") &&
                    !this.PuntoITCheck(service_s.id)) ||
                  changeInput === true ? (
                    <div className={`NumPadContainer ${changeInput ? "bgm" : ""}`}>
                      <div className="NumPd">
                        {!changeInput && <span>+39</span>}
                        <input
                          className={`${changeInput ? "bgm" : ""}`}
                          type="text"
                          placeholder="_ _ _  _ _ _ _ _ _ _"
                          value={
                            changeInput ? `${this.state.tel_no}` : this.state.tel_no
                          }
                          onChange={this.handleChange}
                        />
                        {!changeInput && <i className="fas fa-address-book"></i>}
                      </div>
                      <div className="Numbers">
                        {this.PuntoITCheck(service_s.id) ? (
                          <Fragment>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, ".", "0", "x"].map(
                              (num) => (
                                <span
                                  key={num}
                                  id={`num${num}`}
                                  className={`bgm ${num === "x" ? "x" : ""}`}
                                  onClick={() =>
                                    num === "x" ? this.clearOne() : this.addNr(num)
                                  }
                                >
                                  {num === "x" ? (
                                    <span>
                                      <i className="fal fa-times" />
                                      <div className="triangle"></div>
                                    </span>
                                  ) : (
                                    num
                                  )}
                                </span>
                              )
                            )}
                          </Fragment>
                        ) : (
                          <Fragment>
                            {[
                              1,
                              2,
                              3,
                              4,
                              5,
                              6,
                              7,
                              8,
                              9,
                              "C",
                              0,
                              <img src={images["arrow-back"]} alt="back" />,
                            ].map((num) => (
                              <span
                                key={num}
                                id={`num${num}`}
                                onClick={() =>
                                  num === "C"
                                    ? this.clear()
                                    : num.type === "img"
                                    ? this.clearOne()
                                    : this.addNr(num)
                                }
                              >
                                {num}
                              </span>
                            ))}
                          </Fragment>
                        )}
                      </div>
                    </div>
                  ) : (
                    (this.props.serviceType === "SCMS" ||
                      service_s.id === "PAYS" ||
                      service.type.toString() === "B" ||
                      service.type.toString() === "0") && (
                      <div
                        className={"GamingBanner " + (isEPay ? "" : "mrPayBanner")}
                      >
                        <div
                          className="img"
                          alt={serviceMobile?.service_id.substring(0, 3)}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            // background:
                            //   this.props.serviceType === "SCMS"
                            //     ? `${getServiceIdIdentifier(
                            //         BannerColors,
                            //         serviceMobile?.service_id || ""
                            //       )}`
                            //     : "#ffff",
                          }}
                        >
                          {/* eslint-disable-next-line jsx-a11y/alt-text */}
                          <img
                            style={{
                              width:
                                service.background ||
                                isEPay ||
                                this.props.serviceType === "SCMS"
                                  ? "100%"
                                  : "50%",
                            }}
                            src={
                              isEPay
                                ? `${baseUrlImg}/${
                                    service.background ||
                                    "uncategorized/placeholder.jpg"
                                  }`
                                : service.background
                                ? `${baseUrlImg}/${service.background}`
                                : images[service_s.id]
                            }
                            alt=""
                          />
                        </div>
                      </div>
                    )
                  )}
                  {/* {this.PuntoITCheck(service_s.id) && (
                    <div className="infoRicariche">
                      <i className="fal fa-info-circle"></i>
                      Voucher generato è valido solo per la <br />
                      ricarica ai conti dei utenti
                    </div>
                  )} */}

                  <div
                    className={
                      "TotalServices " +
                      (hasCostZero && servicesLength < 5 ? "defaultCost" : "")
                    }
                  >
                    <React.Fragment>
                      <Swiper
                        slidesPerView={
                          hasCostZero && servicesLength > 4
                            ? 4
                            : Math.min(allServices.length, 5)
                        }
                        onTouchStart={this.onTouchStart}
                        onTouchEnd={this.onTouchEnd}
                      >
                        {allServices
                          .sort((i, c) => Number(i?.cost) - Number(c?.cost))
                          .map((item, index) => {
                            const hasCustomPrices =
                              this.PuntoITCheck(service_s.id) ||
                              service.type.toString() === "B"

                            const sameCostMobile =
                              parseFloat(item?.cost) ===
                              parseFloat(serviceMobile.cost)
                            const sameIdMobile =
                              item.service_id.toString() ===
                              serviceMobile.service_id.toString()
                            return (
                              <SwiperSlide key={`${item.service_id}${index}`}>
                                <Fragment>
                                  {(
                                    hasCustomPrices ? sameCostMobile : sameIdMobile
                                  ) ? (
                                    <div
                                      className={`${parseFloat(
                                        item?.cost
                                      )} ${parseFloat(serviceMobile.cost)} serv ${
                                        (
                                          hasCustomPrices
                                            ? sameCostMobile
                                            : sameIdMobile
                                        )
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() => this.changeService(item)}
                                    >
                                      <div className="Bottom">
                                        <span className="Price">
                                          {parseInt(item.cost)}
                                        </span>
                                        <span className="Euro">€</span>
                                      </div>
                                      <div className="Upper">
                                        <div className="Upper--Left"></div>
                                        <div className="Upper--Right"></div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      className={`2t ${parseFloat(
                                        item?.cost
                                      )} ${parseFloat(serviceMobile.cost)} serv ${
                                        (
                                          hasCustomPrices
                                            ? sameCostMobile
                                            : sameIdMobile
                                        )
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() => this.changeService(item)}
                                    >
                                      <span className="Price">
                                        {parseInt(item.cost)}
                                      </span>
                                      <span className="Euro">€</span>
                                    </div>
                                  )}
                                </Fragment>
                              </SwiperSlide>
                            )
                          })}
                      </Swiper>

                      {this.PuntoITCheck(serviceMobile.service_id.toString()) ||
                      hasCostZero ? (
                        changeInput ? (
                          <div
                            className="serv"
                            onClick={() => {
                              this.setState({ changeInput: false })
                            }}
                          >
                            <span>x</span>
                          </div>
                        ) : (
                          <div
                            className="serv"
                            onClick={() => {
                              this.setState({ changeInput: true })
                            }}
                          >
                            <span>+</span>
                          </div>
                        )
                      ) : (
                        servicesLength < 5 &&
                        this.range(servicesLength + 1, 5).map((item) => {
                          return <div key={item} className="serv noborder"></div>
                        })
                      )}
                    </React.Fragment>
                  </div>

                  {CF_SERVICES.includes(service_s.id) && (
                    <div className="CodiceFiscale">
                      <label>
                        Codice Fiscale <span>*</span>
                      </label>
                      <input
                        value={this.state.codFisInps}
                        maxLength={16}
                        onChange={(e) => {
                          const inputValue = e.target.value
                          const isValid = this.validateCodiceFiscale(inputValue)
                          this.setState({
                            codFisInps: inputValue,
                            isValidCodFis: isValid,
                          })
                        }}
                      />
                      {codFisInps && isValidCodFis && (
                        <i className="far fa-check-circle"></i>
                      )}
                      {codFisInps && !isValidCodFis && (
                        <i className="far fa-times-circle"></i>
                      )}
                    </div>
                  )}
                  <h5 className="service-name">{serviceMobile.name}</h5>

                  <div
                    className="Confimation Buttons"
                    onClick={() => {
                      this.setPrint(true)
                    }}
                  >
                    <span className="stampCup prenota">
                      <h3>Prenota </h3>
                      <i className="fal fa-qrcode" />
                    </span>

                    <span
                      className="stampCup annulla"
                      onClick={() => {
                        this.props.togglePopUp(false)
                        this.props.setScannedBarcode(null)
                      }}
                    >
                      <h3>Annulla </h3>
                      <i className="fal fa-close" />
                    </span>

                    <span
                      className="stampCup esegui"
                      onClick={() => {
                        if (this.props.accountInfo?.token) {
                          if (serviceMobile.type === 1) {
                            this.handleSubmit(
                              serviceMobile.service_id,
                              costOrNumber,
                              serviceMobile.type
                            )
                            this.setPrint(true)
                          } else this.setState({ confermaMsg: true })
                        } else {
                          window.location.hash = "login"
                          this.props.togglePopUp(false)
                          this.setPrint(true)
                        }
                      }}
                    >
                      <h3>Esegui </h3>
                      <i className="fal fa-check" />
                    </span>
                  </div>
                  {(rechargeMobile?.errors || rechargeMobile?.message) && (
                    <div className="messages">
                      <div className="closeM" onClick={this.hideAlert}>
                        chiudi messaggi
                      </div>
                      {rechargeMobile?.errors &&
                        Object.keys(rechargeMobile?.errors).map((item, index) => {
                          return (
                            <div className="errorM" key={index}>
                              <i className="fad fa-exclamation text-danger"></i>
                              {rechargeMobile?.errors[item]}
                            </div>
                          )
                        })}

                      {rechargeMobile?.message && (
                        <div className="infoM">
                          <i className="fad fa-info text-info"></i>{" "}
                          {rechargeMobile?.message}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {this.state.flipped && (
              <div className="back">
                {service_s && (
                  <ModulePopUpBack
                    service_s={service_s}
                    handleFlip={() => this.handleFlip()}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {/* {console.log("rechargeMobile", rechargeMobile, toPrint)} */}
        {Object.keys(rechargeMobile).length > 0 &&
          toPrint &&
          (isEPay ? (
            <EpayPrintTicket
              arr={rechargeMobile.return ? rechargeMobile.return : rechargeMobile}
              setPrint={this.setPrint}
            />
          ) : (
            <PrintTicket arr={rechargeMobile} setPrint={this.setPrint} />
          ))}
      </div>
    )
  }
}

const mapsStateToProps = (state) => ({
  isShowing: state.main.isShowing,
  service_s: state.auth.service_s,
  rechargeMobile: state.auth.rechargeMobile,
  skinExtras: state.auth.skinExtras,
  accountInfo: state.auth.accountInfo,
  serviceType: state.auth.serviceType,
  scannedBarcode: state.auth.scannedBarcode,
})

export default connect(mapsStateToProps, { ...MainActions, ...AuthActions })(
  ModulePopUp4
)
