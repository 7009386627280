import { Button, Icon, Input, message, notification, Table } from "antd"
import React, { useState } from "react"
import images from "themes/images"
import { endpoint } from "../../../../config/api"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { MainActions, AuthActions } from "redux-store/models"
import axios from "axios"
import EditMainProvider from "../../../../shared-components/adminSharedComp/adminModals/MainProvider/EditMainProvider"
import CreateListinoForm from "./CreateListinoForm"

const staticData = [
  {
    id: 1,
    name: "Listino 1",
    status: "1",
    skin: "Mrpay, MrWallet, Wintoto...",
    categories: "Category 1, Category 2",
  },
  {
    id: 2,
    name: "Listino 2",
    status: "0",
    skin: "Mrpay, MrWallet, Wintoto...",
    categories: "Category 3, Category 4",
  },
  {
    id: 3,
    name: "Listino 3",
    status: "1",
    skin: "Mrpay, MrWallet, Wintoto...",
    categories: "Category 5, Category 6",
  },
  {
    id: 4,
    name: "Listino 4",
    status: "0",
    skin: "Mrpay, MrWallet, Wintoto...",
    categories: "Category 7, Category 8",
  },
]

const Listino = (props) => {
  const { screenWidth, getProviders, accountInfo } = props
  const [searchQuery, setSearchQuery] = useState("")
  const [addListinoVisible, setAddListinoVisible] = useState(false)
  const [editProviderVisible, setEditProviderVisible] = useState(false)
  const [selectedProvider, setSelectedProvider] = useState({})

  const token = accountInfo?.token

  const filteredData = staticData.filter((provider) =>
    provider.name.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const handleUpdateProvider = (status, id) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    }
    axios
      .put(
        `${endpoint}/providers/${id}`,
        {
          status,
        },
        {
          headers,
        }
      )
      .then(() => {
        notification.success({
          message: "Provider Updated",
          description: "The provider information has been updated successfully.",
          duration: 2,
        })
        getProviders()
      })
      .catch((error) => {
        message.error("Failed to update provider!")
      })
  }

  const columns =
    screenWidth > 600
      ? [
          {
            title: "Nome Listino",
            dataIndex: "name",
            ellipsis: true,
            width: "10%",
          },
          {
            title: "Categorie Attive",
            dataIndex: "categories",
            render: (categories) => (
              <span>{categories ? categories : "No categories available"}</span>
            ),
            ellipsis: true,
            width: "20%",
          },
          {
            title: "User associati",
            dataIndex: "categories",
            render: (categories) => (
              <span>{categories ? categories : "No categories available"}</span>
            ),
            ellipsis: true,
            width: screenWidth > 1200 ? "20%" : "20%",
          },
          {
            title: "Skin",
            dataIndex: "skin",

            ellipsis: true,
            width: "12%",
          },
          {
            title: "Status",
            dataIndex: "status",
            render: (status, record) => (
              <img
                onClick={() =>
                  handleUpdateProvider(record.status === "1" ? "0" : "1", record.id)
                }
                src={images[status === "1" ? "activeBtn" : "inactiveBtn"]}
                className="activate-btn"
                alt="Activate Button"
              />
            ),
            width: "12%",
            align: "center",
          },

          {
            title: "Impostazioni",
            render: (text, record) => (
              <Button
                icon="setting"
                // onClick={() => history.push(`/back-office/servizi/${record.name}`)}
                onClick={() => {
                  setSelectedProvider(record)
                  setEditProviderVisible(true)
                }}
              >
                Settings
              </Button>
            ),
            ellipsis: true,
            width: "10%",
            align: "center",
          },
        ]
      : [
          {
            title: "Nome Provider",
            dataIndex: "name",
            ellipsis: true,
          },
          {
            title: "Credito",
            dataIndex: "wallet",
            render: (wallet) => <span>€ {parseFloat(wallet).toFixed(2)}</span>,
            ellipsis: true,
            align: "right",
          },
          {
            title: "Status",
            dataIndex: "status",
            render: (status) => (
              <img
                src={images[status === "1" ? "activeBtn" : "inactiveBtn"]}
                className="activate-btn"
                alt="Activate Button"
                height={15}
              />
            ),
            align: "center",
          },
          {
            title: "Impostazioni",
            render: (text, record) => (
              <Button
                icon="setting"
                onClick={() => {
                  setSelectedProvider(record)
                  setEditProviderVisible(true)
                }}
              ></Button>
            ),
            ellipsis: true,
            align: "center",
          },
        ]

  return (
    <>
      <CreateListinoForm
        isVisible={addListinoVisible}
        onClose={() => setAddListinoVisible(false)}
      />

      <EditMainProvider
        visible={editProviderVisible}
        provider={selectedProvider}
        onClose={() => setEditProviderVisible(false)}
        getProviders={getProviders}
      />
      <div className="Container Annunci AllServices providers-admin">
        <div className="container-fluid overview">
          <div className="panels-container">
            <div className="provider-header">
              <div className="title">
                <Icon type="bank" /> Listino
              </div>
              <div className="filters">
                <Button
                  type="primary"
                  icon="plus"
                  onClick={() => setAddListinoVisible(true)}
                >
                  Crea Nuovo Listino
                </Button>
                <Input
                  placeholder="Cerca"
                  suffix={<Icon type="search" />}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            <div className="providers-table">
              <Table
                dataSource={filteredData}
                columns={columns}
                pagination={false}
                className="table-striped-rows"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  screenWidth: state.main.screenWidth,
  providers: state.main.providers,
  accountInfo: state.auth.accountInfo,
})

export default connect(mapStateToProps, { ...MainActions, ...AuthActions })(
  withRouter(Listino)
)
