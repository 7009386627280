import { Button, Modal, Form, Input, Icon, Select } from "antd"
import React, { useEffect, useState } from "react"
import { MainActions, AuthActions } from "redux-store/models"
import { connect } from "react-redux"

const { Option } = Select

const CreateMainProvider = ({
  isVisible,
  onClose,
  form,
  createProvider,
  getAllServices,
  activeSkinId,
  categories,
}) => {
  const { getFieldDecorator, validateFields } = form
  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    validateFields((err, values) => {
      if (!err) {
        createProvider(
          values.name,
          values.categories,
          values.wallet,
          values.status,
          values.url,
          values.username,
          values.password,
          setLoading,
          onClose
        )
      }
    })
  }

  useEffect(() => {
    getAllServices(activeSkinId)
  }, [activeSkinId, getAllServices])

  return (
    <Modal
      className="add-service-modal"
      title="Create Provider"
      visible={isVisible}
      onCancel={onClose}
      width={500}
      footer={
        <Button type="primary" block onClick={handleSubmit} loading={loading}>
          Create Provider
        </Button>
      }
    >
      <Form layout="vertical" className="add-service-form">
        <Form.Item label="Provider Name">
          {getFieldDecorator("name", {
            rules: [
              {
                required: true,
                message: "Please enter the provider name!",
              },
            ],
          })(<Input type="text" />)}
        </Form.Item>

        <Form.Item label="Categories">
          {getFieldDecorator("categories", {
            rules: [
              {
                required: true,
                message: "Please select at least one category!",
              },
            ],
          })(
            <Select
              mode="multiple"
              placeholder="Select categories"
              style={{ width: '100%' }}
            >
              {categories &&
                categories.map((category) => (
                  <Option key={category.id} value={category.id}>
                    {category.name}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Wallet Balance">
          {getFieldDecorator("wallet", {
            rules: [
              {
                required: true,
                message: "Please enter the wallet balance!",
              },
            ],
          })(<Input type="number" />)}
        </Form.Item>

        <Form.Item label="Status">
          {getFieldDecorator("status", {
            rules: [
              {
                required: true,
                message: "Please select the status!",
              },
            ],
          })(
            <Select placeholder="Select Status">
              <Option value="1">Active</Option>
              <Option value="0">Inactive</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item label="URL">
          {getFieldDecorator("url", {
            rules: [
              {
                required: true,
                message: "Please enter the URL!",
              },
            ],
          })(<Input type="text" />)}
        </Form.Item>

        <Form.Item label="Username">
          {getFieldDecorator("username", {
            rules: [
              {
                required: true,
                message: "Please enter the username!",
              },
            ],
          })(<Input type="text" suffix={<Icon type="user" />} />)}
        </Form.Item>

        <Form.Item label="Password">
          {getFieldDecorator("password", {
            rules: [
              {
                required: true,
                message: "Please enter the password!",
              },
            ],
          })(<Input.Password />)}
        </Form.Item>
      </Form>
    </Modal>
  )
}

const CreateMainProviderForm = Form.create()(CreateMainProvider)

const mapsStateToProps = ({ main, auth }) => ({
  activeSkinId: main.activeSkinId,
  categories: auth.allServices.categories, 
})

export default connect(mapsStateToProps, { ...MainActions, ...AuthActions })(
  CreateMainProviderForm
)
