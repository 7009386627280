import React, {useState} from "react";

const BolletinoFooter = () => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = event => {
    setIsActive(current => !current);
  };
  return (
  <React.Fragment>
    <section className={isActive ? "show": ""} onClick={handleClick}>
      Prima di confermare la prenotazione verificare di aver inserito
      correttamente il numero di Conto Corrente Postale o l’IBAN del
      beneficiario e che i dati riportati nella sezione “Eseguito da”
      corrispondano a quelli indicati dal richiedente. <br></br>
    {/* </section>
    <section> */}
      Qualora dovessero essere inseriti dati non corretti il pagamento potrebbe
      essere inviato ad un beneficiario diverso rispetto a quello desiderato
      oppure il beneficiario potrebbe non riconoscere l’esecutore, chiedendo un
      nuovo pagamento.
      
    </section>
    {/* <section>
      Tutti i dati forniti sono trattati nel rispetto della normativa vigente sul trattamento dei dati, come riportato
      nei: <a href="#">Foglio Informativo</a> e <a href="#">Privacy Policy</a>.
    </section> */}
  </React.Fragment>
);
} 

const CostoFiss = () => (
  <section className="color">Il costo del servizio è fisso a 2,50€</section>
);

export const BolletinoHeader = () => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = event => {
    setIsActive(current => !current);
  };
  return (
    <React.Fragment >
      <section className={isActive ? "show": ""} onClick={handleClick}>
        Prenotazione in ottemperanza della normativa italiana come previsto
        dall’art. 17 del D.Lgs 231/07 ed indicato dalle Linee Guida per il
        Servizio di Pagamento Bollettini di Banca d’Italia. Per effettuare il
        pagamento di un bollettino bianco o parzialmente premarcato, di
        tipologia 123, 451, 674, dovrai compilare questi campi. Una volta
        inseriti i dati potrai procedere a visualizzare il riepilogo della tua
        prenotazione. Comunica o stampa il codice prenotazione che viene
        generato al termine della procedura e recati presso l’agenzia Bpoint per
        eseguire il pagamento. La prenotazione ha una durata di 24 ore.
        <CostoFiss />
      </section>
    </React.Fragment>
  );
};

const BolletinoAutoHeader = () => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = event => {
    setIsActive(current => !current);
  };
  return (
  <React.Fragment>
    <section className={isActive ? "show": ""} onClick={handleClick}>
      Qui puoi pagare il Bollo Auto. Inserisci la targa e il tipo di veicolo, il
      sistema ti indicherà l’importo da pagare e i dati a compilare per
      concludere il pagamento della tua tassa automobilistica.
      <CostoFiss />
    </section>
  </React.Fragment>
);
} 

const CompilaIDati = ({ title }) => (
  <section>
    {`Compila i dati riportati ${title} in tuo possesso. Completato l’inserimento dei dati in basso, potrai visualizzare il riepilogo e procedere con il pagamento.`}
  </section>
);

const PagamentoErogato = () => (
  <section className="color">
    Il servizio di pagamento è erogato dall’Istituto di pagamento Se.Pa.Fin.
    S.p.A. - Il costo dell’operazione è di 2,50€.
  </section>
);

const FrecciaHeader = () => (
  <React.Fragment>
    <CompilaIDati title="del Bollettino Freccia" />
    <PagamentoErogato />
  </React.Fragment>
);

const PagoPaHeader = () => (
  <React.Fragment>
    <CompilaIDati title="sul documento PagoPA " />
    <PagamentoErogato />
  </React.Fragment>
);
const MavRavHeader = () => (
  <React.Fragment>
    <CompilaIDati title="del Mav o Rav in" />
    <PagamentoErogato />
  </React.Fragment>
);

export const FooterByService = ({ service_id }) => {
  return (
    ![
      "SBOL003",
      "SBOL004",
      "SBOL006",
      "SBOL007",
      "BOL007",
      "SPPA001",
      "SPAGF24",
    ].includes(service_id) && (
      <div className="Bolletini-Info">
        {" "}
        <BolletinoFooter />
      </div>
    )
  );
};

export const HeaderByService = ({ service_id }) =>
  ["SBOL001", "SBOL003", "SBOL004", "SBOL006", "SBOL007", "SPPA001"].includes(
    service_id
  ) && (
    <div className="Bolletini-Info">
      {service_id === "SBOL003" || service_id === "SBOL004" ? (
        <MavRavHeader />
      ) : service_id === "SBOL006" ? (
        <BolletinoAutoHeader />
      ) : service_id === "SBOL007" ? (
        <FrecciaHeader />
      ) : service_id === "SPPA001" ? (
        <PagoPaHeader />
      ) : service_id === "SBOL001" ? (
        <BolletinoHeader />
      ) : (
        <BolletinoHeader />
      )}
    </div>
  );

export const PrivacyLinks = (props) => {
  const { informativo, privacy, setInformativo, setPrivacy } = props;
  return (
    <div className="privacy">
      <div>Dichiaro di aver preso visione e di accettare: </div>
      <LinkCheckBox
        title="Foglio informativo"
        visited={informativo}
        setVisited={setInformativo}
      />
      <LinkCheckBox
        title="Informativa sulla Privacy"
        visited={privacy}
        setVisited={setPrivacy}
      />
    </div>
  );
};

const LinkCheckBox = ({ title, href, visited, setVisited }) => {
  const toggle = () => setVisited(!visited);
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" onClick={toggle}>
      <span className={`link-checkbox ${visited ? "is-active" : ""}`}>
        {visited && <i className="fal fa-check"></i>}
      </span>
      <span>{title}</span>
    </a>
  );
};
