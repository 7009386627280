import React, { useState } from "react";
import { Input } from "antd";
import "./FloatingLabelInput.css";

const FloatingLabelInput = ({ label, value, onChange, type, ...restProps }) => {
  const [focused, setFocused] = useState(false);


  const handleBlur = (e) => {
    if (!e.target.value) {
      setFocused(false);
    }
  };

  return (
    <div className="custom-input-container">
      {type === "password" ? (
        <Input.Password
          className={`custom-input pass-input ${focused ? "focused" : ""}`}
          onFocus={() => setFocused(true)}
          onBlur={handleBlur}
          value={value} 
          onChange={onChange} 
          autoComplete="off"
          {...restProps}
        />
      ) : (
        <Input
          className={`custom-input ${focused ? "focused" : ""}`}
          onFocus={() => setFocused(true)}
          onBlur={handleBlur}
          value={value} 
          onChange={onChange} 
          autoComplete="off"
          {...restProps}
        />
      )}
      <div className={`floating-label ${focused || value ? "focused" : ""}`}>{label}</div>
    </div>
  );
};

export default FloatingLabelInput;
