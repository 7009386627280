import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import UseCode from "routes/views/UseCode"
import { MainActions, AuthActions } from "redux-store/models"
import { withRouter } from "react-router-dom"
import NewCategoryList from "./NewCategoryList"
import CompanyCards from "./CompanyCards"
import { Button } from "antd"
import CompaniesFavorite from "./CompaniesFavorite"
import CompaniesSearch from "./CompaniesSearch"
import axios from "axios"
import { endpoint } from "../../../config/api"

const DashboardDomNew = (props) => {
  const {
    getCodiceTicket,
    accountInfo,
    match,
    services,
    setServices,
    getCategoryServices,
    getFavorites,
    subCategories,
    setSubCategories,
    favorites,
  } = props

  const [hasVPT, setHasVPT] = useState(false)
  const [menuClassName, setMenuClassName] = useState("notFixed")
  const [categoryActive, setCategoryActive] = useState("")
  const [activeSubCategory, setActiveSubCategory] = useState(null)
  const [searchValue, setSearchValue] = useState("")

  const id = match.params.id


  const setFixedMenu = () => {
    const catInst = document.querySelector(".Dashboard > .Categories")
    if (catInst) {
      catInst.style.height =
        catInst.getBoundingClientRect().height -
        (catInst.getBoundingClientRect().height +
          186 -
          document.querySelector("footer").getBoundingClientRect().y) +
        "px"
    }

    const scrollPoint = document
      .querySelector("#SpecStatistich")
      ?.classList?.contains("min")
      ? 386
      : 486
    const top = window.scrollY || document.documentElement.scrollTop
    const newMenuClassName = top >= scrollPoint ? "fixed" : "notFixed"

    if (menuClassName !== newMenuClassName) {
      setMenuClassName(newMenuClassName)
    }
  }

  const handleScroll = () => {
    if (accountInfo?.token && match.params.id !== "caffe_mamanero") {
      setFixedMenu()
    }
  }

  const ChangeCompanies = (CategoryType) => {
    setCategoryActive(CategoryType)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [accountInfo, match])

  useEffect(() => {
    setServices(null)
    setSubCategories(null)
    setCategoryActive("")
  }, [id])

  useEffect(() => {
    getFavorites()
  }, [])

  useEffect(() => {
    if (searchValue.length === 0) {
      if (subCategories && subCategories.length > 0) {
        const firstSubCategory = subCategories[0]
        setActiveSubCategory(firstSubCategory.id)
        getCategoryServices(firstSubCategory.id)
      }
    }
  }, [subCategories, searchValue])

  const handleSubCategoryClick = (sub) => {
    setActiveSubCategory(sub.id)
    getCategoryServices(sub.id)
  }

  const onChange = (e) => {
    setSearchValue(e.target.value)
  }

  const filterCompanies = () => {
    const token = accountInfo?.token

    axios
      .get(`${endpoint}/category/companies/search?query=${searchValue}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => setServices(res.data))
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    if (searchValue.length > 0) {
      filterCompanies()
    } else {
      if (categoryActive) {
        getCategoryServices(categoryActive)
      }
    }
  }, [searchValue])

  return (
    <div className="DContainer maxWidth">
      {hasVPT && (
        <div
          className="backDrop"
          onClick={() => {
            setHasVPT(false)
          }}
        >
          <UseCode
            getCodiceTicket={getCodiceTicket}
            paymentNotFinished={true}
            prenotationType="PrenotedBollettini"
          />
        </div>
      )}
      <div className={`Dashboard ${menuClassName}`}>
        <NewCategoryList
          menuClassName={menuClassName}
          activeCategory={categoryActive}
          onClickCategory={ChangeCompanies}
        />
        <div className={`CompaniesAndOther ${menuClassName}`}>
          <CompaniesFavorite favourites={favorites} />

          <div className="Comp">
            <CompaniesSearch onChange={onChange} />
            <div className="telefoniche-options">
              {subCategories &&
                subCategories.length > 0 &&
                subCategories.map((sub) => (
                  <Button
                    key={sub.id}
                    onClick={() => handleSubCategoryClick(sub)}
                    className={activeSubCategory === sub.id ? "active" : ""}
                  >
                    {sub.name}
                  </Button>
                ))}
            </div>
            <div className="Companies">
              {services ? (
                <CompanyCards companies={services} categoryActive={categoryActive} activeSubCategory={activeSubCategory}/>
              ) : (
                <p>No companies found for this category.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapsStateToProps = (state) => ({
  favorites: state.main.favorites,
  services: state.main.services,
  accountInfo: state.auth.accountInfo,
  scannedBarcode: state.auth.scannedBarcode,
  skinExtras: state.auth.skinExtras,
  subCategories: state.main.subCategories,
})

export default withRouter(
  connect(mapsStateToProps, { ...MainActions, ...AuthActions })(DashboardDomNew)
)
