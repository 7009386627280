import React, { useEffect, useState } from "react";
import { PagaButton, PagaPosButton, AnullaButton } from "./PrintTicketSerap";

const ConfirmPaymentModal = (props) => {
  const [bodyStyle, setBodyStyle] = useState({ overflowY: "auto" });

  useEffect(() => {
    props.toggleBodyClass && document.body.classList.add(`sepa-ticket`);
    return () => {
      props.toggleBodyClass && document.body.classList.remove(`sepa-ticket`);
    };
  }, []);

  const { pay, payPos, cancel, importo, commissione, totale , accountInfo } = props;

  return (
    <React.Fragment>
      <div className="body" style={bodyStyle}>
        <div className="pagoPaConfirm">
          <div className="Info">
            <InfoPart title="Importo:" value={importo} />
            <InfoPart title="Commissione:" value={commissione} />
            <InfoPart title="Totale:" value={totale || parseFloat(importo) + parseFloat(commissione)} />
          </div>
          <p>Confermi ed esegui il pagamento</p>
        </div>
      </div>
      <div className="Bolletini new">
        <div className="Actions">
          <PagaButton onClick={pay} />
          {accountInfo.profile.role.name !== "user" && <PagaPosButton onClick={payPos} />}
          <AnullaButton onClick={cancel} />
        </div>
      </div>
    </React.Fragment>
  );
};

const InfoPart = (props) => {
  const { title, value } = props;
  return (
    <div>
      <span>{title}</span>
      <span>
        {parseFloat(value).toLocaleString("it-IT", {
          minimumFractionDigits: 2,
        }) + "€"}
      </span>
    </div>
  );
};

export default ConfirmPaymentModal;
