import { Form, Select, DatePicker, Row, Col, Checkbox } from "antd"
import React, { useEffect } from "react"
import FloatingLabelInput from "../../FloatingLabelInput/FloatingLabelInput"
import moment from "moment"
import { docType } from "../../../config"

const { Option } = Select

function Step3({ form, formData, setFormData }) {
  const { getFieldDecorator, getFieldValue } = form

  const handleInputChange = (field) => (e) => {
    e.persist()
    setFormData((prevState) => ({
      ...prevState,
      [field]: e.target.value,
    }))
  }

  useEffect(() => {
    const { birthday, data_di_rilascio } = formData

    if (
      birthday &&
      data_di_rilascio &&
      moment.isMoment(birthday) &&
      moment.isMoment(data_di_rilascio)
    ) {
      const day = birthday.date()
      const month = birthday.month() + 1

      const releaseDate = moment(data_di_rilascio)

      const expiryYear = releaseDate.year() + 10

      const expiryDate = moment().set({
        date: day,
        month: month - 1,
        year: expiryYear,
      })

      setFormData((prevState) => ({
        ...prevState,
        data_di_scadenza: expiryDate,
      }))
    }
  }, [formData.birthday, formData.data_di_rilascio, setFormData])

  return (
    <>

      <Form.Item hasFeedback>
        {getFieldDecorator("nickname", {
          initialValue: formData.nickname,
          rules: [
            {
              required: true,
              message: "Inserire un nome utente valido!",
            },
            {
              validator: (rule, value, callback) => {
                if (value && value.length >= 4) {
                  callback()
                } else {
                  callback("Username è corto!")
                }
              },
            },
          ],
        })(
          <FloatingLabelInput
            label="Username"
            onChange={handleInputChange("nickname")}
          />
        )}
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item hasFeedback>
            {getFieldDecorator("password", {
              initialValue: formData.password,
              rules: [
                {
                  required: true,
                  message: "Inserire password!",
                },
              ],
            })(
              <FloatingLabelInput
                type="password"
                label="Password"
                onChange={handleInputChange("password")}
              />
            )}
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item hasFeedback>
            {getFieldDecorator("confirm_password", {
              initialValue: formData.confirm_password,
              rules: [
                {
                  required: true,
                  message: "Confermare la password!",
                },
                {
                  validator: (rule, value, callback) => {
                    if (value === getFieldValue("password")) {
                      callback()
                    } else {
                      callback("Conferma password errata!")
                    }
                  },
                },
              ],
            })(
              <FloatingLabelInput
                type="password"
                label="Conferma Password"
                onChange={handleInputChange("confirm_password")}
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Form.Item hasFeedback>
        {getFieldDecorator("number", {
          initialValue: formData.number,
          rules: [{ required: true, message: "Inserire numero di telefono!" }],
        })(
          <FloatingLabelInput
            label="Cellulare"
            onChange={(e) => {
              handleInputChange("number")(e)
            }}
          />
        )}
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item>
            {getFieldDecorator("identity_type", {
              initialValue: formData.identity_type,
              rules: [
                {
                  required: true,
                  message: "Inserire tipo di documenti!",
                },
              ],
            })(
              <Select
                onChange={(e) => {
                  form.validateFields(["identity_id"])
                  setFormData((prevState) => ({
                    ...prevState,
                    identity_type: e,
                  }))
                }}
                placeholder="Tipo documento"
              >
                {docType.map((doc) => (
                  <Option key={doc.id} value={doc.id}>
                    {doc.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item hasFeedback>
            {getFieldDecorator("identity_id", {
              initialValue: formData.identity_id,
              rules: [
                {
                  required: true,
                  message: "Inserire il numero di documento!",
                  whitespace: true,
                },
                {
                  pattern:
                    getFieldValue("identity_type") === "1"
                      ? /^[A-Z]{2}\d{5}[A-Z]{2}$|^[A-Z]{2}\d{7}$/ // Carta d'identità
                      : getFieldValue("identity_type") === "2"
                      ? /^[A-Z]{2}\d{7}$/ // Patente di guida
                      : /^[A-Z]{2}\d{5}[A-Z]{2}$|^[A-Z]{2}\d{7}$/, // Passaporto
                  message: "Inserisci un documento valido",
                },
              ],
            })(
              <FloatingLabelInput
                label="Numero documento"
                onChange={handleInputChange("identity_id")}
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Form.Item hasFeedback>
        {getFieldDecorator("rilasciato_da", {
          initialValue: formData.rilasciato_da,
          rules: [
            {
              required: true,
              whitespace: true,
            },
          ],
        })(
          <Select
            placeholder="Rilasciato da"
            onChange={(e) => {
              setFormData((prevState) => ({
                ...prevState,
                rilasciato_da: e,
              }))
            }}
          >
            <Option value="1">Comune</Option>
            <Option value="10">Motorizzazione</Option>
            <Option value="13">Questura</Option>
            <Option value="14">Polizia</Option>
            <Option value="16">Commissariato</Option>
            <Option value="19">Altro</Option>
          </Select>
        )}
      </Form.Item>

      <Form.Item hasFeedback style={{ marginTop: 16 }}>
        {getFieldDecorator("luogo_di_rilascio", {
          initialValue: formData.luogo_di_rilascio,
          rules: [
            {
              required: true,
              whitespace: true,
              message: "Inserire il luogo di rilascio",
            },
          ],
        })(
          <FloatingLabelInput
            label="Luogo di rilascio"
            onChange={handleInputChange("luogo_di_rilascio")}
          />
        )}
      </Form.Item>

      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={12}>
          <Form.Item hasFeedback>
            {getFieldDecorator("data_di_rilascio", {
              initialValue: formData.data_di_rilascio,
              rules: [
                {
                  required: true,
                  message: "Inserisci una data valida!",
                },
              ],
            })(
              <DatePicker
                format={"DD/MM/YYYY"}
                placeholder="Data di rilascio"
                onChange={(date) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    data_di_rilascio: date,
                  }))
                }
              />
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item hasFeedback>
            {getFieldDecorator("data_di_scadenza", {
              initialValue: formData.data_di_scadenza,
              rules: [
                {
                  required: true,
                  message: "Inserisci una data valida!",
                },
              ],
            })(
              <DatePicker
                format={"DD/MM/YYYY"}
                placeholder="Data di scadenza"
                onChange={(date) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    data_di_scadenza: date,
                  }))
                }
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        {getFieldDecorator("privacy_policy", {
          initialValue: formData.privacy_policy,
        })(
          <Checkbox
            className="checkbox-privacy"
            style={{ marginBottom: 10 }}
            checked={formData.privacy_policy}
            onChange={(e) => {
              setFormData((prevState) => ({
                ...prevState,
                privacy_policy: e.target.checked,
              }))
            }}
          >
            Dichiaro di aver letto e accettato l'
            <a
              href="/informativa-trattamento-dati-personali.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              Informativa sulla Privacy
            </a>{" "}
            e i
            <a
              href="/termini-e-condizioni.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              {" "}
              Termini e Condizioni
            </a>{" "}
            del servizio, e acconsento al trattamento dei miei dati personali per
            l'erogazione del servizio in conformità al GDPR.
          </Checkbox>
        )}
      </Form.Item>

      <Form.Item>
        {getFieldDecorator("recieve_emails", {
          initialValue: formData.recieve_emails,
        })(
          <Checkbox
            checked={formData.recieve_emails}
            onChange={(e) => {
              setFormData((prevState) => ({
                ...prevState,
                recieve_emails: e.target.checked,
              }))
            }}
          >
            Desidero ricevere comunicazioni commerciali e promozionali.
          </Checkbox>
        )}
      </Form.Item>
    </>
  )
}

export default Step3
