import React from "react"
import "./styles.css"
import { connect } from "react-redux"
import { AuthActions, MainActions } from "redux-store/models"
import { Icon, Steps } from "antd"

const { Step } = Steps;
class AdminLoginDom extends React.Component {
  state = {
    current: this.props.addEditSkin.stepNumber
  }

  onChange = current => {
    const { addEditSkinDetails , addEditSkin} = this.props
    if( (JSON.stringify(addEditSkin.step2) !== '{}' && current == 2) || (addEditSkin.step3 && current == 3) || current == 1){
    addEditSkinDetails({
      step1: {
        ...(addEditSkin?.step1 ? addEditSkin.step1 : {}),
      },
      step2: {
        ...(addEditSkin?.step2 ? addEditSkin.step2 : {}),
      },
      step3:{
        ...(addEditSkin?.step3 ? addEditSkin.step3 : {}),
      },
      skinId: addEditSkin?.newSkinId,
      skinName: addEditSkin?.skinName,
      skinPannel: true,
      stepNumber: current,
    })
    this.setState({current:current})
  }
  }

  componentDidUpdate(prevProps){
    const { addEditSkin} = this.props
    if(prevProps.addEditSkin.stepNumber != addEditSkin.stepNumber){
      this.setState({current:addEditSkin.stepNumber})
    }
  }

  render() {
    const { component, addEditSkin, screenWidth } = this.props
    const { current } = this.state;

    return (
      <div className="AdminLogin">
        <div className="AdminLogin--Part--Blue">
        {addEditSkin?.skinPannel === false || addEditSkin?.stepNumber === 0 ?
          <span
            className="title" >
          Tutti Skin
          </span>
          :
          <Steps labelPlacement="vertical" current={current} initial={1} 
          // onChange={this.onChange}
           className="Stepper">
          <Step title="STEP 1" description={screenWidth < 700 ? "Creare Sito" : "Creare Sito Servizi"} icon={current == 1 ? <i className ="fas fa-check"></i> : <i className ="fas fa-sliders-v"></i>} />
          <Step title="STEP 2" description={screenWidth < 700 ? "Finanziario" : "Informazioni Finanziario"} icon={current == 2 ? <i className ="fas fa-check"></i> :  <i className ="fas fa-landmark"></i>}/>
          <Step title="STEP 3" description={screenWidth < 700 ? "Administratore" : "Creare Administratore"} icon={current == 3 ? <i className ="fas fa-check"></i> :  <i className ="fas fa-user"></i>}/>
        </Steps>
        }
        </div>
        <div className="AdminLogin--Part"></div>
        <div className="AdminLogin--LoginForm--Back">{component}</div>
      </div>
    )
  }
}
const mapsStateToProps = (state) => {
  return {
    addEditSkin: state.auth.addEditSkin,
    screenWidth: state.main.screenWidth
  }
}

export default connect(mapsStateToProps, { ...AuthActions, ...MainActions })(
  AdminLoginDom
)
