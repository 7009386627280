import React from "react"
class OtpInput extends React.Component {
  render() {
    return (
      <form>
        <div className="otpContainer">
          <input
            name="otp1"
            type="text"
            autoComplete="off"
            className="otpInput"
            onChange={(e) => this.props.handleChange(e)}
            onFocus={() => this.props.handleFocus(0)}
            onKeyUp={(e) => this.props.inputFocus(e)}
            tabIndex="1"
            maxLength="1"
          />
          <input
            name="otp2"
            type="text"
            autoComplete="off"
            className="otpInput"
            onChange={(e) => this.props.handleChange(e)}
            onFocus={() => this.props.handleFocus(1)}
            onKeyUp={(e) => this.props.inputFocus(e)}
            tabIndex="2"
            maxLength="1"
          />
          <input
            name="otp3"
            type="text"
            autoComplete="off"
            className="otpInput"
            onChange={(e) => this.props.handleChange(e)}
            onFocus={() => this.props.handleFocus(2)}
            onKeyUp={(e) => this.props.inputFocus(e)}
            tabIndex="3"
            maxLength="1"
          />
          <input
            name="otp4"
            type="text"
            autoComplete="off"
            className="otpInput"
            onChange={(e) => this.props.handleChange(e)}
            onFocus={() => this.props.handleFocus(3)}
            onKeyUp={(e) => this.props.inputFocus(e)}
            tabIndex="4"
            maxLength="1"
          />
          <input
            name="otp5"
            type="text"
            autoComplete="off"
            className="otpInput"
            onChange={(e) => this.props.handleChange(e)}
            onFocus={() => this.props.handleFocus(4)}
            onKeyUp={(e) => this.props.inputFocus(e)}
            tabIndex="5"
            maxLength="1"
          />
          <input
            name="otp6"
            type="text"
            autoComplete="off"
            className="otpInput"
            onChange={(e) => this.props.handleChange(e)}
            onFocus={() => this.props.handleFocus(5)}
            onKeyUp={(e) => this.props.inputFocus(e)}
            tabIndex="6"
            maxLength="1"
          />
        </div>
      </form>
    )
  }
}

export default OtpInput
