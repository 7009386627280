import React, { Component } from "react"
import { Azioni, Header } from "shared-components"
import SpedizioniForm from "../domains/Spedizioni/SpedizioniForm"
export class Spedizioni extends Component {
  render() {
    return (
      <div className="Spedizioni">
        <Header></Header>
        <Azioni activeMain="dashboard" active="spedizioni"></Azioni>
        <SpedizioniForm />
      </div>
    )
  }
}

export default Spedizioni
