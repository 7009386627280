import React from "react"
import { useParams } from "react-router-dom"
import EditServiceForm from "../../shared-components/adminSharedComp/EditServiceForm/EditServiceForm"

const EditServiceAdmin = () => {
  const { id } = useParams()

  return (
    <div style={{ padding: 20, width: "100%" }}>
      <EditServiceForm id={id} />
    </div>
  )
}

export default EditServiceAdmin
