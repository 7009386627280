import React from "react"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import { withRouter } from "react-router-dom"
import images from "themes/images"
import { Button, Form, notification, Spin } from "antd"
import SpedizioniRow from "./SpedizioniRow"
import PrintTicket from "../../../shared-components/ModulePopUp/PrintTicket"

class SpedizioniStep2 extends React.Component {
  state = {
    formData: {
      nominativoDestinatario: "",
      pressoDestinatario: "",
      capDestinatario: "",
      indirizzoDestinatario: "",
      emailDestinatario: "",
      telefonoDestinatario: "",
      noteDestinatario: "",
      nominativoMittente: "",
      pressoMittente: "",
      capMittente: "",
      indirizzoMittente: "",
      emailMittente: "",
      telefonoMittente: "",
      destinatarioIsBusiness: false,
      packages: this.props.packages,
    },
    showTicket: false,
  }

  handleAddRow = () => {
    const { formData } = this.state
    const packages = [...formData.packages]
    packages.push({
      id: packages.length + 1,
      pesoReale: null,
      altezza: null,
      larghezza: null,
      profondita: null,
    })
    this.setState({
      formData: {
        ...formData,
        packages,
      },
    })
  }

  handleRemoveRow = (id) => {
    const { formData } = this.state
    const packages = formData.packages.filter((pckg) => pckg.id !== id)
    this.setState({
      formData: {
        ...formData,
        packages,
      },
    })
  }

  handleChange = (event) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [event.target.name]: event.target.value,
      },
    })
  }

  handlePackagesArrayChange = (index, key, event) => {
    const packages = [...this.state.formData.packages]
    packages[index][key] = event.target.value
    this.setState({ packages })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const {
      nominativoDestinatario,
      pressoDestinatario,
      capDestinatario,
      indirizzoDestinatario,
      emailDestinatario,
      telefonoDestinatario,
      noteDestinatario,
      nominativoMittente,
      pressoMittente,
      capMittente,
      indirizzoMittente,
      emailMittente,
      telefonoMittente,
      destinatarioIsBusiness,
      packages,
    } = this.state.formData

    const { couriers, setShippingInfo, shipping_id } = this.props

    const selectedCourier = couriers.find((obj) => obj.shipping_id == shipping_id)

    const isPackageArrayValid = packages.every((obj) => {
      return Object.values(obj).every((value) => value)
    })

    if (
      !nominativoDestinatario ||
      !pressoDestinatario ||
      !capDestinatario ||
      !indirizzoDestinatario ||
      !emailDestinatario ||
      !telefonoDestinatario ||
      !nominativoMittente ||
      !pressoMittente ||
      !capMittente ||
      !indirizzoMittente ||
      !emailMittente ||
      !telefonoMittente ||
      !isPackageArrayValid
    ) {
      notification["error"]({
        message: "Errore di convalida",
        description: "Si prega di compilare tutti i campi richiesti.",
      })
      return
    }

    const packages_details = packages.map((obj) => {
      const { id, ...rest } = obj
      return rest
    })

    setShippingInfo(
      "SPEDPRO001",
      shipping_id.toString(),
      nominativoMittente,
      pressoMittente,
      indirizzoMittente,
      selectedCourier.comuneMittente,
      selectedCourier.nazioneMittente.sigla,
      capMittente,
      telefonoMittente,
      emailMittente,
      nominativoDestinatario,
      pressoDestinatario,
      indirizzoDestinatario,
      selectedCourier.comuneDestinatario,
      selectedCourier.nazioneDestinatario.sigla,
      capDestinatario,
      telefonoDestinatario,
      emailDestinatario,
      noteDestinatario,
      destinatarioIsBusiness,
      packages_details
    )
  }

  handleBuyOrder = () => {
    const { shippingData, buyShippingOrder } = this.props
    buyShippingOrder("SPEDPRO001", shippingData.id.toString())
  }

  showTicket = () => {
    this.setState({ showTicket: true })
  }

  componentDidMount = () => {
    const { userConfigura, shipping_id, couriers } = this.props
    const selectedCourier = couriers.find((obj) => obj.shipping_id == shipping_id)
    this.setState({
      formData: {
        ...this.state.formData,
        capDestinatario: selectedCourier?.capDestinatario,
        nominativoMittente: userConfigura.first_name,
        pressoMittente: userConfigura.last_name,
        capMittente: userConfigura.cap,
        indirizzoMittente: userConfigura.address,
        telefonoMittente: userConfigura.phone,
        emailMittente: userConfigura.email,
      },
    })
  }

  componentDidUpdate(prevProps) {
    const { shippingOrder } = this.props
    if (
      Object.keys(shippingOrder || {}).length > 0 &&
      shippingOrder !== prevProps.shippingOrder
    ) {
      this.showTicket()
    }
  }

  render() {
    const {
      accountInfo,
      couriers,
      shipping_id,
      shippingData,
      loading,
      shippingOrder,
    } = this.props
    const {
      nominativoDestinatario,
      pressoDestinatario,
      capDestinatario,
      indirizzoDestinatario,
      emailDestinatario,
      telefonoDestinatario,
      noteDestinatario,
      packages,
      nominativoMittente,
      pressoMittente,
      capMittente,
      indirizzoMittente,
      emailMittente,
      telefonoMittente,
    } = this.state.formData
    const { showTicket } = this.state
    const selectedCourier = couriers.find((obj) => obj.shipping_id == shipping_id)

    return (
      <>
        <Form>
          {Object.keys(shippingOrder || {}).length > 0 && showTicket && (
            <PrintTicket arr={shippingOrder} />
          )}
          <div className="formsContainer">
            <div className="formsContainer--body animated fadeIn Spedizione">
              <div className="rightForm">
                <div className="rightForm--header">
                  <div className="TitleBack">
                    <i
                      className="fal fa-chevron-left Arrow"
                      onClick={
                        Object.keys(shippingData).length == 0
                          ? this.props.previousStep
                          : undefined
                      }
                    ></i>
                    Spedizioni
                  </div>
                </div>
                <div className="rightForm--left">
                  <div className="formsContainer--body__item title">
                    <div className="title--left">
                      <i className="fas fa-dolly-empty"></i>
                      DESTINATARIO
                    </div>
                  </div>
                  <div className="formsContainer--body__item">
                    <div className="label">
                      Nominativo <span className="Red">*</span>
                    </div>
                    <input
                      placeholder="Nome del destinatario"
                      type="text"
                      name="nominativoDestinatario"
                      value={nominativoDestinatario}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="formsContainer--body__item">
                    <div className="label">
                      Presso - Ditta - C/O <span className="Red">*</span>
                    </div>
                    <input
                      placeholder="Indicare presso chi consegnare (se necessario)"
                      type="text"
                      name="pressoDestinatario"
                      value={pressoDestinatario}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="formsContainer--body__semiCont mt-2 cap-citta">
                    <div className="formsContainer--body__item semi">
                      <div className="label">
                        Cap - Città <span className="Red">*</span>
                      </div>
                      <input
                        type="text"
                        placeholder="Cap - Città"
                        name="capDestinatario"
                        value={capDestinatario}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="formsContainer--body__item semi">
                      <div className="label">
                        Indirizzo <span className="Red">*</span>
                      </div>
                      <input
                        type="text"
                        placeholder="Indirizzo"
                        name="indirizzoDestinatario"
                        value={indirizzoDestinatario}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="formsContainer--body__semiCont mt-2">
                    <div className="formsContainer--body__item semi">
                      <div className="label">
                        E-mail <span className="Red">*</span>
                      </div>
                      <input
                        type="text"
                        placeholder="E-mail del destinatario"
                        name="emailDestinatario"
                        value={emailDestinatario}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="formsContainer--body__item semi">
                      <div className="label">
                        Telefono <span className="Red">*</span>
                      </div>
                      <input
                        type="text"
                        placeholder="Telefono del destinatario"
                        name="telefonoDestinatario"
                        value={telefonoDestinatario}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="formsContainer--body__item">
                    <div className="label">Note</div>
                    <input
                      placeholder="Eventuali note sulla spedizione"
                      type="text"
                      name="noteDestinatario"
                      value={noteDestinatario}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="rightForm--right">
                  <div className="formsContainer--body__item title">
                    <div className="title--right">
                      <i className="fas fa-dolly"></i>
                      MITTENTE
                    </div>
                    <div className="title--user">
                      <i className="fas fa-star"></i>
                      <span>{accountInfo?.profile?.name}</span>
                      <i className="fas fa-store"></i>
                    </div>
                  </div>
                  <div className="formsContainer--body__semiCont mt-2">
                    <div className="formsContainer--body__item semi">
                      <div className="label">
                        Nominativo <span className="Red">*</span>
                      </div>
                      <input
                        type="text"
                        placeholder="Nome del mitente"
                        name="nominativoMittente"
                        value={nominativoMittente}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="formsContainer--body__item semi user">
                      <input
                        type="text"
                        value={accountInfo?.profile?.name}
                        readOnly
                      />
                      <i className="fal fa-address-book inputI"></i>
                    </div>
                  </div>
                  <div className="formsContainer--body__item">
                    <div className="label">
                      Presso - Ditta - C/O <span className="Red">*</span>
                    </div>
                    <input
                      placeholder="Indicare presso chi ritirare (se necessario)"
                      type="text"
                      name="pressoMittente"
                      value={pressoMittente}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="formsContainer--body__semiCont mt-2 cap-citta">
                    <div className="formsContainer--body__item semi">
                      <div className="label">
                        Cap - Città <span className="Red">*</span>
                      </div>
                      <input
                        type="text"
                        placeholder="Cap - Città"
                        name="capMittente"
                        value={capMittente}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="formsContainer--body__item semi">
                      <div className="label">
                        Indirizzo <span className="Red">*</span>
                      </div>
                      <input
                        type="text"
                        placeholder="Indirizzo"
                        name="indirizzoMittente"
                        value={indirizzoMittente}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="formsContainer--body__semiCont mt-2">
                    <div className="formsContainer--body__item semi">
                      <div className="label">
                        E-mail <span className="Red">*</span>
                      </div>
                      <input
                        type="text"
                        placeholder="E-mail del mittente"
                        name="emailMittente"
                        value={emailMittente}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="formsContainer--body__item semi">
                      <div className="label">
                        Telefono <span className="Red">*</span>
                      </div>
                      <input
                        type="text"
                        placeholder="Telefono del mittente"
                        name="telefonoMittente"
                        value={telefonoMittente}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="rightForm--footer">
                  <div className="title">SPEDIZIONE</div>
                  <div className="information">
                    <i className="fas fa-exclamation-triangle"></i>
                    Dichiarare pesi e/o dimensioni inferiori a quelli reali potrebbe
                    causare ritardi nella gestione della spedizione e addebiti sul
                    costo finale
                  </div>
                  <div className="addrows">
                    {packages.map((pckg, index) => (
                      <SpedizioniRow
                        id={pckg.id}
                        key={pckg.id}
                        handleAddRow={this.handleAddRow}
                        defaultValuePeso={pckg.pesoReale}
                        defaultValueAltezza={pckg.altezza}
                        defaultValueLarghezza={pckg.larghezza}
                        defaultValueProfondita={pckg.profondita}
                        handleRemoveRow={() => this.handleRemoveRow(pckg.id)}
                        handleChangePeso={this.handlePackagesArrayChange.bind(
                          this,
                          index,
                          "pesoReale"
                        )}
                        handleChangeAltezza={this.handlePackagesArrayChange.bind(
                          this,
                          index,
                          "altezza"
                        )}
                        handleChangeLarghezza={this.handlePackagesArrayChange.bind(
                          this,
                          index,
                          "larghezza"
                        )}
                        handleChangeProfondita={this.handlePackagesArrayChange.bind(
                          this,
                          index,
                          "profondita"
                        )}
                      />
                    ))}
                    <div className="submit-btn">
                      <Button type="primary" onClick={this.handleSubmit}>
                        Concludi Ordine
                      </Button>
                    </div>
                  </div>
                  {loading ? (
                    <div className="tariffa-row loading">
                      <Spin tip="Loading..." size="large" />
                    </div>
                  ) : (
                    <div className="tariffa-row">
                      <div className="tariffa selected">
                        <div className="tariffa--img">
                          <img
                            src={images[selectedCourier?.corriere]}
                            alt={selectedCourier?.corriere}
                          />
                          <span>{selectedCourier?.servizio}</span>
                        </div>

                        <div className="formsContainer--body__semiCont mt-2">
                          <div className="formsContainer--body__item semi">
                            <div className="label">Tariffa</div>
                            <input
                              type="text"
                              defaultValue={
                                shippingData?.tariffa
                                  ? (shippingData?.tariffa / 1.22).toFixed(2) + " €"
                                  : (selectedCourier?.tariffa / 1.22).toFixed(2) +
                                    " €"
                              }
                              readOnly
                            />
                          </div>
                          <div className="formsContainer--body__item semi">
                            <div className="label">Iva</div>
                            <input
                              type="text"
                              defaultValue={
                                shippingData?.tariffa
                                  ? ((0.22 * shippingData?.tariffa) / 1.22).toFixed(
                                      2
                                    ) + " €"
                                  : (
                                      (0.22 * selectedCourier?.tariffa) /
                                      1.22
                                    ).toFixed(2) + " €"
                              }
                              readOnly
                            />
                          </div>
                          <div className="formsContainer--body__item semi">
                            <div className="label">Totale</div>
                            <input
                              type="text"
                              className="totale"
                              defaultValue={
                                shippingData?.tariffa
                                  ? shippingData?.tariffa + " €"
                                  : selectedCourier?.tariffa + " €"
                              }
                              readOnly
                            />
                          </div>
                          <div className="formsContainer--body__item semi">
                            <Button
                              className={
                                Object.keys(shippingData).length === 0
                                  ? "buy-disabled"
                                  : ""
                              }
                              disabled={Object.keys(shippingData).length === 0}
                              type="primary"
                              onClick={this.handleBuyOrder}
                            >
                              Acquista
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-start" }} className="next">
            <Button
              type="primary"
              onClick={this.props.previousStep}
              disabled={Object.keys(shippingData).length !== 0}
            >
              Previous
            </Button>
          </div>
        </Form>
      </>
    )
  }
}

const mapsStateToProps = (state) => ({
  accountInfo: state.auth.accountInfo,
  couriers: state.auth.couriers.shipping_options,
  shipping_id: state.auth.selectedShippingId,
  packages: state.auth.packagesDetails,
  userConfigura: state.auth.usersConfigura,
  shippingData: state.auth.shippingData,
  loading: state.auth.couriersLoading,
  shippingOrder: state.auth.shippingOrder,
})

export default withRouter(
  connect(mapsStateToProps, { ...MainActions, ...AuthActions })(SpedizioniStep2)
)
