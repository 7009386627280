import React, { Fragment } from "react"
import "./adminServizi.css"
import AdminServiziItem from "./AdminServiziItem"
import AuthAction from "redux-store/models/auth"
import MainAction from "redux-store/models/main"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import AdminSubServizzi from "../AdminSubServizzi/AdminSubServizzi"
import { Select } from "antd"
const { Option } = Select
class AdminServizi extends React.Component {
  state = { activeCategory: -1, filterName: "", selectedSupplier: "E-Pay" }
  componentDidMount() {
    const { activeSkinId, setActiveSkinId, getAllServices } = this.props
    if (activeSkinId === -1) {
      setActiveSkinId(1)
      getAllServices(1, this.state.selectedSupplier)
    } else {
      getAllServices(activeSkinId, this.state.selectedSupplier)
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.activeSkinId === -1) {
      await this.props.setActiveSkinId(1)
    }
    if (this.props.activeSkinId !== prevProps.activeSkinId) {
      await this.props.getAllServices(
        this.props.activeSkinId === -1 ? 1 : this.props.activeSkinId, this.state.selectedSupplier
      )
    }
  }

  getServices = (id) =>
    (this.props.allServices?.services || []).filter(
      ({ company_id }) => company_id === Number(id)
    )

  handleFilterChange = (event) => {
    this.setState({ filterName: event.target.value })
  }

  render() {
    const id = this.props.match.params.id

    const { allServices, servicesLoader, activeSkinId, UpdateServicesChangeStatus } =
      this.props
    const { activeCategory, filterName } = this.state
    const filteredServices = (allServices?.companies || []).filter((itemList) => {
      const { full_name } = itemList
      return (
        (itemList.category_id === activeCategory || activeCategory === -1) &&
        full_name.toLowerCase().includes(filterName.toLowerCase())
      )
    })

    console.log(activeCategory)

    if (id)
      return (
        <AdminSubServizzi services={this.getServices(id)} serviceSelected={id} />
      )
    return (
      <Fragment>
        {this.props.MessaggiComponent}
        {servicesLoader === true ? (
          <div className="loaderAdmin">Loading...</div>
        ) : (
          <div className="d-flex flex-column w-100">
            <div className="AdminServiziCategories">
              <Select
                defaultValue={this.state.selectedSupplier}
                onChange={(value) => {
                  this.setState({ selectedSupplier: value })
                  this.props.getAllServices(activeSkinId, value)
                }}
              >
                <Option value="E-Pay">E-Pay</Option>
                <Option value="Sepafin">Sepafin</Option>
                <Option value="MrPay">MrPay</Option>
              </Select>
              <input
                className="search-service"
                placeholder="Cerca Servizio..."
                type="text"
                value={this.filterName}
                onChange={this.handleFilterChange}
              />
              <div
                onClick={() => this.setState({ activeCategory: -1 })}
                className={
                  "AdminServiziCategories__item" +
                  (activeCategory === -1 ? " active" : "")
                }
              >
                Tutti
              </div>
              {(allServices?.categories || []).map((category) => (
                <div
                  key={category.id}
                  className={
                    "AdminServiziCategories__item" +
                    (activeCategory === category.id ? " active" : "")
                  }
                  data-id={category.name}
                  onClick={() =>      
                    this.setState({ activeCategory: category.id })}
              
                  
                >
                  {category.full_name}
                </div>
              ))}
            </div>

            <div className="AdminServizi">
              {filteredServices.length > 0 ? (
                filteredServices.map((itemList, i) => (
                  <AdminServiziItem
                    {...itemList}
                    key={`${itemList.number_id}`}
                    UpdateServicesChangeStatus={UpdateServicesChangeStatus}
                    activeSkinId={activeSkinId}
                    getService={() => {
                      this.props.getAllServices(this.props.activeSkinId, this.state.selectedSupplier)
                    }}
                  />
                ))
              ) : (
                <div className="NoData">
                  <i className="fal fa-info-circle"></i>
                  <span>No Data</span>
                </div>
              )}
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}
const mapStateToProps = (state) => ({
  activeSkinId: state.main.activeSkinId,
  allServices: state.auth.allServices,
  servicesLoader: state.auth.servicesLoader,
  account: state.auth.account,
})
export default connect(mapStateToProps, { ...AuthAction, ...MainAction })(
  withRouter(AdminServizi)
)
