import React from "react"
import { baseUrlImg } from "../../config/api"

const PrintTicketBolletino = ({ arr }) => {
  const rowLabels = [
    "Valore",
    "Codice attivazione",
    "TerminalID",
    "Data",
    "Ora",
    "Trace-Nr.",
    "Ricevuta Nr.",
  ]

  return (
    <div className="pin-receipt">
      <div className="receipt-info">
        <span className="service-name">{arr.service_type}</span>
        <img
          src={`${baseUrlImg}/${arr.logo}`}
          className="service-logo"
          alt="service logo"
        />
        {rowLabels.map((label, index) => (
          <div key={index} className="receipt-row">
            {label}: <span> {JSON.parse(arr.receipt)[label]}</span>
          </div>
        ))}
        <div className="receipt-description">
          {JSON.parse(arr.receipt)["Description"]}
        </div>
        {JSON.parse(arr.receipt)["receiptLink"] && (
          <a href={JSON.parse(arr.receipt)["receiptLink"]} target="_blank">
            CLICA PER LA RICEVUTA
          </a>
        )}
      </div>
    </div>
  )
}

export default PrintTicketBolletino
