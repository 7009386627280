import React from "react";
import AllTranzacioni from "./AllTransazioni";
import AdminPanelDom from "../domains/adminPanel/adminPanelDom";
import { connect } from "react-redux";
import MainActions from "redux-store/models/main";

class AdminPanelListaUtenti extends React.Component {
  render() {
    return (
      <AdminPanelDom
      component={({MessaggiComponent})=>
          <AllTranzacioni MessaggiComponent={MessaggiComponent} />
        }
        {...this.props}
      />
    );
  }
}
const mapStatToProps = (state) => ({
  activeSkinId: state.main.activeSkinId,
});
export default connect(mapStatToProps, MainActions)(AdminPanelListaUtenti);
