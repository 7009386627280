import React from "react"
import AdminPanelDom from "../domains/adminPanel/adminPanelDom"
import Listino from "../domains/adminPanel/Listino/Listino"

class AdminPanelServizi extends React.Component {
  render() {
    return (
      <AdminPanelDom
        component={({ MessaggiComponent }) => <Listino />}
        {...this.props}
      />
    )
  }
}
export default AdminPanelServizi
