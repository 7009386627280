import React from "react";

const SliderComponent = ({ value, steps, handleSliderChange }) => {
  return (
    <div className="slider-container">
      {steps.map((step, index) => (
        <div
          key={index}
          className={
            value >= step && value < steps[index + 1]
              ? "step selected"
              : "step"
          }
        >
          {step}
        </div>
      ))}
      <input
        type="range"
        min={steps[0]}
        max={steps[steps.length - 1]}
        step={steps[1] - steps[0]}
        value={value}
        onChange={handleSliderChange}
        list="steplist"
        className="slider1"
        style={{
          background: `linear-gradient(to right,  rgb(9 237 108) 0%, #00B850 ${
            (100 * value) / steps[steps.length - 1]
          }%, #EFEFEF ${(100 * value) / steps[steps.length - 1]}%, #EFEFEF 100%)`,
        }}
      />
      <datalist id="steplist">
        {steps.map((step, index) => (
          <option key={index} value={step} />
        ))}
      </datalist>
    </div>
  );
};

export default SliderComponent;
