import React from "react"
import AdminPanelDom from "../domains/adminPanel/adminPanelDom"
import ProvidersAdmin from "./ProvidersAdmin"

class AdminPanelServizi extends React.Component {
  render() {
    return (
      <AdminPanelDom
        component={({ MessaggiComponent }) => (
          <ProvidersAdmin forAdmin={true} MessaggiComponent={MessaggiComponent} />
        )}
        {...this.props}
      />
    )
  }
}
export default AdminPanelServizi
