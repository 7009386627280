import React from "react";
import AdminPanelDom from "../domains/adminPanel/adminPanelDom";
import { connect } from "react-redux";
import Gallery from "./Gallery";

class AdminPanelGallery extends React.Component {
  render() {
    return (
      <AdminPanelDom
     component={({MessaggiComponent})=>
          <Gallery
            forAdmin={true} MessaggiComponent={MessaggiComponent}  onSelect={() => {}} showActions={true}
          />
        }
        {...this.props}
      />
    );
  }
}
const mapStatToProps = (state) => ({
  activeSkinId: state.main.activeSkinId,
});
export default connect(mapStatToProps, null)(AdminPanelGallery);