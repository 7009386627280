import { put, call, select } from "redux-saga/effects"
import MainActions from "../models/main"
import * as MainRequest from "services/main"
import { notification } from "antd"

export const getIsSepafin = (state) =>
  !!state.auth?.accountInfo?.profile?.hasSepafinCredentials

export function* getRegisterToken({ agency_id, callBack }) {
  const response = yield call(MainRequest.registerTokenReq, agency_id)
  callBack(response.data)
}
export function* sendPrenotazione({ objectData }, resetState) {
  const response = yield call(MainRequest.createPrenotazione, objectData)
  if (response.data) {
    if (resetState) {
      resetState()
    }
    //console.log("got response ", response, objectData);
    notification["success"]({
      message: response.data.message,
    })
  } else if (response.error) {
    notification["error"]({
      message: response.error.response.data.message,
      description: Object.values(response.error.response.data.errors),
    })
  }
}
export function* forgotPassword({ email }) {
  const response = yield call(MainRequest.forgotPasswordReq, email)

  if (response.data) {
    notification["success"]({
      message: response.data.message,
    })
  }
  // console.log("response", response.data, response.data.message);
}
export function* getServices() {
  // console.log("funx callllled");
  const response = yield call(MainRequest.fetchServices)
  const isSepafin = yield select(getIsSepafin)

  if (response.data) {
    let newServices = { ...(response.data?.all_services || {}) }
    if (
      newServices["SPRDPST"] &&
      newServices["SPRDPST"]["SMAVRAV"] &&
      newServices["SPRDPST"]["SMAVRAV"].services
    ) {
      newServices["SPRDPST"]["SMAVRAV"].services[0] = {
        ...newServices["SPRDPST"]["SMAVRAV"].services[0],
        name: "Mav-Rav",
      }
    }
    if (!isSepafin) {
      delete newServices["SPRDPST"]
    } else if ("PRDPST" in newServices && "SPRDPST" in newServices) {
      newServices["PRDPST"] = {
        ...newServices["PRDPST"],
        ...newServices["SPRDPST"],
      }
      newServices["PRDPST"].group = "pagamenti"
      newServices["PRDPST"].name = "Pagamenti"
      delete newServices["SPRDPST"]
    }
    for (const key in newServices) {
      const entry = newServices[key]
      newServices[key] = { ...entry }

      for (const nestedKey in entry) {
        if (entry[nestedKey] && entry[nestedKey].services) {
          newServices[key][nestedKey].services = entry[nestedKey].services.map(
            (service) => ({
              ...service,
              type: service.type,
            })
          )
        }
      }
    }
    yield put(MainActions.setServices(newServices))
  }
}

export function* getCategoryServices({ subCategoryId }) {
  const response = yield call(MainRequest.fetchCategoryServices, subCategoryId)
  yield put(MainActions.setServices(response.data))
}

export function* getCompanyServices({ companyId }) {
  const response = yield call(MainRequest.fetchCompanyServices, companyId)
  yield put(MainActions.setCompanyServices(response.data))
}

export function* getFavorites() {
  const response = yield call(MainRequest.fetchFavorites)
  if (response.data) {
    let favorites = { ...(response.data?.favorites || {}) }
    for (const key in favorites) {
      const entry = favorites[key]
      favorites[key] = { ...entry }

      for (const nestedKey in entry) {
        if (entry[nestedKey] && entry[nestedKey].services) {
          favorites[key][nestedKey].services = entry[nestedKey].services.map(
            (service) => ({
              ...service,
              type: service.type,
            })
          )
        }
      }
    }
    yield put(MainActions.setFavorites(favorites))
  }
}
export function* toggleFavorite(params) {
  const response = yield call(MainRequest.setOnFav, params.id, params.sType)
  // console.log("responseeee", response);
  if (response.status === 200) {
    const response = yield call(MainRequest.fetchFavorites)
    if (response.data) {
      yield put(MainActions.setFavorites(response.data.favorites))
      if (params.companyId) {
        yield call(getCompanyServices, { companyId: params.companyId })
      }
    }
  }
}
export function* getUsers(params) {
  //console.log("params", params);
  if (params.LOAD_FALSE) {
    //dont load
  } else {
    yield put(MainActions.setLoaderForAdminUtenti(true))
  }
  const response = yield call(
    MainRequest.fetchUsers,
    params.search_user,
    params.skin_id,
    params.backoffice,
    params.limit,
    params.page_number,
    params.username,
    params.ragione_sociale,
    params.city,
    params.user_id,
    params.credito,
    params.from_last_login,
    params.to_last_login
  )
  if (response && response.data) {
    yield put(
      MainActions.setUsers({
        users: response.data.users,
        total_pages: response.data.total_pages,
      })
    )

    yield put(MainActions.setLoaderForAdminUtenti(false))
  } else {
    yield put(MainActions.setLoaderForAdminUtenti(false))
  }
}

export function* getSkinUsers(params) {
  const response = yield call(MainRequest.fetchSkinUsers, params.skin_id)
  if (response && response.data) {
    yield put(
      MainActions.setSkinUsers({
        users: response.data.users,
      })
    )
  }
}

export function* getSearchedUsers({ search_user }) {
  yield put(MainActions.setLoaderForAdminUtenti(true))
  const response = yield call(MainRequest.fetchSearchedUsers, search_user)

  if (response && response.data) {
    yield put(
      MainActions.setUsers({
        users: response.data.users,
        total_pages: 1,
      })
    )
    yield put(MainActions.setLoaderForAdminUtenti(false))
  } else {
    yield put(MainActions.setLoaderForAdminUtenti(false))
  }
}

export function* getUsersSimple() {
  const response = yield call(MainRequest.fetchUsersSimple)
  // console.log("getUsers called", response);
  if (response.data) {
    yield put(MainActions.setUsersSimple(response.data.users))
  }
}
export function* getUsersBySearch(params) {
  const response = yield call(MainRequest.fetchUsersBySearch, params.search_user)
  if (response.data) {
    yield put(MainActions.setUsersBySearch(response.data.users))
  }
}

export function* getOverviewDashboard(data) {
  const response = yield call(MainRequest.updatateOverviewWidget, data.period)
  if (response.data) {
    yield put(MainActions.setOverviewDashboard(response.data.balance))
  }
}

export function* getUserPhotos({ id }) {
  yield put(MainActions.setPhotoLoading(true))
  const response = yield call(MainRequest.fetchphotos, id)
  if (response.data) {
    yield put(MainActions.setUserPhotos(response.data))
    yield put(MainActions.setPhotoLoading(false))
  }
}

export function* getSystemModes() {
  const response = yield call(MainRequest.fetchSystemModeList)
  if (response.data) {
    yield put(MainActions.setSystemModes(response.data.modes))
  }
}

export function* updateSystemMode(params) {
  const response = yield call(
    MainRequest.updateSystemMode,
    params.sysmode,
    params.password
  )
  if (response.status === 200) {
    notification["success"]({
      message: response?.data?.message,
    })
    const response1 = yield call(MainRequest.fetchSystemModeList)
    if (response1.data) {
      yield put(MainActions.setSystemModes(response1.data.modes))
    }
  }
}

export function* getSelectedCompany({ service_id }) {
  const response = yield call(MainRequest.fetchSelectedCompany, service_id)
  if (response.data) {
    yield put(MainActions.setSelectedCompany(response.data))
  } else {
    yield put(MainActions.setSelectedCompany(null))
  }
}

export function* getAllImages({ group }) {
  const response = yield call(MainRequest.fetchAllImages, group)
  if (response.data) {
    yield put(MainActions.setAllImages(response.data))
  }
}

export function* uploadNewImage({ fileList, group }) {
  const response = yield call(MainRequest.uploadNewImageReq, fileList, group)
  // if (response.data) {
  //   notification.open({ message: "Successfully added!" })
  // }
}

export function* updateImage({ id, name, group, image }) {
  yield call(MainRequest.updateImageReq, id, name, group, image)
}

export function* deleteImage({ id }) {
  yield call(MainRequest.deleteImageReq, id)
}

export function* updateCompany({
  id,
  logo,
  backgroundImage,
  icon,
  logoScontrino,
  colorBg,
  colorTesto,
  services,
  content,
  content_scontrino,
  is_percentage,
}) {
  const response = yield call(
    MainRequest.updateCompanyReq,
    id,
    logo,
    backgroundImage,
    icon,
    logoScontrino,
    colorBg,
    colorTesto,
    services,
    content,
    content_scontrino,
    is_percentage
  )
  if (response.data) {
    notification["success"]({
      message: "Servizio aggiornato con successo!",
    })
  } else if (response.error) {
    notification["error"]({
      message: "Error",
      description: "Si è verificato un errore durante l'aggiornamento del servizio",
    })
  }
}

export function* getServicesData() {
  const response = yield call(MainRequest.getServicesDataReq)
  if (response.data) {
    yield put(MainActions.setServicesData(response.data.services))
  }
}

export function* getServicesTree() {
  const response = yield call(MainRequest.getServicesTreeReq)
  if (response.data) {
    yield put(MainActions.setServicesTree(response.data))
  }
}

export function* changeServiceCategory({ companyId, categoryId }) {
  const response = yield call(
    MainRequest.changeServiceCategoryReq,
    companyId,
    categoryId
  )
  if (response.data) {
    notification["success"]({
      message: response.data.message,
    })
  }
}

export function* updateServiceName({ id, name, setLoading }) {
  if (setLoading) {
    setLoading(true)
  }
  const response = yield call(MainRequest.updateServiceNameReq, id, name)
  if (response.data) {
    notification["success"]({
      message: response.data.message,
    })
  }
  if (setLoading) {
    setLoading(false)
  }
}

export function* getProviders() {
  const response = yield call(MainRequest.getProvidersReq)

  if (response?.data && response?.status === 200) {
    yield put(MainActions.setProviders(response.data))
  }
}

export function* createProvider({
  name,
  categories,
  wallet,
  status,
  url,
  username,
  password,
  setLoading,
  closeModal,
}) {
  setLoading(true)

  const response = yield call(
    MainRequest.createProviderReq,
    name,
    categories,
    wallet,
    status,
    url,
    username,
    password
  )
  if (response.data) {
    notification["success"]({
      message: "Provider created successfully!",
    })
    closeModal()
    yield call(getProviders)
  }
  setLoading(false)
}

export function* updateProvider({
  name,
  categories,
  wallet,
  status,
  url,
  username,
  password,
  id,
  setLoading,
  closeModal,
}) {
  setLoading(true)

  const response = yield call(
    MainRequest.updateProviderReq,
    name,
    categories,
    wallet,
    status,
    url,
    username,
    password,
    id
  )
  if (response.data) {
    notification["success"]({
      message: "Provider updated successfully!",
    })
    closeModal()
    yield call(getProviders)
  }
  setLoading(false)
}
