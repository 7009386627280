import MyInput from "./Input";
import React from "react";
import images from "themes/images";
import {connect} from "react-redux";

const TelefonoInput = (props) => {
  const { set, value,skinExtras,nome, ...otherProps } = props;
  const handleChange = (e) => {
    set(e.target.value);
  };

  const rightTelefono = () => {
    const allowedStartVal = ["0039", "39"];
    if (allowedStartVal.some((val) => value.toString().startsWith(val))) return value;
    return "+39" + value;
  };
  const message=`Salve ${nome} vi contattiamo dal support ${skinExtras.name}`

  return (
    <div className="position-relative">
      <MyInput labelName={"Telefono: "} type={"text"} value={value} handleChange={handleChange} {...otherProps} />
      <div
        className="whatsapp-link"
        onClick={() =>
          window.open(
            `https://api.whatsapp.com/send/?phone=${rightTelefono()}&text=${message}&app_absent=0`,
            "Popup",
            "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30"
          )
        }
      >
        <img alt="whatsapp" src={images["whatsApp"]} />
      </div>
    </div>
  );
};

const mpst = (state) => ({
  skinExtras: state.auth.skinExtras,
})
export default connect(mpst, null)(TelefonoInput)
