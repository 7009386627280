import React, { Component } from "react"
import images from "themes/images"
import "./style.css"
import { Loader } from "shared-components"
// import ShopList from "./ShopList";
// import BestSeller from "./BestSeller";
// import PromotionBottom from "./PromotionBottom";
// import PromotionTop from "./PromotionTop";

import { withRouter } from "react-router-dom"
import Slider from "react-slick"
import ProductItem from "./ProductItem"
import { connect } from "react-redux"
import ShopActions from "redux-store/models/shop"

export const NextArrow = (props) => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  )
}

export const PrevArrow = (props) => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  )
}

class Products extends Component {
  state = {
    tagSelected: "",
    selectedCategory: "profumeriacosmesi",
  }
  clickBannerHandler = (id) => {
    window.location.hash = `products/${id}`
    let el = document.querySelector(".homeProd")
    el.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    })
  }

  handleCategoryChange = (category) => {
    this.setState({ selectedCategory: category })
  }

  render() {
    const { defaultProducts, categories, isMobile } = this.props
    const { selectedCategory } = this.state
    const tagParam = this.props.match.params.tag

    //Profumeria | Cosmesi & Sport | Relax
    const categoriesOptions = [
      { name: "profumeriacosmesi", text: "Profumeria | Cosmesi" },
      { name: "sportrelax", text: "Sport | Relax" },
    ]

    const optionsProducts = selectedCategory ? defaultProducts[selectedCategory] : []

    return (
      <div className="shopProd">
        {/* Main banner */}
        {!isMobile && (
          <div className="mainBanner marginBottom">
            <Slider
              {...{
                dots: true,
                speed: 500,
                infinite: true,
                autoplay: true,
                slidesToScroll: 1,
                draggable: false,
              }}
              className="catgItems__items maxWidth"
            >
              <div className="custom-banner">
                <div className="banner-text">
                  <h1>Arredamento</h1>
                  <h5>Tanti prodotti per la tua casa</h5>
                  <div
                    className="viewMore"
                    onClick={(e) => {
                      e.preventDefault()
                      this.clickBannerHandler("sportivi%20/%20in%20forma")
                    }}
                  >
                    ACQUISTA ORA
                  </div>
                </div>
                <img src={images.bannerArredamento1} />
                <img src={images.bannerArredamento2} />
              </div>
              <img
                onClick={(e) => {
                  e.preventDefault()
                  this.clickBannerHandler("comodità%20/%20essere%20comodi")
                }}
                src={images.mainBanner3}
                alt=""
              ></img>
            </Slider>
          </div>
        )}

        {categories && Object.keys(categories).length < 1 ? (
          <Loader></Loader>
        ) : (
          <>
            {/* Top Brands Eletronicca on slider */}
            <div className="titleSlider maxWidth ">Top Brands Elettronica</div>
            <div className="banners">
              <div className="brandsSlider maxWidth">
                {" "}
                <Slider
                  {...{
                    infinite: true,
                    speed: 500,
                    slidesToShow: isMobile ? 3 : 7,
                    slidesToScroll: isMobile ? 1 : 7,
                    autoplay: isMobile ? true : false,
                  }}
                >
                  {Object.keys(categories["informaticaelettronica"].brands).map(
                    (brand) => (
                      <div
                        key={brand}
                        onClick={() => {
                          this.props.getProductsList(
                            null,
                            categories["informaticaelettronica"].brands[brand].name,
                            "Informatica | Elettronica"
                          )
                          this.props.setCategory("Informatica | Elettronica")
                          this.props.history.push(
                            `/product-filtered/Informatica__Elettronica`
                          )
                          this.props.openProducts(true)
                        }}
                      >
                        <img
                          src={
                            categories["informaticaelettronica"].brands[brand].url
                          }
                          alt={
                            categories["informaticaelettronica"].brands[brand].name
                          }
                        />
                        <span>
                          {categories["informaticaelettronica"].brands[brand].name}
                        </span>
                      </div>
                    )
                  )}
                </Slider>
              </div>
            </div>

            {/* All categories on slider */}
            <div className="titleSlider maxWidth ">Categorie</div>
            <div className="banners">
              <Slider
                {...{
                  dots: false,
                  infinite: isMobile ? true : false,
                  slidesToShow: isMobile ? 1 : 3,
                  slidesToScroll: 1,
                  autoplay: true,
                  nextArrow: <NextArrow />,
                  prevArrow: <PrevArrow />,
                  variableWidth: true,
                }}
              >
                {!isMobile && <div key="blank-slide" className="blank-slide"></div>}
                {categories &&
                  Object.keys(categories).map((item, index) => {
                    let url =
                      categories[item]?.name?.split(" | ")?.[0] +
                      (categories[item]?.name?.split(" | ")?.[1]
                        ? "__" + categories[item]?.name?.split(" | ")?.[1]
                        : "")
                    return (
                      <div key={index} className={`categoriesSlider ${item}`}>
                        <div className="left">
                          <div className="nr_prod">1500 prodotti</div>
                          <div className="text">
                            <div className="first-word">
                              {categories[item]?.name?.split(" | ")[0]}
                            </div>
                            <div className="second-word">
                              {categories[item]?.name?.split(" | ")[1]}
                            </div>
                          </div>
                          <div
                            className="left--button"
                            onClick={(e) => {
                              e.preventDefault()
                              this.props.getProductsList(
                                null,
                                null,
                                categories[item].name
                              )
                              this.props.setCategory(categories[item].name)
                              this.props.history.push(`/product-filtered/${url}`)
                            }}
                          >
                            <span>VEDI TUTTO</span>
                          </div>
                        </div>
                        <div className="right">
                          {" "}
                          <img src={images[item]} alt=""></img>
                        </div>
                      </div>
                    )
                  })}
              </Slider>
            </div>
          </>
        )}

        {/* Default products for some categories */}
        <div className="maxWidth">
          {/* PROFUMERIA COSMESI & SPORT RELAX */}
          <div className="bestSeller optionsCategories">
            {optionsProducts && Object.keys(optionsProducts).length < 1 ? (
              <Loader></Loader>
            ) : (
              <>
                <div className="option-row">
                  {categoriesOptions.map((category) => (
                    <div
                      key={category.name}
                      onClick={() => this.handleCategoryChange(category.name)}
                      className={selectedCategory === category.name ? "active" : ""}
                    >
                      {category.text}
                    </div>
                  ))}
                </div>
                <div className="products">
                  {optionsProducts &&
                    optionsProducts.map((item, index) => {
                      return (
                        <ProductItem
                          item={item}
                          key={index}
                          category={categories[selectedCategory]?.name}
                        ></ProductItem>
                      )
                    })}
                </div>
                <div
                  className="viewMore"
                  onClick={() => {
                    const url =
                      categories[selectedCategory]?.name?.split(" | ")?.[0] +
                      (categories[selectedCategory]?.name?.split(" | ")?.[1]
                        ? "__" +
                          categories[selectedCategory]?.name?.split(" | ")?.[1]
                        : "")
                    this.props.getProductsList(
                      null,
                      null,
                      categories[selectedCategory]?.name
                    )
                    this.props.setCategory(categories[selectedCategory]?.name)
                    this.props.history.push(
                      `/product-filtered/${url}/${tagParam ? tagParam : ""}`
                    )
                  }}
                >
                  Vedi tutto
                </div>
              </>
            )}
          </div>

          {/* INFORMATICA ELETTRONICA / GIOCATTOLI COSTUMI / MODA ACCESSORI / CASA GIARDINO BANNER  */}
          <div className="bestSeller paddingBottom ">
            {Object.keys(defaultProducts).map((item) => {
              const prod = defaultProducts[item]
              let url =
                categories[item]?.name?.split(" | ")?.[0] +
                (categories[item]?.name?.split(" | ")?.[1]
                  ? "__" + categories[item]?.name?.split(" | ")?.[1]
                  : "")

              // check if category is informaticaelettronica or giocattolicostumi
              if (["informaticaelettronica", "giocattolicostumi"].includes(item)) {
                return (
                  <div className="homeProd" key={item}>
                    <div className="filtersCateg text-uppercase">
                      <i className={`fal ${categories[item]?.name.toLowerCase()}`} />{" "}
                      {categories[item]?.name}
                    </div>
                    <div className="products-content">
                      <div className="categories">
                        <div className="categories--list">
                          {categories &&
                            categories[item]?.subcategories &&
                            Object.keys(categories[item].subcategories).map(
                              (subcat) => (
                                <div
                                  key={subcat}
                                  onClick={() => {
                                    this.props.getProductsList(
                                      null,
                                      null,
                                      categories[item]?.name
                                    )
                                    this.props.setCategory(categories[item]?.name)
                                    this.props.history.push(
                                      `/product-filtered/${url}/${
                                        tagParam ? tagParam : ""
                                      }`
                                    )
                                  }}
                                >
                                  {categories[item].subcategories[subcat].name}
                                </div>
                              )
                            )}
                        </div>
                        <div className="categories--image">
                          <img src={images[item + "Img"]} />
                        </div>
                      </div>{" "}
                      <div className="products">
                        {prod.map((subItem, index) => {
                          return (
                            <ProductItem
                              item={subItem}
                              key={index}
                              category={categories[item]?.name}
                            ></ProductItem>
                          )
                        })}
                      </div>
                    </div>
                    {isMobile && (
                      <div
                        className="viewMore"
                        onClick={() => {
                          this.props.getProductsList(
                            null,
                            null,
                            categories[item]?.name
                          )
                          this.props.setCategory(categories[item]?.name)
                          this.props.history.push(
                            `/product-filtered/${url}/${tagParam ? tagParam : ""}`
                          )
                        }}
                      >
                        Vedi tutto
                      </div>
                    )}
                  </div>
                )
              }
              if (item === "modaaccessori") {
                return (
                  <div className="homeProd" key={item}>
                    {isMobile ? (
                      <div className="filtersCateg text-uppercase">
                        <i className="fal fa-heart" /> {categories[item]?.name}
                      </div>
                    ) : (
                      <div className="categoryTitle">
                        <div className="left-line"></div>
                        <div className="title"> {categories[item]?.name}</div>
                        <div className="right-line"></div>
                      </div>
                    )}

                    <div className="products">
                      {prod.map((subItem, index) => {
                        return (
                          <ProductItem
                            item={subItem}
                            key={index}
                            category={categories[item]?.name}
                          ></ProductItem>
                        )
                      })}
                    </div>
                    <div
                      className="viewMore"
                      onClick={() => {
                        this.props.getProductsList(
                          null,
                          null,
                          categories[item]?.name
                        )
                        this.props.setCategory(categories[item]?.name)
                        this.props.history.push(
                          `/product-filtered/${url}/${tagParam ? tagParam : ""}`
                        )
                      }}
                    >
                      Vedi tutto
                    </div>
                  </div>
                )
              }
              if (item === "casagiardino") {
                return (
                  <div className="homeProd" key={item}>
                    <div className="banner-left">
                      {" "}
                      <div className="banner-title"> {categories[item]?.name}</div>
                      <div
                        className={isMobile ? "viewMoreM" : "viewMore"}
                        onClick={() => {
                          this.props.getProductsList(
                            null,
                            null,
                            categories[item]?.name
                          )
                          this.props.setCategory(categories[item]?.name)
                          this.props.history.push(
                            `/product-filtered/${url}/${tagParam ? tagParam : ""}`
                          )
                        }}
                      >
                        {isMobile && (
                          <div class="circle">
                            <i class="fa fa-arrow-right"></i>
                          </div>
                        )}
                        Vedi tutto
                      </div>
                    </div>

                    <img className="banner-img" src={images[item + "Banner"]} />
                  </div>
                )
              }
            })}
          </div>

          {/* SALUTE BELLEZZA & REGALI ORIGINALI BANNERS */}
          <div className="bestSeller paddingBottom banner-row">
            {Object.keys(defaultProducts).map((item) => {
              let url =
                categories[item]?.name?.split(" | ")?.[0] +
                (categories[item]?.name?.split(" | ")?.[1]
                  ? "__" + categories[item]?.name?.split(" | ")?.[1]
                  : "")
              if (["salutebellezza", "regalioriginali"].includes(item))
                return (
                  <div className="homeProd" key={item}>
                    <div className="banner-left">
                      {" "}
                      <div className="banner-title"> {categories[item]?.name}</div>
                      <div
                        className={isMobile ? "viewMoreM" : "viewMore"}
                        onClick={() => {
                          this.props.getProductsList(
                            null,
                            null,
                            categories[item]?.name
                          )
                          this.props.setCategory(categories[item]?.name)
                          this.props.history.push(
                            `/product-filtered/${url}/${tagParam ? tagParam : ""}`
                          )
                        }}
                      >
                        {isMobile && (
                          <div class="circle">
                            <i class="fa fa-arrow-right"></i>
                          </div>
                        )}
                        Vedi tutto
                      </div>
                    </div>

                    <img className="banner-img" src={images[item + "Banner"]} />
                  </div>
                )
            })}
          </div>
        </div>

        {/* <PromotionTop></PromotionTop> */}
        {/* <FlashDeals></FlashDeals> */}
        {/* <PromotionBottom></PromotionBottom> */}
        {/* <FlashDeals></FlashDeals> */}
      </div>
    )
  }
}

export default withRouter(
  connect(
    ({ shop: { shopTags } }) => ({
      shopTags,
    }),
    { ...ShopActions }
  )(Products)
)
