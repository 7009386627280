import React from "react"

const CompanyCard = () => {
  return (
    <div className="company-card">
      <div className="image">
        <img src="https://mrbo.mrwallet.app/gallery/background/electronicarts.jpeg" />
      </div>
      <div className="information">
        <div className="category-name">Crypto</div>
        <div className="company-name">Azteco Bitcoin Voucher</div>
        <div className="service-name">A partire da 10,00 €</div>
      </div>
    </div>
  )
}

export default CompanyCard
