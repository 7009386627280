import React from "react"
import "./adminListaUtenti.css"
import MainActions from "redux-store/models/main"
import AuthActions from "redux-store/models/auth"
import CalendarRangePicker from "shared-components/CalendarRangePicker/CalendarRangePicker"
import SpanFormater from "shared-components/SpanFormater/SpanFormater"
import { switchUserStatus } from "services/auth"
import { connect } from "react-redux"
import moment from "moment"
import { Select, Pagination, Input, Button, Table, Icon, message } from "antd"
import { withRouter } from "react-router"
import { skin } from "../../../../config/api"

const { Option } = Select

class NewAdminUtenti extends React.Component {
  state = {
    perPage: 100,
    page_number: 1,
    username: "",
    ragione_sociale: "",
    city: "",
    user_id: "",
    isCalendarOpen: false,
    from: "",
    to: "",
    fromLabel: "",
    toLabel: "",
    filter: false,
    picker: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
        color: "var(--accent-bg)",
      },
    ],
    eyeClicked: false,
  }

  constructor(props) {
    super(props)
    this.refresh = this.refresh.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  setCalendar = (val) => {
    this.setState({ isCalendarOpen: val })
  }

  componentDidMount() {
    const { skinList, activeSkinId, getUsers } = this.props

    if (skinList && skinList.length > 0) {
      this.setActiveSkin()
    }
    if (activeSkinId === -1) {
      getUsers(
        null,
        {
          skin_id: skin.skin_id,
        },
        this.state.perPage,
        this.state.page_number
      )
    } else {
      this.fetchUsers()
    }
  }

  componentDidUpdate(prevProps) {
    const { skinList, activeSkinId } = this.props

    if (skinList !== prevProps.skinList && skinList.length > 0) {
      this.setActiveSkin()
    }

    if (activeSkinId !== prevProps.activeSkinId) {
      this.fetchUsers()
    }

    if (this.props.fromDate && this.props.fromDate !== prevProps.fromDate) {
      const label = moment(this.props.fromDate, "DD/MM/YYYY")

      this.setState({
        from: this.props.fromDate,
        to: this.props.fromDate,
        fromLabel: label,
        toLabel: label,
        perPage: 100,
      })
    }
  }

  setActiveSkin = () => {
    const { skinList, accountInfo, activeSkinId, setActiveSkinId } = this.props
    const roleName = accountInfo?.profile?.role?.name

    const activeSkins = skinList.filter((skin) => skin.status === 1)
    const getFirstActiveSkinId = () => {
      return activeSkins.length > 0 ? activeSkins[0].id : undefined
    }

    setActiveSkinId(
      roleName === "super_admin" || roleName === "agent"
        ? accountInfo.profile.activity_id
        : activeSkinId !== -1
        ? activeSkinId
        : getFirstActiveSkinId()
    )
  }

  fetchUsers = () => {
    const { skinList, accountInfo, activeSkinId, getUsers } = this.props
    const roleName = accountInfo?.profile?.role?.name
    const isSpecial = activeSkinId === -1 && roleName !== "support"

    const userParams = {
      skin_id: isSpecial
        ? skinList[0]?.id
        : accountInfo?.profile?.activity_id || activeSkinId,
      ...(!isSpecial && { backoffice: true }),
    }

    getUsers(null, userParams, this.state.perPage, 1)
  }

  refresh() {
    this.setState({
      username: "",
      ragione_sociale: "",
      city: "",
      user_id: "",
      from: "",
      to: "",
      fromLabel: "",
      toLabel: "",
      filter: false,
    })
    this.props.getUsers(
      null,
      {
        skin_id:
          this.props.activeSkinId === -1
            ? this.props.skinList[0]?.id
            : this.props.activeSkinId,
        ...(this.props.activeSkinId !== -1 && { backoffice: true }),
      },
      this.state.perPage,
      1
    )
  }

  handleSearch = () => {
    const { getUsers, activeSkinId } = this.props
    const { username, ragione_sociale, city, from, to, perPage } = this.state
    getUsers(
      null,
      {
        skin_id: activeSkinId === -1 ? this.props.skinList[0]?.id : activeSkinId,
        ...(this.props.activeSkinId !== -1 && { backoffice: true }),
        username: username,
        ragione_sociale: ragione_sociale,
        city: city,
        from_last_login: from,
        to_last_login: to,
      },
      perPage,
      1
    )
    this.setState({
      page_number: 1,
    })
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node
            }}
            placeholder={`Search ${dataIndex}`}
            value={this.state[dataIndex] || selectedKeys[0]}
            onChange={(e) => {
              this.setState({ [dataIndex]: e.target.value })
              setSelectedKeys(e.target.value ? [e.target.value] : [])
              if (e.target.value.length === 3) {
                setTimeout(() => {
                  this.handleSearch()
                }, 200)
              }
            }}
            onPressEnter={() => this.handleSearch()}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => this.handleSearch()}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => this.refresh()} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      )
    },
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
    render: (text) => (this.state.searchedColumn === dataIndex ? text : text),
  })

  handleSubmit(e) {
    const { username, ragione_sociale, city, user_id, from, to, perPage } =
      this.state
    e.preventDefault()
    this.props.getUsers(
      null,
      {
        skin_id: this.props.activeSkinId,
        ...(this.props.activeSkinId !== -1 && { backoffice: true }),
        username: username,
        ragione_sociale: ragione_sociale,
        city: city,
        user_id: user_id,
        from_last_login: from,
        to_last_login: to,
      },
      perPage,
      1
    )
    this.setState({
      page_number: 1,
      filter: true,
    })
  }

  render() {
    const {
      userList,
      LoaderAU,
      screenWidth,
      activeSkinId,
      accountInfo,
      total_pages,
      setDepositoModalAdmin,
      setUserMessageModalAdmin,
    } = this.props
    const { perPage, page_number } = this.state

    const showCity = userList && userList?.some((user) => user.city !== "-")
    const Special =
      activeSkinId === -1 && accountInfo?.profile?.role?.name !== "support"

    var columns =
      screenWidth <= 768
        ? [
            {
              title: "Utente",
              dataIndex: "username",
              key: "username",
              ellipsis: true,
              width: "50%",
              ...this.getColumnSearchProps("username"),
              render: (text, record) => (
                <span
                  className="user-icons"
                  onClick={() => {
                    if (activeSkinId !== -1) {
                      this.props.history.push(
                        `/back-office/movimenti/${record.username}`
                      )
                    }
                  }}
                >
                  {record.role === "agency" ? (
                    <i className="fas fa-store"></i>
                  ) : record.role === "user" ? (
                    <i className="fal fa-user"></i>
                  ) : record.role === "agent" ? (
                    <i className="fas fa-user-tie"></i>
                  ) : (
                    <></>
                  )}
                  <SpanFormater
                    myClassName="nomeTd"
                    Word={text}
                    size={35}
                    nrOfRows={1}
                    formatWord={true}
                  />
                </span>
              ),
              getColumnSearchProps: (dataIndex) =>
                this.getColumnSearchProps(dataIndex, "username"),
            },
            {
              title: "Credito",
              dataIndex: "wallet",
              key: "wallet",
              ellipsis: true,
              width: "30%",
              align: "right",
              render: (text) => `${text}€`,
            },
            {
              title: "Impostazioni",
              dataIndex: "refresh",
              key: "refresh",
              ellipsis: true,
              width: "20%",
              align: "center",
              render: (text, record) => (
                <div className="circle-icons">
                  <i
                    className="fas fa-eye"
                    onClick={(e) => {
                      this.props.editUtentiRespModal({
                        visibility: true,
                        data: { ...record },
                      })
                    }}
                  ></i>
                </div>
              ),
              onHeaderCell: () => {
                return {
                  onClick: () => {
                    this.refresh()
                  },
                }
              },
            },
          ]
        : [
            {
              title: "Utente",
              dataIndex: "username",
              key: "username",
              ellipsis: true,
              width: "10%",
              ...this.getColumnSearchProps("username"),
              render: (text, record) => (
                <span
                  style={{ cursor: "pointer" }}
                  className="user-icons"
                  onClick={() => {
                    if (activeSkinId !== -1) {
                      this.props.history.push(
                        `/back-office/movimenti/${record.username}`
                      )
                    }
                  }}
                >
                  {record.role === "agency" ? (
                    <i className="fas fa-store"></i>
                  ) : record.role === "user" ? (
                    <i className="fal fa-user"></i>
                  ) : record.role === "agent" ? (
                    <i className="fas fa-user-tie"></i>
                  ) : (
                    <></>
                  )}

                  <SpanFormater
                    myClassName="nomeTd"
                    Word={text}
                    size={35}
                    nrOfRows={1}
                    formatWord={true}
                  />
                </span>
              ),
              getColumnSearchProps: (dataIndex) =>
                this.getColumnSearchProps(dataIndex, "username"),
            },
            {
              title: "Ragione Sociale",
              dataIndex: "ragione_sociale",
              key: "ragione_sociale",
              ellipsis: true,
              width: "15%",
              ...this.getColumnSearchProps("ragione_sociale"),
              render: (text, record) => (
                <SpanFormater
                  myClassName="nomeTd"
                  Word={text}
                  size={30}
                  nrOfRows={1}
                  formatWord={true}
                />
              ),
            },
            {
              title: "Credito",
              dataIndex: "wallet",
              key: "wallet",
              ellipsis: true,
              width: "9%",
              align: "right",
              render: (text) => `${text}€`,
            },
            {
              title: "Città",
              dataIndex: "city",
              key: "city",
              ellipsis: true,
              width: "10%",
              ...this.getColumnSearchProps("city"),
              render: (text, record) => (
                <SpanFormater
                  myClassName="nomeTd"
                  Word={text}
                  size={30}
                  nrOfRows={1}
                  formatWord={true}
                />
              ),
            },
            {
              title: "Ultimo Deposito",
              dataIndex: "last_deposit",
              key: "last_deposit",
              ellipsis: true,
              width: "12%",
              responsive: ["xl"],
            },
            {
              title: (
                <span>
                  Ultimo Login <i className="fal fa-calendar-alt"></i>
                </span>
              ),
              dataIndex: "last_login_time",
              key: "last_login_time",
              ellipsis: true,
              width: "12%",
              className: "ultimo-login-col",
              responsive: ["xl"],
              onHeaderCell: () => {
                return {
                  onClick: () => {
                    this.setCalendar(true)
                  },
                }
              },
            },
            {
              title:
                accountInfo?.profile?.role?.name !== "main_admin"
                  ? "Deposito"
                  : "Deposito/Addebito",
              dataIndex: "operazioni",
              key: "operazioni",
              ellipsis: true,
              width: "16%",
              align: "center",
              render: (text, record) => (
                <div className="operazioni-buttons">
                  <Button
                    className={`deposito-btn ${
                      record.status !== 1 ? "disabled" : ""
                    }`}
                    disabled={record.status !== 1}
                    onClick={() => {
                      setDepositoModalAdmin({
                        depositoModalVis: true,
                        type: "deposit",
                        username: record.username,
                        id: record.id,
                      })
                    }}
                  >
                    Deposito
                  </Button>
                  {this.props.accountInfo?.profile.role?.name !== "super_admin" && (
                    <Button
                      className="addebito-btn"
                      onClick={() => {
                        setDepositoModalAdmin({
                          depositoModalVis: true,
                          type: "withdraw",
                          username: record.username,
                          id: record.id,
                        })
                      }}
                    >
                      Addebito
                    </Button>
                  )}
                </div>
              ),
            },
            {
              title: "Listino",
              dataIndex: "supplier",
              key: "supplier",
              ellipsis: true,
              width: "12%",
              align: "center",
              render: (text, record) =>
                this.props.accountInfo?.profile.role?.name !== "super_admin" &&
                accountInfo?.profile?.role?.name !== "agent" && (
                  <Select
                    defaultValue={record?.supplier ? record?.supplier : "MrPay"}
                    onChange={(value) => {
                      this.props.activateDeactivateSepafin(record.id, value)
                    }}
                    style={{ width: 100 }}
                  >
                    <Option value="E-Pay">E-Pay</Option>
                    <Option value="Sepafin">Sepafin</Option>
                    <Option value="MrPay">MrPay</Option>
                  </Select>
                ),
            },
            {
              title: "Impostazioni",
              dataIndex: "refresh",
              key: "refresh",
              ellipsis: true,
              width: "10%",
              align: "center",
              render: (text, record) => (
                <div className="circle-icons">
                  <i
                    className={`fas fa-envelope${
                      record.status == 1 ? "" : " disabled"
                    }`}
                    onClick={() => {
                      record.status == 1 &&
                        setUserMessageModalAdmin({
                          userMsgModalVis: true,
                          username: record.username,
                          id: record.id,
                        })
                    }}
                  ></i>
                  <i
                    className={`fas fa-lock${
                      record.status !== 1 ? "-alt" : "-open-alt active"
                    }`}
                    onClick={async (e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      const changedStatus = record.status !== 1 ? 1 : Special ? 0 : 3
                      switchUserStatus(
                        record.id,
                        changedStatus,
                        () => {
                          changedStatus === 1
                            ? message.success(
                                `lo stato dell${
                                  record.username
                                } ${`è cambiato : 'ATTIVATO'`}`
                              )
                            : message.error(
                                `lo stato dell${
                                  record.username
                                } ${`è cambiato : 'DISATTIVATO'`}`
                              )
                          this.props.getUsers(
                            null,
                            Special
                              ? { skin_id: this.props.skinList[0]?.id }
                              : {
                                  skin_id: this.props.activeSkinId,
                                  ...(this.props.activeSkinId !== -1 && {
                                    backoffice: true,
                                  }),
                                },
                            perPage,
                            page_number,
                            true
                          )
                        },
                        accountInfo.role,
                        activeSkinId
                      )
                    }}
                  ></i>
                  <i
                    className="fas fa-eye"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      if (this.props.accountInfo.profile.role.name !== "agent") {
                        this.props.getAgents(this.props.activeSkinId)
                      }
                      if (activeSkinId === -1) {
                        this.props.setUserDetail({
                          username: "extra",
                          id: record.id,
                        })
                      } else {
                        record && record.role === "user"
                          ? this.props.getUserByUserId(record.id, activeSkinId)
                          : record.role === "agent"
                          ? this.props.getAgentByUserId(record.id, activeSkinId)
                          : this.props.getUserDetail(record.id, activeSkinId)
                      }
                    }}
                  ></i>
                  {/* {this.props.accountInfo?.profile.role?.name !== "super_admin" &&
                    accountInfo?.profile?.role?.name !== "agent" && (
                      <img
                        style={{ cursor: "pointer" }}
                        src={
                          images[
                            record?.sepafin_active ? "sepafinIcon" : "mrPayIcon"
                          ]
                        }
                        onClick={() =>
                          this.props.activateDeactivateSepafin(record.id)
                        }
                        alt="sepafin icon"
                      />
                    )} */}
                </div>
              ),
              onHeaderCell: () => {
                return {
                  onClick: () => {
                    this.refresh()
                  },
                }
              },
            },
          ]

    // Remove City Column
    if (!showCity) {
      const index = columns.findIndex((column) => column.key === "city")
      if (index !== -1) {
        columns.splice(index, 1)
      }
    }

    // Remove Ultimo Deposito && Ultimo Login Columns for screens smaller than 1440px
    if (screenWidth < 1440) {
      const keysToRemove = ["last_login_time", "last_deposit"]
      keysToRemove.forEach((key) => {
        const index = columns.findIndex((column) => column.key === key)
        if (index !== -1) {
          columns.splice(index, 1)
        }
      })
    }

    // Remove Supplier Column if role is not admin
    if (accountInfo?.profile?.role?.name !== "main_admin") {
      const index = columns.findIndex((column) => column.key === "supplier")
      if (index !== -1) {
        columns.splice(index, 1)
      }
    }

    // Function that recursively applies modifications to all nesting levels of the children data
    const applyModificationsToChildren = (data) => {
      return data?.map((u) => ({
        ...u,
        id: u.id,
        username: u.username,
        ragione_sociale: u.rag_soc,
        wallet: u.wallet,
        city: u.city,
        supplier: u.supplier,
        last_login_time:
          !u.last_login_time || u.last_login_time === "-"
            ? "-"
            : moment(u.last_login_time, "DD-MM-YYYY hh:mm:ss").format(
                "DD/MM/YYYY HH:mm:ss"
              ),
        role: u.role,
        sepafin_active: u.sepafin_active,
        status: u.status,
        last_deposit:
          !u.last_deposit || u.last_deposit === "-"
            ? "-"
            : moment(u.last_deposit, "DD-MM-YYYY hh:mm:ss").format(
                "DD/MM/YYYY HH:mm:ss"
              ),
        children:
          u?.children?.length > 0 && applyModificationsToChildren(u?.children),
      }))
    }

    var data =
      userList && Array.isArray(userList) && applyModificationsToChildren(userList)

    return (
      <div className="AdminListaUtenti">
        {this.props.MessaggiComponent}
        {this.state.isCalendarOpen && (
          <CalendarRangePicker
            setStateFunc={(item) => {
              this.setState({
                picker: [item.selection],
                from: moment(item.selection.startDate).format("YYYY-MM-DD"),
                to: moment(item.selection.endDate).format("YYYY-MM-DD"),
                fromLabel: moment(item.selection.startDate).format("DD/MM/YYYY"),
                toLabel: moment(item.selection.endDate).format("DD/MM/YYYY"),
              })
            }}
            setStateFuncEmpty={() => {
              this.setState({
                from: "",
                to: "",
                fromLabel: "",
                toLabel: "",
              })

              this.props.getUsers(
                null,
                {
                  skin_id: this.props.activeSkinId,
                  ...(this.props.activeSkinId !== -1 && { backoffice: true }),
                  username: this.state.username,
                  ragione_sociale: this.state.ragione_sociale,
                  city: this.state.city,
                  from_last_login: "",
                  to_last_login: "",
                },
                perPage,
                1
              )
            }}
            picker={this.state.picker}
            setCalendar={this.setCalendar}
            handleSubmit={this.handleSubmit}
          />
        )}
        <div
          className={`utenti-header ${
            this.props.activeSkinId !== -1 ? "activeSkin" : ""
          }`}
        >
          <div
            style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Icon type="usergroup-add" />
            <h5 onClick={this.refresh}>Utenti</h5>
          </div>

          {this.props.activeSkinId !== -1 && (
            <div>
              <Button
                onClick={() => {
                  this.props.history.push("/back-office/registraAgenzia")
                }}
                className="register-btn"
                style={{
                  marginLeft: 20,
                  marginRight: 10,
                }}
                type="primary"
              >
                Registra Agenzia
              </Button>
              {accountInfo?.profile?.role?.name === "main_admin" && (
                <Button
                  onClick={() => {
                    this.props.history.push("/back-office/registraAgente")
                  }}
                  className="register-btn"
                  type="primary"
                >
                  Registra Agente
                </Button>
              )}
            </div>
          )}
        </div>
        <div
          className={`AdminListaUtentiRow ${
            this.props.activeSkinId !== -1 ? "activeSkin" : ""
          }`}
        >
          {LoaderAU && LoaderAU === true ? (
            <div className="loaderAdmin">Loading...</div>
          ) : (
            <Table
              dataSource={data}
              columns={columns}
              rowKey={(record) => record.id}
              rowClassName={(record) =>
                record.role === "user"
                  ? "data-row user"
                  : record.role === "agency" && record?.children?.length > 0
                  ? "data-row agency"
                  : record.role === "agent" && record?.children?.length > 0
                  ? "data-row agent"
                  : "data-row"
              }
              pagination={false}
            />
          )}
        </div>
        <div className="paginationWrapper">
          <Pagination
            current={this.state.page_number || 1}
            onChange={(e) => {
              this.setState({ page_number: e })
              !this.state.filter
                ? this.props.getUsers(
                    null,
                    {
                      skin_id: this.props.activeSkinId,
                      ...(this.props.activeSkinId !== -1 && { backoffice: true }),
                    },
                    perPage,
                    e
                  )
                : this.props.getUsers(
                    null,
                    {
                      skin_id: this.props.activeSkinId,
                      ...(this.props.activeSkinId !== -1 && { backoffice: true }),
                      username: this.state.username,
                      ragione_sociale: this.state.ragione_sociale,
                      city: this.state.city,
                      user_id: this.state.user_id,
                      from_last_login: this.state.from,
                      to_last_login: this.state.to,
                    },
                    perPage,
                    e
                  )
            }}
            total={total_pages ? total_pages * 10 : 10}
          />
          <Select
            defaultValue={100}
            onChange={(e) => {
              this.setState({ perPage: parseInt(e), clickedPage: 1 }, () => {
                !this.state.filter
                  ? this.props.getUsers(
                      null,
                      {
                        skin_id: this.props.activeSkinId,
                        ...(this.props.activeSkinId !== -1 && { backoffice: true }),
                      },
                      e,
                      page_number
                    )
                  : this.props.getUsers(
                      null,
                      {
                        skin_id: this.props.activeSkinId,
                        ...(this.props.activeSkinId !== -1 && { backoffice: true }),
                        username: this.state.username,
                        ragione_sociale: this.state.ragione_sociale,
                        city: this.state.city,
                        user_id: this.state.user_id,
                        from_last_login: this.state.from,
                        to_last_login: this.state.to,
                      },
                      e,
                      page_number
                    )
              })
            }}
            value={this.state.perPage}
          >
            <Option value={10}>10 / Pagina</Option>
            <Option value={25}>25 / Pagina</Option>
            <Option value={50}>50 / Pagina</Option>
            <Option value={100}>100 / Pagina</Option>
          </Select>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  userList: state.main.userList.users,
  total_pages: state.main.userList.total_pages,
  LoaderAU: state.main.LoaderAU,
  screenWidth: state.main.screenWidth,
  accountInfo: state.auth.accountInfo,
  skinList: state.auth.skinList,
})
export default connect(mapStateToProps, { ...MainActions, ...AuthActions })(
  withRouter(NewAdminUtenti)
)
