import React from "react";
import { connect } from "react-redux";
import PrenotationForm from "./PrenotationForm";
import { AuthActions, MainActions } from "redux-store/models"

const assicurazioniInputProps = {
  nome: { extraClass: "semi" },
  cognome: { extraClass: "semi" },
  codice_fiscale: { label: "Codice Fiscale" },
  via: {placeholder:'Indirizo Residenza'},
  nr_civicio: {
    label: "Nr. Civicio",
    extraClass: "semi",
  },
  cap: { extraClass: "semi" },
  citta: { extraClass: "semi" },
  provincia: { extraClass: "semi" },
  telefono: { Icon: () => <i className="fal fa-phone inputI" /> },
  email: {label:'E-mail',Icon: () => <i className="fal fa-envelope inputI" /> },
  situazione: {
    type: "dropdown",
    label: "situazione assicurativa",
    position: "right",
    initialValue:'Prima assicurazione dopo voltura P.R.A.',
    dropdownData: [
      { value: "Prima assicurazione dopo voltura P.R.A." },
      { value: "Prima assicurazione dopo immatricolazione" },
      { value: "Provenienza da altra compagnia" },
      { value: "Recupero classe di merito dopo prima immatricolazione (Legge Bersani) con famigliare convivente" },
      {
        value:
          "Targa famigliare convivente:Recupero classe di merito dopo prima immatricolazione o voltura al P.R.A. con stesso proprietario - Legge Bersani personale",
      },
      {
        value:
          "Recupero classe di merito da altro veicolo venduto/rottamato/in conto vendita/esportato definitivamente all’estero",
      },
    ],
  },
   tipo: {
    type: "dropdown",
    position: "right",
    extraClass: "semi",
    initialValue:'Auto',
    dropdownData: [{value:'Auto'},{value:'Moto'},{value:'Autocarro'}]
  },
  targa: {
    position: "right",
    extraClass: "semi",
    placeholder:' ',
  },
   accesories:{
      label:'Garanzie accessorie',
      position:'right',
      type:'dropdown',
      extraClass:'semi multiselect',
      mode:'multiple',
      allowClear:true,
      initialValue:'Furto',
       dropdownData: [{value:'Furto'},{value:'Incendio'},{value:'Collisione'},{value:'Kasco'},{value:'Rottura cristalli'},
       {value:'Eventi naturali'},{value:'Infortuni conduce...'},{value:'Assistenza e tute..'}]
  },
  valore: {
    label: "Valore assicurato",
    position: "right",
    extraClass: "semi",
    placeholder:"€"
  },
  note: {
    type: "notes",
    extraClass: "notes",
    position: "right",
    label: "Descrivi la tua esigenza assicurativa e sarai contattato dal broker assicurativo:",
    placeholder:' ',
  },
};

const Assicurazioni = (props) => {
  const { nome_agenzia, color, name, goBack, isMobile, activeService , buyAssicurazione , accountInfo } = props;

  const submit = (state,props,resetState) => {
    const {accesories,cap,citta,codice_fiscale,cognome,email,nome,note,nr_civicio,provincia,situazione,targa,telefono,tipo,valore,via} = state
    buyAssicurazione(
      9,
      nome,
      cognome,
      email,
      telefono,
      codice_fiscale,
      via,
      nr_civicio,
      cap,
      citta,
      provincia,
      situazione,
      tipo,
      targa,
      accesories,
      valore,
      note,
      accountInfo.profile.username,
      (msg)=>{
        resetState(msg);
      }
    )
  };

  return (
    <PrenotationForm
      inputProps={assicurazioniInputProps}
      title="ASSICURAZIONE AUTO/MOTO"
      description="Miglior preventivo entro 24 ore!"
      submit={submit}
      {...{ nome_agenzia, color, name, goBack, isMobile, activeService }}
    />
  );
};

export default connect(null, { ...AuthActions})(Assicurazioni)