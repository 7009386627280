import React from "react";
import { connect } from "react-redux"
import { isSepafinSelector} from "utils/HelperFunc";
import Actions from "shared-components/ModulePopUp/Bolletino/Actions.jsx"
import { HeaderByService, FooterByService } from "shared-components/ModulePopUp/BolletiniHeadFooter";

const CommonBolletino = (props) => {
  const { title,image, children, allFavServices, toggleFavorite,favService, service_id, close, handleSubmit,isSepafin , isPrenotaBolletino } = props;

  const scannerByServiceId = {
    BOL002:'PRDPST,Bollettino Premarcato',
     BOL007:'PRDPST,PagoPA',
     103185:'PRDPST,PagoPa'
  }

  const getActions = () => {
    return (
       <Actions
        isSepafin={isSepafin}
        toggleScanner={()=>{
          window.location.href = `${window.location.origin}/#/scan-mobile?redirectUrl=${window.location.href}?click=${scannerByServiceId[service_id]}`
        }}
        cancel={close}
        submit={handleSubmit}
        service_id={service_id}
        isPrenotaBolletino={isPrenotaBolletino}
      />
    );
  };

  const isAcitve = allFavServices ? allFavServices.includes(favService) : false;
  const favouriteHandle = () => toggleFavorite(favService, isAcitve ? "remove" : "set");
  return (
    <React.Fragment>
      {title && <div className="bolletini--header">
        {title}
        <i onClick={favouriteHandle} className={"fas fa-star" + (isAcitve ? " active" : "")} aria-hidden="true"></i>
      </div>}
      {image && image}
      <HeaderByService service_id={service_id} />
      {children}
      {/* {!["BOL001", "BOL002"].includes(service_id) && (
        <PrivacyLinks
          informativo={informativo}
          privacy={privacy}
          setInformativo={setInformativo}
          setPrivacy={setPrivacy}
        />
      )} */}
      <FooterByService service_id={service_id} />

      {getActions()}
    </React.Fragment>
  );
};

const mapsStateToProps = (state) => ({
   isSepafin:isSepafinSelector(state),
})

export default connect(mapsStateToProps,null)(
  CommonBolletino
)