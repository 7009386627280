import React from "react"

export default () => (
  <svg
    name="SvgLogos"
    xmlns="http://www.w3.org/2000/svg"
    style={{ display: "none" }}
  >
    <symbol id="bulb">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20.175"
        height="30"
        viewBox="0 0 20.175 30"
      >
        <g transform="translate(-72.412)">
          <path
            className="a"
            d="M82.5,0a10.088,10.088,0,0,0-5.257,18.7l.323,2.381a2.24,2.24,0,0,0,2.171,1.976h5.526a2.24,2.24,0,0,0,2.171-1.976l.323-2.381A10.088,10.088,0,0,0,82.5,0Zm4.294,17.691a.678.678,0,0,0-.338.5L86.089,20.9a.892.892,0,0,1-.826.8H79.737a.892.892,0,0,1-.826-.8l-.367-2.707a.678.678,0,0,0-.338-.5,8.731,8.731,0,1,1,8.588,0Z"
          />
          <path
            className="a"
            d="M166.519,351.19h-7.106a.678.678,0,1,0,0,1.357h7.106a.678.678,0,1,0,0-1.357Z"
            transform="translate(-80.467 -327.365)"
          />
          <path
            className="a"
            d="M177.48,382.526h-5.385a.678.678,0,0,0-.678.678,3.371,3.371,0,1,0,6.742,0A.678.678,0,0,0,177.48,382.526Zm-2.693,2.693a2.018,2.018,0,0,1-1.9-1.336h3.794A2.018,2.018,0,0,1,174.787,385.219Z"
            transform="translate(-92.288 -356.576)"
          />
          <path
            className="a"
            d="M166.329,145.246c0-.013,0-.025,0-.038s0-.019,0-.028,0-.024,0-.036,0-.021-.006-.031,0-.022-.007-.032-.006-.023-.01-.034l-.009-.028c0-.011-.009-.023-.014-.034l-.012-.027-.016-.031-.016-.029-.017-.026-.022-.031-.008-.011-.01-.012-.026-.03-.02-.021-.027-.026-.023-.02-.028-.021-.027-.019-.028-.017-.03-.017-.028-.014-.034-.015-.027-.01-.037-.012-.015,0-.013,0-.036-.008-.032-.005-.031,0-.036,0h-.066l-.028,0-.037,0-.03.006-.033.007-.033.009-.03.01-.033.013-.028.012-.03.016-.029.017-.026.017-.031.022-.011.008-1.393,1.09-1.393-1.09a.678.678,0,0,0-.836,0l-1.393,1.09-1.393-1.09-.011-.008-.031-.022-.026-.017-.029-.016-.031-.016-.027-.012-.034-.014-.028-.009-.034-.01-.032-.007-.031-.006-.036,0-.028,0h-.066l-.036,0-.032,0-.032.005-.036.008-.013,0-.015,0-.037.012-.028.01-.034.015-.028.014-.03.017-.028.017-.027.019-.028.021-.023.02-.027.025-.02.021-.025.029-.01.012-.008.011-.021.03-.017.027-.016.028-.016.032-.012.026c0,.011-.01.023-.014.034s-.006.019-.009.028-.007.023-.01.034,0,.021-.007.032,0,.021-.006.031,0,.024,0,.036,0,.019,0,.028,0,.025,0,.038,0,.019,0,.028,0,.024,0,.036,0,.021,0,.031,0,.021.005.032,0,.024.008.036l0,.013,2.611,9.765a.678.678,0,0,0,1.311-.351l-2.087-7.806.213.167a.679.679,0,0,0,.836,0l1.393-1.09,1.393,1.09a.678.678,0,0,0,.836,0l.214-.167-2.087,7.806a.678.678,0,1,0,1.311.351l2.611-9.765s0-.009,0-.013.005-.024.008-.036,0-.021.005-.032,0-.021,0-.032,0-.024,0-.036S166.329,145.256,166.329,145.246Z"
            transform="translate(-79.53 -134.762)"
          />
        </g>
      </svg>
    </symbol>
    <symbol id="fire">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20.341"
        height="30.408"
        viewBox="0 0 20.341 30.408"
      >
        <g transform="translate(-86.2 0.208)">
          <path
            className="a"
            d="M105.4,15.824c-.356-.825-.844-1.769-1.359-2.769a35.476,35.476,0,0,1-2.747-6.29c-.65-2.305-.534-4.07.354-5.4a.879.879,0,0,0-.937-1.343c-.166.04-4.095,1.022-5.994,4.2a7.014,7.014,0,0,0-.993,3.508,4.69,4.69,0,0,0-1.8-.914.879.879,0,0,0-1.04.977c.309,2.351-.748,3.911-1.972,5.717a22.057,22.057,0,0,0-1.663,2.739c-1.306,2.68-1.093,5.56.63,8.56A10.321,10.321,0,0,0,96.808,30a10.128,10.128,0,0,0,2.619-.344c.067-.018.134-.035.2-.055a9.165,9.165,0,0,0,5.686-5,10.907,10.907,0,0,0,.093-8.774ZM93.654,26.987a3.911,3.911,0,0,1-.947-3.052c.13-1.135,1.129-2.446,2.1-3.713a11.755,11.755,0,0,0,2.4-4.21c.936,1.2,1.787,3.339.619,6.743a.879.879,0,0,0,.933,1.158,2.841,2.841,0,0,0,1.2-.465,4.914,4.914,0,0,1-.6,3.291,3.178,3.178,0,0,1-2.714,1.5,8.418,8.418,0,0,1-.85-.06,3.713,3.713,0,0,1-2.134-1.193Zm10.063-3.128a7.574,7.574,0,0,1-2.452,3,7.174,7.174,0,0,0-.207-5.764.879.879,0,0,0-.909-.494l-.052.009a7.842,7.842,0,0,0-1.083-5.063,6.916,6.916,0,0,0-2.435-2.361.879.879,0,0,0-1.207,1.132c.625,1.44-.634,3.092-1.967,4.84-1.11,1.456-2.258,2.962-2.444,4.578a5.75,5.75,0,0,0,.225,2.38A8.6,8.6,0,0,1,89.4,23.936c-1.436-2.5-1.624-4.76-.574-6.915A20.694,20.694,0,0,1,90.367,14.5a10.757,10.757,0,0,0,2.312-5.357,10.851,10.851,0,0,1,1.55,1.483.879.879,0,0,0,1.522-.79,5.957,5.957,0,0,1,.473-4.7A7.433,7.433,0,0,1,99.3,2.447a9.668,9.668,0,0,0,.3,4.794,36.774,36.774,0,0,0,2.877,6.619c.5.972.976,1.891,1.308,2.66a9.124,9.124,0,0,1-.074,7.339Z"
            transform="translate(0 0)"
          />
        </g>
      </svg>
    </symbol>
    <symbol id="fire-bulb">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="71.134"
        height="30.408"
        viewBox="0 0 71.134 30.408"
      >
        <g transform="translate(0 0.208)">
          <g transform="translate(-72.412)">
            <path
              className="a"
              d="M82.5,0a10.088,10.088,0,0,0-5.257,18.7l.323,2.381a2.24,2.24,0,0,0,2.171,1.976h5.526a2.24,2.24,0,0,0,2.171-1.976l.323-2.381A10.088,10.088,0,0,0,82.5,0Zm4.294,17.691a.678.678,0,0,0-.338.5L86.089,20.9a.892.892,0,0,1-.826.8H79.737a.892.892,0,0,1-.826-.8l-.367-2.707a.678.678,0,0,0-.338-.5,8.731,8.731,0,1,1,8.588,0Z"
            />
            <path
              className="a"
              d="M166.519,351.19h-7.106a.678.678,0,1,0,0,1.357h7.106a.678.678,0,1,0,0-1.357Z"
              transform="translate(-80.467 -327.365)"
            />
            <path
              className="a"
              d="M177.48,382.526h-5.385a.678.678,0,0,0-.678.678,3.371,3.371,0,1,0,6.742,0A.678.678,0,0,0,177.48,382.526Zm-2.693,2.693a2.018,2.018,0,0,1-1.9-1.336h3.794A2.018,2.018,0,0,1,174.787,385.219Z"
              transform="translate(-92.288 -356.576)"
            />
            <path
              className="a"
              d="M166.329,145.246c0-.013,0-.025,0-.038s0-.019,0-.028,0-.024,0-.036,0-.021-.006-.031,0-.022-.007-.032-.006-.023-.01-.034l-.009-.028c0-.011-.009-.023-.014-.034l-.012-.027-.016-.031-.016-.029-.017-.026-.022-.031-.008-.011-.01-.012-.026-.03-.02-.021-.027-.026-.023-.02-.028-.021-.027-.019-.028-.017-.03-.017-.028-.014-.034-.015-.027-.01-.037-.012-.015,0-.013,0-.036-.008-.032-.005-.031,0-.036,0h-.066l-.028,0-.037,0-.03.006-.033.007-.033.009-.03.01-.033.013-.028.012-.03.016-.029.017-.026.017-.031.022-.011.008-1.393,1.09-1.393-1.09a.678.678,0,0,0-.836,0l-1.393,1.09-1.393-1.09-.011-.008-.031-.022-.026-.017-.029-.016-.031-.016-.027-.012-.034-.014-.028-.009-.034-.01-.032-.007-.031-.006-.036,0-.028,0h-.066l-.036,0-.032,0-.032.005-.036.008-.013,0-.015,0-.037.012-.028.01-.034.015-.028.014-.03.017-.028.017-.027.019-.028.021-.023.02-.027.025-.02.021-.025.029-.01.012-.008.011-.021.03-.017.027-.016.028-.016.032-.012.026c0,.011-.01.023-.014.034s-.006.019-.009.028-.007.023-.01.034,0,.021-.007.032,0,.021-.006.031,0,.024,0,.036,0,.019,0,.028,0,.025,0,.038,0,.019,0,.028,0,.024,0,.036,0,.021,0,.031,0,.021.005.032,0,.024.008.036l0,.013,2.611,9.765a.678.678,0,0,0,1.311-.351l-2.087-7.806.213.167a.679.679,0,0,0,.836,0l1.393-1.09,1.393,1.09a.678.678,0,0,0,.836,0l.214-.167-2.087,7.806a.678.678,0,1,0,1.311.351l2.611-9.765s0-.009,0-.013.005-.024.008-.036,0-.021.005-.032,0-.021,0-.032,0-.024,0-.036S166.329,145.256,166.329,145.246Z"
              transform="translate(-79.53 -134.762)"
            />
          </g>
          <g transform="translate(-35.406 0)">
            <path
              className="b"
              d="M105.4,15.824c-.356-.825-.844-1.769-1.359-2.769a35.476,35.476,0,0,1-2.747-6.29c-.65-2.305-.534-4.07.354-5.4a.879.879,0,0,0-.937-1.343c-.166.04-4.095,1.022-5.994,4.2a7.014,7.014,0,0,0-.993,3.508,4.69,4.69,0,0,0-1.8-.914.879.879,0,0,0-1.04.977c.309,2.351-.748,3.911-1.972,5.717a22.057,22.057,0,0,0-1.663,2.739c-1.306,2.68-1.093,5.56.63,8.56A10.321,10.321,0,0,0,96.808,30a10.128,10.128,0,0,0,2.619-.344c.067-.018.134-.035.2-.055a9.165,9.165,0,0,0,5.686-5,10.907,10.907,0,0,0,.093-8.774ZM93.654,26.987a3.911,3.911,0,0,1-.947-3.052c.13-1.135,1.129-2.446,2.1-3.713a11.755,11.755,0,0,0,2.4-4.21c.936,1.2,1.787,3.339.619,6.743a.879.879,0,0,0,.933,1.158,2.841,2.841,0,0,0,1.2-.465,4.914,4.914,0,0,1-.6,3.291,3.178,3.178,0,0,1-2.714,1.5,8.418,8.418,0,0,1-.85-.06,3.713,3.713,0,0,1-2.134-1.193Zm10.063-3.128a7.574,7.574,0,0,1-2.452,3,7.174,7.174,0,0,0-.207-5.764.879.879,0,0,0-.909-.494l-.052.009a7.842,7.842,0,0,0-1.083-5.063,6.916,6.916,0,0,0-2.435-2.361.879.879,0,0,0-1.207,1.132c.625,1.44-.634,3.092-1.967,4.84-1.11,1.456-2.258,2.962-2.444,4.578a5.75,5.75,0,0,0,.225,2.38A8.6,8.6,0,0,1,89.4,23.936c-1.436-2.5-1.624-4.76-.574-6.915A20.694,20.694,0,0,1,90.367,14.5a10.757,10.757,0,0,0,2.312-5.357,10.851,10.851,0,0,1,1.55,1.483.879.879,0,0,0,1.522-.79,5.957,5.957,0,0,1,.473-4.7A7.433,7.433,0,0,1,99.3,2.447a9.668,9.668,0,0,0,.3,4.794,36.774,36.774,0,0,0,2.877,6.619c.5.972.976,1.891,1.308,2.66a9.124,9.124,0,0,1-.074,7.339Z"
              transform="translate(0 0)"
            />
          </g>
          <g transform="translate(-965.584 -387.996)">
            <text className="c" transform="translate(1003.107 407.5)">
              <tspan x="-3.387" y="0">
                +
              </tspan>
            </text>
          </g>
        </g>
      </svg>
    </symbol>

    <symbol id="bars">
      <svg viewBox="0 -53 384 384" xmlns="http://www.w3.org/2000/svg">
        <path d="m368 154.667969h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0" />
        <path d="m368 32h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0" />
        <path d="m368 277.332031h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0" />
      </svg>
    </symbol>
    <symbol id="heart">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 412.735 412.735"
      >
        <g>
          <g>
            <path
              d="M295.706,35.522C295.706,35.522,295.706,35.522,295.706,35.522c-34.43-0.184-67.161,14.937-89.339,41.273
			c-22.039-26.516-54.861-41.68-89.339-41.273C52.395,35.522,0,87.917,0,152.55C0,263.31,193.306,371.456,201.143,375.636
			c3.162,2.113,7.286,2.113,10.449,0c7.837-4.18,201.143-110.759,201.143-223.086C412.735,87.917,360.339,35.522,295.706,35.522z
			 M206.367,354.738C176.065,336.975,20.898,242.412,20.898,152.55c0-53.091,43.039-96.131,96.131-96.131
			c32.512-0.427,62.938,15.972,80.457,43.363c3.557,4.905,10.418,5.998,15.323,2.44c0.937-0.68,1.761-1.503,2.44-2.44
			c29.055-44.435,88.631-56.903,133.066-27.848c27.202,17.787,43.575,48.114,43.521,80.615
			C391.837,243.456,236.669,337.497,206.367,354.738z"
            />
          </g>
        </g>
      </svg>
    </symbol>
    <symbol id="cart">
      <svg version="1.1" viewBox="0 0 450.391 450.391">
        <g>
          <g>
            <g>
              <path
                d="M143.673,350.322c-25.969,0-47.02,21.052-47.02,47.02c0,25.969,21.052,47.02,47.02,47.02
				c25.969,0,47.02-21.052,47.02-47.02C190.694,371.374,169.642,350.322,143.673,350.322z M143.673,423.465
				c-14.427,0-26.122-11.695-26.122-26.122c0-14.427,11.695-26.122,26.122-26.122c14.427,0,26.122,11.695,26.122,26.122
				C169.796,411.77,158.1,423.465,143.673,423.465z"
              />
              <path
                d="M342.204,350.322c-25.969,0-47.02,21.052-47.02,47.02c0,25.969,21.052,47.02,47.02,47.02s47.02-21.052,47.02-47.02
				C389.224,371.374,368.173,350.322,342.204,350.322z M342.204,423.465c-14.427,0-26.122-11.695-26.122-26.122
				c0-14.427,11.695-26.122,26.122-26.122s26.122,11.695,26.122,26.122C368.327,411.77,356.631,423.465,342.204,423.465z"
              />
              <path
                d="M448.261,76.037c-2.176-2.377-5.153-3.865-8.359-4.18L99.788,67.155L90.384,38.42
				C83.759,19.211,65.771,6.243,45.453,6.028H10.449C4.678,6.028,0,10.706,0,16.477s4.678,10.449,10.449,10.449h35.004
				c11.361,0.251,21.365,7.546,25.078,18.286l66.351,200.098l-5.224,12.016c-5.827,15.026-4.077,31.938,4.702,45.453
				c8.695,13.274,23.323,21.466,39.184,21.943h203.233c5.771,0,10.449-4.678,10.449-10.449c0-5.771-4.678-10.449-10.449-10.449
				H175.543c-8.957-0.224-17.202-4.936-21.943-12.539c-4.688-7.51-5.651-16.762-2.612-25.078l4.18-9.404l219.951-22.988
				c24.16-2.661,44.034-20.233,49.633-43.886l25.078-105.012C450.96,81.893,450.36,78.492,448.261,76.037z M404.376,185.228
				c-3.392,15.226-16.319,26.457-31.869,27.69l-217.339,22.465L106.58,88.053l320.261,4.702L404.376,185.228z"
              />
            </g>
          </g>
        </g>
      </svg>
    </symbol>
    <symbol id="bell">
      <svg viewBox="-21 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path d="m453.332031 229.332031c-8.832031 0-16-7.167969-16-16 0-61.269531-23.847656-118.847656-67.15625-162.175781-6.25-6.25-6.25-16.382812 0-22.632812s16.382813-6.25 22.636719 0c49.34375 49.363281 76.519531 115.007812 76.519531 184.808593 0 8.832031-7.167969 16-16 16zm0 0" />
        <path d="m16 229.332031c-8.832031 0-16-7.167969-16-16 0-69.800781 27.179688-135.445312 76.542969-184.789062 6.25-6.25 16.386719-6.25 22.636719 0s6.25 16.386719 0 22.636719c-43.328126 43.304687-67.179688 100.882812-67.179688 162.152343 0 8.832031-7.167969 16-16 16zm0 0" />
        <path d="m234.667969 512c-44.117188 0-80-35.882812-80-80 0-8.832031 7.167969-16 16-16s16 7.167969 16 16c0 26.476562 21.523437 48 48 48 26.472656 0 48-21.523438 48-48 0-8.832031 7.167969-16 16-16s16 7.167969 16 16c0 44.117188-35.882813 80-80 80zm0 0" />
        <path d="m410.667969 448h-352c-20.589844 0-37.335938-16.746094-37.335938-37.332031 0-10.925781 4.757813-21.269531 13.058594-28.375 32.445313-27.414063 50.941406-67.261719 50.941406-109.480469v-59.480469c0-82.34375 66.988281-149.332031 149.335938-149.332031 82.34375 0 149.332031 66.988281 149.332031 149.332031v59.480469c0 42.21875 18.496094 82.066406 50.730469 109.332031 8.511719 7.253907 13.269531 17.597657 13.269531 28.523438 0 20.585937-16.746094 37.332031-37.332031 37.332031zm-176-352c-64.707031 0-117.335938 52.628906-117.335938 117.332031v59.480469c0 51.644531-22.632812 100.414062-62.078125 133.757812-.746094.640626-1.921875 1.964844-1.921875 4.097657 0 2.898437 2.433594 5.332031 5.335938 5.332031h352c2.898437 0 5.332031-2.433594 5.332031-5.332031 0-2.132813-1.171875-3.457031-1.878906-4.054688-39.488282-33.386719-62.121094-82.15625-62.121094-133.800781v-59.480469c0-64.703125-52.628906-117.332031-117.332031-117.332031zm0 0" />
        <path d="m234.667969 96c-8.832031 0-16-7.167969-16-16v-64c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v64c0 8.832031-7.167969 16-16 16zm0 0" />
      </svg>
    </symbol>
    <symbol id="home">
      <svg version="1.1" viewBox="0 0 463.937 463.937">
        <g>
          <g>
            <path
              d="M460.414,216.518l-85.6-73.1c0.1-0.5,0.2-1,0.3-1.6v-95.6c-0.1-5.7-4.6-10.3-10.3-10.5h-47.8c-5.5,0-10,4.9-10,10.5v39.6
			l-68.5-58.4c-3.7-3.2-9.2-3.2-13,0l-221.9,189c-4.3,3.5-4.8,9.8-1.3,14.1s9.8,4.8,14.1,1.3c0.1-0.1,0.1-0.1,0.2-0.2l215.4-183.4
			l77.1,65.7l46.1,39.2l92.3,78.6c4.2,3.6,10.5,3.1,14.1-1.1C465.114,226.418,464.614,220.118,460.414,216.518z M355.014,126.518
			l-28-23.6v-47.1h28V126.518z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M416.314,236.718l-28.1-24l-149.7-127.9c-3.7-3.2-9.2-3.2-13,0l-149.7,127.9l-28.1,24c-4.2,3.6-4.7,9.9-1.1,14.1
			c3.5,4.2,9.7,4.7,13.8,1.2l0.1-0.1l12.5-10.8v187.5c0.1,5.6,4.7,10.2,10.3,10.3h297.3c5.6-0.1,10.2-4.6,10.3-10.3v-187.5
			l12.5,10.8c1.8,1.5,4,2.4,6.4,2.4c2.9,0,5.7-1.3,7.6-3.5C421.114,246.518,420.514,240.218,416.314,236.718z M272.014,418.818h-80
			v-108h80V418.818z M292.014,418.818v-117.7c0-5.5-4.1-10.3-9.6-10.3h-100.8c-5.5,0-9.6,4.8-9.6,10.3v117.7h-79v-194.8l139-118.4
			l139,118.4v194.8H292.014z"
            />
          </g>
        </g>
      </svg>
    </symbol>
  </svg>
)
