import { skin, endpoint, handleError } from "config/api"
import { gamesApiByServiceId } from "config/index"
//import { removeItalianChar } from "config";
import { notification } from "antd"
import { message } from "antd"
import axios from "axios"
import { sepaObject } from "utils/HelperFunc"
import { unSubscribeSocketSupport, unSubscribeSocketUser } from "config/socket.js"

export const instanceAxios = axios.create({
  baseURL: endpoint,
})

const posObject = (isPos) => {
  return {
    ...(typeof isPos !== "boolean" ? { specific_POS_code: isPos, TEST: true } : {}),
    POS: !!isPos,
  }
}

const getIsMrPay = async () =>
  (await JSON.parse(localStorage.getItem("accountDataB") || "{}")).profile
    ?.supplier === "MrPay"

instanceAxios.interceptors.request.use(
  async (config) => {
    var Auth = true
    if (config.url === "/users/login" || config.url === "/skin/extra") {
      //api -> without token
      Auth = false
    }
    const value = await localStorage.getItem("accountDataB")
    const keys = JSON.parse(value)
    config.headers = {
      ...(Auth ? { Authorization: `Bearer ${keys?.token}` } : {}),
      Accept: "application/json",
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)
instanceAxios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => handleError(error)
)

export const fetchLogin = (email, password, device_token) =>
  instanceAxios
    .post(`/users/login`, {
      username: email,
      password: password,
      // device_token: device_token,
      // newlogintest: true,
      ...skin,
    })
    .catch((error) => ({ error }))

export const fetchLoginOtp = (email, password, device_token, need_auth, otp) =>
  instanceAxios
    .post(`/test/test`, {
      username: email,
      password: password,
      device_token: device_token,
      new_device_authorization: need_auth,
      new_device_authorization_code: otp,
      ...skin,
    })
    .catch((error) => ({ error }))

export const logoutApi = () =>
  instanceAxios
    .post(`/users/logout`, {
      ...skin,
    })
    .then((res) => {
      if (res.status === 200) {
        unSubscribeSocketUser(
          JSON.parse(localStorage.getItem("accountDataB")) &&
            JSON.parse(localStorage.getItem("accountDataB")).profile.id
        )
        if (
          JSON.parse(localStorage.getItem("accountDataB")) &&
          JSON.parse(localStorage.getItem("accountDataB")).profile.role.name ===
            "support"
        ) {
          unSubscribeSocketSupport()
        }
        localStorage.removeItem("accountDataB")
      }
    })
    .catch((error) => ({ error }))
//
export const fetchBolletiniPremercati = async (
  service_id,
  numero_conto_corrente,
  importo,
  codice_identificativo,
  tipologia,
  eseguito_da,
  via_piazza,
  cap,
  citta,
  provincia,
  indirizzo,
  user_id
) => {
  const isMrPay = await getIsMrPay()

  let params = {
    service_id,
    numero_conto_corrente,
    importo,
    codice_identificativo,
    tipologia,
    eseguito_da,
    via_piazza,
    cap,
    citta,
    provincia,
    indirizzo,
    user_id,
  }

  const ravParams = {
    codice_identificativo: "rav",
    cap: "codice_fiscale",
    via_piazza: "scadenza",
    tipologia: "numero_documento",
  }
  const mavParams = {
    codice_identificativo: "mav",
    cap: "cap",
    via_piazza: "scadenza",
    tipologia: "riferimento",
  }

  const paramsToModify = ["codice_identificativo", "cap", "via_piazza", "tipologia"]
  const modifyParamsByService = {
    BOL004: mavParams,
    BOL003: ravParams,
    106163: mavParams,
    106161: ravParams,
    102964: ravParams,
    102965: mavParams,
  }

  let requestParams = {
    service_id,
    numero_conto_corrente,
    importo: isMrPay ? importo : parseInt(importo),
    eseguito_da,
    citta,
    provincia,
    indirizzo,
    user_id,
    ...skin,
  }

  paramsToModify.forEach((currentParam) => {
    const paramKey = modifyParamsByService[service_id]
      ? modifyParamsByService[service_id][currentParam]
      : currentParam
    requestParams[paramKey] = params[currentParam]
  })

  const endpoint = isMrPay ? "/test/rechargeBOL" : "/reserve-recharge-bol"

  return instanceAxios.post(endpoint, requestParams).catch((error) => ({ error }))
}

export const fetchBolletiniBianchi = (
  service_id,
  importo,
  id_prenotazione,
  nome,
  cognome,
  conto_corrente,
  causale
) =>
  instanceAxios
    .post(`/test/rechargeBOL`, {
      service_id,
      importo,
      new_bol_pay: true,
      id_prenotazione,
      nome,
      cognome,
      conto_corrente,
      causale,
      ...skin,
    })
    .catch((error) => ({ error }))

export const fetchPayments = (
  username,
  from,
  to,
  page_number,
  limit,
  skin_id,
  excel,
  service,
  monthly
) => {
  return instanceAxios
    .post(`/users/payments`, {
      ...(username ? { username } : null),
      ...(from ? { from } : null),
      ...(to ? { to } : null),
      ...(page_number ? { page_number } : null),
      ...(limit ? { limit } : null),
      ...(skin_id ? { skin_id } : { ...skin }),
      ...(excel === "special" ? { excel } : {}),
      ...(service ? { service } : null),
      ...(monthly ? { monthly } : false),
    })
    .catch((error) => ({ error }))
}

export const fetchAllPayments = (from, to) => {
  return instanceAxios
    .post("/users/allPayments", {
      ...(from ? { from } : null),
      ...(to ? { to } : null),
    })
    .catch((error) => ({ error }))
}

export const fetchRefills = (page_number, limit) => {
  return instanceAxios.get("/providerRefills", {
    params: { page_number, limit, ...skin },
  })
}
export const fetchRequestedRefills = (page_number, limit, skin_id, from, to) => {
  return instanceAxios.get("/requested-refills", {
    params: {
      page_number,
      limit,
      ...(skin_id ? { skin_id } : { ...skin }),
      from,
      to,
    },
  })
}

export const fetchRechargeMobile = async (
  service_id,
  phone_number,
  is_sepafin,
  username,
  codice_fiscale,
  amount,
  ean,
  userId
) => {
  const isMrPay = await getIsMrPay()
  const getRequestUrl = () => {
    if (is_sepafin) {
      return "/sepafin/ricarche"
    } else if (isMrPay) {
      return "/test/recharge"
    } else {
      return "/recharge"
    }
  }

  const requestData = {
    service_id,
    ...(phone_number ? { tel_no: phone_number } : {}),
    ...(amount ? { amount: parseInt(amount) } : {}),
    ...skin,
    ...sepaObject(username),
    codice_fiscale,
  }

  if (is_sepafin || isMrPay) {
    return instanceAxios
      .post(getRequestUrl(), requestData)
      .catch((error) => ({ error }))
  } else {
    const params = {
      service_id: service_id,
      phoneNumber: phone_number,
      amount: parseInt(amount),
      ean: ean,
      ...skin,
      user_id: userId,
      codice_fiscale: codice_fiscale,
    }

    return instanceAxios
      .get(getRequestUrl(), { params })
      .catch((error) => ({ error }))
  }
}

export const fetchRechargeMobileSim = (
  service_id,
  phone_number,
  amount,
  ean,
  userId
) =>
  instanceAxios
    .post(`/reserve-recharge-phone`, {
      service_id: service_id,
      phoneNumber: phone_number,
      amount: parseInt(amount),
      ean: ean,
      ...skin,
      user_id: userId,
    })
    .catch((error) => ({ error }))

export const captureRechargeMobileSimReq = (payload) => {
  return instanceAxios
    .post(`/capture-recharge-phone`, {
      ...skin,
      payload,
    })
    .catch((error) => ({ error }))
}

export const fetchPostePay = (
  service_id,
  importo,
  user_id,
  intestazione,
  codice_fiscale_intestatario,
  ordinante,
  codice_fiscale_ordinante,
  numero_postepay,
  // document_type,
  // imageUrl,
  // imageUrl2,
  is_sepafin,
  username
) =>
  instanceAxios
    .post(is_sepafin ? `/sepafin/rpp` : `/test/rechargeBOL`, {
      ...{ service_id: service_id },
      ...{ importo: importo.toString() },
      ...(user_id ? { user_id: user_id } : {}),
      ...{ intestazione: intestazione },
      ...{ codice_fiscale_intestatario: codice_fiscale_intestatario },
      ...{ ordinante: ordinante },
      ...{ codice_fiscale_ordinante: codice_fiscale_ordinante },
      ...{ numero_postepay: numero_postepay },
      ...sepaObject(username),
      // ...(document_type ? { document_type: document_type } : {}),
      // ...(imageUrl ? { document_front: imageUrl } : {}),
      // ...(imageUrl2 ? { document_back: imageUrl2 } : {}),
      ...skin,
    })
    .catch((error) => ({ error }))

export const fetchAds = () =>
  instanceAxios
    .get("/messages", {
      params: {
        ...skin,
      },
    })
    .catch((error) => ({ error }))

export const sendCreatedAds = (
  importance,
  title,
  text,
  specific_skin_id,
  user_id,
  type
) =>
  instanceAxios
    .post(`/messages`, {
      importance,
      title,
      text,
      ...(specific_skin_id ? { specific_skin_id } : {}),
      ...skin,
      ...(user_id ? { user_id } : null),
      ...(type ? { type } : null),
    })
    .catch((error) => ({ error }))

export const readAdsRequest = (id) =>
  instanceAxios
    .post(`/messages/${id}/read`, {
      ...skin,
    })
    .catch((error) => ({ error }))

export const fetchRegisterAllInfo = (
  first_name,
  last_name,
  nickname,
  email,
  gender,
  personal_number,
  birthday,
  nazione,
  province_of_birth,
  city_of_birth,
  nazioneDiResidenca,
  residence_province,
  residence_city,
  address,
  cap,
  identity_id,
  identity_type,
  number_prefix,
  number,
  frontImg,
  backImg,
  role,
  aRagSoc,
  aInsegna,
  aPhone,
  aAdress,
  aCity,
  aComcode,
  aCap,
  aPiva,
  aFcode,
  confirm_password,
  password,
  rilasciato_da,
  luogo_di_rilascio,
  data_di_rilascio,
  data_di_scadenza,
  a_cordinate,
  a_contry,
  rent,
  privacy_policy,
  recieve_emails,
  percentage,
  register_token,
  wallet,
  tipo_di_rent,
  active_skin,
  agent_id,
  logged_in_user_id
) =>
  instanceAxios
    .post(
      `${
        register_token || agent_id ? "/users/register/loged-out" : "/users/create"
      }`,
      {
        first_name,
        last_name,
        username: nickname,
        email,
        gender,
        personal_number,
        birthday,
        birth_country: nazione,
        birth_comune_code: province_of_birth,
        birth_place: city_of_birth,
        country: nazioneDiResidenca,
        comune_code: residence_province,
        city: residence_city,
        address,
        cap,
        document_number: identity_id,
        document_type: identity_type,
        phone: number_prefix + number,
        document_front: frontImg,
        document_back: backImg,
        role,
        a_ragione_sociale: aRagSoc,
        a_insegna: aInsegna,
        a_phone: aPhone,
        a_address: aAdress,
        a_city: aCity,
        a_comune_code: aComcode,
        a_cap: aCap,
        a_p_iva: aPiva,
        a_codice_fiscale: aFcode,
        password,
        confirm_password,
        rilasciato_da,
        luogo_di_rilascio,
        data_di_rilascio,
        data_di_scadenza,
        a_cordinate,
        a_contry,
        rent,
        privacy_policy,
        recieve_emails,
        percentage,
        ...(register_token ? { register_token } : {}),
        wallet,
        tipo_di_rent,
        ...(active_skin
          ? {
              skin_id: active_skin,
            }
          : { ...skin }),
        agent_id,
        logged_in_user_id,
      }
    )
    .catch((error) => ({ data: error.response.data }))

export const sendChangedPassword = (oldPassword, newPassword) =>
  instanceAxios
    .post(`/users/changePassword`, {
      old_password: oldPassword,
      password: newPassword,
      confirm_password: newPassword,
      ...skin,
    })
    .catch((error) => ({ error }))
export const fetchConfigura = (id) =>
  instanceAxios
    .get(`/users/${id}`, {
      params: {
        ...skin,
      },
    })
    .catch((error) => ({ error }))
export const fetchBarcodeData = (barcode) => {
  return instanceAxios
    .get("/bollettino", {
      params: {
        barcode,
        ...skin,
      },
    })
    .catch((error) => ({ error }))
}
export const changeAgentReq = (aaa, agent_id, skin_id) => {
  return instanceAxios
    .post(`/agency/${agent_id}/changeAgent`, {
      agent_id: aaa,
      ...(skin_id && skin_id !== -1 ? { skin_id } : skin),
    })
    .catch((error) => ({ error }))
}
export const changeAgentSkinReq = (agent_id, new_skin_id) => {
  return instanceAxios
    .post(`/agent/changeSkin`, {
      agent_id,
      new_skin_id,
      ...skin,
    })
    .catch((error) => ({ error }))
}
export const fetchCodice = (barcode, prenotationType) => {
  const url =
    prenotationType === "PrenotedBollettini"
      ? "sepafin/getPrenotedBollettini"
      : "payment"
  const method = prenotationType === "PrenotedBollettini" ? "post" : "get"
  const params = {
    barcode: barcode,
    ...skin,
    ...(prenotationType === "PrenotedBollettini" ? {} : { type: prenotationType }),
  }
  const reqParams = prenotationType === "PrenotedBollettini" ? params : { params }
  return instanceAxios[method](`/${url}`, reqParams).catch((error) => ({ error }))
}
export const fetchAgents = (skin_id) =>
  instanceAxios
    .get("/agents", {
      params: {
        ...(skin_id ? { skin_id } : skin),
      },
    })
    .catch((error) => ({ error }))

export const switchUserStatus = (id, status, c, role, backOffice) => {
  instanceAxios
    .post(
      (role === "main_admin" && !backOffice) || backOffice === -1
        ? `/skins/${id}/changeStatus`
        : `/users/${id}/changeStatus`,
      {
        ...{ status },
        ...(backOffice ? (backOffice !== -1 ? skin : backOffice) : skin),
      }
    )
    .then(
      (data) => {
        if (data && data.status === 200) {
          c()
        } else {
          message.error("è successo qualcosa di sbagliato")
        }
      },
      (data) => {}
    )
    .catch((err) => {
      message.error("è successo qualcosa di sbagliato")
    })
}
export const transferMoney = (
  id,
  amount,
  type,
  c,
  role,
  backOffice,
  message,
  sendMsg,
  callback,
  username
) => {
  return instanceAxios
    .post(
      (role === "main_admin" && !backOffice) || backOffice === -1
        ? `/skin/transferMoney/${id}`
        : `/users/${id}/transfer`,
      {
        amount,
        type,
        ...(backOffice
          ? backOffice !== -1
            ? { skin_id: backOffice }
            : skin
          : skin),
        message,
        sendMsg,
        ...sepaObject(username),
      }
    )
    .then((data) => {
      if (data && data.status === 200) {
        c()
        notification["success"]({
          message: "Azione completata",
          description: data?.data?.message,
          placement: "bottomRight",
        })
        callback()
        // /skin/transferMoney/{skin_id}
        // this.setState({ isPopUpActive: false });
        // this.props.getUsers();
      }
    })
    .catch((error) => ({ error }))
}

export const requireTransfer = (amount, document) => {
  return instanceAxios
    .post(`/users/require-transfer`, {
      ...skin,
      amount,
      document,
      type: "deposit",
    })
    .then((data) => {
      if (data && data.status === 200) {
        notification["success"]({
          message: "Azione completata",
          description: data?.data?.message,
          placement: "bottomRight",
        })
      }
    })
    .catch((error) => ({ error }))
}
export const requireTransferDoc = (refill) => {
  return instanceAxios
    .get(`/requested-refills/${refill}`, {
      params: {
        ...skin,
      },
    })
    .catch((error) => ({ error }))
}
export const requireTransferUpdate = (refill, status) => {
  return instanceAxios
    .post(`/requested-refills/${refill}/update`, {
      ...skin,
      status,
    })
    .then((data) => {
      if (data && data.status === 200) {
        notification["success"]({
          message: "Azione completata",
          description: data?.message?.title,
          placement: "bottomRight",
        })
        return data
      }
    })
    .catch((error) => ({ error }))
}
export const fetchUserDetails = (user_id, skin_id) => {
  return instanceAxios
    .get(`agency/${user_id}`, {
      params: {
        ...(skin_id ? (skin_id !== -1 ? { skin_id } : skin) : skin),
      },
    })
    .catch((error) => ({ error }))
}
export const updateUsers = (
  user_id,
  phone,
  document_type,
  document_number,
  rilasciato_da,
  luogo_di_rilascio,
  data_di_rilascio,
  data_di_scadenza,
  a_insegna,
  a_cordinate,
  a_phone,
  a_address,
  a_city,
  a_comune_code,
  a_cap,
  a_country,
  a_rent,
  password,
  confirm_password,
  skin_id,
  mainAdminObj
) => {
  return instanceAxios
    .post(`/users/update`, {
      user_id,
      phone,
      document_type,
      document_number,
      rilasciato_da,
      luogo_di_rilascio,
      data_di_rilascio,
      data_di_scadenza,
      a_insegna,
      a_cordinate,
      a_phone,
      a_address,
      a_city,
      a_comune_code,
      a_cap,
      a_country,
      a_rent,
      password,
      confirm_password,

      username: mainAdminObj.username,
      email: mainAdminObj.email,
      a_ragione_sociale: mainAdminObj.a_ragione_sociale,
      a_iva: mainAdminObj.a_iva,
      first_name: mainAdminObj.first_name,
      last_name: mainAdminObj.last_name,
      birth_comune_code: mainAdminObj.birth_comune_code,
      birth_country: mainAdminObj.birth_country,
      country: mainAdminObj.country,
      birth_place: mainAdminObj.birth_place,
      birthday: mainAdminObj.birthday,
      city: mainAdminObj.city,
      comune_code: mainAdminObj.comune_code,
      gender: mainAdminObj.gender,
      personal_number: mainAdminObj.personal_number,
      ragione_sociale: mainAdminObj.ragione_sociale,
      p_iva: mainAdminObj.p_iva,
      address: mainAdminObj.address,
      cap: mainAdminObj.cap,
      ...(skin_id && skin_id !== -1 ? { skin_id } : skin),
    })
    .catch((error) => ({ error }))
}
export const fetchSkinExtras = (skin_id) => {
  return instanceAxios
    .get(`/skin/extra`, {
      params: {
        ...skin,
        ...(skin_id ? { skin_id: skin_id } : {}),
      },
    })
    .catch((error) => ({ error }))
}
export const fetchErrors = (
  limit,
  page_number,
  skin_name,
  from,
  to,
  description
) => {
  return instanceAxios
    .get(`/errors`, {
      params: {
        ...skin,
        ...(limit ? { limit } : {}),
        ...(page_number ? { page_number } : {}),
        ...(skin_name ? { skin_name } : {}),
        ...(from ? { from } : null),
        ...(to ? { to } : null),
        ...(description ? { description } : null),
      },
    })
    .catch((error) => ({ error }))
}

export const deleteErrorReq = (id) => {
  return instanceAxios
    .post(`/error/${id}/changeStatus`, {
      ...skin,
      status: 4,
    })
    .catch((error) => ({ error }))
}
export const sendDataFormReq = (
  typee,
  link,
  nome_agenzia,
  extra_data,
  bagaglio,
  bagaglio_stiva,
  partenza,
  partenza_stazione,
  andata_time,
  destinazione,
  destinazione_stazione,
  compagnie,
  adulti,
  ragazzi,
  tipologia_biglietto,
  ritorno_date,
  categoria,
  descrizione_categoria,
  quantity,
  name,
  email,
  telefono,
  prezzo,
  username,
  codice_fiscale
) => {
  return instanceAxios
    .post(
      `/buy/ticket`,
      typee === 1
        ? {
            ...skin,
            type: typee,
            link,
            nome_agenzia,
            extra_data,
            bagaglio,
            bagaglio_stiva,
            price: parseFloat(prezzo),
            ...sepaObject(username),
          }
        : typee === 2
        ? {
            ...skin,
            type: typee,
            link,
            nome_agenzia,
            extra_data,
            partenza,
            partenza_stazione,
            andata_time,
            destinazione,
            destinazione_stazione,
            tipologia_biglietto,
            compagnie,
            adulti,
            ragazzi,
            ritorno_date,
            price: parseFloat(prezzo),
            ...sepaObject(username),
          }
        : {
            ...skin,
            type: typee,
            link,
            nome_agenzia,
            extra_data,
            categoria,
            descrizione_categoria,
            email,
            telefono,
            price: parseFloat(prezzo),
            destinazione,
            codice_fiscale,
            ...sepaObject(username),
          }
    )
    .catch((error) => ({ error }))
}
export const getDataFormDetailReq = (limit, page_number) => {
  return instanceAxios
    .get(`/tickets`, {
      params: {
        ...skin,
        limit,
        page_number,
      },
    })
    .catch((error) => ({ error }))
}
export const getDataFormDetailActivesReq = (isVisure, limit, page_number) => {
  return instanceAxios
    .get(`/${isVisure ? "visure" : "tickets"}/completed`, {
      params: {
        ...skin,
        limit,
        page_number,
      },
    })
    .catch((error) => ({ error }))
}
export const getTicketByTicketIdReq = (ticket_id) => {
  return instanceAxios
    .get(`/ticket/${ticket_id}`, {
      params: {
        ...skin,
      },
    })
    .catch((error) => ({ error }))
}
export const updateDataFormReq = (
  typee,
  link,
  nome_agenzia,
  extra_data,
  bagaglio,
  bagaglio_stiva,
  partenza,
  partenza_stazione,
  andata_time,
  destinazione,
  destinazione_stazione,
  compagnie,
  adulti,
  ragazzi,
  tipologia_biglietto,
  ritorno_date,
  categoria,
  descrizione_categoria,
  quantity,
  name,
  email,
  telefono,
  price,
  ticket_id,
  consegna,
  cognome,
  phone,
  stato,
  citta,
  address1,
  address2,
  provincia,
  cap,
  note_address,
  company_name,
  energiaObj,
  codice_sconto,
  contact_time,
  superfice,
  nr_bagni,
  controsoffitti,
  nr_condizionatori,
  impianti,
  checkedList,
  codice_fiscale
) => {
  return instanceAxios
    .post(
      `/ticket/${ticket_id}/update`,
      typee === 1
        ? {
            ...skin,
            price,
            type: typee,
            link,
            nome_agenzia,
            extra_data,
            bagaglio,
            bagaglio_stiva,
          }
        : typee === 2
        ? {
            ...skin,
            price,
            type: typee,
            link,
            nome_agenzia,
            extra_data,
            partenza,
            partenza_stazione,
            andata_time,
            destinazione,
            destinazione_stazione,
            tipologia_biglietto,
            compagnie,
            adulti,
            ragazzi,
            ritorno_date,
          }
        : typee === 3
        ? {
            ...skin,
            price,
            type: typee,
            link,
            nome_agenzia,
            extra_data,
            categoria,
            descrizione_categoria,
            quantity,
            name,
            email,
            telefono,
            destinazione,
            codice_fiscale,
          }
        : typee === 8
        ? {
            ...skin,
            price: 0,
            type: typee,
            tipologia_persona: energiaObj.tipologia_persona,
            tipologia_contratto: energiaObj.tipologia_contratto,
            nome_cognome_consulento: energiaObj.nome_cognome_consulento,
            codice_consulento: energiaObj.codice_consulento,
            telefono: energiaObj.telefono,
            email: energiaObj.email,
            //tipologia_persona === 1
            nome: energiaObj.nome,
            cognome: energiaObj.cognome,
            luogo_nascita: energiaObj.luogo_nascita,
            data_nascita: energiaObj.data_nascita,
            codice_fiscale: energiaObj.codice_fiscale,
            //tipologia_persona else
            ragione_sociale: energiaObj.ragione_sociale,
            p_iva: energiaObj.p_iva,
            nome_cognome_rappresentante: energiaObj.nome_cognome_rappresentante,
            codice_rappresentante: energiaObj.codice_rappresentante,
            //
            residenza_comune: energiaObj.residenza_comune,
            residenza_indirizzo: energiaObj.residenza_indirizzo,
            residenza_civico: energiaObj.residenza_civico,
            residenza_cap: energiaObj.residenza_cap,
            corrispondenza: energiaObj.corrispondenza,
            //corrispondenza  === 2
            corrispondenza_comune: energiaObj.corrispondenza_comune,
            corrispondenza_indirizzo: energiaObj.corrispondenza_indirizzo,
            corrispondenza_civico: energiaObj.corrispondenza_civico,
            corrispondenza_cap: energiaObj.corrispondenza_cap,
            //
            fornitura: energiaObj.fornitura,
            //fornitura ===2
            fornitura_comune: energiaObj.fornitura_comune,
            fornitura_indirizzo: energiaObj.fornitura_indirizzo,
            fornitura_civico: energiaObj.fornitura_civico,
            fornitura_cap: energiaObj.fornitura_cap,
            //
            confermo_fornitura: energiaObj.confermo_fornitura,
            confermo_econimoche: energiaObj.confermo_econimoche,
            confermo_informativa: energiaObj.confermo_informativa,
            confermo_presa_visione: energiaObj.confermo_presa_visione,
            marketing: energiaObj.marketing,
            dati_personali: energiaObj.dati_personali,
          }
        : typee === 12
        ? {
            ...skin,
            type: typee,
            nome_agenzia,
            nome: name,
            cognome,
            email,
            phone,
            citta,
            price,
          }
        : {
            ...skin,
            type: typee,
            link,
            nome_agenzia,
            extra_data,
            price: parseFloat(price),
            consegna,
            nome: name,
            cognome,
            email,
            phone,
            stato,
            citta,
            address1,
            address2,
            provincia,
            cap,
            note_address,
            company_name,
          }
    )
    .catch((error) => ({ error }))
}
export const sendVisureDetailsReq = (
  type,
  codice_fiscale,
  provincia,
  address,
  telefono,
  email,
  nome,
  cognome,
  data_di_nascita,
  luogo_di_nascita,
  ragione_sociale,
  p_iva,
  comune,
  servizi,
  price,
  sc
) => {
  return instanceAxios
    .post(
      "/buy/visure",
      type === 1
        ? {
            ...skin,
            type,
            codice_fiscale,
            provincia,
            address,
            telefono,
            email,
            nome,
            cognome,
            data_di_nascita,
            luogo_di_nascita,
            servizi,
            price,
            sc: parseInt(sc),
          }
        : {
            ...skin,
            type,
            ragione_sociale,
            p_iva,
            codice_fiscale,
            provincia,
            comune,
            address,
            email,
            telefono,
            luogo_di_nascita,
            servizi,
            price,
            sc: parseInt(sc),
          }
    )
    .catch((error) => ({ error }))
}

export const userConfirmation = (
  setButtonsSupport,
  ticket_id,
  status,
  c,
  recall,
  document,
  isVisure
) => {
  return instanceAxios
    .post(`/${isVisure ? "visura" : "ticket"}/${ticket_id}/changeStatus`, {
      ...skin,
      status,
      document,
      ...sepaObject(),
    })
    .then((res) => {
      if (res.status === 200 && c) {
        setButtonsSupport({ status: false, instance: null })
        c(false)
        notification.open({
          message: "Hai ricevuto una notifica",
          description: res.data.message,
        })
        recall()
      }
    })
}
export const getVisureReq = () => {
  return instanceAxios
    .get(`/visure`, {
      params: {
        ...skin,
      },
    })

    .catch((error) => ({ error }))
}
export const uploadPdf = (id, document, isVisura) => {
  return instanceAxios

    .post(`/${isVisura ? "visura" : "ticket"}/${id}/addDocument`, {
      ...skin,
      document,
    })
    .then((res) => {
      if (res.status === 200) {
        notification.open({
          message: "Upload Notifica!",
          description: res.data.message,
        })
      }
    })
}
export const getVisureByVisureIdReq = ({ visura_id }) => {
  return instanceAxios
    .get(`/visura/${visura_id}`, {
      params: {
        ...skin,
      },
    })
    .catch((error) => ({ error }))
}

export const updateVisuraReq = (
  visura_id,
  type,
  codice_fiscale,
  provincia,
  address,
  telefono,
  email,
  price,

  ragione_sociale,
  p_iva,
  comune,

  nome,
  cognome,
  data_di_nascita,
  luogo_di_nascita,
  servizi
) => {
  return instanceAxios
    .post(
      `/visura/${visura_id}/update`,
      type === 1
        ? {
            ...skin,
            type,
            codice_fiscale,
            provincia,
            address,
            telefono,
            email,
            nome,
            cognome,
            data_di_nascita,
            luogo_di_nascita,
            price,
            servizi,
          }
        : {
            ...skin,
            type,
            codice_fiscale,
            provincia,
            address,
            telefono,
            email,
            ragione_sociale,
            p_iva,
            comune,
            price,
            servizi,
          }
    )
    .catch((error) => ({ error }))
}
export const getAgentByUserIdReq = (user_id, skin_id) => {
  return instanceAxios
    .get(`/agent/${user_id}`, {
      params: {
        ...(skin_id ? (skin_id !== -1 ? skin : skin_id) : skin),
      },
    })
    .catch((error) => ({ error }))
}
export const getUserByUserIdReq = (user_id, skin_id) => {
  return instanceAxios
    .get(`/user/${user_id}`, {
      params: {
        ...(skin_id ? (skin_id !== -1 ? skin : skin_id) : skin),
      },
    })
    .catch((error) => ({ error }))
}
export const getSkinsReq = () => {
  return instanceAxios
    .get("/skins", {
      ...skin,
    })
    .catch((error) => ({ error }))
}
export const getFaturaDetailsReq = (user_id, year, month) => {
  return instanceAxios
    .post("/users/report", {
      ...skin,
      user_id,
      year,
      month,
    })
    .catch((error) => ({ error }))
}
export const getAllFaturaBySearchReq = (
  username,
  year,
  month,
  perPage,
  page_number,
  raport
) => {
  const paramsToSend = {}
  if (username) {
    paramsToSend["username"] = username
  }
  if (year) {
    paramsToSend["year"] = year
  }
  if (month) {
    paramsToSend["month"] = month
  }
  if (perPage) {
    paramsToSend["limit"] = perPage
  }
  if (page_number) {
    paramsToSend["page_number"] = page_number
  }
  if (raport) {
    paramsToSend["raport"] = raport
  }
  return instanceAxios
    .get("/fatture", {
      params: {
        ...skin,
        ...paramsToSend,
      },
    })
    .catch((error) => ({ error }))
}
export const getAllServicesReq = (skin_id, supplier) => {
  return instanceAxios
    .get("/allServices", {
      params: {
        skin_id,
        supplier,
      },
    })
    .catch((error) => ({ error }))
}
export const sendMailFatturaReq = (file_name) => {
  return instanceAxios
    .post("/mailFattura", {
      ...skin,
      file_name,
    })
    .catch((error) => ({ error }))
}
export const printFatturaReq = (file_name) => {
  return instanceAxios
    .post("/printFattura", {
      ...skin,
      file_name,
    })
    .catch((error) => ({ error }))
}
export const addLogo = (logo, logo2, logo3, logo4, skin_id) => {
  return instanceAxios
    .post(`/skins/${skin_id}/addLogo`, {
      logo,
      logo2,
      logo3,
      logo4,
    })
    .catch((error) => ({ error }))
}
export const AddSkinReq = (name, url, email) => {
  return instanceAxios
    .post(`/skins`, {
      name,
      url,
      email,
    })
    .catch((error) => ({ error }))
}
export const AddExtraDataReq = (
  mail,
  address,
  cel,
  link1,
  link2,
  link3,
  link4,
  link5,
  link6,
  fb,
  ig,
  pin,
  yt,
  primo_color,
  secondo_color,
  background_color,
  head_color,
  skin_id
) => {
  return instanceAxios
    .post(`/skins/${skin_id}/updateExtra`, {
      mail,
      address,
      cel,
      link1,
      link2,
      link3,
      link4,
      link5,
      link6,
      fb,
      ig,
      pin,
      yt,
      primo_color,
      secondo_color,
      background_color,
      head_color,
      skin_id,
    })
    .catch((error) => ({ error }))
}
export const AddInfoFinanzReq = (
  nome_banca,
  societa_beneficiaria,
  iban,
  tipo_affitto,
  canone_mensile,
  agenzia_percentuale,
  agende_percentuale,
  metodo_affitto,
  skin_affitto,
  minimum_agenzies,
  ranges,
  skin_id
) => {
  return instanceAxios
    .post(`/skins/${skin_id}/updateInfoFinanziario`, {
      nome_banca,
      societa_beneficiaria,
      iban,
      tipo_affitto,
      canone_mensile,
      agenzia_percentuale,
      agende_percentuale,
      metodo_affitto,
      skin_affitto,
      minimum_agenzies,
      ranges,
    })
    .catch((error) => ({ error }))
}
export const AddSuperAdminReq = (
  first_name,
  last_name,
  gender,
  username,
  email,
  phone,
  personal_number,
  password,
  confirm_password,
  address,
  city,
  comune_code,
  cap,
  country,
  birth_place,
  birth_country,
  birthday,
  a_ragione_sociale,
  a_p_iva,
  a_codice_fiscale,
  skin_id
) => {
  return instanceAxios
    .post(`/skins/${skin_id}/addSuperAdmin`, {
      first_name,
      last_name,
      gender,
      username,
      email,
      phone,
      personal_number,
      password,
      confirm_password,
      address,
      city,
      comune_code,
      cap,
      country,
      birth_place,
      birth_country,
      birthday,
      a_ragione_sociale,
      a_p_iva,
      a_codice_fiscale,
    })

    .catch((error) => ({ error }))
}
export const widgetPaymentsReq = (skin_id) => {
  return instanceAxios
    .get("/widgetPayments", {
      params: {
        ...(skin_id && skin_id !== -1 ? { skin_id } : {}),
      },
    })
    .catch((error) => ({ error }))
}
export const getStatisticheReq = (skin_id, from, to, username, service) => {
  return instanceAxios
    .get("/statistiche", {
      params: {
        ...(skin_id && skin_id !== -1 ? { skin_id } : {}),
        ...(from && from !== "" ? { from } : {}),
        ...(to && to !== "" ? { to } : {}),
        ...(username && username !== "" ? { username } : {}),
        ...(service && service !== "" ? { service } : {}),
      },
    })
    .catch((error) => ({ error }))
}
export const ServiceChangeStatusReq = (
  name,
  full_name,
  company_id,
  active,
  skin_id
) => {
  return instanceAxios
    .post(`/companies/${company_id}/update`, {
      name,
      full_name,
      active,
      ...(skin_id && skin_id !== -1 ? { skin_id } : skin),
    })
    .catch((error) => ({ error }))
}

export const customVoucher = (service_id, importo, username, codice_fiscale) => {
  const urlAddition =
    service_id in gamesApiByServiceId && gamesApiByServiceId[service_id]
      ? gamesApiByServiceId[service_id]
      : "bgameVoucher"
  return instanceAxios
    .post(`/buy/${urlAddition}`, {
      service_id,
      ...(importo ? { importo: importo } : {}),
      ...(codice_fiscale ? { codiceFiscale: codice_fiscale } : {}),
      ...skin,
      ...sepaObject(username),
    })
    .catch((error) => ({ error }))
}

export const buyExalogicReq = (service_id, importo, username, codice_fiscale) => {
  return instanceAxios
    .post(`/buy/exalogic`, {
      service_id,
      ...(importo ? { importo: importo } : {}),
      ...(codice_fiscale ? { codiceFiscale: codice_fiscale } : {}),
      ...skin,
      ...sepaObject(username),
    })
    .catch((error) => ({ error }))
}

export const StatisticheMainReq = () =>
  instanceAxios
    .get(`/statisticheMain`, {
      params: {
        ...skin,
      },
    })
    .catch((error) => ({ error }))
export const fetchBolletiniRequest = (
  codiceFiscaleOrPartitaIvaIsRequired,
  service_id,
  person_type,
  via_piazza,
  citta,
  importo,
  tipologia,
  numero_conto_corrente,
  causale,
  nome,
  cognome,
  codice_fiscale,
  denominazione,
  partita_iva,
  codice_identificativo,
  dynamicFields,
  username
) => {
  return instanceAxios
    .post(`/sepafin/freeBookBollettini`, {
      service_id,
      person_type,
      codiceFiscaleOrPartitaIvaIsRequired,
      via_piazza,
      citta,
      importo: importo.toString(),
      tipologia: parseInt(tipologia),
      numero_conto_corrente,
      causale,
      codice_identificativo,
      ...(Object.keys(dynamicFields || {}).length ? dynamicFields : {}),
      ...(person_type === "F"
        ? { nome, cognome, codice_fiscale }
        : { denominazione, partita_iva }),
      ...skin,
    })
    .catch((error) => ({ error }))
}

export const buyTicketOnlineReq = (
  typee,
  link,
  nome_agenzia,
  extra_data,
  price,
  consegna,
  nome,
  cognome,
  email,
  phone,
  stato,
  citta,
  address1,
  address2,
  provincia,
  cap,
  note_address,
  company_name,
  codice_sconto,
  contact_time,
  superfice,
  nr_bagni,
  controsoffitti,
  nr_condizionatori,
  impianti,
  checkedList
) =>
  instanceAxios
    .post(`/buy/ticket`, {
      ...skin,
      type: typee,
      link,
      nome_agenzia,
      extra_data,
      price: parseFloat(price),
      consegna,
      ...(consegna === 2
        ? {
            nome,
            cognome,
            email,
            phone,
            stato,
            citta,
            address1,
            address2,
            provincia,
            cap,
            note_address,
            codice_sconto,
            contact_time,
            superfice,
            nr_bagni,
            controsoffitti,
            nr_condizionatori,
            impianti,
            checkedList,

            ...(company_name ? { company_name } : {}),
          }
        : {}),
    })
    .catch((error) => ({ error }))

export const buyAssicurazioneReq = (
  typee,
  nome,
  cognome,
  email,
  telefono,
  codice_fiscale,
  via,
  nr_civicio,
  cap,
  citta,
  provincia,
  situazione,
  tipo,
  targa,
  accesories,
  valore,
  note,
  username
) =>
  instanceAxios
    .post(`/buy/ticket`, {
      ...skin,
      type: typee,
      nome,
      cognome,
      email,
      telefono,
      codice_fiscale,
      via,
      nr_civicio,
      cap,
      citta,
      provincia,
      situazione,
      tipo,
      targa,
      accesories,
      valore,
      note,
      ...sepaObject(username),
    })
    .catch((error) => ({ error }))

export const pagoPaRequest = (
  service_id,
  tipologia,
  codice_fiscale_bol,
  codice_aviso,
  tipo_veicolo,
  targa,
  username
) => {
  return instanceAxios
    .post(`/sepafin/book${service_id === "SPPA001" ? "PagoPA" : "Auto"}`, {
      ...skin,
      service_id,
      ...sepaObject(username),
      tipologia,
      ...(service_id === "SPPA001"
        ? { codice_fiscale_bol, codice_aviso }
        : { tipo_veicolo: parseInt(tipo_veicolo), targa }),
      codiceFiscaleOrPartitaIvaIsRequired: false,
    })
    .catch((error) => ({ error }))
}

export const mrPayPagoPaRequest = async (
  service_id,
  tipologia,
  codice_fiscale_bol,
  codice_aviso,
  importo,
  ente_creditore,
  eseguito_da,
  user_id
) => {
  const isMrPay = await getIsMrPay()
  const requestParams = {
    ...skin,
    service_id,
    tipologia,
    codice_fiscale_bol,
    codice_aviso,
    importo: isMrPay ? importo : parseInt(importo),
    ente_creditore,
    eseguito_da,
    user_id,
  }

  const endpoint = isMrPay ? "/test/rechargeBOL" : "/reserve-recharge-bol"

  return instanceAxios.post(endpoint, requestParams).catch((error) => ({ error }))
}

export const getBolletiniAutoReq = (service_id, tipo, targa, user_id) => {
  return instanceAxios
    .post(`/reserve-recharge-bol`, {
      ...skin,
      service_id,
      tipo,
      targa,
      user_id,
    })
    .catch((error) => ({ error }))
}

export const bolletinoCaptureReq = (payload) => {
  return instanceAxios
    .post(`/capture-recharge-bol`, {
      ...skin,
      payload,
    })
    .catch((error) => ({ error }))
}

export const pagoTicketReq = (barcode, isPos, username) =>
  instanceAxios
    .post(`/sepafin/payBollettini`, {
      ...skin,
      barcode,
      ...sepaObject(username),
      ...posObject(isPos),
    })
    .catch((error) => ({ error }))

export const frecciaRequest = (
  service_id,
  importo,
  causale,
  person_type,
  via_piazza,
  citta,
  identificativo_pagamento,
  iban,
  cin_importo,
  cin_intermedio,
  cin_complessivo,
  codice_esenzione,
  nome,
  cognome,
  denominazione,
  isPos,
  username
) =>
  instanceAxios
    .post(`/sepafin/payFreccia`, {
      ...skin,
      service_id,
      importo,
      causale,
      person_type,
      via_piazza,
      ...sepaObject(username),
      citta,
      ...posObject(isPos),
      identificativo_pagamento,
      iban,
      cin_importo,
      cin_intermedio,
      cin_complessivo,
      codice_esenzione,
      nome,
      cognome,
      denominazione,
      codiceFiscaleOrPartitaIvaIsRequired: false,
    })
    .catch((error) => ({ error }))
export const mavRavRequest = (
  service_id,
  person_type,
  via_piazza,
  citta,
  phone_number,
  importo,
  codice,
  nome,
  cognome,
  denominazione,
  causale,
  partita_iva,
  isPos,
  username
) =>
  instanceAxios
    .post(`/sepafin/mavrav`, {
      ...skin,
      service_id,
      person_type,
      via_piazza,
      causale,
      citta,
      ...posObject(isPos),
      ...sepaObject(username),
      phone_number,
      importo,
      codice,
      ...(service_id === "SBOL003" ? { tipologia: "rav" } : { tipologia: "mav" }),
      ...(person_type === "F" ? { nome, cognome } : { denominazione, partita_iva }),
      codiceFiscaleOrPartitaIvaIsRequired: false,
    })
    .catch((error) => ({ error }))

export const payPagoPaReq = (
  service_id,
  total_amount,
  fee_amount,
  pagamento_id,
  isPos,
  username
) => {
  const service = service_id?.service_id || service_id
  return instanceAxios
    .post(`/sepafin/pay${service === "SPPA001" ? "PagoPA" : "Auto"}`, {
      ...skin,
      service_id: service,
      total_amount,
      fee_amount,
      ...sepaObject(username),
      ...posObject(isPos),
      pagamento_id,
    })
    .catch((error) => ({ error }))
}

export const cancelPagamentoReq = (pagamento_id, service_id) =>
  instanceAxios
    .post("/sepafin/cancelPayment", {
      ...skin,
      pagamento_id,
      service_id,
      ...sepaObject(),
    })
    .catch((error) => ({ error }))
export const internetRegisterReq = (name, indirizo, phone, user_id) =>
  instanceAxios
    .post(`/internet/register`, {
      ...skin,
      full_name: name,
      indirizo,
      phone,
      user_id,
    })
    .catch((error) => ({ error }))

export const bokkingF24Req = (
  service_id,
  person_type,
  via_piazza,
  citta,
  codice_ufficio,
  codice_atto,
  data_pagamento,
  importo,
  taxes_array,
  nome,
  cognome,
  codice_fiscale,
  denominazione,
  codiceOperazione,
  partita_iva,
  username
) =>
  instanceAxios
    .post(`/sepafin/bookF24`, {
      ...skin,
      service_id,
      person_type,
      via_piazza,
      citta,
      codice_ufficio,
      codice_atto,
      data_pagamento,
      importo,
      ...sepaObject(username),
      ...(codiceOperazione ? { codice_operazione: codiceOperazione } : {}),
      taxes_array,
      ...(person_type === "F"
        ? { nome, cognome, codice_fiscale }
        : { denominazione, partita_iva }),
      codiceFiscaleOrPartitaIvaIsRequired: true,
    })
    .catch((error) => ({ error }))

export const payFReq = (service_id, importo, fee, pagamento_id, isPos, username) =>
  instanceAxios
    .post(`/sepafin/payF24`, {
      ...skin,
      service_id,
      importo,
      fee,
      ...sepaObject(username),
      ...posObject(isPos),
      pagamento_id,
    })
    .catch((error) => ({ error }))
export const createUserBgameReq = (
  nome,
  cognome,
  data_nascita,
  sesso,
  id_nazione_nascita,
  id_comune_nascita,
  codice_fiscale,
  id_comune_residenza,
  indirizzo,
  cap,
  telefono,
  cellulare,
  email,
  id_tipo_documento,
  documento,
  rilasciato_da,
  data_rilascio,
  data_scadenza,
  id_nazione_cittadinanza,
  nick,
  password,
  question,
  answer
) =>
  instanceAxios
    .post(`/bgame/createUser`, {
      ...skin,
      nome,
      cognome,
      data_nascita,
      sesso,
      id_nazione_nascita: parseInt(id_nazione_nascita),
      id_comune_nascita: parseInt(id_comune_nascita),
      codice_fiscale,
      id_comune_residenza: parseInt(id_comune_residenza),
      indirizzo,
      cap,
      telefono,
      cellulare,
      email,
      id_tipo_documento: parseInt(id_tipo_documento),
      documento,
      rialsciato_da: rilasciato_da,
      data_rilascio,
      data_scadenza,
      id_nazione_cittadinanza: parseInt(id_nazione_cittadinanza),
      nick,
      password,
      question,
      answer,
    })
    .catch((error) => ({ error }))

export const getRegistrazioneDataReq = () =>
  instanceAxios
    .get(`/bgame/registrazioneData`, {
      params: {
        ...skin,
      },
    })
    .catch((error) => ({ error }))

export const getCompanyNameByContoCorrentoReq = (numeroContoCorrente, tipologia) =>
  instanceAxios
    .post(`/sepafin/freeBookBollettini/codiceFiscaleRequired`, {
      numero_conto_corrente: numeroContoCorrente,
      tipologia,
    })
    .catch((error) => ({ error }))

export const sepafinCredentials = (sep_username, sep_password) =>
  instanceAxios
    .post(`/users/changeSepafinCredentials`, {
      ...skin,
      sep_username,
      sep_password,
    })
    .catch((error) => ({ error }))

export const sepafinLogin = (sepafin_username, sepafin_password) => {
  return instanceAxios
    .post(`/getSepafinToken`, {
      sepafin_username,
      sepafin_password,
      ...skin,
    })
    .catch((error) => {})
}

export const sepafinForgetPassword = (sepafin_username) => {
  return instanceAxios
    .post(`/sepafin/resetPassword`, {
      sepafin_username,
      ...skin,
    })
    .catch((error) => {})
}

export const getAgencyPosReq = (username) => {
  return instanceAxios
    .post(`/sepafin/getAgencyPos`, {
      ...sepaObject(username),
      ...skin,
    })
    .catch((error) => ({ error }))
}

export const nuovoBillerReq = (params) => {
  const {
    denomination,
    numero_conto_corrente,
    note,
    file_content_type,
    file_base64,
  } = params
  return instanceAxios
    .post(`/sepafin/requestNewBiller`, {
      denomination,
      numero_conto_corrente,
      note,
      file_content_type,
      file_base64,
      ...skin,
    })
    .catch((error) => ({ error }))
}

export const activateDeactivateSepafinReq = (userId, supplier) => {
  return instanceAxios
    .post(`/users/sepafinactivation/${userId}`, {
      ...skin,
      supplier,
    })
    .catch((error) => ({ error }))
}

export const getVisureByServiceIdReq = (service_id) =>
  instanceAxios
    .get(`/visuredetails`, {
      params: {
        ...skin,
        service_id,
      },
    })
    .catch((error) => ({ error }))
export const buyVisureReq = (service_id, fields, extra_options, username) => {
  return instanceAxios
    .post(`/visurebuy`, {
      ...fields,
      service_id,
      ...sepaObject(username),
      ...(extra_options.length ? { extra_options } : {}),
      ...skin,
    })
    .catch((error) => ({ error }))
}

export const setVisureStaticInfo = (params) =>
  instanceAxios
    .post(`/setvisurestaticinfo`, { ...params, ...skin })
    .catch((error) => ({ error }))

export const buyBitNovoVoucherReq = (params) =>
  instanceAxios
    .post(`/buybitnovovoucher`, {
      ...params,
      ...skin,
      ...sepaObject(params.username),
    })
    .catch((error) => ({ error }))

export const getVisureDocumentReq = (barcode) =>
  instanceAxios
    .get(`/visuredocument`, {
      params: {
        ...skin,
        barcode,
      },
    })
    .catch((error) => ({ error }))

export const getVisureStaticInfo = (service_id) =>
  instanceAxios
    .get(`/getvisurestaticinfo`, {
      params: {
        ...skin,
        service_id,
      },
    })
    .catch((error) => ({ error }))

export const getBussinessInfosReq = (piva_or_cf, username) =>
  instanceAxios
    .get(`/visure/businessinfos`, {
      params: {
        ...skin,
        piva_or_cf,
        ...sepaObject(username),
      },
    })
    .catch((error) => ({ error }))

export const getBookingsBolMrReq = async (id_prenotazione, service_id, user_id) => {
  const requestParams = {
    ...skin,
    id_prenotazione,
    service_id: parseInt(service_id),
    user_id,
  }
  const isMrPay = await getIsMrPay()
  const endpoint = isMrPay ? `/getBookingsBolMr` : `/reserve-recharge-bol`

  return isMrPay
    ? instanceAxios
        .get(endpoint, { params: requestParams })
        .catch((error) => ({ error }))
    : instanceAxios.post(endpoint, requestParams).catch((error) => ({ error }))
}

export const barcodeReaderReq = (barcode_data) =>
  instanceAxios
    .post(`/barcodereader`, {
      ...skin,
      barcode_data,
    })
    .catch((error) => ({ error }))

export const fetchContiAccount = (service_id) =>
  instanceAxios
    .get(`/contigioco/getAccount`, {
      params: {
        ...skin,
        service_id,
      },
    })
    .catch((error) => ({ error }))

export const setNewContiAccountReq = (service_id, conti_username, conti_password) =>
  instanceAxios
    .post(`/contigioco/setAccount`, {
      ...skin,
      service_id,
      conti_username,
      conti_password,
    })
    .catch((error) => ({ error }))

export const getCouriersReq = (
  service_id,
  sender_country_code,
  sender_cap,
  sender_place_name,
  receiver_country_code,
  receiver_cap,
  receiver_place_name,
  packages_details
) =>
  instanceAxios
    .post(`/spedizione/checkcap`, {
      ...skin,
      service_id,
      sender_country_code,
      sender_cap,
      sender_place_name,
      receiver_country_code,
      receiver_cap,
      receiver_place_name,
      packages_details,
    })
    .catch((error) => ({ error }))

export const setShippingInfoReq = (
  service_id,
  shipping_id,
  nominativoMittente,
  pressoMittente,
  indirizzoMittente,
  comuneMittente,
  provinciaMittente,
  capMittente,
  telefonoMittente,
  emailMittente,
  nominativoDestinatario,
  pressoDestinatario,
  indirizzoDestinatario,
  comuneDestinatario,
  provinciaDestinatario,
  capDestinatario,
  telefonoDestinatario,
  emailDestinatario,
  noteDestinatario,
  destinatarioIsBusiness,
  packages_details
) =>
  instanceAxios
    .post(`/spedizione/setshippinginfo`, {
      ...skin,
      service_id,
      shipping_id,
      nominativoMittente,
      pressoMittente,
      indirizzoMittente,
      comuneMittente,
      provinciaMittente,
      capMittente,
      telefonoMittente,
      emailMittente,
      nominativoDestinatario,
      pressoDestinatario,
      indirizzoDestinatario,
      comuneDestinatario,
      provinciaDestinatario,
      capDestinatario,
      telefonoDestinatario,
      emailDestinatario,
      noteDestinatario,
      destinatarioIsBusiness,
      packages_details,
    })
    .catch((error) => ({ error }))

export const buyShippingOrderReq = (service_id, shipping_info_id) =>
  instanceAxios
    .post(`/spedizione/buy`, {
      ...skin,
      service_id,
      shipping_info_id,
    })
    .catch((error) => ({ error }))

export const fetchAnnualiStatitiche = (skinId) =>
  instanceAxios
    .get("/getAnnualiStatitiche", {
      params: {
        skin_id: skinId,
      },
    })
    .catch((error) => ({ error }))

export const fetchLatestServiceStatistic = (skinId) =>
  instanceAxios
    .get("/getLatestServiceStatistic", {
      params: {
        skin_id: skinId,
      },
    })
    .catch((error) => ({ error }))

export const sendInfoEmailReq = (city, email, phone, name, surname, id) => {
  return instanceAxios
    .post("/send-email-register-user", {
      city,
      email,
      phone,
      name,
      surname,
      id,
    })
    .catch((error) => ({ error }))
}

export const createExalogicAccountReq = (name, username, password, domain) => {
  return instanceAxios
    .post("/create-exalogic-accounts", {
      name,
      username,
      password,
      domain,
    })
    .catch((error) => ({ error }))
}

export const getContiGiocoServicesReq = () => {
  return instanceAxios.get("get-contigioco-acc").catch((error) => ({ error }))
}

export const createGiftCardReq = (amount, cf) => {
  return instanceAxios
    .post("/gift-cards/create", {
      amount,
      cf,
    })
    .catch((error) => ({ error }))
}
