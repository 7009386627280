import React, { Component, useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { AuthActions, MainActions } from "redux-store/models";
import { notification, Tooltip, Radio } from "antd";
import { Loader } from "shared-components";
import { Checkbox } from "antd";
import { capitalize } from "lodash";
import { toCurrency,visureSlector } from "utils/HelperFunc";
import { getVisureByServiceIdReq } from "../../../services/auth";
import "./VisureStyles.css";
import VisureFilter from "./VisureFilter"
import _ from "lodash";
class FormVisureDomain extends Component {
  state = {
    activeService: "",
    activeCategory: null,
    cateogryChosenIndex: 0,
    loading: false,
    selectedServices: [this.getActiveServiceId()].filter((s) => Boolean(s)),
    allSelectableServices: [],
  };
  setActiveService = (activeService) => {
    this.setState({ activeService });
  };
  setActive = (activeState) => {
    this.setState({ ...activeState });
  };

  setFirstActiveCategory = () => {
    const { servicesVisure } = this.props;
    const firstCategory = Object.keys(servicesVisure || {})[0];
    const firstService = servicesVisure && firstCategory && servicesVisure[firstCategory].services[0];
    this.setActive({ activeService: firstService, activeCategory: firstCategory, categoryShown: firstCategory });
  };

  async componentDidMount() {
    await this.props.getServices();
    await this.setFirstActiveCategory();
  }

  stopLoading = () =>
    this.setState((state) => {
      const fieldIds = this.getFieldIds();
      let newState = {};
      fieldIds.forEach((id) => (newState[id] = undefined));
      return { loading: false, ...newState };
    });

  fetchDataDisponibiliInfos = () => {
    const { visureByServiceIdDetails } = this.props;
    const service_id = visureByServiceIdDetails?.service_id;
    (this.state.allSelectableServices || []).forEach((service) => {
      if (!this.state[service] && this.getActiveServiceId() === service_id) {
        if (service_id === service) {
          const { visure_nome, visure_price_total, visure_price_withoutIVA, visure_iva } =
            visureByServiceIdDetails || {};
          this.setState({ [service_id]: { visure_nome, visure_price_total, visure_price_withoutIVA, visure_iva } });
        } else {
          getVisureByServiceIdReq(service).then((res) => {
            const { visure_nome, visure_price_total, visure_price_withoutIVA, visure_iva } = res?.data || {};
            this.setState({ [service]: { visure_nome, visure_price_total, visure_price_withoutIVA, visure_iva } });
          });
        }
      }
    });
  };

  stringify = (val) => (typeof val === "string" ? val : JSON.stringify(val || []));

  isDifferent = (a, b) => !_.isEqual(this.stringify(a), this.stringify(b));

  componentWillUpdate(prevProps, prevState) {
    const { allSelectableServices } = this.state;
    const { visureByServiceIdDetails } = this.props;
    const similarServices = visureByServiceIdDetails?.dati_disponibili;
    const activeServiceId = this.getActiveServiceId();
    const newSelectedServices = activeServiceId && [
      ...new Set([activeServiceId, ...(similarServices || [])].filter((s) => Boolean(s))),
    ];
    if (activeServiceId && newSelectedServices && this.isDifferent(newSelectedServices, allSelectableServices)) {
      this.setState({
        allSelectableServices: newSelectedServices,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeService, cateogryChosenIndex, allSelectableServices,categoryShown } = this.state;
    const { service_id, type, cateogries } = activeService || {};
    const { visureByServiceIdDetails, servicesVisure, setBussinessInfos, getVisureByServiceId,visureMobileDetails } = this.props;
    const groupServiceId = () => cateogries[cateogryChosenIndex || 0].details.service_id;
    const changedActiveService = JSON.stringify(prevState?.activeService || {}) !== JSON.stringify(activeService || {});
    const changedCategory = prevState.cateogryChosenIndex !== cateogryChosenIndex;
       const isMobile = this.props.screenWidth <= 1024


       if(isMobile){
    if(visureMobileDetails?.activeCategory !== categoryShown && visureMobileDetails?.activeCategory ){
      this.setState({categoryShown:visureMobileDetails?.activeCategory })
    }
    if(JSON.stringify(activeService || {}) !== JSON.stringify(visureMobileDetails?.activeService || {})){
      this.setState({activeService:visureMobileDetails?.activeService })
    }
  }

    if (changedCategory || changedActiveService) {
      this.setState({ loading: true }, () => {
        setBussinessInfos(null);
        this.setState({ bussinessSearch: null });
        this.setState({ selectedServices: [this.getActiveServiceId()].filter((s) => Boolean(s)) });
        getVisureByServiceId(type === "group" && cateogries ? groupServiceId() : service_id, () => this.stopLoading());
      });
      if (!changedCategory && changedActiveService) {
        this.setState({ cateogryChosenIndex: 0 });
        this.setState({ selectedServices: [this.getActiveServiceId()].filter((s) => Boolean(s)) });
      }
    }

    if (this.isDifferent(visureByServiceIdDetails, prevProps?.visureByServiceIdDetails)) {
      let serviceId = visureByServiceIdDetails?.service_id;
      const { visure_nome, visure_price_total, visure_price_withoutIVA, visure_iva } = visureByServiceIdDetails || {};
      serviceId &&
        this.setState({ [serviceId]: { visure_nome, visure_price_total, visure_price_withoutIVA, visure_iva } });
    }

    if (
      visureByServiceIdDetails &&
      (this.isDifferent(allSelectableServices, prevState?.allSelectableServices) ||
        this.isDifferent(visureByServiceIdDetails, prevProps?.visureByServiceIdDetails))
    ) {
      this.fetchDataDisponibiliInfos();
    }

    if (Object.keys(prevProps?.servicesVisure || {}).length === 0 && Object.keys(servicesVisure || {}).length) {
      this.setFirstActiveCategory();
    }
    const campi = Object.keys(visureByServiceIdDetails?.visure_fields?.campi || {});
    const prevCampi = Object.keys(prevProps.visureByServiceIdDetails?.visure_fields?.campi || {});
    if (
      JSON.stringify(campi || {}) !== JSON.stringify(prevCampi || {}) ||
      !this.state?.rightCamps ||
      !this.state?.leftCamps
    ) {
      let leftCamps = [];
      let rightCamps = [];
      for (let i = 0; i <= campi.length; i = i + 2) {
        if (campi[i] !== undefined) {
          leftCamps.push(campi[i]);
          rightCamps.push(campi[i + 1]);
        }
      }
      this.setState({ leftCamps, rightCamps });
    }
  }

  getActiveServiceId(state = this.state) {
    const { activeService, cateogryChosenIndex } = state || {};
    return (
      activeService?.service_id ||
      (activeService?.cateogries && activeService?.cateogries[cateogryChosenIndex].details.service_id)
    );
  }

  purchase = (fields, index) => {
    const clear =
      index + 1 === this.state.selectedServices.length ? this.clear : () => this.purchase(fields, index + 1);
    this.setState({ selectedServiceIndex: index });
    return this.props.buyVisure(this.state.selectedServices[index], fields, this.getOptionChecked(), clear, this.props.accountInfo.profile.hasSepafinCredentials,this.props.accountInfo.profile.username);
  };

  submit = () => {
    const { visureByServiceIdDetails, bussinessInfo } = this.props;
    const validationStringRepalced = this.getValidazione();
    const hasFile = Object.keys(visureByServiceIdDetails?.visure_fields?.campi).filter((camp) => visureByServiceIdDetails?.visure_fields?.campi[camp].tipo === "file").length > 0;
    var fieldLoaded = hasFile ? true : !validationStringRepalced.includes("$");
    if (this.cciaNreaValidation()) {
      if ((fieldLoaded && !!eval(validationStringRepalced)) || bussinessInfo) {
        let fields = {};
        const campi = visureByServiceIdDetails?.visure_fields?.campi || {};
        this.getFieldIds().forEach((fieldKey) => {
          const serviceName = campi[fieldKey]?.nome.toLowerCase();
          fields[fieldKey] = bussinessInfo
            ? bussinessInfo[serviceName === "nrea" ? "rea" : serviceName]
            : this.state[fieldKey];
        });
        let index = Number(this.state.selectedServiceIndex || 0);
        this.purchase(fields, index);
      } else {
        notification["warning"]({
          message: (
            <div>
              {this.getValidazioneUserString()
                .split("<br />")
                .map((t, i) => (
                  <div key={t}>{`${i === 0 ? "" : `- `}${t}`}</div>
                ))}
            </div>
          ),
        });
      }
    } else {
      notification["warning"]({
        message: "Nrea deve essere numero e Cciaa stringa di due caratteri",
      });
    }
  };

  idToNum = (id) => Number((id || "").replaceAll("$", ""));

  getFieldIds = () =>
    Object.keys(this.state)
      .filter((key) => key.includes("$"))
      .sort((a, b) => this.idToNum(b) - this.idToNum(a));

  getFieldNames = () => {
    let fieldNames = {};
    const campi = this.props.visureByServiceIdDetails?.visure_fields?.campi || {};
    this.getFieldIds().forEach((fieldId) => {
      const fieldName = (campi[fieldId] && campi[fieldId].nome) || "";
      if (fieldName && fieldName !== "") {
        fieldNames[fieldName] = fieldId;
      }
    });
    return fieldNames;
  };

  getValidazioneUserString = () => {
    const { validazione, campi } = this.props.visureByServiceIdDetails?.visure_fields || {};
    let userString = validazione || "";
    this.getFieldIds().forEach(
      (validKey) => (userString = this.replaceStringForEval(userString, validKey, campi[validKey]?.nome))
    );
    const replaceMap = {
      "&&": "e",
      ") ||": `<br /> `,
      "||": `o`,
      "(": ``,
      "*": ``,
    };
    Object.keys(replaceMap).forEach((replaceKey) => {
      userString = userString.replaceAll(replaceKey, replaceMap[replaceKey]);
    });
    return `Selezionare una di queste combinazioni di campi:<br /> ` + userString;
  };

  replaceStringForEval = (string, validKey, value) => {
    return (string || "").replaceAll(validKey, typeof value === "string" ? `'${value}'` : value);
  };

  getValidazione = () => {
    let validazione = this.props.visureByServiceIdDetails?.visure_fields?.validazione || "";
    this.getFieldIds().forEach(
      (validKey) => (validazione = this.replaceStringForEval(validazione, validKey, this.state[validKey]))
    );
    return this.props.bussinessInfo ? "true" : validazione;
  };

  getFormSide(campKeys) {
    const campi = this.props.visureByServiceIdDetails?.visure_fields?.campi || {};
    return (
      <FormSide campKeys={campKeys} campi={campi} setState={(state) => this.setState(state)} campState={this.state} />
    );
  }

  getOptionChecked = () => {
    const options = this.props.visureByServiceIdDetails?.visure_options || [];
    let optionsChecked = [];
    options.forEach(({ nome }) => {
      if (this.state[nome] && !(nome in optionsChecked)) optionsChecked.push(nome);
    });
    return optionsChecked;
  };

  beforeSubmit = () => this.setState({ confirmSubmit: true, selectedServiceIndex: 0 });

  searchChange = (e) => this.setState({ bussinessSearch: e.target.value });

  beforeSearch = () => this.setState({ beforeSearch: true });
    closeBeforeSearch = () => this.setState({ beforeSearch: false });

  search = () => {
    this.props.getBussinessInfos(this.state.bussinessSearch,this.closeBeforeSearch,this.props.accountInfo.profile.hasSepafinCredentials,this.props.accountInfo.profile.username);
  }

  clear = () => {
    this.getFieldIds().forEach((key) => this.setState({ [key]: undefined }));
    this.setState({ confirmSubmit: false, selectedServiceIndex: 0 });
  };

  getIsVal = () => {
    try {
      const validationStringRepalced = this.getValidazione();
      const fieldLoaded = !validationStringRepalced.includes("$");
      return fieldLoaded && !!eval(validationStringRepalced);
    } catch {
      return null;
    }
  };

  visureSearch = (e) => {
    return this.setState({ searchedVisure: e.target.value });
  };

  cciaNreaValidation = () => {
    const names = Object.keys(this.getFieldNames());
    if (!names.includes("Cciaa") || !names.includes("NRea") || this.props.bussinessInfo) return true;

    const indexOfCcia = names.indexOf("Cciaa");
    const indexOfNrea = names.indexOf("NRea");
    const ids = Object.values(this.getFieldNames());

    let validCcia = true;
    let validNrea = true;

    if (indexOfCcia >= 0) validCcia = !!(typeof ids[indexOfCcia] === "string" && this.state[ids[indexOfCcia]].length === 2);
    if (indexOfNrea >= 0) validNrea = !isNaN(this.state[ids[indexOfNrea]]);

    return validNrea && validCcia;
  };

  getVisureInfos = (data, className) => {
    return (
      !!data?.length && (
        <div className={`rightForm--${className}`}>
          {data.map(({ type, value }) =>
            type === "title" ? (
              <h3 key={value}>{value}</h3>
            ) : type === "paragraph" ? (
              <p key={value}>{value}</p>
            ) : (
              <li key={value}>{value}</li>
            )
          )}
        </div>
      )
    );
  };

  getConsegnaAndCost = (cost) => {
    return (
      <div className="visure-cost">
        <div>
          <i className="fal fa-wallet"></i>
          <label>Costo:</label>
          <span>{toCurrency(cost)}</span>
          <label>(+ iva)</label>
        </div>
        <div>
          <i className="fal fa-clock"></i>
          <label>CONSEGNA</label>
          <span className="consegna__status">{this.props.visureByServiceIdDetails?.consegna}</span>
        </div>
      </div>
    );
  };

  getSubmitRow = (visureName, priceWithoutIva, price) => {
    return (
      <React.Fragment>
        <span>{visureName}</span>
        <span>{toCurrency(priceWithoutIva)}</span>
        <span>Totale Iva</span>
        <span>{toCurrency(price)}</span>
      </React.Fragment>
    );
  };

 getActiveService = ()=>{
       const isMobile = this.props.screenWidth <= 1024
       return (isMobile && this.props.visureMobileDetails?.activeService )|| this.state.activeService
 }

 getCategoryShown= () =>{
         const isMobile = this.props.screenWidth <= 1024
         return (isMobile && this.props.visureMobileDetails?.activeCategory) || this.state.categoryShown
 }

  render() {
    const {
    
      loading,
      leftCamps,
      rightCamps,
      bussinessSearch,
      searchedVisure,
      selectedServices,
      allSelectableServices,
    } = this.state;
    const { visureByServiceIdDetails, bussinessInfo,screenWidth } = this.props;
     const isMobile = screenWidth <= 1024
     const activeService = this.getActiveService();
     const categoryShown = this.getCategoryShown();
    const hasSearch = JSON.stringify(["Cciaa", "NRea"]) === JSON.stringify(Object.keys(this.getFieldNames()));
    const {
      visure_iva,
      visure_price_withoutIVA,
      visure_nome,
      faq,
      head_info,
      dati_disponibili,
    } = visureByServiceIdDetails || {};

    const options = visureByServiceIdDetails?.visure_options || [];
    const selectedOptions = Object.keys(this.state)
      .filter((key) => {
        const fieldKey = key.includes("opzione");
        return fieldKey && this.state[key];
      })
      .map((optionKey) => options[Number(optionKey.replace("opzione_", ""))] || {});
    const {
      denominazione,
      piva,
      cf,
      indirizzo,
      cap,
      comune,
      rea,
      dataIscrizione,
      descrizioneAteco,
      pec,
      codAteco,
      dipendenti,
    } = bussinessInfo || {};
    const fullIndirizzo = `${indirizzo} - ${cap} - ${comune}`;
    const isValid = this.getIsVal();
    return (
      <div className={`formsContainer ${isValid ? "is-vaild" : ""}`}>
        <div className={`visures ${isMobile?'is-mobile':""}`}>
          {!isMobile &&<div className="visures--services ">
            <div className="visureHeader">
              <div className="formsContainer--body formsContainer--body__item">
                <div className="rightForm--search">
                  <input placeholder="Search" value={searchedVisure} onChange={this.visureSearch} />
                  <i className={`fas fa-search`}></i>
                </div>
              </div>
            </div>
      <VisureFilter {...{searchedVisure,activeService,categoryShown}}  setActive={this.setActive} />
          </div>}
          <div className="visures--form">
            {loading ? (
              <Loader />
            ) : (
              <div className="formsContainer--body">
                <div className="rightForm">
                  <div className="rightForm--header">
                    <div>
                      {isMobile && <i className="fal fa-chevron-left cursor-pointer" onClick={()=>window.history.back()}></i>}
                    <h1>{activeService?.name}</h1>
                      </div>
                    {this.getConsegnaAndCost(visure_price_withoutIVA)}
                  </div>
                  {activeService?.cateogries && (
                    <div className="rightForm--categories">
                      {activeService?.cateogries.map(({ name }, index) => {
                        const isActive = this.state.cateogryChosenIndex === index;
                        return (
                          <span
                            className={`${isActive ? "is-active" : ""}`}
                            key={name}
                            onClick={() => this.setState({ cateogryChosenIndex: index })}
                          >
                            <div>
                              <i className={`fas fa-${isActive ? "check-circle" : "circle"}`}></i>

                              {name}
                            </div>
                          </span>
                        );
                      })}
                    </div>
                  )}

                  {this.getVisureInfos(JSON.parse(head_info || "[]"), "head-info")}
                  {this.getVisureInfos(JSON.parse(faq || "[]"), "faq")}

                  {hasSearch && (
                    <div className="rightForm--search">
                      <div className="formsContainer--body__item semi">
                        <div className="label">Inserisci P.IVA dell'azienda ed esegui la ricerca </div>
                        <div>
                          <input
                            placeholder="Inserire Partita Iva"
                            value={bussinessSearch}
                            onChange={this.searchChange}
                          />
                          <i className={`fas fa-search`} onClick={this.beforeSearch}></i>
                        </div>
                      </div>
                    </div>
                  )}

                  {bussinessInfo && hasSearch ? (
                    <React.Fragment>
                      <div className="rightForm--bussiness-info">
                        <h2 className="skin-color">{`DATI DELLA SOCIETÀ - ${denominazione}`}</h2>
                        <div className="bussiness-info__row">
                          <p>Partita IVA:</p>
                          <b>{piva}</b>
                          <p>- Codice Fiscale:</p>
                          <b>{cf}</b>
                        </div>
                        <div className="bussiness-info__row">
                          <p>Rag. Sociale: </p>
                          <b className="skin-color">{denominazione}</b>
                        </div>
                        <div className="bussiness-info__row">
                          <p>Indirizzo: </p>
                          <b>{fullIndirizzo}</b>
                        </div>
                        <div className="bussiness-info__row">
                          <p>Rea: </p>
                          <b>{rea}</b>
                        </div>
                        <div className="bussiness-info__row">
                          <p>Data Iscrizione: </p>
                          <b>{dataIscrizione}</b>
                        </div>
                        <div className="bussiness-info__row">
                          <p>Ateco: </p>
                          <b>{descrizioneAteco}</b>
                        </div>
                        <div className="bussiness-info__row">
                          <p>Cod. Ateco: </p>
                          <b>{codAteco}</b>
                        </div>
                        <div className="bussiness-info__row">
                          <p>Pec: </p>
                          <b>{pec}</b>
                        </div>
                        <div className="bussiness-info__row">
                          <p>Dipendenti: </p>
                          <b>{dipendenti}</b>
                        </div>
                        {dati_disponibili && allSelectableServices.length >= 2 && (
                          <div className="rightForm--data-disponibili">
                            <h2>DOCUMENTI DISPONIBILI</h2>
                            <div
                              className={`data-disponibili__form ${!!selectedServices.length ? "" : "cannot-submit"}`}
                            >
                              <div className="dati-checkboxes">
                                {allSelectableServices.map((serviceId) => {
                                  const { visure_nome, visure_price_withoutIVA } = this.state[serviceId] || {};
                                  const isSelected = selectedServices.includes(serviceId);
                                  const select = () => {
                                    if (isSelected) {
                                      this.setState({
                                        selectedServices: [...selectedServices.filter((s) => s !== serviceId)],
                                      });
                                    } else {
                                      this.setState({ selectedServices: [...selectedServices, serviceId] });
                                    }
                                  };
                                  return (
                                    <div className={`checkbox ${isSelected ? "is-selected" : ""}`} onClick={select}>
                                      <h3>{visure_nome}</h3>
                                      <label>Costo: </label>
                                      <div className="cost">
                                        <span>
                                          {" "}
                                          {`${toCurrency(visure_price_withoutIVA)}`} <small>(+ iva)</small>
                                        </span>
                                        <div>{isSelected && <i className="fa fa-check"></i>}</div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                              {!!selectedServices.length &&
                                this.getConsegnaAndCost(
                                  selectedServices
                                    .map((s) => Number(this.state[s]?.visure_price_withoutIVA))
                                    .reduce((a, b) => a + b, 0)
                                )}
                              {!!selectedServices.length && (
                                <div className="formsContainer--body__item submit">
                                  <button onClick={this.beforeSubmit}>Esegui</button>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {!(dati_disponibili && allSelectableServices.length >= 2) && (
                          <div className="formsContainer--body__item submit">
                            <button onClick={this.beforeSubmit}>Esegui</button>
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="rightForm--left">{this.getFormSide(leftCamps)}</div>
                      <div className="rightForm--right">
                        {this.getFormSide(rightCamps)}
                        {(options || []).map(({ tipo, prezzo, nome }) => {
                          return (
                            <div key={`${tipo}${nome}${prezzo}`}>
                              <Checkbox
                                onChange={(e) => {
                                  this.setState({ [nome]: e.target.checked });
                                }}
                              >
                                <span>{capitalize(tipo)}</span>
                                <span>{` ( ${toCurrency(prezzo)} ) `}</span>
                              </Checkbox>
                            </div>
                          );
                        })}

                        <div className="formsContainer--body__item submit">
                          <button onClick={this.beforeSubmit}>Esegui</button>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
         {this.state.beforeSearch && (
          <div className="coffe-payment-modal">
            <div className="modalbackdrop" onClick={this.closeBeforeSearch} />
            <div className="bolletini visure-modal">
              <div className="visure-modal__content">
                <h2>Costo ricerca P. Iva</h2>
                <div className="Message">
                  <div className="content__grid">
                    <span>Totale da Pagare:</span>
                    <span>
                      {toCurrency(0.25)}
                    </span>
                  </div>
                </div>
              </div>
              <div class="Actions">
                <div></div>
                <div class="Actions--Item" onClick={this.search} data-disable="enable" htmltype="submit">
                  <h3>esegui</h3>
                  <img src="/static/media/check-symbol.ed9abdc9.svg" alt="" />
                </div>
                <div class="Actions--Item" onClick={this.closeBeforeSearch}>
                  <h3>Annulla</h3>
                  <img src="/static/media/close.e95ef0fe.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.confirmSubmit && (
          <div className="coffe-payment-modal">
            <div className="modalbackdrop" onClick={() => this.setState({ confirmSubmit: false })} />
            <div className="bolletini visure-modal">
              <div className="visure-modal__content">
                <h2>RIEPILOGO</h2>
                <div className="Message">
                  <div className="content__grid">
                    {selectedServices.length ? (
                      selectedServices.map((serviceId) => {
                        const { visure_nome, visure_price_withoutIVA, visure_iva } = this.state[serviceId] || {};
                        return this.getSubmitRow(visure_nome, visure_price_withoutIVA, visure_iva);
                      })
                    ) : (
                      <React.Fragment>
                        {this.getSubmitRow(visure_nome, visure_price_withoutIVA, visure_iva)}
                      </React.Fragment>
                    )}

                    {selectedOptions.map(({ tipo, prezzo }) => {
                      return (
                        tipo &&
                        prezzo && (
                          <React.Fragment key={tipo + prezzo}>
                            <span>{capitalize(tipo || "")}</span>

                            <span>{toCurrency(prezzo)}</span>
                          </React.Fragment>
                        )
                      );
                    })}
                    <span>Totale da Pagare:</span>
                    <span>
                      {toCurrency(
                        _.sum([
                          ...selectedServices.map((serviceId) => Number(this.state[serviceId]?.visure_price_total)),
                        ]) + _.sum(selectedOptions.map(({ prezzo }) => prezzo))
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div class="Actions">
                <div></div>
                <div class="Actions--Item" onClick={this.submit} data-disable="enable" htmltype="submit">
                  <h3>esegui</h3>
                  <img src="/static/media/check-symbol.ed9abdc9.svg" alt="" />
                </div>
                <div class="Actions--Item" onClick={() => this.setState({ confirmSubmit: false })}>
                  <h3>Annulla</h3>
                  <img src="/static/media/close.e95ef0fe.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const FormSide = (props) => {
  const { campKeys, campi, setState, campState } = props;
  return (campKeys || []).map((camp) => {
    const { nome, istruzioni, opzioni, tipo } = campi[camp] || {};
    const cleanName = (nome || "").replaceAll("*", "");
    const nameTooltip = cleanName.split("(");
    const label = (nameTooltip && nameTooltip[0]) || "";
    const labelTip = (nameTooltip && nameTooltip.length >= 2 && nameTooltip[1]) || "";
    const onMount = (e) => setState({ [camp]: undefined });
    const radioMount = (e) => {
      const inputNullable = campi[camp]?.null;
      if (isNaN(inputNullable) ? !inputNullable : !Number(inputNullable)) {
        setState({ [camp]: opzioni[Object.keys(opzioni)[0]] });
      } else {
        setState({ [camp]: undefined });
      }
    };

    const fileUpInput = (e) => {
      //let _self = this;
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onload = function () {
        var data = reader.result;
        setState({ [camp]: "image_" + camp + "|" + data });
      };
      file instanceof Blob && reader.readAsDataURL(file);
      //let fileType = file && file.name.split(".")[1];
    };

    const onChange = (e) => setState({ [camp]: e.target.value || undefined });

    return (
      campi[camp] && (
        <>
          <div
            key={JSON.stringify(campi[camp])}
            class={`formsContainer--body__item ${campi[camp]?.null === true ? "" : "cant-be-empty"}`}
          >
            <div className="label">
              <span>{label}</span>
              {nome.includes("*") && <span className="required">{` * `}</span>}
              {labelTip && (
                <Tooltip className="label-instruzione" title={"(" + labelTip}>
                  <i className="fal fa-info-circle"></i>
                </Tooltip>
              )}
            </div>
            {istruzioni && (
              <Tooltip className="camp-instruzione" title={istruzioni}>
                <i className="fal fa-info-circle"></i>
              </Tooltip>
            )}
            {opzioni ? (
              <UpdateFirstValue onMount={radioMount} shouldFireOnMount={!!(camp in campState)}>
                <Radio.Group onChange={onChange} name={camp} value={campState[camp]}>
                  {Object.keys(opzioni || {}).map((key) => {
                    const value = opzioni[key];
                    return (
                      <Radio key={value} value={value}>
                        {opzioni[key]}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </UpdateFirstValue>
            ) : tipo === "file" ? (
              <input
                type={"file"}
                shouldFireOnMount={!(camp in campState)}
                onChange={fileUpInput}
                name={camp}
                id={camp}
                placeholder={cleanName}
              />
            ) : (
              <Input
                type={"text"}
                shouldFireOnMount={!(camp in campState)}
                onMount={onMount}
                onChange={onChange}
                name={camp}
                id={camp}
                value={campState[camp]}
                placeholder={cleanName}
              />
            )}
          </div>
        </>
      )
    );
  });
};

const UpdateFirstValue = ({ shouldFireOnMount, onMount, children }) => {
  useEffect(() => {
    shouldFireOnMount && onMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFireOnMount]);

  return children;
};

const Input = ({ shouldFireOnMount, onMount, value, ...otherProps }) => {
  const [className, setClassName] = useState("");
  const [val, setVal] = useState(value);
  const onBlur = () => {
    if (className !== "touched") setClassName("touched");
  };

  useEffect(() => {
    setVal(value ? value : "");
  }, [value]);

  return (
    <UpdateFirstValue onMount={onMount} shouldFireOnMount={shouldFireOnMount}>
      <input className={className} value={val} {...otherProps} onBlur={onBlur} />
    </UpdateFirstValue>
  );
};

const mapsStateToProps = (state) => {
  return {
    servicesVisure:visureSlector(state),
    visureByServiceIdDetails: state.auth.visureByServiceIdDetails,
    bussinessInfo: state.auth.bussinessInfo,
    screenWidth: state.main.screenWidth,
    visureMobileDetails:state.auth.visureMobileDetails,
    accountInfo: state.auth.accountInfo
  };
};

export default connect(mapsStateToProps, { ...AuthActions, ...MainActions })(FormVisureDomain);