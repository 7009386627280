import { DatePicker, Form, Select } from "antd"
import React, { useEffect } from "react"
import FloatingLabelInput from "../../FloatingLabelInput/FloatingLabelInput"
import GenderToggle from "../../GenderToggle/GenderToggle"
import { uniqBy } from "lodash"
import countriesArray from "config/countryArr"
import VirtualizedSelect from "react-virtualized-select"
import moment from "moment"
import AdressAutoFinder from "../../AdressAutoFinder/AdressAutoFinder"

var CodiceFiscale = require("codice-fiscale-js")

const { Option } = Select

function Step1({ form, formData, setFormData }) {
  const { getFieldDecorator } = form

  const dateFormat = "DD/MM/YYYY"

  const handleNazione = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      nazione: e,
    }))
  }

  console.log(formData)

  const handleInputChange = (field) => (e) => {
    e.persist()
    setFormData((prevState) => ({
      ...prevState,
      [field]: e.target.value,
    }))
  }

  const allNazione = uniqBy(countriesArray, "nazione")

  const nazioneList = []
  if (allNazione && allNazione.length > 0) {
    for (let i = 0; i < allNazione.length; i++) {
      nazioneList.push(
        <Option key={allNazione[i].nazione} value={allNazione[i].nazione}>
          {allNazione[i].nazione}
        </Option>
      )
    }
  }

  let city_of_birth = []
  if (countriesArray) {
    city_of_birth = countriesArray
      .filter((items) => items.nazione === formData.nazione.toUpperCase())
      .map((items) => {
        return {
          label: items.provincia,
          value: items.provincia,
          codeKey: items.codeKey,
        }
      })
  }

  let province_of_birthOptions = []
  if (countriesArray) {
    province_of_birthOptions = countriesArray
      .filter((items) => items.nazione === formData.nazione.toUpperCase())
      .map((items) => {
        return {
          label: items.sigla,
          value: items.sigla,
        }
      })
  }

  const validateCodiceFiscale = (e) => {
    const codFisInps = e
    const str = codFisInps

    const fiscalCodeKey = str.substring(str.length - 5, str.length - 1)
    const sexKey = parseInt(str.substring(9, 11), 10)
    const yearBKey = str.substring(6, 8)
    const monthBKey = str.substring(8, 9)

    let month = ""
    let year = 1900 + parseInt(yearBKey, 10)
    let day = sexKey % 40

    const monthMap = {
      A: "01",
      B: "02",
      C: "03",
      D: "04",
      E: "05",
      H: "06",
      L: "07",
      M: "08",
      P: "09",
      R: "10",
      S: "11",
      T: "12",
    }

    month = monthMap[monthBKey.toUpperCase()]

    if (sexKey > 40) {
      setFormData((prevState) => ({
        ...prevState,
        gender: "F",
      }))
    } else {
      setFormData((prevState) => ({
        ...prevState,
        gender: "M",
      }))
    }

    form.setFieldsValue({
      birthday: moment(`${day}-${parseInt(month)}-${year}`, "DD-MM-YYYY"),
    })

    setFormData((prevState) => ({
      ...prevState,
      birthday: moment(`${day}-${parseInt(month)}-${year}`, "DD-MM-YYYY"),
    }))

    countriesArray
      .filter((comune) => comune.codeKey.toString() === fiscalCodeKey.toString())
      .map((comune) => {
        setFormData((prevState) => ({
          ...prevState,
          comuniSelected: comune,
          nazione: comune.nazione,
          province_of_birth: comune.sigla,
          city_of_birth: comune.provincia,
        }))

        return comune
      })
  }

  useEffect(() => {
    if (formData.birthday) {
      const parsedBirthday = moment(formData.birthday, "DD/MM/YYYY", true)

      if (parsedBirthday.isValid() && formData.city_of_birth) {
        const day = parsedBirthday.date()
        const month = parsedBirthday.month() + 1
        const year = parsedBirthday.year()

        const cf = new CodiceFiscale({
          name: formData.first_name,
          surname: formData.last_name,
          gender: formData.gender,
          day: day,
          month: month,
          year: year,
          birthplace: formData.city_of_birth,
        })

        setFormData((prevState) => ({
          ...prevState,
          codice_fiscale: cf.code,
        }))
      } else {
        console.error("Invalid birthday format:", formData.birthday)
      }
    }
  }, [
    formData.first_name,
    formData.last_name,
    formData.gender,
    formData.birthday,
    formData.country_code,
    formData.city_of_birth,
    formData.province_of_birth,
  ])

  return (
    <>
      <Form.Item hasFeedback>
        {getFieldDecorator("codice_fiscale", {
          initialValue: formData.codice_fiscale,
          rules: [
            {
              required: true,
              message: "Inserire codice fiscale!",
              whitespace: true,
            },
            {
              pattern: new RegExp(
                "^[A-Z]{6}\\d{2}[A-EHLMPRST]{1}\\d{2}[A-Z]{1}\\d{3}[A-Z]{1}$"
              ),
              message:
                "Codice fiscale non valido! Deve essere nel formato corretto.",
            },
          ],
        })(
          <FloatingLabelInput
            label="Codice Fiscale"
            onChange={(e) => {
              validateCodiceFiscale(e.target.value)
              handleInputChange("codice_fiscale")(e)
            }}
          />
        )}
      </Form.Item>

      <Form.Item hasFeedback style={{ marginBottom: "-17px" }}>
        {getFieldDecorator("first_name", {
          initialValue: formData.first_name,
          rules: [{ required: true, message: "Inserire nome!", whitespace: true }],
        })(
          <FloatingLabelInput
            label="Nome"
            onChange={(e) => {
              handleInputChange("first_name")(e)
            }}
          />
        )}
      </Form.Item>

      <Form.Item hasFeedback>
        {getFieldDecorator("last_name", {
          initialValue: formData.last_name,
          rules: [{ required: true, message: "Inserire cognome!" }],
        })(
          <FloatingLabelInput
            label="Cognome"
            onChange={(e) => {
              handleInputChange("last_name")(e)
            }}
          />
        )}
      </Form.Item>

      <Form.Item hasFeedback>
        {getFieldDecorator("email", {
          initialValue: formData.email,
          rules: [
            { type: "email", message: "E-mail non valido!" },
            { required: true, message: "Inserire E-mail!" },
          ],
        })(
          <FloatingLabelInput
            label="Email"
            onChange={(e) => {
              handleInputChange("email")(e)
            }}
          />
        )}
      </Form.Item>

      <Form.Item>
        {getFieldDecorator("gender", {
          initialValue: formData.gender,
          rules: [{ required: true, message: "Inserire sesso!" }],
        })(
          <GenderToggle
            initialGender={formData.gender}
            onGenderChange={(gender) => {
              setFormData((prevState) => ({
                ...prevState,
                gender: gender,
              }))
            }}
          />
        )}
      </Form.Item>

      <Form.Item style={{ marginBottom: "16px" }}>
        {getFieldDecorator("birthday", {
          initialValue:
            formData.birthday !== "" &&
            moment(formData.birthday, dateFormat).isValid()
              ? moment(formData.birthday, dateFormat)
              : null,
          rules: [
            {
              required: true,
              message: "Inserisci data di nascita",
            },
          ],
        })(
          <DatePicker
            placeholder="Data di nascita"
            format={("DD/MM/YYYY", "DD/MM/YYYY")}
            onChange={(date) => {
              const formattedDate = date ? date.format(dateFormat) : ""
              setFormData((prevState) => ({
                ...prevState,
                birthday: formattedDate,
              }))
            }}
          />
        )}
      </Form.Item>

      <Form.Item hasFeedback style={{ marginBottom: "16px" }}>
        {formData.nazione === "" && (
          <Select defaultValue={formData.nazione} onChange={(e) => handleNazione(e)}>
            {nazioneList}
          </Select>
        )}
        {formData.nazione !== "" && (
          <Select value={formData.nazione} onChange={(e) => handleNazione(e)}>
            {nazioneList}
          </Select>
        )}
      </Form.Item>

      <Form.Item hasFeedback style={{ marginBottom: "16px" }}>
        <VirtualizedSelect
          placeholder="Comune di nascita"
          options={city_of_birth}
          onChange={(city_of_birth) =>
            setFormData((prevState) => ({
              ...prevState,
              city_of_birth: city_of_birth.value,
              country_code: city_of_birth.codeKey,
            }))
          }
          value={formData.city_of_birth}
          maxHeight={100}
        />
      </Form.Item>

      <Form.Item hasFeedback style={{ marginBottom: "16px" }}>
        <VirtualizedSelect
          placeholder="Provincia"
          options={province_of_birthOptions}
          onChange={(province_of_birth) =>
            setFormData((prevState) => ({
              ...prevState,
              province_of_birth: province_of_birth.value,
            }))
          }
          value={formData.province_of_birth}
          maxHeight={100}
        />
      </Form.Item>

      <Form.Item hasFeedback>
        {getFieldDecorator("address", {
          initialValue: formData.address,
          rules: [
            {
              required: true,
              message: "Inserisci il indirizzo di residenza!",
              whitespace: true,
            },
          ],
        })(
          <FloatingLabelInput
            label="Indirizzo di residenza"
            onChange={(e) => {
              handleInputChange("address")(e)
            }}
          />
        )}
      </Form.Item>

      <AdressAutoFinder
        form={form}
        nazione={formData.nazioneDiResidenca}
        province={formData.residence_province}
        comune={formData.residence_city}
        setNazione={(nazione) => {
          setFormData((prevState) => ({
            ...prevState,
            nazioneDiResidenca: nazione,
          }))
        }}
        setProvince={(province) => {
          setFormData((prevState) => ({
            ...prevState,
            residence_province: province,
          }))
        }}
        setComune={(comune) => {
          setFormData((prevState) => ({
            ...prevState,
            residence_city: comune,
          }))
        }}
      />
    </>
  )
}

export default Step1
