import { Button, Modal, Form, notification, Input } from "antd"
import React from "react"
import axios from "axios"
import { endpoint } from "../../../config/api"

const AddVariableService = ({
  isVisible = true,
  onClose,
  form,
  accountInfo,
  id,
  getSelectedCompany,
}) => {
  const { getFieldDecorator, validateFields } = form
  const token = accountInfo?.token

  const handleSubmit = () => {
    validateFields((err, values) => {
      if (!err) {
        const headers = {
          Authorization: `Bearer ${token}`,
        }

        console.log("Form values:", values)

        axios
          .post(
            `${endpoint}/create/service`,
            {
              name: values.name,
              costo: values.cost,
              type: values.type,
              company_id: id,
            },
            { headers }
          )
          .then(() => {
            notification.success({
              message: "Service added successfully!",
            })
            getSelectedCompany(id)
            onClose()
          })
          .catch((err) => console.log(err))
      }
    })
  }

  return (
    <Modal
      className="add-service-modal"
      title="Add service"
      visible={isVisible}
      onCancel={onClose}
      width={500}
      footer={
        <Button type="primary" block onClick={handleSubmit}>
          Create
        </Button>
      }
    >
      <Form layout="vertical" className="add-service-form">
        <Form.Item label="Service name">
          {getFieldDecorator("name", {
            rules: [
              {
                required: true,
                message: "Per favore seleziona un service name!",
              },
            ],
          })(<Input placeholder="Service name" />)}
        </Form.Item>

        <Form.Item label="Service cost">
          {getFieldDecorator("cost", {
            rules: [
              {
                required: true,
                message: "Per favore seleziona un service cost!",
              },
            ],
          })(<Input placeholder="Service cost" />)}
        </Form.Item>

        <Form.Item label="Ticket type">
          {getFieldDecorator("type", {
            rules: [
              {
                required: true,
                message: "Per favore seleziona un service type!",
              },
            ],
          })(<Input placeholder="Service type" />)}
        </Form.Item>
      </Form>
    </Modal>
  )
}

const AddVariableServiceForm = Form.create()(AddVariableService)
export default AddVariableServiceForm
