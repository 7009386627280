import React, { useState, useEffect } from "react"
import { Checkbox, Select } from "antd"
import { RadioGroup } from "shared-components"
import "./style.css"
import $ from "jquery"
import ClickOut from "react-onclickout"
import AuthActions from "redux-store/models/auth"
import MainActions from "redux-store/models/main"
import { connect } from "react-redux"
const { Option } = Select

const Messaggi = (props) => {
  const { close, skinList, createAds, accountInfo, screenWidth } = props
  const radioData = [
    { name: "Cancellazione prodotto", id: 3 },
    { name: "Informazione", id: 2 },
    { name: "Nuovo Prodotto", id: 1 },
    //  { name : "Popup" , id : 5}
  ]
  const radioDataCategories = [
    { name: "Messaggio", id: 6 },
    { name: "Pop up", id: 5 },
  ]
  const typeMessage = [
    { name: "Text", id: "Text" },
    { name: "HTML", id: "HTML" },
  ]
  const [categoria, setCategoria] = useState(radioData[0].id)
  const [categoriaAnnunci, setCategoriaAnnunci] = useState(radioDataCategories[0].id)
  const [skinSelected, setSkinSelected] = useState("")
  const [skins, setSkins] = useState({})
  const [title, setTitle] = useState("")
  const [from, setFrom] = useState("")
  const [to, setTo] = useState("")
  const [message, setMessage] = useState("")
  const [orario, setOrario] = useState(false)
  const [repeat, setRepeat] = useState(false)
  const [volte, setVolte] = useState(1)
  const [type, setType] = useState(typeMessage[0].id)
  const changeCategoria = (newValueId) => {
    setCategoria(newValueId)
    if (newValueId != 5) {
      setType(typeMessage[0].id)
      setOrario(false)
      setRepeat(false)
      setFrom("")
      setTo("")
      setVolte(1)
    }
  }

  const changeCategoriaAnnunci = (newValueId) => {
    setCategoriaAnnunci(newValueId)
  }
console.log(typeof(categoria), categoriaAnnunci, skinSelected)
  const changeTitle = (e) => setTitle(e.target.value)
  const changeMessage = (e) => setMessage(e.target.value)
  const changeFrom = (e) => setFrom(e.target.value)
  const changeTo = (e) => setTo(e.target.value)

  const changeType = (newValueId) => setType(newValueId)
  const changeVolte = (e) => setVolte(e.target.value)
  const changeOrario = (e) => setOrario(e.target.checked)
  const changeRepeat = (e) => setRepeat(e.target.checked)
  const setNewSkins = () => {
    let newSkinFormat = {}
    skinList.map(({ id, username }) => (newSkinFormat[id] = username))
    setSkins(newSkinFormat)
  }
  useEffect(() => {
    setNewSkins()
  }, [skinList])

  useEffect(() => {
    setNewSkins()
    $("body").addClass("messaggi-popup-body")
    return () => {
      $("body").removeClass("messaggi-popup-body")
    }
  }, [])

  const fileUpInput = (e) => {
    const reader = new FileReader()
    const file = e.target.files[0]
    reader.onload = function () {
      setMessage(reader.result)
    }
    reader.readAsText(file)
  }

  const clearState = () => {
    setTitle("")
    setMessage("")
    setSkins({})
    setSkinSelected("")
    setCategoria(radioData[0].id)
    setCategoriaAnnunci(radioDataCategories[0].id)
    setType(typeMessage[0].id)
    close()
  }

  const submit = () => {
    const selectedSkins =
      skinSelected && skinSelected.length
        ? skinSelected.filter((s) => s !== "all")
        : []
    createAds({
      importance: categoriaAnnunci === 5 ? categoriaAnnunci : categoria,
      title: title,
      text: message,
      specific_skin_id:
        accountInfo?.profile?.role?.name !== "super_admin"
          ? selectedSkins || []
          : [accountInfo.profile.activity_id],
      user_id: null,
      clearState,
      type,
    })
  }

  const getMarginBottom = () => {
    const marginb =
      ($(".messaggi-popup").height() - $(".messaggi-popup__content").outerHeight()) /
      2
    return marginb > 0 ? marginb : 0
  }

  const handleSelectAll = (value) => {
    const allSkins = Object.keys(skins)
    if (value.includes("all")) {
      return allSkins.concat("all")
    } else if (value.length === allSkins.length) {
      return []
    } else {
      return value.filter((skin) => skin !== "all")
    }
  }

  const skinSelect = (value) => {
    setSkinSelected(handleSelectAll(value))
  }

  return (
    <ClickOut
      onClickOut={(e) => {
        if (!$(".ant-select-dropdown").find(e.target).length) {
          close()
        }
      }}
    >
      <div className="messaggi-popup nuovo-biller annunci-list">
        <div className="backDrop" onClick={close} />
        <div
          className="messaggi-popup__content"
          // style={{ marginBottom: getMarginBottom() }}
        >
          <div className="content__header">
            <span>CREA ANNUNCIO</span>
            <i className="fal fa-times" onClick={close}></i>
          </div>
          <div className="content__body">
            <div className="content__body__radio">
              <div className="body__label is-required">Tipo di Annuncio:</div>
              {screenWidth < 650 ? (
                <Select
                  className="categoria-select"
                  defaultValue={radioData[0].name}
                  style={{ width: "100%" }}
                  onChange={changeCategoria}
                >
                  {radioData.map((option) => (
                    <Option key={option.id} value={option.id}>{option.name}</Option>
                  ))}
                </Select>
              ) : (
                <RadioGroup
                  className="body__radio"
                  data={radioData}
                  color="DarkGray"
                  activeColor="Admin"
                  borderColor="Gray"
                  value={categoria}
                  setValue={changeCategoria}
                />
              )}
            </div>
            <div className="content__body__radio">
              <div className="body__label is-required">Categoria Annuncio:</div>
              {screenWidth < 650 ? (
                <Select
                  className="categoria-select"
                  defaultValue={radioDataCategories[0].name}
                  style={{ width: "100%" }}
                  onChange={changeCategoriaAnnunci}
                >
                  {radioDataCategories.map((option) => (
                    <Option key={option.id} value={option.id}>{option.name}</Option>
                  ))}
                </Select>
              ) : (
                <RadioGroup
                  className="body__radio categories"
                  data={radioDataCategories}
                  color="DarkGray"
                  activeColor="Admin"
                  borderColor="Gray"
                  value={categoriaAnnunci}
                  setValue={changeCategoriaAnnunci}
                />
              )}

              {accountInfo?.profile?.role?.name !== "super_admin" && (
                <div className="body__select-skin">
                  {/* <button
                  onClick={() => {
                    setSkinSelected(skinList.map(({ id }) => id))
                  }}
                >
                  TUTTE
                </button> */}
                  <div className="select-wrapper">
                    <div className="select-wrapper__placeholder">
                      <span>
                        {!skinSelected.length
                          ? "Seleziona Skin"
                          : (skinSelected || [])
                              .map(
                                (id) =>
                                  (skins[id] && skins[id].substring(0, 4)) || ""
                              )
                              .join(",")}
                      </span>
                      <i className="fal fa-chevron-down" />
                    </div>

                    <Select
                      mode="multiple"
                      className="select-Skin"
                      allowClear
                      placeholder="Seleziona Skin"
                      value={(skinSelected || []).map((s) => s.toString())}
                      onChange={skinSelect}
                    >
                      <Option key="all">Tutti Skin</Option>
                      {Object.keys(skins).map((skinKey) => (
                        <Option key={skinKey}>{skins[skinKey]}</Option>
                      ))}
                    </Select>
                  </div>
                  {/* <Select
                  mode="multiple"
                  className="select-Skin"
                  allowClear
                  placeholder="Seleziona Skin"
                  value={(skinSelected || []).map((s) => s.toString())}
                  onChange={skinSelect}
                >
                  {Object.keys(skins).map((skinKey) => (
                    <Option key={skinKey}>{skins[skinKey]}</Option>
                  ))}
                </Select> */}
                </div>
              )}
            </div>

            {accountInfo?.profile?.role?.name !== "super_admin" &&
              screenWidth < 650 && (
                <div className="content__body__radio">
                  <div className="body__label is-required">Seleziona Skin:</div>
                  <Select
                    mode="multiple"
                    showArrow
                    className="categoria-select"
                    allowClear
                    placeholder="Seleziona Skin"
                    value={(skinSelected || []).map((s) => s.toString())}
                    onChange={skinSelect}
                    maxTagCount={3}
                  >
                    <Option key="all">Tutti Skin</Option>
                    {Object.keys(skins).map((skinKey) => (
                      <Option key={skinKey}>{skins[skinKey]}</Option>
                    ))}
                  </Select>
                </div>
              )}

            <div className="body__label title is-required"> Titolo:</div>
            <div className="body__input">
              <input onChange={changeTitle} value={title} />
            </div>
            <div className="content__body__radio">
              <div className="body__label messagge is-required"> Messaggio:</div>
              {categoriaAnnunci == 5 && (
                <RadioGroup
                  className="body__radio1"
                  data={typeMessage}
                  color="DarkGray"
                  activeColor="Admin"
                  borderColor="Gray"
                  value={type}
                  setValue={changeType}
                />
              )}
            </div>
            {type == "Text" && (
              <div>
                <div className="body__input is-textarea">
                  <textarea onChange={changeMessage} value={message} />
                </div>
              </div>
            )}
            {categoriaAnnunci == 5 && type == "HTML" && (
              <div>
                <div className="body__input file">
                  <input type="file" onChange={fileUpInput} accept=".html" />
                </div>
                <div className="body__label orario">
                  <Checkbox checked={orario} onChange={changeOrario}>
                    Orario
                  </Checkbox>
                </div>
              </div>
            )}

            {orario && (
              <div className="body__orario_input">
                <div className="content__body__radio">
                  <div className="body__label"> From</div>
                  <div className="body__input">
                    <input
                      type="datetime-local"
                      onChange={changeFrom}
                      value={from}
                    />
                  </div>
                </div>
                <div className="content__body__radio">
                  <div className="body__label"> To</div>
                  <div className="body__input">
                    <input type="datetime-local" onChange={changeTo} value={to} />
                  </div>
                </div>
              </div>
            )}
            {categoriaAnnunci == 5 && type == "HTML" && (
              <div className="body__label orario">
                <Checkbox checked={repeat} onChange={changeRepeat}>
                  Ripetibile
                </Checkbox>
              </div>
            )}
            {repeat && (
              <div>
                <div className="body__label repeat is-required">
                  {" "}
                  Numero di volte per annunciare :
                </div>
                <div className="body__input">
                  <input type="number" onChange={changeVolte} value={volte} />
                </div>
              </div>
            )}
          </div>
          <div className="content__footer">
          <button className="annulla-btn" onClick={close}>
              ANNULLA
            </button>
            <button onClick={submit}>INVIA</button>
          
          </div>
        </div>
      </div>
    </ClickOut>
  )
}

const mapStateToProps = (state) => ({
  skinList: state.auth.skinList,
  accountInfo: state.auth.accountInfo,
  screenWidth: state.main.screenWidth,
})

export default connect(mapStateToProps, { ...MainActions, ...AuthActions })(Messaggi)
