import React, { useState } from "react"
import { Input, Tooltip } from "antd"
import { Form } from "antd"
import { get, max } from "lodash"
import _ from "lodash"

const ReturnFormItem = ({
  name,
  message,
  barcodeData,
  descName,
  getFieldDecorator,
  icon,
  NotInput,
  placeholder,
  defaultValue,
  valuePropName,
  datepicker,
  className,
  maximum,
  maxLength,
  disabled,
  setFieldsValue,
  tooltip,
}) => {
  const [val, setVal] = useState("")
  const [prevVal, setPrevVal] = useState("")
  const spreadProp = []
  if (valuePropName) {
    spreadProp.valuePropName = valuePropName
  }
  const validator = {
    validator: async (rule, value) => {
      if (maximum && (parseInt(value) >= maximum || isNaN(parseInt(value)))) {
        throw new Error(
          "il massimo pagamento consentito per i bollettini bianchi è di 250,00 €"
        )
      }
    },
  }

  function removeByIndex(str, index) {
    return str.slice(0, index) + str.slice(index + 1)
  }

  const onChange = (e) => {
    setVal(e.target.value)
    //console.log(e.target.value)
  }

  const onBlur = () => {
    if (setFieldsValue && name === "importo") {
      try {
        // for when the input gets clears
        if (typeof val === "string" && !val.length) {
          val = "0.0"
        }

        let newVal = val.replace(/[^0-9,]/g, "")
        let commaLength = newVal.replace(/[^,]/g, "")
        const lastCommaIndex = (prevVal || "").indexOf(",")
        if (lastCommaIndex !== -1 && commaLength?.length >= 2) {
          const newCommaIndex =
            newVal[lastCommaIndex] === "," ? lastCommaIndex : lastCommaIndex + 1
          newVal = removeByIndex(newVal, newCommaIndex)
        }
        setPrevVal(newVal)
        // const importoPart = newVal.split(",")
        // const firstPart = (importoPart[0] || "");
        // const separation = firstPart.length - Math.floor((firstPart).length/3) * 3
        // let fullImporto = firstPart.substring(0,separation)
        // fullImporto = (fullImporto || "").replace(/\.$/, "");
        // let fullImportoWithPoint = firstPart.substring(separation,firstPart.length).replace(/(.{3})/g,"$1.")
        // fullImportoWithPoint = (fullImportoWithPoint || "").replace(/\.$/, "");
        // const decimalImporto = (importoPart[1] || "").length || newVal[newVal.length - 1] === "," ? "," + (importoPart[1] || "") : (importoPart[1] || "")
        // newVal=fullImporto+(fullImportoWithPoint.length && fullImporto.length ? "." + fullImportoWithPoint : fullImportoWithPoint)+decimalImporto
        setFieldsValue({ [name]: newVal })
        return newVal
      } catch (error) {
        console.error(error)
      }
    }
  }

  return (
    <div
      className={`InputItem ${
        className ? className : valuePropName ? "checkbox" : ""
      }`}
    >
      {descName && (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <span className={`${valuePropName ? "checkbox" : "desc"}`}>
            {descName} <span className="Red">*</span>
          </span>
          {maxLength && (
            <span
              className="desc"
              style={{ marginLeft: "5px", fontSize: "11px", height: "13px" }}
            >
              ({defaultValue?.length || val.length}/{maxLength})
            </span>
          )}
        </div>
      )}
      <div className={`${valuePropName ? "checkbox" : "item"}`}>
        <Form.Item
        >
          {getFieldDecorator(`${name}`, {
            rules: datepicker
              ? [
                  {
                    type: "object",
                    required: true,
                    message: message ?? "",
                  },
                ]
              : valuePropName
              ? [validator]
              : [
                  {
                    required: message,
                    message: message ?? "",
                    whitespace: true,
                  },
                  validator,
                ],

            initialValue: defaultValue
              ? defaultValue
              : get(barcodeData, `data.${name}` || ""),
            ...spreadProp,
          })(
            NotInput ? (
              NotInput
            ) : (
              <Input
                disabled={disabled}
                onBlur={onBlur}
                onChange={onChange}
                placeholder={placeholder ? placeholder : descName}
                maxLength={maxLength}
              />
            )
          )}
        </Form.Item>
      </div>
      {icon && <Tooltip title={tooltip}>{icon}</Tooltip>}
    </div>
  )
}
export default ReturnFormItem
