import { put, call, delay, select } from "redux-saga/effects"
import AuthActions from "../models/auth"
import MainActions from "../models/main"
import * as AuthRequest from "services/auth"
import { sepafinRegisterLogin, sepafinReLoginRequire } from "utils/HelperFunc"
//import { fetchUsers } from "services/main";
import { notification } from "antd"

function* setPaymentLoading(key, duration) {
  const message = "Attendere, transazione in corso"
  yield call(notification["info"], { key, duration: duration || 0, message })
}

function typeOfPayment(response) {
  const newResponse = response || response?.data
  return newResponse?.prenotazione
    ? "Prenotazione"
    : newResponse?.payment
    ? "payment"
    : "other"
}

function needRelogin(response) {
  const errorData = response?.error?.response?.data
  return (
    [11, 10].includes(errorData?.stuck) || (errorData && errorData["need-relogin"])
  )
}

function* sepaRelogin(username) {
  sepafinReLoginRequire(username)
  yield put(AuthActions.setShowSepafinLogin(true))
}

function* sepafinResponseHanlde(response, key, clearFields, username, notSepafin) {
  const responseData = response?.data?.data
    ? response?.data?.data
    : response && response?.data
  if (needRelogin(response) && !notSepafin) {
    yield call(sepaRelogin, username)
  } else if (responseData) {
    const { message, wallet, errors } = responseData || {}
    yield call(notification.close, key)
    const paymentType = typeOfPayment(responseData)
    const paymentNotFinished =
      paymentType === "Prenotazione" || response?.data?.isPrenotazione
    // if(key !== "payVisureSepafin" && key !== "customVoucherPayment"){
    yield put(
      AuthActions.setBolletiniBianchi({
        ...responseData,
        paymentType,
        paymentNotFinished,
      })
    )
    // }
    if (!errors) {
      yield call(notification["success"], {
        message: message || "transazione e completato",
      })
      if (clearFields && typeof clearFields === "function") {
        clearFields()
      }
    }
    if (wallet) {
      const accountData = localStorage.getItem("accountDataB")
      const data = JSON.parse(accountData)
      const account = { ...data, profile: { ...data.profile, wallet } }

      localStorage.setItem("accountDataB", JSON.stringify(account))
      yield put(AuthActions.setAccountInfo(account))
    }
  }
  yield call(notification.close, key)
}

export function* signInByEmail(credencials) {
  const response = yield call(
    AuthRequest.fetchLogin,
    credencials.email,
    credencials.password,
    credencials.device_token
  )
  if (response) {
    if (response?.data) {
      const { name, username, email } = response?.data.profile || {}
      localStorage.setItem(
        "acccountSRPCH",
        btoa(JSON.stringify({ name, username, email }))
      )
      localStorage.setItem("accountDataB", JSON.stringify(response?.data))
      yield put(AuthActions.setAccountInfo(response?.data))
      credencials.c(response?.data.profile)
      if (response.data.stuck == 10 || response.data.stuck == 11) {
        yield put(AuthActions.setShowSepafinLogin(true))
      }
    }
    if (response?.error) {
      yield put(AuthActions.setLoginMsg("Nome utente o password errate"))
      yield put(AuthActions.setOtpInfo(response?.error.response?.data))
      yield delay(4000)
      yield put(AuthActions.setLoginMsg(""))
    }
  }
}

export function* signInByOtp(credencials) {
  const response = yield call(
    AuthRequest.fetchLoginOtp,
    credencials.email,
    credencials.password,
    credencials.device_token,
    credencials.need_auth,
    credencials.otp
  )
  if (response) {
    if (response?.data) {
      const { name, username, email } = response?.data.profile || {}
      localStorage.setItem(
        "acccountSRPCH",
        btoa(JSON.stringify({ name, username, email }))
      )
      localStorage.setItem("accountDataB", JSON.stringify(response?.data))
      localStorage.setItem("device_token", response?.data?.device_token)
      yield put(AuthActions.setAccountInfo(response?.data))
      credencials.c(response?.data.profile)
      if (response.data.stuck == 10 || response.data.stuck == 11) {
        yield put(AuthActions.setShowSepafinLogin(true))
      }
    }
    if (response?.error) {
      yield put(AuthActions.setLoginMsg(response?.error.response?.data.message))
      yield delay(4000)
      yield put(AuthActions.setLoginMsg(""))
    }
  }
}

export function* getAgents(params) {
  let response
  if (params?.skin_id && params?.skin_id !== -1) {
    response = yield call(AuthRequest.fetchAgents, params.skin_id)
  } else {
    response = yield call(AuthRequest.fetchAgents)
  }
  if (response?.data) {
    yield put(AuthActions.setAgents(response?.data.agents))
  }
}

export function* getTransferDocument(params) {
  const response = yield call(AuthRequest.requireTransferDoc, params.refill)
  if (response?.data) {
    yield put(AuthActions.setTransferDocument(response?.data.data))
  }
}

export function* getAccountInfo() {}

export function* logOut() {
  const response = yield call(AuthRequest.logoutApi)

  if (response) {
    localStorage.setItem("accountDataB", {})
    yield put(AuthActions.setAccountInfo({}))
  }
}

export function* getBolletiniBianchi(params) {
  yield call(setPaymentLoading, "getBolletiniBianchi")
  const response = yield call(
    AuthRequest.fetchBolletiniBianchi,
    params.service_id,
    params.importo,
    params.id_prenotazione,
    params.nome,
    params.cognome,
    params.conto_corrente,
    params.causale
  )
  if (response?.data) {
    if (response?.data) {
      const paymentType = typeOfPayment(response?.data)
      const paymentNotFinished =
        paymentType === "Prenotazione" || response?.data?.isPrenotazione
      yield put(
        AuthActions.setBolletiniBianchi({
          ...response?.data,
          paymentType,
          paymentNotFinished,
        })
      )
      notification["info"]({
        message: response?.data?.message,
      })
      params.clearFields()
    } else if (response?.error) {
      if (response?.error.response?.status === 444) {
        const error = { errors: { notCorrect: ["data are not corrected."] } }
        yield put(AuthActions.setBolletiniBianchi(error))
      } else {
        yield put(AuthActions.setBolletiniBianchi(response?.error.response?.data))
      }
    }
  }
  if (response && params.callBack) {
    params.callBack(false)
  }
  yield call(notification.close, "getBolletiniBianchi")
}
export function* addTicket({ ticket }) {
  const my_tickets = yield select((state) => state.auth.formDetails.my_tickets)
  let tickets = yield select((state) => state.auth.formDetails.tickets)
  yield put(
    AuthActions.setDataFormDetails({
      my_tickets,
      tickets: tickets ? [ticket, ...tickets] : [ticket],
    })
  )
}

export function* pagoTicket({ barcode, isPos, username }) {
  yield call(setPaymentLoading, "PaymentLoading")
  const response = yield call(AuthRequest.pagoTicketReq, barcode, isPos, username)
  yield call(sepafinResponseHanlde, response, "PaymentLoading", username, null)
}
export function* addVisure({ singleVisure }) {
  const my_visure = yield select((state) => state.auth.Visure.my_visure)
  let visure = yield select((state) => state.auth.Visure.visure)

  yield put(
    AuthActions.setVisure({
      my_visure,
      visure: visure ? [...visure, singleVisure] : visure,
    })
  )
}
export function* getBolletiniPremercati(params) {
  const accountData = JSON.parse(localStorage.getItem("accountDataB")) || {}
  const isMrPay = accountData?.profile?.supplier === "MrPay"

  const formattedImporto = isMrPay
    ? params.importo + "." + params.importo_cent
    : params.importo + params.importo_cent.toString().padEnd(2, "0")

  yield call(setPaymentLoading, "getBolletiniPremercati")
  const response = yield call(
    AuthRequest.fetchBolletiniPremercati,
    params.service_id,
    params.numero_conto_corrente,
    formattedImporto,
    params.codice_identificativo,
    params.tipologia,
    params.eseguito_da,
    params.via_piazza,
    params.cap,
    params.citta,
    params.provincia,
    params.indirizzo,
    params.user_id
  )
  // console.log("response", response);

  if (response) {
    if (response?.data) {
      yield put(AuthActions.setBolletiniBianchi(response?.data))
      if (response?.data?.message) {
        notification["info"]({
          message: response?.data?.message,
        })
      }
      params.clearFields(false)
    } else if (response?.error) {
      if (response?.error.response?.status === 444) {
        const error = { errors: { notCorrect: ["data are not corrected."] } }
        yield put(AuthActions.setBolletiniBianchi(error))
      } else {
        yield put(AuthActions.setBolletiniBianchi(response?.error.response?.data))
      }
    }
    if (params.callBack) {
      params.callBack(false)
    }
    // if (response && params.clearFields) {
    //   params.clearFields(false)
    // }
  }
  yield call(notification.close, "getBolletiniPremercati")
}

export function* getPaymentsForExcel(params) {
  yield put(AuthActions.setPaymentsExcelLoading(true))
  const response = yield call(
    AuthRequest.fetchPayments,
    params.username,
    params.from,
    params.to,
    params.page_number,
    params.limit,
    params.skin_id,
    params.excel,
    params.service
  )
  if (response) {
    if (response?.status === 200) {
      if (response?.data) {
        yield put(AuthActions.setPaymentsForExcel(response?.data.transactions))
      }
    } else if (response?.error) {
      yield put(AuthActions.setPayments(response?.error.response?.data))
    }
  }
  yield put(AuthActions.setPaymentsExcelLoading(false))
}
export function* getPayments(params) {
  yield put(AuthActions.setPaymentsLoading(true))
  const response = yield call(
    AuthRequest.fetchPayments,
    params.username,
    params.from,
    params.to,
    params.page_number,
    params.limit,
    params.skin_id,
    params.excel,
    params.service,
    params.monthly
  )

  if (response) {
    if (response?.status === 200) {
      if (response?.data) {
        yield put(
          AuthActions.setPayments(
            response?.data.transactions ?? response?.data?.result
          )
        )
        yield put(
          AuthActions.setPaymentsPages({
            total_pages: response?.data.total_pages,
            total_records: response?.data.total_records,
          })
        )

        const statsMain = yield select((state) => state.auth.StatisticheMain)

        yield put(
          AuthActions.setStatisticheMain({
            ...statsMain,
            total: {
              commissioni: response?.data?.balance?.commissione,
              proviggioni: response?.data?.balance?.proviggioni,
              transazioni: response?.data?.balance?.saldo,
              system_aggio: response?.data?.balance?.system_aggio,
            },
          })
        )

        if (response?.data.usernames) {
          yield put(AuthActions.setUsernames(response?.data.usernames))
        }
        if (response?.data.listed_services) {
          yield put(AuthActions.setListedServices(response?.data.listed_services))
        }
        if (response?.data.balance) {
          yield put(MainActions.setOverviewDashboard(response?.data.balance))
        }
      }
    } else if (response?.error) {
      yield put(AuthActions.setPayments(response?.error.response?.data))
    }
  }
  yield put(AuthActions.setPaymentsLoading(false))
}

export function* getAllPaymentsForExcel(params) {
  yield put(AuthActions.setPaymentsExcelLoading(true))
  const response = yield call(AuthRequest.fetchAllPayments, params.from, params.to)

  if (response) {
    if (response?.status === 200) {
      if (response?.data) {
        yield put(AuthActions.setAllPaymentsForExcel(response?.data.transactions))
      }
    } else if (response?.error) {
      yield put(AuthActions.setAllPayments(response?.error.response?.data))
    }
  }
  yield put(AuthActions.setPaymentsExcelLoading(false))
}

export function* getAllPayments(params) {
  yield put(AuthActions.setPaymentsLoading(true))
  const response = yield call(AuthRequest.fetchAllPayments, params.from, params.to)

  if (response) {
    if (response?.status === 200) {
      if (response?.data) {
        yield put(AuthActions.setAllPayments(response?.data.transactions))
      }
    } else if (response?.error) {
      yield put(AuthActions.setAllPayments(response?.error.response?.data))
    }
  }
  yield put(AuthActions.setPaymentsLoading(false))
}

export function* getRequestedRefills({ page_number, limit, skin_id, from, to }) {
  yield put(AuthActions.setPaymentsLoading(true))
  const response = yield call(
    AuthRequest.fetchRequestedRefills,
    page_number,
    limit,
    skin_id,
    from,
    to
  )
  if (response?.status === 200) {
    if (response.data) {
      yield put(AuthActions.setRequestedRefills(response?.data.data.refills))
      yield put(
        AuthActions.setRefillsPages({
          total_pages: response?.data.data.total_pages,
          total_records: response?.data.data.total_records,
        })
      )
    }
  }
  yield put(AuthActions.setPaymentsLoading(false))
}

export function* requireTransfer({ importo, document }) {
  yield call(AuthRequest.requireTransfer, importo, document)
  yield put(AuthActions.setPaymentsLoading(true))
  const response = yield call(AuthRequest.fetchRequestedRefills, 1, 25, null, "", "")
  if (response?.status === 200) {
    if (response.data) {
      yield put(AuthActions.setRequestedRefills(response?.data.data.refills))
      yield put(
        AuthActions.setRefillsPages({
          total_pages: response?.data.data.total_pages,
          total_records: response?.data.data.total_records,
        })
      )
    }
  }
  yield put(AuthActions.setPaymentsLoading(false))
}

export function* getRefills({ page_number, limit }) {
  yield put(AuthActions.setPaymentsLoading(true))
  const response = yield call(AuthRequest.fetchRefills, page_number, limit)
  if (response?.status === 200) {
    if (response?.data) {
      yield put(AuthActions.setPayments(response?.data.transactions))
      yield put(
        AuthActions.setPaymentsPages({
          total_pages: response?.data.total_pages,
          total_records: response?.data.total_records,
        })
      )

      if (response?.data.usernames) {
        yield put(AuthActions.setUsernames(response?.data.usernames))
      }
      if (response?.data.balance) {
        yield put(MainActions.setOverviewDashboard(response?.data.balance))
      }
    }
  }
  yield put(AuthActions.setPaymentsLoading(false))
  //console.log("response", response);
}
export function* getRechargeMobile(params) {
  yield call(setPaymentLoading, "getRechargeMobile")

  const response = yield call(
    AuthRequest.fetchRechargeMobile,
    params.service_id,
    params.phone_number,
    params.is_sepafin,
    params.username,
    params.codice_fiscale,
    params.amount,
    params.ean,
    params.userId
  )
  if (params.is_sepafin) {
    yield call(
      sepafinResponseHanlde,
      response,
      "getRechargeMobile",
      params.username,
      null
    )
  } else {
    if (response) {
      if (response?.data) {
        if (response?.data.wallet) {
          const accountData = localStorage.getItem("accountDataB")
          const data = JSON.parse(accountData)

          const d = {
            ...data,
            profile: {
              ...data.profile,
              wallet: response?.data.wallet,
            },
          }

          localStorage.setItem("accountDataB", JSON.stringify(d))
          yield put(AuthActions.setAccountInfo(d))
        }
        yield put(
          AuthActions.setRechargeMobile({
            ...response?.data,
            msg: response?.data?.message,
          })
        )
      } else if (response?.error) {
        yield put(AuthActions.setRechargeMobile(response?.error.response?.data))
      }
      //set loading false
      if (params.callBack) {
        params.callBack(false)
      }
    }
  }
  yield call(notification.close, "getRechargeMobile")
}

export function* getRechargeMobileSim(params) {
  yield call(setPaymentLoading, "getRechargeMobileSim")

  const response = yield call(
    AuthRequest.fetchRechargeMobileSim,
    params.service_id,
    params.phone_number,
    params.amount,
    params.ean,
    params.userId
  )
  if (params.is_sepafin) {
    yield call(
      sepafinResponseHanlde,
      response,
      "getRechargeMobile",
      params.username,
      null
    )
  } else {
    if (response) {
      if (response?.data) {
        if (response?.data.wallet) {
          const accountData = localStorage.getItem("accountDataB")
          const data = JSON.parse(accountData)

          const d = {
            ...data,
            profile: {
              ...data.profile,
              wallet: response?.data.wallet,
            },
          }

          localStorage.setItem("accountDataB", JSON.stringify(d))
          yield put(AuthActions.setAccountInfo(d))
        }
        yield put(
          AuthActions.setRechargeMobile({
            ...response?.data,
            msg: response?.data?.message,
          })
        )
      } else if (response?.error) {
        yield put(AuthActions.setRechargeMobile(response?.error.response?.data))
      }
      //set loading false
      if (params.callBack) {
        params.callBack(false)
      }
    }
  }
  yield call(notification.close, "getRechargeMobileSim")
}

export function* captureRechargeMobileSim({ payload }) {
  yield call(setPaymentLoading, "captureRechargeMobileSim")
  const response = yield call(AuthRequest.captureRechargeMobileSimReq, payload)
  if (response.data) {
    yield put(
      AuthActions.setRechargeMobile({
        ...response?.data,
        msg: response?.data?.message,
      })
    )
  } else if (response?.error) {
    yield put(AuthActions.setRechargeMobile(response?.error.response?.data))
  }
  yield call(notification.close, "captureRechargeMobileSim")
}

export function* userTransferMoney(params) {
  if (params.is_sepafin) {
    yield call(setPaymentLoading, "PaymentLoading")
  }
  const response = yield call(
    AuthRequest.transferMoney,
    params.user_id,
    params.amount,
    params.type,
    params.c,
    params.role,
    params.backOffice,
    params.message,
    params.sendMsg,
    params.callback,
    params.username
  )
  if (params.is_sepafin) {
    yield call(
      sepafinResponseHanlde,
      response,
      "PaymentLoading",
      params.username,
      null
    )
  } else {
    if (response) {
      if (response?.error)
        yield put(AuthActions.setPostePay(response?.error.response?.data))
    }
  }
}

export function* getPostePay(params) {
  if (params.is_sepafin) {
    yield call(setPaymentLoading, "PaymentLoading")
  }
  console.log(params.username, params.is_sepafin)
  const response = yield call(
    AuthRequest.fetchPostePay,
    params.service_id,
    params.importo,
    params.user_id,
    params.intestazione,
    params.codice_fiscale_intestatario,
    params.ordinante,
    params.codice_fiscale_ordinante,
    params.numero_postepay,
    // params.document_type,
    // params.imageUrl,
    // params.imageUrl2,
    params.is_sepafin,
    params.username
  )
  if (params.is_sepafin) {
    yield call(
      sepafinResponseHanlde,
      response,
      "PaymentLoading",
      params.username,
      null
    )
  } else {
    if (response) {
      if (response?.data) {
        if (response?.data.wallet) {
          const accountData = localStorage.getItem("accountDataB")
          const data = JSON.parse(accountData)

          const d = {
            ...data,
            profile: {
              ...data.profile,
              wallet: response?.data.wallet,
            },
          }

          localStorage.setItem("accountDataB", JSON.stringify(d))
          yield put(AuthActions.setAccountInfo(d))
        }
        yield put(AuthActions.setPostePay(response?.data))
        notification["info"]({
          message: response?.data?.message,
        })
        params.clearFields()
      } else if (response?.error) {
        yield put(AuthActions.setPostePay(response?.error.response?.data))
      }
      if (params.setPostePayLoading) params.setPostePayLoading(false)
    }
  }
}

export function* getAds() {
  const response = yield call(AuthRequest.fetchAds)
  if (response?.status === 200) {
    yield put(AuthActions.setAds(response?.data.notifications))
    yield put(AuthActions.setMsg(response?.data.messages))
    yield put(AuthActions.setPopup(response?.data.popup_messages))
    yield put(
      AuthActions.setPrivateMsg(
        response?.data.ticket_messages
          ? [...response?.data.private_messages, ...response?.data.ticket_messages]
          : response?.data.private_messages
      )
    )
  }
}

export function* addPrivateMsg(msg) {
  let allMsgs = yield select((state) => {
    return state.auth.privMsg
  })
  yield put(AuthActions.setPrivateMsg([...allMsgs, msg.privMsg]))
  // console.log("called addprivate", allMsgs, msg);
}

export function* getRegister(params) {
  params.setLoading(true)

  const response = yield call(
    AuthRequest.fetchRegisterAllInfo,
    params.first_name,
    params.last_name,
    params.nickname, // for username
    params.email,
    params.gender,
    params.personal_number,
    params.birthday,
    params.nazione,
    params.province_of_birth,
    params.city_of_birth,
    params.nazioneDiResidenca,
    params.residence_province,
    params.residence_city,
    params.address,
    params.cap,
    params.identity_id,
    params.identity_type,
    params.number_prefix,
    params.number,
    params.frontImg,
    params.backImg,
    params.role,
    params.aRagSoc,
    params.aInsegna,
    params.aPhone,
    params.aAdress,
    params.aCity,
    params.aComcode,
    params.aCap,
    params.aPiva,
    params.aFcode,
    params.confirm_password,
    params.password,
    params.rilasciato_da,
    params.luogo_di_rilascio,
    params.data_di_rilascio,
    params.data_di_scadenza,
    params.a_cordinate,
    params.a_contry,
    params.rent,
    params.privacy_policy,
    params.recieve_emails,
    params.percentage,
    params.register_token,
    params.wallet,
    params.tipo_di_rent,
    params.active_skin,
    params.agent_id,
    params.logged_in_user_id,
    params.setLoading,
    params.resetFields
  )

  if (response?.data && response?.status === 200) {
    yield put(AuthActions.setRegister(response?.data))
    // yield delay(6000)
    yield put(AuthActions.setRegister({}))
    if (response?.data?.message) {
      notification["success"]({
        message: response?.data?.message,
      })
    }
    params.resetFields()
  } else if (response?.error) {
    yield put(AuthActions.setRegister(response?.error.response?.data))
    notification["error"]({
      message: "Error",
      placement: "bottomRight",
      description: response?.error.response?.data?.message || "Validation error",
      duration: 4,
    })
  }

  params.setLoading(false)
}

export function* readAds({ id }) {
  yield call(AuthRequest.readAdsRequest, id)
}

export function* requireRefillStatus({ id, status }) {
  const res = yield call(AuthRequest.requireTransferUpdate, id, status)
  if (res.status === 200) {
    yield put(AuthActions.setRefillStatus(true, id, status))
  } else {
    yield put(AuthActions.setRefillStatus(false, id, status))
  }
}

export function* createAds({ data }) {
  let { importance, title, text, specific_skin_id, user_id, clearState, type } = data
  yield put(AuthActions.createAdsResponse(true, null))
  const response = yield call(
    AuthRequest.sendCreatedAds,
    importance,
    title,
    text,
    specific_skin_id,
    user_id,
    type
  )
  if (response) {
    if (response?.data?.message) {
      notification["success"]({
        message: response?.data?.message,
      })
      clearState()
    }
    if (response?.status === 200) {
      yield put(AuthActions.createAdsResponse(false, response?.data))
      yield delay(3000)
      yield put(AuthActions.createAdsResponse(false, null))
    } else {
      yield put(AuthActions.createAdsResponse(false, response?.error.response?.data))
      yield delay(3000)
      yield put(AuthActions.createAdsResponse(false, null))
    }
  }
}
export function* getChangedPassword(data) {
  const response = yield call(
    AuthRequest.sendChangedPassword,
    data.oldPassword,
    data.newPassword
  )
  if (response?.data) {
    notification["success"]({
      message: response?.data?.message,
    })
  }
  // console.log("ca ka responseeeee", response, response?.message);
}
export function* getConfigura(data) {
  const response = yield call(AuthRequest.fetchConfigura, data.id)
  if (response?.status === 200) {
    yield put(AuthActions.setConfiguraData(response?.data.user))
  } else {
    yield put(AuthActions.setConfiguraData({}))
  }
}
export function* getCodiceTicket(props) {
  const { barcode, service, visible } = props
  const response = yield call(AuthRequest.fetchCodice, barcode, service)
  if (response?.status === 200) {
    const paymentOrPrenotazione =
      response?.data?.prenotazione || response?.data?.payment
    const paymentType = typeOfPayment(paymentOrPrenotazione)
    const paymentNotFinished =
      props.paymentNotFinished ||
      paymentType === "Prenotazione" ||
      response?.data?.isPrenotazione
    if (paymentOrPrenotazione?.receipt_type === "base64") {
      yield put(
        AuthActions.setBolletiniBianchi({
          ...paymentOrPrenotazione,
          barcode,
          paymentType,
          paymentNotFinished,
        })
      )
    } else {
      yield put(
        AuthActions.setPaymentsFromCode({
          ...paymentOrPrenotazione,
          barcode,
          paymentType,
          paymentNotFinished,
          visible,
        })
      )
    }
  }
  if (response?.error) {
    yield put(AuthActions.setPaymentsFromCode(response?.error.response?.data))
  }
}
export function* getBarcodeData(e) {
  const response = yield call(AuthRequest.fetchBarcodeData, e.barcode)
  if (response?.status === 200) {
    yield put(AuthActions.setBarcodeData(response?.data))
    e.callback(response?.data)
  } else {
    yield put(AuthActions.setBarcodeData(response?.message))
    e.callback(response?.data)
  }
}
export function* changeAgent(data) {
  let response
  if (data?.skin_id && data?.skin_id !== -1) {
    response = yield call(
      AuthRequest.changeAgentReq,
      data.id,
      data.id2,
      data.skin_id
    )
  } else {
    response = yield call(AuthRequest.changeAgentReq, data.id, data.id2)
  }
  if (response) {
    if (response?.status === 200) {
      yield put(AuthActions.setUserDetail({}))
      // const ress = yield call(fetchUsers);
      // if (ress.data) {
      //   yield put(MainActions.setUsers(ress.data.users));
      // }
    }
  }
  // console.log("response changeAgent", data, response);
}
export function* changeAgentSkin({ agent_id, new_skin_id }) {
  notification["info"]({
    key: "changeAgentSkin",
    duration: 0,
    message: "Attendere, il cambiamento in corso",
  })
  const response = yield call(AuthRequest.changeAgentSkinReq, agent_id, new_skin_id)
  if (response) {
    if (response?.status === 200) {
      notification["success"]({
        message: response.data.message,
      })
      notification.close("changeAgentSkin")
      yield put(AuthActions.setUserDetail({}))
      yield put(MainActions.setActiveSkinId(new_skin_id))
    }
  }
}
export function* getUserDetail(data) {
  const response = yield call(AuthRequest.fetchUserDetails, data.id, data.skin_id)
  if (response) {
    if (response?.status === 200) {
      yield put(
        AuthActions.setUserDetail({ ...response?.data.user, role: "agency" })
      )
    }
  }
  // console.log("response get users details", data, response);
}
export function* updateUserDetail(data) {
  let response

  response = yield call(
    AuthRequest.updateUsers,
    data.user_id,
    data.phone,
    data.document_type,
    data.document_number,
    data.rilasciato_da,
    data.luogo_di_rilascio,
    data.data_di_rilascio,
    data.data_di_scadenza,
    data.a_insegna,
    data.a_cordinate,
    data.a_phone,
    data.a_address,
    data.a_city,
    data.a_comune_code,
    data.a_cap,
    data.a_country,
    data.a_rent,
    data.password,
    data.confirm_password,
    data?.skin_id && data?.skin_id !== -1 ? data?.skin_id : null,
    data.mainAdminObj
  )

  if (response?.data) {
    if (response?.status === 200) {
      notification["success"]({
        message: "Azione completata",
        description: response?.data.message,
      })
      yield delay(4000)
      yield put(AuthActions.updateUserDetailMsg(""))
    }
    data.resetState(data.user_id)
  }
  if (response?.error) {
    yield delay(4000)
    yield put(AuthActions.updateUserDetailMsg(""))
  }
}
export function* getSkinExtras({ activeSkinId }) {
  const response = yield call(AuthRequest.fetchSkinExtras, activeSkinId)
  if (response?.data) {
    if (response?.status === 200) {
      yield put(AuthActions.setSkinExtras(response?.data.skin))
    }
  }
  // console.log("response skin extras", response);
}
export function* getErrors({
  limit,
  page_number,
  skin_name,
  from,
  to,
  DONT_LOAD,
  description,
}) {
  if (!DONT_LOAD) {
    yield put(AuthActions.setErrorsLoading(true))
  }

  const response = yield call(
    AuthRequest.fetchErrors,
    limit,
    page_number,
    skin_name,
    from,
    to,
    description
  )
  if (response?.data) {
    if (response?.status === 200) {
      yield put(
        AuthActions.setErrors({
          errors: response?.data?.errors,
          total_pages: response?.data?.total_pages,
          skins: response?.data?.skins,
        })
      )
    }
  }
  yield put(AuthActions.setErrorsLoading(false))
  // console.log("fetchErrors", response);
}
export function* deleteError(data) {
  const response = yield call(AuthRequest.deleteErrorReq, data.id)
  if (response?.data) {
    if (response?.status === 200) {
      notification["success"]({
        message: `Error con id ${data.id} [DELETED]`,
        placement: "topRight",
      })
      if (data.c) {
        data.c()
      }
    }
  }
  // console.log("deleteErrorReq", response);
}

export function* sendDataForm(data) {
  if (data.is_sepafin) {
    yield call(setPaymentLoading, "PaymentLoading")
  }
  const response = yield call(
    AuthRequest.sendDataFormReq,
    data.typee,
    data.link,
    data.nome_agenzia,
    data.extra_data,
    data.bagaglio,
    data.bagaglio_stiva,
    data.partenza,
    data.partenza_stazione,
    data.andata_time,
    data.destinazione,
    data.destinazione_stazione,
    data.compagnie,
    data.adulti,
    data.ragazzi,
    data.tipologia_biglietto,
    data.ritorno_date,
    data.categoria,
    data.descrizione_categoria,
    data.quantity,
    data.name,
    data.email,
    data.telefono,
    data.prezzo,
    data.username,
    data.codice_fiscale
  )
  if (data.is_sepafin) {
    yield call(
      sepafinResponseHanlde,
      response,
      "PaymentLoading",
      data.username,
      null
    )
  } else {
    if (response?.status === 200) {
      data.callBack({
        error: false,
        msg: response?.data.message,
      })
    }
  }
  // console.log("ca ka response", data, response);
}
export function* getDataFormDetails({ limit, page_number }) {
  const response = yield call(AuthRequest.getDataFormDetailReq, limit, page_number)

  if (response?.data) {
    if (response?.status === 200) {
      yield put(AuthActions.setDataFormDetails(response?.data))
    }
  }
}
export function* getDataFormDetailsActives(data) {
  const response = yield call(
    AuthRequest.getDataFormDetailActivesReq,
    data.isVisure,
    data.limit,
    data.page_number
  )

  if (response?.data) {
    if (response?.status === 200) {
      yield put(AuthActions.setDataFormDetailsActives(response?.data))
    }
  }
}
export function* getTicketByTicketId(ticket_id) {
  const response = yield call(
    AuthRequest.getTicketByTicketIdReq,
    ticket_id.ticket_id
  )

  if (response?.data) {
    if (response?.status === 200) {
      yield put(AuthActions.setTicketByTicketId(response?.data.data))
    }
  }
  // console.log("fetchErrors", response);
}

export function* updateDataForm(data) {
  const response = yield call(
    AuthRequest.updateDataFormReq,
    data.typee,
    data.link,
    data.nome_agenzia,
    data.extra_data,
    data.bagaglio,
    data.bagaglio_stiva,
    data.partenza,
    data.partenza_stazione,
    data.andata_time,
    data.destinazione,
    data.destinazione_stazione,
    data.compagnie,
    data.adulti,
    data.ragazzi,
    data.tipologia_biglietto,
    data.ritorno_date,
    data.categoria,
    data.descrizione_categoria,
    data.quantity,
    data.name,
    data.email,
    data.telefono,
    data.price,
    data.ticket_id,
    data.consegna,
    data.cognome,
    data.phone,
    data.stato,
    data.citta,
    data.address1,
    data.address2,
    data.provincia,
    data.cap,
    data.note_address,
    data.company_name,
    data.energiaObj,
    data.codice_sconto,
    data.contact_time,
    data.superfice,
    data.nr_bagni,
    data.controsoffiti,
    data.nr_condizionatori,
    data.impianti,
    data.checkedList,
    data.codice_fiscale
  )
  if (response?.status === 200) {
    data.callBack({
      error: false,
      msg: response?.data.message,
    })
  }
}
export function* sendVisureDetails(data) {
  const response = yield call(
    AuthRequest.sendVisureDetailsReq,
    data.typee,
    data.codice_fiscale,
    data.provincia,
    data.address,
    data.telefono,
    data.email,
    data.nome,
    data.cognome,
    data.data_di_nascita,
    data.luogo_di_nascita,
    data.ragione_sociale,
    data.p_iva,
    data.comune,
    data.servizi,
    data.price,
    data.sc
  )
  if (response?.status === 200) {
    data.callBack({
      error: false,
      msg: response?.data.message,
    })
  }
}
export function* getVisure() {
  const response = yield call(AuthRequest.getVisureReq)

  if (response?.data) {
    if (response?.status === 200) {
      yield put(AuthActions.setVisure(response?.data))
    }
  }
}
export function* getVisureByVisureId(visura_id) {
  const response = yield call(AuthRequest.getVisureByVisureIdReq, visura_id)

  if (response?.data) {
    if (response?.status === 200) {
      yield put(AuthActions.setVisureByVisureId(response?.data.data))
    }
  }
}

export function* updateVisura(data) {
  const response = yield call(
    AuthRequest.updateVisuraReq,
    //same
    data.visura_id,
    data.typee,
    data.codice_fiscale,
    data.provincia,
    data.address,
    data.telefono,
    data.email,
    data.price,
    //type2
    data.ragione_sociale,
    data.p_iva,
    data.comune,
    //type1
    data.nome,
    data.cognome,
    data.data_di_nascita,
    data.luogo_di_nascita,
    data.servizi
  )
  if (response?.status === 200) {
    data.callBack({
      error: false,
      msg: response?.data.message,
    })
  }
}
export function* getAgentByUserId(data) {
  const response = yield call(
    AuthRequest.getAgentByUserIdReq,
    data.user_id,
    data.skin_id
  )

  if (response?.data) {
    if (response?.status === 200) {
      yield put(AuthActions.setUserDetail({ ...response?.data.user, role: "agent" }))
    }
  }
}
export function* getUserByUserId(data) {
  const response = yield call(
    AuthRequest.getUserByUserIdReq,
    data.user_id,
    data.skin_id
  )

  if (response?.data) {
    if (response?.status === 200) {
      yield put(AuthActions.setUserDetail({ ...response?.data.user, role: "user" }))
    }
  }
}
export function* getSkins() {
  const response = yield call(AuthRequest.getSkinsReq)

  if (response?.data) {
    if (response?.status === 200) {
      yield put(AuthActions.setSkins(response?.data.users))
    }
  }
}
export function* getFaturaDetails(params) {
  const response = yield call(
    AuthRequest.getFaturaDetailsReq,
    params.user_id,
    params.year,
    params.month
  )
  if (response?.data) {
    if (response?.status === 200) {
      yield put(AuthActions.setFaturaDetails(response?.data))
    }
  }
}
export function* getAllServices({ skin_id, supplier }) {
  yield put(AuthActions.setServicesLoading(true))
  const response = yield call(AuthRequest.getAllServicesReq, skin_id, supplier)
  if (response?.data) {
    if (response?.status === 200) {
      yield put(AuthActions.setAllServices(response?.data.result))
    }
  }

  yield put(AuthActions.setServicesLoading(false))
}
export function* getAllFaturaBySearch({
  username,
  year,
  month,
  perPage,
  page_number,
  raport,
}) {
  yield put(AuthActions.setFatturaLoading(true))
  const response = yield call(
    AuthRequest.getAllFaturaBySearchReq,
    username,
    year,
    month,
    perPage,
    page_number,
    raport
  )
  if (response?.data) {
    if (response?.status === 200) {
      yield put(
        AuthActions.setAllFaturaBySearch({
          FaturaDetails: response?.data?.fatture,
          Users: response?.data?.usernames && response?.data?.usernames,
          total_pages: response?.data?.total_pages,
          total_records: response?.data?.total_records,
        })
      )
    }
    yield put(AuthActions.setFatturaLoading(false))
  }
}
export function* sendMailFattura({ file_name }) {
  const response = yield call(AuthRequest.sendMailFatturaReq, file_name)
  if (response) {
    notification["success"]({
      message: "Azione completata",
      description: response?.data.message,
      placement: "bottomRight",
    })
  }
}
export function* AddSkinNew({ name, url, email }) {
  const response = yield call(AuthRequest.AddSkinReq, name, url, email)
  if (response?.data) {
    // console.log(response?.data.skin_id);
    yield put(AuthActions.setSkinId(response?.data.skin_id))
    yield put(AuthActions.registerSkinSucc({ addSkinSucc: true }))
  }
}
export function* getWidgetPayments({ skin_id }) {
  const response = yield call(AuthRequest.widgetPaymentsReq, skin_id)
  if (response?.data) {
    yield put(AuthActions.setWidgetPayments(response?.data.payments))
  }

  // console.log("response wiget payments", response);
}

export function* getBookingsBolMr({ id_prenotazione, service_id, user_id }) {
  yield call(setPaymentLoading, "getBookingsBolMr")

  const response = yield call(
    AuthRequest.getBookingsBolMrReq,
    id_prenotazione,
    service_id,
    user_id
  )
  const { data, message } = response?.data || {}
  if (data) {
    yield put(AuthActions.setBolletiniBianchi(response.data))
    notification["success"]({ message })
  }
  yield call(notification.close, "getBookingsBolMr")
}

export function* AddSuperAdmin({
  first_name,
  last_name,
  gender,
  username,
  email,
  phone,
  personal_number,
  password,
  confirm_password,
  address,
  city,
  comune_code,
  cap,
  country,
  birth_place,
  birth_country,
  birthday,
  a_ragione_sociale,
  a_p_iva,
  a_codice_fiscale,
  skin_id,
  c,
}) {
  const response = yield call(
    AuthRequest.AddSuperAdminReq,
    first_name,
    last_name,
    gender,
    username,
    email,
    phone,
    personal_number,
    password,
    confirm_password,
    address,
    city,
    comune_code,
    cap,
    country,
    birth_place,
    birth_country,
    birthday,
    a_ragione_sociale,
    a_p_iva,
    a_codice_fiscale,
    skin_id
  )
  if (response) {
    if (response.data) {
      yield call(c)
      notification["success"]({
        message: "Azione completata",
        description: response?.data.message,
      })
    }
  }
}
export function* AddExtraData({
  mail,
  address,
  cel,
  link1,
  link2,
  link3,
  link4,
  link5,
  link6,
  fb,
  ig,
  pin,
  yt,
  primo_color,
  secondo_color,
  background_color,
  head_color,
  skin_id,
}) {
  const response = yield call(
    AuthRequest.AddExtraDataReq,
    mail,
    address,
    cel,
    link1,
    link2,
    link3,
    link4,
    link5,
    link6,
    fb,
    ig,
    pin,
    yt,
    primo_color,
    secondo_color,
    background_color,
    head_color,
    skin_id
  )
  if (response) {
    yield put(AuthActions.registerSkinSucc({ addExtraDataSucc: true }))
  }
}

export function* AddInformazioniFinanziario({
  nome_banca,
  societa_beneficiaria,
  iban,
  tipo_affitto,
  canone_mensile,
  agenzia_percentuale,
  agende_percentuale,
  metodo_affitto,
  skin_affitto,
  minimum_agenzies,
  ranges,
  skin_id,
  c,
}) {
  const response = yield call(
    AuthRequest.AddInfoFinanzReq,
    nome_banca,
    societa_beneficiaria,
    iban,
    tipo_affitto,
    canone_mensile,
    agenzia_percentuale,
    agende_percentuale,
    metodo_affitto,
    skin_affitto,
    minimum_agenzies,
    ranges,
    skin_id
  )
  if (response) {
    if (response.data) {
      yield put(AuthActions.registerSkinSucc({ addExtraDataSucc: true }))
      yield call(c)
      notification["success"]({
        message: "Azione completata",
        description: response?.data.message,
      })
    }
  }
}
export function* getStatistiche(params) {
  yield put(AuthActions.setStatisticheLoading(true))
  const response = yield call(
    AuthRequest.getStatisticheReq,
    params.skin_id,
    params.from,
    params.to,
    params.username,
    params.service
  )
  if (response) {
    const stats = yield select((state) => state.auth.Statistiche)
    yield put(
      AuthActions.setStatistiche({
        ...stats,
        total: {
          commissione: response?.data?.total?.commissione,
          proviggioni: response?.data?.total?.proviggioni,
          importo: response?.data?.total?.importo,
          system_aggio: response?.data?.total?.system_aggio,
        },
        data: response?.data?.data,
        filter: {
          username: params.username,
          service: params.service,
        },
      })
    )
  }
  yield put(AuthActions.setStatisticheLoading(false))
}
export function* getVisureByServiceId(params) {
  const response = yield call(AuthRequest.getVisureByServiceIdReq, params.service_id)
  if (response?.data) {
    yield put(
      AuthActions.setVisureByServiceId({
        ...response?.data,
        service_id: params.service_id,
      })
    )
    params.callBack()
  }
}

function downloadPDF(b64Data, name) {
  let file =
    `data:application/pdf;headers=filename%3D${name};base64,` + encodeURI(b64Data)
  const downloadLink = document.createElement("a")
  downloadLink.href = file
  downloadLink.download = name
  downloadLink.click()
}

export function* getVisureDocument(params) {
  yield put(AuthActions.setVisureDocumentLoading(true))
  const response = yield call(AuthRequest.getVisureDocumentReq, params.barcode)
  yield put(AuthActions.setVisureDocumentLoading(false))
  if (response?.data) {
    yield put(AuthActions.setVisureDocument(response?.data))
    downloadPDF(response?.data?.data?.file_base64_pdf, response?.data?.data?.nome)
  }
}

export function* getBussinessInfos(params) {
  if (params.is_sepafin) {
    yield call(setPaymentLoading, "PaymentLoading")
  }
  yield put(AuthActions.setBussinessInfoLoading(true))
  const response = yield call(
    AuthRequest.getBussinessInfosReq,
    params.piva_or_cf,
    params.username
  )
  yield put(AuthActions.setBussinessInfoLoading(false))
  if (params.is_sepafin) {
    yield call(
      sepafinResponseHanlde,
      response,
      "PaymentLoading",
      params.username,
      null
    )
  } else {
    if (response?.data) {
      yield put(AuthActions.setBussinessInfos(response?.data?.data))
      params.callback && params.callback()
    }
  }
}

export function* UpdateServiceChangeStatus(params) {
  const response = yield call(
    AuthRequest.ServiceChangeStatusReq,
    params.name,
    params.full_name,
    params.company_id,
    params.active,
    params.skin_id
  )
  if (response) {
    yield call(params.c)
    notification["success"]({
      message: response?.data?.message,
    })
  }
}

export function* buyBitNovoVoucher({
  service_id,
  importo,
  validateCustomer = false,
  callBack,
  is_sepafin,
  username,
}) {
  yield call(setPaymentLoading, "buyBitNovoVoucher")
  const response = yield call(AuthRequest.buyBitNovoVoucherReq, {
    service_id,
    importo,
    validateCustomer,
    username,
  })
  if (response) {
    if (is_sepafin) {
      yield call(
        sepafinResponseHanlde,
        response,
        "buyBitNovoVoucher",
        username,
        null
      )
    } else {
      if (response?.data) {
        if (response?.data.wallet) {
          const accountData = localStorage.getItem("accountDataB")
          const data = JSON.parse(accountData)

          const d = {
            ...data,
            profile: {
              ...data.profile,
              wallet: response?.data.wallet,
            },
          }

          localStorage.setItem("accountDataB", JSON.stringify(d))
          yield put(AuthActions.setAccountInfo(d))
        }
        yield put(
          AuthActions.setRechargeMobile({
            ...response?.data,
            msg: response?.data?.message,
          })
        )
      } else if (response?.error) {
        yield put(AuthActions.setRechargeMobile(response?.error?.response?.data))
      }
      if (callBack) {
        callBack(false)
      }
    }
  }
  yield call(notification.close, "buyBitNovoVoucher")
}

export function* getCustomVoucherReq(params) {
  if (params.is_sepafin) {
    yield call(setPaymentLoading, "PaymentLoading")
  }
  const response = yield call(
    AuthRequest.customVoucher,
    params.service_id,
    params.tel_no,
    params.username,
    params.codice_fiscale
  )
  if (params.is_sepafin) {
    yield call(
      sepafinResponseHanlde,
      response,
      "PaymentLoading",
      params.username,
      null
    )
  } else {
    if (response) {
      if (response?.data) {
        if (response?.data.wallet) {
          const accountData = localStorage.getItem("accountDataB")
          const data = JSON.parse(accountData)

          const d = {
            ...data,
            profile: {
              ...data.profile,
              wallet: response?.data.wallet,
            },
          }

          localStorage.setItem("accountDataB", JSON.stringify(d))
          yield put(AuthActions.setAccountInfo(d))
        }
        yield put(
          AuthActions.setRechargeMobile({
            ...response?.data,
            msg: response?.data?.message,
          })
        )
      } else if (response?.error) {
        yield put(AuthActions.setRechargeMobile(response?.error?.response?.data))
      }
      if (params.callBack) {
        params.callBack(false)
      }
    }
  }
}

export function* buyExalogic(params) {
  if (params.is_sepafin) {
    yield call(setPaymentLoading, "PaymentLoading")
  }
  const response = yield call(
    AuthRequest.buyExalogicReq,
    params.service_id,
    params.tel_no,
    params.username,
    params.codice_fiscale
  )
  if (params.is_sepafin) {
    yield call(
      sepafinResponseHanlde,
      response,
      "PaymentLoading",
      params.username,
      null
    )
  } else {
    if (response) {
      if (response?.data) {
        if (response?.data.wallet) {
          const accountData = localStorage.getItem("accountDataB")
          const data = JSON.parse(accountData)

          const d = {
            ...data,
            profile: {
              ...data.profile,
              wallet: response?.data.wallet,
            },
          }

          localStorage.setItem("accountDataB", JSON.stringify(d))
          yield put(AuthActions.setAccountInfo(d))
        }
        yield put(
          AuthActions.setRechargeMobile({
            ...response?.data,
            msg: response?.data?.message,
          })
        )
      } else if (response?.error) {
        yield put(AuthActions.setRechargeMobile(response?.error?.response?.data))
      }
      if (params.callBack) {
        params.callBack(false)
      }
    }
  }
}

export function* getStatisticheMain() {
  const response = yield call(AuthRequest.StatisticheMainReq)
  if (response?.data) {
    yield put(
      AuthActions.setStatisticheMain({
        data: response?.data.data,
        rete: response?.data.rete,
        total: response?.data.total,
      })
    )
  }
}
export function* fetchBolletini({
  codiceFiscaleOrPartitaIvaIsRequired,
  service_id,
  person_type,
  via_piazza,
  citta,
  importo,
  tipologia,
  numero_conto_corrente,
  causale,
  nome,
  cognome,
  codice_fiscale,
  denominazione,
  partita_iva,
  codice_identificativo,
  dynamicFields,
  clearFields,
  importo_cent,
  username,
}) {
  yield call(setPaymentLoading, "PaymentLoading")
  const response = yield call(
    AuthRequest.fetchBolletiniRequest,
    codiceFiscaleOrPartitaIvaIsRequired,
    service_id,
    person_type,
    via_piazza,
    citta,
    importo + "." + importo_cent,
    tipologia,
    numero_conto_corrente,
    causale,
    nome,
    cognome,
    codice_fiscale,
    denominazione,
    partita_iva,
    codice_identificativo,
    dynamicFields
  )
  yield call(sepafinResponseHanlde, response, "PaymentLoading", username, () => {
    clearFields()
    AuthActions.setDetailByContoCorrente({
      codiceFiscaleOrPartitaIvaIsRequired: true,
    })
  })
}
export function* buyTicketOnline({
  typee,
  link,
  nome_agenzia,
  extra_data,
  price,
  consegna,
  nome,
  cognome,
  email,
  phone,
  stato,
  citta,
  address1,
  address2,
  provincia,
  cap,
  note_address,
  company_name,
  codice_sconto,
  contact_time,
  superfice,
  nr_bagni,
  controsoffitti,
  nr_condizionatori,
  impianti,
  checkedList,
  callBack,
}) {
  const response = yield call(
    AuthRequest.buyTicketOnlineReq,
    typee,
    link,
    nome_agenzia,
    extra_data,
    price,
    consegna,
    nome,
    cognome,
    email,
    phone,
    stato,
    citta,
    address1,
    address2,
    provincia,
    cap,
    note_address,
    company_name,
    codice_sconto,
    contact_time,
    superfice,
    nr_bagni,
    controsoffitti,
    nr_condizionatori,
    impianti,
    checkedList
  )
  if (response?.status === 200) {
    callBack({
      error: false,
      msg: response?.data.message,
    })
  }
}

export function* buyAssicurazione({
  typee,
  nome,
  cognome,
  email,
  telefono,
  codice_fiscale,
  via,
  nr_civicio,
  cap,
  citta,
  provincia,
  situazione,
  tipo,
  targa,
  accesories,
  valore,
  note,
  username,
  callback,
}) {
  const response = yield call(
    AuthRequest.buyAssicurazioneReq,
    typee,
    nome,
    cognome,
    email,
    telefono,
    codice_fiscale,
    via,
    nr_civicio,
    cap,
    citta,
    provincia,
    situazione,
    tipo,
    targa,
    accesories,
    valore,
    note,
    username
  )
  if (response?.status === 200) {
    callback(response?.data.message)
  }
}

export function* setPagoPa({
  service_id,
  tipologia,
  codice_fiscale_bol,
  codice_aviso,
  clearFields,
  tipo_veicolo,
  targa,
  username,
}) {
  yield call(setPaymentLoading, "pagoPaPayment")
  const response = yield call(
    AuthRequest.pagoPaRequest,
    service_id,
    tipologia,
    codice_fiscale_bol,
    codice_aviso,
    tipo_veicolo,
    targa,
    username
  )
  yield call(sepafinResponseHanlde, response, "pagoPaPayment", username, clearFields)
}

export function* mrPayPagoPa({
  service_id,
  tipologia,
  codice_fiscale_bol,
  codice_aviso,
  clearFields,
  importo,
  importo_cent,
  ente_creditore,
  eseguito_da,
  user_id,
  isMrPay,
  callBack,
}) {
  yield call(setPaymentLoading, "mrPayPagoPaRequest")

  const formattedImporto = isMrPay
    ? importo + "." + importo_cent
    : importo + importo_cent.toString().padEnd(2, "0")

  const response = yield call(
    AuthRequest.mrPayPagoPaRequest,
    service_id,
    tipologia,
    codice_fiscale_bol,
    codice_aviso,
    formattedImporto,
    ente_creditore,
    eseguito_da,
    user_id,
    isMrPay
  )

  if (response) {
    if (response?.data) {
      const paymentType = typeOfPayment(response?.data)
      const paymentNotFinished =
        paymentType === "Prenotazione" || response?.data?.isPrenotazione
      yield put(
        AuthActions.setBolletiniBianchi({
          ...response?.data,
          paymentType,
          paymentNotFinished,
        })
      )
      notification["info"]({
        message: response?.data?.message,
      })
      clearFields(false)
    } else if (response?.error) {
      if (response?.error.response?.status === 444) {
        const error = { errors: { notCorrect: ["data are not corrected."] } }
        yield put(AuthActions.setBolletiniBianchi(error))
      } else {
        yield put(AuthActions.setBolletiniBianchi(response?.error.response?.data))
      }
    }
    if (callBack) {
      callBack(false)
    }
  }
  yield call(notification.close, "mrPayPagoPaRequest")
}

export function* getBolletiniAuto(params) {
  yield call(setPaymentLoading, "getBolletiniAuto")

  const response = yield call(
    AuthRequest.getBolletiniAutoReq,
    params.service_id,
    params.tipo,
    params.targa,
    params.user_id
  )
  if (response) {
    if (response?.data) {
      yield put(AuthActions.setBolletiniBianchi(response?.data))
      notification["info"]({
        message: response?.data?.message,
      })

      params.clearFields(false)
    } else if (response?.error) {
      if (response?.error.response?.status === 444) {
        const error = { errors: { notCorrect: ["data are not corrected."] } }
        yield put(AuthActions.setBolletiniBianchi(error))
      } else {
        yield put(AuthActions.setBolletiniBianchi(response?.error.response?.data))
      }
    }
    if (params.callBack) {
      params.callBack(false)
    }
  }
  yield call(notification.close, "getBolletiniAuto")
}

export function* bolletinoCapture({ payload }) {
  yield call(setPaymentLoading, "bolletinoCaptureReq")

  const response = yield call(AuthRequest.bolletinoCaptureReq, payload)
  if (response) {
    yield put(
      AuthActions.setBolletiniBianchi({
        ...response?.data.return,
      })
    )
    yield put(
      AuthActions.setBolletiniBianchi({
        ...response?.data.return,
      })
    )
    notification["info"]({
      message: response?.data?.message,
    })
  }
  yield call(notification.close, "bolletinoCaptureReq")
}

export function* setFreccia({
  service_id,
  importo,
  causale,
  person_type,
  via_piazza,
  citta,
  identificativo_pagamento,
  iban,
  cin_importo,
  cin_intermedio,
  cin_complessivo,
  codice_esenzione,
  nome,
  cognome,
  denominazione,
  isPos,
  username,
}) {
  yield call(setPaymentLoading, "frecciaPayment")

  const response = yield call(
    AuthRequest.frecciaRequest,
    service_id,
    importo,
    causale,
    person_type,
    via_piazza,
    citta,
    identificativo_pagamento,
    iban,
    cin_importo,
    cin_intermedio,
    cin_complessivo,
    codice_esenzione,
    nome,
    cognome,
    denominazione,
    isPos,
    username
  )
  yield call(sepafinResponseHanlde, response, "frecciaPayment", username, null)
}
export function* setMavRav({
  service_id,
  person_type,
  via_piazza,
  citta,
  phone_number,
  importo,
  codice,
  nome,
  cognome,
  denominazione,
  partita_iva,
  causale,
  clearFields,
  isPos,
  username,
}) {
  yield call(setPaymentLoading, "mavRavPayment")

  const response = yield call(
    AuthRequest.mavRavRequest,
    service_id,
    person_type,
    via_piazza,
    citta,
    phone_number,
    importo,
    codice,
    nome,
    cognome,
    denominazione,
    causale,
    partita_iva,
    isPos,
    username
  )
  yield call(sepafinResponseHanlde, response, "mavRavPayment", username, clearFields)
}

export function* payPagoPa({
  service_id,
  total_amount,
  fee_amount,
  pagamento_id,
  isPos,
  username,
}) {
  yield call(setPaymentLoading, "payPagoPA")

  const response = yield call(
    AuthRequest.payPagoPaReq,
    service_id,
    total_amount,
    fee_amount,
    pagamento_id,
    isPos,
    username
  )
  yield call(sepafinResponseHanlde, response, "payPagoPA", username, null)
}

export function* cancelPagamento({ pagamento_id, callBack, service_id }) {
  const service = service_id?.service_id || service_id
  const response = yield call(AuthRequest.cancelPagamentoReq, pagamento_id, service)
  if (response?.status === 200 && response?.data) {
    callBack()
    yield put(AuthActions.setBolletiniBianchi({}))
  }
}

export function* setBokingSep({
  service_id,
  person_type,
  via_piazza,
  citta,
  codice_ufficio,
  codice_atto,
  data_pagamento,
  importo,
  taxes_array,
  nome,
  cognome,
  codice_fiscale,
  denominazione,
  partita_iva,
  codiceOperazione,
  clearFields,
  username,
}) {
  yield call(setPaymentLoading, "bokkingF24")

  const response = yield call(
    AuthRequest.bokkingF24Req,
    service_id,
    person_type,
    via_piazza,
    citta,
    codice_ufficio,
    codice_atto,
    data_pagamento,
    importo,
    taxes_array,
    nome,
    cognome,
    codice_fiscale,
    denominazione,
    codiceOperazione,
    partita_iva,
    username
  )
  yield call(sepafinResponseHanlde, response, "bokkingF24", username, clearFields)
}

export function* internetRegister({ name, indirizo, phone, account_info }) {
  const response = yield call(
    AuthRequest.internetRegisterReq,
    name,
    indirizo,
    phone,
    account_info.profile.id
  )
  if (response?.data?.message) {
    account_info.profile.filled_internet_form = 1
    yield put(AuthActions.setAccountInfo(account_info))
    yield call(notification["success"], {
      message: response?.data?.message,
    })
  }
}
export function* setPayFSaga({
  service_id,
  importo,
  fee,
  pagamento_id,
  isPos,
  username,
}) {
  const service = service_id?.service_id || service_id
  yield call(setPaymentLoading, "payF24")
  const response = yield call(
    AuthRequest.payFReq,
    service,
    importo,
    fee,
    pagamento_id,
    isPos,
    username
  )
  yield call(sepafinResponseHanlde, response, "payF24", username, null)
}

export function* buyVisure({
  service_id,
  fields,
  extra_options,
  callback,
  is_sepafin,
  username,
}) {
  if (is_sepafin) {
    yield call(setPaymentLoading, "payVisureSepafin")
  }
  const response = yield call(
    AuthRequest.buyVisureReq,
    service_id,
    fields,
    extra_options,
    username
  )
  if (is_sepafin) {
    yield call(sepafinResponseHanlde, response, "payVisureSepafin", username, null)
  } else {
    if (response?.data?.message) {
      yield call(notification["success"], {
        message: response?.data?.message,
      })
      yield call(callback)
    }
  }
}

export function* getRegistrazioneData() {
  const response = yield call(AuthRequest.getRegistrazioneDataReq)
  if (response?.data) {
    yield put(AuthActions.setRegistrazioneData(response?.data?.data))
  }
}
export function* getCompanyNameByContoCorrento({ contoCorrente, tipologia }) {
  const response = yield call(
    AuthRequest.getCompanyNameByContoCorrentoReq,
    contoCorrente,
    tipologia
  )
  if (response?.status === 200) {
    if (response?.data) {
      yield put(AuthActions.setDetailByContoCorrente(response?.data))
    } else {
      yield put(
        AuthActions.setDetailByContoCorrente({
          codiceFiscaleOrPartitaIvaIsRequired: true,
        })
      )
    }
  } else {
    yield put(
      AuthActions.setDetailByContoCorrente({
        codiceFiscaleOrPartitaIvaIsRequired: true,
      })
    )
  }
  if (response?.message) {
    notification["success"]({
      message: response?.data?.message,
    })
  }
}

export function* createUserBgame({
  nome,
  cognome,
  data_nascita,
  sesso,
  id_nazione_nascita,
  id_comune_nascita,
  codice_fiscale,
  id_comune_residenza,
  indirizzo,
  cap,
  telefono,
  cellulare,
  email,
  id_tipo_documento,
  documento,
  rilasciato_da,
  data_rilascio,
  data_scadenza,
  id_nazione_cittadinanza,
  nick,
  password,
  question,
  answer,
}) {
  const response = yield call(
    AuthRequest.createUserBgameReq,
    nome,
    cognome,
    data_nascita,
    sesso,
    id_nazione_nascita,
    id_comune_nascita,
    codice_fiscale,
    id_comune_residenza,
    indirizzo,
    cap,
    telefono,
    cellulare,
    email,
    id_tipo_documento,
    documento,
    rilasciato_da,
    data_rilascio,
    data_scadenza,
    id_nazione_cittadinanza,
    nick,
    password,
    question,
    answer
  )
  if (response?.message) {
    notification["success"]({
      message: response?.data?.message,
    })
  }
}
export function* sepafinCredentials(params) {
  const response = yield call(
    AuthRequest.sepafinCredentials,
    params.sep_username,
    params.sep_password
  )
  if (response?.data) {
    if (response?.status === 200) {
      notification["success"]({
        message: response?.data?.message,
      })
    }
  }
}

export function* sepafinLogin(params) {
  const response = yield call(
    AuthRequest.sepafinLogin,
    params.sepafin_username,
    params.sepafin_password
  )
  const { expires, sepafin_access_token, message, waitForOTP } = response?.data || {}
  if (response?.data && response?.status === 200) {
    if (!waitForOTP) {
      sepafinRegisterLogin(sepafin_access_token, expires, params.username)
      yield put(AuthActions.setIsLoggedinSepafin(true))
      yield put(AuthActions.setShowSepafinLogin(false))
    }
    yield call(notification["success"], { key: "sepafinLogin", message })
  }
}

export function* sepafinForgetPassword(params) {
  const response = yield call(
    AuthRequest.sepafinForgetPassword,
    params.sepafin_username
  )
  if (response?.data && response?.status === 200) {
    notification["success"]({
      message: response?.data?.message || "Sucefully password reset",
    })
  }
}

export function* getAgencyPos({ username }) {
  const response = yield call(AuthRequest.getAgencyPosReq, username)
  const responseData = response?.data?.data
    ? response?.data?.data
    : response && response?.data

  if (needRelogin(response)) {
    yield call(sepaRelogin(username))
  } else if (responseData) {
    yield put(AuthActions.setAgencyPos(responseData.availablePoses))
  }
}

export function* nuovoBillerSubmit(params) {
  const {
    denomination,
    numero_conto_corrente,
    note,
    file_content_type,
    file_base64,
  } = params
  const response = yield call(
    AuthRequest.nuovoBillerReq,
    denomination,
    numero_conto_corrente,
    note,
    file_content_type,
    file_base64
  )
  if (response?.data && response?.status === 200) {
    notification["success"]({
      message: response?.data?.message,
    })
  }
}

export function* activateDeactivateSepafin(params) {
  const { userId, supplier } = params
  const response = yield call(
    AuthRequest.activateDeactivateSepafinReq,
    userId,
    supplier
  )
  if (response?.data && response?.status === 200) {
    notification["success"]({
      message: response?.data?.message,
    })
  }
}

export function* getContiAccount({ service_id }) {
  const response = yield call(AuthRequest.fetchContiAccount, service_id)

  if (response?.data && response?.status === 200) {
    notification["success"]({
      message: response?.data?.message,
    })
  }
  yield put(AuthActions.setContiAccount(response?.data))
}

export function* setNewContiAccount({ service_id, conti_username, conti_password }) {
  const response = yield call(
    AuthRequest.setNewContiAccountReq,
    service_id,
    conti_username,
    conti_password
  )
  if (response?.data && response?.status === 200) {
    notification["success"]({
      message: response?.data?.message,
    })
    yield put(AuthActions.setNewContiStatus(response?.status))
  } else {
    yield put(AuthActions.setNewContiStatus(response?.error?.response?.status))
  }
}

export function* getCouriers({
  service_id,
  sender_country_code,
  sender_cap,
  sender_place_name,
  receiver_country_code,
  receiver_cap,
  receiver_place_name,
  packages_details,
}) {
  yield put(AuthActions.setCouriersLoading(true))
  const response = yield call(
    AuthRequest.getCouriersReq,
    service_id,
    sender_country_code,
    sender_cap,
    sender_place_name,
    receiver_country_code,
    receiver_cap,
    receiver_place_name,
    packages_details
  )
  if (response?.data && response?.status === 200) {
    notification["success"]({
      message: response?.data?.message,
    })
    yield put(AuthActions.setCouriers(response?.data?.data))
  }
  yield put(AuthActions.setCouriersLoading(false))
}

export function* setShippingInfo({
  service_id,
  shipping_id,
  nominativoMittente,
  pressoMittente,
  indirizzoMittente,
  comuneMittente,
  provinciaMittente,
  capMittente,
  telefonoMittente,
  emailMittente,
  nominativoDestinatario,
  pressoDestinatario,
  indirizzoDestinatario,
  comuneDestinatario,
  provinciaDestinatario,
  capDestinatario,
  telefonoDestinatario,
  emailDestinatario,
  noteDestinatario,
  destinatarioIsBusiness,
  packages_details,
}) {
  yield put(AuthActions.setCouriersLoading(true))
  const response = yield call(
    AuthRequest.setShippingInfoReq,
    service_id,
    shipping_id,
    nominativoMittente,
    pressoMittente,
    indirizzoMittente,
    comuneMittente,
    provinciaMittente,
    capMittente,
    telefonoMittente,
    emailMittente,
    nominativoDestinatario,
    pressoDestinatario,
    indirizzoDestinatario,
    comuneDestinatario,
    provinciaDestinatario,
    capDestinatario,
    telefonoDestinatario,
    emailDestinatario,
    noteDestinatario,
    destinatarioIsBusiness,
    packages_details
  )
  if (response?.data && response?.status === 200) {
    notification["success"]({
      message: response?.data?.message,
    })
    yield put(AuthActions.setShippingData(response?.data?.data?.shipping_info))
  }
  yield put(AuthActions.setCouriersLoading(false))
}

export function* buyShippingOrder({ service_id, shipping_info_id }) {
  const response = yield call(
    AuthRequest.buyShippingOrderReq,
    service_id,
    shipping_info_id
  )
  if (response?.data && response?.status === 200) {
    notification["success"]({
      message: response?.data?.message,
    })
    yield put(AuthActions.setShippingOrder(response?.data))
  }
}

export function* getAnnualiStatitiche({ skinId }) {
  yield put(AuthActions.setAnnualiStatisticheLoading(true))
  const response = yield call(AuthRequest.fetchAnnualiStatitiche, skinId)
  if (response?.data && response?.status === 200) {
    yield put(AuthActions.setAnnualiStatitiche(response?.data))
  }
  yield put(AuthActions.setAnnualiStatisticheLoading(false))
}

export function* getLatestServiceStatistic({ skinId }) {
  const response = yield call(AuthRequest.fetchLatestServiceStatistic, skinId)
  if (response?.data && response?.status === 200) {
    yield put(AuthActions.setLatestServiceStatistic(response?.data))
  }
}

export function* sendInfoEmail({ city, email, phone, name, surname, id }) {
  const response = yield call(
    AuthRequest.sendInfoEmailReq,
    city,
    email,
    phone,
    name,
    surname,
    id
  )

  if (response?.data && response?.status === 200) {
    notification["success"]({
      message: response.data.message,
    })
  } else {
    notification["error"]({
      message: "An error occurred. Please try again.",
    })
  }
}

export function* createExalogicAccount({
  name,
  username,
  password,
  domain,
  resetFields,
  closeModal,
}) {
  yield put(AuthActions.setExalogicAccountLoading(true))

  const response = yield call(
    AuthRequest.createExalogicAccountReq,
    name,
    username,
    password,
    domain
  )

  if (response?.data && response?.status === 200) {
    notification["success"]({
      message: response.data.message,
    })
    resetFields()
    closeModal()
  }

  yield put(AuthActions.setExalogicAccountLoading(false))
}

export function* getContiGiocoServices() {
  const response = yield call(AuthRequest.getContiGiocoServicesReq)

  if (response?.data && response?.status === 200) {
    yield put(AuthActions.setContiGiocoServices(response.data))
  }
}

export function* createGiftCard(params) {
  const response = yield call(
    AuthRequest.createGiftCardReq,
    params.amount,
    params.cf
  )

  if (response) {
    console.log(response)
    if (response?.data) {
      if (response?.data.wallet) {
        const accountData = localStorage.getItem("accountDataB")
        const data = JSON.parse(accountData)

        const d = {
          ...data,
          profile: {
            ...data.profile,
            wallet: response?.data.wallet,
          },
        }

        localStorage.setItem("accountDataB", JSON.stringify(d))
        yield put(AuthActions.setAccountInfo(d))
      }
      yield put(
        AuthActions.setRechargeMobile({
          ...response?.data,
          msg: response?.data?.message,
        })
      )
    } else if (response?.error) {
      yield put(AuthActions.setRechargeMobile(response?.error?.response?.data))
    }
  }
}
