import React from "react"
import { baseUrlImg } from "../../config/api"

const PrintTicketPin = ({ arr, barcode }) => {
  const rowLabels = [
    { label: "Service Name", field: "serviceName" },
    { label: "TXN ID", field: "TXN ID" },
    { label: "E-Pay TXN ID", field: "epay TXN ID" },
    { label: "Date/Ora", field: ["Data POS", "Ora POS"] },
    { label: "Numero Seriale", field: "Numero seriale" },
    { label: "Scadenza", field: "Scadenza" },
    { label: "PIN", field: "PIN" },
    { label: "Importo", field: "Importo" },
  ]
  return (
    <div className="pin-receipt" style={{ padding: "0 20px" }}>
      <div className="receipt-info">
        <span className="service-name">
          {JSON.parse(arr.receipt)["serviceName"]}
        </span>
        <img src={`${baseUrlImg}/${arr.logo}`} alt="service logo" />
        {rowLabels.map(({ label, field }, index) => (
          <div key={index} className="receipt-row">
            {label}:
            <span>
              {Array.isArray(field)
                ? `${JSON.parse(arr.receipt)[field[0]]} ${
                    JSON.parse(arr.receipt)[field[1]]
                  }`
                : JSON.parse(arr.receipt)[field]}
            </span>
          </div>
        ))}
      </div>
      {/* {barcode !== 1 && (
        <div className="receipt-barcode">
          <img
            className="barcodeModal"
            src={`https://barcode.tec-it.com/barcode.ashx?data=${
              barcode || 0
            }&code=Code128&multiplebarcodes=false&translate-esc=false&unit=Fit&dpi=96&imagetype=Gif&rotation=0&color=%23000000&bgcolor=%23ffffff&qunit=Mm&quiet=0`}
            alt=""
          />
        </div>
      )} */}
      <div className="receipt-info">
        <div className="receipt-description">
          {JSON.parse(arr.receipt)["Description"]}
        </div>
      </div>
    </div>
  )
}

export default PrintTicketPin
