import React from "react"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import { withRouter } from "react-router-dom"
import images from "themes/images"
import { get } from "lodash"
import { Button, Spin, notification } from "antd"
import SpedizioniRow from "./SpedizioniRow"

class SpedizioniStep1 extends React.Component {
  state = {
    formData: {
      senderCountryCode: "IT",
      senderCap: this.props.userConfigura ? this.props.userConfigura.cap : "",
      senderPlaceName: this.props.userConfigura
        ? this.props.userConfigura.a_city
        : "",
      receiverCountryCode: "IT",
      receiverCap: "",
      receiverPlaceName: "",
      packages:
        Object.keys(this.props?.packages).length === 0
          ? [
              {
                id: 1,
                pesoReale: null,
                altezza: null,
                larghezza: null,
                profondita: null,
              },
            ]
          : this.props.packages,
    },
    selectedOption: this.props.selectedShippingId
      ? this.props.selectedShippingId
      : "",
  }

  handleAddRow = () => {
    const { formData } = this.state
    const packages = [...formData.packages]
    packages.push({
      id: packages.length + 1,
      pesoReale: null,
      altezza: null,
      larghezza: null,
      profondita: null,
    })
    this.setState({
      formData: {
        ...formData,
        packages,
      },
    })
  }

  handleRemoveRow = (id) => {
    const { formData } = this.state
    const packages = formData.packages.filter((pckg) => pckg.id !== id)
    this.setState({
      formData: {
        ...formData,
        packages,
      },
    })
  }

  handleChange = (event) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [event.target.name]: event.target.value,
      },
    })
  }

  handlePackagesArrayChange = (index, key, event) => {
    const packages = [...this.state.formData.packages]
    packages[index][key] = event.target.value
    this.setState({ packages })
  }

  handleShippingClick = (option) => {
    this.setState({ selectedOption: option })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const {
      senderCountryCode,
      senderPlaceName,
      senderCap,
      receiverCountryCode,
      receiverPlaceName,
      receiverCap,
      packages,
    } = this.state.formData
    const { setPackagesDetails, getCouriers } = this.props

    const isPackageArrayValid = packages.every((obj) => {
      return Object.values(obj).every((value) => value)
    })

    if (
      !receiverCap ||
      !receiverCountryCode ||
      !receiverPlaceName ||
      !senderCap ||
      !senderCountryCode ||
      !senderPlaceName ||
      !isPackageArrayValid
    ) {
      notification["error"]({
        message: "Errore di convalida",
        description: "Si prega di compilare tutti i campi richiesti.",
      })
      return
    }

    const packages_details = packages.map((obj) => {
      const { id, ...rest } = obj
      return rest
    })

    getCouriers(
      "SPEDPRO001",
      senderCountryCode,
      senderCap,
      senderPlaceName,
      receiverCountryCode,
      receiverCap,
      receiverPlaceName,
      packages_details
    )
    setPackagesDetails(packages)
  }

  componentDidMount = () => {
    const { accountInfo, getConfigura } = this.props
    if (get(accountInfo, "profile.id")) {
      getConfigura(accountInfo.profile.id)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { userConfigura } = this.props
    if (prevProps.userConfigura.cap !== userConfigura.cap) {
      this.setState({
        formData: {
          ...this.state.formData,
          senderCap: userConfigura.cap,
          senderPlaceName: userConfigura.a_city,
        },
      })
    }
  }

  render() {
    const {
      accountInfo,
      history,
      couriers,
      loading,
      setSelectedShippingId,
      setPackagesDetails,
      setCouriers,
    } = this.props
    const {
      senderCountryCode,
      senderCap,
      senderPlaceName,
      receiverCountryCode,
      receiverCap,
      receiverPlaceName,
      packages,
    } = this.state.formData
    const { selectedOption } = this.state

    return (
      <>
        <div className="formsContainer">
          <div className="formsContainer--body animated fadeIn Spedizione">
            <div className="rightForm">
              <div className="rightForm--header">
                <div className="TitleBack">
                  <i
                    className="fal fa-chevron-left Arrow"
                    onClick={() => {
                      history.goBack()
                      setPackagesDetails({})
                      setSelectedShippingId(null)
                      setCouriers({})
                    }}
                  ></i>
                  Spedizioni
                </div>
              </div>
              <div className="rightForm--step1">
                <div className="formsContainer--body__item title">
                  <div className="title--left">
                    <i className="fas fa-dolly-empty"></i>
                    PARTENZA
                  </div>
                  <div className="title--user">
                    <i className="fas fa-star"></i>
                    <span>{accountInfo?.profile?.name}</span>
                    <i className="fas fa-store"></i>
                  </div>
                </div>
                <div className="formsContainer--body__semiCont mt-2 firstStep">
                  <div className="formsContainer--body__item semi">
                    <div className="label">
                      Nazione <span className="Red">*</span>
                    </div>
                    <input
                      type="text"
                      placeholder="Nazione"
                      name="senderCountryCode"
                      value={senderCountryCode}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className="formsContainer--body__item semi">
                    <div className="label">
                      Cap <span className="Red">*</span>
                    </div>
                    <input
                      type="text"
                      placeholder="Digita Cap"
                      name="senderCap"
                      defaultValue={senderCap}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className="formsContainer--body__item semi">
                    <div className="label">
                      Città di Partenza <span className="Red">*</span>
                    </div>
                    <input
                      type="text"
                      placeholder="Città di Partenza"
                      name="senderPlaceName"
                      defaultValue={senderPlaceName}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="formsContainer--body__item title mittente">
                  <div className="title--left">
                    <i className="fas fa-dolly"></i>
                    DESTINAZIONE
                  </div>
                  {/* <div className="title--user">
                    <i className="fas fa-star"></i>
                    <span>Andrea234</span>
                    <i className="fas fa-user"></i>
                  </div> */}
                </div>
                <div className="formsContainer--body__semiCont mt-2 firstStep">
                  <div className="formsContainer--body__item semi">
                    <div className="label">
                      Nazione <span className="Red">*</span>
                    </div>
                    <input
                      type="text"
                      placeholder="Nazione"
                      name="receiverCountryCode"
                      value={receiverCountryCode}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="formsContainer--body__item semi">
                    <div className="label">
                      Cap <span className="Red">*</span>
                    </div>
                    <input
                      type="text"
                      placeholder="Digita Cap "
                      name="receiverCap"
                      value={receiverCap}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="formsContainer--body__item semi">
                    <div className="label">
                      Città di Destinazione <span className="Red">*</span>
                    </div>
                    <input
                      type="text"
                      placeholder="Città di Destinazione"
                      name="receiverPlaceName"
                      value={receiverPlaceName}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="rightForm--footer">
                <div className="title">SPEDIZIONE</div>
                <div className="information">
                  <i className="fas fa-exclamation-triangle"></i>
                  Dichiarare pesi e/o dimensioni inferiori a quelli reali potrebbe
                  causare ritardi nella gestione della spedizione e addebiti sul
                  costo finale
                </div>
                <div className="addrows">
                  {packages &&
                    packages.map((pckg, index) => (
                      <SpedizioniRow
                        id={pckg.id}
                        key={pckg.id}
                        handleAddRow={this.handleAddRow}
                        handleRemoveRow={() => this.handleRemoveRow(pckg.id)}
                        defaultValuePeso={pckg.pesoReale}
                        defaultValueAltezza={pckg.altezza}
                        defaultValueLarghezza={pckg.larghezza}
                        defaultValueProfondita={pckg.profondita}
                        handleChangePeso={this.handlePackagesArrayChange.bind(
                          this,
                          index,
                          "pesoReale"
                        )}
                        handleChangeAltezza={this.handlePackagesArrayChange.bind(
                          this,
                          index,
                          "altezza"
                        )}
                        handleChangeLarghezza={this.handlePackagesArrayChange.bind(
                          this,
                          index,
                          "larghezza"
                        )}
                        handleChangeProfondita={this.handlePackagesArrayChange.bind(
                          this,
                          index,
                          "profondita"
                        )}
                      />
                    ))}
                  <div className="submit-btn">
                    <Button onClick={this.handleSubmit}>Ricerca</Button>
                  </div>
                </div>
                {loading ? (
                  <div className="tariffa-row loading">
                    <Spin tip="Loading..." size="large" />
                  </div>
                ) : (
                  <div className="tariffa-row">
                    {couriers &&
                      couriers.map((option) => (
                        <div
                          key={option.shipping_id}
                          className={`tariffa select-option ${
                            selectedOption == option.shipping_id ? "selected" : ""
                          }`}
                        >
                          <div className="tariffa--img">
                            <img
                              src={images[option?.corriere]}
                              alt={option?.corriere}
                            />{" "}
                            <span>{option?.servizio}</span>
                          </div>
                          <div className="formsContainer--body__semiCont mt-2">
                            <div className="formsContainer--body__item semi">
                              <div className="label">Tariffa</div>
                              <input
                                type="text"
                                defaultValue={
                                  (option?.tariffa / 1.22).toFixed(2) + " €"
                                }
                                readOnly
                              />
                            </div>
                            <div className="formsContainer--body__item semi">
                              <div className="label">Iva</div>
                              <input
                                type="text"
                                defaultValue={
                                  ((0.22 * option?.tariffa) / 1.22).toFixed(2) + " €"
                                }
                                readOnly
                              />
                            </div>
                            <div className="formsContainer--body__item semi">
                              <div className="label">Totale</div>
                              <input
                                type="text"
                                className="totale"
                                defaultValue={option?.tariffa + " €"}
                                readOnly
                              />
                            </div>
                            <div className="formsContainer--body__item semi">
                              <Button
                                onClick={() => {
                                  this.handleShippingClick(option.shipping_id)
                                  setSelectedShippingId(option.shipping_id)
                                }}
                              >
                                Procedi
                              </Button>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }} className="next"
        >
          <Button
            type="primary"
            onClick={this.props.nextStep}
            disabled={this.state.selectedOption == ""}
          >
            Next
          </Button>
        </div>
      </>
    )
  }
}

const mapsStateToProps = (state) => ({
  accountInfo: state.auth.accountInfo,
  userConfigura: state.auth.usersConfigura,
  couriers: state.auth.couriers.shipping_options,
  selectedShippingId: state.auth.selectedShippingId,
  packages: state.auth.packagesDetails,
  loading: state.auth.couriersLoading,
})

export default withRouter(
  connect(mapsStateToProps, { ...MainActions, ...AuthActions })(SpedizioniStep1)
)
