import React from "react"
import "./adminListaUtenti.css"
import AdminListaUtentiRow from "./AdminListaUtentiRow"
import MainActions from "redux-store/models/main"
import CalendarRangePicker from "shared-components/CalendarRangePicker/CalendarRangePicker"
import { connect } from "react-redux"
import moment from "moment"
import { Select, Pagination , Form, Input, Button } from "antd"

const { Option } = Select
class AdminListaUtenti extends React.Component {
  state = {
    perPage: 25,
    page_number: 1,
    username : "",
    ragione_sociale : "",
    city : "",
    user_id : "",
    isCalendarOpen: false,
    from: "",
    to: "",
    fromLabel: "",
    toLabel: "",
    filter : false,
    picker: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
        color: "var(--accent-bg)",
      },
    ],
  }

  constructor(props){
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUsername = this.handleUsername.bind(this);
    this.handleRagione = this.handleRagione.bind(this);
    this.handleCity = this.handleCity.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  setCalendar = (val) => {
    this.setState({ isCalendarOpen: val })
  }

  componentDidMount() {
    const Special =
      this.props.activeSkinId === -1 &&
      this.props.accountInfo?.profile?.role?.name !== "support"
    
      if(this.props.accountInfo?.profile?.role?.name === "super_admin" || this.props.accountInfo?.profile?.role?.name === "agent")
      this.props.setActiveSkinId(this.props.accountInfo?.profile?.activity_id)

    if (Special) {
      this.props.getUsers(
        null,
        {
          skin_id: this.props.accountInfo?.profile?.activity_id ? this.props.accountInfo?.profile?.activity_id : 1
        },
        25,
        1
      )
    } 
    else {
      this.props.getUsers(
        null,
        {
          skin_id: this.props.accountInfo?.profile?.activity_id ? this.props.accountInfo?.profile?.activity_id : this.props.activeSkinId,
          backoffice: true,
        },
        25,
        1
      )
    }
  }
  componentDidUpdate(prevProps,prevState) {
    if (this.props.activeSkinId !== prevProps.activeSkinId) {
      this.setState({ page_number: 1 })
      const Special =
        this.props.activeSkinId === -1 &&
        this.props.accountInfo?.profile?.role?.name !== "support"
      if (Special) {
        this.props.getUsers(
          null,
          {
            skin_id: 1,
          },
          25,
          1
        )
      } else {
        this.props.getUsers(
          null,
          {
            skin_id: this.props.activeSkinId,
            backoffice: true,
          },
          25,
          1
        )
      }
    }

    if (this.props.fromDate && this.props.fromDate !== prevProps.fromDate) {
      const label = moment(this.props.fromDate, "DD/MM/YYYY")

      this.setState({
        from: this.props.fromDate,
        to: this.props.fromDate,
        fromLabel: label,
        toLabel: label,
        perPage: 25,
      })
    }
  }

  handleSubmit(e){
    const { username, ragione_sociale, city, user_id , from , to } = this.state
    e.preventDefault()
      this.props.getUsers(
        null,
        {
          skin_id: this.props.activeSkinId,
          backoffice: true,
          username : username,
          ragione_sociale : ragione_sociale,
          city : city,
          user_id : user_id,
          from_last_login : from ,
          to_last_login : to
        },
        25,
        1,
      )
      this.setState({
        page_number : 1,
        filter : true
      })
  }  

  handleUsername(e){
    const { ragione_sociale, city , from , to , perPage } = this.state
    this.setState({username : e.target.value})
    if(e.target.value.length > 2 || e.target.value.length == 0){
    this.props.getUsers(
      null,
      {
        skin_id: this.props.activeSkinId,
        backoffice: true,
        username : e.target.value,
        ragione_sociale : ragione_sociale,
        city : city,
        from_last_login : from ,
        to_last_login : to
      },
      perPage,
      1,
    )
    this.setState({
      page_number : 1,
      filter : e.target.value.length == 0 ? false : true
    })
  }
  }

  handleRagione(e){
    const { username, city , from , to , perPage } = this.state
    this.setState({ragione_sociale : e.target.value})
    if(e.target.value.length > 2 || e.target.value.length == 0){
    this.props.getUsers(
      null,
      {
        skin_id: this.props.activeSkinId,
        backoffice: true,
        username : username,
        ragione_sociale : e.target.value,
        city : city,
        from_last_login : from ,
        to_last_login : to
      },
      perPage,
      1,
    )
    this.setState({
      page_number : 1,
      filter : e.target.value.length == 0 ? false : true
    })
   }
  }

  handleCity(e){
    const { username, ragione_sociale , from , to , perPage } = this.state
    this.setState({city : e.target.value})
    if(e.target.value.length > 2 || e.target.value.length == 0){
    this.props.getUsers(
      null,
      {
        skin_id: this.props.activeSkinId,
        backoffice: true,
        username : username,
        ragione_sociale : ragione_sociale,
        city : e.target.value,
        from_last_login : from ,
        to_last_login : to
      },
      perPage,
      1,
    )
    this.setState({
      page_number : 1,
      filter : e.target.value.length == 0 ? false : true
    })
   }
  }

  refresh(){
    this.setState({
      username : "",
      ragione_sociale : "",
      city : "",
      user_id : "",
      from : "" ,
      to : "",
      fromLabel : "",
      toLabel : "",
      filter : false
    })
    this.props.getUsers(
      null,
      {
        skin_id: this.props.activeSkinId,
        backoffice: true,
      },
      25,
      1
    )
} 

  SortArrayByDate(Array, Order) {
    const newArray = [...Array]
    if (Order === "Desc") {
      newArray.sort((a, b) =>
        moment(
          a.last_login_time === "-" ? "01-01-2300 00:00:00" : a.last_login_time,
          "DD-MM-YYYY HH:mm:ss"
        ).diff(
          moment(
            b.last_login_time === "-" ? "01-01-2300 00:00:00" : b.last_login_time,
            "DD-MM-YYYY HH:mm:ss"
          )
        )
      )
    } else {
      newArray.sort((a, b) =>
        moment(
          b.last_login_time === "-" ? "01-01-1800 00:00:00" : b.last_login_time,
          "DD-MM-YYYY HH:mm:ss"
        ).diff(
          moment(
            a.last_login_time === "-" ? "01-01-1800 00:00:00" : a.last_login_time,
            "DD-MM-YYYY HH:mm:ss"
          )
        )
      )
    }
    return newArray
  }

  render() {
    const {
      userList,
      LoaderAU,
      screenWidth,
      activeSkinId,
      accountInfo,
      total_pages,
    } = this.props
    const { perPage, page_number , filter } = this.state
    const Special =
      activeSkinId === -1 && accountInfo?.profile?.role?.name !== "support"
    return (
      <div className="AdminListaUtenti">
        {this.props.MessaggiComponent}
        <div className="AdminListaUtenti--Header">
          <span>ID Utente</span>
          <span
            className={`${Special && screenWidth < 1024 ? "SpecSm" : ""}`}
            style={screenWidth <= 850 && Special ? { left: "7%" } : {}}
          >
            <Input style={{width:"70%"}} name="username" value={this.state.username} onChange={this.handleUsername} placeholder="Username"></Input>
          </span>
          <span
            className={`${
              Special && screenWidth < 1024 ? "SpecSm" : Special ? "none" : ""
            }`}
            style={
              Special && screenWidth <= 1440
                ? {
                    width: "calc(43.5% - 230px)",
                    left: "-2.8%",
                    marginLeft: "1%",
                  }
                : Special
                ? {
                    width: "calc(36.5% - 230px)",
                    marginLeft: "1%",
                    left: "-2.8%",
                  }
                : screenWidth > 1440
                ? {
                    width: "calc(36.5% - 230px)",
                    left: "-2.8%",
                  }
                : {
                    width: "calc(36.5% - 230px)",
                    left: "-2.5%",
                  }
            }
          >
            <Input name="ragione_sociale" value={this.state.ragione_sociale} onChange={this.handleRagione} placeholder="Ragione Sociale"></Input>
          </span>
          <span
            className={`${Special && screenWidth < 1024 ? "SpecSm" : ""}`}
            style={
              screenWidth >= 2000 && !Special
                ? {
                    width: "4%",
                    justifyContent: "flex-end",
                    marginRight: "1.5%",
                  }
                : screenWidth <= 400 && Special
                ? { paddingLeft: "8%" }
                : screenWidth <= 500 && Special
                ? { paddingLeft: "10%" }
                : screenWidth <= 550 && Special
                ? { paddingLeft: "12%" }
                : screenWidth <= 850 && Special
                ? { justifyContent: "flex-end", left: "-3%" }
                : Special
                ? {
                    width: "7.5%",
                    justifyContent: "flex-end",
                    marginRight: "1%",
                    left: "-3%"
                  }
                : {
                    width: "4%",
                    justifyContent: "center",
                    marginRight: "0.8%",
                    left: "-0.2%"
                  }
            }
          >
            Credito
          </span>
          {Special ? null : <span style={{ width: "7%", left: "0" }}>
            <Input name="city" value={this.state.city} onChange={this.handleCity} placeholder="Citta"></Input>
            </span>
          }
          <span
            style={
              Special
                ? { width: "13%", justifyContent: "center", left: 0 }
                : { width: "12%", justifyContent: "center", left: 0 }
            }
            className={`${
              Special && screenWidth < 1024 ? "SpecSm" : Special ? "none" : ""
            }`}
          >
            Ultimo Deposito
          </span>
          <span
            className={`${Special ? "none" : ""}`}
            style={
              !Special && screenWidth <= 850
                ? { justifyContent: "center", left: 0, display: "none" }
                : Special
                ? { width: "18%", justifyContent: "center", marginRight: "1%" }
                : { width: "13%", justifyContent: "center", left: 0 }
            }
          >
            <Form onSubmit={this.handleSubmit}>
           <span  style={{width:"100%"}}>
             <div
                        className="dal calendar"
                        type="text"
                        onClick={() => {
                          this.setCalendar(true)
                        }}
                      >
                        <span style={{width:"100%"}}>
                          {this.state.fromLabel
                            ? `${this.state.fromLabel} - ${this.state.toLabel}`
                            : "Ultimo Login"}
                          <i style={{marginLeft : "10px"}} className="fal fa-calendar-alt"></i>
                        </span>
              </div>
            </span>
           </Form>
          </span>
          <span
            style={{ width: "210px", justifyContent: "center" }}
            className={`${Special ? "activated" : ""}`}
          >
            {this.props.accountInfo?.profile.role.name != "agent" ? "Operazioni" : ""}
            <Button type="button" style={{marginLeft : "20px"}} onClick={this.refresh}>Refresh</Button>
          </span>
        </div>
        {this.state.isCalendarOpen && (
                <CalendarRangePicker
                  setStateFunc={(item) => {
                    this.setState({
                      picker: [item.selection],
                      from: moment(item.selection.startDate).format("YYYY-MM-DD"),
                      to: moment(item.selection.endDate).format("YYYY-MM-DD"),
                      fromLabel: moment(item.selection.startDate).format(
                        "DD/MM/YYYY"
                      ),
                      toLabel: moment(item.selection.endDate).format("DD/MM/YYYY"),
                    })
                  }}
                  setStateFuncEmpty={() => {
                    this.setState({
                      from: "",
                      to: "",
                      fromLabel: "",
                      toLabel: "",
                    })
                    
                    this.props.getUsers(
                      null,
                      {
                        skin_id: this.props.activeSkinId,
                        backoffice: true,
                        username : this.state.username,
                        ragione_sociale : this.state.ragione_sociale,
                        city : this.state.city,
                        from_last_login : "" ,
                        to_last_login : ""
                      },
                      perPage,
                      1,
                    )
                  }}
                  picker={this.state.picker}
                  setCalendar={this.setCalendar}
                  handleSubmit={this.handleSubmit}
                />
              )}
        <div className="AdminListaUtentiRow">
          {/* <div style={this.props.activeSkinId == -1 ? {display : "none"} : {}  } >
           <span>
             <button type="button" onClick={this.refresh}>Refresh</button>
           </span>
           </div> */}
          {LoaderAU && LoaderAU === true ? (
            <div className="loaderAdmin">Loading...</div>
          ) : userList && Array.isArray(userList) && userList.length >= 1 ? (
            userList.map((itemList, i) => {
              return (
                <AdminListaUtentiRow
                  itemList={itemList}
                  key={itemList.id + i}
                  perPage={perPage}
                  page_number={page_number}
                  filter = {filter}
                />
              )
            })
          ) : (
            <div className="NoData">
              <i className="fal fa-info-circle"></i>
              <span>No Data</span>
            </div>
          )}
        </div>
        <div className="paginationWrapper">
          <Pagination
            current={this.state.page_number || 1}
            onChange={(e) => {
              this.setState({ page_number: e })
              !this.state.filter ? this.props.getUsers(
                null,
                {
                  skin_id: this.props.activeSkinId,
                  backoffice: true,
                },
                perPage,
                e
              ) : this.props.getUsers(
                null,
                {
                  skin_id: this.props.activeSkinId,
                  backoffice: true,
                  username : this.state.username,
                  ragione_sociale : this.state.ragione_sociale,
                  city : this.state.city,
                  user_id : this.state.user_id,
                  from_last_login : this.state.from ,
                  to_last_login : this.state.to
                },
                perPage,
                e
              )
            }}
            total={total_pages ? total_pages * 10 : 10}
          />
          <Select
            defaultValue={25}
            onChange={(e) => {
              this.setState({ perPage: parseInt(e), clickedPage: 1 }, () => {
                !this.state.filter ? this.props.getUsers(
                  null,
                  {
                    skin_id: this.props.activeSkinId,
                    backoffice: true,
                  },
                  e,
                  page_number
                ) : this.props.getUsers(
                  null,
                  {
                    skin_id: this.props.activeSkinId,
                    backoffice: true,
                    username : this.state.username,
                    ragione_sociale : this.state.ragione_sociale,
                    city : this.state.city,
                    user_id : this.state.user_id,
                    from_last_login : this.state.from ,
                    to_last_login : this.state.to
                  },
                  e ,
                  page_number
                )
              })
            }}
            value={this.state.perPage}
          >
            <Option value={10}>10 / Pagina</Option>
            <Option value={25}>25 / Pagina</Option>
            <Option value={50}>50 / Pagina</Option>
          </Select>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  userList: state.main.userList.users,
  total_pages: state.main.userList.total_pages,
  LoaderAU: state.main.LoaderAU,
  screenWidth: state.main.screenWidth,
  accountInfo: state.auth.accountInfo,
})
export default connect(mapStateToProps, { ...MainActions })(AdminListaUtenti)
