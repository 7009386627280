import React from "react"

const MySpan = ({
  title,
  iconClass,
  classNm,
  handleChange,
  defaultValue,
  component,
  name,
  error,
}) => (
  <div>
    <span
      className={classNm ? classNm + " spanField" : "spanField"}
      style={{ borderColor: error ? "red" : "" }}
    >
      <input
        required
        name={name}
        placeholder={title}
        onChange={(e) => {
          handleChange(e)
        }}
        defaultValue={defaultValue || ""}
      />
      {component ? component : <i className={iconClass} />}
    </span>
    {error && (
      <div className="error-message" style={{ fontSize: 12, color: "red" }}>
        {error}
      </div>
    )}
  </div>
)

export default MySpan
