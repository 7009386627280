import React, { useEffect } from "react"
import images from "themes/images"

const PaymentModal = ({ setPrint, setModal }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [])
  return (
    <div className="coffe-payment-modal">
      <div className="modalbackdrop" onClick={() => setModal()} />
      <div className="coffe-payment-modal__content">
        <h1>ACQUISTO ESEGUITO</h1>
        <h3>Grazie. Il tuo ordine e stato ricevuta!</h3>
        <img src={images["bolletinoFake"]} alt="" />
        <button
          onClick={async () => {
            await setPrint(false)
            await setPrint(true)
          }}
        >
          SCARICA L’ORDINE
        </button>
      </div>
    </div>
  )
}
export default PaymentModal
