import React, { useEffect, useState } from "react"
import { Button, Form, Input, Modal, notification, Select } from "antd"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import Gallery from "../../routes/views/Gallery"
import { MainActions, AuthActions } from "redux-store/models"
import { baseUrlImg, endpoint } from "../../config/api"
import { connect } from "react-redux"
import "./categoryStyle.css"
import axios from "axios"

const { Option } = Select

const AddCategory = ({
  form,
  getServicesTree,
  servicesTree,
  accountInfo,
  selectedId,
  setSelectedId,
}) => {
  const [description, setDescription] = useState("")
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [icon, setIcon] = useState("")
  const [selectedGalleryImage, setSelectedGalleryImage] = useState(null)
  const [color, setColor] = useState("#0000")
  const { getFieldDecorator, validateFields, setFieldsValue } = form

  const token = accountInfo?.token

  const modules = {
    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    },
  }

  const flattenCategories = (categories) => {
    let flatList = []
    categories.forEach((category) => {
      flatList.push(category)
      if (category.children_recursive && category.children_recursive.length > 0) {
        flatList = flatList.concat(flattenCategories(category.children_recursive))
      }
    })
    return flatList
  }

  const findCategoryById = (categories, id) => {
    for (const category of categories) {
      if (category.id === id) {
        return category
      }
      if (category.children_recursive && category.children_recursive.length > 0) {
        const found = findCategoryById(category.children_recursive, id)
        if (found) return found
      }
    }
    return null
  }

  const flatCategories = flattenCategories(servicesTree || [])

  const selectedCategory = findCategoryById(servicesTree || [], selectedId)

  useEffect(() => {
    if (selectedCategory) {
      setDescription(selectedCategory.description || "")
      setIcon(selectedCategory.icon || "")
      setSelectedGalleryImage(selectedCategory.icon || null)

      setFieldsValue({
        name: selectedCategory.name,
        parentCategory: selectedCategory.parent_id,
        slug: selectedCategory.slug,
      })
      setColor(selectedCategory.color)
    }
  }, [selectedCategory, setFieldsValue])

  const handleSubmit = () => {
    validateFields((err, values) => {
      if (!err) {
        const headers = {
          Authorization: `Bearer ${token}`,
        }
        if (selectedId) {
          axios
            .post(
              `${endpoint}/category/update`,
              {
                id: selectedId,
                name: values.name,
                description,
                parent_id: values.parentCategory,
                icon: icon.id,
                slug: values.slug,
                color: color,
              },
              {
                headers: headers,
              }
            )
            .then(() => {
              notification.success({
                message: "Category updated successfully!",
              })
              form.resetFields()
              setSelectedId(null)
              getServicesTree()
            })
            .catch((err) => console.log(err))
        } else {
          axios
            .post(
              `${endpoint}/category/create`,
              {
                name: values.name,
                description,
                parent_id: values.parentCategory,
                icon: icon.id,
                slug: values.slug,
                color: color,
              },
              {
                headers: headers,
              }
            )
            .then(() => {
              notification.success({
                message: "Category added successfully!",
              })
              form.resetFields()
              getServicesTree()
            })
            .catch((err) => console.log(err))
        }
      }
    })
  }

  useEffect(() => {
    getServicesTree()
  }, [])

  return (
    <>
      <Modal
        className="gallery-modal"
        width={550}
        title="Gallery"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => {
          setIsModalVisible(false)
          setIcon(selectedGalleryImage)
        }}
      >
        <Gallery
          onSelect={(image) => setSelectedGalleryImage(image)}
          modalCardStyle={{ width: "100px", height: "100px" }}
          selectedImage={selectedGalleryImage}
          modalWidth={{ width: "100px", height: "30px" }}
          showActions={false}
          imageType="icon"
        />
      </Modal>
      <div className="categories">
        <div className="container">
          <div className="title">Aggiungi Categoria</div>
          <div className="content">
            <Form>
              <Form.Item label="Nome Categoria" name="name">
                {getFieldDecorator("name", {
                  rules: [
                    {
                      required: true,
                      message: "Please input the category name!",
                    },
                  ],
                })(<Input type="text" placeholder="Nome" />)}
              </Form.Item>

              <Form.Item
                style={{ marginBottom: 5 }}
                label="Parent Category"
                name="parentCategory"
              >
                {getFieldDecorator("parentCategory", {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                })(
                  <Select placeholder="Select Parent Category">
                    {flatCategories.map((category) => (
                      <Option value={category.id} key={category.id}>
                        {category.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item label="Slug" name="slug">
                {getFieldDecorator("slug", {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                })(<Input type="text" placeholder="Slug" />)}
              </Form.Item>

              <Form.Item label="Color" name="color">
                {getFieldDecorator("color", {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                })(
                  <div
                    style={{
                      border: "1px solid #d9d9d9",
                      borderRadius: 4,
                      height: 33,
                      padding: "0 10px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <input
                      type="color"
                      name="color"
                      onChange={(e) => setColor(e.target.value)}
                      style={{ width: "27px", marginRight: 5 }}
                      value={color}
                    />
                    <span> {color}</span>
                  </div>
                )}
              </Form.Item>

              <span className="form-info">
                Assegna un termine genitore per creare una gerarchia. Il termine
                Ricariche, ad esempio, potrebbe essere il genitore di Ricariche
                Telefoniche e Ricariche Pay Tv.
              </span>

              <Form.Item
                style={{ marginTop: 15 }}
                label="Descrizione (Opzionale)"
                name="description"
              >
                <ReactQuill
                  value={description}
                  onChange={(e) => setDescription(e)}
                  modules={modules}
                  placeholder="Type your description..."
                />
              </Form.Item>
            </Form>
          </div>
        </div>

        <div className="container">
          <div className="title">Icona</div>
          <div className="content">
            <div className="browse-files">
              <span>Trascina e rilascia i file da caricare qui</span>
              {selectedGalleryImage ? (
                <img
                  src={`${baseUrlImg}/${selectedGalleryImage.path_slug}`}
                  width={100}
                  height={100}
                  onClick={() => setIsModalVisible(true)}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <Button type="default" onClick={() => setIsModalVisible(true)}>
                  Browse Files
                </Button>
              )}
            </div>
          </div>
        </div>

        <div className="buttons">
          <Button type="default" onClick={() => setSelectedId(null)}>
            Cancella
          </Button>
          <Button type="primary" onClick={handleSubmit}>
            {selectedId ? "Update" : "Aggiungi Categoria"}
          </Button>
        </div>
      </div>
    </>
  )
}

const AddCategoryForm = Form.create()(AddCategory)

const mapsStateToProps = (state) => ({
  accountInfo: state.auth.accountInfo,
  servicesTree: state.main.servicesTree,
})

export default connect(mapsStateToProps, { ...MainActions, ...AuthActions })(
  AddCategoryForm
)
