import React from "react"
import { AuthActions } from "redux-store/models"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Header } from "../../shared-components"
import PublicBollettino from "../../shared-components/ModulePopUp/Bolletino/PublicBollettino"

function Main() {
  return (
    <div className="prenotazione-bolletino">
      <Header></Header>
      <div
          className="module container-fluid max-width_modulePopUP"
        >
          <div className="row">
             <PublicBollettino />
          </div>
      </div>
    </div>
  )
}

export default withRouter(connect(null, { ...AuthActions })(Main))
