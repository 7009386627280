import React, { useEffect, useState,useRef } from "react";
import $ from "jquery";
import ClickOut from "react-onclickout";

const getMarginBottom = () => {
    const poupHeight= $(".messaggi-popup").height();
    const contentHeight=$(".messaggi-popup__content").outerHeight()

  const marginb = (poupHeight - contentHeight )/ 2;
  return marginb > 0 && contentHeight >= poupHeight ? marginb : 0;
};

const AdminModal = (props) => {
  const { visibility,children,close,selector, className } = props;
  const [contentStyles,setContentStyles]=useState({ marginBottom: getMarginBottom() })
  const [popupStyles,setPopupStyles]=useState(visibility?{}:{display:"none"})
  const ref=useRef(null);

  useEffect(()=>{
    setContentStyles({ marginBottom: getMarginBottom() })
  },[ref])
  useEffect(()=>{
    setPopupStyles(visibility?{}:{display:"none"})
  },[visibility])

  return (  
    <ClickOut onClickOut={(e) => {
      const className =(typeof e.target?.className === "string" && e.target?.className )|| ""
      const isDropdownClicked =className.includes("ant-select-dropdown") || className.includes("list-dropdown-buttons")
        if (!$(selector).find(e.target).length && !$(e.target).is($(selector)) && !isDropdownClicked) {
          close()
        }
      }}>
      <div ref={ref} className={`messaggi-popup nuovo-biller ${className || ''}`} style={popupStyles}>
        <div className="backDrop" onClick={close} />
        <div className="messaggi-popup__content" style={contentStyles}>
         {children}
        </div>
      </div>
    </ClickOut>
  );
};

AdminModal.defaultProps = {
  submit: () => {},
};


export default AdminModal
