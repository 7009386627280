import React, { useState } from "react"
import ShopActions from "redux-store/models/shop"

import "./style.css"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import Slider2 from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const CatItems = ({
  total_records,
  subcategories,
  setSubCategory,
  setSubSubCategory,
  getProductsList,
  setManufacturer,
  isSelectedC,
  orderVal,
  isMobile,
  openProducts,
  tags,
  catParam,
}) => {
  const settings = {
    dots: false,
    speed: 500,
    infinite: false,
    slidesToScroll: isMobile ? 2 : 1,
    slidesToShow: isMobile ? 2 : 5,
  }

  const [isOpen, setIsOpen] = useState(false)

  const handleTextHover = () => {
    setIsOpen(true)
  }

  const handleTextLeave = () => {
    setIsOpen(false)
  }

  return (
    <div className="catgItems">
      <div className="catgItems__header">
        <div className="catgItems__title">
          Trovati {total_records ? `${total_records} prodotti` : "..."}
        </div>
        <div className="tags-dropdown">
          <div
            className="tags-text"
            onMouseEnter={handleTextHover}
            onMouseLeave={handleTextLeave}
          >
            Tags
            <i
              className={isOpen ? "fas fa-caret-up" : "fas fa-caret-down"}
              onClick={isOpen ? handleTextLeave : handleTextHover}
            />
          </div>

          {isOpen && (
            <div
              className="tags-flyout"
              onMouseEnter={handleTextHover}
              onMouseLeave={handleTextLeave}
            >
              <div className="tags-grid">
                {tags &&
                  Object?.keys(tags).map((tagKey, index) => (
                    <div
                      key={index}
                      className="tag"
                      onClick={() => {
                        window.location.hash = `product-filtered/${catParam}/${tags[tagKey]}`
                        // this.setState({ tagSelected: tagKey });
                        openProducts(true)
                      }}
                    >
                      {tags[tagKey]}
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <Slider2 {...settings} className="catgItems__items">
        {subcategories &&
          Object.keys(subcategories).map((item, index) => {
            return (
              <div
                key={index}
                className="testt"
                onClick={() => {
                  setSubCategory(subcategories[item].name)
                  setSubSubCategory(null)
                  getProductsList(
                    null,
                    null,
                    isSelectedC,
                    null,
                    orderVal,
                    null,
                    null,
                    subcategories[item].name
                  )
                  openProducts(true)
                }}
              >
                <div className="descr">
                  <span>{subcategories[item].name}</span>
                  <div>View tutti</div>
                </div>

                <img src={subcategories[item].url} alt=""></img>
              </div>
            )
          })}
      </Slider2>

      {subcategories &&
        Object.keys(subcategories)
          .filter((item) => subcategories[item]?.subcategories?.length !== 0)
          .map((item, index) => {
            const sub = subcategories[item]

            return (
              <div key={index} className="catgGroup">
                <div className="catgGroup__title">{sub.name}</div>
                <div className="catgGroup__items">
                  {sub.subcategories &&
                    Object.keys(sub.subcategories).map((subitem, i) => {
                      return (
                        <div
                          key={i}
                          className="subCatgGroup"
                          onClick={() => {
                            getProductsList(
                              null,
                              null,
                              isSelectedC,
                              sub.subcategories[subitem]?.name,
                              null,
                              null,
                              null,
                              sub.name
                            )
                            setSubCategory(sub.name)
                            setSubSubCategory(sub.subcategories[subitem]?.name)
                            setManufacturer(null)
                            openProducts(true)
                          }}
                        >
                          <div className="subCatgGroup__title">
                            {sub.subcategories[subitem]?.name}
                          </div>
                          <img src={sub.subcategories[subitem]?.url} alt=""></img>
                        </div>
                      )
                    })}
                </div>
              </div>
            )
          })}

      {/* <div className="shopTags">
        {
          tags &&
            Object.keys(tags).map((tagKey) => {
              return (
                <span
                  key={tagKey}
                  // className={tagSelected === tagKey ? "active" : ""}
                  onClick={() => {
                    window.location.hash = `product-filtered/${catParam}/${tags[tagKey]}`
                    // this.setState({ tagSelected: tagKey });
                    openProducts(true)
                  }}
                >
                  {tags[tagKey]}
                </span>
              )
            })
        }
      </div> */}
    </div>
  )
}

const mstp = (state) => ({
  isSelectedC: state.shop.isSelectedCategory,
  isSelectedSC: state.shop.isSelectedSubCategory,
  orderVal: state.shop.orderVal,
})

export default withRouter(connect(mstp, { ...ShopActions })(CatItems))
