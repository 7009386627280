import React from "react"
import { connect } from "react-redux"
import { AuthActions, MainActions } from "redux-store/models"
import "./adminHeader.css"
import images from "themes/images"
import Chat from "shared-components/Chat/Chat"
import { Button, Tooltip } from "antd"

class AdminHeader extends React.Component {
  state = {
    chat: false,
  }

  returnIcon = (id) => {
    return require(`../../../assets/images${id}/logo.svg`)
  }

  render() {
    const { small, accountInfo, activeSkinId, skinExtras, menuSkinVisible } =
      this.props

    return (
      <div className={`AdminHeader ${menuSkinVisible && "small"}`}>
        {!small && (
          <div className="AdminHeader--Title">
            {window.innerWidth > 550 && window.innerWidth < 1024 ? (
              <Tooltip title="Impossibile aprire il menu sullo schermo tra 550 px e 1024 px">
                <span style={{ cursor: "pointer" }} onClick={this.props.handleClick}>
                  <i className="fal fa-bars"></i>
                </span>
              </Tooltip>
            ) : (
              <span style={{ cursor: "pointer" }}>
                <i className="fal fa-bars" onClick={this.props.handleClick}></i>
              </span>
            )}
            <a href="/#/back-office/utenti">
              {accountInfo.profile.role.name != "support" &&
                accountInfo.profile.role.name != "main_admin" && (
                  <>
                    <img
                      className={
                        "imageHeaderTitle " + accountInfo.profile.activity_name
                      }
                      src={
                        accountInfo.profile.activity_id === 55
                          ? images["logoMain55"]
                          : this.returnIcon(accountInfo.profile.activity_id)
                      }
                      alt=""
                    />
                  </>
                )}
              {(accountInfo.profile.role.name == "support" ||
                accountInfo.profile.role.name == "main_admin") &&
              activeSkinId !== -1 &&
              activeSkinId !== undefined ? (
                <>
                  <div className="admin-header-logo">
                    {" "}
                    <img
                      className={"logo-" + activeSkinId}
                      alt={`logo-${activeSkinId}`}
                      src={
                        this.props.activeSkinId === 55
                          ? images["logoMain55"]
                          : images.getLogoBySkinId(activeSkinId)
                      }
                    />
                    <span>
                      {" "}
                      {accountInfo.profile.role.name == "support"
                        ? "SUPPORT PANNEL"
                        : "SERVICES ADMIN PANNEL"}{" "}
                    </span>
                  </div>
                </>
              ) : (
                <span>
                  {(accountInfo.profile.role.name == "support" ||
                    accountInfo.profile.role.name == "main_admin") && (
                    <>
                      <span>
                        {this.props.accountInfo.profile.role.name === "support"
                          ? this.props.accountInfo.profile.username ===
                            "support_prenotazioni"
                            ? "Luce&Gas"
                            : "Support"
                          : "Admin"}
                      </span>
                      <span> Panel</span>
                    </>
                  )}
                </span>
              )}
            </a>
          </div>
        )}

        <div
          className="AdminHeader--Box"
          style={{ marginLeft: window.innerWidth > 1024 ? 10 : 0 }}
        >
          {window.innerWidth > 1024 && (
            <Button
              icon={menuSkinVisible ? "menu-unfold" : "menu-fold"}
              onClick={this.props.handleClick}
              style={{
                padding: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          )}
          <div style={{ display: "flex", alignItems: "center" }}>
            {accountInfo?.profile?.username !== "support_prenotazioni" && (
              <div
                className={`AdminHeader--Category ${
                  this.props.location.pathname.includes("statistiche")
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  this.props.history.push("/back-office/statistiche")
                }}
              >
                <div>
                  <i className="fal fa-analytics"></i>
                  <span>Statistiche</span>
                </div>
              </div>
            )}
            {accountInfo?.profile?.username !== "support_prenotazioni" &&
              accountInfo?.profile?.role?.name !== "super_admin" &&
              accountInfo?.profile?.role?.name !== "agent" && (
                <div
                  className={`AdminHeader--Category ${
                    this.props.location.pathname.includes("support") ? "active" : ""
                  }`}
                  onClick={() => {
                    this.props.history.push("/back-office/support")
                  }}
                >
                  <div>
                    <i className="fal fa-exclamation-circle"></i>
                    <span>Errori</span>
                  </div>
                </div>
              )}
            {/* {accountInfo?.profile?.username !== "support_prenotazioni" && (
            <div
              className={`AdminHeader--Category ${
                this.props.location.pathname.includes("utenti") ? "active" : ""
              }`}
              onClick={() => {
                this.props.history.push("/back-office/utenti")
              }}
            >
              <div>
                <i className="fal fa-user-circle"></i>
                <span>Utenti</span>
              </div>
            </div>
          )} */}
            {accountInfo?.profile?.username !== "support_prenotazioni" && (
              <div
                className={`AdminHeader--Category ${
                  this.props.location.pathname.includes("messaggi") ? "active" : ""
                }`}
                onClick={() => {
                  this.props.history.push("/back-office/messaggi")
                }}
              >
                <div>
                  <i className="fal fa-envelope"></i>
                  <span>Messaggi</span>
                </div>
              </div>
            )}
            {accountInfo?.profile?.username !== "support_prenotazioni" && (
              <div
                className={`AdminHeader--Category ${
                  this.props.location.pathname.includes("movimenti") ? "active" : ""
                }`}
                onClick={() => {
                  this.props.history.push("/back-office/movimenti")
                }}
              >
                <div>
                  <i className="fal fa-wallet"></i>
                  <span>Movimenti</span>
                </div>
              </div>
            )}
            {(accountInfo?.profile?.role.name === "super_admin" ||
              accountInfo?.profile?.role.name === "agent") && (
              <div
                className={`AdminHeader--Category ${
                  this.props.location.pathname.includes("fatture") ? "active" : ""
                }`}
                onClick={() => {
                  this.props.history.push("/back-office/fatture")
                }}
              >
                <div>
                  <i className="fal fa-list-ul"></i>
                  <span style={{ whiteSpace: "nowrap" }}>Rendi Conto</span>
                </div>
              </div>
            )}
            {accountInfo?.profile?.username !== "support_prenotazioni" &&
              accountInfo?.profile?.role.name !== "support" && (
                <div
                  className={`AdminHeader--Category ${
                    this.props.location.pathname.includes("depositi") ? "active" : ""
                  }`}
                  onClick={() => {
                    this.props.history.push("/back-office/depositi")
                  }}
                >
                  <div>
                    <i className="fal fa-credit-card"></i>
                    <span>Depositi</span>
                  </div>
                </div>
              )}
            {accountInfo?.profile?.username !== "support_prenotazioni" && (
              <div
                className={`AdminHeader--Category ${
                  this.props.location.pathname.includes("shop") ? "active" : ""
                }`}
                onClick={() => {
                  this.props.history.push("/back-office/ordine-shop")
                }}
              >
                <div>
                  <i className="fal fa-bags-shopping" />
                  <span>Shop</span>
                </div>
              </div>
            )}
            {accountInfo?.profile?.username !== "support_prenotazioni" &&
              accountInfo?.profile?.role?.name !== "super_admin" &&
              accountInfo?.profile?.role?.name !== "agent" && (
                <div
                  className={`AdminHeader--Category ${
                    this.props.location.pathname.includes("servizi") ? "active" : ""
                  }`}
                  onClick={() => {
                    this.props.history.push("/back-office/servizi")
                  }}
                >
                  <div>
                    <i className="far fa-briefcase"></i>
                    <span>Servizi</span>
                  </div>
                </div>
              )}

            <div
              className={`AdminHeader--Category--Prenotazioni ${
                this.props.location.pathname.includes("prenotazioni") ? "active" : ""
              }`}
              onClick={() => {
                this.props.history.push("/back-office/prenotazioni")
              }}
            >
              <div>
                <i className="fal fa-ticket"></i>
                <span>Prenotazioni</span>
              </div>
            </div>
          </div>
          {/* {small && (
            <div
              className={`AdminHeader--Category `}
              onClick={() => {
                this.setState((state) => ({ chat: !state.chat }))
              }}
            >
              <div>
                <Chat />
              </div>
            </div>
          )} */}
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  accountInfo: state.auth.accountInfo,
  activeSkinId: state.main.activeSkinId,
  skinExtras: state.auth.skinExtras,
})
export default connect(mapStateToProps, { ...MainActions, ...AuthActions })(
  AdminHeader
)
