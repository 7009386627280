import React from "react"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import F24 from "./F24"
import Bolletino from "./Bolletino/Bolletino"
//import NewBolletino from "./NewBolletini";

class ModulePopUp1 extends React.Component {
  state = {
    helper: false,
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    document.body.style.overflow = "hidden"
    if (this.props.service.id === "BOL001") {
      this.setState({ helper: true })
    }
  }
  componentWillUnmount() {
    document.body.style.removeProperty("overflow")
  }
  render() {
    const { helper } = this.state
    const { service, accountInfo, reportisticaDet, isSepafin } = this.props

    console.log(service)

    return (
      <div
        className={`modulePopUP modulePopUP1 bolletino ${
          accountInfo?.profile?.role?.id ? "" : "not-logged"
        } ${
          service.id === "SPAGF24"
            ? isSepafin
              ? "sepa f24"
              : ""
            : isSepafin
            ? "flex"
            : ""
        } ${reportisticaDet ? "" : "max"}`}
        onClick={() => {
          // this.props.togglePopUp(false);
        }}
      >
        <div className="module container-fluid max-width_modulePopUP">
          <div className="row">
            {
              service.id === "SPAGF24" ? (
                <F24 service_id={service.id} />
              ) : (
                // isSepaUser || isTestAcc ?
                <Bolletino service_id={service.id} helper={helper} />
              )
              //  : (

              //   <NewBolletino
              //     service={service}
              //     service_id={service.service_id}
              //   ></NewBolletino>
              // )
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapsStateToProps = (state) => ({
  isShowing: state.main.isShowing,
  service: state.auth.service_s,
  accountInfo: state.auth.accountInfo,
  reportisticaDet: state.auth.ReportisticaDet,
  isSepafin: !!state.auth?.accountInfo?.profile?.hasSepafinCredentials,
})

export default connect(mapsStateToProps, { ...MainActions, ...AuthActions })(
  ModulePopUp1
)
